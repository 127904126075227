import React from 'react';
import { Modal } from 'react-bootstrap';
import { graphql, Query, Mutation } from 'react-apollo';

import promotionListQuery from './graphql/query/promotionList';
import instancePriceQueryClient from './../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../config/graphqlClient/mutation/updateInstancePrice';
import instancePriceStepQueryClient from './../../config/graphqlClient/query/instancePriceStep';
import client from './../../config/client';
import additionalListQuery from './graphql/query/additionalList';

import ReactHTMLParser from 'react-html-parser';
import { Button } from 'react-bootstrap';
import ModalAlert from './../ModalAlert';
import ModalConfirm from './../ModalConfirm';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';

const PromotionContainer = styled.div`
	img {
		max-width: 400px;
		margin: 20px auto 20px;
		display: block;
		border-radius: 6px;
	}

	padding-left: 10px;
	padding-right: 10px;

	p, li, ul, span {
		font-size: 20px !important;
		line-height: 1 !important;
	}

	strong {
		font-size: 24px !important;
		line-height: 1 !important;
	}

	p > span {
		font-size: 18px !important;
		line-height: 1 !important;
	}
`;

const ModalConfirmStyle = styled(ModalConfirm)`
	.modal-dialog {
		margin-top: 30px;
	}
`;

class ModalPromotion extends React.Component {
	state = {
		openModal: false,
	}

	checkAdditionalTrueDigitalPark(updateInstancePrice) {
		const { data } = this.props;
		const setting_truck_type_id = data.instancePrice.setting_truck_type_id;
		
		if (
			this.props.name === 'TDP400' ||
			this.props.name === 'TDP6WH'
		) {
			client
				.query({
					query: additionalListQuery,
					fetchPolicy: 'network-only',
					variables: {
						setting_truck_type_id,
					},
				})
				.then(({ data }) => {
					let additional = '';
					const additionalLabor4Wheels = data.additionalList.filter(
						item => item._id === '5c38262bd4425c3d608c9061',
					);
					const additionalLabor6Wheels = data.additionalList.filter(
						item => item._id === '5c77a4bbd3bb6bbabe8681e1',
					);

					if (additionalLabor4Wheels.length > 0) {
						additional = {
							_id: additionalLabor4Wheels[0]._id,
							name: additionalLabor4Wheels[0].name,
							price: additionalLabor4Wheels[0].price,
							qty: 2,
							truck_type_size: additionalLabor4Wheels[0].truck_type_size,
							__typename: 'Additional',
						};
					} else if (additionalLabor6Wheels.length > 0) {
						additional = {
							_id: additionalLabor6Wheels[0]._id,
							name: additionalLabor6Wheels[0].name,
							price: additionalLabor6Wheels[0].price,
							qty: 4,
							truck_type_size: additionalLabor6Wheels[0].truck_type_size,
							__typename: 'Additional',
						};
					}

					const instancePriceData = client.cache.readQuery({
						query: instancePriceQueryClient,
					});

					const removeLaborBeforeSetValue = instancePriceData.instancePrice.additional.filter(
						item =>
							!(
								item._id === '5c38262bd4425c3d608c9061' ||
								item._id === '5c77a4bbd3bb6bbabe8681e1'
							),
					);

					if (additional) {
						updateInstancePrice({
							variables: {
								promotion_name: this.props.name,
								paymentType:
									instancePriceData.instancePrice.paymentType === 'creditCard'
										? 'creditCard'
										: '',
								additional: [...removeLaborBeforeSetValue, additional],
							},
						});
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			updateInstancePrice({
				variables: {
					promotion_name: this.props.name,
				},
			});
		}
	}

	detectLanguageData = (data = null, language = 'th') => {
		return data[language] || data['th'];
	}

	render() {
		return (
				<Query
					query={promotionListQuery}
					fetchPolicy="network-only"
					variables={{
						name: this.props.name,
						setting_truck_type_id: this.props.data.instancePrice
							.setting_truck_type_id,
					}}
				>
					{({ loading, error, data }) => {
						if (loading)
							return null;

						if (error)
							return (
								<ModalAlert show={this.props.show} icon="fal fa-exclamation-triangle" title="แจ้งเตือน" onCloseModal={() => this.props.onCloseModal()}>
									<div className="text-center">{ error.message }</div>
									<hr />
									<Button bsStyle="primary" block style={{marginTop: 10}} onClick={() => this.props.onCloseModal()}>ตกลง</Button>
								</ModalAlert>
							);

						if (data.promotionList.promotion.length === 0 && data.promotionList.promotion_for_you.length === 0)
							return (
								<ModalConfirm
									show={this.props.show}
									labelButtonConfirm="ปิด"
									titleHeader="แจ้งเตือน"
									onHide={() => this.props.onCloseModal()}
									onPressButtonConfirm={() => this.props.onCloseModal()}
									>
									<div style={{lineHeight: 1}}>
										ไม่มีโปรโมชั่นนี้ โปรดตรวจสอบใหม่อีกครั้ง
									</div>
								</ModalConfirm>
							);

						const promotion = data.promotionList.promotion.length > 0 ? data.promotionList.promotion[0] : data.promotionList.promotion_for_you[0];

						return (
							<Mutation
								mutation={updateInstancePriceMutationClient}
								variables={{ promotion_name: this.props.name }}
								update={cache => {
									this.props.onCloseModal();
									const data = cache.readQuery({
										query: instancePriceStepQueryClient,
									});
									cache.writeQuery({
										query: instancePriceStepQueryClient,
										data: {
											instancePriceStep: {
												...data.instancePriceStep,
												promotion: false,
											},
										},
									});
								}}
							>
								{ updateInstancePrice => {
									return (
										<ModalConfirmStyle
											show={this.props.show}
											labelButtonConfirm={this.props.t('common:instancePrice.promotion.popupPromotion.buttonConfirm')}
											labelButtonCancel={this.props.t('common:instancePrice.promotion.popupPromotion.buttonCancel')}
											styleColorButtonConfirm="primary"
											titleHeader={this.detectLanguageData(promotion.title_lang, this.props.t('common:lang'))}
											onHide={() => this.props.onCloseModal()}
											onPressButtonCancel={() => this.props.onCloseModal()}
											onPressButtonConfirm={() => {
												this.checkAdditionalTrueDigitalPark(updateInstancePrice)
											}}
										>
											<div>
												<PromotionContainer>{ReactHTMLParser(this.detectLanguageData(promotion.detail_lang, this.props.t('common:lang')))}</PromotionContainer>
											</div>
										</ModalConfirmStyle>
									);
								}}
							</Mutation>
						);
					}}
				</Query>
		);
	}
}

export default graphql(instancePriceQueryClient)(withNamespaces()(ModalPromotion));
