import gql from 'graphql-tag';

export default gql`
	query ShipmentLastPayment(
    $shipper: ID
  ) {
		shipmentLastPayment(
      shipper: $shipper
    ) {
			paid_type
		}
	}
`;
