import gql from 'graphql-tag';

export default gql`
	mutation AddCreditCard(
        $token_card: token_card
	) {
		addCustomerCreditCard(
			token_card: $token_card
		) {
			succeed
		}
	}
`;
