import gql from 'graphql-tag';

export default gql`
  query shareRoute(
    $shipment_type: Int
  ) {
    settingTruckList (
			shipment_type: $shipment_type
		) {
			_id
			setting_truck_name {
				th
				en
			}
			setting_truck_type {
				_id
				truck_type_name {
					th
					en
				}
				truck_type_size_lang {
					th
					en
				}
			}
		}

    additionalList {
      _id
      name_lang {
        th
        en
      }
      truck_type_size_lang {
        th
        en
      }
    }

    accessoryList {
			_id
			name_lang {
				th
				en
			}
		}
  }
`;
