import gql from 'graphql-tag';

export default gql`
	mutation AccountUserUpdate(
    $_id: ID
    $accountInfoId: ID
    $permissionShipmentList: Boolean
    $permissionNotification: Boolean
    $accountUserStatus: Int
  ) {
		accountUserUpdate(
      _id: $_id
      account_info_id: $accountInfoId
      account_user_permission: {
        shipment_list: $permissionShipmentList
        noti: $permissionNotification
      }
      account_user_status: $accountUserStatus
    ) {
      _id
    }
	}
`;
