import gql from 'graphql-tag';

export default gql`
	query AccountContactList($contact_name: String) {
		accountContactList(contact_name: $contact_name) {
			account_contact {
				_id
				contact_name
				contact_tel
				contact_address {
					_id
					structured_formatting {
						main_text
						secondary_text
					}
					address
					province
					district
					subdistrict
					postcode
					lat
					lng
				}
			}
		}
	}
`;
