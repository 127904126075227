import gql from 'graphql-tag';

export default gql`
query  search(
  $shipment_tracking_number : String
  $shipper: ID
){
  shipmentList(
    shipment_number: $shipment_tracking_number
    shipper: $shipper
    see_detail:true
  	) {
    shipment {
      _id
      shipment_type
      driver_name_under_mama
      driver_tel_under_mama
      round_trip
      shipment_status
      shipment_number
      shipper
      shipper_detail
      transporter		
      transporter_detail
      transporter_name
      transporter_phone_code
      transporter_phone_number
      user_setting_truck_id
      driver_user_id
      shipment_tracking_number
      distance
      paid_type
      paid_status
      setting_truck_name{
        th
      }
      truck_type_name{
        th
      }
      truck_type_qty
      pick_up_date
      last_update
      create_date
      shipment_address{
        mode
        address
        subdictrict
        lat
        lng
        contact_name
        contact_tel
        remark
        use_contact_point
        paid_by_cod
        pick_up_date
        delivery_date
      }
      shipment_other_detail{
        remark
        user_ref
        document {
          path
        }
      }
      shipment_additional{
        additional_id
        additional
        qty
        price
        address
      }
      trackStatus
      tracking_drop
      shipment_accessory {
        name_lang {
          th
          en
        }
      }
      completed_date
    }
    total_page
  }
}
`;
