import gql from 'graphql-tag';

export default gql`
	mutation AccountInviteDelete($_id: ID!) {
		accountInviteDelete(
      _id: $_id
    ) {
      succeed
    }
	}
`;
