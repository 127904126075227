import gql from 'graphql-tag';

export default gql`
	mutation ShareRouteAdd($obj_msg: String) {
		shareRouteAdd(obj_msg: $obj_msg) {
			_id
			url
		}
	}
`;
