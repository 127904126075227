import React, { Component } from "react";
import {Row , InputGroup , FormControl ,Button} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './style.css';
import { withNamespaces } from 'react-i18next';

const ItemContainer = styled(Row)`
    ${props => props.disabled && `
        opacity: 0.5;
    `};
`;

const InfoContainer = styled.i`
    margin-left: 7px;
    color: #B3B3B3;
    font-size: 16px;
    cursor: pointer;
`;

const IconContainer = styled.div`    
    font-size: 22px !important;
`;

const QtyContainer = styled.div`
    margin-top:10px;
    width: 100%;
    display: block;
    float: left;

    & .input-group{
        width: 130px;
    }
    
    &  input{
        text-align: center;
    }

    & .form-control[disabled]{
        background-color: #FBFBFB;
        color: #7C7C7C;
    }
`;
const AddressPod = styled.div`
    width: 100%;
    margin-top:10px;
    padding-top: 10px;
    float: left;
    border-top: 1px dashed #E3E3E3;
    position: relative;
    cursor: pointer;

    & .title{

    }

    & .detail{
        color: #808080;
        width: calc(100% - 50px);
        float: left;
        line-height: 1;
    }

    & .rightIconStyle{
        position: absolute;
        right: 0;
        top: calc(50% - 15px);
        float: left;
        color: #B3B3B3;
        width:20px;
        text-align: right;
        font-size: 18px;
        padding-top:7px;
    }
`;

const SubTitle = styled.div`
    width: 100%;
    float: left;
    margin-top: 3px;
    color: rgb(128, 128, 128);
    font-weight: normal;
    font-size: 18px;
    line-height: 1;
    white-space: pre-wrap;
`;

const AdditionalListItem = (props) => (
    <ItemContainer disabled={props.disabled} data-id={props._id} className={`additionalItem ${props.selected && props.price != 0 ? "selected" : ""}`}>
        <div className="title">
            {props.title} 

            {props.subTitle && (
                <SubTitle>{props.subTitle}</SubTitle>
            )}

            {props.price === 0 ? (
                <small>{props.t('common:instancePrice.additional.free')}</small>
            ) : (
                <small>{props.selected && `+ ${props.t('common:instancePrice.additional.extraCharge')} `}{props.price.toLocaleString('en-US', {maximumFractionDigits:2})} {props.priceUnit}</small>
            )}
            
            <InfoContainer  onClick={props.onClickInfo} className="fal fa-info-circle" />
            
            <QtyContainer style={{"display" :props.showQty ? "block" : "none"}} >
                <InputGroup>
                    <InputGroup.Button>
                        <Button onClick={(e) => props.onClickNumber(e,"mis")}>-</Button>
                    </InputGroup.Button>
                    
                    <FormControl
                        type="number"
                        value={props.value}
                        min={props.min}
                        max={props.max}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onClick={(e) => props.onClickNumber(e,"input")}
                    />

                    <InputGroup.Button>
                        <Button onClick={(e) => props.onClickNumber(e,"plus")}>+</Button>
                    </InputGroup.Button>
                </InputGroup>
            </QtyContainer>
        </div>
        
        <IconContainer onClick={props.disabled ? "" : props.onClick} className={`${props.className} rightIconStyle`} style={{color:`${props.selected ? "#19AA19" : "#E3E3E3"}`, cursor: 'pointer'}}><i className={props.selected ? "fas fa-check-circle" : "fal fa-circle"}></i></IconContainer>

        {
            props.showPodAddress && (
                <AddressPod onClick={props.onClickInfo}>
                    <div className="title">{props.t('common:instancePrice.additional.titleDocumentDeliveryAddress')}</div>
                    <div className="detail">{props.showPodAddress}</div>
                    <div className="rightIconStyle"><i className="fal fa-chevron-right"></i></div>
                </AddressPod>
            )
        }
   </ItemContainer>
);

AdditionalListItem.propTypes = {
    title: PropTypes.string,
    priceUnit: PropTypes.string,
    subTitle: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    price: PropTypes.string,
};
  
AdditionalListItem.defaultProps = {
    title : "",
    subTitle : "",
    selected: false,
    disabled: false,
    price: '',
};

export default withNamespaces()(AdditionalListItem);
