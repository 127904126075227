import gql from 'graphql-tag';

export default gql`
	mutation UpdateInstancePriceStep(
		$createShipment: Boolean
		$searchLocation: Boolean
		$contactInformation: Boolean
		$confirmShipment: Boolean
		$selectTruck: Boolean
		$paymentChanel: Boolean
		$paymentCOD: Boolean
		$additional: Boolean
		$promotion: Boolean
		$priceResult: Boolean
		$selectDateTime: Boolean
		$modalAdditional: Boolean
		$paymentCredit: Boolean
		$paymentCreditList: Boolean
		$paymentCreditEdit: Boolean
		$contact: Boolean
		$selectDateTimeContact: Boolean
		$selectRemark: Boolean
		$notification: Boolean
		$notificationMultiDrop: Boolean
	) {
		updateInstancePriceStep(
			createShipment: $createShipment
			searchLocation: $searchLocation
			contactInformation: $contactInformation
			confirmShipment: $confirmShipment
			selectTruck: $selectTruck
			paymentChanel: $paymentChanel
			paymentCOD: $paymentCOD
			additional: $additional
			promotion: $promotion
			priceResult: $priceResult
			selectDateTime: $selectDateTime
			modalAdditional: $modalAdditional
			paymentCredit: $paymentCredit
			paymentCreditList: $paymentCreditList
			paymentCreditEdit: $paymentCreditEdit
			contact: $contact
			selectDateTimeContact: $selectDateTimeContact
			selectRemark: $selectRemark
			notificationMultiDrop: $notificationMultiDrop
		) @client
	}
`;
