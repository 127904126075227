import gql from 'graphql-tag';

export default gql`
	query LocationTemp {
		locationTemp @client {
			address
			addressDefault
			latitude
			longitude
			latitudeCenter
			longitudeCenter
			isFitBounds
			isSelected
			type
			shipmentAddressIndex
			datetime
		}
	}
`;
