import React from 'react';
import styled from 'styled-components';
import { Input, Row, Col, Table, Button as ButtonAntd, Icon } from 'antd';
import ContactAdd from './container/ContactAdd';
import ContactEdit from './container/ContactEdit';
import ContactDelete from './container/ContactDelete';
import accountContactListQuery from '../../graphql/query/accountContactList';
import { Query } from 'react-apollo';

const Container = styled.div``;
const TableContainer = styled.div`
	font-size: 20px;
	margin-top: 20px;

	.ant-table {
		line-height: 1;
	}

	.ant-table-tbody
		> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
		> td {
		background: #f9f9f9;
	}

	.ant-table-thead > tr > th {
		font-weight: bold;
	}
`;

const ToolsContainer = styled.div`
	display: flex;

	.ant-btn {
		display: flex;
		align-items: center;

		& i {
			margin-right: 10px;
		}

		&:not(:last-child) {
			margin-right: 10px;
		}
	}
`;

class ContactList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpenModalAdd: false,
			isOpenModalEdit: false,
			isOpenModalDeleted: false,
			contactId: null,
			contactName: '',
			contactTel: '',
			contactNameSearch: '',
		};
	}

	openModalAddHandler = () => {
		this.setState({
			isOpenModalAdd: true,
		});
	};

	hideModalAdd = () => {
		this.setState({
			isOpenModalAdd: false,
		});
	};

	openModalEditHandler = (contact) => {
		this.setState({
			isOpenModalEdit: true,
			contactId: contact._id,
			contactName: contact.contact_name,
			contactTel: contact.contact_tel,
		});
	};

	hideModalEdit = () => {
		this.setState({
			isOpenModalEdit: false,
		});
	};

	openModalDeleteHandler = contact => {
		this.setState({
			isOpenModalDeleted: true,
			contactId: contact._id,
			contactName: contact.contact_name,
		});
	};

	hideModalDeleted = () => {
		this.setState({
			isOpenModalDeleted: false,
		});
	};

	changeContactNameSearch = event => {
		this.setState({ contactNameSearch: event.target.value });
	};

	render() {
		const columns = [
			{
				title: 'รายชื่อผู้ติดต่อ',
				dataIndex: 'contactName',
			},
			{
				title: 'เบอร์โทรศัพท์',
				dataIndex: 'phoneNumber',
			},
			{
				title: 'เครื่องมือ',
				dataIndex: 'tools',
				width: 300,
			},
		];

		return (
			<Container>
				<Row type="flex" justify="space-between">
					<Col xs={12} md={12} lg={6}>
						<Input.Search
							placeholder="ค้นหาจากรายชื่อ"
							defaultValue=""
							value={this.state.contactNameSearch}
							onChange={this.changeContactNameSearch}
						/>
					</Col>
					<Col xs={12} md={12} lg={6}>
						<Row type="flex" justify="end">
							<Col>
								<ButtonAntd onClick={this.openModalAddHandler}>
									+ เพิ่มรายชื่อ
								</ButtonAntd>
							</Col>
						</Row>
					</Col>
				</Row>

				<Query
					query={accountContactListQuery}
					fetchPolicy="cache-and-network"
					variables={{
						contact_name: this.state.contactNameSearch,
					}}
				>
					{({ data, loading, error }) => {
						let dataTable = [];
						if (!loading && !error) {
							dataTable = data.accountContactList.account_contact.map(
								(item, index) => {
									return {
										key: index,
										id: item._id,
										contactName: item.contact_name,
										phoneNumber: item.contact_tel,
										tools: (
											<ToolsContainer>
												<ButtonAntd onClick={() => this.openModalEditHandler(item)}>
													<i className="fal fa-pen-square"></i> แก้ไข
												</ButtonAntd>
												<ButtonAntd onClick={() => this.openModalDeleteHandler(item)}>
													<i className="fal fa-trash-alt"></i> ลบ
												</ButtonAntd>
											</ToolsContainer>
										),
									};
								}
							);
						}

						return (
							<TableContainer>
								<Table
									columns={columns}
									dataSource={dataTable}
									bordered
									pagination={false}
									size="middle"
									tableLayout="fixed"
									loading={
										loading && {
											indicator: (
												<Icon type="loading" style={{ fontSize: 24 }} spin />
											),
										}
									}
									locale={{
										emptyText: 'ไม่มีข้อมูล',
									}}
								/>
							</TableContainer>
						);
					}}
				</Query>

				<ContactAdd
					visible={this.state.isOpenModalAdd}
					onHide={this.hideModalAdd}
				/>

				<ContactEdit
					visible={this.state.isOpenModalEdit}
					onHide={this.hideModalEdit}
					contactId={this.state.contactId}
					contactName={this.state.contactName}
					contactTel={this.state.contactTel}
				/>

				<ContactDelete
					visible={this.state.isOpenModalDeleted}
					onHide={this.hideModalDeleted}
					title={`คุณต้องการลบรายชื่อ ${this.state.contactName} ใช่หรือไม่?`}
					contactId={this.state.contactId}
				/>
			</Container>
		);
	}
}

export default ContactList;
