import gql from 'graphql-tag';

export default gql`
	query EndCustomerTracking($order_number: String) {
		endCustomerTracking(order_number: $order_number) {
			_id
			order_number
			sender {
				_id
				account_info_business_name
			}
			customer {
				name
			}
			state {
				timestamp
				state_text
				remark
				document
			}
			status
			status_timestamp
			create_date
			last_update
		}
	}
`;
