import gql from 'graphql-tag';

export default gql`
	query DetailShipmentReview($shipment_id: ID) {
		shipmentList(_id: $shipment_id, see_detail: true) {
			shipment {
				driver_name_under_mama
				driver_user_id
				shipment_address {
					subdictrict
				}
				shipment_number
				transporter
			}
		}

		trackingList(shipment_id: $shipment_id) {
			tracking {
				_id
				driver_name
				driver_display_pic
				favorite_driver
				driver_user_agent
			}
		}
	}
`;
