import gql from 'graphql-tag';

export default gql`
	mutation TrackingDropCreate(
    $shipment_id: ID!
    $tracking_name: String!
    $tracking_drop: [String]!
  ) {
		trackingDropCreate(
      shipment_id: $shipment_id
      tracking_name: $tracking_name
      tracking_drop: $tracking_drop
    ) {
			_id
      shipment_id
      tracking_number
      tracking_drop
      delete
      create_date
      drop_link
		}
	}
`;
