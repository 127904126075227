import React, { createContext, useReducer, useEffect } from 'react';
import client from 'src/config/client';
import accountInfoListQuery from './graphql/query/accountInfoList';

const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

const initialState = {
	isParcelCustomer: false,
};

const userDetail = (state, action) => {
	return updateObject(state, {
		isParcelCustomer: action.payload.isParcelCustomer,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'actionUpdateUser':
			return userDetail(state, action);
		default:
			return { ...state };
	}
};

export const UserCreateContext = createContext();
const UserProvider = props => {
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	const getIsParcelCustomer = async () => {
		const { data: accountInfoListData } = await client.query({
			query: accountInfoListQuery,
			variables: {
				customer_service_type: 'PARCEL',
			},
			fetchPolicy: 'network-only',
		});

		if (
			accountInfoListData &&
			accountInfoListData.accountInfoList &&
			accountInfoListData.accountInfoList.account_info.length > 0 &&
			accountInfoListData.accountInfoList.account_info[0]._id !== null
		) {
			dispatch({
				type: 'actionUpdateUser',
				payload: {
					isParcelCustomer: true,
				},
			});
		}
	};

	useEffect(() => {
		getIsParcelCustomer();
	}, []);

	const store = {
		state,
		dispatch,
	};

	return (
		<UserCreateContext.Provider value={store}>
			{children}
		</UserCreateContext.Provider>
	);
};

export default UserProvider;
