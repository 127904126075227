import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;

	${props =>
		props.disabled &&
		`
		cursor: not-allowed;
	`}
`;

const IconContainer = styled.div`
	font-size: 18px;
	margin-right: 14px;
	color: ${props => (props.isSelect ? '#19AA19' : '#B3B3B3')};
	cursor: pointer;

	${props =>
		props.disabled &&
		`
		color: #F4F4F4;
		cursor: not-allowed;
	`}
`;

const TextContainer = styled.div``;
const Title = styled.div`
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
	color: ${props => (props.isSelect ? '#000000' : '#808080')};
	margin-top: -4px;
`;

const SubTitle = styled.div`
	font-size: 16px;
	line-height: 1;
	color: ${props => (props.isSelect ? '#19AA19' : '#B3B3B3')};
`;

const Icon = styled.span`
	margin-left: 4px;
	color: #b3b3b3;
	font-size: 14px;
	cursor: pointer;
`;

const SelectBox = props => {
	const { title, ...except } = props;
	return (
		<Container {...except} onClick={props.onPressSelect}>
			<IconContainer isSelect={props.isSelect} disabled={props.disabled}>
				{props.disabled && <i className="fas fa-circle" />}

				{!props.disabled ? (
					props.isSelect ? (
						<i className="fas fa-check-circle" />
					) : (
						<i className="fal fa-circle" />
					)
				) : null}
			</IconContainer>
			<TextContainer>
				<Title isSelect={props.isSelect}>
					{props.title}{' '}
					{props.icon && (
						<Icon onClick={e => props.onPressIcon && props.onPressIcon(e)}>
							<i className={props.icon} />
						</Icon>
					)}
				</Title>

				<SubTitle isSelect={props.isSelect}>{props.subTitle}</SubTitle>
			</TextContainer>
		</Container>
	);
};

SelectBox.propTypes = {
	onPressSelect: PropTypes.func,
	onPressIcon: PropTypes.func,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	icon: PropTypes.string,
	isSelect: PropTypes.bool,
	disabled: PropTypes.bool,
};

SelectBox.defaultProps = {
	isSelect: false,
	title: '',
	subTitle: '',
	icon: '',
	disabled: '',
};

export default SelectBox;
