import React from 'react';
import { FormGroup,FormControl,Image} from "react-bootstrap";
import { SketchPicker} from 'react-color';
import reactCSS from 'reactcss';
class TablePacking extends React.Component {
    constructor(props, context) {
		super(props, context);
		this.state = {
            background: '#fff',
            color: props.color,
            namecargo: props.namecargo,
            length: parseInt(props.length),
            width: parseInt(props.width),
            height: parseInt(props.height),
            weight: parseInt(props.weight),
            qty: parseInt(props.qty),
        };
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    handleChange = (color) => {
        this.setState({ color: color.hex.replace("#","") });
        this.props.handleChangeItem(color.hex.replace("#","") , "box_color")
    };
  render() {
    const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `#${this.state.color}`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            marginTop: '6px',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
            right: '0',
            marginRight: '50px',
            marginTop: '-351px'
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
    return (
        <tr>
            <td>
                <a style={{color:"rgb(217, 1, 1)"}} onClick={() => this.props.handleDelete()}><i className="fas fa-times" style={{marginTop: "6px"}}></i></a> 
            </td>
            <td>
                <Image style={{height:"30px",width:"auto" }} src={this.props.image} rounded />
            </td>
            <td>
                <FormGroup>
                    <FormControl type="text" name="namecargo" value={this.props.namecargo} placeholder="name" onChange={(e) => this.props.handleChangeItem(e.target.value , "box_name")} />
                </FormGroup>
            </td>
            <td>
                <FormGroup>
                    <FormControl type="number" name="length" value={this.props.length} placeholder="0" onChange={(e) => this.props.handleChangeItem(e.target.value , "box_length")} />
                </FormGroup>
            </td>
            <td>
                <FormGroup>
                    <FormControl type="number" name="width" value={this.props.width} placeholder="0" onChange={(e) => this.props.handleChangeItem(e.target.value , "box_width")} />
                </FormGroup>
            </td>
            <td>
                <FormGroup>
                    <FormControl type="number" name="height" value={this.props.height} placeholder="0" onChange={(e) => this.props.handleChangeItem(e.target.value , "box_height")} />
                </FormGroup>
            </td>
            <td>
                <FormGroup>
                    <FormControl type="number" name="weight" value={this.props.weight} placeholder="0" onChange={(e) => this.props.handleChangeItem(e.target.value , "box_weight")} />
                </FormGroup>
            </td>
            <td>
                <FormGroup>
                    <FormControl type="number" name="qty" value={this.props.qty} placeholder="0" onChange={(e) => this.props.handleChangeItem(e.target.value , "box_number")} />
                </FormGroup>
            </td>
            <td>
                <div style={ styles.swatch } onClick={ this.handleClick }>
                    <div style={ styles.color } />
                </div>
                { this.state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.handleClose }/>
                        <SketchPicker state={{position: 'fixed'}} color={ this.state.color } onChange={this.handleChange } />
                    </div> : null }
            </td>
        </tr>
    )
  }
}

export default TablePacking;