import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    margin: 10px 0px;
`;
const BoxLocation = styled.div`
    display: flex;
	align-self: stretch;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;
const ContentText = styled.div`
	font-size: 18px;
    color: #000;
`;
const BoxLine = styled.div`
    display: flex;
	align-self: stretch;
	flex-direction: row;
	align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    position: relative;
`;
const Icon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => props.color ? props.color : '#f2f2f2'};

    ${props => props.dot && `
        &::after {
            content: '';
            background-color: #FFFFFF;
            width: 6px;
            height: 6px;
            position: absolute;
            z-index: 999;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    `}
`;
const Line = styled.div`
	background-color: #f2f2f2;
	flex: 1;
	height: 5px;
`;
const LinePrimary = styled.div`
	background-color: #D90101;
	height: 5px;
	position: absolute;
	width: calc(((100% - 16px) / ${props => props.totalStatusNumber}) * ${props => props.statusNumber});
`;
const IconActiveContainer = styled.div`
    display: flex;
	position: absolute;
	left: calc(((100% - 16px) / ${props => props.totalStatusNumber}) * ${props => props.statusNumber});
	align-items: center;
	justify-content: center;
`;
//-----
const BoxDetail = styled.div`
    display: flex;
	background-color: #f2f2f2;
	padding: 10px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
    border-radius: 6px;
    position: relative;
`;
const Triangle = styled.div`
    width: 12px;
    height: 12px;
    background-color: #f2f2f2;
    position: absolute;
	top: -3px;
    left: calc((((100% - 16px) / ${props => props.totalStatusNumber}) * ${props => props.statusNumber}));
    
    ${props => props.statusNumber === 0 && `
        left: 5px;
    `}

    transform: rotate(45deg);
`;
const ContentTextContainer = styled.div`
    display: flex;
	flex-direction: row;
	align-items: center;
`;
const IconFont = styled.div`
    font-size: 16px;
    margin-right: 5px;
`;

const BoxStatusDetail = props => {
    return (
        <Container>
            <BoxLocation>
                <ContentText>{props.pickup}</ContentText>
                <ContentText>{props.delivery}</ContentText>
            </BoxLocation>
            <BoxLine>
                <Icon color="#D90101"/>
                <Line />
                <Icon color="#f2f2f2"/>
                <LinePrimary
                    totalStatusNumber={props.totalStatusNumber}
                    statusNumber={props.statusNumber}
                />
                <IconActiveContainer totalStatusNumber={props.totalStatusNumber} statusNumber={props.statusNumber}>
                    <Icon color="#D90101" dot={true}/>
                </IconActiveContainer>
            </BoxLine>
            <BoxDetail>
                <Triangle
                    totalStatusNumber={props.totalStatusNumber}
                    statusNumber={props.statusNumber}
                />
                <ContentTextContainer>
                    <IconFont><i className={props.icon}></i></IconFont>
                    <ContentText> {props.detail}</ContentText>
                </ContentTextContainer>
            </BoxDetail>
        </Container>
    )
};

BoxStatusDetail.propTypes = {
	pickup: PropTypes.string,
	delivery: PropTypes.string,
	detail: PropTypes.string,
	onPress: PropTypes.func,
	icon: PropTypes.string,
};

BoxStatusDetail.defaultProps = {
	pickup: '',
	delivery: '',
	detail: '',
};

export default BoxStatusDetail;
