import React, { Component } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import appUrl from 'src/config/appUrl';
import { Modal, Button, Avatar } from 'antd';
import firebaseConfig from 'src/config/firebaseConfig';
import s3 from 'src/config/s3';
import { compose, graphql } from 'react-apollo';
import client from 'src/config/client';
import urijs from 'urijs';
import driverAcceptFirebaseioDeleteMutation from './graphql/mutation/driverAcceptFirebaseioDelete';

const DriverDetailContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	font-size: 20px;

	& .title {
		font-size: 24px;
		margin-bottom: 20px;
	}

	& .driver-name {
		margin-bottom: 20px;
	}

	& .shipment-detail {
		margin-bottom: 30px;
	}

	& .ant-btn {
		background-color: #d90101;
		color: #ffffff;
		font-size: 20px;
		height: 36px;
		line-height: 36px;
		border: none;

		&:hover {
			background-color: #d90101;
			color: #ffffff;
		}
	}
`;

const AvatarContainer = styled.div`
	position: relative;
	display: inline-block;
	margin-bottom: 10px;

	& .ant-avatar {
		border: 1px solid #e7e7e7;
	}

	& .ant-avatar img {
		width: 100%;
		object-fit: cover;
		position: absolute;
		display: block;
		top: -9999px;
		bottom: -9999px;
		left: -9999px;
		right: -9999px;
		margin: auto;
	}
`;

const AgentDetailContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	font-size: 20px;

	& .title {
		font-size: 24px;
		margin-bottom: 50px;
	}

	& .driver-name {
		margin-bottom: 50px;
	}

	& .shipment-detail {
		margin-bottom: 50px;
	}

	& .ant-btn {
		background-color: #d90101;
		color: #ffffff;
		font-size: 20px;
		height: 36px;
		line-height: 36px;
		border: none;

		&:hover {
			background-color: #d90101;
			color: #ffffff;
		}
	}
`;

class ModalDriverAccept extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpenModal: true,
			popupDetail: {
				address: '',
				driver_display_pic: '',
				driver_name: '',
				shipment_id: '',
				shipment_number: '',
				user_setting_truck_license_plate: '',
				accept_type: null,
			},
		};

		if (!firebase.apps.length) {
			firebase.initializeApp(firebaseConfig);
		}
	}

	componentDidMount() {
		this.getDataDriverAcceptPopup();
	}

	getDataDriverAcceptPopup = () => {
		try {
			const accountInfoId = this.props.accountInfoId;
			if (!accountInfoId) {
				return;
			}

			firebase
				.database()
				.ref(`/driver_accept_popup/${accountInfoId}`)
				.limitToLast(1)
				.on('value', async snapshot => {
					if (snapshot.numChildren() === 1) {
						snapshot.forEach(child => {
							const val = child.val();
							console.log(val);
							const popupDetail = {
								address: val.address,
								driver_display_pic: val.driver_display_pic,
								driver_name: val.driver_name,
								shipment_id: val.shipment_id,
								shipment_number: val.shipment_number,
								user_setting_truck_license_plate:
									val.user_setting_truck_license_plate,
								accept_type: val.accept_type,
							};

							this.setState(
								{
									popupDetail: popupDetail,
									isOpenModal: true,
								},
								() => {
									setTimeout(() => {
										const urlPath = urijs(window.location.href).path();
										if (urlPath === '/shipmentdetail') {
											client.queryManager.refetchQueryByName('ShipmentList');
											client.queryManager.refetchQueryByName('TrackingList');
										}

										this.removeData();
									}, 1000);
								}
							);
						});
					}
				});
		} catch (error) {
			console.log(error);
		}
	};

	removeData = async () => {
		const accountInfoId = this.props.accountInfoId;

		try {
			await this.props.driverAcceptFirebaseioDeleteMutation({
				variables: {
					id: accountInfoId,
				},
			});
		} catch (error) {
			console.log(error);
		}
	};

	handleCancel = () => {
		this.setState({ isOpenModal: false });
	};

	render() {
		const {
			driver_display_pic,
			driver_name,
			address,
			shipment_number,
			shipment_id,
			accept_type,
		} = this.state.popupDetail;

		let modal = null;
		if (shipment_id) {
			if (accept_type === 1) {
				const driverDisplayPic = driver_display_pic
					? `${s3}/${driver_display_pic}`
					: '';

				modal = (
					<Modal
						visible={this.state.isOpenModal}
						footer={null}
						onCancel={this.handleCancel}
					>
						<DriverDetailContainer>
							<div className="title">จัดหาพนักงานขับรถของคุณได้แล้ว!</div>
							<AvatarContainer>
								<Avatar size={100} icon="user" src={driverDisplayPic} />
							</AvatarContainer>
							<div className="driver-name">{driver_name}</div>
							<div className="shipment-detail">
								<div>{address}</div>
								<div>{shipment_number}</div>
							</div>
							<div>
								<Button
									block
									href={`${appUrl}/shipmentdetail?shipment=${shipment_id}`}
								>
									ดูรายละเอียด
								</Button>
							</div>
						</DriverDetailContainer>
					</Modal>
				);
			} else if (accept_type === 2) {
				modal = (
					<Modal
						visible={this.state.isOpenModal}
						footer={null}
						onCancel={this.handleCancel}
					>
						<AgentDetailContainer>
							<div className="title">จัดหาผู้ดูแลงานของคุณได้แล้ว!</div>
							<div>ผู้ดูแลงาน</div>
							<div className="driver-name">{driver_name}</div>
							<div className="shipment-detail">
								กำลังมอบหมายงานให้พนักงานขับรถ
							</div>
							<div>
								<Button
									block
									href={`${appUrl}/shipmentdetail?shipment=${shipment_id}`}
								>
									ดูรายละเอียด
								</Button>
							</div>
						</AgentDetailContainer>
					</Modal>
				);
			}
		}

		return <React.Fragment>{modal}</React.Fragment>;
	}
}

export default compose(
	graphql(driverAcceptFirebaseioDeleteMutation, {
		name: 'driverAcceptFirebaseioDeleteMutation',
	})
)(ModalDriverAccept);
