import React from 'react';
import styled from 'styled-components';
import urijs from 'urijs';
import { Header } from 'src/components';
import Helmet from 'react-helmet';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Query, Mutation, graphql, compose } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import client from 'src/config/client';
import notificationListQuery from './graphql/query/notificationList';
import notificationUpdateMutation from './graphql/mutation/notificationUpdate';
import { Skeleton, Empty } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { HeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const Container = styled.div`
  margin-bottom: 30px;
`;

const Card = styled.div`
  padding-top: calc(90px + ${heightTopHeader}px);
  @media(max-width: 767px) {
    padding-top: 30px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  border-bottom: 2px solid #E3E3E3;
  line-height: 1;
  height: 40px;
`;

const NotificationContainer = styled.div`
	font-size: 20px;
	line-height: 1;
  box-sizing: border-box;

	& .notification {
		&-body {
			display: flex;
			flex-direction: column;
	
			&__title {
				padding: 10px 20px;
				font-size: 16px;
				color: #B3B3B3;
			}
	
			&__list {
				display: flex;
				flex-direction: row;
				padding: 15px 20px;
				position: relative;
        cursor: pointer;

        &:hover {
          background-color: #F9F9F980;
        }
        
        &:not(:last-child) {
          border-bottom: 1px solid #E3E3E3;
        }
	
				&--active {
					background-color: #F9F9F9 !important;
				}

				&-detail {
					display: flex;
					flex-direction: column;
					flex: 1;

					&__title {
						font-size: 18px;
						font-weight: bold;
					}

					&__sub-title {
						font-size: 16px;
            color: #888888;
            white-space: pre-wrap;
					}
				}

				&-time {
          position: absolute;
          top: 10px;
          right: 20px;
					color: #B3B3B3;
					font-size: 14px;
					align-self: flex-start;
				}
			}
		}

		&-icon {
			width: 35px;
			height:35px;
			background-color: #D90101;
			border-radius: 50%;
			margin-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px;

			& img {
				width: 100%;
			}
		}
	}
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;

    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      color: #000000;
    }
`;

class Notification extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
      data: '',
      pageNumber: 1,
    }
  }

  nextPage(page, total_page, refetch) {
		this.setState({pageNumber: page + 1, total_page});
		refetch();
	}

  render() {
    moment.locale(this.props.t('common:lang'));
    return (
      <div>
        <Helmet>
					<title>Notification | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
        <HeaderNav full/>

        <Container className="container">
          <Card>
            <Title>{this.props.t('common:notification.title')}</Title>
            <Query
              query={notificationListQuery}
              fetchPolicy="network-only"
              variables={{
                show_data: 20,
                page_number: this.state.pageNumber,
              }}
            >
              {
                ({ data, loading, error, refetch }) => {
                  if(loading) return (
                    <Skeleton style={{marginTop: 20}} active size="small"/>
                  );

                  if(error) return (<div style={{marginTop: 10}}>{ error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Error.' }</div>);

                  const {
                    notification,
                    total_page,
                  } = data.notificationList;
                  const filterNewNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') < 60);
                  const filterOldNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') >= 60);
                  const notificationList = [
                    {
                      title: this.props.t('common:notification.new'),
                      lists: [...filterNewNotification],
                    },
                    {
                      title: filterNewNotification.length > 0 ? this.props.t('common:notification.previous') : this.props.t('common:notification.all'),
                      lists: [...filterOldNotification],
                    }
                  ];

                  return (
                    <NotificationContainer>
                      <div className="notification-body">
                        {
                          notification.length === 0 && (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={
                                <span>
                                  {this.props.t('common:notification.empty')}
                                </span>
                              }
                              style={{margin: '80px 0px'}}
                            />
                          )
                        }

                        {
                          notificationList.map((item) => (
                            <React.Fragment>
                              { item.lists.length > 0 && (<div className="notification-body__title">{item.title}</div>) }
                              { item.lists.map((list, index) => (
                                <Mutation
                                  mutation={notificationUpdateMutation}
                                  variables={{
                                    _id: list._id,
                                    read_status: 2,
                                  }}
                                  update={(cache, { data }) => {
                                    const { succeed } = data.notificationUpdate;
                                    if(succeed) {
                                      if(list.notification_type === 1) {
                                        window.location = `/shipmentdetail?shipment=${list.shipment_id}`;
                                      } else if(list.notification_type === 2) {
                                        window.location = `/setting-business`;
                                      }
                                    }
                                  }}
                                  refetchQueries={() => ['NotificationList']}
                                >
                                  {
                                    (notificationUpdate) => {
                                      return (
                                        <div onClick={() => notificationUpdate()} key={index} className={`notification-body__list ${list.read_status === 1 && 'notification-body__list--active'}`}>
                                          <div className="notification-body__list-icon">
                                            <div className="notification-icon">
                                              <img 
                                                src={`${list.pic_type === 1 ? require('src/assets/images/notification-icon-giztix.svg') : list.pic_type === 3 ? require('src/assets/images/notification-icon-bullhorn.svg') : require('src/assets/images/notification-icon-truck.svg')}`}
                                                alt="icon"
                                              />
                                            </div>
                                          </div>
                                          <div className="notification-body__list-detail">
                                            <div className="notification-body__list-detail__title">{list.title_lang[this.props.t('common:lang')]}</div>
                                            <div className="notification-body__list-detail__sub-title">
                                              <div>{list.sub_title_lang[this.props.t('common:lang')]}</div>
                                            </div>
                                          </div>
                                          <div className="notification-body__list-time" title={`${moment(list.create_date).format('YYYY-MM-DD HH:mm:ss')}`}>{moment(list.create_date).fromNow()}</div>
                                        </div>
                                      )
                                    }
                                  }
                                </Mutation>
                              )) }
                            </React.Fragment>
                          ))
                        }                        
                      </div>

                      { (error || loading || total_page <= 1 ) ?  null :
                        (<ReactPaginate pageCount={total_page} forcePage={this.state.pageNumber -1}  containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected, total_page, refetch); }} />)
                      }
                    </NotificationContainer>
                  )
                }
              }
            </Query>
          </Card>
        </Container>
      </div>
    )
  }
}

export default withNamespaces()(Notification);