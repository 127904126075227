import gql from 'graphql-tag';

export default gql`
  query AccountInviteList(
    $account_info_id: ID
    $key_inv: String
    $status: Int
  ) {
    accountInviteList(
      account_info_id: $account_info_id
      key_inv: $key_inv
      status: $status
    ) {
      _id
      account_info_id
      email
      key_inv
      status
      account_info_business_name
      create_date
    }
  }
`;