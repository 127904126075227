import React from "react";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RowContainer = styled(Row)`
    background-color: #fff;
    width: 100%;
    border-radius: 0px;
    border: none;
    position: absolute;
    top: 60px;
    z-index: 99;
    margin-left: 0px;
    margin-right: 0px;
    height: 50px;

    @media(max-width: 767px) {
        position: relative;
        top: 0;
    }

    .breadcrumb > li + li:before {
        content: '';
        padding: 0;
    }
    
    .breadcrumb li {
        display: inline-block;
        height: 36px;
        padding: 0 15px;
    }
    
    .breadcrumb li.right,
    .breadcrumb li.img {
        padding: 0;
    }
    
    .breadcrumb li a {
        line-height: 35px;
        color: #000;
    }
    
    .breadcrumb > .active {
        color: #000;
        font-weight: bold;
        border-bottom: 2px solid #d90101;
    }
    
    .breadcrumb li.right a {
        line-height: 35px;
        color: #808080;
        text-decoration: none;
    }
    
    .breadcrumb li.img a {
        color: #808080;
        text-decoration: none;
    }
    
    .breadcrumb li.img img {
        height: 35px;
        float: left;
        padding-left: 10px;
    }
    
    padding-left: 20px;
`;
const BreadcrumbContainer = styled.div`
    padding: 7px 20px 0 15px;
    background-color: transparent;
    margin-bottom: 0;
`;
const Link = styled.a`
    padding: 7px 7px 0 0;
    background-color: transparent;
    margin-bottom: 0;
    color:#333333;
    display: inline-block;
`;
const Text = styled.div`
    padding: 7px 7px 0 0;
    background-color: transparent;
    margin-bottom: 0;
    color:#6d6d6d;
    display: inline-block;
`;
const BreadcrumbTitel = (props) => (
    <RowContainer>
        <BreadcrumbContainer className="container titel">
            {
                props.menu.map((item, key, arr) => (
                    (item.link !== "#" ) ? (
                        <Link key={key} active={item.active} href={item.link}> {item.icon ? (<i className={item.icon}></i>) : null} {item.title}</Link>
                    ) : (
                        <Text key={key} active={item.active}> {item.icon ? (<i className={item.icon}></i>) : null} {item.title}</Text>
                    )
                ))
            }
        </BreadcrumbContainer>
    </RowContainer>
);

BreadcrumbTitel.propTypes = {
    menu: PropTypes.array,
};
  
BreadcrumbTitel.defaultProps = {
    menu: [],
};

export default BreadcrumbTitel;
