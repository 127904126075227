import React from 'react';
import styled from 'styled-components';
import { Statistic, Spin, Icon } from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ReactModal from 'react-modal';
import stylesReactModal from './ModalCardFlashDeals.module.css';
import { Button } from 'react-bootstrap';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
ReactModal.defaultStyles.overlay.backgroundColor = '#00000050';
const { Countdown } = Statistic;
const Container = styled.div`
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  position: relative;
  overflow: hidden;
  padding: 10px;
`;

const LoadingContainer = styled.div`
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  position: relative;
  overflow: hidden;
  padding: 10px;
  min-height: 350px;

  & .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Ribbon = styled.div`
  line-height: 48px;
  text-align: center;
  font-size: 36px;
  color: #FFFFFF;
  background-color: #D90101;
  height: 48px;
  display: inline-block;
  min-width: 100px;
  padding: 0px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  white-space: nowrap;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 20px;
  box-sizing: border-box;

  @media(max-width: 767px) {
    font-size: 16px;
    height: 23px;
    line-height: 23px;
    padding: 0px 10px;
    left: 5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const ContentContainer = styled.div`
  text-align: center;
  margin-top: 80px;
  white-space: nowrap;

  @media(max-width: 767px) {
    margin-top: 32px;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #000000;
  line-height: 0.6;

  @media(max-width: 767px) {
    font-size: 20px;
  }
`;

const PriceContainer = styled.div`
  font-size: 24px;
  line-height: 1;

  & .price {
    font-weight: bold;
    font-size: 52px;
    color: #D90101;
    margin-left: 5px;
  }

  @media(max-width: 767px) {
    font-size: 18px;
    
    & .price {
      font-size: 34px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
  width: 100%;
  padding: 5px 8px;
`;

const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  position: absolute;
  font-size: 20px;
  top: 20px;
  right: 20px;

  & .countdown {
    font-weight: bold;
    padding-left: 5px;
  }

  & .ant-statistic-content {
    font-size: 20px;
    line-height: 1;
  }

  @media(max-width: 767px) {
    font-size: 16px;
    top: 5px;
    right: 5px;

    & .ant-statistic-content {
      font-size: 16px;
      line-height: 1;
    }
  }

  & i {
    font-size: 14px;
  }
`;

const GrossPriceContainer = styled.div`
  color: #888888;
  font-size: 24px;
  font-weight: normal;
  
  & .price {
    text-decoration: line-through;
  }

  @media(max-width: 767px) {
    font-size: 18px;
  }
`;

const TruckContainer = styled.div`
  margin-bottom: 10px;
  & img {
    height: 130px;
  }

  @media(max-width: 767px) {
    & img {
      height: 80px;
    }
  }
`;

const customStylesReactModalFlashDeals = {
	overlay: {
		zIndex: 999,
	}
};

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: #E3E3E3;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ButtonStyle =styled(Button)`
  min-width: 100px;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const DetailContainer = styled.div`
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 30px;

  @media(max-width: 767px) {
    font-size: 18px;
    line-height: 1;
  }
`;

const ModalCardFlashDeals = (props) => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={() => props.onRequestClose()}
      style={customStylesReactModalFlashDeals}
      className={stylesReactModal.modalPromotion}
    >
      {
        props.isLoading ? (
          <LoadingContainer>
            <Spin indicator={antIcon} />
          </LoadingContainer>
        ) : (
          <Container {...props}>
            <Ribbon>{props.name}</Ribbon>
            <ContentContainer>
              <TruckContainer>
                <img src={props.truckTypeImage} alt="truck type"/>
              </TruckContainer>
              <Title>{props.provinceName}</Title>
              <PriceContainer>{`เหลือเพียง `}
                <span className="price">
                  <NumberFormat displayType={'text'} value={props.priceSale} thousandSeparator={true}/> บาท
                </span>
              </PriceContainer>
              <CountDownContainer>
                <i className="fal fa-clock"></i>
                <span className="countdown">
                  <Countdown value={props.exp} format={`${moment(props.exp).diff(moment(), 'days') > 0 ? 'D วัน HH:mm:ss' : 'HH:mm:ss'}`} />
                </span>
              </CountDownContainer>
              <GrossPriceContainer>{`ราคาเดิม `}
                <span className="price">
                  <NumberFormat displayType={'text'} value={props.price} thousandSeparator={true}/> บาท
                </span>
              </GrossPriceContainer>
            </ContentContainer>
            <LineHorizontal />
            <DetailContainer>
              <div><strong>รายละเอียด</strong></div>
              <div><strong>ประเภทรถ: </strong>{props.truckTypeName}</div>
              <div><strong>ช่วงวันเวลาที่รับสินค้า: </strong>{props.pickupDate}</div>
            </DetailContainer>
            <Footer>
              <ButtonStyle onClick={() => props.onClose()}>ปิด</ButtonStyle>
              <ButtonStyle bsStyle={'primary'} onClick={() => props.onSelect()}>จองเลย</ButtonStyle>
            </Footer>
          </Container>
        )
      }

    </ReactModal>
  )
}

export default ModalCardFlashDeals;