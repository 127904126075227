import gql from 'graphql-tag';

export default gql`
  query LineDriverUser(
    $account_info_id: ID
    $line_user_id: ID
  ) {
    driverUserList (
      account_info_id: $account_info_id
      line_user_id: $line_user_id
    ) {
      driver_user {
        _id
        account_info_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_enable
      }
    }
  }
`;