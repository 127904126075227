import React from 'react';
import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';

const Container = styled.div`
	line-height: 1;
	padding: 10px;
	cursor: pointer;
	border-bottom: 1px solid #e3e3e3;
	font-size: 18px;

	& .location-name {
		font-weight: bold;
	}

	&:hover {
		background-color: #f9f9f9;
	}
`;

const ContactList = props => {
	return (
		<Container {...props} onClick={props.clicked}>
			<div className="location-name">{props.locationName}</div>
			<div>{props.contact}</div>
			<div>
				<Dotdotdot clamp={1}>{props.locationAddress}</Dotdotdot>
			</div>
		</Container>
	);
};

export default ContactList;
