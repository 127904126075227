import gql from 'graphql-tag';

export default gql`
	query reportResultList(
		$_id: ID
		$name: String
		$status: Int
		$create_date: String
		$page_number: Int
		$order_by: String
		$order_type: String
	) {
		reportResultList(
			show_data: 30
			_id: $_id
			name: $name
			status: $status
			create_date: $create_date
			page_number: $page_number
			order_by: $order_by
			order_type: $order_type
		) {
			report {
				_id
				name
				file_result
				status
				create_date
				error_result
			}
			total_page
		}
	}
`;
