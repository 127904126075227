import React from "react";
import {Row} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    CreditCardIcon
} from "src/components";

const ItemContainer = styled(Row)`
`;

const IconContainer = styled.div`
    font-size: 22px !important;
`;

const InfoContainer = styled.i`
    margin-left: 7px;
    color: #B3B3B3;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	border-bottom: 1px solid #e3e3e3;
	padding-bottom: 12px;
	margin-bottom: 12px;

	.image {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 50px;
        justify-content: center;
	}

	.detail {
		flex: 1;
		margin-left: 15px;

		&-title {
			font-size: 18px;
			font-weight: bold;
			line-height: 1;
		}

		&-sub-title {
			font-size: 16px;
			line-height: 1;
			color: #808080;
		}
    }
    
    .arrow {
        font-size: 22px;
        line-height: 1;
        padding-left: 10px;
        text-align: right;
    }
`;

const CreditListItem = (props) => (
    <Container {...props} onClick={props.onClick}>
		<div className="image">
            <CreditCardIcon brand={props.cardBrand} />
		</div>
		<div className="detail">
			<div className="detail-title">{props.name}</div>
			<div className="detail-sub-title">
                {props.title}
			</div>
		</div>
		<div className="arrow">
			<i style={{color:`${props.edit ? "#E3E3E3" : props.selected ? "#19AA19" : "#E3E3E3"}`}} className={props.edit ? "fal fa-angle-right" : props.selected ? "fas fa-check-circle" : "fal fa-circle"} />
		</div>
	</Container>
);

CreditListItem.propTypes = {
    title: PropTypes.string,
    selected: PropTypes.bool,
    cardBrand: PropTypes.string,
    edit: PropTypes.bool,
};
  
CreditListItem.defaultProps = {
    title : "",
    selected: false,
    edit: false
};

export default CreditListItem;
