import React from 'react';
import styled from 'styled-components';

const SelectBox = styled.select`
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	font-size: 18px;
	padding: 4px 10px;
	outline: none;

	${props =>
		props.error &&
		`
    border: 1px solid #D90101;
  `}
`;

const Select = props => {
	return <SelectBox {...props}>{props.children}</SelectBox>;
};

export default Select;
