import gql from 'graphql-tag';

export default gql`
	query AdditionalList($setting_truck_type_id: ID) {
		additionalList(setting_truck_type_id: $setting_truck_type_id) {
			_id
			name
			price
			truck_type_size
			truck_type_weight
			min
			limit
			address
		}
	}
`;
