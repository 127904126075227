import gql from "graphql-tag";

export default gql`
query
  lazadaPackageOrders(
    $params: paramsLazPackageOrder
    $sort: sort
    $page: Int
    $showData: Int!
  ){
    lazadaPackageOrders(
      params: $params
      sort: $sort
      page: $page
	    showData: $showData
    ){
			orders{
      _id
      trackingNumber
      giztix_package_status
      packageCode
      plan_date
      delivery_zone
      delivery_sla_date
      pickup_sla_date
      payment{
        paidAmount
        paymentType
      }
      items{
        name
      }
      create_date
      last_update
    }
    totalPage
    totalDocument
  }
    }
  

`;
