import React from 'react';
import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 1;
	margin-bottom: 10px;
	min-height: 50px;
	cursor: pointer;

	& .list-address {
		flex: 1;
		box-sizing: border-box;
	}

	& .list-arrow {
		width: 30px;
		text-align: right;

		& i {
			color: #b3b3b3;
			font-size: 30px;
		}
	}
`;

const list = props => {
	return (
		<Container onClick={props.clicked}>
			<div className="list-address">
				<Dotdotdot clamp={2}>{props.text}</Dotdotdot>
			</div>
			<div className="list-arrow">
				<i className="fal fa-angle-right"></i>
			</div>
		</Container>
	);
};

export default list;
