import gql from 'graphql-tag';

export default gql`
query  search(
  $_id : ID
){
  shipmentList(
    _id: $_id
    see_detail: true
  	) {
    shipment {
      _id
      shipment_status
      shipment_number
      shipper
      shipper_detail
      transporter_detail
      user_setting_truck_id
      driver_user_id
      shipment_tracking_number
      distance
      paid_type
      paid_status
      setting_truck_id
      setting_truck_type_id
      truck_type_pic
      setting_truck_name {
        th
        en
      }
      truck_type_name{
        th
        en
      }
      truck_type_qty
      pick_up_date
      create_date
      last_update
      shipment_address{
        mode
        address
        subdictrict
        province
        lat
        lng
        contact_name
        contact_tel
        remark
        use_contact_point
        paid_by_cod
      }
      shipment_other_detail{
        remark
      }
      shipment_additional{
        _id
        additional_id
        additional
        qty
        price
        address
      }
      trackStatus
      round_trip
      shipment_type
      shipment_confirm_detail {
        job_code
        bl_number
        commodity
        customer_name
        invoice_no
        packaging_type
        weight_per_container
      }
      shipment_accessory {
        _id
        accessory_id
        accessory
      }
      multi_point {
        distance_point
        distance_point_detail
        total_point
        price_per_point
      }
    }
    total_page
  }

  additionalList {
    _id
    address
    name
    price
    truck_type_size_lang {
      th
      en
    }
  }
}
`;
