import gql from 'graphql-tag';

export default gql`
	query PromotionList(
			$setting_truck_type_id: ID
			$lat_origin : String
			$lng_origin : String
			$lat_destination : String
			$lng_destination : String
		) {
		promotionList(
			setting_truck_type_id: $setting_truck_type_id
			lat_origin : $lat_origin
			lng_origin : $lng_origin
			lat_destination : $lat_destination
			lng_destination : $lng_destination
		) {
			promotion {
				_id
				name
				title
				detail
				discount
				type
				limit
				setting_truck_type_id
				location_start
				location_end
				start
				exp
				promotion_pic
				error
				title_lang {
					en
					th
				}
				sub_title_lang {
					en
					th
				}
				for_you
			}

			promotion_for_you {
				_id
				name
				title
				detail
				discount
				type
				limit
				setting_truck_type_id
				location_start
				location_end
				start
				promotion_pic
				error
				title_lang {
					en
					th
				}
				sub_title_lang {
					en
					th
				}
				for_you
			}
		}
	}
`;
