import React from 'react';
import styled from 'styled-components';
import { Header,ModalConfirm } from 'src/components';
import {
	Row,
	Col,
	Radio,
	Checkbox,
	Button,
	FormControl,
} from 'react-bootstrap';
import {
	SubHeader,
	Card,
	Input,
	Menu,
	SelectBox,
	Select,
	Label,
} from '../../components';

import Helmet from 'react-helmet';
import color from '../../../../config/color';
import provinceData from '../../../../config/province/changwats/th.json';
import districtData from '../../../../config/province/amphoes/th.json';
import subDistrictData from '../../../../config/province/tambons/th.json';
import { WithContext as ReactTags } from 'react-tag-input';
import accountBusinessUpdateMutation from './graphql/mutation/accountBusinessUpdate';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { Mutation, Query, Compose, graphql } from 'react-apollo';
import Moment from 'react-moment';
import { compose } from 'redux';
import landingUrl from '../../../../config/landingUrl';
import { withNamespaces } from 'react-i18next';
import { HeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';

//react tag input
const KeyCodes = {
	comma: 188,
	enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

//styled component
const Container = styled.div`
	background-color: #f9f9f9;
	padding: 20px;
	padding-top: calc(150px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		padding-top: 20px;
	}

	div.ReactTags__tagInput {
		width: 200px;
		display: inline-block;
	}

	div.ReactTags__tagInput input {
		font-size: 19px;
		border-radius: 6px;
		border: 1px solid #e3e3e3;
		padding: 0 12px;
		line-height: 1;
		height: 40px;
		background-color: #ffffff;
		outline: none;
	}

	div.ReactTags__selected span.ReactTags__tag {
		border: 1px solid #ddd;
		background: #eee;
		font-size: 20px;
		line-height: 1;
		display: inline-block;
		padding: 5px;
		margin: 0 5px;
		margin-left: 0;
		cursor: move;
		border-radius: 4px;
	}

	div.ReactTags__selected a.ReactTags__remove {
		color: #aaa;
		margin-left: 10px;
		cursor: pointer;
		text-decoration: none;
		font-size: 24px;
	}
`;

const TextHeader = styled.div`
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
`;

const StepContainer = styled.div`
	position: relative;
	margin-top: 25px;

	& ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;
		display: inline-block;

		& li {
			display: inline-block;

			&:not(:last-child)::after {
				content: '';
				background-color: #b3b3b3;
				width: 77px;
				height: 1px;
				display: inline-block;
				position: relative;
				margin: 4px 10px;
			}

			& span {
				display: inline-block;
				width: 24px;
				height: 24px;
				background-color: #b3b3b3;
				border-radius: 50%;
				text-align: center;
				font-weight: bold;
				padding-bottom: 5px;
				line-height: 1.3;
				margin-right: 6px;
			}

			&.active span {
				background-color: ${color.Primary};
				color: #ffffff;
			}
		}
	}
`;

const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const LineHorizontal = styled.div`
	height: 1px;
	width: 100%;
	background-color: #e3e3e3;
`;

const ButtonNext = styled.button`
	background-color: ${color.Primary};
	color: #ffffff;
	font-size: 20px;
	border: 1px solid ${color.Primary};
	border-radius: 4px;
	cursor: pointer;
	padding: 0px 60px;
	padding-top: 2px;
	box-sizing: border-box;
	min-height: 40px;
	white-space: nowrap;

	&:focus {
		outline: none;
	}

	&:hover {
		opacity: 0.9;
	}

	&:active {
		background-color: ${color.White} '50';
	}

	&:disabled {
		opacity: 0.65;
		cursor: not-allowed;
	}

	& i {
		font-size: 1.2em;
		display: inline-block;
		float: right;
		width: 20px;
	}
`;

const ButtonBack = styled.button`
	background-color: transparent;
	color: #000000;
	font-size: 20px;
	border: 0px solid ${color.Primary};
	border-radius: 4px;
	cursor: pointer;
	padding: 0px;
	padding-top: 2px;
	box-sizing: border-box;
	height: 40px;

	&:focus {
		outline: none;
	}

	&:hover {
		opacity: 0.9;
	}

	&:disabled {
		opacity: 0.65;
		cursor: not-allowed;
	}

	& i {
		font-size: 1.2em;
		display: inline-block;
		float: left;
		width: 30px;
	}
`;

const CustomRadio = styled(Radio)`
	padding-left: 35px;
	margin-top: 12px;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	& input:checked ~ .checkmark {
		background-color: #ffffff;
	}

	&:hover .checkmark {
		background-color: #f1f1f1;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #ffffff;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
	}

	$.disabled .checkmark {
		background-color: #f9f9f9;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	& input:checked ~ .checkmark:after {
		display: block;
	}

	& .checkmark:after {
		top: 5px;
		left: 5px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #19aa19;
	}
`;

const CustomCheckbox = styled(Checkbox)`
	padding-left: 35px;
	margin-top: 12px;

	& input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 24px;
		width: 24px;
		background-color: #ffffff;
		border: 1px solid #e3e3e3;
		border-radius: 4px;
	}

	$.disabled .checkmark {
		background-color: #f9f9f9;
	}

	&:hover input ~ .checkmark {
		background-color: #f1f1f1;
	}

	& input:checked ~ .checkmark {
		border: 1px solid #ffffff;
		background-color: #19aa19;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	& input:checked ~ .checkmark:after {
		display: block;
	}

	& .checkmark:after {
		left: 7px;
		top: 2px;
		width: 8px;
		height: 14px;
		border: solid #ffffff;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const Link = styled.a`
	line-height: 1;
	&:hover,
	&:active,
	&:focus,
	&:visited {
		color: #1cb5e0;
		text-decoration: none;
	}
`;

const TextRight = styled.div`
	height: 40px;
	top: 0;
	right: 0;
	background-color: #ffffff;
	border: 1px solid #e3e3e3;
	padding: 0px 12px;
	box-sizing: border-box;
	border-radius: 6px;
	line-height: 40px;
`;

const TextContent = styled.div`
	font-size: 20px;
	line-height: 1;
	margin-top: 10px;
`;

const TagsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
`;

const TagsEmail = styled.div`
	font-size: 19px;
	border-radius: 6px;
	border: 1px solid #e3e3e3;
	padding: 9px;
	line-height: 1;
	background-color: #f9f9f9;
	margin-right: 10px;
	display: inline-block;
	margin-bottom: 10px;

	& span.delete {
		font-size: 14px;
		color: #cacaca;
		cursor: pointer;
		margin-left: 5px;
	}
`;

const TextMessageError = styled.span`
	font-size: 20px;
	color: #ff0000;
`;

const selectOptionTypeBusiness = [
	{ value: '', label: '-- เลือก --' },
	{ value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
	{ value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
	{ value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
	{ value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
	{ value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
	{ value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
	{ value: 'การบริการ', label: 'การบริการ' },
	{ value: 'เครื่องจักร', label: 'เครื่องจักร' },
	{ value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
	{ value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
	{ value: 'บ้านและสวน', label: 'บ้านและสวน' },
	{ value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
	{ value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
	{ value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
	{ value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
	{ value: 'อื่นๆ', label: 'อื่นๆ' },
];

const selectOptionCountry = [
	{ value: '', label: '-- เลือก --' },
	{ value: 'ไทย', label: 'ไทย' },
];

const StatusContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
	padding: 20px;
	
	& .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		&-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 44px
			height: 44px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.1);

			& i {
				margin: auto;
				font-size: 20px;
			}

			&-danger {
				background-color: rgba(217, 1, 1, 0.1);

				& i {
					color: #D90101;
				}
			}

			&-success {
				background-color: rgba(25, 170, 25, 0.1);

				& i {
					color: #19AA19;
				}
			}
		}

		&-text {
			flex: 1;
			margin-left: 8px;
			line-height: 1;
			font-size: 20px;
			font-weight: bold;
		}
	}

	& .detail {
		font-size: 20px;
		line-height: 1;
		margin-top: 10px;

		&-list {
			display: flex;
			flex-direction: row;

			&-left {
				align-self: flex-start;
				flex: 1;
			}

			&-right {
				align-self: flex-end;
			}
		}
	}

	& .text-danger {
		color: #D90101;
		font-weight: bold;
	}
`;

const CreditContainer = styled.div`
	display: flex;
	flex-direction: column;

	border-radius: 6px;
	border: 1px solid #e3e3e3;
	background-color: #ffffff;
	padding: 20px;
	margin-bottom: 10px;
	line-height: 1;
	align-items: flex-end;

	& .text-header {
		margin-bottom: 10px;
	}

	& .text-bold {
		font-weight: bold;
	}

	& .text-medium {
		font-size: 24px;
	}

	& .text-large {
		font-size: 30px;
	}

	& .line {
		background-color: #e3e3e3;
		height: 1px;
		width: 100%;
		margin: 10px 0;
	}
`;

class Business extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			businessId: '',
			businessReqNumber: '',
			businessStatus: '',
			isEditData: false, //สำหรับแก้ไขข้อมูลเมื่อ businessStatus อนุมัติแล้ว
			create_date: '',
			approved_date: '',
			business_credit_approved: '',
			business_credit_used: '',

			//data step 1
			businessName: '',
			businessNameInvalid: false,
			taxId: '',
			taxIdInvalid: false,
			branch: '',
			branchInvalid: false,
			postcode: '',
			postcodeInvalid: false,
			businessAddress: '',
			businessAddressInvalid: false,
			country: { value: '', label: '-- เลือก --' },
			countryInvalid: false,
			province: { value: '', label: '-- เลือก --', pid: '' },
			provinceInvalid: false,
			district: { value: '', label: '-- เลือก --', pid: '' },
			districtInvalid: false,
			subdistrict: { value: '', label: '-- เลือก --' },
			subdistrictInvalid: false,
			businessType: { value: '', label: '-- เลือก --' },
			businessTypeInvalid: false,
			phoneCode: '66',
			phoneNumber: '',
			phoneNumberInvalid: '',
			phoneCodeCoordinator: '66',
			phoneNumberCoordinator: '',
			email: '',
			emailInvalid: false,
			emailTags: [],
			emailTagsInvalid: '',
			emailTagsDelete: [],
			invite_code: '',
			recommend_company: '',

			// shippingAddress
			shippingAddressChecked: false,
			shippingAddresspostcode: '',
			shippingAddresspostcodeInvalid: false,
			shippingAddress: '',
			shippingAddressInvalid: false,
			shippingAddresscountry: { value: '', label: '-- เลือก --' },
			shippingAddresscountryInvalid: false,
			shippingAddressprovince: { value: '', label: '-- เลือก --', pid: '' },
			shippingAddressprovinceInvalid: false,
			shippingAddressdistrict: { value: '', label: '-- เลือก --', pid: '' },
			shippingAddressdistrictInvalid: false,
			shippingAddresssubdistrict: { value: '', label: '-- เลือก --' },
			shippingAddresssubdistrictInvalid: false,

			roundDate: '',
			afterInvoice: '',
			businessPayRound: '',
			businessPayRoundInvalid: false,
			roundDateInvalid: false,
			afterInvoiceInvalid: false,

			//data step 2
			companyWillUse: '', //คาดว่าบริษัทของท่านจะใช้บริการขนส่ง
			companyWillUseInvalid: '',
			productType: [
				//ประเภทสินค้าที่ต้องการจัดส่ง
				{ id: 'สินค้าทั่วไป', value: 'สินค้าทั่วไป', checked: false },
				{ id: 'รักษาอุณหภูมิ', value: 'รักษาอุณหภูมิ', checked: false },
				{
					id: 'กลุ่มสินค้าอันตราย',
					value: 'กลุ่มสินค้าอันตราย',
					checked: false,
				},
				{ id: 'อื่นๆ', value: 'อื่นๆ', checked: false },
			],
			productTypeInvalid: '',
			productTypeOther: '',
			productTypeOtherInvalid: '',
			truckType: [
				//ประเภทรถที่ต้องการจัดส่ง
				{ id: '4 ล้อ', value: '4 ล้อ', checked: false },
				{ id: '6 ล้อ', value: '6 ล้อ', checked: false },
				{ id: '10 ล้อ', value: '10 ล้อ', checked: false },
				{
					id: '4 ล้อ (ควบคุมอุณหภูมิ)',
					value: '4 ล้อ (ควบคุมอุณหภูมิ)',
					checked: false,
				},
				{
					id: '6 ล้อ (ควบคุมอุณหภูมิ)',
					value: '6 ล้อ (ควบคุมอุณหภูมิ)',
					checked: false,
				},
				{
					id: '10 ล้อ (ควบคุมอุณหภูมิ)',
					value: '10 ล้อ (ควบคุมอุณหภูมิ)',
					checked: false,
				},
				{ id: 'เทรลเลอร์', value: 'เทรลเลอร์', checked: false },
			],
			truckTypeInvalid: '',

			//data step 3
			creditLimit: '', //วงเงินเครดิตที่ต้องการ
			creditLimitInvalid: '',
			receiveInvoiceAndReceipt: true, //ช่องทางการรับเอกสารใบแจ้งหนี้และใบเสร็จรับเงิน
			transportationFee: '', //คุณต้องการทำหัก ณ ที่จ่ายค่าขนส่งทุกครั้งที่ใช้บริการหรือไม่
			transportationFeeInvalid: '',
			businessInvoiceType: '', //คุณต้องการรับเอกสารใบแจ้งหนี้เมื่อใด
			businessInvoiceTypeInvalid: '',
			acceptTerms: false, //ในการลงทะเบียน จะถือว่าคุณยอมรับ...
			business_invoice_date_range_start: '',
			businessInvoiceDateRangeStartInvalid: '',
			business_invoice_date_range_end: '',
			businessInvoiceDateRangeEndInvalid: '',
			business_invoice_date_range_start_second: '',
			businessInvoiceDateRangeStartInvalid_second: '',
			business_invoice_date_range_end_second: '',
			businessInvoiceDateRangeEndInvalid_second: '',

			//แก้ไขข้อมูล กรณ๊สถานะอนุมัติ
			phoneCodeOld: '66',
			phoneNumberOld: '',
			phoneCodeOldCoordinator: '66',
			phoneNumberOldCoordinator: '',
			emailTagsOld: [],
			emailTagsOldDelete: [],
			business_invoice_date_range_start_old: '',
			business_invoice_date_range_end_old: '',
			business_invoice_date_range_start_old_second: '',
			business_invoice_date_range_end_old_second: '',
			businessInvoiceTypeOld: '',
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this._inputCredit = React.createRef();
	}

	componentWillReceiveProps(newProps) {
		console.log('componentWillReceiveProps');
		const { loading, error } = newProps.accountInfoListQuery;
		if (!loading && !error) {
			const {
				accountInfoList: { account_info },
				accountInfoList: { account_business },
			} = newProps.accountInfoListQuery;
			const accountInfo = account_info[0];
			const accountBusiness = account_business;

			if (accountBusiness === null) {
				window.location.href = '/register-business';
				return;
			}

			console.log(accountInfo);

			this.setState({
				businessId: accountBusiness._id,
				businessReqNumber: accountBusiness.business_req_number,
				businessStatus: accountBusiness.business_status,
				//step 1
				businessName: accountBusiness.account_info_business_name,
				taxId: accountInfo.account_info_tax_id,
				branch: accountInfo.account_info_branch,
				postcode: accountInfo.account_info_postcode,
				businessAddress: accountInfo.account_info_address,
				country: {
					value: accountInfo.account_info_country,
					label: accountInfo.account_info_country,
				},
				province: this.renderProvinceData(accountInfo.account_info_province),
				district: this.renderDistrictData(accountInfo.account_info_district),
				subdistrict: {
					value: accountInfo.account_info_subdistrict,
					label: accountInfo.account_info_subdistrict,
				},
				businessType: {
					value: accountInfo.account_info_corporate_type,
					label: accountInfo.account_info_corporate_type,
				},
				phoneCode: accountInfo.account_info_corporate_phone_code,
				phoneNumber: accountInfo.account_info_corporate_phone_number,
				phoneCodeCoordinator: accountInfo.account_info_corporate_co_phone_code,
				phoneNumberCoordinator:
					accountInfo.account_info_corporate_co_phone_number,
				account_user_type: accountInfo.account_users[0].account_user_type,
				emailTags: accountInfo.account_business_email.map(e => {
					return { id: e._id, text: e.email };
				}),
				invite_code: accountBusiness.invite_code,
				recommend_company: accountBusiness.recommend_company,

				shippingAddressChecked: accountInfo.shipping_from_main_address,
				shippingAddresspostcode: accountInfo.account_info_shipping_address[0]
					? accountInfo.account_info_shipping_address[0].postcode
					: '',
				shippingAddress: accountInfo.account_info_shipping_address[0]
					? accountInfo.account_info_shipping_address[0].address
					: '',
				shippingAddresscountry: {
					value: accountInfo.account_info_shipping_address[0]
						? accountInfo.account_info_shipping_address[0].country
						: '',
					label: accountInfo.account_info_shipping_address[0]
						? accountInfo.account_info_shipping_address[0].country
						: '',
				},
				shippingAddressprovince: this.renderProvinceData(
					accountInfo.account_info_shipping_address[0]
						? accountInfo.account_info_shipping_address[0].province
						: ''
				),
				shippingAddressdistrict: this.renderDistrictData(
					accountInfo.account_info_shipping_address[0]
						? accountInfo.account_info_shipping_address[0].district
						: ''
				),
				shippingAddresssubdistrict: {
					value: accountInfo.account_info_shipping_address[0]
						? accountInfo.account_info_shipping_address[0].subdistrict
						: '',
					label: accountInfo.account_info_shipping_address[0]
						? accountInfo.account_info_shipping_address[0].subdistrict
						: '',
				},

				roundDate: accountBusiness.business_pay_round.round_date,
				afterInvoice: accountBusiness.business_pay_round.after_invoice,
				businessPayRound:
					Number(accountBusiness.business_pay_round.round_date) > 0 ? 1 : 2,

				//step 2
				companyWillUse: accountBusiness.business_usage_information_1,
				productType: this.state.productType.map((e, index, arr) => {
					let data = accountBusiness.business_usage_information_2.filter(
						item => item === e.id
					);
					if (data.length) {
						e.checked = true;
					} else if (index === arr.length - 1) {
						//เมื่อไม่เจอให้นำค่าไว้ที่ช่องระบุ
						let other = accountBusiness.business_usage_information_2.filter(
							item =>
								item !== 'สินค้าทั่วไป' &&
								item !== 'รักษาอุณหภูมิ' &&
								item !== 'กลุ่มสินค้าอันตราย'
						);
						if (other.length) {
							e.checked = true;
							this.setState({ productTypeOther: other });
						}
					}

					return e;
				}),
				truckType: this.state.truckType.map((e, index, arr) => {
					let data = accountBusiness.business_usage_information_3.filter(
						item => item === e.id
					);
					if (data.length) {
						e.checked = true;
					}
					return e;
				}),
				creditLimit: this.formatNumber(
					accountBusiness.business_credit_req.toString(),
					false
				),
				receiveInvoiceAndReceipt:
					accountBusiness.business_edocument_receipt === true ? 1 : 2,
				transportationFee: accountBusiness.business_alway_wht.toString(),
				businessInvoiceType: accountBusiness.business_invoice_type.toString(),
				business_invoice_date_range_start:
					accountBusiness.business_invoice_type.toString() === '3' ? accountBusiness.business_invoice_date_range.start : '',
				business_invoice_date_range_end:
					accountBusiness.business_invoice_type.toString() === '3' ? accountBusiness.business_invoice_date_range.end : '',
				business_invoice_date_range_start_second:
					accountBusiness.business_invoice_type.toString() === '5' ? accountBusiness.business_invoice_date_range.start : '',
				business_invoice_date_range_end_second:
					accountBusiness.business_invoice_type.toString() === '5' ? accountBusiness.business_invoice_date_range.end : '',
				create_date: accountBusiness.create_date,
				approved_date: accountBusiness.approved_date,
				business_credit_approved: accountBusiness.business_credit_approved,
				business_credit_used: accountBusiness.business_credit_used,
				acceptTerms: true,
			});
		}
	}

	renderProvinceData(data) {
		let dataFilter = provinceData.th.changwats.filter(e => e.name === data);
		return { value: data, label: data, pid: dataFilter[0] ? dataFilter[0].pid : '' };
	}

	renderDistrictData(data) {
		let dataFilter = districtData.th.amphoes.filter(e => e.name === data);
		return { value: data, label: data, pid: dataFilter[0] ? dataFilter[0].pid : '' };
	}

	selectOptionProvinceData(mode) {
		let options = [{ value: '', label: '-- เลือก --', pid: '' }];

		provinceData.th.changwats.map((item, index) => {
			const province = { value: item.name, label: item.name, pid: item.pid };
			options.push(province);
			return province;
		});

		return options;
	}

	selectOptionDistrictData(mode) {
		let options = [{ value: '', label: '-- เลือก --', pid: '' }];

		let dataFilter = districtData.th.amphoes.filter(
			e => e.changwat_pid === this.state.province.pid
		);
		if(mode === "shippingAddress"){
			dataFilter = districtData.th.amphoes.filter(
				e => e.changwat_pid === this.state.shippingAddressprovince.pid
			);
		}
		dataFilter.map((item, index) => {
			const district = { value: item.name, label: item.name, pid: item.pid };
			options.push(district);
			return district;
		});

		return options;
	}

	selectOptionSubDistrictData(mode) {
		let options = [{ value: '', label: '-- เลือก --' }];

		let dataFilter = subDistrictData.th.tambons.filter(
			e => e.amphoe_pid === this.state.district.pid
		);
		if(mode === "shippingAddress"){
			dataFilter = subDistrictData.th.tambons.filter(
				e => e.amphoe_pid === this.state.shippingAddressdistrict.pid
			);
		}
		dataFilter.map((item, index) => {
			const subdistrict = { value: item.name, label: item.name };
			options.push(subdistrict);
			return subdistrict;
		});

		return options;
	}

	changeCountry(event,mode) {
		if(mode === "shippingAddress"){
			this.setState(
				{ shippingAddresscountry: { value: event.value, label: event.label } },
				() => {
					this.setState({
						shippingAddressprovince: { value: '', label: '-- เลือก --', pid: '' },
						shippingAddressdistrict: { value: '', label: '-- เลือก --' },
						shippingAddresscountryInvalid: false,
					});
				}
			);
		}
		else{
			this.setState(
				{ country: { value: event.value, label: event.label } },
				() => {
					this.setState({
						province: { value: '', label: '-- เลือก --', pid: '' },
						district: { value: '', label: '-- เลือก --' },
						countryInvalid: false,
					});
				}
			);
		}
		
	}

	changeProvince(event,mode) {
		if(mode === "shippingAddress"){
			this.setState(
				{ shippingAddressprovince: { value: event.value, label: event.label, pid: event.pid } },
				() => {
					this.setState({
						shippingAddressdistrict: { value: '', label: '-- เลือก --' },
						shippingAddressprovinceInvalid: false,
					});
					this.selectOptionDistrictData("shippingAddress");
				}
			);
		}
		else{
			this.setState(
				{ province: { value: event.value, label: event.label, pid: event.pid } },
				() => {
					this.setState({
						district: { value: '', label: '-- เลือก --' },
						provinceInvalid: false,
					});
					this.selectOptionDistrictData();
				}
			);
		}
	}

	changeDistrict(event,mode) {
		if(mode === "shippingAddress"){
			this.setState(
				{ shippingAddressdistrict: { value: event.value, label: event.label, pid: event.pid } },
				() => {
					this.setState({
						shippingAddresssubdistrict: { value: '', label: '-- เลือก --' },
						shippingAddressdistrictInvalid: false,
					});
					this.selectOptionSubDistrictData("shippingAddress");
				}
			);
		}
		else{
			this.setState(
				{ district: { value: event.value, label: event.label, pid: event.pid } },
				() => {
					this.setState({
						subdistrict: { value: '', label: '-- เลือก --' },
						districtInvalid: false,
					});
					this.selectOptionSubDistrictData();
				}
			);
		}
	}

	changeSubDistrict(event,mode) {
		if(mode === "shippingAddress"){
			this.setState({
				shippingAddresssubdistrict: { value: event.value, label: event.label },
				shippingAddresssubDistrictInvalid: false,
			});
		}
		else{
			this.setState({
				subdistrict: { value: event.value, label: event.label },
				subDistrictInvalid: false,
			});
		}
	}

	changeBusinessType(event) {
		this.setState({
			businessType: { value: event.value, label: event.label },
			businessTypeInvalid: false,
		});
	}

	handleSelectProductType(event) {
		let productType = this.state.productType;

		let data = productType.map(e => {
			if (e.value === event.target.value) {
				e.checked = event.target.checked;
			}

			return e;
		});

		this.setState({ productType: data });
	}

	handleSelectTruckType(event) {
		let truckType = this.state.truckType;

		let data = truckType.map(e => {
			if (e.value === event.target.value) {
				e.checked = event.target.checked;
			}

			return e;
		});

		this.setState({ truckType: data });
	}

	handleNextStepOne() {
		this.setState({
			businessNameInvalid: this.state.businessName ? false : true,
		});
		this.setState({ taxIdInvalid: this.state.taxId ? false : true });
		this.setState({ branchInvalid: this.state.branch ? false : true });
		this.setState({ postcodeInvalid: this.state.postcode ? false : true });
		this.setState({
			businessAddressInvalid: this.state.businessAddress ? false : true,
		});
		this.setState({ countryInvalid: this.state.country.value ? false : true });
		this.setState({
			provinceInvalid: this.state.province.value ? false : true,
		});
		this.setState({
			districtInvalid: this.state.district.value ? false : true,
		});
		this.setState({
			subDistrictInvalid: this.state.subdistrict.value ? false : true,
		});
		this.setState({
			businessTypeInvalid: this.state.businessType.value ? false : true,
		});
		this.setState({
			phoneNumberInvalid: this.state.phoneNumber ? '' : 'กรุณากรอกข้อมูล',
		});
		this.setState({
			emailTagsInvalid: this.state.email
				? this.checkEmail(this.state.email)
					? this.state.emailTags.filter(e => e.text === this.state.email).length
						? 'อีเมลนี้ถูกเพิ่มแล้ว'
						: ''
					: 'รูปแบบอีเมลไม่ถูกต้อง'
				: this.state.emailTags.length
				? ''
				: 'กรุณากรอกข้อมูล',
		});

		this.setState({
			shippingAddresspostcodeInvalid: this.state.shippingAddresspostcode
				? false
				: true,
		});
		this.setState({
			shippingAddressInvalid: this.state.shippingAddress ? false : true,
		});
		this.setState({
			shippingAddresscountryInvalid: this.state.shippingAddresscountry.value
				? false
				: true,
		});
		this.setState({
			shippingAddressprovinceInvalid: this.state.shippingAddressprovince.value
				? false
				: true,
		});
		this.setState({
			shippingAddressdistrictInvalid: this.state.shippingAddressdistrict.value
				? false
				: true,
		});
		this.setState({
			shippingAddresssubDistrictInvalid: this.state.shippingAddresssubdistrict
				.value
				? false
				: true,
		});
		this.setState({
			businessPayRoundInvalid: this.state.businessPayRound ? false : true,
		});

		if (
			this.state.businessName &&
			this.state.taxId &&
			this.state.branch &&
			this.state.postcode &&
			this.state.businessAddress &&
			this.state.country.value &&
			this.state.province.value &&
			this.state.district.value &&
			this.state.subdistrict.value &&
			this.state.businessType.value &&
			this.state.phoneNumber &&
			this.state.emailTags.length &&
			!this.state.email &&
			this.state.shippingAddresspostcode &&
			this.state.shippingAddress &&
			this.state.shippingAddresscountry.value &&
			this.state.shippingAddressprovince.value &&
			this.state.shippingAddressdistrict.value &&
			this.state.shippingAddresssubdistrict.value &&
			this.state.businessPayRound
		) {
			this.setState({ step: 2 });
		}
	}

	handleNextStepTwo() {
		this.setState({
			companyWillUseInvalid: this.state.companyWillUse
				? ''
				: 'กรุณาเลือกรายการ',
		});
		this.setState({
			productTypeInvalid: this.state.productType.filter(e => e.checked === true)
				.length
				? ''
				: 'กรุณาเลือกรายการ',
		});
		this.setState({
			truckTypeInvalid: this.state.truckType.filter(e => e.checked === true)
				.length
				? ''
				: 'กรุณาเลือกรายการ',
		});
		this.setState({
			productTypeOtherInvalid: this.state.productType.filter(
				e => e.value === 'อื่นๆ' && e.checked === true
			).length
				? this.state.productTypeOther
					? ''
					: 'กรุณากรอกข้อมูล'
				: '',
		});

		let checkProductTypeOther = true;
		if (
			this.state.productType.filter(
				e => e.value === 'อื่นๆ' && e.checked === true
			).length
		) {
			if (this.state.productTypeOther === '') {
				checkProductTypeOther = false;
			}
		}

		if (
			this.state.companyWillUse &&
			this.state.productType.filter(e => e.checked === true).length &&
			this.state.truckType.filter(e => e.checked === true).length &&
			checkProductTypeOther
		) {
			this.setState({ step: 3 });
		}
	}

	handleSendForm(accountBusinessUpdate) {
		this.setState({
			creditLimitInvalid: this.state.creditLimit ? '' : 'กรุณากรอกข้อมูล',
		});
		this.setState({
			transportationFeeInvalid: this.state.transportationFee
				? ''
				: 'กรุณาเลือกรายการ',
		});
		this.setState({
			businessInvoiceTypeInvalid: this.state.businessInvoiceType
				? ''
				: 'กรุณาเลือกรายการ',
		});

		if (Number(this.state.businessInvoiceType) === 3) {
			this.setState({
				businessInvoiceDateRangeStartInvalid: this.state
					.business_invoice_date_range_start
					? ''
					: 'กรุณากรอกข้อมูล',
			});
			this.setState({
				businessInvoiceDateRangeEndInvalid: this.state
					.business_invoice_date_range_end
					? ''
					: 'กรุณากรอกข้อมูล',
			});
		}

		if (Number(this.state.businessInvoiceType) === 5) {
			this.setState({
				businessInvoiceDateRangeStartInvalid_second: this.state
					.business_invoice_date_range_start_second
					? ''
					: 'กรุณากรอกข้อมูล',
			});
			this.setState({
				businessInvoiceDateRangeEndInvalid_second: this.state
					.business_invoice_date_range_end_second
					? ''
					: 'กรุณากรอกข้อมูล',
			});
		}

    const roundDateInvalid =
      this.state.businessPayRound == 1 ? (Number(this.state.roundDate) > 0 ? false : true) : false;
    const afterInvoiceInvalid =
      this.state.businessPayRound == 2
        ? Number(this.state.afterInvoice) > 0
          ? false
          : true
        : false;
    this.setState({
      shippingAddresspostcodeInvalid: this.state.shippingAddresspostcode ? false : true,
    });
    this.setState({ shippingAddressInvalid: this.state.shippingAddress ? false : true });
    this.setState({
      shippingAddresscountryInvalid: this.state.shippingAddresscountry.value ? false : true,
    });
    this.setState({
      shippingAddressprovinceInvalid: this.state.shippingAddressprovince.value ? false : true,
    });
    this.setState({
      shippingAddressdistrictInvalid: this.state.shippingAddressdistrict.value ? false : true,
    });
    this.setState({
      shippingAddresssubDistrictInvalid: this.state.shippingAddresssubdistrict.value ? false : true,
    });
    this.setState({ businessPayRoundInvalid: this.state.businessPayRound ? false : true });

    this.setState({ roundDateInvalid });
    this.setState({ afterInvoiceInvalid });

		console.log( this.state.shippingAddresscountry)

		if (
			this.state.creditLimit &&
			this.state.transportationFee &&
			this.state.businessInvoiceType &&
			this.state.shippingAddresspostcode &&
			this.state.shippingAddresspostcode &&
			this.state.shippingAddress &&
			this.state.shippingAddresscountry.value &&
			this.state.shippingAddressprovince.value &&
			this.state.shippingAddressdistrict.value &&
			this.state.shippingAddresssubdistrict.value &&
			this.state.businessPayRound &&
			!roundDateInvalid &&
			!afterInvoiceInvalid &&
			Number(this.state.businessInvoiceType) === 3
				? this.state.business_invoice_date_range_start &&
				  this.state.business_invoice_date_range_end
					? true
					: false
				: true	&&
			Number(this.state.businessInvoiceType) === 5
				? this.state.business_invoice_date_range_start_second &&
				  this.state.business_invoice_date_range_end_second
					? true
					: false
				: true
		) {
			accountBusinessUpdate({
				variables: {
					// _id: '5c80f4ee724f3211aad727c8',
					account_business_id: this.state.businessId,
					account_info_business_name: this.state.businessName,
					account_info_tax_id: this.state.taxId,
					account_info_branch: this.state.branch,
					account_info_postcode: this.state.postcode,
					account_info_address: this.state.businessAddress,
					account_info_country: this.state.country.value,
					account_info_province: this.state.province.value,
					account_info_district: this.state.district.value,
					account_info_subdistrict: this.state.subdistrict.value,
					account_info_corporate_type: this.state.businessType.value,
					account_info_corporate_phone_code: this.state.phoneCode,
					account_info_corporate_phone_number: this.state.phoneNumber,
					account_info_corporate_co_phone_code: this.state.phoneCodeCoordinator,
					account_info_corporate_co_phone_number: this.state
						.phoneNumberCoordinator,
					account_business_email: this.state.emailTags.map(e => {
						if (e.id) {
							//ถ้ามีค่า id ให้ส่ง id ไปด้วยเพราะจะเป็นการแก้ไข
							return { _id: e.id, email: e.text };
						}
						//ถ้าไม่มี id ให้เพิ่ม email เข้าไปใหม่
						return { email: e.text };
					}),
					account_business_email_delete: this.state.emailTagsDelete.map(e => {
						return { _id: e.id };
					}),
					business_usage_information_1: this.state.companyWillUse,
					business_usage_information_2: this.state.productType
						.filter(e => e.checked === true)
						.map(e => {
							if (e.value === 'อื่นๆ') {
								return this.state.productTypeOther.toString();
							}

							return e.value;
						}),
					business_usage_information_3: this.state.truckType
						.filter(e => e.checked === true)
						.map(e => e.value),
					business_credit_req: this.formatNumber(this.state.creditLimit, true),
					business_edocument_receipt: this.state.receiveInvoiceAndReceipt
						? 1
						: 2,
					business_alway_wht: this.state.transportationFee,
					business_invoice_type: this.state.businessInvoiceType,
					business_invoice_date_range:
						Number(this.state.businessInvoiceType) === 3
							? {
									start: this.state.business_invoice_date_range_start,
									end: this.state.business_invoice_date_range_end,
							  }
							: 
						Number(this.state.businessInvoiceType) === 5
							? {
									start: this.state.business_invoice_date_range_start_second,
									end: this.state.business_invoice_date_range_end_second,
							  }
							: undefined,
					business_status: 1,
					invite_code: this.state.invite_code,
					recommend_company: this.state.recommend_company,
					shipping_from_main_address: this.state.shippingAddressChecked,
					account_info_shipping_address: [
						{
							address: this.state.shippingAddress,
							country: this.state.shippingAddresscountry.value,
							province: this.state.shippingAddressprovince.value,
							district: this.state.shippingAddressdistrict.value,
							subdistrict: this.state.shippingAddresssubdistrict.value,
							postcode: this.state.shippingAddresspostcode,
						},
					],
					business_pay_round: {
						round_date:
							this.state.businessPayRound == 1 ? this.state.roundDate : 0,
						after_invoice:
							this.state.businessPayRound == 2 ? this.state.afterInvoice : 0,
					},
				},
			});
		}
	}

	handleDelete(i) {
		const { emailTags } = this.state;
		this.setState(
			{
				emailTagsInvalid: '',
				emailTags: emailTags.filter((e, index) => index !== i),
			},
			() => {
				if (emailTags.length) {
					this.setState({
						emailTagsDelete: [
							...this.state.emailTagsDelete,
							{ id: emailTags[i].id, text: emailTags[i].text },
						],
					});
				}
			}
		);
	}

	handleAddition(event) {
		if (this.checkEmail(event.target.value)) {
			//ป้อนกันอีเมลซ้ำ
			const checkEmail = this.state.emailTags.filter(
				e => e.text === event.target.value
			);
			if (checkEmail.length === 0) {
				this.setState({
					email: '',
					emailTags: [
						...this.state.emailTags,
						{ id: '', text: event.target.value },
					],
					emailTagsInvalid: '',
				});
			} else {
				this.setState({ emailTagsInvalid: 'อีเมลนี้ถูกเพิ่มแล้ว' });
			}
		} else {
			this.setState({ emailTagsInvalid: 'รูปแบบอีเมลไม่ถูกต้อง' });
		}
	}

	checkEmail = email => {
		const validateEmail = /^(([a-zA-Z0-9]+([\_\.\-][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
		if (validateEmail.test(email)) {
			return true;
		}

		return false;
	};

	checkPhoneNumber = phoneNumber => {
		const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
		if (validatePhoneNumber.test(phoneNumber)) {
			return true;
		}

		return false;
	};

	checkEditForm(businessStatus) {
		return businessStatus !== 2 ? true : false;
	}

	handleSelectShippingAddress(event) {
		const {
			postcode,
			postcodeInvalid,
			businessAddress,
			businessAddressInvalid,
			country,
			countryInvalid,
			province,
			provinceInvalid,
			district,
			districtInvalid,
			subdistrict,
			subdistrictInvalid,
		} = this.state;
		if (event.target.checked) {
			this.setState({
				shippingAddressChecked: event.target.checked,
				shippingAddresspostcode: postcode,
				shippingAddresspostcodeInvalid: postcodeInvalid,
				shippingAddress: businessAddress,
				shippingAddressInvalid: businessAddressInvalid,
				shippingAddresscountry: country,
				shippingAddresscountryInvalid: countryInvalid,
				shippingAddressprovince: province,
				shippingAddressprovinceInvalid: provinceInvalid,
				shippingAddressdistrict: district,
				shippingAddressdistrictInvalid: districtInvalid,
				shippingAddresssubdistrict: subdistrict,
				shippingAddresssubdistrictInvalid: subdistrictInvalid,
			});
		} else {
			this.setState({
				shippingAddressChecked: event.target.checked,
			});
		}
	}

	//แก้ไขข้อมูล เมื่อสถานะอนุมัติ
	handleEditData(accountBusinessUpdate) {
		this.setState({
			phoneNumberInvalid: this.state.phoneNumber ? '' : 'กรุณากรอกข้อมูล',
		});
		this.setState({
			emailTagsInvalid: this.state.email
				? this.checkEmail(this.state.email)
					? this.state.emailTags.filter(e => e.text === this.state.email).length
						? 'อีเมลนี้ถูกเพิ่มแล้ว'
						: ''
					: 'รูปแบบอีเมลไม่ถูกต้อง'
				: this.state.emailTags.length
				? ''
				: 'กรุณากรอกข้อมูล',
		});

		if (Number(this.state.businessInvoiceType) === 3) {
			this.setState({
				businessInvoiceDateRangeStartInvalid: this.state
					.business_invoice_date_range_start
					? ''
					: 'กรุณากรอกข้อมูล',
			});
			this.setState({
				businessInvoiceDateRangeEndInvalid: this.state
					.business_invoice_date_range_end
					? ''
					: 'กรุณากรอกข้อมูล',
			});
		}

		if (Number(this.state.businessInvoiceType) === 5) {
			this.setState({
				businessInvoiceDateRangeStartInvalid_second: this.state
					.business_invoice_date_range_start_second
					? ''
					: 'กรุณากรอกข้อมูล',
			});
			this.setState({
				businessInvoiceDateRangeEndInvalid_second: this.state
					.business_invoice_date_range_end_second
					? ''
					: 'กรุณากรอกข้อมูล',
			});
		}

		const roundDateInvalid =
		this.state.businessPayRound == 1 ? (Number(this.state.roundDate) > 0 ? false : true) : false;
		const afterInvoiceInvalid =
		this.state.businessPayRound == 2
			? Number(this.state.afterInvoice) > 0
			? false
			: true
			: false;
		this.setState({
		shippingAddresspostcodeInvalid: this.state.shippingAddresspostcode ? false : true,
		});
		this.setState({ shippingAddressInvalid: this.state.shippingAddress ? false : true });
		this.setState({
		shippingAddresscountryInvalid: this.state.shippingAddresscountry.value ? false : true,
		});
		this.setState({
		shippingAddressprovinceInvalid: this.state.shippingAddressprovince.value ? false : true,
		});
		this.setState({
		shippingAddressdistrictInvalid: this.state.shippingAddressdistrict.value ? false : true,
		});
		this.setState({
		shippingAddresssubDistrictInvalid: this.state.shippingAddresssubdistrict.value ? false : true,
		});
		this.setState({ businessPayRoundInvalid: this.state.businessPayRound ? false : true });

		this.setState({ roundDateInvalid });
		this.setState({ afterInvoiceInvalid });

		if (
			this.state.phoneNumber &&
			this.state.emailTags.length &&
			!this.state.email  &&
			this.state.shippingAddresspostcode &&
			this.state.shippingAddresspostcode &&
			this.state.shippingAddress &&
			this.state.shippingAddresscountry.value &&
			this.state.shippingAddressprovince.value &&
			this.state.shippingAddressdistrict.value &&
			this.state.shippingAddresssubdistrict.value &&
			this.state.businessPayRound &&
			!roundDateInvalid &&
			!afterInvoiceInvalid  &&
			Number(this.state.businessInvoiceType) === 3
				? this.state.business_invoice_date_range_start &&
				  this.state.business_invoice_date_range_end
					? true
					: false
				: true	&&
			Number(this.state.businessInvoiceType) === 5
				? this.state.business_invoice_date_range_start_second &&
				  this.state.business_invoice_date_range_end_second
					? true
					: false
				: true
		) {
			accountBusinessUpdate({
				variables: {
					account_business_id: this.state.businessId,
					account_info_corporate_phone_code: this.state.phoneCode,
					account_info_corporate_phone_number: this.state.phoneNumber,
					account_info_corporate_co_phone_code: this.state.phoneCodeCoordinator,
					account_info_corporate_co_phone_number: this.state
						.phoneNumberCoordinator,
					business_invoice_type: this.state.businessInvoiceType,
					account_business_email: this.state.emailTags.map(e => {
						if (e.id) {
							//ถ้ามีค่า id ให้ส่ง id ไปด้วยเพราะจะเป็นการแก้ไข
							return { _id: e.id, email: e.text };
						}
						//ถ้าไม่มี id ให้เพิ่ม email เข้าไปใหม่
						return { email: e.text };
					}),
					account_business_email_delete: this.state.emailTagsDelete.map(e => {
						return { _id: e.id };
					}),
					business_invoice_date_range:
						Number(this.state.businessInvoiceType) === 3
							? {
									start: this.state.business_invoice_date_range_start,
									end: this.state.business_invoice_date_range_end,
							  }
							: 
						Number(this.state.businessInvoiceType) === 5
							? {
									start: this.state.business_invoice_date_range_start_second,
									end: this.state.business_invoice_date_range_end_second,
							  }
							: undefined,
					shipping_from_main_address: this.state.shippingAddressChecked,
					account_info_shipping_address: [
						{
							address: this.state.shippingAddress,
							country: this.state.shippingAddresscountry.value,
							province: this.state.shippingAddressprovince.value,
							district: this.state.shippingAddressdistrict.value,
							subdistrict: this.state.shippingAddresssubdistrict.value,
							postcode: this.state.shippingAddresspostcode,
						},
					],
					business_pay_round: {
						round_date:
							this.state.businessPayRound == 1 ? this.state.roundDate : 0,
						after_invoice:
							this.state.businessPayRound == 2 ? this.state.afterInvoice : 0,
					},
				},
			});
		}
	}

	//credit input format
	formatNumber(number, revers) {
		//ป้องกันข้อมูลว่างแล้วค่าเป็น 0
		if (number === '') {
			return '';
		}

		if (revers === true) {
			// format number 1,234,567 to 1000000
			return Number(number.replace(/\D/g, ''));
		}

		// format number 1000000 to 1,234,567
		return Number(number.replace(/\D/g, ''))
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	formatCurrency(event, inputRef) {
		// get input value
		var input_val = event.target.value;

		// original length
		var original_len = input_val.length;

		// initial caret position
		var caret_pos = event.target.selectionStart;

		input_val = this.formatNumber(input_val);

		// send updated string to input
		this.setState({ creditLimit: input_val }, () => {
			// put caret back in the right position
			let updated_len = input_val.length;
			caret_pos = updated_len - original_len + caret_pos;
			inputRef.current.setSelectionRange(caret_pos, caret_pos);
		});
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>
						จิซทิกซ์สำหรับองค์กร/ธุรกิจ | GIZTIX บริการขนส่ง บริษัทขนส่ง
						รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า
						ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full={true} />
				<SubHeader backLink="/" />

				<Container>
					<Row>
						<Col lg={2} md={3} sm={12}>
							<Menu />
						</Col>
						<Col lg={7} md={5} sm={12}>
							{this.state.businessStatus === 2 &&
							!this.props.accountInfoListQuery.loading ? (
								<Card>
									<div>
										<TextHeader>
											{this.props.t('common:setting.business.title')}
										</TextHeader>
										<TextSubHeader>
											{this.props.t('common:setting.business.subTitle')}
										</TextSubHeader>
										<div style={{ marginTop: 20 }}>
											<Row>
												<Col md={6} sm={12}>
													<Label style={{ marginTop: 20 }}>
														{this.props.t(
															'common:setting.business.titleCompanyName'
														)}
														:
													</Label>
													<div>{this.state.businessName}</div>
												</Col>
												<Col md={6} sm={12}>
													<Label style={{ marginTop: 20 }}>
														{this.props.t('common:setting.business.titleTaxID')}
														:
													</Label>
													<div>{this.state.taxId}</div>
												</Col>
												<Col md={12} sm={12}>
													<Label style={{ marginTop: 20 }}>
														{this.props.t(
															'common:setting.business.titleCompanyAddress'
														)}
														:
													</Label>
													<div>
														{`${this.state.businessAddress} 
														เขต/อำเภอ ${this.state.district.label}
														แขวง/ตำบล ${this.state.subdistrict.label}
														จังหวัด ${this.state.province.label}
														ประเทศ ${this.state.country.label} ${this.state.postcode}`}
													</div>
												</Col>
												<Col md={6} sm={12}>
													<Label style={{ marginTop: 20 }}>
														{this.props.t(
															'common:setting.business.titleBusinessType'
														)}
														:
													</Label>
													<div>{this.state.businessType.value}</div>
												</Col>
											</Row>
										</div>
									</div>

									<LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />

									<TextHeader>
										{this.props.t(
											'common:setting.business.titleBillingAddress'
										)}
									</TextHeader>
									<TextSubHeader>
										{this.props.t(
											'common:setting.business.subTitleBillingAddress'
										)}
									</TextSubHeader>
									<div style={{ marginTop: 20 }}>
										<Row>
											<Col md={6} sm={12}>
												<Label style={{ marginTop: 20 }}>
													{this.props.t(
														'common:setting.business.titleCompanyName'
													)}
													:
												</Label>
												<div>{this.state.businessName}</div>
											</Col>
											<Col md={6} sm={12}>
												<Label style={{ marginTop: 20 }}>
													{this.props.t('common:setting.business.titleTaxID')}:
												</Label>
												<div>{this.state.taxId}</div>
											</Col>
											<Col md={6} sm={12}>
												<Label style={{ marginTop: 20 }}>
													{this.props.t(
														'common:setting.business.titleCompanyAddress'
													)}
													:
												</Label>
												<div>
													{`${this.state.businessAddress} 
														เขต/อำเภอ ${this.state.district.label}
														แขวง/ตำบล ${this.state.subdistrict.label}
														จังหวัด ${this.state.province.label}
														ประเทศ ${this.state.country.label}
														${this.state.postcode}`}
												</div>
											</Col>
											<Col md={6} sm={12}>
												<Label style={{ marginTop: 20 }}>
													{this.props.t('common:setting.business.titleBranch')}:
												</Label>
												<div>{this.state.branch}</div>
											</Col>
										</Row>
									</div>

									<div style={{ marginTop: 10 }}>
										<Row>
											<Col md={6} sm={12}>
												<Label
													style={{
														marginTop: 10,
														color: `${
															this.state.phoneNumberInvalid ? '#FF0000' : ''
														}`,
													}}
													required
												>
													{this.props.t(
														'common:setting.business.input.mobileNumber.label'
													)}
													:
												</Label>
												<InputGroup>
													<Select
														disabled={!this.state.isEditData}
														style={{
															borderTopRightRadius: 0,
															borderBottomRightRadius: 0,
															borderRightWidth: 0,
															border: `${
																this.state.phoneNumberInvalid
																	? '1px solid #FF0000'
																	: ''
															}`,
														}}
														value={this.state.phoneCode}
														onChange={event =>
															this.setState({ phoneCode: event.target.value })
														}
													>
														<option value="66">+66</option>
													</Select>
													<Input
														disabled={!this.state.isEditData}
														onChange={event =>
															this.setState({
																phoneNumber: event.target.value,
																phoneNumberInvalid: '',
															})
														}
														value={this.state.phoneNumber}
														placeholder={this.props.t(
															'common:setting.business.input.mobileNumber.placeholder'
														)}
														style={{ width: '100%' }}
														styleInput={{
															borderTopLeftRadius: 0,
															borderBottomLeftRadius: 0,
															borderLeftWidth: 0,
														}}
														messageError={
															this.state.phoneNumberInvalid
																? this.state.phoneNumberInvalid
																: ''
														}
													/>
												</InputGroup>
											</Col>
											<Col md={6} sm={12}>
												<Label style={{ marginTop: 10 }}>
													{this.props.t(
														'common:setting.business.input.coordinatorMobilePhone.label'
													)}
													:
												</Label>
												<InputGroup>
													<Select
														disabled={!this.state.isEditData}
														style={{
															borderTopRightRadius: 0,
															borderBottomRightRadius: 0,
															borderRightWidth: 0,
														}}
														value={this.state.phoneCodeCoordinator}
														onChange={event =>
															this.setState({
																phoneCodeCoordinator: event.target.value,
															})
														}
													>
														<option value="66">+66</option>
													</Select>
													<Input
														disabled={!this.state.isEditData}
														onChange={event =>
															this.setState({
																phoneNumberCoordinator: event.target.value,
															})
														}
														value={this.state.phoneNumberCoordinator}
														placeholder={this.props.t(
															'common:setting.business.input.coordinatorMobilePhone.placeholder'
														)}
														style={{ width: '100%' }}
														styleInput={{
															borderTopLeftRadius: 0,
															borderBottomLeftRadius: 0,
														}}
													/>
												</InputGroup>
											</Col>

											<Col sm={12}>
												<Label
													inline
													style={{
														marginTop: 10,
														color: `${
															this.state.emailTagsInvalid ? '#FF0000' : ''
														}`,
													}}
													required
												>
													{this.props.t(
														'common:setting.business.input.email.label'
													)}
												</Label>
											</Col>

											{this.state.isEditData === true ? (
												<Col sm={12}>
													<TagsContainer>
														{this.state.emailTags.length
															? this.state.emailTags.map((e, index) => (
																	<TagsEmail key={index}>
																		{e.text}
																		<span
																			className="delete"
																			onClick={() => this.handleDelete(index)}
																		>
																			{' '}
																			<i className="far fa-times"></i>
																		</span>
																	</TagsEmail>
															  ))
															: ''}
														<Input
															onChange={event => {
																this.setState({
																	email: event.target.value,
																	emailTagsInvalid: '',
																});
															}}
															onKeyPress={event => {
																if (
																	event.charCode === KeyCodes.enter ||
																	event.charCode === KeyCodes.comma
																) {
																	this.handleAddition(event);
																}
															}}
															onBlur={event => {
																if (event.target.value) {
																	this.handleAddition(event);
																}
															}}
															value={this.state.email}
															style={{ display: 'inline-block' }}
															styleInput={{ width: 250 }}
															placeholder="user@email.com"
															messageError={
																this.state.emailTagsInvalid === true
																	? this.state.emailTagsInvalid
																	: false
															}
														/>
													</TagsContainer>
												</Col>
											) : (
												<Col sm={12}>
													<TagsContainer>
														{this.state.emailTags.length
															? this.state.emailTags.map((e, index) => (
																	<TagsEmail key={index}>{e.text}</TagsEmail>
															  ))
															: null}
													</TagsContainer>
												</Col>
											)}

										</Row>

										<Row>
											<Col md={12} sm={12}>
												<Label inline style={{ marginTop: 10 }}>
													การรับเอกสารใบแจ้งหนี้
												</Label>
												{this.state.isEditData === false && (
													<p>
													{
														Number(this.state.businessInvoiceType) === 1
																? '1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน'
																: Number(this.state.businessInvoiceType) === 2
																? '1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน'
																: Number(this.state.businessInvoiceType) === 3 
																? `1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่ ${this.state.business_invoice_date_range_start} - ${this.state.business_invoice_date_range_end} ของทุกเดือน`
																: Number(this.state.businessInvoiceType) === 4
																? '1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน' 
																: `1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลวันที่ ${this.state.business_invoice_date_range_start_second} - ${this.state.business_invoice_date_range_end_second} ของทุกเดือน` 
													}
													</p>
												)}

												{this.state.isEditData === true && (
													<Row>
														<Col md={12} sm={12}>
															<CustomRadio
																name="radioInvoiceType"
																value="1"
																checked={this.state.businessInvoiceType === '1'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน
															</CustomRadio>
														</Col>
														<Col md={12} sm={12}>
															<CustomRadio
																name="radioInvoiceType"
																value="2"
																checked={this.state.businessInvoiceType === '2'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน
															</CustomRadio>
														</Col>
														<Col md={12}>
															<CustomRadio
																name="radioInvoiceType"
																value="3"
																checked={this.state.businessInvoiceType === '3'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด (โปรดระบุวัน)
																<div>
																	<FormControl
																		componentClass="select"
																		placeholder="select"
																		style={{
																			display: 'inline-block',
																			width: 80,
																			fontSize: 18,
																			marginRight: 5,
																			border: this.state
																				.businessInvoiceDateRangeStartInvalid
																				? '1px solid #FF0000'
																				: '',
																		}}
																		value={
																			this.state
																				.business_invoice_date_range_start
																		}
																		onChange={event => {
																			this.setState({
																				business_invoice_date_range_start:
																					event.target.value,
																				businessInvoiceDateRangeStartInvalid:
																					'',
																			});
																			if (
																				Number(event.target.value) >
																				Number(
																					this.state
																						.business_invoice_date_range_end
																				)
																			) {
																				this.setState({
																					business_invoice_date_range_end: '',
																				});
																			}
																		}}
																	>
																		<option value="">วันที่</option>
																		{Array.from(Array(31), (e, i) => (
																			<option key={i} value={i + 1}>
																				{i + 1}
																			</option>
																		))}
																	</FormControl>
																	{` ถึง `}
																	<FormControl
																		componentClass="select"
																		placeholder="select"
																		style={{
																			display: 'inline-block',
																			width: 80,
																			fontSize: 18,
																			marginLeft: 5,
																			marginRight: 5,
																			border: this.state
																				.businessInvoiceDateRangeEndInvalid
																				? '1px solid #FF0000'
																				: '',
																		}}
																		value={
																			this.state.business_invoice_date_range_end
																		}
																		onChange={event => {
																			this.setState({
																				business_invoice_date_range_end:
																					event.target.value,
																				businessInvoiceDateRangeEndInvalid: '',
																			});

																			if (
																				Number(event.target.value) <
																				Number(
																					this.state
																						.business_invoice_date_range_start
																				)
																			) {
																				this.setState({
																					business_invoice_date_range_start: '',
																				});
																			}
																		}}
																	>
																		<option value="">วันที่</option>
																		{Array.from(Array(31), (e, i) => (
																			<option key={i} value={i + 1}>
																				{i + 1}
																			</option>
																		))}
																	</FormControl>
																	{` ของทุกเดือน`}
																</div>
																<div
																	style={{
																		fontSize: 18,
																		color: '#B3B3B3',
																	}}
																>
																	*หมายเหตุ
																	การแก้ไขวันที่จะมีผลในรอบบิลถัดไปเท่านั้น
																</div>
															</CustomRadio>
														</Col>
														<Col md={12} >
															<CustomRadio
																name="radioInvoiceType"
																value="4"
																checked={this.state.businessInvoiceType === '4'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน
															</CustomRadio>	
														</Col>
														<Col md={12}>
															<CustomRadio
																name="radioInvoiceType"
																value="5"
																checked={this.state.businessInvoiceType === '5'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด (โปรดระบุวัน)
																<div>
																	<FormControl
																		componentClass="select"
																		placeholder="select"
																		style={{
																			display: 'inline-block',
																			width: 80,
																			fontSize: 18,
																			marginRight: 5,
																			border: this.state
																				.businessInvoiceDateRangeStartInvalid_second
																				? '1px solid #FF0000'
																				: '',
																		}}
																		value={
																			this.state
																				.business_invoice_date_range_start_second
																		}
																		onChange={event => {
																			this.setState({
																				business_invoice_date_range_start_second:
																					event.target.value,
																				businessInvoiceDateRangeStartInvalid_second:
																					'',
																			});
																			if (
																				Number(event.target.value) >
																				Number(
																					this.state
																						.business_invoice_date_range_end_second
																				)
																			) {
																				this.setState({
																					business_invoice_date_range_end_second: '',
																				});
																			}
																		}}
																	>
																		<option value="">วันที่</option>
																		{Array.from(Array(27), (e, i) => (
																			<option key={i+4} value={i + 5}>
																				{i + 5}
																			</option>
																		))}
																	</FormControl>
																	{` ถึง `}
																	<FormControl
																		componentClass="select"
																		placeholder="select"
																		style={{
																			display: 'inline-block',
																			width: 80,
																			fontSize: 18,
																			marginLeft: 5,
																			marginRight: 5,
																			border: this.state
																				.businessInvoiceDateRangeEndInvalid_second
																				? '1px solid #FF0000'
																				: '',
																		}}
																		value={
																			this.state.business_invoice_date_range_end_second
																		}
																		onChange={event => {
																			this.setState({
																				business_invoice_date_range_end_second:
																					event.target.value,
																				businessInvoiceDateRangeEndInvalid_second: '',
																			});

																			if (
																				Number(event.target.value) <
																				Number(
																					this.state
																						.business_invoice_date_range_start_second
																				)
																			) {
																				this.setState({
																					business_invoice_date_range_start_second: '',
																				});
																			}
																		}}
																	>
																		<option value="">วันที่</option>
																		{Array.from(Array(27), (e, i) => (
																			<option key={i + 4} value={i + 5}>
																				{i + 5}
																			</option>
																		))}
																	</FormControl>
																	{` ของทุกเดือน`}
																</div>
																<div
																	style={{
																		fontSize: 18,
																		color: '#B3B3B3',
																	}}
																>
																	*หมายเหตุ
																	การแก้ไขวันที่จะมีผลในรอบบิลถัดไปเท่านั้น
																</div>
															</CustomRadio>
														</Col>
													</Row>
												)}
											</Col>
											<Col md={12} sm={12}>
												<Label inline style={{ marginTop: 10 }}>
													ระยะเวลาที่ต้องการชำระเงิน
												</Label>
												{this.state.isEditData === false && (
													<p>
													{
														Number(this.state.businessPayRound) === 1
																? `ทุกวันที่ ${this.state.roundDate} ของเดือน`
																: `หลังจากวันแจ้งหนี้ ${this.state.afterInvoice} วัน`
													}
													</p>
												)}

												{this.state.isEditData === true && (
													<Row>
														<Col  md={4} sm={12}>
															<CustomRadio
																disabled={!this.checkEditForm()}
																name="radioBusinessPayRound"
																value="1"
																checked={this.state.businessPayRound == '1'}
																onChange={event =>
																this.setState({
																	businessPayRound: event.target.value,
																	roundDate: 0,
																	afterInvoice: 0,
																})
																}
																inline
															>
																<span className="checkmark"></span> ทุกวันที่
															</CustomRadio>
															<div style={{ marginTop: 10, marginLeft: 35 }}>
																<FormControl
																as="input"
																type="number"
																min="0"
																max="31"
																style={{
																	display: 'inline-block',
																	width: 80,
																	marginRight: 5,
																	height: 34,
																	border: this.state.roundDateInvalid
																	? '1px solid #FF0000'
																	: '',
																}}
																value={this.state.roundDate}
																onChange={event => {
																	if (event.target.value > 31) {
																	this.setState({
																		roundDate: 31,
																		roundDateInvalid: '',
																	});
																	} else if (event.target.value < 0) {
																	this.setState({
																		roundDate: 0,
																		roundDateInvalid: '',
																	});
																	} else {
																	this.setState({
																		roundDate: event.target.value,
																		roundDateInvalid: '',
																	});
																	}
																}}
																/>
																{` ของเดือน `}
															</div>
														</Col>
														<Col md={4} sm={12}>
															<CustomRadio
																disabled={!this.checkEditForm()}
																name="radioBusinessPayRound"
																value="2"
																checked={this.state.businessPayRound == '2'}
																onChange={event =>
																this.setState({
																	businessPayRound: event.target.value,
																	roundDate: 0,
																	afterInvoice: 0,
																})
																}
																inline
															>
																<span className="checkmark"></span> หลังจากวันแจ้งหนี้
															</CustomRadio>
															<div style={{ marginTop: 10, marginLeft: 35 }}>
																<FormControl
																as="input"
																type="number"
																min="0"
																style={{
																	display: 'inline-block',
																	width: 80,
																	marginRight: 5,
																	height: 34,
																	border: this.state.afterInvoiceInvalid
																	? '1px solid #FF0000'
																	: '',
																}}
																value={this.state.afterInvoice}
																onChange={event => {
																	if (event.target.value < 0) {
																	this.setState({
																		afterInvoic: 0,
																		afterInvoiceInvalid: '',
																	});
																	} else {
																	this.setState({
																		afterInvoice: event.target.value,
																		afterInvoiceInvalid: '',
																	});
																	}
																}}
																/>
																{` วัน `}
															</div>
														</Col>
													</Row>
												)}
											</Col>
											<Col md={12} sm={12}>
												<Label style={{ marginTop: 10 }}>ที่อยู่สำหรับจัดส่งเอกสาร</Label>
												{this.state.isEditData === false ? (
													<div>
														{ this.state.shippingAddress ? `${this.state.shippingAddress} 
															เขต/อำเภอ ${this.state.shippingAddressdistrict.label}
															แขวง/ตำบล ${this.state.shippingAddresssubdistrict.label}
															จังหวัด ${this.state.shippingAddressprovince.label}
															ประเทศ ${this.state.shippingAddresscountry.label}
															${this.state.shippingAddresspostcode}` : '-'}
													</div>
												): (
													<div>
													<Row>
														<Col md={12}>
															<CustomCheckbox
																inline
																value="shippingAddress"
																checked={this.state.shippingAddressChecked}
																onClick={event =>
																	this.handleSelectShippingAddress(event)
																}
															>
																<span className="checkmark"></span>{' '}
																ใช้ที่อยู่เดียวกันกับที่อยู่บริษัท
															</CustomCheckbox>
														</Col>
													</Row>
													<Row style={{ marginTop: 10 }}>
														<Col md={9} sm={12}>
															<Input
																onChange={event =>
																	this.setState({
																		shippingAddress: event.target.value,
																		shippingAddressAddressInvalid: false,
																	})
																}
																value={this.state.shippingAddress}
																style={{ marginTop: 10 }}
																inputLabel="ที่อยู่บริษัท"
																placeholder="123/3"
																required
																messageError={
																	this.state.shippingAddressInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event => this.changeCountry(event,"shippingAddress")}
																value={this.state.shippingAddresscountry}
																style={{ marginTop: 10 }}
																options={selectOptionCountry}
																inputLabel="ประเทศ"
																required
																messageError={
																	this.state.shippingAddresscountryInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event => this.changeProvince(event,"shippingAddress")}
																value={this.state.shippingAddressprovince}
																style={{ marginTop: 10 }}
																options={this.selectOptionProvinceData("shippingAddress")}
																inputLabel="จังหวัด"
																required
																messageError={
																	this.state.shippingAddressprovinceInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event => this.changeDistrict(event,"shippingAddress")}
																value={this.state.shippingAddressdistrict}
																style={{ marginTop: 10 }}
																options={this.selectOptionDistrictData("shippingAddress")}
																inputLabel="เขต/อำเภอ"
																required
																messageError={
																	this.state.shippingAddressdistrictInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event =>
																	this.changeSubDistrict(event,"shippingAddress")
																}
																value={this.state.shippingAddresssubdistrict}
																style={{ marginTop: 10 }}
																options={this.selectOptionSubDistrictData("shippingAddress")}
																inputLabel="แขวง/ตำบล"
																required
																messageError={
																	this.state
																		.shippingAddresssubdistrictInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<Input
																onChange={event =>
																	this.setState({
																		shippingAddresspostcode: event.target.value,
																		shippingAddresspostcodeInvalid: false,
																	})
																}
																value={this.state.shippingAddresspostcode}
																style={{ marginTop: 7 }}
																inputLabel="รหัสไปรษณีย์"
																placeholder="10260"
																required
																messageError={
																	this.state.shippingAddresspostcodeInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
													</Row>
												</div>
												)}

												
											</Col>
										</Row>
									</div>

									<div>
										<Row>
											<Col md={6} sm={12}>
												<Label style={{ marginTop: 20 }}>INVITE CODE:</Label>
												<div>{this.state.invite_code || "-"}</div>
											</Col>

											<Col md={6} sm={12}>
												<Label style={{ marginTop: 20 }}>บริษัทที่แนะนำ:</Label>
												<div>{this.state.recommend_company || "-"}</div>
											</Col>
										</Row>
									</div>

									<LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />

									<div>
										<Row>
											<Col sm={6} xs={12}>
												<Link
													href={landingUrl + '/termscondition'}
													target="_blank"
												>
													{this.props.t(
														'common:setting.business.termAndConditionsOfGiztix'
													)}{' '}
													>
												</Link>{' '}
												<br />{' '}
												<Link href={landingUrl + '/policy'} target="_blank">
													{this.props.t(
														'common:setting.business.termAndConditionsOfDelivery'
													)}{' '}
													>{' '}
												</Link>{' '}
												<br />{' '}
												<Link href={landingUrl + '/policy'} target="_blank">
													{this.props.t('common:setting.business.refundPolicy')}{' '}
													>
												</Link>
											</Col>
											<Col sm={6} xs={12}>
												<div style={{ textAlign: 'right' }}>
													{this.state.isEditData ? (
														<Row>
															<Col sm={6}>
																<Button
																	style={{ marginTop: 10 }}
																	block
																	onClick={() => {
																		this.setState({
																			isEditData: false,
																			phoneCode: this.state.phoneCodeOld,
																			phoneNumber: this.state.phoneNumberOld,
																			phoneCodeCoordinator: this.state
																				.phoneCodeOldCoordinator,
																			phoneNumberCoordinator: this.state
																				.phoneNumberOldCoordinator,
																			emailTags: this.state.emailTagsOld,
																			emailTagsDelete: this.state
																				.emailTagsOldDelete,
																			phoneNumberInvalid: '',
																			emailTagsInvalid: '',
																			business_invoice_date_range_start: this
																				.state
																				.business_invoice_date_range_start_old,
																			business_invoice_date_range_end: this
																				.state
																				.business_invoice_date_range_end_old,
																			business_invoice_date_range_start_second: this
																				.state
																				.business_invoice_date_range_start_old_second,
																			business_invoice_date_range_end_second: this
																				.state
																				.business_invoice_date_range_end_old_second,
																			businessInvoiceType: this.state
																				.businessInvoiceTypeOld,
																			businessInvoiceDateRangeStartInvalid: '',
																			businessInvoiceDateRangeEndInvalid: '',
																		});
																	}}
																>
																	{this.props.t(
																		'common:setting.business.buttonCancel'
																	)}
																</Button>
															</Col>
															<Col sm={6}>
																<Mutation
																	mutation={accountBusinessUpdateMutation}
																	update={(cache, { data }) => {
																		this.setState({ isEditData: false });
																	}}
																	onError={error => {
																		console.log(error);
																	}}
																>
																	{accountBusinessUpdate => {
																		return (
																			<Button
																				style={{ marginTop: 10 }}
																				block
																				bsStyle="primary"
																				onClick={() =>
																					this.handleEditData(
																						accountBusinessUpdate
																					)
																				}
																			>
																				{this.props.t(
																					'common:setting.business.buttonSave'
																				)}
																			</Button>
																		);
																	}}
																</Mutation>
															</Col>
														</Row>
													) : (
														<Row>
															<Col sm={6}></Col>
															<Col sm={6}>
																{this.state.account_user_type === 1 && (
																	<Button
																		style={{ marginTop: 10 }}
																		block
																		onClick={() => {
																			this.setState({
																				isEditData: true,
																				phoneCodeOld: this.state.phoneCode,
																				phoneNumberOld: this.state.phoneNumber,
																				phoneCodeOldCoordinator: this.state
																					.phoneCodeCoordinator,
																				phoneNumberOldCoordinator: this.state
																					.phoneNumberCoordinator,
																				emailTagsOld: this.state.emailTags,
																				emailTagsOldDelete: this.state
																					.emailTagsDelete,
																				business_invoice_date_range_start_old: this
																					.state
																					.business_invoice_date_range_start,
																				business_invoice_date_range_end_old: this
																					.state
																					.business_invoice_date_range_end,
																				business_invoice_date_range_start_old_second: this
																					.state
																					.business_invoice_date_range_start_second,
																				business_invoice_date_range_end_old_second: this
																					.state
																					.business_invoice_date_range_end_second,
																				businessInvoiceTypeOld: this.state
																					.businessInvoiceType,
																			});
																		}}
																	>
																		{this.props.t(
																			'common:setting.business.buttonEdit'
																		)}
																	</Button>
																)}
															</Col>
														</Row>
													)}
												</div>
											</Col>
										</Row>
									</div>
								</Card>
							) : null}

							{this.state.businessStatus !== 2 &&
							!this.props.accountInfoListQuery.loading ? (
								<Card>
									<div>
										<TextHeader>การลงทะเบียนใช้งาน Giztix Business</TextHeader>
										<TextSubHeader>
											ระบุข้อมูลของบริษัทเพื่อการจองขนส่งสำหรับองค์กร/ธุรกิจ
										</TextSubHeader>

										<StepContainer>
											<ul>
												<li
													className={`${
														this.state.step >= 1 ? 'active' : null
													}`}
												>
													<span>1</span>ข้อมูลบริษัท
												</li>
												<li
													className={`${
														this.state.step >= 2 ? 'active' : null
													}`}
												>
													<span>2</span>ข้อมูลการใช้งาน
												</li>
												<li
													className={`${
														this.state.step >= 3 ? 'active' : null
													}`}
												>
													<span>3</span>ข้อมูลการขอเครดิต
												</li>
											</ul>
										</StepContainer>

										{this.state.step === 1 ? (
											<div style={{ marginTop: 20 }}>
												<div>
													<Row>
														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		businessName: event.target.value,
																		businessNameInvalid: false,
																	})
																}
																value={this.state.businessName}
																style={{ marginTop: 10 }}
																inputLabel="ชื่อบริษัท"
																placeholder="ชื่อบริษัทของคุณ"
																required
																messageError={
																	this.state.businessNameInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		taxId: event.target.value,
																		taxIdInvalid: false,
																	})
																}
																value={this.state.taxId}
																style={{ marginTop: 10 }}
																inputLabel="เลขประจำตัวผู้เสียภาษีอากร"
																placeholder="0-0000-00000-0000"
																required
																messageError={
																	this.state.taxIdInvalid && 'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		businessAddress: event.target.value,
																		businessAddressInvalid: false,
																	})
																}
																value={this.state.businessAddress}
																style={{ marginTop: 10 }}
																inputLabel="ที่อยู่บริษัท"
																placeholder="123/3"
																required
																messageError={
																	this.state.businessAddressInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		branch: event.target.value,
																		branchInvalid: false,
																	})
																}
																value={this.state.branch}
																style={{ marginTop: 10 }}
																inputLabel="สาขา"
																placeholder="สำนักงานใหญ่"
																required
																messageError={
																	this.state.branchInvalid && 'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<SelectBox
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event => this.changeCountry(event)}
																value={this.state.country}
																style={{ marginTop: 10 }}
																options={selectOptionCountry}
																inputLabel="ประเทศ"
																required
																messageError={
																	this.state.countryInvalid && 'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<SelectBox
																onChange={event => this.changeProvince(event)}
																value={this.state.province}
																style={{ marginTop: 10 }}
																options={this.selectOptionProvinceData()}
																inputLabel="จังหวัด"
																required
																disabled={
																	!this.state.country.value ||
																	!this.checkEditForm(this.state.businessStatus)
																}
																messageError={
																	this.state.provinceInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<SelectBox
																onChange={event => this.changeDistrict(event)}
																value={this.state.district}
																style={{ marginTop: 10 }}
																options={this.selectOptionDistrictData()}
																inputLabel="เขต/อำเภอ"
																required
																disabled={
																	!this.state.province.value ||
																	!this.checkEditForm(this.state.businessStatus)
																}
																messageError={
																	this.state.districtInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<SelectBox
																onChange={event =>
																	this.changeSubDistrict(event)
																}
																value={this.state.subdistrict}
																style={{ marginTop: 10 }}
																options={this.selectOptionSubDistrictData()}
																inputLabel="แขวง/ตำบล"
																required
																disabled={
																	!this.state.district.value ||
																	!this.checkEditForm(this.state.businessStatus)
																}
																messageError={
																	this.state.subDistrictInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		postcode: event.target.value,
																		postcodeInvalid: false,
																	})
																}
																value={this.state.postcode}
																style={{ marginTop: 10 }}
																inputLabel="รหัสไปรษณีย์"
																placeholder="10260"
																required
																messageError={
																	this.state.postcodeInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<SelectBox
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.changeBusinessType(event)
																}
																value={this.state.businessType}
																style={{ marginTop: 10 }}
																options={selectOptionTypeBusiness}
																inputLabel="ประเภทธุรกิจ"
																required
																messageError={
																	this.state.businessTypeInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={6} sm={12}>
															<Label
																style={{
																	marginTop: 10,
																	color: `${
																		this.state.phoneNumberInvalid
																			? '#FF0000'
																			: ''
																	}`,
																}}
																required
															>
																เบอร์โทรศัพท์
															</Label>
															<InputGroup>
																<Select
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	style={{
																		borderTopRightRadius: 0,
																		borderBottomRightRadius: 0,
																		borderRightWidth: 0,
																		border: `${
																			this.state.phoneNumberInvalid
																				? '1px solid #FF0000'
																				: ''
																		}`,
																	}}
																	value={this.state.phoneCode}
																	onChange={event =>
																		this.setState({
																			phoneCode: event.target.value,
																		})
																	}
																>
																	<option value="66">+66</option>
																</Select>
																<Input
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	onChange={event =>
																		this.setState({
																			phoneNumber: event.target.value,
																			phoneNumberInvalid: '',
																		})
																	}
																	value={this.state.phoneNumber}
																	placeholder="0123456789"
																	style={{ width: '100%' }}
																	styleInput={{
																		borderTopLeftRadius: 0,
																		borderBottomLeftRadius: 0,
																		borderLeftWidth: 0,
																	}}
																	messageError={
																		this.state.phoneNumberInvalid
																			? this.state.phoneNumberInvalid
																			: ''
																	}
																/>
															</InputGroup>
														</Col>
														<Col md={6} sm={12}>
															<Label style={{ marginTop: 10 }}>
																เบอร์เจ้าหน้าที่ประสานงาน
															</Label>
															<InputGroup>
																<Select
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	style={{
																		borderTopRightRadius: 0,
																		borderBottomRightRadius: 0,
																		borderRightWidth: 0,
																	}}
																	value={this.state.phoneCodeCoordinator}
																	onChange={event =>
																		this.setState({
																			phoneCodeCoordinator: event.target.value,
																		})
																	}
																>
																	<option value="66">+66</option>
																</Select>
																<Input
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	onChange={event =>
																		this.setState({
																			phoneNumberCoordinator:
																				event.target.value,
																		})
																	}
																	value={this.state.phoneNumberCoordinator}
																	placeholder="0123456789"
																	style={{ width: '100%' }}
																	styleInput={{
																		borderTopLeftRadius: 0,
																		borderBottomLeftRadius: 0,
																	}}
																/>
															</InputGroup>
														</Col>

														<Col sm={12}>
															<Label
																inline
																style={{
																	marginTop: 10,
																	color: `${
																		this.state.emailTagsInvalid ? '#FF0000' : ''
																	}`,
																}}
																required
															>
																อีเมล์ เพื่อใช้ในการรับ เอกสารสำคัญ (กด enter
																เพื่อเพิ่มอีเมล)
															</Label>
														</Col>

														{this.checkEditForm(this.state.businessStatus) ===
														true ? (
															<Col sm={12}>
																<TagsContainer>
																	{this.state.emailTags.length
																		? this.state.emailTags.map((e, index) => (
																				<TagsEmail key={index}>
																					{e.text}
																					<span
																						className="delete"
																						onClick={() =>
																							this.handleDelete(index)
																						}
																					>
																						{' '}
																						<i className="far fa-times"></i>
																					</span>
																				</TagsEmail>
																		  ))
																		: ''}
																	<Input
																		onChange={event => {
																			this.setState({
																				email: event.target.value,
																				emailTagsInvalid: '',
																			});
																		}}
																		onKeyPress={event => {
																			if (
																				event.charCode === KeyCodes.enter ||
																				event.charCode === KeyCodes.comma
																			) {
																				this.handleAddition(event);
																			}
																		}}
																		onBlur={event => {
																			if (event.target.value) {
																				this.handleAddition(event);
																			}
																		}}
																		value={this.state.email}
																		style={{ display: 'inline-block' }}
																		styleInput={{ width: 250 }}
																		placeholder="user@email.com"
																		messageError={
																			this.state.emailTagsInvalid === true
																				? this.state.emailTagsInvalid
																				: false
																		}
																	/>
																</TagsContainer>
															</Col>
														) : (
															<Col sm={12}>
																<TagsContainer>
																	{this.state.emailTags.length
																		? this.state.emailTags.map((e, index) => (
																				<TagsEmail key={index}>
																					{e.text}
																				</TagsEmail>
																		  ))
																		: null}
																</TagsContainer>
															</Col>
														)}

														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		invite_code: event.target.value,
																	})
																}
																value={this.state.invite_code}
																style={{ marginTop: 10 }}
																inputLabel="INVITE CODE"
																placeholder="INVITE CODE"
																maxlngth={5}
															/>
														</Col>

														<Col md={6} sm={12}>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		recommend_company: event.target.value,
																	})
																}
																value={this.state.recommend_company}
																style={{ marginTop: 10 }}
																inputLabel="บริษัทที่แนะนำ"
																placeholder="บริษัทที่แนะนำ"
																maxlngth={5}
															/>
														</Col>
													</Row>
												</div>

												<LineHorizontal
													style={{ marginTop: 40, marginBottom: 40 }}
												/>

												<TextHeader>ที่อยู่สำหรับจัดส่งเอกสาร</TextHeader>

												<div>
													<Row>
														<Col md={12}>
															<CustomCheckbox
																inline
																value="shippingAddress"
																checked={this.state.shippingAddressChecked}
																onClick={event =>
																	this.handleSelectShippingAddress(event)
																}
															>
																<span className="checkmark"></span>{' '}
																ใช้ที่อยู่เดียวกันกับที่อยู่บริษัท
															</CustomCheckbox>
														</Col>
													</Row>
													<Row style={{ marginTop: 10 }}>
														<Col md={9} sm={12}>
															<Input
																disabled={
																	this.state.shippingAddressChecked
																}
																onChange={event =>
																	this.setState({
																		shippingAddress: event.target.value,
																		shippingAddressAddressInvalid: false,
																	})
																}
																value={this.state.shippingAddress}
																style={{ marginTop: 10 }}
																inputLabel="ที่อยู่บริษัท"
																placeholder="123/3"
																required
																messageError={
																	this.state.shippingAddressInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																disabled={
																	this.state.shippingAddressChecked
																}
																onChange={event => this.changeCountry(event,"shippingAddress")}
																value={this.state.shippingAddresscountry}
																style={{ marginTop: 10 }}
																options={selectOptionCountry}
																inputLabel="ประเทศ"
																required
																messageError={
																	this.state.shippingAddresscountryInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event => this.changeProvince(event,"shippingAddress")}
																value={this.state.shippingAddressprovince}
																style={{ marginTop: 10 }}
																options={this.selectOptionProvinceData("shippingAddress")}
																inputLabel="จังหวัด"
																required
																disabled={
																	this.state.shippingAddressChecked
																}
																messageError={
																	this.state.shippingAddressprovinceInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event => this.changeDistrict(event,"shippingAddress")}
																value={this.state.shippingAddressdistrict}
																style={{ marginTop: 10 }}
																options={this.selectOptionDistrictData("shippingAddress")}
																inputLabel="เขต/อำเภอ"
																required
																disabled={
																	this.state.shippingAddressChecked
																}
																messageError={
																	this.state.shippingAddressdistrictInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<SelectBox
																onChange={event =>
																	this.changeSubDistrict(event,"shippingAddress")
																}
																value={this.state.shippingAddresssubdistrict}
																style={{ marginTop: 10 }}
																options={this.selectOptionSubDistrictData("shippingAddress")}
																inputLabel="แขวง/ตำบล"
																required
																disabled={
																	this.state.shippingAddressChecked
																}
																messageError={
																	this.state
																		.shippingAddresssubdistrictInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
														<Col md={3} sm={12}>
															<Input
																disabled={
																	this.state.shippingAddressChecked
																}
																onChange={event =>
																	this.setState({
																		shippingAddresspostcode: event.target.value,
																		shippingAddresspostcodeInvalid: false,
																	})
																}
																value={this.state.shippingAddresspostcode}
																style={{ marginTop: 7 }}
																inputLabel="รหัสไปรษณีย์"
																placeholder="10260"
																required
																messageError={
																	this.state.shippingAddresspostcodeInvalid &&
																	'กรุณากรอกข้อมูล'
																}
															/>
														</Col>
													</Row>
												</div>

												<LineHorizontal
													style={{ marginTop: 60, marginBottom: 40 }}
												/>

												<div>
													<Row>
														<Col md={6} sm={6}></Col>
														<Col md={6} sm={6}>
															<div style={{ textAlign: 'right' }}>
																<ButtonNext
																	onClick={() => this.handleNextStepOne()}
																>
																	ต่อไป <i className="fal fa-angle-right"></i>
																</ButtonNext>
															</div>
														</Col>
													</Row>
												</div>
											</div>
										) : null}

										{this.state.step === 2 ? (
											<div>
												<div style={{ marginTop: 40 }}>
													<Label inline required>
														1. คาดว่าบริษัทของท่านจะใช้บริการขนส่ง
													</Label>{' '}
													{this.state.companyWillUseInvalid ? (
														<TextMessageError>
															{this.state.companyWillUseInvalid}
														</TextMessageError>
													) : null}
													<Row>
														<Col md={3}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																value="1 ครั้ง ต่อวัน"
																checked={
																	this.state.companyWillUse === '1 ครั้ง ต่อวัน'
																}
																onChange={event =>
																	this.setState({
																		companyWillUse: event.target.value,
																	})
																}
																name="radio1"
																inline
															>
																<span className="checkmark"></span> 1 ครั้ง
																ต่อวัน
															</CustomRadio>
														</Col>
														<Col md={3}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																value="มากกว่า 1 ครั้งต่อวัน"
																checked={
																	this.state.companyWillUse ===
																	'มากกว่า 1 ครั้งต่อวัน'
																}
																onChange={event =>
																	this.setState({
																		companyWillUse: event.target.value,
																	})
																}
																name="radio1"
																inline
															>
																<span className="checkmark"></span> มากกว่า 1
																ครั้งต่อวัน
															</CustomRadio>
														</Col>
														<Col md={3}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																value="1 - 5 ครั้ง/สัปดาห์"
																checked={
																	this.state.companyWillUse ===
																	'1 - 5 ครั้ง/สัปดาห์'
																}
																onChange={event =>
																	this.setState({
																		companyWillUse: event.target.value,
																	})
																}
																name="radio1"
																inline
															>
																<span className="checkmark"></span> 1 - 5
																ครั้ง/สัปดาห์
															</CustomRadio>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 60 }}>
													<Label inline required>
														2. ประเภทสินค้าที่ต้องการจัดส่ง (เลือกได้มากกว่า 1)
													</Label>{' '}
													{this.state.productTypeInvalid ? (
														<TextMessageError>
															{this.state.productTypeInvalid}
														</TextMessageError>
													) : null}
													<Row>
														<Col md={3}>
															<CustomCheckbox
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																inline
																value={this.state.productType[0].value}
																checked={this.state.productType[0].checked}
																onClick={event =>
																	this.handleSelectProductType(event)
																}
															>
																<span className="checkmark"></span>{' '}
																{this.state.productType[0].value}
															</CustomCheckbox>
														</Col>
														<Col md={3}>
															<CustomCheckbox
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																inline
																value={this.state.productType[1].value}
																checked={this.state.productType[1].checked}
																onClick={event =>
																	this.handleSelectProductType(event)
																}
															>
																<span className="checkmark"></span>{' '}
																{this.state.productType[1].value}
															</CustomCheckbox>
														</Col>
														<Col md={3}>
															<CustomCheckbox
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																inline
																value={this.state.productType[2].value}
																checked={this.state.productType[2].checked}
																onClick={event =>
																	this.handleSelectProductType(event)
																}
															>
																<span className="checkmark"></span>{' '}
																{this.state.productType[2].value}
															</CustomCheckbox>
														</Col>
														<Col md={6}>
															<CustomCheckbox
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																inline
																value={this.state.productType[3].value}
																checked={this.state.productType[3].checked}
																onClick={event =>
																	this.handleSelectProductType(event)
																}
															>
																<span className="checkmark"></span>{' '}
																{this.state.productType[3].value}
															</CustomCheckbox>
															<Input
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																styleInput={{
																	border: `${
																		this.state.productTypeOtherInvalid
																			? '1px solid #FF0000'
																			: ''
																	}`,
																}}
																style={{
																	marginLeft: 30,
																	marginTop: 20,
																	width: 135,
																	display: 'inline-block',
																}}
																value={this.state.productTypeOther}
																onChange={event =>
																	this.setState({
																		productTypeOther: event.target.value,
																	})
																}
																placeholder="ระบุ"
															/>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 60 }}>
													<Label inline required>
														3. ประเภทรถที่ต้องการจัดส่ง (เลือกได้มากกว่า 1)
													</Label>{' '}
													{this.state.truckTypeInvalid ? (
														<TextMessageError>
															{this.state.truckTypeInvalid}
														</TextMessageError>
													) : null}
													<Row>
														{this.state.truckType.map(e => {
															return (
																<Col md={3} key={e.value}>
																	<CustomCheckbox
																		disabled={!this.checkEditForm()}
																		inline
																		value={e.value}
																		checked={e.checked}
																		onClick={event =>
																			this.handleSelectTruckType(event)
																		}
																	>
																		<span className="checkmark"></span>
																		{e.value}
																	</CustomCheckbox>
																</Col>
															);
														})}
													</Row>
												</div>

												<LineHorizontal
													style={{ marginTop: 150, marginBottom: 40 }}
												/>

												<div>
													<Row>
														<Col md={6} sm={6} xs={6}>
															<ButtonBack
																onClick={() => this.setState({ step: 1 })}
															>
																<i className="fal fa-angle-left"></i>ก่อนหน้านี้
															</ButtonBack>
														</Col>
														<Col md={6} sm={6} xs={6}>
															<div style={{ textAlign: 'right' }}>
																<ButtonNext
																	onClick={() => this.handleNextStepTwo()}
																>
																	ต่อไป <i className="fal fa-angle-right"></i>
																</ButtonNext>
															</div>
														</Col>
													</Row>
												</div>
											</div>
										) : null}

										{this.state.step === 3 ? (
											<div>
												<div style={{ marginTop: 40 }}>
													<Label
														style={{
															color: `${
																this.state.creditLimitInvalid ? '#FF0000' : ''
															}`,
														}}
														inline
														required
													>
														1. วงเงินเครดิตที่ต้องการ
													</Label>
													<Row>
														<Col md={6}>
															<InputGroup style={{ marginTop: 6 }}>
																<Input
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	type="text"
																	value={this.state.creditLimit}
																	inputRef={this._inputCredit}
																	onChange={event =>
																		this.formatCurrency(
																			event,
																			this._inputCredit
																		)
																	}
																	style={{ width: '100%' }}
																	styleInput={{
																		borderBottomRightRadius: 0,
																		borderTopRightRadius: 0,
																	}}
																	placeholder="จำนวนเงิน"
																	messageError={
																		this.state.creditLimitInvalid
																			? this.state.creditLimitInvalid
																			: ''
																	}
																/>
																<TextRight
																	style={{
																		borderBottomLeftRadius: 0,
																		borderTopLeftRadius: 0,
																		borderLeftWidth: 0,
																		border: `${
																			this.state.creditLimitInvalid
																				? '1px solid #FF0000'
																				: ''
																		}`,
																	}}
																>
																	บาท
																</TextRight>
															</InputGroup>

															<TextContent style={{ color: '#B3B3B3' }}>
																*จำนวนวงเงินเครดิตที่คุณระบุไว้อาจไม่ใช่จำนวนจริงที่คุณจะได้รับ
																ทั้งนี้จำนวนที่ได้รับเป็นไปตามการพิจารณาจากทาง
																Giztix เท่านั้น
															</TextContent>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 40 }}>
													<Label>
														2. ช่องทางการรับเอกสารใบแจ้งหนี้และใบเสร็จรับเงิน
													</Label>
													<Row>
														<Col md={12}>
															<CustomCheckbox
																disabled={true}
																checked={this.state.receiveInvoiceAndReceipt}
																onChange={event =>
																	this.setState({
																		receiveInvoiceAndReceipt:
																			event.target.checked,
																	})
																}
																inline
															>
																<span className="checkmark"></span>{' '}
																ท่านยอมรับการรับเอกสารผ่านช่องทาง
																E-Invoice/E-Receipt
															</CustomCheckbox>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 40 }}>
													<Label inline required>
														3. คุณต้องการทำหัก ณ
														ที่จ่ายค่าขนส่งทุกครั้งที่ใช้บริการหรือไม่
													</Label>{' '}
													{this.state.transportationFeeInvalid ? (
														<TextMessageError>
															{this.state.transportationFeeInvalid}
														</TextMessageError>
													) : null}
													<Row>
														<Col lg={3} md={4} sm={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radio2"
																value="1"
																checked={this.state.transportationFee === '1'}
																onClick={event =>
																	this.setState({
																		transportationFee: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> ต้องการ
															</CustomRadio>
														</Col>
														<Col lg={3} md={4} sm={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radio2"
																value="2"
																checked={this.state.transportationFee === '2'}
																onClick={event =>
																	this.setState({
																		transportationFee: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> ไม่ต้องการ
															</CustomRadio>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 40 }}>
													<Label inline required>
														4.  คุณต้องการรับเอกสารใบแจ้งหนี้เมื่อใด
													</Label>{' '}
													{this.state.businessInvoiceTypeInvalid ? (
														<TextMessageError>
															{this.state.businessInvoiceTypeInvalid}
														</TextMessageError>
													) : null}
													<Row>
														<Col md={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radioInvoiceType"
																value="1"
																checked={this.state.businessInvoiceType === '1'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน
															</CustomRadio>
														</Col>
														<Col md={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radioInvoiceType"
																value="2"
																checked={this.state.businessInvoiceType === '2'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน
															</CustomRadio>
														</Col>

														<Col md={12}>
															<CustomRadio
																value="3"
																checked={this.state.businessInvoiceType === '3'}
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
																name="radioInvoiceType"
															>
																<span className="checkmark"></span>{' '}
																1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด (โปรดระบุวัน){' '}
																<br/>
																<FormControl
																	componentClass="select"
																	placeholder="select"
																	style={{
																		display: 'inline-block',
																		width: 80,
																		fontSize: 18,
																		marginLeft: 5,
																		marginRight: 5,
																		border: this.state
																				.businessInvoiceDateRangeStartInvalid
																				? '1px solid #FF0000'
																				: '',
																	}}
																	value={
																		this.state.business_invoice_date_range_start
																	}
																	onChange={event => {
																		this.setState({
																			business_invoice_date_range_start:
																				event.target.value,
																			businessInvoiceDateRangeStartInvalid:
																				'',
																		});
																		if (
																			Number(event.target.value) >
																			Number(
																				this.state
																					.business_invoice_date_range_end
																			)
																		) {
																			this.setState({
																				business_invoice_date_range_end: '',
																			});
																		}
																	}}
																	name="business_invoice_date_range_start"
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																>
																	<option value="">วันที่</option>
																	{Array.from(Array(31), (e, i) => (
																		<option key={i} value={i + 1}>
																			{i + 1}
																		</option>
																	))}
																</FormControl>
																{` ถึง `}
																<FormControl
																	componentClass="select"
																	placeholder="select"
																	style={{
																		display: 'inline-block',
																		width: 80,
																		fontSize: 18,
																		marginLeft: 5,
																		marginRight: 5,
																		border: this.state
																				.businessInvoiceDateRangeEndInvalid
																				? '1px solid #FF0000'
																				: '',
																	}}
																	value={
																		this.state.business_invoice_date_range_end
																	}
																	onChange={event => {
																		this.setState({
																			business_invoice_date_range_end:
																				event.target.value,
																			businessInvoiceDateRangeEndInvalid: '',
																		});

																		if (
																			Number(event.target.value) <
																			Number(
																				this.state
																					.business_invoice_date_range_start
																			)
																		) {
																			this.setState({
																				business_invoice_date_range_start: '',
																			});
																		}
																	}}
																	name="business_invoice_date_range_end"
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																>
																	<option value="">วันที่</option>
																	{Array.from(Array(31), (e, i) => (
																		<option key={i} value={i + 1}>
																			{i + 1}
																		</option>
																	))}
																</FormControl>
																{` ของทุกเดือน`}
															</CustomRadio>
														</Col>
														<Col md={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radioInvoiceType"
																value="4"
																checked={this.state.businessInvoiceType === '4'}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
															>
																<span className="checkmark"></span> 1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน
															</CustomRadio>
														</Col>
														<Col md={12}>
															<CustomRadio
																value="5"
																checked={this.state.businessInvoiceType === '5'}
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																onChange={event =>
																	this.setState({
																		businessInvoiceType: event.target.value,
																	})
																}
																inline
																name="radioInvoiceType"
															>
																<span className="checkmark"></span>{' '}
																1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด (โปรดระบุวัน){' '}
																<br/>
																<FormControl
																	componentClass="select"
																	placeholder="select"
																	style={{
																		display: 'inline-block',
																		width: 80,
																		fontSize: 18,
																		marginLeft: 5,
																		marginRight: 5,
																		border: this.state
																				.businessInvoiceDateRangeStartInvalid_second
																				? '1px solid #FF0000'
																				: '',
																	}}
																	value={
																		this.state.business_invoice_date_range_start_second
																	}
																	onChange={event => {
																		this.setState({
																			business_invoice_date_range_start_second:
																				event.target.value,
																			businessInvoiceDateRangeStartInvalid_second:
																				'',
																		});
																		if (
																			Number(event.target.value) >
																			Number(
																				this.state
																					.business_invoice_date_range_end_second
																			)
																		) {
																			this.setState({
																				business_invoice_date_range_end_second: '',
																			});
																		}
																	}}
																	name="business_invoice_date_range_start_second"
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																>
																	<option value="">วันที่</option>
																	{Array.from(Array(27), (e, i) => (
																		<option key={i + 4} value={i + 5}>
																			{i + 5}
																		</option>
																	))}
																</FormControl>
																{` ถึง `}
																<FormControl
																	componentClass="select"
																	placeholder="select"
																	style={{
																		display: 'inline-block',
																		width: 80,
																		fontSize: 18,
																		marginLeft: 5,
																		marginRight: 5,
																		border: this.state
																				.businessInvoiceDateRangeEndInvalid_second
																				? '1px solid #FF0000'
																				: '',
																	}}
																	value={
																		this.state.business_invoice_date_range_end_second
																	}
																	onChange={event => {
																		this.setState({
																			business_invoice_date_range_end_second:
																				event.target.value,
																			businessInvoiceDateRangeEndInvalid_second: '',
																		});

																		if (
																			Number(event.target.value) <
																			Number(
																				this.state
																					.business_invoice_date_range_start_second
																			)
																		) {
																			this.setState({
																				business_invoice_date_range_start_second: '',
																			});
																		}
																	}}
																	name="business_invoice_date_range_end_second"
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																>
																	<option value="">วันที่</option>
																	{Array.from(Array(27), (e, i) => (
																		<option key={i + 4} value={i + 5}>
																			{i + 5}
																		</option>
																	))}
																</FormControl>
																{` ของทุกเดือน`}
															</CustomRadio>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 40 }}>
													<Label inline required>
														5.  คุณต้องการชำระเงินค่าบริการเมื่อใด
													</Label>{' '}
													{this.state.businessPayRoundInvalid ? (
														<TextMessageError>
															{this.state.businessPayRoundInvalid}
														</TextMessageError>
													) : null}
													<Row>
														<Col lg={3} md={4} sm={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radioBusinessPayRound"
																value="1"
																checked={this.state.businessPayRound == '1'}
																onChange={event =>
																	this.setState({
																		businessPayRound: event.target.value,
																		roundDate: 0,
																		afterInvoice: 0,
																	})
																}
																inline
															>
																<span className="checkmark"></span> ทุกวันที่
															</CustomRadio>
															<div style={{ marginTop: 10, marginLeft: 35 }}>
																<FormControl
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	as="input"
																	type="number"
																	min="0"
																	max="31"
																	style={{
																		display: 'inline-block',
																		width: 80,
																		marginRight: 5,
																		height: 34,
																		border: this.state.roundDateInvalid
																			? '1px solid #FF0000'
																			: '',
																	}}
																	value={this.state.roundDate}
																	onChange={event => {
																		if (event.target.value > 31) {
																			this.setState({
																				roundDate: 31,
																				roundDateInvalid: '',
																			});
																		} else if (event.target.value < 0) {
																			this.setState({
																				roundDate: 0,
																				roundDateInvalid: '',
																			});
																		} else {
																			this.setState({
																				roundDate: event.target.value,
																				roundDateInvalid: '',
																			});
																		}
																	}}
																/>
																{` ของเดือน `}
															</div>
														</Col>
														<Col lg={3} md={4} sm={12}>
															<CustomRadio
																disabled={
																	!this.checkEditForm(this.state.businessStatus)
																}
																name="radioBusinessPayRound"
																value="2"
																checked={this.state.businessPayRound == '2'}
																onChange={event =>
																	this.setState({
																		businessPayRound: event.target.value,
																		roundDate: 0,
																		afterInvoice: 0,
																	})
																}
																inline
															>
																<span className="checkmark"></span>{' '}
																หลังจากวันแจ้งหนี้
															</CustomRadio>
															<div style={{ marginTop: 10, marginLeft: 35 }}>
																<FormControl
																	disabled={
																		!this.checkEditForm(
																			this.state.businessStatus
																		)
																	}
																	as="input"
																	type="number"
																	min="0"
																	style={{
																		display: 'inline-block',
																		width: 80,
																		marginRight: 5,
																		height: 34,
																		border: this.state.afterInvoiceInvalid
																			? '1px solid #FF0000'
																			: '',
																	}}
																	value={this.state.afterInvoice}
																	onChange={event => {
																		if (event.target.value < 0) {
																			this.setState({
																				afterInvoic: 0,
																				afterInvoiceInvalid: '',
																			});
																		} else {
																			this.setState({
																				afterInvoice: event.target.value,
																				afterInvoiceInvalid: '',
																			});
																		}
																	}}
																/>
																{` วัน `}
															</div>
														</Col>
													</Row>
												</div>

												<div style={{ marginTop: 60 }}>
													<Row>
														<Col md={7}>
															<CustomCheckbox
																value={this.state.acceptTerms}
																disabled={!this.checkEditForm()}
																checked={this.state.acceptTerms}
																onClick={event =>
																	this.setState({
																		acceptTerms: event.target.checked,
																	})
																}
																inline
															>
																<span className="checkmark"></span>{' '}
																ในการลงทะเบียน จะถือว่าคุณยอมรับ{' '}
																<Link
																	href={landingUrl + '/termscondition'}
																	target="_blank"
																>
																	เงื่อนไขข้อกำหนด Giztix Business
																</Link>
																,{' '}
																<Link href={landingUrl} target="_blank">
																	เงื่อนไขข้อกำหนดการจัดส่งสินค้าและการรับประกัน{' '}
																</Link>{' '}
																และ{' '}
																<Link
																	href={landingUrl + '/policy'}
																	target="_blank"
																>
																	นโยบายคืนเงิน
																</Link>{' '}
																สำหรับการให้บริการของ Giztix Business ทั้งหมด
															</CustomCheckbox>
														</Col>
													</Row>
												</div>

												<LineHorizontal
													style={{ marginTop: 170, marginBottom: 40 }}
												/>

												<div>
													<Row>
														<Col md={6} sm={6} xs={6}>
															<ButtonBack
																onClick={() => this.setState({ step: 2 })}
															>
																<i className="fal fa-angle-left"></i>ก่อนหน้านี้
															</ButtonBack>
														</Col>
														<Col md={6} sm={6} xs={6}>
															<div style={{ textAlign: 'right' }}>
																<Mutation
																	mutation={accountBusinessUpdateMutation}
																	update={(cache, { data }) => {
																		this.setState({
																			step:1,
																			businessStatus: 1,
																			isOpenModalError: true,
																			textModalError: "บันทึกข้อมูลเรียบร้อย",
																		});
																	}}
																	onError={error => {
																		const errorMessage = error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'เกิดข้อผิดพลาดบางอย่างลองใหม่อีกครั้ง';
																		this.setState({
																			isOpenModalError: true,
																			textModalError: errorMessage,
																		});
																	}}
																>
																	{accountBusinessUpdate => {
																		return (
																			<div>
																				{this.checkEditForm() ? (
																					<ButtonNext
																						disabled={!this.state.acceptTerms}
																						onClick={() =>
																							this.handleSendForm(
																								accountBusinessUpdate
																							)
																						}
																					>
																						ส่งแบบฟอร์ม
																					</ButtonNext>
																				) : null}
																			</div>
																		);
																	}}
																</Mutation>
															</div>
														</Col>
													</Row>
												</div>
											</div>
										) : null}
									</div>
								</Card>
							) : null}
						</Col>
						<Col lg={3} md={4} sm={12}>
							{this.state.businessStatus === 1 ||
							this.state.businessStatus === 5 ? (
								<StatusContainer>
									<div className="header">
										<div className="header-icon">
											<i className="far fa-ellipsis-h"></i>
										</div>
										<div className="header-text">
											กำลังรอการอนุมัติจากทาง{' '}
											<span style={{ whiteSpace: 'nowrap' }}>
												Giztix Business
											</span>
										</div>
									</div>
									<div className="detail">
										<div className="detail-list">
											<div className="detail-list-left">หมายเลขคำร้อง</div>
											<div className="detail-list-right">
												BU{this.state.businessReqNumber}
											</div>
										</div>
										<div className="detail-list">
											<div className="detail-list-left">วันที่ยื่นคำร้อง</div>
											<div className="detail-list-right">
												<Moment format="DD/MM/YYYY HH:mm">
													{this.state.create_date}
												</Moment>
											</div>
										</div>
										<div className="detail-list">
											<div className="detail-list-left">สถานะคำร้อง</div>
											<div className="detail-list-right">รอตรวจสอบ</div>
										</div>
									</div>
								</StatusContainer>
							) : null}

							{this.state.businessStatus === 4 ? (
								<StatusContainer>
									<div className="header">
										<div className="header-icon header-icon-danger">
											<i className="fal fa-exclamation-triangle"></i>
										</div>
										<div className="header-text">
											<span style={{ whiteSpace: 'nowrap' }}>
												Giztix Business
											</span>{' '}
											ปฏิเสธคำร้องของคุณ
										</div>
									</div>
									<div className="detail">
										<div className="detail-list">
											<div className="detail-list-left">หมายเลขคำร้อง</div>
											<div className="detail-list-right">
												BU{this.state.businessReqNumber}
											</div>
										</div>
										<div className="detail-list">
											<div className="detail-list-left">วันที่ยื่นคำร้อง</div>
											<div className="detail-list-right">
												<Moment format="DD/MM/YYYY HH:mm">
													{this.state.create_date}
												</Moment>
											</div>
										</div>
										<div className="detail-list">
											<div className="detail-list-left">สถานะคำร้อง</div>
											<div className="detail-list-right text-danger">
												ไม่อนุมัติ
											</div>
										</div>
									</div>
								</StatusContainer>
							) : null}

							{this.state.businessStatus === 2 ||
							this.state.businessStatus === 3 ? (
								<div>
									<CreditContainer>
										<div className="text-medium text-bold text-header">
											{this.props.t('common:setting.business.credit.title')}
										</div>
										<div>
											{this.props.t(
												'common:setting.business.credit.spentAmount'
											)}
										</div>
										<div className="text-large  text-bold">
											{parseFloat(this.state.business_credit_used)
												.toFixed(2)
												.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
										</div>
										<div className="line"></div>
										<div>
											{this.props.t('common:setting.business.credit.balance')}
										</div>
										<div className="text-medium text-bold">
											{parseFloat(
												this.state.business_credit_approved -
													this.state.business_credit_used
											)
												.toFixed(2)
												.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
										</div>
										<div className="line"></div>
										<div>
											{this.props.t(
												'common:setting.business.credit.totalCreditLimit'
											)}
										</div>
										<div className="text-medium">
											{parseFloat(this.state.business_credit_approved)
												.toFixed(2)
												.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
										</div>
									</CreditContainer>

									<StatusContainer>
										<div className="header">
											<div className="header-icon header-icon-success">
												<i className="fas fa-check"></i>
											</div>
											<div className="header-text">
												{this.props.t('common:setting.business.detail.title')}
											</div>
										</div>
										<div className="detail">
											<div className="detail-list">
												<div className="detail-list-left">
													{this.props.t(
														'common:setting.business.detail.requestNumber'
													)}
												</div>
												<div className="detail-list-right">
													BU{this.state.businessReqNumber}
												</div>
											</div>
											<div className="detail-list">
												<div className="detail-list-left">
													{this.props.t(
														'common:setting.business.detail.requestDate'
													)}
												</div>
												<div className="detail-list-right">
													<Moment format="DD/MM/YYYY HH:mm">
														{this.state.create_date}
													</Moment>
												</div>
											</div>
											<div className="detail-list">
												<div className="detail-list-left">
													{this.props.t(
														'common:setting.business.detail.requestStatus'
													)}
												</div>
												{this.state.businessStatus === 2 ? (
													<div className="detail-list-right">
														{this.props.t(
															'common:setting.business.detail.status.approved'
														)}
													</div>
												) : null}
												{this.state.businessStatus === 3 ? (
													<div className="detail-list-right text-danger">
														ถูกระงับการใช้งาน
													</div>
												) : null}
											</div>
											{this.state.invite_code && (
												<div className="detail-list">
													<div className="detail-list-left">
														{this.props.t(
															'common:setting.business.detail.approvedByInvite'
														)}
													</div>
													<div className="detail-list-right">
														{this.state.invite_code}
													</div>
												</div>
											)}
											<div className="detail-list">
												<div className="detail-list-left">
													{this.props.t(
														'common:setting.business.detail.approvedDate'
													)}
												</div>
												<div className="detail-list-right">
													<Moment format="DD/MM/YYYY HH:mm">
														{this.state.approved_date}
													</Moment>
												</div>
											</div>
										</div>
									</StatusContainer>
								</div>
							) : null}
						</Col>
					</Row>

					<ModalConfirm
						show={this.state.isOpenModalError}
						onPressButtonConfirm={() => this.setState({ isOpenModalError: false })}
						styleColorButtonConfirm="primary"
						labelButtonConfirm={`ตกลง`}
						titleHeader={`แจ้งเตือน`}
						onHide={() => this.setState({ isOpenModalError: false })}
					>
						{ this.state.textModalError }
					</ModalConfirm>
				</Container>
			</div>
		);
	}
}

export default compose(
	graphql(accountInfoListQuery, {
		name: 'accountInfoListQuery',
		fetchPolicy: 'network-only',
	})
)(withNamespaces()(Business));
