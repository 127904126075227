import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from "react-i18next";

const Container = styled.div`
    position: relative;

    & .service-item-container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        &-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 1;
            flex: 1;
        }
        
        &-price {
            font-size: 28px;
            font-weight: bold;
            line-height: 1;

            ${props => props.grossPrice && `
                color: #D90101;
            `}
        }
    }

    & .service-item-container-sub {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #B3B3B3;
        font-weight: normal;
        font-size: 18px;
        line-height: 1.2;
        justify-content: space-between;
        line-height: 1;

        @media(max-width: 767px) {
            width: 60%;
        }
    }

    & .price-discount {
        position: absolute;
        top: 20px;
        right: 0px;
        color: #B3B3B3;
        font-size: 18px;
    }
`;

const InfoContainer = styled.i`
    margin-left: 7px;
    color: #B3B3B3;
    font-size: 16px;
    cursor: pointer;
`;

const ServicePriceItem = (props) => {
    const { Title, ...except } = props;

    return (
        <Container {...except}>
            <div className="service-item-container-main">
                <div className="service-item-container-main-title">{props.Title} <InfoContainer id="create-shipment__info-service-price" onClick={() => props.onPress ? props.onPress() : null} className="fal fa-info-circle" /></div>
                <div className="service-item-container-main-price">{props.free ? (<span style={{color: "#D90101",fontSize : 20}}>{props.t('common:instancePrice.freeCharge')}</span>) : `${props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${props.t('common:instancePrice.baht')}` }</div>
            </div>

            {
                !!props.grossPrice && (
                    <div className="price-discount"><del>{props.grossPrice} {props.t('common:instancePrice.baht')}</del></div>
                )
            }

            <div className="service-item-container-sub">{props.subTitleNewLine} {props.subTitle}</div>
        </Container>
    )
};

ServicePriceItem.propTypes = {
    Title: PropTypes.string,
    subTitle: PropTypes.string,
    subTitleNewLine: PropTypes.string,
    price: PropTypes.string,
    grossPrice: PropTypes.string,
    free: PropTypes.bool,
};
  
ServicePriceItem.defaultProps = {
    Title : "",
    subTitle : "",
    price : "",
    grossPrice: "",
    free: false,
    subTitleNewLine: "",
};

export default withNamespaces()(ServicePriceItem);
