import gql from 'graphql-tag';

export default gql`
	query AccountInfoListBusiness {
		accountInfoList {
			account_business {
				_id
				business_status
				customer_service_type
			}
		}
	}
`;
