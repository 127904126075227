import gql from "graphql-tag";

export default gql`
  query FavoriteAndBlacklistDriverList(
    $setting_truck_service: ID
    $driver_name: String
    $mode: String!
  ) {
    favoriteAndBlacklistDriverList(
      setting_truck_service: $setting_truck_service
      driver_name: $driver_name
      mode: $mode
    ) {
      driver_user {
        _id
        driver_name
        driver_display_pic
        setting_truck_name {
          th
          en
        }
        truck_type_name {
          th
          en
        }
      }
    }
  }
`;
