import gql from "graphql-tag";

export default gql`
  mutation DriverFavoriteAndBlacklistAdd(
    $driver_user_id: [ID]!
    $mode: String!
  ) {
    driverfavoriteAndBlacklistAdd(
      driver_user_id: $driver_user_id
      mode: $mode
    ) {
      _id
    }
  }
`;