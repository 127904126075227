import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #F9F9F9;
  height: 10px;
  box-shadow: inset 0px 2px 10px rgba(0,0,0,0.20);
`

const BlankSpace = (props) => {
  return (
    <Container/>
  )
}

export default BlankSpace;