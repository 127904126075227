import React from 'react';
import styled from 'styled-components';
import color from '../../../../config/color';
import urijs from 'urijs';
import { Query, graphql, compose } from 'react-apollo';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { withNamespaces } from 'react-i18next';

const MenuContainer = styled.div`
	& ul {
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		margin-bottom: 20px;
	}

	& ul > li {
		font-size: 20px;
		padding: 14px 12px 12px 20px;
		color: #000000;
		border-radius: 4px;
		line-height: 1;
		position: relative;

		&:hover {
			background-color: #f2f2f2;
		}

		& a {
			text-decoration: none;
			color: inherit;
			display: flex;
			width: 100%;
			cursor: pointer;

			& i {
				font-size: 20px;
				padding-right: 12px;
				display: inline-block;
				width: 40px;
				text-align: center;
			}

			& span {
				flex: 1;
			}
		}

		&.active {
			background-color: #f2f2f2;
			font-weight: bold;

			&::after {
				content: '';
				background-color: ${color.Primary};
				width: 4px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
	}
`;

class Menu extends React.Component {
	state = {
		urlPath: '',
	};

	componentWillMount() {
		const uri = urijs(window.location.href).pathname();
		this.setState({ urlPath: uri });
	}

	render() {
		return (
			<Query query={accountInfoListQuery} fetchPolicy="cache-and-network">
				{({ data, loading, error }) => {
					if (loading || error) return <div></div>;

					const { account_info, account_business } = data.accountInfoList;
					const accountInfo = account_info[0];
					const accountBusiness = account_business;
					const isCustomerServiceType = accountInfo.customer_service_type.includes(
						'PARCEL'
					);

					return (
						<MenuContainer>
							<ul>
								<li
									className={
										this.state.urlPath === '/setting-profile' && 'active'
									}
								>
									<a href="/setting-profile">
										<i className="far fa-user" />
										<span>{this.props.t('common:setting.menu.profile')}</span>
									</a>
								</li>

								<li
									className={
										this.state.urlPath === '/setting-contact' && 'active'
									}
								>
									<a href="/setting-contact">
										<i className="far fa-address-book" />
										<span>รายชื่อผู้ติดต่อที่บันทึกไว้</span>
									</a>
								</li>

								{accountBusiness && (
									<React.Fragment>
										<li
											className={
												this.state.urlPath === '/setting-business' && 'active'
											}
										>
											<a href="/setting-business">
												<i className="far fa-briefcase" />
												<span>
													{this.props.t('common:setting.menu.companyProfile')}
												</span>
											</a>
										</li>

										{accountBusiness.business_status === 2 && (
											<React.Fragment>
												{accountInfo.account_users[0].account_user_type ===
													1 && (
													<li
														className={
															this.state.urlPath === '/sub-account' && 'active'
														}
													>
														<a href="/sub-account">
															<i className="far fa-users" />
															<span>
																{this.props.t('common:setting.menu.subAccount')}
															</span>
														</a>
													</li>
												)}

												<li
													className={
														this.state.urlPath === '/setting-tracking' &&
														'active'
													}
												>
													<a href="/setting-tracking">
														<i className="fal fa-box-alt"></i>
														<span>จัดการหน้าติดตามงานขนส่ง</span>
													</a>
												</li>

												{isCustomerServiceType && (
													<li
														className={
															this.state.urlPath === '/reports' && 'active'
														}
													>
														<a href="/reports">
															<i className="fal fa-file"></i>
															<span>รายงาน (Report)</span>
														</a>
													</li>
												)}
											</React.Fragment>
										)}
									</React.Fragment>
								)}
							</ul>
						</MenuContainer>
					);
				}}
			</Query>
		);
	}
}

export default withNamespaces()(Menu);
