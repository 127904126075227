import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import MediaQuery from 'react-responsive';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  width: 400px;
  min-height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  background-color: #FFFFFF;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(max-width: 767px) {
    width: 300px;
  }
`;

const ContainerEmbed = styled.div`
  width: 760px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  background-color: #FFFFFF;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & object {
    width: 760px;
    height: calc(100vh - 100px);
  }
`;

const Paginate = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  line-height: 1;

  & i {
    padding-top: 4px;
  }

  & div:not(:first-child), & div:not(:last-child) {
    margin: 0px 10px;
  }
`;

class PDFPreview extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  onNextPage() {
    this.setState({ pageNumber: this.state.pageNumber < this.state.numPages ? (this.state.pageNumber + 1) : this.state.pageNumber });
  }

  onPreviousPage() {
    this.setState({ pageNumber: this.state.pageNumber > 1 ? (this.state.pageNumber - 1) : this.state.pageNumber })
  }

  render() {
    const { pageNumber, numPages } = this.state;
    const isMobile = {
      Android: function() {
          return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
          return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
          return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };

    return (
      <div>
        {
          isMobile.any() ? (
            <Container>
              <Document
                  file={`https://cors-anywhere.herokuapp.com/${this.props.file}`}
                  onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <MediaQuery minWidth={768}>
                  <Page width={400} pageNumber={pageNumber} />
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                  <Page width={300} pageNumber={pageNumber} />
                </MediaQuery>
              </Document>

              {
                numPages && (
                  <Paginate>
                    <div onClick={() => this.onPreviousPage()}><i className="far fa-caret-circle-left"></i></div>
                    <div> Page {pageNumber} of {numPages} </div>
                    <div onClick={() => this.onNextPage()}><i className="far fa-caret-circle-right"></i></div>
                  </Paginate>
                )
              }
            </Container>
          ) : (
            <ContainerEmbed>
              <object data={`${this.props.file}`} type="application/pdf">
                <embed src={`${this.props.file}`} type="application/pdf" height="600" alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" />
              </object>
            </ContainerEmbed>
          )
        }
      </div>
    )
  }
}

PDFPreview.propTypes = {
  file: PropTypes.string,
};

PDFPreview.default = {
  file: '',
};

export default PDFPreview;