import gql from 'graphql-tag';

export default gql`
	mutation RegisterDriverLine(
		$account_info_id: ID!
		$line_user_id: ID!
		$driver_name: String!
		$driver_phone_code: String!
    $driver_phone_number: String!
    $driver_enable: Int!
    $user_setting_truck_license_plate: String!
    $driver_status: Boolean
    $setting_truck_service: [ID]
	) {
		registerDriverLine(
			account_info_id: $account_info_id
      line_user_id: $line_user_id
      driver_name: $driver_name
      driver_phone_code: $driver_phone_code
      driver_phone_number: $driver_phone_number
      driver_enable: $driver_enable
      user_setting_truck_license_plate: $user_setting_truck_license_plate
      driver_status: $driver_status
      setting_truck_service : $setting_truck_service
		) {
			_id
      exist
		}
	}
`;
