import React, { Component } from 'react';
import styled from 'styled-components';
import { List, Header, LabelRank } from './components';
import { ModalConfirm } from 'src/components';
import { withNamespaces } from 'react-i18next';
import ReactParser from 'react-html-parser';
import challengeDistanceQuery from './graphql/query/challengeDistance';
import challengeListQuery from './graphql/query/challengeList';
import moment from 'moment';
import 'moment-duration-format';
import client from 'src/config/client';
import formatMoney from 'src/app/utils/formatMoney';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	right: 0px;
	top: 70%;
	z-index: 1;
	transform: translateY(-50%);
	transition: 0.5s;

	${props =>
		!props.isOpen &&
		`
    right:-220px;
	`}
	
	@media(max-width: 767px) {
		top: 56%;
	}
`;

const ButtonFlag = styled.div`
	width: 60px;
	height: 60px;
	background: rgb(217, 1, 1);
	background: linear-gradient(rgba(217, 1, 1, 1) 0%, rgba(168, 1, 1, 1) 100%);
	border-radius: 10px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: -6px 6px 20px rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-size: 24px;
	margin-top: 20px;
	cursor: pointer;
`;

const BoxContent = styled.div`
	background-color: #ffffff;
	width: 220px;
	min-height: 300px;
	border-radius: 6px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: -6px 6px 20px rgba(0, 0, 0, 0.16);
	padding: 15px 20px;

	& .body {
		min-height: 170px;
	}
`;

const ListStyle = styled(List)`
	&:not(:last-child) {
		border-bottom: 1px solid #e3e3e3;
	}

	&:last-child {
		margin-bottom: 20px;
	}
`;

const RankContainer = styled.div`
	display: flex;
	flex-direction: row;

	& div:first-child {
		flex: 1;
	}
`;

const Button = styled.a`
	font-size: 16px;
	border: 1px solid #e3e3e3;
	border-radius: 6px;
	width: 100%;
	padding: 8px;
	background-color: #ffffff;
	outline: none;
	display: block;
	text-align: center;
	color: #000000;
	text-decoration: none !important;

	&:hover,
	&:active,
	&:focus {
		background-color: #fafafa;
		color: #000000;
	}
`;

const InfoContainer = styled.div`
	line-height: 1;
`;

const Loading = styled.div`
	display: flex;
	align-items: center;
	color: #939393;
	justify-content: center;
	min-height: 170px;
`;

class DistanceChallenge extends Component {
	state = {
		isOpen: false,
		isOpenModalInfo: false,
		isLoading: false,
		isError: false,
		distance: 0,
		rank: 0,
		rankOld: 0,
		endDate: 0,
		countDown: '00:00:00',
	};

	componentDidMount() {
		this.setState({ isLoading: true, });
		setTimeout(() => {
			this.fetchDataChallengeDistance();
		}, 1000)
	}

	setDefaultData = () => {
		this.setState({
			distance: 0,
			rank: 0,
			rankOld: 0,
			endDate: 0,
		});
	}

	fetchDataChallengeDistance = async () => {
		try {
			this.setState({ 
				isLoading: true,
				isError: false
			});

			const { data: dataChallengeList } = await client.query({
				query: challengeListQuery,
				variables: {
					start_date_lte: moment().format('YYYY-MM-DD'),
					end_date_gte: moment().format('YYYY-MM-DD'),
					show_data: 1
				},
				fetchPolicy: 'network-only',
			});

			// console.log(dataChallengeList)

			const { challengeList } = dataChallengeList;

			if(!challengeList){
				return
			}

			// console.log('challengeList', challengeList)

			const { challenge } = challengeList;

			if(!(challenge.length > 0)){
				return
			}

			// console.log('challenge', challenge)

			const {
				distance,
				rank,
				rank_old,
			} = challenge[0].account_challenge;

			// const { data } = await client.query({
			// 	query: challengeDistanceQuery,
			// 	fetchPolicy: 'network-only',
			// });

			// const {
			// 	accountInfoList,
			// 	challengeList,
			// } = data;

			// const {
			// 	account_business
			// } = accountInfoList;

			// if(!account_business) {
			// 	return;
			// }

			// const {
			// 	distance,
			// 	rank,
			// 	rank_old,
			// } = account_business.challenge_distance;

			this.setState({
				distance: distance,
				rank: rank,
				rankOld: rank_old,
				endDate: challenge[0].end_date,
			});

			this.setState({ 
				isLoading: false,
			}, () => {
				this.startTimer();
			});
		} catch(error) {
			console.log(error);
			this.setState({ 
				isLoading: false,
				isError: true,
			});
		}
	}

	startTimer = () => {
		setInterval(() => {
			this.setState({ countDown: this.getFormatTime(this.state.endDate)});
		}, 1000);
	}
	
	toggleButton = () => {
		const {
			isOpen
		} = this.state;
		this.setState({ isOpen: !isOpen });
	};

	renderIconColorRank(rank) {
		if(rank > 5){
			return 'black'
		}
		return `${rank}`

		return 'blank';
	}

	renderIconRank(rank) {
		if (rank === 1 || rank === 2 || rank === 3) {
			return 'fas fa-chess-queen';
		}

		return 'fas fa-chess-pawn';
	}

	renderChess(rank){
        switch (rank) {
            case 1:
                return "fas fa-chess-queen";
            case 2:
                return "fas fa-chess-queen";
            case 3:
                return "fas fa-chess-queen";
            case 4:
                return "fas fa-chess-queen";
            case 5:
                return "fas fa-chess-queen";
            case 6:
                return "fas fa-chess-pawn";
            case 7:
                return "fas fa-chess-pawn";
            case 8:
                return "fas fa-chess-pawn";
            case 9:
                return "fas fa-chess-pawn";
            case 10:
                return "fas fa-chess-pawn";
            default:
                return "fas fa-circle";
        }
    }

	renderRank(currentRank = 0, formRank = 0) {
		if(formRank === 0 || formRank === currentRank) {
			return (
				<LabelRank
					label={`${'-'} ${this.props.t('common:distanceChallenge.rank')}`}
					icon="fas fa-chevron-right"
					color="#B3B3B3"
				/>
			)
		}

		const subRank = Math.abs(formRank - currentRank);
		const label = subRank !== 0 ? subRank : '-';

		if (currentRank < formRank) {
			return (
				<LabelRank
					label={`${label} ${this.props.t('common:distanceChallenge.rank')}`}
					icon="fas fa-chevron-up"
					color="#01A714"
				/>
			);
		} else if (currentRank > formRank) {
			return (
				<LabelRank
					label={`${label} ${this.props.t('common:distanceChallenge.rank')}`}
					icon="fas fa-chevron-down"
					color="#D90101"
				/>
			);
		}
  }
  
  handleOpenModalInfo = () => {
    this.setState({ isOpenModalInfo: true });
	}
	
	getFormatTime = (date) => {
		const expDate = moment(date);
		const timeLeft = expDate.diff(moment(), 'second');

		if (timeLeft <= 0) {
			return '00:00:00';
		}

		return moment
			.duration(timeLeft, 'second')
			.format(`D [${this.props.t('common:distanceChallenge.day')}] HH:mm:ss`);
	}

	openHandler = () => {
		const {
			isOpen
		} = this.state;

		this.setState({isOpen: !isOpen});
	}

	isCountDown3Day = (date) => {
		const expDate = moment(date);
		const timeLeft = expDate.diff(moment(), 'second');
		return timeLeft <= 259200;
	}

	render() {
		const {
			isOpen,
			isOpenModalInfo,
			isLoading,
			isError,
			distance,
			rank,
			rankOld,
			countDown,
			endDate,
		} = this.state;
		
		return (
			<Container isOpen={isOpen}>
				<ButtonFlag onClick={this.openHandler} title="GIZTIX Distance Challenge">
					<i className="fal fa-flag-checkered" />
				</ButtonFlag>

				<BoxContent isOpen={isOpen}>
					<Header
						onPressInfo={() => this.handleOpenModalInfo()}
					/>

					<div className="body">
						{
							(isLoading) && (
								<Loading>
									<i className="fal fa-spinner-third fa-spin" />
								</Loading>
							)
						}

						{
							(isError) && (
								<Loading>
								ระบบกำลังประมวลผล
								</Loading>
							)
						}

						{
							!isLoading && !isError && (
								<React.Fragment>
									<ListStyle
										title={this.props.t('common:distanceChallenge.yourRank')}
										description={
											<RankContainer>
												<div>{rank ? rank : '-'}</div>
												{this.renderRank(rank, rankOld)}
											</RankContainer>
										}
										icon={this.renderChess(rank)}
										iconNameColor={this.renderIconColorRank(rank)}
									/>
			
									<ListStyle
										title={this.props.t('common:distanceChallenge.totalDistanceOfMonth')}
										description={`${distance ? formatMoney(distance, 0) : '0'} ${this.props.t('common:distanceChallenge.km')}`}
										icon="fal fa-map-marker-alt"
										iconNameColor="grey"
									/>
			
									<ListStyle
										title={this.props.t('common:distanceChallenge.countdown')}
										description={countDown}
										icon="fal fa-alarm-clock"
										iconNameColor="grey"
										colorDescription={this.isCountDown3Day(endDate) ? '#D90101' : null}
									/>
								</React.Fragment>
							)
						}
					</div>

					<Button
						target="_blank"
						href="https://blog.giztix.com/กลับมาอีกครั้งกับกิจกรรม-giztix-distance-challenge-ครั้งที่-2/"
					>
						{this.props.t('common:distanceChallenge.btnViewReward')} >
					</Button>
				</BoxContent>

				<ModalConfirm
					show={isOpenModalInfo}
					onPressButtonConfirm={() => this.setState({ isOpenModalInfo: false })}
					labelButtonConfirm={this.props.t('common:distanceChallenge.modalInfo.buttonConfirm')}
					titleHeader={`GIZTIX Distance Challenge #2`}
					onHide={() => this.setState({ isOpenModalInfo: false })}
				>
					<InfoContainer>
            {this.props.t('common:distanceChallenge.modalInfo.content')
              .split('\n')
              .map(item => (<div>{ReactParser(item)}</div>)
            )}
          </InfoContainer>
				</ModalConfirm>
			</Container>
		);
	}
}

export default withNamespaces()(DistanceChallenge);
