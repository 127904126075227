import React from 'react';
import classes from './Avatar.module.css';

const Avatar = (props) => {
  return (
    <div>
      {props.src && (
        <div className={classes.profileImage}>
          <img src={props.src} alt="profile" />
        </div>
      )}

      {!props.src && (
        <div className={classes.profileImage}>
          <i className="fas fa-user"></i>
        </div>
      )}
    </div>
  )
}

export default Avatar;