import React, { Component } from "react";
import {Row , Col} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardContainer = styled(Row)`
    margin:0;
    margin-bottom: 10px;
    line-height: 1;

    .box-no-border {
        display: -webkit-box;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(227, 227, 227);
    }

    .box-no-border .img {
        float: left;
        width: 60px;
        padding: 6px;
    }

    .box-no-border .img i {
        font-size: 30px;
    }

    .box-no-border .detail {
        float: left;
    }
    
    .box-no-border p {
        margin-bottom: 0;
    }
    
`;
const BoxNoBorder = (props) => (
    <CardContainer>
        <Col className="box-no-border" sm={12}>
            {props.content}
        </Col>
    </CardContainer>
);

BoxNoBorder.propTypes = {
    content: PropTypes.node,
    active:PropTypes.bool
};
  
BoxNoBorder.defaultProps = {
    content : "",
    active : false
};

export default BoxNoBorder;
