import React, { Component } from "react";
import { Row ,Breadcrumb,FormGroup,InputGroup,Button,FormControl} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from "./../../config/color";
import appIos from './../../assets/images/app-store.svg';
import playStore from './../../assets/images/play-store.svg';
import MediaQuery from 'react-responsive';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const RowContainer = styled(Row)`
    background-color: #fff;
    width: 100%;
    border-radius: 0px;
    border: none;
    position: absolute;
    top: calc(60px + ${heightTopHeader}px);
    z-index: 99;
    margin-left: 0px;
    margin-right: 0px;
    height: 50px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);

    @media(max-width: 767px) {
        position: relative;
        top: 0;
    }

    .breadcrumb>li+li:before  {
        content: "";
        padding: 0;
    }

    .breadcrumb li {
        display: inline-block;
        height: 36px;
        padding: 0 15px;
    }

    .breadcrumb li.right,
    .breadcrumb li.img  {
        padding: 0;
    }

    .breadcrumb li a {
        line-height: 35px;
        color: #000;
    }

    .breadcrumb>.active {
        color: #000;
        font-weight: bold;
        border-bottom: 2px solid #D90101;
    }

    .breadcrumb li.right a {
        line-height: 35px;
        color: #808080;
        text-decoration: none;
    }

    .breadcrumb li.img a {
        color: #808080;
        text-decoration: none;
    }

    .breadcrumb li.img  img{
        height: 35px;
        float: left;
        padding-left: 10px;
    }
`;

const BreadcrumbContainer = styled(Breadcrumb)`
    padding: 7px 20px 0 0;
    background-color: transparent;
    margin-bottom: 0;

    @media(max-width: 767px) {
        padding: 7px 10px;
    }
`;

const InputContainer = styled.div`
    width: 290px;
    float: left;

    @media(max-width: 767px) {
        float: none;
        width: 100%;
    }
`;

const ButtonSearch = styled(Button)`
    width: 90px;
`;

const ItemRight = styled(Breadcrumb.Item)`
    float: right;
`;

const BackBtn = styled.a`
    float: left;
    padding-right: 10px;
    border-right: 1px solid #e3e3e3;
    margin-right: 10px;    
    margin-top: 8px;
    margin-left: 20px;
    color: ${color.Black};
    cursor: pointer;
`;

class TrackingHeader extends Component {
    state = {
        value : this.props.valueTracking || ''
    };

    handleChange(e){
        this.setState({ value: e.target.value });
    }

    searchTracking(){
        const {
            trackingUrl,
        } = this.props;
        if(this.state.value !== ""){
            const path = `/trackingdetail${!!trackingUrl ? `/${trackingUrl}` : ''}?shipment=${this.state.value}`;
            window.location = path;
        }
    }

    render() {
        return (
            <RowContainer>
                <BreadcrumbContainer className="container">
                    <MediaQuery minWidth={768}>
                        { this.props.backLink ? <BackBtn href={this.props.backLink}> <i className="fal fa-arrow-left" style={{color:"#D90101",marginRight:"10px"}}></i> กลับ</BackBtn> : null}
                    </MediaQuery>

                    <InputContainer>
                        <FormGroup>
                            <InputGroup>
                            <FormControl 
                                type="text"
                                value={this.state.value}
                                placeholder="รหัสงานขนส่ง"
                                onChange={event =>
                                    this.handleChange(event)
                                }

                                onKeyPress={(event) => {
                                    if(event.charCode === 13) {
                                        this.searchTracking(event);
                                    }
                                }}
                            />
                            <InputGroup.Button>
                                <ButtonSearch bsStyle="primary" onClick={() => this.searchTracking()}>ติดตาม</ButtonSearch>
                            </InputGroup.Button>
                            </InputGroup>
                        </FormGroup>
                    </InputContainer>
                    
                    <MediaQuery minWidth={768}>
                        <ItemRight className="img" href="https://itunes.apple.com/app/id1439755826" target="_blank"> <img src={appIos} className="logo" alt="logo" /></ItemRight>
                        <ItemRight className="img" href="https://play.google.com/store/apps/details?id=com.giztix.app" target="_blank"><img src={playStore} className="logo" alt="logo" /></ItemRight>
                        <ItemRight className="right">Download Our App</ItemRight>
                    </MediaQuery>
                </BreadcrumbContainer>
            </RowContainer>
        )
    }
};

TrackingHeader.propTypes = {
    menu: PropTypes.array,
};
  
TrackingHeader.defaultProps = {
    menu: [],
};

export default TrackingHeader;
