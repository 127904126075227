import React from 'react';
import styled from 'styled-components';
import { Input, Button } from 'antd';
import { Query, Mutation } from 'react-apollo';
import { geolocated } from 'react-geolocated';
import getNameFromlatlngQuery from './graphql/query/getNameFromlatlng';
import mapSearchQuery from './graphql/query/mapSearch';
import textMapSearchQuery from './graphql/query/textMapSearch';
import getLatLngMutation from './graphql/mutation/getLatLng';
import { Skeleton } from 'src/components';
import {
	ButtonBack,
	Header,
	Blank,
	ButtonIcon,
	LocationList,
} from '../../components';
import { ContactAddressContext } from '../../../../contexts/ContactAddressContext';
import client from 'src/config/client';

const HeaderStyle = styled(Header)`
	margin-bottom: 10px;
`;

const BlankStyle = styled(Blank)`
	margin-bottom: 20px;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 5px;
`;

const ButtonBackStyle = styled(ButtonBack)`
	margin-bottom: 10px;
`;

const MoreSearchContainer = styled.div`
	text-align: center;
	margin-top: 20px;

	& .text-label {
		font-size: 18px;
	}
`;

class SearchLocation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			locationSearch: '',
			showTextSearch: false,
		};
	}

	static contextType = ContactAddressContext;

	backHandler = () => {
		this.context.onCloseAllAndOpen(['stepContactDetail']);
	};

	changeLocationSearch = event => {
		this.setState({ locationSearch: event.target.value });
	};

	getCurrentLocation = async () => {
		try {
			if (this.props.coords) {
				const { latitude, longitude } = this.props.coords;
				const { data } = await client.query({
					query: getNameFromlatlngQuery,
					variables: {
						latitude: latitude,
						longitude: longitude,
					},
				});

				const { address } = data.getNameFromlatlng;
				this.context.setData({
					tempLat: latitude,
					tempLng: longitude,
					tempAddress: address,
					fitBounds: true,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	selectAddress(item, getLatLng) {
		this.setState({
			focus: false,
			numAddress: 0,
		});

		if (item.lat && item.lng) {
			this.context.setData({
				tempLat: Number(item.lat),
				tempLng: Number(item.lng),
				tempAddress: item.description,
				fitBounds: true,
			});
		} else {
			getLatLng({
				variables: {
					address: item.description,
				},
			});
		}
	}

	render() {
		let result = null;

		if (this.state.locationSearch) {
			result = (
				<React.Fragment>
					<BlankStyle />
					<HeaderStyle title="ผลลัพธ์การค้นหา" />

					<Query
						query={mapSearchQuery}
						variables={{
							input: this.state.locationSearch,
						}}
						fetchPolicy="cache-and-network"
					>
						{({ loading, error, data }) => {
							if (loading)
								return (
									<Skeleton height={180}>
										<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
										<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
										<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
										<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
										<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
										<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
									</Skeleton>
								);
							if (error) return error.graphQLErrors[0].message;

							//ค้นหาสถานที่เพิ่มเติม
							if (this.state.showTextSearch) {
								return (
									<Query
										query={textMapSearchQuery}
										variables={{
											input: this.state.locationSearch,
										}}
										fetchPolicy="cache-and-network"
									>
										{({ loading, error, data }) => {
											if (loading)
												return (
													<Skeleton height={180}>
														<rect
															x="0"
															y="0"
															rx="4"
															ry="4"
															width="300"
															height="13"
														/>
														<rect
															x="0"
															y="20"
															rx="3"
															ry="3"
															width="250"
															height="10"
														/>
														<rect
															x="0"
															y="60"
															rx="4"
															ry="4"
															width="300"
															height="13"
														/>
														<rect
															x="0"
															y="80"
															rx="3"
															ry="3"
															width="250"
															height="10"
														/>
														<rect
															x="0"
															y="120"
															rx="4"
															ry="4"
															width="300"
															height="13"
														/>
														<rect
															x="0"
															y="140"
															rx="3"
															ry="3"
															width="250"
															height="10"
														/>
													</Skeleton>
												);
											if (error) return error.graphQLErrors[0].message;

											if (
												data.textMapSearch === null ||
												data.textMapSearch.length === 0
											)
												return (
													<div
														style={{
															textAlign: 'center',
															marginTop: 20,
															fontSize: 18,
														}}
													>
														ไม่พบข้อมูล
													</div>
												);

											return data.textMapSearch.map((item, index) => (
												<Mutation
													key={index}
													mutation={getLatLngMutation}
													update={(cache, { data }) => {
														this.context.setData({
															tempLat: Number(data.getLatlng.lat),
															tempLng: Number(data.getLatlng.lng),
															tempAddress: item.description,
															fitBounds: true,
														});
													}}
												>
													{getLatLng => {
														return (
															<LocationList
																locationTitle={
																	item.structured_formatting.main_text
																}
																locationAddress={
																	item.structured_formatting.secondary_text
																}
																onPressSelect={() =>
																	this.selectAddress(item, getLatLng)
																}
															/>
														);
													}}
												</Mutation>
											));
										}}
									</Query>
								);
							}

							//ค้นหาสถานที่ปกติ
							return (
								<div>
									{data.mapSearch.map((item, index) => (
										<Mutation
											key={index}
											mutation={getLatLngMutation}
											update={(cache, { data }) => {
												this.context.setData({
													tempLat: Number(data.getLatlng.lat),
													tempLng: Number(data.getLatlng.lng),
													tempAddress: item.description,
													fitBounds: true,
												});
											}}
										>
											{getLatLng => {
												return (
													<div>
														<LocationList
															locationTitle={
																item.structured_formatting.main_text
															}
															locationAddress={
																item.structured_formatting.secondary_text
															}
															onPressSelect={() =>
																this.selectAddress(item, getLatLng)
															}
														/>
													</div>
												);
											}}
										</Mutation>
									))}

									{data.mapSearch.length === 0 && (
										<MoreSearchContainer>
											<div className="text-label">หากไม่พบสถานที่ต้องการ?</div>
											<div>
												<Button
													style={{ marginTop: 10 }}
													block
													onClick={() =>
														this.setState({ showTextSearch: true })
													}
												>
													ค้นหาสถานที่เพิ่มเติม
												</Button>
											</div>
										</MoreSearchContainer>
									)}
								</div>
							);
						}}
					</Query>
				</React.Fragment>
			);
		}

		return (
			<div>
				<ButtonBackStyle clicked={this.backHandler} />
				<Input.Search
					placeholder="ค้นหาสถานที่"
					value={this.state.locationSearch}
					onChange={this.changeLocationSearch}
				/>

				<ButtonIcon
					icon="fal fa-crosshairs"
					label="ใช้ตำแหน่งปัจจุบัน"
					onClick={this.getCurrentLocation}
				/>

				{result}
			</div>
		);
	}
}

export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	watchPosition: true,
	geolocationProvider: navigator.geolocation,
})(SearchLocation);
