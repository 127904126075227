import React from 'react';
//Library
import styled from 'styled-components';
import {
	Modal,
	FormControl,
	FormGroup,
	InputGroup,
	DropdownButton,
	MenuItem,
	Button,
	HelpBlock,
} from 'react-bootstrap';
import { compose, graphql, Mutation, Query } from 'react-apollo';
import moment from 'moment';
//Component
import { CardHeader, ButtonLink, Input } from '../../components';
//GraphQL Server
import registerMutation from './graphql/mutation/register';
import otpGeneratePhoneMutation from './graphql/mutation/otpGeneratePhone';
import getOTPMutation from './graphql/mutation/getOTP';
import usernameAvailableMutation from './graphql/mutation/usernameAvailable';
import phoneNumberAvailableMutation from './graphql/mutation/phoneNumberAvailable';
//GraphQL Client
import updateModalRegisterMutationClient from '../../config/graphqlClient/mutation/updateModalRegister';
import updateModalLoginMutationClient from '../../config/graphqlClient/mutation/updateModalLogin';
import modalRegisterQueryClient from '../../config/graphqlClient/query/modalRegister';
import updateRegisterMutationClient from '../../config/graphqlClient/mutation/updateRegister';
import userQueryClient from '../../config/graphqlClient/query/user';
import shipmentConfirmStatusQueryClient from '../../config/graphqlClient/query/shipmentConfirmStatus';
import landingUrl from '../../config/landingUrl';

import color from '../../config/color';
//Img
import img from './../../assets/images/complete.png';
//Render Props
import { ShipmentConfirm } from '../../renderProps';

import client from '../../config/client';
import { withNamespaces } from 'react-i18next';
import urijs from 'urijs';
import { Formik } from 'formik';
import * as Yup from 'yup';

const Container = styled.div`
	margin-top: 32px;

	.btn-register {
		height: 40px;
		margin-top: 30px;
	}
	
	.btn-register-complete {
		height: 40px;
		margin-top: 30px;
	}
`;

const ModalContainer = styled(Modal)`
	.modal-dialog {
		margin-top: 120px;
		width: 380px;
	}

	@media(max-width: 767px) {
		.modal-dialog {
			width: calc(100% - 20px);
			margin-top: 40px;
		}
	}
`;

const InputOTPContainer = styled.div`
	margin-top: 20px;
	text-align: center;
	margin-bottom: 20px;
`;

const InputOTP = styled.input`
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	text-align: center;
	width: 48px;
	height: 56px;
	margin-right: 18px;
	font-size: 30px;
`;

const OTPRefContainer = styled.div`
	margin-top: 40px;
	text-align: center;
	color: #808080;
`;

const MessageContainer = styled.div`
	text-align: center;
	color: ${props => props.color || '#b3b3b3'};
	margin-bottom: 5px;
	line-height: 1;

	& i {
		font-size: 16px;
	}
`;

const Title = styled.div`
	text-align: center;
	font-size: 28px;
	color: #000000;
	font-weight: 700;
`;

const SubTitle = styled.div`
	text-align: center;
	font-size: 18px;
	color: #808080;
`;

const Img = styled.img`
	width: 160px;
`;

const ButtonTypeUser = styled.div`
	color: #000000;
	padding: 20px;
	font-size: 20px;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.13);
	border-radius: 6px;
	cursor: pointer;
	background-color: #FFFFFF;

	& i {
		color: ${color.Primary}
		margin-right: 20px;
		font-size: 25px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.02);
	}
`;

const IconRightInput = styled.i`
	display: inline-block;
	position: absolute;
	font-size: 16px;
	color: ${props => props.color || '#b3b3b3'};
	right: 10px;
	top: 10px;
	z-index: 10; //ให้อยู่บนสุดเสมอ
`;

const TermContainer = styled.div`
	color: #808080;
	text-align: center;
	margin-top: 20px;

	& a {
		color: #1cb5e0;
	}
`;

class ModalRegister extends React.Component {
	constructor(props) {
		super(props);

		this._inputOTP1 = React.createRef();
		this._inputOTP2 = React.createRef();
		this._inputOTP3 = React.createRef();
		this._inputOTP4 = React.createRef();
	}

	state = {
		account_user_name: '',
		account_user_password: '',
		account_user_phone_code: '66',
		account_user_phone_number: '',
		account_info_business_type_th: '',
		account_info_tax_id: '',
		stepPage: 1,
		otp1: '',
		otp2: '',
		otp3: '',
		otp4: '',
		ref: '',
		error_message: '',
		time: '05:00',
		otpValid: '',
		otpInvalid: '',
		userType: '', //B (Business) : ลูกค้าองค์กร/ธุรกิจ P (Person) : ลูกค้าทั่วไป
		emailValid: false,
		emailInvalid: false,
		passwordValidate: null,
		phoneNumberValidate: null,
		phoneNumberMessageError: '',
		taxIdValidate: null,
		emailMessageError: '',
		hidePassword: true,
		inviteCode: '',
		isFromRef: false,
	};

	componentDidMount() {
		const uriInviteCode = urijs(window.location.href).query(true).driver_invite_code;
		this.setState({ 
			inviteCode: uriInviteCode ? uriInviteCode : '' ,
			isFromRef: uriInviteCode ? true : false,
		})
	}

	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	//กดพื้นที่นอก Modal ทำให้ Modal ถูกปิด และรีเซ็ตค่าฟอร์มทั้งหมด
	handleClose(updateModalRegister,updateModalLogin){
		updateModalRegister({ variables: { isOpen: false } }).then(() => {
			updateModalLogin({ variables: { isOpen: false } }).then(() => {				
				if(window.location.pathname === "/register-business"){
					setTimeout(() => {window.location.reload();}, 200);
				}
				setTimeout(() => {
					this.setState({
						account_user_name: '',
						account_user_password: '',
						account_user_phone_code: '66',
						account_user_phone_number: '',
						account_info_business_type_th: '',
						account_info_tax_id: '',
						stepPage: 1,
						otp1: '',
						otp2: '',
						otp3: '',
						otp4: '',
						ref: '',
						error_message: '',
						time: '05:00',
						otpValid: '',
						otpInvalid: '',
						userType: '',
						emailValid: false,
						emailInvalid: false,
						passwordValidate: null,
						phoneNumberValidate: null,
						phoneNumberMessageError: '',
						taxIdValidate: null,
						emailMessageError: '',
						hidePassword: true,
					}, () => {
						client.cache.writeData({
							data: {
								shipmentConfirmStatus: {
									isConfirm: false,
									__typename: 'ShipmentConfirmStatus',
								}
							}
						});
					});
				}, 500);
			});
		});
	};

	//เมื่อกรอกอีเมล
	handleChangeEmail = (event, usernameAvailable) => {
		clearTimeout(this._changeEmailTimeout);
		const email = event.target.value;
		this.setState(
			{
				account_user_name: email,
				emailInvalid: true,
				emailValid: false,
				emailMessageError: '',
			},
			() => {
				if (this.checkEmail(this.state.account_user_name)) {
					this._changeEmailTimeout = setTimeout(() => {
						usernameAvailable();
					}, 500);
				} else {
					this.setState({ emailMessageError: this.props.t('common:register.register.inputEmail.validate.format') })
				}
			},
		);
	};

	handleChangePhoneNumber(phoneNumber, phoneNumberAvailable) {
		clearTimeout(this._changePhoneNumberTimeout);
		this.setState(
			{
				account_user_phone_number: phoneNumber,
				phoneNumberValidate: false,
			},
			() => {
				if (this.checkPhoneNumber(this.state.account_user_phone_number)) {
					this._changePhoneNumberTimeout = setTimeout(() => {
						phoneNumberAvailable();
					}, 500);
				} else {
					this.setState({
						phoneNumberMessageError: this.props.t('common:register.register.inputPhoneNumber.validate.format'),
						phoneNumberValidate: false,
					})
				}
			},
		);
	};

	//เลือก phone code
	handleSelectPhoneCode = account_user_phone_code => {
		this.setState({ account_user_phone_code });
	};

	//ป้อนหมายเลข OTP
	handleChangeOTP = (index, event, getOTP) => {
		const value = event.target.value;
		this.setState(
			{
				[`otp${index}`]: value,
			},
			() => {
				if (
					this.state.otp1 &&
					this.state.otp2 &&
					this.state.otp3 &&
					this.state.otp4
				) {
					getOTP();
				} else {
					if (value) {
						if (index === 1) {
							this._inputOTP2.current.focus();
						} else if (index === 2) {
							this._inputOTP3.current.focus();
						} else if (index === 3) {
							this._inputOTP4.current.focus();
						}
					} else {
						if (index === 2) {
							this._inputOTP1.current.focus();
						} else if (index === 3) {
							this._inputOTP2.current.focus();
						} else if (index === 4) {
							this._inputOTP3.current.focus();
						}
					}
				}
			},
		);
	};

	//รีเซ็ตเวลา OTP
	durationTime = () => {
		clearTimeout(this.countDown);
		const eventTime = moment()
			.add(5, 'minutes')
			.unix();
		const currentTime = moment().unix();
		const diffTime = eventTime - currentTime;
		let duration = moment.duration(diffTime * 1000);
		const interval = 1000;

		this.countDown = setInterval(() => {
			duration = moment.duration(duration - interval);
			const time = `${('0' + duration.minutes()).slice(-2)}:${(
				'0' + duration.seconds()
			).slice(-2)}`;

			this.setState({
				time,
			});

			if (duration.as('milliseconds') === 0) {
				clearTimeout(this.countDown);
			}
		}, interval);
	};

	//Check format email
	checkEmail = email => {
		const validateEmail = /^(([a-zA-Z0-9]+([\_\.\-][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
		if (validateEmail.test(email)) {
			return true;
		}

		return false;
	};

	//Check format password
	checkPassword = password => {
		if (password.length >= 6) {
			//รหัสผ่านต้องมีอย่างน้อย 6 ตัว
			return true;
		}

		return false;
	};

	//Check format phone number
	checkPhoneNumber = phoneNumber => {
		const validatePhoneNumber = /^[0]{1}([\d]{9})(\.9116)?$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
		if (validatePhoneNumber.test(phoneNumber)) {
			return true;
		}

		return false;
	};

	//หมายเลขประจำตัวผู้เสียภาษี
	checkTaxID = taxID => {
		const validateTaxID = /^[0-9]{10}(?:[0-9]{3})?$/; //ต้องเป็นตัวเลขอย่างน้อย 10 หรือ 13 ตัว
		if (validateTaxID.test(taxID)) {
			return true;
		}

		return false;
	};

	onSubmitRegister(e,updateRegister,otpGenerate){
		if(e.type == "click" || (e.type == "keypress" && e.charCode == 13) && this.checkPassword(
			this.state.account_user_password,
		) &&
		this.checkPhoneNumber(
			this.state.account_user_phone_number,
		) &&
		this.checkEmail(
			this.state.account_user_name,
		) && !this.state.emailInvalid){
			updateRegister().then(() => {
				otpGenerate();
			});
		}
	}

	render() {
		const RegisterSchema = Yup.object().shape({
			email: Yup.string()
				.matches(/^(([a-zA-Z0-9]+([\_\.\-][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, 'รูปแบบอีเมลไม่ถูกต้อง')
				.required('กรุณากรอกข้อมูล'),
			password: Yup.string()
        .min(6, 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวขึ้นไป')
				.required('กรุณากรอกข้อมูล'),
			phoneCode: Yup.string()
        .required('กรุณากรอกข้อมูล'),
      phoneNumber: Yup.string()
        .matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง')
        .required('กรุณากรอกข้อมูล'),
		});

		return (
			<Mutation mutation={updateModalRegisterMutationClient}>
				{updateModalRegister => {
					return (
						<Mutation
							mutation={updateModalLoginMutationClient}
							variables={{ isOpen: true }}
						>
							{
								(updateModalLogin) => {
									return (
										<ModalContainer
											show={this.props.show}
											onHide={() => this.handleClose(updateModalRegister,updateModalLogin)}
										>
											<Modal.Body style={{ padding: 30 }}>
												{/* หน้าเลือกประเภทผู้ใช้ */}
												{this.state.stepPage === 1 ? (
													<div>
														<Query query={modalRegisterQueryClient}>
															{
																({ data }) => {
																	return (
																		<Mutation
																			mutation={updateModalLoginMutationClient}
																			variables={{ isOpen: true }}
																		>
																			{
																				(updateModalLogin) => {
																					return (
																						<CardHeader
																							title={this.props.t('common:register.selectUserType.title')}
																							subTitle={this.props.t('common:register.selectUserType.subTitle')}
																							symbol
																							arrowBack={data.modalRegister.isBack}
																							onClickArrowBack={() => {
																								updateModalRegister({
																									variables: { isOpen: false },
																								});
																								setTimeout(() => {
																									updateModalLogin({
																										variables: { isOpen: true },
																									});
																								}, 200);
																							}}
																						/>
																					)
																				}
																			}
																		</Mutation>
																	)
																}
															}
														</Query>

														<ButtonTypeUser
															style={{ marginTop: 20, marginBottom: 15 }}
															onClick={() => {
																this.setState({
																	stepPage: 2,
																	account_info_business_type_th: 'ลูกค้าองค์กร/ธุรกิจ',
																	userType: 'B',
																	account_user_name: '',
																	account_user_password: '',
																	account_user_phone_number: '',
																	account_info_tax_id: '',
																	emailValid: false,
																	emailInvalid: false,
																	passwordValidate: null,
																	phoneNumberValidate: null,
																	phoneNumberMessageError: '',
																	taxIdValidate: null,
																	emailMessageError: '',
																	hidePassword: true,
																});
															}}
														>
															<i className="fal fa-users" /> {this.props.t('common:register.userType.business')}
														</ButtonTypeUser>
														<ButtonTypeUser
															style={{ marginBottom: 150 }}
															onClick={() => {
																this.setState({
																	stepPage: 2,
																	account_info_business_type_th: 'ลูกค้าบุคคลทั่วไป',
																	userType: 'P',
																	account_user_name: '',
																	account_user_password: '',
																	account_user_phone_number: '',
																	account_info_tax_id: '',
																	emailValid: false,
																	emailInvalid: false,
																	passwordValidate: null,
																	phoneNumberValidate: null,
																	phoneNumberMessageError: '',
																	taxIdValidate: null,
																	emailMessageError: '',
																	hidePassword: true,
																});
															}}
														>
															<i className="fal fa-user" /> {this.props.t('common:register.userType.customer')}
														</ButtonTypeUser>
													</div>
												) : null}

												{/* หน้าสมัคร */}
												{this.state.stepPage === 2 ? (
													<Mutation
														mutation={updateRegisterMutationClient}
														variables={{
															email: this.state.account_user_name,
															password: this.state.account_user_password,
															phoneCode: this.state.account_user_phone_code,
															phoneNumber: this.state.account_user_phone_number,
															taxID: null,
														}}
														update={cache => { }}
													>
														{updateRegister => {
															return (
																<Mutation
																	mutation={otpGeneratePhoneMutation}
																	variables={{
																		phone:
																			this.state.account_user_phone_code +
																			this.state.account_user_phone_number.replace(/^[0]{1}([\d]{9})(\.9116)?$/g, '$1'),
																	}}
																	update={(cache, { data }) => {
																		const ref = data.otpGeneratePhone.ref;
																		this.setState({
																			ref: ref,
																			stepPage: 4,
																			otp1: '',
																			otp2: '',
																			otp3: '',
																			otp4: '',
																			otpValid: '',
																			otpInvalid: '',
																		});

																		this.durationTime();
																		setTimeout(() => {
																			this._inputOTP1.current.focus();
																		}, 100);
																	}}
																>
																	{otpGenerate => {
																		return (
																			<div>
																				<CardHeader
																					title={this.props.t('common:register.register.title')}
																					subTitle={this.props.t('common:register.register.subTitle')}
																					symbol
																					arrowBack
																					onClickArrowBack={() => {
																						this.setState({ stepPage: 1 });
																					}}
																				/>
																				<Container>
																					{/* ตรวจสอบอีเมลว่ามีอีเมลอยู่ในระบบแล้วหรือไม่ ถ้าไม่มีสามารถใช้สมัครสมาชิกได้ */}
																					<Mutation
																						mutation={usernameAvailableMutation}
																						variables={{ email: this.state.account_user_name }}
																						update={(
																							cache,
																							{
																								data: {
																									usernameAvailable: { available },
																								},
																							},
																						) => {
																							if (available) {
																								this.setState({
																									emailValid: true,
																									emailInvalid: false,
																									emailMessageError: '',
																								});
																							} else {
																								this.setState({
																									emailValid: false,
																									emailInvalid: true,
																									emailMessageError: this.props.t('common:register.register.inputEmail.validate.used'),
																								});
																							}
																						}}
																					>
																						{usernameAvailable => {
																							return (
																								<Input
																									type="text"
																									placeholder={this.props.t('common:register.register.inputEmail.placeholder')}
																									iconLeft="fas fa-envelope"
																									iconLeftColor={
																										this.state.emailValid ? color.Primary : null
																									}
																									status={this.state.emailValid === true ? 'valid' : this.state.emailInvalid === true ? 'invalid' : ''}
																									value={this.state.account_user_name}
																									onChange={event => {
																										this.handleChangeEmail(
																											event,
																											usernameAvailable,
																										);
																									}}
																									name="email"
																									autoFocus
																								/>
																							);
																						}}
																					</Mutation>

																					{this.state.emailMessageError ? (
																						<HelpBlock
																							style={{
																								marginBottom: 0,
																								color: `${color.SoftGray}`,
																							}}
																						>
																							{this.state.emailMessageError}
																						</HelpBlock>
																					) : null}

																					<Input
																						style={{ marginTop: 20 }}
																						iconLeft="fas fa-key"
																						iconRight={this.state.hidePassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
																						iconLeftColor={this.state.passwordValidate ? color.Primary : null}
																						placeholder={this.props.t('common:register.register.inputPassword.placeholder')}
																						onPressIconRight={() =>
																							this.setState(prev => ({
																								hidePassword: !prev.hidePassword,
																							}))
																						}
																						status={this.state.passwordValidate === true ? 'valid' : this.state.passwordValidate === false ? 'invalid' : ''}
																						value={this.state.account_user_password}
																						onChange={event => {
																							if (this.checkPassword(event.target.value)) {
																								this.setState({
																									account_user_password: event.target.value,
																									passwordValidate: true,
																								});
																							} else {
																								this.setState({
																									account_user_password: event.target.value,
																									passwordValidate: false,
																								});
																							}
																						}}
																						type={this.state.hidePassword ? "password" : "text"}
																						name="password"
																					/>

																					<Mutation
																						mutation={phoneNumberAvailableMutation}
																						variables={{ 
																							phone_code: this.state.account_user_phone_code,
																							phone_number: this.state.account_user_phone_number.replace(/^[0]{1}([\d]{9})(\.9116)?$/g, '$1'),
																						}}
																						update={(
																							cache,
																							{
																								data: {
																									phoneNumberAvailable: { available },
																								},
																							},
																						) => {
																							if (available) {
																								this.setState({
																									phoneNumberValidate: true,
																									phoneNumberMessageError: '',
																								});
																							} else {
																								const re = /^[0]{1}(\d{9})(\.9116)?$/g;
																								const checkPhoneNumberDemo = this.state.account_user_phone_number.replace(re, '$2');

																								this.setState({
																									phoneNumberValidate: checkPhoneNumberDemo ? true : false,
																									phoneNumberMessageError: checkPhoneNumberDemo ? '' : this.props.t('common:register.register.inputPhoneNumber.validate.used'),
																								});
																							}
																						}}
																					>
																						{phoneNumberAvailable => {
																							return (
																								<FormGroup style={{ marginTop: 20 }}>
																									<InputGroup>
																										<DropdownButton
																											componentClass={InputGroup.Button}
																											id="input-dropdown-addon"
																											title={'+' + this.state.account_user_phone_code}
																										>
																											<MenuItem
																												eventKey="66"
																												onSelect={event =>
																													this.handleSelectPhoneCode(event)
																												}
																											>
																												+66
																											</MenuItem>
																										</DropdownButton>

																										{this.state.phoneNumberValidate === true ? (
																											<IconRightInput
																												className="fas fa-check-circle"
																												color={color.Primary}
																											/>
																										) : this.state.phoneNumberValidate === false ? (
																											<IconRightInput
																												className="fas fa-times-circle"
																												color={color.SoftGray}
																											/>
																										) : null}

																										<FormControl
																											type="text"
																											style={{ paddingRight: 38 }}
																											placeholder={this.props.t('common:register.register.inputPhoneNumber.placeholder')}
																											value={this.state.account_user_phone_number}
																											onChange={event => {
																												this.setState({ phoneNumberValidate: null });
																												this.handleChangePhoneNumber(
																													event.target.value,
																													phoneNumberAvailable,
																												);
																											}}

																											name="phoneNumber"
																										/>
																									</InputGroup>
																									{this.state.phoneNumberMessageError ? (
																										<HelpBlock
																											style={{
																												marginBottom: 0,
																												color: `${color.SoftGray}`,
																											}}
																										>
																											{this.state.phoneNumberMessageError}
																										</HelpBlock>
																									) : null}
																								</FormGroup>
																							);
																						}}
																					</Mutation>

																					{/* {this.state.userType === 'B' ? (
																						<Input
																							style={{ marginTop: 20 }}
																							type="text"
																							placeholder="เลขประจำตัวผู้เสียภาษี"
																							iconLeft="fas fa-users"
																							iconLeftColor={
																								this.state.taxIdValidate ? color.Primary : null
																							}
																							status={this.state.taxIdValidate === true ? 'valid' : this.state.taxIdValidate === false ? 'invalid' : ''}
																							value={this.state.account_info_tax_id}
																							onChange={event => {
																								if (this.checkTaxID(event.target.value)) {
																									this.setState({
																										account_info_tax_id: event.target.value,
																										taxIdValidate: true,
																									});
																								} else {
																									this.setState({
																										account_info_tax_id: event.target.value,
																										taxIdValidate: false,
																									});
																								}
																							}}
																							name="tax_id"
																						/>
																					) : null} */}

																					<Input
																						disabled={this.state.isFromRef}
																						style={{ marginTop: 20 }}
																						type="text"
																						placeholder="invite code"
																						iconLeft="fas fa-user-tag"
																						value={this.state.inviteCode}
																						onChange={event => {
																							this.setState({
																								inviteCode: event.target.value,
																							});
																						}}
																						name="inviteCode"
																					/>

																					<Button
																						className="btn-register"
																						bsStyle="primary"
																						block
																						onClick={(e) => this.onSubmitRegister(e,updateRegister,otpGenerate)}
																						disabled={
																							!this.checkPassword(
																								this.state.account_user_password,
																							) ||
																							!this.checkPhoneNumber(
																								this.state.account_user_phone_number,
																							) ||
																							!this.checkEmail(
																								this.state.account_user_name,
																							) ||
																							// (!this.checkTaxID(this.state.account_info_tax_id) && this.state.userType === 'B') ||
																							this.state.emailInvalid ||
																							!this.state.phoneNumberValidate
																						}
																					>
																						{this.props.t('common:register.register.buttonRegister')}
																					</Button>
																					<TermContainer>
																						<div>
																							<small>
																								{this.props.t('common:register.register.term.accept')} <a href={`${landingUrl}/termscondition`} target="_blank">{this.props.t('common:register.register.term.termsConditions')}</a> {this.props.t('common:register.register.term.and')} <a href={`${landingUrl}/policy`} target="_blank">{this.props.t('common:register.register.term.privacyPolicies')}</a> {this.props.t('common:register.register.term.ofGIZTIX')}
																							</small>
																						</div>
																					</TermContainer>
																				</Container>
																			</div>
																		);
																	}}
																</Mutation>
															);
														}}
													</Mutation>
												) : null}

												{/* หน้าป้อน OTP */}
												{this.state.stepPage === 4 ? (
													<div>
														<CardHeader
															title={this.props.t('common:register.confirmPhoneNumber.title')}
															subTitle={this.props.t('common:register.confirmPhoneNumber.subTitle')}
															arrowBack
															onClickArrowBack={() =>
																this.setState({
																	stepPage: 2,
																})
															}
														/>

														<OTPRefContainer>
															OTP Ref. {this.state.ref} (Time {this.state.time})
														</OTPRefContainer>

														<Mutation
															mutation={registerMutation}
															variables={{
																account_user_register_from: 1,
																account_info_business_type_th: this.state
																	.account_info_business_type_th,
																account_user_name: this.state.account_user_name,
																account_user_password: this.state.account_user_password,
																account_user_phone_code: this.state.account_user_phone_code.toString(),
																account_user_phone_number: this.state.account_user_phone_number
																	.toString()
																	.replace(/^[0]{1}([\d]{9})(\.9116)?$/g, '$1'),
																account_info_tax_id: this.state.account_info_tax_id.toString(),
																driver_inv_code: this.state.inviteCode,
																utm_source: this.getCookie("utm_source"),
																utm_medium: this.getCookie("utm_medium"),
																utm_campaign: this.getCookie("utm_campaign"),
																ip_address: this.getCookie("ip_address"),
															}}
															update={(
																cache,
																{
																	data: {
																		register: { account_token_key },
																	},
																},
															) => {
																if (account_token_key) {
																	localStorage.setItem('token', account_token_key);
																	const userData = cache.readQuery({
																		query: userQueryClient,
																	});

																	const res = this.state.account_user_name.split("@");

																	cache.writeQuery({
																		query: userQueryClient,
																		data: {
																			user: {
																				...userData.user,
																				token: account_token_key,
																				businessName: res[0]
																			},
																		},
																	});
																}
															}}
															
															refetchQueries={() => ['search', 'AccountInfoList']}
														>
															{register => {
																return (
																	<ShipmentConfirm>
																		{shipmentConfirm => (
																			<Mutation
																				mutation={getOTPMutation}
																				variables={{
																					ref: this.state.ref,
																					otp: `${this.state.otp1}${this.state.otp2}${
																						this.state.otp3
																						}${this.state.otp4}`,
																				}}
																				update={(
																					cache,
																					{
																						data: {
																							getOtp: { available },
																						},
																					},
																				) => {
																					if (available) {
																						this.setState(
																							{
																								otpValid: true,
																								otpInvalid: false,
																							},
																							() => {
																								register().then(() => {
																									if (this.props.shipmentConfirmStatusQuery.shipmentConfirmStatus.isConfirm && shipmentConfirm) {
																										shipmentConfirm();
																									}

																									setTimeout(() => {
																										this.setState({ stepPage: 5 });
																									}, 500);
																								});
																							},
																						);
																					} else {
																						this.setState(
																							{
																								otpValid: false,
																								otpInvalid: true,
																								otp1: '',
																								otp2: '',
																								otp3: '',
																								otp4: '',
																							},
																							() => {
																								this._inputOTP1.current.focus();
																							},
																						);
																					}
																				}}
																			>
																				{ getOTP => {
																					return (
																						<InputOTPContainer>
																							<InputOTP
																								ref={this._inputOTP1}
																								maxLength="1"
																								autocorrect="off"
																								value={this.state.otp1}
																								onChange={event => {
																									this.handleChangeOTP(1, event, getOTP);
																								}}
																								onFocus={() => {
																									this._inputOTP1.current.select();
																								}}
																							/>
																							<InputOTP
																								ref={this._inputOTP2}
																								maxLength="1"
																								autocorrect="off"
																								value={this.state.otp2}
																								onChange={event => {
																									this.handleChangeOTP(2, event, getOTP);
																								}}
																								onFocus={() => {
																									this._inputOTP2.current.select();
																								}}
																							/>
																							<InputOTP
																								ref={this._inputOTP3}
																								maxLength="1"
																								autocorrect="off"
																								value={this.state.otp3}
																								onChange={event => {
																									this.handleChangeOTP(3, event, getOTP);
																								}}
																								onFocus={() => {
																									this._inputOTP3.current.select();
																								}}
																							/>
																							<InputOTP
																								ref={this._inputOTP4}
																								maxLength="1"
																								autocorrect="off"
																								value={this.state.otp4}
																								onChange={event => {
																									this.handleChangeOTP(4, event, getOTP);
																								}}
																								onFocus={() => {
																									this._inputOTP4.current.select();
																								}}
																								style={{ marginRight: 0 }}
																							/>
																						</InputOTPContainer>
																					);
																				}}
																			</Mutation>
																		)}
																	</ShipmentConfirm>
																);
															}}
														</Mutation>

														{this.state.otpValid ? (
															<MessageContainer color={color.Primary}>
																<i className="fas fa-check-circle" /> {this.props.t('common:register.confirmPhoneNumber.correctOtp')}
															</MessageContainer>
														) : null}

														{this.state.otpInvalid ? (
															<div>
																<MessageContainer color={color.SoftGray}>
																	<i className="fas fa-times-circle" /> {this.props.t('common:register.confirmPhoneNumber.incorrectOtp')}
																</MessageContainer>
																<MessageContainer color={color.SoftGray}>
																	{this.props.t('common:register.confirmPhoneNumber.fillOtpAgainOrReSendOtpBySms')}
																</MessageContainer>
															</div>
														) : null}

														<div style={{ textAlign: 'center', marginTop: 100 }}>
															<Mutation
																mutation={otpGeneratePhoneMutation}
																variables={{
																	phone:
																		this.state.account_user_phone_code +
																		this.state.account_user_phone_number.replace(/^[0]{1}([\d]{9})(\.9116)?$/g, '$1'),
																}}
																update={(cache, { data }) => {
																	const ref = data.otpGeneratePhone.ref;
																	this.setState(
																		{
																			ref: ref,
																			otp1: '',
																			otp2: '',
																			otp3: '',
																			otp4: '',
																			otpValid: '',
																			otpInvalid: '',
																		},
																		() => {
																			this._inputOTP1.current.focus();
																			this.durationTime();
																		},
																	);
																}}
															>
																{otpGenerate => {
																	return (
																		<ButtonLink
																			icon="fas fa-envelope"
																			text={this.props.t('common:register.confirmPhoneNumber.resendSMS')}
																			iconColor="#8B8B8B"
																			textColor="#8B8B8B"
																			onClick={() => {
																				otpGenerate();
																			}}
																		/>
																	);
																}}
															</Mutation>
														</div>
													</div>
												) : null}

												{/* หน้าป้อน OTP */}
												{this.state.stepPage === 5 ? (
													<div style={{ textAlign: 'center' }}>
														<Img src={img} alt="register complete" />
														<Title style={{ marginTop: 40 }}>{this.props.t('common:register.registerComplete.registerSuccessfully')}</Title>
														<SubTitle>
															<div>{this.props.t('common:register.registerComplete.welcomeToGiztixOnDemandTruckApplication')}</div>
															<div>{this.props.t('common:register.registerComplete.variousTypesOfTruckServiceToChooseFrom')}</div>
														</SubTitle>

														<Mutation
															mutation={updateModalRegisterMutationClient}
															variables={{ isOpen: false }}
														>
															{updateModalRegister => {
																return (
																	<Mutation
																			mutation={updateModalLoginMutationClient}
																			variables={{ isOpen: true }}
																		>
																			{
																				(updateModalLogin) => {
																					return (
																					<Button
																						block
																						bsStyle="primary"
																						style={{ marginTop: 25 }}
																						className="btn-register-complete"
																						onClick={() => {
																							this.handleClose(updateModalRegister, updateModalLogin);
																						}}
																					>
																						{this.props.t('common:register.registerComplete.checkPriceNow')}
																					</Button>
																					)
																				}
																			}
																		</Mutation>
																	
																);
															}}
														</Mutation>
													</div>
												) : null}
											</Modal.Body>
										</ModalContainer>
									);
								}
							}
						</Mutation>
					);
				}}
			</Mutation>
		);
	}
}

export default compose(
	graphql(shipmentConfirmStatusQueryClient, {
		name: 'shipmentConfirmStatusQuery'
	}),
)(withNamespaces()(ModalRegister));
