import gql from 'graphql-tag';

export default gql`
	query InstancePriceStep {
		instancePriceStep @client {
			createShipment
			searchLocation
			contactInformation
			confirmShipment
			selectTruck
			paymentChanel
			paymentCOD
			additional
			promotion
			priceResult
			selectDateTime
			modalAdditional
			paymentCredit
			paymentCreditList
			paymentCreditEdit
			contact
			selectDateTimeContact
			selectRemark
			notificationMultiDrop
		}
	}
`;
