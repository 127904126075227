import gql from 'graphql-tag';

export default gql`
  mutation AccountUserAdd(
    $account_info_id: ID!
    $account_invite_id: ID
    $account_user_display_name: String
    $account_user_phone_code: String!
    $account_user_phone_number: String!
    $account_user_password: String!
    $account_user_display_picture: String
    $account_user_register_from: Int!
    $account_user_name: String!
    $account_user_type: Int!
  ) {
    accountUserAdd(
      account_info_id: $account_info_id
      account_invite_id: $account_invite_id
      account_user_display_name: $account_user_display_name
      account_user_phone_code: $account_user_phone_code
      account_user_phone_number: $account_user_phone_number
      account_user_password: $account_user_password
      account_user_display_picture: $account_user_display_picture
      account_user_register_from: $account_user_register_from
      account_user_name: $account_user_name
      account_user_type: $account_user_type
    ) {
      _id
    }
  }
`;