import React from 'react';
import styled from 'styled-components';
import { Statistic } from 'antd';
import moment from 'moment';

const { Countdown } = Statistic;
const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	& .right {
		flex: 1;
	}

	.ant-statistic {
		display: inline-block;
	}

	.ant-statistic-content {
		font-weight: bold;
		color: #d90101;
		font-size: 30px;
		line-height: 1;
	}
`;

const Icon = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	border: 1px solid #e7e7e7;
	margin-right: 10px;

	& i {
		font-size: 26px;
		border-bottom: 2px;
		color: rgba(0, 0, 0, 0.7);
	}
`;

const matchingTimeCountDown = props => {
	const dateTimeAddHour = moment(props.dateTime).add(1, 'hours');
	return (
		<Container>
			<Icon>
				<i className="fal fa-alarm-clock"></i>
			</Icon>
			<div className="right">
				<div>
					พนักงานขับรถ: <strong>กำลังรอมอบหมายงาน...</strong>
				</div>
				{dateTimeAddHour.diff(moment(), 'seconds') > 0 ? (
					<React.Fragment>
						<div>การันตีได้รถแน่นนอน ภายใน</div>
						<div>
							<Countdown value={dateTimeAddHour} format="mm:ss" /> นาที
						</div>
					</React.Fragment>
				) : (
					<div>ทีมงาน Giztix กำลังช่วยจัดคนขับรถให้</div>
				)}
			</div>
		</Container>
	);
};

export default matchingTimeCountDown;
