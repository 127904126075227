import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 15px 0px;
  padding-bottom: 15px;
  line-height: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const TextTitle = styled.div`
  font-size: 20px;
  flex: 1;
`;

const CheckBoxContainer = styled.div`
  font-size: 22px;

  & i {
    padding-top: 2px;
    color: ${props => props.selected ? '#19aa19;' : '#e3e3e3'};
  }
`;

const CheckboxItem = (props) => {
  return (
    <Container {...props} onClick={props.onPressSelected}>
      <TextTitle>{props.name}</TextTitle>
      <CheckBoxContainer selected={props.selected}>
        <i className={`${props.selected ? 'fas fa-check-circle' : 'fal fa-circle'}`}></i>
      </CheckBoxContainer>
    </Container>
  )
}

CheckboxItem.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.bool,
  onPressSelected: PropTypes.func,
};

CheckboxItem.defaultProps = {
  name: '',
  selected: false,
};

export default CheckboxItem;