import gql from 'graphql-tag';

export default gql`
	mutation Register(
		$account_user_name: String!
		$account_user_password: String
		$account_user_phone_code: String!
		$account_user_phone_number: String!
		$account_user_register_from: Int!
		$account_info_tax_id: String
    $account_info_business_type_th: String
		$driver_inv_code: String
		$utm_source: String
		$utm_medium: String
		$utm_campaign: String
		$ip_address: String
	) {
		register(
			account_info_type: 1
			account_user_register_from: $account_user_register_from
			account_info_register_channel: 2
			account_user_name: $account_user_name
			account_user_password: $account_user_password
			account_user_phone_code: $account_user_phone_code
			account_user_phone_number: $account_user_phone_number
			account_info_tax_id: $account_info_tax_id
			account_info_business_type: { th: $account_info_business_type_th }
			driver_inv_code: $driver_inv_code
			utm_source: $utm_source
			utm_medium: $utm_medium
			utm_campaign: $utm_campaign
			ip_address: $ip_address
			) {
				account_token_key
			}
	}
`;
