import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import { compose, graphql, Query, Mutation } from 'react-apollo';
import singleUploadMutation from './graphql/mutation/singleUpload';
import s3 from './../../config/s3';
import baseUrl from './../../config/baseUrl';
import reportUrl from './../../config/reportUrl';


const Container = styled.div`
  // margin-top: 10px;
  background-image: url(${require('src/assets/images/homepage/cover/circle.svg')});
  background-repeat: no-repeat;
  background-position: top left 100px;
  background-size: 200%;
  height: 100vh;
`;


const InnerContainer = styled.div`
  max-width: 600px;
  margin: 50px auto 0;
`;

const Label = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const FileContainer = styled.div`
	margin: 5px 0;
	display: flex;
	flex-direction: row;
	border-radius: 6px;
	align-items: center;
	padding: 5px 0px 5px 10px;
	height: 40px;
	box-sizing: border-box;

	${props => props.isBorderValidated ? ` border: 1px solid #FF0000; ` :` border: 1px solid #E3E3E3; ` }

	& .file-left {
		flex: 1;
		display: flex;
		flex-direction: row;

		&-icon {
			font-size: 20px;
			margin-right: 10px;
		}

		&-text {
			font-size: 20px;
		}
	}

	& .file-right {
		display: flex;
		flex-direction: row;

		&-filename {
			margin-right: 10px;
		}

		&-delete {
			margin-right: 10px;
			cursor: pointer;
		}

		& button {
			border-radius: 0px;
			border-left-width: 1px;
			border-top-width: 0px;
			border-right-width: 0px;
			border-bottom-width: 0px;
		}
	}
`;

class Uploadfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document1: '',
    };

    this._inputDocument1 = React.createRef();
  }


	handleSingleUploadDocument(uploadFile, documentName , event) {
		const {
			target: {
				validity,
				files: [file]
			}
		} = event;

		if(validity) {
			const timestamp = new Date().getTime();
			uploadFile({ variables: {
				file: file,
				path: `upload/admin_upload/${documentName}_${timestamp}`
			}})	
		}
  }
  
  render() {
    return (
      <Container>
        <Helmet>
          <title>OptimizeRoute | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
          <meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
        </Helmet>
        <InnerContainer>
          <Label>Optimize Route for Shipment</Label>
          <Row style={{marginTop: 10}}>
            <Col sm={12}>
              <FileContainer isBorderValidated={this.state.document1Invalid}>
                <div className="file-left">
                  <div className="file-left-icon">
                    {
                      this.state.document1 ? (
                        <i className="fas fa-check-circle text-green" style={{color:"#19AA19"}}></i>
                      ) : (
                        <i className="fas fa-circle text-grey" style={{color:"#ddd"}}></i>
                      )
                    }
                  </div>
                  <div className="file-left-text">เอกสาร Excel optimize result <span className="text-primary">*</span></div>
                </div>
                {
                  this.state.document1 ? (
                    <div className="file-right">
                      <div className="file-right-filename text-blue"><a href={`#`} >อัพโหลดเรียบร้อย{/*this.state.document1.split('/')[2]*/}</a></div>
                      <div className="file-right-delete text-red" onClick={() => this.setState({ document1: '' })}><i className="far fa-times"></i> ลบ</div>
                    </div>
                  ) : (
                    <div className="file-right">
                      <Mutation mutation={singleUploadMutation}
                        update={(cache, { data }) => {
                          this.setState({document1: data.upload.path});
                        }}

                        onError={(error) => {
                          console.log(error)
                        }}
                      >
                        {
                          (uploadFile) => {
                            return (
                              <input onChange={(event) => this.handleSingleUploadDocument(uploadFile, 'test_pic', event)} type="file" id="file" ref={this._inputDocument1} style={{display: "none"}}/>
                            )
                          }
                        }
                      </Mutation>
                      <Button onClick={() => this._inputDocument1.current.click()}>+ อัพโหลด</Button>
                    </div>
                  )
                }
              </FileContainer>
            </Col>
          </Row>
          <Row style={{textAlign:"center",marginTop:"20px"}}>
            <Col>
                <Button disabled={this.state.document1 ? false : true} bsStyle="primary" onClick={() => this.setState({document1 : ''},window.open(reportUrl+"/optimizeRouteXlsx?path="+this.state.document1,"_self"))} >Optimize Route</Button>
            </Col>
          </Row>
        </InnerContainer>
      </Container>
    )
  }
}

export default Uploadfile;