import gql from 'graphql-tag';

export default gql`
query  search(
  $_id : ID
  $shipmentNumber : String
){
  shipmentList(
    _id: $_id
    see_detail: true
    shipment_number: $shipmentNumber
  	) {
    shipment {
      _id
      shipment_status
      shipment_number
      shipper
      shipper_detail
      transporter_detail
      user_setting_truck_id
      driver_user_id
      shipment_tracking_number
      distance
      paid_type
      paid_status
      setting_truck_id
      setting_truck_type_id
      truck_type_pic
      setting_truck_name{
        th
      }
      truck_type_name{
        th
      }
      truck_type_qty
      pick_up_date
      create_date
      last_update
      shipment_address{
        mode
        address
        subdictrict
        lat
        lng
        contact_name
        contact_tel
        remark
        use_contact_point
        paid_by_cod
      }
      shipment_other_detail{
        remark
      }
      shipment_additional{
        additional
        qty
        price
      }
      trackStatus
      round_trip
      polyline_list {
        distance
        duration
        polyline
      }
      shipment_type
      tracking_drop
    }
    total_page
  }
}
`;
