import React from 'react';
import { Mutation } from 'react-apollo';
import { Button } from 'react-bootstrap';
import zipLabelPacelMutation from '../../../graphql/mutation/zipLabelPacel';

export default class ButtonDownloadAll extends React.Component {
  render() {
    const { linkLabels } = this.props;

    return (
      <Mutation
        mutation={zipLabelPacelMutation}
        update={(_cache, { data: zipLabelPacelData }) => {
          window.open(zipLabelPacelData.zipLabelPacel.linkDownLoad, '_blank');
        }}
        onError={err => {
          alert(err.message);
        }}
      >
        {(zipLabelPacel, { loading: zipLabelPacelLoading }) => (
          <Button
            bsStyle="link"
            bsSize="large"
            disabled={zipLabelPacelLoading}
            style={{
              color: '#1C73E8',
              fontSize: 18,
            }}
            onClick={() =>
              zipLabelPacel({
                variables: {
                  linkLabels,
                },
              })
            }
          >
            Download All{zipLabelPacelLoading ? '...' : ''}
          </Button>
        )}
      </Mutation>
    );
  }
}
