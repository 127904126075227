import React from 'react';
import styled from 'styled-components';
import { Statistic } from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const { Countdown } = Statistic;
const Container = styled.div`
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const Ribbon = styled.div`
  line-height: 23px;
  text-align: center;
  font-size: 20px;
  color: #FFFFFF;
  background-color: #D90101;
  height: 23px;
  display: inline-block;
  min-width: 100px;
  padding: 0px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 5px;

  @media(max-width: 990px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  text-align: center;
  margin-top: 32px;
  white-space: nowrap;
  margin-bottom: 40px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  line-height: 0.8;
  white-space: pre-wrap;
  margin: 0px 20px;
  height: 100%;

  @media(max-width: 767px) {
    font-size: 20px;
  }

  @media(max-width: 990px) {
    font-size: 24px;
  }
`;

const Button = styled.button`
  font-size: 24px;
  background-color: transparent;
  border: 1px solid #D90101;
  color: #D90101;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0px 16px;
  height: 30px;
  min-width: 88px;
  outline: none;

  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

  &:hover {
    color: #FFFFFF;
    background-color: #D90101;
  }

  @media(max-width: 767px) {
    height: 25px;
    min-width: 60px;
    font-size: 18px;
  }
`;

const PriceContainer = styled.div`
  font-size: 24px;

  & .price {
    font-weight: bold;
    font-size: 48px;
    color: #D90101;
    margin-left: 5px;
  }

  @media(max-width: 767px) {
    font-size: 18px;
    
    & .price {
      font-size: 34px;
    }
  }

  @media(max-width: 990px) {
    font-size: 18px;
    & .price {
      font-size: 34px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  position: absolute;
  font-size: 20px;
  top: 5px;
  right: 5px;

  & .countdown {
    font-weight: bold;
    padding-left: 5px;
    margin-top: 3px;
  }

  & .ant-statistic-content {
    font-size: 20px;
    line-height: 1;
  }

  @media(max-width: 990px) {
    font-size: 16px;

    & .ant-statistic-content {
      font-size: 16px;
      line-height: 1;
    }
  }

  & i {
    font-size: 14px;
  }
`;

const GrossPriceContainer = styled.div`
  color: #888888;
  font-size: 18px;
  
  & .price {
    text-decoration: line-through;
  }
`;

const TruckContainer = styled.div`
  margin-bottom: 10px;
  & img {
    height: 120px;
  }

  @media(max-width: 990px) {
    & img {
      height: 80px;
    }
  }
`;

const CardFlashDealsItem = (props) => {
  return (
    <Container {...props} onClick={() => props.onSelect()}>
      <Ribbon>{props.name}</Ribbon>
      <ContentContainer>
        <TruckContainer>
          <img src={props.truckTypeImage} alt="truck type"/>
        </TruckContainer>
        <Title>{props.provinceName}</Title>
        <PriceContainer>เหลือเพียง
          <span className="price">
            <NumberFormat displayType={'text'} value={props.priceSale} thousandSeparator={true}/> บาท
          </span>
        </PriceContainer>
        <CountDownContainer>
          <i className="fal fa-clock"></i>
          <span className="countdown">
            <Countdown value={props.exp} format={`${moment(props.exp).diff(moment(), 'days') > 0 ? 'D วัน HH:mm:ss' : 'HH:mm:ss'}`} />
          </span>
        </CountDownContainer>
        <GrossPriceContainer>{`ราคาเดิม `}
          <span className="price">
            <NumberFormat displayType={'text'} value={props.price} thousandSeparator={true}/> บาท
          </span>
        </GrossPriceContainer>
      </ContentContainer>
      <Footer>
        <Button onClick={() => props.onSelect()}>จอง</Button>
      </Footer>
    </Container>
  )
}

export default CardFlashDealsItem;