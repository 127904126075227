import gql from 'graphql-tag';

export default gql`
	query InstancePrice {
		instancePrice @client {
			setting_truck_id
			setting_truck_name {
				th
				en
			}
			setting_truck_type_id
			setting_truck_type_name {
				th
				en
			}
			setting_truck_type_size {
				th
				en
			}
			setting_truck_type_pic
			additional {
				_id
				name {
					th
					en
				}
				price
				qty
				address
				truck_type_size {
					th
					en
				}
			}
			creditCard {
				id
				bank
				last_digits
				brand
				expiration_month
				expiration_year
				name
			}
			pickupDate
			promotion_name
			paymentType
			remark
			userRef
			document {
				path
			}
			roundTrip
			shipmentAddress {
				mode
				address
				latitude
				longitude
				contactName
				phoneNumber
				remark
				paidByCOD
				province
				pickupDate
				isSaveContact
				mainAddress
				secondaryAddress
			}
			optimizeRoute
			tempShipmentAddress {
				mode
				address
				latitude
				longitude
				contactName
				phoneNumber
				remark
				paidByCOD
			}
			shipmentType
			shipmentConfirmDetail {
				jobCode
				blNumber
				commodity
				customerName
				invoiceNo
				packagingType
				weightPerContainer
				receiptDoc {
					path
				}
			}
			accessory {
				_id
				name {
					th
					en
				}
			}
			flashDeals {
				id
				name {
					en
					th
				}
				settingTruckId
				settingTruckName {
					en
					th
				}
				settingTruckTypeId
				truckTypeName {
					en
					th
				}
				truckTypePic
				locationStart {
					en
					th
				}
				locationEnd {
					en
					th
				}
				exp
				pickupStart
				pickupEnd
				price
				priceSale
			}
			favoriteDriver {
				id
			}
		}
	}
`;
