import gql from 'graphql-tag';

export default gql`
	query($input: String, $mode: String) {
		mapSearch(input: $input, mode: $mode) {
			description
			structured_formatting {
				main_text
				secondary_text
			}
			lat
			lng
			contact_name
			contact_tel
			mode
		}
	}
`;
