import React, { Component } from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from './../../../../config/color';

import './style.css';

import {
	Card,
	CardHeader,
	CreditCardIcon,
	ModalAlert,
	ModalConfirm
} from 'src/components';

//credit Icon
import visa from '../../../../assets/images/credit/visa_icon.png';
import jcb from '../../../../assets/images/credit/jcb_icon.png';
import master from '../../../../assets/images/credit/master_card_icon.png';

import { compose, graphql, Query, Mutation } from 'react-apollo';
import instancePriceStepQueryClient from './../../../../config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';
import creditCardEditQueryClient from './../../../../config/graphqlClient/query/creditCardEdit';
import creditCardDefaultMutation from './graphql/mutation/creditCardDefault';
import removeCreditCardMutation from './graphql/mutation/removeCreditCard';
import creditCardListQuery from './graphql/query/creditCardList';

import { withNamespaces } from "react-i18next";

const Container = styled.div`
	width: 100%;
`;

const TitelContainer = styled(Row)`
	padding: 15px 20px 0;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	font-weight: bold;
	font-size: 20px;
`;
const BoxContainer = styled(Row)`
	margin-top: 20px;
`;
const CreditContainer = styled.div`
	margin-top: 20px;
	padding: 20px;
`;
const CreditBackContainer = styled.div`
	margin-top: 20px;
	padding: 15px 0;
`;
const CreditImage = styled(CreditCardIcon)`
	width: 40px;
`;
const CreditBrand = styled(CreditCardIcon)`
	width: 40px;
	position: absolute;
	right: 10px;
	top: 10px;
`;
const InnerContainer = styled.div``;
const CardNumberContainer = styled.div`
	margin-top: 10px;
	font-size: 18px;
	font-weight: bold;
`;
const CardNameContainer = styled.div`
	font-size: 16px;
	font-weight: normal;
	width: 100px;
	word-break: break-word;
	text-transform: uppercase;
`;

const CardDateContainer = styled.div`
	position: absolute;
	font-size: 12px;
	right: 10px;
	bottom: 10px;
	width: 65px;
`;
const SmallContainer = styled.small`
	width: 100%;
	float: left;
	font-size: 16px;
`;

const RadioContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 30px;

	& .radio-label {
		flex: 1;
		font-size: 20px;
		color: #000000;
	}

	& .radio {
		font-size: 20px;

		& i {
			cursor: pointer;
			color: ${props => props.active ? '#19AA19' : '#E3E3E3'};

			${props => props.active && `
				cursor: not-allowed;
			`}
		}
	}
`;

class PaymentCreditEdit extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			creditName: '',
			creditnumber: '',
			expiration: '',
			creditCvv: '',
			openModal: false,
		};
	}

	closeTap(mode, updateInstancePriceStep) {
		if (mode === 'chanel') {
			updateInstancePriceStep({
				variables: {
					paymentCredit: false,
					paymentCreditEdit: false,
					paymentChanel: false,
					paymentCreditList: true,
				},
			});
		} else {
			updateInstancePriceStep({
				variables: {
					paymentCredit: false,
					paymentCreditEdit: false,
					paymentChanel: false,
					paymentCreditList: false,
				},
			});
		}
	}

	creditCardStyle() {
		const { brand } = this.props.creditCardEditQueryClient.creditCardEdit;

		if (brand === 'Visa') {
			return 'Visa';
		} else if (brand === 'MasterCard') {
			return 'MasterCard';
		} else if (brand === 'JCB') {
			return 'JCB';
		}

		return 'blank';
	}

	onDelete(removeCreditCard, updateInstancePrice) {
		const { navigation } = this.props;
	}

	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Mutation mutation={updateInstancePriceMutationClient}>
								{(updateInstancePrice, { error }) => {
									return (
										<Query query={instancePriceQueryClient}>
											{({ data }) => {
												return (
													<Card
														content={
															<div>
																<CardHeader borderCardBottom title={this.props.t('common:instancePrice.PaymentCreditEdit.lorem1')} onPressClose={() =>
																		this.closeTap(
																			'chanel',
																			updateInstancePriceStep,
																		)
																}/>

																{/* No Card List */}
																<div>
																	<CreditContainer
																		className={`creditCard ${this.creditCardStyle()}`}
																	>
																		<InnerContainer>
																			<CreditImage
																				brand={this.creditCardStyle()}
																			/>
																			<CardNumberContainer>
																				XXXX XXXX XXXX{' '}
																				{
																					this.props.creditCardEditQueryClient
																						.creditCardEdit.last_digits
																				}
																			</CardNumberContainer>
																			<CardNameContainer>
																				{
																					this.props.creditCardEditQueryClient
																						.creditCardEdit.name
																				}
																			</CardNameContainer>
																			<CardDateContainer>
																			{this.props.t('common:instancePrice.PaymentCreditEdit.lorem2')}
																				<SmallContainer>
																					{(
																						'0' +
																						this.props.creditCardEditQueryClient
																							.creditCardEdit.expiration_month
																					).slice(-2)}{' '}
																					/{' '}
																					{
																						this.props.creditCardEditQueryClient
																							.creditCardEdit.expiration_year
																					}
																				</SmallContainer>
																			</CardDateContainer>
																		</InnerContainer>
																	</CreditContainer>
																</div>
																<div style={{ marginTop: 20 }}>
																	<Mutation
																		mutation={creditCardDefaultMutation}
																		update={(cache) => {
																			cache.writeQuery({
																				query: creditCardEditQueryClient,
																				data: {
																					creditCardEdit: {
																						...this.props.creditCardEditQueryClient.creditCardEdit,
																						card_default: true,
																					},
																				},
																			});
																		}}
																	>
																		{creditCardDefault => {
																			return (
																				<div>
																					<RadioContainer active={this.props.creditCardEditQueryClient.creditCardEdit.card_default}>
																						<div className="radio-label">{this.props.t('common:instancePrice.PaymentCreditEdit.lorem3')}</div>
																						<div
																							className="radio"
																							onClick={() =>
																								creditCardDefault({
																									variables: {
																										cardId: this.props
																											.creditCardEditQueryClient
																											.creditCardEdit.id,
																									},
																								}).then(() => {
																									const {
																										id,
																										bank,
																										last_digits,
																										brand,
																										expiration_month,
																										expiration_year,
																										name,
																									} = this.props.creditCardEditQueryClient.creditCardEdit;
																									updateInstancePrice({
																										variables: {
																											paymentType: 'creditCard',
																											creditCard: {
																												id,
																												bank,
																												last_digits,
																												brand,
																												expiration_month,
																												expiration_year,
																												name,
																												__typename: 'CreditCard',
																											},
																										},
																									});
																								})
																							}
																						>
																							{
																								this.props.creditCardEditQueryClient
																								.creditCardEdit.card_default ? (<i className="fas fa-check-circle"></i>) : (<i className="fal fa-circle"></i>)
																							}
																						</div>
																					</RadioContainer>
																				</div>
																			);
																		}}
																	</Mutation>
																</div>
																<div style={{ marginTop: 10 }}>
																	<Mutation
																		mutation={removeCreditCardMutation}
																		refetchQueries={[
																			{
																				query: creditCardListQuery,
																			},
																		]}
																		update={() => {
																			updateInstancePriceStep({
																				variables: {
																					paymentCredit: false,
																					paymentCreditEdit: false,
																					paymentChanel: false,
																					paymentCreditList: true,
																				},
																			});
																		}}
																	>
																		{(removeCreditCard, { loading }) => {
																			return (
																				<div>
																					<Button
																						block
																						onClick={() =>
																							this.setState({
																								openModal: true,
																							})
																						}
																					>
																						{this.props.t('common:instancePrice.PaymentCreditEdit.lorem4')}
																					</Button>

																					<ModalConfirm
																						show={this.state.openModal}
																						labelButtonConfirm={this.props.t('common:instancePrice.PaymentCreditEdit.lorem4')}
																						labelButtonCancel={this.props.t('common:instancePrice.PaymentCreditEdit.lorem5')}
																						titleHeader={this.props.t('common:instancePrice.PaymentCreditEdit.lorem6')}
																						onHide={() => this.setState({ openModal: false })}
																						onPressButtonCancel={() => this.setState({ openModal: false })}
																						onPressButtonConfirm={() => {
																							removeCreditCard({
																								variables: {
																									cardId: this.props
																										.creditCardEditQueryClient
																										.creditCardEdit.id,
																								},
																							}).then(() => {
																								if (
																									this.props
																										.creditCardEditQueryClient
																										.creditCardEdit.id ===
																									this.props
																										.instancePriceQueryClient
																										.instancePrice.creditCard
																										.id
																								) {
																									if (
																										this.props
																											.instancePriceQueryClient
																											.instancePrice
																											.paymentType ===
																										'creditCard'
																									) {
																										updateInstancePrice({
																											variables: {
																												paymentType: '',
																												creditCard: {
																													id: '',
																													bank: '',
																													last_digits: '',
																													brand: '',
																													expiration_month:
																														'',
																													expiration_year: '',
																													name: '',
																													__typename:
																														'CreditCard',
																												},
																											},
																										}).then(() => {
																											this.setState({
																												openModal: false,
																											});
																										}); // delete
																									}
																								}
																							});
																						}}
																					>
																						<div>
																						{this.props.t('common:instancePrice.PaymentCreditEdit.lorem7')}: {' '}
																							{
																								this.props
																									.creditCardEditQueryClient
																									.creditCardEdit.last_digits
																							}
																						</div>
																					</ModalConfirm>
																				</div>
																			);
																		}}
																	</Mutation>
																</div>
															</div>
														}
													/>
												);
											}}
										</Query>
									);
								}}
							</Mutation>
						);
					}}
				</Mutation>
			</Container>
		);
	}
}

PaymentCreditEdit.propTypes = {};

PaymentCreditEdit.defaultProps = {};

export default compose(
	graphql(creditCardEditQueryClient, {
		name: 'creditCardEditQueryClient',
	}),
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQueryClient',
	}),
)(withNamespaces()(PaymentCreditEdit));
