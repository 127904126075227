import React, { Component } from 'react';
import { Row, Col, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import Moment from 'react-moment';
import styled from 'styled-components';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

//Style
import 'bootstrap-daterangepicker/daterangepicker.css';
//GraphQL
import { Query } from 'react-apollo';
import shipmentList from './graphql/query/shipmentList';
//Component
import {
	Header,
	BreadcrumbHeader,
	Card,
	CardHeader,
	Input,
} from 'src/components';
import { Label, ProgressCircle, SelectDropdown } from './components';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { HeaderNav, SubHeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import accountInfoListQuery from './graphql/query/accountInfoList';
import client from 'src/config/client';
import { Menu } from 'src/components';

//Style Component
const Container = styled.div`
	.mt-small {
		margin-top: 24px;
	}

	.text-center {
		text-align: center;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.table {
		border-collapse: collapse;
	}

	.table,
	.table th,
	.table td {
		border: 1px solid #e3e3e3;
	}

	.table thead tr th {
		background-color: #f2f2f2;
		padding: 10px 10px 5px 10px;
		vertical-align: middle;
	}

	.table tbody tr td {
		background-color: #ffffff;
		vertical-align: middle;
		padding: 10px 10px 5px 10px;
	}
`;

const ContainerOption = styled(Row)`
	@media (max-width: 767px) {
		& > div {
			margin-bottom: 10px;
		}
	}
`;

const ButtonOutline = styled(Link)`
	border: 1px solid #e3e3e3;
	padding: 5px 15px;
	color: #000;
	border-radius: 4px;

	& i {
		font-size: 12px;
	}

	:hover {
		text-decoration: none;
	}
`;

const TextLabelSelect = styled.span`
	font-size: 18px;
	padding-right: 6px;
	white-space: nowrap;
`;

const ContainerInline = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

//รูปแบบข้อความหัวตาราง
const TextMainTableHeader = styled.div`
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
`;

const TextSubTableHeader = styled.small`
	display: inline-block;
	font-weight: normal;
	margin-top: 5px;
	font-size: 18px;
	line-height: 1;
`;

//รูปแบบข้อความเนื้อหาตาราง
const TextMainTableBody = styled.div`
	font-weight: bold;
	font-size: 20px;
	line-height: 1;
`;

const TextSubTableBody = styled.small`
	display: inline-block;
	font-weight: normal;
	color: #808080;
	margin-top: 5px;
	font-size: 18px;
	line-height: 1;
`;

const TextPopOverTableBody = styled.div`
	font-weight: bold;
	font-size: 20px;
	line-height: 1;
	cursor: pointer;
`;

//container ต้นทาง -> ปลายทาง
const StationPointContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const SelectBox = styled(Select)`
	font-size: 18px;
	line-height: 1;
`;

//แก้ไขรูปแบบ Dropdown สถานะงาน
const CustomerOptionStatus = data => {
	switch (data.value) {
		case 0:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
				/>
			);
		case 2:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fal fa-square"
					iconColor="#000000"
				/>
			);
		case 10:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fas fa-square"
					iconColor="#000000"
				/>
			);
		case 3:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fal fa-square"
					iconColor="#D90101"
				/>
			);
		case 4:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fas fa-square"
					iconColor="#D90101"
				/>
			);
		case 5:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fal fa-times"
					iconColor="#000000"
				/>
			);
		case 6:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fal fa-exclamation-triangle"
					iconColor="#000000"
				/>
			);
		case 8:
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
					iconName="fas fa-phone-square"
					iconColor="#D90101"
				/>
			);
		default:
			return null;
	}
};

//แก้ไขรูปแบบ Dropdown เรียงตาม
const CustomerOptionOrder = data => {
	switch (data.value) {
		case '1':
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
				/>
			);
		case '-1':
			return (
				<SelectDropdown
					innerProps={data.innerProps}
					label={data.label}
					isSelected={data.isSelected}
				/>
			);
		default:
			return null;
	}
};

const SubHeaderNavStyle = styled(SubHeaderNav)`
	position: fixed;
	top: calc(60px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		position: relative;
		top: 0;
	}
`;

class ShipmentList extends Component {
	state = {
		formOptionStatus: {
			value: 0,
			label: this.props.t('common:shipmentList.allShipments'),
		},
		statusID: 0,
		formOptionDate: '',
		formOptionOrder: {
			value: '-1',
			label: this.props.t('common:shipmentList.bookingDate'),
		},
		order_type: '-1',
		formOptionSearch: '',
		page_number: 1,
		pick_up_date: '',
		startDate: '',
		endDate: '',
		shipmentUserRef: '',
	};

	handleChangeSelectOptionStatus = (formOptionStatus, refetch) => {
		const statusID = formOptionStatus.value;
		this.setState({ formOptionStatus, statusID });
		refetch();
	};

	handleChangeSelectOptionOrder = (formOptionOrder, refetch) => {
		this.setState({
			formOptionOrder,
			page_number: 1,
			order_type: formOptionOrder.value,
		});
		refetch();
	};

	handleChangeSearch = event => {
		this.setState({ formOptionSearch: event.target.value, page_number: 1 });
	};

	handleDateRangePicker(event, picker, refetch) {
		const date = `${picker.startDate.format(
			'YYYY-MM-DD'
		)}:${picker.endDate.format('YYYY-MM-DD')}`;
		const show = `${picker.startDate.format(
			'DD/MM/YYYY'
		)} - ${picker.endDate.format('DD/MM/YYYY')}`;

		this.setState(
			{
				formOptionDate: show,
				pick_up_date: date,
				startDate: picker.startDate.format('MM/DD/YYYY'),
				endDate: picker.endDate.format('MM/DD/YYYY'),
			},
			() => {
				refetch();
			}
		);
	}

	nextPage(page, total_page, refetch) {
		this.setState({ page_number: page + 1, total_page });
		refetch();
	}

	render() {
		//ตัวเลือกสถานะงาน
		const SelectOptionsStatus = [
			{ value: 0, label: this.props.t('common:shipmentList.allShipments') },
			{
				value: 2,
				label: this.props.t('common:shipmentList.waitingForTransporterAccept'),
			},
			{
				value: 10,
				label: this.props.t('common:shipmentList.waitingForStartShipment'),
			},
			{
				value: 8,
				label: this.props.t('common:shipmentList.madeAnAppointmentToCustomer'),
			},
			{
				value: 3,
				label: this.props.t('common:shipmentList.shipmentProcessing'),
			},
			{
				value: 4,
				label: this.props.t('common:shipmentList.shipmentCompleted'),
			},
			{ value: 5, label: this.props.t('common:shipmentList.canceled') },
			{
				value: 6,
				label: this.props.t('common:shipmentList.shipmentHasProblem'),
			},
		];

		//ตัวเลือก Order_type
		const SelectOptionsOrder = [
			{ value: '-1', label: this.props.t('common:shipmentList.bookingDate') },
			{ value: '1', label: this.props.t('common:shipmentList.ShipmentDate') },
		];

		return (
			<Container>
				<Helmet>
					<title>
						Shipment | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
						รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full />
				<Menu>
					<Query
						query={shipmentList}
						variables={{
							shipment_number: this.state.formOptionSearch,
							shipment_status: this.state.statusID,
							page_number: this.state.page_number,
							order_type: this.state.order_type,
							pick_up_date: this.state.pick_up_date,
							shipment_user_ref: this.state.shipmentUserRef,
						}}
						fetchPolicy="cache-and-network"
					>
						{({ loading, error, data, refetch }) => {
							return (
								<Row
									className="wrapper"
									style={{ paddingLeft: 20, paddingRight: 20 }}
								>
									<Col md={12}>
										<Card
											content={
												<div>
													<CardHeader
														title={this.props.t('common:shipmentList.title')}
													/>
													<ContainerOption className="form-input">
														<Col sm={3} xs={12}>
															<ContainerInline>
																<TextLabelSelect>
																	{this.props.t(
																		'common:shipmentList.shipmentStatus'
																	)}
																</TextLabelSelect>
																<div style={{ width: 100 + '%' }}>
																	<SelectBox
																		defaultValue={this.state.formOptionStatus}
																		onChange={event =>
																			this.handleChangeSelectOptionStatus(
																				event,
																				refetch
																			)
																		}
																		options={SelectOptionsStatus}
																		placeholder={this.props.t(
																			'common:shipmentList.filterShipmentStatus'
																		)}
																		components={{
																			Option: CustomerOptionStatus,
																		}}
																	/>
																</div>
															</ContainerInline>
														</Col>

														<Col sm={2} xs={12}>
															<ContainerInline>
																<TextLabelSelect>
																	{this.props.t(
																		'common:shipmentList.startShipmentDate'
																	)}{' '}
																	:
																</TextLabelSelect>
																<div style={{ width: 100 + '%' }}>
																	<DateRangePicker
																		onApply={(event, picker) =>
																			this.handleDateRangePicker(
																				event,
																				picker,
																				refetch
																			)
																		}
																		onCancel={() =>
																			this.setState(
																				{
																					pick_up_date: '',
																					formOptionDate: '',
																					startDate: '',
																					endDate: '',
																				},
																				() => {
																					refetch();
																				}
																			)
																		}
																		startDate={this.state.startDate || moment()}
																		endDate={this.state.endDate || moment()}
																		containerStyles={{ display: 'block' }}
																		opens="right"
																		format="DD/MM/YYYY"
																	>
																		<Input
																			type="text"
																			value={this.state.formOptionDate}
																			onChange={event => {}}
																			placeholder={this.props.t(
																				'common:shipmentList.filterStartShipmentDate'
																			)}
																		/>
																	</DateRangePicker>
																</div>
															</ContainerInline>
														</Col>

														<Col sm={3} xs={12}>
															<ContainerInline>
																<TextLabelSelect>
																	{this.props.t(
																		'common:shipmentList.arrangeBy'
																	)}
																</TextLabelSelect>
																<div style={{ width: 100 + '%' }}>
																	<SelectBox
																		onChange={event =>
																			this.handleChangeSelectOptionOrder(
																				event,
																				refetch
																			)
																		}
																		options={SelectOptionsOrder}
																		placeholder={this.props.t(
																			'common:shipmentList.arrangeBy'
																		)}
																		defaultValue={this.state.formOptionOrder}
																		components={{ Option: CustomerOptionOrder }}
																	/>
																</div>
															</ContainerInline>
														</Col>
														<Col sm={2} xs={12}>
															<div
																style={{ width: 100 + '%' }}
																className="form-input"
															>
																<Input
																	type="text"
																	value={this.state.formOptionSearch}
																	placeholder={this.props.t(
																		'common:shipmentList.searchShipmentId'
																	)}
																	onChange={event =>
																		this.handleChangeSearch(event)
																	}
																	iconRight={
																		this.state.formOptionSearch
																			? 'fas fa-times-circle'
																			: null
																	}
																	onPressIconRight={() => {
																		this.setState({ formOptionSearch: '' });
																	}}
																/>
															</div>
														</Col>
														<Col sm={2} xs={12}>
															<div
																style={{ width: 100 + '%' }}
																className="form-input"
															>
																<Input
																	type="text"
																	value={this.state.shipmentUserRef}
																	placeholder={this.props.t(
																		'common:shipmentList.searchShipmentReferenceCode'
																	)}
																	onChange={event => {
																		this.setState({
																			shipmentUserRef: event.target.value,
																			page_number: 1,
																		});
																	}}
																	iconRight={
																		this.state.shipmentUserRef
																			? 'fas fa-times-circle'
																			: null
																	}
																	onPressIconRight={() => {
																		this.setState({ shipmentUserRef: '' });
																	}}
																/>
															</div>
														</Col>
													</ContainerOption>

													<Row>
														<Col md={12}>
															<Table
																striped
																hover
																responsive
																className="table mt-small"
															>
																<thead>
																	<tr>
																		<th
																			width="150px"
																			style={{ minWidth: '142px' }}
																		>
																			&nbsp;
																		</th>
																		<th className="text-center" width="60px">
																			<TextMainTableHeader>
																				%
																			</TextMainTableHeader>
																		</th>
																		<th style={{ minWidth: 210, width: 210 }}>
																			<TextMainTableHeader>
																				{this.props.t(
																					'common:shipmentList.shipmentNo'
																				)}
																			</TextMainTableHeader>
																			<TextSubTableHeader>
																				{this.props.t(
																					'common:shipmentList.ShipmentDate'
																				)}
																			</TextSubTableHeader>
																		</th>
																		<th style={{ minWidth: 170, width: 170 }}>
																			<TextMainTableHeader>
																				{this.props.t(
																					'common:shipmentList.Status'
																				)}
																			</TextMainTableHeader>
																			<TextSubTableHeader>
																				{this.props.t(
																					'common:shipmentList.lastUpdate'
																				)}
																			</TextSubTableHeader>
																		</th>
																		<th width="200px">
																			<TextMainTableHeader>
																				{this.props.t(
																					'common:shipmentList.truckType'
																				)}
																			</TextMainTableHeader>
																			<TextSubTableHeader>
																				{this.props.t(
																					'common:shipmentList.licensePlate'
																				)}
																			</TextSubTableHeader>
																		</th>
																		<th style={{ minWidth: 500, width: 500 }}>
																			<StationPointContainer>
																				<div
																					className="text-left"
																					style={{ width: 140 }}
																				>
																					<TextMainTableHeader>
																						{this.props.t(
																							'common:shipmentList.deliveryPointContainerYardCy'
																						)}
																					</TextMainTableHeader>
																				</div>
																				<div
																					className="text-center"
																					style={{ width: 40 }}
																				></div>
																				<div
																					className="text-left"
																					style={{ width: 140 }}
																				>
																					<TextMainTableHeader>
																						{this.props.t(
																							'common:shipmentList.deliveryPointLoadingUnloading'
																						)}
																					</TextMainTableHeader>
																				</div>
																				<div
																					className="text-center"
																					style={{ width: 40 }}
																				></div>
																				<div
																					className="text-left"
																					style={{ width: 140 }}
																				>
																					<TextMainTableHeader>
																						{this.props.t(
																							'common:shipmentList.returnPointOfLoadedEmptyContainer'
																						)}
																					</TextMainTableHeader>
																				</div>
																			</StationPointContainer>
																		</th>
																		<th className="text-right" width="250px">
																			<TextMainTableHeader>
																				{this.props.t(
																					'common:shipmentList.transporter'
																				)}
																			</TextMainTableHeader>
																			<TextSubTableHeader>
																				{this.props.t(
																					'common:shipmentList.bookingShipmentDate'
																				)}
																			</TextSubTableHeader>
																		</th>
																		{loading || error ? null : data.shipmentList
																				.shipment.length > 0 &&
																		  data.shipmentList.shipment[0]
																				.account_business_id ? (
																			<th className="text-right" width="150px">
																				<TextMainTableHeader>
																					{this.props.t(
																						'common:shipmentList.BookingName'
																					)}
																				</TextMainTableHeader>
																				<TextSubTableHeader>
																					{this.props.t(
																						'common:shipmentList.Email'
																					)}
																				</TextSubTableHeader>
																			</th>
																		) : null}
																		<th className="text-right" width="150px">
																			<TextMainTableHeader>
																				{this.props.t(
																					'common:shipmentList.shipmentReferenceCode'
																				)}
																			</TextMainTableHeader>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{loading ? (
																		<tr>
																			<td colSpan="9" className="text-center">
																				{this.props.t(
																					'common:shipmentList.loading'
																				)}
																				...
																			</td>
																		</tr>
																	) : error ? (
																		<tr>
																			<td colSpan="9" className="text-center">
																				{error.graphQLErrors[0].message}
																			</td>
																		</tr>
																	) : data.shipmentList.shipment.length ? (
																		data.shipmentList.shipment.map(
																			(item, index) => (
																				<tr key={index}>
																					<td>
																						<ButtonOutline
																							to={`/shipmentdetail?shipment=${item._id}&tracking=${item.shipment_number}`}
																						>
																							<i className="fal fa-search"></i>{' '}
																							{this.props.t(
																								'common:shipmentList.viewDetails'
																							)}
																						</ButtonOutline>
																					</td>
																					<td className="text-center">
																						<ProgressCircle
																							percent={item.trackStatus}
																						/>
																					</td>
																					<td>
																						<TextMainTableBody>
																							{item.shipment_number}
																						</TextMainTableBody>
																						<TextSubTableBody>
																							{this.props.t(
																								'common:shipmentList.startShipment'
																							)}{' '}
																							<Moment format="DD/MM/YYYY - HH:mm">
																								{item.pick_up_date}
																							</Moment>
																						</TextSubTableBody>
																					</td>
																					<td>
																						<TextMainTableBody>
																							<Label
																								type={item.shipment_status}
																							/>
																						</TextMainTableBody>
																						<TextSubTableBody>
																							<Moment format="DD/MM/YYYY - HH:mm">
																								{item.last_update}
																							</Moment>
																						</TextSubTableBody>
																					</td>
																					<td>
																						<TextMainTableBody>
																							รถ{' '}
																							{item.setting_truck_name.th +
																								item.truck_type_name.th}
																						</TextMainTableBody>
																						<TextSubTableBody>
																							{item.user_setting_truck_license_plate
																								? item.user_setting_truck_license_plate
																								: 'รอดำเนินงาน...'}
																							{item.user_setting_truck_province
																								? ' - ' +
																								  item.user_setting_truck_province
																								: null}
																						</TextSubTableBody>
																					</td>
																					<td>
																						<StationPointContainer>
																							<div
																								className="text-left"
																								style={{ width: 140 }}
																							>
																								<TextMainTableBody>
																									{
																										item.shipment_address[0]
																											.subdictrict
																									}
																								</TextMainTableBody>
																								<TextSubTableBody>
																									{
																										item.shipment_address[0]
																											.province
																									}{' '}
																									-{' '}
																									{
																										item.shipment_address[0]
																											.postcode
																									}
																								</TextSubTableBody>
																							</div>
																							<div
																								className="text-center"
																								style={{ width: 40 }}
																							>
																								{item.round_trip == 1 ? (
																									<i
																										className="fal fa-exchange"
																										style={{ fontSize: 20 }}
																									/>
																								) : (
																									<i
																										className="fal fa-arrow-right"
																										style={{ fontSize: 20 }}
																									/>
																								)}
																							</div>
																							{item.round_trip != 1 &&
																							item.shipment_address.length >
																								2 &&
																							item.shipment_type == 1 ? (
																								<div
																									className="text-left"
																									style={{ width: 150 }}
																								>
																									<OverlayTrigger
																										trigger="hover"
																										key="bottom"
																										placement="bottom"
																										overlay={
																											<Popover
																												id={`popover-positioned-${item._id}`}
																												title={`จุดส่งสินค้าทั้งหมด ${item
																													.shipment_address
																													.length - 1} จุด `}
																											>
																												{item.shipment_address.map(
																													(address, key) =>
																														key != 0 && (
																															<p>
																																<strong>{`จุดที่ ${key +
																																	1} `}</strong>
																																{`${address.subdictrict} ${address.province} - ${address.postcode}`}
																															</p>
																														)
																												)}
																											</Popover>
																										}
																									>
																										<TextPopOverTableBody>
																											{`จุดส่งสินค้าทั้งหมด ${item
																												.shipment_address
																												.length - 1} จุด`}
																										</TextPopOverTableBody>
																									</OverlayTrigger>
																								</div>
																							) : (
																								<div
																									className="text-left"
																									style={{ width: 140 }}
																								>
																									<TextMainTableBody>
																										{
																											item.shipment_address[1]
																												.subdictrict
																										}
																									</TextMainTableBody>
																									<TextSubTableBody>
																										{
																											item.shipment_address[1]
																												.province
																										}{' '}
																										-{' '}
																										{
																											item.shipment_address[1]
																												.postcode
																										}
																									</TextSubTableBody>
																								</div>
																							)}
																							<div
																								className="text-center"
																								style={{ width: 40 }}
																							>
																								{item.shipment_type == 2 ||
																								item.shipment_type == 3 ? (
																									<i
																										className="fal fa-arrow-right"
																										style={{ fontSize: 20 }}
																									/>
																								) : null}
																							</div>
																							{item.shipment_type == 2 ||
																							item.shipment_type == 3 ? (
																								<div
																									className="text-left"
																									style={{ width: 140 }}
																								>
																									<TextMainTableBody>
																										{
																											item.shipment_address[2]
																												.subdictrict
																										}
																									</TextMainTableBody>
																									<TextSubTableBody>
																										{
																											item.shipment_address[2]
																												.province
																										}{' '}
																										-{' '}
																										{
																											item.shipment_address[2]
																												.postcode
																										}
																									</TextSubTableBody>
																								</div>
																							) : (
																								<div
																									className="text-left"
																									style={{ width: 140 }}
																								></div>
																							)}
																						</StationPointContainer>
																					</td>
																					<td className="text-right">
																						<TextMainTableBody>
																							{item.transporter_detail ||
																								this.props.t(
																									'common:shipmentList.GIZTIXCompanyName'
																								)}
																						</TextMainTableBody>
																						<TextSubTableBody>
																							{this.props.t(
																								'common:shipmentList.bookingOn'
																							)}{' '}
																							<Moment format="DD/MM/YYYY - HH:mm">
																								{item.create_date}
																							</Moment>
																						</TextSubTableBody>
																					</td>
																					{item.account_business_id ? (
																						<td className="text-right">
																							<TextMainTableBody>
																								{item.account_user_display_name}
																							</TextMainTableBody>
																							<TextSubTableBody>
																								{item.account_user_name}
																							</TextSubTableBody>
																						</td>
																					) : null}
																					<td className="text-right">
																						<TextSubTableBody>
																							{item.shipment_other_detail
																								.user_ref || '-'}
																						</TextSubTableBody>
																					</td>
																				</tr>
																			)
																		)
																	) : (
																		<tr>
																			<td colSpan="9" className="text-center">
																				{this.props.t(
																					'common:shipmentList.notFound'
																				)}
																			</td>
																		</tr>
																	)}
																</tbody>
															</Table>

															{error ||
															loading ||
															data.shipmentList.total_page <= 1 ? null : (
																<ReactPaginate
																	pageCount={data.shipmentList.total_page}
																	forcePage={this.state.page_number - 1}
																	containerClassName="pagination"
																	nextLabel=">"
																	previousLabel="<"
																	onPageChange={({ selected }) => {
																		this.nextPage(
																			selected,
																			data.shipmentList.total_page,
																			refetch
																		);
																	}}
																/>
															)}
														</Col>
													</Row>
												</div>
											}
										/>
									</Col>
								</Row>
							);
						}}
					</Query>
				</Menu>
			</Container>
		);
	}
}

export default withNamespaces()(ShipmentList);
