import gql from 'graphql-tag';

export default gql`
	query AccountInviteList(
    $account_info_id: ID
    $status: Int
    $key_inv: String
  ) {
		accountInviteList(
      account_info_id: $account_info_id
      status: $status
      key_inv: $key_inv
    ) {
      _id
      account_info_id
      email
      key_inv
      status
    }
	}
`;
