import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import common_en from './config/i18n/lang/en/common.json';
import common_th from './config/i18n/lang/th/common.json';

const resources = {
  en: {
    common: common_en
  },
  th: {
    common: common_th
  },
};

const languageDetector = {
	type: 'languageDetector',
	async: true, // async detection
	detect: async cb => {
		const lang = await localStorage.getItem('lang');
		if (lang) {
			return cb(lang);
		} else {
			return cb('th');
		}

		// return cb(DeviceInfo.getDeviceLocale().replace('_', '-'));
	},
	init: () => {},
	cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'th',
    resources,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
