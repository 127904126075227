import gql from 'graphql-tag';

export default gql`
	query AdditionalList($setting_truck_type_id: ID) {
		additionalList(setting_truck_type_id: $setting_truck_type_id) {
			_id
			name
			name_lang {
				th
				en
			}
			price
			truck_type_size_lang {
				th
				en
			}
			truck_type_weight
			min
			limit
			address
		}

		accessoryList(setting_truck_type_id: $setting_truck_type_id) {
			_id
			name
			name_lang {
				th
				en
			}
			position
			setting_truck_type_id
		}
	}
`;
