import React from 'react';
import Lottie from 'lottie-react';
import Success from './Success.json';

export const SuccessLottie = () => {
	return (
		<div className="">
			<Lottie
				style={{
					zIndex: '9999',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: `300px`,
				}}
				loop={true}
				animationData={Success}
			/>
		</div>
	);
};

export default SuccessLottie;
