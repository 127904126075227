import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	border-bottom: 1px solid #ddd;
	padding: 15px 0;
	line-height: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	min-height: 60px;

	${props =>
		props.clicked &&
		`
		cursor: pointer;
	`}
`;

const ContactContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	line-height: 1;
`;

const CheckBoxContainer = styled.div`
	font-size: 22px;

	& i {
		padding-top: 2px;
		color: ${props => (props.selected ? '#19aa19;' : '#e3e3e3')};
	}
`;

const ContactName = styled.div`
	font-weight: bold;
	font-size: 20px;
`;

const SubTitle = styled.div`
	color: #808080;
	font-size: 18px;
`;

const Icon = styled.div`
	margin-right: 15px;
	width: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	& i {
		font-size: 22px;
	}
`;

const ContactItem = props => {
	let icon = null;
	if (props.icon) {
		icon = (
			<Icon>
				<i className={props.icon}></i>
			</Icon>
		);
	}

	return (
		<Container {...props} onClick={props.clicked}>
			{icon}
			<ContactContainer>
				<ContactName>{props.name}</ContactName>
				<SubTitle>{props.subTitle}</SubTitle>
			</ContactContainer>
			<CheckBoxContainer selected={props.selected}>
				<i
					className={`${
						props.selected ? 'fas fa-check-circle' : 'fal fa-circle'
					}`}
				></i>
			</CheckBoxContainer>
		</Container>
	);
};

ContactItem.propTypes = {
	name: PropTypes.string,
	subTitle: PropTypes.string,
	icon: PropTypes.string,
	selected: PropTypes.bool,
	clicked: PropTypes.func,
};

ContactItem.defaultProps = {
	name: '',
	subTitle: '',
	selected: false,
	icon: '',
};

export default ContactItem;
