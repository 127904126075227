import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, Button } from '../../../../components';
import accountContactAddressDeleteMutation from './graphql/mutation/accountContactAddressDelete';

class ContactAddressDelete extends React.Component {
	render() {
		return (
			<Mutation
				mutation={accountContactAddressDeleteMutation}
				variables={{
					contactId: this.props.contactId,
					addressId: this.props.contactAddressId,
				}}
				update={(cache, { data }) => {
					if (data.accountContactAddressDelete.succeed) {
						this.props.onHide();
					}
				}}
				onError={error => {
					const textError =
						error.graphQLErrors && error.graphQLErrors.length > 0
							? error.graphQLErrors[0].message
							: error.message;

					alert(textError);
				}}
				refetchQueries={() => ['AccountContactList']}
			>
				{(accountContactAddressDelete, { loading, error }) => {
					return (
						<Modal
							width={640}
							title={this.props.title}
							closable={false}
							visible={this.props.visible}
							onCancel={this.props.onHide}
							footer={[
								<Button key="back" onClick={this.props.onHide}>
									ปิด
								</Button>,
								<Button
									key="submit"
									type="primary"
									onClick={() => accountContactAddressDelete()}
									disabled={loading}
									loading={loading}
								>
									ยืนยัน
								</Button>,
							]}
						>
							กด "ยืนยัน" เพื่อทำการลบรายชื่อผู้ติดต่อ
						</Modal>
					);
				}}
			</Mutation>
		);
	}
}

export default ContactAddressDelete;
