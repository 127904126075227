import React from 'react';
import styled from 'styled-components';

const Blank = styled.div`
	background-color: #f9f9f9;
	height: 10px;
`;

const blank = props => {
	return <Blank {...props} />;
};

export default blank;
