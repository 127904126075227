import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Input } from 'antd';
import Select from '../../components/Select';
import reportResultListQuery from '../../graphql/query/reportResultList';
import { Query } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import s3 from 'src/config/s3';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import firebase from 'firebase';
import firebaseConfig from 'src/config/firebaseConfig';

const Table = styled.table`
	& {
		width: 100%;
		line-height: 1;
		font-size: 20px;
		margin-top: 10px;
		border-collapse: collapse;

		& td,
		& th {
			padding: 10px 6px;
		}

		& tr {
			border-bottom: 1px solid #e7e7e7;
		}

		& thead th {
			font-size: 20px;
			font-weight: bold;
			color: #000000;
		}

		& tbody {
			& tr {
				&:nth-child(odd) {
					background: #f9f9f9;
				}

				&:hover {
					background-color: #f5f5f5;
				}
			}
		}
	}
`;

class TableWaitDownloadReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			status: '',
			reportName: '',
			createDate: '',
			pageNumber: 1,
			reportsStatus: [],
		};

		if (!firebase.apps.length) {
			firebase.initializeApp(firebaseConfig);
		}
	}

	getReportData = () => {
		try {
			firebase
				.database()
				.ref(`/report_result/`)
				.on('value', snapshot => {
					const reports = [];
					snapshot.forEach(child => {
						const val = child.val();
						reports.push({ id: child.key, status: val.status, file: val.file });
					});
					this.setState({
						reportsStatus: reports,
					});
				});
		} catch (error) {
			this.setState({
				reportsStatus: [],
			});
		}
	};

	componentDidMount() {
		this.getReportData();
	}

	onNextPage(page, total_page, refetch) {
		this.setState({ pageNumber: page + 1, total_page });
		refetch();
	}

	handleDateRangePicker(picker) {
		return `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format(
			'YYYY-MM-DD'
		)}`;
	}

	displayRangeDate(date) {
		if (date) {
			const split = date.split(':');
			return `${moment(split[0]).format('DD/MM/YYYY')} - ${moment(
				split[1]
			).format('DD/MM/YYYY')}`;
		}

		return '';
	}

	renderButton = report => {
		const { id, status, fileResult } = report;

		if (status === 2 && fileResult) {
			return (
				<a href={fileResult}>
					<Button
						as="a"
						style={{ fontSize: 20, lineHeight: 1 }}
						href={`${s3}/${fileResult}`}
						target="_blank"
					>
						ดาวน์โหลด
					</Button>
				</a>
			);
		}

		if (status === 3) {
			return (
				<Button bsStyle="danger" disabled bsSize="small">
					ผิดพลาด
				</Button>
			);
		}

		const findReport = this.state.reportsStatus.find(data => {
			return data.id === id;
		});

		if (findReport && findReport.status === 2) {
			return (
				<Button
					as="a"
					style={{ fontSize: 20, lineHeight: 1 }}
					href={findReport.file}
					target="_blank"
				>
					ดาวน์โหลด
				</Button>
			);
		}

		return (
			<Button bsStyle="warning" disabled bsSize="small">
				รอดาวน์โหลด
			</Button>
		);
	};

	render() {
		return (
			<>
				<Query
					query={reportResultListQuery}
					variables={{
						page_number: this.state.pageNumber,
						status: this.state.status || undefined,
						name: this.state.reportName || undefined,
						create_date: this.state.createDate || undefined,
					}}
					fetchPolicy="cache-and-network"
				>
					{({ loading, error, data, refetch }) => {
						let totalPage = 1;
						let dataTable = [];

						if (data.reportResultList) {
							const { total_page, report } = data.reportResultList;
							totalPage = total_page;
							dataTable = report.map(item => ({
								id: item._id,
								name: item.name,
								createDate: item.create_date,
								fileResult: item.file_result,
								status: item.status,
							}));
						}

						return (
							<>
								<Table>
									<thead>
										<tr>
											<th style={{ minWidth: 120, width: 120 }}>สถานะ</th>
											<th style={{ minWidth: 300 }}>ชื่อรายงาน</th>
											<th style={{ minWidth: 200, width: 200 }}>วันที่สร้าง</th>
										</tr>
									</thead>
									<thead>
										<tr>
											<td>
												<Select
													value={this.state.status}
													onChange={event =>
														this.setState({
															status: event.target.value,
															pageNumber: 1,
														})
													}
												>
													<option value="0">ทั้งหมด</option>
													<option value="1">รอดาวน์โหลด</option>
													<option value="2">ดาวน์โหลด</option>
													<option value="3">ผิดพลาด</option>
												</Select>
											</td>
											<td>
												<Input
													allowClear
													style={{ maxWidth: 300 }}
													placeholder="ค้นหาชื่อรายงาน"
													value={this.state.reportName}
													onChange={event =>
														this.setState({
															reportName: event.target.value,
															pageNumber: 1,
														})
													}
												/>
											</td>
											<td>
												<DateRangePicker
													onApply={(_event, picker) => {
														const rangeDate = this.handleDateRangePicker(
															picker
														);

														this.setState(
															{
																createDate: rangeDate,
																pageNumber: 1,
															},
															() => {
																refetch();
															}
														);
													}}
													onCancel={() =>
														this.setState({
															createDate: '',
															pageNumber: 1,
														})
													}
													opens="right"
													format="DD/MM/YYYY"
												>
													<Input
														style={{ width: 200 }}
														placeholder="ค้นหาวันที่สร้าง"
														value={this.displayRangeDate(this.state.createDate)}
														onChange={() => {}}
													/>
												</DateRangePicker>
											</td>
										</tr>
									</thead>
									<tbody>
										{loading && (
											<tr>
												<td colSpan={3}>กำลังโหลด...</td>
											</tr>
										)}

										{error && (
											<tr>
												<td colSpan={3}>{error}</td>
											</tr>
										)}

										{!loading && !error && (
											<>
												{dataTable.length > 0 &&
													dataTable.map(item => (
														<tr key={item.id}>
															<td>{this.renderButton(item)}</td>
															<td>{item.name}</td>
															<td>
																{item.createDate ? (
																	<Moment format="DD/MM/YYYY HH:mm:ss">
																		{item.createDate}
																	</Moment>
																) : (
																	'-'
																)}
															</td>
														</tr>
													))}

												{dataTable.length === 0 && (
													<tr>
														<td colSpan={3}>ไม่มีข้อมูล</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</Table>

								{error || loading || totalPage <= 1 ? null : (
									<div style={{ marginTop: 20 }}>
										<ReactPaginate
											pageCount={totalPage}
											forcePage={this.state.pageNumber - 1}
											containerClassName="pagination"
											nextLabel=">"
											previousLabel="<"
											onPageChange={({ selected }) => {
												this.onNextPage(selected, totalPage, refetch);
											}}
										/>
									</div>
								)}
							</>
						);
					}}
				</Query>
			</>
		);
	}
}

export default TableWaitDownloadReport;
