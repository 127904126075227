import React from 'react';
import {
	Row,
	Col,
	Button,
	Modal,
	FormControl,
	InputGroup,
	FormGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, graphql, Mutation, Query } from 'react-apollo';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import getInstancePrice from 'src/view/InstancePrice2/graphql/query/getInstancePrice';
import moment from 'moment';
import holiday from 'src/config/holiday';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import updateLocationTempMutationClient from 'src/config/graphqlClient/mutation/updateLocationTemp';
import selectDateQueryClient from 'src/config/graphqlClient/query/selectDate';
import updateSelectDateMutationClient from 'src/config/graphqlClient/mutation/updateSelectDate';
import creditCardListQuery from './graphql/query/creditCardList';
import creditCardDefaultQueryClient from 'src/config/graphqlClient/query/creditCardDefault';
import accountInfoListQuery from './graphql/query/accountInfoList';
import shareRouteListQuery from './graphql/query/shareRouteList';
import shareRouteAddMutation from './graphql/mutation/shareRouteAdd';
import detailShipment from './../../graphql/query/detailShipment';
import updateInstancePrice from 'src/config/graphqlClient/mutation/updateInstancePrice';
import optimizeRouteQuery from './graphql/query/optimizeRoute';
import currentUserQuery from './graphql/query/currentUser';
import client, { instancePrice, instancePriceStep } from 'src/config/client';
import s3 from 'src/config/s3';

import {
	Card,
	BlankSpace,
	DropPointItem,
	ServiceListItem,
	CreditCardIcon,
	ServicePriceItem,
	ServicePromotionItem,
	ModalAlert,
	ModalPromotion,
	Skeleton,
	ModalConfirm
} from 'src/components';

import {
	StepHeader,
	Route,
	SelectBox,
	TruckTypeSelect,
	ServiceSelect,
	PaymentChannelSelect,
	BoxDetail,
} from './../../components';
import _ from 'lodash';
import appUrl from '../../../../config/appUrl';
import {
	FacebookShareButton,
	GooglePlusShareButton,
	TwitterShareButton,
	LineShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	GooglePlusIcon,
	LineIcon,
	EmailIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import urijs from 'urijs';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import modeToText from './../../function/modeToText';
import detectLanguageData from './../../function/detectLanguageData';
import { withNamespaces } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';
import Dotdotdot from 'react-dotdotdot';
import dateNowQuery from './graphql/query/dateNow';
import { Button as ButtonAntd, Spin, Icon } from 'antd';
import 'moment-duration-format';
import { getWorkingTime4Wheels, checkProvinceMultiDrop4Wheels } from './../../function/instancePriceTime';
import { InstancePriceContext } from '../../contexts/InstancePriceContext';
import color from '../../../../config/color';

//ส่วนของ Step 1
const RouteContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 10px;
`;

const RouteContainerList = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const RouteStyle = styled(Route)`
	&:not(:last-child)::after {
		content: '';
		width: 1px;
		height: 100%;
		position: absolute;
		border-left: 2px dotted #b3b3b3;
		margin-top: 20px;
		left: 10px;
		top: 0px;
		z-index: -999;
	}

	&.dragging-helper-class {
		background-color: #ffffff;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
		border: 1px solid #e3e3e3;
	}
`;

// END ส่วนของ Step 1

//ส่วนของ Step 2
const TruckTypeSelectStyle = styled(TruckTypeSelect)`
	padding-bottom: 12px;
	border-bottom: 1px solid #e3e3e3;
`;

const ServiceContainer = styled.div`
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	padding: 10px 0px;

	& .line {
		width: 1px;
		background-color: #e3e3e3;
		align-self: stretch;
		margin: 0px 15px;
	}
`;

const ServiceSelectStyle = styled(ServiceSelect)`
	flex: 1;
`;

const PaymentChannelSelectStyle = styled(PaymentChannelSelect)`
	flex: 1;
`;
// END ส่วนของ Step 2

const Container = styled.div``;
const DropPointContainer = styled(Row)`
	margin: 0px 0 0;
`;

const OverFlowWrapper = styled.div`
	position: absolute;
	z-index: 999;
	width: calc(100% - 20px);
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
`;

const SelectBoxStyle = styled(SelectBox)``;

//share route
const ShareRouteContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0px;
	right: 0px;
	padding-right: 10px;

	& .ant-btn-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		border: none;
		color: #000000;

		&:hover {
			color: #000000;
			background-color: #E3E3E3;
		}

		&:focus {
			color: #000000;
		}

		&:disabled {
			background-color: #ffffff;
			cursor: not-allowed;

			&:hover {
				background-color: #ffffff;
			}
		}

		& i {
			font-size: 20px;
			padding-right: 3px;
		}
	}

	& .ant-spin {
		font-size: 24px;
		margin-top: 2px;
		color: #00000075;
	}
`;

const ShareButtonContainer = styled(Col)`
	.SocialMediaShareButton {
		display: inline-block;
		margin: 5px 10px;
	}
`;

const ButtonSearch = styled(Button)`
	width: 100px;
	color: #1683ff;
	background-color: #eee;
	border: 1px solid #ccc;
	border-left: none;
	padding-left: 0;
`;

const FormGroupSearch = styled(FormGroup)`
	max-width: 470px;
	width: 100%;
	margin: 5px auto;
	padding: 0px 15px;
`;

const IconClose = styled.i`
	float: right;
	cursor: pointer;
`;

const ButtonAddLocation = styled.div`
	cursor: pointer;
	box-sizing: border-box;
	font-size: 18px;
	color: #b3b3b3;
	margin-left: 28px;
	margin-right: 5px;

	display: flex;
	flex-direction: row;
	align-items: center;

	& div:first-child {
		& i {
			font-size: 10px;
			margin-right: 10px;
			padding-bottom: 2px;
			color: #e3e3e3;
		}

		flex: 1;
		height: 50px;
		border-bottom: 1px solid #e3e3e3;

		display: flex;
		flex-direction: row;
		align-items: center;
	}

	& div:last-child {
		border: 0px;
		outline: none;
		white-space: nowrap;
		color: #888888;
	}
`;

const BoxContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 10px;
	margin-top: 20px;
	align-items: center;

	& .box {
		flex: 1;
	}

	& .box:not(:first-child) {
		padding-left: 20px;
		border-left: 1px solid #e3e3e3;
	}
`;

const TabSelectShipmentType = styled.div`
	margin-top: 20px;

	& > ul {
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;

		& > li {
			color: #b3b3b3;
			text-align: center;
			cursor: pointer;
			line-height: 1;
			flex: 1;
			padding: 0px 10px;

			&:not(:last-child) {
				border-right: 1px solid #b3b3b3;
			}

			&.active {
				color: #da0d0d;
				font-weight: bold;

				& > span {
					display: block;
					border: 1px solid #da0d0d;
					border-radius: 4px;
					padding: 5px 10px;
				}
			}
		}
	}

	@media(max-width: 400px) {
		& > ul {
			& > li {
				padding: 0px;

				&:first-child {
					flex: 1.5;
				}

				&:not(:last-child) {
					border: none;
				}
			}
		}
	}
`;

const InfoHeader = styled.div`
	position: absolute;
	right: 5px;
	top: 0px;

	& i {
		margin-left: 7px;
    color: #B3B3B3;
    font-size: 18px;
		cursor: pointer;
	}
`;

const ModalShareRoute = styled(Modal)`
	.modal-dialog {
		margin-top: 120px;
	}

	@media(max-width: 767px) {
		.modal-dialog {
			width: calc(100% - 20px);
			margin-top: 40px;
		}
	}
`;

const TemporarilyClosed = styled.div`
		left: 0;
		right: 0;
		width: 100%;
		z-index: 1000;
		padding: 20px;
		line-height: 1;
		font-size: 20px;
		position: absolute;
		text-align: center;
		color: ${color.Primary};
		white-space: pre-wrap;
`;

class CreateShipment extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			openModal: false,
			openModalPromotion: false,
			openModalAlert: false,
			selectDateComplete: false,
			alertText: '',
			pickupDate: null,
			isOpenModalGiztixDiscount: false,
			roundTrip: '',
			isLoadingShareRoute: false,

			//share route
			isOpenModalShareRoute: false,
			url: '',
			copied: false,

			//modal alert
			isOpenModalAlert: false,
			textModalAlert: '',

			//optimize route
			isLoadingOptimizeRoute: false,
			isOpenModalRoundTrip: false,

			isOpenModalAlertFlashDealsRoundTrip: false,
			isOpenModalAlertFlashDealsSwitchLocation: false,
			isOpenModalTotalDistance: false,
		};
	}

	static contextType = InstancePriceContext;
	
	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	checkWorkingTime4Wheels() {
		const currentTime = moment();
		const holidayFilter = holiday.filter(
			item => item === moment(currentTime).format('YYYY-MM-DD'),
		);

		if (holidayFilter.length === 1) {
			return false;
		}

		if (this.checkProvinceInBangkokPickup()) {
			const startTime = moment(currentTime).set({
				hour: '05',
				minute: '00',
				second: '00',
			});
			const endTime = moment(currentTime).set({
				hour: '20',
				minute: '00',
				second: '59',
			});

			if (currentTime.isBetween(startTime, endTime, null, '[]')) {
				return true;
			}
		} else {
			const startTime = moment(currentTime).set({
				hour: '05',
				minute: '00',
				second: '00',
			});
			const endTime = moment(currentTime).set({
				hour: '18',
				minute: '00',
				second: '59',
			});

			if (currentTime.isBetween(startTime, endTime, null, '[]')) {
				return true;
			}
		}

		return false;
	}

	setPickupDate = (updateInstancePrice) => {
		if (this.checkWorkingTime4Wheels()) {
			const date = getWorkingTime4Wheels();

			if(!moment(date).isValid()) {
				return;
			}
			
			return updateInstancePrice({
				variables: {
					pickupDate: date.format('YYYY-MM-DD HH:mm'),
				},
			}).then(() => {
				this.props.updateSelectDateMutation({
					variables: { isCompleted: true },
				});
			});
		} else {
			const date = getWorkingTime4Wheels();
			const alertText =
				this.props.instancePriceQuery.instancePrice.shipmentAddress.length >=
					3 && this.props.instancePriceQuery.instancePrice.shipmentType === 1
					? `${this.props.t('common:instancePrice.modal.setPickupDateMultiDrop.content')} \n${this.props.t('common:instancePrice.modal.setPickupDateMultiDrop.date')} ${date.format(
							'DD/MM/YYYY HH:mm',
					  )}`
					: `${this.props.t('common:instancePrice.modal.setPickupDateAfterHours.content')} \n${this.props.t('common:instancePrice.modal.setPickupDateMultiDrop.date')} ${date.format(
							'DD/MM/YYYY HH:mm',
					  )}`;

			this.setState({
				alertText: alertText,
				openModalAlert: true,
				pickupDate: date.format('YYYY-MM-DD HH:mm'),
			});
		}
	}

	removeAdditionalTrueDigitalPark(removePromotion, updateInstancePriceStep) {
		const { instancePriceQuery } = this.props;
		const promotionName = instancePriceQuery.instancePrice.promotion_name;

		if (promotionName === 'TDP400' || promotionName === 'TDP6WH') {
			const instancePriceData = client.cache.readQuery({
				query: instancePriceQueryClient,
			});

			const removeLaborBeforeSetValue = instancePriceData.instancePrice.additional.filter(
				item =>
					!(
						item._id === '5c38262bd4425c3d608c9061' ||
						item._id === '5c77a4bbd3bb6bbabe8681e1'
					),
			);

			removePromotion({
				variables: {
					promotion_name: '',
					additional: removeLaborBeforeSetValue,
				},
			});
		} else {
			removePromotion({
				variables: {
					promotion_name: '',
				},
			});
		}

		updateInstancePriceStep({
			variables: {
				createShipment: true,
				confirmShipment: false,
				selectTruck: false,
				additional: false,
				paymentChanel: false,
				paymentCOD: false,
				paymentCredit: false,
				paymentCreditList: false,
				paymentCreditEdit: false,
				promotion: false,
				priceResult: false,
				selectDateTime: false,
				notificationMultiDrop: false,
			},
		});
	}

	additionalVariables(instancePrice) {
		return instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional_qty: item.qty,
			};
		});
	}

	getTruckTypeSizeAdditional(instancePrice) {
		if (instancePrice.additional.length > 0) {
			const truckTypeSizeFilter = instancePrice.additional.filter(
				item => item.truck_type_size,
			);

			if (truckTypeSizeFilter.length > 0) {
				return truckTypeSizeFilter[0].truck_type_size;
			}
		}

		return instancePrice.setting_truck_type_size;
	}

	checkDateNewYear = () => {
		const currentTime = moment();
		if (currentTime.isBetween('2019-12-28', '2020-01-01', null, '[]')) {
				return true;
		}

		return false;
	}

	renderButton(updateInstancePriceStep) {
		const { instancePrice } = this.props.instancePriceQuery;
		if (
			instancePrice.setting_truck_id === '5be5057787415b16d8cf48f6' &&
			(instancePrice.shipmentAddress.length < 3 || checkProvinceMultiDrop4Wheels()) &&
			!this.isFlashDeals() &&
			!this.checkDateNewYear()
		) {
			// 4wheels
			return (
				<Row style={{ marginTop: '20px',marginBottom: '30px'}}>
					<Col xs={6} style={{ paddingRight: '10px' }}>
						<Button
							id="app- reserve-book-grey"
							className="button__reservation tutorial-button-time-booking"
							bsSize="lg"
							block
							onClick={() => {
								if (this.checkBooking(updateInstancePriceStep)) {
									updateInstancePriceStep({
										variables: {
											createShipment: true,
											selectTruck: false,
											selectDateTime: true,
											additional: false,
											paymentChanel: false,
											paymentCredit: false,
											paymentCreditList: false,
											paymentCreditEdit: false,
											paymentCOD: false,
											promotion: false,
											priceResult: false,
											notificationMultiDrop: false,
										},
									});
								}
							}}
						>
							<i className="fal fa-clock" />{' '}
							{this.props.t('common:instancePrice.buttonBooking.bookInAdvance')}
						</Button>
					</Col>
					<Col xs={6} style={{ paddingLeft: '10px' }}>
						<Mutation mutation={updateInstancePriceMutationClient}>
							{updateInstancePrice => {
								return this.checkWorkingTime4Wheels() ? (
									<Button
										id="app-quick-booking"
										bsSize="lg"
										bsStyle="primary"
										className="have-small button__booking tutorial-button-booking"
										block
										onClick={() => {
											if (this.checkBooking(updateInstancePriceStep)) {
												this.setPickupDate(updateInstancePrice);
											}
										}}
									>
										{this.props.t('common:instancePrice.buttonBooking.continue')}{' '}
										<i className="far fa-angle-right" />{' '}
										<small>
											({this.props.t('common:instancePrice.buttonBooking.within')} 2{' '}
											{this.props.t('common:instancePrice.buttonBooking.hour')}....)
										</small>
									</Button>
								) : (
									<Button
										bsSize="lg"
										bsStyle="primary"
										className="have-small"
										block
										onClick={() => {
											if (this.checkBooking(updateInstancePriceStep)) {
												this.setPickupDate(updateInstancePrice);
											}
										}}
									>
										{this.props.t('common:instancePrice.buttonBooking.pickUpAt')}{' '}
										<i className="far fa-angle-right" />{' '}
										<small>( 07.00 {this.props.t('common:instancePrice.buttonBooking.unitTime')} )</small>
									</Button>
								);
							}}
						</Mutation>
					</Col>
				</Row>
			);
		} else if (
			instancePrice.setting_truck_id === '5c4ac1ea9d52c92174bf0b2f' ||
			instancePrice.setting_truck_id === '5cad5a065e962c4d15eb2daf' ||
			instancePrice.setting_truck_id === '5cbe8678723da822892a6417' ||
			instancePrice.shipmentAddress.length >= 3 ||
			this.isFlashDeals() ||
			this.checkDateNewYear()
		) {
			// 6wheels 10wheels, MultiRoute
			return (
				<Row style={{ marginTop: '20px',marginBottom: '30px' }}>
					<Col xs={12} style={{ paddingRight: '10px' }}>
						<Button
							style={{ whiteSpace: 'pre-wrap' }}
							id="app-reserve-book-red"
							className="button__reservation-full"
							bsSize="lg"
							bsStyle="primary"
							block
							onClick={() => {
								if (this.checkBooking(updateInstancePriceStep)) {
									updateInstancePriceStep({
										variables: {
											createShipment: true,
											selectTruck: false,
											selectDateTime: true,
											additional: false,
											paymentChanel: false,
											paymentCredit: false,
											paymentCreditList: false,
											paymentCreditEdit: false,
											paymentCOD: false,
											promotion: false,
											priceResult: false,
											notificationMultiDrop: false,
										},
									});
								}
							}}
						>
							<i className="fal fa-clock" /> {this.props.t('common:instancePrice.buttonBooking.bookInAdvance')}
						</Button>
					</Col>
				</Row>
			);
		}
	}

	getDiscountPromotion(instancePrice, getInstancePrice) {
		if (instancePrice.promotion_name) {
			if (
				getInstancePrice.promotion_error ||
				!getInstancePrice.promotion.name
			) {
				return this.props.t(
					'common:instancePrice.stepServicePaymentDetails.promotion.incorrectCondition',
				);
			} else {
				return `- ${getInstancePrice.discountPrice} ${this.props.t(
					'common:instancePrice.baht',
				)}`;
			}
		}

		return this.props.t(
			'common:instancePrice.stepServicePaymentDetails.promotion.notApplicable',
		);
	}

	renderPercentage(discountPrice, distancePrice) {
		return Math.round((discountPrice / distancePrice) * 100);
	}

	renderPriceRemark(instancePrice, business) {
		const additional = instancePrice.additional.slice();
		const additionalFilter = additional.filter(
			item =>
				item._id === '5c38262bd4425c3d608c9061' || // แรงงาน 4 ล้อ
				item._id === '5c77a4bbd3bb6bbabe8681e1' || // แรงงาน 6 ล้อ
				item._id === '5d144613f13324149ba13545' || // แรงงาน 10 ล้อ
				item._id === '5d14a013f13324149ba13560' || // ผู้ช่วยเพิ่มเติม 4 ล้อ
				item._id === '5d14a03bf13324149ba13564' || // ผู้ช่วยเพิ่มเติม 6 ล้อ
				item._id === '5d14a06df13324149ba13568' || // ผู้ช่วยเพิ่มเติม 10 ล้อ
				item._id === '5d5cf2f566085815b00c27b6', // แรงงานขนส่งมอเตอร์ไซต์
		);

		let text = '';
		if (instancePrice.shipmentType === 1) {
			if (additionalFilter.length === 0) {
				text = this.props.t(
					// 'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeIsNotIncluded',
					'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeAndVatIsNotIncluded',
				);

				if (business) {
					text = this.props.t(
						'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeAndVatIsNotIncluded',
					);
				}
			} else {
				text = this.props.t(
					// 'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeIsIncluded',
					'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeAndVatIsIncluded',
				);

				if (business) {
					text = this.props.t(
						'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeAndVatIsIncluded',
					);
				}
			}
		} else {
			text = this.props.t('common:instancePrice.stepServicePaymentDetails.totalCharge.includeAirportSeaport');
		}

		return '(' + text + ')' ;
	}

	getDiscount(discount, name) {
		return discount.filter(item => item.detail === name);
	}

	getPaymentType(paymentType) {
		if (paymentType === 'creditCard') {
			return 1;
		}
		if (paymentType === 'COD') {
			return 2;
		}
		if (paymentType === 'Invoice') {
			return 3;
		}

		return null;
	}

	copyText(e) {
		const copyText = document.getElementById('sharetracking_input');
		copyText.select();
		document.execCommand('copy');
		this.setState({
			copied: true,
		});
	}

	async addShareRoute() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		const {
			shipmentAddress,
			setting_truck_id,
			setting_truck_name,
			setting_truck_type_id,
			setting_truck_type_name,
			setting_truck_type_size,
			setting_truck_type_pic,
			additional,
			promotion_name,
			roundTrip,
			accessory,
			shipmentType,
		} = instancePriceData.instancePrice;

		const shipmentAddressFilter = shipmentAddress.map(item => ({
			...item,
			pickupDate: '',
			latitude: Number(item.latitude),
			longitude: Number(item.longitude),
		}));
		const shareRoute = {
			shipmentAddress: shipmentAddressFilter,
			setting_truck_id,
			setting_truck_name,
			setting_truck_type_id,
			setting_truck_type_name,
			setting_truck_type_size,
			setting_truck_type_pic,
			additional,
			promotion_name,
			roundTrip,
			accessory,
			shipmentType,
		};

		this.setState({ isLoadingShareRoute: true });

		try {
			await this.props.shareRouteAddMutation({
				variables: {
					obj_msg: JSON.stringify(shareRoute),
				},
				update: (cache, { data }) => {
					this.setState({
						url: data.shareRouteAdd.url,
						isOpenModalShareRoute: true,
					});
				},
			});

			this.setState({ isLoadingShareRoute: false });
		} catch (error) {
			console.log(error);
			this.setState({ isLoadingShareRoute: false });
		}
	}

	handleSwitchLocation() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		const { shipmentAddress } = instancePriceData.instancePrice;
		const [pickup, delivery] = shipmentAddress;
		const pickupNew = {
			...delivery,
			mode: 'pickup',
		};

		const deliveryNew = {
			...pickup,
			mode: 'delivery',
		};

		const shipmentAddressNew = [pickupNew, deliveryNew];

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					shipmentAddress: shipmentAddressNew,
				},
			},
		});

		this.setIsFitBound();
	}

	handleAddLocation() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		const { shipmentType } = instancePriceData.instancePrice;

		if (shipmentType === 1) {
			//รับค่า index
			const { shipmentAddress } = this.props.instancePriceQuery.instancePrice;
			//เก็บค่า index ใน temp
			const locationTempData = client.readQuery({
				query: locationTempQueryClient,
			});

			client.writeQuery({
				query: locationTempQueryClient,
				data: {
					locationTemp: {
						...locationTempData.locationTemp,
						shipmentAddressIndex: shipmentAddress.length, //ตำแหน่งสุดท้าย
						type: 'delivery',
					},
				},
			});
		}

		//เปิดหน้า search location
		const instancePriceStepData = client.cache.readQuery({
			query: instancePriceStepQueryClient,
		});

		client.writeQuery({
			query: instancePriceStepQueryClient,
			data: {
				instancePriceStep: {
					...instancePriceStepData.instancePriceStep,
					createShipment: false,
					selectTruck: false,
					selectDateTime: false,
					additional: false,
					paymentChanel: false,
					paymentCOD: false,
					paymentCredit: false,
					paymentCreditList: false,
					paymentCreditEdit: false,
					promotion: false,
					priceResult: false,
					searchLocation: true,
					notificationMultiDrop: false,
				},
			},
		});
	}

	handleDeleteLocation(index) {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		const newShipmentAddress = instancePriceData.instancePrice.shipmentAddress.filter(
			(item, i) => index !== i,
		);

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					shipmentAddress: newShipmentAddress,
					optimizeRoute: false,
				},
			},
		});

		this.setIsFitBound();
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		const { shipmentAddress } = instancePriceData.instancePrice;
		const arrayMoveShipmentAddress = arrayMove(
			shipmentAddress,
			oldIndex,
			newIndex,
		);

		const newShipmentAddress = arrayMoveShipmentAddress.map((item, index) => {
			if (index === 0) {
				item.mode = 'pickup';
			} else {
				item.mode = 'delivery';
			}
			return item;
		});

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					optimizeRoute: false,
					shipmentAddress: newShipmentAddress,
				},
			},
		});

		this.setIsFitBound();
	};

	checkTruckTypeMultiRoute() {
		const {
			shipmentAddress,
			setting_truck_id,
			shipmentType,
		} = this.props.instancePriceQuery.instancePrice;

		// not 4 wheels and 6 wheels
		if (
			shipmentAddress.length >= 3 &&
			shipmentType === 1 &&
			!(
				setting_truck_id === '5be5057787415b16d8cf48f6' ||
				setting_truck_id === '5c4ac1ea9d52c92174bf0b2f' ||
				setting_truck_id === '5cad5a065e962c4d15eb2daf'
			)
		) {
			return false;
		}

		return true;
	}

	checkCondition6WheelsCrane() {
		const {
			shipmentAddress,
			shipmentType,
			setting_truck_type_id
		} = this.props.instancePriceQuery.instancePrice;

		if(
			setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33' &&
			shipmentType === 1 &&
			shipmentAddress.length !== 2
		) {
			return false;
		}

		return true;
	}

	checkPaymentType() {
		const {
			paymentType,
		} = this.props.instancePriceQuery.instancePrice;

		if (paymentType === '') {
			return false;
		}

		return true;
	}

	checkShipmentAddressCOD() {
		const {
			shipmentAddress,
			paymentType,
		} = this.props.instancePriceQuery.instancePrice;

		if(paymentType === 'COD' && shipmentAddress.filter(item => item.paidByCOD === 1).length === 0) {
			return false;
		}

		return true;
	}

	checkBooking(updateInstancePriceStep) {
		//ปุ่มจองล่วงหน้า
		if (!this.checkTruckTypeMultiRoute()) {
			this.setState(
				{
					isOpenModalAlert: true,
					textModalAlert:
					this.props.t('common:instancePrice.modal.multiDropError.content'),
				},
				() => {
					updateInstancePriceStep({
						variables: {
							createShipment: true,
							selectTruck: true,
							selectDateTime: false,
							additional: false,
							paymentChanel: false,
							paymentCOD: false,
							paymentCredit: false,
							paymentCreditList: false,
							paymentCreditEdit: false,
							promotion: false,
							priceResult: false,
							notificationMultiDrop: false,
						},
					});
				},
			);

			return false;
		} else if (!this.checkCondition6WheelsCrane()) {
			this.setState(
				{
					isOpenModalAlert: true,
					textModalAlert:
					this.props.t('common:instancePrice.modal.6wheelsCraneError.content'),
				},
				() => {
					updateInstancePriceStep({
						variables: {
							createShipment: true,
							selectTruck: true,
							selectDateTime: false,
							additional: false,
							paymentChanel: false,
							paymentCOD: false,
							paymentCredit: false,
							paymentCreditList: false,
							paymentCreditEdit: false,
							promotion: false,
							priceResult: false,
							notificationMultiDrop: false,
						},
					});
				},
			);

			return false;
		} else if (!this.checkPaymentType()) {
			this.setState(
				{
					isOpenModalAlert: true,
					textModalAlert: this.props.t('common:instancePrice.modal.selectPaymentMethod.content'),
				},
				() => {
					updateInstancePriceStep({
						variables: {
							createShipment: true,
							selectTruck: false,
							selectDateTime: false,
							additional: false,
							paymentChanel: true,
							paymentCOD: false,
							paymentCredit: false,
							paymentCreditList: false,
							paymentCreditEdit: false,
							promotion: false,
							priceResult: false,
							notificationMultiDrop: false,
						},
					});
				},
			);

			return false;
		} else if (!this.checkShipmentAddressCOD()) {
			this.setState(
				{
					isOpenModalAlert: true,
					textModalAlert: 'กรุณาเลือกจุดชำระเงิน',
				},
				() => {
					updateInstancePriceStep({
						variables: {
							createShipment: true,
							selectTruck: false,
							selectDateTime: false,
							additional: false,
							paymentChanel: false,
							paymentCOD: true,
							paymentCredit: false,
							paymentCreditList: false,
							paymentCreditEdit: false,
							promotion: false,
							priceResult: false,
							notificationMultiDrop: false,
						},
					});
				},
			);

			return false;
		}

		return true;
	}

	async handleOptimizeRoute() {
		try {
			//ดึง shipmentAddress ใน client
			const instancePriceData = client.cache.readQuery({
				query: instancePriceQueryClient,
			});

			const {
				optimizeRoute,
				shipmentAddress,
				tempShipmentAddress,
			} = instancePriceData.instancePrice;

			if (optimizeRoute) {
				client.writeQuery({
					query: instancePriceQueryClient,
					data: {
						instancePrice: {
							...instancePriceData.instancePrice,
							optimizeRoute: !optimizeRoute,
							shipmentAddress: tempShipmentAddress,
						},
					},
				});

				this.setIsFitBound();
				return;
			}

			//ขึ้น loading
			this.setState({ isLoadingOptimizeRoute: true });

			//กรอกข้อมูลให้มีแต่จุดส่ง
			const shipmentAddressDelivery = shipmentAddress
				.filter(item => item.latitude !== 0 && item.longitude !== 0)
				.map(item => ({
					...item,
					latitude: item.latitude,
					longitude: item.longitude,
				}));

			//จัด route ใหม่ เฉพาะจุดส่ง
			const { data: optimizeRouteData } = await client.query({
				query: optimizeRouteQuery,
				variables: {
					address: shipmentAddressDelivery.map(item => ({
						lat: String(item.latitude),
						lng: String(item.longitude),
					})),
				},

				fetchPolicy: 'network-only',
			});

			//route ใหม่
			const sortShipmentAddress = optimizeRouteData.optimizeRoute.address.map(
				x => {
					const index = shipmentAddressDelivery.findIndex(
						y => String(y.latitude) === x.lat && String(y.longitude) === x.lng,
					);
					const data = shipmentAddressDelivery[index];
					shipmentAddressDelivery.splice(index, 1); //remove array
					return data;
				},
			);

			const newShipmentAddress = sortShipmentAddress;

			client.writeQuery({
				query: instancePriceQueryClient,
				data: {
					instancePrice: {
						...instancePriceData.instancePrice,
						optimizeRoute: !optimizeRoute,
						tempShipmentAddress: shipmentAddress,
						shipmentAddress: newShipmentAddress,
					},
				},
			});

			this.setState({ isLoadingOptimizeRoute: false });
			this.setIsFitBound();
		} catch (error) {
			console.log(error);
			this.setState({ isLoadingOptimizeRoute: false });
		}
	}

	//เลือกรูปแบบการขนส่ง
	handleSelectShipmentType(shipmentType) {
		let shipmentAddress = [];
		if (shipmentType === 1) {
			shipmentAddress = [
				{
					mode: 'pickup',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
				{
					mode: 'delivery',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
			];
		} else if (shipmentType === 2) {
			shipmentAddress = [
				{
					mode: 'importPickup',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
				{
					mode: 'importDelivery',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
				{
					mode: 'importReturn',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
			];
		} else if (shipmentType === 3) {
			shipmentAddress = [
				{
					mode: 'exportPickup',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
				{
					mode: 'exportLoading',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
				{
					mode: 'exportReturn',
					address: '',
					latitude: 0,
					longitude: 0,
					contactName: '',
					phoneNumber: '',
					remark: '',
					paidByCOD: null,
					province: '',
					pickupDate: '',
					isSaveContact: false,
					mainAddress: '',
					secondaryAddress: '',
					__typename: 'ShipmentAddress',
				},
			];
		}

		if (shipmentType === 1) {
			client.writeData({
				data: {
					instancePrice: {
						...instancePrice,
						shipmentType: shipmentType,
						shipmentAddress: shipmentAddress,
					},
				},
			});
		} else if (shipmentType === 2 || shipmentType === 3) {
			client.writeData({
				data: {
					instancePrice: {
						...instancePrice,
						setting_truck_id: '5cbe8678723da822892a6417',
						setting_truck_name: {
							th: 'รถหัวลาก',
							en: 'Trailer',
							__typename: 'SettingTruckName',
						},
						setting_truck_type_id: '5cbe8678723da822892a6412',
						setting_truck_type_name: {
							th: 'ตู้ 20"GP',
							en: 'Container 20"GP',
							__typename: 'SettingTruckTypeName',
						},
						setting_truck_type_pic: 'upload/setting_truck/container20-210419.png',
						setting_truck_type_size: {
							th: '250 x 1,200 x ไม่เกิน 280 ซม.',
							en: '250 x 1,200 x maximum 280 cm.',
							__typename: 'SettingTruckTypeSize',
						},
						shipmentType: shipmentType,
						shipmentAddress: shipmentAddress,
					},
				},
			});
		}

		//เคลียร์ step เป็นค่าเริ่มต้น
		client.writeData({
			data: {
				instancePriceStep: {
					...instancePriceStep,
				},
			},
		});
	}

	checkShipmentAddress(instancePrice) {
		const { shipmentAddress } = instancePrice;

		if (
			(shipmentAddress.filter(
				item =>
					item.mode === 'pickup' && item.latitude !== 0 && item.longitude !== 0,
			).length > 0 &&
				shipmentAddress.filter(
					item =>
						item.mode === 'delivery' &&
						item.latitude !== 0 &&
						item.longitude !== 0,
				).length > 0) ||
			(shipmentAddress.filter(
				item =>
					item.mode === 'importPickup' &&
					item.latitude !== 0 &&
					item.longitude !== 0,
			).length > 0 &&
				shipmentAddress.filter(
					item =>
						item.mode === 'importDelivery' &&
						item.latitude !== 0 &&
						item.longitude !== 0,
				).length > 0 &&
				shipmentAddress.filter(
					item =>
						item.mode === 'importReturn' &&
						item.latitude !== 0 &&
						item.longitude !== 0,
				).length > 0) ||
			(shipmentAddress.filter(
				item =>
					item.mode === 'exportPickup' &&
					item.latitude !== 0 &&
					item.longitude !== 0,
			).length > 0 &&
				shipmentAddress.filter(
					item =>
						item.mode === 'exportLoading' &&
						item.latitude !== 0 &&
						item.longitude !== 0,
				).length > 0 &&
				shipmentAddress.filter(
					item =>
						item.mode === 'exportReturn' &&
						item.latitude !== 0 &&
						item.longitude !== 0,
				).length > 0)
		) {
			return false;
		}

		return true;
	}

	formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
	
			const negativeSign = amount < 0 ? "-" : "";
	
			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;
	
			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		} catch (e) {
			console.log(e)
		}
	};

	getPromotionTitle(Pricing) {
		const {
			discountPrice, distancePrice, promotionCodeList
		} = Pricing;

		const {
			for_you, title_lang, name
		} = promotionCodeList;

		if(name) {
			if(for_you) {
				return (
					<div style={{width: '100%'}}>
						<Dotdotdot clamp={1}>
							{detectLanguageData(title_lang, this.props.t('common:lang'))}
						</Dotdotdot>
					</div>
				);
			}

			return `${this.props.t(
				'common:instancePrice.stepServicePaymentDetails.promotion.use',
			)} ${
				name
			} [${this.renderPercentage(
				discountPrice,
				distancePrice,
			)}%]`;
		}

		return this.props.t(
			'common:instancePrice.stepServicePaymentDetails.promotion.title',
		);
	}

	checkProvinceInBangkokPickup() {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		const { shipmentAddress } = instancePriceData.instancePrice;

		const provinces = [
			'กรุงเทพมหานคร',
			'นครปฐม',
			'นนทบุรี',
			'ปทุมธานี',
			'สมุทรปราการ',
			'สมุทรสาคร',
		];

		const shipmentAddressProvince = shipmentAddress
			.filter(item => item.mode === 'pickup')
			.map(item => item.province);

		return _.some(
			shipmentAddressProvince,
			item => provinces.indexOf(item) !== -1,
		);
	}

	clearFlashDeals() {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					flashDeals: {
						...instancePriceData.instancePrice.flashDeals,
						id: '',
					}
				}
			}
		});
	}

	isFlashDeals() {
		const {
			flashDeals
		} = this.props.instancePriceQuery.instancePrice;

		return !!flashDeals.id;
	}

	getFormatTime(second) {
		return moment
			.duration(second, 'second')
			.format(`D [${this.props.t('common:instancePrice.totalDistance.time.day')}] HH [${this.props.t('common:instancePrice.totalDistance.time.hour')}] mm [${this.props.t('common:instancePrice.totalDistance.time.min')}]`, {
				trunc: true,
				trim: "both"
		});
	}

	setIsFitBound() {
		const locationTempData = client.readQuery({
			query: locationTempQueryClient,
		});

		client.writeQuery({
			query: locationTempQueryClient,
			data: {
				locationTemp: {
					...locationTempData.locationTemp,
					isFitBounds: true,
				},
			},
		});
	}

	render() {
		const currentUser = this.props.currentUserQuery;
		const isCanBooking = (currentUser && currentUser.currentUser && currentUser.currentUser.booking) || false;

		const accountInfo = this.props.accountInfoListQuery;

		let cradit_balance = 0;
		let invite_code = null;
		if (!accountInfo.loading && !accountInfo.error && accountInfo.accountInfoList != undefined && accountInfo.accountInfoList.account_business !== null) {
			invite_code = accountInfo.accountInfoList.account_business.invite_code;
			cradit_balance = accountInfo.accountInfoList.account_business.business_credit_approved-accountInfo.accountInfoList.account_business.business_credit_used;
		}
		
		const RouteSortableItem = SortableElement(
			({
				item,
				index,
				indexs,
				arr,
				roundTrip,
				updateInstancePriceStep,
				locationTempMutation,
			}) => {
				return (
					<RouteStyle
						className="tutorial-select-location"
						key={indexs}
						isDrag={
							item.address &&
							arr[1].address &&
							arr.length > 2 &&
							(item.mode === 'pickup' || item.mode === 'delivery')
								? true
								: false
						}
						isDragDisabled={indexs === 0}
						rightIcon={`
						${arr.length === 2 &&
							indexs === 0 &&
							item.address &&
							arr[1].address &&
							item.mode === 'pickup' &&
							'fal fa-exchange'}
						${arr.length === 2 &&
							indexs !== 0 &&
							item.address &&
							arr[0].address &&
							!roundTrip &&
							item.mode === 'delivery' &&
							this.props.instancePriceQuery.instancePrice.setting_truck_type_id !== "5cbe8678723da822892a6411" &&
							'fal fa-plus-circle'}
						${(arr.length > 2 &&
							indexs !== 0 &&
							item.mode === 'delivery') &&
							'fal fa-minus-circle'}
					`}
						onPressRightIcon={() => {
							if (
								arr.length === 2 &&
								indexs === 0 &&
								item.address &&
								arr[1].address
							) {
								if(this.isFlashDeals()) {
									this.setState({
										isOpenModalAlertFlashDealsSwitchLocation: true,
									})

									return;
								}

								this.handleSwitchLocation();
							} else if (
								arr.length === 2 &&
								indexs !== 0 &&
								item.address &&
								arr[0].address &&
								this.props.instancePriceQuery.instancePrice.setting_truck_type_id !== "5cbe8678723da822892a6411"
							) {
								this.handleAddLocation();
							} else if (arr.length > 2 && indexs !== 0) {
								this.handleDeleteLocation(indexs);
							}
						}}
						styleRightIcon={{
							transform:
								arr.length === 2 && indexs === 0 ? 'rotate(90deg)' : null,
						}}
						placeholder={`${this.props.t(
							'common:instancePrice.selectLocationPoint',
						)} ${indexs + 1} - ${modeToText(
							item.mode,
							this.props.t('common:lang'),
						)}`}
						pinColor={
							item.mode === 'pickup' ||
							item.mode === 'importPickup' ||
							item.mode === 'exportPickup'
								? '#000000'
								: '#DB1F26'
						}
						label={`${this.props.t('common:instancePrice.point')} ${indexs +
							1} - ${modeToText(item.mode, this.props.t('common:lang'))}`}
						location={item.address}
						onPressSelect={() => {
							this.context.setData({
								searchLocationIsDrag: false,
								searchLocationMainAddress: item.mainAddress,
								searchLocationSecondaryAddress: item.secondaryAddress,
							});

							updateInstancePriceStep({
								variables: {
									createShipment: false,
									selectTruck: false,
									selectDateTime: false,
									additional: false,
									paymentChanel: false,
									paymentCOD: false,
									paymentCredit: false,
									paymentCreditList: false,
									paymentCreditEdit: false,
									promotion: false,
									priceResult: false,
									searchLocation: true,
									notificationMultiDrop: false,
								},
							}).then(() => {
								locationTempMutation({
									variables: {
										address: item.address,
										addressDefault: item.address,
										latitude: Number(item.latitude),
										longitude: Number(item.longitude),
										latitudeCenter: Number(item.latitude),
										longitudeCenter: Number(item.longitude),
										isFitBounds: undefined,
										isSelected: undefined,
										shipmentAddressIndex: indexs,
										type: item.mode,
										datetime: undefined,
									},
								});
							})
						}}
					/>
				);
			},
		);

		const RouteContainerListSortableList = SortableContainer(
			({ items, roundTrip, updateInstancePriceStep, locationTempMutation }) => {
				return (
					<RouteContainerList>
						{items.map((item, index, arr) =>  (
							<RouteSortableItem
								key={`item-${index}`}
								index={index}
								indexs={index}
								item={item}
								arr={arr}
								roundTrip={roundTrip}
								updateInstancePriceStep={updateInstancePriceStep}
								locationTempMutation={locationTempMutation}
								disabled={index === 0}
								collection={index === 0 ? 0 : 1}
							/>
						))}
					</RouteContainerList>
				);
			},
		);

		return (
			<Container>
				<ModalConfirm
					show={this.state.isOpenModalAlert}
					onPressButtonConfirm={() =>
						this.setState({ isOpenModalAlert: false })
					}
					labelButtonConfirm={this.props.t('common:instancePrice.modal.button.ok')}
					titleHeader={this.props.t('common:instancePrice.modal.title')}
					styleColorButtonConfirm="primary"
					onHide={() => this.setState({ isOpenModalAlert: false })}
				>
					<div style={{ lineHeight: 1, whiteSpace: 'pre-wrap' }}>{this.state.textModalAlert}</div>
				</ModalConfirm>

				<ModalConfirm
					show={this.state.isOpenModalRoundTrip}
					onPressButtonConfirm={() =>
						this.setState({ isOpenModalRoundTrip: false })
					}
					labelButtonConfirm={this.props.t('common:instancePrice.roundtrip.info.buttonConfirm')}
					titleHeader={this.props.t('common:instancePrice.roundtrip.info.title')}
					onHide={() => this.setState({ isOpenModalRoundTrip: false })}
				>
					<div style={{ lineHeight: 1 }}>
						{
							this.props.t('common:instancePrice.roundtrip.info.description').split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
						}
					</div>
				</ModalConfirm>

				<ModalConfirm
					show={this.state.isOpenModalAlertFlashDealsSwitchLocation}
					labelButtonConfirm={`ยืนยัน`}
					styleColorButtonConfirm="primary"
					labelButtonCancel={`ปิด`}
					titleHeader={`คุณกำลังพลาดโอกาส ได้ราคา FLASH DEALS !!`}
					onHide={() => {
						this.setState({
							isOpenModalAlertFlashDealsSwitchLocation: false,
						});
					}}
					onPressButtonCancel={() => {
						this.setState({
							isOpenModalAlertFlashDealsSwitchLocation: false,
						});
					}}
					onPressButtonConfirm={() => {
						this.clearFlashDeals();
						this.handleSwitchLocation();
						this.setState({
							isOpenModalAlertFlashDealsSwitchLocation: false,
						});
					}}
				>
					<div style={{lineHeight: 1}}>
						คุณต้องการเลือกเขตพื้นที่นอกจังหวัดที่กำหนด ซึ่งจะส่งผลให้ไม่ตรงตามเงื่อนไขของ FLASH DEALS ยืนยันทำรายการนี้ต่อหรือไม่
					</div>
				</ModalConfirm>

				<Query query={instancePriceQueryClient}>
					{instancePriceData => {
						return (
							<Card
								content={
									<div
										id="CreateShipmentStepOne"
										style={{ position: 'relative' }}
									>
										<ShareRouteContainer>											
											<ButtonAntd
												size="large"
												shape="circle"
												title={this.props.t('common:instancePrice.shareRoute.title')}
												onClick={() => this.addShareRoute()}
												disabled={
													(instancePriceData.data.instancePrice.shipmentType ===
														1 &&
														(!instancePriceData.data.instancePrice.shipmentAddress.filter(
															item =>
																item.mode !== 'pickup' &&
																item.latitude !== 0 &&
																item.longitude !== 0,
														).length > 0 ||
															!instancePriceData.data.instancePrice.shipmentAddress.filter(
																item =>
																	item.mode !== 'delivery' &&
																	item.latitude !== 0 &&
																	item.longitude !== 0,
															).length > 0)) ||
													(instancePriceData.data.instancePrice.shipmentType !==
														1 &&
														instancePriceData.data.instancePrice.shipmentAddress.filter(
															item => item.latitude === 0 && item.longitude === 0,
														).length > 0)
												}
											>
												{!this.state.isLoadingShareRoute ? (<i className="fal fa-share-alt" />) : (<Spin indicator={<Icon type="loading" spin />} />)}
											</ButtonAntd>
										</ShareRouteContainer>

										<ModalShareRoute
											show={this.state.isOpenModalShareRoute}
											onHide={() =>
												this.setState({
													isOpenModalShareRoute: false,
													copied: false,
												})
											}
										>
											<ModalShareRoute.Body style={{ textAlign: 'center' }}>
												<h3 style={{ textAlign: 'left', marginTop: '0' }}>
													{this.props.t('common:instancePrice.shareRoute.title')}{' '}
													<IconClose
														onClick={() =>
															this.setState({
																isOpenModalShareRoute: false,
																copied: false,
															})
														}
														className="fal fa-times"
													/>
												</h3>
												<Row style={{ padding: '20px', fontWeight: 'normal' }}>
													<ShareButtonContainer xs={12}>
														<LineShareButton
															url={this.state.url}
															title={`${this.props.t('common:instancePrice.shareRoute.title')}`}
														>
															<LineIcon size={32} round={true} />
														</LineShareButton>
														<FacebookShareButton
															url={this.state.url}
															title={`${this.props.t('common:instancePrice.shareRoute.title')}`}
														>
															<FacebookIcon size={32} round={true} />
														</FacebookShareButton>
														<TwitterShareButton
															url={this.state.url}
															title={`${this.props.t('common:instancePrice.shareRoute.title')}`}
														>
															<TwitterIcon size={32} round={true} />
														</TwitterShareButton>
														<EmailShareButton url={this.state.url}>
															<EmailIcon size={32} round={true} />
														</EmailShareButton>
													</ShareButtonContainer>
												</Row>
												<Row>
													<Col>
														<FormGroupSearch>
															<InputGroup>
																<FormControl
																	style={{ borderRight: 'none' }}
																	id="sharetracking_input"
																	type="text"
																	readOnly
																	value={this.state.url}
																/>
																<InputGroup.Button>
																	<CopyToClipboard text={this.state.url}>
																		<ButtonSearch
																			text={this.state.url}
																			onClick={() => this.copyText()}
																		>
																			{this.state.copied
																				? this.props.t('common:instancePrice.shareRoute.buttonCopied')
																				: this.props.t('common:instancePrice.shareRoute.buttonCopy')}
																		</ButtonSearch>
																	</CopyToClipboard>
																</InputGroup.Button>
															</InputGroup>
														</FormGroupSearch>
													</Col>
												</Row>
											</ModalShareRoute.Body>
										</ModalShareRoute>

										<div className="tutorial-choose-type-shipment">
											<StepHeader
												number="1."
												title={this.props.t(
													'common:instancePrice.stepSelectTruckType.title',
												)}
												subTitle={this.props.t(
													'common:instancePrice.stepSelectTruckType.subTitle',
												)}
											/>

											<TabSelectShipmentType>
												<ul>
													<li
														className={
															instancePriceData.data.instancePrice
																.shipmentType === 1 && 'active'
														}
														onClick={() => this.handleSelectShipmentType(1)}
													>
														<span>
															{this.props.t(
																'common:instancePrice.stepSelectTruckType.tabs.domestic',
															)}
														</span>
													</li>
													<li
														className={
															instancePriceData.data.instancePrice
																.shipmentType === 2 && 'active'
														}
														onClick={() => this.handleSelectShipmentType(2)}
													>
														<span>
															{this.props.t(
																'common:instancePrice.stepSelectTruckType.tabs.import',
															)}
														</span>
													</li>
													<li
														className={
															instancePriceData.data.instancePrice
																.shipmentType === 3 && 'active'
														}
														onClick={() => this.handleSelectShipmentType(3)}
													>
														<span>
															{this.props.t(
																'common:instancePrice.stepSelectTruckType.tabs.export',
															)}
														</span>
													</li>
												</ul>
											</TabSelectShipmentType>
										</div>
									</div>
								}
							/>
						);
					}}
				</Query>

				<BlankSpace height={10} />

				<Query query={instancePriceQueryClient}>
					{instancePriceData => {
						return (
							<Card
								content={
									<div
										className="tutorial-choose-location"
										id="CreateShipmentStepTwo"
										style={{ position: 'relative' }}
									>
										{
											instancePriceData.data.instancePrice.shipmentType === 1 && (
												<InfoHeader onClick={() => {
													const instancePriceStepData = client.readQuery({
														query: instancePriceStepQueryClient,
													});

													client.writeQuery({
														query: instancePriceStepQueryClient,
														data: {
															instancePriceStep: {
																...instancePriceStepData.instancePriceStep,
																createShipment: true,
																confirmShipment: false,
																searchLocation: false,
																contactInformation: false,
																selectTruck: false,
																paymentChanel: false,
																paymentCOD: false,
																additional: false,
																promotion: false,
																priceResult: false,
																selectDateTime: false,
																modalAdditional: false,
																paymentCredit: false,
																paymentCreditList: false,
																paymentCreditEdit: false,
																contact: false,
																selectDateTimeContact: false,
																selectRemark: false,
																notificationMultiDrop: true,
															}
														}
													});
												}}>
													<i className="fal fa-info-circle"></i>
												</InfoHeader>
											)
										}

										<StepHeader
											number="2."
											title={
												instancePriceData.data.instancePrice.shipmentType === 1
													? this.props.t('common:instancePrice.selectPickupDeliveryAddress.domestic.title')
													: instancePriceData.data.instancePrice
															.shipmentType === 2
													? this.props.t('common:instancePrice.selectPickupDeliveryAddress.import.title')
													: instancePriceData.data.instancePrice
															.shipmentType === 3
													? this.props.t('common:instancePrice.selectPickupDeliveryAddress.export.title')
													: ''
											}
											subTitle={
												instancePriceData.data.instancePrice.shipmentType === 1
													? this.props.t('common:instancePrice.selectPickupDeliveryAddress.domestic.subTitle')
													: instancePriceData.data.instancePrice
															.shipmentType === 2
													? this.props.t('common:instancePrice.selectPickupDeliveryAddress.import.subTitle')
													: instancePriceData.data.instancePrice
															.shipmentType === 3
													? this.props.t('common:instancePrice.selectPickupDeliveryAddress.export.subTitle')
													: ''
											}
										/>

										<Mutation mutation={updateInstancePriceStepMutationClient}>
											{updateInstancePriceStep => {
												return (
													<div>
														<RouteContainer>
															<RouteContainerListSortableList
																items={
																	instancePriceData.data.instancePrice
																		.shipmentAddress
																}
																updateInstancePriceStep={
																	updateInstancePriceStep
																}
																locationTempMutation={
																	this.props.locationTempMutation
																}
																onSortEnd={this.onSortEnd}
																roundTrip={
																	instancePriceData.data.instancePrice.roundTrip
																}
																useDragHandle
																helperClass="dragging-helper-class"
															/>

															{instancePriceData.data.instancePrice
																.shipmentAddress.length > 2 &&
																instancePriceData.data.instancePrice
																	.shipmentAddress.length <= 50 && // เปลี่ยนจำนวนจำกัดจุด
																instancePriceData.data.instancePrice
																	.shipmentType === 1 && (
																	<ButtonAddLocation
																		onClick={() => this.handleAddLocation()}
																	>
																		<div>
																			<i className="fas fa-circle" />{' '}
																			{this.props.t(
																				'common:instancePrice.addDeliveryAddress',
																			)}
																		</div>
																		<div>
																			<i className="fal fa-plus-circle" />
																		</div>
																	</ButtonAddLocation>
																)}

															<BoxContainer>
																<div className="box">
																	{instancePriceData.data.instancePrice
																		.shipmentAddress.length >= 3 &&
																	instancePriceData.data.instancePrice
																		.shipmentType === 1 ? (
																		<SelectBoxStyle
																			title={
																				this.state.isLoadingOptimizeRoute
																					? this.props.t(
																							'common:instancePrice.optimizeRoute.loading',
																					  )
																					: this.props.t(
																							'common:instancePrice.optimizeRoute.title',
																					  )
																			}
																			subTitle={this.props.t(
																				'common:instancePrice.optimizeRoute.subTitle',
																			)}
																			isSelect={
																				instancePriceData.data.instancePrice
																					.optimizeRoute
																			}
																			onPressSelect={() => {
																				if (
																					!this.state.isLoadingOptimizeRoute
																				) {
																					this.handleOptimizeRoute();
																				}
																			}}
																		/>
																	) : (
																		<Mutation
																			mutation={
																				updateInstancePriceMutationClient
																			}
																			variables={{
																				roundTrip: instancePriceData.data
																					.instancePrice.roundTrip
																					? ''
																					: 1,
																			}}
																			update={() => {
																				this.setIsFitBound();
																			}}
																		>
																			{updateInstancePrice => {
																				return (
																					<React.Fragment>
																						<SelectBoxStyle
																							disabled={
																								instancePriceData.data
																									.instancePrice.shipmentType !==
																								1
																							}
																							className="tutorial-roundtrip"
																							title={this.props.t(
																								'common:instancePrice.roundtrip.title',
																							)}
																							subTitle={
																								instancePriceData.data
																									.instancePrice.shipmentType ===
																								1
																									? this.props.t(
																											'common:instancePrice.roundtrip.subTitle',
																										)
																									: '*' + this.props.t('common:instancePrice.roundtrip.domesticOnly')
																							}
																							isSelect={
																								instancePriceData.data
																									.instancePrice.roundTrip
																							}
																							onPressSelect={() => {				
																								if(this.isFlashDeals()) {
																									this.setState({
																										isOpenModalAlertFlashDealsRoundTrip: true,
																									})

																									return;
																								}

																								if (
																									instancePriceData.data
																										.instancePrice
																										.shipmentType === 1
																								) {
																									updateInstancePrice();
																								}
																							}}
																							icon="fal fa-info-circle"
																							onPressIcon={e => {
																								e.stopPropagation();
																								this.setState({
																									isOpenModalRoundTrip: true,
																								});
																							}}
																						/>

																						<ModalConfirm
																							show={this.state.isOpenModalAlertFlashDealsRoundTrip}
																							labelButtonConfirm={`ยืนยัน`}
																							styleColorButtonConfirm="primary"
																							labelButtonCancel={`ปิด`}
																							titleHeader={`คุณกำลังพลาดโอกาส ได้ราคา FLASH DEALS !!`}
																							onHide={() => {
																								this.setState({
																									isOpenModalAlertFlashDealsRoundTrip: false,
																								});
																							}}
																							onPressButtonCancel={() => {
																								this.setState({
																									isOpenModalAlertFlashDealsRoundTrip: false,
																								});
																							}}
																							onPressButtonConfirm={() => {
																								this.clearFlashDeals();
																								updateInstancePrice();
																								this.setState({
																									isOpenModalAlertFlashDealsRoundTrip: false,
																								});
																							}}
																						>
																							<div style={{lineHeight: 1}}>
																								คุณต้องการใช้บริการ ไป-กลับ ซึ่งจะส่งผลให้ไม่ตรงตามเงื่อนไขของ FLASH DEALS ยืนยันทำรายการนี้ต่อหรือไม่
																							</div>
																						</ModalConfirm>
																					</React.Fragment>
																				);
																			}}
																		</Mutation>
																	)}
																</div>
																<div className="box">
																	<Query
																		query={getInstancePrice}
																		fetchPolicy="cache-and-network"
																		variables={{
																			additional: this.additionalVariables(
																				instancePriceData.data.instancePrice,
																			),
																			settingTruckTypeId:
																				instancePriceData.data.instancePrice
																					.setting_truck_type_id,
																			address: instancePriceData.data.instancePrice.shipmentAddress
																				.filter(
																					item =>
																						item.latitude !== 0 &&
																						item.longitude !== 0,
																				)
																				.map(item => ({
																					lat: item.latitude,
																					lng: item.longitude,
																				})),
																			promotionName:
																				instancePriceData.data.instancePrice
																					.promotion_name,
																			pickupDate:
																				instancePriceData.data.instancePrice
																					.pickupDate,
																			round_trip: instancePriceData.data
																				.instancePrice.roundTrip
																				? instancePriceData.data.instancePrice
																						.roundTrip
																				: null,
																			paymentType: this.getPaymentType(
																				instancePriceData.data.instancePrice
																					.paymentType,
																			),
																			shipmentType:
																				instancePriceData.data.instancePrice
																					.shipmentType,
																			utm_source: this.getCookie("utm_source"),
																			utm_medium: this.getCookie("utm_medium"),
																			utm_campaign: this.getCookie("utm_campaign"),
																			ip_address: this.getCookie("ip_address"),
																			flash_deals_id: instancePriceData.data.instancePrice.flashDeals.id,
																		}}
																	>
																		{({ loading, error, data }) => {
																			let distance = '';
																			const {
																				shipmentAddress,
																			} = instancePriceData.data.instancePrice;

																			if (
																				shipmentAddress.filter(
																					item =>
																						item.latitude === 0 &&
																						item.longitude === 0,
																				).length
																			) {
																				distance = this.props.t(
																					'common:instancePrice.totalDistance.selectLocation',
																				);
																			} else if (loading) {
																				distance = this.props.t(
																					'common:instancePrice.totalDistance.loading',
																				);
																			} else if (error) {
																				distance =
																					'0 ' +
																					this.props.t(
																						'common:instancePrice.totalDistance.unit',
																					);
																			} else if (!loading && !error) {
																				distance = instancePriceData.data
																					.instancePrice.roundTrip === 1 ? data.getInstancePrice.distance * 2 : data.getInstancePrice.distance;
																			}

																			const subTitle = `${distance.toLocaleString('en-US', {maximumFractionDigits:2})} ${
																				typeof distance === 'number'
																					? `${this.props.t('common:instancePrice.totalDistance.unit')} / ${this.getFormatTime(data.getInstancePrice.duration)}`
																					: ''
																			}`;

																			return (
																				<BoxDetail
																					icon="fal fa-map-marked-alt"
																					title={this.props.t(
																						'common:instancePrice.totalDistance.title',
																					)}
																					subTitle={subTitle}
																					disabled={
																						typeof distance !== 'number'
																					}
																					onPressInfo={e => {
																						this.setState({ isOpenModalTotalDistance: true });
																					}}
																				/>
																			);
																		}}
																	</Query>
																</div>

																<ModalConfirm
																	show={this.state.isOpenModalTotalDistance}
																	labelButtonConfirm={`รับทราบ`}
																	titleHeader={`วิธีการคำนวณระยะเวลาเดินทาง`}
																	onHide={() => {
																		this.setState({
																			isOpenModalTotalDistance: false,
																		});
																	}}
																	onPressButtonConfirm={() => {
																		this.setState({
																			isOpenModalTotalDistance: false,
																		});
																	}}
																>
																	<div style={{lineHeight: 1}}>
																		<ol
																			style={{
																				margin: 0,
																				padding: '0px 15px',
																			}}
																		>
																			<li style={{marginBottom: 15}}>ระยะเวลาการเดินทางไปยังจุดหมายปลายทาง คำนวณจากการจราจร ณ เวลาที่ท่านทำการจองขนส่ง โดยระยะเวลาที่ใช้การเดินทางในจริงจะขึ้นอยู่กับสภาพจราจรในเวลานั้นๆ ซึ่งอาจคลาดเคลื่อนจากที่ระบุไว้</li>
																			<li style={{marginBottom: 15}}>ในกรณีการขนส่งของท่านมีปลายทางหลายจุดส่ง ระยะเวลาในการเดินทางอาจมีการคลาดเคลื่อนขึ้นอยู่กับ ระยะเวลาการลงสินค้า, ความเร็วที่ใช้ตามกฎหมายกำหนดขนาดรถประเภทที่ท่านเลือก ซึ่งอาจส่งผลให้ระยะเวลาที่ระบบคำนวณมีความคลาดเคลื่อน</li>
																			<li>ระยะเวลาการเดินทางที่ระบุอาจมีการคลาดเคลื่อนจากปัจจัยอื่นๆ เช่น อุบัติเหตุ สภาพอากาศ การเบี่ยงเส้นทางหรือปัจจัยอื่นๆ</li>
																		</ol>
																	</div>
																</ModalConfirm>
															</BoxContainer>
														</RouteContainer>
													</div>
												);
											}}
										</Mutation>
									</div>
								}
							/>
						);
					}}
				</Query>
				<BlankSpace height={10} />
				<Query query={instancePriceStepQueryClient}>
					{data => {
						const {
							createShipment,
							selectTruck,
							paymentChanel,
							paymentCOD,
							paymentCredit,
							paymentCreditList,
							paymentCreditEdit,
							additional,
							promotion,
							priceResult,
						} = data.data.instancePriceStep;

						return (
							<Query query={instancePriceQueryClient}>
								{instancePriceData => {
									const instancePrice = instancePriceData.data.instancePrice;
									return (
										<Mutation mutation={updateInstancePriceStepMutationClient}>
											{updateInstancePriceStep => {
												return (
													<Card
														content={
															<div id="CreateShipmentStepThree">
																{this.checkShipmentAddress(instancePrice) ? (
																	<OverFlowWrapper />
																) : (
																	<Query
																		query={getInstancePrice}
																		fetchPolicy="cache-and-network"
																		variables={{
																			additional: this.additionalVariables(
																				instancePrice,
																			),
																			settingTruckTypeId:
																				instancePrice.setting_truck_type_id,
																			address: instancePrice.shipmentAddress
																				.filter(
																					item =>
																						item.latitude !== 0 &&
																						item.longitude !== 0,
																				)
																				.map(item => ({
																					lat: item.latitude,
																					lng: item.longitude,
																				})),
																			promotionName:
																				instancePrice.promotion_name,
																			pickupDate: instancePrice.pickupDate,
																			round_trip: instancePrice.roundTrip
																				? instancePrice.roundTrip
																				: null,
																			paymentType: this.getPaymentType(
																				instancePrice.paymentType,
																			),
																			shipmentType: instancePrice.shipmentType,
																			utm_source: this.getCookie("utm_source"),
																			utm_medium: this.getCookie("utm_medium"),
																			utm_campaign: this.getCookie("utm_campaign"),
																			ip_address: this.getCookie("ip_address"),
																			flash_deals_id: instancePrice.flashDeals.id,
																		}}
																		onCompleted={data => {
																			if (
																				this.props.selectDateQuery.selectDate
																					.isCompleted
																			) {
																				if (
																					data.getInstancePrice
																						.promotion_error ||
																					(instancePrice.promotion_name &&
																						!data.getInstancePrice.promotion
																							.name)
																				) {
																					this.props
																						.updateSelectDateMutation({
																							variables: {
																								isCompleted: false,
																							},
																						})
																						.then(() => {
																							this.setState({
																								openModal: true,
																							});
																						});
																				} else {
																					this.props
																						.updateSelectDateMutation({
																							variables: {
																								isCompleted: false,
																							},
																						})
																						.then(() => {
																							updateInstancePriceStep({
																								variables: {
																									createShipment: false,
																									confirmShipment: true,
																									selectTruck: false,
																									selectDateTime: false,
																									additional: false,
																									paymentChanel: false,
																									paymentCOD: false,
																									paymentCredit: false,
																									paymentCreditList: false,
																									paymentCreditEdit: false,
																									promotion: false,
																									priceResult: false,
																									notificationMultiDrop: false,
																								},
																							});
																						});
																				}
																			}
																		}}
																	>
																		{({ loading, error, data }) => {
																			if (loading) return null;
																			if (error)
																				return (
																					<div>
																						{error.graphQLErrors.length > 0 &&
																						error.graphQLErrors[0].message ===
																							'นอกพื้นที่บริการ' &&
																						instancePrice.shipmentAddress
																							.length <= 2 ? (
																							<div
																								style={{
																									textAlign: 'center',
																									color: '#D90101',
																									margin: '0 -15px 20px',
																								}}
																							>
																								{ this.props.t('common:instancePrice.error.outOfServiceArea').split('\n').map(item => (<div>{item}</div>)) }
																							</div>
																						) : null}

																						{error.graphQLErrors.length > 0 &&
																						error.graphQLErrors[0].message ===
																							'นอกพื้นที่บริการ' &&
																						instancePrice.shipmentAddress
																							.length > 2 ? (
																							<div
																								style={{
																									textAlign: 'center',
																									color: '#D90101',
																									margin: '0 -15px 20px',
																								}}
																							>
																								{ this.props.t('common:instancePrice.error.bangkokAndMetropolitanArea').split('\n').map(item => (<div>{item}</div>)) }
																							</div>
																						) : null}
																						<OverFlowWrapper />
																					</div>
																				);
																			return null;
																		}}
																	</Query>
																)}

																<DropPointContainer>
																	<div className="tutorial-select-truck-type">
																		<StepHeader
																			number="3."
																			title={this.props.t(
																				'common:instancePrice.stepServicePaymentDetails.title',
																			)}
																			subTitle={this.props.t(
																				'common:instancePrice.stepServicePaymentDetails.subTitle',
																			)}
																			style={{ marginBottom: 20 }}
																		/>

																		<TruckTypeSelectStyle
																			className="create-shipment__truck-type"
																			serviceImage={`${s3}/${
																				instancePrice.setting_truck_type_pic
																			}`}
																			selected={selectTruck}
																			onClick={() =>
																				updateInstancePriceStep({
																					variables: {
																						createShipment: true,
																						selectTruck: true,
																						selectDateTime: false,
																						additional: false,
																						paymentChanel: false,
																						paymentCOD: false,
																						paymentCredit: false,
																						paymentCreditList: false,
																						paymentCreditEdit: false,
																						promotion: false,
																						priceResult: false,
																						notificationMultiDrop: false,
																					},
																				})
																			}

																			truckName={`${this.props.t('common:lang') === 'th' ? 'รถขนส่ง ' : ''}${detectLanguageData(instancePrice.setting_truck_name, this.props.t('common:lang'))} - ${detectLanguageData(instancePrice.setting_truck_type_name, this.props.t('common:lang'))}`}
																		/>
																	</div>

																	<Row>
																		<Col xs={12}>
																			<ServiceContainer>
																				<ServiceSelectStyle
																					className="create-shipment__service tutorial-select-service"
																					isSelected={additional}
																					subTitle={
																						instancePrice.additional.length ===
																							0 &&
																						instancePrice.accessory.length === 0
																							? this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.additional.notApplicable',
																							  )
																							: `${instancePrice.additional
																									.length +
																									instancePrice.accessory
																										.length} ${this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.additional.items',
																							  )}`
																					}
																					onClick={() =>
																						updateInstancePriceStep({
																							variables: {
																								createShipment: true,
																								selectTruck: false,
																								selectDateTime: false,
																								additional: true,
																								paymentChanel: false,
																								paymentCOD: false,
																								paymentCredit: false,
																								paymentCreditList: false,
																								paymentCreditEdit: false,
																								promotion: false,
																								priceResult: false,
																								notificationMultiDrop: false,
																							},
																						})
																					}
																				/>

																				<div className="line" />

																				<PaymentChannelSelectStyle
																					className="create-shipment__payment-channel tutorial-select-payment-channel"
																					isSelected={
																						paymentChanel ||
																						paymentCOD ||
																						paymentCredit ||
																						paymentCreditList ||
																						paymentCreditEdit
																					}
																					subTitle={
																						instancePrice.paymentType
																							? instancePrice.paymentType ===
																									'COD'
																								? this.props.t(
																										'common:instancePrice.stepServicePaymentDetails.paymentMethods.cod',
																								  )
																								: instancePrice.paymentType ===
																								  'Invoice'
																								? this.props.t(
																										'common:instancePrice.stepServicePaymentDetails.paymentMethods.invoice',
																								  )
																								: instancePrice.paymentType ===
																								  'creditCard'
																								? instancePrice.creditCard
																										.last_digits
																								: this.props.t(
																										'common:instancePrice.stepServicePaymentDetails.paymentMethods.notApplicableYet',
																								  )
																							: this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.paymentMethods.notApplicableYet',
																							  )
																					}
																					onClick={() =>
																						updateInstancePriceStep({
																							variables: {
																								createShipment: true,
																								selectTruck: false,
																								selectDateTime: false,
																								additional: false,
																								paymentChanel: true,
																								paymentCOD: false,
																								paymentCredit: false,
																								paymentCreditList: false,
																								promotion: false,
																								priceResult: false,
																								notificationMultiDrop: false,
																							},
																						})
																					}
																					CreditCardIcon={
																						instancePrice.paymentType &&
																						instancePrice.paymentType ==
																							'creditCard' ? (
																							<CreditCardIcon
																								brand={
																									instancePrice.creditCard.brand
																								}
																							/>
																						) : null
																					}
																					LeftCreditIcon={
																						!instancePrice.paymentType ? (
																							<CreditCardIcon brand="NO" />
																						) : instancePrice.paymentType ===
																						  'COD' ? (
																							<CreditCardIcon brand="COD" />
																						) : instancePrice.paymentType ===
																						  'Invoice' ? (
																							<CreditCardIcon brand="Invoice" />
																						) : null
																					}
																				/>
																			</ServiceContainer>
																		</Col>
																	</Row>

																	{!this.checkShipmentAddress(instancePrice) ? (
																		<Query
																			query={getInstancePrice}
																			fetchPolicy="cache-and-network"
																			variables={{
																				additional: this.additionalVariables(
																					instancePrice,
																				),
																				settingTruckTypeId:
																					instancePrice.setting_truck_type_id,
																				address: instancePrice.shipmentAddress
																					.filter(
																						item =>
																							item.latitude !== 0 &&
																							item.longitude !== 0,
																					)
																					.map(item => ({
																						lat: item.latitude,
																						lng: item.longitude,
																					})),
																				promotionName:
																					instancePrice.promotion_name,
																				pickupDate: instancePrice.pickupDate,
																				round_trip: instancePrice.roundTrip
																					? instancePrice.roundTrip
																					: null,
																				paymentType: this.getPaymentType(
																					instancePrice.paymentType,
																				),
																				shipmentType:
																					instancePrice.shipmentType,
																				utm_source: this.getCookie("utm_source"),
																				utm_medium: this.getCookie("utm_medium"),
																				utm_campaign: this.getCookie("utm_campaign"),
																				ip_address: this.getCookie("ip_address"),
																				flash_deals_id: instancePrice.flashDeals.id,
																			}}
																			onCompleted={data => {
																				if (
																					this.props.selectDateQuery.selectDate
																						.isCompleted
																				) {
																					if (
																						data.getInstancePrice
																							.promotion_error ||
																						(instancePrice.promotion_name &&
																							!data.getInstancePrice.promotion
																								.name)
																					) {
																						this.props
																							.updateSelectDateMutation({
																								variables: {
																									isCompleted: false,
																								},
																							})
																							.then(() => {
																								this.setState({
																									openModal: true,
																								});
																							});
																					} else {
																						this.props
																							.updateSelectDateMutation({
																								variables: {
																									isCompleted: false,
																								},
																							})
																							.then(() => {
																								updateInstancePriceStep({
																									variables: {
																										createShipment: false,
																										confirmShipment: true,
																										selectTruck: false,
																										selectDateTime: false,
																										additional: false,
																										paymentChanel: false,
																										paymentCOD: false,
																										paymentCredit: false,
																										paymentCreditList: false,
																										promotion: false,
																										priceResult: false,
																										notificationMultiDrop: false,
																									},
																								});
																							});
																					}
																				}
																			}}
																		>
																			{({
																				loading,
																				error,
																				data,
																				refetch,
																				variables,
																			}) => {
																				if (loading)
																					return (
																						<Skeleton
																							width={460}
																							height={76}
																							style={{ marginTop: 8 }}
																						>
																							<rect
																								x="0"
																								y="0"
																								rx="4"
																								ry="4"
																								width="250"
																								height="15"
																							/>

																							<rect
																								x="355"
																								y="0"
																								rx="4"
																								ry="4"
																								width="105"
																								height="15"
																							/>

																							<rect
																								x="0"
																								y="39"
																								rx="3"
																								ry="3"
																								width="130"
																								height="13"
																							/>

																							<rect
																								x="0"
																								y="63"
																								rx="3"
																								ry="3"
																								width="290"
																								height="13"
																							/>
																						</Skeleton>
																					);

																				if (error)
																					return (
																						<div>																							
																							<ServicePromotionItem
																								isShowRightIcon
																								Title={this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.promotion.title',
																								)}
																								price={this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.promotion.notApplicable',
																								)}
																								selected={promotion}
																								border
																							/>

																							<ServicePriceItem
																								style={{ marginTop: 10 }}
																								Title={this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.totalCharge.title',
																								)}
																								subTitle={`(${
																									this.props.instancePriceQuery
																										.instancePrice
																										.shipmentType === 1
																										? this.props.t('common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeIsNotIncluded')
																										: this.props.t('common:instancePrice.stepServicePaymentDetails.totalCharge.includeAirportSeaport')
																								})`}
																								price={`0.00`}
																							/>
																						</div>
																					);

																				const Pricing = data.getInstancePrice;

																				return (
																					<Mutation
																						mutation={
																							updateInstancePriceMutationClient
																						}
																					>
																						{updateInstancePrice => {
																							const isBusiness =
																								accountInfo.accountInfoList &&
																								accountInfo.accountInfoList
																									.account_business !== null &&
																								accountInfo.accountInfoList
																									.account_business
																									.business_status === 2;

																							return (
																								<div>
																									<ModalConfirm
																										show={
																											this.state
																												.isOpenModalGiztixDiscount
																										}
																										onPressButtonConfirm={() =>
																											this.setState({
																												isOpenModalGiztixDiscount: false,
																											})
																										}
																										labelButtonConfirm={this.props.t('common:instancePrice.modal.giztixDiscount.buttonConfirm')}
																										titleHeader={this.props.t('common:instancePrice.modal.giztixDiscount.title')}
																										styleColorButtonConfirm="primary"
																										onHide={() =>
																											this.setState({
																												isOpenModalGiztixDiscount: false,
																											})
																										}
																									>
																										<div>
																										{
																											invite_code? (
																												this.props.t('common:instancePrice.modal.giztixDiscount.contentRefer').split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
																											) : (
																												this.props.t('common:instancePrice.modal.giztixDiscount.content').split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
																											)
																										}
																										</div>
																									</ModalConfirm>

																									{(Pricing && _.some(Pricing.discount, [
																										'detail',
																										'Cash voucher',
																									])) && (
																										<ServicePromotionItem
																											Title={`Cash voucher`}
																											price={`- ${
																												this.formatMoney(Pricing.discount.filter(
																													item =>
																														item.detail ===
																														'Cash voucher',
																												)[0].price, 0)
																											} ${this.props.t(
																												'common:instancePrice.baht',
																											)}`}
																											border
																											// icon='fal fa-info-circle'
																											onPressIcon={() =>
																												this.setState({
																													isOpenModalGiztixDiscount: true,
																												})
																											}
																										/>
																									)}

																									{(Pricing && _.some(
																										Pricing.discount_error,
																										['detail', 'Cash voucher'],
																									)) && (
																										<ServicePromotionItem
																											Title={`Cash voucher`}
																											price={`- ${
																												this.formatMoney(Pricing.discount_error.filter(
																													item =>
																														item.detail ===
																														'Cash voucher',
																												)[0].price, 0)
																											} ${this.props.t(
																												'common:instancePrice.baht',
																											)}`}
																											border
																											onPressIcon={() =>
																												this.setState({
																													isOpenModalGiztixDiscount: true,
																												})
																											}
																											textInfo={`(${this.props.t('common:instancePrice.cashVoucherNotUsePromotion')})`}
																											lineStrike
																										/>
																									)}

																									{(Pricing && _.some(Pricing.discount, [
																										'detail',
																										'ส่วนลด Giztix Business',
																									])) && (
																										<ServicePromotionItem
																											Title={`
																													${
																														Pricing.discount.filter(
																															item =>
																																item.detail ===
																																'ส่วนลด Giztix Business',
																														)[0].detail
																													} 
																													[${
																														Pricing.discount.filter(
																															item =>
																																item.detail ===
																																'ส่วนลด Giztix Business',
																														)[0].percent
																													}%]
																												`}
																											price={`-${
																												this.formatMoney(Pricing.discount.filter(
																													item =>
																														item.detail ===
																														'ส่วนลด Giztix Business',
																												)[0].price, 0)
																											} ${this.props.t('common:instancePrice.baht')}`}
																											border
																											icon="fal fa-info-circle"
																											onPressIcon={() =>
																												this.setState({
																													isOpenModalGiztixDiscount: true,
																												})
																											}
																										/>
																									)}

																									<ServicePromotionItem
																										isShowRightIcon
																										className="create-shipment__promotion"
																										Title={this.getPromotionTitle(Pricing)}
																										price={this.getDiscountPromotion(
																											instancePrice,
																											Pricing,
																										)}
																										selected={promotion}
																										border
																										onClick={() => {
																											updateInstancePriceStep({
																												variables: {
																													createShipment: true,
																													confirmShipment: false,
																													selectTruck: false,
																													additional: false,
																													paymentChanel: false,
																													paymentCOD: false,
																													paymentCredit: false,
																													paymentCreditList: false,
																													promotion: true,
																													priceResult: false,
																													selectDateTime: false,
																													notificationMultiDrop: false,
																												},
																											});
																										}}
																										icon={
																											instancePrice.promotion_name &&
																											'fal fa-times-circle'
																										}
																										onPressIcon={e => {
																											e.stopPropagation();
																											this.removeAdditionalTrueDigitalPark(
																												updateInstancePrice,
																												updateInstancePriceStep,
																											);
																										}}
																									/>

																									<ServicePriceItem
																										style={{ marginTop: 10 }}
																										Title={this.props.t(
																											'common:instancePrice.stepServicePaymentDetails.totalCharge.title',
																										)}
																										subTitle={
																											!isBusiness &&
																											this.renderPriceRemark(
																												instancePrice,
																												isBusiness,
																											)
																										}
																										subTitleNewLine={
																											isBusiness &&
																											this.renderPriceRemark(
																												instancePrice,
																												isBusiness,
																											)
																										}
																										subPrice={`${this.props.t(
																											'common:instancePrice.stepServicePaymentDetails.totalCharge.totalDistance',
																										)} ${
																											Pricing.distance
																										} ${this.props.t(
																											'common:instancePrice.stepServicePaymentDetails.totalCharge.kilometer',
																										)}`}
																										price={Pricing.totalPrice}
																										free={
																											Pricing.totalPrice === 0
																												? true
																												: false
																										}
																										grossPrice={(Pricing.gross_price > Pricing.totalPrice) ? this.formatMoney(Pricing.gross_price) : ''}
																										onPress={() =>
																											updateInstancePriceStep({
																												variables: {
																													createShipment: true,
																													confirmShipment: false,
																													selectTruck: false,
																													additional: false,
																													paymentChanel: false,
																													paymentCOD: false,
																													paymentCredit: false,
																													paymentCreditList: false,
																													promotion: false,
																													priceResult: true,
																													selectDateTime: false,
																													notificationMultiDrop: false,
																												},
																											})
																										}
																									/>

																									<ModalConfirm
																										show={this.state.openModal}
																										labelButtonConfirm={this.props.t('common:instancePrice.modal.promotionError.buttonConfirm')}
																										labelButtonCancel={this.props.t('common:instancePrice.modal.promotionError.buttonCancel')}
																										styleColorButtonConfirm="primary"
																										titleHeader={this.props.t('common:instancePrice.modal.promotionError.title')}
																										onHide={() =>
																											this.setState({
																												openModal: false,
																											})
																										}
																										onPressButtonCancel={() => {
																											this.setState(
																												{ openModal: false },
																												() => {
																													setTimeout(() => {
																														this.setState({
																															openModalPromotion: true,
																														});
																													}, 300);
																												},
																											);
																										}}
																										onPressButtonConfirm={() => {
																											updateInstancePriceStep({
																												variables: {
																													createShipment: false,
																													selectTruck: false,
																													selectDateTime: false,
																													additional: false,
																													paymentChanel: false,
																													paymentCOD: false,
																													paymentCredit: false,
																													paymentCreditList: false,
																													promotion: false,
																													priceResult: false,
																													confirmShipment: true,
																													notificationMultiDrop: false,
																												},
																											}).then(() => {
																												updateInstancePrice({
																													variables: {
																														promotion_name: '',
																													},
																												}).then(() => {
																													this.setState({
																														openModal: false,
																													});
																												});
																											});
																										}}
																									>
																										<div
																											style={{ lineHeight: 1 }}
																										>
																											{Pricing.promotion_error ||
																												this.props.t('common:instancePrice.modal.promotionError.content')}
																										</div>
																									</ModalConfirm>

																									<ModalConfirm
																										show={
																											this.state.openModalAlert
																										}
																										onPressButtonConfirm={() => {
																											updateInstancePrice({
																												variables: {
																													pickupDate: this.state
																														.pickupDate,
																												},
																											}).then(() => {
																												this.props.updateSelectDateMutation(
																													{
																														variables: {
																															isCompleted: true,
																														},
																													},
																												);
																												this.setState({
																													openModalAlert: false,
																												});
																											});
																										}}
																										labelButtonConfirm={this.props.t('common:instancePrice.modal.button.ok')}
																										titleHeader={this.props.t('common:instancePrice.modal.title')}
																										styleColorButtonConfirm="primary"
																										onHide={() =>
																											this.setState({
																												openModalAlert: false,
																											})
																										}
																									>
																										<div
																											style={{ lineHeight: 1 }}
																										>
																											{this.state.alertText
																												.split('\n')
																												.map((item, index) => {
																													return (
																														<div key={index}>
																															{item}
																														</div>
																													);
																												})}
																										</div>
																									</ModalConfirm>

																									<ModalPromotion
																										show={
																											this.state
																												.openModalPromotion
																										}
																										name={
																											instancePrice.promotion_name
																										}
																										onCloseModal={() =>
																											this.setState({
																												openModalPromotion: false,
																											})
																										}
																									/>
																								</div>
																							);
																						}}
																					</Mutation>
																				);
																			}}
																		</Query>
																	) : (
																		<div>
																			<div className="tutorial-select-promotion">
																				<ServicePromotionItem
																					isShowRightIcon
																					Title={this.props.t(
																						'common:instancePrice.stepServicePaymentDetails.promotion.title',
																					)}
																					price={this.props.t(
																						'common:instancePrice.stepServicePaymentDetails.promotion.notApplicable',
																					)}
																					selected={promotion}
																					border
																				/>
																			</div>

																			<div className="tutorial-total-price">
																				<ServicePriceItem
																					style={{ marginTop: 10 }}
																					Title={this.props.t(
																						'common:instancePrice.stepServicePaymentDetails.totalCharge.title',
																					)}
																					subTitle={`(${
																						this.props.instancePriceQuery
																							.instancePrice.shipmentType === 1
																							? this.props.t(
																									'common:instancePrice.stepServicePaymentDetails.totalCharge.labourChargeIsNotIncluded',
																							  )
																							: this.props.t('common:instancePrice.stepServicePaymentDetails.totalCharge.includeAirportSeaport')
																					})`}
																					price={`0.00`}
																					onPress={() =>
																						updateInstancePriceStep({
																							variables: {
																								createShipment: false,
																								selectTruck: false,
																								selectDateTime: false,
																								additional: false,
																								paymentChanel: false,
																								paymentCOD: false,
																								paymentCredit: false,
																								paymentCreditList: false,
																								paymentCreditEdit: false,
																								promotion: false,
																								priceResult: false,
																								searchLocation: true,
																								notificationMultiDrop: false,
																							},
																						}).then(() => {
																							instancePrice.shipmentAddress.filter(
																								item =>
																									item.mode === 'pickup' &&
																									item.latitude === 0 &&
																									item.longitude === 0,
																							).length > 0
																								? this.props.onType('pickup')
																								: this.props.onType('delivery');
																						})
																					}
																				/>
																			</div>
																		</div>
																	)}
																</DropPointContainer>

																{isCanBooking ? this.renderButton(updateInstancePriceStep) : <TemporarilyClosed dangerouslySetInnerHTML={{ __html: this.props.t('common:temporarilyClose') }} /> }
															</div>
														}
													/>
												);
											}}
										</Mutation>
									);
								}}
							</Query>
						);
					}}
				</Query>
			</Container>
		);
	}
}

CreateShipment.propTypes = {
	onClickPickup: PropTypes.func,
};

CreateShipment.defaultProps = {
	height: 15,
};

export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
	graphql(updateLocationTempMutationClient, {
		name: 'locationTempMutation',
	}),
	graphql(selectDateQueryClient, {
		name: 'selectDateQuery',
	}),
	graphql(updateSelectDateMutationClient, {
		name: 'updateSelectDateMutation',
	}),
	graphql(accountInfoListQuery, {
		name: 'accountInfoListQuery',
		fetchPolicy: 'network-only',
	}),
	graphql(shareRouteAddMutation, {
		name: 'shareRouteAddMutation',
	}),
	graphql(currentUserQuery, {
		name: 'currentUserQuery',
	}),
)(withNamespaces()(CreateShipment));
