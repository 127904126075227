import gql from "graphql-tag";

export default gql`
    query lazadaPackageOrderStatus(
        $create_date: String
    ){
    lazadaPackageOrderStatus(
            create_date: $create_date
        )
    {
        total
        new
        outbound_lazada
        inbound_giztix
        cancel
        out_delivery
        delivered
        return_lazada
        }
    }
`;
