import gql from 'graphql-tag';

export default gql`
  mutation UserSettingTruckAdd(
    $user_setting_truck_license_plate: String!
    $driver_status: Boolean
  ) {
    userSettingTruckAdd(
      user_setting_truck_license_plate: $user_setting_truck_license_plate
      driver_status: $driver_status
    ) {
      _id
    }
  }
`;