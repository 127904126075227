import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div``;
const Title = styled.div`
	font-size: 22px;
	font-weight: bold;
`;
const InputContainer = styled.div`
	position: relative;
`;
const InputSearch = styled.input`
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	background-color: #ffffff;
	outline: none;
	padding: 0px 10px;
	box-sizing: border-box;
	height: 36px;
	display: block;
	width: 100%;
	padding-right: 70px;

	&:placeholder {
		color: #888888;
	}
`;
const IconRight = styled.i`
	position: absolute;
	top: 9px;
	right: 10px;
	font-size: 18px;
  color: #888888;
  
  &::after {
    content: '';
    width: 1px;
    height: 36px;
    border-left: 1px solid #e3e3e3;
    position: absolute;
    top: -9px;
    right: 26px;
  }
`;

const IconDelete = styled.i`
  position: absolute;
  top: 9px;
  right: 45px;
  font-size: 18px;
  color: rgb(179, 179, 179);
  cursor: pointer;
`;

class RouteSearch extends React.Component {
	render() {
		return (
			<Container {...this.props}>
				<Title>{this.props.label}</Title>
				<InputContainer>
					<InputSearch
					 	className="search-location__input"
						placeholder={this.props.placeholder}
						value={this.props.inputValue}
						type={this.props.inputType}
						ref={this.props.inputRef}
						onChange={this.props.onChange}
					/>
					<IconRight className="far fa-search" />
					{
						this.props.inputValue && (
							<IconDelete className="fas fa-times-circle" onClick={this.props.onPressDelete} />
						)
					}
				</InputContainer>
			</Container>
		);
	}
}

RouteSearch.propTypes = {
	label: PropTypes.string,
};

RouteSearch.defaultProps = {
	label: '',
};

export default RouteSearch;
