import gql from 'graphql-tag';

export default gql`
	mutation AccountContactUpdate(
		$_id: ID!
		$contact_name: String
		$contact_tel: String
	) {
		accountContactUpdate(
			_id: $_id
			contact_name: $contact_name
			contact_tel: $contact_tel
		) {
			_id
		}
	}
`;
