import gql from 'graphql-tag';

export default gql`
	query ShipmentAddressHistoryList($mode: String) {
		shipmentAddressHistoryList(mode: $mode) {
			shipmentAddressHistory {
        _id
        description
        structured_formatting {
          main_text
          secondary_text
        }
        lat
        lng
        contact_name
        contact_tel
        mode
      }

      shipmentAddressFavorite {
        _id
        description
        structured_formatting {
          main_text
          secondary_text
        }
        lat
        lng
        contact_name
        contact_tel
        mode
      }
		}
	}
`;
