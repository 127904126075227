import gql from 'graphql-tag';

export default gql`
	mutation UpdateCreditCardEdit(
		$id: String
		$bank: String
		$last_digits: String
		$brand: String
		$expiration_month: String
		$expiration_year: String
		$name: String
		$card_default: Boolean
	) {
		updateCreditCardEdit(
			id: $id
			bank: $bank
			last_digits: $last_digits
			brand: $brand
			expiration_month: $expiration_month
			expiration_year: $expiration_year
			name: $name
			card_default: $card_default
		) @client
	}
`;
