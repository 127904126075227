import React from 'react';
import {geolocated} from 'react-geolocated';
import {
	Row,
	Col,
	Button,
	FormGroup,
	ControlLabel,
	FormControl,
	Modal
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, Mutation, Query ,compose} from 'react-apollo';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import updateLocationTempMutationClient from 'src/config/graphqlClient/mutation/updateLocationTemp';
import moment from 'moment';
import getInstancePrice from 'src/view/InstancePrice2/graphql/query/getInstancePrice';
import shipmentPaymentConfirmMutation from './graphql/mutation/shipmentPaymentConfirm';
import modalLoginQueryClient from 'src/config/graphqlClient/query/modalLogin';
import accountInfoListQuery from './graphql/query/accountInfoList';
import currentUserQuery from './graphql/query/currentUser';
import singleUploadMutation from './graphql/mutation/singleUpload';
import client from 'src/config/client';
import URI from 'urijs';
import color from "src/config/color";
import s3 from 'src/config/s3';
import {
	ImageContainer,
	ImageThumbnail,
} from './Styles';

import {
	Card,
	BlankSpace,
	CardHeader,
	BoxServiceItem,
	CreditCardIcon,
	BoxPriceItem,
	ModalAlert,
	Skeleton,
	PDFPreview,
	ModalConfirm,
} from 'src/components';

import { Header } from './../../components';
import { RouteContact, PaymentChannel, ShipmentDetailList } from './components';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import _ from 'lodash';
import Lottie from 'react-lottie'
import animationDot from './lottie/dot.json';
import modeToText from './../../function/modeToText';
import detectLanguageData from './../../function/detectLanguageData';
import { withNamespaces } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';
import Dotdotdot from 'react-dotdotdot';
import dateNowQuery from '../../graphql/query/datenow';
import {
	ShipmentDetail,
	ShipmentAddress,
	SelectDriver,
	ModalBookingConfirmImport,
} from './container';
import Authentication from '../../renderProps/Authentication';
import { InstancePriceContext } from './../../contexts/InstancePriceContext';
import { getWorkingTime } from './../../function/instancePriceTime';

const Container = styled.div`
	width: 100%;
`;

const TitleContainer = styled(Row)`
	margin: 20px 0;
	font-size: 22px;
	font-weight: bold;
	line-height: 1;
`;

const TransporterContainer = styled(Row)``;
const TransporterInner = styled(Col)`
	& .transporter {
		&-detail {
			display: flex;
			flex-direction: column;
		}

		&-title {
			font-size: 18px;
		}

		&-company-name {
			font-size: 18px;
			font-weight: bold;
			line-height: 1;
		}
	}
`;

const Line = styled.div`
  border-bottom: 1px solid #e3e3e3;
`;

const ModalAlertSuccess = styled(Modal)`
  & .modal-dialog {
		width: 400px;
    top: 10%;
    transform: translateY(-50%);
	}
	
	& .modal-body {
		padding: 40px;
	}


	@media(max-width: 767px) {
		.modal-dialog {
			width: calc(100% - 20px);
			margin-top: 40px;
			top: 0;
		}
	}
`;

const backFucn = (instancePriceStepData, updateInstancePriceStep) => {
	updateInstancePriceStep({
		variables: {
			...instancePriceStepData.data.instancePriceStep,
			confirmShipment: false,
			createShipment: true,
			selectDateTime: false,
			selectDateTimeContact: false,
			selectRemark: false,
			contact: false,
		},
	});
};

const ShipmentDetailListContainer = styled.div``;
const ShipmentDetailListStyled = styled(ShipmentDetailList)`
	&:last-child {
		margin-bottom: -10px;
	}
`;

const LottieContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const BookingConfirmListContainer = styled.div`;
	& .row {
		margin: 0px;
	}

	& > div {
		padding: 10px 0px;

		&:first-child {
			margin-top: 20px;
		}

		&:last-child {
			margin-bottom: 30px;
		}

		&:nth-child(odd) {
			background-color: #EEEEEE;
		}
	}
`;

const SubList = styled.div`
    color: #808080;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    padding: 0 0 10px;

    & .title {
        flex: 1;
        margin-right: 10px;
    }

    & .detail {
        text-align: right;
    }
`;


class ConfirmShipment extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			showErrorPayment: false,
			titleErrorPayment : this.props.t('common:instancePrice.modal.paymentError.title.paymentFailed'),
			textErrorPayment : this.props.t('common:instancePrice.modal.paymentError.content'),
			re_shipment: 0,
			latitude: 0,
			longitude: 0,
			userRef: '',
			isOpenModalUserRef: false,
			remark: '',
			isOpenModalRemark: false,
			currentTime: moment(),
			isOpenModalAlertWorkingTime: false,
			isOpenModalDocumentsConfirmDelete: false,
			selectDocumentPathDelete: '',

			//LightBox
			photoIndexLightBox: 0,
			isOpenLightBox: false,
			
			//Modal Cash voucher
			isOpenModalGiztixDiscount: false,

			//Modal Loading Booking //สำหรับแสดงผล lottie
			isOpenModalLoadingBooking: false,
			stepLoadingBooking: 1,
			shipmentId: '', //ไอดี shipment หลังจาก book สำเร็จ

			isOpenModalBookingConfirm: false,
			isValidBookingConfirm: false,

			isOpenModalAlertContactDatetime: false,
			textModalAlertContactDatetime: '',

			isOpenModalBookingConfirmReview: false,

			//receipt doc
			receiptDocIndexLightBox: 0,
			isOpenReceiptDocLightBox: false,

			isOpenModalRemarkBike: false,
			stepModalBike: 1,

			isOpenModalShipmentPaymentConfirmError: false,
			textModalShipmentPaymentConfirmError: '',
			isLoadingConfirm: false,
		};

		this._inputUploadImage = React.createRef();
		this._inputUploadReceiptDoc = React.createRef();
		this.shipmentConfirmTimeout = null;
	}

	static contextType = InstancePriceContext;

	async componentDidMount() {
		window.scrollTo(0, 0);
		this.getDateTimeServer();
	}
	
	getDateTimeServer = async () => {
		try {
			const { data: dateNowData } = await client.query({
				query: dateNowQuery,
				fetchPolicy: 'network-only',
			});
			const { now } = dateNowData.dateNow;
			this.setState({ currentTime: now });
		} catch(error) {
			console.log(error);
			this.setState({ currentTime: null });
		}
	}
	
	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	additionalVariables(data) {
		return data.instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional_qty: item.qty,
			};
		});
	}

	shipmentAdditionalVariables(data) {
		return data.instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional: item.name.th,
				qty: item.qty,
				price: item.price,
				address: item.address,
			};
		});
	}

	getPaymentType(paymentType) {
		if (paymentType === 'creditCard') {
			return 1;
		}
		if (paymentType === 'COD') {
			return 2;
		}
		if (paymentType === 'Invoice') {
			return 3;
		}
	}

	checkRemark() {
		const { remark, setting_truck_type_id } = this.props.instancePriceQuery.instancePrice;
		if(remark === '' && setting_truck_type_id === '5bed286e2a9d8d18e851d7be') {
			this.setState({ isOpenModalRemarkBike: true });
			return false;
		}

		return true;
	}

	clickShipmentPaymentConfirm = async (event, Pricing) => {
		try {
			const checkContact = this.checkContactInformation();
			if(checkContact === false) {
				throw 'Check contact information';
			};

			const checkRemark = this.checkRemark();
			if(checkRemark === false) {
				throw 'Check remark';
			};

			const checkPickupDate = await this.checkPickupDate();

			if(checkPickupDate === false) {
				throw 'Check pickup date';
			};

			setTimeout(() => {
				this.setState({
					isLoadingConfirm: false,
				});
			}, 1000);

			const checkBookingConfirm = this.checkBookingConfirmDetail();
			if(checkContact && checkBookingConfirm) {
				const { data: currentUserData } = await client.query({
					query: currentUserQuery,
					fetchPolicy: 'network-only',
				});

				if (currentUserData.currentUser.result) { //ตรวจสอบ login หรือไม่ ถ้า login แล้วให้ขึ้น popup กำลังดำเนินการจอง...
					const { shipmentType } = this.props.instancePriceQuery.instancePrice;
					if(shipmentType === 1) {
						this.setState({
							isOpenModalLoadingBooking: true,
							stepLoadingBooking: 1,
						});
	
						this.shipmentConfirmTimeout = setTimeout(() => {
							this.handleShipmentPaymentConfirm(Pricing);
							event.preventDefault();
						}, 3000);
					} else if(shipmentType === 2 || shipmentType === 3) {
						this.setState({ isOpenModalBookingConfirmReview: true });
					}
				} else {
					const modalLoginData = client.readQuery({
						query: modalLoginQueryClient,
					});

					client.writeQuery({
						query: modalLoginQueryClient,
						data: {
							modalLogin: {
								...modalLoginData.modalLogin,
								isOpen: true,
							}
						}
					});

					localStorage.removeItem(
						'token',
					);
				}
			}

		} catch(error) {
			this.setState({
				isLoadingConfirm: false,
			});

			console.log(error);
		}
	}

	checkPickupDate = async () => {
		const instancePriceData = client.readQuery({ query: instancePriceQueryClient });
		const { pickupDate, shipmentType } = instancePriceData.instancePrice;
		await this.getDateTimeServer();
		const workingTime = getWorkingTime(this.state.currentTime);

		if (shipmentType === 1) {
			const now = moment(pickupDate,"YYYY-MM-DD HH:mm");
			const then = moment(workingTime,"YYYY-MM-DD HH:mm");
			const diffTime = now.diff(then, "minutes");
			
			if (diffTime < -5) {
				this.setState({
					isOpenModalAlertWorkingTime: true
				});
				
				return false;
			}
		} else if (shipmentType === 2 || shipmentType === 3) {
			const now = moment(pickupDate,"YYYY-MM-DD");
			const then = moment(workingTime,"YYYY-MM-DD");
			const diffTime = now.diff(then, "days");

			if (diffTime < 0) {
				this.setState({
					isOpenModalAlertWorkingTime: true
				});
				
				return false;
			}
		}

		return true;
	}

	checkContactInformation() {
		const { shipmentAddress, shipmentType } = this.props.instancePriceQuery.instancePrice;
		const shipmentAddressAddIndex = shipmentAddress.map(
			(item, index) => ({
				...item,
				index,
			}),
		);

		const shipmentReject = _.reject(shipmentAddressAddIndex, item => {
			if(shipmentType === 1) {
				return item.contactName && item.phoneNumber;
			} else if(shipmentType === 2 || shipmentType === 3) {
				return item.contactName && item.phoneNumber && item.pickupDate;
			}
		});

		if(shipmentReject.length > 0) {
			this.props.locationTempMutation({
				variables: {
					address: undefined,
					addressDefault: undefined,
					latitude: undefined,
					longitude: undefined,
					latitudeCenter: undefined,
					longitudeCenter: undefined,
					isFitBounds: undefined,
					isSelected: undefined,
					shipmentAddressIndex: shipmentReject[0].index,
					type: shipmentReject[0].mode,
					datetime: (shipmentType === 2 || shipmentType === 3) ? shipmentReject[0].pickupDate : undefined,
				},
			});

			const data = client.cache.readQuery({
				query: instancePriceStepQueryClient,
			});
	
			client.cache.writeQuery({
				query: instancePriceStepQueryClient,
				data: {
					instancePriceStep: {
						...data.instancePriceStep,
						contact: true,
						selectDateTimeContact: false,
						selectRemark: false,
					},
				},
			});

			this.setState({ isOpenModalAlertContact: true });
			return false;
		}

		return true;
	}

	checkBookingConfirmDetail() {
		const { shipmentConfirmDetail, shipmentType } = this.props.instancePriceQuery.instancePrice;
		if(shipmentConfirmDetail.blNumber === '' && (shipmentType === 2 || shipmentType === 3)) {
			this.setState({ isOpenModalBookingConfirm: true });
			return false;
		}

		return true;
	}

	shipmentAddressVariables() {
		const { shipmentAddress, roundTrip, paymentType ,shipmentType } = this.props.instancePriceQuery.instancePrice;
		
		let newShipmentAddress = [];
		if(shipmentType === 1){
			//set data like to schema
			newShipmentAddress = shipmentAddress.map(item => ({
				mode: modeToText(item.mode),
				address: item.address,
				lat: item.latitude,
				lng: item.longitude,
				contact_name: item.contactName,
				contact_tel: item.phoneNumber,
				remark: item.remark,
				paid_by_cod: paymentType === 'COD' ? item.paidByCOD : null,
				delivery_date: item.pickupDate,
				save_contact_detail: item.isSaveContact,
				structured_formatting: {
					main_text: item.mainAddress || undefined,
					secondary_text: item.secondaryAddress || undefined,
				}
			}));
		
			if (roundTrip) {
				const roundTripShipmentAddress = {
					mode: 'roundtrip',
					address: newShipmentAddress[0].address,
					lat: newShipmentAddress[0].lat,
					lng: newShipmentAddress[0].lng,
					contact_name: newShipmentAddress[0].contact_name,
					contact_tel: newShipmentAddress[0].contact_tel,
					remark: newShipmentAddress[0].remark,
					delivery_date:  newShipmentAddress[0].pickupDate,
					paid_by_cod: null,
					pick_up_date: null,
				};
		
				return [...newShipmentAddress, roundTripShipmentAddress];
			}
		} else {
			//set data like to schema
			newShipmentAddress = shipmentAddress.map(item => ({
				mode: modeToText(item.mode),
				address: item.address,
				lat: item.latitude,
				lng: item.longitude,
				contact_name: item.contactName,
				contact_tel: item.phoneNumber,
				remark: item.remark,
				paid_by_cod: paymentType === 'COD' ? item.paidByCOD : null,
				pick_up_date: item.pickupDate,
			}));
		}
	 
		return newShipmentAddress;
	}

	shipmentPaymentConfirmVariables() {
		const uri = new URI(`${window.location}`);
		const params = uri.search(true);
		
		if(params.shipment !== undefined){
			this.state = {
				showErrorPayment: false,
				onOpenErrorInvoice: false,
				titleErrorPayment : this.props.t('common:instancePrice.modal.paymentError.title.paymentFailed'),
				textErrorPayment : this.props.t('common:instancePrice.modal.paymentError.content'),
				re_shipment: 1,
				latitude: 0,
				longitude: 0,
				isOpenModalAlertImage: false,
				textAlertModalImage: '',
				isOpenModalAlertContact: false,
				isOpenModalLoadingBooking: false,
				stepLoadingBooking: 1,
			}
		}

		if(this.props.isGeolocationEnabled && this.props.coords != null){
			this.state = {
				showErrorPayment: false,
				onOpenErrorInvoice: false,
				titleErrorPayment : this.props.t('common:instancePrice.modal.paymentError.title.paymentFailed'),
				textErrorPayment : this.props.t('common:instancePrice.modal.paymentError.content'),
				re_shipment: 0,
				latitude: this.props.coords.latitude,
				longitude: this.props.coords.longitude,		
			}
		}

		const { data } = this.props;
		const { __typename: _1, ...settingTruckName } = data.instancePrice.setting_truck_name;
		const { __typename: _2, ...settingTruckTypeName } = data.instancePrice.setting_truck_type_name;

		return {
			setting_truck_name: settingTruckName,
			truck_type_name: settingTruckTypeName,
			setting_truck_id: data.instancePrice.setting_truck_id,
			setting_truck_type_id: data.instancePrice.setting_truck_type_id,
			pick_up_date: data.instancePrice.pickupDate,
			shipment_other_detail_document: data.instancePrice.document.map(item => ({
				path: item.path,
			})),
			shipment_other_detail_user_ref: data.instancePrice.userRef,
			shipment_other_detail_remark: data.instancePrice.remark,
			get_price_address: data.instancePrice.shipmentAddress.map(item => ({
				lat: item.latitude,
				lng: item.longitude,
			})),
			shipment_address: this.shipmentAddressVariables(),
			shipment_additional: this.shipmentAdditionalVariables(data),
			paymentType: this.getPaymentType(data.instancePrice.paymentType),
			paymentCreditCardId: data.instancePrice.creditCard.id,
			paymentCreditCardBank: data.instancePrice.creditCard.bank,
			paymentCreditCardLastDigits: data.instancePrice.creditCard.last_digits,
			paymentCreditCardBrand: data.instancePrice.creditCard.brand,
			paymentCreditCarName: data.instancePrice.creditCard.name,
			additional: this.additionalVariables(data),
			reShipment : this.state.re_shipment,
			promotion_name: data.instancePrice.promotion_name,
			shipment_lat : this.state.latitude,
			shipment_lng : this.state.longitude,
			round_trip: data.instancePrice.roundTrip ? 1 : null,
			shipment_type: data.instancePrice.shipmentType,
			shipment_accessory: data.instancePrice.accessory.map(item => ({accessory_id: item._id})),
			shipment_confirm_detail: data.instancePrice.shipmentType !== 1 ? {
				job_code: data.instancePrice.shipmentConfirmDetail.jobCode,
				bl_number: data.instancePrice.shipmentConfirmDetail.blNumber,
				commodity: data.instancePrice.shipmentConfirmDetail.commodity,
				customer_name: data.instancePrice.shipmentConfirmDetail.customerName,
				invoice_no: data.instancePrice.shipmentConfirmDetail.invoiceNo,
				packaging_type: data.instancePrice.shipmentConfirmDetail.packagingType,
				weight_per_container: data.instancePrice.shipmentConfirmDetail.weightPerContainer,
				receipt_doc: data.instancePrice.shipmentConfirmDetail.receiptDoc.map(item => ({
					path: item.path,
				})),
			} : undefined,
			utm_source: this.getCookie("utm_source"),
			utm_medium: this.getCookie("utm_medium"),
			utm_campaign: this.getCookie("utm_campaign"),
			ip_address: this.getCookie("ip_address"),
			flash_deals_id: data.instancePrice.flashDeals.id,
			show_driver_user_id: data.instancePrice.favoriteDriver.map((item) => item.id),
		};
	}

	handleGoToShipment(_id) {
		return (window.location = `/shipmentdetail?shipment=${_id}`);
	}

	getDiscountPromotion(instancePrice, getInstancePrice) {
		if (instancePrice.promotion_name) {
			if (
				getInstancePrice.promotion_error ||
				!getInstancePrice.promotion.name
			) {
				return '*' + this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.incorrectCondition');
			} else {
				return `-${getInstancePrice.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}`;
			}
		}

		return this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.notApplicable');
	}

	renderPercentage(discountPrice, distancePrice){
		return Math.round((discountPrice / distancePrice) * 100);
	}

	checkFileTypeImage = (files) => {
		if (files.length === 0) {
			return false;
		}

		let validFileSize = true;
		files.forEach(file => {
			validFileSize = validFileSize && (
				file.type === 'image/png' ||
				file.type === 'image/jpeg' ||
				file.type === 'application/pdf'
			);
		});

		return validFileSize;
	}

	checkFileSizeImage = (files) => {
		if (files.length === 0) {
			return false;
		}

		let validFileSize = true;
		files.forEach(file => {
			const size = parseFloat(file.size / 1024).toFixed(2);
			validFileSize = validFileSize && size < 10240;
		});

		return validFileSize;
	};

	convertFileName = (filename) => {
		const fileName = filename.split('.').slice(0, -1).join('.');
		const fileExtension = filename.split('.').pop();
		const editFileName = fileName.match(/[a-zA-Z0-9]+/g).join('-');
	
		return {
			fullFileName: `${editFileName}.${fileExtension}`,
			fileName: editFileName,
			ext: fileExtension,
		};
	}

	handleSingleUploadDocument(uploadFile, documentName , event) {
		if(this.checkFileTypeImage(event.target.files) === false) {
			this.setState({ isOpenModalAlertImage: true, textAlertModalImage: this.props.t('common:instancePrice.modal.uploadFileCheckTypeFile.content') });
			return;
		}

		if(this.checkFileSizeImage(event.target.files) === false) {
			this.setState({ isOpenModalAlertImage: true, textAlertModalImage: this.props.t('common:instancePrice.modal.uploadFileCheckSizeFile.content') });
			return;
		}

		const {
			target: {
				validity,
				files,
			},
		} = event;

		if (validity) {
			files.forEach((file) => {
				const editFileName = this.convertFileName(file.name);
				const timestamp = new Date().getTime();
				uploadFile({
					variables: {
						file: file,
						path: `upload/shipments/${editFileName.fileName}_${documentName}_${timestamp}`,
					},
				});
			});
		}
	}

	onDeletePhoto = (callback) => {
		const data = client.readQuery({
			query: instancePriceQueryClient,
		});

		const { selectDocumentPathDelete } = this.state;
		const document = data.instancePrice.document.filter(
			item => `${s3}/${item.path}` !== selectDocumentPathDelete,
		);

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...data.instancePrice,
					document,
				},
			},
		});

		callback();
	}

	getDiscount(discount, name) {
		return discount.filter(item => item.detail === name);
	}

	renderPaymentCodDropPoint(shipmentAddress) {
		const dropPoint = shipmentAddress.map((item, index) => ({ ...item, index })).filter(item => item.paidByCOD === 1);

		if(dropPoint.length === 0) {
			return '-';
		}

		return this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.payCashAtPoint') + ' ' + (dropPoint[0].index + 1) + ' - ' + (modeToText(dropPoint[0].mode, this.props.t('common:lang')));
	}

	renderFormatDatetime(datetime, index ,type) {
		const format = index === 0  && type !== 1 ? 'DD/MM/YYYY' : 'DD/MM/YYYY - HH:mm';
		return datetime ? moment(datetime, 'YYYY-MM-DD HH:mm').format(format) : '';
	}

	getTitleModeEng(mode) {
		if(mode === 'importPickup' || mode === 'exportPickup') {
			return 'Pickup';
		} else if(mode === 'importDelivery') {
			return 'Delivery';
		} else if(mode === 'importReturn' || mode === 'exportReturn') {
			return 'Return';
		} else if(mode === 'exportLoading') {
			return 'Loading';
		} else {
			return '';
		}
	}

	async handleShipmentPaymentConfirm(Pricing) {
		try {
			await this.props.shipmentPaymentConfirmMutation({
				variables: this.shipmentPaymentConfirmVariables(),
				update: (cache, { data }) => {
					if (
						this.props.data.instancePrice
							.paymentType === 'COD' || this.props.data.instancePrice
							.paymentType === 'Invoice' || Pricing.totalPrice === 0
					) {

						//When Shipment Success
						this.setState({ stepLoadingBooking: 2 });
		
						cache.writeData({
							data: {
								shipmentConfirmStatus: {
									isConfirm: false,
									__typename: 'ShipmentConfirmStatus',
								}
							}
						});

						setTimeout(() => {
							this.setState({ stepLoadingBooking: 3, shipmentId: data.shipmentPaymentConfirm._id });
						}, 2000);
		
						setTimeout(() => {
							this.handleGoToShipment(
								data.shipmentPaymentConfirm
									._id,
							);
						}, 4000);
					}
		
					if (
						this.props.data.instancePrice.paymentType === 'creditCard'
					) {
						window.location.href = data.shipmentPaymentConfirm.uri;
						// this.setState({
						// 	onOpenPayment3Ds: true,
						// 	uri: data.shipmentPaymentConfirm.uri,
						// });
					}
				},
				refetchQueries: () => ['search'],
			});
		} catch(error) {
			console.log('ShipmentPaymentConfirm', error);
			this.setState({
				isOpenModalLoadingBooking: false,
				stepLoadingBooking: 1,
			});

			if (
				error.graphQLErrors.length > 0
			) {
				const errorFirst = error.graphQLErrors[0].message;
				const errorFilter = error.graphQLErrors.filter(
					item =>
						item.message ===
						'You must login.',
				);

				const errorCredit = error.graphQLErrors.filter(
					item =>
						item.message ===
						'the security code is invalid',
				);
				
				const errorNotfoundCredit = error.graphQLErrors.filter(
					item =>
						item.message ===
						'was not found',
				);

				const errorCredit3d = error.graphQLErrors.filter(
					item =>
						item.message ===
						'3d secure is requested but card is not enrolled',
				);

				const errorCreditBalance = error.graphQLErrors.filter(
					item =>
						item.message ===
						"เครดิตคงเหลือไม่เพียงพอ",
				);

				const errorCreditFund = 0;
				// const errorCreditFund = error.graphQLErrors.filter(
				// 	item =>
				// 		item.message ===
				// 		'3d secure is requested but card is not enrolled',
				// );

				if (errorFilter.length > 0) {
					const modalLoginData = client.readQuery({
						query: modalLoginQueryClient,
					});

					client.writeQuery({
						query: modalLoginQueryClient,
						data: {
							modalLogin: {
								...modalLoginData.modalLogin,
								isOpen: true,
							}
						}
					});

					//when shipment error and show modal login
					client.cache.writeData({
						data: {
							shipmentConfirmStatus: {
								isConfirm: true,
								__typename: 'ShipmentConfirmStatus',
							}
						}
					});

					client.cache.writeData({
						data: {
							user: {
								token: '',
								__typename: 'User',
								},
							},
					});
				}

				if (errorCredit.length > 0 || errorNotfoundCredit.length > 0) {
					this.setState({
						showErrorPayment: true,
						titleErrorPayment : this.props.t('common:instancePrice.modal.paymentError.title.incorrectInformation'),
						textErrorPayment : this.props.t('common:instancePrice.modal.paymentError.content')
					});
				} else if (errorCredit3d.length > 0) {
					this.setState({
						showErrorPayment: true,
						titleErrorPayment : this.props.t('common:instancePrice.modal.paymentError.title.creditNotActivated3DSecure'),
						textErrorPayment : this.props.t('common:instancePrice.modal.paymentError.content')
					});
				} else if (errorCreditFund.length > 0) {
					this.setState({
						showErrorPayment: true,
						titleErrorPayment : this.props.t('common:instancePrice.modal.paymentError.title.creditNotEnough'),
						textErrorPayment : this.props.t('common:instancePrice.modal.paymentError.content')
					});
				} else if (errorCreditBalance.length > 0) {
					this.setState({
						onOpenErrorInvoice: true
					});
				} else {
					this.setState({
						isOpenModalShipmentPaymentConfirmError: true,
						textModalShipmentPaymentConfirmError: errorFirst,
					});
				}
			}
		}
	}

	renderReceiptDocLightBox(receiptDocsList) {
		const receiptDocs = receiptDocsList.map(item => {
			const patternCheckFileType = /^.*\.(pdf|PDF)$/;
			const linkFile = `${s3}/${item}`;
			if(patternCheckFileType.test(linkFile)) {
				return (<PDFPreview file={linkFile} />);
			}

			return linkFile;
		});

		const customStyle = {
			overlay: {
				zIndex: 99999,
			}
		}
		
		return (
			<Lightbox
				mainSrc={receiptDocs[this.state.receiptDocIndexLightBox]}
				nextSrc={receiptDocs.length > 1 ? receiptDocs[(this.state.receiptDocIndexLightBox + 1) % receiptDocs.length] : undefined}
				prevSrc={receiptDocs.length > 1 ? receiptDocs[(this.state.receiptDocIndexLightBox + receiptDocs.length - 1) % receiptDocs.length] : undefined}
				onCloseRequest={() => this.setState({ isOpenReceiptDocLightBox: false })}
				onMovePrevRequest={() =>
					this.setState(prev => ({
						receiptDocIndexLightBox: (prev.receiptDocIndexLightBox + receiptDocs.length - 1) % receiptDocs.length,
					}))
				}
				onMoveNextRequest={() =>
					this.setState(prev => ({
						receiptDocIndexLightBox: (prev.receiptDocIndexLightBox + 1) % receiptDocs.length,
					}))
				}
				reactModalStyle={customStyle}
			/>
		)
	}

	getAddressLabel(mode) {
		if(mode === 'importPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.addressLabel.importPickup');
		} else if(mode === 'importDelivery') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.addressLabel.importDelivery');
		} else if(mode === 'importReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.addressLabel.importReturn');
		} else if(mode === 'exportPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.addressLabel.exportPickup');
		} else if(mode === 'exportLoading') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.addressLabel.exportLoading');
		} else if(mode === 'exportReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.addressLabel.exportReturn');
		} else {
			return mode;
		}
	}
	
	getDateLabel(mode) {
		if(mode === 'importPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.dateLabel.importPickup');
		} else if(mode === 'importDelivery') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.dateLabel.importDelivery');
		} else if(mode === 'importReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.dateLabel.importReturn');
		} else if(mode === 'exportPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.dateLabel.exportPickup');
		} else if(mode === 'exportLoading') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.dateLabel.exportLoading');
		} else if(mode === 'exportReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.dateLabel.exportReturn');
		} else {
			return mode;
		}
	}
	
	getContactLabel(mode) {
		if(mode === 'importPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.contactLabel.importPickup');
		} else if(mode === 'importDelivery') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.contactLabel.importDelivery');
		} else if(mode === 'importReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.contactLabel.importReturn');
		} else if(mode === 'exportPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.contactLabel.exportPickup');
		} else if(mode === 'exportLoading') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.contactLabel.exportLoading');
		} else if(mode === 'exportReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.contactLabel.exportReturn');
		} else {
			return mode;
		}
	}
	
	getRemarkLabel(mode) {
		if(mode === 'importPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.remarkLabel.importPickup');
		} else if(mode === 'importDelivery') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.remarkLabel.importDelivery');
		} else if(mode === 'importReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.remarkLabel.importReturn');
		} else if(mode === 'exportPickup') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.remarkLabel.exportPickup');
		} else if(mode === 'exportLoading') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.remarkLabel.exportLoading');
		} else if(mode === 'exportReturn') {
			return this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.remarkLabel.exportReturn');
		} else {
			return mode;
		}
	}

	formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
	
			const negativeSign = amount < 0 ? "-" : "";
	
			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;

			let price = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
			if(price.split(".")[1] == "00"){
				price = price.split(".")[0];
			}
			return price;
		} catch (e) {
			console.log(e)
		}
	};

	getPriceMultiPoint(getInstancePrice) {
		const multiPoint = getInstancePrice.multi_point;

		if (multiPoint) {
			return multiPoint.reduce((sum, item) => {
				return sum + item.price_per_point * item.total_point;
			}, 0);
		}

		return 0;
	}

	getPromotionTitle(Pricing) {
		const {
			discountPrice,
			promotionCodeList,
			distancePrice,
		} = Pricing;

		const {
			for_you, title_lang, name
		} = promotionCodeList;


    if(name) {
			if(for_you) {
				return (
						<div style={{width: 230}}>
								<Dotdotdot clamp={1}>
										{detectLanguageData(title_lang, this.props.t('common:lang'))}
								</Dotdotdot>
						</div>
				);
		}
    
      return `${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.promotionCode')} ${name} [${this.renderPercentage(discountPrice, distancePrice)}%]`;
		}

		return this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.promotionCode');
	}

	closeModalBike() {
		this.setState({ 
			isOpenModalRemarkBike: false,
			remark: '',
		}, () => {
			setTimeout(() => {
				this.setState({ stepModalBike: 1 });
			}, 500);
		});
	}

	getFileNameFormPath = (filePath) => {
		return filePath.replace(/^.*[\\\/]/, '');
	}

	render() {
		const accountInfo = this.props.accountInfoListQuery;
		let cradit_balance = 0;
		let invite_code = null;
		if (!accountInfo.loading && !accountInfo.error && accountInfo.accountInfoList && accountInfo.accountInfoList.account_business !== null) {
			invite_code = accountInfo.accountInfoList.account_business.invite_code;
			cradit_balance = accountInfo.accountInfoList.account_business.business_credit_approved-accountInfo.accountInfoList.account_business.business_credit_used;
		}

		return (
			<div>
				<Query query={instancePriceQueryClient}>
					{instancePriceData => {
						return (
							<div>
								<ModalAlertSuccess show={this.state.isOpenModalLoadingBooking}>
									<ModalAlertSuccess.Body style={{textAlign: 'center'}}>
										{
											(this.state.stepLoadingBooking === 1 || this.state.stepLoadingBooking === 2) && (
												<div style={{width: '180px', margin: '0px auto'}}>
													<img style={{width: '100%'}} src={`${require('src/assets/images/shipment-truck-icon.svg')}`} alt="shipment-truck-icon" id="matching-driver"/>
												</div>
											)
										}

										{
											(this.state.stepLoadingBooking === 3) && (
												<div style={{width: '180px', margin: '0px auto'}}>
													<img style={{width: '100%'}} src={`${require('src/assets/images/shipment-driver-icon.svg')}`} alt="shipment-driver-icon" id="matching-complete" />
												</div>
											)
										}

										<div style={{fontSize: 24, lineHeight: 1, marginTop: 10}}>
											<strong>
												{ this.state.stepLoadingBooking === 1 ? this.props.t('common:instancePrice.shipmentConfirm.bookingInProgress') : ''}
												{ this.state.stepLoadingBooking === 2 ? this.props.t('common:instancePrice.shipmentConfirm.bookingSuccess') : ''}
												{ this.state.stepLoadingBooking === 3 ? this.props.t('common:instancePrice.shipmentConfirm.bookingToTracking').split('\n').map(item => (<div>{item}</div>)) : ''}
											</strong>
										</div>

										{
											(this.state.stepLoadingBooking === 1 || this.state.stepLoadingBooking === 2) && (
												<LottieContainer>
													<Lottie
														options={{
															loop: true,
															autoplay: true, 
															animationData: animationDot,
															rendererSettings: {
																preserveAspectRatio: 'xMidYMid slice'
															},
														}}
														height={30}
														width={100}
														isClickToPauseDisabled={true}
													/>
												</LottieContainer>
											)
										}

										{
											(this.state.stepLoadingBooking === 1 || this.state.stepLoadingBooking === 2) && (
												<div style={{marginTop: 40}}>
													<Button block disabled={this.state.stepLoadingBooking === 2} onClick={() => {
														if (this.shipmentConfirmTimeout) {
															clearTimeout(this.shipmentConfirmTimeout);
															this.shipmentConfirmTimeout = null;
															this.setState({ isOpenModalLoadingBooking: false })
														}
													}}>{this.props.t('common:instancePrice.shipmentConfirm.cancelBooking')}</Button>
												</div>
											)
										}

										{
											(this.state.stepLoadingBooking === 3) && (
												<div style={{marginTop: 40}}>
													<Button bsStyle="primary" block onClick={() => {
														this.handleGoToShipment(this.state.shipmentId);
													}}>ติดตามงานขนส่ง</Button>
												</div>
											)
										}
									</ModalAlertSuccess.Body>
								</ModalAlertSuccess>

								<Query query={instancePriceStepQueryClient}>
									{instancePriceStepData => {
										const documents = instancePriceData.data.instancePrice.document.map(item => {
											const patternFileType = /\.[0-9a-z]{1,5}$/;
											const patternCheckFileType = /^.*\.(pdf|PDF)$/;
											const thumbnail = patternCheckFileType.test(item.path) ? `${require('src/assets/images/file-type-pdf.png')}` : `${s3}/${item.path}`;
											const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

											return {
												path: `${s3}/${item.path}`, //path ไฟล์
												fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
												type: type, //ประเภทไฟล์
												thumbnail: thumbnail, //รูปตัวอย่าง
											}
										});

										const documentsImage = documents.map(item => {
											const patternCheckFileType = /^.*\.(pdf|PDF)$/;
											if(patternCheckFileType.test(item.path)) {
												return (<PDFPreview file={item.path} />);
											}

											return item.thumbnail;
										});

										
										const receiptDocs = instancePriceData.data.instancePrice.shipmentConfirmDetail.receiptDoc.map(item => {
											const patternFileType = /\.[0-9a-z]{1,5}$/;
											const patternCheckFileType = /^.*\.(pdf|PDF)$/;
											const thumbnail = patternCheckFileType.test(item.path) ? `${require('src/assets/images/file-type-pdf.png')}` : `${s3}/${item.path}`;
											const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

											return {
												path: `${s3}/${item.path}`, //path ไฟล์
												fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
												type: type, //ประเภทไฟล์
												thumbnail: thumbnail, //รูปตัวอย่าง
											}
										});

										return (
											<Mutation mutation={updateInstancePriceStepMutationClient}>
												{updateInstancePriceStep => {
													return (
														<Container>
															<Card content={
																<div>
																	<CardHeader
																		backFucn={() => {
																			this.context.onCloseAll();

																			backFucn(
																				instancePriceStepData,
																				updateInstancePriceStep,
																			)
																		}}
																	/>
																	<TitleContainer>{this.props.t('common:instancePrice.shipmentConfirm.title')}</TitleContainer>
																	<TransporterContainer>
																		<TransporterInner sm={12}>
																			<div className="transporter-detail">
																				<div className="transporter-title">{this.props.t('common:instancePrice.shipmentConfirm.transporter')}</div>
																				<div className="transporter-company-name">{this.props.t('common:instancePrice.shipmentConfirm.GIZTIXCompanyName')}</div>
																			</div>
																			<div className="transporter-img"></div>
																		</TransporterInner>
																	</TransporterContainer>
																</div>
															}/>

															<BlankSpace height={10} />

															<ShipmentDetail />

															<BlankSpace height={10} />

															<ShipmentAddress />

															<Authentication>
																{this.props.instancePriceQuery.instancePrice.shipmentType === 1 && (
																	<React.Fragment>
																		<BlankSpace height={10} />
																		<SelectDriver />
																	</React.Fragment>
																)}
															</Authentication>
															
															{
																(this.props.instancePriceQuery.instancePrice.shipmentType === 2 ||
																this.props.instancePriceQuery.instancePrice.shipmentType === 3) && (
																	<div>
																		<BlankSpace height={10} />
																		<Card
																			content={
																				<div>
																					<Header title={this.props.t('common:instancePrice.shipmentConfirm.titleImportantDocument')} style={{ marginBottom: 10 }}/>
																					<ShipmentDetailListContainer>
																						<ShipmentDetailListStyled
																							required
																							title={this.props.t('common:instancePrice.shipmentConfirm.bookingConfirm.title')}
																							icon="fal fa-clipboard-list"
																							rightLabel={this.props.instancePriceQuery.instancePrice.shipmentConfirmDetail.blNumber ? this.props.t('common:instancePrice.shipmentConfirm.bookingConfirm.edit') : this.props.t('common:instancePrice.shipmentConfirm.bookingConfirm.add')}
																							onClick={() => {
																								this.setState({ isOpenModalBookingConfirm: true });
																							}}
																							content={this.props.instancePriceQuery.instancePrice.shipmentConfirmDetail.blNumber && this.props.t('common:instancePrice.shipmentConfirm.bookingConfirm.complete')}
																							border
																						/>
																							{
																								receiptDocs.length > 0 && (
																									<div style={{marginLeft: 35 }}>
																										<div style={{marginTop: 5}}>{this.props.t('common:instancePrice.shipmentConfirm.bookingConfirm.titleReceiptDoc')}</div>
																										<ImageContainer>
																											{
																												receiptDocs.map((item, index) => (
																													<ImageThumbnail>
																														<div className="image">
																															<div className="image-hover">
																																<div className="btn-hover-image" style={{margin: 'auto'}} onClick={() => {
																																	this.setState({
																																		receiptDocIndexLightBox: index,
																																		isOpenReceiptDocLightBox: true,
																																	});
																																}}>
																																	<i className="fal fa-search"></i><span>{ item.type === 'image' ? this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewPicture') : this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewDocument') }</span>
																																</div>
																															</div>
																															<img src={`${item.thumbnail}`} alt="" />
																														</div>
																														<div className="title-filename" title={this.getFileNameFormPath(item.path)}>
																															<Dotdotdot clamp={2}>{this.getFileNameFormPath(item.path)}</Dotdotdot>
																														</div>
																													</ImageThumbnail>
																												))
																											}
																										</ImageContainer>
																									</div>
																								)
																							}
																					</ShipmentDetailListContainer>
																				</div>
																			}
																		/>
																	</div>
																)
															}

															{/* Booking Confirm Import */}
															{
																this.state.isOpenModalBookingConfirm && (
																	<ModalBookingConfirmImport 
																		show={this.state.isOpenModalBookingConfirm}
																		onHide={() => this.setState({ isOpenModalBookingConfirm: false })}	
																	/>
																)
															}

															{ this.state.isOpenReceiptDocLightBox && !this.state.isOpenModalBookingConfirm && this.renderReceiptDocLightBox(instancePriceData.data.instancePrice.shipmentConfirmDetail.receiptDoc.map(item => item.path)) }

															<BlankSpace height={10} />

															<Card
																content={
																	<div id="confirm-shipment-more">
																		<Header title={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.title')} style={{ marginBottom: 10 }}/>
																		
																		<ShipmentDetailListContainer>
																			<Mutation mutation={singleUploadMutation}
																				update={(cache, { data }) => {
																					const instancePriceData = cache.readQuery({
																						query: instancePriceQueryClient,
																					});
																					
																					if (instancePriceData.instancePrice.document.length === 6) {
																						return;
																					}

																					const document = instancePriceData.instancePrice.document.concat(
																						{
																							path: data.singleUpload.path,
																							__typename: 'Document',
																						},
																					);

																					cache.writeQuery({
																						query: instancePriceQueryClient,
																						data: {
																							instancePrice: {
																								...instancePriceData.instancePrice,
																								document,
																							},
																						},
																					});
																				}}

																				onError={(error) => {
																					console.log(error)
																				}}
																			>
																				{
																					(uploadFile) => {
																						return (
																							<input
																								accept="image/jpeg, image/png, application/pdf"
																								onChange={(event) => {
																									const instancePriceData = client.readQuery({
																										query: instancePriceQueryClient,
																									});

																									const filesTotal = instancePriceData.instancePrice.document.length + event.target.files.length;
																									if (filesTotal > 6) {
																										this.setState({
																											isOpenModalAlertImage: true,
																											textAlertModalImage: this.props.t('common:instancePrice.modal.uploadFileProductMaximum.content'),
																										});
																										return;
																									}

																									this.handleSingleUploadDocument(uploadFile, 'document', event);
																								}}
																								type="file"
																								id="file"
																								ref={this._inputUploadImage}
																								style={{display: "none"}}
																								multiple
																							/>
																						)
																					}
																				}
																			</Mutation>
																			
																			<ShipmentDetailListStyled
																				title={`${this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.title')} (${documents.length}/6)`}
																				icon="fal fa-camera"
																				rightLabel={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.add')}
																				border
																				contentImage={documents}
																				onUpload={() => {
																					if(documents.length < 6) { //อัพโหลดได้สูงสุด 6 ไฟล์
																						this._inputUploadImage.current.click();
																					} else {
																						this.setState({ isOpenModalAlertImage: true, textAlertModalImage: this.props.t('common:instancePrice.modal.uploadFileProductMaximum.content') });
																					}
																				}}
																			>
																				{
																					documents.length > 0 && (
																						<ImageContainer>
																							{
																								documents.map((item, index) => (
																									<ImageThumbnail>
																										<div className="image">
																											<div className="image-hover">
																												<div className="btn-hover-image" onClick={() => {
																													this.setState({
																														photoIndexLightBox: index,
																														isOpenLightBox: true,
																													});
																												}}>
																													<i className="fal fa-search"></i><span>{ item.type === 'image' ? this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewPicture') : this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewDocument') }</span>
																												</div>
																												<div className="btn-hover-image" onClick={() => {
																													this.setState({ isOpenModalDocumentsConfirmDelete: true, selectDocumentPathDelete: item.path });
																												}}>
																													<i className="fal fa-trash-alt"></i><span>{ item.type === 'image' ? this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.deletePicture') : this.props.t('common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.deleteDocument') }</span>
																												</div>
																											</div>
																											<img src={`${item.thumbnail}`} alt="" />
																										</div>
																										<div className="title-filename" title={this.getFileNameFormPath(item.path)}>
																											<Dotdotdot clamp={2}>{this.getFileNameFormPath(item.path)}</Dotdotdot>
																										</div>
																									</ImageThumbnail>
																								))
																							}
																						</ImageContainer>
																					)
																				}
																			</ShipmentDetailListStyled>

																			<ModalConfirm
																				show={this.state.isOpenModalDocumentsConfirmDelete}
																				onPressButtonConfirm={() => this.onDeletePhoto(() => {
																					this.setState({ isOpenModalDocumentsConfirmDelete: false });
																				})}
																				labelButtonConfirm="ยืนยัน"
																				titleHeader="คุณต้องการลบรูปภาพสินค้า/เอกสาร ใช่หรือไม่?"
																				labelButtonCancel="ปิด"
																				onPressButtonCancel={() => this.setState({ isOpenModalDocumentsConfirmDelete: false })}
																				styleColorButtonConfirm="primary"
																				onHide={() => this.setState({ isOpenModalDocumentsConfirmDelete: false })}
																			>
																				กด "ยืนยัน" เพื่อทำการลบรูปภาพสินค้า/เอกสาร
																			</ModalConfirm>

																			<ModalConfirm
																				show={this.state.isOpenModalAlertImage}
																				onPressButtonConfirm={() => this.setState({ isOpenModalAlertImage: false })}
																				labelButtonConfirm={this.props.t('common:instancePrice.modal.button.ok')}
																				titleHeader={this.props.t('common:instancePrice.modal.title')}
																				styleColorButtonConfirm="primary"
																				onHide={() => this.setState({ isOpenModalAlertImage: false })}
																			>
																				{ this.state.textAlertModalImage }
																			</ModalConfirm>

																			{ this.state.isOpenLightBox && (
																				<Lightbox
																					mainSrc={documentsImage[this.state.photoIndexLightBox]}
																					nextSrc={documentsImage.length > 1 ? documentsImage[(this.state.photoIndexLightBox + 1) % documentsImage.length] : undefined}
																					prevSrc={documentsImage.length > 1 ? documentsImage[(this.state.photoIndexLightBox + documentsImage.length - 1) % documentsImage.length] : undefined}
																					onCloseRequest={() => this.setState({ isOpenLightBox: false })}
																					onMovePrevRequest={() =>
																						this.setState(prev => ({
																							photoIndexLightBox: (prev.photoIndexLightBox + documentsImage.length - 1) % documentsImage.length,
																						}))
																					}
																					onMoveNextRequest={() =>
																						this.setState(prev => ({
																							photoIndexLightBox: (prev.photoIndexLightBox + 1) % documentsImage.length,
																						}))
																					}
																				/>
																			)}

																			<Mutation
																					mutation={updateInstancePriceMutationClient}
																					variables={{
																						userRef: this.state.userRef
																					}}
																				>
																				{updateInstancePrice => {
																					return (
																						<div>
																							<ModalConfirm
																								show={this.state.isOpenModalUserRef}
																								onPressButtonCancel={() => this.setState({ isOpenModalUserRef: false })}
																								onPressButtonConfirm={() => this.setState({ isOpenModalUserRef: false }, () => updateInstancePrice())}
																								labelButtonConfirm={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.buttonConfirm')}
																								labelButtonCancel={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.buttonCancel')}
																								titleHeader={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.title')}
																								styleColorButtonConfirm="primary"
																							>
																								<FormControl
																									placeholder={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.placeholder')}
																									value={
																										this.state.userRef
																									}
																									onChange={(e) => this.setState({ userRef: e.target.value })}
																								/>
																							</ModalConfirm>
																						</div>
																					);
																				}}
																			</Mutation>

																			<ShipmentDetailListStyled
																				onClick={() => this.setState({ isOpenModalUserRef: true, userRef: this.props.instancePriceQuery.instancePrice.userRef })}
																				title={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.title')}
																				content={instancePriceData.data.instancePrice.userRef}
																				icon="fal fa-clipboard-list"
																				rightLabel={instancePriceData.data.instancePrice.userRef ? this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.edit') : this.props.t('common:instancePrice.shipmentConfirm.moreInformations.refJobCode.add')}
																				border
																			/>

																			<Mutation
																					mutation={updateInstancePriceMutationClient}
																					variables={{
																						remark: this.state.remark
																					}}
																				>
																				{updateInstancePrice => {
																					return (
																						<div>
																							<ModalConfirm
																								show={this.state.isOpenModalRemark}
																								onPressButtonCancel={() => this.setState({ isOpenModalRemark: false })}
																								onPressButtonConfirm={() => this.setState({ isOpenModalRemark: false }, () => updateInstancePrice())}
																								labelButtonConfirm={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.buttonConfirm')}
																								labelButtonCancel={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.buttonCancel')}
																								titleHeader={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.title')}
																								styleColorButtonConfirm="primary"
																							>
																								<FormControl
																									componentClass="textarea"
																									placeholder={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.placeholder')}
																									value={
																										this.state.remark
																									}
																									maxLength={255}
																									onChange={(e) => this.setState({ remark: e.target.value })}
																								/>
																							</ModalConfirm>
																						</div>
																					);
																				}}
																			</Mutation>

																			{
																				instancePriceData.data.instancePrice.shipmentType === 1 && (
																					<ShipmentDetailListStyled
																						style={{whiteSpace: "pre-line"}}
																						onClick={() => this.setState({
																							remark: instancePriceData.data.instancePrice.remark
																						}, () => {
																							if(instancePriceData.data.instancePrice.setting_truck_type_id === '5bed286e2a9d8d18e851d7be') {
																								this.setState({ isOpenModalRemarkBike: true });
																								return;
																							}
																							this.setState({ isOpenModalRemark: true });
																						})}
																						title={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.title')}
																						content={
																							instancePriceData.data.instancePrice.remark
																						}
																						icon="fal fa-file-alt"
																						rightLabel={instancePriceData.data.instancePrice.remark ? this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.edit') : this.props.t('common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.add')}
																					/>
																				)
																			}

																			{
																				(instancePriceData.data.instancePrice.shipmentType === 2 ||
																				instancePriceData.data.instancePrice.shipmentType === 3) && (
																					<ShipmentDetailListStyled
																						style={{whiteSpace: "pre-line"}}
																						onClick={() => {
																							const instancePriceStepData = client.readQuery({
																								query: instancePriceStepQueryClient,
																							});

																							client.writeQuery({
																								query: instancePriceStepQueryClient,
																								data: {
																									instancePriceStep: {
																										...instancePriceStepData.instancePriceStep,
																										selectRemark: true,
																									}
																								}
																							});
																						}}
																						title={this.props.t('common:instancePrice.shipmentConfirm.moreInformations.more.title')}
																						content={
																							instancePriceData.data.instancePrice.remark
																						}
																						icon="fal fa-file-alt"
																						rightLabel={instancePriceData.data.instancePrice.remark ? this.props.t('common:instancePrice.shipmentConfirm.moreInformations.more.edit') : this.props.t('common:instancePrice.shipmentConfirm.moreInformations.more.add')}
																					/>
																				)
																			}
																		</ShipmentDetailListContainer>
																	</div>
																}
															/>

															<BlankSpace height={10} />
															
															<Card
																content={<Query
																	query={getInstancePrice}
																	fetchPolicy="cache-and-network"
																	variables={{
																		additional: this.additionalVariables(
																			instancePriceData.data,
																		),
																		settingTruckTypeId:
																			instancePriceData.data.instancePrice
																				.setting_truck_type_id,
																		address: 
																			instancePriceData.data.instancePrice.shipmentAddress.filter(item => item.latitude !== 0 && item.longitude !== 0).map(item => ({ lat: item.latitude, lng: item.longitude })),
																		promotionName:
																			instancePriceData.data.instancePrice
																				.promotion_name,
																		pickupDate:
																			instancePriceData.data.instancePrice
																				.pickupDate,
																		round_trip: instancePriceData.data.instancePrice
																		.roundTrip ? instancePriceData.data.instancePrice
																		.roundTrip : null,
																		paymentType: this.getPaymentType(instancePriceData.data.instancePrice.paymentType),
																		shipmentType: instancePriceData.data.instancePrice.shipmentType,
																		utm_source: this.getCookie("utm_source"),
																		utm_medium: this.getCookie("utm_medium"),
																		utm_campaign: this.getCookie("utm_campaign"),
																		ip_address: this.getCookie("ip_address"),
																		flash_deals_id: instancePriceData.data.instancePrice.flashDeals.id,
																	}}
																	onCompleted={(data) => {
																		if(this.getDiscount(data.getInstancePrice.discount, 'Cash voucher').length > 0) {
																			const data = client.cache.readQuery({
																				query: instancePriceQueryClient
																			});

																			client.cache.writeQuery({
																				query: instancePriceQueryClient,
																				data: {
																					instancePrice: {
																						...data.instancePrice,
																						promotion_name: '',
																					}
																				}
																			});
																		}
																	}}
																>
																	{({
																		loading,
																		error,
																		data,
																		refetch,
																		variables,
																	}) => {
																		if (loading) return (
																			<Skeleton width={460} height={76} style={{marginTop: 8}}>
																				<rect
																					x="0"
																					y="0"
																					rx="4"
																					ry="4"
																					width="250"
																					height="15"
																				/>

																				<rect
																					x="355"
																					y="0"
																					rx="4"
																					ry="4"
																					width="105"
																					height="15"
																				/>

																				<rect
																					x="0"
																					y="39"
																					rx="3"
																					ry="3"
																					width="130"
																					height="13"
																				/>

																				<rect
																					x="0"
																					y="63"
																					rx="3"
																					ry="3"
																					width="290"
																					height="13"
																				/>
																				
																			</Skeleton>
																		);
																		if (error) return "Error";
																		const Pricing = data.getInstancePrice;
																		const vatList = [
																			{
																				name: this.props.t('common:instancePrice.PriceResult.vat7'),
																				price: Pricing.business_price_vat,
																				priceDisplay: `${this.formatMoney(Pricing.business_price_vat)}`,
																			},
																			{
																				name: this.props.t('common:instancePrice.PriceResult.wht1'),
																				price: Pricing.business_price_wht1,
																				priceDisplay: `-${this.formatMoney(Pricing.business_price_wht1)}`,
																			},
																			{
																				name: this.props.t('common:instancePrice.PriceResult.wht3'),
																				price: Pricing.business_price_wht3,
																				priceDisplay: `-${this.formatMoney(Pricing.business_price_wht3)}`,
																			},
																			{
																				name: this.props.t('common:instancePrice.PriceResult.wht5'),
																				price: Pricing.business_price_wht5,
																				priceDisplay: `-${this.formatMoney(Pricing.business_price_wht5)}`,
																			},
																		];

																		return (
																			<div>
																				<Header title={this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.title')} style={{ marginBottom: 20 }}/>
																				<Modal show={this.state.onOpenErrorInvoice} size="lg">
																					<Modal.Body>
																						<h2>{this.props.t('common:instancePrice.modal.title')}</h2>
																						<p style={{lineHeight:1}}>
																							{this.props.t('common:instancePrice.modal.creditLimit.content').split('\n').map(item => (<div>{item}</div>))}
																						</p>
																						<p style={{lineHeight:1}}>
																							<span style={{width:"120px",float:"left"}}>{this.props.t('common:instancePrice.modal.creditLimit.totalCharge')}: </span> 
																							<b>{Pricing.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',',)} {this.props.t('common:instancePrice.baht')}</b><br/>
																							<span style={{width:"120px",float:"left"}}> {this.props.t('common:instancePrice.modal.creditLimit.remainingCredit')}:</span>  
																							<b style={{color:color.Primary}}>{cradit_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',',)} {this.props.t('common:instancePrice.baht')}</b>
																						</p>
																					</Modal.Body>

																					<Modal.Footer>
																						<div style={{textAlign:"left",float:"left",lineHeight:"0.9"}}>
																							<small>
																								{
																									this.props.t('common:instancePrice.modal.creditLimit.contect').split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
																								}
																							</small>
																							
																						</div>
																						<Button onClick={() => this.setState({ onOpenErrorInvoice : false })} bsStyle="default" >{this.props.t('common:instancePrice.modal.creditLimit.buttonConfirm')}</Button>
																					</Modal.Footer>
																				</Modal>
																				
																				{instancePriceData.data.instancePrice
																					.paymentType === 'COD' ? (
																						<PaymentChannel title={this.renderPaymentCodDropPoint(instancePriceData.data.instancePrice.shipmentAddress)}>
																							<i className="fal fa-hand-holding-usd" />
																						</PaymentChannel>
																					) : instancePriceData.data.instancePrice
																						.paymentType === 'creditCard' ? (
																							<PaymentChannel title={`XXXX  XXXX  XXXX  ${instancePriceData.data.instancePrice.creditCard.last_digits || '-'}`}>
																								<CreditCardIcon
																									brand={
																										instancePriceData.data
																											.instancePrice.creditCard.brand
																									}
																								/>
																							</PaymentChannel>
																						) : instancePriceData.data.instancePrice
																						.paymentType === 'Invoice' ? (
																							<PaymentChannel title={`${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.paymentMethods.invoice')}`}>
																								<i className="fal fa-file-alt" />
																							</PaymentChannel>
																						) :(
																							'-'
																					)}

																				<Line style={{ marginTop: 10 }}/>
																				<div style={{ marginTop: 15 }}>
																					<BoxPriceItem
																						title={`${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.transportationFee')}`}
																						price={`${(data.getInstancePrice.distancePrice -
																							this.getPriceMultiPoint(
																							data.getInstancePrice,
																							))
																							.toString()
																							.replace(
																								/\B(?=(\d{3})+(?!\d))/g,
																								',',
																							)} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}`}
																					/>

																					{
																						Pricing.multi_point ?
																						(
																								<div>
																										<BoxPriceItem
																												title={`${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.multiDropCharge')}`}
																										/>

																										<SubList>
																												<div className="title">{this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.subList.multidrop.01')}</div>
																												<div className="detail">{this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.subList.multidrop.free')}</div>
																										</SubList>

																										{
																												Pricing.multi_point.filter(item => item.distance_point === 1)[0].total_point > 0 && Pricing.multi_point.filter(item => item.distance_point === 1).map(item => (
																														<SubList>
																																<div className="title">{this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.subList.multidrop.02')} [{item.price_per_point} x {item.total_point}]</div>
																																<div className="detail">{`${this.formatMoney(item.price_per_point * item.total_point, 0)} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}`}</div>
																														</SubList>
																												))
																										}

																										{
																												Pricing.multi_point.filter(item => item.distance_point === 2)[0].total_point > 0 && Pricing.multi_point.filter(item => item.distance_point === 2).map(item => (
																														<SubList>
																																<div className="title">{this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.subList.multidrop.03')} [{item.price_per_point} x {item.total_point}]</div>
																																<div className="detail">{`${this.formatMoney(item.price_per_point * item.total_point, 0)} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}`}</div>
																														</SubList>
																												))
																										}

																										<BoxPriceItem
																												title={`${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.totalPriceMultiDrop')}`}
																												price={`${this.formatMoney(this.getPriceMultiPoint(data.getInstancePrice), 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`}
																										/>
																								</div>
																						) : null
																					}
																					
																					<ModalConfirm
																						show={this.state.isOpenModalGiztixDiscount}
																						onPressButtonConfirm={() => this.setState({ isOpenModalGiztixDiscount: false })}
																						labelButtonConfirm={this.props.t('common:instancePrice.modal.giztixDiscount.buttonConfirm')}
																						titleHeader={this.props.t('common:instancePrice.modal.giztixDiscount.title')}
																						styleColorButtonConfirm="primary"
																						onHide={() => this.setState({ isOpenModalGiztixDiscount: false })}
																					>
																						<div>
																							{
																								invite_code ? (
																									this.props.t('common:instancePrice.modal.giztixDiscount.contentRefer').split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
																								) : (
																									this.props.t('common:instancePrice.modal.giztixDiscount.content').split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
																								)
																							}
																						</div>
																					</ModalConfirm>

																					{
																						_.some(Pricing.discount, [
																							'detail', 'ส่วนลด Giztix Business'
																						]) && (
																							<BoxPriceItem
																								title={`
																									${Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].detail} 
																									[${Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].percent}%]
																								`}
																								price={`-${Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.props.t('common:instancePrice.baht')}`}
																								icon='fal fa-info-circle'
																								onPressIcon={() => this.setState({ isOpenModalGiztixDiscount: true })}
																							/>
																						)
																					}

																					<BoxPriceItem
																						title={this.getPromotionTitle(Pricing)}
																						price={this.getDiscountPromotion(this.props.data.instancePrice, Pricing)}
																					/>

																					<Line style={{ marginBottom: 10 }}/>

																					{ Pricing.additional.map(
																						(additional, index, arr) => (
																							<BoxPriceItem
																								key={index}
																								title={additional.price !== 0 ? `${this.props.t('common:lang') === 'th' ? 'ค่า' : ''}${
																									detectLanguageData(additional.name_lang, this.props.t('common:lang'))
																									} [${additional.price
																										.toString()
																										.replace(
																											/\B(?=(\d{3})+(?!\d))/g,
																											',',
																										)} x ${additional.qty}]` : `${this.props.t('common:lang') === 'th' ? 'ค่า' : ''}${detectLanguageData(additional.name_lang, this.props.t('common:lang'))}`}
																								price={additional.price !== 0 ? `${additional.total_price
																									.toString()
																									.replace(
																										/\B(?=(\d{3})+(?!\d))/g,
																										',',
																									)} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}` : this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.free')}
																								border={index === (arr.length - 1)}
																							/>
																						),
																					)}

																					{
																						_.some(Pricing.discount, [
																							'detail', 'Cash voucher'
																						]) && (
																							<div>
																								<BoxPriceItem
																									title={`
																										${Pricing.discount.filter(item => item.detail === 'Cash voucher')[0].detail} 
																									`}
																									price={`-${Pricing.discount.filter(item => item.detail === 'Cash voucher')[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}`}
																									// icon='fal fa-info-circle'
																									onPressIcon={() => this.setState({ isOpenModalGiztixDiscount: true })}
																									border={true}
																								/>
																							</div>
																						)
																					}

																					{
																						(!!Pricing.flash_deals._id && !Pricing.flash_deals.error_flash_deals) && (
																							<div>
																								<BoxPriceItem
																									title={`
																										Flash Deals (${Pricing.flash_deals.name_lang[this.props.t('common:lang')]})
																									`}
																									price={`-${Pricing.flash_deals.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}`}
																									border={true}
																								/>
																							</div>
																						)
																					}

																					{
																						vatList.filter(item => item.price > 0).map((item, index, arr) => {
																							return (
																								<React.Fragment>
																									<BoxPriceItem 
																										title={`${item.name}`}
																										price={`${item.priceDisplay} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`}
																										border={index === (arr.length - 1)}
																									/>
																								</React.Fragment>
																							)
																						})
																					}

																					<BoxPriceItem
																						title={this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.totalCharge')}
																						subTitle={`${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.totalDistance')} ${ this.props.data.instancePrice.roundTrip === 1 ? (Pricing.distance * 2).toLocaleString('en-US',{ maximumFractionDigits: 2 }) : Pricing.distance.toLocaleString('en-US',{ maximumFractionDigits: 2 })} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.kilometer')}`}
																						price={this.formatMoney(Pricing.business_total_price)}
																					/>
																				</div>

																				<Row style={{ marginTop: '20px' }}>
																					<Col xs={12} style={{ paddingLeft: '10px',marginBottom: '30px' }}>
																						<Button
																							id="app-booking-confirm"
																							bsSize="lg"
																							bsStyle="primary"
																							block
																							disabled={this.state.isLoadingConfirm || (
																								this.props.data.instancePrice.shipmentType === 1 ?
																								!moment(this.props.data.instancePrice.pickupDate, 'YYYY-MM-DD HH:mm').isValid() : !moment(this.props.data.instancePrice.shipmentAddress[0].pickupDate, 'YYYY-MM-DD HH:mm').isValid()
																							)}
																							onClick={(e) => {
																								this.setState({
																									isLoadingConfirm: true,
																								});

																								if((Pricing.totalPrice > cradit_balance) && this.props.data.instancePrice.paymentType === 'Invoice'){
																									this.setState({onOpenErrorInvoice: true})
																									return;
																								}

																								this.clickShipmentPaymentConfirm(e, Pricing)
																							}}
																						>
																							{this.props.t('common:instancePrice.shipmentConfirm.buttonConfirmBooking')}{this.state.isLoadingConfirm && '...'}
																						</Button>
																					</Col>
																				</Row>
																				
																				<ModalConfirm
																					show={this.state.isOpenModalAlertContact}
																					onPressButtonConfirm={() => this.setState({ isOpenModalAlertContact: false })}
																					labelButtonConfirm={this.props.t('common:instancePrice.shipmentConfirm.contact.modalInformationEmpty.buttonConfirm')}
																					titleHeader={this.props.t('common:instancePrice.shipmentConfirm.contact.modalInformationEmpty.title')}
																					styleColorButtonConfirm="primary"
																					onHide={() => this.setState({ isOpenModalAlertContact: false })}
																				>
																					<div style={{lineHeight: 1}}>
																						{this.props.t('common:instancePrice.shipmentConfirm.contact.modalInformationEmpty.detail').split('\n').map(item => (<div>{item}</div>))}
																					</div>
																				</ModalConfirm>

																				<ModalConfirm
																					show={this.state.isOpenModalAlertContactDatetime}
																					onPressButtonConfirm={() => this.setState({ isOpenModalAlertContactDatetime: false })}
																					labelButtonConfirm={this.props.t('common:instancePrice.modal.contactRequiredPickupDate.buttonConfirm')}
																					titleHeader={this.props.t('common:instancePrice.modal.title')}
																					styleColorButtonConfirm="primary"
																					onHide={() => this.setState({ isOpenModalAlertContactDatetime: false })}
																				>
																					<div style={{lineHeight: 1}}>
																						{ this.state.textModalAlertContactDatetime }
																					</div>
																				</ModalConfirm>



																				<Mutation
																					mutation={updateInstancePriceMutationClient}
																					variables={{
																						remark: this.state.remark
																					}}
																				>
																				{updateInstancePrice => {
																					return (
																						<div>
																							<ModalConfirm
																								show={this.state.isOpenModalAlertWorkingTime}
																								labelButtonConfirm={`ตกลง`}
																								labelButtonCancel={`ยกเลิก`}
																								styleColorButtonConfirm="primary"
																								titleHeader={`แจ้งเตือน`}
																								onHide={() => {
																									this.setState({
																										isOpenModalAlertWorkingTime: false,
																									});
																								}}
																								onPressButtonCancel={() => {
																									const instancePriceData = client.readQuery({
																										query: instancePriceQueryClient,
																									});

																									const { shipmentType } = instancePriceData.instancePrice;

																									if (shipmentType === 1) {
																										const data = client.readQuery({
																											query: instancePriceStepQueryClient,
																										});
																								
																										client.writeQuery({
																											query: instancePriceStepQueryClient,
																											data: {
																												instancePriceStep: {
																													...data.instancePriceStep,
																													selectDateTime: true
																												},
																											},
																										});
																									}

																									this.setState({
																										isOpenModalAlertWorkingTime: false,
																									});
																								}}
																								onPressButtonConfirm={() => {
																									const instancePriceData = client.readQuery({
																										query: instancePriceQueryClient,
																									});

																									const { shipmentAddress, shipmentType } = instancePriceData.instancePrice;
																									const workingTime = getWorkingTime(this.state.currentTime).format('YYYY-MM-DD HH:mm');
																									if(shipmentType === 1) {
																										client.writeQuery({
																											query: instancePriceQueryClient,
																											data: {
																												instancePrice: {
																													...instancePriceData.instancePrice,
																													pickupDate: workingTime,
																												}
																											}
																										});
																									} else if (shipmentType === 2 || shipmentType === 3) {
																										const shipmentAddressIndex = 1;
																										const newShipmentAddress = shipmentAddress.map((item, key) => {
																											if (key === shipmentAddressIndex) {
																												return {
																													...item,
																													pickupDate: getWorkingTime(this.state.currentTime).format('YYYY-MM-DD 00:00'),
																												};
																											} else if(
																												key > shipmentAddressIndex && shipmentAddress[shipmentAddressIndex].pickupDate !== getWorkingTime(this.state.currentTime).format('YYYY-MM-DD 00:00')
																											) {
																												return {
																													...item,
																													pickupDate: '',
																												}
																											} else {
																												return item;
																											}
																										});

																										client.writeQuery({
																											query: instancePriceQueryClient,
																											data: {
																												instancePrice: {
																													...instancePriceData.instancePrice,
																													pickupDate: workingTime,
																													shipmentAddress: newShipmentAddress,
																												}
																											}
																										});
																									}

																									this.setState({
																										isOpenModalAlertWorkingTime: false
																									});
																								}}
																							>
																								<div style={{lineHeight: 1}}>
																									{instancePriceData.data.instancePrice.shipmentType === 1 && (<span>สามารถจองรถล่วงหน้าได้เร็วที่สุดวันที่ {getWorkingTime(this.state.currentTime).format('DD/MM/YYYY เวลา HH:mm น.')} ต้องการใช้เวลานี้ใช่หรือไม่</span>)}
																									{instancePriceData.data.instancePrice.shipmentType !== 1 && (<span>สามารถจองรถล่วงหน้าได้เร็วที่สุดวันที่ {getWorkingTime(this.state.currentTime).format('DD/MM/YYYY')} ต้องการใช้เวลานี้ใช่หรือไม่</span>)}
																								</div>
																							</ModalConfirm>
																						</div>
																					);
																				}}
																			</Mutation>

																				{
																					(instancePriceData.data.instancePrice.shipmentType === 2 ||
																					instancePriceData.data.instancePrice.shipmentType === 3) && (
																						<ModalConfirm
																							width={920}
																							show={this.state.isOpenModalBookingConfirmReview}
																							styleColorButtonConfirm="primary"
																							onHide={() => this.setState({ isOpenModalBookingConfirmReview: false })}
																							onPressButtonConfirm={(event) => this.setState({ isOpenModalBookingConfirmReview: false }, () => {
																								event.preventDefault();
																								this.shipmentConfirmTimeout = setTimeout(() => {
																									this.handleShipmentPaymentConfirm(Pricing);
																								}, 3000);

																								this.setState({
																									isOpenModalLoadingBooking: true,
																									stepLoadingBooking: 1,
																									isOpenModalBookingConfirmReview: false,
																								});
																							})}
																							labelButtonConfirm={this.props.t('common:instancePrice.modal.reviewBookingConfirm.buttonConfirm')}
																							labelButtonCancel={this.props.t('common:instancePrice.modal.reviewBookingConfirm.buttonCancel')}
																							onPressButtonCancel={() => this.setState({ isOpenModalBookingConfirmReview: false })}
																						>
																							<div style={{lineHeight: 1}}>
																								<div style={{fontSize: 22}}><strong>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.title')}</strong></div>
																								<div style={{fontSize: 18, color: '#B3B3B3'}}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.subTitle')}</div>
																								<div style={{fontSize: 22, marginTop: 25}}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.titleTransportationDetail')} - <strong>{instancePriceData.data.instancePrice.shipmentType === 2 ? this.props.t('common:instancePrice.modal.reviewBookingConfirm.import') : instancePriceData.data.instancePrice.shipmentType === 3 ? this.props.t('common:instancePrice.modal.reviewBookingConfirm.export') : ''}</strong></div>
																								<BookingConfirmListContainer>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.jobCode')}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.jobCode || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.customerName')}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.customerName || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{instancePriceData.data.instancePrice.shipmentType === 2 ? 'B / L Number' : 'Booking Confirmation Number'}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.blNumber || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.invoiceNo')}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.invoiceNo || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.commodity')}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.commodity || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.packagingType')}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.packagingType || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.weightPerContainer')}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentConfirmDetail.weightPerContainer + ' KG.' || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.amountOfContainer')}:</Col>
																										<Col md={8} sm={12}><strong>{ '1 x ' + detectLanguageData(instancePriceData.data.instancePrice.setting_truck_type_name, this.props.t('common:lang')) || '-' }</strong></Col>
																									</Row>

																									<Row>
																										<Col md={4} sm={12}>{this.getAddressLabel(instancePriceData.data.instancePrice.shipmentAddress[0].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[0].address || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.getDateLabel(instancePriceData.data.instancePrice.shipmentAddress[0].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ this.renderFormatDatetime(instancePriceData.data.instancePrice.shipmentAddress[0].pickupDate, 0) || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.getContactLabel(instancePriceData.data.instancePrice.shipmentAddress[0].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[0].contactName + ', ' + instancePriceData.data.instancePrice.shipmentAddress[0].phoneNumber || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{ this.getRemarkLabel(instancePriceData.data.instancePrice.shipmentAddress[0].mode) }:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[0].remark || '-' }</strong></Col>
																									</Row>

																									<Row>
																										<Col md={4} sm={12}>{this.getAddressLabel(instancePriceData.data.instancePrice.shipmentAddress[1].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[1].address || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.getDateLabel(instancePriceData.data.instancePrice.shipmentAddress[1].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ this.renderFormatDatetime(instancePriceData.data.instancePrice.shipmentAddress[1].pickupDate, 1) || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.getContactLabel(instancePriceData.data.instancePrice.shipmentAddress[1].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[1].contactName + ', ' + instancePriceData.data.instancePrice.shipmentAddress[1].phoneNumber || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{ this.getRemarkLabel(instancePriceData.data.instancePrice.shipmentAddress[1].mode) }:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[1].remark || '-' }</strong></Col>
																									</Row>

																									<Row>
																										<Col md={4} sm={12}>{this.getAddressLabel(instancePriceData.data.instancePrice.shipmentAddress[2].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[2].address || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.getDateLabel(instancePriceData.data.instancePrice.shipmentAddress[2].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ this.renderFormatDatetime(instancePriceData.data.instancePrice.shipmentAddress[2].pickupDate, 2) || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.getContactLabel(instancePriceData.data.instancePrice.shipmentAddress[2].mode)}:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[2].contactName + ', ' + instancePriceData.data.instancePrice.shipmentAddress[2].phoneNumber || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{ this.getRemarkLabel(instancePriceData.data.instancePrice.shipmentAddress[2].mode) }:</Col>
																										<Col md={8} sm={12}><strong>{ instancePriceData.data.instancePrice.shipmentAddress[2].remark || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.truckType')}:</Col>
																										<Col md={8} sm={12}><strong>{ detectLanguageData(instancePriceData.data.instancePrice.setting_truck_name, this.props.t('common:lang')) || '-' }</strong></Col>
																									</Row>
																									<Row>
																										<Col md={4} sm={12}>{this.props.t('common:instancePrice.modal.reviewBookingConfirm.content.title.truckNumber')}:</Col>
																										<Col md={8} sm={12}><strong>{ 1 || '-' }</strong></Col>
																										{/* // */}
																									</Row>
																								</BookingConfirmListContainer>
																							</div>
																						</ModalConfirm>
																					)
																				}

																				<ModalConfirm
																					show={this.state.isOpenModalRemarkBike}
																					labelButtonConfirm={this.state.stepModalBike === 1 ? 'ต่อไป >' : 'ยืนยัน'}
																					styleColorButtonConfirm="primary"
																					labelButtonCancel={'< กลับ'}
																					titleHeader={this.state.stepModalBike === 1 ? 'เงื่อนไขบริการขนส่งมอเตอร์ไซค์ขนาดใหญ่ (บังคับ) 1/2' : 'เงื่อนไขบริการขนส่งมอเตอร์ไซค์ขนาดใหญ่ (บังคับ) 2/2'}
																					onHide={() => this.closeModalBike()}
																					onPressButtonCancel={this.state.stepModalBike === 1 ? undefined : this.state.stepModalBike === 2 ? () => this.setState({ stepModalBike: 1 }) : undefined}
																					onPressButtonConfirm={() => {
																						if(this.state.stepModalBike === 1) {
																							this.setState({ stepModalBike: 2 });
																							return;
																						} else if(this.state.stepModalBike === 2) {
																							const instancePriceData = client.readQuery({
																								query: instancePriceQueryClient,
																							});
		
																							client.writeQuery({
																								query: instancePriceQueryClient,
																								data: {
																									instancePrice: {
																										...instancePriceData.instancePrice,
																										remark: this.state.remark,
																									}
																								}
																							});

																							this.closeModalBike();
																						}
																					}}
																					disabledButtonConfirm={this.state.remark === '' && this.state.stepModalBike === 2}
																				>
																					{
																						this.state.stepModalBike === 1 && (
																							<React.Fragment>
																								<div style={{lineHeight: 1.2}}>
																									<div style={{fontWeight: 'bold'}}>เงื่อนไขการใช้บริการ</div>
																									<div>
																										1. จำกัดน้ำหนักรถไม่เกิน 300 กิโลกรัม ต่อเที่ยว สูงสุด 2 คัน<br/>
																										2. ในการขนส่งมอเตอร์ไซค์ลูกค้าจำเป็นต้องมีสำเนาเอกสารรถทุกคัน และมอบให้กับพนักงานขนส่ง ณ จุดรับรถเท่านั้น<br/>
																										3. ขนส่งเฉพาะภายในประเทศเท่านั้น<br/>
																										4. ค่าขนส่งดังกล่าวรวมค่าแรงและอุปกรณ์เสริมแล้ว<br/>
																										5. ประกันการขนส่งสูงสุด 20,000 บาท* 
																									</div>
																									<br/>
																									<div style={{fontWeight: 'bold'}}>เอกสารประกอบการขนส่ง</div>
																									<div>
																										1. สำเนาเล่มทะเบียนรถมอเตอร์ไซค์ (‪‎เล่มสีเขียว)‬<br/>
																										2. สำเนาบัตรประชาชนเจ้าของรถ<br/>
																										3. สำเนาบัตรประชนคนรับรถ (ถ้ามี)
																									</div>
																									<br/>
																									<div style={{fontWeight: 'bold'}}>การปฏิเสธความรับผิดชอบ‬</div>
																									<div>
																										1. เราขอปฏิเสธความรับผิดชอบทุกกรณีหาก รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ ที่ท่านนำมาส่งกับเรา เป็นรถที่ได้ มาโดยผิดกฏหมาย ไม่ว่าจะเป็นรถขโมยมา รถสวมทะเบียนปลอม รถหนีภาษี และ อื่นๆ<br/>
																										2. ในวันส่งมอบ รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ ลูกค้าต้องตรวจสอบความเรียบร้อยของตัว รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ หากเกิดความเสียหายให้แจ้งกับพนักงานขนส่งทันที ก่อนเซ็นรับรถ "หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขนส่ง ของ Giztix ทราบโดยทันที Giztix ขอปฏิเสธความรับผิดชอบทุกกรณี และ ลูกค้าจะไม่สามารถเรียกร้องสิทธิ ค่าชดเชยใดๆทั้งสิ้น"<br/>
																										3. หากตกลงใช้บริการ ส่งรถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ กับ Giztix ถือว่าท่านได้อ่านเงื่อนไขต่างๆ ครบถ้วนทุกอย่างแล้ว และ ลูกค้าจะเรียกร้องสิทธิภายหลังไม่ได้
																									</div>
																								</div>

																								<div style={{padding: 20, margin: '10px 0px', backgroundColor: '#F9F9F9', borderRadius: 6, lineHeight: 1, textAlign: 'center'}}>
																									<div style={{color: '#D90101'}}>**ข้อควรระวัง**</div>
																									<div>
																										"หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขับรถ หรือติดต่อ Giztix โดยทันที ก่อนเซ็นรับมอบ<br/>
																										เราจะถือว่าลูกค้าได้รับรถในสภาพสมบูรณ์เรียบร้อยตามสภาพรถเดิม<br/>
																										และลูกค้าจะไม่สามารถเรียกร้องสิทธิค่าชดเชยภายหลังได้”
																									</div>
																								</div>
																							</React.Fragment>
																						)
																					}

																					{
																						this.state.stepModalBike === 2 && (
																							<FormControl
																								componentClass="textarea"
																								placeholder={`ตัวอย่าง: Honda CBR 650rr ทะเบียน ก1234 กรุงเทพมหานคร, หมวกกันน็อค AGV K3 1 ใบ`}
																								value={
																									this.state.remark
																								}
																								maxLength={255}
																								onChange={(e) => this.setState({ remark: e.target.value })}
																							/>
																						)
																					}
																				</ModalConfirm>

																				<ModalConfirm
																					show={this.state.isOpenModalShipmentPaymentConfirmError}
																					onPressButtonConfirm={() => this.setState({ isOpenModalShipmentPaymentConfirmError: false })}
																					labelButtonConfirm={`ตกลง`}
																					titleHeader={`แจ้งเตือน`}
																					styleColorButtonConfirm="primary"
																					onHide={() => this.setState({ isOpenModalShipmentPaymentConfirmError: false })}
																				>
																					<div style={{lineHeight: 1}}>
																						{ this.state.textModalShipmentPaymentConfirmError }
																					</div>
																				</ModalConfirm>
																			</div>
																		);
																	}}
																</Query>
																}
															/>
														</Container>
													);
												}}
											</Mutation>
										);
									}}
								</Query>

								<ModalAlert
									show={this.state.showErrorPayment}
									onCloseModal={() => this.setState({
										showErrorPayment: false,
									})}
									icon="fal fa-exclamation-triangle"
									title={this.state.titleErrorPayment}
								>
									<div className="text-center">
										{this.state.textErrorPayment}
									</div>
									<hr />
									<Button
										bsStyle="primary"
										block
										style={{ marginTop: 10 }}
										onClick={() => {
											this.setState({
												showErrorPayment: false,
											});
										}}
									>
										{this.props.t('common:instancePrice.modal.button.ok')}
									</Button>
								</ModalAlert>
							</div>
						);
					}}
				</Query>
			</div>
		);
	}
}

ConfirmShipment.propTypes = {
	onClickPickup: PropTypes.func,
};

ConfirmShipment.defaultProps = {
	height: 15,
};
export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	watchPosition: true,
	geolocationProvider: navigator.geolocation,
})(
	compose(
		graphql(instancePriceQueryClient),
		graphql(accountInfoListQuery, {
			name: 'accountInfoListQuery',
			fetchPolicy: 'cache-and-network',
		}),
		graphql(updateLocationTempMutationClient, {
			name: 'locationTempMutation',
		}),
		graphql(instancePriceQueryClient, {
			name: 'instancePriceQuery',
		}),
		graphql(shipmentPaymentConfirmMutation, {
			name: 'shipmentPaymentConfirmMutation',
		}),
		withNamespaces(),
	)(ConfirmShipment),
);

