import gql from 'graphql-tag';

export default gql`
mutation ShipmentsUpdate(
    $shipment_id: ID!
    $cancel_comment: String
    $shipment_status: Int
    $transporter: ID
    $driver_name_under_mama: String
    $driver_tel_under_mama: String
    $backhaul : String
){
    shipmentsUpdate(
        _id:$shipment_id
        cancel_comment: $cancel_comment
        shipment_status: $shipment_status
        transporter: $transporter
        driver_name_under_mama: $driver_name_under_mama
        driver_tel_under_mama: $driver_tel_under_mama
        backhaul : $backhaul
    ){
        _id
    }
  }
`;
