import gql from 'graphql-tag';

export default gql`
	mutation AccountUserUpdate(
		$account_user_display_name: String
		$account_user_phone_code: String
		$account_user_phone_number: String
	) {
		accountUserUpdate(
			account_user_display_name: $account_user_display_name
			account_user_phone_code: $account_user_phone_code
			account_user_phone_number: $account_user_phone_number
		) {
			_id
		}
	}
`;
