import React from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	color: #ffffff;
	margin-top: 5px;

	& input {
		transform: scale(1.2);
		margin: 0px;
		margin-right: 10px;
	}

	& span {
		margin-top: 2px;
	}
`;

const getTimeTomorrow = () => {
	let today = moment();
	let tomorrow = moment(today)
		.add(1, 'days')
		.startOf('day');
	return new Date(tomorrow);
};

const CheckBoxHideModal = props => {
	const [cookies, setCookie, removeCookie] = useCookies([props.name]);

	return (
		<Container>
			<input
				type="checkbox"
				onChange={event => {
					if (event.target.checked) {
						setCookie(props.name, true, { path: '/', expires: getTimeTomorrow() });
					} else {
						removeCookie(props.name);
					}
				}}
			/>{' '}
			<span>ไม่แสดงในวันนี้อีก</span>
		</Container>
	);
};

export default CheckBoxHideModal;
