import React from 'react';
import { Modal, Button } from '../../../../components';
import { Form, Input, Row, Col } from 'antd';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import accountContactAddressUpdateMutation from './graphql/mutation/accountContactAddressUpdate';
import Map from './container/Map';
import ContactDetail from './container/ContactDetail';
import SearchLocation from './container/SearchLocation';
import { ContactAddressContext } from '../../contexts/ContactAddressContext';
import SimpleBar from 'simplebar-react';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	border: 1px solid #e3e3e3;
	min-height: 500px;
	border-radius: 3px;
	overflow: hidden;

	& .left {
		background-color: #ffffff;
		width: 320px;
		border-right: 1px solid #e3e3e3;
	}

	& .right {
		flex: 1;
		background-color: #f9f9f9;
	}
`;

const FormStyle = styled(Form.Item)`
	.ant-form-item-label {
		line-height: 1;
	}

	&.ant-form-item {
		margin-bottom: 15px;
	}

	.ant-input:hover,
	.ant-input:focus {
		border-color: rgba(0, 0, 0, 0.3);
		box-shadow: none;
	}

	.ant-form-explain {
		font-size: 18px;
		line-height: 1;
		min-height: auto;
	}
`;

class ContactAddressEdit extends React.Component {
	static contextType = ContactAddressContext;

	render() {
		const contactEditSchema = Yup.object().shape({
			contactId: Yup.string().required('กรุณากรอกข้อมูล'),
			contactTel: Yup.string()
				.matches(/^[0]\d{8,9}$/, 'รูปแบบไม่ถูกต้อง')
				.required('กรุณากรอกข้อมูล'),
		});

		return (
			<Mutation mutation={accountContactAddressUpdateMutation}>
				{(accountContactAddressUpdate, { loading, error }) => {
					return (
						<Formik
							initialValues={{
								contactId: this.context.state.contactId,
								contactTel: this.context.state.contactTel,
								mainAddress: this.context.state.mainAddress,
							}}
							enableReinitialize={true}
							validationSchema={contactEditSchema}
							onSubmit={values => {
								const {
									lat,
									lng,
									secondaryAddress,
									addressId,
									contactId,
									address,
								} = this.context.state;
								
								accountContactAddressUpdate({
									variables: {
										address: address,
										changeContactId: values.contactId,
										mainAddress: values.mainAddress,
										addressId: addressId,
										contactId: contactId,
										secondaryAddress: secondaryAddress,
										latitude: lat,
										longitude: lng,
									},
									update: (cache, { data }) => {
										if (data.accountContactAddressUpdate._id) {
											this.props.onHide();
										}
									},
									refetchQueries: () => ['AccountContactList'],
								}).catch(error => {
									const textError =
										error.graphQLErrors && error.graphQLErrors.length > 0
											? error.graphQLErrors[0].message
											: error.message;

									alert(textError);
								});
							}}
						>
							{props => {
								const {
									handleChange,
									handleSubmit,
									handleBlur,
									handleReset,
									touched,
									errors,
									values,
								} = props;

								return (
									<Modal
										width={1200}
										title={<div>แก้ไขผู้ติดต่อและสถานที่</div>}
										closable={false}
										visible={this.props.visible}
										onCancel={() => {
											handleReset();
											this.props.onHide();
										}}
										footer={[
											<Button
												key="back"
												onClick={() => {
													handleReset();
													this.props.onHide();
												}}
											>
												ปิด
											</Button>,
											<Button
												key="submit"
												type="primary"
												onClick={() => handleSubmit()}
												disabled={
													loading || !this.context.state.stepContactDetail
												}
												loading={loading}
											>
												ยืนยัน
											</Button>,
										]}
									>
										<Container>
											<div className="left">
												<SimpleBar style={{ maxHeight: 480 }} forceVisible="y">
													<div style={{ padding: 15 }}>
														{this.context.state.stepContactDetail && (
															<ContactDetail formik={props} />
														)}

														{this.context.state.stepSearchLocation && (
															<SearchLocation formik={props} />
														)}
													</div>
												</SimpleBar>
											</div>
											<div className="right">
												<Map />
											</div>
										</Container>
									</Modal>
								);
							}}
						</Formik>
					);
				}}
			</Mutation>
		);
	}
}

export default ContactAddressEdit;
