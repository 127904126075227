import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const IconContainer = styled.div`
	font-size: 24px;
	margin-right: 14px;
	line-height: 1;
	color: ${props => (!props.disabled ? '#000000' : '#B3B3B3')};
`;

const TextContainer = styled.div``;
const Title = styled.div`
	font-size: 18px;
  font-weight: bold;
  line-height: 1;
	color: ${props => (!props.disabled ? '#000000' : '#808080')};
	margin-top: -4px;
`;

const SubTitle = styled.div`
	font-size: 16px;
	line-height: 1;
	color: ${props => (!props.disabled ? '#000000' : '#B3B3B3')};
`;

const Icon = styled.span`
	margin-left: 4px;
	color: #b3b3b3;
	font-size: 14px;
	cursor: pointer;
`;

const BoxDetail = props => {
	return (
		<Container {...props}>
			{
				props.icon && (
					<IconContainer disabled={props.disabled}>
						<i className={props.icon} />
					</IconContainer>
				)
			}
			<TextContainer>
				<Title disabled={props.disabled}>
					{props.title}{' '}
					{props.onPressInfo && (
						<Icon onClick={e => props.onPressInfo && props.onPressInfo(e)}>
							<i className="fal fa-info-circle" />
						</Icon>
					)}
				</Title>
				<SubTitle disabled={props.disabled}>{props.subTitle}</SubTitle>
			</TextContainer>
		</Container>
	);
};

BoxDetail.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	icon: PropTypes.string,
	disabled: PropTypes.bool,
};

BoxDetail.defaultProps = {
	title: '',
	subTitle: '',
	icon: '',
	disabled: false,
};

export default BoxDetail;
