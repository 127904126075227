import React from 'react';
import styled from 'styled-components';
import { Header } from './../../../../components';
import { SubHeader, InputEdit, Card, Select, Label, ModalPhoneOTP, Menu } from '../../components';
import Helmet from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import { compose, graphql, Mutation } from 'react-apollo';
import accountInfoListQuery from './graphql/query/accountInfoList';
import accountInfoListMutation from './graphql/mutation/accountInfoUpdate';
import otpGeneratePhoneMutation from './graphql/mutation/otpGeneratePhone';
import editPasswordMutation from './graphql/mutation/editPassword';
import accountUserUpdatePictureMutation from './graphql/mutation/accountUserUpdatePicture';
import accountUserUpdateMutation from './graphql/mutation/accountUserUpdate';
import s3 from '../../../../config/s3';
import client from '../../../../config/client';
import { Input } from './components';
import singleUploadMutation from './graphql/mutation/singleUpload';
import { withNamespaces } from 'react-i18next';
import { HeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const Container = styled.div`
	background-color: #f9f9f9;
	height: 100vh;
	padding: 20px;
	padding-top: calc(150px + ${heightTopHeader}px);

	@media(max-width: 767px) {
		padding-top: 20px;
	}
`;

const TextHeader = styled.div`
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
`;

const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const ImageProfile = styled.div`
	margin: 20px auto;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 1px solid #F2F2F2;
	position: relative;
	overflow: hidden;

	& i {
		position: absolute;
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%);
		font-size: 50px;
		color: rgba(0, 0, 0, 0.1);
	}

	& img {
		width: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
	}
`;

const Button = styled.button`
	background-color: #FFFFFF;
	color: #000000;
	font-size: 16px;
	padding: 4px 15px;
	border: 1px solid #E3E3E3;
	border-radius: 4px;
	margin: 0 4px;

	&:focus {
		outline: none;
	}

	&:active {
		background-color: #F9F9F9;
	}
`;

class Profile extends React.Component {
	constructor(props) {
		super(props);

		this._inputProfileImage = React.createRef();
		this.state = {
			account_user_display_name: '',
			account_user_display_name_edit: '',
			phoneCode: '66',
			phoneNumber: '',
			phoneNumberEdit: '',
			refOTP: '',
			email: '',
			password: '',
			newPassword: '',
			confirmPassword: '',
			messageErrorPassword: '',
			newPasswordValidate: null,
			confirmPasswordValidate: null,
			isEditInputUsername: false,
			messageErrorUsername: '',
			isEditInputPhoneNumber: false,
			isEditInputEmail: false,
			isEditInputPassword: false,
			isOpenModalPhoneOTP: false,
			account_user_display_picture: '',
			account_users_id: '',
		}
	}

	componentWillReceiveProps(newProps) {
		const { loading, error } = newProps.accountInfoListQuery;
		if (!loading && !error) {
			const {
				accountInfoList: { account_info },
			} = newProps.accountInfoListQuery;
			const accountInfo = account_info[0];
			this.setState({
				account_user_display_name: accountInfo.account_users[0].account_user_display_name,
				account_user_display_name_edit: accountInfo.account_users[0].account_user_display_name,
				phoneNumber: '0' + this.changeFormatPhoneNumberOldUser(accountInfo.account_users[0].account_user_phone_number),
				phoneNumberEdit: '0' + this.changeFormatPhoneNumberOldUser(accountInfo.account_users[0].account_user_phone_number),
				email: accountInfo.account_users[0].account_user_name,
				account_user_display_picture: accountInfo.account_users[0].account_user_display_picture ? (s3 + '/' + accountInfo.account_users[0].account_user_display_picture) : '',
				account_users_id: accountInfo.account_users[0]._id,
			});
		}
	}

	changeFormatPhoneNumberOldUser(phoneNumber) {
		//delete phone code 66
		if(phoneNumber != null){
			return phoneNumber.replace(
				/\b66+/g,
				'',
			);
		}
		else{
			return '';
		}
		
	}

	formatPhoneNumberDisplay(phoneCode, phoneNumber) {
		return '+' + phoneCode + phoneNumber.replace(
			/\b0+/g,
			'',
		);
	}

	onOTPSuccess(status, accountInfoList) {
		if(status) {
			accountInfoList().then(() => {
				setTimeout(() => {
					this.setState({isOpenModalPhoneOTP: false});
				}, 500);
			})
		}
	}

	//Check format phone number
	checkPhoneNumber = phoneNumber => {
		const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
		if (validatePhoneNumber.test(phoneNumber)) {
			return true;
		}

		return false;
	};

	//Check format password
	checkPassword = password => {
		if (password.length >= 6) {
			//รหัสผ่านต้องมีอย่างน้อย 6 ตัว
			return true;
		}

		return false;
	};

	matchPassword = () => {
		if (this.state.confirmPassword) {
			if (this.state.newPassword === this.state.confirmPassword) {
				this.setState({ confirmPasswordValidate: true });
			} else {
				this.setState({ confirmPasswordValidate: false });
			}
		}
	};

	// getBase64(file, cb) {
	// 	let reader = new FileReader();
	// 	reader.readAsDataURL(file);
	// 	reader.onload = (event) => {
	// 		const img = new Image();
	// 		img.src = event.target.result;
	// 		img.onload = function () {
	// 			const square = 200;

	// 			if(this.width >= square && this.height >= square) {
	// 				const elem = document.createElement('canvas');
	// 				elem.width = square;
	// 				elem.height = square;
	// 				const context = elem.getContext('2d');
	// 				context.clearRect(0, 0, square, square);
	// 				var imageWidth;
	// 				var imageHeight;
	// 				var offsetX = 0;
	// 				var offsetY = 0;

	// 				if (this.width > this.height) {
	// 					imageWidth = Math.round(square * this.width / this.height);
	// 					imageHeight = square;
	// 					offsetX = - Math.round((imageWidth - square) / 2);
	// 				} else {
	// 						imageHeight = Math.round(square * this.height / this.width);
	// 						imageWidth = square;    
	// 						offsetY = - Math.round((imageHeight - square) / 2);            
	// 				}
					
	// 				context.drawImage(this, offsetX, offsetY, imageWidth, imageHeight);
	// 				const base64 = context.canvas.toDataURL('image/png', 1.0);
	// 				cb(base64);
	// 			}
	// 		}
	// 	};
	// 	reader.onerror = function (error) {
	// 			console.log('Error: ', error);
	// 	};
	// }


	// handleUploadProfileImage(accountUserUpdatePicture) {
	// 	const file = this._inputProfileImage.current.files[0];
	// 	if(file) {
	// 		if(file.type === 'image/png' || file.type === 'image/jpeg') {
	// 			this.getBase64(file, (result) => {
	// 				this.setState({profileImageBase64: result})
	// 				accountUserUpdatePicture({
	// 					variables: {
	// 						account_user_display_picture: result,
	// 					},
	// 				});
	// 			});
	// 		}
	// 		this.getBase64(file, (result) => {
	// 			if(file.type === 'image/png' || file.type === 'image/jpeg') {
	// 				this.setState({profileImageBase64: result})
	// 				accountUserUpdatePicture({
	// 					variables: {
	// 						account_user_display_picture: result,
	// 					},
	// 				});
	// 			}
	// 		});
	// 	}
	// }

	handleClickUploadProfileImage() {
		this._inputProfileImage.current.click();
  }

  checkFileTypeImage(file) {
		if(file) {
			if(file.type === 'image/png' || file.type === 'image/jpeg') {
				return true;
			} else {
				return false;
			}
		}
  }
  
  checkFileSizeImage(file) {
		if(file) {
			const size = parseFloat(file.size / 1024).toFixed(2);
			if(size < 10240) { //ขนาดไฟล์ 10240KB or 10Mb
				return true;
			} else {
				return false;
			}
		}
	}

  handleSingleUploadProfileImage(uploadFile, documentName , event) {
		const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
		if(checkFileTypeImage === false) {
			this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png และ jpg เท่านั้น' });
			return;
		}

		const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
		if(checkFileSize === false) {
			this.setState({ isOpenModalAlertImage: true, textAlertModalImage: this.props.t('common:instancePrice.modal.uploadFileCheckSizeFile.content') });
			return;
		}

		const {
			target: {
				validity,
				files: [file]
			}
		} = event;

		if(validity) {
			const timestamp = new Date().getTime();
			uploadFile({ variables: {
				file: file,
				path: `upload/profile/${documentName}_${timestamp}`
			}})	
		}
  }

	render() {
		return (
			<div style={{...this.props.style }}>
				{
					this.state.isOpenModalPhoneOTP ? (
						<Mutation mutation={accountUserUpdateMutation}
							variables={{
								account_user_phone_code: this.state.phoneCode.toString(),
								account_user_phone_number: this.state.phoneNumber
									.toString()
									.replace(/\b0+/g, ''),
							}}
							update={(cache, { data }) => {
								const _id = data.accountUserUpdate._id;
								if(_id) {
									this.setState({ isEditInputPhoneNumber: false });
								}
							}}
						>
							{
								(accountInfoList) => {
									return (
										<ModalPhoneOTP callback={(status) => this.onOTPSuccess(status, accountInfoList)}  refOTP={this.state.refOTP} phoneCode={this.state.phoneCode} phoneNumber={this.state.phoneNumber} show={this.state.isOpenModalPhoneOTP} onHide={() => this.setState({isOpenModalPhoneOTP: false})}></ModalPhoneOTP>
									)
								}
							}
						</Mutation>
					) : null
				}
				<Helmet>
					<title>แก้ไขบัญชีผู้ใช้ | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full={true} />
				<SubHeader backLink="/" />

				<Container>
					<Row>
						<Col lg={2} md={3} sm={12}>
							<Menu />
						</Col>
						<Col lg={8} md={6} sm={12}>
							<Card>
								<TextHeader>{this.props.t('common:setting.profile.title')}</TextHeader>
								<TextSubHeader>
									{this.props.t('common:setting.profile.subTitle')}
								</TextSubHeader>

								{
									!this.props.accountInfoListQuery.loading ? (
										<Row>
											<Col md={6} sm={9}>
												<Label style={{ marginTop: 20 }}>{this.props.t('common:setting.profile.input.displayName.label')}</Label>
												<Mutation mutation={accountUserUpdateMutation}
													variables={{ account_user_display_name: this.state.account_user_display_name }}
													update={(cache, { data }) => {
														const _id = data.accountUserUpdate._id;
														if(_id) {
															this.setState({ isEditInputUsername: false });
														}
													}}
													onError={(error) => {
														this.setState({ messageErrorUsername: 'เกิดข้อผิดพลาดบางอย่าง' })
													}}
													refetchQueries={() => ['search', 'AccountInfoList']}
												>
													{
														(accountUserUpdate) => {
															return (
																<InputEdit
																	value={this.state.account_user_display_name_edit}
																	onChange={event =>
																		this.setState({ account_user_display_name_edit: event.target.value })
																	}
																	edited={this.state.isEditInputUsername}
																	placeholder={this.props.t('common:setting.profile.input.displayName.placeholder')}
																	type="text"
																	onClickButtonChange={() =>
																		this.setState({ isEditInputUsername: true, account_user_display_name_edit: this.state.account_user_display_name })
																	}
																	onClickButtonCancel={() =>
																		this.setState({ isEditInputUsername: false, account_user_display_name_edit: this.state.account_user_display_name, messageErrorUsername: '' })
																	}
																	onClickButtonSave={() => {
																		this.setState({ messageErrorUsername: '', account_user_display_name: this.state.account_user_display_name_edit }, () => {
																			accountUserUpdate();
																		});
																	}}
																	disabledButtonSave={!this.state.account_user_display_name_edit}
																	messageError={this.state.messageErrorUsername}
																	textLabelChange={this.props.t('common:setting.profile.input.buttonChange')}
																	textLabelSave={this.props.t('common:setting.profile.input.buttonSave')}
																	textLabelCancel={this.props.t('common:setting.profile.input.buttonCancel')}
																/>
															)
														}
													}
												</Mutation>

												<Label style={{ marginTop: 20 }}>{this.props.t('common:setting.profile.input.phoneNumber.label')}</Label>
												{
													!this.state.isEditInputPhoneNumber ? (
														<InputEdit
															placeholder="+66 12 345 6789"
															type="text"
															value={this.formatPhoneNumberDisplay(this.state.phoneCode, this.state.phoneNumber)}
															onClickButtonChange={() => this.setState({ isEditInputPhoneNumber: true })}
															textLabelChange={this.props.t('common:setting.profile.input.buttonChange')}
															textLabelSave={this.props.t('common:setting.profile.input.buttonSave')}
															textLabelCancel={this.props.t('common:setting.profile.input.buttonCancel')}
														/>
													) : null
												}
												
												{
													this.state.isEditInputPhoneNumber ? (
														<InputGroup>
															<Select style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} value={this.state.phoneCode} onChange={(event) => this.setState({phoneCode: event.target.value})}>
																<option value="66">+66</option>
															</Select>
															
															<Mutation
																mutation={otpGeneratePhoneMutation}
																variables={{
																	phone:
																		this.state.phoneCode +
																		this.state.phoneNumber.replace(
																			/\b0+/g,
																			'',
																		),
																}}
																update={(cache, { data }) => {
																	this.setState({
																		isOpenModalPhoneOTP: true,
																		refOTP: data.otpGeneratePhone.ref,
																	});
																}}
															>
															 {
																 (otpGeneratePhone) => {
																	 return (
																		 <InputEdit
																				style={{width: '100%'}}
																				styleInput={this.state.isEditInputPhoneNumber ? {borderTopLeftRadius: 0, borderBottomLeftRadius: 0} : null}
																				placeholder={this.props.t('common:setting.profile.input.phoneNumber.placeholder')}
																				type="text"
																				value={this.state.phoneNumberEdit}
																				edited={this.state.isEditInputPhoneNumber}
																				onChange={event =>
																					this.setState({ phoneNumberEdit: event.target.value })
																				}
																				onClickButtonChange={() => this.setState({ isEditInputPhoneNumber: true, phoneNumberEdit: this.state.phoneNumber })}
																				onClickButtonCancel={() => this.setState({ isEditInputPhoneNumber: false, phoneNumberEdit: this.state.phoneNumber })}
																				onClickButtonSave={() => this.setState({ phoneNumber: this.state.phoneNumberEdit }, () => { otpGeneratePhone() })}
																				disabledButtonSave={!this.checkPhoneNumber(this.state.phoneNumberEdit)}
																				textLabelChange={this.props.t('common:setting.profile.input.buttonChange')}
																				textLabelSave={this.props.t('common:setting.profile.input.buttonSave')}
																				textLabelCancel={this.props.t('common:setting.profile.input.buttonCancel')}
																			/>
																	 )
																 }
															 }
															</Mutation>
														</InputGroup>
													) : null
												}
												
												<Label style={{ marginTop: 20 }}>{this.props.t('common:setting.profile.input.email.label')}</Label>
												<Input
													placeholder="user@email.com"
													type="text"
													value={this.state.email}
													disabled
													style={{marginRight: 52}}
												/>

												<Label style={{ marginTop: 20 }}>{this.props.t('common:setting.profile.input.password.label')}</Label>
												{
													this.state.isEditInputPassword ? (
														<Input
															style={{marginRight: 52}}
															placeholder={this.props.t('common:setting.profile.input.oldPassword.placeholder')}
															type="password"
															value={this.state.password}
															onChange={(event) => this.setState({ password: event.target.value })}
															disabled={this.isEditInputPassword}
															name="oldpassword"
															messageError={this.state.messageErrorPassword}
														/>
													) : null
												}
												
												{
													this.state.isEditInputPassword ? (
														<Input
															style={{marginTop: 6, marginRight: 52}}
															placeholder={this.props.t('common:setting.profile.input.newPassword.placeholder')}
															type="password"
															value={this.state.newPassword}
															onChange={(event) => this.setState({ newPassword: event.target.value }, () => {
																if(this.checkPassword(this.state.newPassword)) {
																	this.setState({ newPasswordValidate: false }, () => {
																		this.matchPassword();
																	})
																} else {
																	this.setState({ newPasswordValidate: true }, () => {
																		this.matchPassword();
																	})
																}
															})}
															disabled={this.isEditInputPassword}
															name="newpassword"
															messageError={this.state.newPasswordValidate && this.props.t('common:setting.profile.input.newPassword.placeholder')}
														/>
													) : null
												}

												<Mutation mutation={editPasswordMutation}
													variables={{
														new_password: this.state.newPassword,
														old_password: this.state.password,
													}}

													update={(cache, { data }) => {
														const succeed = data.editPassword.succeed;
														if(succeed) {
															this.setState({ 
																isEditInputPassword: false,
																password: '',
																newPassword: '',
																confirmPassword: '',
																messageErrorPassword: '',
															})
														}
													}}

													onError={(error) => {
														const err_message = error.graphQLErrors.length ? error.graphQLErrors[0].message : null;
														if(err_message === "The old password you have entered is incorrect.") {
															this.setState({messageErrorPassword: "รหัสผ่านไม่ถูกต้อง"});
														} else if(err_message === "You must login.") {
															this.setState({messageErrorPassword: "กรุณาเข้าสู่ระบบ"});
														} else {
															this.setState({messageErrorPassword: "เกิดข้อผิดพลาดบางอย่างลองอีกครั้ง"})
														}
													}}
												>
													{
														(editPassword) => {
															return (
																<InputEdit
																	style={{marginTop: 6}}
																	placeholder={this.state.isEditInputPassword ? this.props.t('common:setting.profile.input.confirmPassword.placeholder') : this.props.t('common:setting.profile.input.password.placeholder')}
																	type="password"
																	value={this.state.confirmPassword}
																	onChange={(event) => this.setState({ confirmPassword: event.target.value }, () => {
																		this.matchPassword();
																	})}
																	onClickButtonChange={() => this.setState({ isEditInputPassword: true })}
																	onClickButtonCancel={() => this.setState({ messageErrorPassword: '', isEditInputPassword: false,password: '', newPassword: '', confirmPassword: '', passwordValidate: null, newPasswordValidate: null, confirmPasswordValidate: null })}
																	onClickButtonSave={() => editPassword()}
																	disabledButtonSave={!this.state.password || !this.checkPassword(this.state.newPassword) || !(this.state.newPassword === this.state.confirmPassword)}
																	edited={this.state.isEditInputPassword}
																	name="confirmpassword"
																	messageError={this.state.confirmPasswordValidate === false && 'รหัสผ่านไม่ตรงกัน'}
																	textLabelChange={this.props.t('common:setting.profile.input.buttonChange')}
																	textLabelSave={this.props.t('common:setting.profile.input.buttonSave')}
																	textLabelCancel={this.props.t('common:setting.profile.input.buttonCancel')}
																/>
															)
														}
													}
												</Mutation>
											</Col>
											<Col sm={6}>
												<div style={{textAlign: 'center', marginTop: 20}}>
													<div style={{fontWeight: 'bold'}}>{this.props.t('common:setting.profile.input.profileImage.label')}</div>
													<ImageProfile>
														{
															this.state.account_user_display_picture ? (<img src={`${this.state.account_user_display_picture}`} alt="" />) : (<i className="fas fa-user"></i>)
														}
													</ImageProfile>

													<Mutation
														mutation={singleUploadMutation}
														update={async (cache, { data }) => {
															await this.props.accountUserUpdatePictureMutation({
																variables: {
																	account_user_display_picture: data.singleUpload.path,
																},
																refetchQueries: () => ['search', 'AccountInfoList'],
															})
															.then(({ data }) => {
																this.setState({ account_user_display_picture: this.state.profileImageBase64 })
															}).catch((error) => {
																alert('เกิดข้อผิดพลาดบางอย่าง ลองใหม่อีกครั้ง.')
															});
														}}
														onError={(error) => {
															console.log(error)
														}}
													>
														{
															(uploadFile) => {
																return (
																	<input accept="image/jpeg, image/png" onChange={(event) => this.handleSingleUploadProfileImage(uploadFile, 'profileImage_' + this.state.account_users_id, event)} type="file" id="file" ref={this._inputProfileImage} style={{display: "none"}}/>
																)
															}
														}
													</Mutation>

													<Button onClick={() => this.handleClickUploadProfileImage()}>{this.props.t('common:setting.profile.input.profileImage.button')}</Button>
												</div>
											</Col>
										</Row>
									) : 'loading...'
								}
							</Card>
						</Col>
						<Col lg={2} md={3} sm={12}>
							
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default compose(
	graphql(accountInfoListQuery, {
		name: 'accountInfoListQuery',
		fetchPolicy: 'cache-and-network',
	}),
	graphql(accountUserUpdatePictureMutation, {
		name: 'accountUserUpdatePictureMutation',
	})
)(withNamespaces()(Profile));
