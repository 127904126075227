import React from 'react';
import styled from 'styled-components';
import {
	Modal,
	Button,
	MenuItem,
	FormGroup,
	InputGroup,
	DropdownButton,
	FormControl,
	Row,
	Col,
} from 'react-bootstrap';
import { CardHeader, Input, ButtonLink } from 'src/components';
import { compose, graphql, Mutation } from 'react-apollo';
import color from 'src/config/color';
import moment from 'moment';

//GraphQL Server
import signinMutation from './graphql/mutation/signin';
import otpGeneratePhoneMutation from './graphql/mutation/otpGeneratePhone';
import getOTPMutation from './graphql/mutation/getOTP';
import accountInfoUpdateMutation from './graphql/mutation/accountInfoUpdate';
import accountInfoListQuery from './graphql/query/accountInfoList';
import getInstancePriceQuery from './graphql/query/getInstancePrice';
//GraphQL Client
import updateModalRegisterMutationClient from 'src/config/graphqlClient/mutation/updateModalRegister';
import updateModalForgetPasswordMutationClient from 'src/config/graphqlClient/mutation/updateModalForgetPassword';
import updateModalLoginMutationClient from 'src/config/graphqlClient/mutation/updateModalLogin';
import modalLoginQueryClient from 'src/config/graphqlClient/query/modalLogin';
import userQueryClient from 'src/config/graphqlClient/query/user';
import shipmentConfirmStatusQueryClient from 'src/config/graphqlClient/query/shipmentConfirmStatus';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import creditCardDefaultQueryClient from 'src/config/graphqlClient/query/creditCardDefault';
import shipmentLastPaymentQuery from './graphql/query/shipmentLastPayment';
import creditCardListQuery from './graphql/query/creditCardList';

//Render Props
import { ShipmentConfirm } from 'src/renderProps';

import client from 'src/config/client';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	margin-top: 28px;

	.btn-login {
		height: 40px;
		margin-top: 40px;
	}
`;

const MessageContainer = styled.div`
	text-align: left;
	color: #b3b3b3;
	margin-bottom: 5px;

	& i {
		font-size: 16px;
	}
`;

const ModalContainer = styled(Modal)`
	.modal-dialog {
		margin-top: 120px;
		width: 380px;
	}

	@media (max-width: 767px) {
		.modal-dialog {
			width: calc(100% - 20px);
			margin-top: 40px;
		}
	}
`;

const IconRightInput = styled.i`
	display: inline-block;
	position: absolute;
	font-size: 16px;
	color: ${props => props.color || '#b3b3b3'};
	right: 10px;
	top: 10px;
	z-index: 10; //ให้อยู่บนสุดเสมอ
`;

const ButtonConfirm = styled(Button)`
	height: 40px;
	margin-top: 30px;
`;

const OTPRefContainer = styled.div`
	margin-top: 40px;
	text-align: center;
	color: #808080;
`;

const InputOTPContainer = styled.div`
	margin-top: 20px;
	text-align: center;
	margin-bottom: 20px;
`;

const InputOTP = styled.input`
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	text-align: center;
	width: 48px;
	height: 56px;
	margin-right: 18px;
	font-size: 30px;
`;

const OTPMessageContainer = styled.div`
	text-align: center;
	color: ${props => props.color || '#b3b3b3'};
	margin-bottom: 5px;
	line-height: 1;

	& i {
		font-size: 16px;
	}
`;

const ModalRightContainer = styled.div`
	padding: 30px 15px 30px 15px;
`;

class ModalLogin extends React.Component {
	constructor(props) {
		super(props);

		this._inputOTP1 = React.createRef();
		this._inputOTP2 = React.createRef();
		this._inputOTP3 = React.createRef();
		this._inputOTP4 = React.createRef();

		this.state = {
			email: '',
			password: '',
			error_message: '',
			hidePassword: true,
			stepPage: 1,
			phoneNumberValidate: null,
			account_info_phone_number: '',
			account_info_phone_code: '66',
			loading: false,
			ref: '',
			otp1: '',
			otp2: '',
			otp3: '',
			otp4: '',
			otpValid: '',
			otpInvalid: '',
			time: '05:00',
			account_token_key: '',
			isOldUser: false,
		};
	}

	handleClose = updateModalLogin => {
		if (
			window.location.pathname !== '/register-business' &&
			window.location.pathname !== '/shipment'
		) {
			updateModalLogin({ variables: { isOpen: false } }).then(() => {
				setTimeout(() => {
					this.setState(
						{
							email: '',
							password: '',
							error_message: '',
							hidePassword: true,
							stepPage: 1,
							phoneNumberValidate: null,
							account_info_phone_number: '',
							account_info_phone_code: '66',
							loading: false,
							ref: '',
							otp1: '',
							otp2: '',
							otp3: '',
							otp4: '',
							otpValid: '',
							otpInvalid: '',
							time: '05:00',
							account_token_key: '',
							isOldUser: false,
						},
						() => {
							client.cache.writeData({
								data: {
									shipmentConfirmStatus: {
										isConfirm: false,
										__typename: 'ShipmentConfirmStatus',
									},
								},
							});
						}
					);
				}, 500);
			});
		}
	};

	onSignin(signin) {
		this.setState({ error_message: '' }, () => {
			signin();
		});
	}

	//เลือก phone code
	handleSelectPhoneCode = account_info_phone_code => {
		this.setState({ account_info_phone_code });
	};

	//Check format phone number
	checkPhoneNumber = phoneNumber => {
		const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
		if (validatePhoneNumber.test(phoneNumber)) {
			return true;
		}

		return false;
	};

	onSubmitOTPGenerate(e, otpGeneratePhone) {
		const chkPhoneNumberValid = this.checkPhoneNumber(
			this.state.account_info_phone_number
		);
		if (
			(e.type === 'click' || (e.type === 'keypress' && e.charCode === 13)) &&
			chkPhoneNumberValid
		) {
			this.setState({ loading: true, error_message: '' }, e => {
				otpGeneratePhone();
			});
		}
	}

	//รีเซ็ตเวลา OTP
	durationTime = () => {
		clearTimeout(this.countDown);
		const eventTime = moment()
			.add(5, 'minutes')
			.unix();
		const currentTime = moment().unix();
		const diffTime = eventTime - currentTime;
		let duration = moment.duration(diffTime * 1000);
		const interval = 1000;

		this.countDown = setInterval(() => {
			duration = moment.duration(duration - interval);
			const time = `${('0' + duration.minutes()).slice(-2)}:${(
				'0' + duration.seconds()
			).slice(-2)}`;

			this.setState({
				time,
			});

			if (duration.as('milliseconds') === 0) {
				clearTimeout(this.countDown);
			}
		}, interval);
	};

	//ป้อนหมายเลข OTP
	handleChangeOTP = (index, event, getOTP) => {
		const value = event.target.value;
		this.setState(
			{
				[`otp${index}`]: value,
			},
			() => {
				if (
					this.state.otp1 &&
					this.state.otp2 &&
					this.state.otp3 &&
					this.state.otp4
				) {
					getOTP();
				} else {
					if (value) {
						if (index === 1) {
							this._inputOTP2.current.focus();
						} else if (index === 2) {
							this._inputOTP3.current.focus();
						} else if (index === 3) {
							this._inputOTP4.current.focus();
						}
					} else {
						if (index === 2) {
							this._inputOTP1.current.focus();
						} else if (index === 3) {
							this._inputOTP2.current.focus();
						} else if (index === 4) {
							this._inputOTP3.current.focus();
						}
					}
				}
			}
		);
	};

	getPaymentType(paymentType) {
		if (paymentType === 'creditCard') {
			return 1;
		}
		if (paymentType === 'COD') {
			return 2;
		}
		if (paymentType === 'Invoice') {
			return 3;
		}

		return null;
	}

	//## GET DEFAULT PAYMENT
	async fetchShipmentLastPayment(token) {
		try {
			const { data } = await client.query({
				query: shipmentLastPaymentQuery,
				fetchPolicy: 'network-only',
				context: {
					authorization: token,
				},
			});

			const { paid_type } = data.shipmentLastPayment;

			return paid_type;
		} catch (error) {
			console.log(error);
		}
	}

	async getDefaultPaymentType(token, paymentType = '') {
		try {
			if (paymentType) {
				//set paymentType
				if (paymentType !== 'creditCard') {
					if (paymentType === 'COD') {
						this.setPaymentTypeCOD();
					}

					if (paymentType === 'Invoice') {
						this.setPaymentTypeInvoice();
					}

					this.setIsCreditCardDefault(false);
				}
			} else {
				//ถ้าไม่มีการส่ง paymentType มา ให้ใช้ค่า default ของบัญชีผู้ใช้
				const shipmentLastPayment = await this.fetchShipmentLastPayment(token);
				if (shipmentLastPayment === 1) {
					this.getDefaultCreditCard();
					return;
				} else if (shipmentLastPayment === 2) {
					this.setPaymentTypeCOD();
					return;
				} else if (shipmentLastPayment === 3) {
					this.setPaymentTypeInvoice();
				} else {
					const { data: accountInfoListData } = await client.query({
						query: accountInfoListQuery,
					});

					if (
						//business default invoice
						accountInfoListData.accountInfoList.account_business !== null &&
						accountInfoListData.accountInfoList.account_business
							.business_status === 2
					) {
						this.setPaymentTypeInvoice();
						this.setIsCreditCardDefault(false);
					} else {
						//default creditCard
						this.getDefaultCreditCard();
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getDefaultCreditCard() {
		try {
			//ดึงข้อมูลรายการบัตรเครดิต
			const { data: creditCardListData } = await client.query({
				query: creditCardListQuery,
			});

			//กรองข้อมูลรายการบัตร เฉพาะบัตรที่ถูก default ไว้
			const creditCardDefaultFilter = creditCardListData.creditCardList.filter(
				item => item.card_default === true
			);

			if (creditCardDefaultFilter.length === 1) {
				//ถ้ามี default บัตรเครดิต
				this.setCreditCard(creditCardDefaultFilter[0]);
			} else {
				//set default credit card to false
				this.setIsCreditCardDefault(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	setIsCreditCardDefault(isDefault) {
		const creditCardDefaultData = client.cache.readQuery({
			query: creditCardDefaultQueryClient,
		});

		client.cache.writeQuery({
			query: creditCardDefaultQueryClient,
			data: {
				creditCardDefault: {
					...creditCardDefaultData.creditCardDefault,
					isDefault: isDefault,
				},
			},
		});
	}

	setPaymentTypeCOD() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					paymentType: 'COD',
				},
			},
		});
	}

	setPaymentTypeInvoice() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					paymentType: 'Invoice',
				},
			},
		});
	}

	setCreditCard(creditCard) {
		const {
			id,
			bank,
			last_digits,
			brand,
			expiration_month,
			expiration_year,
			name,
		} = creditCard;

		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					paymentType: 'creditCard',
					creditCard: {
						id,
						bank,
						last_digits,
						brand,
						expiration_month,
						expiration_year,
						name,
						__typename: 'CreditCard',
					},
				},
			},
		});
	}
	//## END GET DEFAULT PAYMENT

	render() {
		return (
			<Mutation mutation={updateModalLoginMutationClient}>
				{updateModalLogin => {
					return (
						<ModalContainer
							show={this.props.show}
							onHide={() => this.handleClose(updateModalLogin)}
						>
							<Modal.Body style={{ padding: 15 }}>
								<Row>
									{/* <Col sm={7}>
										<ModalLeftContainer>
										<Slider {...settingsSlider}>
											<div className="image-slider">
												<a target="_blank" rel="noopener noreferrer" href={`${appUrl}/specialdeal?content=michelindeal`}>
													<LazyImage src={imagePromotion1} alt="promotion300"/>
												</a>
											</div>
											
											<div className="image-slider">
												<a target="_blank" rel="noopener noreferrer" href={`${appUrl}/specialdeal-scb?content=scbdeal`}>
													<LazyImage src={imagePromotion2} alt="promotion500"/>
												</a>
											</div>
										</Slider>
										</ModalLeftContainer>
									</Col> */}
									<Col sm={12}>
										<ModalRightContainer>
											<ShipmentConfirm>
												{shipmentConfirm => (
													<Mutation
														mutation={signinMutation}
														variables={{
															email: this.state.email,
															password: this.state.password,
														}}
														update={async (cache, { data }) => {
															const token = data.signin.account_token_key;

															if (token) {
																try {
																	//ดึงข้อมูลผู้ใช้มาเพื่อตรวจสอบว่ามีเบอร์มือถือหรือไม่
																	const { data } = await client.query({
																		query: accountInfoListQuery,
																		fetchPolicy: 'network-only',
																		context: {
																			authorization: token,
																		},
																	});

																	const account_info =
																		data.accountInfoList.account_info[0];

																	//ถ้าผู้ใช้ไม่มีเบอร์โทรศัพท์ (ผู้ใช้เก่า) ให้เพิ่มเบอร์ใหม่เข้าไปใหม่
																	if (
																		data.accountInfoList.account_info[0]
																			.account_info_phone_number === null
																	) {
																		//ไปยังหน้าป้อนเบอร์โทรศัพท์
																		this.setState({ stepPage: 2 });
																	} else {
																		//ถ้ามีเบอร์อยู่แล้วให้ login ปกติ
																		//บันทึก token ลง storage
																		localStorage.setItem('token', token);
																		const data = cache.readQuery({
																			query: modalLoginQueryClient,
																		});

																		//ปิด Modal
																		cache.writeQuery({
																			query: modalLoginQueryClient,
																			data: {
																				modalLogin: {
																					...data.modalLogin,
																					isOpen: false,
																				},
																			},
																		});

																		const userData = cache.readQuery({
																			query: userQueryClient,
																		});

																		cache.writeQuery({
																			query: userQueryClient,
																			data: {
																				user: {
																					...userData.user,
																					token: token,
																				},
																			},
																		});

																		this.getDefaultPaymentType(token);

																		//เคลียร์อีเมลและรหัสผา่นเมื่อ login สำเร็จ
																		setTimeout(() => {
																			this.setState({
																				email: '',
																				password: '',
																				error_message: '',
																			});
																		}, 500);

																		// isConfirm = true and getInstancePrice not error
																		if (
																			this.props.shipmentConfirmStatusQuery
																				.shipmentConfirmStatus.isConfirm &&
																			shipmentConfirm
																		) {
																			// shipmentConfirm();
																		}

																		if (
																			window.location.pathname ===
																				'/shipment' ||
																			window.location.pathname ===
																				'/favorite-driver' ||
																			window.location.pathname === '/'
																		) {
																			window.location.reload();
																		}
																	}
																} catch (error) {
																	console.log(error);
																}
															} else {
																this.setState({
																	error_message: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง.',
																});
															}
														}}
														onError={error => {
															const err_message = error.graphQLErrors.length
																? error.graphQLErrors[0].message
																: null;
															if (err_message === 'You must login.') {
																this.setState({
																	error_message: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง.',
																	loading: false,
																});
															} else {
																this.setState({
																	error_message:
																		'เกิดข้อผิดพลาดบางอย่างลองอีกครั้ง.',
																	loading: false,
																});
															}
														}}
														refetchQueries={({ data }) => {
															const instancePriceData = client.readQuery({
																query: instancePriceQueryClient,
															});

															const token = data.signin.account_token_key;

															return [
																'search',
																'AccountInfoList',
																{
																	query: getInstancePriceQuery,
																	variables: {
																		additional: instancePriceData.instancePrice.additional.map(
																			item => {
																				return {
																					additional_id: item._id,
																					additional_qty: item.qty,
																				};
																			}
																		),
																		settingTruckTypeId:
																			instancePriceData.instancePrice
																				.setting_truck_type_id,
																		address: instancePriceData.instancePrice.shipmentAddress
																			.filter(
																				item =>
																					item.latitude !== 0 &&
																					item.longitude !== 0
																			)
																			.map(item => ({
																				lat: item.latitude,
																				lng: item.longitude,
																			})),
																		promotionName:
																			instancePriceData.instancePrice
																				.promotion_name,
																		pickupDate:
																			instancePriceData.instancePrice
																				.pickupDate,
																		round_trip: instancePriceData.instancePrice
																			.roundTrip
																			? instancePriceData.instancePrice
																					.roundTrip
																			: null,
																		paymentType: this.getPaymentType(
																			instancePriceData.instancePrice
																				.paymentType
																		),
																		shipmentType:
																			instancePriceData.instancePrice
																				.shipmentType,
																	},
																	context: {
																		authorization: token,
																	},
																},
															];
														}}
													>
														{(signin, { loading }) => {
															return (
																<div>
																	{/* หน้าล็อกอิน */
																	this.state.stepPage === 1 ? (
																		<div>
																			<CardHeader
																				title={this.props.t(
																					'common:login.logIn'
																				)}
																				subTitle={this.props.t(
																					'common:login.fillEmailAndPassword'
																				)}
																			/>

																			<Container>
																				{this.state.error_message ? (
																					<MessageContainer>
																						<i className="fas fa-times-circle" />{' '}
																						{this.state.error_message}
																					</MessageContainer>
																				) : null}

																				<Input
																					iconLeft="fas fa-envelope"
																					placeholder={this.props.t(
																						'common:login.email'
																					)}
																					value={this.state.email}
																					onChange={event =>
																						this.setState({
																							email: event.target.value,
																						})
																					}
																					onKeyPress={e =>
																						e.charCode === 13 &&
																						!loading &&
																						this.onSignin(signin)
																					}
																					type="text"
																					name="email"
																					autoFocus
																					style={{ marginBottom: 15 }}
																					inputLabel={this.props.t(
																						'common:login.titleUser'
																					)}
																				/>

																				<Input
																					iconLeft="fas fa-key"
																					iconRight={
																						this.state.hidePassword
																							? 'fas fa-eye-slash'
																							: 'fas fa-eye'
																					}
																					placeholder={this.props.t(
																						'common:login.password'
																					)}
																					onPressIconRight={() =>
																						this.setState(prev => ({
																							hidePassword: !prev.hidePassword,
																						}))
																					}
																					value={this.state.password}
																					onChange={event =>
																						this.setState({
																							password: event.target.value,
																						})
																					}
																					onKeyPress={e =>
																						e.charCode === 13 &&
																						!loading &&
																						signin()
																					}
																					type={
																						this.state.hidePassword
																							? 'password'
																							: 'text'
																					}
																					name="password"
																					inputLabel={this.props.t(
																						'common:login.titlePassword'
																					)}
																				/>
																				<Button
																					disabled={loading}
																					className="btn-login"
																					bsStyle="primary"
																					block
																					onClick={e =>
																						!loading && this.onSignin(signin)
																					}
																				>
																					{loading
																						? this.props.t(
																								'common:login.buttonLogin'
																						  ) + '...'
																						: this.props.t(
																								'common:login.buttonLogin'
																						  )}
																				</Button>

																				<Mutation
																					mutation={
																						updateModalForgetPasswordMutationClient
																					}
																					variables={{ isOpen: true }}
																				>
																					{updateModalForgetPassword => {
																						return (
																							<div
																								style={{
																									textAlign: 'center',
																									marginTop: 10,
																								}}
																							>
																								<Button
																									style={{
																										height: 40,
																										color: '#000000',
																									}}
																									bsStyle="link"
																									onClick={() => {
																										updateModalLogin({
																											variables: {
																												isOpen: false,
																											},
																										});
																										setTimeout(() => {
																											updateModalForgetPassword();
																										}, 200);
																									}}
																								>
																									{this.props.t(
																										'common:login.forgetPassword'
																									)}
																								</Button>
																							</div>
																						);
																					}}
																				</Mutation>

																				<div
																					style={{
																						textAlign: 'center',
																						marginTop: 40,
																					}}
																				>
																					{this.props.t(
																						'common:login.noAccount'
																					)}
																					<Mutation
																						mutation={
																							updateModalRegisterMutationClient
																						}
																						variables={{
																							isOpen: true,
																							isBack: true,
																						}}
																					>
																						{updateModalRegister => {
																							return (
																								<Button
																									style={{
																										height: 40,
																										color: color.Primary,
																										width: 95,
																									}}
																									bsStyle="link"
																									onClick={() => {
																										updateModalLogin({
																											variables: {
																												isOpen: false,
																											},
																										});
																										setTimeout(() => {
																											updateModalRegister();
																										}, 200);
																									}}
																								>
																									{this.props.t(
																										'common:login.register'
																									)}
																								</Button>
																							);
																						}}
																					</Mutation>
																				</div>
																			</Container>
																		</div>
																	) : null}

																	{/* หน้าป้อนเบอร์โทรสำหรับผู้ใช้เก่า */}
																	{this.state.stepPage === 2 ? (
																		<div>
																			<CardHeader
																				title="ระบุหมายเลขโทรศัพท์"
																				subTitle="ต้องเป็นหมายเลขโทรศัพท์ปัจจุบันที่คุณใช้อยู่เพื่อรับ OTP"
																				symbol
																				arrowBack
																				onClickArrowBack={() =>
																					this.setState({
																						stepPage: 1,
																					})
																				}
																			/>

																			<Mutation
																				mutation={otpGeneratePhoneMutation}
																				variables={{
																					phone:
																						this.state.account_info_phone_code +
																						this.state.account_info_phone_number.replace(
																							/\b0+/g,
																							''
																						),
																				}}
																				update={(cache, { data }) => {
																					const ref = data.otpGeneratePhone.ref;
																					this.setState({
																						ref: ref,
																						stepPage: 3,
																						otp1: '',
																						otp2: '',
																						otp3: '',
																						otp4: '',
																						otpValid: '',
																						otpInvalid: '',
																						loading: false,
																					});

																					this.durationTime();
																					setTimeout(() => {
																						this._inputOTP1.current.focus();
																					}, 100);
																				}}
																			>
																				{otpGeneratePhone => {
																					return (
																						<div>
																							<FormGroup
																								style={{ marginTop: 20 }}
																							>
																								<InputGroup>
																									<DropdownButton
																										componentClass={
																											InputGroup.Button
																										}
																										id="input-dropdown-addon"
																										title={
																											'+' +
																											this.state
																												.account_info_phone_code
																										}
																									>
																										<MenuItem
																											eventKey="66"
																											onSelect={event =>
																												this.handleSelectPhoneCode(
																													event
																												)
																											}
																										>
																											+66
																										</MenuItem>
																									</DropdownButton>

																									{this.state
																										.phoneNumberValidate ===
																									true ? (
																										<IconRightInput
																											className="fas fa-check-circle"
																											color={color.Primary}
																										/>
																									) : this.state
																											.phoneNumberValidate ===
																									  false ? (
																										<IconRightInput
																											className="fas fa-times-circle"
																											color={color.SoftGray}
																										/>
																									) : null}

																									<FormControl
																										type="text"
																										style={{ paddingRight: 38 }}
																										placeholder="เบอร์มือถือ : 081-2345-6789"
																										value={
																											this.state
																												.account_info_phone_number
																										}
																										onKeyPress={e =>
																											this.onSubmitOTPGenerate(
																												e,
																												otpGeneratePhone
																											)
																										}
																										onChange={event =>
																											this.setState(
																												{
																													account_info_phone_number:
																														event.target.value,
																												},
																												() => {
																													if (
																														this.checkPhoneNumber(
																															this.state
																																.account_info_phone_number
																														)
																													) {
																														this.setState({
																															phoneNumberValidate: true,
																														});
																													} else {
																														this.setState({
																															phoneNumberValidate: false,
																														});
																													}
																												}
																											)
																										}
																										name="phoneNumber"
																									/>
																								</InputGroup>
																							</FormGroup>

																							<ButtonConfirm
																								disabled={
																									this.state.loading ||
																									!this.checkPhoneNumber(
																										this.state
																											.account_info_phone_number
																									)
																								}
																								bsStyle="primary"
																								block
																								onClick={e =>
																									this.onSubmitOTPGenerate(
																										e,
																										otpGeneratePhone
																									)
																								}
																							>
																								{this.state.loading
																									? 'ยืนยัน...'
																									: 'ยืนยัน'}
																							</ButtonConfirm>
																						</div>
																					);
																				}}
																			</Mutation>
																		</div>
																	) : null}

																	{/* หน้าป้อน OTP */}
																	{this.state.stepPage === 3 ? (
																		<div>
																			<CardHeader
																				title="ยืนยันเบอร์โทรศัพท์มือถือของคุณ"
																				subTitle="เราส่งรหัส (OTP) ไปที่หมายเลขโทรศัพท์ของคุณแล้ว"
																				arrowBack
																				onClickArrowBack={() =>
																					this.setState({
																						stepPage: 2,
																					})
																				}
																			/>

																			<OTPRefContainer>
																				OTP Ref. {this.state.ref} (Time{' '}
																				{this.state.time})
																			</OTPRefContainer>

																			<Mutation
																				mutation={accountInfoUpdateMutation}
																				variables={{
																					account_info_phone_code: this.state.account_info_phone_code.toString(),
																					account_info_phone_number: this.state.account_info_phone_number
																						.toString()
																						.replace(/\b0+/g, ''),
																				}}
																				onError={error => {
																					const err_message = error
																						.graphQLErrors.length
																						? error.graphQLErrors[0].message
																						: null;
																					if (
																						err_message === 'You must login.'
																					) {
																						this.setState({
																							error_message:
																								'อีเมลหรือรหัสผ่านไม่ถูกต้อง.',
																							loading: false,
																						});
																					} else {
																						this.setState({
																							error_message:
																								'เกิดข้อผิดพลาดบางอย่างลองอีกครั้ง.',
																							loading: false,
																						});
																					}
																				}}
																				context={{
																					authorization: this.state
																						.account_token_key,
																				}}
																			>
																				{accountInfoUpdate => {
																					return (
																						<Mutation
																							mutation={signinMutation}
																							variables={{
																								email: this.state.email,
																								password: this.state.password,
																							}}
																							update={async (
																								cache,
																								{ data }
																							) => {
																								const token =
																									data.signin.account_token_key;
																								if (token) {
																									//บันทึก token ลง storage
																									localStorage.setItem(
																										'token',
																										token
																									);
																									const data = cache.readQuery({
																										query: modalLoginQueryClient,
																									});

																									const userData = cache.readQuery(
																										{
																											query: userQueryClient,
																										}
																									);

																									cache.writeQuery({
																										query: userQueryClient,
																										data: {
																											user: {
																												...userData.user,
																												token: token,
																											},
																										},
																									});

																									this.getDefaultPaymentType(
																										token
																									);

																									//เคลียร์อีเมลและรหัสผา่นเมื่อ login สำเร็จ
																									setTimeout(() => {
																										this.setState({
																											email: '',
																											password: '',
																											error_message: '',
																										});
																									}, 500);

																									// isConfirm = true and getInstancePrice not error
																									if (
																										this.props
																											.shipmentConfirmStatusQuery
																											.shipmentConfirmStatus
																											.isConfirm &&
																										shipmentConfirm
																									) {
																										// shipmentConfirm();
																									}

																									//ปิด Modal
																									cache.writeQuery({
																										query: modalLoginQueryClient,
																										data: {
																											modalLogin: {
																												...data.modalLogin,
																												isOpen: false,
																											},
																										},
																									});
																								} else {
																									this.setState({
																										error_message:
																											'อีเมลหรือรหัสผ่านไม่ถูกต้อง.',
																									});
																								}
																							}}
																							onError={error => {
																								const err_message = error
																									.graphQLErrors.length
																									? error.graphQLErrors[0]
																											.message
																									: null;
																								if (
																									err_message ===
																									'You must login.'
																								) {
																									this.setState({
																										error_message:
																											'อีเมลหรือรหัสผ่านไม่ถูกต้อง.',
																										loading: false,
																									});
																								} else {
																									this.setState({
																										error_message:
																											'เกิดข้อผิดพลาดบางอย่างลองอีกครั้ง.',
																										loading: false,
																									});
																								}
																							}}
																							refetchQueries={({ data }) => {
																								const instancePriceData = client.readQuery(
																									{
																										query: instancePriceQueryClient,
																									}
																								);

																								const token =
																									data.signin.account_token_key;

																								return [
																									'search',
																									'AccountInfoList',
																									{
																										query: getInstancePriceQuery,
																										variables: {
																											additional: instancePriceData.instancePrice.additional.map(
																												item => {
																													return {
																														additional_id:
																															item._id,
																														additional_qty:
																															item.qty,
																													};
																												}
																											),
																											settingTruckTypeId:
																												instancePriceData
																													.instancePrice
																													.setting_truck_type_id,
																											address: instancePriceData.instancePrice.shipmentAddress
																												.filter(
																													item =>
																														item.latitude !==
																															0 &&
																														item.longitude !== 0
																												)
																												.map(item => ({
																													lat: item.latitude,
																													lng: item.longitude,
																												})),
																											promotionName:
																												instancePriceData
																													.instancePrice
																													.promotion_name,
																											pickupDate:
																												instancePriceData
																													.instancePrice
																													.pickupDate,
																											round_trip: instancePriceData
																												.instancePrice.roundTrip
																												? instancePriceData
																														.instancePrice
																														.roundTrip
																												: null,
																											paymentType: this.getPaymentType(
																												instancePriceData
																													.instancePrice
																													.paymentType
																											),
																											shipmentType:
																												instancePriceData
																													.instancePrice
																													.shipmentType,
																										},
																										context: {
																											authorization: token,
																										},
																									},
																								];
																							}}
																						>
																							{(signin, { loading }) => {
																								return (
																									<Mutation
																										mutation={getOTPMutation}
																										variables={{
																											ref: this.state.ref,
																											otp: `${this.state.otp1}${this.state.otp2}${this.state.otp3}${this.state.otp4}`,
																										}}
																										update={(
																											cache,
																											{
																												data: {
																													getOtp: { available },
																												},
																											}
																										) => {
																											if (available) {
																												this.setState(
																													{
																														otpValid: true,
																														otpInvalid: false,
																														error_message: '',
																													},
																													() => {
																														signin().then(
																															() => {
																																accountInfoUpdate();
																															}
																														);
																													}
																												);
																											} else {
																												this.setState(
																													{
																														otpValid: false,
																														otpInvalid: true,
																														otp1: '',
																														otp2: '',
																														otp3: '',
																														otp4: '',
																													},
																													() => {
																														this._inputOTP1.current.focus();
																													}
																												);
																											}
																										}}
																									>
																										{getOTP => {
																											return (
																												<InputOTPContainer>
																													<InputOTP
																														ref={
																															this._inputOTP1
																														}
																														maxLength="1"
																														autocorrect="off"
																														value={
																															this.state.otp1
																														}
																														onChange={event => {
																															this.handleChangeOTP(
																																1,
																																event,
																																getOTP
																															);
																														}}
																														onFocus={() => {
																															this._inputOTP1.current.select();
																														}}
																													/>
																													<InputOTP
																														ref={
																															this._inputOTP2
																														}
																														maxLength="1"
																														autocorrect="off"
																														value={
																															this.state.otp2
																														}
																														onChange={event => {
																															this.handleChangeOTP(
																																2,
																																event,
																																getOTP
																															);
																														}}
																														onFocus={() => {
																															this._inputOTP2.current.select();
																														}}
																													/>
																													<InputOTP
																														ref={
																															this._inputOTP3
																														}
																														maxLength="1"
																														autocorrect="off"
																														value={
																															this.state.otp3
																														}
																														onChange={event => {
																															this.handleChangeOTP(
																																3,
																																event,
																																getOTP
																															);
																														}}
																														onFocus={() => {
																															this._inputOTP3.current.select();
																														}}
																													/>
																													<InputOTP
																														ref={
																															this._inputOTP4
																														}
																														maxLength="1"
																														autocorrect="off"
																														value={
																															this.state.otp4
																														}
																														onChange={event => {
																															this.handleChangeOTP(
																																4,
																																event,
																																getOTP
																															);
																														}}
																														onFocus={() => {
																															this._inputOTP4.current.select();
																														}}
																														style={{
																															marginRight: 0,
																														}}
																													/>
																												</InputOTPContainer>
																											);
																										}}
																									</Mutation>
																								);
																							}}
																						</Mutation>
																					);
																				}}
																			</Mutation>

																			{this.state.otpValid ? (
																				<OTPMessageContainer
																					color={color.Primary}
																				>
																					<i className="fas fa-check-circle" />{' '}
																					รหัส OTP ถูกต้อง
																				</OTPMessageContainer>
																			) : null}

																			{this.state.otpInvalid ? (
																				<div>
																					<OTPMessageContainer
																						color={color.SoftGray}
																					>
																						<i className="fas fa-times-circle" />{' '}
																						รหัส OTP ไม่ถูกต้อง
																					</OTPMessageContainer>
																					<OTPMessageContainer
																						color={color.SoftGray}
																					>
																						กรอกรหัสใหม่ หรือ ส่งรหัส OTP ทาง
																						SMS อีกครั้ง
																					</OTPMessageContainer>
																				</div>
																			) : null}

																			<div
																				style={{
																					textAlign: 'center',
																					marginTop: 100,
																				}}
																			>
																				<Mutation
																					mutation={otpGeneratePhoneMutation}
																					variables={{
																						phone:
																							this.state
																								.account_info_phone_code +
																							this.state.account_info_phone_number.replace(
																								/\b0+/g,
																								''
																							),
																					}}
																					update={(cache, { data }) => {
																						const ref =
																							data.otpGeneratePhone.ref;
																						this.setState(
																							{
																								ref: ref,
																								otp1: '',
																								otp2: '',
																								otp3: '',
																								otp4: '',
																								otpValid: '',
																								otpInvalid: '',
																							},
																							() => {
																								this._inputOTP1.current.focus();
																								this.durationTime();
																							}
																						);
																					}}
																				>
																					{otpGenerate => {
																						return (
																							<ButtonLink
																								icon="fas fa-envelope"
																								text="ส่ง SMS อีกครั้ง"
																								iconColor="#8B8B8B"
																								textColor="#8B8B8B"
																								onClick={() => {
																									otpGenerate();
																								}}
																							/>
																						);
																					}}
																				</Mutation>
																			</div>
																		</div>
																	) : null}
																</div>
															);
														}}
													</Mutation>
												)}
											</ShipmentConfirm>
										</ModalRightContainer>
									</Col>
								</Row>
							</Modal.Body>
						</ModalContainer>
					);
				}}
			</Mutation>
		);
	}
}

export default compose(
	graphql(shipmentConfirmStatusQueryClient, {
		name: 'shipmentConfirmStatusQuery',
	})
)(withNamespaces()(ModalLogin));
