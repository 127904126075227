import React from 'react';
import { Modal } from 'antd';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import axios from 'axios';
import { Input } from 'src/components';
import lambdaUrl from 'src/config/lambdaUrl.js';
import Button from './Button';
import 'bootstrap-daterangepicker/daterangepicker.css';

const ModalStyle = styled(Modal)`
	.ant-modal-header {
		border-bottom: none;
		padding: 20px;
		padding-bottom: 10px;

		& .sub-title {
			margin-top: 5px;
			font-size: 18px;
			color: #b3b3b3;
		}
	}

	.ant-modal-body {
		font-size: 20px;
	}

	.ant-modal-title {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
	}

	.ant-modal-footer {
		border-top: none;
	}
`;

const TextMessageError = styled.span`
	font-size: 20px;
	color: #ff0000;
`;

const formatDate = 'DD/MM/YYYY';
const validationSchema = yup.object({
	accountInfoId: yup.string().required('กรุณากรอกข้อมูล'),
	createDate: yup
		.string()
		.required('กรุณาเลือกช่วงวันที่')
		.test('maxDate', 'เลือกได้สูงสุด 30 วัน', function(value) {
			if (!value) {
				return true;
			}

			const splitDate = value.split(':');
			const startDate = moment(splitDate[0], formatDate);
			const endDate = moment(splitDate[1], formatDate);
			return endDate.diff(startDate, 'days') <= 30;
		}),
});

class ModalCreateReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	displayFormatDate(date) {
		if (date) {
			const split = date.split(':');
			return `${moment(split[0], formatDate).format(formatDate)} - ${moment(
				split[1],
				formatDate
			).format(formatDate)}`;
		}

		return '';
	}

	async fetchParcelOrder(values) {
		try {
			this.setState({
				isLoading: true,
			});

			const splitDate = values.createDate.split(':');
			const createDate = `${moment(splitDate[0], formatDate).format(
				'YYYY-MM-DD'
			)}:${moment(splitDate[1], formatDate).format('YYYY-MM-DD')}`;

			const data = JSON.stringify({
				account_info: values.accountInfoId,
				create_date: createDate,
			});

			const config = {
				method: 'post',
				url: `${lambdaUrl}/paecel-order-for-cus`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			};

			await axios(config).then(res => {
				if (res.data._id) {
					this.props.onComplete();
				}
			});
		} catch (error) {
			alert(error.message);
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	}

	render() {
		return (
			<Formik
				initialValues={{
					accountInfoId: this.props.accountInfoId,
					createDate: '',
				}}
				validationSchema={validationSchema}
				onSubmit={values => this.fetchParcelOrder(values)}
			>
				{props => {
					const {
						values,
						errors,
						touched,
						handleReset,
						handleSubmit,
						setFieldValue,
						setFieldTouched,
					} = props;

					return (
						<ModalStyle
							width={500}
							destroyOnClose
							title={this.props.selectReport.name}
							closable={false}
							visible={this.props.visible}
							afterClose={() => {
								handleReset();
							}}
							onCancel={() => {
								this.props.onCancel();
							}}
							footer={[
								<Button
									key="back"
									onClick={() => {
										this.props.onCancel();
									}}
								>
									ปิด
								</Button>,
								<Button
									type="primary"
									onClick={() => handleSubmit()}
									disabled={this.state.isLoading}
									loading={this.state.isLoading}
								>
									ดาวน์โหลดรายงาน
								</Button>,
							]}
						>
							<>
								ช่วงวันที่{' '}
								{touched.createDate && (
									<TextMessageError>{errors.createDate}</TextMessageError>
								)}
								<DateRangePicker
									onApply={(_event, picker) => {
										const rangeDate = `${picker.startDate.format(
											formatDate
										)}:${picker.endDate.format(formatDate)}`;
										setFieldValue('createDate', rangeDate);
									}}
									onCancel={() => {
										setFieldValue('createDate', '');
									}}
									containerStyles={{ display: 'block' }}
									opens="right"
									format={formatDate}
								>
									<Input
										type="text"
										name="createDate"
										onChange={() => {}}
										onBlur={() => {
											setFieldTouched('createDate');
										}}
										value={this.displayFormatDate(values.createDate)}
										placeholder="เลือกช่วงวันที่"
									/>
								</DateRangePicker>
							</>
						</ModalStyle>
					);
				}}
			</Formik>
		);
	}
}

export default ModalCreateReport;
