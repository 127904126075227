import gql from 'graphql-tag';

export default gql`
    query challengeList(
        $_id: ID
        $name: String
        $start_date_lte: String
        $end_date_gte: String
        $show_data: Int
        $page_number: Int
        $order_by: String
        $order_type: String
    ){
        challengeList(
        _id: $_id
        name: $name
        start_date_lte: $start_date_lte
        end_date_gte: $end_date_gte
        show_data: $show_data
        page_number: $page_number
        order_by: $order_by
        order_type: $order_type
        ){
            challenge{
            end_date
            account_challenge{
            distance
            rank
            rank_old
            }
        }
        }
    }
`;
