
import React, { Component } from "react";
import { Row,Col , FormGroup ,InputGroup ,FormControl ,Button } from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from "./../../../../config/color";

import {
    Card,
    CardHeader,
    BoxPriceItem,
    Skeleton,
} from "./../../../../components";

import { graphql, Query, Mutation } from 'react-apollo';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import getInstancePrice from 'src/view/InstancePrice2/graphql/query/getInstancePrice';
import moment from 'moment';
import _ from 'lodash';
import { withNamespaces } from 'react-i18next';
import detectLanguageData from "../../function/detectLanguageData";
import Dotdotdot from 'react-dotdotdot';

const Container = styled.div`
    width: 100%;
`;

const PromotionContainer = styled.div`
    margin-top: 20px;
`;

const Line = styled.div`
  border-bottom: 1px solid #e3e3e3;
`;

const SubList = styled.div`
    color: #808080;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    padding: 0 0 10px;

    & .title {
        flex: 1;
        margin-right: 10px;
    }

    & .detail {
        text-align: right;
    }
`;

class PriceResult extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: ''
        };
    }

    closeTap(updateInstancePriceStep){
        updateInstancePriceStep({
            variables: {
                priceResult: false,
            },
        });
    }

    additionalVariables(instancePrice) {
		return instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional_qty: item.qty,
			};
		});
    }
    
    getDiscountPromotion(instancePrice, getInstancePrice) {
		if (instancePrice.promotion_name) {
			if (
				getInstancePrice.promotion_error ||
				!getInstancePrice.promotion.name
			) {
				return this.props.t('common:instancePrice.PriceResult.lorem1');
			} else {
				return `-${getInstancePrice.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`;
			}
		}

		return this.props.t('common:instancePrice.stepServicePaymentDetails.promotion.notApplicable');
    }
    
    getPaymentType(paymentType) {
		if (paymentType === 'creditCard') {
			return 1;
		}
		if (paymentType === 'COD') {
			return 2;
		}
		if (paymentType === 'Invoice') {
			return 3;
		}

		return null;
    }
    
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
	
			const negativeSign = amount < 0 ? "-" : "";
	
			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;
	
			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		} catch (e) {
			console.log(e)
		}
    };
    
    getPriceMultiPoint(getInstancePrice) {
		const multiPoint = getInstancePrice.multi_point;

		if (multiPoint) {
			return multiPoint.reduce((sum, item) => {
				return sum + item.price_per_point * item.total_point;
			}, 0);
		}

		return 0;
    }
    
    getPromotionTitle(Pricing) {
		const {
            discountPrice,
            promotionCodeList,
            totalPrice,
		} = Pricing;

		const {
			for_you, title_lang, name
		} = promotionCodeList;


        if(name) {
			if(for_you) {
                return (
                    <div style={{width: 200}}>
                        <Dotdotdot clamp={1}>
                            {detectLanguageData(title_lang, this.props.t('common:lang'))}
                        </Dotdotdot>
                    </div>
                );
            }
    
            return `${this.props.t('common:instancePrice.shipmentDetails.promotionCode')} ${name} [${Math.round(100 - (totalPrice / (totalPrice + discountPrice)) * 100)}%]`;
		}

		return this.props.t(
			'common:instancePrice.shipmentDetails.promotionCode',
		);
    }
    
    priceList(list) {
        return list.map((item, index, arr) => (
            <React.Fragment>
                <BoxPriceItem 
                    key={index}
                    title={item.name}
                    price={item.priceDisplay}
                />

                {
                    item.subList && item.subList.map((list, key) => (
                        <SubList key={key}>
                            <div className="title">{list.name}</div>
                            <div className="detail">{`${list.priceDisplay}`}</div>
                        </SubList>
                    ))
                }

                {(index === arr.length - 1) && (
                    <Line style={{ marginBottom: 10 }}/>
                )}
            </React.Fragment>
        ))
    }

    render() {
        return (
            <Container>
                <Mutation mutation={updateInstancePriceStepMutationClient}>
                    {updateInstancePriceStep => {
                        return (
                        <Card content={
                            <div>
                                <CardHeader borderCardBottom title={this.props.t('common:instancePrice.shipmentDetails.title')} onPressClose={() => this.closeTap(updateInstancePriceStep)} />
                                <Query
                                    query={instancePriceQueryClient}
                                >
                                    {
                                        ({ data }) => {                                            
                                            const instancePrice = data.instancePrice;

                                            return (
                                                <Query
                                                    query={getInstancePrice}
                                                    variables={{
                                                        additional: this.additionalVariables(instancePrice),
                                                        settingTruckTypeId:instancePrice.setting_truck_type_id,
                                                        address: 
                                                            instancePrice.shipmentAddress.filter(item => item.latitude !== 0 && item.longitude !== 0).map(item => ({ lat: item.latitude, lng: item.longitude })),
                                                        promotionName: instancePrice.promotion_name,
                                                        pickupDate: instancePrice.pickupDate,
                                                        round_trip: instancePrice.roundTrip ? instancePrice.roundTrip : null,
                                                        paymentType: this.getPaymentType(instancePrice.paymentType),
                                                        shipmentType: instancePrice.shipmentType,
                                                        flash_deals_id: instancePrice.flashDeals.id,
                                                    }}
                                                >
                                                    {
                                                        ({ loading, error, data }) => {
                                                            if (loading) return (
                                                                <Skeleton height={76} style={{marginTop: 8}}>
                                                                    <rect
                                                                        x="0"
                                                                        y="0"
                                                                        rx="4"
                                                                        ry="4"
                                                                        width="250"
                                                                        height="15"
                                                                    />

                                                                    <rect
                                                                        x="355"
                                                                        y="0"
                                                                        rx="4"
                                                                        ry="4"
                                                                        width="105"
                                                                        height="15"
                                                                    />

                                                                    <rect
                                                                        x="0"
                                                                        y="39"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="130"
                                                                        height="13"
                                                                    />

                                                                    <rect
                                                                        x="0"
                                                                        y="63"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="290"
                                                                        height="13"
                                                                    />
                                                                </Skeleton>
                                                            );

                                                            if (error) return  {error};
                                                            const Pricing = data.getInstancePrice;
                                                            const distancePriceList = [
                                                                {
																	name: this.props.t('common:instancePrice.shipmentDetails.transportationFee'),
																	price: data.getInstancePrice.distancePrice - this.getPriceMultiPoint( data.getInstancePrice),
																	priceDisplay: `${(data.getInstancePrice.distancePrice -
                                                                        this.getPriceMultiPoint(
                                                                            data.getInstancePrice,
                                                                        )).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                },
                                                                {
                                                                    name: this.props.t('common:instancePrice.PriceResult.lorem2'),
                                                                    price: Pricing.multi_point ? 0 : '',
                                                                    priceDisplay: '',
                                                                    subList: Pricing.multi_point  ? [
                                                                        {
                                                                            name: this.props.t('common:instancePrice.PriceResult.subList.multidrop.01'),
                                                                            price: 0,
                                                                            priceDisplay: this.props.t('common:instancePrice.PriceResult.subList.multidrop.free'),
                                                                        },
                                                                        ...(Pricing.multi_point.filter(item => item.distance_point === 1)[0].total_point > 0 ? Pricing.multi_point.filter(item => item.distance_point === 1).map((item) => {
                                                                            return {
                                                                                name: `${this.props.t('common:instancePrice.PriceResult.subList.multidrop.02')} [${item.price_per_point} x ${item.total_point}]`,
                                                                                price: (item.price_per_point * item.total_point) === 0 ? '' : item.price_per_point * item.total_point,
                                                                                priceDisplay: `${this.formatMoney(item.price_per_point * item.total_point, 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                            }
                                                                        }) : []),
                                                                        ...(Pricing.multi_point.filter(item => item.distance_point === 2)[0].total_point > 0 ? Pricing.multi_point.filter(item => item.distance_point === 2).map((item) => {
                                                                            return {
                                                                                name: `${this.props.t('common:instancePrice.PriceResult.subList.multidrop.03')} [${item.price_per_point} x ${item.total_point}]`,
                                                                                price: (item.price_per_point * item.total_point) === 0 ? '' : item.price_per_point * item.total_point,
                                                                                priceDisplay: `${this.formatMoney(item.price_per_point * item.total_point, 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                            }
                                                                        }) : []),
                                                                    ] : [],
                                                                },
                                                                {
                                                                    name: this.props.t('common:instancePrice.PriceResult.totalPriceMultiDrop'),
                                                                    price: Pricing.multi_point ? this.getPriceMultiPoint(data.getInstancePrice) : '',
                                                                    priceDisplay: `${this.formatMoney(this.getPriceMultiPoint(data.getInstancePrice), 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                },
                                                                ...(_.some(Pricing.discount, [
                                                                    'detail', 'ส่วนลด Giztix Business'
                                                                ]) ? [{
                                                                    name: `${Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].detail} [${Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].percent}%]`,
                                                                    price: Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].price,
                                                                    priceDisplay: `-${this.formatMoney(Pricing.discount.filter(item => item.detail === 'ส่วนลด Giztix Business')[0].price, 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                }] : []),
                                                                ...(Pricing.promotion && Pricing.promotion.name !== null) ? [{
                                                                    name: this.getPromotionTitle(Pricing),
                                                                    price: this.getDiscountPromotion(
                                                                        instancePrice,
                                                                        Pricing,
                                                                    ),
                                                                    priceDisplay: this.getDiscountPromotion(
                                                                        instancePrice,
                                                                        Pricing,
                                                                    ),
                                                                }] : [{
                                                                    name: this.props.t('common:instancePrice.shipmentDetails.promotionCode'),
                                                                    price: 0,
                                                                    priceDisplay: this.props.t('common:instancePrice.shipmentDetails.promotionCodeNotUse'),
                                                                }],
                                                            ];

                                                            const additionalList = Pricing.additional.map((additional, key) => ({
                                                                name: additional.price !== 0 ? `${this.props.t('common:lang') === 'th' ? 'ค่า' : ''}${
                                                                    detectLanguageData(additional.name_lang, this.props.t('common:lang'))
                                                                    } [${additional.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')} x ${additional.qty}]` : `${this.props.t('common:lang') === 'th' ? 'ค่า' : ''}${detectLanguageData(additional.name_lang, this.props.t('common:lang'))}`,
                                                                price: additional.total_price,
                                                                priceDisplay: additional.price !== 0 ? `${additional.total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')} ${this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.baht')}` : this.props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.free'),
                                                            }));
                                                            const discountList = [
                                                                ...(_.some(Pricing.discount, [
                                                                    'detail', 'Cash voucher'
                                                                ]) ? [{
                                                                    name: Pricing.discount.filter(item => item.detail === 'Cash voucher')[0].detail,
                                                                    price: Pricing.discount.filter(item => item.detail === 'Cash voucher')[0].price,
                                                                    priceDisplay: `-${this.formatMoney(Pricing.discount.filter(item => item.detail === 'Cash voucher')[0].price, 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                }] : []),
                                                            ];

                                                            const flashDeal = [
                                                                ...(!!Pricing.flash_deals._id && !Pricing.flash_deals.error_flash_deals) ? [{
                                                                    name: `Flash Deals (${Pricing.flash_deals.name_lang[this.props.t('common:lang')]})`,
                                                                    price: Pricing.flash_deals.discount,
                                                                    priceDisplay: `-${this.formatMoney(Pricing.flash_deals.discount, 0)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
                                                                }] : [],
                                                            ];
                                                            
															const vatList = [
																{
																	name: this.props.t('common:instancePrice.PriceResult.vat7'),
																	price: Pricing.business_price_vat > 0 ? Pricing.business_price_vat : '',
																	priceDisplay: `${this.formatMoney(Pricing.business_price_vat)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
																},
																{
																	name: this.props.t('common:instancePrice.PriceResult.wht1'),
																	price: Pricing.business_price_wht1 > 0 ? Pricing.business_price_wht1 : '',
																	priceDisplay: `-${this.formatMoney(Pricing.business_price_wht1)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
																},
																{
																	name: this.props.t('common:instancePrice.PriceResult.wht3'),
																	price: Pricing.business_price_wht3 > 0 ? Pricing.business_price_wht3 : '',
																	priceDisplay: `-${this.formatMoney(Pricing.business_price_wht3)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
																},
																{
																	name: this.props.t('common:instancePrice.PriceResult.wht5'),
																	price: Pricing.business_price_wht5 > 0 ? Pricing.business_price_wht5 : '',
																	priceDisplay: `-${this.formatMoney(Pricing.business_price_wht5)} ${this.props.t('common:instancePrice.shipmentDetails.baht')}`,
																},
                                                            ];

                                                            return (
                                                                <PromotionContainer>
                                                                    <div>
                                                                        {this.priceList(distancePriceList.filter(item => item.price !== ''))}
                                                                        {this.priceList(additionalList)}
                                                                        {this.priceList(discountList)}
                                                                        {this.priceList(flashDeal)}
                                                                        {this.priceList(vatList.filter(item => item.price !== ''))}

                                                                        <BoxPriceItem 
                                                                            title={this.props.t('common:instancePrice.shipmentDetails.totalCharge')}
                                                                            subTitle={`${this.props.t('common:instancePrice.shipmentDetails.totalDistance')} ${instancePrice.roundTrip === 1 ? (Pricing.distance * 2).toLocaleString('en-US', {maximumFractionDigits:2}) : Pricing.distance.toLocaleString('en-US', {maximumFractionDigits:2})} ${this.props.t('common:instancePrice.shipmentDetails.kilometer')}`}
                                                                            price={Pricing.business_total_price % 1 === 0 ? Pricing.business_total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : Pricing.business_total_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        />
            
                                                                    </div>
                                                                </PromotionContainer>
                                                            )
                                                        }
                                                    }
                                                </Query>
                                            )
                                        }
                                    }
                                </Query>
                            </div>} />);
                    }}
                </Mutation>
            </Container>   
        )
    }
};

export default withNamespaces()(PriceResult);
