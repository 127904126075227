import React from 'react';
import { Query } from 'react-apollo';
import currentUserQuery from './graphql/query/currentUser';
import { defaults } from '../../config/client';
import { Redirect } from 'react-router-dom';

class CurrentUser extends React.Component {
	render() {
		return (
			<Query query={currentUserQuery} fetchPolicy="network-only">
				{({ loading, error, data, client }) => {
					if (loading || error) return null;

					if (!loading && !error) {
						if (!data.currentUser.result) {
							client.cache.writeData({
								data: defaults,
							});

							localStorage.removeItem('token');
							return <Redirect to="/" />;
						}

						return this.props.children;
					}
				}}
			</Query>
		);
	}
}

export default CurrentUser;
