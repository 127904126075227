import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const ModalStyle = styled(Modal)`
	.ant-modal-header {
		border-bottom: none;
		padding: 20px;
		padding-bottom: 10px;

		& .sub-title {
			margin-top: 5px;
			font-size: 18px;
			color: #b3b3b3;
		}
	}

	.ant-modal-body {
		font-size: 20px;
	}

	.ant-modal-title {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
	}

	.ant-modal-footer {
		border-top: none;
	}
`;

const modal = props => {
	return (
		<ModalStyle {...props}>{props.children}</ModalStyle>
	);
};

export default modal;
