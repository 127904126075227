import React, { Component } from 'react';
import { Row ,Breadcrumb,FormGroup,InputGroup,Button,FormControl, Col ,Image,Table,ButtonToolbar} from "react-bootstrap";
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
    BreadcrumbTitel,
    Footer,
    Card,
	CardHeader,
	TruckListItem,
    Skeleton,
} from "src/components";
import { HeaderNav } from 'src/app/components';
import { graphql, Query, Mutation } from 'react-apollo';
import settingTruckListQuery from './graphql/query/settingTruckList';
import s3 from 'src/config/s3';
import { withNamespaces } from "react-i18next";
import detectLanguageData from './function/detectLanguageData';
import {SelectDropdown,TablePackingItem,TablePacking } from './components';

import Boxs from "src/assets/images/box.png";
import Bigbags from "src/assets/images/bigbags.png";
import Barrels from "src/assets/images/barrels.png";
import Sacks from "src/assets/images/sacks.png";
import Pallet from "src/assets/images/pallet.jpg";
import Onpallet from "src/assets/images/nopallet.jpg";
import axios from "axios";
import heightTopHeader from 'src/app/utils/heightTopHeader';

const Container = styled.div`
    width: 100%;
    margin-top: calc(60px + ${heightTopHeader}px);

    @media(max-width: 767px) {
        margin-top: 0px;
    }

	#tab-truck .nav-tabs {
		border-bottom: 1px solid #ddd;
		box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.2);
	}

	#tab-truck .nav-tabs > li {
		float: left;
		margin-bottom: -1px;
	}

	#tab-truck .nav-tabs > li a {
		color: #000000;
	}

	#tab-truck .nav-tabs > li.active a {
		border: none;
		border-bottom: 2px solid #d90101;
		color: #d90101;
	}

	#tab-truck .tab-content {
		padding-top: 15px;
    }
    .addcargobtn{
        color: #007bff;
        border-color: #007bff;
        background-color: #fff;
    }
    .addcargobtn:hover {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }
    .car-list{
        padding: 0px 10px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .sketch-picker{
        margin-bottom: 10px;
    }
    .form-group {
       margin-bottom: 0px !important; 
    }
    
`;

const TruckListContainer = styled(Row)`
    padding: 0px 10px;
    max-height: calc(100vh - 200px);
    overflow-y: hidden;
    overflow-x: hidden;
`;

const TruckHeader = styled.div`
	padding: 6px 10px 5px 15px;
	background-color: #f9f9f9;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 14px;
	margin-left: -15px;
	margin-right: -15px;
`;


class LoadCalculator extends Component {

    constructor(props, context) {
		super(props, context);
		this.state = {
			setting_truck_type_id: "5bed280b2a9d8d18e851d7bb",
			setting_truck_type_name: "คอก",
            setting_truck_length: 175,
            setting_truck_width: 150,
            setting_truck_height: 250,
            setting_truck_tonnage: 1.3 *1000 ,
            palletlength: 175 *10,
            palletwidth: 150 *10,
            palletheight: 150,
            palletpayload: 1.3 *1000,
            palletlevel: 250  *10,
            palletseparat: 50,
            selectOptionCargo:'box',
            selectedOption: 'option1',
            pathpacking: Boxs,
            namecargo:"cargo1",
            background: '#fff',
            showstep: 1,
            imageresponse: "",
            dataresponse: "",
            color: {
                r: '241',
                g: '112',
                b: '19',
                a: '1',
            },
            datasend:{},
            packer_data_cargo : [
                {   
                    box_img:Boxs,
                    box_type: 'box',
                    box_name: 'สินค้าที่ 1',
                    box_length: 300,
                    box_width: 700,
                    box_height: 300,
                    box_weight: 30,
                    box_number: 30,
                    box_color: 'F48B04',
                },
                {
                    box_img:Boxs,
                    box_type: 'box',
                    box_name: 'สินค้าที่ 2',
                    box_length: 300,
                    box_width: 700,
                    box_height: 300,
                    box_weight: 30,
                    box_number: 30,
                    box_color: 'FF1B00',
                },
                {
                    box_img:Boxs,
                    box_type: 'box',
                    box_name: 'สินค้าที่ 3',
                    box_length: 300,
                    box_width: 700,
                    box_height: 300,
                    box_weight: 30,
                    box_number: 30,
                    box_color: 'CF0F0F',
                }
            ]
        };
    }
    handleOptionChange(event) {
        this.setState({
          selectedOption: event.target.value
        });
      }
      
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
      };
    onSelectpacking(event){
        this.setState({ 
            selectOptionCargo: event.target.value,
            pathpacking: event.target[event.target.selectedIndex].dataset.image,
        });
    }
    onSelect(truck, item) {
        const { _id, setting_truck_name, truck_type_name, truck_type_size_lang, truck_type_pic , truck_type_size , truck_type_weight } = item;
        var height = 0;
        var width = 0;
        var length = 0;
        var tonnage = 0;
        if (item == 'STALL') {
            height =  250;
            length = 210;
            width = 150;
            tonnage = 1.3 * 1000;
            this.setState({
                setting_truck_type_id :  '5be5057787415b16d8cf4123',
                setting_truck_type_size: '150 x 210 x 250 ซม.',
                setting_truck_type_name: 'คอก (ตอนเดียว)',
                setting_truck_length: length,
                setting_truck_width: width,
                setting_truck_height:height,
                setting_truck_tonnage: tonnage,
                showstep :1,
                imageresponse:"",
                palletlength:  length *10,
                palletwidth: width *10,
                palletheight: 150,
                palletpayload: tonnage,
                palletlevel: height *10,
                palletseparat: 50,
            })
        }else if(item == 'CABINET') {
            height =  210;
            length = 210;
            width = 150;
            tonnage = 1.3 * 1000;
            this.setState({
                setting_truck_type_id : '5be5057787415b16d8cf4223',
                setting_truck_type_size: '150 x 210 x 210 ซม.',
                setting_truck_type_name: 'ตู้ทึบ (ตอนเดียว)',
                setting_truck_length: length,
                setting_truck_width: width,
                setting_truck_height:height,
                setting_truck_tonnage: tonnage,
                showstep :1,
                imageresponse:"",
                palletlength:  length *10,
                palletwidth: width *10,
                palletheight: 150,
                palletpayload: tonnage,
                palletlevel: height *10,
                palletseparat: 50,
            })
        }else{
            if (truck_type_name["th"].replace('"',"") == "ตู้ 40GP") {
                height =  235;
                length = 1200;
                width = 235;
                tonnage = 28 * 1000;
            }else if (truck_type_name["th"].replace('"',"") == "ตู้ 40HQ") {
                height =  260;
                length = 590;
                width = 235;
                tonnage = 28 * 1000;
            }else if (truck_type_name["th"].replace('"',"") == "ตู้ 20GP") {
                height =  235;
                length = 590;
                width = 235;
                tonnage = 23 * 1000;
            }else{
                var truckheight = truck_type_size.split(" x ")[2].replace("ไม่เกิน ","");
                var setheight = truckheight.replace(" ซม.","");
                height =  parseInt(setheight.replace(",",""));
                length = parseInt(truck_type_size.split(" x ")[1].replace(",",""));
                width = parseInt(truck_type_size.split(" x ")[0].replace(",",""));
                tonnage = Number(truck_type_weight.replace(" ตัน","")) * 1000;

            }
            this.setState({
                setting_truck_type_id :  _id,
                setting_truck_type_size:truck_type_size,
                setting_truck_type_name: truck_type_name["th"],
                setting_truck_length: length,
                setting_truck_width: width,
                setting_truck_height:height,
                setting_truck_tonnage: tonnage,
                showstep :1,
                imageresponse:"",
                palletlength:  length *10,
                palletwidth: width *10,
                palletheight: 150,
                palletpayload: tonnage,
                palletlevel: height *10,
                palletseparat: 50,
            })
        }
        
        this.setStatecargo();
    }
    addcargo(){
		const packer_data_cargo = this.state.packer_data_cargo;
        const data = {
            box_img:this.state.pathpacking,
            box_type: this.state.selectOptionCargo,
            box_name: `สินค้าที่ ${packer_data_cargo.length + 1}`,
            box_length: 300,
            box_width: 700,
            box_height: 300,
            box_weight: 30,
            box_number: 10,
            box_color:  Math.random().toString(16).substr(-6),
        };
        this.setState({
            packer_data_cargo: [...packer_data_cargo, data],
        });
    }
    deleteCargo(key){
		const packer_data_cargo = this.state.packer_data_cargo;
        const removeItemSelect = packer_data_cargo.filter(( item , index) => index !== key);
        return this.setState({
            packer_data_cargo: removeItemSelect,
        });
    }
    isShowSizeLabel(id) {
		if (
			id === '5cbe8678723da822892a6412' ||
			id === '5cbe8678723da822892a6413' ||
            id === '5cbe8678723da822892a6414' 
		) {
			return false;
		}

		return true;
    }
    setStatecargo(){
        this.setState({
            showstep: 1 ,
            imageresponse: ""
        })
    }
    handleChangeSelectOptionCargo = (optionSelect) => {
		// const statusID = optionSelect.value;
		this.setState({selectOptionCargo : optionSelect});
    };

    
    objectToFormData(obj, form, namespace) {
    
        var fd = form || new FormData();
        var formKey;
        
        for(var property in obj) {
          if(obj.hasOwnProperty(property)) {
            
            if(namespace) {
              formKey = namespace + '[' + property + ']';
            } else {
              formKey = property;
            }
           
            // if the property is an object, but not a File,
            // use recursivity.
            if (obj[property] instanceof Array) {
                obj[property].forEach((element, index) => {
                    const tempFormKey = `${formKey}[${index}]`;
                    this.objectToFormData(element, fd, tempFormKey);
                });
            }
            else if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
              
              this.objectToFormData(obj[property], fd, property);
              
                // console.log(obj);
            } else {
              
              // if it's a string or a File object
              fd.append(formKey, obj[property]);
            }
            
          }
        }
        
        return fd;
          
    };

    setValue(value , name, index){
        let packer_data_cargo = [...this.state.packer_data_cargo];
        packer_data_cargo[index] = {...packer_data_cargo[index],[name]: value};
        this.setState({ packer_data_cargo });
    }
    
    sumitcargo(){
        var data = "";
        if (this.state.selectedOption === 'option2') {
            data =  {
                api_key: '7XJW-OK0D-IB6L-MT93',
                image_all: true,
                auto_mode: "manual",
                packer_data_cargo: this.state.packer_data_cargo,
                packer_data_container: [
                    {
                        container_name: "truck",
                        container_length: this.state.setting_truck_length *10,
                        container_width: this.state.setting_truck_width *10,
                        container_height: this.state.setting_truck_height *10,
                        container_tonnage: this.state.setting_truck_tonnage 
                    },
                ],
                pallet_enable: true,
                pallet_length:  parseInt(this.state.palletlength),
                pallet_width: parseInt(this.state.palletwidth),
                pallet_height: parseInt(this.state.palletheight),
                pallet_tonnage: parseInt(this.state.palletpayload),
                pallet_max_height: parseInt(this.state.palletlevel),
                pallet_level_separate_height:parseInt(this.state.palletseparat),
            } 
        }else{
            data =  {
                api_key: '7XJW-OK0D-IB6L-MT93',
                image_all: true,
                packer_data_cargo: this.state.packer_data_cargo,
                packer_data_container: [
                    {
                        container_name: "truck",
                        container_length: this.state.setting_truck_length *10,
                        container_width: this.state.setting_truck_width *10,
                        container_height: this.state.setting_truck_height *10,
                        container_tonnage: this.state.setting_truck_tonnage 
                    },
                ]
            } 

        }
        this.setState({ 
            showstep :2,
        });
        let headers = { headers: {"Accept": "application/json"} }
        axios.post('https://packer.searates.com/services/api', this.objectToFormData(data),headers)
        .then(response => {
            if (response.data.packages_packed > 0 ) {
               if (response.data.packages_packed == response.data.packages_total) {
                    this.setState({ 
                        imageresponse: response.data.group,
                        dataresponse: response.data,
                    });
                }else{
                    this.setState({ 
                        showstep :1,
                    });
                    setTimeout(
                        function() {
                            alert("ปริมาณสินค้าทั้งหมดเกินปริมาณบรรจุของรถ กรุณากรอกรายละเอียดสินค้าใหม่อีกครั้ง");
                        },100
                    );
                } 
            }else{
                this.setState({ 
                    showstep :1,
                });
                setTimeout(
                    function() {
                        alert("ปริมาณสินค้าทั้งหมดเกินปริมาณบรรจุของรถ กรุณากรอกรายละเอียดสินค้าใหม่อีกครั้ง");
                    },100
                );
            }
            
        })
        .catch(error => {
            console.log(error);
        });
    }
    renderImage(type){
        switch (type) {
            case 'box':
                return Boxs;
            case 'sack':
                return Sacks;
            case 'barrel':
                return Barrels;
            case 'bigbags':
                return Bigbags;
            default:
                return '-';
        }
    }
    render() {
        const TOKEN = {
            authorization: "admin eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJrZXkiOiI1YzBhMDI1ZmU3YTM1MDA3YzBjYmE1MTciLCJpYXQiOjE1NDk4NzIzODc2MTR9._0mSqYqB5UU-8Z01uoyqVrfA8cl_5hpO5_uCGbC2gJY"
        };
        
        return (
        <div>
            <Helmet>
					<title>Load Calculator | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
				</Helmet>
                <HeaderNav />

                <div className="container">
                    <Container>
                        <Row>

                        <Col md={3}>
                            <Card
                                content={
                                    <div>
                                        <CardHeader
                                            borderCardBottom
                                            title="1. เลือกประเภทรถขนส่ง"
                                        />
                                        <TruckListContainer>
                                            <div className="car-list">
                                                <Query
                                                    query={settingTruckListQuery}
                                                    fetchPolicy="network-only"
                                                    context={TOKEN}
                                                >
                                                    {({ loading, error, data, refetch }) => {
                                                        if (loading)
                                                            return (
                                                                <Skeleton
                                                                    height={250}
                                                                    width={300}
                                                                    style={{ marginTop: 10 }}
                                                                >
                                                                    <rect
                                                                        x="0"
                                                                        y="0"
                                                                        rx="5"
                                                                        ry="5"
                                                                        width="50"
                                                                        height="50"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="0"
                                                                        rx="4"
                                                                        ry="4"
                                                                        width="112"
                                                                        height="13"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="24"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="149"
                                                                        height="10"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="40"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="149"
                                                                        height="10"
                                                                    />

                                                                    <rect
                                                                        x="0"
                                                                        y="86"
                                                                        rx="5"
                                                                        ry="5"
                                                                        width="50"
                                                                        height="50"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="86"
                                                                        rx="4"
                                                                        ry="4"
                                                                        width="112"
                                                                        height="13"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="110"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="149"
                                                                        height="10"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="126"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="149"
                                                                        height="10"
                                                                    />

                                                                    <rect
                                                                        x="0"
                                                                        y="174"
                                                                        rx="5"
                                                                        ry="5"
                                                                        width="50"
                                                                        height="50"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="174"
                                                                        rx="4"
                                                                        ry="4"
                                                                        width="112"
                                                                        height="13"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="198"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="149"
                                                                        height="10"
                                                                    />
                                                                    <rect
                                                                        x="59"
                                                                        y="214"
                                                                        rx="3"
                                                                        ry="3"
                                                                        width="149"
                                                                        height="10"
                                                                    />
                                                                </Skeleton>
                                                            );

                                                        if (error) return <div>{error.message}</div>;
                                                        return (
                                                            <div>	
                                                                {
                                                                    data.settingTruckList.map((truck, index) => (
                                                                        <div>
                                                                            <TruckHeader key={index}>
                                                                                {`${this.props.t('common:instancePrice.truckType.truckTypes')} ${detectLanguageData(truck.setting_truck_name, this.props.t('common:lang'))}`}
                                                                            </TruckHeader>
                                                                            { truck.setting_truck_type.filter(item => item.truck_type_enable).map((item, key, arr) => {
                                                                                if(item._id !== '5bed286e2a9d8d18e851d7be'){
                                                                                    // console.log(item)
                                                                                    return  <TruckListItem
                                                                                            titleSize={this.isShowSizeLabel(item._id) ? item._id !== '5bed286e2a9d8d18e851d7be' ? this.props.t('common:instancePrice.truckType.sizeWidthLengthHeight') : '' : ''}
                                                                                            _id={item._id}
                                                                                            key={key}
                                                                                            image={`${s3}/${item.truck_type_pic}`}
                                                                                            enabled={item.truck_type_enable }
                                                                                            onClick={() => {																			
                                                                                                this.onSelect(
                                                                                                    truck,
                                                                                                    item
                                                                                                );
                                                                                            }}
                                                                                            selected={
                                                                                                item._id ===
                                                                                                this.state.setting_truck_type_id
                                                                                            }
                                                                                            title={
                                                                                                `${
                                                                                                    detectLanguageData(truck.setting_truck_name, this.props.t('common:lang'))
                                                                                                } - ${
                                                                                                    detectLanguageData(item.truck_type_name, this.props.t('common:lang'))
                                                                                                }` || undefined
                                                                                            }
                                                                                            size={
                                                                                                `${
                                                                                                    detectLanguageData(item.truck_type_size_lang, this.props.t('common:lang'))
                                                                                                }` || undefined
                                                                                            }
                                                                                            weightTotal={`${item._id !== '5bed286e2a9d8d18e851d7be' ? this.props.t('common:instancePrice.truckType.limitWeight') : ''} ${detectLanguageData(item.truck_type_weight_lang, this.props.t('common:lang')) ||
                                                                                                undefined}`}
                                                                                            showInfo={false}
                                                                                        />
                                                                                    }
                                                                                })
                                                                        }
                                                                        { truck._id == '5be5057787415b16d8cf48f6' &&
                                                                           <TruckListItem
                                                                                titleSize={this.props.t('common:LoadCalculator.titlesizetruck')}
                                                                                _id={'5be5057787415b16d8cf4123'}
                                                                                image={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/setting_truck/4-WHEELS-STALL.png`}
                                                                                enabled={1}
                                                                                title={this.props.t('common:LoadCalculator.StallSingle')}
                                                                                size={this.props.t('common:LoadCalculator.sizetruckStall')}
                                                                                weightTotal={this.props.t('common:LoadCalculator.weightTotal')}
                                                                                onClick={() => {																			
                                                                                    this.onSelect(
                                                                                        truck,
                                                                                        'STALL'
                                                                                    );
                                                                                }}
                                                                                selected={'5be5057787415b16d8cf4123' === this.state.setting_truck_type_id}
                                                                            />
                                                                        }
                                                                        { truck._id == '5be5057787415b16d8cf48f6' &&
                                                                           <TruckListItem
                                                                                titleSize={this.props.t('common:LoadCalculator.titlesizetruck')}
                                                                                _id={'5be5057787415b16d8cf4223'}
                                                                                image={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/setting_truck/4-WHEELS-CABINET.png`}
                                                                                enabled={1}
                                                                                title={this.props.t('common:LoadCalculator.CabinetSingle')}
                                                                                size={this.props.t('common:LoadCalculator.sizetruckCabinet')}
                                                                                weightTotal={this.props.t('common:LoadCalculator.weightTotal')}
                                                                                onClick={() => {																			
                                                                                    this.onSelect(
                                                                                        truck,
                                                                                        'CABINET'
                                                                                    );
                                                                                }}
                                                                                selected={'5be5057787415b16d8cf4223' === this.state.setting_truck_type_id}
                                                                            />
                                                                        }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    }}
                                                </Query>
                                            </div>
                                        </TruckListContainer>
                                    </div>
                                }
                            />
                        </Col>

                        <Col md={9}>
                            <Row>
                                {(this.state.showstep == 1) ? (
                                <Col md={12}>
                                    <Card
                                        content={
                                            <div>
                                                <CardHeader
                                                    borderCardBottom
                                                    title="2. ประเภทบรรจุ"
                                                />
                                                <TruckListContainer>
                                                    <div  className="car-list">
                                                        <Row style={{marginTop:"30px",marginBottom:"30px"}}>
                                                            <Col md={4} style={{textAlign:"center"}}>
                                                                <div className="img_cargo" style={{marginBottom:"20px"}}>
                                                                    <Image style={{height:"150px"}} src={this.renderImage(this.state.selectOptionCargo)} rounded />
                                                                </div>
                                                            </Col>
                                                            <Col md={8}>
                                                                <Row >
                                                                    <Col md={5}>
                                                                        <div className="radio" style={{textAlign:"center"}}>
                                                                            <label style={{padding:"0"}}>
                                                                            <div style={{width:"100%"}}>
                                                                                <Image style={{height:"90px"}} src={Onpallet} rounded />
                                                                            </div>
                                                                                <input type="radio" value="option1" style={{position:"unset",margin: '0'}} checked={this.state.selectedOption === 'option1'} onChange={(event) => {this.handleOptionChange(event)}}  />
                                                                                <div>ไม่ใส่พาเรท</div> 
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={7}>
                                                                        <div className="radio" style={{textAlign:"center"}}>
                                                                            <label style={{padding:"0"}}>
                                                                            <div style={{width:"100%"}}>
                                                                                <Image style={{height:"90px"}} src={Pallet} rounded />
                                                                            </div>
                                                                                <input type="radio" value="option2" style={{position:"unset", margin: '0'}} checked={this.state.selectedOption === 'option2'}  onChange={(event) => {this.handleOptionChange(event)}} /> 
                                                                                <div>ใส่พาเรท</div>
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={2}>
                                                                <FormControl
                                                                    componentClass="select"
                                                                    placeholder="select"
                                                                    defaultValue={this.state.selectOptionCargo}
                                                                    onChange={(event) => {
                                                                        this.onSelectpacking(event);
                                                                    }}
                                                                    name="selectoption_Cargo">
                                                                    <option value="box" selected data-image={Boxs}>Boxes</option>
                                                                    <option value="bigbags" data-image={Bigbags}>BigBags</option>
                                                                    <option value="barrel" data-image={Barrels}>Barrels</option>
                                                                    <option value="sack" data-image={Sacks}>Sacks</option>
                                                                </FormControl>
                                                            </Col>
                                                            <Col md={2}>
                                                                <ButtonToolbar>
                                                                    <Button className="addcargobtn"  onClick={() => this.addcargo() }>เพิ่มสินค้า</Button>
                                                                </ButtonToolbar>
                                                            </Col>  
                                                            <Col md={8}>
                                                                <div style={{float:"right"}}>
                                                                    <Button  className="addcargobtn" disabled={this.state.packer_data_cargo.length > 0 ? false : "disabled"} onClick={() => this.sumitcargo(this.setimage64) }>คำนวณ</Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {this.state.selectedOption === 'option2' &&
                                                            <Row >
                                                                <Col  md={12}>
                                                                    <Table className="table table-bordered" style={{marginTop:"10px"}}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th colSpan="6" style={{textAlign:"center"}}>พารามิเตอร์พาเลท</th>
                                                                            </tr>
                                                                            <tr >
                                                                                <th>ยาว(mm)</th>
                                                                                <th>กว้าง(mm)</th>
                                                                                <th>สูง(mm)</th>
                                                                                <th>น้ำหนักบรรทุกสูงสุด(kg)</th>
                                                                                <th>ระดับความสูงโหลดสูงสุด(mm)</th>
                                                                                <th>ความหนาของแผ่นพาเรท(mm)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{width:"10%"}}>
                                                                                    <FormControl type="text" name="PalletLength" value={this.state.palletlength} onChange={(event) => this.setState({ palletlength: event.target.value})} />
                                                                                </td>
                                                                                <td style={{width:"10%"}}>
                                                                                    <FormControl type="text" name="PalletWidth" value={this.state.palletwidth} onChange={(event) => this.setState({ palletwidth: event.target.value})} />
                                                                                </td>
                                                                                <td style={{width:"10%"}}>
                                                                                    <FormControl type="text" name="PalletHeight" value={this.state.palletheight} onChange={(event) => this.setState({ palletheight: event.target.value})} />
                                                                                </td>
                                                                                <td style={{width:"20%"}}>
                                                                                    <FormControl type="text" name="PalletPayload" value={this.state.palletpayload} onChange={(event) => this.setState({ palletpayload: event.target.value})} />
                                                                                </td>
                                                                                <td style={{width:"25%"}}>
                                                                                    <FormControl type="text" name="Palletlevel" value={this.state.palletlevel} onChange={(event) => this.setState({ palletlevel: event.target.value})} />
                                                                                </td>
                                                                                <td style={{width:"25%"}}>
                                                                                    <FormControl type="text" name="PalletseParat" value={this.state.palletseparat} onChange={(event) => this.setState({ palletseparat: event.target.value})} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <Row>
                                                            <Col md={12}>
                                                                <TablePacking
                                                                 imgCargo={Boxs}
                                                                >   
                                                                    {
                                                                        this.state.packer_data_cargo.map((item, key) => (
                                                                            <TablePackingItem
                                                                                key={key}
                                                                                image={item.box_img}
                                                                                namecargo={item.box_name}
                                                                                length={item.box_length}
                                                                                width={item.box_width}
                                                                                height={item.box_height}
                                                                                weight={item.box_weight}
                                                                                qty={item.box_number}
                                                                                color={item.box_color}
                                                                                handleDelete={() => this.deleteCargo(key)}
                                                                                handleChangeItem={(value , name) => this.setValue(value , name , key) }

                                                                            />
                                                                        ))
                                                                    }
                                                                </TablePacking>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div style={{float:"right"}}>
                                                                    <Button className="addcargobtn" disabled={this.state.packer_data_cargo.length > 0 ? false : "disabled"} onClick={() => this.sumitcargo(this.setimage64) }>คำนวณ</Button>
                                                                </div>
                                                                <div>
                                                                <Image src={this.state.image64}  />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </TruckListContainer>
                                            </div>
                                        }
                                    />
                                </Col>
                                ):(
                                    <Col md={12}>
                                        <Card
                                            content={
                                                <div>
                                                    <CardHeader   
                                                        borderCardBottom
                                                        title="3. ผลลัพท์"
                                                    />
                                                    <TruckListContainer>
                                                        <div  className="car-list">
                                                            <Row>
                                                                <Col md={12}>
                                                                    <h2 style={{marginTop:'10px'}}>การขนส่งทั้งหมด</h2>
                                                                    <div style={{float:"right"}}>
                                                                        <Button className="addcargobtn" onClick={() => this.setStatecargo() }>กลับ</Button>
                                                                    </div>
                                                                    {this.state.imageresponse.length > 0 &&
                                                                        <h3 style={{borderBottom: "1px solid #ddd",paddingBottom: "20px"}}>ใช้รถทั้งหมด {this.state.dataresponse.unit.truck-1} คัน</h3>
                                                                    }
                                                                    {this.state.imageresponse ? (
                                                                        // <h3>ใช้รถทั้งหมด {this.state.dataresponse.unit.truck-1} คัน</h3>,
                                                                        this.state.imageresponse.map((item) => (
                                                                            <div className="transportslist">
                                                                                <div>รูปแบบนี้ใช้รถ {item.number} คัน</div>
                                                                                <div>บรรจุคันละ {item.packed} packed</div>
                                                                                <div><Image style={{height:"500px", width: "auto"}} src={item.image64} rounded /></div>
                                                                                <hr></hr>
                                                                            </div>
                                                                        )) 
                                                                    ) : (
                                                                        <div>Loading...</div>
                                                                    )
                                                                    } 
                                                                    {this.state.imageresponse.length > 0 &&
                                                                        <div style={{float:"right"}}>
                                                                            <Button className="addcargobtn" onClick={() => this.setStatecargo() }>กลับ</Button>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        
                                                    </TruckListContainer>
                                                </div>
                                            }
                                        />
                                    </Col>
                                    )
                                }
                            </Row>
                        </Col>

                    </Row>
            
                    </Container>
                    
                </div>
            <Footer />
        </div>
        )
    }
}

export default withNamespaces()(LoadCalculator);