import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 1;
	height: 30px;
	
	& .buttonBackArrow {
		cursor: pointer;
		display: flex;
		
		& i {
			color: rgb(217, 1, 1);
			margin-right: 10px;
			font-size: 20px;
		}

		& .buttonBackText {
			font-size: 20px;
		}
	}
`;

const buttonBack = props => {
	return (
		<Container {...props}>
			<div className="buttonBackArrow" onClick={props.clicked}>
				<i className="fal fa-arrow-left"></i>
				<div className="buttonBackText">กลับ</div>
			</div>
		</Container>
	);
};

export default buttonBack;
