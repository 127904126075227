import gql from 'graphql-tag';

export default gql`
	query AccountInfoListSubAccount {
		accountInfoList {
      account_info {
        account_info_email
        
        account_users {
          _id
          account_user_type
          account_user_status
          account_user_name
          account_user_phone_code
          account_user_phone_number
          account_user_display_name
          account_user_display_picture
          account_user_permission {
						shipment_list
						noti
					}
        }
      }
    }
	}
`;
