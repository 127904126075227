import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const StyledButton = styled(Button)`
	&.ant-btn {
		min-width: 100px;
		height: 36px;

		&:hover {
			color: #000000;
		}
	}

	&.ant-btn-primary {
		color: #ffffff;
		background-color: #d90101 !important;
		border: none;

		&:hover,
		&:focus {
			color: #ffffff;
			background-color: #b70101 !important;
		}

		&[disabled] {
			background-color: #b7010190 !important;
		}
	}
`;

const button = props => {
	return <StyledButton {...props}>{props.children}</StyledButton>;
};

export default button;
