import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Container, Pin, DetailContainer } from './Styles';

const propTypes = {
	type: PropTypes.oneOf(['success', 'fail', 'process']),
	children: PropTypes.node.isRequired,
	borderBottom: PropTypes.bool,
};

const defaultProps = {
	type: 'process',
	borderBottom: false,
};

const DropPointItem = props => {
	const { children, type, borderBottom, ...otherProps } = props;

	return (
		<Container {...otherProps}>
			<Pin type={type}>
				<i class="fas fa-circle" />
			</Pin>
			<DetailContainer borderBottom={borderBottom ? 1 : 0}>
				{children}
			</DetailContainer>
		</Container>
	);
};

DropPointItem.propTypes = propTypes;
DropPointItem.defaultProps = defaultProps;

export default DropPointItem;
