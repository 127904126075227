import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  display: inline-block;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  border: 1px solid hsl(210, 9%, 96%);
  position: relative;
  height: 110px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;

  & img {
    height: 100%;
    width: 100%;
  }
`;

const CardBannerSponsor = (props) => {
  return (
    <Container {...props}>
      <img src={props.src} alt="banner" />
    </Container>
  )
}

export default CardBannerSponsor;