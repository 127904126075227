import React, { Component } from 'react';
import {
	Row,
	Col,
	Table,
	OverlayTrigger,
	Button,
	FormControl,
	NavDropdown,
	NavItem,
	Checkbox,
	Popover,
	Alert,
} from 'react-bootstrap';
import Moment from 'react-moment';
import styled from 'styled-components';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';

//Style
import 'bootstrap-daterangepicker/daterangepicker.css';
//GraphQL
import { Query, Mutation } from 'react-apollo';
import shipmentList from './graphql/query/shipmentList';
//Component
import {
	Header,
	BreadcrumbHeader,
	Card,
	CardHeader,
	Input,
} from 'src/components';
// import { Label, ProgressCircle, SelectDropdown } from './components';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { HeaderNav, SubHeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import accountInfoListQuery from './graphql/query/accountInfoList';
import lazadaPackageOrdersListQuery from './graphql/query/lazadaPackageOrdersList';
import lazadaPackageOrderStatusQuery from './graphql/query/lazadaPackageOrderStatus';
import lazadaParcelOrderListQuery from './graphql/query/parcelOrdersList';
import parcelOrderStatus from './graphql/query/parcelOrderStatus';
import client from 'src/config/client';
import lambdaUrl from 'src/config/lambdaUrl.js';
import axios from 'axios';
import DO_NOTHING_MUTATION from './graphql/mutation/doNothing';
import CardTable from './container/CardTable/CardTable';
import ModalImportFileProduct from './container/ModalImportFileProduct';
import { Menu } from 'src/components';
import ModalPrintLabelPreview from './container/ModalPrintLabelPreview';
import createLabelPacelsMutation from './graphql/mutation/createLabelPacels';
import cancelParcelOrderMutation from './graphql/mutation/cancelOrderApi';
import ModalAlert from '../../components/ModalAlert';
import baseUrl from '../../config/baseUrl';
import ModalConfirm from '../../components/ModalConfirm';
import ModalAlertMessage from '../../components/ModalAlert/ModelAlertMessage';

//Style Component

const Container = styled.div``;

const CustomCheckbox = styled(Checkbox)`
	padding-left: 35px !important;
	margin-top: -22px;

	& input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #ffffff;
		border: 1px solid #e3e3e3;
		border-radius: 4px;
	}

	&:hover input ~ .checkmark {
		background-color: #f1f1f1;
	}

	& input:checked ~ .checkmark {
		border: 1px solid #ffffff;
		background-color: #19aa19;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	& input:checked ~ .checkmark:after {
		display: block;
	}

	& .checkmark:after {
		left: 6px;
		top: 3px;
		width: 6px;
		height: 10px;
		border: solid #ffffff;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const TableContainer = styled(Table)`
	margin-bottom: 10px;

	& tbody {
		& tr {
			& td {
				vertical-align: middle;
			}
		}
	}
`;

const AboveWrapper = styled.div`
	background-color: #fff;
	padding-top: 160px;
`;

const BoxDashboard = styled.div`
	height: 90px;
	min-width: 175px;
	padding-top: 9x;
	padding-left: 10px;
	border: 2px solid black;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	:not(:first-child) {
		margin-left: 10px;
	}

	& h1 {
		font-weight: bold;
		color: #fff;
		line-height: 1px;
	}

	& h3 {
		font-weight: bold;
		color: #fff;
	}
`;

const BoxWrapper = styled.div`
	display: flex;
	margin-top: 15px;
	margin-left: 20px;
	margin-bottom: 10px;
	overflow: auto;

	&::-webkit-scrollbar {
		display: block;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&:hover::-webkit-scrollbar {
		display: block;
	}

	&:hover {
		-ms-overflow-style: block; /* IE and Edge */
		scrollbar-width: block; /* Firefox */
	}

	& ${BoxDashboard}.white {
		background: #fff;
		border-color: #000;
		& h1 {
			font-weight: bold;
			color: #000;
		}

		& h3 {
			font-weight: bold;
			color: #000;
		}
	}

	& ${BoxDashboard}.black {
		background: #000;
		border-color: #000;
		color: #fff;
	}

	& ${BoxDashboard}.yellow {
		background: #e89c37;
		border-color: #e89c37;
		color: #fff;
	}

	& ${BoxDashboard}.blue {
		background: #337ab7;
		border-color: #337ab7;
		color: #fff;
	}

	& ${BoxDashboard}.red {
		background: #c63b3c;
		border-color: #c63b3c;
		color: #fff;
	}

	& ${BoxDashboard}.emerald {
		background: #82c5b9;
		border-color: #82c5b9;
		color: #fff;
	}

	& ${BoxDashboard}.green {
		background: #81b214;
		border-color: #81b214;
		color: #fff;
	}

	& ${BoxDashboard}.velvet {
		background: #d54062;
		border-color: #d54062;
		color: #fff;
	}
`;
const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	margin-bottom: 10px;

	strong {
		font-weight: bold;
	}
`;

const FormatStatus = styled.div`
	background: #fff;
	color: #000;
	border-radius: 5px;
	text-align: center;
	width: 70%;
	font-weight: bold;

	&.none {
		border: 2px solid black;
	}

	&.black {
		background: rgb(0, 0, 0);
		color: rgb(255, 255, 255);
	}

	&.blue {
		background: #337ab7;
		color: rgb(255, 255, 255);
	}

	&.red {
		background: #c63b3c;
		color: rgb(255, 255, 255);
	}

	&.yellow {
		background: #e89c37;
		color: rgb(255, 255, 255);
	}

	&.green {
		background: #81b214;
		color: rgb(255, 255, 255);
	}

	&.emerald {
		background: #82c5b9;
		color: rgb(255, 255, 255);
	}

	&.velvet {
		background: #d54062;
		color: rgb(255, 255, 255);
	}
`;

const SubHeaderNavStyle = styled(SubHeaderNav)`
	position: fixed;
	top: calc(60px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		position: relative;
		top: 0;
	}
`;

class ProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cutOffList: [],
			page: 1,
			totalDoc: 0,
			checked: [],
			selectedProduct: [],
			linkDownloadLabelParcels: [],
			selectedProductList: [],

			//Filter
			trackingNumber: '',
			kaUser: '',
			zoneCode: '',
			status: 0,
			packageCode: '',
			planDate: '',
			displayPlanDate: '',
			productItem: '',
			createDate: '',
			displayCreateDate: '',
			lastUpdate: '',
			displayLastUpdate: '',
			slaPickup: '',
			displaySlaPickup: '',
			slaDelivery: '',
			displaySlaDelivery: '',
			orderNumber: '',
			insuranceValue: null,
			poNumber: '',
			otherRefCode: '',
			destination: '',
			cosigneeName: '',
			paidAmount: null,
			pickupAddress: '',
			deliveryAddress: '',

			//modal
			isPlanFromDateModal: false,
			isPlanFromOrderModal: false,
			isOpenFMCRImportFileMOdal: false,
			isOpenModalImportFileProduct: false,
			isOpenModalPrintLabelPreview: false,

			//sort
			order_by: 'create_date',
			order_type: '-1',

			accountInfoId: '',
			isAlert: {
				type: '',
				title: '',
				message: '',
				isOpen: false,
			},
			isAlertMessage: {
				message: '',
				isOpen: false,
			},
			isAlertNoCancel: {
				isOpen: false,
				data: [],
			},
		};

		this._inputImportFile = React.createRef();
	}

	componentDidMount() {
		this.getAccountInfoId();
	}

	async getAccountInfoId() {
		const { data: accountInfoListData } = await client.query({
			query: accountInfoListQuery,
			variables: {
				customer_service_type: 'PARCEL',
			},
			fetchPolicy: 'network-only',
		});

		if (
			accountInfoListData &&
			accountInfoListData.accountInfoList &&
			accountInfoListData.accountInfoList.account_info.length > 0 &&
			accountInfoListData.accountInfoList.account_info[0]._id !== null
		) {
			this.setState({
				accountInfoId: accountInfoListData.accountInfoList.account_info[0]._id,
			});
		}
	}

	uploadFileHandler = async (file, refetchlazadaParcelOrderListQuery) => {
		try {
			const path = JSON.stringify({ path: file.path });

			console.log('uploadFileHandler', file, path);

			const config = {
				method: 'post',
				url: `${lambdaUrl}/btv-create-order`,
				headers: {
					'Content-Type': 'application/json',
				},
				data: path,
			};

			await axios(config).then(res => {
				console.log('uploadFileHandler com', JSON.stringify(res.data));
				// if (!res.data._id) {
				//   alert('Error');
				//   return;
				// }
				refetchlazadaParcelOrderListQuery();
			});
		} catch (error) {
			alert(`${lambdaUrl}/btv-create-order'` + error);
		}
	};

	fetchlazadaPackageOrders = async () => {
		try {
			const { data } = await client.query({
				query: lazadaPackageOrdersListQuery,
				variables: {
					page: 1,
					showData: 15,
				},
			});

			console.log('fetchlazadaPackageOrders', data);

			if (data && data.parcelOrders && data.parcelOrders.orders.length > 0) {
				// console.log('fetchlazadaPackageOrders: ', data.lazadaImportOrderList.order)
				this.setState({
					cutOffList: [...data.parcelOrders.orders],
					totalDoc: data.parcelOrders.totalDocument,
				});
			}
		} catch (error) {
			alert(error);
		}
	};

	formatMoney = number => {
		return `${number.toLocaleString('en-US', {
			maximumFractionDigits: 2,
		})} บาท`;
	};

	formatNumber = number => {
		return `${number.toLocaleString('en-US', {
			maximumFractionDigits: 2,
		})}`;
	};

	fetchMorelazadaPackageOrders = async () => {
		try {
			const { data: moreData } = await client.query({
				query: lazadaPackageOrdersListQuery,
				variables: {
					page: this.state.page + 1,
					showData: 15,
				},
			});

			console.log('fetchMorelazadaPackageOrders', moreData);

			if (
				moreData &&
				moreData.parcelOrders &&
				moreData.parcelOrders.orders.length > 0
			) {
				// console.log('fetchlazadaPackageOrders: ', moreData.lazadaImportOrderList.order)
				this.setState(prevState => ({
					cutOffList: [
						...prevState.cutOffList,
						...moreData.parcelOrders.orders,
					],
					page: prevState.page + 1,
				}));
			}
		} catch (error) {
			alert(error);
		}
	};

	reFetchlazadaPackageOrders = async () => {
		try {
			const { data: moreData } = await client.query({
				query: lazadaPackageOrdersListQuery,
				variables: {
					page: 1,
					showData: this.state.cutOffList.length,
				},
			});

			if (
				moreData &&
				moreData.parcelOrders &&
				moreData.parcelOrders.orders.length > 0
			) {
				this.setState(prevState => ({
					cutOffList: [...moreData.parcelOrders.orders],
					checked: [],
				}));
			}
		} catch (error) {
			alert(error);
		}
	};

	refetchQueries = () => {
		setTimeout(() => {
			client.mutate({
				mutation: DO_NOTHING_MUTATION,
				refetchQueries: () => [
					'LazadaImportOrderListBookingFile',
					'parcelOrderStatus',
				],
			});
		}, 500);
	};

	handlerClickCheckBox = packageData => {
		if (
			this.state.checked.filter(data => data._id === packageData._id).length > 0
		) {
			this.setState(prevState => {
				return {
					checked: prevState.checked.filter(
						data => data._id !== packageData._id
					),
				};
			});
		} else {
			this.setState(prevState => ({
				checked: [...prevState.checked, packageData],
			}));
		}
	};

	handlerCheckAll = () => {
		if (this.state.checked.length > 0) {
			this.setState({
				checked: [],
			});
		} else {
			const newCheck = this.state.cutOffList.filter(
				data => data.parcel_order_status == 1
			);
			this.setState({
				checked: newCheck,
			});
		}
	};

	renderCheckCheckBox = packageData => {
		return (
			this.state.checked.filter(data => data._id === packageData._id).length > 0
		);
	};

	renderSorting(order_by) {
		if (order_by == this.state.order_by) {
			if (this.state.order_type == 1) {
				return 'sorting_asc';
			} else {
				return 'sorting_desc';
			}
		}

		return 'sorting';
	}

	sort(order_by, refetch) {
		if (order_by == this.state.order_by) {
			this.setState({ order_type: this.state.order_type * -1 });
		} else {
			this.setState({ order_type: 1, order_by });
		}
		refetch();
	}

	renderStatus = status => {
		switch (status) {
			case 1:
				return <FormatStatus className="black">คงคลังลูกค้า</FormatStatus>;
			case 2:
				return <FormatStatus className="none">แพลนไปรับสินค้า</FormatStatus>;
			case 3:
				return <FormatStatus className="yellow">กำลังไปคลัง GSC</FormatStatus>;
			case 4:
				return (
					<FormatStatus className="none">
						Failed นำสินค้าออกจาก Lazada
					</FormatStatus>
				);
			case 5:
				return <FormatStatus className="blue">คงคลัง Giztix</FormatStatus>;
			case 6:
				return <FormatStatus className="red">ยกเลิก</FormatStatus>;
			case 7:
				return <FormatStatus className="emerald">ออกจัดส่งลูกค้า</FormatStatus>;
			case 8:
				return <FormatStatus className="green">ส่งสำเร็จ</FormatStatus>;
			case 9:
				return <FormatStatus className="velvet">คืนคลังลูกค้า</FormatStatus>;
			default:
				return <div> - </div>;
		}
	};

	handleDateRangePicker(mode, picker) {
		const date = `${picker.startDate.format(
			'YYYY-MM-DD'
		)}:${picker.endDate.format('YYYY-MM-DD')}`;

		const display = `${picker.startDate.format(
			'DD/MM/YYYY'
		)} - ${picker.endDate.format('DD/MM/YYYY')}`;

		if (mode === 'createDate') {
			this.setState({ createDate: date, displayCreateDate: display });
		} else if (mode === 'lastUpdate') {
			this.setState({ lastUpdate: date, displayLastUpdate: display });
		} else if (mode === 'slaPickup') {
			this.setState({ slaPickup: date, displaySlaPickup: display });
		} else if (mode === 'slaDelivery') {
			this.setState({ slaDelivery: date, displaySlaDelivery: display });
		} else if (mode === 'planDate') {
			this.setState({ planDate: date, displayPlanDate: display });
		}
	}

	calTimeLeft = (date, mode) => {
		const timeTillDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
		const timeFormat = 'YYYY-MM-DD HH:mm:ss';

		const then = moment(timeTillDate, timeFormat);
		const timeLeft = then.diff(moment(), 'second');

		if (timeLeft <= 0) {
			if (mode == 0) {
				return moment(timeTillDate).format('DD/MM/YYYY HH:mm:ss');
			} else {
				return (
					<b style={{ color: 'red' }}>
						{moment(timeTillDate).format('DD/MM/YYYY HH:mm:ss')}
					</b>
				);
			}
		}

		const formatTime = moment
			.duration(timeLeft, 'second')
			.format(`DD [วัน] HH [ชั่วโมง] mm [นาที]`);

		return formatTime;
	};

	calTimeStatus = date => {
		const timeTillDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
		const timeFormat = 'YYYY-MM-DD HH:mm:ss';

		const then = moment(timeTillDate, timeFormat);
		const timeLeft = then.diff(moment(), 'second');

		console.log('timeLeft: ', timeLeft);

		if (timeLeft <= 10800) {
			return (
				<span style={{ color: 'red' }}>
					{moment(timeTillDate).format('DD/MM/YYYY HH:mm:ss')}
				</span>
			);
		}
	};

	handleClickCancelOrder = (refetch, data) => {
		if (data.cancelOrderApi.message === 'Failed') {
			this.setState({
				isAlertMessage: {
					message: data.cancelOrderApi.messageError,
					isOpen: true,
				},
				isAlert: {
					isOpen: false,
				},
			});
		} else {
			this.setState({
				selectedProduct: [],
				selectedProductList: [],
				isAlertMessage: {
					message: 'บันทึกสำเร็จแล้ว',
					isOpen: true,
				},
				isAlert: {
					isOpen: false,
				},
			});
			refetch();
		}
	};

	render() {
		return (
			<Container>
				<Helmet>
					<title>
						Shipment | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
						รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full />
				<Menu>
					{/* 
				<Query
					query={shipmentList}
					variables={{
						shipment_number: this.state.formOptionSearch,
						shipment_status: this.state.statusID,
						page_number: this.state.page_number,
						order_type: this.state.order_type,
						pick_up_date: this.state.pick_up_date,
						shipment_user_ref: this.state.shipmentUserRef,
					}}
					fetchPolicy="cache-and-network"
				>
					{({ loading, error, data, refetch }) => {
						return ( */}
					<Row style={{ padding: 0, margin: 0 }}>
						<Col md={12} style={{ padding: 0, margin: 0 }}>
							<div>
								<div>
									<CardTable
										style={{ borderTop: 'none' }}
										title=""
										category={``}
										ctTableFullWidth
										ctTableResponsive
										rightButton={
											<div style={{ float: 'right', marginLeft: '15px' }}>
												<Button
													style={{
														background: '#87b792',
														color: '#fff',
														marginRight: '15px',
														display: 'none',
													}}
													onClick={() =>
														this.setState({ isPlanFromDateModal: true })
													}
												>
													<i className="fas fa-cloud-upload"></i>{' '}
													แพลนรับสินค้าจากวันที่สร้าง
												</Button>

												<Button
													style={{
														background: '#c19970',
														color: '#fff',
														display: 'none',
													}}
													onClick={() =>
														this.setState({ isPlanFromOrderModal: true })
													}
													disabled={this.state.checked.length === 0}
												>
													<i className="fas fa-cloud-upload"></i> แพลนรับสินค้า
												</Button>
											</div>
										}
										content={
											<Query
												query={lazadaParcelOrderListQuery}
												variables={{
													params: {
														trackingNumber: this.state.trackingNumber,
														packageCode: this.state.packageCode,
														create_date: this.state.createDate,
														last_update: this.state.lastUpdate,
														plan_date: this.state.planDate,
														pickup_sla_date: this.state.slaPickup,
														delivery_sla_date: this.state.slaDelivery,
														parcel_order_status: this.state.status,
														delivery_zone: this.state.zoneCode,
														account_info: this.state.kaUser,
														orderNumber: this.state.orderNumber,
														insuranceValue: this.state.insuranceValue,
														poNumber: this.state.poNumber,
														otherRefCode: this.state.otherRefCode,
														productName: this.state.productName,
														paidAmount: this.state.paidAmount || undefined,
														pickupAddress: this.state.pickupAddress,
														deliveryAddress: this.state.deliveryAddress,
														consigneeName: this.state.cosigneeName,
														poNumber: this.state.poNumber,
													},
													showData: 30,
													page: this.state.page,
													sort: {
														order_by: this.state.order_by,
														order_type: this.state.order_type,
													},
												}}
												fetchPolicy="cache-and-network"
												onCompleted={() => {
													this.setState({
														selectedProduct: [],
														selectedProductList: [],
													});
												}}
											>
												{({ data, loading, error, refetch }) => {
													return (
														<div>
															<AboveWrapper>
																<FilterWrapper>
																	<div
																		style={{
																			display: 'flex',
																			flexDirection: 'row',
																			alignItems: 'center',
																		}}
																	>
																		<strong
																			style={{
																				marginLeft: 20,
																				marginRight: '10px',
																			}}
																		>
																			สถานะรายการสินค้า:
																		</strong>{' '}
																		วันที่
																		<DateRangePicker
																			onApply={(event, picker) =>
																				this.handleDateRangePicker(
																					'createDate',
																					picker
																				)
																			}
																			onCancel={() =>
																				this.setState({
																					createDate: '',
																					displayCreateDate: '',
																					page: 1,
																				})
																			}
																			containerStyles={{
																				display: 'block',
																				marginLeft: '10px',
																			}}
																			opens="right"
																			format="DD/MM/YYYY"
																		>
																			<FormControl
																				type="text"
																				value={this.state.displayCreateDate}
																				placeholder="วันที่รับข้อมูล"
																			/>
																		</DateRangePicker>
																	</div>

																	<div style={{ display: 'flex' }}>
																		<Mutation
																			mutation={cancelParcelOrderMutation}
																			update={(_cache, { data }) => {
																				this.handleClickCancelOrder(
																					refetch,
																					data
																				);
																			}}
																			onError={() => {
																				alert('ยกเลิกไม่สำเร็จ');
																			}}
																		>
																			{cancelOrderApi => (
																				<ModalConfirm
																					show={this.state.isAlert.isOpen}
																					onPressButtonCancel={() => {
																						this.setState({
																							isAlert: {
																								isOpen: false,
																							},
																						});
																					}}
																					onPressButtonConfirm={() => {
																						this.state.selectedProductList.forEach(
																							item => {
																								cancelOrderApi({
																									variables: {
																										trackingNumber:
																											item.trackingNumber,
																									},
																								});
																							}
																						);
																					}}
																					labelButtonCancel="ปิด"
																					labelButtonConfirm="ยืนยัน"
																					titleHeader="แจ้งเตือน"
																					onHide={() => {
																						this.setState({
																							isAlert: {
																								isOpen: false,
																							},
																						});
																					}}
																				>
																					<div className="content">
																						<strong style={{ lineHeight: 1 }}>
																							ต้องการยกเลิก Order ใช่หรือไม่
																							<ul style={{ paddingLeft: 24 }}>
																								{this.state.selectedProductList.map(
																									item => (
																										<li>
																											{item.trackingNumber}
																										</li>
																									)
																								)}
																							</ul>
																						</strong>
																						<p>
																							กรุณาตรวจสอบความถูกต้องก่อนกดยืนยัน
																						</p>
																					</div>
																				</ModalConfirm>
																			)}
																		</Mutation>
																		<Button
																			style={{
																				marginRight: '10px',
																				backgroundColor: '#842029',
																				color: '#ffffff',
																			}}
																			disabled={
																				this.state.selectedProductList
																					.length === 0
																			}
																			onClick={() => {
																				const _findStatus = this.state.selectedProductList.filter(
																					item => item.parcel_order_status !== 1
																				);
																				if (_findStatus.length > 0) {
																					this.setState({
																						isAlertNoCancel: {
																							isOpen: true,
																							data: _findStatus,
																						},
																					});
																				} else {
																					this.setState({
																						isAlert: {
																							isOpen: true,
																						},
																					});
																				}
																				// this.handleClickCancelOrder(refetch);
																			}}
																		>
																			Cancel Order
																		</Button>

																		<ModalConfirm
																			show={this.state.isAlertNoCancel.isOpen}
																			onPressButtonCancel={() => {
																				this.setState({
																					isAlertNoCancel: {
																						isOpen: false,
																						data: [],
																					},
																				});
																			}}
																			labelButtonCancel="ปิด"
																			labelButtonConfirm="ยืนยัน"
																			titleHeader="แจ้งเตือน"
																			onHide={() => {
																				this.setState({
																					isAlertNoCancel: {
																						isOpen: false,
																						data: [],
																					},
																				});
																			}}
																		>
																			<div className="content">
																				<strong style={{ lineHeight: 1 }}>
																					Order
																					<ul style={{ paddingLeft: 24 }}>
																						{this.state.isAlertNoCancel.data.map(
																							item => (
																								<li>{item.trackingNumber}</li>
																							)
																						)}
																					</ul>
																					ไม่สามารถยกเลิกได้
																				</strong>
																				<p>กรุณาตรวจสอบอีกครั้ง</p>
																			</div>
																		</ModalConfirm>

																		<Link to="/reports">
																			<Button
																				style={{
																					marginRight: '10px',
																				}}
																			>
																				ดาวน์โหลด Report
																			</Button>
																		</Link>
																		<Mutation
																			mutation={createLabelPacelsMutation}
																			update={(
																				_cache,
																				{ data: createLabelPacelsData }
																			) => {
																				this.setState({
																					linkDownloadLabelParcels: [
																						...createLabelPacelsData
																							.createLabelPacels.linkDownLoads,
																					],
																					isOpenModalPrintLabelPreview: true,
																				});
																			}}
																			onError={err => {
																				alert(err.message);
																			}}
																		>
																			{(
																				createLabelPacels,
																				{ loading: createLabelPacelsLoading }
																			) => (
																				<Button
																					disabled={
																						this.state.selectedProduct
																							.length === 0 ||
																						createLabelPacelsLoading
																					}
																					style={{
																						marginRight: '10px',
																						background: '#81B214',
																						color: '#ffffff',
																					}}
																					onClick={() =>
																						createLabelPacels({
																							variables: {
																								orderIdList: this.state
																									.selectedProduct,
																							},
																						})
																					}
																				>
																					<i className="fas fa-print" />{' '}
																					{`Print Label${
																						this.state.selectedProduct.length >
																						0
																							? ` (${this.state.selectedProduct.length})`
																							: ''
																					}`}
																					{createLabelPacelsLoading
																						? '...'
																						: ''}
																				</Button>
																			)}
																		</Mutation>

																		<ModalPrintLabelPreview
																			open={
																				this.state.isOpenModalPrintLabelPreview
																			}
																			linkDownloadLabelParcels={
																				this.state.linkDownloadLabelParcels
																			}
																			onClose={() =>
																				this.setState({
																					isOpenModalPrintLabelPreview: false,
																				})
																			}
																		/>

																		<Button
																			style={{
																				marginRight: '20px',
																				background: '#D90101',
																				color: '#ffffff',
																			}}
																			onClick={() =>
																				this.setState({
																					isOpenModalImportFileProduct: true,
																				})
																			}
																		>
																			<i className="fas fa-cloud-upload"></i>{' '}
																			Import product
																		</Button>

																		<ModalImportFileProduct
																			accountInfoId={this.state.accountInfoId}
																			open={
																				this.state.isOpenModalImportFileProduct
																			}
																			onClose={() =>
																				this.setState({
																					isOpenModalImportFileProduct: false,
																				})
																			}
																			onCompleted={() => {
																				refetch();
																				this.refetchQueries();
																			}}
																		/>
																	</div>
																</FilterWrapper>

																<Query
																	query={parcelOrderStatus}
																	variables={{
																		create_date: this.state.createDate,
																	}}
																	fetchPolicy="cache-and-network"
																>
																	{({
																		data: orderStatusData,
																		loading: loadingStatusData,
																		error: errorStatusData,
																		refetch: refetchStatusData,
																	}) => {
																		// console.log(
																		// 	'orderStatusData',
																		// 	orderStatusData
																		// );
																		return (
																			<BoxWrapper>
																				<BoxDashboard className="white">
																					<h3>ทั้งหมด</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.total
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus.total
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="black">
																					<h3>คงคลังลูกค้า</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.new
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus.new
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="yellow">
																					<h3>กำลังไปคลัง GSC</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.outbound_lazada
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus
																										.outbound_lazada
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="blue">
																					<h3>คงคลัง Giztix</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.inbound_giztix
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus
																										.inbound_giztix
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="emerald">
																					<h3>ออกจัดส่งลูกค้า</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.out_delivery
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus
																										.out_delivery
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="green">
																					<h3>ส่งสำเร็จ</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.delivered
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus.delivered
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="velvet">
																					<h3>คืนคลังลูกค้า</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.return_lazada
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus
																										.return_lazada
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>

																				<BoxDashboard className="red">
																					<h3>ยกเลิก</h3>
																					<h1>
																						{orderStatusData &&
																						orderStatusData.parcelOrderStatus &&
																						orderStatusData.parcelOrderStatus
																							.cancel
																							? this.formatNumber(
																									orderStatusData
																										.parcelOrderStatus.cancel
																							  )
																							: 0}
																						{` `}รายการ
																					</h1>
																				</BoxDashboard>
																			</BoxWrapper>
																		);
																	}}
																</Query>
															</AboveWrapper>
															<span
																style={{
																	fontWeight: 'bold',
																	fontSize: '20px',
																	margin: '10px 20px',
																}}
															>
																<strong>ตารางรายการสินค้า</strong>
															</span>
															<div
																className="table-vertical"
																style={{
																	minHeight: 'calc(100vh - 420px)',
																	maxHeight: 'calc(100vh - 420px)',
																	overflowX: 'scroll',
																	marginRight: 20,
																	marginLeft: 20,
																}}
															>
																<TableContainer striped hover>
																	<thead>
																		<tr>
																			{
																				// check box column //
																				// <th style={{ minWidth: '100px' }}></th>
																			}
																			{
																				// <th style={{ minWidth: '100px' }}>No</th>
																			}
																			<th style={{ minWidth: '60px' }}></th>
																			<th style={{ minWidth: '170px' }}>
																				Tracking Number
																			</th>
																			<th style={{ minWidth: '170px' }}>
																				สถานะ
																			</th>
																			<th style={{ minWidth: '400px' }}>
																				สินค้า
																			</th>
																			<th style={{ minWidth: '200px' }}>COD</th>
																			<th style={{ minWidth: '400px' }}>
																				Pickup
																			</th>
																			<th style={{ minWidth: '400px' }}>
																				Delivery
																			</th>
																			<th style={{ minWidth: '220px' }}>
																				Consignee
																			</th>
																			<th style={{ minWidth: '220px' }}>
																				Order Number
																			</th>
																			<th style={{ minWidth: '220px' }}>
																				Insurance Value
																			</th>
																			<th style={{ minWidth: '220px' }}>
																				PO Number
																			</th>
																			<th style={{ minWidth: '220px' }}>
																				Other Ref. Code
																			</th>
																			<th
																				style={{ minWidth: '200px' }}
																				className={this.renderSorting(
																					'create_date'
																				)}
																				onClick={() =>
																					this.sort('create_date', refetch)
																				}
																			>
																				วันที่รับข้อมูล
																			</th>
																		</tr>
																		<tr>
																			<th>
																				<CustomCheckbox
																					inline
																					checked={
																						this.state.selectedProductList
																							.length > 0 &&
																						this.state.selectedProduct.length >
																							0 &&
																						(data.parcelOrders.orders || [])
																							.length > 0 &&
																						(data.parcelOrders.orders || [])
																							.map(
																								productItem => productItem._id
																							)
																							.every(productId =>
																								this.state.selectedProduct.includes(
																									productId
																								)
																							)
																					}
																					onChange={event => {
																						if (
																							event.target.checked &&
																							data.parcelOrders.orders.length
																						) {
																							const productsId = data.parcelOrders.orders.map(
																								productItem => productItem._id
																							);
																							this.setState({
																								selectedProduct: [
																									...productsId,
																								],
																								selectedProductList:
																									data.parcelOrders.orders,
																							});
																						} else {
																							this.setState({
																								selectedProduct: [],
																								selectedProductList: [],
																							});
																						}
																					}}
																				>
																					<span className="checkmark" />
																				</CustomCheckbox>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Tracking Number"
																					name="trackingNumber"
																					value={this.state.trackingNumber}
																					onChange={event => {
																						this.setState({
																							trackingNumber:
																								event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					componentClass="select"
																					placeholder="select"
																					defaultValue={this.state.status}
																					onChange={event =>
																						this.setState({
																							status: event.target.value,
																							page: 1,
																						})
																					}
																					name="status"
																				>
																					<option value={0}>ทั้งหมด</option>
																					<option value={1}>
																						คงคลังลูกค้า
																					</option>
																					{
																						// <option value={2}>แพลนไปรับสินค้า</option>
																						// <option value={4}>Failed นำสินค้าออกจาก Lazada</option>
																					}
																					<option value={3}>
																						กำลังไปคลัง GSC
																					</option>
																					<option value={5}>
																						คงคลัง Giztix
																					</option>
																					<option value={7}>
																						ออกจัดส่งลูกค้า
																					</option>
																					<option value={8}>ส่งสำเร็จ</option>
																					<option value={9}>
																						คืนคลังลูกค้า
																					</option>
																					<option value={6}>ยกเลิก</option>
																				</FormControl>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหาสินค้า"
																					name="productName"
																					value={this.state.productName}
																					onChange={event => {
																						this.setState({
																							productName: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา COD"
																					name="paidAmount"
																					value={this.state.paidAmount}
																					onChange={event => {
																						this.setState({
																							paidAmount:
																								event.target.value === ''
																									? null
																									: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Pickup"
																					name="pickupAddress"
																					value={this.state.pickupAddress}
																					onChange={event => {
																						this.setState({
																							pickupAddress: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Delivery"
																					name="deliveryAddress"
																					value={this.state.deliveryAddress}
																					onChange={event => {
																						this.setState({
																							deliveryAddress:
																								event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Consignee"
																					name="cosigneeName"
																					value={this.state.cosigneeName}
																					onChange={event => {
																						this.setState({
																							cosigneeName: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Order Number"
																					name="orderNumber"
																					value={this.state.orderNumber}
																					onChange={event => {
																						this.setState({
																							orderNumber: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Insurance Value"
																					name="insuranceValue"
																					value={this.state.insuranceValue}
																					onChange={event => {
																						this.setState({
																							insuranceValue:
																								event.target.value === ''
																									? null
																									: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา PO Number"
																					name="poNumber"
																					value={this.state.poNumber}
																					onChange={event => {
																						this.setState({
																							poNumber: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<FormControl
																					type="text"
																					placeholder="ค้นหา Ref. Code"
																					name="otherRefCode"
																					value={this.state.otherRefCode}
																					onChange={event => {
																						this.setState({
																							otherRefCode: event.target.value,
																							page: 1,
																						});
																					}}
																				/>
																			</th>
																			<th>
																				<DateRangePicker
																					onApply={(event, picker) =>
																						this.handleDateRangePicker(
																							'createDate',
																							picker
																						)
																					}
																					onCancel={() =>
																						this.setState({
																							createDate: '',
																							displayCreateDate: '',
																							page: 1,
																						})
																					}
																					containerStyles={{
																						display: 'block',
																					}}
																					opens="right"
																					format="DD/MM/YYYY"
																				>
																					<FormControl
																						type="text"
																						value={this.state.displayCreateDate}
																						placeholder="วันที่รับข้อมูล"
																					/>
																				</DateRangePicker>
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{!loading &&
																		!error &&
																		data.parcelOrders.orders.length > 0 ? (
																			data.parcelOrders.orders.map(
																				(itemN, indexN) => (
																					<tr key={itemN._id}>
																						<td>
																							<CustomCheckbox
																								inline
																								checked={
																									!!this.state.selectedProduct.find(
																										productId =>
																											productId === itemN._id
																									)
																								}
																								onChange={event => {
																									if (
																										event.target.checked &&
																										this.state.selectedProduct
																											.length === 30
																									) {
																										alert(
																											'เลือกได้สูงสุด 30 รายการ'
																										);
																										return;
																									}

																									this.setState({
																										selectedProduct: event
																											.target.checked
																											? [
																													...this.state
																														.selectedProduct,
																													itemN._id,
																											  ]
																											: this.state.selectedProduct.filter(
																													productId =>
																														productId !==
																														itemN._id
																											  ),
																										selectedProductList: event
																											.target.checked
																											? [
																													...this.state
																														.selectedProductList,
																													itemN,
																											  ]
																											: this.state.selectedProductList.filter(
																													productId =>
																														productId._id !==
																														itemN._id
																											  ),
																									});
																								}}
																							>
																								<span className="checkmark" />
																							</CustomCheckbox>
																						</td>
																						<td
																							style={{
																								width: '50px',
																							}}
																						>
																							<Button
																								onClick={() => {
																									window.open(
																										`/trackingdetail?shipment=${itemN.trackingNumber}`,
																										'_blank'
																									);
																								}}
																							>
																								{itemN.trackingNumber
																									? itemN.trackingNumber
																									: null}
																							</Button>
																						</td>
																						<td>
																							{this.renderStatus(
																								itemN.parcel_order_status
																							)}
																						</td>
																						<td
																							style={{
																								width: '300px',
																								wordWrap: 'break-word',
																							}}
																						>
																							<OverlayTrigger
																								trigger="hover"
																								key="bottom"
																								placement="bottom"
																								overlay={
																									<Popover
																										id={`popover-positioned-${itemN._id}`}
																										title={`สินค้าทั้งหมด ${itemN.items.length} ชิ้น `}
																									>
																										{itemN.items.map(
																											(product, key) => (
																												<p>
																													{`${product.name}`}{' '}
																												</p>
																											)
																										)}
																									</Popover>
																								}
																							>
																								<b
																									style={{
																										cursor: 'pointer',
																									}}
																								>
																									{itemN.items.length > 1
																										? `สินค้า ${itemN.items.length} ชิ้น`
																										: itemN.items[0]
																										? itemN.items[0].name
																										: ' - '}
																								</b>
																							</OverlayTrigger>
																						</td>
																						<td>
																							{itemN.payment.paidAmount &&
																							itemN.payment.paymentType ===
																								'COD'
																								? this.formatMoney(
																										itemN.payment.paidAmount
																								  )
																								: '-'}
																						</td>
																						<td>
																							{itemN.origin.address.details
																								? itemN.origin.address.details
																								: '-'}
																						</td>
																						<td>
																							{itemN.destination.address.details
																								? itemN.destination.address
																										.details
																								: '-'}
																						</td>
																						<td>
																							<p style={{ margin: 0 }}>
																								{itemN.destination
																									? itemN.destination.name
																									: '-'}
																							</p>
																							<p style={{ margin: 0 }}>
																								{itemN.destination
																									? itemN.destination.phone
																									: '-'}
																							</p>
																						</td>
																						<td>
																							{itemN.orderNumber
																								? itemN.orderNumber
																								: '-'}
																						</td>
																						<td>
																							{itemN.insuranceValue
																								? itemN.insuranceValue
																								: '-'}
																						</td>
																						<td>
																							{itemN.poNumber
																								? itemN.poNumber
																								: '-'}
																						</td>
																						<td>
																							{itemN.otherRefCode
																								? itemN.otherRefCode
																								: '-'}
																						</td>
																						<td>
																							{itemN.create_date ? (
																								<Moment format="DD/MM/YYYY HH:mm:ss">
																									{itemN.create_date}
																								</Moment>
																							) : (
																								'-'
																							)}
																						</td>
																					</tr>
																				)
																			)
																		) : (
																			<tr>
																				<td colSpan="7">No Data</td>
																			</tr>
																		)}
																		{!(
																			this.state.trackingNumber ||
																			this.state.packageCode ||
																			this.state.displayCreateDate ||
																			this.state.lastUpdate ||
																			this.state.status > 0 ||
																			this.state.planDate
																		) && (
																			<tr
																				style={{
																					display:
																						this.state.cutOffList.length ==
																							this.state.totalDoc && 'none',
																				}}
																			>
																				<td colSpan="8">
																					<Button
																						onClick={() =>
																							this.fetchMorelazadaPackageOrders()
																						}
																					>
																						loading more
																					</Button>
																				</td>
																			</tr>
																		)}
																	</tbody>
																</TableContainer>
															</div>
															<div
																style={{
																	textAlign: 'right',
																	marginTop: 20,
																	marginRight: 20,
																}}
															>
																{!loading && !error && data.parcelOrders && (
																	<ReactPaginate
																		pageCount={data.parcelOrders.totalPage}
																		forcePage={this.state.page - 1}
																		containerClassName="pagination"
																		nextLabel=">"
																		previousLabel="<"
																		onPageChange={({ selected }) => {
																			this.setState({ page: selected + 1 });
																		}}
																	/>
																)}
															</div>
														</div>
													);
												}}
											</Query>
										}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Menu>
				{/* );
					}}
				</Query> */}
				<ModalAlertMessage
					id="alertCancelOrderError"
					show={this.state.isAlertMessage.isOpen}
					title={`${this.state.isAlertMessage.message}`}
					onCloseModal={() =>
						this.setState({
							isAlertMessage: {
								message: '',
								isOpen: false,
							},
						})
					}
				/>
			</Container>
		);
	}
}

export default withNamespaces()(ProductList);
