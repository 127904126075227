import React from 'react';
import styled from 'styled-components';
import { Modal as ModalBootstrap } from 'react-bootstrap';

const ModalContainer = styled(ModalBootstrap)`
  & .modal-dialog {
    top: 30%;
    transform: translateY(-50%);
  }
`;

const Modal = (props) => {
  return (
    <ModalContainer show={props.show} onHide={props.onHide}>
      <ModalContainer.Body style={{textAlign: 'center'}}>
        <div><strong>{ props.title }</strong></div>
        { props.children }
      </ModalContainer.Body>
    </ModalContainer>
  )
}

export default Modal;