import gql from 'graphql-tag';

export default gql`
	query SettingPortList($description: String) {
		settingPortList(description: $description) {
			settingPort {
        _id
        description
        structured_formatting {
          main_text
          secondary_text
        }
        lat
        lng
        container_port_type
        province
        district
        subdistrict
        postcode
      }

      settingPortFavorite {
        _id
        account_info_id
        setting_container_port_id
        description
        structured_formatting {
          main_text
          secondary_text
        }
        lat
        lng
        container_port_type
        province
        district
        subdistrict
        postcode
      }
		}
	}
`;
