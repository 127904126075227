import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from "react-i18next";

const Container = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

	.truck-img {
		& img {
			height: 40px;
		}
	}

	.detail {
		flex: 1;
		margin-left: 20px;

		&-title {
			font-size: 20px;
			line-height: 1;
		}

		&-truck-name {
			font-size: 20px;
			font-weight: bold;
			line-height: 1;
		}
	}

	.arrow {
		color: #b3b3b3;
		font-size: 20px;
		line-height: 1;

    ${ props => props.selected && `color: #D90101;` }
  }

  ${ props => props.selected && `color: #D90101;` }
`;

class TruckTypeSelect extends React.Component {
	render() {
		return (
			<Container {...this.props} selected={this.props.selected}>
				<div className="truck-img">
					<img src={this.props.serviceImage} alt="truck" />
				</div>
				<div className="detail">
					<div className="detail-title">{ this.props.t('common:instancePrice.stepServicePaymentDetails.titleTruckTypes') }</div>
					<div className="detail-truck-name">{ this.props.truckName }</div>
				</div>
				<div className="arrow">
          {
            this.props.selected ? (
              <i className="fal fa-chevron-left" />
            ) : (
              <i className="fal fa-chevron-right" />
            )
          }
				</div>
			</Container>
		);
	}
}

TruckTypeSelect.propTypes = {
  serviceImage: PropTypes.string,
  truckName: PropTypes.string,
  selected: PropTypes.bool,
};

TruckTypeSelect.defaultProps = {
  serviceImage: '',
  truckName: '',
  selected: false,
};

export default withNamespaces()(TruckTypeSelect);
