import gql from 'graphql-tag';

export default gql`
query AccountInfoListLineDriver(
  $id: ID
) {
  accountInfoList(
    _id: $id
  ) {
    account_info {
      account_info_phone_code
      account_info_phone_number
    }
  }
}
`;
