import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 20px;
  font-weight: bold;

  ${props => props.inline && `
    display: inline-block;
  `}
`;

const Required = styled.span`
  color: #D90101;
`;

const Label = (props) => {
  return (
    <Container inline={props.inline} style={props.style}>{props.children}{ props.required && (<Required>*</Required>) }</Container>
  )
}

export default Label;