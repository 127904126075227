import gql from "graphql-tag";

export default gql`
	query parcelOrders($params: paramsLazPackageOrder, $sort: sort, $page: Int, $showData: Int!) {
		parcelOrders(params: $params, sort: $sort, page: $page, showData: $showData) {
			orders {
				_id
				trackingNumber
				payment {
					paymentType
					paidAmount
					currency
				}
				parcel_order_status
				account_info {
					_id
					account_info_business_name
				}
				items {
					name
				}
				delivery_zone
				pickup_sla_date
				delivery_sla_date
				create_date
				packageCode
				origin {
					address {
						details
					}
				}
				destination {
					name
					phone
					address {
						details
					}
				}
				orderNumber
				insuranceValue
				poNumber
				otherRefCode
			}
			totalPage
			totalDocument
		}
	}
`;
