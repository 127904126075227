import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Header } from './../../../../components';
import {
	Card,
	BlankSpace,
	CardHeader,
	BoxServiceItem,
	CreditCardIcon,
	BoxPriceItem,
	ModalAlert,
	Skeleton,
	PDFPreview,
	ModalConfirm,
} from 'src/components';
import { Row, Col, Button, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import detectLanguageData from './../../../../function/detectLanguageData';
import modeToText from './../../../../function/modeToText';
import moment from 'moment';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import updateLocationTempMutationClient from 'src/config/graphqlClient/mutation/updateLocationTemp';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import styled from 'styled-components';
import { RouteContact, PaymentChannel, ShipmentDetailList } from './../../components';
import singleUploadMutation from './graphql/mutation/singleUpload';
import client from 'src/config/client';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import s3 from 'src/config/s3';

const ShipmentDetailListContainer = styled.div``;
const ShipmentDetailListStyled = styled(ShipmentDetailList)`
	&:last-child {
		margin-bottom: -10px;
	}
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;

	& .image {
		width: 86px;
		height: 86px;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000080;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0px;
		}
	}

	.btn-hover-image {
		color: white;
		font-size: 18px;
		cursor: pointer;
		padding: 2px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: normal;

		& i {
			margin-right: 5px;
		}
	}
`;

class ShipmentMore extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			isOpenModalAlertImage: false,
			textAlertModalImage: '',

			//LightBox
			photoIndexLightBox: 0,
			isOpenLightBox: false,

			userRef: '',
			isOpenModalUserRef: false,

			remark: '',
			isOpenModalRemark: false,
		};

		this._inputUploadImage = React.createRef();
	}

	handleSingleUploadDocument(uploadFile, documentName, event) {
		const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
		if (checkFileTypeImage === false) {
			this.setState({
				isOpenModalAlertImage: true,
				textAlertModalImage: this.props.t(
					'common:instancePrice.modal.uploadFileCheckTypeFile.content'
				),
			});
			return;
		}

		const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
		if (checkFileSize === false) {
			this.setState({
				isOpenModalAlertImage: true,
				textAlertModalImage: this.props.t(
					'common:instancePrice.modal.uploadFileCheckSizeFile.content'
				),
			});
			return;
		}

		const {
			target: {
				validity,
				files: [file],
			},
		} = event;

		if (validity) {
			const timestamp = new Date().getTime();
			uploadFile({
				variables: {
					file: file,
					path: `upload/shipments/${documentName}_${timestamp}`,
				},
			});
		}
	}

	onDeletePhotoonDeletePhoto(file) {
		const data = client.readQuery({
			query: instancePriceQueryClient,
		});

		const document = data.instancePrice.document.filter(item => `${s3}/${item.path}` !== file.path);

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...data.instancePrice,
					document,
				},
			},
		});
	}

	checkFileTypeImage(file) {
		if (file) {
			return (
				file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf'
			);
		}
	}

	checkFileSizeImage(file) {
		if (file) {
			const size = parseFloat(file.size / 1024).toFixed(2);
			//ขนาดไฟล์ 10240KB or 10Mb
			return size < 10240;
		}
	}

	onDeletePhoto(file) {
		const data = client.readQuery({
			query: instancePriceQueryClient,
		});

		const document = data.instancePrice.document.filter(item => `${s3}/${item.path}` !== file.path);

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...data.instancePrice,
					document,
				},
			},
		});
	}

	render() {
		return (
			<React.Fragment>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Query query={instancePriceStepQueryClient}>
								{instancePriceStepData => {
									return (
										<Query query={instancePriceQueryClient}>
											{instancePriceData => {
												const documents = instancePriceData.data.instancePrice.document.map(
													item => {
														const patternFileType = /\.[0-9a-z]{1,5}$/;
														const patternCheckFileType = /^.*\.(pdf|PDF)$/;
														const thumbnail = patternCheckFileType.test(item.path)
															? `${require('src/assets/images/file-type-pdf.png')}`
															: `${s3}/${item.path}`;
														const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

														return {
															path: `${s3}/${item.path}`, //path ไฟล์
															fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
															type: type, //ประเภทไฟล์
															thumbnail: thumbnail, //รูปตัวอย่าง
														};
													}
												);

												const documentsImage = documents.map(item => {
													const patternCheckFileType = /^.*\.(pdf|PDF)$/;
													if (patternCheckFileType.test(item.path)) {
														return <PDFPreview file={item.path} />;
													}

													return item.thumbnail;
												});

												return (
													<Card
														content={
															<div id="confirm-shipment-more">
																<Header
																	title={this.props.t(
																		'common:instancePrice.shipmentConfirm.moreInformations.title'
																	)}
																	style={{ marginBottom: 10 }}
																/>

																<ShipmentDetailListContainer>
																	<Mutation
																		mutation={singleUploadMutation}
																		update={(cache, { data }) => {
																			const instancePriceData = cache.readQuery({
																				query: instancePriceQueryClient,
																			});

																			const document = instancePriceData.instancePrice.document.concat(
																				{
																					path: data.singleUpload.path,
																					__typename: 'Document',
																				}
																			);

																			cache.writeQuery({
																				query: instancePriceQueryClient,
																				data: {
																					instancePrice: {
																						...instancePriceData.instancePrice,
																						document,
																					},
																				},
																			});
																		}}
																		onError={error => {
																			console.log(error);
																		}}
																	>
																		{uploadFile => {
																			return (
																				<input
																					accept="image/jpeg, image/png, application/pdf"
																					onChange={event =>
																						this.handleSingleUploadDocument(
																							uploadFile,
																							'document',
																							event
																						)
																					}
																					type="file"
																					id="file"
																					ref={this._inputUploadImage}
																					style={{ display: 'none' }}
																				/>
																			);
																		}}
																	</Mutation>

																	<ShipmentDetailListStyled
																		title={`${this.props.t(
																			'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.title'
																		)} (${documents.length}/6)`}
																		icon="fal fa-camera"
																		rightLabel={this.props.t(
																			'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.add'
																		)}
																		border
																		contentImage={documents}
																		onUpload={() => {
																			if (documents.length < 6) {
																				//อัพโหลดได้สูงสุด 6 ไฟล์
																				this._inputUploadImage.current.click();
																			} else {
																				this.setState({
																					isOpenModalAlertImage: true,
																					textAlertModalImage: this.props.t(
																						'common:instancePrice.modal.uploadFileProductMaximum.content'
																					),
																				});
																			}
																		}}
																	>
																		{documents.length > 0 && (
																			<ImageContainer>
																				{documents.map((item, index) => (
																					<div className="image">
																						<div className="image-hover">
																							<div
																								className="btn-hover-image"
																								onClick={() => {
																									this.setState({
																										photoIndexLightBox: index,
																										isOpenLightBox: true,
																									});
																								}}
																							>
																								<i className="fal fa-search"></i>
																								<span>
																									{item.type === 'image'
																										? this.props.t(
																												'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewPicture'
																										  )
																										: this.props.t(
																												'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewDocument'
																										  )}
																								</span>
																							</div>
																							<div
																								className="btn-hover-image"
																								onClick={() => {
																									this.onDeletePhoto(item);
																								}}
																							>
																								<i className="fal fa-trash-alt"></i>
																								<span>
																									{item.type === 'image'
																										? this.props.t(
																												'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.deletePicture'
																										  )
																										: this.props.t(
																												'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.deleteDocument'
																										  )}
																								</span>
																							</div>
																						</div>
																						<img src={`${item.thumbnail}`} alt="" />
																					</div>
																				))}
																			</ImageContainer>
																		)}
																	</ShipmentDetailListStyled>

																	<ModalConfirm
																		show={this.state.isOpenModalAlertImage}
																		onPressButtonConfirm={() =>
																			this.setState({ isOpenModalAlertImage: false })
																		}
																		labelButtonConfirm={this.props.t(
																			'common:instancePrice.modal.button.ok'
																		)}
																		titleHeader={this.props.t('common:instancePrice.modal.title')}
																		styleColorButtonConfirm="primary"
																		onHide={() => this.setState({ isOpenModalAlertImage: false })}
																	>
																		{this.state.textAlertModalImage}
																	</ModalConfirm>

																	{this.state.isOpenLightBox && (
																		<Lightbox
																			mainSrc={documentsImage[this.state.photoIndexLightBox]}
																			nextSrc={
																				documentsImage.length > 1
																					? documentsImage[
																							(this.state.photoIndexLightBox + 1) %
																								documentsImage.length
																					  ]
																					: undefined
																			}
																			prevSrc={
																				documentsImage.length > 1
																					? documentsImage[
																							(this.state.photoIndexLightBox +
																								documentsImage.length -
																								1) %
																								documentsImage.length
																					  ]
																					: undefined
																			}
																			onCloseRequest={() =>
																				this.setState({ isOpenLightBox: false })
																			}
																			onMovePrevRequest={() =>
																				this.setState(prev => ({
																					photoIndexLightBox:
																						(prev.photoIndexLightBox + documentsImage.length - 1) %
																						documentsImage.length,
																				}))
																			}
																			onMoveNextRequest={() =>
																				this.setState(prev => ({
																					photoIndexLightBox:
																						(prev.photoIndexLightBox + 1) % documentsImage.length,
																				}))
																			}
																		/>
																	)}

																	<Mutation
																		mutation={updateInstancePriceMutationClient}
																		variables={{
																			userRef: this.state.userRef,
																		}}
																	>
																		{updateInstancePrice => {
																			return (
																				<div>
																					<ModalConfirm
																						show={this.state.isOpenModalUserRef}
																						onPressButtonCancel={() =>
																							this.setState({ isOpenModalUserRef: false })
																						}
																						onPressButtonConfirm={() =>
																							this.setState({ isOpenModalUserRef: false }, () =>
																								updateInstancePrice()
																							)
																						}
																						labelButtonConfirm={this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.buttonConfirm'
																						)}
																						labelButtonCancel={this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.buttonCancel'
																						)}
																						titleHeader={this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.title'
																						)}
																						styleColorButtonConfirm="primary"
																					>
																						<FormControl
																							placeholder={this.props.t(
																								'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.placeholder'
																							)}
																							value={this.state.userRef}
																							onChange={e =>
																								this.setState({ userRef: e.target.value })
																							}
																						/>
																					</ModalConfirm>
																				</div>
																			);
																		}}
																	</Mutation>

																	<ShipmentDetailListStyled
																		onClick={() =>
																			this.setState({
																				isOpenModalUserRef: true,
																				userRef: this.props.instancePriceQuery.instancePrice
																					.userRef,
																			})
																		}
																		title={this.props.t(
																			'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.title'
																		)}
																		content={instancePriceData.data.instancePrice.userRef}
																		icon="fal fa-clipboard-list"
																		rightLabel={
																			instancePriceData.data.instancePrice.userRef
																				? this.props.t(
																						'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.edit'
																				  )
																				: this.props.t(
																						'common:instancePrice.shipmentConfirm.moreInformations.refJobCode.add'
																				  )
																		}
																		border
																	/>

																	<Mutation
																		mutation={updateInstancePriceMutationClient}
																		variables={{
																			remark: this.state.remark,
																		}}
																	>
																		{updateInstancePrice => {
																			return (
																				<div>
																					<ModalConfirm
																						show={this.state.isOpenModalRemark}
																						onPressButtonCancel={() =>
																							this.setState({ isOpenModalRemark: false })
																						}
																						onPressButtonConfirm={() =>
																							this.setState({ isOpenModalRemark: false }, () =>
																								updateInstancePrice()
																							)
																						}
																						labelButtonConfirm={this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.buttonConfirm'
																						)}
																						labelButtonCancel={this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.buttonCancel'
																						)}
																						titleHeader={this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.title'
																						)}
																						styleColorButtonConfirm="primary"
																					>
																						<FormControl
																							componentClass="textarea"
																							placeholder={this.props.t(
																								'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.placeholder'
																							)}
																							value={this.state.remark}
																							maxLength={255}
																							onChange={e =>
																								this.setState({ remark: e.target.value })
																							}
																						/>
																					</ModalConfirm>
																				</div>
																			);
																		}}
																	</Mutation>

																	{instancePriceData.data.instancePrice.shipmentType === 1 && (
																		<ShipmentDetailListStyled
																			style={{ whiteSpace: 'pre-line' }}
																			onClick={() =>
																				this.setState(
																					{
																						remark: instancePriceData.data.instancePrice.remark,
																					},
																					() => {
																						if (
																							instancePriceData.data.instancePrice
																								.setting_truck_type_id ===
																							'5bed286e2a9d8d18e851d7be'
																						) {
																							this.setState({ isOpenModalRemarkBike: true });
																							return;
																						}

																						this.setState({ isOpenModalRemark: true });
																					}
																				)
																			}
																			title={this.props.t(
																				'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.title'
																			)}
																			content={instancePriceData.data.instancePrice.remark}
																			icon="fal fa-file-alt"
																			rightLabel={
																				instancePriceData.data.instancePrice.remark
																					? this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.edit'
																					  )
																					: this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.remarkCargoDetail.add'
																					  )
																			}
																		/>
																	)}

																	{(instancePriceData.data.instancePrice.shipmentType === 2 ||
																		instancePriceData.data.instancePrice.shipmentType === 3) && (
																		<ShipmentDetailListStyled
																			style={{ whiteSpace: 'pre-line' }}
																			onClick={() => {
																				const instancePriceStepData = client.readQuery({
																					query: instancePriceStepQueryClient,
																				});

																				client.writeQuery({
																					query: instancePriceStepQueryClient,
																					data: {
																						instancePriceStep: {
																							...instancePriceStepData.instancePriceStep,
																							selectRemark: true,
																						},
																					},
																				});
																			}}
																			title={this.props.t(
																				'common:instancePrice.shipmentConfirm.moreInformations.more.title'
																			)}
																			content={instancePriceData.data.instancePrice.remark}
																			icon="fal fa-file-alt"
																			rightLabel={
																				instancePriceData.data.instancePrice.remark
																					? this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.more.edit'
																					  )
																					: this.props.t(
																							'common:instancePrice.shipmentConfirm.moreInformations.more.add'
																					  )
																			}
																		/>
																	)}
																</ShipmentDetailListContainer>
															</div>
														}
													/>
												);
											}}
										</Query>
									);
								}}
							</Query>
						);
					}}
				</Mutation>
			</React.Fragment>
		);
	}
}

export default compose(
	graphql(instancePriceQueryClient),
	graphql(updateLocationTempMutationClient, {
		name: 'locationTempMutation',
	}),
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	})
)(withNamespaces()(ShipmentMore));
