import React from 'react';
import styled from 'styled-components';
import color from '../../config/color';

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #E3E3E3;
  background-image: url(${props => props.img});
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;


  & .card-content {
    padding: 60px 90px;

    &-title {
      position: relative;
      font-weight: 700;
      font-size: 60px;
      color: #FFFFFF;
      line-height: 1;
    }

    &-subtitle {
      font-size: 24px;
      color: #FFFFFF;
      line-height: 1;
    }
  }
`;

const SymbolSquare = styled.div`
	display: inline-block;
	position: absolute;
	top: -5px;
	height: 14px;
	width: 14px;
	border-radius: 4px;
  background-color: ${color.Primary};
`;

const CardImageHeader = (props) => {
  return (
    <Container img={props.img}>
      <div className="card-content">
        <div className="card-content-title">{props.title}<SymbolSquare /></div>
        <div className="card-content-subtitle">{props.subtitle.split("\n").map(item => {
          return (<div>{item}</div>)
        })}</div>
      </div>
    </Container>
  )
}

export default CardImageHeader;