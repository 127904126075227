import gql from 'graphql-tag';

export default gql`
	query FlashDealsList($id: ID, $showData: Int, $pageNumber: Int) {
		flashDealsList(_id: $id, show_data: $showData, page_number: $pageNumber) {
			flash_deals {
				_id
				status
				setting_truck_id
				setting_truck_type_id
				truck_type_pic
				pickup_start
				pickup_end
				name_lang {
					th
					en
				}
				discount
				location_start_lang {
					th
					en
				}
				location_end_lang {
					th
					en
				}
				truck_type_name {
					th
					en
				}
				setting_truck_name {
					th
					en
				}
				position_start {
					lat
					lng
				}
				position_end {
					lat
					lng
				}
				truck_type_size_lang {
					th
					en
				}
				price
				price_sale
				exp
			}
			total_page
		}
	}
`;
