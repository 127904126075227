import React, { Component } from "react";
import styled from "styled-components";
import { Card, CardHeader , ModalConfirm } from "src/components";
import { graphql, compose } from "react-apollo";
import instancePriceQueryClient from "src/config/graphqlClient/query/instancePrice";
import instancePriceStepQueryClient from "src/config/graphqlClient/query/instancePriceStep";
import locationTempQueryClient from "src/config/graphqlClient/query/locationTemp";
import client from "src/config/client";
import _ from "lodash";
import { withNamespaces } from "react-i18next";
import { RadioItem, DriverItem, CheckboxItem } from "./components";
import { Button } from "react-bootstrap";
import { Input } from "antd";
import { InstancePriceContext } from "./../../contexts/InstancePriceContext";
import favoriteAndBlacklistDriverList from "./graphql/query/favoriteAndBlacklistDriverList";
import s3 from 'src/config/s3';

const { Search } = Input;
const Container = styled.div`
  width: 100%;
`;

const RadioItemCustom = styled(RadioItem)`
  ${props =>
    props.border &&
    `
    border-bottom: 1px solid #ddd;
  `}
`;

const CheckboxItemCustom = styled(CheckboxItem)`
  border-bottom: 1px solid #ddd;
`;

const TextMessage = styled.div`
  color: #888888;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

class SelectRemark extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      optionSelect: 1,
      isLoadingDriverList: false,
      driverName: '',
      driverLists: [],
      driverSelected: [],
      showModalInfo: false,
    };
  }

  static contextType = InstancePriceContext;

  componentDidMount() {
    this.setStateFromClient();
  }

  setStateFromClient = () => {
    const instancePriceData = client.readQuery({
      query: instancePriceQueryClient,
    });

    const {
      favoriteDriver,
    } = instancePriceData.instancePrice;
    
    const driverSelected = favoriteDriver.map((item) => item.id);

    this.setState({
      driverSelected: [...driverSelected],
      optionSelect: driverSelected.length === 0 ? 1 : 2,
    }, () => {
      if(this.state.optionSelect === 2) {
        this.fetchDriverList();
      }
    });
  }

  fetchDriverList = async () => {
    try {
      this.setState({ isLoadingDriverList: true });
      const instancePriceData = client.readQuery({
        query: instancePriceQueryClient,
      });

      const { setting_truck_type_id } = instancePriceData.instancePrice;
      const { driverName } = this.state;
      const { data } = await client.query({
        query: favoriteAndBlacklistDriverList,
        variables: {
          setting_truck_service: setting_truck_type_id,
          driver_name: driverName,
          mode: 'favorite',
        },
        fetchPolicy: 'network-only',
      });

      const { driver_user } = data.favoriteAndBlacklistDriverList;

      const driverLists = driver_user.map(item => {
        return {
          id: item._id,
          driverName: item.driver_name,
          driverDisplayPic: item.driver_display_pic,
        };
      });

      this.setState({ driverLists: driverLists, isLoadingDriverList: false });
    } catch (error) {
      this.setState({ isLoadingDriverList: false });
      console.log(error);
    }
  };

  closePopup() {
    this.context.onClose(["selectDriver"]);
  }

  selectHandler = item => {
    if (item.value === 2) {
      this.fetchDriverList();
    }

    this.setState({ 
      optionSelect: item.value,
      driverName: '',
      driverSelected: [],
    });
  };

  searchDriverHandler = event => {
    this.setState({ driverName: event.target.value }, () => {
      this.fetchDriverList();
    });
  };

  selectDriverHandler = (driverId) => {
    const { driverSelected } = this.state;
    const checkSelected = driverSelected.filter(item => item === driverId);
    const newDriverLists = checkSelected.length > 0 ? driverSelected.filter(item => item !== driverId) : [...driverSelected, driverId];
    this.setState({ driverSelected: newDriverLists });
  }

  isSelectDriver = (driverId) => {
    return this.state.driverSelected.filter(item => item === driverId).length > 0;
  }

  selectAllDriver = () => {
    const { driverLists } = this.state;
    const isSelectAll = this.isSelectAll();

    if(isSelectAll) {
      this.setState({ driverSelected: [] });
    } else {
      const driverSelected = driverLists.map(item => item.id);
      this.setState({ driverSelected: driverSelected });
    }
  }

  isSelectAll = () => {
    const { driverLists, driverSelected } = this.state;
    return driverLists.length === driverSelected.length;
  }

  confirmSelectHandler = () => {
    const {
      driverSelected,
    } = this.state;

    const instancePriceData = client.readQuery({
      query: instancePriceQueryClient,
    });
    
    const favoriteDriver = driverSelected.map((item) => {
      return {
        id: item,
        __typename: 'FavoriteDriver'
      }
    });
    
    client.writeQuery({
      query: instancePriceQueryClient,
      data: {
        instancePrice: {
          ...instancePriceData.instancePrice,
          favoriteDriver: [...favoriteDriver]
        }
      }
    });

    this.context.onClose(['selectDriver']);
  }

  render() {
    const { optionSelect, driverLists, driverName, isLoadingDriverList, driverSelected } = this.state;

    const selectList = [
      {
        label: "พนักงานขับรถ Giztix ทั้งหมด",
        value: 1
      },
      {
        label: "ระบุพนักงานขับรถคนโปรด",
        value: 2
      }
    ];

    const optionList = selectList.map((item, index) => (
      <RadioItemCustom
        key={index}
        name={item.label}
        selected={item.value === optionSelect}
        onPressSelected={() => this.selectHandler(item)}
        border={true}
        showInfo={index === 1 ? true : false}
        onClickInfo={e => {
          e.stopPropagation();
          this.setState({showModalInfo : true});
        }}
      />
    ));

    let renderDriverList = null;
    if (optionSelect === 2) {
      renderDriverList = (
        <React.Fragment>
          <Search
            placeholder="ค้นหาชื่อพนักงานขับรถ"
            onSearch={value => console.log(value)}
            value={driverName}
            onChange={event => this.searchDriverHandler(event)}
            style={{ marginTop: 15 }}
          />

          {!isLoadingDriverList && driverLists.length > 0 && (
            <React.Fragment>
              {driverName === '' && (
                <CheckboxItemCustom 
                  name={`เลือกทั้งหมด (${driverLists.length} คน)`}
                  onPressSelected={this.selectAllDriver}
                  selected={this.isSelectAll()}
                />
              )}

              {driverLists.map(item => (
                <DriverItem 
                  key={item.id} 
                  name={item.driverName}
                  onPressSelect={() => this.selectedDriver(item.id)}
                  selected={this.isSelectDriver(item.id)}
                  onPressSelected={() => this.selectDriverHandler(item.id)}
                  src={item.driverDisplayPic ? `${s3}/${item.driverDisplayPic}` : ''}
                />
              ))}
            </React.Fragment>
          )}

          {(!isLoadingDriverList && driverLists.length === 0) && (<TextMessage>- คุณยังไม่มีพนักงานขับรถคนโปรด -</TextMessage>)}
          {isLoadingDriverList && (<TextMessage>กำลังโหลด...</TextMessage>)}
        </React.Fragment>
      );
		}

    return (
      <Container>
        <Card
          content={
            <div>
              <CardHeader
                title={"เลือกพนักงานขับรถ"}
                onPressClose={() => this.closePopup()}
                borderCardBottom
              />
              {optionList}
              {renderDriverList}
              <Button 
                bsStyle="primary"
                block 
                style={{ marginTop: 50 }}
                disabled={optionSelect === 2 && driverSelected.length === 0}
                onClick={() => this.confirmSelectHandler()}
              >
                ยืนยัน
              </Button>
            </div>
          }
        />

        {/* Modal เงื่อนไขการเลือกคนขับคนโปรด*/}
				<ModalConfirm
					show={this.state.showModalInfo}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					onHide={() => this.setState({ showModalInfo: false })}
					onPressButtonConfirm={() => this.setState({ showModalInfo: false })}
				>
					<div style={{lineHeight: 1}}>
						<div style={{marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>เงื่อนไขการเลือกคนขับคนโปรด</div>
						<div style={{marginBottom: 40}}>
            ในกรณีคนขับที่คุณเลือกไม่ทราบสามารถรับงานได้ ทางระบบจะทำการคัดเลือกพนักงานท่านอื่นให้อัตโนมัติ ยกเว้นพนักงานที่ท่านแบน
            </div>
					</div>
				</ModalConfirm>
      </Container>
    );
  }
}

export default compose(
  graphql(instancePriceQueryClient, {
    name: "instancePriceQuery"
  }),
  graphql(instancePriceStepQueryClient, {
    name: "instancePriceStepQuery"
  }),
  graphql(locationTempQueryClient, {
    name: "locationTempQuery"
  })
)(withNamespaces()(SelectRemark));
