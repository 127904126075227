import React, { Component } from "react";
import {Row , Badge} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './style.css';

const ItemContainer = styled(Row)`
`;

const IconContainer = styled.div`
    font-size: 22px !important;
`;

const RadioListItem = (props) => (
    <ItemContainer onClick={props.onClick} className={`radioItem ${props.selected ? "selected" : ""} ${props.disabled ? "disabled" : ""}`}>
        <div className="imageStyle"><img src={props.LeftImage} /></div>
        <div className="title">{props.Title} 
            <small>{props.subTitle} <br/> {props.subTitle2}</small>
        </div>
        <IconContainer className="rightIconStyle" style={{color:`${props.selected ? "#D90101" : "#E3E3E3"}`}}><i className={props.selected ? "far fa-dot-circle" : "far fa-circle"}></i></IconContainer>
    </ItemContainer>
);

RadioListItem.propTypes = {
    LeftImage: PropTypes.string,
    Title: PropTypes.string,
    subTitle: PropTypes.string,
    subTitle2: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool
};
  
RadioListItem.defaultProps = {
    title : "",
    subTitle : "",
    selected: false,
    disabled: false
};

export default RadioListItem;
