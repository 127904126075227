
import React, { Component } from "react";
import { Row,Col , FormGroup ,ControlLabel ,FormControl ,Button } from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from "./../../../../config/color";

import {
    Card,
    BlankSpace,
    CardHeader,
    ButtonLink,
    AddressListItem
} from "./../../../../components";


import { graphql, Query, Mutation } from 'react-apollo';
import instancePriceStepQueryClient from './../../../../config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';
import { withNamespaces } from 'react-i18next';
import modeToText from '../../function/modeToText'

const Container = styled.div`
    width: 100%;
`;

const BoxContainer = styled.div`
    background-color: #F9F9F9;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
`;
const TruckListContainer = styled(Row)`
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
`;
const KeyContainer = styled.div`
    font-size:45px;
    margin-bottom: 15px;
`;
const TitelKeyContainer = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
const SmallContainer = styled(Row)`
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
    line-height: 1;
`;

const TitleContainer = styled(Row)`
    padding: 15px 20px 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
`;
const SmallTitel = styled.small`
    font-size: 20px;
    width:100%;
    float:left;
    font-weight: bold;
    margin-top: 15px;
`;

class PaymentCOD extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: ''
        };
    }

    
    closeTap(mode,updateInstancePriceStep){
        if(mode === "chanel"){
            updateInstancePriceStep({
                variables: {
                    paymentCOD: false,
                    paymentChanel: true,
                },
            });
        }
        else{
            updateInstancePriceStep({
                variables: {
                    paymentCOD: false,
                    paymentChanel: false,
                },
            });
        }
        
    }

    selectDroppoint(index, updateInstancePrice){
			const newShipmentAddress = this.props.instancePriceQuery.instancePrice.shipmentAddress.map(
				(item, key) =>
					key === index
						? {
								...item,
								paidByCOD: 1
							}
						: {
							...item,
							paidByCOD: null
						}
			);

			updateInstancePrice({
				variables: {
					paymentType: 'COD',
					shipmentAddress: newShipmentAddress
				},
			});
    }

    render() {
    return (
        <Container>
            <Mutation mutation={updateInstancePriceStepMutationClient}>
                {updateInstancePriceStep => {
                    return (
                    <Mutation
                        mutation={updateInstancePriceMutationClient}
                        update={() => {
                            updateInstancePriceStep({
                                variables: {
                                    paymentCOD: false,
                                    paymentChanel: false,
                                },
                            })
                        }}
                    >
                        {(updateInstancePrice, { error }) => {
                            return (
                                <Query query={instancePriceQueryClient}>
                                {({ data }) => {
                                    return (
                                    <Card content={
                                        <div>
                                            <CardHeader borderCardBottom title={this.props.t('common:instancePrice.paymentMethod.cod.title')} onPressClose={() => this.closeTap("chanel",updateInstancePriceStep)} />
                                            {
                                                (data.instancePrice.shipmentAddress.filter(item => item.mode === 'pickup' && item.latitude === 0 && item.longitude === 0).length > 0 ||
                                                data.instancePrice.shipmentAddress.filter(item => item.mode === 'delivery' && item.latitude === 0 && item.longitude === 0).length > 0) ? 
                                                (   
                                                    <div>
                                                    <BoxContainer>
                                                        <KeyContainer className="fal fa-lock-alt"></KeyContainer>
                                                        <TitelKeyContainer>
                                                        { this.props.t('common:instancePrice.PaymentCOD.errorTitle') }
                                                            <SmallContainer>{ this.props.t('common:instancePrice.PaymentCOD.errorDetail').split('\n').map(item => (<div>{item}</div>)) }</SmallContainer>
                                                        </TitelKeyContainer>
                                                    </BoxContainer>
                                                    </div>
                                                ) : 
                                                (
                                                    <div>
                                                        <TruckListContainer>
                                                            {
                                                                data.instancePrice.shipmentAddress
                                                                    .map((item, index) => ({ ...item, index }))
                                                                    .filter(item => item.address !== '')
                                                                    .map((item, index) => (
                                                                        <AddressListItem
                                                                            key={index}
                                                                            className="COD__list"
                                                                            onClick={()=> this.selectDroppoint(index, updateInstancePrice)}
                                                                            Title={`${this.props.t('common:instancePrice.paymentMethod.cod.payCashAtPoint')} ${index+1} - ${modeToText(item.mode, this.props.t('common:lang'))}`}
                                                                            LeftIcon="fas fa-map-marker-alt"
                                                                            LeftIconColor={item.mode === 'pickup' ? '#000000' : '#DB1F26'}
                                                                            detail={item.address}
                                                                            selected={item.paidByCOD === 1}
                                                                        />
                                                                    ))
                                                            }
                                                        </TruckListContainer>
                                                    </div>
                                                )
                                            }
                                        </div>} />);
                                }}
                            </Query>);
                        }}
                    </Mutation>
                    );
             }}
             </Mutation>
        </Container>   
    )
    }
};

export default 	graphql(instancePriceQueryClient, {
									name: 'instancePriceQuery',
								})(withNamespaces()(PaymentCOD));
