import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import logo from './../../assets/images/logo_giztix.png';
import appIos from './../../assets/images/app-store.svg';
import playStore from './../../assets/images/play-store.svg';

import version from './../../config/version';
import landingUrl from '../../config/landingUrl';
import { withNamespaces } from 'react-i18next';

const FooterContainer = styled.div`
	background-color: #000;
	color: rgba(255, 255, 255, 0.6);
	padding: 80px 0 60px;

	& p {
		line-height: 1;
	}

	a:hover {
		color: #ffffff80;
	}
`;
const MenuContainer = styled(Row)`
	padding-bottom: 40px;
`;
const DownloadContainer = styled(Row)`
	padding-top: 40px;
	padding-bottom: 40px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
`;
const LoadAppContainer = styled.div``;

const CopyRightContainer = styled(Row)`
	padding-top: 40px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
`;
const Title = styled.div`
	color: rgba(255, 255, 255, 1);
	margin-bottom: 10px;
`;
const LinkImg = styled.a`
	margin-right: 10px;
`;
const Img = styled.img`
	height: 40px;
	border-radius: 4px;
	border: none;
`;

const Link = styled.a`
	color: rgba(255, 255, 255, 0.6);
	margin-bottom: 5px;
	width: 100%;
	float: left;
`;
const SocailLinkContainer = styled.div`
	width: 100%;
	float: left;
`;
const SocailLink = styled.a`
	display: inline-block;
	margin-right: 20px;
	font-size: 27px;
	line-height: 30px;
	color: rgba(255, 255, 255, 0.6);
`;

class Footer extends React.Component {
	state = {
		openMenu: false,
	};

	componentDidMount() {
		const script = document.createElement('script');

		script.src =
			'https://www.trustmarkthai.com/callbackData/initialize.js?t=ac54f3-22-5-c2eebed8b4423b9f63231bdf5fe34ef38a451cc';
		script.id = 'dbd-init';
		script.async = true;
		document.body.appendChild(script);
	}

	render() {
		return (
			<FooterContainer>
				<div className="container">
					<MenuContainer>
						<Col sm={6}>
							<Title>
								{this.props.t('common:header.contactCustomerService')}
							</Title>
							<p>
								{this.props.t('common:header.GIZTIXCompanyName')}
								<br />
								{this.props.t('common:header.AddressGIZTIX')}
								<br />
								{this.props.t('common:header.AddressGIZTIX2')}
							</p>
							<p>
								{this.props.t('common:header.emailGiztix')}{' '}
								<a href="mailto:service@giztix.com" style={{ color: '#ccc' }}>
									service@giztix.com
								</a>
								<br />
								{this.props.t('common:header.contactGiztix')}02-026-6895
								<br />
								{this.props.t('common:header.giztixOpenhours')}
							</p>
						</Col>
						<Col sm={2}>
							<Title>{this.props.t('common:header.MainMenu')}</Title>
							<Link href="/tracking">
								{this.props.t('common:header.shipmentTracking')}
							</Link>
							<Link href={`${landingUrl}/pricing`}>
								{this.props.t('common:header.transportationRate')}
							</Link>
							<Link href="https://blog.giztix.com/">
								{this.props.t('common:header.newsPromotion')}
							</Link>
							<Link href={`${landingUrl}/helpcenter`}>
								{this.props.t('common:header.helpCenter')}
							</Link>
						</Col>
						<Col sm={2}>
							<Title>{this.props.t('common:header.aboutUs')}</Title>
							<Link href={`${landingUrl}/aboutus`}>
								{this.props.t('common:header.WhyGIZTIX')}
							</Link>
							<Link href={`${landingUrl}/contactus`}>
								{this.props.t('common:header.contactUs')}
							</Link>
							
							<Link href={`${landingUrl}/termscondition`}>
								{this.props.t('common:header.TermsConditions')}
							</Link>
							<Link href={`${landingUrl}/complain`}>
								{this.props.t('common:header.SolvingRequestPolicy')}
							</Link>
						</Col>
						<Col sm={2}>
							<Title>{this.props.t('common:header.OtherService')}</Title>
							<Link href="http://thumbweb.maninnovation.com/">ThumbWeb</Link>
              				<Link href={`${landingUrl}/subscribe`}>{this.props.t("common:header.blogSubscribe")}</Link>
							{/* <Link href="https://parcel.giztix.com/">{this.props.t('common:header.parcelMenu')}</Link>*/}
						</Col>
					</MenuContainer>
					<DownloadContainer>
						<Col sm={6}>
							<Title>
								{this.props.t('common:header.DownloadApplicationat')}
							</Title>
							<LoadAppContainer>
								<LinkImg
									className="img"
									href="https://itunes.apple.com/app/id1439755826"
									target="_blank"
								>
									<Img src={appIos} className="logo" alt="logo" />
								</LinkImg>
								<LinkImg
									className="img"
									href="https://play.google.com/store/apps/details?id=com.giztix.app"
									target="_blank"
								>
									<Img src={playStore} className="logo" alt="logo" />
								</LinkImg>
							</LoadAppContainer>
						</Col>
						<Col sm={4}>
							<Title>{this.props.t('common:header.SubscribeHere')}</Title>
							<SocailLinkContainer>
								<SocailLink
									href="https://www.facebook.com/giztix/"
									target="_blank"
								>
									<i className="fab fa-facebook-square"></i>
								</SocailLink>
								<SocailLink
									href="https://line.me/R/ti/p/@giztix"
									target="_blank"
								>
									<i className="fab fa-line"></i>
								</SocailLink>
								<SocailLink
									href="https://www.instagram.com/giztix_official/"
									target="_blank"
								>
									<i className="fab fa-instagram"></i>
								</SocailLink>
								<SocailLink
									href="https://www.youtube.com/channel/UCDoz39PN2yzoQB5d0-xcgJg"
									target="_blank"
								>
									<i className="fab fa-youtube"></i>
								</SocailLink>
							</SocailLinkContainer>
						</Col>
						<Col sm={2}>
							<div id="Certificate-banners"></div>
						</Col>
					</DownloadContainer>
					<CopyRightContainer>
						<Col sm={6}>
							<span>
								© 2019 GIZTIX EXPRESS - สงวนลิขสิทธิ์ (เวอร์ชั่น {version})
							</span>
						</Col>
						<Col sm={2}>
						<Link
								href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/Privacy_Notice.pdf"
								target="_blank"
							>
								{this.props.t('common:header.PrivacyNotice')}
							</Link>
						</Col>
						{/* <Col sm={2}>
							<Link href={`${landingUrl}/policy`}>
								{this.props.t('common:header.PrivacyPolicy')}
							</Link>
						</Col> */}

						<Col md={2} sm={4}>
							<Link href="/privacy-policy">
								{this.props.t('common:header.PrivacyPolicy')}
							</Link>
						</Col>
						<Col sm={2}>
							<Link href={`${landingUrl}/standard`}>
								{this.props.t('common:header.ServiceStandard')}
							</Link>
						</Col>
					</CopyRightContainer>
				</div>
			</FooterContainer>
		);
	}
}

Footer.propTypes = {
	active: PropTypes.number,
};

Footer.defaultProps = {
	active: null,
};

export default withNamespaces()(Footer);
