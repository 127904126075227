import React, { Component } from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
	InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from 'src/config/color';

import {
	Card,
	BlankSpace,
	CardHeader,
	ButtonLink,
	AddressListItem,
	ModalConfirm,
} from 'src/components';

import { Header } from './../../components';

import { compose, graphql, Query, Mutation } from 'react-apollo';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import { Formik } from 'formik';
import * as Yup from 'yup';
import modeToText from './../../function/modeToText';
import client from '../../../../config/client';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { InstancePriceContext } from "../../contexts/InstancePriceContext";
import { Checkbox } from './components';

const Container = styled.div`
	width: 100%;
`;
const HeaderStyle = styled(Header)`
	margin-top: 20px;
`;

const AddressContainer = styled.div`
	padding: 10px 0px;
	line-height: 1;
	font-size: 18px;
`;

const TextControlLabel = styled(ControlLabel)`
	font-size: 18px;
	white-space: nowrap;
`;

const Required = styled.span`
	color: #D90101;
	font-size: 18px;
`;

const TextValidate = styled.span`
	color: #FF0000;
`;

const Icon = styled.span`
	margin-left: 4px;
	color: #b3b3b3;
	font-size: 14px;
	cursor: pointer;
`;

class Contact extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			datetime: '',
			isOpenModalInfoPickupDate: false,
			isOpenModalAlertSelectPickupDateShipmentPrevious: false,
		};
	}

	static contextType = InstancePriceContext;

	componentDidMount() {
		const { datetime } = this.props.locationTempQuery.locationTemp;
		this.setState({ datetime: datetime });
		this.setContactToContext()
	}

	setContactToContext = () => {
		const { shipmentAddressIndex } = this.props.locationTempQuery.locationTemp;
		const { contactName, phoneNumber, isSaveContact, remark, mainAddress } = this.props.instancePriceQuery.instancePrice.shipmentAddress[shipmentAddressIndex];

		this.context.setData({
			contactName: contactName,
			contactTel: phoneNumber,
			isSaveContact: isSaveContact,
			remark: remark,
			mainAddress: mainAddress,
		});
	}

	componentWillReceiveProps(newProps) {
		if(newProps.locationTempQuery.locationTemp.datetime) {
			this.setState({ datetime: newProps.locationTempQuery.locationTemp.datetime });
		}
	}

	closeTap(updateInstancePriceStep) {
		updateInstancePriceStep({
      variables: {
        contact: false,
      },
    });
	}

	onSubmit(values, updateInstancePrice) {
		const {
			shipmentAddressIndex, datetime, type
		} = this.props.locationTempQuery.locationTemp;

		const { shipmentAddress, shipmentType } = this.props.instancePriceQuery.instancePrice;

		const newShipmentAddress = shipmentAddress.map((item, key) => {
			if (key === shipmentAddressIndex) {
				return {
					...item,
					contactName: values.contactName,
					phoneNumber: values.phoneNumber,
					remark: values.remark,
					pickupDate: datetime,
					isSaveContact: values.isSaveContact,
					mainAddress: values.mainAddress,
				};
			} else if(
				key > shipmentAddressIndex && shipmentAddress[shipmentAddressIndex].pickupDate !== datetime
			) {
				return {
					...item,
					pickupDate: '',
				}
			} else {
				return item;
			}
		});

		if(!moment(datetime).isValid() && shipmentType !== 1) { //ป้อนกันวันที่เป็น Invalid date
			return;
		}

		updateInstancePrice({
			variables: {
				shipmentAddress: newShipmentAddress,
				pickupDate: (type === 'importDelivery' || type === 'exportLoading') ? datetime : undefined,
			},
		});
	}

	getType(index) {
		const shipmentAddressIndex = !!index ? index : this.props.locationTempQuery.locationTemp.shipmentAddressIndex;
		const { shipmentAddress } = this.props.instancePriceQuery.instancePrice;
		return this.props.t('common:instancePrice.shipmentConfirm.contact.point') + ' ' + (shipmentAddressIndex + 1) + ' - ' + (modeToText(shipmentAddress[shipmentAddressIndex].mode, this.props.t('common:lang')));
	}

	getTypePrevious() {
		const shipmentAddressIndex = this.props.locationTempQuery.locationTemp.shipmentAddressIndex - 1;
		if(shipmentAddressIndex >= 0) {
			const { shipmentAddress } = this.props.instancePriceQuery.instancePrice;
			return this.props.t('common:instancePrice.shipmentConfirm.contact.point') + ' ' + (shipmentAddressIndex + 1) + ' - ' + (modeToText(shipmentAddress[shipmentAddressIndex].mode, this.props.t('common:lang')));
		}


		return null;
	}

	getAddress() {
		const { shipmentAddressIndex } = this.props.locationTempQuery.locationTemp;
		return this.props.instancePriceQuery.instancePrice.shipmentAddress[shipmentAddressIndex].address;
	}

	getPickupDate() {
		const { shipmentAddressIndex, datetime } = this.props.locationTempQuery.locationTemp;
		const formatDatetime = shipmentAddressIndex === 0 ? 'DD-MM-YYYY' : 'DD-MM-YYYY - HH:mm';
		return datetime ? moment(datetime, 'YYYY-MM-DD HH:mm').format(formatDatetime) : '';
	}
	
	getTitleRemark() {
		const { type } = this.props.locationTempQuery.locationTemp;
		if(type === 'importPickup' || type === 'exportPickup') {
			return 'Pickup remark';
		} else if(type === 'importDelivery') {
			return 'Delivery remark';
		} else if(type === 'importReturn' || type === 'exportReturn') {
			return 'Return remark';
		} else if(type === 'exportLoading') {
			return 'Loading remark';
		} else {
			return 'Remark';
		}
	}

	openSelectDatetime() {
		const checkHasPickupDateShipmentPrevious = this.checkHasPickupDateShipmentPrevious();
		if(!checkHasPickupDateShipmentPrevious) {
			this.setState({ isOpenModalAlertSelectPickupDateShipmentPrevious: true });
			return;
		}

		const instancePriceStepData = client.readQuery({
			query: instancePriceStepQueryClient
		});
		
		client.writeQuery({
			query: instancePriceStepQueryClient,
			data: {
				instancePriceStep: {
					...instancePriceStepData.instancePriceStep,
					selectDateTimeContact: true,
				}
			}
		});
	}

	checkHasPickupDateShipmentPrevious = () => {
		const { shipmentAddressIndex } = this.props.locationTempQuery.locationTemp;
		const { shipmentAddress, pickupDate, shipmentType } = this.props.instancePriceQuery.instancePrice;

		if(shipmentType === 1 && shipmentAddressIndex === 1 && pickupDate) {
			return true;
		}

		if(shipmentAddress[shipmentAddressIndex - 1].pickupDate) {
			return true;
		}

		return false;
	}

	setEmptyDatetime(){
		const locationTempData = client.readQuery({
			query: locationTempQueryClient,
		});

		client.writeQuery({
			query: locationTempQueryClient,
			data: {
				locationTemp: {
					...locationTempData.locationTemp,
					datetime: this.state.datetime,
				},
			},
		});
	}

	getTitlePickupDate(mode) {
		let text = '';
		if(mode === 'importPickup' || mode === 'exportPickup') {
			text += this.props.t('common:instancePrice.shipmentConfirm.contact.date');
		} else {
			text += this.props.t('common:instancePrice.shipmentConfirm.contact.dateTime');
		}

		return text + modeToText(mode, this.props.t('common:lang'));
	}

	pickupDateFormat() {
		const { type } = this.props.locationTempQuery.locationTemp;
		const { pickupDate } = this.props.instancePriceQuery.instancePrice;
		const { datetime } = this.state;
	
		if (datetime) {
			if (type === 'importPickup' || type === 'exportPickup') {
				return `${moment(datetime).format('DD/MM/YYYY')}`;
			} else if (type === 'importDelivery' || type === 'exportLoading') {
				return `${moment(datetime).format('DD/MM/YYYY - HH:mm')}`;
			} else if (type === 'importReturn' || type === 'exportReturn') {
				const date = moment(datetime).format(`DD/MM/YYYY`);
				const time = moment(datetime).format(`HH:mm`);
				const before = this.props.t('common:instancePrice.shipmentConfirm.contact.beforeTime');
				return `${date} - ${before} ${time}`
			} else{
				return `${moment(datetime).format('DD/MM/YYYY - HH:mm')}`;
			}
		}else if(pickupDate) {
			if(type === 'pickup') {
				return `${moment(pickupDate, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')}`;
			}
		}
	
		return '';
	}

	openSelectContactHandler = () => {
		this.context.onOpen(['selectContact']);
	}

	render() {
		const ContactSchema = Yup.object().shape({
			contactName: Yup.string()
				.required(this.props.t('common:instancePrice.shipmentConfirm.contact.inputName.validate.required')),
			phoneNumber: Yup.string()
				.matches(/^[0]\d{8,9}$/, this.props.t('common:instancePrice.shipmentConfirm.contact.inputPhoneNumber.validate.format'))
				.required(this.props.t('common:instancePrice.shipmentConfirm.contact.inputPhoneNumber.validate.required')),
			remark: Yup.string(),
		});

		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Mutation
								mutation={updateInstancePriceMutationClient}
								update={(cache) => {
									updateInstancePriceStep({
										variables: {
											contact: false,
										},
									}).then(() => {
										const locationTempData = cache.readQuery({
											query: locationTempQueryClient,
										});
	
										cache.writeQuery({
											query: locationTempQueryClient,
											data: {
												locationTemp: {
													...locationTempData.locationTemp,
													address: '',
													addressDefault: '',
													latitude: 0,
													longitude: 0,
													latitudeCenter: 0,
													longitudeCenter: 0,
													isFitBounds: true,
													isSelected: false,
													type: '',
													shipmentAddressIndex: 0,
													datetime: '',
												},
											},
										});
									});
								}}
							>
								{(updateInstancePrice, { error }) => {
									return (
										<Query query={instancePriceQueryClient}>
											{({ data }) => {
												return (
													<div>
														{
															data.instancePrice.shipmentType === 1 && (
																<Formik
																	initialValues={{
																		contactName: this.context.state.contactName,
																		phoneNumber: this.context.state.contactTel,
																		remark: this.context.state.remark,
																		isSaveContact: this.context.state.isSaveContact,
																		mainAddress: this.context.state.mainAddress,
																	}}
																	validationSchema={ContactSchema}
																	enableReinitialize={true}
																	onSubmit={(values) => {
																		this.onSubmit(values, updateInstancePrice);
																	}}
																>
																	{
																		(props) => {
																			const { handleSubmit, values, errors, touched, handleChange, handleBlur } = props;
																			return (
																				<div id="confirm-shipment-contact">
																					<Card
																						style={{paddingBottom: 0}}
																						content={
																							<div>
																								<CardHeader
																									borderCardBottom
																									title={this.props.t('common:instancePrice.shipmentConfirm.contact.title')}
																									onPressClose={() =>
																										this.closeTap(updateInstancePriceStep)
																									}
																								/>
																								<HeaderStyle title={this.getType()} />
																								<AddressContainer>
																									{ this.getAddress() }
																								</AddressContainer>
						
																								<FormGroup controlId="formBasicText" style={{marginTop: 10}}>
																									<FormControl
																										className="confirm-shipment-more__contact-detail"
																										type="text"
																										componentClass="textarea"
																										value={values.remark}
																										placeholder={this.props.t('common:instancePrice.shipmentConfirm.contact.inputRemark.placeholder')}
																										onChange={handleChange}
																										onBlur={handleBlur}
																										name="remark"
																									/>
																								</FormGroup>
																							</div>
																						}
																					/>
							
																					<BlankSpace height={10} />
						
																					<Card
																						content={
																							<div>
																								<Header required title={this.props.t('common:instancePrice.shipmentConfirm.contact.titleCardContactInformation')} style={{ marginBottom: 10 }}/>
																								<TextControlLabel>{this.props.t('common:instancePrice.shipmentConfirm.contact.inputName.title')}<Required>*</Required> <TextValidate>{ (errors.contactName && touched.contactName) && errors.contactName }</TextValidate></TextControlLabel>
																								<FormGroup controlId="formBasicText">
																									<InputGroup>
																										<FormControl
																											className="confirm-shipment-more__contact-name"
																											type="text"
																											value={values.contactName}
																											placeholder={this.props.t('common:instancePrice.shipmentConfirm.contact.inputName.placeholder')}
																											onChange={handleChange}
																											onBlur={handleBlur}
																											name="contactName"
																										/>
																										<InputGroup.Addon
																											onClick={this.openSelectContactHandler}
																											style={{backgroundColor: '#ffffff', cursor: 'pointer'}}
																											title="เลือกผู้ติดต่อที่บันทึกไว้"
																										>
																											<i className="far fa-address-book"></i>
																										</InputGroup.Addon>
																									</InputGroup>
																								</FormGroup>
						
																								<FormGroup controlId="formBasicText">
																									<TextControlLabel>
																										{this.props.t('common:instancePrice.shipmentConfirm.contact.inputPhoneNumber.title')}<Required>*</Required> <TextValidate>{ (errors.phoneNumber && touched.phoneNumber) && errors.phoneNumber }</TextValidate>
																									</TextControlLabel>
																									<FormControl
																										className="confirm-shipment-more__contact-phone-number"
																										type="text"
																										value={values.phoneNumber}
																										placeholder={this.props.t('common:instancePrice.shipmentConfirm.contact.inputPhoneNumber.placeholder')}
																										onChange={handleChange}
																										onBlur={handleBlur}
																										name="phoneNumber"
																									/>
																								</FormGroup>

																								<Checkbox 
																									text="บันทึกข้อมูลสถานที่และผู้ติดต่อ"
																									checked={values.isSaveContact}
																									clicked={() => {
																										props.setFieldValue('isSaveContact', !values.isSaveContact);
																									}}
																								/>

																								{
																									(data.instancePrice.shipmentType === 1) && (
																										<FormGroup controlId="formBasicText">
																											<TextControlLabel>
																												{this.props.locationTempQuery.locationTemp.type == "pickup" ? 'วัน/เวลาที่ต้องการเข้ารับสินค้า' : 'วัน/เวลาที่ต้องการให้จัดส่งสินค้า'}
																												<Icon onClick={() => this.setState({ isOpenModalInfoPickupDate: true })}>
																													<i className="fal fa-info-circle" />
																												</Icon>
																											</TextControlLabel>
																											<FormGroup>
																												<InputGroup>
																													<FormControl 
																														onClick={() => this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && this.openSelectDatetime()}
																														className="confirm-shipment-more__date-time"
																														type="text"
																														value={this.pickupDateFormat()}
																														placeholder={this.props.locationTempQuery.locationTemp.type == "pickup" ? 'วัน/เวลาที่ต้องการเข้ารับสินค้า' : 'วัน/เวลาที่ต้องการให้จัดส่งสินค้า'}
																														style={{backgroundColor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 ? '#FFFFFF' : null, cursor: 'pointer'}}
																														disabled={this.props.locationTempQuery.locationTemp.shipmentAddressIndex === 0}
																														autocomplete="off"
																													/>
																													<InputGroup.Addon
																														onClick={() => this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 ? this.state.datetime !== "" ? this.setState({datetime : ''},() => this.setEmptyDatetime()) : this.openSelectDatetime() : null}
																														style={{backgroundColor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && '#FFFFFF', cursor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && 'pointer'}}
																													>
																														{
																															this.state.datetime == "" ? (<i className="far fa-calendar-alt"></i>) : (<i className="fal fa-times"></i>)
																														}
																													</InputGroup.Addon>
																												</InputGroup>
																											</FormGroup>
																											<ModalConfirm
																												show={this.state.isOpenModalInfoPickupDate}
																												labelButtonConfirm="ปิด"
																												titleHeader="วัน/เวลาที่ต้องการเข้ารับสินค้า"
																												onHide={() => this.setState({ isOpenModalInfoPickupDate: false })}
																												onPressButtonConfirm={() => this.setState({ isOpenModalInfoPickupDate: false })}
																												>
																												<div style={{lineHeight: 1}}>
																													<div>
																														การระบุเวลาในการจัดส่งสินค้านั้นคำนวณจากเวลารับสินค้าที่ท่านระบุ และบวกด้วยเวลาการบรรจุสินค้าและเวลาในในการเดินทาง
																													</div>
																													<div style={{marginTop: 10, fontWeight: 'bold'}}>
																														ตัวอย่างการคิดเวลา
																													</div>
																													<div>
																														รับสินค้า เวลา 08.00 น. ใช้เวลาในการเดินทาง 2 ชั่วโมง และเวลาเผื่อสำหรับการเดินทางและการขึ้นสินค้า 1 ชั่วโมง
																													</div>
																													<div style={{marginTop: 10}}>
																														ดังนั้นท่านจะสามารถเลือกเวลาในการจัดส่งสินค้าได้เร็วสุดคือ เวลา 11.00 น.
																														ทั้งนี้เวลาดังกล่าวเป็นการคาดคะเน อาจมีการคลาดเคลื่อนของเวลาในการจัดส่งสินค้า
																														รบกวนท่านเผื่อเวลาในการจองและการกำหนดเวลาในการส่งสินค้าอย่างระมัดระวัง
																													</div>
																												</div>
																											</ModalConfirm>
																											<ModalConfirm
																												show={this.state.isOpenModalAlertSelectPickupDateShipmentPrevious}
																												labelButtonConfirm="ปิด"
																												titleHeader="วัน/เวลาที่ต้องการเข้ารับสินค้า"
																												onHide={() => this.setState({ isOpenModalAlertSelectPickupDateShipmentPrevious: false })}
																												onPressButtonConfirm={() => this.setState({ isOpenModalAlertSelectPickupDateShipmentPrevious: false })}
																												>
																												<div style={{lineHeight: 1}}>
																													<div>
																														{`กรุณาเพิ่มวัน/เวลาที่ต้องการเข้ารับสินค้า${this.getTypePrevious()}`}
																													</div>
																												</div>
																											</ModalConfirm>
																										</FormGroup>
																									)
																								}

																								<Button
																									style={{marginTop: 30}}
																									onClick={handleSubmit}
																									bsStyle="primary"
																									block
																								>
																									{this.props.t('common:instancePrice.shipmentConfirm.contact.buttonConfirm')}
																								</Button>
																							</div>
																						}
																					/>
																				</div>
																			)
																		}
																	}
																</Formik>
															)
														}

														{
															(data.instancePrice.shipmentType === 2 || data.instancePrice.shipmentType === 3) && (
																<Formik
																	initialValues={{
																		contactName: this.context.state.contactName,
																		phoneNumber: this.context.state.contactTel,
																		remark: this.context.state.remark,
																	}}
																	validationSchema={ContactSchema}
																	enableReinitialize={true}
																	onSubmit={(values) => {
																		this.onSubmit(values, updateInstancePrice);
																	}}
																>
																	{
																		(props) => {
																			const { handleSubmit, values, errors, touched, handleChange, handleBlur } = props;
																			return (
																				<div id="confirm-shipment-contact">
																					<Card
																						style={{paddingBottom: 0}}
																						content={
																							<div>
																								<CardHeader
																									borderCardBottom
																									title={this.props.t('common:instancePrice.shipmentConfirm.contact.title')}
																									onPressClose={() =>
																										this.closeTap(updateInstancePriceStep)
																									}
																								/>
																								<HeaderStyle title={this.getType()} />
																								<AddressContainer>
																									{ this.getAddress() }
																								</AddressContainer>
																							</div>
																						}
																					/>

																					<BlankSpace height={10} />
						
																					<Card
																						style={{paddingBottom: 0}}
																						content={
																							<div>
																								<Header required title={this.getTitlePickupDate(this.props.locationTempQuery.locationTemp.type)} style={{ marginBottom: 10 }}/>
																								<FormGroup onClick={() => this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && this.openSelectDatetime()}>
																									<InputGroup>
																										<FormControl
																											className="confirm-shipment-more__date-time"
																											type="text"
																											value={this.pickupDateFormat()}
																											placeholder={this.getTitlePickupDate(this.props.locationTempQuery.locationTemp.type)}
																											style={{backgroundColor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && '#FFFFFF', cursor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && 'pointer'}}
																											disabled={this.props.locationTempQuery.locationTemp.shipmentAddressIndex === 0}
																											autocomplete="off"
																										/>
																										<InputGroup.Addon style={{backgroundColor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && '#FFFFFF', cursor: this.props.locationTempQuery.locationTemp.shipmentAddressIndex !== 0 && 'pointer'}}>
																											<i className="far fa-calendar-alt"></i>
																										</InputGroup.Addon>
																									</InputGroup>
																								</FormGroup>
																								
																							</div>
																						}
																					/>
							
																					<BlankSpace height={10} />
						
																					<Card
																						style={{paddingBottom: 0}}
																						content={
																							<div>
																								<Header required title={this.props.t('common:instancePrice.shipmentConfirm.contact.titleCardContactInformation')} style={{ marginBottom: 10 }}/>
																								<TextControlLabel>{this.props.t('common:instancePrice.shipmentConfirm.contact.inputName.title')}<Required>*</Required> <TextValidate>{ (errors.contactName && touched.contactName) && errors.contactName }</TextValidate></TextControlLabel>
																								<FormGroup controlId="formBasicText">
																									<InputGroup>
																										<FormControl
																											className="confirm-shipment-more__contact-name"
																											type="text"
																											value={values.contactName}
																											placeholder={this.props.t('common:instancePrice.shipmentConfirm.contact.inputName.placeholder')}
																											onChange={handleChange}
																											onBlur={handleBlur}
																											name="contactName"
																										/>
																										<InputGroup.Addon
																											onClick={this.openSelectContactHandler}
																											style={{backgroundColor: '#ffffff', cursor: 'pointer'}}
																											title="เลือกผู้ติดต่อที่บันทึกไว้"
																										>
																											<i className="far fa-address-book"></i>
																										</InputGroup.Addon>
																									</InputGroup>
																								</FormGroup>
						
																								<FormGroup controlId="formBasicText">
																									<TextControlLabel>
																										{this.props.t('common:instancePrice.shipmentConfirm.contact.inputPhoneNumber.title')}<Required>*</Required> <TextValidate>{ (errors.phoneNumber && touched.phoneNumber) && errors.phoneNumber }</TextValidate>
																									</TextControlLabel>
																									<FormControl
																										className="confirm-shipment-more__contact-phone-number"
																										type="text"
																										value={values.phoneNumber}
																										placeholder={this.props.t('common:instancePrice.shipmentConfirm.contact.inputPhoneNumber.placeholder')}
																										onChange={handleChange}
																										onBlur={handleBlur}
																										name="phoneNumber"
																									/>
																								</FormGroup>
																							</div>
																						}
																					/>

																					<BlankSpace height={10} />
						
																					<Card
																						content={
																							<div>
																								<Header title={this.getTitleRemark()} style={{ marginBottom: 10 }}/>
																								<FormGroup controlId="formBasicText" style={{marginTop: 10}}>
																									<FormControl
																										className="confirm-shipment-more__contact-detail"
																										type="text"
																										componentClass="textarea"
																										value={values.remark}
																										placeholder={this.props.t('common:instancePrice.shipmentConfirm.contact.inputRemark.placeholder')}
																										onChange={handleChange}
																										onBlur={handleBlur}
																										name="remark"
																									/>
																								</FormGroup>
																								
																								<Button
																									style={{marginTop: 20}}
																									onClick={handleSubmit}
																									bsStyle="primary"
																									block
																									disabled={!moment(this.state.datetime, 'YYYY-MM-DD HH:mm').isValid()}
																								>
																									{this.props.t('common:instancePrice.shipmentConfirm.contact.buttonConfirm')}
																								</Button>
																							</div>
																						}
																					/>
																				</div>
																			)
																		}
																	}
																</Formik>
															)
														}
													</div>
												);
											}}
										</Query>
									);
								}}
							</Mutation>
						);
					}}
				</Mutation>
			</Container>
		);
	}
}

export default compose(
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery',
	}),
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
)(withNamespaces()(Contact));
