import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import ModalCreateReport from '../../components/ModalCreateReport';

const Table = styled.table`
	& {
		width: 100%;
		line-height: 1;
		font-size: 20px;
		margin-top: 10px;
		border-collapse: collapse;

		& td,
		& th {
			padding: 10px 6px;
		}

		& tr {
			border-bottom: 1px solid #e7e7e7;
		}

		& thead th {
			font-size: 20px;
			font-weight: bold;
			color: #000000;
		}

		& tbody {
			& tr {
				&:nth-child(odd) {
					background: #f9f9f9;
				}

				&:hover {
					background-color: #f5f5f5;
				}
			}
		}
	}
`;

class TableReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpenModal: false,
			selectReport: null,
		};
	}

	render() {
		const reportList = [
			{
				name: 'รายงานการส่งของสินค้า',
				description: 'แสดงรายละเอียดสถานะขนส่งสินค้าทั้งหมด',
				value: 'parcelOrder',
			},
		];

		return (
			<>
				<Table>
					<thead>
						<tr>
							<th style={{ minWidth: 150, width: 150 }}>เครื่องมือ</th>
							<th style={{ minWidth: 400 }}>ชื่อรายงาน</th>
							<th style={{ minWidth: 300 }}>รายละเอียด</th>
						</tr>
					</thead>
					<tbody>
						{reportList.map(item => (
							<tr key={item.name}>
								<td>
									<Button
										onClick={() =>
											this.setState({
												isOpenModal: true,
												selectReport: item,
											})
										}
									>
										ดาวน์โหลดรายงาน
									</Button>
								</td>
								<td>{item.name}</td>
								<td>{item.description}</td>
							</tr>
						))}
					</tbody>
				</Table>

				{this.state.selectReport && (
					<ModalCreateReport
						visible={this.state.isOpenModal}
						selectReport={this.state.selectReport}
						accountInfoId={this.props.accountInfoId}
						onCancel={() =>
							this.setState({
								isOpenModal: false,
							})
						}
						onComplete={() => {
							this.setState(
								{
									isOpenModal: false,
								},
								() => {
									this.props.onComplete();
								}
							);
						}}
					/>
				)}
			</>
		);
	}
}

export default TableReport;
