import React from 'react';
import Lightbox from 'lightbox-react';
import styled from 'styled-components';
import s3 from '../../../../config/s3';
import { Col as ColAnt, Row as RowAnt } from 'antd';

const ImageProductContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	flex-direction: row;

	& .image {
		width: 86px;
		height: 86px;
		cursor: pointer;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;
		border: 1px solid #e3e3e3;

		& img {
			width: 100%;
			height: 100%;
			top: -9999px;
			margin: auto;
			left: -9999px;
			display: block;
			right: -9999px;
			bottom: -9999px;
			object-fit: cover;
			position: absolute;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		height: 100%;
		display: none;
		position: absolute;
		border-radius: 4px;
		background-color: #00000030;
	}

	.image:hover {
		.image-hover {
			display: block;
		}
	}
`;

class LightBoxPreview extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpenPhotoProductLightBox: false,
			photoProductIndexLightBox: 0,
		};
	}

	render() {
		const { documentsImage } = this.props;

		return (
			<RowAnt>
				<ColAnt>
					{this.state.isOpenPhotoProductLightBox && (
						<Lightbox
							mainSrc={`${s3}/${
								documentsImage[this.state.photoProductIndexLightBox]
							}`}
							nextSrc={
								documentsImage.length > 1
									? documentsImage[
											(this.state.photoProductIndexLightBox + 1) %
												documentsImage.length
									  ]
									: undefined
							}
							prevSrc={
								documentsImage.length > 1
									? documentsImage[
											(this.state.photoProductIndexLightBox +
												documentsImage.length -
												1) %
												documentsImage.length
									  ]
									: undefined
							}
							onCloseRequest={() =>
								this.setState({
									isOpenPhotoProductLightBox: false,
								})
							}
							onMovePrevRequest={() =>
								this.setState(prev => ({
									photoProductIndexLightBox:
										(prev.photoProductIndexLightBox +
											documentsImage.length -
											1) %
										documentsImage.length,
								}))
							}
							onMoveNextRequest={() =>
								this.setState(prev => ({
									photoProductIndexLightBox:
										(prev.photoProductIndexLightBox + 1) %
										documentsImage.length,
								}))
							}
						/>
					)}

					<ImageProductContainer>
						{documentsImage.map((item, index) => (
							<div
								className="image"
								onClick={() =>
									this.setState({
										photoProductIndexLightBox: index,
										isOpenPhotoProductLightBox: true,
									})
								}
							>
								<div className="image-hover" />
								<img src={`${s3}/${item}`} alt="productImage" />
							</div>
						))}
					</ImageProductContainer>
				</ColAnt>
			</RowAnt>
		);
	}
}

export default LightBoxPreview;
