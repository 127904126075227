import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';


const Container = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid #e7e7e7;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  background-color: #FFFFFF;

  & ul {
    margin: 0;
    padding: 0;
    margin: auto 0;

    & li {
      margin: 0;
      padding 0;

      display: inline-block;
      line-height: 50px;
      padding: 0 20px;
      cursor: pointer;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      transition-delay: 0s;
      color: rgba(0, 0, 0, 0.7);
      height: 50px;

      &:hover {
        color: #000000;
      }

      &.active {
        border-bottom: 2px solid #d90101;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  & .download-app {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #808080;
    margin-right: 20px;

    & div {
      margin-right: 10px;
    }

    & img {
      height: 35px;
    }

    & a {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
`;

const LinkStyle = styled(Link)`
  color: #000000;

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Icon = styled.i`
	font-size: 16px;
	vertical-align: -2px;
	margin-right: 10px;
`;

class SubHeaderNav extends Component {
	render() {
		const { menu } = this.props;

		return (
			<Container {...this.props}>
				<ul>
					{menu.map((item, index, arr) => (
						<li key={index} className={`${item.active ? 'active' : ''}`}>
              <LinkStyle to={item.link}>
                {item.icon && <Icon className={item.icon} />}
                {item.title}
              </LinkStyle>
						</li>
					))}
				</ul>
        <MediaQuery minWidth={768}>
          <div className="download-app">
            <div>Download Our App</div>
            <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/app/id1439755826"><img src={require('src/assets/images/app-store.svg')} className="logo" alt="logo" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.giztix.app"><img src={require('src/assets/images/play-store.svg')} className="logo" alt="logo" /></a>
          </div>
        </MediaQuery>
			</Container>
		);
	}
}

export default SubHeaderNav;
