import gql from 'graphql-tag';

export default gql`
	query CreditCardList {
		creditCardList {
			id
			bank
			last_digits
			brand
			expiration_month
			expiration_year
			name
			card_default
		}
	}
`;
