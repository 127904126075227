import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 15px 0px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  line-height: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  flex: 1;
`;

const CheckBoxContainer = styled.div`
  font-size: 22px;

  & i {
    padding-top: 2px;
    cursor: pointer;
    color: ${props => props.selected ? '#19aa19;' : '#e3e3e3'};
  }
`;

const AccessoryListItem = (props) => {
  return (
    <Container {...props}>
      <TextTitle>{props.name}</TextTitle>
      <CheckBoxContainer selected={props.selected} onClick={props.onPressSelected}>
        <i className={`${props.selected ? 'fas fa-check-circle' : 'fal fa-circle'}`}></i>
      </CheckBoxContainer>
    </Container>
  )
}

AccessoryListItem.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.bool,
  onPressSelected: PropTypes.func,
};

AccessoryListItem.defaultProps = {
  name: '',
  selected: false,
};

export default AccessoryListItem;