import gql from 'graphql-tag';

export default gql`
	query AccountInfoListTrackingUrl {
		accountInfoList {
			account_info {
				account_info_tracking_url
				account_info_logo
      }
		}
	}
`;