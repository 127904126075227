import React from "react";
import styled from 'styled-components';

const TabStyled = styled.div`
  .Tabs {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    & ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;

      & li {
        min-width: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 55px;
        padding: 0 10px;
        color: rgba(0, 0, 0, 0.7);
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-delay: 0s;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        line-height: 1;
    
        &:hover {
          color: #000000;
        }
    
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    & i {
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .active {
    border-bottom: 2px solid #d90101 !important;
    color: #000000 !important;
  }

`;

const Tabs = (props) => {
  let menuList = null;

  if(props.menu.length > 0) {
    menuList = (
      <ul>
        {props.menu.map((item, index) => (
          <li key={index} className={item.active ? 'active' : ''} onClick={item.clicked}>
            {item.icon && (<i className={item.icon}></i>)}{item.title}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <TabStyled>
      <ul>
        {menuList}
      </ul>
    </TabStyled>
  );
};

export default Tabs;
