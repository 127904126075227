import React from 'react';
import styled from 'styled-components';
import { Header, ModalConfirm, Input } from 'src/components';
import { SubHeader, Card, Menu } from '../../components';
import Helmet from 'react-helmet';
import {
	Row,
	Col,
	Button,
	FormGroup,
	InputGroup,
	FormControl,
} from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { HeaderNav } from 'src/app/components';
import { Tabs as TabsAntd } from 'antd';
import ContactList from './container/ContactList';
import ContactAddressList from './container/ContactAddressList';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const BackgroundContainer = styled.div`
	background-color: #f9f9f9;
	height: calc(100vh);
	overflow-y: auto;
`;

const Container = styled.div`
	padding-top: calc(150px + ${heightTopHeader}px);
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 60px;

	@media (max-width: 767px) {
		padding-top: 20px;
	}
`;

const TextHeader = styled.div`
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	position: relative;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
	margin-bottom: 20px;
`;

const TabsContainer = styled.div`
	.ant-tabs-nav .ant-tabs-tab-active {
		color: #000000;
		font-weight: bold;
		font-size: 20px;
	}

	.ant-tabs-nav .ant-tabs-tab:hover {
		color: #000000;
	}

	.ant-tabs-ink-bar {
		background-color: #d90101;
	}
`;

class Contact extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			data: '',
		};
	}

	changeTab = key => {
		this.setState({ selectedTabs: key });
	};

	render() {
		return (
			<div>
				<Helmet>
					<title>
						รายชื่อผู้ติดต่อที่บันทึกไว้ | GIZTIX บริการขนส่ง บริษัทขนส่ง
						รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า
						ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full={true} />
				<SubHeader backLink="/" />
				<BackgroundContainer>
					<Container>
						<Row>
							<Col lg={2} md={3} sm={12}>
								<Menu />
							</Col>
							<Col lg={8} md={6} sm={12}>
								<Card>
									<React.Fragment>
										<TextHeader>{`ข้อมูลรายชื่อผู้ติดต่อที่บันทึกไว้`}</TextHeader>
										<TextSubHeader>
											แสดงรายละเอียดข้อมูลรายชื่อผู้ติดต่อที่บันทึกไว้
										</TextSubHeader>

										<TabsContainer>
											<TabsAntd
												onChange={key => this.changeTab(key)}
												animated={false}
											>
												<TabsAntd.TabPane tab="รายชื่อผู้ติดต่อ" key="1">
													<ContactList />
												</TabsAntd.TabPane>

												<TabsAntd.TabPane
													tab="รายชื่อผู้ติดต่อและสถานที่"
													key="2"
												>
													<ContactAddressList />
												</TabsAntd.TabPane>
											</TabsAntd>
										</TabsContainer>
									</React.Fragment>
								</Card>
							</Col>
							<Col lg={2} md={3} sm={12} />
						</Row>
					</Container>
				</BackgroundContainer>
			</div>
		);
	}
}

export default withNamespaces()(Contact);
