import React, { Component } from 'react';
import { Layout, Popover, Empty, Spin, Icon as IconAntd } from 'antd';
import styled from 'styled-components';
import { Icon, HeaderMenu } from '../../components';
import MediaQuery from 'react-responsive';
import client from 'src/config/client';
import userQueryClient from 'src/config/graphqlClient/query/user';
import { compose, graphql, Query, Mutation } from 'react-apollo';
import { Modal, Button } from 'react-bootstrap';
import AccountInfoList from './graphql/query/accountInfoList';
import { withNamespaces } from 'react-i18next';
import s3 from 'src/config/s3';
import modalLoginQueryClient from 'src/config/graphqlClient/query/modalLogin';
import modalRegisterQueryClient from 'src/config/graphqlClient/query/modalRegister';
import notificationListQuery from './graphql/query/notificationList';
import notificationCountQuery from './graphql/query/notificationCount';
import notificationUpdateMutation from './graphql/mutation/notificationUpdate';
import notificationCountUpdateMutation from './graphql/mutation/notificationCountUpdate';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SearchTracking } from './components';
import landingUrl from 'src/config/landingUrl';
import PropTypes from 'prop-types';
import ModalDriverAccept from '../../container/ModalDriverAccept';

const HeaderContainer = styled.div`
  background-color: #FFFFFF;
  min-height: 60px;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
  z-index: 999;

  @media(max-width: 767px) {
    position: relative;
  }
`;

const Header = styled.div`
  line-height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0px auto;

  ${
    props => props.full === false && `
      @media(min-width: 768px) {
        width: 750px;
      }
    
      @media(min-width: 992px) {
        width: 970px;
      }
    
      @media(min-width: 1200px) {
        width: 1170px;
      }
    `
  }

  @media(max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Container = styled.div`
  & .menu-right {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    & ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
    }

    & ul > li {
      margin: 0;
      padding: 0px 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 20px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.7);
      
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      transition-delay: 0s;

      &:hover {
        color: #000000;
      }

      &:last-child {
        padding-right: 0px;
      }
    }
  }

  @media(max-width: 767px) {
    & .menu-right {
      & ul > li {
        width: 35px;
      }
    }
  }
`;

const HeaderLogo = styled.div`
  width: 150px;
  height: 36px;
  background: rgba(255, 255, 255, 0.2);
  float: left;
  margin: 0 24px 0 0;
  cursor: pointer;

  & img {
    width: 100%;
  }
`;

const HeaderButton = styled.button`
  line-height: 1;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 20px;
  outline: none;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

  &:hover {
    border: 1px solid #000000;
    color: #000000;
  }
`;

const HeaderButtonIcon = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;
  color: rgba(0, 0, 0, 0.7);

  &:hover {
    color: #000000;
  }
`

const TopBanner = styled.div`
  position: relative;

  & img {
    width: 100%;
  }

  & .close-btn{
		cursor: pointer;
		position: absolute;
		top: 5px;
		left: 10px;
		color: #fff;
		width: 30px;
    height: 30px;
	}
`;

const WelcomeContainer = styled.div`
	& .close-btn{
		text-align: right;
	}
	& h2{
		color: #D90101;
		font-weight: bold;
		margin-bottom:0;
	}
	& h3{
		margin-top:0;
    	font-weight: bold;
		margin-bottom:0;
	}
`;

const TextChangeLanguage = styled.span`
	font-size: 18px;
`;

const Badge = styled.span`
	position: absolute;
	top: -6px;
	right: -10px;
	min-width: 16px;
	height: 16px;
	padding: 0 6px;
	color: #FFFFFF;
	font-weight: normal;
	font-size: 16px;
	white-space: nowrap;
	text-align: center;
	background: #f5222d;
	border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
  line-height: 16px;
`;

const NotificationContainer = styled.div`
	font-size: 20px;
	line-height: 1;
	width: 320px;
	box-sizing: border-box;
	position: relative;

	& .notification {
		&-header {
			padding: 15px 20px;
			display: flex;
			flex-direction: row;
			align-item: center;
	
			&__title {
				font-weight: bold;
				flex: 1;
			}
	
			&__btn-read {
				color: #808080;
				cursor: pointer;
				font-size: 16px;
			}
		}
	
		&-body {
			display: flex;
			flex-direction: column;
			min-height: 250px;
			max-height: 380px;
			overflow-y: auto;
	
			&__title {
				padding: 10px 20px;
				font-size: 16px;
				color: #B3B3B3;
			}
	
			&__list {
				display: flex;
				flex-direction: row;
				padding: 15px 20px;
				position: relative;
				cursor: pointer;

				&:hover {
          background-color: #F9F9F980;
        }
	
				&--active {
					background-color: #F9F9F9;
				}

				&-detail {
					display: flex;
					flex-direction: column;
					flex: 1;

					&__title {
						font-size: 18px;
						font-weight: bold;
					}

					&__sub-title {
						font-size: 16px;
						color: #888888;
						white-space: pre-wrap;
					}
				}

				&-time {
					position: absolute;
          top: 10px;
          right: 20px;
					color: #B3B3B3;
					font-size: 14px;
					align-self: flex-start;
				}
			}
		}

		&-footer {
			margin-top: 2px;
		}

		&-icon {
			width: 35px;
			height:35px;
			background-color: #D90101;
			border-radius: 50%;
			margin-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px;

			& img {
				width: 100%;
			}
		}

		&__btn {
			padding: 10px 0px;
			width: 100%;
			font-size: 16px;
			color: #808080;
			background-color: #FFFFFF;
			border: none;
			outline: none;
		}

		&-line {
			width: 100%;
			height: 1px;
			background-color: #E3E3E3;
		}
  }
`;

const PopoverNotificationContainer = styled.div`
  & .ant-popover {
    z-index: 10;
  }

  & .ant-popover-inner-content {
    padding: 3px 0px;
  }
`;

const DropDownContainer = styled.div`
  padding: 5px 0px;
`;

const DropDownItem = styled.div`
  cursor: pointer;
  min-width: 160px;
  font-size: 20px;
  padding: 10px 15px;
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  color: rgba(0, 0, 0, 0.7);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;
  
  & i {
    padding-right: 10px;
  }

  &:hover {
    background-color: #f5f5f5;
    color: #000000;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const LinkStyle = styled(Link)`
  color: rgba(0, 0, 0, 0.7);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

  &:hover {
    color: #000000;
  }
`;

const ImageProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageProfile = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #F2F2F2;

  & img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;

const NavItemDivider = styled.div`
	background-color: #E3E3E3;
  width: 1px;
  height: 60%;
`;

const TopHeaderHotLine = styled.div`
  height: 36px;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(to right, #D90101, #D94C01);
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
  box-sizing: border-box;
  padding: 10px;

  & i {
    font-size: 16px;
    padding-right: 10px;
  }
`;

class HeaderNav extends Component {
  static propTypes = {
    isLoadingLogo: PropTypes.bool,
  }

  static defaultProps = {
    isLoadingLogo: false,
  }

	state = {
		current: 'mail',
    visible: false,
    isOpenMenu: false,
    isShowTopBanner: true,
    isShowWelcome: true,
	};

	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
  };
  
  openPopUpUser(token , hash) {
    const noHashURL = window.location.href.replace(/#.*$/, '');
    
		if(!token){
			if(hash != ""){
				if(hash === "#register"){
					window.history.replaceState('', document.title, noHashURL) ;
					
					client.cache.writeData({
						data: {
							modalRegister: {
								isOpen: false,
								isBack: false ,
								__typename: 'ModalRegister',
							},
						},
					})
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: false,
								__typename: 'ModalLogin',
							},
						},
					})
				}

				if(hash === "#login"){
					window.history.replaceState('', document.title, noHashURL) ;
					
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: true,
								__typename: 'ModalLogin',
							},
						},
					});
					client.cache.writeData({
						data: {
							modalRegister: {
								isOpen: false,
								isBack: false ,
								__typename: 'ModalRegister',
							},
						},
					});
				}
			}
			else{
        if(
          window.location.pathname === "/register-business" ||
          window.location.pathname === "/shipment" ||
          window.location.pathname === "/favorite-driver"
        ){
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: true,
								__typename: 'ModalLogin',
							},
						},
					});
				}
			}		
		}
  }
  
  switchLanguage() {
		const { t, i18n } = this.props;
		if (t('common:lang') === 'th') {
			i18n.changeLanguage('en');
			localStorage.setItem('lang', 'en');
		} else {
			i18n.changeLanguage('th');
			localStorage.setItem('lang', 'th');
		}
  }
  
  openModalLogin() {
    const modalLoginData = client.readQuery({
      query: modalLoginQueryClient,
    });

    client.writeQuery({
      query: modalLoginQueryClient,
      data: {
        modalLogin: {
          ...modalLoginData.modalLogin,
          isOpen: true,
          isBack: false,
        }
      }
    });
  }

  openModalRegister() {
    const modalRegisterData = client.readQuery({
      query: modalRegisterQueryClient,
    });

    client.writeQuery({
      query: modalRegisterQueryClient,
      data: {
        modalRegister: {
          ...modalRegisterData.modalRegister,
          isOpen: true
        }
      }
    });
  }

  async handleRemoveCountNotification() {
    try {
      await this.props.notificationCountUpdateMutation({
        variables: {
          count_number: 0,
        },
        refetchQueries: () => ['NotificationCount', 'NotificationList']
      });
    } catch(error) {

    }
  }

	render() {
    const TOKEN = {
      authorization: localStorage.getItem('token')
    };

		return (
			<Container>
        <Query query={userQueryClient}>
          {
            ({ data }) => {
              const token = data.user.token;

              return (
                <Query
                  query={AccountInfoList}
                  context={TOKEN}
                >
                  {
                    ({ data: accountInfoListData, loading, error }) => {
                      const account_info = accountInfoListData;
                      let displayName = '';
                      let displayPicture = '';
                      let accountBusiness = null;
                      let accountInfoId = '';

                      if(!loading && !error && account_info !== {} && account_info !== undefined && account_info.accountInfoList !== undefined) {
                        displayName = account_info.accountInfoList.account_info[0].account_users[0].account_user_display_name;
                        displayPicture = account_info.accountInfoList.account_info[0].account_users[0].account_user_display_picture ? s3 + '/' + account_info.accountInfoList.account_info[0].account_users[0].account_user_display_picture : '';
                        accountBusiness = account_info.accountInfoList.account_business !== null ? true : false;
                        accountInfoId = account_info.accountInfoList.account_info[0]._id;
                      }

                      return (
                        <React.Fragment>
                          {accountInfoId && (<ModalDriverAccept accountInfoId={accountInfoId} />)}
                          {this.openPopUpUser(token, window.location.hash)}

                          {
                            token && window.location.hash === "#welcome" ? (
                              <Modal
                                show={this.state.isShowWelcome}
                                onHide={() =>
                                  this.setState({
                                    isShowWelcome: false
                                  },() => window.location.hash = "")
                                }
                              >
                                <Modal.Body style={{ textAlign: 'center' }}>
                                  <WelcomeContainer>
                                    <div className="close-btn">
                                      <i className="fal fa-times" onClick={() => this.setState({isShowWelcome:false},() => window.location.hash = "")}></i>
                                    </div>
                                    <img src={require('src/assets/images/welcomeSubAccount.png')} style={{width:"90%"}} alt="" />
                                    <h2>{ this.props.t('common:header.Welcome') } </h2>
                                    <h3>{displayName}</h3>
                                    <p>{ this.props.t('common:header.tagLine') }</p>
                                  </WelcomeContainer>
                                  <Button bsStyle="primary" onClick={() => this.setState({isShowWelcome:false},() =>  window.location.hash = "")} style={{width:150,marginBottom:30}}>
                                    { this.props.t('common:header.WelcomeBtn') }
                                  </Button>
                                </Modal.Body>
                              </Modal>
                            ) :null
                          }

                          <MediaQuery maxWidth={767}>
                            {
                              this.state.isShowTopBanner && (
                                <TopBanner>
                                  <a href="https://giztix.page.link/downloadGiztixApp">
                                    <img src={`${require('src/assets/images/popup_loadapp-05.png')}`} alt="download app" />
                                  </a>
                                  <div className="close-btn" onClick={() => this.setState({isShowTopBanner : false})} > <i class="fal fa-times"></i> </div>
                                </TopBanner>
                              )
                            }
                          </MediaQuery>

                          <HeaderContainer>
                            <MediaQuery minWidth={768}>
                              <TopHeaderHotLine>
                                <i className="fas fa-bullhorn"></i>
                                <span>{this.props.t('common:headerNav.hotline')}</span>
                              </TopHeaderHotLine>
                            </MediaQuery>

                            <Header full={this.props.full ? true : false}>
                              {
                                this.props.isLoadingLogo === false && (
                                  <HeaderLogo>
                                    <a href={landingUrl}>
                                      <img src={this.props.trackingUrl || require('src/assets/images/logo_giztix.png')} alt="logo" />
                                    </a>
                                  </HeaderLogo>
                                )
                              }
                              
                              <div className="menu-right">
                                <ul>
                                  {
                                    token && (
                                      <li>
                                        <PopoverNotificationContainer>
                                          <Popover getPopupContainer={triggerNode => document.getElementById('abc')} placement="bottomRight" content={
                                            <DropDownContainer>
                                              <DropDownItem onClick={() => {
                                                window.location = '/setting-profile';
                                              }}>
                                                <i className="far fa-user"></i>{ this.props.t('common:header.editAccount') }
                                              </DropDownItem>
                                              {
                                                accountBusiness && (
                                                  <DropDownItem onClick={() => {
                                                    window.location = '/setting-business';
                                                  }}>
                                                    <i className="far fa-suitcase"></i>{ this.props.t('common:header.companyProfile') }
                                                  </DropDownItem>
                                                )
                                              }
                                              <DropDownItem onClick={() => {
                                                localStorage.removeItem('token');
                                                window.location = '/';
                                              }}>
                                                <i className="far fa-power-off"></i>{ this.props.t('common:header.logOut') }
                                              </DropDownItem>
                                            </DropDownContainer>
                                          } trigger="click">
                                            <ImageProfileContainer id="abc">
                                                {
                                                  displayPicture ? (
                                                  <ImageProfile>
                                                    <img src={`${displayPicture}`} alt="profile" />
                                                  </ImageProfile>
                                                  ) : (
                                                    <Icon type="fal" name="fa-user-circle" size={24} />
                                                  )
                                                }

                                                <MediaQuery minWidth={768}>
                                                  <span style={{
                                                    marginLeft: 10,
                                                    lineHeight: 1,
                                                    height: 16,
                                                  }}>{ displayName ? displayName : 'username' }</span>
                                                </MediaQuery>
                                            </ImageProfileContainer>
                                          </Popover>
                                        </PopoverNotificationContainer>
                                      </li>
                                    )
                                  }

                                  {
                                    token && (
                                      <li>
                                        <NavItemDivider />
                                      </li>
                                    )
                                  }

                                  <MediaQuery maxWidth={767}>
                                    {
                                      !token && (
                                        <li>
                                          <PopoverNotificationContainer>
                                            <Popover getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottomRight" content={
                                              <DropDownContainer>
                                                <DropDownItem onClick={() => this.openModalLogin()}>
                                                  { this.props.t('common:header.signin') }
                                                </DropDownItem>
                                                <DropDownItem onClick={() => this.openModalRegister()}>
                                                  { this.props.t('common:header.register') }
                                                </DropDownItem>
                                              </DropDownContainer>
                                            } trigger="click">
                                              <Icon type="fal" name="fa-user-circle" size={24} />
                                            </Popover>
                                          </PopoverNotificationContainer>
                                        </li>
                                      )
                                    }

                                    {
                                      token && (
                                        <li>
                                          <HeaderButtonIcon>
                                            <LinkStyle to="/notification">
                                              <Icon type="fal" name="fa-bell" size={24} />
                                            </LinkStyle>
                                          </HeaderButtonIcon>
                                        </li>
                                      )
                                    }
                                  </MediaQuery>

                                  <MediaQuery minWidth={768}>
                                    {
                                      !token && (
                                        <li onClick={() => this.openModalRegister()}>
                                          { this.props.t('common:header.register') }
                                        </li>
                                      )
                                    }

                                    {
                                      !token && (
                                        <li onClick={() => this.openModalLogin()}>
                                          <HeaderButton>{ this.props.t('common:header.signin') }</HeaderButton>
                                        </li>
                                      )
                                    }

                                    {
                                      token && (
                                        <li>
                                          <HeaderButtonIcon>
                                            <PopoverNotificationContainer>
                                              <Popover getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottomRight" content={
                                                <NotificationContainer>
                                                  <div className="notification-header">
                                                    <div className="notification-header__title">{this.props.t('common:notification.title')}</div>
                                                    <Mutation
                                                      mutation={notificationUpdateMutation}
                                                      variables={{
                                                        multi: true,
                                                        read_status: 2,
                                                      }}
                                                      refetchQueries={() => ['NotificationList']}
                                                    >
                                                      {
                                                        (notificationUpdate) => {
                                                          return (
                                                            <div className="notification-header__btn-read" onClick={() => notificationUpdate()}>{this.props.t('common:notification.markAllRead')}</div>
                                                          )
                                                        }
                                                      }
                                                    </Mutation>
                                                  </div>
                                                  <div className="notification-line"></div>
                                                  <Query
                                                    query={notificationListQuery}
                                                    fetchPolicy="network-only"
                                                    variables={{
                                                      show_data: 10
                                                    }}
                                                  >
                                                    {
                                                      ({ data, loading, error }) => {
                                                        if(loading) return (<div className="notification-body">
                                                          <Spin indicator={
                                                            <IconAntd type="loading" style={{ fontSize: 24, color: '#DDDDDD', margin: '80px 0px' }} spin />
                                                          } />
                                                        </div>);
                                                        if(error) return (<div className="notification-body">{ error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Error.' }</div>);
                      
                                                        const {
                                                          notification
                                                        } = data.notificationList;
                                                        const filterNewNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') < 60);
                                                        const filterOldNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') >= 60);
                                                        const notificationList = [
                                                          {
                                                            title: this.props.t('common:notification.new'),
                                                            lists: [...filterNewNotification],
                                                          },
                                                          {
                                                            title: filterNewNotification.length > 0 ? this.props.t('common:notification.previous') : this.props.t('common:notification.all'),
                                                            lists: [...filterOldNotification],
                                                          }
                                                        ];
                      
                                                        return (
                                                          <div>
                                                            <div className="notification-body" data-simplebar>
                                                              {
                                                                notification.length === 0 && (
                                                                  <Empty
                                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                    description={
                                                                      <span>
                                                                        {this.props.t('common:notification.empty')}
                                                                      </span>
                                                                    }
                                                                    style={{margin: '80px 0px'}}
                                                                  />
                                                                )
                                                              }
                      
                                                              {
                                                                notificationList.map((item) => (
                                                                  <React.Fragment>
                                                                    { item.lists.length > 0 && (<div className="notification-body__title">{item.title}</div>) }
                                                                    { item.lists.map((list, index) => (
                                                                      <Mutation
                                                                        mutation={notificationUpdateMutation}
                                                                        variables={{
                                                                          _id: list._id,
                                                                          read_status: 2,
                                                                        }}
                                                                        update={(cache, { data }) => {
                                                                          const { succeed } = data.notificationUpdate;
                                                                          if(succeed) {
                                                                            if(list.notification_type === 1) {
                                                                              window.location = `/shipmentdetail?shipment=${list.shipment_id}`;
                                                                            } else if(list.notification_type === 2) {
                                                                              window.location = `/setting-business`;
                                                                            }
                                                                          }
                                                                        }}
                                                                        refetchQueries={() => ['NotificationList']}
                                                                      >
                                                                        {
                                                                          (notificationUpdate) => {
                                                                            return (
                                                                              <div onClick={() => {notificationUpdate()}} key={index} className={`notification-body__list ${list.read_status === 1 && 'notification-body__list--active'}`}>
                                                                                <div className="notification-body__list-icon">
                                                                                  <div className="notification-icon">
                                                                                    <img 
                                                                                      src={`${list.pic_type === 1 ? require('src/assets/images/notification-icon-giztix.svg') : list.pic_type === 3 ? require('src/assets/images/notification-icon-bullhorn.svg') : require('src/assets/images/notification-icon-truck.svg')}`}
                                                                                      alt="icon"
                                                                                    />
                                                                                  </div>
                                                                                </div>
                                                                                <div className="notification-body__list-detail">
                                                                                  <div className="notification-body__list-detail__title">{list.title_lang[this.props.t('common:lang')]}</div>
                                                                                  <div className="notification-body__list-detail__sub-title">
                                                                                    <div>{list.sub_title_lang[this.props.t('common:lang')]}</div>
                                                                                  </div>
                                                                                </div>
                                                                                <div className="notification-body__list-time"  title={`${moment(list.create_date).format('YYYY-MM-DD HH:mm:ss')}`}>{moment(list.create_date).fromNow()}</div>
                                                                              </div>
                                                                            )
                                                                          }
                                                                        }
                                                                      </Mutation>
                                                                    )) }
                                                                  </React.Fragment>
                                                                ))
                                                              }
                                                            </div>
                                                          </div>
                                                        )
                                                      }
                                                    }
                                                  </Query>
                      
                                                  <div className="notification-line"></div>
                                                  <div className="notification-footer">
                                                    <Link to="/notification">
                                                      <button className="notification__btn">{this.props.t('common:notification.seeAll')}</button>
                                                    </Link>
                                                  </div>
                                                </NotificationContainer>
                                              } trigger="click">
                                                <span style={{position: 'relative'}} onClick={() => this.handleRemoveCountNotification()}>
                                                  <Icon type="fal" name="fa-bell" size={24} />
                                                  <Query
                                                    query={notificationCountQuery}
                                                    fetchPolicy="cache-and-network"
                                                  >
                                                    {
                                                      ({ data, loading, error }) => {
                                                        if(loading || error) return null;
                                                        return data.notificationCount != null && !!data.notificationCount.count_number && (
                                                          <Badge>{ data.notificationCount.count_number }</Badge>
                                                        )
                                                      }
                                                    }
                                                  </Query>
                                                </span>
                                              </Popover>
                                            </PopoverNotificationContainer>
                                          </HeaderButtonIcon>
                                        </li>
                                      )
                                    }

                                    <li>
                                      <PopoverNotificationContainer>
                                        <Popover getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottomRight" content={
                                          <SearchTracking />
                                        } trigger="click">
                                          <Icon type="fal" name="fa-search" size={24} />
                                        </Popover>
                                      </PopoverNotificationContainer>
                                    </li>

                                    <li>
                                      <HeaderButtonIcon onClick={() => this.switchLanguage()}>
                                        <img src={`${require('src/assets/images/flag/' + (this.props.t('common:lang') === 'th' ? 'en' : 'th').toUpperCase() + '.png')}`} style={{height:15,marginRight:10,marginBottom:4}} alt="flag" />
                                        <TextChangeLanguage>{ (this.props.t('common:lang') === 'th' ? 'en' : 'th').toUpperCase() }</TextChangeLanguage>
                                      </HeaderButtonIcon>
                                    </li>
                                  </MediaQuery>

                                  <li>
                                    <HeaderButtonIcon onClick={() => this.setState({ isOpenMenu: true })}>
                                      <Icon type="far" name="fa-bars" size={24} />
                                    </HeaderButtonIcon>
                                  </li>
                                </ul>
                              </div>
                            </Header>
                          </HeaderContainer>
                        </React.Fragment>
                      )
                    }
                  }
                </Query>
              )
            }
          }
        </Query>

        <HeaderMenu
          openMenu={this.state.isOpenMenu}
          onTouchEnd={() => this.setState({ isOpenMenu: false })}
        />
      </Container>
		);
	}
}

export default compose(
  graphql(notificationCountUpdateMutation, {
    name: 'notificationCountUpdateMutation',
  })
)(withNamespaces()(HeaderNav));
