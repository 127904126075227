import baseUrl from './baseUrl';

let lambdaUrl = '';

if (baseUrl === 'https://api.giztix.com') {
	lambdaUrl = 'https://lambdatruck.giztix.com';
}

if (baseUrl === 'https://demoapi.giztix.com') {
	lambdaUrl = 'https://demolambdatruck.giztix.com';
}

export default lambdaUrl;
