import gql from 'graphql-tag';

export default gql`
  mutation DriverUserAdd(
    $account_info_id: ID
    $driver_name: String!
    $driver_phone_code: String!
    $driver_phone_number: String!
    $driver_enable: Int!
  ) {
    driverUserAdd(
      account_info_id: $account_info_id
      driver_name: $driver_name
      driver_phone_code: $driver_phone_code
      driver_phone_number: $driver_phone_number
      driver_enable: $driver_enable
    ) {
      _id
    }
  }
`;