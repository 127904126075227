import React, { Component } from 'react';
import styled from 'styled-components';
import { Card, CardHeader, Skeleton } from 'src/components';
import { graphql, compose, Query } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import _ from 'lodash';
import { withNamespaces } from 'react-i18next';
import { InstancePriceContext } from './../../contexts/InstancePriceContext';
import { ContactItem } from './components';
import accountInfoListQuery from './graphql/query/accountInfoList';

const Container = styled.div`
	width: 100%;
`;

const ContactListContainer = styled.div`
	margin-top: 20px;
`;

class SelectContact extends Component {
	static contextType = InstancePriceContext;

	closePopup() {
		this.context.onClose(['selectContact']);
	}

	selectOwnContactHandler = (contact) => {
		this.context.setData({
			contactName: contact.contact_name,
			contactTel: contact.contact_tel,
		});

		this.context.onClose(['selectContact']);
	};

	selectSearchContactSaved = () => {
		this.context.onOpen(['selectContactSaved']);
	};

	render() {
		return (
			<Container>
				<Card
					content={
						<div>
							<CardHeader
								title={'เลือกช่องทางข้อมูลติดต่อ'}
								onPressClose={() => this.closePopup()}
								borderCardBottom
							/>

							<ContactListContainer>
								<Query query={accountInfoListQuery}>
									{({ data, loading, error }) => {
										if (loading)
											return (
												<Skeleton width={220} height={170}>
													<rect
														x="0"
														y="0"
														rx="4"
														ry="4"
														width="143"
														height="13"
													/>
													<rect
														x="0"
														y="24"
														rx="3"
														ry="3"
														width="200"
														height="10"
													/>

													<rect
														x="0"
														y="59"
														rx="4"
														ry="4"
														width="143"
														height="13"
													/>

													<rect
														x="0"
														y="83"
														rx="3"
														ry="3"
														width="200"
														height="10"
													/>

													<rect
														x="0"
														y="118"
														rx="4"
														ry="4"
														width="143"
														height="13"
													/>

													<rect
														x="0"
														y="142"
														rx="3"
														ry="3"
														width="200"
														height="10"
													/>
												</Skeleton>
											);

										if (error) {
											let textError =
												error.graphQLErrors && error.graphQLErrors.length > 0
													? error.graphQLErrors[0].message
													: error.message;
											
											if(textError === 'You must login.') {
												textError = 'เข้าสู่ระบบเพื่อใช้งาน';
											}
											
											return <div>{textError}</div>;
										}

										const {
											account_info_phone_number,
											account_info_business_name,
										} = data.accountInfoList.account_info[0];

										const contactData = {
											contact_name: account_info_business_name,
											contact_tel: `0${account_info_phone_number}`,
										}

										return (
											<React.Fragment>
												<ContactItem
													icon="fal fa-user"
													name={`ใช้ข้อมูลติดต่อของคุณ`}
													subTitle={`${account_info_business_name} [0${account_info_phone_number}]`}
													clicked={() => this.selectOwnContactHandler(contactData)}
												/>

												<ContactItem
													icon="fal fa-address-book"
													name={`ค้นหาจากผู้ติดต่อที่บันทึกไว้`}
													clicked={this.selectSearchContactSaved}
												/>
											</React.Fragment>
										);
									}}
								</Query>
							</ContactListContainer>
						</div>
					}
				/>
			</Container>
		);
	}
}

export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
	graphql(instancePriceStepQueryClient, {
		name: 'instancePriceStepQuery',
	}),
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery',
	})
)(withNamespaces()(SelectContact));
