import gql from 'graphql-tag';

export default gql`
	query OptimizeRoute(
    $address: [input_optimize_route_address]
  ) {
		optimizeRoute(
      address: $address
    ) {
			address {
        lat
        lng
      }
		}
	}
`;
