import React from 'react';
import styled from 'styled-components';
import 'rc-drawer/assets/index.css';
import HeaderDesktop from './../HeaderDesktop';
import HeaderMobile from './../HeaderMobile';
import MediaQuery from 'react-responsive';


class Header extends React.Component {
	render() {
		return (
			<div>
				<MediaQuery minWidth={768}>
					<HeaderDesktop {...this.props} />
				</MediaQuery>
				<MediaQuery maxWidth={767}>
					<HeaderMobile {...this.props} />
				</MediaQuery>
			</div>
		)
	}
}

export default Header;