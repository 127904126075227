import gql from 'graphql-tag';

export default gql`
	mutation UpdateLocationTemp(
		$address: String
		$addressDefault: String
		$latitude: String
		$longitude: String
		$latitudeCenter: String
		$longitudeCenter: String
		$isFitBounds: Boolean
		$isSelected: Boolean
		$type: String
		$shipmentAddressIndex: Int
		$datetime: String
	) {
		updateLocationTemp(
			address: $address
			addressDefault: $addressDefault
			latitude: $latitude
			longitude: $longitude
			latitudeCenter: $latitudeCenter
			longitudeCenter: $longitudeCenter
			isFitBounds: $isFitBounds
			isSelected: $isSelected
			type: $type
			shipmentAddressIndex: $shipmentAddressIndex
			datetime: $datetime
		) @client
	}
`;
