import React, { Component } from 'react';
import {
	HeaderNav,
	SubHeaderNav,
	CardFlashDealsSmallItem,
	ModalCardFlashDeals,
	CardBannerSponsor,
} from 'src/app/components';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Layout, Button as ButtonAntd, Popover } from 'antd';
import { withNamespaces } from 'react-i18next';
import {
	GoogleMaps,
	CheckBoxHideModal,
	GiztixSurvey,
	DistanceChallenge2,
} from './components';
import { Query } from 'react-apollo';
import { ModalAlert, ModalConfirm } from 'src/components';
import { Button } from 'react-bootstrap';
import { InstancePriceContext } from './contexts/InstancePriceContext';
import InstancePriceContextProvider from './contexts/InstancePriceContext';
import { Helmet } from 'react-helmet';
import stylesReactModal from './ReactModal.module.css';
import { Menu } from 'src/components';

//PromotionContainer
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//screen
import {
	CreateShipment,
	SearchLocation,
	ConfirmShipment,
	SelectTruck,
	PaymentChanel,
	PaymentCOD,
	Additional,
	Promotion,
	PriceResult,
	SelectDateTime,
	PaymentCredit,
	PaymentCreditList,
	PaymentCreditEdit,
	Contact,
	SelectDateTimeContact,
	SelectRemark,
	NotificationMultiDrop,
	SelectDriver,
	SelectContact,
	SelectContactSaved,
} from './screens';
import ReactModal from 'react-modal';
import stylesReactModalSidebar from './ReactModalSidebar.module.css';
import client, { instancePrice, instancePriceStep } from 'src/config/client';
import URI from 'urijs';
import modeToEng from './function/modeToEng';

import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import detailShipmentQuery from './graphql/query/detailShipment';
import creditCardListQuery from './graphql/query/creditCardList';
import shareRouteListQuery from './graphql/query/shareRouteList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import creditCardDefaultQueryClient from 'src/config/graphqlClient/query/creditCardDefault';
import firstShipmentQuery from './graphql/query/firstShipment';
import settingTruckListQuery from 'src/view/InstancePrice2/screens/SelectTruck/graphql/query/settingTruckList';
import detailShareRouteQuery from './graphql/query/shareRoute';
import flashDealsListQuery from './graphql/query/flashDealsList';
import shipmentLastPaymentQuery from './graphql/query/shipmentLastPayment';
import SimpleBar from 'simplebar-react'; //" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import s3 from 'src/config/s3.js';
import moment from 'moment';
import { withCookies } from 'react-cookie';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const customStylesReactModal = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: 'transparent',
		border: 0,
	},
	overlay: {
		zIndex: 1010,
	},
};

const customStylesReactModalSidebar = {
	overlay: {
		zIndex: 1010,
		backgroundColor: '#FFFFFF',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
};

const customStylesReactModalBike = {
	overlay: {
		zIndex: 1010,
		overflowY: 'scroll',
	},
};

ReactModal.defaultStyles.overlay.backgroundColor = '#00000050';

const PopupContainer = styled.div`
	padding: 10px;
`;

const Container = styled(Layout)``;
const ContentContainer = styled.div`
	margin-top: calc(60px + ${heightTopHeader}px);
	background-color: #ffffff;

	@media (max-width: 767px) {
		margin-top: 0px;
	}
`;

const LayoutContent = styled.div`
	& .with-sidebar {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	& .sidebar {
		width: 420px;
		flex-grow: 1;

		@media (max-width: 767px) {
			width: 100%;
		}
	}

	& .not-sidebar {
		flex-basis: 0;
		flex-grow: 999;

		@media (max-width: 767px) {
			order: -1;
		}
	}

	@media (max-width: 767px) {
		& .with-sidebar {
			flex-direction: column;
		}
	}
`;

const SidebarContainer = styled.div`
	box-sizing: border-box;
	border-right: 1px solid #d9d9d9;
	height: calc(${props => props.height}px - 60px - 50px);
	padding: 0px 10px;
	overflow-y: auto;
	overflow-x: hidden;

	@media (max-width: 767px) {
		border: none;
		height: auto;
		padding-bottom: 60px;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	& [data-simplebar] {
		position: absolute;
	}
`;

const SideBar = styled.div``;

const NotSidebar = styled.div`
	position: relative;
	height: calc(
		${props => props.height}px - 60px - 50px
			${props => props.isHasFlashDeals && '- 155px'}
			${props => (props.isShowFlashDeals ? '- 20px' : '+ 105px')}
	);
	background-color: #eeeeee;
	border-bottom: 1px solid #eeeeee;

	@media (max-width: 767px) {
		height: 200px;
	}

	& [data-simplebar] {
		position: absolute;
	}
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0px;
	z-index: 1;
	background-color: #ffffff;
	padding-left: 16px;
	padding-right: 16px;
	height: calc(${props => props.height}px - 60px - 50px);
	overflow-x: hidden;
	border-right: 1px solid #d9d9d9;
`;

const PopupPolicy = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 270px;

	& .policy {
		&-title {
			font-weight: bold;
			font-size: 22px;
		}

		&-detail {
			font-size: 18px;
			line-height: 1;
		}

		&-footer {
			margin-top: 5px;
			text-align: right;

			& button {
				background-color: transparent;
				border: none;
				outline: none;
				font-size: 20px;
				padding: 0px 10px;
			}
		}
	}
`;

const TopMapContainer = styled.div`
	position: absolute;
	width: 100%;
	z-index: 1;
`;

const ButtonPolicy = styled(ButtonAntd)`
	margin: 20px;

	&.ant-btn-circle {
		width: 50px;
		height: 50px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&.ant-btn i {
		font-size: 25px;
	}

	@media (max-width: 767px) {
		margin: 10px;

		&.ant-btn-circle {
			width: 40px;
			height: 40px;
		}

		&.ant-btn i {
			font-size: 20px;
		}
	}
`;

const PromotionContainerBike = styled.div`
	position: relative;
	margin-top: 60px;
	margin-bottom: 30px;
	border-radius: 10px;
	background-color: #ffffff;
	padding: 24px;

	& .btnCloseModal {
		color: #00000080;
		font-size: 24px;
		position: absolute;
		top: 10px;
		right: 15px;
		cursor: pointer;
	}

	& img {
		width: 100%;
	}

	@media (max-width: 768px) {
		margin: 10px;
	}
`;

const PromotionContainer = styled.div`
	position: relative;
	width: 100%;
	max-width: 500px;

	@media screen and (max-width: 499px) {
		max-width: 320px;
	}

	.image-slider {
		border-radius: 6px;
		background-color: #eeeeee;

		& a {
			outline: none;
		}

		& img {
			width: 100%;
			border-radius: 6px;
		}
	}

	.slick-slide {
		padding: 0px 5px;
	}

	& .close-modal {
		position: absolute;
		color: #000000;
		z-index: 10000;
		top: 0px;
		right: 0px;
		background-color: #ffffff;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
	}

	& img {
		width: 100%;
	}

	& .close-modal {
		cursor: pointer;
	}
`;

const FlashDealsContainer = styled.div`
	background-image: linear-gradient(to bottom, white 100%, transparent);

	& .header {
		display: flex;
		flex-direction: row;
		padding: 5px 10px 0px 20px;

		& img {
			height: 15px;
		}

		& .truckCarImg {
			height: 45px;
		}

		& .textBanner {
			display: inline-flex;
			align-items: center;
			font-weight: bold;
			cursor: pointer;
			margin: 5px 0px;
		}

		& a {
			color: #1d7fe3;
			text-decoration: none;
			font-size: 16px;
		}

		.slide-left {
			-webkit-animation: slide-left 12s linear infinite;
			animation: slide-left 12s linear infinite;
		}

		& i {
			margin-left: 10px;
			margin-right: 10px;
		}

		@-webkit-keyframes slide-left {
			0% {
				-webkit-transform: translateX(100vw);
				transform: translateX(100vw);
			}
			100% {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
			}
		}

		@keyframes slide-left {
			0% {
				-webkit-transform: translateX(100vw);
				transform: translateX(100vw);
			}
			100% {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
			}
		}

		@media screen and (max-width: 1440px) {
			.slide-left {
				-webkit-animation: slide-left 12.5s linear infinite;
				animation: slide-left 12.5s linear infinite;
			}

			@-webkit-keyframes slide-left {
				0% {
					-webkit-transform: translateX(100vw);
					transform: translateX(100vw);
				}
				100% {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
				}
			}

			@keyframes slide-left {
				0% {
					-webkit-transform: translateX(100vw);
					transform: translateX(100vw);
				}
				100% {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
				}
			}
		}
	}

	& .list-item {
		padding-left: 10px;
		overflow-x: auto;
		width: 100%;
		white-space: nowrap;
		transition: height 5s, opacity 0.5s linear;

		& [data-simplebar] {
			position: relative;
		}
	}
`;

const CardFlashDealsSmallItemStyle = styled(CardFlashDealsSmallItem)`
	margin-right: 10px;
	margin-bottom: 5px;
`;

const CardBannerSponsorStyle = styled(CardBannerSponsor)`
	margin-right: 10px;
	margin-bottom: 5px;
`;

const FlashDealsButton = styled.a`
	display: inline-block;
	margin-top: 10px;
	margin-left: 10px;
	background-color: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
	border: none;
	outline: none;
	padding: 0px 6px;

	& img {
		height: 10px;
	}
`;

const settingsSliderPromotion = {
	dots: true,
	infinite: true,
	arrows: false,
	autoplay: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	centerPadding: 30,
};
class InstancePrice extends Component {
	state = {
		heightSidebar: 0,
		widthDevice: 0,

		isOpenPopupPolicy: false,
		showOmiseError: false,
		isHaveErrorPayment: false,
		isOpenModalPromotion: false,
		isOpenModalPromotionFirstTruck: false,
		isOpenModalPromotionBike: false,
		isOpenModalFlashDeals: false,
		isOpenModalPromotionEvent1: false,
		isOpenModalPromotionEvent2: false,
		isOpenModalGtxBU: false,
		isFlashDeals: true,
		isShowDistance: false,

		//ModalFlashDeal
		flashDealId: '',
		flashDealList: [],
		isOpenPopupOverSearchLocation: false,

		isParcelCustomer: false,
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', () => {
			setTimeout(() => {
				this.updateWindowDimensions();
			}, 200);
		});
		window.addEventListener('orientationchange', () => {
			setTimeout(() => {
				this.updateWindowDimensions();
			}, 200);
		});

		this.getParamUrl();
		this.checkShowModalPromotion();
		this.checkPopupPolicy();
		this.getFlashDeals();
		this.fetchDataFlashDeals();
		this.getIsParcelCustomer();
	}

	async getIsParcelCustomer() {
		const { data: accountInfoListData } = await client.query({
			query: accountInfoListQuery,
			variables: {
				customer_service_type: 'PARCEL',
			},
			fetchPolicy: 'network-only',
		});

		if (
			accountInfoListData &&
			accountInfoListData.accountInfoList &&
			accountInfoListData.accountInfoList.account_info.length > 0 &&
			accountInfoListData.accountInfoList.account_info[0]._id !== null
		) {
			this.setState({ isParcelCustomer: true });
		}
	}

	async getFlashDeals() {
		const uriFlashDealId = URI(window.location.href).query(true).flashDealId;
		if (uriFlashDealId) {
			try {
				const {
					data: { flashDealsList },
				} = await client.query({
					query: flashDealsListQuery,
					variables: {
						id: uriFlashDealId,
					},
				});
				const { flash_deals } = flashDealsList;
				if (flash_deals.length === 1) {
					this.setState({
						isOpenModalFlashDeals: true,
						flashDealId: uriFlashDealId,
					});
					return;
				}
			} catch (error) {
				console.log(error);
			}
		}
	}

	async fetchDataFlashDeals() {
		try {
			const { data } = await client.query({
				query: flashDealsListQuery,
			});

			this.setState({ flashDealList: [...data.flashDealsList.flash_deals] });
		} catch (error) {
			console.log(error);
		}
	}

	getParamUrl() {
		const uriRoute = URI(window.location.href).query(true).route;
		const uriPromotionCode = URI(window.location.href).query(true)
			.promotion_code;
		const uriOrder = URI(window.location.href).query(true).order;
		const uriShipmentId = URI(window.location.href).query(true).shipment;

		if (uriRoute) {
			this.getShareRoute(uriRoute);
		} else if (uriPromotionCode) {
			this.getPromotionCode(uriPromotionCode);
		} else if (uriShipmentId) {
			this.reBooking(uriShipmentId);
		} else if (uriOrder) {
			this.setState({ isHaveErrorPayment: true });
			this.reBooking(uriOrder);
		}

		if (!uriShipmentId) {
			//not rebook
			this.getDefaultPaymentType('');
		}
	}

	updateWindowDimensions() {
		this.setState({
			widthDevice: window.innerWidth,
			heightSidebar: window.innerHeight - heightTopHeader,
		});
	}

	checkPopupPolicy() {
		this.setState({ isOpenPopupPolicy: window.innerWidth >= 850 });
	}

	checkBetweenDateTime(startDateTime, endDateTime) {
		const format = 'YYYY-MM-DD HH:mm:ss';
		const current = moment();
		const startDate = moment(startDateTime, format);
		const endDate = moment(endDateTime, format);
		const range = current.isBetween(startDate, endDate, null, '[]');

		return range;
	}

	async checkShowModalPromotion() {
		try {
			if (
				this.checkBetweenDateTime('2021-01-01 00:00:00', '2021-01-31 23:59:59')
			) {
				if (!this.getCookie('hide_event_promotion_2')) {
					this.setState({ isOpenModalPromotionEvent2: true });
				}

				// return;
			}

			if (
				this.checkBetweenDateTime('2021-04-28 00:00:00', '2021-09-30 23:59:59')
			) {
				if (!this.getCookie('hide_event_promotion_1')) {
					this.setState({ isOpenModalPromotionEvent1: true });
				}

				return;
			}

			const { data: firstShipmentData } = await client.query({
				query: firstShipmentQuery,
				fetchPolicy: 'network-only',
			});

			const { first_shipment } = firstShipmentData.firstShipment;
			if (first_shipment) {
				const uriPromotionCode = URI(window.location.href).query(true)
					.promotion_code;
				if (uriPromotionCode) {
					return;
				}

				const instancePriceData = client.readQuery({
					query: instancePriceQueryClient,
				});

				client.writeQuery({
					query: instancePriceQueryClient,
					data: {
						instancePrice: {
							...instancePriceData.instancePrice,
							promotion_name: 'FIRSTTRUCK',
						},
					},
				});

				if (
					this.checkBetweenDateTime(
						'2021-01-01 00:00:00',
						'2021-01-31 23:59:59'
					)
				) {
					return;
				}

				if (!this.getCookie('hide_event_promotion_first_truck')) {
					this.setState({ isOpenModalPromotionFirstTruck: true });
				}

				return;
			}

			const { data: accountInfoListData } = await client.query({
				query: accountInfoListQuery,
				fetchPolicy: 'network-only',
			});

			if (accountInfoListData.accountInfoList.account_business === null) {
				this.setState({ isOpenModalPromotion: true });
			} else if (
				accountInfoListData.accountInfoList.account_business !== null &&
				accountInfoListData.accountInfoList.account_business.business_status ==
					2 &&
				moment().isBetween('2020-07-20 00:00', '2020-07-28 23:59', null, '[]')
			) {
				if (this.getCookie('hide_event_promotion_gtx_bu')) {
					this.setState({ isOpenModalGtxBU: false });

					return;
				}

				this.setState({ isOpenModalGtxBU: true });
			}
		} catch (error) {
			console.log(error);
		}
	}

	async reBooking(shipmentId) {
		try {
			//ดึงข้อมูลรายละเอียด shipment
			const { data: detailShipmentData } = await client.query({
				query: detailShipmentQuery,
				variables: {
					_id: shipmentId,
				},
			});

			//detail shipment
			const shipment = detailShipmentData.shipmentList.shipment[0];

			//additionList for use truck type size
			const additionList = detailShipmentData.additionalList;

			//ดึงข้อมูลรถ
			const { data: settingTruckListData } = await client.query({
				query: settingTruckListQuery,
				variables: {
					shipmentType: shipment.shipment_type,
				},
			});

			const listTruck = settingTruckListData.settingTruckList.filter(
				x => x._id === shipment.setting_truck_id
			);
			const TruckData = listTruck[0].setting_truck_type.filter(
				y => y._id === shipment.setting_truck_type_id
			);

			//set format data shipment address to client
			const shipmentAddress = shipment.shipment_address
				.filter(item => item.mode !== 'roundtrip')
				.map(item => {
					return {
						mode: modeToEng(item.mode, shipment.shipment_type || 1),
						address: item.address,
						latitude: item.lat,
						longitude: item.lng,
						contactName: item.contact_name,
						phoneNumber: item.contact_tel,
						remark: item.remark ? item.remark : '',
						paidByCOD: item.paid_by_cod,
						province: item.province,
						pickupDate: '',
						isSaveContact: false,
						mainAddress: '',
						secondaryAddress: '',
						__typename: 'ShipmentAddress',
					};
				});

			//set format data additional to client
			const additionalFormatClient = shipment.shipment_additional
				//นำบริการเสี่ยงภัยออก (เพราะไม่ใช่สิ่งที่ต้องให้ลูกค้าเลือก)
				.filter(item => item.additional_id !== '5d36be910dbce733b42b018b')
				.map(item => {
					return {
						_id: item.additional_id,
						address: item.address,
						name: {
							th: item.additional,
							en: item.additional,
							__typename: 'AdditionalName',
						},
						price: item.price,
						qty: item.qty,
						truck_type_size: null,
						__typename: 'Additional',
					};
				});

			//add truck type size
			const additionalAddTruckTypeSize = additionalFormatClient.map(item => {
				const data = additionList.find(e => e._id === item._id);
				if (data) {
					return {
						...item,
						truck_type_size: {
							...data.truck_type_size_lang,
							__typename: 'AdditionalTruckTypeSize',
						},
					};
				}

				return item;
			});

			//payment chanel
			const paymentType = this.getPaymentTypeToText(shipment.paid_type);
			await this.getDefaultPaymentType(paymentType);

			//set data to client
			const instancePriceData = client.cache.readQuery({
				query: instancePriceQueryClient,
			});

			client.cache.writeQuery({
				query: instancePriceQueryClient,
				data: {
					instancePrice: {
						...instancePriceData.instancePrice,
						shipmentAddress: shipmentAddress,
						setting_truck_id: shipment.setting_truck_id,
						setting_truck_name: {
							th: listTruck[0].setting_truck_name.th,
							en: listTruck[0].setting_truck_name.en,
							__typename: 'SettingTruckName',
						},
						setting_truck_type_id: shipment.setting_truck_type_id,
						setting_truck_type_name: {
							th: TruckData[0].truck_type_name.th,
							en: TruckData[0].truck_type_name.en,
							__typename: 'SettingTruckTypeName',
						},
						setting_truck_type_size: {
							...TruckData[0].truck_type_size_lang,
							__typename: 'SettingTruckTypeSize',
						},
						setting_truck_type_pic: shipment.truck_type_pic,
						additional: additionalAddTruckTypeSize,
						roundTrip: shipment.round_trip ? 1 : '',
						remark:
							shipment.shipment_other_detail.remark !== null
								? shipment.shipment_other_detail.remark
								: '',
						shipmentType: shipment.shipment_type || 1,
						accessory: shipment.shipment_accessory.map(item => ({
							_id: item.accessory_id,
							name: {
								th: item.accessory,
								en: item.accessory,
								__typename: 'AccessoryName',
							},
							__typename: 'Accessory',
						})),
					},
				},
			});

			if (this.state.isHaveErrorPayment) {
				this.setState({ showOmiseError: true });
			}
		} catch (error) {
			console.log(error);
		}
	}

	getPaymentTypeToText(paidType) {
		if (paidType === 1) {
			return 'creditCard';
		} else if (paidType === 2) {
			return 'COD';
		} else if (paidType === 3) {
			return 'Invoice';
		}

		return '';
	}

	async getDefaultCreditCard() {
		try {
			//ดึงข้อมูลรายการบัตรเครดิต
			const { data: creditCardListData } = await client.query({
				query: creditCardListQuery,
			});

			//กรองข้อมูลรายการบัตร เฉพาะบัตรที่ถูก default ไว้
			const creditCardDefaultFilter = creditCardListData.creditCardList.filter(
				item => item.card_default === true
			);

			if (creditCardDefaultFilter.length === 1) {
				//ถ้ามี default บัตรเครดิต
				this.setCreditCard(creditCardDefaultFilter[0]);
			} else {
				//set default credit card to false
				this.setIsCreditCardDefault(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async fetchShipmentLastPayment() {
		try {
			const { data } = await client.query({
				query: shipmentLastPaymentQuery,
			});

			const { paid_type } = data.shipmentLastPayment;

			return paid_type;
		} catch (error) {
			console.log(error);
		}
	}

	async getDefaultPaymentType(paymentType) {
		try {
			if (paymentType) {
				//set paymentType
				if (paymentType !== 'creditCard') {
					if (paymentType === 'COD') {
						this.setPaymentTypeCOD();
					}

					if (paymentType === 'Invoice') {
						this.setPaymentTypeInvoice();
					}

					this.setIsCreditCardDefault(false);
				}
			} else {
				//ถ้าไม่มีการส่ง paymentType มา ให้ใช้ค่า default ของบัญชีผู้ใช้
				const shipmentLastPayment = await this.fetchShipmentLastPayment();
				if (shipmentLastPayment === 1) {
					this.getDefaultCreditCard();
					return;
				} else if (shipmentLastPayment === 2) {
					this.setPaymentTypeCOD();
					return;
				} else if (shipmentLastPayment === 3) {
					this.setPaymentTypeInvoice();
				} else {
					const { data: accountInfoListData } = await client.query({
						query: accountInfoListQuery,
					});

					if (
						//business default invoice
						accountInfoListData.accountInfoList.account_business !== null &&
						accountInfoListData.accountInfoList.account_business
							.business_status === 2
					) {
						this.setPaymentTypeInvoice();
						this.setIsCreditCardDefault(false);
					} else {
						//default creditCard
						this.getDefaultCreditCard();
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	setIsCreditCardDefault(isDefault) {
		const creditCardDefaultData = client.cache.readQuery({
			query: creditCardDefaultQueryClient,
		});

		client.cache.writeQuery({
			query: creditCardDefaultQueryClient,
			data: {
				creditCardDefault: {
					...creditCardDefaultData.creditCardDefault,
					isDefault: isDefault,
				},
			},
		});
	}

	setPaymentTypeCOD() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					paymentType: 'COD',
				},
			},
		});
	}

	setPaymentTypeInvoice() {
		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					paymentType: 'Invoice',
				},
			},
		});
	}

	setCreditCard(creditCard) {
		const {
			id,
			bank,
			last_digits,
			brand,
			expiration_month,
			expiration_year,
			name,
		} = creditCard;

		const instancePriceData = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					paymentType: 'creditCard',
					creditCard: {
						id,
						bank,
						last_digits,
						brand,
						expiration_month,
						expiration_year,
						name,
						__typename: 'CreditCard',
					},
				},
			},
		});
	}

	async getShareRoute(param) {
		const shareRouteListData = await client.query({
			query: shareRouteListQuery,
			variables: {
				id: param,
			},
		});

		if (shareRouteListData.data.shareRouteList.length === 0) {
			return;
		}

		const objMsg = shareRouteListData.data.shareRouteList[0].obj_msg;
		const objMsgParse = JSON.parse(objMsg);

		//ดึงข้อมูล name ให้รองรับ 2 ภาษา
		const { data: detailShareRouteData } = await client.query({
			query: detailShareRouteQuery,
			variables: {
				shipment_type: objMsgParse.shipmentType,
			},
		});

		const truck = detailShareRouteData.settingTruckList.filter(
			x => x._id === objMsgParse.setting_truck_id
		);
		const truckType = truck[0].setting_truck_type.filter(
			y => y._id === objMsgParse.setting_truck_type_id
		)[0];
		const additionalList = detailShareRouteData.additionalList;
		const accessoryList = detailShareRouteData.accessoryList;

		const setting_truck_name =
			typeof objMsgParse.setting_truck_name === 'string'
				? {
						th: truck[0].setting_truck_name.th,
						en: truck[0].setting_truck_name.en,
						__typename: 'SettingTruckName',
				  }
				: objMsgParse.setting_truck_name;

		const setting_truck_type_name =
			typeof objMsgParse.setting_truck_type_name === 'string'
				? {
						th: truckType.truck_type_name.th,
						en: truckType.truck_type_name.en,
						__typename: 'SettingTruckTypeName',
				  }
				: objMsgParse.setting_truck_type_name;

		const setting_truck_type_size =
			typeof objMsgParse.setting_truck_type_size === 'string'
				? {
						th: truckType.truck_type_size_lang.th,
						en: truckType.truck_type_size_lang.en,
						__typename: 'SettingTruckTypeSize',
				  }
				: objMsgParse.setting_truck_type_size;

		const accessory = objMsgParse.accessory.map(item => {
			const filterAccessoryList = accessoryList.filter(
				list => list._id === item._id
			)[0];
			const name =
				typeof item.name === 'string'
					? {
							th: filterAccessoryList.name_lang.th,
							en: filterAccessoryList.name_lang.en,
							__typename: 'AccessoryName',
					  }
					: item.name;

			return {
				...item,
				name,
			};
		});

		const additional = objMsgParse.additional.map(item => {
			const filterAdditionalList = additionalList.filter(
				list => list._id === item._id
			)[0];
			const name =
				typeof item.name === 'string'
					? {
							th: filterAdditionalList.name_lang.th,
							en: filterAdditionalList.name_lang.en,
							__typename: 'AdditionalName',
					  }
					: item.name;

			const truck_type_size =
				typeof item.truck_type_size === 'string'
					? {
							th: filterAdditionalList.truck_type_size_lang.th,
							en: filterAdditionalList.truck_type_size_lang.en,
							__typename: 'AdditionalTruckTypeSize',
					  }
					: item.truck_type_size;

			return {
				...item,
				name,
				truck_type_size,
			};
		});

		const setFormatData = {
			...objMsgParse,
			setting_truck_name,
			setting_truck_type_name,
			setting_truck_type_size,
			accessory,
			additional,
		};

		try {
			const data = client.cache.readQuery({
				query: instancePriceQueryClient,
			});

			client.cache.writeQuery({
				query: instancePriceQueryClient,
				data: {
					instancePrice: {
						...data.instancePrice,
						...setFormatData,
					},
				},
			});
		} catch (error) {
			console.log(error);
		}
	}

	async getPromotionCode(param) {
		const data = client.cache.readQuery({
			query: instancePriceQueryClient,
		});

		client.cache.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...data.instancePrice,
					promotion_name: param,
				},
			},
		});

		if (param === 'GTXBIKE') {
			this.setState({ isOpenModalPromotionBike: true });
			const data = client.cache.readQuery({
				query: instancePriceQueryClient,
			});

			client.cache.writeQuery({
				query: instancePriceQueryClient,
				data: {
					instancePrice: {
						...data.instancePrice,
						setting_truck_id: '5be5057787415b16d8cf48f6',
						setting_truck_name: {
							th: '4 ล้อ',
							en: '4 Wheels',
							__typename: 'SettingTruckName',
						},
						setting_truck_type_id: '5bed286e2a9d8d18e851d7be',
						setting_truck_type_name: {
							th: 'ขนส่งมอเตอร์ไซค์ขนาดใหญ่',
							en: 'ขนส่งมอเตอร์ไซค์ขนาดใหญ่',
							__typename: 'SettingTruckTypeName',
						},
						setting_truck_type_size: {
							th: 'น้ำหนักรถไม่เกิน 300 กิโลกรัม/คัน',
							en: 'น้ำหนักรถไม่เกิน 300 กิโลกรัม/คัน',
							__typename: 'SettingTruckTypeSize',
						},
						setting_truck_type_pic:
							'upload/setting_truck/PICKUP_CABINET-BIGBIKE.png',
						shipmentType: 1,
						additional: [
							{
								_id: '5d5cf2f566085815b00c27b6',
								name: {
									th: 'ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่',
									en: 'ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่',
									__typename: 'AdditionalName',
								},
								price: 150,
								qty: 1,
								truck_type_size: {
									th: null,
									en: null,
									__typename: 'AdditionalTruckTypeSize',
								},
								address: null,
								__typename: 'Additional',
							},
						],
					},
				},
			});
		}
	}

	selectFlashDeals(flashDealData) {
		const {
			_id,
			name_lang,
			setting_truck_type_id,
			setting_truck_id,
			setting_truck_name,
			truck_type_name,
			truck_type_pic,
			location_start_lang,
			location_end_lang,
			exp,
			pickup_start,
			pickup_end,
			price,
			price_sale,
			position_start,
			position_end,
			truck_type_size_lang,
		} = flashDealData;

		const flashDeals = {
			id: _id,
			name: {
				en: name_lang.en,
				th: name_lang.th,
				__typename: 'FlashDealsName',
			},
			settingTruckId: setting_truck_type_id,
			settingTruckName: {
				en: setting_truck_name.en,
				th: setting_truck_name.th,
				__typename: 'FlashDealsSettingTruckName',
			},
			settingTruckTypeId: setting_truck_type_id,
			truckTypeName: {
				en: truck_type_name.en,
				th: truck_type_name.th,
				__typename: 'FlashDealsTruckTypeName',
			},
			truckTypePic: truck_type_pic,
			locationStart: {
				en: location_start_lang.en,
				th: location_start_lang.th,
				__typename: 'FlashDealsLocationStart',
			},
			locationEnd: {
				en: location_end_lang.en,
				th: location_end_lang.th,
				__typename: 'FlashDealsLocationEnd',
			},
			exp: exp,
			pickupStart: pickup_start,
			pickupEnd: pickup_end,
			price: price,
			priceSale: price_sale,
			__typename: 'FlashDeals',
		};

		const shipmentAddress = [
			{
				mode: 'pickup',
				address: location_start_lang.th,
				latitude: position_start.lat,
				longitude: position_start.lng,
				contactName: '',
				phoneNumber: '',
				remark: '',
				paidByCOD: null,
				province: location_start_lang.th,
				pickupDate: '',
				isSaveContact: false,
				mainAddress: '',
				secondaryAddress: '',
				__typename: 'ShipmentAddress',
			},
			{
				mode: 'delivery',
				address: location_end_lang.th,
				latitude: position_end.lat,
				longitude: position_end.lng,
				contactName: '',
				phoneNumber: '',
				remark: '',
				paidByCOD: null,
				province: location_end_lang.th,
				pickupDate: '',
				isSaveContact: false,
				mainAddress: '',
				secondaryAddress: '',
				__typename: 'ShipmentAddress',
			},
		];

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePrice,
					flashDeals: flashDeals,
					shipmentAddress: shipmentAddress,
					setting_truck_id: setting_truck_id,
					setting_truck_name: {
						th: setting_truck_name.th,
						en: setting_truck_name.en,
						__typename: 'SettingTruckName',
					},
					setting_truck_type_id: setting_truck_type_id,
					setting_truck_type_name: {
						th: truck_type_name.th,
						en: truck_type_name.en,
						__typename: 'SettingTruckTypeName',
					},
					setting_truck_type_size: {
						th: truck_type_size_lang.th,
						en: truck_type_size_lang.en,
						__typename: 'SettingTruckTypeSize',
					},
					setting_truck_type_pic: truck_type_pic,
				},
			},
		});

		client.writeData({
			data: {
				instancePriceStep: {
					...instancePriceStep,
				},
			},
		});

		this.setState({
			isOpenPopupOverSearchLocation: true,
		});
	}

	setDefaultDataWhenSelectTruck() {
		const data = client.readQuery({
			query: instancePriceQueryClient,
		});

		const additional = [
			{
				_id: '5d5cf2f566085815b00c27b6',
				name: {
					th: 'ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่',
					en: 'ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่',
					__typename: 'AdditionalName',
				},
				price: 150,
				qty: 1,
				truck_type_size: {
					th: null,
					en: null,
					__typename: 'AdditionalTruckTypeSize',
				},
				address: null,
				__typename: 'Additional',
			},
		];

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...data.instancePrice,
					additional:
						data.instancePrice.setting_truck_type_id ===
						'5bed286e2a9d8d18e851d7be'
							? additional
							: [],
					favoriteDriver: [],
				},
			},
		});
	}

	setPromotionName(promotionName) {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					promotion_name: promotionName,
				},
			},
		});
	}

	getCookie = cookieName => {
		const { cookies } = this.props;
		return cookies.get(cookieName);
	};

	render() {
		const menu = [
			{
				active: true,
				link: '/',
				icon: 'fal fa-search',
				title: this.props.t('common:instancePrice.checkTransportationPrice'),
			},
			{
				active: false,
				link: '/shipment',
				icon: 'fal fa-box',
				title: this.props.t('common:instancePrice.shipment'),
			},
			{
				active: false,
				link: '/favorite-driver',
				icon: 'fal fa-user',
				title: this.props.t('common:instancePrice.favoriteDriver'),
			},
			...(this.state.isParcelCustomer
				? [
						{
							active: false,
							link: '/product-list',
							icon: 'fal fa-truck',
							title: 'งานขนส่งรายชิ้น',
						},
				  ]
				: []),
		];

		let bannerList = [];
		if (
			this.checkBetweenDateTime('2020-03-01 00:00:00', '2021-03-31 23:59:59')
		) {
			bannerList = [
				//GTX BU
				{
					src:
						'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-BU-Register-250x110.png',
					link: '/giztixbusiness',
				},
				//ฟีเจอร์(ไม่)ลับ ที่ช่วยให้การขนส่งสินค้าเป็นเรื่องง่าย
				{
					src:
						'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/secret_feature_250x110.png',
					link: 'https://bit.ly/33wkgAe',
				},
				// SKZIC
				{
					src:
						'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/18-mar-2020/Banner-ZIC-Check-Price-250x110.jpg',
					link: 'https://www.skzicthailand.com/home/product_zic',
				},
				// {
				// 	src:
				// 		'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-Promotion-Firstbook-discount-10_250_110.png',
				// 	link: '/',
				// },
				// {
				// 	src:
				// 		'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/8feature250.png',
				// 	link: 'http://bit.ly/3c9f1db',
				// },
				// {
				// 	src:
				// 		'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/flashsale-gtxgbu.jpg',
				// 	link: 'https://bit.ly/2Ck3Tx9',
				// },
			];
		}

		const contentPolicy = (
			<PopupPolicy>
				<div className="policy-title">
					{this.props.t('common:instancePrice.popupPolicy.title')}
				</div>
				<div className="policy-detail">
					{this.props
						.t('common:instancePrice.popupPolicy.detail')
						.split('\n')
						.map(item => (
							<div>{item}</div>
						))}
				</div>
				<div className="policy-footer">
					<button onClick={() => this.setState({ isOpenPopupPolicy: false })}>
						{this.props.t('common:instancePrice.popupPolicy.btnClose')}
					</button>
				</div>
			</PopupPolicy>
		);

		return (
			<InstancePriceContextProvider>
				<Query query={instancePriceQueryClient}>
					{({ data }) => {
						const { shipmentAddress, shipmentType } = data.instancePrice;
						const checkShipmentAddress = shipmentAddress.filter(
							item => item.latitude !== 0 && item.longitude !== 0
						);

						const current = moment();
						const isDisplay = moment(current).isBefore(
							moment('2020-01-31 23:59:59', 'YYYY-MM-DD HH:mm:ss')
						);

						if (
							((shipmentType === 1 && checkShipmentAddress.length >= 2) ||
								(shipmentType !== 1 && checkShipmentAddress.length >= 3)) &&
							isDisplay
						) {
							return <GiztixSurvey />;
						}

						return null;
					}}
				</Query>

				<InstancePriceContext.Consumer>
					{context => {
						return (
							<Container>
								<Helmet>
									<title>
										New Shipment | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง
										รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า
										ส่งสินค้าด่วน
									</title>
									<meta
										name="description"
										content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย"
									/>
								</Helmet>
								<HeaderNav full />
								<ContentContainer>
									<MediaQuery minWidth={768}>
										<SubHeaderNav menu={menu} />
										{/* <Menu> */}
									</MediaQuery>

									<ModalConfirm
										show={this.state.showOmiseError}
										onPressButtonConfirm={() =>
											this.setState({ showOmiseError: false })
										}
										labelButtonConfirm={this.props.t(
											'common:instancePrice.modalPaymentError.buttonConfirm'
										)}
										titleHeader={this.props.t(
											'common:instancePrice.modalPaymentError.title'
										)}
										styleColorButtonConfirm="primary"
										onHide={() => this.setState({ showOmiseError: false })}
									>
										<div style={{ lineHeight: 1 }}>
											{this.props.t(
												'common:instancePrice.modalPaymentError.description'
											)}
										</div>
									</ModalConfirm>

									<Query query={instancePriceStepQueryClient}>
										{({ data }) => {
											const {
												createShipment,
												searchLocation,
												confirmShipment,
												selectTruck,
												paymentChanel,
												paymentCOD,
												additional,
												promotion,
												priceResult,
												selectDateTime,
												paymentCredit,
												paymentCreditList,
												paymentCreditEdit,
												contact,
												selectDateTimeContact,
												selectRemark,
												notificationMultiDrop,
											} = data.instancePriceStep;

											return (
												<LayoutContent>
													<div className="with-sidebar">
														<div className="sidebar">
															<SidebarContainer
																height={this.state.heightSidebar}
																data-simplebar
															>
																<SideBar>
																	{!!createShipment && <CreateShipment />}
																	{!!searchLocation && <SearchLocation />}
																	{!!confirmShipment && <ConfirmShipment />}
																</SideBar>
															</SidebarContainer>
														</div>

														<div className="not-sidebar">
															<NotSidebar
																height={this.state.heightSidebar}
																isHasFlashDeals={
																	(!!this.state.flashDealList.length ||
																		!!bannerList.length) &&
																	!!createShipment
																}
																isShowFlashDeals={this.state.isFlashDeals}
															>
																<MediaQuery minWidth={768}>
																	<Query query={accountInfoListQuery}>
																		{({
																			data: accountInfoData,
																			loading: accountInfoLoading,
																			error: accountInfoError,
																		}) => {
																			if (
																				accountInfoLoading ||
																				accountInfoError
																			) {
																				return null;
																			}
																			if (accountInfoData) {
																				if (
																					accountInfoData.accountInfoList &&
																					accountInfoData.accountInfoList
																						.account_business !== null &&
																					accountInfoData.accountInfoList
																						.account_business.business_status ==
																						2
																				) {
																					// return <DistanceChallenge2/>
																					return null;
																				}
																			}
																			return null;
																		}}
																	</Query>
																	{(this.state.flashDealList.length > 0 ||
																		bannerList.length > 0) && (
																		<FlashDealsContainer
																			style={{
																				display:
																					searchLocation || confirmShipment
																						? 'none'
																						: '',
																			}}
																		>
																			<div className="header">
																				<div
																					className="textBanner"
																					onClick={() =>
																						this.setState(prevState => ({
																							isFlashDeals: !prevState.isFlashDeals,
																						}))
																					}
																				>
																					<img
																						style={{ display: 'none' }}
																						src={require('src/assets/images/icon-flashdeals.png')}
																						alt="icon flashdeals"
																					/>
																					ข่าวสารและกิจกรรม
																					<i
																						class={`fas  ${
																							this.state.isFlashDeals
																								? 'fa-chevron-down'
																								: 'fa-chevron-up'
																						}`}
																					></i>
																				</div>
																				<div
																					style={{
																						flex: 1,
																						overflow: 'hidden',
																					}}
																				>
																					{//สำหรับใส่ข้อมูลเพิ่มเติม effect เปิดปิด flash deal
																					this.state.isFlashDeals ? null : null}
																				</div>
																				{this.state.flashDealList.length >
																					0 && (
																					<a href="/flash-deals">ดูทั้งหมด</a>
																				)}
																			</div>
																			<div
																				className="list-item"
																				style={{
																					height: this.state.isFlashDeals
																						? 'unset'
																						: '0',
																					opacity: this.state.isFlashDeals
																						? '1'
																						: '0',
																				}}
																			>
																				<SimpleBar>
																					{this.state.flashDealList
																						.slice(0, 2)
																						.map((item, index, arr) => (
																							<CardFlashDealsSmallItemStyle
																								key={item._id}
																								name={
																									item.name_lang[
																										this.props.t('common:lang')
																									]
																								}
																								provinceName={`${
																									item.location_start_lang[
																										this.props.t('common:lang')
																									]
																								} - ${
																									item.location_end_lang[
																										this.props.t('common:lang')
																									]
																								}`}
																								price={item.price}
																								priceSale={item.price_sale}
																								exp={item.exp}
																								truckTypeName={`${
																									this.props.t(
																										'common:lang'
																									) === 'th'
																										? 'รถ '
																										: ''
																								}${
																									item.setting_truck_name[
																										this.props.t('common:lang')
																									]
																								} - ${
																									item.truck_type_name[
																										this.props.t('common:lang')
																									]
																								}`}
																								onSelect={() => {
																									this.setState({
																										isOpenModalFlashDeals: true,
																										flashDealId: item._id,
																									});
																								}}
																							/>
																						))}

																					{bannerList.map((item, index) => (
																						<CardBannerSponsorStyle
																							key={index}
																							src={item.src}
																							href={item.link}
																							target="_blank"
																						/>
																					))}

																					{this.state.flashDealList
																						.slice(2)
																						.map((item, index, arr) => (
																							<CardFlashDealsSmallItemStyle
																								key={item._id}
																								name={
																									item.name_lang[
																										this.props.t('common:lang')
																									]
																								}
																								provinceName={`${
																									item.location_start_lang[
																										this.props.t('common:lang')
																									]
																								} - ${
																									item.location_end_lang[
																										this.props.t('common:lang')
																									]
																								}`}
																								price={item.price}
																								priceSale={item.price_sale}
																								exp={item.exp}
																								truckTypeName={`${
																									this.props.t(
																										'common:lang'
																									) === 'th'
																										? 'รถ '
																										: ''
																								}${
																									item.setting_truck_name[
																										this.props.t('common:lang')
																									]
																								} - ${
																									item.truck_type_name[
																										this.props.t('common:lang')
																									]
																								}`}
																								onSelect={() => {
																									this.setState({
																										isOpenModalFlashDeals: true,
																										flashDealId: item._id,
																									});
																								}}
																							/>
																						))}
																				</SimpleBar>
																			</div>
																		</FlashDealsContainer>
																	)}
																</MediaQuery>
																<TopMapContainer>
																	{/* <Popover getPopupContainer={triggerNode => triggerNode.parentNode} visible={this.state.isOpenPopupPolicy} placement={this.state.widthDevice >= 850 ? 'rightTop' : 'bottomLeft'} content={contentPolicy} trigger="click">
																				<ButtonPolicy shape="circle" onClick={() => this.setState({ isOpenPopupPolicy: !this.state.isOpenPopupPolicy })}>
																					<i className="far fa-shield-alt"></i>
																				</ButtonPolicy>
																			</Popover> */}
																	<React.Fragment>
																		<MediaQuery maxWidth={767}>
																			<FlashDealsButton href="/flash-deals">
																				<img
																					src={require('src/assets/images/icon-flashdeals.png')}
																					alt="flash deals"
																				/>
																			</FlashDealsButton>
																		</MediaQuery>
																	</React.Fragment>

																	<Popover
																		placement="rightTop"
																		content={
																			<PopupPolicy>
																				<div className="policy-title">
																					กรุณาระบุจุดรับ-ส่งสินค้า
																				</div>
																				<div className="policy-detail">
																					Flash Deal
																					เป็นอัตราค่ารถขนส่งราคาพิเศษ
																					โดยคิดค่าบริการแบบเหมาจังหวัด
																					จุดรับ-ส่งสินค้าที่แสดงเป็นค่าเริ่มต้น
																					<br />
																					**
																					กรุณาเปลี่ยนเป็นจุดรับ-ส่งสินค้าของคุณ
																					เพื่อให้รถขนส่งทราบรายละเอียดสถานที่ที่ถูกต้อง
																				</div>
																				<div className="policy-footer">
																					<button
																						onClick={() =>
																							this.setState({
																								isOpenPopupOverSearchLocation: false,
																							})
																						}
																					>
																						ปิด
																					</button>
																				</div>
																			</PopupPolicy>
																		}
																		trigger="click"
																		visible={
																			this.state.isOpenPopupOverSearchLocation
																		}
																		onVisibleChange={visible => {
																			this.setState({
																				isOpenPopupOverSearchLocation: visible,
																			});
																		}}
																		getPopupContainer={triggerNode =>
																			triggerNode.parentNode
																		}
																	>
																		<div
																			style={{
																				width: 5,
																				height: 20,
																				marginTop: 20,
																			}}
																		/>
																	</Popover>
																</TopMapContainer>

																<MediaQuery minWidth={768}>
																	{!!additional && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<Additional data-simplebar />
																		</TopContainer>
																	)}

																	{!!paymentChanel && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<PaymentChanel />
																		</TopContainer>
																	)}

																	{!!paymentCOD && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<PaymentCOD />
																		</TopContainer>
																	)}

																	{!!paymentCreditList && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<PaymentCreditList />
																		</TopContainer>
																	)}

																	{!!paymentCredit && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<PaymentCredit />
																		</TopContainer>
																	)}

																	{!!paymentCreditEdit && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<PaymentCreditEdit />
																		</TopContainer>
																	)}

																	{!!selectTruck && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '340px' }}
																			data-simplebar
																		>
																			<SelectTruck />
																		</TopContainer>
																	)}

																	{!!promotion && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<Promotion />
																		</TopContainer>
																	)}

																	{!!priceResult && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<PriceResult />
																		</TopContainer>
																	)}

																	{!!notificationMultiDrop && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '360px' }}
																			data-simplebar
																		>
																			<NotificationMultiDrop />
																		</TopContainer>
																	)}

																	{!!selectDateTime && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<SelectDateTime />
																		</TopContainer>
																	)}

																	{!!contact && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<Contact />
																		</TopContainer>
																	)}

																	{!!selectDateTimeContact && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<SelectDateTimeContact />
																		</TopContainer>
																	)}

																	{!!selectRemark && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<SelectRemark />
																		</TopContainer>
																	)}

																	{context.state.selectDriver && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<SelectDriver />
																		</TopContainer>
																	)}

																	{context.state.selectContact && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<SelectContact />
																		</TopContainer>
																	)}

																	{context.state.selectContactSaved && (
																		<TopContainer
																			height={this.state.heightSidebar}
																			style={{ width: '320px' }}
																			data-simplebar
																		>
																			<SelectContactSaved />
																		</TopContainer>
																	)}
																</MediaQuery>
																<GoogleMaps />
															</NotSidebar>
														</div>
													</div>

													<MediaQuery maxWidth={767}>
														<ReactModal
															isOpen={selectTruck}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectTruck />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={additional}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<Additional />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={paymentChanel}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<PaymentChanel />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={paymentCOD}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<PaymentCOD />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={paymentCreditList}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<PaymentCreditList />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={paymentCredit}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<PaymentCredit />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={paymentCreditEdit}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<PaymentCreditEdit />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={promotion}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<Promotion />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={priceResult}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<PriceResult />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={notificationMultiDrop}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<NotificationMultiDrop />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={selectDateTime}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectDateTime />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={contact}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<Contact />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={selectDateTimeContact}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectDateTimeContact />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={selectRemark}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectRemark />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={context.state.selectDriver}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectDriver />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={context.state.selectContact}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectContact />
															</PopupContainer>
														</ReactModal>

														<ReactModal
															isOpen={context.state.selectContactSaved}
															style={customStylesReactModalSidebar}
															className={stylesReactModalSidebar.modalPromotion}
														>
															<PopupContainer height={this.state.heightSidebar}>
																<SelectContactSaved />
															</PopupContainer>
														</ReactModal>
													</MediaQuery>
												</LayoutContent>
											);
										}}
									</Query>

									<ReactModal
										isOpen={this.state.isOpenModalPromotion}
										onRequestClose={() =>
											this.setState({ isOpenModalPromotion: false })
										}
										style={customStylesReactModal}
									>
										<PromotionContainer>
											<div
												className="close-modal"
												onClick={() =>
													this.setState({ isOpenModalPromotion: false })
												}
											>
												<i className="fal fa-times" />
											</div>
											<img
												style={{ cursor: 'pointer' }}
												onClick={() => {
													window.open('/giztixbusiness', '_blank');
												}}
												src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promote/add-promotion-web.png"
												alt="promotion"
											/>
										</PromotionContainer>
									</ReactModal>
									{
										<ReactModal
											isOpen={this.state.isOpenModalPromotionFirstTruck}
											onRequestClose={() =>
												this.setState({ isOpenModalPromotionFirstTruck: false })
											}
											style={customStylesReactModal}
											ariaHideApp={false}
										>
											<PromotionContainer>
												<div
													className="close-modal"
													onClick={() =>
														this.setState({
															isOpenModalPromotionFirstTruck: false,
														})
													}
												>
													<i className="fal fa-times" />
												</div>

												<Slider {...settingsSliderPromotion}>
													{this.checkBetweenDateTime(
														'2022-06-01 00:00:00',
														'2022-07-31 23:59:59'
													) && (
														<div className="image-slider">
															<a
																target="_blank"
																href={'https://app.giztix.com/giztixbusiness'}
															>
																<img
																	style={{ width: '100%' }}
																	src={
																		'https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/GTX3KB-invitecode.png'
																	}
																	alt="GTX3KB"
																/>
															</a>
														</div>
													)}

													<div className="image-slider">
														<a
															target="_blank"
															href={'https://app.giztix.com/giztixbusiness'}
														>
															<img
																src={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/promo_first_th.png`}
																alt="promotion"
															/>
														</a>
													</div>
												</Slider>

												<img
													style={{ marginTop: 20, cursor: 'pointer' }}
													onClick={() => {
														window.open('/giztixbusiness', '_blank');
													}}
													src={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/popup-register-business.png`}
													alt="promotion"
												/>
												<CheckBoxHideModal name="hide_event_promotion_first_truck" />
											</PromotionContainer>
										</ReactModal>
									}
									<ReactModal
										isOpen={this.state.isOpenModalGtxBU}
										onRequestClose={() =>
											this.setState({ isOpenModalGtxBU: false })
										}
										style={customStylesReactModal}
										ariaHideApp={false}
									>
										{}
										<PromotionContainer>
											<div
												className="close-modal"
												onClick={() =>
													this.setState({
														isOpenModalGtxBU: false,
													})
												}
											>
												<i className="fal fa-times" />
											</div>
											<img
												style={{ marginTop: 50, cursor: 'pointer' }}
												src={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/pop-gtxgbu.jpg`}
												onClick={() => {
													window.open('https://bit.ly/2Ck3Tx9', '_blank');
												}}
												alt="promotion"
											/>
											<img
												style={{ marginTop: 20, cursor: 'pointer' }}
												onClick={() => {
													window.open('/giztixbusiness', '_blank');
												}}
												src={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/popup-register-business.png`}
												alt="promotion"
											/>
											<CheckBoxHideModal name="hide_event_promotion_gtx_bu" />
										</PromotionContainer>
									</ReactModal>

									<ReactModal
										isOpen={this.state.isOpenModalPromotionEvent1}
										onRequestClose={() =>
											this.setState({ isOpenModalPromotionEvent1: false })
										}
										style={customStylesReactModal}
										ariaHideApp={false}
									>
										<PromotionContainer style={{ maxWidth: 600 }}>
											<div
												className="close-modal"
												onClick={() =>
													this.setState({ isOpenModalPromotionEvent1: false })
												}
											>
												<i className="fal fa-times" />
											</div>
											<img
												style={{ marginTop: 50 }}
												src={`https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/GTXRNY.jpg`}
												alt="โปรโมชั่นหน้าฝน"
											/>
											<CheckBoxHideModal name="hide_event_promotion_1" />
										</PromotionContainer>
									</ReactModal>

									<ReactModal
										isOpen={this.state.isOpenModalPromotionEvent2}
										onRequestClose={() =>
											this.setState({ isOpenModalPromotionEvent2: false })
										}
										style={customStylesReactModal}
										ariaHideApp={false}
									>
										<PromotionContainer style={{ maxWidth: 600 }}>
											<div
												className="close-modal"
												onClick={() =>
													this.setState({ isOpenModalPromotionEvent2: false })
												}
											>
												<i className="fal fa-times" />
											</div>
											<a
												target="_blank"
												href={
													'https://blog.giztix.com/6-มาตรการสำคัญ-เพื่อความปลอดภัยจาก-covid-19/'
												}
											>
												<img
													style={{ marginTop: 50 }}
													src={require(`src/assets/images/Pop-Up-covid.png`)}
													alt="popup_event"
												/>
											</a>
											<CheckBoxHideModal name="hide_event_promotion_2" />
										</PromotionContainer>
									</ReactModal>

									<ReactModal
										isOpen={this.state.isOpenModalPromotionBike}
										onRequestClose={() =>
											this.setState({ isOpenModalPromotionBike: false })
										}
										style={customStylesReactModalBike}
										className={stylesReactModal.modalPromotion}
									>
										<PromotionContainerBike>
											<div
												onClick={() =>
													this.setState({ isOpenModalPromotionBike: false })
												}
												className="btnCloseModal"
											>
												<i className="fal fa-times" />
											</div>
											<div style={{ lineHeight: 1.2 }}>
												<div
													style={{
														fontWeight: 'bold',
														fontSize: 30,
														maxWidth: '96%',
													}}
												>
													โปรโมชั่นพิเศษ! จองรถขนส่ง Bigbike กับ GIZTIX วันนี้
													รับส่วนลดไปเลย 200 บาท!
												</div>
												<img
													style={{ margin: '20px 0px' }}
													src={`https://s3-ap-southeast-1.amazonaws.com/media.kaikin.co/upload/promotion/GTXBIKE.png`}
													alt="promotion"
												/>
												<div style={{ fontWeight: 'bold' }}>
													เงื่อนไขการใช้บริการ
												</div>
												<div>
													1. จำกัดน้ำหนักรถไม่เกิน 300 กิโลกรัม ต่อเที่ยว สูงสุด
													2 คัน
													<br />
													2.
													ในการขนส่งมอเตอร์ไซค์ลูกค้าจำเป็นต้องมีสำเนาเอกสารรถทุกคัน
													และมอบให้กับพนักงานขนส่ง ณ จุดรับรถเท่านั้น
													<br />
													3. ขนส่งเฉพาะภายในประเทศเท่านั้น
													<br />
													4. ค่าขนส่งดังกล่าวรวมค่าแรงและอุปกรณ์เสริมแล้ว
													<br />
													5. ประกันการขนส่งสูงสุด 20,000 บาท*
												</div>
												<br />
												<div style={{ fontWeight: 'bold' }}>
													เอกสารประกอบการขนส่ง
												</div>
												<div>
													1. สำเนาเล่มทะเบียนรถมอเตอร์ไซค์ (‪‎เล่มสีเขียว)‬
													<br />
													2. สำเนาบัตรประชาชนเจ้าของรถ
													<br />
													3. สำเนาบัตรประชนคนรับรถ (ถ้ามี)
												</div>
												<br />
												<div style={{ fontWeight: 'bold' }}>
													การปฏิเสธความรับผิดชอบ‬
												</div>
												<div>
													1. เราขอปฏิเสธความรับผิดชอบทุกกรณีหาก รถมอเตอร์ไซค์
													หรือ รถบิ๊กไบค์ ที่ท่านนำมาส่งกับเรา เป็นรถที่ได้
													มาโดยผิดกฏหมาย ไม่ว่าจะเป็นรถขโมยมา รถสวมทะเบียนปลอม
													รถหนีภาษี และ อื่นๆ
													<br />
													2. ในวันส่งมอบ รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์
													ลูกค้าต้องตรวจสอบความเรียบร้อยของตัว รถมอเตอร์ไซค์
													หรือ รถบิ๊กไบค์
													หากเกิดความเสียหายให้แจ้งกับพนักงานขนส่งทันที
													ก่อนเซ็นรับรถ
													"หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขนส่ง ของ
													Giztix ทราบโดยทันที Giztix
													ขอปฏิเสธความรับผิดชอบทุกกรณี และ
													ลูกค้าจะไม่สามารถเรียกร้องสิทธิ ค่าชดเชยใดๆทั้งสิ้น"
													<br />
													3. หากตกลงใช้บริการ ส่งรถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์
													กับ Giztix ถือว่าท่านได้อ่านเงื่อนไขต่างๆ
													ครบถ้วนทุกอย่างแล้ว และ
													ลูกค้าจะเรียกร้องสิทธิภายหลังไม่ได้
												</div>
											</div>

											<div
												style={{
													padding: 20,
													margin: '10px 0px',
													backgroundColor: '#F9F9F9',
													borderRadius: 6,
													lineHeight: 1,
													textAlign: 'center',
												}}
											>
												<div style={{ color: '#D90101' }}>**ข้อควรระวัง**</div>
												<div>
													"หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขับรถ
													หรือติดต่อ Giztix โดยทันที ก่อนเซ็นรับมอบ
													<br />
													เราจะถือว่าลูกค้าได้รับรถในสภาพสมบูรณ์เรียบร้อยตามสภาพรถเดิม
													<br />
													และลูกค้าจะไม่สามารถเรียกร้องสิทธิค่าชดเชยภายหลังได้”
												</div>
											</div>
										</PromotionContainerBike>
									</ReactModal>

									{this.state.flashDealId && (
										<Query
											query={flashDealsListQuery}
											fetchPolicy="cache-and-network"
											variables={{
												id: this.state.flashDealId,
											}}
										>
											{({ data, loading, error }) => {
												if (loading || error)
													return (
														<ModalCardFlashDeals
															isLoading={true}
															isOpen={this.state.isOpenModalFlashDeals}
														/>
													);

												return (
													<ModalCardFlashDeals
														isLoading={false}
														isOpen={this.state.isOpenModalFlashDeals}
														onClose={() =>
															this.setState({
																isOpenModalFlashDeals: false,
															})
														}
														onRequestClose={() =>
															this.setState({
																isOpenModalFlashDeals: false,
															})
														}
														name={
															data.flashDealsList.flash_deals[0].name_lang[
																this.props.t('common:lang')
															]
														}
														provinceName={`${
															data.flashDealsList.flash_deals[0]
																.location_start_lang[
																this.props.t('common:lang')
															]
														} - ${
															data.flashDealsList.flash_deals[0]
																.location_end_lang[this.props.t('common:lang')]
														}`}
														price={data.flashDealsList.flash_deals[0].price}
														priceSale={
															data.flashDealsList.flash_deals[0].price_sale
														}
														exp={data.flashDealsList.flash_deals[0].exp}
														truckTypeName={`${
															this.props.t('common:lang') === 'th' ? 'รถ ' : ''
														}${
															data.flashDealsList.flash_deals[0]
																.setting_truck_name[this.props.t('common:lang')]
														} - ${
															data.flashDealsList.flash_deals[0]
																.truck_type_name[this.props.t('common:lang')]
														}`}
														truckTypeImage={`${s3}/${data.flashDealsList.flash_deals[0].truck_type_pic}`}
														pickupDate={`${moment(
															data.flashDealsList.flash_deals[0].pickup_start
														).format('DD/MM/YYYY HH:mm')} - ${moment(
															data.flashDealsList.flash_deals[0].pickup_end
														).format('DD/MM/YYYY HH:mm')}`}
														onSelect={() => {
															this.selectFlashDeals(
																data.flashDealsList.flash_deals[0]
															);
															this.setDefaultDataWhenSelectTruck();
															this.setState({
																isOpenModalFlashDeals: false,
															});
														}}
													/>
												);
											}}
										</Query>
									)}
								</ContentContainer>
							</Container>
						);
					}}
				</InstancePriceContext.Consumer>
			</InstancePriceContextProvider>
		);
	}
}

export default withNamespaces()(withCookies(InstancePrice));
