import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;

  & .droppoint {
    display: flex;
    flex-direction: row;
    position: relative;    
    margin-bottom: 20px;
  }

  & .pickup {
    min-height: 30px;
    margin-bottom: 20px;
  }

  & .pin {
    font-size: 18px;
  }

  & .line {
    &::after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      border-left: 2px dotted #B3B3B3;
      margin-top: 20px;
      left: 7px;
      top: 0px;
    }
  }

  & .location {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    &-header {
      color: #808080;
    }

    &-detail {
      display: flex;
      flex-direction: row;

      &-title {
        font-weight: bold;
        flex: 1;
        line-height: 1;
      }

      &-map {
        padding-left: 5px;
        align-self: flex-start;
      }
    }
  }
`;


const DropPoint = (props) => {
  return (
    <Container>
      {
        props.address.map((item, index) => (
          <div key={index} className={`droppoint ${item.mode == "รับสินค้า" ? 'pickup' : ''}`}>
            <div className={index == props.address.length - 1 ? "pin" : "pin line" }><i className={item.mode == "รับสินค้า" ? "fas fa-dot-circle" : "fas fa-map-marker-alt text-primary"}></i></div>
            <div className="location">
              <div className="location-header">{item.mode == "roundtrip" ? 'กลับจุดที่ 1' : `จุดที่${index +1} - ${item.mode}` } :</div>
              <div className="location-detail">
                <div className="location-detail-title">{ item.address }</div>
                <div className="location-detail-map"><a href={`https://maps.google.com?q=${item.lat},${item.lng}`}>ดูแผนที่ ></a></div>
              </div>
            </div>
          </div>
        ))
      }
    </Container>
  )
}

export default DropPoint;