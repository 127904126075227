import React, { Component, createRef } from 'react';
import { FormControl, Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import lambdaUrl from 'src/config/lambdaUrl.js';
import UploadFile from '../UploadFile';
import ModalConfirm from '../ModalConfirm';
import LoadingLottie from '../../../../components/Loading';
import ErrorLottie from '../../../../components/Error';
import SuccessLottie from '../../../../components/Success';

class ModalImportFileProduct extends Component {
	constructor(props) {
		super(props);
		this._inputImportFile = createRef();
		this.state = {
			file: null,
			loading: false,
			error: null,
			success: false,
		};
	}

	componentDidMount() {
		this.setState({
			file: null,
		});
	}

	onCancel = () => {
		this.props.onClose && this.props.onClose();
		this.setState({
			file: null,
		});
	};

	uploadFileHandler = async () => {
		this.setState({
			loading: true,
		});
		try {
			const data = JSON.stringify({
				path: this.state.file.path,
				account_info: this.props.accountInfoId,
			});

			await axios
				.post(`${lambdaUrl}/import-excel-order`, data, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
				.then(res => {
					if (res.data.statusCode === 500) {
						throw new Error(
							`${lambdaUrl}/import-excel-order ` + res.data.messageError
						);
					}

					if (res.data.statusCode === 200) {
						this.onCancel();
						this.props.onCompleted && this.props.onCompleted();
					}
					this.setState({
						success: true,
					});
				});
		} catch (error) {
			alert(error.message);
			// this.setState({
			// 	error: error.message,
			// });
		} finally {
			this.setState({
				loading: false,
			});
			setTimeout(() => {
				this.setState({
					success: false,
				});
			}, 1200);
		}
	};

	render() {
		const { open } = this.props;
		const { file } = this.state;
		// if (this.state.error) {
		// 	return <ErrorLottie error={this.state.error || ''} />;
		// } else
		if (this.state.success) {
			return <SuccessLottie />;
		}
		if (this.state.loading) {
			return <LoadingLottie />;
		} else {
			return (
				<ModalConfirm
					show={open}
					onPressButtonConfirm={() => {
						if (this.state.loading) {
							return;
						} else {
							this.uploadFileHandler();
						}
					}}
					styleColorButtonConfirm="primary"
					labelButtonConfirm={this.state.loading ? 'Loading...' : 'อัพโหลดไฟล์'}
					labelButtonCancel="ยกเลิก"
					onPressButtonCancel={() => this.onCancel()}
					titleHeader={`Import File: Import Product`}
					onHide={() => this.onCancel()}
					disabledButtonConfirm={!this.state.file}
				>
					<Row>
						<Col xs={12} style={{ marginBottom: 20 }}>
							<strong>Import file :</strong>
							<div>
								<UploadFile
									completed={({ fileName, path }) => {
										this._inputImportFile.current.value = null;
										this.setState({
											file: {
												fileName,
												path,
											},
										});
									}}
								>
									{selectFileImport => {
										return (
											<React.Fragment>
												<input
													accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
													onChange={event => {
														selectFileImport(
															event,
															`upload/lazadaProject/document_${new Date().getTime()}`
														);
													}}
													type="file"
													id="file"
													ref={this._inputImportFile}
													style={{ display: 'none' }}
												/>
												<Button
													onClick={() => this._inputImportFile.current.click()}
												>
													<i className="fas fa-cloud-upload"></i> Import Booking
												</Button>{' '}
												{file && file.fileName ? file.fileName : ''}
											</React.Fragment>
										);
									}}
								</UploadFile>
							</div>
						</Col>
						<Col xs={12}>
							<a
								target="_blank"
								href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_import_products_v4.xlsx"
								style={{
									color: '#0F8EE9',
								}}
							>
								ดาวน์โหลดไฟล์ตัวอย่าง
							</a>
						</Col>
						<Col xs={12}>
							<a
								target="_blank"
								href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/GiztixMasterProvince_v1.xlsx"
								style={{
									marginTop: 15,
									color: '#0F8EE9',
								}}
							>
								ดาวน์โหลดไฟล์ Data master จังหวัด/อำเภอ/รหัสไปรษณีย์
							</a>
						</Col>
					</Row>
				</ModalConfirm>
			);
		}
	}
}

export default ModalImportFileProduct;
