import React from 'react';
export const InstancePriceContext = React.createContext();

class InstancePriceContextProvider extends React.Component {
	state = {
		// manage popup
		selectDriver: false,
		selectContact: false,
		selectContactSaved: false,
		//หน้าเพิ่มข้อมูลผู้ติดต่อ
		contactName: '',
		contactTel: '',
		isSaveContact: false,
		remark: '',
		mainAddress: '',
		//หน้าเลือกตำแหน่ง
		searchLocationMainAddress: null,
		searchLocationSecondaryAddress: null,
		searchLocationIsDrag: false,
		searchLocationContactName: '',
		searchLocationContactTel: '',
		searchLocationIsSelectContactAddress: false,
	};

	render() {
		return (
			<InstancePriceContext.Provider
				value={{
					state: this.state,
					onCloseAll: () => {
						this.setState({
							selectDriver: false,
							selectContact: false,
							selectContactSaved: false,
						});
					},
					onCloseAllAndOpen: (popupName = []) => {
						this.setState(
							{
								selectDriver: false,
								selectContact: false,
								selectContactSaved: false,
							},
							() => {
								if (popupName.length > 0) {
									popupName.forEach(element => {
										this.setState({ [`${element}`]: true });
									});
								}
							}
						);
					},
					onClose: (popupName = []) => {
						popupName.forEach(element => {
							this.setState({ [`${element}`]: false });
						});
					},
					onOpen: (popupName = []) => {
						popupName.forEach(element => {
							this.setState({
								[`${element}`]: true,
							});
						});
					},
					setData: (data) => {
						this.setState((prev) => {
							return {
								...prev,
								...data,
							}
						});
					}
				}}
			>
				{this.props.children}
			</InstancePriceContext.Provider>
		);
	}
}

export default InstancePriceContextProvider;
