import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import s3 from "src/config/s3";
import { withNamespaces } from "react-i18next";
import { ShipmentDetailContext } from "../../contexts/ShipmentDetailContext";
import classes from "./ManageDriver.module.css";
import { RadioItem } from "./components";
import { Button } from "react-bootstrap";
import driverfavoriteAndBlacklistAddMutation from "./graphql/mutation/driverfavoriteAndBlacklistAdd";
import driverfavoriteAndBlacklistDeleteMutation from "./graphql/mutation/driverfavoriteAndBlacklistDelete";

class DetailReviewDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: this.props.favoriteDriver,
    };
  }

  static contextType = ShipmentDetailContext;

  isSelect = value => {
    return this.state.select === value;
  };

  selectHandler = value => {
    this.setState({ select: value });
  };

  close = () => {
    this.context.onClose(["manageDriver"]);
  };

  confirmHandler = (
    driverfavoriteAndBlacklistAdd,
    driverfavoriteAndBlacklistDelete
  ) => {
    const { select } = this.state;
    const driverId = this.props.driverId;

    if (select === 0) {
      driverfavoriteAndBlacklistDelete({
        variables: {
          driver_user_id: [driverId]
        }
      });
    } else if (select === 1) {
      driverfavoriteAndBlacklistAdd({
        variables: {
          driver_user_id: [driverId],
          mode: "favorite"
        }
      });
    } else if (select === 2) {
      driverfavoriteAndBlacklistAdd({
        variables: {
          driver_user_id: [driverId],
          mode: "blacklist"
        }
      });
    }
  };

  render() {
    const manageList = [
      { label: "ไม่ดำเนินการใดๆ", value: 0 },
      { label: "บันทึกเป็นพนักงานขับรถคนโปรด", value: 1 },
      { label: "แบนพนักงานขับรถคนนี้", value: 2 }
    ];

    const list = (
      <div>
        {manageList.map((item, index) => (
          <RadioItem
            key={index}
            name={item.label}
            className={classes.list}
            selected={this.isSelect(item.value)}
            onPressSelected={() => this.selectHandler(item.value)}
          />
        ))}
      </div>
    );

    return (
      <div className={classes.toolContainer}>
        <div>
          <div
            className={classes.btnClose}
            onClick={() => this.context.onClose(["manageDriver"])}
          >
            <i className="fal fa-times" />
          </div>

          <div className={classes.header}>
            <div className={classes.title}>เกี่ยวกับพนักงานขับรถ</div>
            <div className={classes.subTitle}>
              เลือกหัวข้อด้านล่างเพื่อแก้ไข
            </div>
          </div>

          {list}

          <Mutation
            mutation={driverfavoriteAndBlacklistDeleteMutation}
            onError={() => {
              alert("เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง");
            }}
            update={() => this.close()}
            refetchQueries={() => ["TrackingList"]}
          >
            {(
              driverfavoriteAndBlacklistDelete,
              { loading: loadingDriverfavoriteAndBlacklistDelete }
            ) => {
              return (
                <Mutation
                  mutation={driverfavoriteAndBlacklistAddMutation}
                  onError={() => {
                    alert("เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง");
                  }}
                  update={() => this.close()}
                  refetchQueries={() => ["TrackingList"]}
                >
                  {(
                    driverfavoriteAndBlacklistAdd,
                    { loading: loadingDriverfavoriteAndBlacklistAdd }
                  ) => {
                    return (
                      <Button
                        bsStyle="primary"
                        block
                        onClick={() =>
                          this.confirmHandler(
                            driverfavoriteAndBlacklistAdd,
                            driverfavoriteAndBlacklistDelete
                          )
                        }
                        disabled={
                          loadingDriverfavoriteAndBlacklistAdd ||
                          loadingDriverfavoriteAndBlacklistDelete
                        }
                      >
                        {loadingDriverfavoriteAndBlacklistAdd ||
                        loadingDriverfavoriteAndBlacklistDelete
                          ? "...ยืนยัน"
                          : "ยืนยัน"}
                      </Button>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(DetailReviewDriver);
