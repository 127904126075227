import gql from 'graphql-tag';

export default gql`
  query AccountInfoListByTrackingURL(
    $account_info_tracking_url: String!
  ) {
    accountInfoListByTrackingURL (
      account_info_tracking_url: $account_info_tracking_url
    ) {
      account_info_logo
    }
  }
`;