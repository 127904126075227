import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: relative;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;

  & .header {
    &__title {
      font-size: 24px;
    }

    &__sub-title {
      font-size: 20px;
    }

    &__info {
      position: absolute;
      top: 40px;
      right: -5px;
      font-size: 18px;
      color: #888888;
      cursor: pointer;
    }
  }

  & img {
    width: 80%;
  }
`;

const Header = (props) => {
  const {title, ...except} = props;

  return (
    <Container {...except}>
      <div className="header__info" onClick={() => props.onPressInfo ? props.onPressInfo() : null}>
        <i className="fal fa-info-circle" />
      </div>
      <img src={require('src/assets/images/Distance-02.png')} />
      {props.title && <div className="header__title">{props.title}</div>}
      {props.subTitle && <div className="header__sub-title">{props.subTitle}</div>}
    </Container>
  )
};

Header.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

Header.defaultProps = {
  title: '',
  subTitle: '',
}

export default Header;