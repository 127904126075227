import React from 'react';
import styled from 'styled-components';
import { Col, Row, Button } from 'react-bootstrap';
import color from 'src/config/color';
import Moment from 'react-moment';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { Query } from 'react-apollo';
import { Spin, Icon as IconAntd } from 'antd';

const Container = styled.div`
  text-align: center;

	.img-complete {
		width: 160px;
	}

	.title {
		font-size: 24px;
		font-weight: bold;
		margin: 20px;
	}

	.box {
		display: flex;
		flex-direction: row;
		font-size: 24px;
		width: 536px;
		justify-content: space-between;
		margin: 0px auto;
	}

	.box-content {
		flex: 1;
		padding: 0px 10px;

		&:not(:last-child) {
			border: solid #E3E3E3;
			border-width: 0 1px 0 0;
		}
	}

	.box-promotion {
		float: left;
		flex: 1;
		flex-direction: column;
		background-color : #F9F9F9;
		padding:15px;

		.circle-red {
			float: left;
			width: 68px;
			height: 68px;
			font-size: 24px;
			color: #000000;
			font-weight: bold;
			text-align: center;
			align-items: center;
			border: 2px solid #d90001;
			border-radius: 50%;
			padding-top: 15px;
		}

		.promotion-content {
			text-align: left;
			float: left;
			color: #000000;
			width: calc(100% - 68px);
			padding-left: 10px;

			.promotion-title{
				font-size: 24px;
				line-height: 1;
				margin-bottom: 5px;
				font-weight: bold;
				color: #000000;
			}
			.promotion-detail{
				color : #000000;
				line-height: 1;
			}
		}
	}

	.box-text-bold {
		font-weight: bold;
	}

	.text-label {
		display: inline-block;
		background-color: #E3E3E3;
		padding: 0px 20px;
		border-radius: 6px;
	}

	.text-label.green {
		display: inline-block;
		background-color: #19AA19;
		color:#ffffff;
		padding: 0px 20px;
		border-radius: 6px;
	}

	.img-barcode {
		width: 120px;
		height: 120px;
	}

	.contact {
		margin-top: 40px;
		line-height: 1;

		& a, a:active, a:hover, a:visited, a:focus {
			color: #1CB5E0;
			text-decoration: none;
		}
	}
`;

class Registered extends React.Component {
  render() {
    return (
      <Query
        query={accountInfoListQuery}
        fetchPolicy="cache-and-network"
      >
        {
          ({ data, loading, error }) => {
            if(loading) {
              return (<div style={{textAlign: 'center'}}>
                <Spin indicator={
                  <IconAntd type="loading" style={{ fontSize: 24, color: '#d90101' }} spin />
                } />
              </div>)
            }

            if(error) {
              return (<div>{error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Error.'}</div>)
            }

            return (
              <Container style={{textAlign: 'center'}}>
                <img className="img-complete" src={`${require('src/assets/images/complete.png')}`} alt="" />
                <div className="title">ยื่นคำร้องลงทะเบียนใช้งาน Giztix Business เรียบร้อยแล้ว</div>

                <div className="box">
                  <div className="box-content">
                    <div>หมายเลขคำร้อง</div>
                    <div className="box-text-bold">BU{ data.accountInfoList.account_business.business_req_number }</div>
                  </div>
                  <div className="box-content">
                    <div>วันที่ยื่นคำร้อง</div>
                    <div className="box-text-bold"><Moment format="DD/MM/YYYY HH:mm">{ data.accountInfoList.account_business.create_date }</Moment></div>
                  </div>
                  <div className="box-content">
                    <div>สถานะคำร้อง</div>
                    {
                      data.accountInfoList.account_business.business_status === 1 ? (
                        <div className="box-text-bold text-label">รอตรวจสอบ</div>
                      ) : null
                    }

                    {
                      data.accountInfoList.account_business.business_status === 2 ? (
                        <div className="box-text-bold text-label green">อนุมัติเรียบร้อย</div>
                      ) : null
                    }

                    {		
                      data.accountInfoList.account_business.business_status === 3 ? (
                        <div className="box-text-bold text-label">ระงับการใช้งานชั่วคราว</div>
                      ) : null
                    }

                    {		
                      data.accountInfoList.account_business.business_status === 4 ? (
                        <div className="box-text-bold text-label">ไม่อนุมัติ</div>
                      ) : null
                    }
                  </div>
                </div>

                <div className="contact">จะมีเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ <br/> สอบถามสถานะการสมัครได้ที่ <a href="mailto:business@giztix.com">business@giztix.com</a></div>

                <div style={{margin: '30px auto 0 auto', width: 350}}>
                  <Row>
                    <Col md={12}><Button block onClick={() => window.location.href = '/'} bsStyle="primary" bsSize="lg"> <i className="far fa-search"></i> เช็คราคาค่าขนส่งฟรีทันที</Button></Col>
                  </Row>
                  <Row>
                    <Col md={6}><Button style={{marginTop: 10}} onClick={() => window.location.href = '/setting-business'} block>แก้ไขคำร้อง</Button></Col>
                    <Col md={6}><Button style={{marginTop: 10}} onClick={() => window.location.href = '/'} block>กลับหน้าแรก</Button></Col>
                  </Row>
                </div>
                
                {
                  data.accountInfoList.account_business.business_status === 2 && data.accountInfoList.account_business.invite_code === "AFWIFC"  ? (
                    <div style={{marginTop:30}}>
                      <div className="title">คุณได้รับสิทธิ <span style={{fontSize:30,color:color.Primary}}>พิเศษ!</span>  <span style={{fontSize:30}}>3 ต่อ</span></div>
                      <Row>
                        <Col md={4} sm={12}>
                          <div className="box-promotion">
                            <div className="circle-red">
                              ต่อที่ 1 
                            </div>
                            <div className="promotion-content">
                              <div className="promotion-title">ลดค่าบริการรถขนส่งสูงสุด <span style={{color : color.Primary}}>500 บาท!</span></div>
                              <div className="promotion-detail">รับส่วนลดค่าบริการรถขนส่งสูงสุด 500 บาท ในการใช้งาน 5 ครั้งแรก</div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4} sm={12}>
                          <div className="box-promotion">
                            <div className="circle-red">
                              ต่อที่ 2  
                            </div>
                            <div className="promotion-content">
                              <div className="promotion-title">ลดค่าบริการรถขนส่ง <span style={{color : color.Primary}}>10%</span></div>
                              <div className="promotion-detail">ส่วนลดค่าบริการรถขนส่ง 10% ครั้งที่ 11 เป็นต้นไป นาน 3 เดือน</div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4} sm={12}>
                          <div className="box-promotion">
                            <div className="circle-red">
                              ต่อที่ 3
                            </div>
                            <div className="promotion-content">
                              <div className="promotion-title">ลดค่าบริการ <span style={{color : color.Primary}}>ตลอดการใช้บริการ</span></div>
                              <div className="promotion-detail">ลดค่าบริการรถขนส่ง 5% ตลอดการใช้บริการ</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : null
                }
              </Container>
            )
          }
        }
      </Query>
    )
  }
}

export default Registered;