import React from 'react';
import Lottie from 'lottie-react';
import Loading from './Loading.json';

export const LoadingLottie = () => {
	return (
		<div className="">
			<Lottie
				style={{
					zIndex: '9999',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: `350px`,
				}}
				loop={true}
				animationData={Loading}
			/>
		</div>
	);
};

export default LoadingLottie;
