import gql from 'graphql-tag';

export default gql`
	query TrackingDropList(
    $shipment_id: ID!
  ) {
		trackingDropList(
      shipment_id: $shipment_id
    ) {
			_id
      shipment_id
      tracking_name
      tracking_number
      tracking_drop
      delete
      create_date
      drop_link
		}
	}
`;
