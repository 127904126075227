import React from 'react';
import {
  Input,
  Card,
  StepHeader,
  SelectBox, 
  Select, 
  Label
} from '../../components';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { compose, graphql, Query, Mutation } from 'react-apollo';

import shareRouteAddMutation from './graphql/mutation/shareRouteAdd';
import getInstancePrice from './graphql/query/getInstancePrice';
import settingTruckListQuery from './graphql/query/settingTruckList';

import provinceData from 'src/config/province/changwats/th.json';

import urijs from 'urijs';
import client from 'src/config/client';

const liff = window.liff;
//Validate Yup
// provincePickup:{ value: '', label: '-- เลือก --' },
//       provinceDelivery:{ value: '', label: '-- เลือก --' },
//       truckType : { value: '', label: '-- เลือก --' ,id: "", catId: ""},

const driverValidate = Yup.object().shape({
  provincePickup : Yup.object().shape({
    value: Yup.string().required("กรุณากรอกข้อมูล")
  }),
  provinceDelivery : Yup.object().shape({
    value: Yup.string().required("กรุณากรอกข้อมูล")
  }),
  truckType : Yup.object().shape({
    value: Yup.string().required("กรุณากรอกข้อมูล")
  }),
});

const Container = styled.div`
  // margin-top: 10px;
  background-image: url(${require('src/assets/images/homepage/cover/circle.svg')});
  background-repeat: no-repeat;
  background-position: top left 100px;
  background-size: 200%;
  height: 100vh;
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const Logo = styled.div`
  text-align: center;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;

  font-size: 24px;
`;

let truckList = null;
class CheckPrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userLineID: '',
      pictureUrl: '',
      statusMessage: '',

      isLoading: false,
      isSuccess: false,
      accountInfoId: '',

      provincePickup:{ value: '', label: '-- เลือก --' ,latitude : "" ,longitude : "" },
      provinceDelivery:{ value: '', label: '-- เลือก --',latitude : "" ,longitude : ""  },
      truckType : { value: '', label: '-- เลือก --' ,id: "", catId: ""},

      // Shipment
      additional: [],
			promotion_name: 'GTXONE',
			roundTrip: '',
			accessory: [],
			shipmentType: 1,
    };
  }

	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

  async componentWillMount() {
    //ดึงข้อมูลรถ
    const { data: settingTruckListData } = await client.query({
      query: settingTruckListQuery,
      variables: {
        shipmentType: 1,
      }
    });
    
    truckList = settingTruckListData.settingTruckList;
    //const listTruck = settingTruckListData.settingTruckList.filter(x => x._id === shipment.setting_truck_id);
   // const TruckData = listTruck[0].setting_truck_type.filter(y => y._id === shipment.setting_truck_type_id);

  }

  handleChangeTruck(truck) {
    // console.log(`truckSelected:`, truck);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ truckSelected : truck , truckID : truck.value});
    // console.log(`truckSelected:`, this.state.truckID);
  }


  async register(values) {
    try {
      this.setState({ isLoading: true });
      this.props.shareRouteAddMutation({
        variables: {
          account_info_id: this.state.accountInfoId,
          line_user_id: values.userLineID,
          driver_name: values.driverName,
          driver_phone_code: values.driverPhoneCode,
          driver_phone_number: values.driverPhoneNumber.replace(/\b0+/g,'',),
          user_setting_truck_license_plate: values.driverLicensePlate,
          driver_enable: 1,
        }
      })
      .then(({ data }) => {
        if(data.registerDriverLine._id) {
          this.setState({ isLoading: false, isSuccess: true });
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({ isLoading: false });
      });
    } catch(error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  }

  async addShareRoute(values) {
		const {
			additional,
			promotion_name,
			roundTrip,
			accessory,
      shipmentType,
      provincePickup,
      provinceDelivery,
      truckType,
    } = this.state;
    
    const truckFilter = truckList.filter(item => item._id === truckType.catId);
    const truckTypeFilter = truckFilter[0].setting_truck_type.filter(item => item._id === truckType.id);
    const shipmentAddressFilter = [
      {
        mode: 'pickup',
        address: provincePickup.label,
        contactName: '',
        phoneNumber: '',
        remark: '',
        paidByCOD: null,
        province: provincePickup.label,
        pickupDate: '',
        latitude: Number(provincePickup.latitude),
        longitude: Number(provincePickup.longitude),
      },
      {
        mode: 'delivery',
        address: provinceDelivery.label,
        contactName: '',
        phoneNumber: '',
        remark: '',
        paidByCOD: null,
        province: provinceDelivery.label,
        pickupDate: '',
        latitude: Number(provinceDelivery.latitude),
        longitude: Number(provinceDelivery.longitude),
      }
    ]
    
		const shareRoute = {
			shipmentAddress: shipmentAddressFilter,
			setting_truck_id : truckType.catId,
			setting_truck_name :truckFilter[0].setting_truck_name,
			setting_truck_type_id : truckType.id,
			setting_truck_type_name : truckTypeFilter[0].setting_truck_type_name,
			setting_truck_type_size : truckTypeFilter[0].setting_truck_type_size,
			setting_truck_type_pic : truckTypeFilter[0].setting_truck_type_pic,
			additional,
			promotion_name,
			roundTrip,
			accessory,
			shipmentType,
		};

		try {
			await this.props.shareRouteAddMutation({
				variables: {
          obj_msg: JSON.stringify(shareRoute),
          utm_link: "&utm_source=nbone&utm_medium=linecheckprice&utm_campaign=sep"
				},
				update: (cache, { data }) => {
          // console.log(data);
          window.location =  data.shareRouteAdd.real_url;
					// this.setState({
					// 	url: data.shareRouteAdd.url,
					// 	isOpenModalShareRoute: true,
					// });
				},
			});
		} catch (error) {
			console.log(error);
		}
  }
  
  selectOptionTruckTypeData(truckList) {
		let selectOptions = [];

    if(truckList != null){
      truckList.map((item, index) => {
        const truck = {
          label: item.setting_truck_name.th,
          options: []
        };

        selectOptions.push(truck);
        
        item.setting_truck_type.map((type, key) => {
          if(type.truck_type_enable == 1){
            const obj = {
              value: `${item.setting_truck_name.th}${type.truck_type_name.th}`, 
              label: `${item.setting_truck_name.th}${type.truck_type_name.th}`, 
              id: type._id, 
              catId: item._id 
            };
            selectOptions[index].options.push(obj);
          }
        });
      });
    }
  
		return selectOptions;
  }
  
  selectOptionProvinceData() {
		let options = [
			{ value: '', label: '-- เลือก --', pid: '',latitude : "" ,longitude : ""},
		];

		provinceData.th.changwats.map((item, index) => {
			const province = { value: item.name, label: item.name ,latitude :item.latitude ,longitude : item.longitude};
			options.push(province);
			return province;
		});

		return options;
	}

	changeProvincePickup(event) {
    this.setState({ provincePickup: { value: event.value, label: event.label, latitude :event.latitude ,longitude : event.longitude  }});
	}
	changeProvinceDelivery(event) {
		this.setState({ provinceDelivery: { value: event.value, label: event.label, latitude :event.latitude ,longitude : event.longitude  }});
  }
  changeTruckType(event){
    this.setState({truckType :{value: event.value,label: event.label, id: event.id, catId: event.catId }});
  }

  checkAddress(){
    if(this.state.truckType.value == "" || this.state.provincePickup.value == "" ||this.state.provinceDelivery.value == "" ){
      return false;
    }
    return true;
  }

  render() {
    if (this.props.settingTruckListQuery.loading == false && this.props.settingTruckListQuery.settingTruckList != undefined) {
      truckList = this.props.settingTruckListQuery.settingTruckList;
    }
    return (
      <Container>
            <img src={`${require('src/assets/images/ads-line.png')}`} style={{width:"100%"}} alt="" />
        <Card style={{ backgroundColor: 'transparent' }}>
          {
            this.state.isSuccess && (
              <div>
                <SuccessContainer>
                  ลงทะเบียนคนขับรถสำเร็จ
                </SuccessContainer>
              </div>
            )
          }

          {
            !this.state.isSuccess && (
              <div>
                <Header>เช็คราคาขนส่ง</Header>
                <Formik
                  initialValues={{
                    provincePickup:{ value: '', label: '-- เลือก --',latitude : "" ,longitude : ""},
                    provinceDelivery:{ value: '', label: '-- เลือก --',latitude :"",longitude : ""},
                    truckType : { value: '', label: '-- เลือก --' ,id: "", catId: ""},
                  }}
                  validationSchema={driverValidate}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    this.addShareRoute(values);
                  }}
                >
                  {
                    (props) => {
                      const { values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
                      return (
                        <div>
                        <Row>
                          <Col xs={12}>
                            <StepHeader
                              number="1."
                              title={"เลือกจังหวัดจุดรับ-จุดส่งสินค้า"}
                              subTitle={"ระบบจะปักหมุดไว้ที่อำเภอเมืองของทุกจังหวัด เพื่อประเมินราคาประมาณ (สามารถระบุจุดละเอียดได้บนหน้าจองขนส่ง)"}
                            />
                          </Col>
                         
                            <Col xs={12}>
                              <SelectBox 
                                onChange={(event) => {this.setState({ provincePickup: { value: event.value, label: event.label, latitude :event.latitude ,longitude : event.longitude }} , props.setFieldValue("provincePickup" , { value: event.value, label: event.label,latitude :event.latitude ,longitude : event.longitude }))}} 
                                value={values.provincePickup} style={{marginTop: 10}} 
                                options={this.selectOptionProvinceData()} 
                                inputLabel="จังหวัด - จุดรับสินค้า" required  
                                error={errors.provincePickup && touched.provincePickup}
                              />
                            </Col>

                            <Col xs={12}>
                              <SelectBox 
                                onChange={(event) => {this.setState({ provinceDelivery: { value: event.value, label: event.label, latitude :event.latitude ,longitude : event.longitude  }} , props.setFieldValue("provinceDelivery" , { value: event.value, label: event.label,latitude :event.latitude,longitude : event.longitude }))}} 
                                value={values.provinceDelivery} style={{marginTop: 10}} 
                                options={this.selectOptionProvinceData()} inputLabel="จังหวัด - จุดส่งสินค้า" required  
                                error={errors.provinceDelivery && touched.provinceDelivery}
                              />
                            </Col>

                            </Row>                       
                          <hr style={{margin:"0 0 20px"}} />
                        <Row>
                          <Col xs={12}>
                            <StepHeader
                              number="2."
                              title={"เลือกประเภทรถขนส่ง"}
                              subTitle={"ระบุประเภทรถที่คุณต้องการขนส่ง"}
                            />
                          </Col>   
                          <Col xs={12}>
                            <SelectBox 
                              onChange={(event) => {this.setState({truckType :{value: event.value,label: event.label, id: event.id, catId: event.catId }},props.setFieldValue("truckType" , { value: event.value, label: event.label, id: event.id , catId: event.catId }))}} 
                              value={values.truckType} style={{marginTop: 10}} 
                              options={this.selectOptionTruckTypeData(truckList)} inputLabel="ประเภทรถขนส่ง" required  
                              error={errors.truckType && touched.truckType}
                            />
                          </Col>

                          {
                            this.checkAddress() ? (
                                <Query
                                  query={getInstancePrice}
                                  fetchPolicy="cache-and-network"
                                  variables={{
                                    settingTruckTypeId:this.state.truckType.id,
                                    address:[
                                      {
                                        lat: this.state.provincePickup.latitude,
                                        lng: this.state.provincePickup.longitude
                                      },
                                      {
                                        lat: this.state.provinceDelivery.latitude,
                                        lng: this.state.provinceDelivery.longitude
                                      },
                                    ],
                                    pickupDate:"",
                                    shipmentType:1,
                                    utm_source: this.getCookie("utm_source"),
                                    utm_medium: this.getCookie("utm_medium"),
                                    utm_campaign: this.getCookie("utm_campaign"),
                                    ip_address: this.getCookie("ip_address"),
                                  }}
                                >
                                  {({ loading, error, data ,variables}) => {
                                    let distance = "";
                                    let totalPrice = "0.00";
                                    if (loading) {
                                      distance = "loading";
                                    } else if (error) {
                                      distance = '0 กม.';
                                    }else if (!loading && !error) {
                                      totalPrice = data.getInstancePrice.totalPrice;
                                      distance = data.getInstancePrice.distance;
                                    }
                                    return (
                                      <div className="price">
                                        <div className="col-xs-6">
                                          <b style={{width:"100%",float:"left",lineHeight:"8px"}}>ราคาค่าขนส่งทั้งหมด:</b>
                                          <small>ราคาโดยประมาณ อาจมีเปลี่ยนแปลงขั้นต้น</small>
                                        </div>
                                        <div className="col-xs-6" style={{textAlign:"right"}}>
                                          <h1  style={{width:"100%",float:"left",lineHeight:"8px",marginBottom:0,color:"#D90101"}}>{totalPrice.toLocaleString('en-US', {maximumFractionDigits:2})} บาท</h1>
                                          <small>ระยะทางโดยประมาณ: {`${distance.toLocaleString('en-US', {maximumFractionDigits:2})} ${
                                          typeof distance === 'number'
                                            ? "กม."
                                            : ''
                                        }`}</small>
                                        </div>
                                      </div>
                                    );
                                  }}
                              </Query>
                            ) : (
                              <div className="price">
                                  <div className="col-xs-6">
                                    <b style={{width:"100%",float:"left",lineHeight:"8px"}}>ราคาค่าขนส่งทั้งหมด:</b>
                                    <small>ราคาโดยประมาณ อาจมีเปลี่ยนแปลงขั้นต้น</small>
                                  </div>
                                  <div className="col-xs-6" style={{textAlign:"right"}}>
                                    <h1 style={{width:"100%",float:"left",lineHeight:"8px",marginBottom:0,color:"#D90101"}}>0.00 บาท</h1>
                                    <small> ระยะทางโดยประมาณ: 0 กม.</small>
                                  </div>
                              </div>
                            )
                          }
                          
																
                          <Col xs={12}>
                             ** อัตราค่าบริการที่ได้รับเป็นราคาโดยประมาณ <br/> กรุณาระบุจุดรับ และส่งสินค้าของคุณเพื่อเริ่มใช้บริการ
                          </Col>

                          <Col xs={12}>
                            <Button style={{marginTop:30,padding:10}} bsStyle="primary" block onClick={() => handleSubmit()} disabled={this.state.isLoading}>{this.state.isLoading ? 'จองและรับราคาพิเศษ...' : 'จองและรับราคาพิเศษ'}</Button>
                          </Col>
                        </Row>
                        </div>
                      )
                    }
                  }
                </Formik>
              </div>
            )
          }
        </Card>
      </Container>
    )
  }
}

export default compose(
  graphql(settingTruckListQuery, 
    { name: 'settingTruckListQuery' ,
    variables: {
      shipmentType: 1,
    }}
  ),
  graphql(shareRouteAddMutation, {
    name: 'shareRouteAddMutation'
  })
)(CheckPrice);