import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Query } from 'react-apollo';
import modalLoginQueryClient from './config/graphqlClient/query/modalLogin';
import modalRegisterQueryClient from './config/graphqlClient/query/modalRegister';
import modalForgetPasswordQueryClient from './config/graphqlClient/query/modalForgetPassword';
import { ModalLogin, ModalRegister, ModalForgetPassword } from './components';

import InstancePrice from './view/InstancePrice2';
import ShipmentDetail from './view/ShipmentDetail';
import ShipmentList from './view/ShipmentList';
import TrackingDetail from './view/TrackingDetail';
import Tracking from './view/Tracking';
import NotFound from './view/NotFound';
import NotiDownloadApp from './view/NotiDownloadApp';
import Profile from './view/Setting/screens/Profile';
import Contact from './view/Setting/screens/Contact';
import SubAccount from './view/Setting/screens/SubAccount';
import RegisterInvite from './view/RegisterInvite';
import RegisterBusiness from './view/Business/screens/Business';
import GiztixBusiness from './view/GiztixBusiness';
import {
	CurrentUser,
	CurrentUserNotRedirect,
	CurrentUserBusiness,
	CheckTrackingUrl,
} from './renderProps';
import client from './config/client';
import Business from './view/Setting/screens/Business';
import SettingTracking from './view/Setting/screens/Tracking';
import Reports from './view/Setting/screens/Reports';
import ShipmentCS from './view/LineDriver/screens/ShipmentCS';
import AssignDriver from './view/LineDriver/screens/AssignDriver';
import RegisterDriver from './view/LineDriver/screens/RegisterDriver';
import GetProfileLine from './view/LineDriver/screens/GetProfileLine';
import Notification from './view/Notification';
import LoadCalculator from './view/LoadCalculator';
import CheckPriceInLineAtGiztix from './view/LineAtGiztix/screens/CheckPrice';
import FlashDeals from './view/FlashDeals';
import UploadfileOptimizeroute from './view/UploadfileOptimizeroute';
import DownloadOptimizeroute from './view/UploadfileOptimizeroute/downloadoptimizeroute';
import FavoriteDriver from './view/FavoriteDriver/screens/FavoriteDriver';
import ProductList from './view/ProductList';
import PDPA from './components/PDPA';
import CookiePolicy from './view/CookiePolicy';
import PrivacyPolicy from './view/PrivacyPolicy';

class Routes extends React.Component {
	constructor(props) {
		super(props);
		this.state = { width: props.width };
	}

	componentWillMount() {
		client.cache.writeData({
			data: {
				user: {
					token: localStorage.getItem('token'),
					__typename: 'User',
				},
			},
		});

		this.setState({ width: window.innerWidth });
	}

	render() {
		return (
			<div>
				<PDPA />
				<Switch>
					<Route
						exact
						path="/"
						render={() => (
							<CurrentUserNotRedirect>
								<InstancePrice />
							</CurrentUserNotRedirect>
						)}
					/>
					<Route
						path="/newshipment"
						render={() => (
							<CurrentUserNotRedirect>
								<InstancePrice />
							</CurrentUserNotRedirect>
						)}
					/>
					<Route path="/uploadfile" component={UploadfileOptimizeroute} />
					<Route
						path="/downloadoptimizeroute"
						component={DownloadOptimizeroute}
					/>
					<Route path="/giztixbusiness" component={GiztixBusiness} />
					<Route path="/shipmentdetail" component={ShipmentDetail} />
					<Route path="/shipment" render={() => <ShipmentList />} />
					<Route
						path="/trackingdetail/:trackingUrl"
						component={TrackingDetail}
					/>
					<Route path="/trackingdetail" component={TrackingDetail} />
					<Route
						path="/tracking/:trackingUrl"
						render={props => (
							<CheckTrackingUrl trackingUrl={props.match.params.trackingUrl}>
								<Tracking {...props} />
							</CheckTrackingUrl>
						)}
					/>
					<Route path="/tracking" component={Tracking} />
					<Route path="/loadcalculator" component={LoadCalculator} />
					<Route
						path="/setting-profile"
						render={() => (
							<CurrentUser>
								<Profile />
							</CurrentUser>
						)}
					/>
					<Route
						path="/setting-contact"
						render={() => (
							<CurrentUser>
								<Contact />
							</CurrentUser>
						)}
					/>
					<Route
						path="/sub-account"
						render={() => (
							<CurrentUserBusiness>
								<SubAccount />
							</CurrentUserBusiness>
						)}
					/>
					<Route
						path="/setting-tracking"
						render={() => (
							<CurrentUserBusiness>
								<SettingTracking />
							</CurrentUserBusiness>
						)}
					/>
					<Route
						path="/reports"
						render={() => (
							<CurrentUserBusiness customerServiceType={['PARCEL']}>
								<Reports />
							</CurrentUserBusiness>
						)}
					/>
					<Route
						path="/setting-business"
						render={() => (
							<CurrentUser>
								<Business />
							</CurrentUser>
						)}
					/>
					<Route path="/register-invite" render={() => <RegisterInvite />} />
					<Route path="/favorite-driver" render={() => <FavoriteDriver />} />
					<Route path="/register-business" component={RegisterBusiness} />
					<Route path="/shipmentcs" component={ShipmentCS} />
					<Route path="/assign-driver" component={AssignDriver} />
					<Route path="/get-profile-line" component={GetProfileLine} />
					<Route path="/checkprice" component={CheckPriceInLineAtGiztix} />
					<Route path="/notification" component={Notification} />
					<Route path="/flash-deals" component={FlashDeals} />
					<Route path="/product-list" component={ProductList} />

					<Route path="/cookie-policy" component={CookiePolicy} />
					<Route path="/privacy-policy" component={PrivacyPolicy} />

					<Route
						path="/scgcbmlogin"
						render={() => {
							window.location.href = 'https://cbm.giztix.com/scgcbmlogin';
							return null;
						}}
					/>
					<Route
						path="/nichireilogin"
						render={() => {
							window.location.href =
								'https://nichirei.giztix.com/nichireilogin';
							return null;
						}}
					/>
					<Route path="*" component={NotFound} />
				</Switch>

				<Query query={modalLoginQueryClient}>
					{({ data }) => {
						return <ModalLogin show={data.modalLogin.isOpen} />;
					}}
				</Query>
				<Query query={modalRegisterQueryClient}>
					{({ data }) => {
						return <ModalRegister show={data.modalRegister.isOpen} />;
					}}
				</Query>
				<Query query={modalForgetPasswordQueryClient}>
					{({ data }) => {
						return (
							<ModalForgetPassword show={data.modalForgetPassword.isOpen} />
						);
					}}
				</Query>
			</div>
		);
	}
}

export default Routes;
