import React, { Component } from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
	InputGroup,
	From,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from './../../../../config/color';

import './style.css';

import {
	Card,
	CardHeader,
	CreditCardIcon,
	Input,
	ModalAlert,
} from './../../../../components';

//credit Icon
import visa from '../../../../assets/images/credit/visa_icon.png';
import jcb from '../../../../assets/images/credit/jcb_icon.png';
import master from '../../../../assets/images/credit/master_card_icon.png';

import { graphql, Query, Mutation } from 'react-apollo';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';
import ReactCardFlip from 'react-card-flip';
import addCustomerCreditCardMutation from './graphql/mutation/addCustomerCreditCard';
import baseUrl  from './../../../../config/baseUrl';

import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	width: 100%;
`;

let publicKeyOmise = 'pkey_test_5csiiomy5aepdt08d06';
let secretKeyOmise = 'skey_test_5csiiomyngtsbtnys6x';
if(baseUrl === "https://api.giztix.com"){
	publicKeyOmise = 'pkey_5e4v2fkmspjovin9j7k';
	secretKeyOmise = 'skey_5e4v2fknahrbul43ml8';
}

const TitelContainer = styled(Row)`
	padding: 15px 20px 20px;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	font-weight: bold;
	font-size: 20px;
`;
const BoxContainer = styled(Row)`
	margin-top: 20px;
`;
const CreditContainer = styled.div`
	margin-top: 0;
	padding: 20px;
`;
const CreditBackContainer = styled.div`
	margin-top: 0;
	margint-bottom: 0;
	padding: 15px 0;
`;
const CreditImage = styled(CreditCardIcon)`
	width: 40px;
`;
const CreditBrand = styled(CreditCardIcon)`
	width: 40px;
	position: absolute;
	right: 10px;
	top: 10px;
`;
const InnerContainer = styled.div``;
const CardNumberContainer = styled.div`
	margin-top: 10px;
	font-size: 18px;
	font-weight: bold;
`;
const CardNameContainer = styled.div`
	font-size: 16px;
	font-weight: normal;
	width: 100px;
	word-break: break-word;
	text-transform: uppercase;
`;

const CardDateContainer = styled.div`
	position: absolute;
	font-size: 12px;
	right: 10px;
	bottom: 10px;
	width: 65px;
`;
const SmallContainer = styled.small`
	width: 100%;
	float: left;
	font-size: 16px;
`;

const SecureContainer = styled.div`
	font-size: 16px;
	text-align: center;
	margin-top: 10px;
	color: #1eafea;
`;

const CvvTabContainer = styled.div`
	width: 100%;
	height: 30px;
	background-color: #000;
	margin-bottom: 10px;
`;
const CvvNameContainer = styled.div`
	width: 100%;
	float: left;
	margin-bottom: 15px;
`;
const CvvFontContainer = styled.div`
	width: 120px;
	background-color: #fff;
	height: 20px;
	border-radius: 2px;
	margin-left: 20px;
	float: left;
`;
const CvvBackContainer = styled.div`
	border: 1px solid #d90101;
	width: 68px;
	height: 33px;
	position: absolute;
	right: 10px;
	top: 49px;
	padding: 5px 7px;
`;
const CvvBackInner = styled.div`
	width: 50px;
	background-color: #fff;
	height: 20px;
	border-radius: 2px;
	float: left;
	color: #000000;
	text-align: center;
	font-weight: bold;
	font-size: 18px;
	line-height: 1;
`;

const CvvDetailContainer = styled.div`
	font-size: 10px;
	text-align: center;
`;

const LabelContainer = styled.div`
	& .label-container {
		display: flex;
		flex-direction: row;
	}

	& .title {
		flex: 1;
	}

	& .counter {
		font-size: 18px;
		color: #B3B3B3;
	}
`;

const { Omise } = window;

class PaymentCredit extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			creditName: '',
			creditNumber: '',
			expMonth: '',
			expYear: '',
			creditCvv: '',
			omiseCard:{},
			omiseToken: '',
			omiseCardId: '',
			isFlipped: false,
			openModal: false,
		};

		this._inputCreditNumber = React.createRef();
		this._inputExpMonth = React.createRef();
		this._inputExpYear = React.createRef();
		this._inputCVV = React.createRef();
		this._inputCreditName = React.createRef();
	}

	componentDidMount() {
		Omise.setPublicKey(publicKeyOmise);
		// Omise.setSecretKey(secretKeyOmise);
		this._inputCreditNumber.current.focus();
	}

	addCreditCardOmise(addCreditCard) {
        // account_info_id: { type: graphqlType.GraphQLID }
        const cardDetails = {
			'name': this.state.creditName,
			'number': this.state.creditNumber,
			'expiration_month':this.state.expMonth,
			'expiration_year':this.state.expYear,
			'security_code': this.state.creditCvv
		};

        Omise.createToken("card",cardDetails,(statusCode, response) => {
			if (statusCode === 200) {
				// Success: send back the TOKEN_ID to your server. The TOKEN_ID can be
				// found in `response.id`.
				this.setState({
					omiseCard : response.card,
					omiseToken : response.id ,
					omiseCardId: response.card.id ,
				},() => addCreditCard());
			}
			else {
				this.setState({
					openModal: true,
				});
			}
        })
	}

	closeTap(mode, updateInstancePriceStep) {
		if (mode === 'chanel') {
			updateInstancePriceStep({
				variables: {
					paymentCredit: false,
					paymentCreditEdit: false,
					paymentChanel: false,
					paymentCreditList: true,
				},
			});
		} else {
			updateInstancePriceStep({
				variables: {
					paymentCredit: false,
					paymentCreditEdit: false,
					paymentChanel: false,
					paymentCreditList: false,
				},
			});
		}
	}

	creditCardStyle() {
		const { creditNumber } = this.state;
		const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
		const mastercard = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
		const jcb = /^(?:2131|1800|35\d{3})\d{11}$/;

		if (visa.test(creditNumber)) {
			return 'Visa';
		} else if (mastercard.test(creditNumber)) {
			return 'MasterCard';
		} else if (jcb.test(creditNumber)) {
			return 'JCB';
		}

		return 'blank';
	}

	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Mutation mutation={updateInstancePriceMutationClient}>
								{(updateInstancePrice, { error }) => {
									return (
										<Query query={instancePriceQueryClient}>
											{({ data }) => {
												return (
													<Card
														content={
															<div>
																<CardHeader title={this.props.t('common:instancePrice.PaymentCredit.lorem1')}
																borderCardBottom
																onPressClose={() =>
																		this.closeTap(
																			'chanel',
																			updateInstancePriceStep,
																		)
																}/>

																{/* No Card List */}
																<div>
																	<ReactCardFlip
																		isFlipped={this.state.isFlipped}
																	>
																		<CreditContainer
																			key="front"
																			className={`creditCard ${this.creditCardStyle()}`}
																		>
																			<InnerContainer>
																				<CreditImage
																					brand={this.creditCardStyle()}
																				/>
																				<CardNumberContainer>
																					{this.state.creditNumber
																						.replace(/\W/gi, '')
																						.replace(/(.{4})/g, '$1 ') ||
																						'XXXX XXXX XXXX XXXX'}
																				</CardNumberContainer>
																				<CardNameContainer>
																					{this.state.creditName || `YOUR NAME`}
																				</CardNameContainer>
																				<CardDateContainer>
																					{this.props.t('common:instancePrice.PaymentCredit.lorem2')}
																					<SmallContainer>
																						{this.state.expMonth || '**'}
																						{' / '}
																						{this.state.expYear || '****'}
																					</SmallContainer>
																				</CardDateContainer>
																			</InnerContainer>
																		</CreditContainer>

																		<CreditBackContainer
																			key="back"
																			className={`creditCard ${this.creditCardStyle()}`}
																		>
																			<InnerContainer>
																				<CvvTabContainer />
																				<CvvNameContainer>
																					<CvvFontContainer />
																					<CvvBackContainer>
																						<CvvBackInner>
																							{this.state.creditCvv}
																						</CvvBackInner>
																					</CvvBackContainer>
																				</CvvNameContainer>

																				<CvvDetailContainer>
																				{this.props.t('common:instancePrice.PaymentCredit.lorem3')}{' '}
																					<b style={{ color: '#d90101' }}>
																						CVV
																					</b>{' '}
																					{this.props.t('common:instancePrice.PaymentCredit.lorem4')} <br />{' '}
																					{this.props.t('common:instancePrice.PaymentCredit.lorem5')}
																				</CvvDetailContainer>
																			</InnerContainer>
																		</CreditBackContainer>
																	</ReactCardFlip>

																	<SecureContainer>
																		<i className="fal fa-lock-alt" /> Secured
																		Payment with PCI-DSS 3.2 & TLS
																	</SecureContainer>

																	<BoxContainer>
																		<Col sm={12}>
																			<FormGroup controlId="formBasicText">
																				<LabelContainer>
																						<div className="label-container">
																								<div className="title">{this.props.t('common:instancePrice:PaymentCredit:lorem6')}<span style={{color:'#d90101'}}>*</span></div>
																								<div className="counter">({`${this.state.creditNumber.length}/${16}`})</div>
																						</div>
																				</LabelContainer>
																				<FormControl
																					inputRef={this._inputCreditNumber}
																					maxLength={16}
																					type="text"
																					pattern="\d*"
																					value={this.state.creditNumber}
																					placeholder={this.props.t('common:instancePrice.PaymentCredit.lorem7')}
																					onChange={e =>
																						this.setState(
																							{
																								creditNumber: e.target.value,
																							},
																							() => {
																								if (
																									this.state.creditNumber
																										.length === 16
																								) {
																									this._inputExpMonth.current.focus();
																								}
																							},
																						)
																					}
																				/>
																			</FormGroup>
																		</Col>

																		<Col sm={6}>
																			<FormGroup controlId="formBasicText">
																				<LabelContainer>
																						<div className="label-container">
																								<div className="title">{this.props.t('common:instancePrice.PaymentCredit.lorem8')}<span style={{color:'#d90101'}}>*</span></div>
																						</div>
																				</LabelContainer>

																				<InputGroup>
																					<Col sm={6} xs={6}>
																						<FormControl
																							inputRef={this._inputExpMonth}
																							type="text"
																							pattern="\d*"
																							maxLength={2}
																							value={this.state.expMonth}
																							placeholder="MM"
																							onChange={e =>
																								this.setState(
																									{
																										expMonth: e.target.value,
																									},
																									() => {
																										if (
																											this.state.expMonth
																												.length === 2
																										) {
																											this._inputExpYear.current.focus();
																										}
																									},
																								)
																							}
																						/>
																					</Col>

																					<Col sm={6} xs={6}>
																						<FormControl
																							inputRef={this._inputExpYear}
																							type="text"
																							maxLength={4}
																							value={this.state.expYear}
																							placeholder="YYYY"
																							pattern="\d*"
																							onChange={e =>
																								this.setState(
																									{
																										expYear: e.target.value,
																									},
																									() => {
																										if (
																											this.state.expYear
																												.length === 4
																										) {
																											this._inputCVV.current.focus();
																										}
																									},
																								)
																							}
																						/>
																					</Col>
																				</InputGroup>
																			</FormGroup>
																		</Col>

																		<Col sm={6}>
																			<FormGroup controlId="formBasicText">
																				<LabelContainer>
																						<div className="label-container">
																								<div className="title">CVV<span style={{color:'#d90101'}}>*</span></div>
																								<div className="counter">({`${this.state.creditCvv.length}/${3}`})</div>
																						</div>
																				</LabelContainer>
																				
																				<FormControl
																					inputRef={this._inputCVV}
																					onFocus={() =>
																						this.setState({ isFlipped: true })
																					}
																					onBlur={() =>
																						this.setState({ isFlipped: false })
																					}
																					maxLength={3}
																					value={this.state.creditCvv}
																					placeholder="XXX"
																					type="text"
																					pattern="\d*"
																					onChange={e =>
																						this.setState(
																							{
																								creditCvv: e.target.value,
																							},
																							() => {
																								if (
																									this.state.creditCvv
																										.length === 3
																								) {
																									this._inputCreditName.current.focus();
																								}
																							},
																						)
																					}
																				/>
																			</FormGroup>
																		</Col>
																		<Col sm={12}>
																			<FormGroup controlId="formBasicText">
																				<LabelContainer>
																						<div className="label-container">
																								<div className="title">{this.props.t('common:instancePrice.PaymentCredit.lorem9')}<span style={{color:'#d90101'}}>*</span></div>
																						</div>
																				</LabelContainer>

																				<FormControl
																					inputRef={this._inputCreditName}
																					type="text"
																					value={this.state.creditName}
																					placeholder={this.props.t('common:instancePrice.PaymentCredit.lorem9')}
																					onChange={e =>
																						this.setState({
																							creditName: e.target.value,
																						})
																					}
																				/>
																			</FormGroup>
																		</Col>
																	</BoxContainer>
																</div>
																<div style={{ marginTop: 20 }}>
																	<Mutation
																		mutation={
																			updateInstancePriceStepMutationClient
																		}
																	>
																		{updateInstancePriceStep => {
																			return (
																				<Mutation
																					mutation={
																						updateInstancePriceMutationClient
																					}
																				>
																					{updateInstancePrice => {
																						return (
																							<Mutation
																								mutation={addCustomerCreditCardMutation}
																								variables={{
																									token_card: {
																										id : this.state.omiseToken,
																										card :
																										{
																											id : this.state.omiseCardId
																										}
																									}
																								}}
																								update={(cache, { data }) => {
																									const creditCard = {
																										id: this.state.omiseCard.id,
																										bank:
																											this.state.omiseCard.bank,
																										last_digits:
																											this.state.omiseCard
																												.last_digits,
																										brand:
																											this.state.omiseCard.brand,
																										expiration_month:
																											this.state.omiseCard
																												.expiration_month,
																										expiration_year:
																											this.state.omiseCard
																												.expiration_year,
																										name:
																											this.state.omiseCard.name,
																										__typename: 'CreditCard',
																									};
																									updateInstancePrice({
																										variables: {
																											paymentType: 'creditCard',
																											creditCard,
																										},
																									}).then(() => {
																										updateInstancePriceStep({
																											variables: {
																												paymentCredit: false,
																												paymentCreditEdit: false,
																												paymentChanel: false,
																												paymentCreditList: true,
																											},
																										});
																									});
																								}}
																								onError={error => {
																									console.log(error)
																									this.setState({
																										openModal: true,
																									});
																								}}
																								refetchQueries={() => (['CreditCardList'])}
																							>
																								{(
																									addCreditCard,
																									{ loading, error },
																								) => {
																									return (
																										<Button
																											bsStyle="primary"
																											block
																											onClick={() =>
																												this.addCreditCardOmise(addCreditCard)
																											}
																										>
																											{this.props.t('common:instancePrice.PaymentCredit.lorem10')}
																										</Button>
																									);
																								}}
																							</Mutation>
																						);
																					}}
																				</Mutation>
																			);
																		}}
																	</Mutation>
																</div>
															</div>
														}
													/>
												);
											}}
										</Query>
									);
								}}
							</Mutation>
						);
					}}
				</Mutation>

				<ModalAlert
					show={this.state.openModal}
					icon="fal fa-exclamation-triangle"
					title={this.props.t('common:instancePrice.PaymentCredit.lorem11')}
					onCloseModal={() => this.setState({ openModal: false })}
				>
					<div className="text-center">
					{this.props.t('common:instancePrice.PaymentCredit.lorem12')}
					</div>
					<hr />
					<Button
						bsStyle="primary"
						block
						style={{ marginTop: 10 }}
						onClick={() =>
							this.setState({
								openModal: false,
							})
						}
					>
						{this.props.t('common:instancePrice.PaymentCredit.lorem13')}
					</Button>
				</ModalAlert>
			</Container>
		);
	}
}

PaymentCredit.propTypes = {};

PaymentCredit.defaultProps = {};

export default withNamespaces()(PaymentCredit);
