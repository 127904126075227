import gql from 'graphql-tag';

export default gql`
	mutation TrackingDropDelete(
    $_id: ID!
  ) {
		trackingDropDelete(
      _id: $_id
    ) {
			succeed
		}
	}
`;
