import React, { Component } from 'react';
import { Row, Col, FormGroup, ControlLabel, FormControl, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from 'src/config/color';

import {
	Card,
	BlankSpace,
	CardHeader,
	ButtonLink,
	PaymentChannelListItem,
	ModalConfirm,
	Skeleton,
} from 'src/components';

import { graphql, Query, Mutation, compose } from 'react-apollo';
import currentUser from './graphql/query/currentUser';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import updateModalLoginMutationClient from 'src/config/graphqlClient/mutation/updateModalLogin';
import accountInfoListQuery from './graphql/query/accountInfoList';
import getInstancePrice from 'src/view/InstancePrice2/graphql/query/getInstancePrice';
import client from 'src/config/client';
import { withNamespaces } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';
import { Link, Redirect } from 'react-router-dom';

const Container = styled.div`
	width: 100%;
`;

const TruckListContainer = styled(Row)`
	margin: 10px 0 0;
`;

const RegisterBusinessContainer = styled.div`
	background-color: #f9f9f9;
	border-radius: 4px;
	padding: 14px 20px;
	text-align: center;
	color: #808080;
	line-height: 1;
	margin-top: 15px;
	font-size: 18px;

	& b {
		color: #000000;
		font-size: 20px;
		line-height: 1.2;
	}
`;

const LinkStyle = styled(Link)`
	&,
	&:hover {
		text-decoration: none;
	}
`;

class PaymentChanel extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			value: '',
			onOpenErrorInvoice: false,
			showErrorCOD: false,
			CODMin: 0,
			isOpenModalPaymentPOD: false,
			isOpenModalRegisterBusiness: false,
		};
	}

	additionalVariables(instancePrice) {
		return instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional_qty: item.qty,
			};
		});
	}

	closeTap(updateInstancePriceStep) {
		updateInstancePriceStep({
			variables: {
				paymentChanel: false,
			},
		});
	}

	openCod(updateInstancePriceStep) {
		updateInstancePriceStep({
			variables: {
				paymentChanel: false,
				paymentCOD: true,
				paymentCreditList: false,
			},
		});
	}

	openCreditList(refetch, updateInstancePriceStep, updateModalLogin) {
		refetch().then(() => {
			if (this.props.data.currentUser.result) {
				updateInstancePriceStep({
					variables: {
						paymentChanel: false,
						paymentCOD: false,
						paymentCreditList: true,
					},
				});
			} else {
				updateModalLogin();
			}
		});
	}

	openInvoice(creditBalance, totalPrice) {
		if (totalPrice > creditBalance) {
			this.setState({ onOpenErrorInvoice: true });
		} else {
			const instancePriceData = client.cache.readQuery({
				query: instancePriceQueryClient,
			});

			client.writeQuery({
				query: instancePriceQueryClient,
				data: {
					instancePrice: {
						...instancePriceData.instancePrice,
						paymentType: 'Invoice',
					},
				},
			});

			const instancePriceStepData = client.cache.readQuery({
				query: instancePriceStepQueryClient,
			});

			client.writeQuery({
				query: instancePriceStepQueryClient,
				data: {
					instancePriceStep: {
						...instancePriceStepData.instancePriceStep,
						paymentChanel: false,
					},
				},
			});
		}
	}

	checkMinPriceCOD(_id, price, updateInstancePriceStep) {
		this.openCod(updateInstancePriceStep);
		this.setState({ showErrorCOD: false });
		// if(_id == '5be5057787415b16d8cf48f6'){ //4 ล้อ
		//     this.setState({"CODMin" : 2000})
		//     if(price <= 2000){
		//         this.setState({"showErrorCOD" : false});
		//         this.openCod(updateInstancePriceStep);
		//     }
		//     else{
		//         this.setState({"showErrorCOD" : true});
		//     }

		// }
		// else if(_id == '5c4ac1ea9d52c92174bf0b2f'){ //6 ล้อ
		//     this.setState({"CODMin" : 4800})
		//     if(price <= 4800){
		//         this.setState({"showErrorCOD" : false});
		//         this.openCod(updateInstancePriceStep);
		//     }
		//     else{
		//         this.setState({"showErrorCOD" : true});
		//     }
		// }
		// else{
		//     this.setState({"showErrorCOD" : false});
		//     this.openCod(updateInstancePriceStep);
		// }
	}

	getPaymentType(paymentType) {
		if (paymentType === 'creditCard') {
			return 1;
		}
		if (paymentType === 'COD') {
			return 2;
		}
		if (paymentType === 'Invoice') {
			return 3;
		}

		return null;
	}

	render() {
		return (
			<Container>
				<Card
					content={
						<Mutation mutation={updateInstancePriceStepMutationClient}>
							{updateInstancePriceStep => {
								return (
									<div>
										<CardHeader
											borderCardBottom
											title={this.props.t('common:instancePrice.paymentMethod.title')}
											onPressClose={() => this.closeTap(updateInstancePriceStep)}
										/>

										<Query query={accountInfoListQuery} fetchPolicy="network-only">
											{({
												data: accountInfoListData,
												loading: loadingAccountInfoList,
												error: errorAccountInfoList,
											}) => {
												if (loadingAccountInfoList) {
													return (
														<Skeleton width={220} height={170} style={{marginTop: 10}}>
															<rect x="0" y="0" rx="4" ry="4" width="143" height="13" />
															<rect x="0" y="24" rx="3" ry="3" width="200" height="10" />

															<rect x="0" y="59" rx="4" ry="4" width="143" height="13" />
															<rect x="0" y="83" rx="3" ry="3" width="200" height="10" />

															<rect x="0" y="118" rx="4" ry="4" width="143" height="13" />
															<rect x="0" y="142" rx="3" ry="3" width="200" height="10" />
														</Skeleton>
													);
												}

												let creditBalance = 0;
												if (
													!errorAccountInfoList && accountInfoListData.accountInfoList != undefined &&
													accountInfoListData.accountInfoList.account_business !== null
												) {
													creditBalance =
														accountInfoListData.accountInfoList.account_business
															.business_credit_approved -
														accountInfoListData.accountInfoList.account_business
															.business_credit_used;
												}

												return (
													<Mutation
														mutation={updateModalLoginMutationClient}
														variables={{ isOpen: true }}
													>
														{updateModalLogin => {
															return (
																<Query query={currentUser}>
																	{({ loading, error, data, refetch, variables }) => {
																		const instancePrice = this.props.instancePriceQueryClient
																			.instancePrice;

																		return (
																			<div>
																				<TruckListContainer>
																					<PaymentChannelListItem
																						className={`payment-channel__credit-card`}
																						onSelect={() =>
																							this.openCreditList(
																								refetch,
																								updateInstancePriceStep,
																								updateModalLogin
																							)
																						}
																						Title={this.props.t(
																							'common:instancePrice.paymentMethod.creditCard.title'
																						)}
																						LeftIcon="fal fa-credit-card"
																						LeftIconColor={color.Primary}
																						subTitle={this.props.t(
																							'common:instancePrice.paymentMethod.creditCard.subTitle'
																						)}
																						disabled={
																							!(this.props.instancePriceQueryClient.instancePrice
																								.shipmentType === 1
																								? true
																								: false)
																						}
																					/>

																					<Query
																						query={getInstancePrice}
																						variables={{
																							additional: this.additionalVariables(instancePrice),
																							settingTruckTypeId:
																								instancePrice.setting_truck_type_id,
																							address: instancePrice.shipmentAddress
																								.filter(
																									item =>
																										item.latitude !== 0 && item.longitude !== 0
																								)
																								.map(item => ({
																									lat: item.latitude,
																									lng: item.longitude,
																								})),
																							promotionName: instancePrice.promotion_name,
																							pickupDate: instancePrice.pickupDate,
																							round_trip: instancePrice.roundTrip
																								? instancePrice.roundTrip
																								: null,
																							paymentType: this.getPaymentType(
																								instancePrice.paymentType
																							),
																							shipmentType: instancePrice.shipmentType,
																							flash_deals_id: instancePrice.flashDeals.id,
																						}}
																					>
																						{({ loading, error, data, refetch, variables }) => {
																							if (loading) return null;
																							if (error) return { error };
																							const Pricing = data.getInstancePrice;
																							return (
																								<div>
																									<PaymentChannelListItem
																										className={`payment-channel__COD`}
																										onSelect={() =>
																											this.checkMinPriceCOD(
																												instancePrice.setting_truck_id,
																												Pricing.totalPrice,
																												updateInstancePriceStep
																											)
																										}
																										Title={this.props.t(
																											'common:instancePrice.paymentMethod.cod.title'
																										)}
																										LeftIcon="fal fa-hand-holding-usd"
																										LeftIconColor={color.Primary}
																										subTitle={this.props.t(
																											'common:instancePrice.paymentMethod.cod.subTitle'
																										)}
																										disabled={
																											!(
																												!(
																													this.props.instancePriceQueryClient
																														.instancePrice.promotion_name ===
																														'TDP400' ||
																													this.props.instancePriceQueryClient
																														.instancePrice.promotion_name ===
																														'TDP6WH'
																												) &&
																												this.props.instancePriceQueryClient
																													.instancePrice.shipmentType === 1
																											)
																										}
																									/>
																									<Modal show={this.state.showErrorCOD} size="lg">
																										<Modal.Body>
																											<h2>
																												{this.props.t(
																													'common:instancePrice.PaymentChanel.lorem1'
																												)}
																											</h2>
																											<p style={{ lineHeight: 1 }}>
																												{this.props.t(
																													'common:instancePrice.PaymentChanel.lorem2'
																												)}{' '}
																												<b style={{ color: color.Primary }}>
																													{this.state.CODMin.toString().replace(
																														/\B(?=(\d{3})+(?!\d))/g,
																														','
																													)}{' '}
																													{this.props.t(
																														'common:instancePrice.PaymentChanel.lorem3'
																													)}
																												</b>{' '}
																												{this.props.t(
																													'common:instancePrice.PaymentChanel.lorem4'
																												)}
																											</p>
																											<p style={{ lineHeight: 1 }}>
																												<span
																													style={{
																														width: '120px',
																														float: 'left',
																													}}
																												>
																													{this.props.t(
																														'common:instancePrice.PaymentChanel.lorem5'
																													)}
																													:{' '}
																												</span>
																												<b>
																													{Pricing.totalPrice
																														.toString()
																														.replace(
																															/\B(?=(\d{3})+(?!\d))/g,
																															','
																														)}{' '}
																													{this.props.t(
																														'common:instancePrice.PaymentChanel.lorem3'
																													)}
																												</b>
																											</p>
																										</Modal.Body>

																										<Modal.Footer>
																											<div
																												style={{
																													textAlign: 'left',
																													float: 'left',
																													lineHeight: '0.9',
																												}}
																											>
																												<small>
																													{this.props.t(
																														'common:instancePrice.PaymentChanel.lorem6'
																													)}{' '}
																													<br />
																													{this.props.t(
																														'common:instancePrice.PaymentChanel.lorem7'
																													)}{' '}
																													:{' '}
																													<a href="mailto:business@giztix.com">
																														business@giztix.com
																													</a>{' '}
																													{this.props.t(
																														'common:instancePrice.PaymentChanel.lorem8'
																													)}{' '}
																													: 02-026-6895
																												</small>
																											</div>
																											<Button
																												onClick={() =>
																													this.setState({ showErrorCOD: false })
																												}
																												bsStyle="default"
																											>
																												{this.props.t(
																													'common:instancePrice.PaymentChanel.lorem9'
																												)}
																											</Button>
																										</Modal.Footer>
																									</Modal>
																								</div>
																							);
																						}}
																					</Query>

																					{!loadingAccountInfoList &&
																					!errorAccountInfoList && accountInfoListData.accountInfoList != undefined &&
																					accountInfoListData.accountInfoList.account_business !==
																						null &&
																					accountInfoListData.accountInfoList.account_business
																						.business_status === 2 &&
																					!(
																						this.props.instancePriceQueryClient.instancePrice
																							.promotion_name === 'TDP400' ||
																						this.props.instancePriceQueryClient.instancePrice
																							.promotion_name === 'TDP6WH'
																					) ? (
																						<Query query={instancePriceQueryClient}>
																							{({ loading, error, data, refetch, variables }) => {
																								if (loading) return null;
																								if (error) return null;

																								const instancePrice = data.instancePrice;
																								return (
																									<Query
																										query={getInstancePrice}
																										variables={{
																											additional: this.additionalVariables(
																												instancePrice
																											),
																											settingTruckTypeId:
																												instancePrice.setting_truck_type_id,
																											address: instancePrice.shipmentAddress
																												.filter(
																													item =>
																														item.latitude !== 0 &&
																														item.longitude !== 0
																												)
																												.map(item => ({
																													lat: item.latitude,
																													lng: item.longitude,
																												})),
																											promotionName: instancePrice.promotion_name,
																											pickupDate: instancePrice.pickupDate,
																											round_trip: instancePrice.roundTrip
																												? instancePrice.roundTrip
																												: null,
																											paymentType: this.getPaymentType(
																												instancePrice.paymentType
																											),
																											shipmentType: instancePrice.shipmentType,
																											flash_deals_id: instancePrice.flashDeals.id,
																										}}
																									>
																										{({
																											loading,
																											error,
																											data,
																											refetch,
																											variables,
																										}) => {
																											if (loading) return null;
																											if (error) return { error };
																											const Pricing = data.getInstancePrice;
																											return (
																												<div>
																													<ModalConfirm
																														show={this.state.isOpenModalPaymentPOD}
																														onPressButtonCancel={() =>
																															this.setState({
																																isOpenModalPaymentPOD: false,
																															})
																														}
																														onPressButtonConfirm={() =>
																															this.setState(
																																{
																																	isOpenModalPaymentPOD: false,
																																},
																																() =>
																																	this.openInvoice(
																																		creditBalance,
																																		Pricing.totalPrice
																																	)
																															)
																														}
																														labelButtonConfirm={this.props.t(
																															'common:instancePrice.PaymentChanel.lorem10'
																														)}
																														labelButtonCancel={this.props.t(
																															'common:instancePrice.PaymentChanel.lorem11'
																														)}
																														titleHeader={this.props.t(
																															'common:instancePrice.PaymentChanel.lorem1'
																														)}
																														styleColorButtonConfirm="primary"
																													>
																														<div style={{ fontSize: 20 }}>
																															{this.props.t(
																																'common:instancePrice.PaymentChanel.lorem12'
																															)}
																														</div>
																														<div
																															style={{
																																fontWeight: 'bold',
																																fontSize: 22,
																															}}
																														>
																															{parseFloat(
																																accountInfoListData.accountInfoList
																																	.account_business
																																	.business_credit_approved -
																																	accountInfoListData
																																		.accountInfoList
																																		.account_business
																																		.business_credit_used
																															)
																																.toFixed(2)
																																.replace(
																																	/\d(?=(\d{3})+\.)/g,
																																	'$&,'
																																)}
																															/
																															{parseFloat(
																																accountInfoListData.accountInfoList
																																	.account_business
																																	.business_credit_approved
																															)
																																.toFixed(2)
																																.replace(
																																	/\d(?=(\d{3})+\.)/g,
																																	'$&,'
																																)}{' '}
																															{this.props.t(
																																'common:instancePrice.PaymentChanel.lorem3'
																															)}
																														</div>
																													</ModalConfirm>

																													<PaymentChannelListItem
																														className={`payment-channel__POD`}
																														onSelect={() =>
																															this.setState({
																																isOpenModalPaymentPOD: true,
																															})
																														}
																														Title={this.props.t(
																															'common:instancePrice.paymentMethod.invoice.title'
																														)}
																														LeftIcon="fal fa-file-alt"
																														LeftIconColor={color.Primary}
																														subTitle={`${this.props.t(
																															'common:instancePrice.paymentMethod.invoice.subTitle'
																														)} ${parseFloat(
																															accountInfoListData.accountInfoList
																																.account_business
																																.business_credit_used
																														)
																															.toFixed(2)
																															.replace(
																																/\d(?=(\d{3})+\.)/g,
																																'$&,'
																															)}/${parseFloat(
																															accountInfoListData.accountInfoList
																																.account_business
																																.business_credit_approved
																														)
																															.toFixed(2)
																															.replace(
																																/\d(?=(\d{3})+\.)/g,
																																'$&,'
																															)} ${this.props.t(
																															'common:instancePrice.baht'
																														)}\n${this.props.t(
																															'common:instancePrice.paymentMethod.invoice.balance'
																														)} ${parseFloat(
																															accountInfoListData.accountInfoList
																																.account_business
																																.business_credit_approved -
																																accountInfoListData.accountInfoList
																																	.account_business
																																	.business_credit_used
																														)
																															.toFixed(2)
																															.replace(
																																/\d(?=(\d{3})+\.)/g,
																																'$&,'
																															)} ${this.props.t(
																															'common:instancePrice.baht'
																														)}`}
																														// subNote="มี Cashback 1,000 บาท"
																													/>
																													<Modal
																														show={this.state.onOpenErrorInvoice}
																														size="lg"
																													>
																														<Modal.Body>
																															<h2>
																																{this.props.t(
																																	'common:instancePrice.PaymentChanel.lorem1'
																																)}
																															</h2>
																															<p style={{ lineHeight: 1 }}>
																																{this.props.t(
																																	'common:instancePrice.PaymentChanel.lorem13'
																																)}{' '}
																																<br />
																																{this.props.t(
																																	'common:instancePrice.PaymentChanel.lorem14'
																																)}
																															</p>
																															<p style={{ lineHeight: 1 }}>
																																<span
																																	style={{
																																		width: '120px',
																																		float: 'left',
																																	}}
																																>
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem5'
																																	)}
																																	:{' '}
																																</span>
																																<b>
																																	{Pricing.totalPrice
																																		.toString()
																																		.replace(
																																			/\B(?=(\d{3})+(?!\d))/g,
																																			','
																																		)}{' '}
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem3'
																																	)}
																																</b>
																																<br />
																																<span
																																	style={{
																																		width: '120px',
																																		float: 'left',
																																	}}
																																>
																																	{' '}
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem15'
																																	)}
																																	:
																																</span>
																																<b
																																	style={{
																																		color: color.Primary,
																																	}}
																																>
																																	{creditBalance
																																		.toString()
																																		.replace(
																																			/\B(?=(\d{3})+(?!\d))/g,
																																			','
																																		)}{' '}
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem3'
																																	)}
																																</b>
																															</p>
																														</Modal.Body>

																														<Modal.Footer>
																															<div
																																style={{
																																	textAlign: 'left',
																																	float: 'left',
																																	lineHeight: '0.9',
																																}}
																															>
																																<small>
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem16'
																																	)}{' '}
																																	<br />
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem7'
																																	)}{' '}
																																	:{' '}
																																	<a href="mailto:business@giztix.com">
																																		business@giztix.com
																																	</a>{' '}
																																	{this.props.t(
																																		'common:instancePrice.PaymentChanel.lorem8'
																																	)}{' '}
																																	: 02-026-6895
																																</small>
																															</div>
																															<Button
																																onClick={() =>
																																	this.setState({
																																		onOpenErrorInvoice: false,
																																	})
																																}
																																bsStyle="default"
																															>
																																{this.props.t(
																																	'common:instancePrice.PaymentChanel.lorem9'
																																)}
																															</Button>
																														</Modal.Footer>
																													</Modal>
																												</div>
																											);
																										}}
																									</Query>
																								);
																							}}
																						</Query>
																					) : (
																						<div>
																							<PaymentChannelListItem
																								className={`payment-channel__POD`}
																								onSelect={() =>
																									this.setState({
																										isOpenModalRegisterBusiness: true,
																									})
																								}
																								Title={this.props.t(
																									'common:instancePrice.paymentMethod.invoice.title'
																								)}
																								LeftIcon="fal fa-file-alt"
																								LeftIconColor={color.Primary}
																								subTitle={this.props.t(
																									'common:instancePrice.PaymentChanel.subTitleInvoiceNotBusiness'
																								)}
																								subTitleColor={`#D90101`}
																							/>

																							<RegisterBusinessContainer>
																								{this.props
																									.t('common:instancePrice.PaymentChanel.lorem17')
																									.split('\n')
																									.map(item => (
																										<div>{ReactHTMLParser(item)}</div>
																									))}
																								<div style={{ marginTop: 15 }}>
																									<Button
																										onClick={() => {
																											window.open('/giztixbusiness', '_blank');
																										}}
																										block
																										bsStyle="primary"
																									>
																										{this.props.t(
																											'common:instancePrice.PaymentChanel.lorem18'
																										)}
																									</Button>
																								</div>
																							</RegisterBusinessContainer>

																							<ModalConfirm
																								show={this.state.isOpenModalRegisterBusiness}
																								labelButtonConfirm={this.props.t(
																									'common:instancePrice.PaymentChanel.modal.buttonConfirm'
																								)}
																								labelButtonCancel={this.props.t(
																									'common:instancePrice.PaymentChanel.modal.buttonCancel'
																								)}
																								titleHeader={this.props.t(
																									'common:instancePrice.PaymentChanel.modal.title'
																								)}
																								styleColorButtonConfirm="primary"
																								onHide={() =>
																									this.setState({
																										isOpenModalRegisterBusiness: false,
																									})
																								}
																								onPressButtonConfirm={() => {
																									window.open('/giztixbusiness', '_blank');
																								}}
																								onPressButtonCancel={() =>
																									this.setState({
																										isOpenModalRegisterBusiness: false,
																									})
																								}
																							>
																								<div style={{ lineHeight: 1 }}>
																									{this.props.t(
																										'common:instancePrice.PaymentChanel.modal.content'
																									)}
																								</div>
																							</ModalConfirm>
																						</div>
																					)}
																				</TruckListContainer>
																			</div>
																		);
																	}}
																</Query>
															);
														}}
													</Mutation>
												);
											}}
										</Query>
									</div>
								);
							}}
						</Mutation>
					}
				/>
			</Container>
		);
	}
}

export default compose(
	graphql(currentUser),
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQueryClient',
	})
)(withNamespaces()(PaymentChanel));
