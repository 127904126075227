import React, { Component } from 'react';
import { Row, Tabs, Tab, Modal, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from './../../../../config/color';

import { graphql, Query, Mutation } from 'react-apollo';
import settingTruckListQuery from './graphql/query/settingTruckList';
import instancePriceStepQueryClient from './../../../../config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';

import s3 from './../../../../config/s3';

import {
	Card,
	CardHeader,
	TruckListItem,
	Skeleton,
	ModalConfirm,
} from './../../../../components';

import { withNamespaces } from "react-i18next";
import detectLanguageData from './../../function/detectLanguageData';
import client from 'src/config/client';

const Container = styled.div`
	width: 100%;

	#tab-truck .nav-tabs {
		border-bottom: 1px solid #ddd;
		box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.2);
	}

	#tab-truck .nav-tabs > li {
		float: left;
		margin-bottom: -1px;
	}

	#tab-truck .nav-tabs > li a {
		color: #000000;
	}

	#tab-truck .nav-tabs > li.active a {
		border: none;
		border-bottom: 2px solid #d90101;
		color: #d90101;
	}

	#tab-truck .tab-content {
		padding-top: 15px;
	}
`;

const TruckListContainer = styled(Row)`
	padding: 0px 10px;
`;

const TruckHeader = styled.div`
	padding: 6px 10px 5px 15px;
	background-color: #f9f9f9;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 14px;
	margin-left: -15px;
	margin-right: -15px;
`;

class SelectTruck extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			setting_truck_id: null,
			setting_truck_name: null,
			setting_truck_type_id: null,
			setting_truck_type_name: null,
			setting_truck_type_size: null,
			setting_truck_type_pic: null,
			amount: 1,
			key: 0,
			onOpenInfoLabor4WheelsBike: false,
			stepModalInfoLabor4WheelsBike: 1,
			remark: '',
			temp_setting_truck_id: null,
			temp_setting_truck_name: {
				th: null,
				en: null,
			},
			temp_setting_truck_type_id: null,
			temp_setting_truck_type_name: {
				th: null,
				en: null,
			},
			temp_setting_truck_type_size: {
				th: null,
				en: null,
			},
			temp_setting_truck_type_pic: null,
			isOpenModalChangeTruckUseFlashDeals: false,
			onOpenInfo6Wheels: false,
			onOpenInfo6WheelsCrane: false,
			onOpenInfo4Wheels: false,
		};
	}

	componentDidMount() {
		const { data } = this.props;
		this.setState({
			setting_truck_id: data.instancePrice.setting_truck_id,
			setting_truck_name: data.instancePrice.setting_truck_name,
			setting_truck_type_id: data.instancePrice.setting_truck_type_id,
			setting_truck_type_name: data.instancePrice.setting_truck_type_name,
			setting_truck_type_size: data.instancePrice.setting_truck_type_size,
			setting_truck_type_pic: data.instancePrice.setting_truck_type_pic,
			key: data.instancePrice.setting_truck_id,
		});
	}

	removePromotionTrueDigitalParkAfterSelectTruck() {
		const { data } = this.props;
		const promotionName = data.instancePrice.promotion_name;

		if (promotionName === 'TDP400' || promotionName === 'TDP6WH') {
			return null;
		}

		return promotionName;
	}

	onSelect() {
		const {
			setting_truck_type_id,
			temp_setting_truck_type_id,
		} = this.state;
		
		if(temp_setting_truck_type_id === '5bed286e2a9d8d18e851d7be') {
			this.setState({
				onOpenInfoLabor4WheelsBike: true,
			});
		} else if(
			temp_setting_truck_type_id !== setting_truck_type_id
		) {
			this.setDataTruckSelectToClient();
		}
	}

	setDataTruckSelectToState(truck, item, callback) {
		const {
			_id, 
			truck_type_name, 
			truck_type_size_lang, 
			truck_type_pic
		} = item;

		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		this.setState({
			temp_setting_truck_id: truck._id,
			temp_setting_truck_name: {
				th: truck.setting_truck_name.th,
				en: truck.setting_truck_name.en,
			},
			temp_setting_truck_type_id: _id,
			temp_setting_truck_type_name: {
				th: truck_type_name.th,
				en: truck_type_name.en,
			},
			temp_setting_truck_type_size: {
				th: truck_type_size_lang.th,
				en: truck_type_size_lang.en,
			},
			temp_setting_truck_type_pic: truck_type_pic,
			remark: instancePriceData.instancePrice.remark,
		}, () => {
			callback();
		});
	}

	setDataTruckSelectToClient() {
		const isUseFlashDeals = this.checkChangeTruckUseFlashDeals(this.state.temp_setting_truck_type_id);
		if(isUseFlashDeals) {
			this.setState({
				isOpenModalChangeTruckUseFlashDeals: true,
			});
			
			return;
		}

		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					setting_truck_id: this.state.temp_setting_truck_id,
					setting_truck_name: {
						...this.state.temp_setting_truck_name,
						__typename: 'SettingTruckName',
					},
					setting_truck_type_id: this.state.temp_setting_truck_type_id,
					setting_truck_type_name: {
						...this.state.temp_setting_truck_type_name,
						__typename: 'SettingTruckTypeName',
					},
					setting_truck_type_size: {
						...this.state.temp_setting_truck_type_size,
						__typename: 'SettingTruckTypeSize',
					},
					setting_truck_type_pic: this.state.temp_setting_truck_type_pic,
					remark: this.state.remark,
				}
			}
		});

		this.setDefaultDataWhenSelectTruck();
		this.closeSideBarSelectTruck();
	}

	setDefaultDataWhenSelectTruck() {
		const data = client.readQuery({
			query: instancePriceQueryClient,
		});

		const additional = [
			{
				_id: '5d5cf2f566085815b00c27b6',
				name: {
					th: 'ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่',
					en: 'ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่',
					__typename: 'AdditionalName',
				},
				price: 150,
				qty: 1,
				truck_type_size: {
					th: null,
					en: null,
					__typename: 'AdditionalTruckTypeSize',
				},
				address: null,
				__typename: 'Additional',
			},
		];

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...data.instancePrice,
					//เพิ่ม Additional ขนย้ายขนส่งมอเตอร์ไซค์ขนาดใหญ่ เมื่อเลือกประเภทรถขนส่งมอเตอร์ไซค์ขนาดใหญ่
					additional: data.instancePrice.setting_truck_type_id === '5bed286e2a9d8d18e851d7be' ? additional : [],
					accessory: [],
					promotion_name: this.removePromotionTrueDigitalParkAfterSelectTruck(),
					pickupDate: '',
					favoriteDriver: [],
				},
			},
		});
	}

	closeSideBarSelectTruck() {
		const instancePriceStepData = client.readQuery({
			query: instancePriceStepQueryClient,
		});

		client.writeQuery({
			query: instancePriceStepQueryClient,
			data: {
				instancePriceStep: {
					...instancePriceStepData.instancePriceStep,
					selectTruck: false,
				}
			}
		})
	}

	checkChangeTruckUseFlashDeals(settingTruckTypeId) {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		const {
			flashDeals,
			setting_truck_type_id,
		} = instancePriceData.instancePrice;

		return !!flashDeals.id && settingTruckTypeId !== setting_truck_type_id;
	}

	closeTap(updateInstancePriceStep) {
		updateInstancePriceStep({
			variables: {
				selectTruck: false,
				onOpenInfo: false,
			},
		});
	}

	handleSelect(key) {
		this.setState({ key: key });
	}

	checkActive(key) {
		if (key == this.state.key) {
			return 'active in';
		} else {
			return '';
		}
	}

	isShowSizeLabel(id) {
		if (
			id === '5cbe8678723da822892a6412' ||
			id === '5cbe8678723da822892a6413' ||
			id === '5cbe8678723da822892a6414'
		) {
			return false;
		}

		return true;
	}

	closeModalInfoLabor4WheelsBike() {
		this.setState({ 
			onOpenInfoLabor4WheelsBike: false,
			remark: '',
		}, () => {
			setTimeout(() => {
				this.setState({ stepModalInfoLabor4WheelsBike: 1 });
			}, 500);
		});
	}

	clearFlashDeals() {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					flashDeals: {
						...instancePriceData.instancePrice.flashDeals,
						id: '',
					}
				}
			}
		});
	}

	checkIsEnableTruckType(truckId, truckTypeId) {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		const {
			shipmentAddress,
			shipmentType,
		} = instancePriceData.instancePrice;

		if(
			truckTypeId === '5cbe8678723da822892a6417' &&
			shipmentType === 1 &&
			shipmentAddress.length !== 2
		) {
			return false;
		}

		if(
			truckId === '5c4ac1eb9d52c92174bf0b33' &&
			shipmentType === 1 &&
			shipmentAddress.length !== 2
		) {
			return false;
		}

		return true;
	}

	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<div className="tutorial-truck-list">
								<Card
									content={
										<div>
											<CardHeader
												borderCardBottom
												title={this.props.t('common:instancePrice.truckType.title')}
												onPressClose={() =>
													this.closeTap(updateInstancePriceStep)
												}
											/>
											<TruckListContainer>
												<div>
													<Query
														query={settingTruckListQuery}
														fetchPolicy="network-only"
														variables={{
															shipmentType: this.props.data.instancePrice.shipmentType
														}}
													>
														{({ loading, error, data, refetch }) => {
															if (loading)
																return (
																	<Skeleton
																		height={250}
																		width={300}
																		style={{ marginTop: 10 }}
																	>
																		<rect
																			x="0"
																			y="0"
																			rx="5"
																			ry="5"
																			width="50"
																			height="50"
																		/>
																		<rect
																			x="59"
																			y="0"
																			rx="4"
																			ry="4"
																			width="112"
																			height="13"
																		/>
																		<rect
																			x="59"
																			y="24"
																			rx="3"
																			ry="3"
																			width="149"
																			height="10"
																		/>
																		<rect
																			x="59"
																			y="40"
																			rx="3"
																			ry="3"
																			width="149"
																			height="10"
																		/>

																		<rect
																			x="0"
																			y="86"
																			rx="5"
																			ry="5"
																			width="50"
																			height="50"
																		/>
																		<rect
																			x="59"
																			y="86"
																			rx="4"
																			ry="4"
																			width="112"
																			height="13"
																		/>
																		<rect
																			x="59"
																			y="110"
																			rx="3"
																			ry="3"
																			width="149"
																			height="10"
																		/>
																		<rect
																			x="59"
																			y="126"
																			rx="3"
																			ry="3"
																			width="149"
																			height="10"
																		/>

																		<rect
																			x="0"
																			y="174"
																			rx="5"
																			ry="5"
																			width="50"
																			height="50"
																		/>
																		<rect
																			x="59"
																			y="174"
																			rx="4"
																			ry="4"
																			width="112"
																			height="13"
																		/>
																		<rect
																			x="59"
																			y="198"
																			rx="3"
																			ry="3"
																			width="149"
																			height="10"
																		/>
																		<rect
																			x="59"
																			y="214"
																			rx="3"
																			ry="3"
																			width="149"
																			height="10"
																		/>
																	</Skeleton>
																);

															if (error) return <div>{error.message}</div>;
															const { shipmentAddress, shipmentType } = this.props.data.instancePrice;
															return (
																<div>	
																	{
																		data.settingTruckList.map((truck, index) => (
																			<div>
																				<TruckHeader key={index}>
																					{`${this.props.t('common:instancePrice.truckType.truckTypes')} ${detectLanguageData(truck.setting_truck_name, this.props.t('common:lang'))}`}
																				</TruckHeader>

																				{ truck.setting_truck_type.filter(item => item.truck_type_enable).map((item, key, arr) => (
																					<TruckListItem
																						titleSize={this.isShowSizeLabel(item._id) ? item._id !== '5bed286e2a9d8d18e851d7be' ? this.props.t('common:instancePrice.truckType.sizeWidthLengthHeight') : '' : ''}
																						_id={item._id}
																						key={key}
																						image={`${s3}/${item.truck_type_pic}`}
																						enabled={this.checkIsEnableTruckType(item._id, truck._id)}
																						onClick={() => {
																							this.setDataTruckSelectToState(truck, item, () => {
																								this.onSelect();
																							});
																						}}
																						selected={
																							item._id ===
																							this.state.setting_truck_type_id
																						}
																						title={
																							`${
																								detectLanguageData(truck.setting_truck_name, this.props.t('common:lang'))
																							} - ${
																								detectLanguageData(item.truck_type_name, this.props.t('common:lang'))
																							}` || undefined
																						}
																						size={
																							`${
																								detectLanguageData(item.truck_type_size_lang, this.props.t('common:lang'))
																							}` || undefined
																						}
																						weightTotal={`${item._id !== '5bed286e2a9d8d18e851d7be' ? this.props.t('common:instancePrice.truckType.limitWeight') : ''} ${detectLanguageData(item.truck_type_weight_lang, this.props.t('common:lang')) ||
																							undefined}`}
																						showInfo={
																							truck._id ===
																								'5c4ac1ea9d52c92174bf0b2f' || //6Wheels
																							truck._id ===
																								'5cad5a065e962c4d15eb2daf' || //10Wheels
																							truck._id ===
																								'5cbe8678723da822892a6417'|| //trailer
																							truck._id ===
																								'5be5057787415b16d8cf48f6' //4Wheels
																								? true
																								: false
																						}
																						onClickInfo={e => {
																							e.stopPropagation();
																							this.setState(() => {
																								if(
																									truck._id === '5be5057787415b16d8cf48f6'
																								) {
																									return {
																										onOpenInfo4Wheels: true,
																									}
																								} else if(
																									item._id === '5c4ac1eb9d52c92174bf0b33'
																								) {
																									return {
																										onOpenInfo6WheelsCrane: true,
																									}
																								} else if(
																									truck._id ===
																									'5c4ac1ea9d52c92174bf0b2f'
																								) {
																									return {
																										onOpenInfo6Wheels: true,
																									};
																								} else if (
																									truck._id ===
																									'5cad5a065e962c4d15eb2daf'
																								) {
																									return {
																										onOpenInfo10Wheels: true,
																									};
																								} else if ( //trailer 40
																									truck._id ===
																									'5cbe8678723da822892a6417' &&
																									//40 GP กับ 40 HQ
																									(item._id === '5cbe8678723da822892a6413' || item._id === '5cbe8678723da822892a6414')
																								) {
																									return {
																										onOpenInfoTrailer40: true,
																									};
																								} else if ( //trailer
																									truck._id ===
																									'5cbe8678723da822892a6417'
																								) {
																									return {
																										onOpenInfoTrailer: true,
																									};
																								}
																							});
																						}}
																					/>
																				))
																			}
																			</div>
																		))
																	}

																	<ModalConfirm
																		show={this.state.onOpenInfoLabor4WheelsBike}
																		labelButtonConfirm={this.state.stepModalInfoLabor4WheelsBike === 1 ? 'ต่อไป >' : 'ยืนยัน'}
																		styleColorButtonConfirm="primary"
																		labelButtonCancel={'< กลับ'}
																		titleHeader={this.state.stepModalInfoLabor4WheelsBike === 1 ? 'เงื่อนไขบริการขนส่งมอเตอร์ไซค์ขนาดใหญ่ (บังคับ) 1/2' : 'เงื่อนไขบริการขนส่งมอเตอร์ไซค์ขนาดใหญ่ (บังคับ) 2/2'}
																		onHide={() => this.closeModalInfoLabor4WheelsBike()}
																		onPressButtonCancel={this.state.stepModalInfoLabor4WheelsBike === 1 ? undefined : this.state.stepModalInfoLabor4WheelsBike === 2 ? () => this.setState({ stepModalInfoLabor4WheelsBike: 1 }) : undefined}
																		onPressButtonConfirm={() => {
																			if(this.state.stepModalInfoLabor4WheelsBike === 1) {
																				this.setState({ stepModalInfoLabor4WheelsBike: 2 });
																				return;
																			} else if(this.state.stepModalInfoLabor4WheelsBike === 2) {
																				this.setDataTruckSelectToClient();
																				this.closeModalInfoLabor4WheelsBike();
																			}
																		}}
																		disabledButtonConfirm={this.state.remark === '' && this.state.stepModalInfoLabor4WheelsBike === 2}
																	>
																		{
																			this.state.stepModalInfoLabor4WheelsBike === 1 && (
																				<React.Fragment>
																					<div style={{lineHeight: 1.2}}>
																						<div style={{fontWeight: 'bold'}}>เงื่อนไขการใช้บริการ</div>
																						<div>
																							1. จำกัดน้ำหนักรถไม่เกิน 300 กิโลกรัม ต่อเที่ยว สูงสุด 2 คัน<br/>
																							2. ในการขนส่งมอเตอร์ไซค์ลูกค้าจำเป็นต้องมีสำเนาเอกสารรถทุกคัน และมอบให้กับพนักงานขนส่ง ณ จุดรับรถเท่านั้น<br/>
																							3. ขนส่งเฉพาะภายในประเทศเท่านั้น<br/>
																							4. ค่าขนส่งดังกล่าวรวมค่าแรงและอุปกรณ์เสริมแล้ว<br/>
																							5. ประกันการขนส่งสูงสุด 20,000 บาท* 
																						</div>
																						<br/>
																						<div style={{fontWeight: 'bold'}}>เอกสารประกอบการขนส่ง</div>
																						<div>
																							1. สำเนาเล่มทะเบียนรถมอเตอร์ไซค์ (‪‎เล่มสีเขียว)‬<br/>
																							2. สำเนาบัตรประชาชนเจ้าของรถ<br/>
																							3. สำเนาบัตรประชนคนรับรถ (ถ้ามี)
																						</div>
																						<br/>
																						<div style={{fontWeight: 'bold'}}>การปฏิเสธความรับผิดชอบ‬</div>
																						<div>
																							1. เราขอปฏิเสธความรับผิดชอบทุกกรณีหาก รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ ที่ท่านนำมาส่งกับเรา เป็นรถที่ได้ มาโดยผิดกฏหมาย ไม่ว่าจะเป็นรถขโมยมา รถสวมทะเบียนปลอม รถหนีภาษี และ อื่นๆ<br/>
																							2. ในวันส่งมอบ รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ ลูกค้าต้องตรวจสอบความเรียบร้อยของตัว รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ หากเกิดความเสียหายให้แจ้งกับพนักงานขนส่งทันที ก่อนเซ็นรับรถ "หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขนส่ง ของ Giztix ทราบโดยทันที Giztix ขอปฏิเสธความรับผิดชอบทุกกรณี และ ลูกค้าจะไม่สามารถเรียกร้องสิทธิ ค่าชดเชยใดๆทั้งสิ้น"<br/>
																							3. หากตกลงใช้บริการ ส่งรถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ กับ Giztix ถือว่าท่านได้อ่านเงื่อนไขต่างๆ ครบถ้วนทุกอย่างแล้ว และ ลูกค้าจะเรียกร้องสิทธิภายหลังไม่ได้
																						</div>
																					</div>

																					<div style={{padding: 20, margin: '10px 0px', backgroundColor: '#F9F9F9', borderRadius: 6, lineHeight: 1, textAlign: 'center'}}>
																						<div style={{color: '#D90101'}}>**ข้อควรระวัง**</div>
																						<div>
																							"หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขับรถ หรือติดต่อ Giztix โดยทันที ก่อนเซ็นรับมอบ<br/>
																							เราจะถือว่าลูกค้าได้รับรถในสภาพสมบูรณ์เรียบร้อยตามสภาพรถเดิม<br/>
																							และลูกค้าจะไม่สามารถเรียกร้องสิทธิค่าชดเชยภายหลังได้”
																						</div>
																					</div>
																				</React.Fragment>
																			)
																		}

																		{
																			this.state.stepModalInfoLabor4WheelsBike === 2 && (
																				<FormControl
																					componentClass="textarea"
																					placeholder={`ตัวอย่าง: Honda CBR 650rr ทะเบียน ก1234 กรุงเทพมหานคร, หมวกกันน็อค AGV K3 1 ใบ`}
																					value={
																						this.state.remark
																					}
																					maxLength={255}
																					onChange={(e) => this.setState({ remark: e.target.value })}
																				/>
																			)
																		}
																	</ModalConfirm>

																	<ModalConfirm
																		show={this.state.isOpenModalChangeTruckUseFlashDeals}
																		labelButtonConfirm={`ยืนยัน`}
																		styleColorButtonConfirm="primary"
																		labelButtonCancel={`ปิด`}
																		titleHeader={`คุณกำลังพลาดโอกาส ได้ราคา FLASH DEALS !!`}
																		onHide={() => {
																			this.setState({
																				isOpenModalChangeTruckUseFlashDeals: false,
																			});
																		}}
																		onPressButtonCancel={() => {
																			this.setState({
																				isOpenModalChangeTruckUseFlashDeals: false,
																			});
																		}}
																		onPressButtonConfirm={() => {
																			this.clearFlashDeals();
																			this.setDataTruckSelectToClient();
																			this.setState({
																				isOpenModalChangeTruckUseFlashDeals: false,
																			});
																		}}
																	>
																		<div style={{lineHeight: 1}}>
																			คุณต้องการเปลี่ยนแปลงประเภทรถขนส่ง ซึ่งจะส่งผลให้ไม่ตรงตามเงื่อนไขของ FLASH  DEALS ยืนยันทำรายการนี้ต่อหรือไม่
																		</div>
																	</ModalConfirm>
																</div>
															)
														}}
													</Query>
												</div>
											</TruckListContainer>
										</div>
									}
								/>
							</div>
						);
					}}
				</Mutation>

				{/* Modal 4 Wheels */}
				<ModalConfirm
					show={this.state.onOpenInfo4Wheels}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					titleHeader={this.props.t('common:instancePrice.truckType.info.title')}
					onHide={() => this.setState({ onOpenInfo4Wheels: false })}
					onPressButtonConfirm={() => this.setState({ onOpenInfo4Wheels: false })}
				>
					<div style={{lineHeight: 1}}>
						{ this.props.t('common:instancePrice.truckType.info.4wheel').split('\n').map(item => (<div>{item}</div>)) }

						<div style={{marginTop: 40,marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetimeTitel')}</div>
						<div style={{marginBottom: 40}}>
							<div style={{marginBottom: 16}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetime2h')}</div>
							<div>{this.props.t('common:instancePrice.truckType.info.calculateFreetime2hDetail')}</div>
						</div>
					</div>
				</ModalConfirm>

				{/* Modal 6 Wheels */}
				<ModalConfirm
					show={this.state.onOpenInfo6Wheels}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					titleHeader={this.props.t('common:instancePrice.truckType.info.title')}
					onHide={() => this.setState({ onOpenInfo6Wheels: false })}
					onPressButtonConfirm={() => this.setState({ onOpenInfo6Wheels: false })}
				>
					<div style={{lineHeight: 1}}>
						{ this.props.t('common:instancePrice.truckType.info.6wheel').split('\n').map(item => (<div>{item}</div>)) }

						<div style={{marginTop: 40,marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetimeTitel')}</div>
						<div style={{marginBottom: 40}}>
							<div style={{marginBottom: 16}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetime2h')}</div>
							<div>{this.props.t('common:instancePrice.truckType.info.calculateFreetime2hDetail')}</div>
						</div>
					</div>
				</ModalConfirm>

				{/* Modal 6 Wheels Crane */}
				<ModalConfirm
					show={this.state.onOpenInfo6WheelsCrane}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					onHide={() => this.setState({ onOpenInfo6WheelsCrane: false })}
					onPressButtonConfirm={() => this.setState({ onOpenInfo6WheelsCrane: false })}
				>
					<div style={{lineHeight: 1}}>
						<div style={{marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.title')}</div>
						<div style={{marginBottom: 40}}>{ this.props.t('common:instancePrice.truckType.info.6wheelCraneFreetime').split('\n').map(item => (<div>{item}</div>)) }</div>


						<div style={{marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetimeTitel')}</div>
						<div style={{marginBottom: 40}}>
							<div style={{marginBottom: 16}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetime2h')}</div>
							<div>{this.props.t('common:instancePrice.truckType.info.calculateFreetime2hDetail')}</div>
						</div>
					
						<div style={{marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.6wheelCraneTitleTermsAndConditions')}</div>
						<div>{ this.props.t('common:instancePrice.truckType.info.6wheelCraneTerms').split('\n').map(item => (<div>{item}</div>)) }</div>
					</div>
				</ModalConfirm>

				{/* Modal 10 Wheels */}
				<ModalConfirm
					show={this.state.onOpenInfo10Wheels}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					titleHeader={this.props.t('common:instancePrice.truckType.info.title')}
					onHide={() => this.setState({ onOpenInfo10Wheels: false })}
					onPressButtonConfirm={() => this.setState({ onOpenInfo10Wheels: false })}
				>
					<div style={{lineHeight: 1}}>
						{ this.props.t('common:instancePrice.truckType.info.10wheel').split('\n').map(item => (<div>{item}</div>)) }

						<div style={{marginTop: 40,marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetimeTitel')}</div>
						<div style={{marginBottom: 40,lineHeight:1}}>
							<div style={{marginBottom: 16}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetime3h')}</div>
							<div>{this.props.t('common:instancePrice.truckType.info.calculateFreetime3hDetail')}</div>
						</div>
					</div>
				</ModalConfirm>

				{/* Modal Trailer */}
				<ModalConfirm
					show={this.state.onOpenInfoTrailer}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					titleHeader={this.props.t('common:instancePrice.truckType.info.title')}
					onHide={() => this.setState({ onOpenInfoTrailer: false })}
					onPressButtonConfirm={() => this.setState({ onOpenInfoTrailer: false })}
				>
					{
						(this.props.data.instancePrice.shipmentType === 1)
						&& (
							<div style={{lineHeight: 1}}>
								{ this.props.t('common:instancePrice.truckType.info.trailer').split('\n').map(item => (<div>{item}</div>)) }
							</div>
						)
					}

					{
						(this.props.data.instancePrice.shipmentType === 2)
						&& (
							<div style={{lineHeight: 1}}>
								{ this.props.t('common:instancePrice.truckType.info.trailerImport').split('\n').map(item => (<div>{item}</div>)) }
							</div>
						)
					}

					{
						this.props.data.instancePrice.shipmentType === 3
						&& (
							<div style={{lineHeight: 1}}>
								{ this.props.t('common:instancePrice.truckType.info.trailerExport').split('\n').map(item => (<div>{item}</div>)) }
							</div>
						)
					}


					<div style={{marginTop: 40,marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetimeTitel')}</div>
					<div style={{marginBottom: 40,lineHeight:1}}>
						<div style={{marginBottom: 16}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetime3h')}</div>
						<div>{this.props.t('common:instancePrice.truckType.info.calculateFreetime3hDetail')}</div>
					</div>
				</ModalConfirm>

				{/* Modal Trailer 40 GP HQ */}
				<ModalConfirm
					show={this.state.onOpenInfoTrailer40}
					labelButtonConfirm={this.props.t('common:instancePrice.truckType.info.accept')}
					titleHeader={this.props.t('common:instancePrice.truckType.info.title')}
					onHide={() => this.setState({ onOpenInfoTrailer40: false })}
					onPressButtonConfirm={() => this.setState({ onOpenInfoTrailer40: false })}
				>
					{
						this.props.data.instancePrice.shipmentType === 2 && (
							<div style={{lineHeight: 1}}>
								{ this.props.t('common:instancePrice.truckType.info.trailer40Import').split('\n').map(item => (<div>{item}</div>)) }
							</div>
						)
					}

					{
						this.props.data.instancePrice.shipmentType === 3 && (
							<div style={{lineHeight: 1}}>
								{ this.props.t('common:instancePrice.truckType.info.trailer40Export').split('\n').map(item => (<div>{item}</div>)) }
							</div>
						)
					}

					<div style={{marginTop: 40,marginBottom: 16, fontSize: 26, fontWeight: 'bold'}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetimeTitel')}</div>
					<div style={{marginBottom: 40,lineHeight:1}}>
						<div style={{marginBottom: 16}}>{this.props.t('common:instancePrice.truckType.info.calculateFreetime4h')}</div>
						<div>{this.props.t('common:instancePrice.truckType.info.calculateFreetime4hDetail')}</div>
					</div>
				</ModalConfirm>
			</Container>
		);
	}
}

export default graphql(instancePriceQueryClient)(withNamespaces()(SelectTruck));
