import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Image} from "react-bootstrap";
import styled from 'styled-components';

const visaIcon = require('./../../assets/images/credit/visa_icon.png');
const masterCardIcon = require('./../../assets/images/credit/master_card_icon.png');
const jcbIcon = require('./../../assets/images/credit/jcb_icon.png');
const blank = require('./../../assets/images/credit/blank.png');

const checkIcon = brand => {
	if (brand === 'Visa') {
		return (
			<Image src={visaIcon} responsive />
		);
	}

	if (brand === 'MasterCard') {
		return (
			<Image src={masterCardIcon} responsive />
		);
	}

	if (brand === 'JCB') {
		return (
			<Image src={jcbIcon} responsive />
		);
	}

	if (brand === 'COD') {
		return (
			<i className="fal fa-hand-holding-usd"></i>
		);
	}

	if (brand === 'Invoice') {
		return (
			<i className="fal fa-file-alt"></i>
		);
	}

	if (brand === 'NO') {
		return (
			<i className="fal fa-usd-circle"></i>
		);
	}

	if (brand === 'blank') {
		return (
			<Image src={blank} responsive />
		);
	}

	return <i className="fal fa-credit-card"></i>;
};

const Container = styled(Row)`
margin-left:0;
margin-right:0
`;

const CreditCardIcon = props => (
	<Container {...props}>
		{props.brand ? (
			checkIcon(props.brand)
		) : (
			<i className="fal fa-credit-card"></i>
		)}
	</Container>
);

CreditCardIcon.propTypes = {
	brand: PropTypes.string,
};

CreditCardIcon.defaultProps = {};

export default CreditCardIcon;
