import React from 'react';
import styled from 'styled-components';
import { Header, ModalConfirm, Input } from 'src/components';
import { SubHeader, Card, Menu } from '../../components';
import Helmet from 'react-helmet';
import {
	Row,
	Col,
	Button,
	FormGroup,
	InputGroup,
	FormControl,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import s3 from 'src/config/s3';
import { withNamespaces } from 'react-i18next';
import { HeaderNav } from 'src/app/components';
import checkExistsTrackingURLQuery from './graphql/query/checkExistsTrackingURL';
import client from 'src/config/client';
import UploadFile from './container/UploadFile';
import ShareRoute from './container/ShareRoute';
import { Modal as ModalAntd} from 'antd';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import accountInfoListQuery from './graphql/query/accountInfoList';
import accountInfoUpdateMutation from './graphql/mutation/accountInfoUpdate';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const BackgroundContainer = styled.div`
	background-color: #f9f9f9;
	height: calc(100vh);
	overflow-y: auto;
`;

const Container = styled.div`
	padding-top: calc(150px + ${heightTopHeader}px);
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 60px;

	@media (max-width: 767px) {
		padding-top: 20px;
	}
`;

const TextHeader = styled.div`
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	position: relative;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
	margin-bottom: 40px;
`;

const Required = styled.span`
	color: #e30301;
`;

const Label = styled.div`
	font-size: 20px;
	display: inline-block;
	font-weight: bold;
`;

const Remark = styled.div`
	color: #939393;
	font-size: 18px;
	margin-top: 5px;
`;

const MessageInput = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	font-size: 18px;
	line-height: 34px;
	color: ${props => props.textColor || '#000000'};

	& i {
		margin-right: 5px;
		color: ${props => props.iconColor || '#000000'};
	}
`;

const FormUrlContainer = styled.div`
	display: flex;
	flex-direction: row;

	.form-control {
		width: 300px;
		margin-right: 10px;
	}

	@media(max-width: 1200px) {
		flex-direction: column;
		
		.input-group {
			display: flex;
			flex-direction: column;
			flex: 1;
		}

		.input-group-addon {
			font-size: 20px;
			width: 100%;
			border: 1px solid #ccc;
			border-radius: 0;
			border-bottom: 0px;
			border-top-left-radius: 4px !important;
			border-top-right-radius: 4px !important;
		}

		.form-control {
			width: 100%;
			border: 1px solid #ccc;
			border-radius: 0px;
			border-bottom-left-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
		}
	}
`;

const UploadFileContainer = styled.div`
	display: flex;
	flex-direction: column;

	& .remark {
		margin-top: 10px;
		font-size: 16px;
		color: #939393;
		line-height: 1;
	}
`;

const ButtonUploadFile = styled.button`
	width: 86px;
	height: 86px;
	background-color: white;
	border: 1px dashed #bcbcbc;
	font-size: 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	outline: none;
	border-radius: 3px;
	line-height: 1.2;

	&:hover {
		background-color: #fafafa;
	}

	&:focus,
	&:active {
		background-color: #fafafa;
	}

	& i {
		font-size: 20px;
		color: #d90101;
	}
`;

const Line = styled.div`
	height: 0;
	border-bottom: 1px solid #e3e3e3;
	margin: 40px 0px;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	& button {
		padding: 10px 20px;
		& i {
			padding-right: 5px;
		}

		&:not(:last-child) {
			margin-right: 20px;
		}
	}
`;

const ButtonStyle = styled(Button)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-width: 120px;
`;

const Loading = styled.div`
	display: flex;
	align-items: center;
	color: #939393;
	padding-left: 10px;
	height: 34px;
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& .image {
		width: 296px;
		height: 86px;
		border: 1px solid #E3E3E3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000080;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0px;
		}
	}

	.btn-hover-image {
		color: white;
		font-size: 18px;
		cursor: pointer;
		padding: 2px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: normal;

		& i {
			margin-right: 5px;
		}
	}
`;

class Tracking extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			isUrlNotExists: false,
			isLoadingFetchDataAccountInfo: false,
      		isLoadingCheckTrackingUrl: false,
			isOpenModalAlertImage: false,
			isOpenModalCreateConfirm: false,
			textAlertModalImage: '',
			accountInfoLogo: '',
			accountInfoTrackingUrl: '',
			isOpenLightBox: false,
			isOpenModalDeleteAccountInfoLogo: false,
			isRegister: false,
			showSharebook: false,
		};

    this._checkTrackingUrlDelay = null;
    this._inputUploadFile = React.createRef();
	}

	componentDidMount() {
		this.setState({ isLoadingFetchDataAccountInfo: true });
		this.fetchAccountInfoList();
	}

	async fetchAccountInfoList() {
		try {
			const { data } = await client.query({
				query: accountInfoListQuery,
				fetchPolicy: 'network-only',
			});

			const {
				account_info
			} = data.accountInfoList;

			this.setState({
				accountInfoLogo: account_info[0].account_info_logo,
				accountInfoTrackingUrl: account_info[0].account_info_tracking_url,
				isRegister: !!account_info[0].account_info_tracking_url,
				isLoadingFetchDataAccountInfo: false,
			});
		} catch(error) {
			console.log(error);
		}
	}

	async checkExistsTrackingURL(url, props) {
		try {
			this.setState({ isLoadingCheckTrackingUrl: true });

			const { data } = await client.query({
				query: checkExistsTrackingURLQuery,
				variables: {
					account_info_tracking_url: url,
				},
				fetchPolicy: 'network-only',
			});

			const { succeed } = data.checkExistsTrackingURL;

			if (succeed) {
				props.setFieldError('url', 'ถูกใช้งานแล้ว');
			}

			this.setState({
				isUrlNotExists: !succeed,
				isLoadingCheckTrackingUrl: false,
			});
		} catch (error) {
			this.setState({ isLoadingCheckTrackingUrl: false });
			console.log(error);
		}
  }
  
  checkFileTypeImage(file) {
    if(file) {
      return file.type === 'image/png' || file.type === 'image/jpeg';
    }
  }

  checkFileSizeImage(file) {
    if(file) {
      const size = parseFloat(file.size / 1024).toFixed(2);
      return size < 2048; //2Mb
    }
  }

  checkFilePixel(file) {		
		if(file) {
			return new Promise((resolve, reject) => {
				var _URL = window.URL || window.webkitURL;
				const img = new Image();
				img.onload = () => resolve(img.width === 140 && img.height === 40);
				img.onerror = () => reject(false);
				img.src = _URL.createObjectURL(file);
			});
		}
	}
	
	onDeletePhoto() {
		this.setState({
			accountInfoLogo: "",
		});
	}

	render() {
		const validateSchema = Yup.object().shape({
			url: Yup.string()
				.matches(/^[a-zA-Z0-9]{1,20}$/, 'รูปแบบไม่ถูกต้อง')
				.required('กรุณากรอกข้อมูล'),
		});

		return (
			<div>
				<Helmet>
					<title>
						จัดการหน้าติดตามงานขนส่ง | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง
						รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full={true} />
				<SubHeader backLink="/" />
				<BackgroundContainer>
					<Container>
						<Row>
							<Col lg={2} md={3} sm={12}>
								<Menu />
							</Col>
							<Col lg={8} md={6} sm={12}>
								<Card>
									<Formik
										initialValues={{
											url: this.state.accountInfoTrackingUrl,
										}}
										validationSchema={validateSchema}
										onSubmit={(values, props) => {
											this.checkExistsTrackingURL(values.url, props);
										}}
										enableReinitialize={true}
									>
										{props => {
											const {
												values,
												errors,
												touched,
												setFieldValue,
												setFieldTouched,
												handleSubmit,
											} = props;
											return (
												<React.Fragment>
													<TextHeader>{`จัดการหน้าติดตามงานขนส่ง`}</TextHeader>
													<TextSubHeader>
														สำหรับการจัดการหน้าติดตามงานขนส่ง
													</TextSubHeader>
													<ModalAntd
														title={<b>แชร์หน้าติดตามงานขนส่ง</b>}
														visible={this.state.showSharebook}
														onCancel={() => this.setState({ showSharebook: false })}
														footer={null}
														width={600}
													>
														<ShareRoute trackingUrl={values.url} />
													</ModalAntd>
													
													
													{
														this.state.isLoadingFetchDataAccountInfo && (
															<Loading style={{display: 'flex', justifyContent: 'center' }}>
																<i className="fal fa-spinner-third fa-spin" />
															</Loading>
														)
													}

													{
														!this.state.isLoadingFetchDataAccountInfo && (
															<React.Fragment>
																<Label>
																	<strong>ตั้งชื่อ URL ของคุณ</strong>
																	<Required>*</Required>
																	{` `}
																</Label>{' '}

																(ไม่เกิน 20 ตัวอักษร) ประกอบด้วยตัวอักษร A-Z หรือตัวเลข 0-9
																และห้ามใช้เครื่องหมายหรืออักขระพิเศษ เช่น !@#$% :
																<FormUrlContainer>
																	<InputGroup>
																		<InputGroup.Addon style={{ fontSize: 20 }}>
																			app.giztix.com/tracking/
																		</InputGroup.Addon>
																		<FormControl
																			type="text"
																			placeholder="ระบุ URL"
																			value={values.url}
																			onChange={event => {
																				setFieldValue('url', event.target.value);
																				setFieldTouched('url', true);
																				this.setState({ isUrlNotExists: false });
																				clearTimeout(this._checkTrackingUrlDelay);
																				this._checkTrackingUrlDelay = setTimeout(
																					() => {
																						handleSubmit();
																					},
																					500,
																				);
																			}}
																			maxLength={20}
																			minLength ={1}
																			disabled={this.state.isRegister}
																		/>
																	</InputGroup>

																	{errors.url && touched.url && (
																		<MessageInput
																			textColor="#CBCBCB"
																			iconColor="#000000"
																		>
																			<i className="fas fa-times-circle" />{' '}{errors.url}
																		</MessageInput>
																	)}

																	{this.state.isUrlNotExists && (
																		<MessageInput
																			textColor="#E30301"
																			iconColor="#E70301"
																		>
																			<i className="fas fa-check-circle" />{' '}ใช้งานได้
																		</MessageInput>
																	)}

																	{this.state.isLoadingCheckTrackingUrl && (
																		<Loading>
																			<i className="fal fa-spinner-third fa-spin" />
																		</Loading>
																	)}
																</FormUrlContainer>
																<Remark>
																	*หมายเหตุ การตั้งชื่อ URL
																	ของหน้าติดตามงานขนส่งของคุณจะตั้งได้เพียงครั้งเดียวเท่านั้น
																	กรุณาตรวจสอบข้อมูลให้ถูกต้อง{' '}
																</Remark>
																<Label style={{ marginTop: 20 }}>
																	รูปภาพโลโก้ของบริษัท:
																</Label>

																<Mutation
																	mutation={accountInfoUpdateMutation}
																>
																	{
																		(accountInfoUpdateLogo) => {
																			return (
																				<UploadFileContainer>
																					<UploadFile
																						completed={(path) => {
																							if(this.state.isRegister) {
																								accountInfoUpdateLogo({
																									variables: {
																										account_info_logo: path,
																									}
																								}).then(() => {
																									this.setState({
																										accountInfoLogo: path,
																									});
																								});
																							} else {
																								this.setState({
																									accountInfoLogo: path,
																								});
																							}
																						}}
																					>
																						{
																							(selectFile) => {
																								return (
																									<React.Fragment>
																										<input
																											accept="image/jpeg, image/png"
																											onChange={async (event) => {
																													event.persist();
																													const files = event.target.files[0];
																													
																													const checkFileTypeImage = this.checkFileTypeImage(files);
																													if(checkFileTypeImage === false) {
																														this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png, jpg เท่านั้น' });
																														return;
																													}

																													const checkFilePixel = await this.checkFilePixel(files);
																													if(checkFilePixel === false) {
																														this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาด (กว้าง x สูง) 140 x 40 พิกเซล' });
																														return;
																													}

																													const checkFileSize = this.checkFileSizeImage(files);
																													if(checkFileSize === false) {
																														this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 2MB' });
																														return;
																													}

																													selectFile(event, `upload/profile/trackingLogo_${new Date().getTime()}`);
																											}}
																											type="file" id="file"
																											ref={this._inputUploadFile}
																											style={{display: "none"}}
																										/>

																										{
																											this.state.accountInfoLogo && (
																												<ImageContainer>
																													<div className="image">
																														<div className="image-hover">
																															<div className="btn-hover-image" onClick={() => {
																																this.setState({
																																	isOpenLightBox: true,
																																});
																															}}>
																																<i className="fal fa-search"></i><span>ดูรูปภาพ</span>
																															</div>
																															<div className="btn-hover-image" onClick={() => {
																																this.setState({ isOpenModalDeleteAccountInfoLogo: true })
																															}}>
																																<i className="fal fa-trash-alt"></i><span>ลบรูปภาพ</span>
																															</div>
																														</div>
																														<img src={`${s3}/${this.state.accountInfoLogo}`} alt="" />
																													</div>
																												</ImageContainer>
																											)
																										}

																										{ this.state.isOpenLightBox && (
																											<Lightbox
																												mainSrc={`${s3}/${this.state.accountInfoLogo}`}
																												onCloseRequest={() => this.setState({ isOpenLightBox: false })}
																											/>
																										)}

																									</React.Fragment>
																								)
																							}
																						}
																					</UploadFile>

																					{
																						!this.state.accountInfoLogo && (
																							<ButtonUploadFile
																								onClick={() => this._inputUploadFile.current.click()}
																							>
																								<i className="fal fa-plus-circle" />
																								เพิ่มรูปภาพ
																							</ButtonUploadFile>
																						)
																					}
																					
																					<div className="remark">
																						ขนาด 140 x 40 pixel
																						<br />
																						ไม่เกิน 2 Mb
																					</div>
																				</UploadFileContainer>
																			)
																		}
																	}
																</Mutation>

																<ModalConfirm
																	show={this.state.isOpenModalAlertImage}
																	onPressButtonConfirm={() => this.setState({ isOpenModalAlertImage: false })}
																	styleColorButtonConfirm="primary"
																	labelButtonConfirm={`ตกลง`}
																	titleHeader={`แจ้งเตือน`}
																	onHide={() => this.setState({ isOpenModalAlertImage: false })}
																>
																	{ this.state.textAlertModalImage }
																</ModalConfirm>

																<Mutation
																	mutation={accountInfoUpdateMutation}
																	variables={{
																		account_info_tracking_url: values.url,
																		account_info_logo: this.state.accountInfoLogo,
																	}}
																	update={(cache, { data }) => {
																		if(data.accountInfoUpdate._id) {
																			this.setState({ isUrlNotExists: false, });
																			this.fetchAccountInfoList();
																		}
																	}}
																>
																	{
																		(accountInfoUpdate, { loading }) => {
																			return (				
																				<ModalConfirm
																					show={this.state.isOpenModalCreateConfirm}
																					onPressButtonConfirm={async () => {
																						this.setState({ isUrlNotExists: false });
																						await this.checkExistsTrackingURL(values.url, props);
																						if(!this.state.isUrlNotExists || errors.url) {
																							this.setState({ isOpenModalCreateConfirm: false });
																						}

																						accountInfoUpdate().then(() => {
																							this.setState({ isOpenModalCreateConfirm: false });
																						});
																					}}
																					styleColorButtonConfirm="primary"
																					labelButtonConfirm={loading ? 'ใช้ URL นี้...' : 'ใช้ URL นี้'}
																					labelButtonCancel={`ยกเลิก`}
																					onPressButtonCancel={() => this.setState({ isOpenModalCreateConfirm: false })}
																					titleHeader={`ยืนยันการสร้างหน้าติดตามงานขนส่งหรือไม่?`}
																					onHide={() => this.setState({ isOpenModalCreateConfirm: false })}
																					disabledButtonConfirm={loading}
																				>
																					<div style={{lineHeight: 1}}>
																						<div>
																							*หมายเหตุ การตั้งชื่อ URL ของหน้าติดตามงานขนส่งของคุณ จะตั้งได้เพียงครั้งเดียวเท่านั้น
																							<br/>กรุณาตรวจสอบข้อมูลให้ถูกต้อง 
																						</div>
																						<div style={{marginTop: 20}}>
																							URL: <strong>{`app.giztix.com/tracking/${values.url}`}</strong>
																						</div>
																					</div>
																				</ModalConfirm>
																			)
																		}
																	}
																</Mutation>
																
																<Mutation
																	mutation={accountInfoUpdateMutation}
																	variables={{
																		account_info_logo: '',
																	}}
																>
																	{
																		(accountInfoUpdateLogo) => {
																			return (
																				<ModalConfirm
																					show={this.state.isOpenModalDeleteAccountInfoLogo}
																					onPressButtonConfirm={() => {
																						accountInfoUpdateLogo().then(() => {
																							this.onDeletePhoto();
																							this.setState({ isOpenModalDeleteAccountInfoLogo: false });
																						});
																					}}
																					styleColorButtonConfirm="primary"
																					labelButtonConfirm={`ลบรูปภาพ`}
																					onPressButtonCancel={() => this.setState({ isOpenModalDeleteAccountInfoLogo: false })}
																					labelButtonCancel={`ปิด`}
																					titleHeader={`แจ้งเตือน`}
																					onHide={() => this.setState({ isOpenModalDeleteAccountInfoLogo: false })}
																				>
																					<div>คุณต้องการลบรูปภาพโลโก้ของบริษัท ใช่หรือไม่?</div>
																				</ModalConfirm>
																			)
																		}
																	}
																</Mutation>

																{
																	!this.state.isRegister && (
																		<React.Fragment>
																			<Line />
																			<Footer>
																				<ButtonStyle
																					bsStyle="primary"
																					disabled={!this.state.isUrlNotExists}
																					onClick={async () => {
																						this.setState({ isOpenModalCreateConfirm: true });
																					}}
																				>
																					สร้างหน้าติดตามงานขนส่ง
																				</ButtonStyle>
																			</Footer>
																		</React.Fragment>
																	)
																}
																{
																	this.state.isRegister && (
																		<React.Fragment>
																			<Line />
																			<Footer>
																				<ButtonStyle
																					bsStyle="primary"
																					onClick={async () => {
																						this.setState({ showSharebook: true })
																					}}
																				>
																					แชร์หน้าติดตามงานขนส่ง
																				</ButtonStyle>
																			</Footer>
																		</React.Fragment>
																	)
																}
															</React.Fragment>
														)
													}
												</React.Fragment>
											);
										}}
									</Formik>
								</Card>
							</Col>
							<Col lg={2} md={3} sm={12} />
						</Row>
					</Container>
				</BackgroundContainer>
			</div>
		);
	}
}

export default withNamespaces()(Tracking);
