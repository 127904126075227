import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	& .icon {
		font-size: 30px;
		line-height: 1;

		& img {
			width: 56px;
		}
	}

	& .detail {
		flex: 1;
		margin-left: 15px;
	}

	& .title {
		line-height: 1;
	}

	& .sub-title {
		font-weight: bold;
		line-height: 1;
	}
`;

const PaymentChannel = props => {
	return (
		<Container>
			<div className="icon">{props.children}</div>
			<div className="detail">
				<div className="title">
					{props.t('common:instancePrice.shipmentConfirm.transportationChargeAndPayment.payWith')}:
				</div>
				<div className="sub-title">{props.title}</div>
			</div>
		</Container>
	);
};

PaymentChannel.propTypes = {
	title: PropTypes.string,
};

PaymentChannel.defaultProps = {
	title: '',
};

export default withNamespaces()(PaymentChannel);
