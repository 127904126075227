import { PDFDocument } from 'pdf-lib';

async function mergePDFDocuments(documents) {
	const mergedPdf = await PDFDocument.create();
	for (let document of documents) {
		const existingPdfBytes = await fetch(document).then(res =>
			res.arrayBuffer()
		);
		document = await PDFDocument.load(existingPdfBytes);
		const copiedPages = await mergedPdf.copyPages(
			document,
			document.getPageIndices()
		);
		copiedPages.forEach(page => mergedPdf.addPage(page));
	}

	return await mergedPdf.save();
}

export default mergePDFDocuments;
