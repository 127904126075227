import React from 'react';
export const ShipmentDetailContext = React.createContext();

class ShipmentDetailContextProvider extends React.Component {
  state = {
    // manage popup
    customerReview: true,
    manageDriver: false,
  }

  render() {
    return (
      <ShipmentDetailContext.Provider value={{
        state: this.state,
        onCloseAll: () => {
          this.setState({
            customerReview: false,
            manageDriver: false,
          });
        },
        onCloseAllAndOpen: (popupName = []) => {
          this.setState({
            customerReview: false,
            manageDriver: false,
          }, () => {
            if(popupName.length > 0) {
              popupName.forEach(element => {
                this.setState({ [`${element}`]: true });
              });
            }
          });
        },
        onClose: (popupName = []) => {
          popupName.forEach(element => {
            this.setState({ [`${element}`]: false });
          });
        },
        onOpen: (popupName = []) => {
          popupName.forEach(element => {
            this.setState({
              [`${element}`]: true,
            });
          });
        },
      }}>
        {this.props.children}
      </ShipmentDetailContext.Provider>
    )
  }
}

export default ShipmentDetailContextProvider;