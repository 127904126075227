import gql from 'graphql-tag';

export default gql`
	mutation Login($email: String!, $password: String!) {
		signin(
			signin_type: 1
			signin_from: 2
			account_user_name: $email
			account_user_password: $password
		) {
			account_token_key
			old_user
		}
	}
`;
