import gql from 'graphql-tag';

export default gql`
    query seach(
        $shipment_id :ID
    ) { 
        invoiceList( 
            shipment_id :$shipment_id 
        ){
            shipment_id
            invoice_status
            paid_type
            paid_credit_card_detail{
                bank
                last_digits
                brand
                name
            }
            invoice_list{
                list_type
                list_name
                list_qty
                list_price
                list_total_price
            }
            promotion_code{
                name
                price
            }
            total_price
            create_date
        }
    } 
`;
