import baseUrl from './baseUrl';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { withClientState } from 'apollo-link-state';
import { ApolloLink } from 'apollo-link';
import urijs from 'urijs';

const shipmentConfirmStatus = {
	isConfirm: false,
	__typename: 'ShipmentConfirmStatus',
};

const creditCardEdit = {
	id: '',
	bank: '',
	last_digits: '',
	brand: '',
	expiration_month: '',
	expiration_year: '',
	name: '',
	card_default: false,
	__typename: 'CreditCardEdit',
};

const creditCardDefault = {
	isDefault: true,
	__typename: 'creditCardDefault',
};

const locationTemp = {
	address: '',
	addressDefault: '',
	latitude: 0,
	longitude: 0,
	latitudeCenter: 0,
	longitudeCenter: 0,
	isFitBounds: false,
	isSelected: false,
	type: '',
	shipmentAddressIndex: 0,
	datetime: '',
	__typename: 'LocationTemp',
};

const modalLogin = {
	isOpen: false,
	__typename: 'ModalLogin',
};

const modalRegister = {
	isOpen: false,
	isBack: false,
	__typename: 'ModalRegister',
};

const modalForgetPassword = {
	isOpen: false,
	__typename: 'ModalForgetPassword',
};

export const instancePriceStep = {
	createShipment: true,
	confirmShipment: false,
	searchLocation: false,
	contactInformation: false,
	selectTruck: false,
	paymentChanel: false,
	paymentCOD: false,
	additional: false,
	promotion: false,
	priceResult: false,
	selectDateTime: false,
	modalAdditional: false,
	paymentCredit: false,
	paymentCreditList: false,
	paymentCreditEdit: false,
	contact: false,
	selectDateTimeContact: false,
	selectRemark: false,
	notificationMultiDrop: false,
	__typename: 'InstancePriceStep',
};

const creditCard = {
	id: '',
	bank: '',
	last_digits: '',
	brand: '',
	expiration_month: '',
	expiration_year: '',
	name: '',
	__typename: 'CreditCard',
};

export const instancePrice = {
	// Location
	shipmentAddress: [
		{
			mode: 'pickup',
			address: '',
			latitude: 0,
			longitude: 0,
			contactName: '',
			phoneNumber: '',
			remark: '',
			paidByCOD: null,
			province: '',
			pickupDate: '',
			isSaveContact: false,
			mainAddress: '',
			secondaryAddress: '',
			__typename: 'ShipmentAddress',
		},
		{
			mode: 'delivery',
			address: '',
			latitude: 0,
			longitude: 0,
			contactName: '',
			phoneNumber: '',
			remark: '',
			paidByCOD: null,
			province: '',
			pickupDate: '',
			isSaveContact: false,
			mainAddress: '',
			secondaryAddress: '',
			__typename: 'ShipmentAddress',
		},
	],

	//Optimize Route
	optimizeRoute: false,
	tempShipmentAddress: [],

	//shipment confirm detail
	shipmentConfirmDetail: {
		jobCode: '',
		customerName: '',
		blNumber: '',
		invoiceNo: '',
		commodity: '',
		packagingType: '',
		weightPerContainer: '',
		receiptDoc: [],
		__typename: 'ShipmentConfirmDetail',
	},
	
	// Shipment
	setting_truck_id: '5be5057787415b16d8cf48f6',
	setting_truck_name: {
		th: '4 ล้อ',
		en: '4 Wheels',
		__typename: 'SettingTruckName',
	},
	setting_truck_type_id: '5be5057787415b16d8cf48f7',
	setting_truck_type_name: {
		th: 'ตู้ทึบ',
		en: 'Cabinet Truck',
		__typename: 'SettingTruckTypeName',
	},
	setting_truck_type_size: {
		th: '150 * 175 * 210 ซม.',
		en: '150 * 175 * 210 cm.',
		__typename: 'SettingTruckTypeSize',
	},
	setting_truck_type_pic: 'upload/setting_truck/4-WHEELS-CABINET1-210419.png',
	additional: [],
	creditCard,
	pickupDate: '',
	promotion_name: '',
	paymentType: '',
	remark: '',
	userRef: '',
	document: [],
	roundTrip: '',
	shipmentType: 1, //1=ภายในประเทศ 2=นำเข้าสินค้า 3=ส่งออกสินค้า
	accessory: [],
	__typename: 'InstancePrice',

	flashDeals: {
		id: '',
		name: {
			en: '',
			th: '',
			__typename: 'FlashDealsName',
		},
		settingTruckId: '',
		settingTruckName: {
			en: '',
			th: '',
			__typename: 'FlashDealsSettingTruckName',
		},
		settingTruckTypeId: '',
		truckTypeName: {
			en: '',
			th: '',
			__typename: 'FlashDealsTruckTypeName',
		},
		truckTypePic: '',
		locationStart: {
			en: '',
			th: '',
			__typename: 'FlashDealsLocationStart',
			},
		locationEnd: {
			en: '',
			th: '',
			__typename: 'FlashDealsLocationEnd',
		},
		exp: '',
		pickupStart: '',
		pickupEnd: '',
		price: 0,
		priceSale: 0,
		__typename: 'FlashDeals',
	},

	favoriteDriver: [],
};

const forgotPassword = {
	email: '',
	password: '',
	ref: '',
	otp: '',
	__typename: 'ForgotPassword',
};

const user = {
	token: '',
	__typename: 'User',
};

const register = {
	email: '',
	password: '',
	phoneCode: '',
	phoneNumber: '',
	taxID: '',
	__typename: 'Register',
};

const selectDate = {
	//สำหรับเก็บสถานะเลือกเวลาจองล่วงหน้า
	isCompleted: false,
	__typename: 'SelectDate',
};

export const defaults = {
	instancePrice,
	forgotPassword,
	user,
	register,
	modalLogin,
	modalRegister,
	modalForgetPassword,
	instancePriceStep,
	locationTemp,
	selectDate,
	creditCardEdit,
	creditCardDefault,
	shipmentConfirmStatus,
};

const httpLink = createUploadLink({
	uri: `${baseUrl}/graphql`,
});

const authContextLink = new ApolloLink((operation, forward) => {
	//รับค่า token จาก context tag mutation
	const contextToken = operation.getContext();
	if (contextToken.authorization) {
		operation.setContext({
			headers: {
				authorization: contextToken.authorization,
			},
		});
	}
	return forward(operation);
});

const authLink = setContext(async (_, { headers }) => {
	let token = localStorage.getItem('token');
	const uriRoute = urijs(window.location.href);
	const uriFragment = uriRoute.fragment(true);
	const pathname = uriFragment.pathname();
	
	/* token tracking page */
	if (
		pathname.indexOf('/trackingdetail') !== -1
	) {
		token =
			'giztix ej8RYXCKc4DM3LT5t4pjA72JfyxxtnTMP85N2hrHP38EdxYdTDTfDjgrQ6Zr7s2hgT76SzNg5XHPqBHZVJa9ZbrxUUe5jxqmQTeS77mgZFfWLDdu7mNVSXFrEqEf3J24';
	}

	return {
		headers: {
			...headers,
			authorization: token,
		},
	};
});

const cache = new InMemoryCache({
	dataIdFromObject: object => null
});

const stateLink = withClientState({
	cache,
	defaults: {
		...defaults,
	},
	resolvers: {
		Mutation: {
			updateCreditCardDefault: (_, { isDefault }, { cache }) => {
				cache.writeData({
					data: {
						creditCardDefault: {
							isDefault,
							__typename: 'CreditCardDefault',
						},
					},
				});

				return null;
			},
			updateCreditCardEdit: (
				_,
				{
					id,
					bank,
					last_digits,
					brand,
					expiration_month,
					expiration_year,
					name,
					card_default,
				},
				{ cache },
			) => {
				cache.writeData({
					data: {
						creditCardEdit: {
							id,
							bank,
							last_digits,
							brand,
							expiration_month,
							expiration_year,
							name,
							card_default,
							__typename: 'CreditCardEdit',
						},
					},
				});

				return null;
			},
			updateLocationTemp: (
				_,
				{
					address,
					addressDefault,
					latitude,
					longitude,
					latitudeCenter,
					longitudeCenter,
					isFitBounds,
					isSelected,
					type,
					shipmentAddressIndex,
					datetime,
				},
				{ cache },
			) => {
				cache.writeData({
					data: {
						locationTemp: {
							address,
							addressDefault,
							latitude,
							longitude,
							latitudeCenter,
							longitudeCenter,
							isFitBounds,
							isSelected,
							type,
							shipmentAddressIndex,
							datetime,
							__typename: 'LocationTemp',
						},
					},
				});

				return null;
			},
			updateInstancePriceStep: (
				_,
				{
					createShipment,
					searchLocation,
					contactInformation,
					confirmShipment,
					selectTruck,
					paymentChanel,
					paymentCOD,
					additional,
					promotion,
					priceResult,
					selectDateTime,
					modalAdditional,
					paymentCredit,
					paymentCreditList,
					paymentCreditEdit,
					contact,
					selectDateTimeContact,
					selectRemark,
					notificationMultiDrop,
				},
				{ cache },
			) => {
				cache.writeData({
					data: {
						instancePriceStep: {
							createShipment,
							searchLocation,
							contactInformation,
							confirmShipment,
							selectTruck,
							paymentChanel,
							paymentCOD,
							additional,
							promotion,
							priceResult,
							selectDateTime,
							modalAdditional,
							paymentCredit,
							paymentCreditList,
							paymentCreditEdit,
							contact,
							selectDateTimeContact,
							selectRemark,
							notificationMultiDrop,
							__typename: 'InstancePriceStep',
						},
					},
				});

				return null;
			},
			updateModalLogin: (_, { isOpen }, { cache }) => {
				cache.writeData({
					data: {
						modalLogin: {
							isOpen,
							__typename: 'ModalLogin',
						},
					},
				});

				return null;
			},
			updateModalRegister: (_, { isOpen, isBack }, { cache }) => {
				cache.writeData({
					data: {
						modalRegister: {
							isOpen,
							isBack,
							__typename: 'ModalRegister',
						},
					},
				});

				return null;
			},
			updateModalForgetPassword: (_, { isOpen }, { cache }) => {
				cache.writeData({
					data: {
						modalForgetPassword: {
							isOpen,
							__typename: 'ModalForgetPassword',
						},
					},
				});

				return null;
			},
			clearInstancePrice: (_, args, { cache }) => {
				cache.writeData({
					data: {
						instancePrice,
					},
				});

				return null;
			},
			updateInstancePrice: (
				_,
				{
					setting_truck_id,
					setting_truck_name,
					setting_truck_type_id,
					setting_truck_type_name,
					setting_truck_type_size,
					setting_truck_type_pic,
					additional,
					creditCard,
					pickupDate,
					promotion_name,
					paymentType,
					remark,
					userRef,
					document,
					roundTrip,
					shipmentAddress,
					optimizeRoute,
					tempShipmentAddress,
					shipmentType,
					shipmentConfirmDetail,
					accessory,
					flashDealsId,
					flashDealsName,
					flashDealsSettingTruckId,
					flashDealsSettingTruckName,
					flashDealsIdSettingTruckTypeId,
					flashDealsTruckTypeName,
					flashDealsIdTruckTypePic,
					flashDealsIdLocationStart,
					flashDealsIdLocationEnd,
					flashDealsExp,
					flashDealsIdPickupStart,
					flashDealsIdPickupEnd,
					flashDealsPrice,
					flashDealsPriceSale,
					favoriteDriver,
				},
				{ cache },
			) => {
				cache.writeData({
					data: {
						instancePrice: {
							setting_truck_id,
							setting_truck_name,
							setting_truck_type_id,
							setting_truck_type_name,
							setting_truck_type_size,
							setting_truck_type_pic,
							additional,
							creditCard,
							pickupDate,
							promotion_name,
							paymentType,
							remark,
							userRef,
							document,
							roundTrip,
							shipmentAddress,
							optimizeRoute,
							tempShipmentAddress,
							shipmentType,
							shipmentConfirmDetail,
							accessory,
							flashDeals: {
								id: flashDealsId,
								name: flashDealsName,
								settingTruckId: flashDealsSettingTruckId,
								settingTruckName: flashDealsSettingTruckName,
								settingTruckTypeId: flashDealsIdSettingTruckTypeId,
								truckTypeName: flashDealsTruckTypeName,
								truckTypePic: flashDealsIdTruckTypePic,
								locationStart: flashDealsIdLocationStart,
								locationEnd: flashDealsIdLocationEnd,
								exp: flashDealsExp,
								pickupStart: flashDealsIdPickupStart,
								pickupEnd: flashDealsIdPickupEnd,
								price: flashDealsPrice,
								priceSale: flashDealsPriceSale,
								__typename: 'FlashDeals',
							},
							favoriteDriver,
							__typename: 'InstancePrice',
						},
					},
				});

				return null;
			},
			updateForgotPassword: (_, { email, password, ref, otp }, { cache }) => {
				cache.writeData({
					data: {
						forgotPassword: {
							email,
							password,
							ref,
							otp,
							__typename: 'ForgotPassword',
						},
					},
				});

				return null;
			},
			updateToken: (_, { token }, { cache }) => {
				cache.writeData({
					data: {
						user: {
							token,
							__typename: 'User',
						},
					},
				});

				return null;
			},
			clearToken: (_, args, { cache }) => {
				cache.writeData({
					data: {
						user,
					},
				});

				return null;
			},
			register: (
				_,
				{ email, password, phoneCode, phoneNumber, taxID },
				{ cache },
			) => {
				cache.writeData({
					data: {
						register: {
							email,
							password,
							phoneCode,
							phoneNumber,
							taxID,
							__typename: 'Register',
						},
					},
				});

				return null;
			},
			updateSelectDate: (_, { isCompleted }, { cache }) => {
				cache.writeData({
					data: {
						selectDate: {
							isCompleted,
							__typename: 'SelectDate',
						},
					},
				});

				return null;
			},
			updateShipmentConfirmStatus: (_, { isConfirm }, { cache }) => {
				cache.writeData({
					data: {
						shipmentConfirmStatus: {
							isConfirm,
							__typename: 'ShipmentConfirmStatus',
						},
					},
				});

				return null;
			},
		},
	},
});

const link = ApolloLink.from([stateLink, authLink, authContextLink, httpLink]);

export default new ApolloClient({
	cache,
	link,
});