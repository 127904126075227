import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import printJS from 'print-js';
import PdfViewer from './components/PdfViewer';
import ButtonDownloadAll from './components/ButtonDownloadAll';
import mergePDFDocuments from './mergePDFDocuments';

const ModalStyle = styled(Modal)`
	& .modal-dialog {
		width: 100%;
		max-width: 1000px;
	}
`;

const ModalHeader = styled(Modal.Header)`
	& button.close {
		font-size: 30px;
	}
`;

const ButtonDownload = styled(Button)`
	padding: 0px !important;

	& a {
		padding: 6px;
		display: flex;
		color: #ffffff;
		justify-content: center;
		background-color: #d90101;
		border-radius: 3px;

		&:hover,
		&:focus {
			color: #ffffff;
		}

		&:focus {
			outline: 1px solid;
		}
	}
`;

class ModalPrintLabelPreview extends Component {
	constructor(props) {
		super(props);
	}

	onCancel = () => {
		this.props.onClose && this.props.onClose();
	};

	render() {
		const { open, linkDownloadLabelParcels } = this.props;

		return (
			<ModalStyle show={open} onHide={() => this.onCancel()}>
				<ModalHeader closeButton>
					<Modal.Title>
						<div
							style={{
								fontWeight: 'bold',
								textAlign: 'center',
								fontSize: 24,
							}}
						>
							Preview Label
						</div>
					</Modal.Title>
				</ModalHeader>
				<Modal.Body>
					{linkDownloadLabelParcels.length > 1 && (
						<div
							style={{
								padding: 8,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<ButtonDownloadAll linkLabels={linkDownloadLabelParcels} />
							<Button
								bsStyle="link"
								style={{
									color: '#1C73E8',
									fontSize: 18,
								}}
								onClick={async () => {
									const pdfBytes = await mergePDFDocuments(
										linkDownloadLabelParcels
									);
									const docUrl = URL.createObjectURL(
										new Blob([pdfBytes], { type: 'application/pdf' })
									);
									printJS(docUrl);
								}}
							>
								Print All
							</Button>
						</div>
					)}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							alignItems: 'flex-start',
							justifyContent: 'center',
						}}
					>
						{linkDownloadLabelParcels.map((item, index) => (
							<div
								key={item}
								style={{
									padding: 8,
								}}
							>
								<PdfViewer
									key={index}
									pdfUrl={item}
									onClick={() => printJS(item)}
								/>
								<ButtonDownload block style={{ marginTop: 8 }}>
									<a download target="_blank" href={item}>
										Download
									</a>
								</ButtonDownload>
							</div>
						))}
					</div>
				</Modal.Body>
			</ModalStyle>
		);
	}
}

export default ModalPrintLabelPreview;
