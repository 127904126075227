import React, { Component } from 'react';
import {
	Row,
	Col,
	Button,
	Grid,
	FormControl,
	FormGroup,
	ControlLabel,
	Image,
} from 'react-bootstrap';
import styled from 'styled-components';
import './style.css';
import color from './../../config/color';
import s3 from './../../config/s3';
import { Helmet } from 'react-helmet';
import URI from 'urijs';
import baseUrl from './../../config/baseUrl';
import appUrl from './../../config/appUrl';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { withNamespaces } from 'react-i18next';
import { Menu } from 'src/components';

//renderProps
import { CurrentUser } from '../../renderProps';

// Component
import {
	GoogleMaps,
	TrackingItem,
	TrackingItemImport,
	TrackingItemExport,
	HeaderTitel,
	ListArrow,
	FavoriteDriverDetail,
	MatchingTimeCountDown,
} from './components';

import {
	Header,
	BreadcrumbHeader,
	Card,
	BlankSpace,
	CardHeader,
	BoxBorder,
	BoxNoBorder,
	CreditCardIcon,
	BoxPriceItem,
	BoxServiceItem,
	BoxStatusDetail,
	ModalAlert,
	StarRating,
	PDFPreview,
	ModalConfirm,
} from './../../components';

import Moment from 'react-moment';
import { compose, graphql, Query, Mutation } from 'react-apollo';

import detailShipment from './graphql/query/detailShipment';
import invoiceListQuery from './graphql/query/invoiceList';
import trackingList from './graphql/query/trackingList';
import trackingUpdate from './graphql/mutation/trackingUpdate';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactHTMLParser from 'react-html-parser';
import moment from 'moment';
import dateNowQuery from './graphql/query/dateNow';
import client from 'src/config/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import urijs from 'urijs';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Dotdotdot from 'react-dotdotdot';
import detectLanguageData from './function/detectLanguageData';
import _ from 'lodash';
import { HeaderNav, SubHeaderNav } from 'src/app/components';
import {
	Avatar,
	Modal as ModalAntd,
	Input,
	Button as ButtonAntd,
	Checkbox as CheckboxAntd,
	Collapse,
} from 'antd';
import ShareRoute from './container/ShareRoute';
import CustomerReview from './container/CustomerReview';
import DetailReviewDriver from './container/DetailReviewDriver';
import ManageDriver from './container/ManageDriver';
import { ShipmentDetailContext } from './contexts/ShipmentDetailContext';
import ShipmentDetailContextProvider from './contexts/ShipmentDetailContext';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import accountInfoListQuery from './graphql/query/accountInfoList';

const ToolContainer = styled(Col)`
	padding: 0;
	float: left;
	height: calc(100vh - 110px - ${heightTopHeader}px);
`;

const SubContainer = styled(Col)`
	padding: 0 15px;
	height: calc(100vh - 160px - ${heightTopHeader}px);
`;

const DetailHeaderContainer = styled(Grid)`
	width: 100%;
	padding-top: 10px;
	border-bottom: 1px solid #e3e3e3;
`;

const TransporterContainer = styled(Row)`
	background-color: whitesmoke;
	margin-right: -20px;
	margin-left: -20px;
`;

const TransporterInner = styled(Col)`
	background-color: white;
	margin: 15px 20px;
	width: calc(100% - 40px);
	padding: 15px;
	border-radius: 4px;
	box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid #f3f3f3;
`;

const Line = styled.hr`
	height: 1px;
	margin-top: 0;
	margin-bottom: 10px;
`;

const ImageProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;

	& .image {
		width: 86px;
		height: 86px;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;
		cursor: pointer;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000030;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: block;
		}
	}
`;

const FormControlStyle = styled(FormControl)`
	font-size: 20px;
	height: 38px;
`;

const SubList = styled.div`
	color: #808080;
	line-height: 1.2;
	display: flex;
	flex-direction: row;
	margin-left: 10px;
	padding: 0 0 10px;

	& .title {
		flex: 1;
		margin-right: 10px;
	}

	& .detail {
		text-align: right;
	}
`;

const SubHeaderNavStyle = styled(SubHeaderNav)`
	position: fixed;
	top: calc(60px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		position: relative;
		top: 0;
	}
`;

const DriverDetailWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const DriverDetailContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const AvatarContainer = styled.div`
	position: relative;
	display: inline-block;

	& .ant-avatar {
		border: 1px solid #e7e7e7;
		cursor: pointer;
	}

	& .ant-avatar img {
		width: 100%;
		object-fit: cover;
		position: absolute;
		display: block;
		top: -9999px;
		bottom: -9999px;
		left: -9999px;
		right: -9999px;
		margin: auto;
	}
`;

const FavoriteIcon = styled.div`
	color: #ffffff;
	font-size: 10px;
	background-color: #de0201;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0px;
	right: 0px;
`;

const TransporterDetailContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const ShipmentNumberContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

class ShipmentDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			viewImageSrc: [],
			photoIndex: 0,
			isOpenLightbox: false,
			showRebook: false,
			showReview: false,
			ShipmentNumber: '',
			copied: false,
			shortUrl: '',
			photoProductIndexLightBox: 0,
			isOpenPhotoProductLightBox: false,
			otherImage: [],
			otherImageIndexLightBox: 0,
			isOpenPhotoOtherLightBox: false,
			isOpenConfirmImageLightBox: false,
			shipmentIdFormUrl: '',

			//cancel booking
			isOpenModalCancelBooking: false,
			isOpenModalNotCancelBooking: false,
			isOpenModalErrorCancelBooking: false,
			textCancelBooking: '', //
			modeTypeCancelBooking: null, //refund, fines
			modeTimeCancelBooking: null, //1:มากกว่า 320 นาที 2:น้อยกว่า 320 นาที
			percentCancelBooking: null, //100, 50, 0

			isDriverWorking: false,
		};
	}

	componentDidMount() {
		const uriShipment = urijs(window.location.href).query(true).shipment;
		this.setState({
			shipmentIdFormUrl: uriShipment,
		});
	}

	renderAddressMode(mode, index) {
		if (mode === 'roundtrip') {
			return `${this.props.t('common:shipmentDetail.backToPoint')} 1 :`;
		}
		return `${this.props.t('common:shipmentDetail.point')} ${index +
			1} - ${mode}`;
	}

	renderStatus(status) {
		console.log(status);
		switch (status) {
			case 2:
				return (
					<Button bsStyle="primary" size="sm" className="outline">
						{this.props.t('common:shipmentList.waitingForTransporterAccept')}
					</Button>
				);
			case 10:
				return (
					<Button bsStyle="default" size="sm">
						{this.props.t('common:shipmentList.waitingForStartShipment')}
					</Button>
				);
			case 3:
				return (
					<Button bsStyle="primary" size="sm" className="outline">
						{this.props.t('common:shipmentList.shipmentProcessing')}
					</Button>
				);
			case 4:
				return (
					<Button bsStyle="success" size="sm" className="outline">
						{this.props.t('common:shipmentList.shipmentCompleted')}
					</Button>
				);
			case 5:
				return (
					<Button bsStyle="danger" size="sm">
						{this.props.t('common:shipmentList.canceled')}
					</Button>
				);
			case 6:
				return (
					<Button bsStyle="warning" size="sm" className="outline">
						{this.props.t('common:shipmentList.shipmentHasProblem')}
					</Button>
				);
			case 7:
				return (
					<Button bsStyle="default" size="sm">
						{this.props.t('common:shipmentList.draft')}
					</Button>
				);
			case 8:
				return (
					<Button bsStyle="primary" size="sm" className="outline">
						{' '}
						<i className="fas fa-phone" />
						{this.props.t('common:shipmentList.madeAnAppointmentToCustomer')}
					</Button>
				);
			default:
				return this.props.t('common:shipmentList.allShipment');
		}
	}

	renderBadgeIcon = param => {
		switch (param) {
			case 4:
				return 'check';
			case 5:
				return 'times';
			case 6:
				return 'exclamation-triangle';
			default:
				return null;
		}
	};

	renderBadgeOutline = param => {
		switch (param) {
			case 2:
				return false;
			case 4:
				return false;
			default:
				return true;
		}
	};

	renderBadgeColor = param => {
		switch (param) {
			case 3:
				return `${color.Primary}`;
			case 4:
				return `${color.Primary}`;
			default:
				return `${color.Black}`;
		}
	};

	handleReview() {
		this.setState({
			showReview: true,
		});
	}

	handleRebooking() {
		this.setState({
			showRebook: true,
		});
	}

	renderLightboxContainner(data, index) {
		let image = [];
		for (var i = 0; i < data.length; i++) {
			const item = data[i];
			image.push(`${s3}/${item}`);
		}

		this.setState({
			isOpenLightbox: true,
			photoIndex: index,
			viewImageSrc: image,
		});
	}

	renderLightbox(mode, data, index) {
		if (mode === 'signature') {
			this.setState({
				isOpenLightbox: true,
				photoIndex: 0,
				viewImageSrc: [`${s3}/${data.signature_picture}`],
			});
		} else {
			let image = [];
			if (index == 1) {
				image.push(`${s3}/${data.back_picture}`);
				if (data.side_picture) {
					image.push(`${s3}/${data.side_picture}`);
				}
				if (data.front_picture) {
					image.push(`${s3}/${data.front_picture}`);
				}
			} else if (index == 2) {
				image.push(`${s3}/${data.side_picture}`);
				if (data.front_picture) {
					image.push(`${s3}/${data.front_picture}`);
				}
				if (data.back_picture) {
					image.push(`${s3}/${data.back_picture}`);
				}
			} else {
				image.push(`${s3}/${data.front_picture}`);

				if (data.back_picture) {
					image.push(`${s3}/${data.back_picture}`);
				}
				if (data.side_picture) {
					image.push(`${s3}/${data.side_picture}`);
				}
			}

			this.setState({
				isOpenLightbox: true,
				photoIndex: 0,
				viewImageSrc: image,
			});
		}
	}

	renderAdditionalPrice(Pricing) {
		let text = '';
		if (
			Pricing.list_name == 'รถใหญ่พิเศษ' ||
			Pricing.list_name == 'รถขนาดใหญ่พิเศษ' ||
			Pricing.list_name == 'บริการคืนใบส่งสินค้า (POD)' ||
			Pricing.list_name == 'รถตอนเดียว'
		) {
			text += `ค่า${Pricing.list_name}`;
		} else {
			text += `ค่า${Pricing.list_name} [${Pricing.list_price.toLocaleString(
				'en-US',
				{
					maximumFractionDigits: 2,
				}
			)} x ${Pricing.list_qty}]`;
		}
		return text;
	}

	renderAdditional(item) {
		let text = '';
		for (let i = 0; i < item.length; i++) {
			if (item[i].list_type == 2) {
				if (
					item[i].list_name == 'รถใหญ่พิเศษ' ||
					item[i].list_name == 'รถขนาดใหญ่พิเศษ' ||
					item[i].list_name == 'บริการคืนใบส่งสินค้า (POD)'
				) {
					text += `${item[i].list_name}`;
				} else {
					text += `${item[i].list_name} x ${item[i].list_qty}`;
				}

				if (i < item.length - 1) {
					text += ', ';
				}
			}
		}
		return text;
	}

	renderAccessory(accessoryList) {
		const accessory = accessoryList.map(
			item => item.name_lang[this.props.t('common:lang')]
		);
		const textAccessory = accessory.map((item, index, array) => {
			const text = item;
			if (index !== array.length - 1) {
				return text + ', ';
			}

			return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
		});

		return <>{textAccessory}</>;
	}

	chkAdditionalPod(shipment) {
		const additional = shipment.shipment_additional.slice();
		const additionalFilter = additional.filter(
			item => item.additional_id == '5ca46d2d910efac4f7704577'
		);
		if (additionalFilter.length === 0) {
			return false;
		}
		return true;
	}

	renderAdditionalPod(shipment) {
		const additional = shipment.shipment_additional.slice();
		const additionalFilter = additional.filter(
			item => item.additional_id == '5ca46d2d910efac4f7704577'
		);
		if (additionalFilter.length === 0) {
			return '';
		}
		return additionalFilter[0].address;
	}

	copyTextTrackingNumber(ShipmentNumber) {
		navigator.clipboard.writeText(
			`${appUrl}/trackingdetail?shipment=${ShipmentNumber}`
		);
	}

	copyText(e) {
		const copyText = document.getElementById('sharetracking_input');
		copyText.select();
		/* Copy the text inside the text field */
		document.execCommand('copy');

		this.setState({
			copied: true,
		});
	}

	renderPercentage(shipmentList) {
		const discountPrice = shipmentList.promotion_code.price;
		let distancePrice = 0;
		shipmentList.invoice_list.map((Pricing, key) => {
			if (Pricing.list_type == 1) {
				distancePrice += Pricing.list_total_price;
			}
		});
		return Math.round((discountPrice / distancePrice) * 100);
	}

	getCurrentTrackingStatus(shipmentList, trackingList) {
		const trackingLength = trackingList.tracking_detail.length;

		// default data
		let currentTrackingStatus = '';
		let currentTrackingStatusNumber = 0;
		let totalTrackingStatusNumber = 1;

		const tracking = trackingList;
		const shipmentItem = shipmentList;

		// check total status number
		if (
			shipmentItem.shipment_additional.filter(
				item => item.additional_id === '5ca46d2d910efac4f7704577'
			).length > 0
		) {
			totalTrackingStatusNumber = trackingLength * 3 + 3;
		} else {
			totalTrackingStatusNumber = trackingLength * 3 + 2;
		}

		if (shipmentItem.shipment_status === 4) {
			if (
				shipmentItem.shipment_additional.filter(
					item => item.additional_id === '5ca46d2d910efac4f7704577'
				).length > 0
			) {
				currentTrackingStatus = this.props.t(
					'common:shipmentDetail.deliveryCompleted'
				);
				currentTrackingStatusNumber = trackingLength * 3 + 3;
			} else {
				currentTrackingStatus = this.props.t(
					'common:shipmentDetail.deliveryCompleted'
				);
				currentTrackingStatusNumber = trackingLength * 3 + 2;
			}
		} else {
			if (tracking.driver_accept === 1) {
				tracking.tracking_detail
					.sort(function(a, b) {
						return a.droppoint - b.droppoint;
					})
					.map((item, index) => {
						if (item.contact_customer_date) {
							if (item.take_picture_date) {
								if (item.signature_date) {
									if (
										currentTrackingStatusNumber <
										(index + 1) * 3 + currentTrackingStatusNumber
									) {
										currentTrackingStatus = `${this.props.t(
											'common:shipmentDetail.point'
										)} ${index + 1} ${this.props.t(
											'common:shipmentDetail.confirmReceivingCargoCompleted'
										)}`;
										currentTrackingStatusNumber = (index + 1) * 3;
									}
								} else {
									if (
										currentTrackingStatusNumber <
										(index + 1) * 2 + currentTrackingStatusNumber
									) {
										currentTrackingStatus = `${this.props.t(
											'common:shipmentDetail.point'
										)} ${index + 1} ${this.props.t(
											'common:shipmentDetail.takeAPictureOfCargo'
										)}`;
										currentTrackingStatusNumber = (index + 1) * 2;
									}
								}
							} else {
								if (
									currentTrackingStatusNumber <
									(index + 1) * 1 + currentTrackingStatusNumber
								) {
									currentTrackingStatus = `${this.props.t(
										'common:shipmentDetail.point'
									)} ${index + 1} ${this.props.t(
										'common:shipmentDetail.contactToPickupPointCompleted'
									)}`;
									currentTrackingStatusNumber = (index + 1) * 1;
								}
							}
						} else {
							if (currentTrackingStatusNumber == 0) {
								currentTrackingStatus = this.props.t(
									'common:shipmentDetail.processing'
								);
								currentTrackingStatusNumber = 0;
							}
						}
					});
			} else {
				currentTrackingStatus = this.props.t(
					'common:shipmentDetail.waitingForTransporterAccept'
				);
				currentTrackingStatusNumber = 0;
			}
		}

		return {
			totalTrackingStatusNumber,
			currentTrackingStatus,
			currentTrackingStatusNumber,
		};
	}

	renderSignatureTracking(tracking, point, index) {
		const length = tracking.length;
	}

	renderTakePictureTracking(tracking, point, index) {
		const length = tracking.length;
	}

	renderContactCustomerTracking(tracking, point, index) {
		const length = point.length;
		console.log(tracking, point, index);
		if (point[index].contact_customer_date == null) {
			return (
				<div className="boxItem inProgress">
					<i className="fas fa-circle" />
					<div className="content">
						<div className="title">
							<b>
								{this.props.t('common:shipmentDetail.point')} {index + 1} -{' '}
								{this.props.t('common:shipmentDetail.contactToPickupPoint')}
							</b>{' '}
						</div>
						<div className="status">
							{this.props.t('common:shipmentDetail.processing')}
						</div>
						<div className="detail" />
					</div>
				</div>
			);
		}
		return (
			<div className="boxItem">
				<i className="fas fa-check-circle" />
				<div className="content">
					<div className="title">
						<b>
							{this.props.t('common:shipmentDetail.point')} {index + 1}
						</b>{' '}
						-{' '}
						{this.props.t(
							'common:shipmentDetail.contactToPickupPointCompleted'
						)}
					</div>
					<div className="status">
						<Moment format="DD/MM/YYYY HH:mm">
							{point[index].contact_customer_date}
						</Moment>
					</div>
					<div className="detail" />
				</div>
			</div>
		);
	}

	renderReceiptImage(shipment, mode) {
		const receipt_doc = shipment.shipment_confirm_detail.receipt_doc.map(
			item => {
				const patternFileType = /\.[0-9a-z]{1,5}$/;
				const patternCheckFileType = /^.*\.(pdf|PDF)$/;
				const thumbnail = patternCheckFileType.test(item.path)
					? `${require('src/assets/images/file-type-pdf.png')}`
					: `${s3}/${item.path}`;
				const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

				return {
					path: `${s3}/${item.path}`, //path ไฟล์
					fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
					type: type, //ประเภทไฟล์
					thumbnail: thumbnail, //รูปตัวอย่าง
				};
			}
		);
		const receiptImage = receipt_doc.map(item => {
			const patternCheckFileType = /^.*\.(pdf|PDF)$/;
			if (patternCheckFileType.test(item.path)) {
				return <PDFPreview file={item.path} />;
			}

			return item.thumbnail;
		});

		if (mode == 'view') {
			return receipt_doc;
		}

		return receiptImage;
	}

	renderConfirmImage(shipment, mode) {
		const patternCheckFileType = /^.*\.(pdf|PDF)$/;
		const shipment_confirm = {
			path: `${s3}/${shipment.shipment_confirm_document}`, //path ไฟล์
			fileType: shipment.shipment_confirm_document.match(/\.[0-9a-z]{1,5}$/)[0], //นามสกุลไฟล์
			type: patternCheckFileType.test(shipment.shipment_confirm_document)
				? 'pdf'
				: 'image', //ประเภทไฟล์
			thumbnail: patternCheckFileType.test(shipment.shipment_confirm_document)
				? `${require('src/assets/images/file-type-pdf.png')}`
				: `${s3}/${shipment.shipment_confirm_document}`, //รูปตัวอย่าง
		};

		let shipmentConfirmImage = '';
		if (patternCheckFileType.test(shipment_confirm.path)) {
			shipmentConfirmImage = <PDFPreview file={shipment_confirm.path} />;
		} else {
			shipmentConfirmImage = shipment_confirm.thumbnail;
		}

		if (mode == 'view') {
			return shipment_confirm;
		}

		return shipmentConfirmImage;
	}

	async getDataCancelBooking(shipment) {
		try {
			const {
				data: {
					dateNow: { now: currentDateTime },
				},
			} = await client.query({
				query: dateNowQuery,
				fetchPolicy: 'network-only',
			});

			const {
				pick_up_date,
				create_date,
				paid_type: paidType, //1 credit card, 2 cod, 3 invoice
				shipment_status: shipmentStatus,
			} = shipment;

			const pickUpDate = moment(pick_up_date).format('YYYY-MM-DD HH:mm');
			const createDate = moment(create_date).format('YYYY-MM-DD HH:mm');
			const bookingTime = moment(pickUpDate).diff(
				moment(createDate),
				'minutes'
			);
			const timeLeft = moment(pickUpDate).diff(
				moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss'),
				'minutes'
			);

			let percent = null;
			let modeTime = null;
			if (bookingTime >= 320) {
				modeTime = 1;
				if (timeLeft >= 300) {
					percent = paidType === 1 ? 100 : 0;
				} else if (timeLeft < 300 && timeLeft >= 180) {
					percent = 50;
				} else {
					// < 180 min
					percent = paidType === 1 ? 0 : 100;
				}
			} else {
				// < 320 min
				modeTime = 2;
				if (timeLeft >= 100) {
					percent = paidType === 1 ? 100 : 0;
				} else if (timeLeft < 100 && timeLeft >= 50) {
					percent = 50;
				} else {
					// < 50 min
					percent = paidType === 1 ? 0 : 100;
				}
			}

			return {
				percent: percent,
				paidType: paidType,
				modeType: paidType === 1 ? 'refund' : 'fines',
				modeTime: modeTime,
				shipmentStatus: shipmentStatus,
				bookingTime: bookingTime,
				timeLeft: timeLeft,
			};
		} catch (error) {
			console.log(error);
		}
	}

	async handleCancelBooking(shipment) {
		const dataCancelBooking = await this.getDataCancelBooking(shipment);
		const { percent, paidType, modeTime, modeType } = dataCancelBooking;

		/*
            percent จำนวนเปอร์เซ็นต์ที่ได้คืนหรือถูกปรับ
            paidType ประเภทช่องทางชำระเงิน
            modeTime 1=เวลาบุ๊คที่มากกว่าหรือเท่ากับ 320 นาที 2=เวลาบุ๊คที่น้อยกว่า 320 นาที
        */
		if (paidType === 1) {
			this.setState({
				modeTypeCancelBooking: modeType,
				modeTimeCancelBooking: modeTime,
				percentCancelBooking: percent,
			});
		} else if (paidType === 2 || paidType === 3) {
			this.setState({
				modeTypeCancelBooking: modeType,
				modeTimeCancelBooking: modeTime,
				percentCancelBooking: percent,
			});
		}

		if (paidType === 1) {
			this.setState({
				isOpenModalCancelBooking: true,
			});
		} else if (paidType === 2) {
			if (percent === 50 || percent === 100) {
				this.setState({
					isOpenModalNotCancelBooking: true,
				});
			} else {
				this.setState({
					isOpenModalCancelBooking: true,
				});
			}
		} else if (paidType === 3) {
			this.setState({
				isOpenModalCancelBooking: true,
			});
		}
	}

	renderConditionCancelBooking() {
		if (
			this.state.modeTypeCancelBooking === 'refund' &&
			this.state.modeTimeCancelBooking === 1
		) {
			return (
				<div>
					<b>เงื่อนไข</b>
					<br />
					1. ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 5
					ชั่วโมง ก่อนกำหนดการให้บริการ
					<br />
					2. ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 5
					ชั่วโมงก่อนกำหนดการให้บริการ
					<br />
					3. ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 3
					ชั่วโมง ก่อนกำหนดการให้บริการ
				</div>
			);
		} else if (
			this.state.modeTypeCancelBooking === 'refund' &&
			this.state.modeTimeCancelBooking === 2
		) {
			return (
				<div>
					<b>เงื่อนไข</b>
					<br />
					1. ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100
					นาที ก่อนกำหนดการให้บริการ
					<br />
					2. ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100
					นาที ก่อนกำหนดการให้บริการ
					<br />
					3. ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 50
					นาที ก่อนกำหนดการให้บริการ
				</div>
			);
		} else if (
			this.state.modeTypeCancelBooking === 'fines' &&
			this.state.modeTimeCancelBooking === 1
		) {
			return (
				<div>
					<b>เงื่อนไข</b>
					<br />
					1. ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
					ชั่วโมงก่อนกำหนดการให้บริการ
					<br />
					2. ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 5
					ชั่วโมง ก่อนกำหนดการให้บริการ
					<br />
					3. ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 3
					ชั่วโมง ก่อนกำหนดการให้บริการ
				</div>
			);
		} else if (
			this.state.modeTypeCancelBooking === 'fines' &&
			this.state.modeTimeCancelBooking === 2
		) {
			return (
				<div>
					<b>เงื่อนไข</b>
					<br />
					1. ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100
					นาที ก่อนกำหนดการให้บริการ
					<br />
					2. ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100
					นาที ก่อนกำหนดการให้บริการ
					<br />
					3. ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน
					เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 50
					นาที ก่อนกำหนดการให้บริการ
				</div>
			);
		}
	}

	renderDescriptionCancelBooking() {
		let time = '';
		if (this.state.modeTypeCancelBooking === 'refund') {
			if (
				this.state.percentCancelBooking === 100 &&
				this.state.modeTimeCancelBooking === 1
			) {
				time = 'อย่างน้อย 5 ชั่วโมง';
			} else if (
				this.state.percentCancelBooking === 50 &&
				this.state.modeTimeCancelBooking === 1
			) {
				time = 'น้อยกว่า 5 ชั่วโมง';
			} else if (
				this.state.percentCancelBooking === 0 &&
				this.state.modeTimeCancelBooking === 1
			) {
				time = 'น้อยกว่า 3 ชั่วโมง';
			} else if (
				this.state.percentCancelBooking === 100 &&
				this.state.modeTimeCancelBooking === 2
			) {
				time = 'อย่างน้อย 100 นาที';
			} else if (
				this.state.percentCancelBooking === 50 &&
				this.state.modeTimeCancelBooking === 2
			) {
				time = 'น้อยกว่า 100 นาที';
			} else if (
				this.state.percentCancelBooking === 0 &&
				this.state.modeTimeCancelBooking === 2
			) {
				time = 'น้อยกว่า 50 นาที';
			}
		} else if (this.state.modeTypeCancelBooking === 'fines') {
			if (
				this.state.percentCancelBooking === 100 &&
				this.state.modeTimeCancelBooking === 1
			) {
				time = 'น้อยกว่า 3 ชั่วโมง';
			} else if (
				this.state.percentCancelBooking === 50 &&
				this.state.modeTimeCancelBooking === 1
			) {
				time = 'น้อยกว่า 5 ชั่วโมง';
			} else if (
				this.state.percentCancelBooking === 0 &&
				this.state.modeTimeCancelBooking === 1
			) {
				time = 'อย่างน้อย 5 ชั่วโมง';
			} else if (
				this.state.percentCancelBooking === 100 &&
				this.state.modeTimeCancelBooking === 2
			) {
				time = 'น้อยกว่า 50 นาที';
			} else if (
				this.state.percentCancelBooking === 50 &&
				this.state.modeTimeCancelBooking === 2
			) {
				time = 'น้อยกว่า 100 นาที';
			} else if (
				this.state.percentCancelBooking === 0 &&
				this.state.modeTimeCancelBooking === 2
			) {
				time = 'อย่างน้อย 100 นาที';
			}
		}

		let textPercent = '';
		const { percentCancelBooking } = this.state;
		if (this.state.modeTypeCancelBooking === 'refund') {
			textPercent =
				percentCancelBooking === 100
					? 'สามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน'
					: percentCancelBooking === 50
					? 'สามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น'
					: percentCancelBooking === 0
					? 'ไม่สามารถยื่นคำร้องขอคืนเงินได้'
					: '';
		} else if (this.state.modeTypeCancelBooking === 'fines') {
			textPercent =
				percentCancelBooking === 100
					? 'ผู้ใช้บริการต้องชำระเงินเต็มจำนวน'
					: percentCancelBooking === 50
					? 'ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น'
					: percentCancelBooking === 0
					? 'สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้'
					: '';
		}

		return (
			<div>
				ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้า{`${time}`}
				<br />
				<b>{textPercent}</b>
			</div>
		);
	}

	sumInvoiceDiscount(list) {
		console.log(list);
		let total = 0;
		if (list) {
			list.map(num => {
				total += parseFloat(num.price);
			});
		}

		return total;
	}

	renderDetailNotCancelBooking() {
		let time = '';
		if (
			this.state.modeTimeCancelBooking === 1 &&
			this.state.percentCancelBooking === 50
		) {
			time = 'น้อยกว่า 5 ชั่วโมง';
		} else if (
			this.state.modeTimeCancelBooking === 1 &&
			this.state.percentCancelBooking === 100
		) {
			time = 'น้อยกว่า 3 ชั่วโมง';
		} else if (
			this.state.modeTimeCancelBooking === 2 &&
			this.state.percentCancelBooking === 50
		) {
			time = 'น้อยกว่า 100 นาที';
		} else if (
			this.state.modeTimeCancelBooking === 2 &&
			this.state.percentCancelBooking === 100
		) {
			time = 'น้อยกว่า 50 นาที';
		}

		return (
			<div>
				เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้า{time}
				<br />
				กรุณาติดต่อ ทีมงานจิซทิกซ์ เบอร์โทรศัพท์ 02-026-6895 , 02-026-6895
			</div>
		);
	}

	async handleSubmitCancelBooking(values) {
		try {
			const uriShipmentId = urijs(window.location.href).query(true).shipment;
			const { data: shipmentListData } = await client.query({
				query: detailShipment,
				variables: {
					_id: uriShipmentId,
				},
				fetchPolicy: 'network-only',
			});

			const dataCancelBooking = await this.getDataCancelBooking(
				shipmentListData.shipmentList.shipment[0]
			);
			const { shipmentStatus, percent, modeType, modeTime } = dataCancelBooking;
			const { percentCancelBooking } = this.state;
			const { data: invoiceListData } = await client.query({
				query: invoiceListQuery,
				variables: {
					shipment_id: uriShipmentId,
				},
			});

			const { total_price } = invoiceListData.invoiceList[0];
			const percentPrice = (total_price * percent) / 100;
			const detail = this.getReasonDetailCancelBooking(
				modeType,
				modeTime,
				percent
			);
			const cancelComment =
				values.reason === 'อื่นๆ' ? values.other : values.reason;

			if (
				(shipmentStatus !== 2 && shipmentStatus !== 8) || //สถานะที่ไม่ให้สามารถยกเลิกได้
				percentCancelBooking !== percent //และตรวจสอบเปอร์เซ็นต์มีการเปลี่ยนแปลงหรือไม่ ป้องกันเปิดหน้าค้างไว้
			) {
				this.setState({
					isOpenModalCancelBooking: false,
					isOpenModalErrorCancelBooking: true,
				});
				return;
			}

			await this.props
				.shipmentsUpdateMutation({
					variables: {
						id: shipmentListData.shipmentList.shipment[0]._id,
						shipmentRefund:
							modeType === 'refund'
								? {
										amount: percentPrice,
										detail: detail,
								  }
								: undefined,
						shipmentFines:
							modeType === 'fines'
								? {
										amount: percentPrice,
										detail: detail,
								  }
								: undefined,
						cancelComment: cancelComment,
						shipmentStatus: 5,
					},
					refetchQueries: ['ShipmentList'],
				})
				.then(({ data }) => {
					if (data.shipmentsUpdate._id) {
						this.setState({ isOpenModalCancelBooking: false });
					}
				})
				.catch(error => {
					console.log(error);
					this.setState({
						isOpenModalCancelBooking: false,
						isOpenModalErrorCancelBooking: true,
					});
				});
		} catch (error) {
			console.log(error);
			this.setState({
				isOpenModalCancelBooking: false,
				isOpenModalErrorCancelBooking: true,
			});
		}
	}

	getReasonDetailCancelBooking(modeType, modeTime, percent) {
		if (percent === 100 && modeType === 'refund' && modeTime === 1) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการจะได้รับเงินคืนเต็มจำนวน',
				en: '',
			};
		} else if (percent === 50 && modeType === 'refund' && modeTime === 1) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการจะได้รับเงินคืนเป็นจำนวน 50%',
				en: '',
			};
		} else if (percent === 0 && modeType === 'refund' && modeTime === 1) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 3 ชั่วโมง ผู้ใช้บริการจะไม่ได้รับเงินคืน',
				en: '',
			};
		} else if (percent === 100 && modeType === 'refund' && modeTime === 2) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการจะได้รับเงินคืนเต็มจำนวน',
				en: '',
			};
		} else if (percent === 50 && modeType === 'refund' && modeTime === 2) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการจะได้รับเงินคืนเป็นจำนวน 50%',
				en: '',
			};
		} else if (percent === 0 && modeType === 'refund' && modeTime === 2) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการจะไม่ได้รับเงินคืน',
				en: '',
			};
		}

		if (percent === 0 && modeType === 'fines' && modeTime === 1) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการไม่ต้องชำระเงิน',
				en: '',
			};
		} else if (percent === 50 && modeType === 'fines' && modeTime === 1) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการต้องชำระเงิน 50%',
				en: '',
			};
		} else if (percent === 100 && modeType === 'fines' && modeTime === 1) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ผู้ใช้บริการต้องชำระเงินเต็มจำนวน',
				en: '',
			};
		} else if (percent === 0 && modeType === 'fines' && modeTime === 2) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการไม่ต้องชำระเงิน',
				en: '',
			};
		} else if (percent === 50 && modeType === 'fines' && modeTime === 2) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการต้องชำระเงิน 50%',
				en: '',
			};
		} else if (percent === 100 && modeType === 'fines' && modeTime === 2) {
			return {
				th:
					'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการต้องชำระเงินเต็มจำนวน',
				en: '',
			};
		}
	}

	formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

			const negativeSign = amount < 0 ? '-' : '';

			let i = parseInt(
				(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
			).toString();
			let j = i.length > 3 ? i.length % 3 : 0;

			return (
				negativeSign +
				(j ? i.substr(0, j) + thousands : '') +
				i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
				(decimalCount
					? decimal +
					  Math.abs(amount - i)
							.toFixed(decimalCount)
							.slice(2)
					: '')
			);
		} catch (e) {
			console.log(e);
		}
	}

	getTimeFromMins(times) {
		var day = 0;
		var hours = 0;
		var minutes = 0;
		var seconds = 0;

		if (times >= 86400) {
			day = (times / 86400) | 0;
			times -= day * 86400;
		}
		if (times >= 3600) {
			hours = (times / 3600) | 0;
			times -= hours * 3600;
		}
		if (times >= 60) {
			minutes = (times / 60) | 0;
			times -= minutes * 60;
		}
		if (times > 0 && times < 60) {
			seconds = times % 60 | 0;
		}

		var showTime = '';

		if (day > 0) {
			showTime = `${day} วัน `;
		}
		if (hours > 0) {
			showTime += `${hours} ชั่วโมง `;
		}
		if (minutes > 0) {
			showTime += `${minutes} นาที `;
		}
		if (seconds > 0) {
			showTime += `${seconds} วินาที`;
		}
		return showTime;
	}

	getPriceMultiPoint(multiPoint) {
		if (multiPoint) {
			return multiPoint.reduce((sum, item) => {
				return sum + item.price_per_point * item.total_point;
			}, 0);
		}

		return 0;
	}

	getPromotionTitle(invoiceList) {
		const { promotion_code } = invoiceList;

		const { name, for_you, title_lang } = promotion_code;

		if (name) {
			if (for_you) {
				return (
					<div style={{ width: 230 }}>
						<Dotdotdot clamp={1}>
							{detectLanguageData(title_lang, this.props.t('common:lang'))}
						</Dotdotdot>
					</div>
				);
			}

			return `${this.props.t(
				'common:shipmentDetail.promotionCode'
			)} ${name} [${this.renderPercentage(invoiceList)}%]`;
		}

		return this.props.t('common:shipmentDetail.promotionCode');
	}

	async getDriverWorking(shipmentId) {
		try {
			const { data } = await client.query({
				query: trackingList,
				variables: {
					shipment_id: shipmentId,
				},
			});
			if (data.trackingList.tracking[0]) {
				this.setState({
					isDriverWorking: data.trackingList.tracking[0].working,
				});
			} else {
				this.setState({
					isDriverWorking: false,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	getFlashDeals(invoiceDiscount) {
		const filterFlashDeals = invoiceDiscount
			.filter(
				item =>
					item.detail !== 'Cash voucher' ||
					item.detail !== 'ส่วนลด Giztix Business'
			)
			.filter(item => String(item.detail).includes('ส่วนลด '));
		return filterFlashDeals;
	}

	checkPODProcess(shipment, tracking) {
		const sortTrackingDetail = tracking.tracking_detail.sort(
			(a, b) => b.droppoint - a.droppoint
		);
		return (
			this.chkAdditionalPod(shipment) &&
			shipment.shipment_type === 1 &&
			tracking.driver_accept === 1 &&
			sortTrackingDetail[0].signature_date !== null
		);
	}

	render() {
		const { viewImageSrc, isOpenLightbox, photoIndex } = this.state;
		const uri = new URI(`${baseUrl}/${this.props.location.search}`);
		const params = uri.search(true);
		const shipmentId = params.shipment;
		let driverId = 0;

		//Validate Yup
		const cancelBookingValidate = Yup.object().shape({
			reason: Yup.string().required('กรุณากรอกข้อมูล'),
			other: Yup.string().when('reason', {
				is: data => {
					return data === 'อื่นๆ';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
		});

		return (
			<CurrentUser>
				<div style={{ backgroundColor: '#ffffff' }}>
					<HeaderNav full />
					<Menu>
						{isOpenLightbox ? (
							viewImageSrc.length > 1 ? (
								<Lightbox
									mainSrc={viewImageSrc[photoIndex]}
									nextSrc={viewImageSrc[(photoIndex + 1) % viewImageSrc.length]}
									prevSrc={
										viewImageSrc[
											(photoIndex + viewImageSrc.length - 1) %
												viewImageSrc.length
										]
									}
									onCloseRequest={() =>
										this.setState({ isOpenLightbox: false })
									}
									onMovePrevRequest={() =>
										this.setState({
											photoIndex:
												(photoIndex + viewImageSrc.length - 1) %
												viewImageSrc.length,
										})
									}
									onMoveNextRequest={() =>
										this.setState({
											photoIndex: (photoIndex + 1) % viewImageSrc.length,
										})
									}
								/>
							) : (
								<Lightbox
									mainSrc={viewImageSrc[photoIndex]}
									onCloseRequest={() =>
										this.setState({ isOpenLightbox: false })
									}
								/>
							)
						) : null}

						<Query
							query={detailShipment}
							variables={{
								_id: shipmentId,
							}}
							fetchPolicy="network-only"
						>
							{({ loading, error, data, refetch, variables }) => {
								if (loading) return 'Loading...';
								if (error) return { error };
								const shipment = data.shipmentList.shipment;
								driverId = shipment[0].driver_user_id;
								if (driverId == null) driverId = 0;

								const documents = shipment[0].shipment_other_detail.document.map(
									item => {
										const patternFileType = /\.[0-9a-z]{1,5}$/;
										const patternCheckFileType = /^.*\.(pdf|PDF)$/;
										const thumbnail = patternCheckFileType.test(item.path)
											? `${require('src/assets/images/file-type-pdf.png')}`
											: `${s3}/${item.path}`;
										const type = patternCheckFileType.test(item.path)
											? 'pdf'
											: 'image';

										return {
											path: `${s3}/${item.path}`, //path ไฟล์
											fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
											type: type, //ประเภทไฟล์
											thumbnail: thumbnail, //รูปตัวอย่าง
										};
									}
								);

								const documentsImage = documents.map(item => {
									const patternCheckFileType = /^.*\.(pdf|PDF)$/;
									if (patternCheckFileType.test(item.path)) {
										return <PDFPreview file={item.path} />;
									}

									return item.thumbnail;
								});

								return (
									<Query
										query={invoiceListQuery}
										variables={{
											shipment_id: shipmentId,
										}}
										fetchPolicy="network-only"
									>
										{({ loading, error, data, refetch, variables }) => {
											if (loading) return 'Loading...';
											if (error) return { error };

											const invoiceList = data.invoiceList;
											const invoiceData = invoiceList[0].invoice_business_list;

											const vatList = [
												{
													name: '(2) ภาษีมูลค่าเพิ่ม VAT (7% ค่าบริการ)',
													price: invoiceList[0].total_vat7,
													priceDisplay: `${this.formatMoney(
														invoiceList[0].total_vat7
													)} ${this.props.t(
														'common:instancePrice.shipmentDetails.baht'
													)}`,
												},
											];

											invoiceList[0].wht_list.map((item, key) => {
												vatList.push({
													name: `(${key + 2}) ภาษีหัก ณ ที่จ่าย WHT (${
														item.wht
													}% )`,
													price: item.total_wht,
													priceDisplay: `-${this.formatMoney(
														item.total_wht
													)} ${this.props.t(
														'common:instancePrice.shipmentDetails.baht'
													)}`,
												});
											});

											return (
												<Row className="wrapper">
													<Helmet>
														<title>
															Shipment {`${shipment[0].shipment_number}`} |
															GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
															รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า
															ส่งสินค้าด่วน
														</title>
														<meta
															name="description"
															content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
														/>
													</Helmet>

													{this.state.isOpenModalCancelBooking && (
														<Formik
															initialValues={{
																reason: '',
																other: '',
															}}
															enableReinitialize={true}
															validationSchema={cancelBookingValidate}
															onSubmit={values => {
																this.handleSubmitCancelBooking(values);
															}}
														>
															{props => {
																const {
																	values,
																	errors,
																	touched,
																	handleChange,
																	handleBlur,
																	handleSubmit,
																} = props;
																return (
																	<ModalConfirm
																		show={this.state.isOpenModalCancelBooking}
																		onPressButtonCancel={() =>
																			this.setState({
																				isOpenModalCancelBooking: false,
																			})
																		}
																		onPressButtonConfirm={() => handleSubmit()}
																		labelButtonConfirm={this.props.t(
																			'common:shipmentDetail.modalCancelBooking.buttonConfirm'
																		)}
																		labelButtonCancel={this.props.t(
																			'common:shipmentDetail.modalCancelBooking.buttonCancel'
																		)}
																		styleColorButtonConfirm="primary"
																		titleHeader={this.props.t(
																			'common:shipmentDetail.modalCancelBooking.title'
																		)}
																		onHide={() =>
																			this.setState({
																				isOpenModalCancelBooking: false,
																			})
																		}
																	>
																		<div style={{ lineHeight: 1.1 }}>
																			{this.renderDescriptionCancelBooking()}
																			<br />
																			{this.renderConditionCancelBooking()}
																			<hr />
																			<Row>
																				<Col xs={12}>
																					<FormGroup>
																						<ControlLabel>
																							เหตุผลการยกเลิกงาน
																							<span
																								style={{ color: '#D90101' }}
																							>
																								*
																							</span>{' '}
																							<span
																								style={{ color: '#D90101' }}
																							>
																								{errors.reason &&
																									touched.reason &&
																									errors.reason}
																							</span>
																						</ControlLabel>
																						<FormControlStyle
																							componentClass="select"
																							placeholder="-- เลือก --"
																							value={values.reason}
																							name="reason"
																							onChange={handleChange}
																							onBlur={handleBlur}
																						>
																							<option value="">
																								-- เลือก --
																							</option>
																							<option value="เปลี่ยนวันใช้งาน">
																								เปลี่ยนวันใช้งาน
																							</option>
																							<option value="เปลี่ยนประเภทรถ">
																								เปลี่ยนประเภทรถ
																							</option>
																							<option value="จองซ้ำ">
																								จองซ้ำ
																							</option>
																							<option value="ต้องการเพิ่มบริการเสริม">
																								ต้องการเพิ่มบริการเสริม
																							</option>
																							<option value="เปลี่ยนเส้นทาง">
																								เปลี่ยนเส้นทาง
																							</option>
																							<option value="เลือกใช้บริการเจ้าอื่น">
																								เลือกใช้บริการเจ้าอื่น
																							</option>
																							<option value="อื่นๆ">
																								อื่นๆ
																							</option>
																						</FormControlStyle>
																					</FormGroup>

																					{values.reason === 'อื่นๆ' && (
																						<FormGroup
																							style={{ marginBottom: 0 }}
																						>
																							<FormControl
																								componentClass="textarea"
																								placeholder="กรุณากรอกเหตุผลการยกเลิกงาน"
																								value={values.other}
																								name="other"
																								onChange={handleChange}
																								onBlur={handleBlur}
																							/>
																						</FormGroup>
																					)}

																					{errors.other && touched.other && (
																						<div
																							style={{
																								color: '#D90101',
																								textAlign: 'right',
																								lineHeight: 1,
																								marginTop: 5,
																							}}
																						>
																							{errors.other}
																						</div>
																					)}
																				</Col>
																			</Row>
																		</div>
																	</ModalConfirm>
																);
															}}
														</Formik>
													)}

													<ModalConfirm
														show={this.state.isOpenModalNotCancelBooking}
														onPressButtonCancel={() =>
															this.setState({
																isOpenModalNotCancelBooking: false,
															})
														}
														labelButtonCancel={`ปิด`}
														styleColorButtonConfirm="primary"
														titleHeader={`ไม่สามารถยกเลิกงานขนส่งได้`}
														onHide={() =>
															this.setState({
																isOpenModalNotCancelBooking: false,
															})
														}
													>
														<div style={{ lineHeight: 1.1 }}>
															{this.renderDetailNotCancelBooking()}
														</div>
													</ModalConfirm>

													<ModalConfirm
														show={this.state.isOpenModalErrorCancelBooking}
														onPressButtonCancel={() =>
															this.setState({
																isOpenModalErrorCancelBooking: false,
															})
														}
														labelButtonCancel={`ปิด`}
														styleColorButtonConfirm="primary"
														titleHeader={`แจ้งเตือน`}
														onHide={() =>
															this.setState({
																isOpenModalErrorCancelBooking: false,
															})
														}
													>
														<div style={{ lineHeight: 1.1 }}>
															ไม่สามารถยกเลิกงานขนส่งได้ กรุณาลองใหม่อีกครั้ง
														</div>
													</ModalConfirm>

													{/* mainShipmentDetail */}
													<ShipmentDetailContextProvider>
														<ShipmentDetailContext.Consumer>
															{context => {
																return (
																	<React.Fragment>
																		<ToolContainer
																			className="ToolDetailWrapper"
																			id="mainShipmentDetail"
																			style={{ width: '840px' }}
																		>
																			<DetailHeaderContainer
																				style={{
																					border: '1px solid #e3e3e3',
																					borderTop: 'none',
																				}}
																			>
																				<CardHeader
																					title={`${shipment[0].shipment_number}`}
																					backLink="/shipment"
																				>
																					{(shipment[0].shipment_status === 2 ||
																						shipment[0].shipment_status ===
																							8) && (
																						<button
																							type="button"
																							className="outline btn btn-sm btn-default"
																							style={{
																								fontWeight: 'normal',
																								marginRight: '10px',
																							}}
																							onClick={() =>
																								this.handleCancelBooking(
																									shipment[0]
																								)
																							}
																						>
																							{this.props.t(
																								'common:shipmentDetail.buttonCancelBooking'
																							)}
																						</button>
																					)}
																					<button
																						type="button"
																						className="outline btn btn-sm btn-default"
																						style={{
																							fontWeight: 'normal',
																							marginRight: '10px',
																						}}
																						onClick={() =>
																							this.setState({
																								showSharebook: true,
																							})
																						}
																					>
																						{this.props.t(
																							'common:shipmentDetail.share'
																						)}
																					</button>
																					<button
																						type="button"
																						className="outline btn btn-sm btn-primary"
																						style={{ fontWeight: 'normal' }}
																						onClick={() =>
																							this.handleRebooking()
																						}
																					>
																						{this.props.t(
																							'common:shipmentDetail.reBooking'
																						)}
																					</button>
																				</CardHeader>
																			</DetailHeaderContainer>
																			<SubContainer
																				className="subDetailWrapper"
																				data-simplebar
																			>
																				{invoiceList.length > 0 && (
																					<Card
																						content={
																							<div>
																								<CardHeader
																									border
																									title={this.props.t(
																										'common:shipmentDetail.shipmentDetails'
																									)}
																								/>
																								{shipment[0].paid_type === 2 ? (
																									<BoxNoBorder
																										active={
																											shipment[0].paid_status
																												? true
																												: false
																										}
																										content={
																											<div>
																												<div className="img">
																													<i className="fal fa-hand-holding-usd" />
																												</div>
																												<div className="detail">
																													<p>
																														{this.props.t(
																															'common:shipmentDetail.payWith'
																														)}
																														: <br />
																														<b>
																															{shipment[0].shipment_address
																																.map(
																																	(
																																		item,
																																		index
																																	) => ({
																																		...item,
																																		index,
																																	})
																																)
																																.filter(
																																	item =>
																																		item.paid_by_cod ===
																																		1
																																)
																																.map(
																																	item =>
																																		`${this.props.t(
																																			'common:shipmentDetail.payCashAtPoint'
																																		)} ${item.index +
																																			1} - ${
																																			item.mode
																																		}`
																																)}
																														</b>
																													</p>
																												</div>
																											</div>
																										}
																									/>
																								) : shipment[0].paid_type ===
																								  1 ? (
																									<BoxNoBorder
																										active={
																											shipment[0].paid_status
																												? true
																												: false
																										}
																										content={
																											<div>
																												<div className="img">
																													<CreditCardIcon
																														brand={
																															invoiceList[0]
																																.paid_credit_card_detail
																																.brand
																														}
																													/>
																												</div>
																												<div className="detail">
																													<p>
																														{this.props.t(
																															'common:shipmentDetail.shipmentDetails'
																														)}
																														: <br />
																														<b>
																															{`XXXX  XXXX  XXXX  ${invoiceList[0].paid_credit_card_detail.last_digits}`}
																														</b>
																													</p>
																												</div>
																											</div>
																										}
																									/>
																								) : null}

																								{/* Price */}
																								{invoiceList.length > 0 && (
																									<div
																										style={{
																											marginTop: '15px',
																										}}
																									>
																										<BoxPriceItem
																											title={<b>ค่าขนส่ง</b>}
																										/>
																										{invoiceData.map(
																											(list, key) =>
																												list.datamaster_service_category_prefix_id !=
																													'5e54cef8e99db3b888a2b749' &&
																												list.datamaster_service_category_prefix_id !=
																													'5e54cef8e99db3b888a2b755' &&
																												key == 0 && (
																													<BoxPriceItem
																														key={key}
																														title={
																															list.invoice_business_list_detail
																														}
																														price={`${(
																															list.invoice_business_list_total_price -
																															list.invoice_business_list_discount +
																															this.sumInvoiceDiscount(
																																invoiceList[0]
																																	.invoice_discount
																															) -
																															this.getPriceMultiPoint(
																																shipment[0]
																																	.multi_point
																															)
																														)
																															.toString()
																															.replace(
																																/\B(?=(\d{3})+(?!\d))/g,
																																','
																															)} ${this.props.t(
																															'common:shipmentDetail.baht'
																														)}`}
																													/>
																												)
																										)}
																										{shipment[0]
																											.shipment_address.length >
																											2 &&
																										shipment[0].round_trip !==
																											1 &&
																										shipment[0]
																											.shipment_type === 1 ? (
																											<div>
																												<BoxPriceItem
																													title={`${this.props.t(
																														'common:shipmentDetail.multiRouteServiceCharge'
																													)}`}
																												/>

																												<SubList>
																													<div className="title">
																														{this.props.t(
																															'common:shipmentDetail.subList.multidrop.01'
																														)}
																													</div>
																													<div className="detail">
																														{this.props.t(
																															'common:shipmentDetail.free'
																														)}
																													</div>
																												</SubList>

																												{shipment[0].multi_point.filter(
																													item =>
																														item.distance_point ===
																														1
																												)[0].total_point > 0 &&
																													shipment[0].multi_point
																														.filter(
																															item =>
																																item.distance_point ===
																																1
																														)
																														.map(item => (
																															<SubList>
																																<div className="title">
																																	{this.props.t(
																																		'common:shipmentDetail.subList.multidrop.02'
																																	)}{' '}
																																	[
																																	{
																																		item.price_per_point
																																	}{' '}
																																	x{' '}
																																	{
																																		item.total_point
																																	}
																																	]
																																</div>
																																<div className="detail">{`${this.formatMoney(
																																	item.price_per_point *
																																		item.total_point,
																																	0
																																)} ${this.props.t(
																																	'common:shipmentDetail.baht'
																																)}`}</div>
																															</SubList>
																														))}

																												{shipment[0].multi_point.filter(
																													item =>
																														item.distance_point ===
																														2
																												)[0].total_point > 0 &&
																													shipment[0].multi_point
																														.filter(
																															item =>
																																item.distance_point ===
																																2
																														)
																														.map(item => (
																															<SubList>
																																<div className="title">
																																	{this.props.t(
																																		'common:shipmentDetail.subList.multidrop.03'
																																	)}{' '}
																																	[
																																	{
																																		item.price_per_point
																																	}{' '}
																																	x{' '}
																																	{
																																		item.total_point
																																	}
																																	]
																																</div>
																																<div className="detail">{`${this.formatMoney(
																																	item.price_per_point *
																																		item.total_point,
																																	0
																																)} ${this.props.t(
																																	'common:shipmentDetail.baht'
																																)}`}</div>
																															</SubList>
																														))}

																												<BoxPriceItem
																													title={`${this.props.t(
																														'common:shipmentDetail.totalPriceMultiDrop'
																													)}`}
																													price={`${this.formatMoney(
																														this.getPriceMultiPoint(
																															shipment[0]
																																.multi_point
																														),
																														0
																													)} ${this.props.t(
																														'common:shipmentDetail.baht'
																													)}`}
																												/>
																											</div>
																										) : null}

																										{invoiceData.map(
																											(list, key) =>
																												list.datamaster_service_category_prefix_id !=
																													'5e54cef8e99db3b888a2b749' &&
																												list.datamaster_service_category_prefix_id !=
																													'5e54cef8e99db3b888a2b755' &&
																												key != 0 && (
																													<BoxPriceItem
																														key={key}
																														title={
																															list.invoice_business_list_detail
																														}
																														qty={`[${list.invoice_business_list_price.toLocaleString(
																															'en-US',
																															{
																																maximumFractionDigits: 2,
																															}
																														)} x ${
																															list.invoice_business_list_qty
																														}]`}
																														price={`${(
																															list.invoice_business_list_total_price -
																															list.invoice_business_list_discount
																														)
																															.toString()
																															.replace(
																																/\B(?=(\d{3})+(?!\d))/g,
																																','
																															)} ${this.props.t(
																															'common:shipmentDetail.baht'
																														)}`}
																													/>
																												)
																										)}

																										{_.some(
																											invoiceList[0]
																												.invoice_discount,
																											[
																												'detail',
																												'ส่วนลด Giztix Business',
																											]
																										) && (
																											<BoxPriceItem
																												title={`
																														${
																															invoiceList[0].invoice_discount.filter(
																																item =>
																																	item.detail ===
																																	'ส่วนลด Giztix Business'
																															)[0].detail
																														} 
																														[${
																															invoiceList[0].invoice_discount.filter(
																																item =>
																																	item.detail ===
																																	'ส่วนลด Giztix Business'
																															)[0].percent
																														}%]
																													`}
																												price={`-${invoiceList[0].invoice_discount
																													.filter(
																														item =>
																															item.detail ===
																															'ส่วนลด Giztix Business'
																													)[0]
																													.price.toString()
																													.replace(
																														/\B(?=(\d{3})+(?!\d))/g,
																														','
																													)} ${this.props.t(
																													'common:shipmentDetail.baht'
																												)}`}
																											/>
																										)}

																										{_.some(
																											invoiceList[0]
																												.invoice_discount,
																											['detail', 'Cash voucher']
																										) && (
																											<div>
																												<Line
																													style={{
																														marginBottom: 10,
																													}}
																												/>
																												<BoxPriceItem
																													title={`
																															${
																																invoiceList[0].invoice_discount.filter(
																																	item =>
																																		item.detail ===
																																		'Cash voucher'
																																)[0].detail
																															} 
																														`}
																													price={`-${invoiceList[0].invoice_discount
																														.filter(
																															item =>
																																item.detail ===
																																'Cash voucher'
																														)[0]
																														.price.toString()
																														.replace(
																															/\B(?=(\d{3})+(?!\d))/g,
																															','
																														)} ${this.props.t(
																														'common:shipmentDetail.baht'
																													)}`}
																												/>
																											</div>
																										)}

																										{this.getFlashDeals(
																											invoiceList[0]
																												.invoice_discount
																										).length === 1 &&
																											shipment[0]
																												.flash_deals_id && (
																												<div>
																													<Line
																														style={{
																															marginBottom: 10,
																														}}
																													/>
																													<BoxPriceItem
																														title={`
																															${this.getFlashDeals(invoiceList[0].invoice_discount)[0].detail} (Flash Deals)
																														`}
																														price={`-${this.getFlashDeals(
																															invoiceList[0]
																																.invoice_discount
																														)[0]
																															.price.toString()
																															.replace(
																																/\B(?=(\d{3})+(?!\d))/g,
																																','
																															)} ${this.props.t(
																															'common:shipmentDetail.baht'
																														)}`}
																													/>
																												</div>
																											)}

																										{invoiceList[0]
																											.promotion_code.name && (
																											<BoxPriceItem
																												title={this.getPromotionTitle(
																													invoiceList[0]
																												)}
																												price={`- ${invoiceList[0].promotion_code.price
																													.toString()
																													.replace(
																														/\B(?=(\d{3})+(?!\d))/g,
																														','
																													)} ${this.props.t(
																													'common:shipmentDetail.baht'
																												)}`}
																											/>
																										)}

																										<Line />

																										{invoiceData.filter(
																											item =>
																												item.datamaster_service_category_prefix_id ==
																												'5e54cef8e99db3b888a2b749'
																										).length > 0 && (
																											<BoxPriceItem
																												title={
																													<b>ค่าบริการเสริม</b>
																												}
																											/>
																										)}
																										{invoiceData.map(
																											(list, key) =>
																												list.datamaster_service_category_prefix_id ==
																													'5e54cef8e99db3b888a2b749' && (
																													<BoxPriceItem
																														key={key}
																														title={
																															list.invoice_business_list_detail
																														}
																														qty={`[${list.invoice_business_list_price.toLocaleString(
																															'en-US',
																															{
																																maximumFractionDigits: 2,
																															}
																														)} x ${
																															list.invoice_business_list_qty
																														}]`}
																														price={`${(
																															list.invoice_business_list_total_price -
																															list.invoice_business_list_discount
																														)
																															.toString()
																															.replace(
																																/\B(?=(\d{3})+(?!\d))/g,
																																','
																															)} ${this.props.t(
																															'common:shipmentDetail.baht'
																														)}`}
																													/>
																												)
																										)}

																										{invoiceData.filter(
																											item =>
																												item.datamaster_service_category_prefix_id ==
																												'5e54cef8e99db3b888a2b749'
																										).length > 0 && <Line />}

																										{invoiceData.filter(
																											item =>
																												item.datamaster_service_category_prefix_id ==
																												'5e54cef8e99db3b888a2b755'
																										).length > 0 && (
																											<BoxPriceItem
																												title={
																													<b>ค่าสำรองจ่าย</b>
																												}
																											/>
																										)}

																										{invoiceData.map(
																											(list, key) =>
																												list.datamaster_service_category_prefix_id ==
																													'5e54cef8e99db3b888a2b755' && (
																													<BoxPriceItem
																														key={key}
																														title={
																															list.invoice_business_list_detail
																														}
																														qty={`[${list.invoice_business_list_price.toLocaleString(
																															'en-US',
																															{
																																maximumFractionDigits: 2,
																															}
																														)} x ${
																															list.invoice_business_list_qty
																														}]`}
																														price={`${(
																															list.invoice_business_list_total_price -
																															list.invoice_business_list_discount
																														)
																															.toString()
																															.replace(
																																/\B(?=(\d{3})+(?!\d))/g,
																																','
																															)} ${this.props.t(
																															'common:shipmentDetail.baht'
																														)}`}
																													/>
																												)
																										)}

																										{invoiceData.filter(
																											item =>
																												item.datamaster_service_category_prefix_id ==
																												'5e54cef8e99db3b888a2b755'
																										).length > 0 && <Line />}

																										<BoxPriceItem
																											title="(1) รวมค่าขนส่งและค่าบริการ หลังหักส่วนลด"
																											price={`${invoiceList[0].total_price
																												.toString()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													','
																												)} ${this.props.t(
																												'common:shipmentDetail.baht'
																											)}`}
																										/>
																										<Line />
																										{vatList
																											.filter(
																												item => item.price != ''
																											)
																											.map(
																												(item, index, arr) => (
																													<React.Fragment>
																														<BoxPriceItem
																															key={index}
																															title={`${item.name}`}
																															price={`${item.priceDisplay}`}
																														/>

																														{item.subList &&
																															item.subList.map(
																																(list, key) => (
																																	<SubList
																																		key={key}
																																	>
																																		<div className="title">
																																			{
																																				list.name
																																			}
																																		</div>
																																		<div className="detail">{`${list.priceDisplay}`}</div>
																																	</SubList>
																																)
																															)}

																														{index ===
																															arr.length -
																																1 && (
																															<Line
																																style={{
																																	marginBottom: 10,
																																}}
																															/>
																														)}
																													</React.Fragment>
																												)
																											)}

																										<BoxPriceItem
																											title={`ยอดรวมภาษี`}
																											price={`${(invoiceList[0]
																												.invoice_business_grand_total_price %
																												1 ===
																											0
																												? invoiceList[0]
																														.invoice_business_grand_total_price
																												: invoiceList[0].invoice_business_grand_total_price.toFixed(
																														2
																												  )
																											)
																												.toString()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													','
																												)} ${this.props.t(
																												'common:shipmentDetail.baht'
																											)}`}
																										/>
																										<Line />
																										<BoxPriceItem
																											title={this.props.t(
																												'common:shipmentDetail.totalCharge'
																											)}
																											subTitle={
																												shipment[0].distance
																													? `${this.props.t(
																															'common:shipmentDetail.totalDistance'
																													  )} ${
																															shipment[0]
																																.round_trip ===
																															1
																																? (
																																		shipment[0]
																																			.distance *
																																		2
																																  ).toLocaleString(
																																		'en-US',
																																		{
																																			maximumFractionDigits: 2,
																																		}
																																  )
																																: shipment[0].distance.toLocaleString(
																																		'en-US',
																																		{
																																			maximumFractionDigits: 2,
																																		}
																																  )
																													  } ${this.props.t(
																															'common:shipmentDetail.kilometer'
																													  )}`
																													: ''
																											}
																											price={`${(invoiceList[0]
																												.invoice_business_grand_total_price %
																												1 ===
																											0
																												? invoiceList[0]
																														.invoice_business_grand_total_price
																												: invoiceList[0].invoice_business_grand_total_price.toFixed(
																														2
																												  )
																											)
																												.toString()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													','
																												)}`}
																										/>
																									</div>
																								)}
																								<div />
																							</div>
																						}
																					/>
																				)}

																				<BlankSpace height={10} />

																				<Card
																					content={
																						<div>
																							<CardHeader
																								border
																								title={this.props.t(
																									'common:shipmentDetail.shipmentInfo'
																								)}
																							/>

																							<Row>
																								<Col
																									sm={6}
																									style={{ paddingRight: '0' }}
																								>
																									<div
																										style={{ lineHeight: 1 }}
																									>
																										<div className="detail">
																											<p>
																												{this.props.t(
																													'common:shipmentList.shipmentNo'
																												)}
																												: <br />
																												<b>
																													{
																														shipment[0]
																															.shipment_number
																													}
																												</b>
																											</p>
																										</div>
																									</div>
																								</Col>
																								<Col
																									sm={6}
																									style={{ paddingLeft: '0' }}
																								>
																									<div
																										style={{ lineHeight: 1 }}
																									>
																										<div
																											className="detail"
																											style={{
																												borderLeft:
																													'1px solid #E3E3E3',
																												paddingLeft: '15px',
																											}}
																										>
																											<p>
																												{this.props.t(
																													'common:shipmentList.bookingOn'
																												)}
																												: <br />
																												<b>
																													<Moment format="DD/MM/YYYY HH:mm">
																														{
																															shipment[0]
																																.create_date
																														}
																													</Moment>
																												</b>
																											</p>
																										</div>
																									</div>
																								</Col>
																							</Row>

																							{shipment[0].business_status ===
																								2 && (
																								<Row>
																									<BlankSpace height={10} />
																									<Col
																										md={12}
																										style={{
																											paddingBottom: 10,
																											paddingTop: 15,
																										}}
																									>
																										<div>
																											<span>
																												{this.props.t(
																													'common:shipmentList.Company'
																												)}
																												:
																											</span>{' '}
																											<b>
																												{
																													shipment[0]
																														.shipper_detail
																												}
																											</b>
																										</div>
																										<div>
																											<span>
																												{this.props.t(
																													'common:shipmentList.BookingName'
																												)}
																												:
																											</span>{' '}
																											<b>
																												{
																													shipment[0]
																														.account_user_display_name
																												}
																											</b>
																										</div>
																									</Col>
																								</Row>
																							)}

																							<TransporterContainer>
																								<TransporterInner sm={12}>
																									<div
																										className="detail"
																										style={{ lineHeight: 1 }}
																									>
																										<p
																											style={{
																												marginBottom: '0',
																											}}
																										>
																											{this.props.t(
																												'common:shipmentDetail.transporter'
																											)}
																											: <br />
																											<b>
																												{shipment[0]
																													.transporter_detail
																													? shipment[0]
																															.transporter_detail
																													: this.props.t(
																															'common:shipmentList.GIZTIXCompanyName'
																													  )}
																											</b>
																										</p>
																									</div>
																									<div className="img" />
																								</TransporterInner>
																							</TransporterContainer>

																							<Row className="serviceWrapper">
																								<BoxServiceItem
																									icon="fal fa-pallet"
																									title={this.props.t(
																										'common:shipmentDetail.shipmentType'
																									)}
																									subTitle={
																										shipment[0].shipment_type ==
																										1
																											? this.props.t(
																													'common:shipmentDetail.domestic'
																											  )
																											: shipment[0]
																													.shipment_type == 2
																											? 'นำเข้า'
																											: shipment[0]
																													.shipment_type == 3
																											? 'ส่งออก'
																											: 'ภายในประเทศ'
																									}
																								/>

																								<BoxServiceItem
																									icon="fal fa-truck"
																									title={this.props.t(
																										'common:shipmentDetail.service'
																									)}
																									subTitle={`รถขนส่ง ${shipment[0].setting_truck_name.th} ${shipment[0].truck_type_name.th}`}
																								/>

																								{invoiceList.length > 0 && (
																									<BoxServiceItem
																										icon="fal fa-box"
																										title={this.props.t(
																											'common:shipmentDetail.additionalService'
																										)}
																										subTitle={this.renderAdditional(
																											invoiceList[0]
																												.invoice_list
																										)}
																									/>
																								)}

																								{shipment[0].shipment_accessory
																									.length > 0 && (
																									<BoxServiceItem
																										title={
																											shipment[0]
																												.shipment_type === 1
																												? this.props.t(
																														'common:shipmentDetail.safety'
																												  )
																												: this.props.t(
																														'common:shipmentDetail.accessory'
																												  )
																										}
																										subTitle={this.renderAccessory(
																											shipment[0]
																												.shipment_accessory
																										)}
																									/>
																								)}

																								{this.chkAdditionalPod(
																									shipment[0]
																								) ? (
																									<BoxServiceItem
																										title="ที่อยู่ในการจัดส่ง สำหรับ POD:"
																										subTitle={this.renderAdditionalPod(
																											shipment[0]
																										)}
																									/>
																								) : null}

																								<BoxServiceItem
																									icon="fal fa-clock"
																									title={this.props.t(
																										'common:shipmentDetail.shipmentDateTime'
																									)}
																									subTitle={
																										<Moment format="DD/MM/YYYY HH:mm">
																											{shipment[0].pick_up_date}
																										</Moment>
																									}
																								/>
																							</Row>

																							<div
																								className="boxPosition"
																								style={{ lineHeight: 1 }}
																							>
																								{shipment[0].shipment_address.map(
																									(item, key) => (
																										<div className="boxItem">
																											{item.mode ==
																											'รับสินค้า' ? (
																												<i
																													className="fas fa-dot-circle"
																													style={{
																														color: '#000000',
																														fontSize: 16,
																													}}
																												/>
																											) : (
																												<i
																													className="fas fa-map-marker-alt"
																													style={{
																														color: '#ED1C24',
																														fontSize: 16,
																													}}
																												/>
																											)}

																											<div
																												className="content"
																												style={{
																													borderBottom:
																														'1px solid rgb(227, 227, 227)',
																												}}
																											>
																												<div className="title">
																													{this.renderAddressMode(
																														item.mode,
																														key
																													)}
																												</div>
																												<div className="detail">
																													<p
																														style={{
																															paddingTop:
																																'10px',
																														}}
																													>
																														<span
																															style={{
																																fontWeight: 600,
																															}}
																														>
																															{item.address}
																														</span>
																													</p>

																													{item.pick_up_date && (
																														<p>
																															{item.mode ==
																																'รับตู้หนัก' ||
																															item.mode ==
																																'รับตู้เบา'
																																? `วันที่${item.mode}`
																																: `วัน/เวลา ที่${item.mode}`}
																															:<br />{' '}
																															<span
																																style={{
																																	fontWeight: 600,
																																}}
																															>
																																{item.mode ==
																																	'รับตู้หนัก' ||
																																item.mode ==
																																	'รับตู้เบา' ? (
																																	<Moment format="DD/MM/YYYY">
																																		{
																																			item.pick_up_date
																																		}
																																	</Moment>
																																) : (
																																	<Moment format="DD/MM/YYYY HH:mm">
																																		{
																																			item.pick_up_date
																																		}
																																	</Moment>
																																)}
																															</span>
																														</p>
																													)}

																													{item.delivery_date && (
																														<p>
																															{`วัน/เวลา ที่${item.mode}`}
																															:<br />{' '}
																															<span
																																style={{
																																	fontWeight: 600,
																																}}
																															>
																																<Moment format="DD/MM/YYYY HH:mm">
																																	{
																																		item.delivery_date
																																	}
																																</Moment>
																															</span>
																														</p>
																													)}

																													<p>
																														{this.props.t(
																															'common:shipmentDetail.contact'
																														)}{' '}
																														<span
																															style={{
																																fontWeight: 600,
																															}}
																														>
																															{
																																item.contact_name
																															}
																															,{' '}
																															<span
																																style={{
																																	fontWeight: 600,
																																}}
																															>
																																{
																																	item.contact_tel
																																}
																															</span>
																														</span>
																													</p>
																													{item.remark ? (
																														<p>
																															{this.props.t(
																																'common:shipmentDetail.remark'
																															)}{' '}
																															<br />
																															<span
																																style={{
																																	fontWeight: 600,
																																	whiteSpace:
																																		'pre-line',
																																}}
																															>
																																{item.remark}
																															</span>
																														</p>
																													) : null}
																												</div>
																											</div>
																										</div>
																									)
																								)}
																							</div>
																						</div>
																					}
																				/>
																				{shipment[0].shipment_type === 2 ||
																				shipment[0].shipment_type === 3 ? (
																					<div>
																						<BlankSpace height={10} />
																						<Card
																							content={
																								<div>
																									<HeaderTitel
																										title="เอกสารสำคัญ"
																										style={{ marginBottom: 10 }}
																									/>
																									<Row className="serviceWrapper">
																										<BoxServiceItem
																											icon="fal fa-clipboard-list"
																											title="เอกสาร Booking confirmation"
																										>
																											<div
																												style={{
																													color: '#4199F0',
																													cursor: 'pointer',
																												}}
																												onClick={() =>
																													this.setState({
																														isOpenConfirmImageLightBox: true,
																													})
																												}
																											>
																												Booking confirmation.pdf
																											</div>
																										</BoxServiceItem>
																										<BoxServiceItem title="ข้อมูลการออกใบเสร็จค่าแอดวานซ์ ">
																											<ImageProductContainer>
																												{this.renderReceiptImage(
																													shipment[0],
																													'view'
																												).map((item, index) => (
																													<div
																														className="image"
																														onClick={() =>
																															this.setState({
																																otherImageIndexLightBox: index,
																																isOpenPhotoOtherLightBox: true,
																															})
																														}
																													>
																														<div className="image-hover" />
																														<img
																															src={`${item.thumbnail}`}
																															alt="productImage"
																														/>
																													</div>
																												))}
																											</ImageProductContainer>
																										</BoxServiceItem>

																										{this.state
																											.isOpenConfirmImageLightBox && (
																											<Lightbox
																												mainSrc={this.renderConfirmImage(
																													shipment[0],
																													'rightbox'
																												)}
																												onCloseRequest={() =>
																													this.setState({
																														isOpenConfirmImageLightBox: false,
																													})
																												}
																											/>
																										)}

																										{this.state
																											.isOpenPhotoOtherLightBox && (
																											<Lightbox
																												mainSrc={
																													this.renderReceiptImage(
																														shipment[0],
																														'rightbox'
																													)[
																														this.state
																															.otherImageIndexLightBox
																													]
																												}
																												nextSrc={
																													this.renderReceiptImage(
																														shipment[0],
																														'rightbox'
																													).length > 1
																														? this.renderReceiptImage(
																																shipment[0],
																																'rightbox'
																														  )[
																																(this.state
																																	.otherImageIndexLightBox +
																																	1) %
																																	this.renderReceiptImage(
																																		shipment[0],
																																		'rightbox'
																																	).length
																														  ]
																														: undefined
																												}
																												prevSrc={
																													this.renderReceiptImage(
																														shipment[0],
																														'rightbox'
																													).length > 1
																														? this.renderReceiptImage(
																																shipment[0],
																																'rightbox'
																														  )[
																																(this.state
																																	.otherImageIndexLightBox +
																																	this.renderReceiptImage(
																																		shipment[0],
																																		'rightbox'
																																	).length -
																																	1) %
																																	this.renderReceiptImage(
																																		shipment[0],
																																		'rightbox'
																																	).length
																														  ]
																														: undefined
																												}
																												onCloseRequest={() =>
																													this.setState({
																														isOpenPhotoOtherLightBox: false,
																													})
																												}
																												onMovePrevRequest={() =>
																													this.setState(
																														prev => ({
																															otherImageIndexLightBox:
																																(prev.otherImageIndexLightBox +
																																	this.renderReceiptImage(
																																		shipment[0],
																																		'rightbox'
																																	).length -
																																	1) %
																																this.renderReceiptImage(
																																	shipment[0],
																																	'rightbox'
																																).length,
																														})
																													)
																												}
																												onMoveNextRequest={() =>
																													this.setState(
																														prev => ({
																															otherImageIndexLightBox:
																																(prev.otherImageIndexLightBox +
																																	1) %
																																this.renderReceiptImage(
																																	shipment[0],
																																	'rightbox'
																																).length,
																														})
																													)
																												}
																											/>
																										)}
																									</Row>
																								</div>
																							}
																						/>
																					</div>
																				) : null}
																				{/* shipment more detail */}
																				{shipment[0].shipment_other_detail
																					.remark ||
																				shipment[0].shipment_other_detail
																					.user_ref ||
																				documents.length ? (
																					<div>
																						<BlankSpace height={10} />
																						<Card
																							content={
																								<div>
																									<HeaderTitel
																										title="เพิ่มเติม"
																										style={{ marginBottom: 10 }}
																									/>
																									<Row className="serviceWrapper">
																										{documents.length > 0 && (
																											<BoxServiceItem
																												icon="fal fa-camera"
																												title={this.props.t(
																													'common:shipmentDetail.cargoDocumentPictures'
																												)}
																											>
																												<div>
																													<ImageProductContainer>
																														{documents.map(
																															(item, index) => (
																																<div
																																	className="image"
																																	onClick={() =>
																																		this.setState(
																																			{
																																				photoProductIndexLightBox: index,
																																				isOpenPhotoProductLightBox: true,
																																			}
																																		)
																																	}
																																>
																																	<div className="image-hover" />
																																	<img
																																		src={`${item.thumbnail}`}
																																		alt="productImage"
																																	/>
																																</div>
																															)
																														)}
																													</ImageProductContainer>

																													{this.state
																														.isOpenPhotoProductLightBox && (
																														<Lightbox
																															mainSrc={
																																documentsImage[
																																	this.state
																																		.photoProductIndexLightBox
																																]
																															}
																															nextSrc={
																																documentsImage.length >
																																1
																																	? documentsImage[
																																			(this
																																				.state
																																				.photoProductIndexLightBox +
																																				1) %
																																				documentsImage.length
																																	  ]
																																	: undefined
																															}
																															prevSrc={
																																documentsImage.length >
																																1
																																	? documentsImage[
																																			(this
																																				.state
																																				.photoProductIndexLightBox +
																																				documentsImage.length -
																																				1) %
																																				documentsImage.length
																																	  ]
																																	: undefined
																															}
																															onCloseRequest={() =>
																																this.setState({
																																	isOpenPhotoProductLightBox: false,
																																})
																															}
																															onMovePrevRequest={() =>
																																this.setState(
																																	prev => ({
																																		photoProductIndexLightBox:
																																			(prev.photoProductIndexLightBox +
																																				documentsImage.length -
																																				1) %
																																			documentsImage.length,
																																	})
																																)
																															}
																															onMoveNextRequest={() =>
																																this.setState(
																																	prev => ({
																																		photoProductIndexLightBox:
																																			(prev.photoProductIndexLightBox +
																																				1) %
																																			documentsImage.length,
																																	})
																																)
																															}
																														/>
																													)}
																												</div>
																											</BoxServiceItem>
																										)}

																										{shipment[0]
																											.shipment_other_detail
																											.user_ref && (
																											<BoxServiceItem
																												icon="fal fa-clipboard-list"
																												title={this.props.t(
																													'common:shipmentDetail.yourJobReferenceCode'
																												)}
																												subTitle={
																													shipment[0]
																														.shipment_other_detail
																														.user_ref
																												}
																											/>
																										)}

																										{shipment[0]
																											.shipment_other_detail
																											.remark && (
																											<BoxServiceItem
																												icon="fal fa-file-alt"
																												title={this.props.t(
																													'common:shipmentDetail.remarkCargoDetails'
																												)}
																												subTitle={
																													shipment[0]
																														.shipment_other_detail
																														.remark
																												}
																											/>
																										)}
																									</Row>
																								</div>
																							}
																						/>
																					</div>
																				) : null}
																			</SubContainer>

																			<SubContainer
																				className="subDetailWrapper"
																				data-simplebar
																			>
																				<Card
																					content={
																						<div>
																							<CardHeader
																								border
																								title={this.props.t(
																									'common:shipmentDetail.trackingShipment'
																								)}
																							/>

																							<div>
																								{this.renderStatus(
																									shipment[0].shipment_status
																								)}
																							</div>

																							<div
																								style={{
																									lineHeight: 1,
																									marginTop: 5,
																								}}
																							>
																								<small
																									style={{ color: '#808080' }}
																								>
																									{this.props.t(
																										'common:shipmentList.lastUpdate'
																									)}
																									:{' '}
																									<Moment format="DD/MM/YYYY - HH:mm">
																										{shipment[0].last_update}
																									</Moment>{' '}
																								</small>
																							</div>

																							{shipment[0].matching_time && (
																								<div
																									style={{
																										lineHeight: 1,
																										marginTop: 0,
																									}}
																								>
																									<small
																										style={{ color: '#808080' }}
																									>
																										Matching time :{' '}
																										{this.getTimeFromMins(
																											shipment[0].matching_time
																										)}
																									</small>
																								</div>
																							)}

																							{shipment[0].shipment_status ===
																								5 && (
																								<div style={{ lineHeight: 1 }}>
																									<small
																										style={{ color: '#808080' }}
																									>
																										<i
																											className="fal fa-times-circle"
																											style={{ fontSize: 12 }}
																										/>{' '}
																										{this.props.t(
																											'common:shipmentDetail.cancelDate'
																										)}
																										:{' '}
																										<Moment format="DD/MM/YYYY - HH:mm">
																											{shipment[0]
																												.cancel_date ||
																												shipment[0].last_update}
																										</Moment>{' '}
																									</small>
																								</div>
																							)}

																							<div style={{ lineHeight: 1 }}>
																								{shipment[0].shipment_status ===
																									5 &&
																									shipment[0]
																										.cancel_comment && (
																										<div
																											style={{ marginTop: 5 }}
																										>
																											<small>
																												<b>
																													{this.props.t(
																														'common:shipmentDetail.reason'
																													)}
																													:
																												</b>
																												<br />
																												{
																													shipment[0]
																														.cancel_comment
																												}
																											</small>
																										</div>
																									)}
																							</div>

																							<div style={{ lineHeight: 1 }}>
																								{//COD
																								shipment[0].shipment_status ===
																									5 &&
																									shipment[0].paid_type === 2 &&
																									shipment[0].shipment_fines
																										.amount !== null && (
																										<div
																											style={{ marginTop: 10 }}
																										>
																											<small>
																												{shipment[0]
																													.shipment_fines
																													.amount !== 0 && (
																													<div>
																														<b
																															style={{
																																color:
																																	'#D90101',
																															}}
																														>
																															{shipment[0]
																																.shipment_fines
																																.status === 1 &&
																																`กรุณาชำระค่ายกเลิกบริการ จำนวน ${this.formatMoney(
																																	shipment[0]
																																		.shipment_fines
																																		.amount
																																)} บาท`}
																															{shipment[0]
																																.shipment_fines
																																.status === 2 &&
																																`ชำระค่ายกเลิกบริการเรียบร้อยแล้ว`}
																														</b>
																													</div>
																												)}
																												<div
																													style={{
																														color:
																															shipment[0]
																																.shipment_fines
																																.status === 2
																																? '#989898'
																																: null,
																													}}
																												>
																													{
																														shipment[0]
																															.shipment_fines
																															.detail.th
																													}
																												</div>
																												<div
																													style={{
																														marginTop: 5,
																													}}
																												>
																													ติดต่อทีมงานจิซทิกซ์
																												</div>
																												<div>
																													เบอร์โทรศัพท์
																													02-026-6895
																												</div>
																											</small>
																										</div>
																									)}

																								{//Invoice
																								shipment[0].shipment_status ===
																									5 &&
																									shipment[0].paid_type === 3 &&
																									shipment[0].shipment_fines
																										.amount !== null && (
																										<div
																											style={{ marginTop: 10 }}
																										>
																											<small>
																												<div>
																													<b>
																														{shipment[0]
																															.shipment_fines
																															.status === 1 &&
																														shipment[0]
																															.shipment_fines
																															.amount > 0 ? (
																															<span
																																style={{
																																	color:
																																		'#D90101',
																																}}
																															>
																																มีค่าใช้จ่ายเพิ่มเติม/ค่าเสียหาย
																															</span>
																														) : (
																															`คืนเงินจำนวน: ${this.formatMoney(
																																shipment[0]
																																	.shipment_refund
																																	.amount
																															)} บาท`
																														)}
																														{shipment[0]
																															.shipment_fines
																															.status === 2 &&
																															`คืนเงินจำนวน: ${this.formatMoney(
																																shipment[0]
																																	.shipment_fines
																																	.amount
																															)} บาท`}
																													</b>
																												</div>
																												<div
																													style={{
																														color:
																															shipment[0]
																																.shipment_fines
																																.status === 2
																																? '#989898'
																																: null,
																													}}
																												>
																													{
																														shipment[0]
																															.shipment_fines
																															.detail.th
																													}
																												</div>
																												<div
																													style={{
																														marginTop: 5,
																													}}
																												>
																													ติดต่อทีมงานจิซทิกซ์
																												</div>
																												<div>
																													เบอร์โทรศัพท์
																													02-026-6895
																												</div>
																											</small>
																										</div>
																									)}

																								{//Credit Card
																								shipment[0].shipment_status ===
																									5 &&
																									shipment[0].shipment_refund
																										.amount !== null && (
																										<div
																											style={{ marginTop: 10 }}
																										>
																											<small>
																												<div>
																													<b>
																														{`คืนเงินจำนวน: ${this.formatMoney(
																															shipment[0]
																																.shipment_refund
																																.amount
																														)} บาท`}
																													</b>
																												</div>
																												<div
																													style={{
																														color:
																															shipment[0]
																																.shipment_refund
																																.status === 2
																																? '#989898'
																																: null,
																													}}
																												>
																													{
																														shipment[0]
																															.shipment_refund
																															.detail.th
																													}
																												</div>
																											</small>
																										</div>
																									)}
																							</div>

																							<hr
																								style={{
																									margin: '10px 0px',
																									borderTop:
																										'1px solid #E3E3E3',
																								}}
																							/>

																							<ShipmentNumberContainer>
																								<div>
																									รหัสติดตามงานขนส่ง:{' '}
																									<b>{`${shipment[0].shipment_number}`}</b>
																								</div>
																								<div>
																									<CopyToClipboard
																										text={
																											shipment[0]
																												.shipment_number
																										}
																									>
																										<ButtonAntd
																											size={34}
																											shape="circle"
																											title={`คัดลอกข้อมูล`}
																										>
																											<i className="fal fa-copy" />
																										</ButtonAntd>
																									</CopyToClipboard>
																								</div>
																							</ShipmentNumberContainer>

																							<hr
																								style={{
																									marginBottom: '10px',
																									marginTop: '10px',
																								}}
																							/>

																							{/* Tracking */}
																							<Query
																								query={trackingList}
																								variables={{
																									shipment_id: shipmentId,
																									check_agent_accept:
																										shipment[0].transporter,
																								}}
																								fetchPolicy="network-only"
																							>
																								{({
																									loading,
																									error,
																									data,
																									refetch,
																									variables,
																								}) => {
																									if (loading)
																										return `${this.props.t(
																											'common:shipmentList.loading'
																										)} ...`;
																									if (error)
																										return this.props.t(
																											'common:shipmentList.draft'
																										);
																									const tracking =
																										data.trackingList.tracking;
																									// console.log(tracking);

																									if (tracking.length > 0) {
																										const trackingStatus = this.getCurrentTrackingStatus(
																											shipment[0],
																											tracking[0]
																										);

																										return (
																											<div
																												style={{
																													lineHeight: 1,
																												}}
																											>
																												<div
																													style={{
																														margin: '15px 0',
																													}}
																												>
																													{shipment[0]
																														.transporter &&
																														(!shipment[0]
																															.driver_user_id ||
																															shipment[0]
																																.transporter !==
																																'5d0083ebc88a512bb2f5f7a6') && (
																															<React.Fragment>
																																<TransporterDetailContainer>
																																	<div>
																																		<div>
																																			{this.props.t(
																																				'common:shipmentDetail.jobResponsibleName'
																																			)}{' '}
																																			<b>
																																				{
																																					shipment[0]
																																						.transporter_name
																																				}
																																			</b>
																																		</div>
																																		{shipment[0]
																																			.matching_date && (
																																			<div>
																																				รับงานเมื่อ:{' '}
																																				{moment(
																																					shipment[0]
																																						.matching_date
																																				).format(
																																					'DD/MM/YYYY - HH:mm'
																																				)}
																																			</div>
																																		)}
																																		<div>
																																			{this.props.t(
																																				'common:shipmentDetail.call'
																																			)}{' '}
																																			<b>{`${
																																				shipment[0]
																																					.transporter_phone_code ==
																																				66
																																					? 0
																																					: shipment[0]
																																							.transporter_phone_code +
																																					  ' '
																																			}${
																																				shipment[0]
																																					.transporter_phone_number
																																			}`}</b>
																																		</div>
																																	</div>

																																	<div>
																																		<CopyToClipboard
																																			text={`${this.props.t(
																																				'common:shipmentDetail.jobResponsibleName'
																																			)} ${
																																				shipment[0]
																																					.transporter_name
																																			}\n${
																																				shipment[0]
																																					.matching_date
																																					? `รับงานเมื่อ: ${moment(
																																							shipment[0]
																																								.matching_date
																																					  ).format(
																																							'YYYY/MM/DD - HH:mm'
																																					  )}\n`
																																					: ''
																																			}${this.props.t(
																																				'common:shipmentDetail.call'
																																			)} ${`${
																																				shipment[0]
																																					.transporter_phone_code ==
																																				66
																																					? 0
																																					: shipment[0]
																																							.transporter_phone_code +
																																					  ' '
																																			}${
																																				shipment[0]
																																					.transporter_phone_number
																																			}`}`}
																																		>
																																			<ButtonAntd
																																				size={
																																					34
																																				}
																																				shape="circle"
																																				title={`คัดลอกข้อมูล`}
																																			>
																																				<i className="fal fa-copy" />
																																			</ButtonAntd>
																																		</CopyToClipboard>
																																	</div>
																																</TransporterDetailContainer>
																																<hr
																																	style={{
																																		marginBottom:
																																			'10px',
																																		marginTop:
																																			'10px',
																																	}}
																																/>
																															</React.Fragment>
																														)}

																													{shipment[0]
																														.transporter &&
																														!shipment[0]
																															.driver_user_id && (
																															<div>
																																<MatchingTimeCountDown
																																	dateTime={
																																		shipment[0]
																																			.matching_date
																																	}
																																/>
																																<hr
																																	style={{
																																		marginBottom:
																																			'10px',
																																		marginTop:
																																			'10px',
																																	}}
																																/>
																															</div>
																														)}

																													{shipment[0]
																														.shipment_type ===
																														1 &&
																														shipment[0]
																															.driver_user_id && (
																															<React.Fragment>
																																<DriverDetailWrapper>
																																	<DriverDetailContainer>
																																		<AvatarContainer
																																			style={{
																																				marginRight: 20,
																																			}}
																																		>
																																			<Avatar
																																				size={
																																					60
																																				}
																																				icon="user"
																																				src={`${s3}/${tracking[0].driver_display_pic}`}
																																				onClick={() => {
																																					if (
																																						tracking[0]
																																							.driver_display_pic
																																					) {
																																						this.setState(
																																							{
																																								isOpenLightboxDriverAvatar: true,
																																							}
																																						);
																																					}
																																				}}
																																			/>

																																			{tracking[0]
																																				.favorite_driver ===
																																				1 && (
																																				<FavoriteIcon>
																																					<i className="fas fa-heart" />
																																				</FavoriteIcon>
																																			)}
																																		</AvatarContainer>

																																		<div>
																																			<div>
																																				{this.props.t(
																																					'common:shipmentDetail.driver'
																																				)}{' '}
																																				<b>
																																					{shipment[0]
																																						.driver_name_under_mama
																																						? shipment[0]
																																								.driver_name_under_mama
																																						: tracking[0]
																																								.driver_name}
																																				</b>
																																			</div>

																																			<div>
																																				{this.props.t(
																																					'common:shipmentDetail.licensePlate'
																																				)}{' '}
																																				<b>
																																					{
																																						tracking[0]
																																							.user_setting_truck_license_plate
																																					}
																																				</b>
																																			</div>

																																			<div>
																																				{this.props.t(
																																					'common:shipmentDetail.call'
																																				)}{' '}
																																				<b>
																																					{shipment[0]
																																						.driver_tel_under_mama
																																						? `${
																																								tracking[0]
																																									.driver_phone_code ==
																																								66
																																									? 0
																																									: tracking[0]
																																											.driver_phone_code +
																																									  ' '
																																						  }${
																																								shipment[0]
																																									.driver_tel_under_mama
																																						  }`
																																						: `${
																																								tracking[0]
																																									.driver_phone_code ==
																																								66
																																									? 0
																																									: tracking[0]
																																											.driver_phone_code +
																																									  ' '
																																						  }${
																																								tracking[0]
																																									.driver_phone_number
																																						  }`}
																																				</b>
																																			</div>
																																		</div>
																																	</DriverDetailContainer>

																																	<div>
																																		<CopyToClipboard
																																			text={`${this.props.t(
																																				'common:shipmentDetail.driver'
																																			)} ${
																																				shipment[0]
																																					.driver_name_under_mama
																																					? shipment[0]
																																							.driver_name_under_mama
																																					: tracking[0]
																																							.driver_name
																																			}\n${this.props.t(
																																				'common:shipmentDetail.licensePlate'
																																			)} ${
																																				tracking[0]
																																					.user_setting_truck_license_plate
																																			}\n${this.props.t(
																																				'common:shipmentDetail.call'
																																			)} ${
																																				shipment[0]
																																					.driver_tel_under_mama
																																					? `${
																																							tracking[0]
																																								.driver_phone_code ==
																																							66
																																								? 0
																																								: tracking[0]
																																										.driver_phone_code +
																																								  ' '
																																					  }${
																																							shipment[0]
																																								.driver_tel_under_mama
																																					  }`
																																					: `${
																																							tracking[0]
																																								.driver_phone_code ==
																																							66
																																								? 0
																																								: tracking[0]
																																										.driver_phone_code +
																																								  ' '
																																					  }${
																																							tracking[0]
																																								.driver_phone_number
																																					  }`
																																			}`}
																																		>
																																			<ButtonAntd
																																				size={
																																					34
																																				}
																																				shape="circle"
																																				title={`คัดลอกข้อมูล`}
																																			>
																																				<i className="fal fa-copy" />
																																			</ButtonAntd>
																																		</CopyToClipboard>
																																	</div>

																																	{this.state
																																		.isOpenLightboxDriverAvatar && (
																																		<Lightbox
																																			mainSrc={`${s3}/${tracking[0].driver_display_pic}`}
																																			onCloseRequest={() =>
																																				this.setState(
																																					{
																																						isOpenLightboxDriverAvatar: false,
																																					}
																																				)
																																			}
																																		/>
																																	)}
																																</DriverDetailWrapper>
																															</React.Fragment>
																														)}
																												</div>

																												{shipment[0]
																													.shipment_type ===
																													1 &&
																													tracking[0]
																														.driver_user_agent ===
																														'5d0083ebc88a512bb2f5f7a6' &&
																													tracking[0]
																														.review_score >
																														0 && (
																														<ListArrow
																															titleHeader="เกี่ยวกับพนักงานขับรถ:"
																															textRight="แก้ไข"
																															clicked={() =>
																																context.onCloseAllAndOpen(
																																	[
																																		'manageDriver',
																																	]
																																)
																															}
																														>
																															<FavoriteDriverDetail
																																type={
																																	tracking[0]
																																		.favorite_driver
																																}
																															/>
																														</ListArrow>
																													)}

																												{/* Status Detail */}
																												{shipment[0]
																													.shipment_type ==
																													1 && (
																													<BoxStatusDetail
																														numPoint={
																															shipment[0]
																																.shipment_address
																																.length
																														}
																														pickup={
																															shipment[0]
																																.shipment_address[0]
																																.subdictrict
																														}
																														delivery={
																															shipment[0]
																																.shipment_address[
																																shipment[0]
																																	.shipment_address
																																	.length - 1
																															].subdictrict
																														}
																														detail={
																															trackingStatus.currentTrackingStatus
																														}
																														statusNumber={
																															trackingStatus.currentTrackingStatusNumber
																														}
																														totalStatusNumber={
																															trackingStatus.totalTrackingStatusNumber
																														}
																														icon={
																															shipment[0]
																																.shipment_status ===
																																4 &&
																															'fal fa-check'
																														}
																													/>
																												)}

																												{/* { tracking[0].review_score != 0 && document.getElementById("review_btn").css("display", "none")} */}

																												{/* customer review */}
																												{shipment[0]
																													.shipment_status ===
																													4 &&
																													tracking[0]
																														.review_score && (
																														<button
																															type="button"
																															className="outline btn btn-sm btn-default"
																															style={{
																																fontWeight:
																																	'normal',
																																width: '100%',
																																padding:
																																	'5px 0px',
																																marginBottom:
																																	'10px',
																															}}
																															onClick={() =>
																																context.onCloseAllAndOpen(
																																	[
																																		'customerReview',
																																	]
																																)
																															}
																														>
																															{this.props.t(
																																'common:shipmentDetail.customerReview.buttonOpenRateDriver'
																															)}
																														</button>
																													)}

																												{shipment[0]
																													.shipment_status ===
																													4 &&
																													!tracking[0]
																														.review_score && (
																														<button
																															type="button"
																															className="outline btn btn-sm btn-default"
																															style={{
																																fontWeight:
																																	'normal',
																																width: '100%',
																																padding:
																																	'5px 0px',
																																marginBottom:
																																	'10px',
																																color:
																																	'#D90101',
																																border:
																																	'1px solid #D90101',
																															}}
																															onClick={() =>
																																this.handleReview()
																															}
																														>
																															{this.props.t(
																																'common:shipmentDetail.customerReview.buttonRateDriver'
																															)}
																														</button>
																													)}

																												{/* PayCOD Detail */}
																												{shipment[0]
																													.paid_type === 2 ? (
																													<BoxBorder
																														active={
																															shipment[0]
																																.paid_status
																																? true
																																: false
																														}
																														content={
																															<div>
																																<div className="img">
																																	<i className="fal fa-hand-holding-usd" />
																																</div>
																																<div className="detail">
																																	<b>
																																		{this.props.t(
																																			'common:shipmentDetail.payBy'
																																		)}
																																	</b>
																																	<p>
																																		{shipment[0].shipment_address
																																			.map(
																																				(
																																					item,
																																					index
																																				) => ({
																																					...item,
																																					index,
																																				})
																																			)
																																			.filter(
																																				item =>
																																					item.paid_by_cod ===
																																					1
																																			)
																																			.map(
																																				item =>
																																					`${this.props.t(
																																						'common:shipmentDetail.payCashAtPoint'
																																					)} ${item.index +
																																						1} - ${
																																						item.mode
																																					}`
																																			)}
																																		<br />
																																		{shipment[0]
																																			.paid_status ===
																																		1
																																			? this.props.t(
																																					'common:shipmentDetail.paid'
																																			  )
																																			: this.props.t(
																																					'common:shipmentDetail.pleasePayToDriver'
																																			  )}
																																	</p>
																																</div>
																															</div>
																														}
																													/>
																												) : shipment[0]
																														.paid_type === 3 ? (
																													<BoxBorder
																														active={true}
																														content={
																															<div>
																																<div className="img">
																																	<i className="fal fa-file-alt" />
																																</div>
																																<div
																																	className="detail"
																																	style={{
																																		marginTop:
																																			'10px',
																																	}}
																																>
																																	<b>
																																		{this.props.t(
																																			'common:shipmentDetail.payBy'
																																		)}
																																	</b>
																																	<p>
																																		{this.props.t(
																																			'common:shipmentDetail.issueInvoice'
																																		)}
																																	</p>
																																</div>
																															</div>
																														}
																													/>
																												) : null}

																												<div className="boxPosition tracking">
																													{/* ดำเนินการส่งเรียบร้อยแล้ว */}
																													{shipment[0]
																														.shipment_status ===
																													4 ? (
																														<div className="boxItem inProgress">
																															<i className="fas fa-circle" />
																															<div className="content">
																																<div className="title">
																																	<b>
																																		{this.props.t(
																																			'common:shipmentDetail.deliveryCompleted'
																																		)}
																																	</b>{' '}
																																</div>
																																<div className="status">
																																	<Moment format="DD/MM/YYYY HH:mm">
																																		{
																																			shipment[0]
																																				.completed_date
																																		}
																																	</Moment>
																																</div>
																																<div className="detail" />
																															</div>
																														</div>
																													) : null}

																													{/* ส่งเอกสาร POD เรียบร้อยแล้ว */}
																													{this.checkPODProcess(
																														shipment[0],
																														tracking[0]
																													) ? (
																														tracking[0]
																															.tracking_detail[0]
																															.pod_date !==
																														null ? (
																															<div className="boxItem">
																																<i className="fas fa-check-circle" />
																																<div className="content">
																																	<div className="title">
																																		<b>
																																			{this.props.t(
																																				'common:shipmentDetail.sendProofOfDeliveryCompleted'
																																			)}
																																		</b>
																																	</div>
																																	<div
																																		className="detail"
																																		style={{
																																			margin:
																																				'10px 0',
																																		}}
																																	>
																																		<Row>
																																			<Col
																																				sm={5}
																																			>
																																				{this.props.t(
																																					'common:shipmentDetail.sendBy'
																																				)}
																																			</Col>
																																			<Col
																																				sm={7}
																																			>
																																				{
																																					tracking[0]
																																						.tracking_detail[0]
																																						.pod_from
																																				}
																																			</Col>
																																		</Row>
																																		<Row>
																																			<Col
																																				sm={5}
																																			>
																																				{this.props.t(
																																					'common:shipmentDetail.trackingNoPod'
																																				)}
																																			</Col>
																																			<Col
																																				sm={7}
																																				style={{
																																					fontWeight:
																																						'bold',
																																				}}
																																			>
																																				{
																																					tracking[0]
																																						.tracking_detail[0]
																																						.pod_track
																																				}
																																			</Col>
																																		</Row>
																																		<Row>
																																			<Col
																																				sm={5}
																																			>
																																				{this.props.t(
																																					'common:shipmentDetail.remark'
																																				)}
																																			</Col>
																																			<Col
																																				sm={7}
																																			>
																																				{
																																					tracking[0]
																																						.tracking_detail[0]
																																						.pod_remark
																																				}
																																			</Col>
																																		</Row>
																																	</div>
																																	<div className="status">
																																		<Moment format="DD/MM/YYYY HH:mm">
																																			{
																																				tracking[0]
																																					.tracking_detail[0]
																																					.pod_date
																																			}
																																		</Moment>
																																	</div>
																																	<div className="detail">
																																		{tracking[0].tracking_detail[0].pod_slip_picture
																																			.filter(
																																				item =>
																																					item
																																			)
																																			.map(
																																				(
																																					item,
																																					index,
																																					arr
																																				) => (
																																					<div
																																						className="img"
																																						onClick={() =>
																																							this.renderLightboxContainner(
																																								arr,
																																								index
																																							)
																																						}
																																					>
																																						<Image
																																							alt="pod_slip_picture"
																																							src={`${s3}/${item}`}
																																							rounded
																																						/>
																																					</div>
																																				)
																																			)}
																																	</div>
																																</div>
																															</div>
																														) : (
																															<div className="boxItem inProgress">
																																<i className="fas fa-circle" />
																																<div className="content">
																																	<div className="title">
																																		<b>
																																			{this.props.t(
																																				'common:shipmentDetail.sendProofOfDelivery'
																																			)}
																																		</b>{' '}
																																	</div>
																																	<div className="status">
																																		{this.props.t(
																																			'common:shipmentDetail.processing'
																																		)}
																																	</div>
																																	<div className="detail" />
																																</div>
																															</div>
																														)
																													) : null}

																													{shipment[0]
																														.shipment_type ===
																														1 && (
																														<Row>
																															<Col md={12}>
																																{/* วนที่อยู่ tracking */}
																																{tracking[0].tracking_detail
																																	.sort(
																																		function(
																																			a,
																																			b
																																		) {
																																			return (
																																				b.droppoint -
																																				a.droppoint
																																			);
																																		}
																																	)
																																	.map(
																																		(
																																			item,
																																			index
																																		) => (
																																			<TrackingItem
																																				step={[
																																					1,
																																					2,
																																					3,
																																				]}
																																				tracking={
																																					tracking[0]
																																				}
																																				detail={
																																					tracking[0]
																																						.tracking_detail
																																				}
																																				index={
																																					index
																																				}
																																				renderLightbox={(
																																					mode,
																																					data,
																																					index
																																				) =>
																																					this.renderLightbox(
																																						mode,
																																						data,
																																						index
																																					)
																																				}
																																			/>
																																		)
																																	)}

																																{/* ขนส่งยืนยันงาน */}
																																{tracking[0]
																																	.driver_accept_date ==
																																null ? (
																																	<div className="boxItem inProgress">
																																		<i className="fas fa-circle" />
																																		<div className="content">
																																			<div className="title">
																																				<b>
																																					{this.props.t(
																																						'common:shipmentDetail.waitingTransporterConfirmShipment'
																																					)}
																																				</b>{' '}
																																			</div>
																																			<div className="status">
																																				{this.props.t(
																																					'common:shipmentDetail.transporterNotConfirmShipmentYet'
																																				)}
																																			</div>
																																			<div className="detail" />
																																		</div>
																																	</div>
																																) : (
																																	<div className="boxItem">
																																		<i className="fas fa-check-circle" />
																																		<div className="content">
																																			<div className="title">
																																				{this.props.t(
																																					'common:shipmentDetail.transporterHasBeenAcceptedShipment'
																																				)}
																																			</div>
																																			<div className="status">
																																				<Moment format="DD/MM/YYYY HH:mm">
																																					{
																																						tracking[0]
																																							.driver_accept_date
																																					}
																																				</Moment>
																																			</div>
																																			<div className="detail" />
																																		</div>
																																	</div>
																																)}
																															</Col>
																														</Row>
																													)}

																													{shipment[0]
																														.shipment_type ==
																														2 && ( //import
																														<Row>
																															<Col md={12}>
																																<TrackingItemImport
																																	tracking={
																																		tracking
																																	}
																																	onClickContainner={(
																																		data,
																																		index
																																	) =>
																																		this.renderLightboxContainner(
																																			data,
																																			index
																																		)
																																	}
																																/>
																															</Col>
																														</Row>
																													)}

																													{shipment[0]
																														.shipment_type ==
																														3 && ( //Export
																														<Row>
																															<Col md={12}>
																																<TrackingItemExport
																																	tracking={
																																		tracking
																																	}
																																	onClickContainner={(
																																		data,
																																		index
																																	) =>
																																		this.renderLightboxContainner(
																																			data,
																																			index
																																		)
																																	}
																																/>
																															</Col>
																														</Row>
																													)}

																													<CustomerReview
																														shipmentId={
																															shipment[0]._id
																														}
																														show={
																															this.state
																																.showReview
																														}
																														onHide={() => {
																															this.setState({
																																showReview: false,
																															});
																														}}
																														onClose={() => {
																															this.setState({
																																showReview: false,
																															});
																														}}
																													/>
																												</div>
																											</div>
																										);
																									}

																									return (
																										<div className="boxPosition tracking">
																											<div className="boxItem">
																												<i className="fas fa-check-circle" />
																												<div className="content">
																													<div className="title">
																														งานแบบร่าง
																													</div>
																													<div className="status">
																														<Moment format="DD/MM/YYYY HH:mm">
																															{
																																shipment[0]
																																	.pick_up_date
																															}
																														</Moment>
																													</div>
																													<div className="detail" />
																												</div>
																											</div>
																										</div>
																									);
																								}}
																							</Query>
																						</div>
																					}
																				/>
																			</SubContainer>
																		</ToolContainer>

																		<DetailReviewDriver
																			shipmentId={shipmentId}
																		/>

																		<Query
																			query={trackingList}
																			variables={{
																				shipment_id: shipmentId,
																				check_agent_accept:
																					shipment[0].transporter,
																			}}
																			fetchPolicy="network-only"
																		>
																			{({ data, loading, error }) => {
																				if (loading || error) return null;

																				const { tracking } = data.trackingList;

																				return (
																					<React.Fragment>
																						{context.state.manageDriver && (
																							<ManageDriver
																								shipmentId={shipmentId}
																								driverId={
																									shipment[0].driver_user_id
																								}
																								favoriteDriver={
																									tracking[0].favorite_driver
																								}
																							/>
																						)}
																					</React.Fragment>
																				);
																			}}
																		</Query>
																	</React.Fragment>
																);
															}}
														</ShipmentDetailContext.Consumer>
													</ShipmentDetailContextProvider>

													{/* Map */}
													<div className="MapDetailWrapper">
														<Query
															query={trackingList}
															variables={{
																shipment_id: shipmentId,
																check_agent_accept: shipment[0].transporter,
															}}
														>
															{({
																loading,
																error,
																data,
																refetch,
																variables,
															}) => {
																if (loading) return 'Loading...';
																if (error) return 'แบบร่าง';
																const tracking = data.trackingList.tracking;
																let diverWorking = false;

																if (shipment[0].shipment_type === 1) {
																	diverWorking =
																		tracking.length > 0
																			? tracking[0].working &&
																			  !this.checkPODProcess(
																					shipment[0],
																					tracking[0]
																			  )
																			: false;
																} else if (
																	shipment[0].shipment_type === 2 ||
																	shipment[0].shipment_type === 3
																) {
																	diverWorking =
																		shipment[0].shipment_status === 3;
																}

																return (
																	<GoogleMaps
																		shipmentId={shipmentId}
																		driverId={driverId}
																		isHideDriver={!diverWorking}
																	/>
																);
															}}
														</Query>
													</div>
												</Row>
											);
										}}
									</Query>
								);
							}}
						</Query>

						<ModalAntd
							title={<b>จัดการ การแชร์สถานะการขนส่ง</b>}
							visible={this.state.showSharebook}
							onCancel={() => this.setState({ showSharebook: false })}
							footer={null}
							width={600}
						>
							<ShareRoute shipmentId={this.state.shipmentIdFormUrl} />
						</ModalAntd>

						<ModalConfirm
							show={this.state.showRebook}
							onPressButtonCancel={() => this.setState({ showRebook: false })}
							onPressButtonConfirm={() => {
								window.location.href = `/?shipment=${shipmentId}`;
							}}
							labelButtonConfirm={this.props.t('common:shipmentDetail.Confirm')}
							labelButtonCancel={this.props.t('common:shipmentDetail.Cancel')}
							styleColorButtonConfirm="primary"
							titleHeader={this.props.t('common:shipmentDetail.notification')}
							onHide={() => this.setState({ showRebook: false })}
						>
							<div style={{ lineHeight: 1 }}>
								{this.props.t('common:shipmentDetail.doYouNeedToReBooking')}
							</div>
						</ModalConfirm>
					</Menu>
				</div>
			</CurrentUser>
		);
	}
}

export default compose(
	graphql(shipmentsUpdateMutation, {
		name: 'shipmentsUpdateMutation',
	})
)(withNamespaces()(ShipmentDetail));
