import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
  padding: 40px;
`;

const Card = (props) => {
  return (
    <Container>
      {props.children}
    </Container>
  )
}

export default Card;