import React from 'react';
import { Query } from 'react-apollo';
import userQueryClient from 'src/config/graphqlClient/query/user';

class Authentication extends React.Component {
	render() {
		return (
			<Query
				query={userQueryClient}
			>
				{
					({ data }) => {
						const token = data.user.token;

						if(!token) {
							return null;
						}

						return this.props.children;
					}
				}
			</Query>
		);
	}
}

export default Authentication;
