import gql from "graphql-tag";

export default gql`
  mutation DriverFavoriteAndBlacklistDelete(
    $driver_user_id: [ID]!
  ) {
    driverfavoriteAndBlacklistDelete(
      driver_user_id: $driver_user_id
    ) {
      succeed
    }
  }
`;
