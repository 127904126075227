import React from 'react';
import styled from 'styled-components';
import { Input, Row, Col, Form, Select } from 'antd';
import { Header, Blank, List } from '../../components';
import { ContactAddressContext } from '../../../../contexts/ContactAddressContext';
import accountContactListQuery from '../../../../../../graphql/query/accountContactList';
import client from 'src/config/client';

const FormStyle = styled(Form.Item)`
	.ant-form-item-label {
		line-height: 1;
	}

	&.ant-form-item {
		margin-bottom: 15px;
	}

	.ant-input:hover,
	.ant-input:focus {
		border-color: rgba(0, 0, 0, 0.3);
		box-shadow: none;
	}

	.ant-select-selection:hover,
	.ant-select-selection:active,
	.ant-select-selection:focus {
		border-color: rgba(0, 0, 0, 0.3);
		box-shadow: none;
	}

	.ant-select-arrow {
		font-size: 16px;
	}

	.ant-form-explain {
		font-size: 18px;
		line-height: 1;
		min-height: auto;
	}
`;

const HeaderStyle = styled(Header)`
	margin-bottom: 15px;
`;

const BlankStyle = styled(Blank)`
	margin-bottom: 20px;
	margin-left: -15px;
	margin-right: -15px;
`;

class ContactDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contactList: [],
		};
	}

	static contextType = ContactAddressContext;

	componentDidMount() {
		this.fetchDataContactList();
	}

	fetchDataContactList = async () => {
		try {
			const { data } = await client.query({
				query: accountContactListQuery,
				fetchPolicy: 'network-only',
			});

			const { account_contact } = data.accountContactList;
			const contactList = account_contact.map(item => {
				return {
					id: item._id,
					contactName: item.contact_name,
					contactTel: item.contact_tel,
				};
			});

			this.setState({
				contactList: contactList,
			});
		} catch (error) {
			console.error(error);
		}
	};

	changeMainAddressHandler = event => {
		this.context.setData({ mainAddress: event.target.value });
	};

	selectSearchLocation = () => {
		const { lat, lng, address } = this.context.state;
		this.context.setData({
			tempLat: lat,
			tempLng: lng,
			tempAddress: address,
		});

		this.context.onCloseAllAndOpen(['stepSearchLocation']);
	};

	render() {
		let selectContactList = (
			<Select.Option value="">
				กำลังโหลด...
			</Select.Option>
		);

		if (this.state.contactList.length > 0) {
			selectContactList = this.state.contactList.map((item, index) => (
				<Select.Option value={item.id} key={index}>
					{item.contactName}
				</Select.Option>
			));
		}

		return (
			<div>
				<HeaderStyle title="ชื่อสถานที่" />
				<FormStyle>
					<Input
						placeholder="ระบุ"
						name="mainAddress"
						value={this.props.formik.values.mainAddress}
						onChange={this.props.formik.handleChange}
						onBlur={this.props.formik.handleBlur}
					/>
				</FormStyle>

				<HeaderStyle title="ที่อยู่" />

				<List
					text={this.context.state.address}
					clicked={this.selectSearchLocation}
				/>

				<BlankStyle />

				<HeaderStyle title="ข้อมูลผู้ติดต่อ" />
				<Row>
					<Col>
						<FormStyle
							label="ชื่อผู้ติดต่อ"
							validateStatus={
								this.props.formik.errors.contactId &&
								this.props.formik.touched.contactId &&
								'error'
							}
							help={
								this.props.formik.errors.contactId &&
								this.props.formik.touched.contactId &&
								this.props.formik.errors.contactId
							}
						>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder="ชื่อผู้ติดต่อ"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								notFoundContent="ไม่มีข้อมูล"
								name="contactId"
								value={this.state.contactList.length > 0 ? this.props.formik.values.contactId : ''}
								onChange={value => {
									const { contactList } = this.state;
									const contactFilter = contactList.filter(
										item => item.id === value
									);
									this.props.formik.setFieldValue('contactId', value);
									this.props.formik.setFieldValue(
										'contactTel',
										contactFilter[0].contactTel || ''
									);
								}}
								onBlur={this.props.formik.handleBlur}
							>
								{selectContactList}
							</Select>
						</FormStyle>
					</Col>
					<Col>
						<FormStyle
							label="เบอร์โทรติดต่อ"
							validateStatus={
								this.props.formik.errors.contactTel &&
								this.props.formik.touched.contactTel &&
								'error'
							}
							help={
								this.props.formik.errors.contactTel &&
								this.props.formik.touched.contactTel &&
								this.props.formik.errors.contactTel
							}
						>
							<Input
								style={{ color: '#000000' }}
								placeholder="0812345678"
								value={this.props.formik.values.contactTel}
								onChange={this.props.formik.handleChange}
								onBlur={this.props.formik.handleBlur}
								name="contactTel"
								disabled
							/>
						</FormStyle>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ContactDetail;
