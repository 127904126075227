import gql from 'graphql-tag';

export default gql`
	mutation AccountBusinessUpdate(
		$id: ID
		$account_business_id: ID
		$account_info_business_name: String
		$account_info_tax_id: String
		$account_info_address: String
		$account_info_country: String
		$account_info_province: String
		$account_info_district: String
		$account_info_subdistrict: String
		$account_info_corporate_type: String
		$account_info_corporate_phone_code: String
		$account_info_corporate_phone_number: String
		$account_info_corporate_co_phone_code: String
		$account_info_corporate_co_phone_number: String
		$account_business_email: [account_business_emailArg]
		$account_business_email_delete: [account_business_email_deleteArg]
		$business_usage_information_1: String
		$business_usage_information_2: [String]
		$business_usage_information_3: [String]
		$business_credit_req: Float
		$business_edocument_receipt: Int
		$business_alway_wht: Int
		$business_status: Int
		$business_invoice_type: Int
		$invite_code: String
		$business_invoice_date_range: business_invoice_date_rangeArg
		$account_info_shipping_address: [accountInfoShippingSddressArg]
		$business_pay_round: business_pay_roundArg
		$shipping_from_main_address: Boolean
		$recommend_company: String
	) {
		accountBusinessUpdate(
			_id: $id
			account_business_id: $account_business_id
			account_info_business_name: $account_info_business_name
			account_info_tax_id: $account_info_tax_id
			account_info_address: $account_info_address
			account_info_country: $account_info_country
			account_info_province: $account_info_province
			account_info_district: $account_info_district
			account_info_subdistrict : $account_info_subdistrict
			account_info_corporate_type: $account_info_corporate_type
			account_info_corporate_phone_code: $account_info_corporate_phone_code
			account_info_corporate_phone_number: $account_info_corporate_phone_number
			account_info_corporate_co_phone_code: $account_info_corporate_co_phone_code
			account_info_corporate_co_phone_number: $account_info_corporate_co_phone_number
			account_business_email: $account_business_email
			account_business_email_delete: $account_business_email_delete
			business_usage_information_1: $business_usage_information_1
			business_usage_information_2: $business_usage_information_2
			business_usage_information_3: $business_usage_information_3
			business_credit_req: $business_credit_req
			business_edocument_receipt: $business_edocument_receipt
			business_alway_wht: $business_alway_wht
			business_status: $business_status
			business_invoice_type: $business_invoice_type
			invite_code : $invite_code
			business_invoice_date_range: $business_invoice_date_range
			shipping_from_main_address: $shipping_from_main_address
			account_info_shipping_address: $account_info_shipping_address
			business_pay_round: $business_pay_round
			recommend_company: $recommend_company
		) {
			business_status
			business_req_number
			last_update
		}
	}
`;
