import React, { PropTypes, Component } from 'react';
import Helmet from 'react-helmet';
import { Button } from 'react-bootstrap';
import { Header, BreadcrumbHeader } from './../../components';
import './style.css';

import image404 from '../../assets/images/Error_404.svg';

class NotFound extends Component {
  render() {
    const menu = [
			{
				active: false,
				link: '/newshipment',
				icon: 'fal fa-search',
				title: 'เช็คราคาขนส่ง',
			},
			{
				active: false,
				link: '/shipment',
				icon: 'fal fa-box',
				title: 'งานขนส่ง',
			},
    ];
    
    return (
      <div>
        <Helmet>
          <title>404 | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
          <meta
            name="description"
            content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย"
          />
        </Helmet>
        <Header full active={3} />
				<BreadcrumbHeader menu={menu} />
        <div className="wrapper404" >
          <img className="img404" src={image404} alt="404 error" />
          <h1><b>ERROR 404</b></h1>
          <p>ขออภัย ไม่พบหน้าเว็บไซต์ที่คุณต้องการ</p>
          <Button
              bsStyle="primary"
              style={{ marginTop: 10,width: "280px" }}
              onClick={() => window.location = "/"}
          >
            <i className="fal fa-home"></i> กลับหน้าแรก
          </Button>
        </div>
      </div>
      
    )
  }
}

export default NotFound;