import gql from 'graphql-tag';

export default gql`
	mutation UserUpdate(
		$account_user_display_picture: String
	) {
	  accountUserUpdate(
	    account_user_display_picture: $account_user_display_picture
		){
	    _id
	  }
	}
`;
