import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StarRatingContainer = styled.div`
	position: relative;
	margin-top: 10px;
`;
const Icon = styled.i`
	position: relative;
	color: ${props => props.color};
	font-size: ${props => props.size}px;
`;
const IconA = styled.a`
	cursor: pointer;
	padding: 0 5px;
`;

const star = (rating, maxLength, size, onRating) => {
	let state = [];
	for (let i = 0; i < maxLength; i++) {
		if (rating > i && rating < i + 1) {
			state.push(
				<IconA disabled={!onRating} onClick={() => onRating(i + 1)}>
					<Icon
						key={i}
						size={size}
						weight="solid"
						color="#F3CE12"
						className="fas fa-star-half-alt"
					/>
				</IconA>,
			);
		} else if (rating > i) {
			state.push(
				<IconA disabled={!onRating} onClick={() => onRating(i + 1)}>
					<Icon
						key={i}
						size={size}
						weight="solid"
						color="#F3CE12"
						className="fas fa-star"
					/>
				</IconA>,
			);
		} else {
			state.push(
				<IconA disabled={!onRating} onClick={() => onRating(i + 1)}>
					<Icon
						key={i}
						size={size}
						color={onRating ? '#E3E3E3' : '#F3CE12'}
						className="fas fa-star"
					/>
				</IconA>,
			);
		}
	}
	return state;
};

const starView = (rating, maxLength, size) => {
	let state = [];
	for (let i = 0; i < maxLength; i++) {
		if (rating > i && rating < i + 1) {
			state.push(
				<IconA>
					<Icon
						key={i}
						size={size}
						weight="solid"
						color="#F3CE12"
						className="fas fa-star-half-alt"
					/>
				</IconA>,
			);
		} else if (rating > i) {
			state.push(
				<IconA>
					<Icon
						key={i}
						size={size}
						weight="solid"
						color="#F3CE12"
						className="fas fa-star"
					/>
				</IconA>,
			);
		} else {
			state.push(
				<IconA>
					<Icon key={i} size={size} color="#E3E3E3" className="fas fa-star" />
				</IconA>,
			);
		}
	}
	return state;
};

const StarRating = props => {
	const { disabled, maxLength, rating, size, onRating, ...rest } = props;
	return (
		<div>
			<StarRatingContainer {...props}>
				{disabled != ''
					? starView(props.rating, props.maxLength, props.size).map(
							item => item,
					  )
					: star(
							props.rating,
							props.maxLength,
							props.size,
							props.onRating,
					  ).map(item => item)}
			</StarRatingContainer>
		</div>
	);
};

StarRating.propTypes = {
	maxLength: PropTypes.number,
	rating: PropTypes.number,
	size: PropTypes.number,
};

StarRating.defaultProps = {
	disabled: '',
	maxLength: 5,
	rating: 0,
	size: 20,
};

export default StarRating;
