import React, { Component } from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from './../../../../config/color';

import {
	Card,
	BlankSpace,
	CardHeader,
	CreditListItem,
	Skeleton,
} from './../../../../components';

//credit Icon
import visa from '../../../../assets/images/credit/visa_icon.png';
import jcb from '../../../../assets/images/credit/jcb_icon.png';
import master from '../../../../assets/images/credit/master_card_icon.png';

import { graphql, Query, Mutation } from 'react-apollo';
import instancePriceStepQueryClient from './../../../../config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';
import creditCardListQuery from './graphql/query/creditCardList';
import updateCreditCardEditMutationClient from './../../../../config/graphqlClient/mutation/updateCreditCardEdit';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	width: 100%;
`;
const BoxContainer = styled.div`
	background-color: #f9f9f9;
	padding: 20px;
	text-align: center;
	margin-top: 20px;
`;
const CreditContainer = styled.div`
	margin-top: 20px;
	margin-right: 0px;
	margin-left: 0px;
`;
const CreditImage = styled.img`
	display: inline-flex;
	height: 30px;
	margin: 10px;
`;
const KeyContainer = styled.div`
	font-size: 45px;
	margin-bottom: 15px;
`;
const TitleKeyContainer = styled.div`
	font-size: 22px;
	font-weight: bold;
`;
const SmallContainer = styled(Row)`
	font-size: 18px;
	font-weight: normal;
	margin-top: 10px;
`;

const TitleContainer = styled(Row)`
	padding: 15px 20px 0;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	font-weight: bold;
	font-size: 18px;
`;
const SmallTitel = styled.small`
	font-size: 14px !important;
	width: 100%;
	float: left;
	font-weight: bold;
	margin-top: 15px;
`;

class PaymentCreditList extends React.Component {
	state = {
		edit: false,
	};

	closeTap(mode, updateInstancePriceStep) {
		if (mode === 'chanel') {
			updateInstancePriceStep({
				variables: {
					paymentCredit: false,
					paymentCreditEdit: false,
					paymentChanel: true,
					paymentCreditList: false,
				},
			});
		} else {
			updateInstancePriceStep({
				variables: {
					paymentCredit: false,
					paymentCreditEdit: false,
					paymentChanel: false,
					paymentCreditList: false,
				},
			});
		}
	}
	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Mutation
								mutation={updateInstancePriceMutationClient}
								update={() => {
									updateInstancePriceStep({
										variables: {
											paymentCredit: false,
											paymentCreditEdit: false,
											paymentChanel: false,
											paymentCreditList: false,
										},
									});
								}}
							>
								{(updateInstancePrice, { error }) => {
									return (
										<Query query={instancePriceQueryClient}>
											{instancePriceData => {
												return (
													<Card
														content={
															<div>
																<CardHeader title={this.props.t('common:instancePrice.paymentMethod.creditCard.title')}
																borderCardBottom
																onPressClose={() =>
																		this.closeTap(
																			'chanel',
																			updateInstancePriceStep,
																		)
																}/>

																<Query
																	query={creditCardListQuery}
																	fetchPolicy="network-only"
																>
																	{({ loading, error, data }) => {
																		if (loading) return (
																			<Skeleton height={170} width={230} style={{marginTop: 10}}>
																				<rect x="0" y="0" rx="2" ry="2" width="51" height="30" /> 
																				<rect x="59" y="9" rx="4" ry="4" width="150" height="13" />

																				<rect x="0" y="59" rx="2" ry="2" width="51" height="30" /> 
																				<rect x="59" y="68" rx="4" ry="4" width="150" height="13" />

																				<rect x="0" y="118" rx="2" ry="2" width="51" height="30" /> 
																				<rect x="59" y="127" rx="4" ry="4" width="150" height="13" />
																			</Skeleton>
																		);
																		if (error) return error;
																		if (data.creditCardList.length === 0) {
																			return (
																				<div>
																					<BoxContainer>
																						<KeyContainer className="fal fa-lock-alt" />
																						<TitleKeyContainer>
																						{this.props.t('common:instancePrice.PaymentCreditList.lorem1')}
																							<SmallContainer>
																							{this.props.t('common:instancePrice.PaymentCreditList.lorem2')}{' '}
																								<b>Omise</b> {this.props.t('common:instancePrice.PaymentCreditList.lorem3')}{' '}
																								<br />
																								{this.props.t('common:instancePrice.PaymentCreditList.lorem4')}
																							</SmallContainer>
																							<SmallContainer>
																								<b>
																								{this.props.t('common:instancePrice.PaymentCreditList.lorem5')}<br />
																								{this.props.t('common:instancePrice.PaymentCreditList.lorem6')}
																								</b>
																							</SmallContainer>
																							<SmallContainer>
																							{this.props.t('common:instancePrice.PaymentCreditList.lorem7')}
																							</SmallContainer>
																						</TitleKeyContainer>
																						<CreditContainer>
																							<CreditImage src={visa} />
																							<CreditImage src={master} />
																							<CreditImage src={jcb} />
																						</CreditContainer>
																					</BoxContainer>
																					<div style={{ marginTop: 20 }}>
																						<Button
																							bsStyle="primary"
																							block
																							onClick={() =>
																								updateInstancePriceStep({
																									variables: {
																										paymentCredit: true,
																										paymentCreditEdit: false,
																										paymentChanel: false,
																										paymentCreditList: false,
																									},
																								})
																							}
																						>
																							+ {this.props.t('common:instancePrice.PaymentCreditList.lorem8')}
																						</Button>
																					</div>
																				</div>
																			);
																		}

																		return (
																			<div>
																				<div style={{ marginTop: 20 }}>
																					{data.creditCardList.map(
																						(item, index, arr) => (
																							<Mutation
																								mutation={
																									updateCreditCardEditMutationClient
																								}
																							>
																								{updateCreditCardEdit => {
																									return (
																										<CreditListItem
																											key={index}
																											edit={this.state.edit}
																											onClick={() => {
																												if (this.state.edit) {
																													updateCreditCardEdit({
																														variables: {
																															id: item.id,
																															bank: item.bank,
																															last_digits:
																																item.last_digits,
																															brand: item.brand,
																															expiration_month:
																																item.expiration_month,
																															expiration_year:
																																item.expiration_year,
																															name: item.name,
																															card_default: item.card_default
																														},
																													}).then(() => {
																														updateInstancePriceStep(
																															{
																																variables: {
																																	paymentCredit: false,
																																	paymentCreditEdit: true,
																																	paymentChanel: false,
																																	paymentCreditList: false,
																																},
																															},
																														);
																													});
																												} else {
																													updateInstancePrice({
																														variables: {
																															paymentType:
																																'creditCard',
																															creditCard: {
																																id: item.id,
																																bank: item.bank,
																																last_digits:
																																	item.last_digits,
																																brand:
																																	item.brand,
																																expiration_month:
																																	item.expiration_month,
																																expiration_year:
																																	item.expiration_year,
																																name: item.name,
																																__typename:
																																	'CreditCard',
																															},
																														},
																													});
																												}
																											}}
																											selected={
																												instancePriceData.data
																													.instancePrice
																													.creditCard.id ===
																												item.id
																											}
																											cardBrand={item.brand}
																											name={item.name}
																											title={`XXXX XXXX XXXX ${
																												item.last_digits
																											}`}
																										/>
																									);
																								}}
																							</Mutation>
																						),
																					)}
																				</div>
																				<div style={{ marginTop: 20 }}>
																					<Col
																						xs={6}
																						style={{
																							paddingRight: '10px',
																							paddingLeft: '0',
																						}}
																					>
																						<Button
																							onClick={() =>
																								updateInstancePriceStep({
																									variables: {
																										paymentCredit: true,
																										paymentCreditEdit: false,
																										paymentChanel: false,
																										paymentCreditList: false,
																									},
																								})
																							}
																							block
																						>
																							+ {this.props.t('common:instancePrice.PaymentCreditList.lorem8')}
																						</Button>
																					</Col>
																					<Col
																						xs={6}
																						style={{
																							paddingLeft: '10px',
																							paddingRight: '0',
																						}}
																					>
																						<Button
																							onClick={() =>
																								this.setState(prev => ({
																									edit: !prev.edit,
																								}))
																							}
																							block
																						>
																							{this.state.edit
																								? this.props.t('common:instancePrice.PaymentCreditList.lorem9')
																								: this.props.t('common:instancePrice.PaymentCreditList.lorem10')}
																						</Button>
																					</Col>
																				</div>
																			</div>
																		);
																	}}
																</Query>
															</div>
														}
													/>
												);
											}}
										</Query>
									);
								}}
							</Mutation>
						);
					}}
				</Mutation>
			</Container>
		);
	}
}

PaymentCreditList.propTypes = {};

PaymentCreditList.defaultProps = {};

export default withNamespaces()(PaymentCreditList);
