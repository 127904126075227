import React, { Component } from 'react';
import {
	Row,
	Breadcrumb,
	FormGroup,
	InputGroup,
	Button,
	FormControl,
	Col,
} from 'react-bootstrap';
import { Spin } from 'antd';
import styled from 'styled-components';
import Select from 'react-select';
import './style.css';
import color from 'src/config/color';
import s3 from 'src/config/s3';
import { Helmet } from 'react-helmet';
import MedaiQuery from 'react-responsive';
import { withNamespaces } from 'react-i18next';
import { Header, BreadcrumbTitel, Footer } from 'src/components';
import { HeaderNav, SubHeaderNav } from 'src/app/components';
import { Query } from 'react-apollo';
import trackingList from './graphql/query/trackingList';
import accountInfoListByTrackingURLQuery from './graphql/query/accountInfoListByTrackingURL';
import client from 'src/config/client';
import BannerSponsor from 'src/app/container/BannerSponsor';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const InputContainer = styled.div`
	margin: calc(100px + ${heightTopHeader}px) auto 0 auto;
	text-align: center;

	@media (max-width: 767px) {
		margin: calc(10px + ${heightTopHeader}px) auto 0 auto;
	}
`;

const ButtonSearch = styled(Button)`
	width: 90px;
`;

const Title = styled.div`
	font-size: 36px;
	text-align: left;

	@media (max-width: 767px) {
		font-size: 32px;
	}
`;

const Subtitle = styled.div`
	margin-bottom: 36px;
	text-align: left;
	color: #808080;
`;

const BannerContainer = styled.div`
	padding: 10px;
	margin-top: 250px;
	margin-bottom: 50px;

	@media (max-width: 767px) {
		margin-top: 100px;
	}
`;

class Tracking extends Component {
	state = {
		value: this.props.valueTracking,
		account_info_logo: '',
		isLoadingLogo: true,
		searchNumber: '',
	};

	componentDidMount() {
		const { trackingUrl } = this.props.match.params;

		this.fetchAccountInfoLogo(trackingUrl);
	}

	async fetchAccountInfoLogo(trackingUrl) {
		try {
			const { data } = await client.query({
				query: accountInfoListByTrackingURLQuery,
				variables: {
					account_info_tracking_url: trackingUrl,
				},
			});

			const { account_info_logo } = data.accountInfoListByTrackingURL;

			this.setState({
				account_info_logo: account_info_logo
					? s3 + '/' + account_info_logo
					: '',
				isLoadingLogo: false,
			});
		} catch (error) {
			console.log(error);
			this.setState({
				isLoadingLogo: false,
			});
		}
	}

	handleChange(e) {
		this.setState({ value: e.target.value });
	}

	searchTracking() {
		if (this.state.value !== '' && this.state.value !== undefined) {
			const { trackingUrl } = this.props.match.params;
			const path = `/trackingdetail${
				!!trackingUrl ? `/${trackingUrl}` : ''
			}?shipment=${this.state.value}`;
			window.location = path;
		} else {
			alert('กรุณา กรอกรหัสติดตามงานขนส่งของคุณ');
		}
	}

	render() {
		const menu = [
			{
				active: true,
				link: '#',
				title: this.props.t('common:header.shipmentTracking'),
			},
		];

		return (
			<div className="backgroundTracking">
				<Helmet>
					<title>
						Tracking | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
						รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav
					isLoadingLogo={this.state.isLoadingLogo}
					trackingUrl={this.state.account_info_logo}
				/>
				<Row>
					{/* <BreadcrumbTitel menu={menu} /> */}
					<InputContainer>
						<Row>
							<Col
								xs={10}
								xsOffset={1}
								sm={8}
								smOffset={2}
								md={6}
								mdOffset={3}
								lg={6}
								lgOffset={3}
							>
								<Title>{this.props.t('common:header.shipmentTracking')}</Title>
								<Subtitle>
									{this.props.t('common:header.shipmentTrackingSubtitle')}
								</Subtitle>
								<FormGroup>
									<InputGroup>
										<FormControl
											type="text"
											value={this.state.value}
											placeholder={this.props.t(
												'common:header.pleaseFillShipmentId'
											)}
											onChange={event => this.handleChange(event)}
											onKeyPress={event => {
												if (event.charCode === 13) {
													this.searchTracking(event);
												}
											}}
										/>
										<InputGroup.Button>
											<ButtonSearch
												bsStyle="primary"
												onClick={() => this.searchTracking()}
											>
												{this.props.t('common:header.tracking')}
											</ButtonSearch>
										</InputGroup.Button>
									</InputGroup>
								</FormGroup>
							</Col>
						</Row>
					</InputContainer>

					<div className="container">
						<BannerContainer>
							<BannerSponsor page="tracking" />
						</BannerContainer>
					</div>
				</Row>
				<Footer />
			</div>
		);
	}
}

export default withNamespaces()(Tracking);
