import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const IconStyle = styled.div`
    float: right;
    margin-top: -10px;
`;

const DetailContainer = styled.div`
    font-size: 20px;
    line-height: 1;
`;

const SubTitle = styled.div`
    font-weight: bold;
    word-break: break-word;
    white-space: pre-wrap;
`;

const BoxServiceItem = (props) => (
    <div onClick={props.onClick} className={props.selected ? "serviceItem selected" : "serviceItem"} style={{ ...props.style }}>
        <div className="icon">
            {props.icon && (
                <i className={props.icon}></i>
            )}
        </div>
        <DetailContainer className="detail" style={{marginBottom: "10"}}>
            {props.title} <br/>
                {props.children ? props.children : (
                    <SubTitle>
                        {props.subTitle instanceof Array ? props.subTitle[0] === "" || props.subTitle[0] === null ? "-" : props.subTitle[0]
                        : props.subTitle === "" || props.subTitle == null ?  "-" : props.subTitle}
                    </SubTitle>
                )}
                
                {props.selected ? (
                    <IconStyle>
                        <i className="fas fa-chevron-left"></i>
                    </IconStyle>
            ) : props.iconRight ? (
                <IconStyle>
                    <i className="fas fa-chevron-right"></i>
                </IconStyle>
            ) : null }
        </DetailContainer>
    </div>
);

BoxServiceItem.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    icon: PropTypes.string,
    border:PropTypes.bool,
    selected:PropTypes.bool
};
  
BoxServiceItem.defaultProps = {
    title : "",
    subTitle: "-",
    price : "",
    border : false,
    selected : false
};

export default BoxServiceItem;
