import React from 'react';
import classes from './ListArrow.module.css';
import PropTypes from 'prop-types';

const ListArrow = (props) => {
  return (
    <div {...props} className={classes.wrapper} onClick={props.clicked}>
      <div className={classes.header}>
        <div className={classes.left}>{props.titleHeader}</div>
        <div className={classes.right}>
          {props.textRight} <i className="fal fa-angle-right"></i>
        </div>
      </div>
      <div className={classes.body}>
        {props.children}
      </div>
    </div>
  )
}

ListArrow.propTypes = {
  titleHeader: PropTypes.string.isRequired,
  textRight: PropTypes.string,
  children: PropTypes.any,
  clicked: PropTypes.func,
}

export default ListArrow;