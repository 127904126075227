import React, { Component } from "react";
import { Row ,Breadcrumb} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import appIos from './../../assets/images/app-store.svg';
import playStore from './../../assets/images/play-store.svg';
import { graphql, compose, Query, Mutation } from 'react-apollo';
import AccountInfoList from './graphql/query/accountInfoList';

const TOKEN = {
    authorization: localStorage.getItem('token')
  };

const RowContainer = styled(Row)`
    background-color: #fff;
    width: 100%;
    border-radius: 0px;
    border: none;
    position: fixed;
    top: 60px;
    z-index: 99;
    margin-left: 0px;
    margin-right: 0px;
    height: 50px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);

    .breadcrumb > li + li:before {
        content: '';
        padding: 0;
    }
    
    .breadcrumb li {
        display: inline-block;
        height: 36px;
        padding: 0 15px;
        font-size: 20px;
    }
    
    .breadcrumb li.right,
    .breadcrumb li.img {
        padding: 0;
    }
    
    .breadcrumb li a {
        color: #000;
        line-height: 42px;
        text-decoration: none;
    }

    .breadcrumb li i {
        font-size: 16px;
        padding-right: 5px;
    }
    
    .breadcrumb > .active {
        color: #000;
        font-weight: bold;
        border-bottom: 2px solid #d90101;
        height: 100%;
    }
    
    .breadcrumb li.right a {
        line-height: 35px;
        color: #808080;
        text-decoration: none;
    }
    
    .breadcrumb li.img a {
        color: #808080;
        text-decoration: none;
    }
    
    .breadcrumb li.img img {
        height: 35px;
        float: left;
        padding-left: 10px;
    }    
`;

const BreadcrumbContainer = styled(Breadcrumb)`
    padding: 7px 20px 0 0;
    background-color: transparent;
    margin-bottom: 0;
`;

const ItemRight = styled(Breadcrumb.Item)`
    float: right;
`;

const BreadcrumbHeader = (props) => (
    <RowContainer>
        <Query
            query={AccountInfoList}
            context={TOKEN}
        >
            {({ data: accountInfoListData, loading, error }) => {
                const account_info = accountInfoListData;
                let accountBuisness = false;

                if(!loading && !error && account_info !== {} && account_info !== undefined) {
                    accountBuisness = account_info.accountInfoList.account_business !== null && account_info.accountInfoList.account_business.business_status === 2;
                }
                
                return (
                    <BreadcrumbContainer className="container">
                        {
                            props.menu.map((item, key, arr) => {
                                return (
                                    item.business ? (
                                        accountBuisness ? (
                                            <Breadcrumb.Item key={key} active={item.active} href={item.link}>
                                                <a href={item.link}><i className={item.icon}></i> {item.title}</a>
                                            </Breadcrumb.Item>
                                        ) : null
                                    ) : (
                                        <Breadcrumb.Item key={key} active={item.active} href={item.link}>
                                            <a href={item.link}><i className={item.icon}></i> {item.title}</a>
                                        </Breadcrumb.Item>
                                    )
                                )
                            })
                        }

                        <ItemRight className="img" href="https://itunes.apple.com/app/id1439755826" target="_blank"><img src={appIos} className="logo" alt="logo" /></ItemRight>
                        <ItemRight className="img" href="https://play.google.com/store/apps/details?id=com.giztix.app" target="_blank"><img src={playStore} className="logo" alt="logo" /></ItemRight>
                        <ItemRight className="right">Download Our App</ItemRight>
                    </BreadcrumbContainer>
                );
            }}
        </Query>
    </RowContainer>
);

BreadcrumbHeader.propTypes = {
    menu: PropTypes.array,
};
  
BreadcrumbHeader.defaultProps = {
    menu: [],
};

export default BreadcrumbHeader;
