import gql from 'graphql-tag';

export default gql`
	query searchTruckList(
		$shipmentType: Int
	) {
		settingTruckList (
			shipment_type: $shipmentType
		) {
			_id
			setting_truck_name {
				th
				en
			}
			setting_truck_type {
				_id
				truck_type_name {
					th
					en
				}
				truck_type_detail {
					th
				}
				truck_type_size
				truck_type_weight
				truck_type_enable
				truck_type_pic
				truck_type_size_lang {
					th
					en
				}
				truck_type_weight_lang {
					th
					en
				}
			}
		}
	}
`;
