import React from 'react';
import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;

  & .route-item {
    &__title {
      font-weight: bold;
      margin-right: 10px;
      white-space: nowrap;
    }
    
    &__detail {
      display: flex;
      flex-direction: column;
    }
  }
`;

const RouteItem = (props) => {
  return (
    <Container {...props}>
      <div className="route-item__title">{props.titleRoute}</div>
      <div className="route-item__detail">
        <div>
          <Dotdotdot clamp={1}>
            {props.address}
          </Dotdotdot>
        </div>
        <div>{props.contact}</div>
      </div>
    </Container>
  )
}

export default RouteItem;