import React, { Component } from "react";
import {Row , Badge} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './style.css';
import { withNamespaces } from "react-i18next";

const ItemContainer = styled(Row)`
    & .text-pre-wrap {
        white-space: pre-wrap;
    }
`;

const IconContainer = styled.div`
    font-size: 22px !important;
`;

const InfoContainer = styled.i`
    margin-left: 7px;
    color: #B3B3B3;
    font-size: 16px;
    position: absolute;
`;


const TruckListItem = (props) => (
    <ItemContainer onClick={props.enabled && props.onClick} data-id={props._id} className={`truckListItem ${props.selected ? "selected" : ""} ${props.enabled ? "" : "disabled"}`}>
        <div className="imageStyle"><img src={props.image} alt="truck" /></div>
        <div className="title">{props.title} 
            <small>
                { props.titleSize && (<div>{props.titleSize}</div>) }
                <div className="text-pre-wrap">{props.size}</div>
                <div className="text-pre-wrap">{props.weightTotal}</div>
            </small>

            {props.showInfo ? (
                <InfoContainer  onClick={(e) => props.onClickInfo(e)} className="fal fa-info-circle" />
            ) : null}
            
        </div>
        <IconContainer className="rightIconStyle" style={{color:`${props.selected ? "#19AA19" : "#E3E3E3"}`}}><i className={props.selected ? "fas fa-check-circle" : "fal fa-circle"}></i></IconContainer>
    </ItemContainer>
);

TruckListItem.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    weightTotal: PropTypes.string,
    selected: PropTypes.bool,
    enabled: PropTypes.bool,
    showInfo: PropTypes.bool,
    titleSize: PropTypes.string,
};
  
TruckListItem.defaultProps = {
    title : "",
    size : "",
    selected: false,
    enabled: false,
    showInfo: false,
    titleSize: '',
};

export default withNamespaces()(TruckListItem);
