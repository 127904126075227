import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import { withNamespaces } from 'react-i18next';
import { ModalConfirm, PDFPreview } from 'src/components';
import * as Yup from 'yup';
import client from 'src/config/client';
import ReactHTMLParser from 'react-html-parser';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import Lightbox from 'lightbox-react';
import s3 from 'src/config/s3';
import {
	Row,
	Col,
	Button,
	FormGroup,
	ControlLabel,
	FormControl,
} from 'react-bootstrap';
import Dotdotdot from 'react-dotdotdot';
import {
	TextMessageForm,
	ReceiptDocContainer,
	ImageContainer,
	ButtonUploadReceiptDoc,
	ImageThumbnail,
} from './Styles';
import singleUploadMutation from './graphql/mutation/singleUpload';

const ModalBookingConfirmImport = props => {
	const { onHide, t, instancePriceQuery, show } = props;
	const [receiptDocIndexLightBox, setReceiptDocIndexLightBox] = useState(0);
	const [isOpenModalAlertImage, setIsOpenModalAlertImage] = useState(false);
	const [textAlertModalImage, setTextAlertModalImage] = useState(false);
	const [isOpenReceiptDocLightBox, setIsOpenReceiptDocLightBox] = useState(
		false
	);
	const _inputUploadReceiptDoc = useRef();

	const renderReceiptDocLightBox = receiptDocsList => {
		const receiptDocs = receiptDocsList.map(item => {
			const patternCheckFileType = /^.*\.(pdf|PDF)$/;
			const linkFile = `${s3}/${item}`;
			if (patternCheckFileType.test(linkFile)) {
				return <PDFPreview file={linkFile} />;
			}

			return linkFile;
		});

		const customStyle = {
			overlay: {
				zIndex: 99999,
			},
		};

		return (
			<Lightbox
				mainSrc={receiptDocs[receiptDocIndexLightBox]}
				nextSrc={
					receiptDocs.length > 1
						? receiptDocs[(receiptDocIndexLightBox + 1) % receiptDocs.length]
						: undefined
				}
				prevSrc={
					receiptDocs.length > 1
						? receiptDocs[
								(receiptDocIndexLightBox + receiptDocs.length - 1) %
									receiptDocs.length
						  ]
						: undefined
				}
				onCloseRequest={() => setIsOpenReceiptDocLightBox(false)}
				onMovePrevRequest={() =>
					setReceiptDocIndexLightBox(
						prevState =>
							(prevState + receiptDocs.length - 1) % receiptDocs.length
					)
				}
				onMoveNextRequest={() =>
					setReceiptDocIndexLightBox(
						prevState => (prevState + 1) % receiptDocs.length
					)
				}
				reactModalStyle={customStyle}
			/>
		);
	};

	const importBookingConfirmValidate = Yup.object().shape({
		jobCode: Yup.string(),
		customerName: Yup.string(),
		blNumber: Yup.string().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.blNumber.validate.required'
			)
		),
		invoiceNo: Yup.string(),
		commodity: Yup.string().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.commodity.validate.required'
			)
		),
		packagingType: Yup.string(),
		weightPerContainer: Yup.number()
			.required(
				t(
					'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.weightPerContainer.validate.required'
				)
			)
			.min(
				1,
				t(
					'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.weightPerContainer.validate.min'
				)
			),
		receiptDoc: Yup.array().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.receiptDoc.validate.required'
			)
		),
	});

	const exportBookingConfirmValidate = Yup.object().shape({
		jobCode: Yup.string(),
		customerName: Yup.string().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.customerName.validate.required'
			)
		),
		blNumber: Yup.string().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.blNumber.validate.required'
			)
		),
		invoiceNo: Yup.string(),
		commodity: Yup.string().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.commodity.validate.required'
			)
		),
		packagingType: Yup.string(),
		weightPerContainer: Yup.number()
			.required(
				t(
					'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.weightPerContainer.validate.required'
				)
			)
			.min(
				1,
				t(
					'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.weightPerContainer.validate.min'
				)
			),
		receiptDoc: Yup.array().required(
			t(
				'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.receiptDoc.validate.required'
			)
		),
	});

	const getFileNameFormPath = (filePath) => {
		return filePath.replace(/^.*[\\\/]/, '');
	}

	const renderReceiptDoc = (receiptDocsList, props) => {
		const receiptDocs = receiptDocsList.map(item => {
			const patternFileType = /\.[0-9a-z]{1,5}$/;
			const patternCheckFileType = /^.*\.(pdf|PDF)$/;
			const thumbnail = patternCheckFileType.test(item)
				? `${require('src/assets/images/file-type-pdf.png')}`
				: `${s3}/${item}`;
			const type = patternCheckFileType.test(item) ? 'pdf' : 'image';

			return {
				path: `${s3}/${item}`, //path ไฟล์
				fileType: item.match(patternFileType)[0], //นามสกุลไฟล์
				type: type, //ประเภทไฟล์
				thumbnail: thumbnail, //รูปตัวอย่าง
			};
		});

		return receiptDocs.map((item, index) => (
			<ImageThumbnail>
				<div className="image">
					<div className="image-hover">
						<div
							className="btn-hover-image"
							onClick={() => {
								setReceiptDocIndexLightBox(index);
								setIsOpenReceiptDocLightBox(true);
							}}
						>
							<i className="fal fa-search" />
							<span>
								{item.type === 'image'
									? t(
											'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewPicture'
										)
									: t(
											'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.viewDocument'
										)}
							</span>
						</div>
						<div
							className="btn-hover-image"
							onClick={() => {
								const receiptDocs = receiptDocsList.filter((e, i) => i !== index);
								props.values.receiptDoc = receiptDocs;
								props.setValues(props.values);
							}}
						>
							<i className="fal fa-trash-alt" />
							<span>
								{item.type === 'image'
									? t(
											'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.deletePicture'
										)
									: t(
											'common:instancePrice.shipmentConfirm.moreInformations.documentAndImage.deleteDocument'
										)}
							</span>
						</div>
					</div>
					<img src={`${item.thumbnail}`} alt="" />
				</div>
				<div className="title-filename" title={getFileNameFormPath(item.path)}>
					<Dotdotdot clamp={2}>{getFileNameFormPath(item.path)}</Dotdotdot>
				</div>
			</ImageThumbnail>
		));
	};

	const checkFileTypeImage = (files) => {
		if (files.length === 0) {
			return false;
		}

		let validFileSize = true;
		files.forEach(file => {
			validFileSize = validFileSize && (
				file.type === 'image/png' ||
				file.type === 'image/jpeg' ||
				file.type === 'application/pdf'
			);
		});

		return validFileSize;
	};

	const checkFileSizeImage = (files) => {
		if (files.length === 0) {
			return false;
		}

		let validFileSize = true;
		files.forEach(file => {
			const size = parseFloat(file.size / 1024).toFixed(2);
			validFileSize = validFileSize && size < 10240;
		});

		return validFileSize;
	};

	const convertFileName = (filename) => {
		const fileName = filename.split('.').slice(0, -1).join('.');
		const fileExtension = filename.split('.').pop();
		const editFileName = fileName.match(/[a-zA-Z0-9]+/g).join('-');
	
		return {
			fullFileName: `${editFileName}.${fileExtension}`,
			fileName: editFileName,
			ext: fileExtension,
		};
	}

	const handleSingleUploadReceiptDoc = (uploadFile, documentName, event) => {
		if (checkFileTypeImage(event.target.files) === false) {
			setIsOpenModalAlertImage(true);
			setTextAlertModalImage(
				t('common:instancePrice.modal.uploadFileCheckTypeFile.content')
			);
			return;
		}

		if (checkFileSizeImage(event.target.files) === false) {
			setIsOpenModalAlertImage(true);
			setTextAlertModalImage(
				t('common:instancePrice.modal.uploadFileCheckSizeFile.content')
			);
			return;
		}

		const {
			target: {
				validity,
				files,
			},
		} = event;

		if (validity) {
			files.forEach((file) => {
				const editFileName = convertFileName(file.name);
				const timestamp = new Date().getTime();
				uploadFile({
					variables: {
						file: file,
						path: `upload/shipments/${editFileName.fileName}_${documentName}_${timestamp}`,
					},
				});
			});
		}
	};

	return (
		<>
			<Query query={instancePriceQueryClient}>
				{instancePriceData => {
					return (
						<Formik
							initialValues={{
								jobCode:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.jobCode,
								blNumber:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.blNumber,
								commodity:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.commodity,
								customerName:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.customerName,
								invoiceNo:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.invoiceNo,
								packagingType:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.packagingType,
								weightPerContainer:
									instancePriceQuery.instancePrice.shipmentConfirmDetail
										.weightPerContainer,
								receiptDoc: instancePriceQuery.instancePrice.shipmentConfirmDetail.receiptDoc.map(
									item => item.path
								),
							}}
							validationSchema={
								instancePriceQuery.instancePrice.shipmentType === 2
									? importBookingConfirmValidate
									: exportBookingConfirmValidate
							}
							onSubmit={values => {
								const instancePriceData = client.readQuery({
									query: instancePriceQueryClient,
								});

								const receiptDocSetFormatData = values.receiptDoc.map(item => ({
									path: item,
									__typename: 'ReceiptDoc',
								}));

								client.writeQuery({
									query: instancePriceQueryClient,
									data: {
										instancePrice: {
											...instancePriceData.instancePrice,
											shipmentConfirmDetail: {
												jobCode: values.jobCode,
												customerName: values.customerName,
												blNumber: values.blNumber,
												invoiceNo: values.invoiceNo,
												commodity: values.commodity,
												packagingType: values.packagingType,
												weightPerContainer: values.weightPerContainer,
												receiptDoc: receiptDocSetFormatData,
												__typename: 'ShipmentConfirmDetail',
											},
										},
									},
								});

								onHide();
							}}
						>
							{props => {
								const {
									handleSubmit,
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
								} = props;
								return (
									<div>
										<ModalConfirm width={920} show={show}>
											<div style={{ lineHeight: 1 }}>
												<div style={{ fontSize: 22 }}>
													<strong>
														{t(
															'common:instancePrice.shipmentConfirm.bookingConfirm.modal.title'
														)}
													</strong>
												</div>
												<div style={{ fontSize: 18, color: '#B3B3B3' }}>
													{t(
														'common:instancePrice.shipmentConfirm.bookingConfirm.modal.subTitle'
													)}{' '}
												</div>
												<div style={{ fontSize: 22, marginTop: 25 }}>
													<strong>
														{t(
															'common:instancePrice.shipmentConfirm.bookingConfirm.modal.titleTransportationDetail'
														)}{' '}
														-{' '}
														{instancePriceData.data.instancePrice
															.shipmentType === 2
															? t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.import'
															  )
															: instancePriceData.data.instancePrice
																	.shipmentType === 3
															? t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.export'
															  )
															: ''}
													</strong>
												</div>
												<Row style={{ marginTop: 20 }}>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.jobCode.label'
																)}
																:
															</ControlLabel>
															<FormControl
																max="100"
																type="text"
																value={values.jobCode}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.jobCode.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="jobCode"
															/>
														</FormGroup>
													</Col>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.customerName.label'
																)}
																{instancePriceQuery.instancePrice
																	.shipmentType === 3 && (
																	<span style={{ color: '#D90101' }}>*</span>
																)}
																:{' '}
																{errors.customerName &&
																	touched.customerName && (
																		<TextMessageForm>
																			{errors.customerName}
																		</TextMessageForm>
																	)}
															</ControlLabel>
															<FormControl
																max="100"
																type="text"
																value={values.customerName}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.customerName.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="customerName"
															/>
														</FormGroup>
													</Col>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{instancePriceQuery.instancePrice
																	.shipmentType === 2
																	? 'B / L Number'
																	: 'Booking Confirmation Number'}
																<span style={{ color: '#D90101' }}>*</span>:{' '}
																{errors.blNumber && touched.blNumber && (
																	<TextMessageForm>
																		{errors.blNumber}
																	</TextMessageForm>
																)}
															</ControlLabel>
															<FormControl
																max="50"
																type="text"
																value={values.blNumber}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.blNumber.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="blNumber"
															/>
														</FormGroup>
													</Col>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.invoiceNo.label'
																)}
																:
															</ControlLabel>
															<FormControl
																max="100"
																type="text"
																value={values.invoiceNo}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.invoiceNo.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="invoiceNo"
															/>
														</FormGroup>
													</Col>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.commodity.label'
																)}
																<span style={{ color: '#D90101' }}>*</span>:{' '}
																{errors.commodity && touched.commodity && (
																	<TextMessageForm>
																		{errors.commodity}
																	</TextMessageForm>
																)}
															</ControlLabel>
															<FormControl
																max="255"
																type="text"
																value={values.commodity}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.commodity.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="commodity"
															/>
														</FormGroup>
													</Col>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.packagingType.label'
																)}
																:
															</ControlLabel>
															<FormControl
																max="50"
																type="text"
																value={values.packagingType}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.packagingType.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="packagingType"
															/>
														</FormGroup>
													</Col>
													<Col md={6} sm={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.weightPerContainer.label'
																)}
																<span style={{ color: '#D90101' }}>*</span>:{' '}
																{errors.weightPerContainer &&
																	touched.weightPerContainer && (
																		<TextMessageForm>
																			{errors.weightPerContainer}
																		</TextMessageForm>
																	)}
															</ControlLabel>
															<FormControl
																type="number"
																value={values.weightPerContainer}
																placeholder={t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.weightPerContainer.placeholder'
																)}
																onChange={handleChange}
																onBlur={handleBlur}
																name="weightPerContainer"
															/>
														</FormGroup>
													</Col>
												</Row>

												<div style={{ fontSize: 22, marginTop: 25 }}>
													{ReactHTMLParser(
														t(
															'common:instancePrice.shipmentConfirm.bookingConfirm.modal.titleIncludeAdvance'
														)
													)}
												</div>

												<Row style={{ marginTop: 10 }}>
													<Col md={12}>
														<FormGroup controlId="formBasicText">
															<ControlLabel>
																{t(
																	'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.receiptDoc.label'
																)}
																<span style={{ color: '#D90101' }}>*</span>:{' '}
																{errors.receiptDoc && touched.receiptDoc && (
																	<TextMessageForm>
																		{errors.receiptDoc}
																	</TextMessageForm>
																)}
															</ControlLabel>
															<ReceiptDocContainer>
																<ButtonUploadReceiptDoc
																	disabled={values.receiptDoc.length === 3}
																	onClick={() => {
																		if (values.receiptDoc.length === 3) {
																			return;
																		}

																		_inputUploadReceiptDoc.current.click();
																	}}
																>
																	{t(
																		'common:instancePrice.shipmentConfirm.bookingConfirm.modal.input.receiptDoc.buttonAdd'
																	)}{' '}
																	({values.receiptDoc.length}/3)
																</ButtonUploadReceiptDoc>

																{values.receiptDoc.length > 0 && (
																	<ImageContainer>
																		{renderReceiptDoc(values.receiptDoc, props)}
																	</ImageContainer>
																)}
															</ReceiptDocContainer>

															<Mutation
																mutation={singleUploadMutation}
																update={(cache, { data }) => {
																	if (values.receiptDoc.length === 3) {
																		return;
																	}
																	const receiptDocs = values.receiptDoc.concat(
																		data.singleUpload.path
																	);
																	values.receiptDoc = receiptDocs;
																	props.setValues(values);
																}}
																onError={error => {
																	console.log(error);
																}}
															>
																{uploadFile => {
																	return (
																		<input
																			accept="image/jpeg, image/png, application/pdf"
																			onChange={event => {
																				const filesTotal = values.receiptDoc.length + event.target.files.length;
																				if (filesTotal > 3) {
																					setTextAlertModalImage('สามารถอัพโหลดได้สูงสุดจำนวน 3 ไฟล์');
																					setIsOpenModalAlertImage(true);
																					return;
																				}

																				handleSingleUploadReceiptDoc(
																					uploadFile,
																					'receiptDoc',
																					event
																				);
																			}}
																			type="file"
																			id="file"
																			ref={_inputUploadReceiptDoc}
																			style={{ display: 'none' }}
																			multiple
																		/>
																	);
																}}
															</Mutation>
														</FormGroup>
													</Col>
												</Row>

												<div style={{ textAlign: 'right' }}>
													<Button
														style={{ minWidth: 100, marginRight: 10 }}
														onClick={() => onHide()}
													>
														{t(
															'common:instancePrice.shipmentConfirm.bookingConfirm.modal.buttonClose'
														)}
													</Button>
													<Button
														style={{ minWidth: 100 }}
														bsStyle="primary"
														onClick={handleSubmit}
													>
														{t(
															'common:instancePrice.shipmentConfirm.bookingConfirm.modal.buttonSave'
														)}
													</Button>
												</div>
											</div>
										</ModalConfirm>

										<ModalConfirm
											show={isOpenModalAlertImage}
											onPressButtonConfirm={() =>
												setIsOpenModalAlertImage(false)
											}
											labelButtonConfirm={t(
												'common:instancePrice.modal.button.ok'
											)}
											titleHeader={t('common:instancePrice.modal.title')}
											styleColorButtonConfirm="primary"
											onHide={() => setIsOpenModalAlertImage(false)}
										>
											{textAlertModalImage}
										</ModalConfirm>

										{isOpenReceiptDocLightBox &&
											show &&
											renderReceiptDocLightBox(values.receiptDoc)}
									</div>
								);
							}}
						</Formik>
					);
				}}
			</Query>
		</>
	);
};

export default compose(
	graphql(instancePriceQueryClient),
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
	withNamespaces()
)(ModalBookingConfirmImport);
