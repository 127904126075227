import gql from 'graphql-tag';

export default gql`
  mutation ReSendShipmentToMama(
    $shipment_id: ID!
  ) {
    reSendShipmentToMama(
      shipment_id: $shipment_id
    ) {
      succeed
    }
  }
`;