import React from 'react';
import styled from 'styled-components';
import { Input, Row, Col, Table, Button as ButtonAntd, Icon } from 'antd';
import accountContactListQuery from '../../graphql/query/accountContactList';
import { Query } from 'react-apollo';
import ContactAddressDelete from './container/ContactAddressDelete';
import ContactAddressEdit from './container/ContactAddressEdit';
import ContactAddressContextProvider, {
	ContactAddressContext,
} from './contexts/ContactAddressContext';

const Container = styled.div``;
const TableContainer = styled.div`
	font-size: 20px;
	margin-top: 20px;

	.ant-table {
		line-height: 1;
	}

	.ant-table-tbody
		> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
		> td {
		background: #f9f9f9;
	}

	.ant-table-thead > tr > th {
		font-weight: bold;
	}
`;

const ToolsContainer = styled.div`
	display: flex;

	.ant-btn {
		display: flex;
		align-items: center;

		& i {
			margin-right: 10px;
		}

		&:not(:last-child) {
			margin-right: 10px;
		}
	}
`;

class ContactList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contactNameSearch: '',
			isOpenModalAdd: false,
			isOpenModalEdit: false,
			isOpenModalDeleted: false,
			contactId: null,
			contactAddressId: null,
			contactName: '',
			mainAddress: '',
		};
	}

	openModalAddHandler = () => {
		this.setState({
			isOpenModalAdd: true,
		});
	};

	hideModalAdd = () => {
		this.setState({
			isOpenModalAdd: false,
		});
	};

	openModalEditHandler = (contact, context) => {
		context.setData({
			contactId: contact.contactId,
			addressId: contact.contactAddressId,
			contactTel: contact.contactTel,
			contactName: contact.contactName,
			mainAddress: contact.mainAddress,
			secondaryAddress: contact.secondaryAddress,
			address: contact.address,
			lat: contact.lat,
			lng: contact.lng,
		});

		context.onCloseAllAndOpen(['stepContactDetail']);

		this.setState({
			isOpenModalEdit: true,
		});
	};

	hideModalEdit = () => {
		this.setState({
			isOpenModalEdit: false,
		});
	};

	openModalDeleteHandler = contact => {
		this.setState({
			isOpenModalDeleted: true,
			contactId: contact.contactId,
			contactAddressId: contact.contactAddressId,
			mainAddress: contact.mainAddress,
		});
	};

	hideModalDeleted = () => {
		this.setState({
			isOpenModalDeleted: false,
		});
	};

	changeContactNameSearch = event => {
		this.setState({ contactNameSearch: event.target.value });
	};

	render() {
		const columns = [
			{
				title: 'ชื่อสถานที่',
				dataIndex: 'address',
			},
			{
				title: 'ข้อมูลผู้ติดต่อ',
				dataIndex: 'contact',
			},
			{
				title: 'เครื่องมือ',
				dataIndex: 'tools',
				width: 300,
			},
		];

		return (
			<ContactAddressContextProvider>
				<ContactAddressContext.Consumer>
					{context => {
						return (
							<Container>
								<Row type="flex" justify="space-between">
									<Col xs={12} md={12} lg={6}>
										<Input.Search
											placeholder="ค้นหาจากรายชื่อ"
											defaultValue=""
											value={this.state.contactNameSearch}
											onChange={this.changeContactNameSearch}
										/>
									</Col>
								</Row>

								<Query
									query={accountContactListQuery}
									fetchPolicy="cache-and-network"
									variables={{
										contact_name: this.state.contactNameSearch,
									}}
								>
									{({ data, loading, error }) => {
										let dataTable = [];
										if (!loading && !error) {
											const accountContactFilter = data.accountContactList.account_contact.filter(
												item => item.contact_address.length > 0
											);

											let contactItem = [];
											accountContactFilter.forEach(accountContact =>
												accountContact.contact_address.forEach(
													contactAddress => {
														const data = {
															contactId: accountContact._id,
															contactAddressId: contactAddress._id,
															contactName: accountContact.contact_name,
															contactTel: accountContact.contact_tel,
															mainAddress:
																contactAddress.structured_formatting.main_text,
															secondaryAddress:
																contactAddress.structured_formatting
																	.secondary_text,
															address: contactAddress.address,
															lat: contactAddress.lat,
															lng: contactAddress.lng,
														};
														contactItem.push(data);
													}
												)
											);

											dataTable = contactItem.map((item, index) => {
												return {
													key: index,
													contact: `${item.contactName}, ${item.contactTel}`,
													address: (
														<>
															<div style={{ fontWeight: 'bold' }}>
																{item.mainAddress || 'ไม่มีชื่อสถานที่'}
															</div>
															<div>{item.address}</div>
														</>
													),
													tools: (
														<ToolsContainer>
															<ButtonAntd
																onClick={() =>
																	this.openModalEditHandler(item, context)
																}
															>
																<i className="fal fa-pen-square"></i> แก้ไข
															</ButtonAntd>
															<ButtonAntd
																onClick={() =>
																	this.openModalDeleteHandler(item)
																}
															>
																<i className="fal fa-trash-alt"></i> ลบ
															</ButtonAntd>
														</ToolsContainer>
													),
												};
											});
										}

										return (
											<TableContainer>
												<Table
													columns={columns}
													dataSource={dataTable}
													bordered
													pagination={false}
													size="middle"
													tableLayout="fixed"
													loading={
														loading && {
															indicator: (
																<Icon
																	type="loading"
																	style={{ fontSize: 24 }}
																	spin
																/>
															),
														}
													}
													locale={{
														emptyText: 'ไม่มีข้อมูล',
													}}
												/>
											</TableContainer>
										);
									}}
								</Query>

								<ContactAddressDelete
									visible={this.state.isOpenModalDeleted}
									onHide={this.hideModalDeleted}
									title={`คุณต้องการลบรายชื่อผู้ติดต่อและสถานที่ "${this.state.mainAddress}" ใช่หรือไม่?`}
									contactId={this.state.contactId}
									contactAddressId={this.state.contactAddressId}
								/>

								<ContactAddressEdit
									visible={this.state.isOpenModalEdit}
									onHide={this.hideModalEdit}
								/>
							</Container>
						);
					}}
				</ContactAddressContext.Consumer>
			</ContactAddressContextProvider>
		);
	}
}

export default ContactList;
