import gql from 'graphql-tag';

export default gql`
	mutation GetLatLng($address: String!) {
		getLatlng(address: $address) {
			lat
			lng
		}
	}
`;
