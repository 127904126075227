import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';

const Container = styled.div`
	display: flex;
  flex-direction: row;

  &:hover {
    background-color: #f9f9f9;
  }

  padding-left: 6px;
`;

const LocationContainer = styled.div`
  border-bottom: 1px solid #E3E3E3;
  padding: 8px 0px;
  cursor: pointer;
	flex: 1;
	padding: 10px;
	padding-left: 0px;
`;

const LocationTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
`;

const LocationAddress = styled.div`
	font-size: 18px;
  font-weight: normal;
	line-height: 1;
	margin-top: 5px;
`;

const LocationList = props => {
	return (
		<Container {...props}>			
			<LocationContainer onClick={ props.onPressSelect }>
				{!!props.locationTitle && (<LocationTitle>{ props.locationTitle }</LocationTitle>)}
				{!!props.locationAddress && (
					<LocationAddress>
						<Dotdotdot clamp={1}>{ props.locationAddress }</Dotdotdot>
					</LocationAddress>
				)}
			</LocationContainer>
		</Container>
	);
};

LocationList.propTypes = {
	locationTitle: PropTypes.string,
	locationAddress: PropTypes.string,
	onPressSelect: PropTypes.func,
};

LocationList.defaultProps = {
  locationTitle: '',
	locationAddress: '',
};

export default LocationList;
