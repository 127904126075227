import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  background-color: ${props => props.bgColor};
  color: #FFFFFF;
  padding: 8px 20px;
  line-height: 1;

  display: flex;
  flex-direction: row;

  & .text-bold {
    font-weight: bold;
  }

  & .icon {
    font-size: 18px;
  }

  & .text {
    margin-left: 8px;
  }

  & .header {
    font-size: 20px;
  }
`;

const MessageAlert = (props) => {
  return (
    <Container bgColor={props.bgColor}>
      <div className="icon"><i className={props.icon}></i></div>
      <div className="text">
        <div className="text-bold header">{props.text}</div>
        <div>{props.time}</div>
      </div>
    </Container>
  )
}

MessageAlert.propTypes = {
  icon: PropTypes.string,
  bgColor: PropTypes.string,
};

MessageAlert.defaultProps = {
  icon: "fas fa-check-circle",
  bgColor: "#D90101",
};

export default MessageAlert;