import gql from 'graphql-tag';

export default gql`
	mutation SendEmailInvite($_id: ID!) {
		sendEmailInvite(
      _id: $_id
    ) {
      succeed
    }
	}
`;
