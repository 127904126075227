import React from 'react';
import { graphql, Mutation, Query } from 'react-apollo';
import instancePriceQueryClient from '../../config/graphqlClient/query/instancePrice';
import shipmentPaymentConfirmMutation from './graphql/mutation/shipmentPaymentConfirm';
import client from '../../config/client';
import getInstancePrice from './graphql/query/getInstancePrice';
class ShipmentConfirm extends React.Component {
	additionalVariables(data) {
		return data.instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional_qty: item.qty,
			};
		});
	}

	shipmentAdditionalVariables(data) {
		return data.instancePrice.additional.map(item => {
			return {
				additional_id: item._id,
				additional: item.name,
				qty: item.qty,
				price: item.price,
				address: item.address,
			};
		});
	}

	getPaymentType(paymentType) {
		if (paymentType === 'creditCard') {
			return 1;
		}
		if (paymentType === 'COD') {
			return 2;
		}
	}

	shipmentPaymentConfirmVariables() {
		const { data } = this.props;
		return {
			setting_truck_name_th: data.instancePrice.setting_truck_name,
			truck_type_name: data.instancePrice.setting_truck_type_name,
			setting_truck_id: data.instancePrice.setting_truck_id,
			setting_truck_type_id: data.instancePrice.setting_truck_type_id,
			pick_up_date: data.instancePrice.pickupDate,
			shipment_other_detail_remark: data.instancePrice.remark,
			shipment_address_pickup_mod: 'รับสินค้า',
			shipment_address_pickup_address: data.instancePrice.pickupAddress,
			shipment_address_pickup_lat: data.instancePrice.pickupLatitude,
			shipment_address_pickup_lng: data.instancePrice.pickupLongitude,
			shipment_address_pickup_contact_name:
				data.instancePrice.pickupContactName,
			shipment_address_pickup_contact_tel: data.instancePrice.pickupPhoneNumber,
			shipment_address_pickup_remark: data.instancePrice.pickupRemark,
			shipment_address_pickup_paid_by_cod:
				data.instancePrice.paymentCodDropPoint === 'pickup' ? 1 : null,
			shipment_address_delivery_mod: 'ส่งสินค้า',
			shipment_address_delivery_address: data.instancePrice.deliveryAddress,
			shipment_address_delivery_lat: data.instancePrice.deliveryLatitude,
			shipment_address_delivery_lng: data.instancePrice.deliveryLongitude,
			shipment_address_delivery_contact_name:
				data.instancePrice.deliveryContactName,
			shipment_address_delivery_contact_tel:
				data.instancePrice.deliveryPhoneNumber,
			shipment_address_delivery_remark: data.instancePrice.deliveryRemark,
			shipment_address_delivery_paid_by_cod:
				data.instancePrice.paymentCodDropPoint === 'delivery' ? 1 : null,
			shipment_additional: this.shipmentAdditionalVariables(data),
			paymentType: this.getPaymentType(data.instancePrice.paymentType),
			paymentCreditCardId: data.instancePrice.creditCard.id,
			paymentCreditCardBank: data.instancePrice.creditCard.bank,
			paymentCreditCardLastDigits: data.instancePrice.creditCard.last_digits,
			paymentCreditCardBrand: data.instancePrice.creditCard.brand,
			paymentCreditCarName: data.instancePrice.creditCard.name,
			additional: this.additionalVariables(data),
			promotion_name: data.instancePrice.promotion_name,
		};
	}

	handleGoToShipment(_id) {
		return (window.location = `/shipmentdetail?shipment=${_id}`);
	}

	render() {
		return (
			<Query
				query={getInstancePrice}
				fetchPolicy="cache-and-network"
				variables={{
					additional: this.additionalVariables(this.props.data),
					settingTruckTypeId: this.props.data.instancePrice
						.setting_truck_type_id,
					pickupLatitude: this.props.data.instancePrice.pickupLatitude,
					pickupLongitude: this.props.data.instancePrice.pickupLongitude,
					deliveryLatitude: this.props.data.instancePrice.deliveryLatitude,
					deliveryLongitude: this.props.data.instancePrice.deliveryLongitude,
					promotionName: this.props.data.instancePrice.promotion_name,
					pickupDate: this.props.data.instancePrice.pickupDate,
					shipmentType: this.props.data.instancePrice.shipmentType,
				}}
			>
				{({ loading, error, data, refetch, variables }) => {
					// if (loading) return <div/>;
					// if (error) return <div/>;
					if (!loading && !error) {

						const Pricing = data.getInstancePrice;
	
						return (
							<Mutation
								mutation={shipmentPaymentConfirmMutation}
								variables={this.shipmentPaymentConfirmVariables()}
								update={(cache, { data }) => {
									if (
										this.props.data.instancePrice.paymentType === 'COD' ||
										Pricing.totalPrice === 0
									) {
										this.handleGoToShipment(data.shipmentPaymentConfirm._id);
									}
	
									// When after login
									cache.writeData({
										data: {
											shipmentConfirmStatus: {
												isConfirm: false,
												__typename: 'ShipmentConfirmStatus',
											},
										},
									});
								}}
								onError={error => {

								}}
								refetchQueries={() => ['search']}
							>
								{(shipmentPaymentConfirm, { loading, error, data }) => {
									return this.props.children(shipmentPaymentConfirm);
								}}
							</Mutation>
						);
					} else {
						return this.props.children();
					}
				}}
			</Query>
		);
	}
}

export default graphql(instancePriceQueryClient)(ShipmentConfirm);
