import React from 'react';
import styled from 'styled-components';
import color from '../../../../config/color';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputBox = styled.input`
  font-size: 19px;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  padding: 0 12px;
  line-height: 1;
  height: 40px;
  background-color: #FFFFFF;
  width: 100%;
  padding-right: 75px;

  &:focus {
    outline: none;
  }

  &:disabled {
    ${props => props.disabled && `
      background-color: #F9F9F9;
    `}
  }

  ${props => props.isBorderValidated && `
    border: 1px solid #FF0000;
  `}

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B3B3B3;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B3B3B3;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #B3B3B3;
  }
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const ButtonChange = styled.button`
  background-color: #FFFFFF;
  color: #000000;
  font-size: 16px;
  padding: 4px 15px;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  margin: 0 4px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #F9F9F9;
  }
`;

const ButtonSave = styled.button`
  background-color: ${color.Primary};
  color: #FFFFFF;
  font-size: 16px;
  padding: 4px 15px;
  border: 1px solid ${color.Primary};
  border-radius: 4px;
  margin: 0 4px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${color.Primary}'80';
  }

  &:disabled {
    opacity: .65;
    cursor: not-allowed;
  }
`;

const ButtonCancel = styled.button`
  background-color: transparent;
  color: #000000;
  font-size: 18px;
  padding: 4px 6px;
  border: 0;
  border-radius: 4px;
  margin: 0 4px;

  position: relative;

  &:focus {
    outline: none;
  }
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
`;

const MessageError = styled.div`
  font-size: 18px;
  color: #FF0000;
  text-align: right;
  margin-right: 52px;
`;

class InputEdit extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        { this.props.inputLabel ? (<Label>{this.props.inputLabel}</Label>) : null }
        <Container>
          <InputContainer>
            <InputBox name={this.props.name} style={this.props.styleInput} onChange={this.props.onChange} value={this.props.value} isBorderValidated={this.props.messageError} placeholder={this.props.placeholder} type={this.props.type} disabled={!this.props.edited} value={this.props.value} />
            { this.props.edited ? (<ButtonSave disabled={this.props.disabledButtonSave} onClick={this.props.onClickButtonSave}>{this.props.textLabelSave}</ButtonSave>) : (<ButtonChange onClick={this.props.onClickButtonChange}>{this.props.textLabelChange}</ButtonChange>) }
          </InputContainer>
          <div style={{width: 52}}>
            { this.props.edited && (<ButtonCancel onClick={this.props.onClickButtonCancel}>{this.props.textLabelCancel}</ButtonCancel>) }
          </div>
        </Container>
        { this.props.messageError ? (<MessageError>{this.props.messageError}</MessageError>) : null }
      </div>
    )
  }
}

InputEdit.propTypes = {
  textLabelSave: PropTypes.string,
  textLabelCancel: PropTypes.string,
  textLabelChange: PropTypes.string,
};

InputEdit.defaultProps = {
  textLabelSave: 'บันทึก',
  textLabelCancel: 'ยกเลิก',
  textLabelChange: 'เปลี่ยน',
};

export default InputEdit;