import React from 'react';
import styled from 'styled-components';
import urijs from 'urijs';
import { Header, ModalConfirm } from 'src/components';
import Helmet from 'react-helmet';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { Input, Select, Label, ModalPhoneOTP } from './components';
import * as Yup from 'yup';
import { Query, Mutation, graphql, compose } from 'react-apollo';
import accountUserAddMutation from './graphql/mutation/accountUserAdd';
import accountInviteListQuery from './graphql/query/accountInviteList';
import singleUploadMutation from './graphql/mutation/singleUpload';
import otpGeneratePhoneMutation from './graphql/mutation/otpGeneratePhone';
import signinMutation from './graphql/mutation/signin';
import { withNamespaces } from 'react-i18next';
import s3 from 'src/config/s3';
import client from 'src/config/client';
import { HeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const Container = styled.div`
  margin-bottom: 30px;
`;

const Card = styled.div`
  margin-top: calc(100px + ${heightTopHeader}px);
  padding: 40px;
  padding-top: 20px;
  
  @media(max-width: 767px) {
    padding: 10px;
    margin-top: 20px;
  }
`;

const TextHeader = styled.div`
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
`;

const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const LineHorizontal = styled.div`
	height: 1px;
	width: 100%;
	background-color: #E3E3E3;
`;

const ImageProfile = styled.div`
	margin: 20px auto;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 1px solid #F2F2F2;
	position: relative;
	overflow: hidden;

	& i {
		position: absolute;
    top: 50%;
    left: 50%;
		transform: translate(-50%, -50%);
		font-size: 50px;
		color: rgba(0, 0, 0, 0.1);
	}

	& img {
		width: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
	}
`;

const ImageContainer = styled.div`
  max-width: 550px;
  font-size: 24px;
  text-align: center;
  margin: auto;
  margin-top: 80px;

  & img {
    width: 100%;
  }

  & .title {
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  text-align: right;

  @media(max-width: 768px) {
    text-align: center;
    
    button {
      width: 100%;
    }
  }
`;

class RegisterInvite extends React.Component {
	constructor(props) {
    super(props);
    
    this._inputProfileImage = React.createRef();
		this.state = {
      inviteId: '',
      isOpenModalAlertImage: false,
      textAlertModalImage: '',

      //OTP
      isOpenModalPhoneOTP: false,
      refOTP: '',
      phoneCode: '',
      phoneNumber: '',
    }
	}

	componentWillMount() {
    const uriInvite = urijs(window.location.href).query(true).invite;
    this.setState({ inviteId: uriInvite ? uriInvite : '' });
  }
  
  handleClickUploadProfileImage() {
		this._inputProfileImage.current.click();
  }

  checkFileTypeImage(file) {
		if(file) {
			if(file.type === 'image/png' || file.type === 'image/jpeg') {
				return true;
			} else {
				return false;
			}
		}
  }
  
  checkFileSizeImage(file) {
		if(file) {
			const size = parseFloat(file.size / 1024).toFixed(2);
			if(size < 10240) { //ขนาดไฟล์ 10240KB or 10Mb
				return true;
			} else {
				return false;
			}
		}
	}

  handleSingleUploadProfileImage(uploadFile, documentName , event) {
		const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
		if(checkFileTypeImage === false) {
			this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png และ jpg เท่านั้น' });
			return;
		}

		const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
		if(checkFileSize === false) {
			this.setState({ isOpenModalAlertImage: true, textAlertModalImage: this.props.t('common:instancePrice.modal.uploadFileCheckSizeFile.content') });
			return;
		}

		const {
			target: {
				validity,
				files: [file]
			}
		} = event;

		if(validity) {
			const timestamp = new Date().getTime();
			uploadFile({ variables: {
				file: file,
				path: `upload/profile/${documentName}_${timestamp}`
			}})	
		}
  }
  
  async handleLogin(username, password) {
    try {
      const { data } = await this.props.signinMutation({
        variables: {
          email: username,
          password: password,
        }
      });

      localStorage.setItem('token', data.signin.account_token_key);
      window.location = '/#welcome';
    } catch(error) {
      console.log(error)
    }
  }

  render() {
    const AccountUserAddSchema = Yup.object().shape({
      account_user_display_name: Yup.string()
        .required(this.props.t('common:registerInvite.input.displayName.validate.required')),
      account_user_phone_code: Yup.string()
        .required('กรุณากรอกข้อมูล'),
      account_user_phone_number: Yup.string()
        .matches(/^[0]\d{9}$/, this.props.t('common:registerInvite.input.mobileNumber.validate.format'))
        .required(this.props.t('common:registerInvite.input.mobileNumber.validate.required')),
      account_user_password: Yup.string()
        .min(6, this.props.t('common:registerInvite.input.password.validate.min'))
        .required(this.props.t('common:registerInvite.input.password.validate.required')),
      account_user_password_confirm: Yup.string()
        .oneOf([Yup.ref('account_user_password'), null], this.props.t('common:registerInvite.input.confirmPassword.validate.match'))
        .required(this.props.t('common:registerInvite.input.confirmPassword.validate.required')),
		}); 

    return (
      <div>
        <Helmet>
					<title>สมัครบัญชีย่อย | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
        <HeaderNav />

        <Query
          query={accountInviteListQuery}
          variables={{
            key_inv: this.state.inviteId,
            status: 1,
          }}
        >
          {
            ({ data, loading, error }) => {
              if(loading || error) return 'loading...';

              if(localStorage.getItem('token')) {
                window.location = '/';
                return;
              }

              if(data.accountInviteList.length > 1 || data.accountInviteList.length === 0) { 
                return (
                  <Container className="container">
                    <ImageContainer>
                      <img src={`${require('src/assets/images/404.png')}`} alt="404" />
                      <div className="title">ERROR 404</div>
                      <div>ขออภัย ไม่พบหน้าเว็ปไซต์ที่คุณต้องการ</div>
                    </ImageContainer>
                  </Container>
                )
              }

              const {
                _id,
                account_info_id,
                email,
                key_inv,
                status,
                account_info_business_name,
                create_date,
              } = data.accountInviteList[0];

              return (
                <Container className="container">
                  <Mutation
                    mutation={accountUserAddMutation}
                  >
                    {
                      (accountUserAdd, { loading: loadingUserAdd }) => {
                        return (
                          <Mutation
                            mutation={otpGeneratePhoneMutation}
                          >
                            {
                              (otpGeneratePhone, { loading: loadingOtpGeneratePhone }) => {
                                return (
                                  <Formik
                                    initialValues={{
                                      account_user_display_name: '',
                                      account_user_phone_code: '66',
                                      account_user_phone_number: '',
                                      account_user_name: email,
                                      account_user_password: '',
                                      account_user_password_confirm: '',
                                    }}
                                    validationSchema={AccountUserAddSchema}
                                    onSubmit={(values) => {
                                      otpGeneratePhone({
                                        variables: {
                                          phone: values.account_user_phone_code + values.account_user_phone_number.replace(/\b0+/g,'',)
                                        }
                                      })
                                      .then(({ data }) => {
                                        this.setState({
                                          isOpenModalPhoneOTP: true,
                                          refOTP: data.otpGeneratePhone.ref,
                                        });
                                      }).catch((error) => {
                                        console.log(error);
                                      });
                                    }}
                                  >
                                    {
                                      (props) => {
                                        const {
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          touched,
                                          errors,
                                          values,
                                        } = props;
                                        
                                        return (
                                          <Card>
                                            <TextHeader>{this.props.t('common:registerInvite.title')} {`${account_info_business_name}`}</TextHeader>
                                            <TextSubHeader style={{marginBottom: 40}}>{this.props.t('common:registerInvite.subTitle')}</TextSubHeader>
                                            <Row>
                                              <Col md={6} sm={9}>
                                                <Row>
                                                  <Col xs={12}>
                                                    <Input 
                                                      style={{marginTop: 10}}
                                                      inputLabel={this.props.t('common:registerInvite.input.displayName.label')} 
                                                      placeholder={this.props.t('common:registerInvite.input.displayName.placeholder')}
                                                      required
                                                      messageError={errors.account_user_display_name && touched.account_user_display_name ? errors.account_user_display_name : ''}
                                                      value={values.account_user_display_name}
                                                      onBlur={handleBlur}
                                                      name="account_user_display_name"
                                                      type="text"
                                                      onChange={handleChange}
                                                    />
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Label style={{ color: `${this.state.phoneNumberInvalid ? '#FF0000': ''}` }} required>{this.props.t('common:registerInvite.input.mobileNumber.label')}</Label>
                                                    <InputGroup>
                                                      <Select
                                                        style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0, border: `${errors.account_user_phone_number && touched.account_user_phone_number ? '1px solid #FF0000': ''}`}} 
                                                        value={this.state.phoneCode} 
                                                        onChange={(event) => this.setState({phoneCode: event.target.value})}
                                                      >
                                                        <option value="66">+66</option>
                                                      </Select>
                                                      <Input
                                                        style={{width: '100%'}}
                                                        styleInput={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}
                                                        placeholder={this.props.t('common:registerInvite.input.mobileNumber.placeholder')}
                                                        messageError={errors.account_user_phone_number && touched.account_user_phone_number ? errors.account_user_phone_number : ''}
                                                        value={values.account_user_phone_number}
                                                        onBlur={handleBlur}
                                                        name="account_user_phone_number"
                                                        type="text"
                                                        onChange={handleChange}
                                                      />
                                                    </InputGroup>
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Input 
                                                      disabled
                                                      value={values.account_user_name}
                                                      inputLabel={this.props.t('common:registerInvite.input.email.label')} 
                                                      placeholder="example@email.com"
                                                    />
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Input
                                                      required
                                                      inputLabel={this.props.t('common:registerInvite.input.password.label')} 
                                                      placeholder={this.props.t('common:registerInvite.input.password.placeholder')}
                                                      messageError={errors.account_user_password && touched.account_user_password ? errors.account_user_password : ''}
                                                      value={values.account_user_password}
                                                      onBlur={handleBlur}
                                                      name="account_user_password"
                                                      type="password"
                                                      onChange={handleChange}
                                                    />
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Input
                                                      required
                                                      inputLabel={this.props.t('common:registerInvite.input.confirmPassword.label')} 
                                                      placeholder={this.props.t('common:registerInvite.input.confirmPassword.placeholder')}
                                                      messageError={errors.account_user_password_confirm && touched.account_user_password_confirm ? errors.account_user_password_confirm : ''}
                                                      value={values.account_user_password_confirm}
                                                      onBlur={handleBlur}
                                                      name="account_user_password_confirm"
                                                      type="password"
                                                      onChange={handleChange}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col sm={6}>
                                                <div style={{textAlign: 'center', marginTop: 20}}>
                                                  <div style={{fontWeight: 'bold'}}>{this.props.t('common:registerInvite.profilePicture')}</div>
                                                  <ImageProfile>
                                                    {values.account_user_display_picture ? (<img src={`${s3}/${values.account_user_display_picture}`} alt="" />) : (<i className="fas fa-user"></i>)}
                                                  </ImageProfile>

                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      props.setFieldValue('account_user_display_picture', data.singleUpload.path)
                                                    }}
                                                    onError={(error) => {
                                                      console.log(error)
                                                    }}
                                                  >
                                                    {
                                                      (uploadFile) => {
                                                        return (
                                                          <input accept="image/jpeg, image/png" onChange={(event) => this.handleSingleUploadProfileImage(uploadFile, 'profileImage_' + account_info_id, event)} type="file" id="file" ref={this._inputProfileImage} style={{display: "none"}}/>
                                                        )
                                                      }
                                                    }
                                                  </Mutation>
                                                  <Button onClick={() => this.handleClickUploadProfileImage()}>{this.props.t('common:registerInvite.changePicture')}</Button>
                                                </div>
                                              </Col>
                                            </Row>

                                            <ModalConfirm
                                              show={this.state.isOpenModalAlertImage}
                                              onPressButtonConfirm={() => this.setState({ isOpenModalAlertImage: false })}
                                              labelButtonConfirm={this.props.t('common:instancePrice.modal.button.ok')}
                                              titleHeader={this.props.t('common:instancePrice.modal.title')}
                                              styleColorButtonConfirm="primary"
                                              onHide={() => this.setState({ isOpenModalAlertImage: false })}
                                            >
                                              { this.state.textAlertModalImage }
                                            </ModalConfirm>

                                            <LineHorizontal style={{ marginTop: 30, marginBottom: 40 }}/>

                                            <Row>
                                              <Col md={6} sm={9}></Col>
                                              <Col md={6} sm={6}>
                                                <ButtonContainer>
                                                  <Button 
                                                    style={{minWidth: 160, padding: 10}}
                                                    bsStyle="primary"
                                                    onClick={() => handleSubmit()}
                                                    disabled={loadingOtpGeneratePhone}
                                                  >
                                                    { loadingOtpGeneratePhone ? `${this.props.t('common:registerInvite.submit')}...` : this.props.t('common:registerInvite.submit') }
                                                  </Button>
                                                </ButtonContainer>
                                              </Col>
                                            </Row>

                                            {
                                              this.state.isOpenModalPhoneOTP && (
                                                <ModalPhoneOTP 
                                                  callback={(status) => {
                                                    accountUserAdd({
                                                      variables: {
                                                        account_info_id: account_info_id,
                                                        account_invite_id: _id,
                                                        account_user_display_name: values.account_user_display_name,
                                                        account_user_phone_code: values.account_user_phone_code,
                                                        account_user_phone_number: values.account_user_phone_number.replace(/\b0+/g,''),
                                                        account_user_password: values.account_user_password,
                                                        account_user_display_picture: values.account_user_display_picture || undefined,
                                                        account_user_register_from: 2,
                                                        account_user_name: values.account_user_name,
                                                        account_user_type: 2,
                                                      }
                                                    }).then(({ data }) => {
                                                      this.handleLogin(values.account_user_name, values.account_user_password);
                                                    }).catch((error) => {
                                                      console.log(error);
                                                    });
                                                  }}
                                                  refOTP={this.state.refOTP}
                                                  phoneCode={values.account_user_phone_code}
                                                  phoneNumber={values.account_user_phone_number}
                                                  show={this.state.isOpenModalPhoneOTP}
                                                  onHide={() => this.setState({isOpenModalPhoneOTP: false})} />
                                              )
                                            }
                                          </Card>
                                        )
                                      }
                                    }
                                  </Formik>
                                )
                              }
                            }
                          </Mutation>
                        )
                      }
                    }
                  </Mutation>
                </Container>
              )
            }
          }
        </Query>
      </div>
    )
  }
}

export default compose(
  graphql(signinMutation, {
    name: 'signinMutation'
  })
)(withNamespaces()(RegisterInvite));