import React, { Component } from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	InputGroup,
	Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from './../../../../config/color';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import { compose, graphql, Query, Mutation } from 'react-apollo';
import locationTempQueryClient from './../../../../config/graphqlClient/query/locationTemp';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';
import instancePriceStepQueryClient from './../../../../config/graphqlClient/query/instancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';

import {
	Card,
	BlankSpace,
	CardHeader,
} from './../../../../components';

const Container = styled.div``;

const SearchContainer = styled(Row)`
	margin-top: 20px;
	margin-left: -20px;
	margin-right: -20px;
`;
const SearchInner = styled(Col)``;

const AddressContainer = styled(Row)`
	margin: 20px 0 0;
`;

const AddressUl = styled.ul`
	padding-left: 0;
`;
const AddressLi = styled.li`
	list-style: none;
	padding: 10px;
	cursor: pointer;
`;
const AddressName = styled.b`
	font-size: 20px;
	line-height: 1;
`;

const AddressRow = styled(Row)``;

const TitelContainer = styled(Row)`
	padding: 15px 20px;
	margin-left: -5px;
	margin-right: -5px;
	padding-left: 0;
	padding-right: 0;
	font-weight: bold;
	font-size: 24px;
	border-bottom: 2px dashed #e3e3e3;
	line-height: 1;
`;
const SmallTitel = styled.small`
	font-size: 18px;
	width: 100%;
	float: left;
	font-weight: normal;
	margin-bottom: 5px;
`;

const TextControlLabel = styled(ControlLabel)`
	font-size: 18px;
`;

class ContactInformation extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleChange = this.handleChange.bind(this);

		this.state = {
			contactName: '',
			phoneNumber: '',
			remark: '',
			deliveryDate: '',
		};
	}

	componentDidMount() {
		const { type } = this.props.locationTempQuery.locationTemp;

		const {
			instancePriceQuery: {
				instancePrice: {
					pickupContactName,
					pickupPhoneNumber,
					pickupRemark,
					deliveryContactName,
					deliveryPhoneNumber,
					deliveryRemark,
				},
			},
		} = this.props;

		if (type === 'pickup') {
			this.setState({
				contactName: pickupContactName,
				phoneNumber: pickupPhoneNumber,
				remark: pickupRemark,
			});
		}

		if (type === 'delivery') {
			this.setState({
				contactName: deliveryContactName,
				phoneNumber: deliveryPhoneNumber,
				remark: deliveryRemark,
			});
		}
	}

	handleChange(e) {
		this.setState({ value: e.target.value });
	}

	getType() {
		const {
			instancePriceQuery: {
				instancePrice: {
					shipmentType
				},
			},
		} = this.props;

		const { type } = this.props.locationTempQuery.locationTemp;

		if (type === 'pickup') {
			return 'จุดที่ 1 - รับสินค้า';
		}

		if (type === 'delivery') {
			return 'จุดที่ 2 - ส่งสินค้า';
		}
	}

	onSubmit(e,updateInstancePrice) {
		const chkError = this.checkValidateAll();
		if(e.type == "click" || (e.type == "keypress" && e.charCode == 13) && chkError){
			const {
				type,
				addressDefault,
				latitude,
				longitude,
			} = this.props.locationTempQuery.locationTemp;
			const { contactName, phoneNumber, remark  } = this.state;

			if (type === 'pickup') {
				updateInstancePrice({
					variables: {
						pickupAddress: addressDefault,
						pickupLatitude: latitude,
						pickupLongitude: longitude,
						pickupContactName: contactName,
						pickupPhoneNumber: phoneNumber,
						pickupRemark: remark,
					},
				});
			}

			if (type === 'delivery') {
				updateInstancePrice({
					variables: {
						deliveryAddress: addressDefault,
						deliveryLatitude: latitude,
						deliveryLongitude: longitude,
						deliveryContactName: contactName,
						deliveryPhoneNumber: phoneNumber,
						deliveryRemark: remark,
					},
				});
			}
		}
		
	}

	checkValidateAll() {
		const { contactName, phoneNumber, remark } = this.state;
		const validatePhoneNumber = /^[0]\d{8,9}$/;
		let textRemark = remark;
		
		if(textRemark == null){
			textRemark = '';
		}
		if (
			contactName &&
			validatePhoneNumber.test(phoneNumber) &&
			textRemark.length <= 255
		) {
			return true;
		}

		return false;
	}

	render() {
		return (
			<Container>
				<Card
					content={
						<div>
							<Mutation mutation={updateInstancePriceStepMutationClient}>
								{updateInstancePriceStep => {
									return (
										<CardHeader
											backFucn={() =>
												updateInstancePriceStep({
													variables: {
														searchLocation: true,
														contactInformation: false,
													},
												})
											}
										/>
									);
								}}
							</Mutation>
						</div>
					}
				/>
				<BlankSpace height={10} />
				<Card
					content={
						<Mutation mutation={updateInstancePriceStepMutationClient}>
							{updateInstancePriceStep => {
							return (

								<Mutation
								mutation={updateInstancePriceMutationClient}
								update={cache => {
									const instancePriceStepData = cache.readQuery({
										query: instancePriceStepQueryClient,
									});

									cache.writeQuery({
										query: instancePriceStepQueryClient,
										data: {
											instancePriceStep: {
												...instancePriceStepData.instancePriceStep,
												createShipment: true,
												contactInformation: false,
											},
										},
									});

									const locationTempData = cache.readQuery({
										query: locationTempQueryClient,
									});

									cache.writeQuery({
										query: locationTempQueryClient,
										data: {
											locationTemp: {
												...locationTempData.locationTemp,
												address: '',
												addressDefault: '',
												latitude: 0,
												longitude: 0,
												latitudeCenter: 0,
												longitudeCenter: 0,
												isFitBounds: true,
												isSelected: false,
												type: '',
												shipmentAddressIndex: 0,
												datetime: ''
											},
										},
									});
								}}
							>
								{updateInstancePrice => {
									return (
										<div>
											<TitelContainer>
												<SmallTitel>กรอกข้อมูลเพื่อยืนยัน</SmallTitel>
												{this.getType()}
											</TitelContainer>

											<AddressContainer>
												<AddressUl>
													<AddressLi>
														<AddressRow>
															<AddressName>
																{
																	this.props.locationTempQuery.locationTemp
																		.addressDefault
																}
															</AddressName>
														</AddressRow>
													</AddressLi>
												</AddressUl>
											</AddressContainer>

											<SearchContainer style={{ marginTop: '0' }}>
												<SearchInner sm={12}>
													<FormGroup controlId="formBasicText">
														<TextControlLabel>ชื่อผู้ติดต่อ*</TextControlLabel>
														<FormControl
															type="text"
															value={this.state.contactName}
															placeholder="ชื่อผู้ติดต่อ"
															onKeyPress={(e) => this.onSubmit(e,updateInstancePrice)}
															onChange={e =>
																this.setState({
																	contactName: e.target.value,
																})
															}
														/>
													</FormGroup>
													<FormGroup controlId="formBasicText">
														<TextControlLabel>เบอร์โทรติดต่อ*</TextControlLabel>
														<FormControl
															type="text"
															value={this.state.phoneNumber}
															placeholder="ตัวอย่าง: 0123456789"
															onKeyPress={(e) => this.onSubmit(e,updateInstancePrice)}
															onChange={e =>
																this.setState({
																	phoneNumber: e.target.value,
																})
															}
														/>
													</FormGroup>
													<FormGroup controlId="formBasicText">
														<TextControlLabel>วัน/เวลาที่ต้องการเข้ารับสินค้า</TextControlLabel>
														<FormControl
															type="text"
															value={this.state.deliveryDate}
															placeholder="วัน/เวลาที่ต้องการเข้ารับสินค้า"
															onKeyPress={(e) => this.onSubmit(e,updateInstancePrice)}
															onChange={e =>
																this.setState({
																	deliveryDate: e.target.value,
																})
															}
														/>
													</FormGroup>
													<FormGroup controlId="formBasicText">
														<TextControlLabel>รายละเอียดเพิ่มเติม</TextControlLabel>
														<FormControl
															type="text"
															componentClass="textarea"
															value={this.state.remark}
															placeholder=""
															onChange={e =>
																this.setState({
																	remark: e.target.value,
																})
															}
														/>
													</FormGroup>
												</SearchInner>
											</SearchContainer>

											<SearchContainer>
												<Col xs={6} style={{ paddingRight: '10px' }}>
													
													<Button
														onClick={() =>
															updateInstancePriceStep({
																variables: {
																	searchLocation: true,
																	contactInformation: false,
																},
															})
														}
														block
													>
														เลือกจุดใหม่
													</Button>
												</Col>
												<Col xs={6} style={{ paddingLeft: '10px' }}>
													<Button
														onClick={(e) => this.onSubmit(e,updateInstancePrice)}
														disabled={!this.checkValidateAll()}
														bsStyle="primary"
														block
													>
														ตกลง
													</Button>
												</Col>
											</SearchContainer>
										</div>

										);
									}}
								</Mutation>
							);
						}}
					</Mutation>
					}
				/>
			</Container>
		);
	}
}

ContactInformation.propTypes = {
	onClickPickup: PropTypes.func,
};

ContactInformation.defaultProps = {
	height: 15,
};

export default compose(
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery',
	}),
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
)(ContactInformation);
