import gql from 'graphql-tag';

export default gql`
	query ShareRouteList(
    $id: ID!
  ) {
		shareRouteList(
      _id: $id
    ) {
			_id
      obj_msg
		}
	}
`;
