import React from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from 'src/config/color';
import moment from 'moment';
import th from 'moment/locale/th';
import * as Datetime from 'react-datetime';
import './style.css';

import { Card, CardHeader, ModalConfirm } from 'src/components';

import { graphql, Query, Mutation, compose } from 'react-apollo';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import holiday from 'src/config/holiday';
import selectDateQueryClient from 'src/config/graphqlClient/query/selectDate';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import dateNowQuery from '../../graphql/query/datenow';
import client from 'src/config/client';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import TimeField from 'react-simple-timefield';
import { getWorkingTime, getWorkingTimeFlashDeals } from './../../function/instancePriceTime';

const Container = styled.div`
	width: 100%;

	& .rdtDays tfoot {
		display: none !important;
	}
`;

class SelectDateTime extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			moment: '',
			currentTime: moment(),
			isOpenModalAlertFlashDeals: false,
			isOpenModalAlertWorkingTime: false,
		};
	}

	componentDidMount() {
		const instancePriceData = this.props.instancePriceQuery.instancePrice;
		const { shipmentType, shipmentAddress, pickupDate } = this.props.instancePriceQuery.instancePrice;

		this.setState({
			currentTime: moment().format('YYYY-MM-DD HH:mm:ss')
		})

		if(shipmentType === 1) {
			if(moment(pickupDate, 'YYYY-MM-DD HH:mm').isValid()) {
				this.setState({
					moment: instancePriceData.pickupDate,
				});
			} else {
				if(this.isFlashDeals()) {
					this.setState({
						moment: getWorkingTimeFlashDeals(),
					});
				} else if (
					instancePriceData.setting_truck_id === '5be5057787415b16d8cf48f6' ||
					instancePriceData.setting_truck_id === '5c4ac1ea9d52c92174bf0b2f' ||
					instancePriceData.setting_truck_id === '5cad5a065e962c4d15eb2daf' ||
					instancePriceData.setting_truck_id === '5cbe8678723da822892a6417'
				) {
					this.setState({
						moment: getWorkingTime(),
					});
				}
			}
		} else if((shipmentType === 2 || shipmentType === 3)) {
			if(moment(shipmentAddress[0].pickupDate, 'YYYY-MM-DD HH:mm').isValid()) {
				this.setState({
					moment: shipmentAddress[0].pickupDate,
				});
			} else {
				this.setState({
					moment: getWorkingTime(),
				});
			}
		}
	}

	checkProvinceInBangkok() {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		const { shipmentAddress } = instancePriceData.instancePrice;

		const provinces = [
			'กรุงเทพมหานคร',
			'นครปฐม',
			'นนทบุรี',
			'ปทุมธานี',
			'สมุทรปราการ',
			'สมุทรสาคร',
		];

		const shipmentAddressProvince = shipmentAddress
			.filter(item => item.mode === 'pickup')
			.map(item => item.province);

		return _.some(
			shipmentAddressProvince,
			item => provinces.indexOf(item) !== -1,
		);
	}

	handleChange = selectDate => {
		const datetime = moment(selectDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
		this.setState(
			{
				moment: datetime,
			},
			() => {
				if (moment(this.state.moment).isBefore(getWorkingTime())) {
					this.setState({
						moment: getWorkingTime(),
					});
				}
			},
		);
	};

	closeTap(updateInstancePriceStep) {
		updateInstancePriceStep({
			variables: {
				selectDateTime: false,
			},
		});
	}

	workingDate(date, getWorkingTime) {
		// holiday
		const holidayFilter = holiday.filter(
			item => item === date.format('YYYY-MM-DD'),
		);
		if (holidayFilter.length === 1) {
			return false;
		}

		// yesterday
		const yesterday = moment(this.state.currentTime).subtract(1, 'day');
		if (date.isBefore(yesterday)) {
			return false;
		}

		//getWorkingTime
		if (date.isBefore(getWorkingTime.subtract(1, 'day'))) {
			return false;
		}

		// working time
		const currentDate = moment(this.state.currentTime);
		const startTime = moment(date).set({
			hour: '08',
			minute: '00',
			second: '00',
		});
		const endTime = moment(date).set({
			hour: '18',
			minute: '00',
			second: '59', ////
		});
		if (
			!currentDate.isBetween(startTime, endTime, null, '[]') &&
			date.isSame(moment(this.state.currentTime), 'd')
		) {
			return false;
		}

		return true;
	}

	updateInstancePrice(updateInstancePriceMutationClient) {
		const { shipmentType } = this.props.instancePriceQuery.instancePrice;
		if(shipmentType === 1) {
			if(this.isFlashDeals() && !this.checkFlashDealsPickupDate(this.state.moment)) {
				this.setState({
					isOpenModalAlertFlashDeals: true,
				});

				return;
			}

			updateInstancePriceMutationClient({
				variables: {
					pickupDate: moment(this.state.moment).format('YYYY-MM-DD HH:mm'),
				},
				update: (cache) => {
					const selectDateData = cache.readQuery({
						query: selectDateQueryClient,
					});
			
					cache.writeQuery({
						query: selectDateQueryClient,
						data: {
							selectDate: {
								...selectDateData.selectDate,
								isCompleted: true,
							},
						},
					});

					const instancePriceData = client.readQuery({
						query: instancePriceQueryClient,
					});
					const { shipmentAddress } = instancePriceData.instancePrice;
					const newShipmentAddress = shipmentAddress.map((item) => {
						return {
							...item,
							pickupDate: '',
						}
					});

					client.writeQuery({
						query: instancePriceQueryClient,
						data: {
							instancePrice: {
								...instancePriceData.instancePrice,
								shipmentAddress: newShipmentAddress,
							}
						}
					});
			
					const instancePriceStepData = client.readQuery({
						query: instancePriceStepQueryClient,
					});
			
					client.writeQuery({
						query: instancePriceStepQueryClient,
						data: {
							instancePriceStep: {
								...instancePriceStepData.instancePriceStep,
								shipmentAddress: [...newShipmentAddress],
								selectDateTime: false,
							},
						},
					});
				}
			});
		} else if(shipmentType === 2 || shipmentType === 3) { //shipmentType 2 or 3
			const { shipmentAddress } = this.props.instancePriceQuery.instancePrice;
			const newShipmentAddress = shipmentAddress.map((item, index) => {
				if(index === 0) {
					return {
						...item,
						pickupDate: moment(this.state.moment).format('YYYY-MM-DD 00:00'),
					}
				} else if(
					index > 0 && shipmentAddress[0].pickupDate !== moment(this.state.moment).format('YYYY-MM-DD 00:00')
				) {
					return {
						...item,
						pickupDate: '',
					}
				} else {
					return item;
				}
			});

			client.writeQuery({
				query: instancePriceQueryClient,
				data: {
					instancePrice: {
						...this.props.instancePriceQuery.instancePrice,
						shipmentAddress: newShipmentAddress,
					}
				}
			});

			const selectDateData = client.readQuery({
				query: selectDateQueryClient,
			});
	
			client.writeQuery({
				query: selectDateQueryClient,
				data: {
					selectDate: {
						...selectDateData.selectDate,
						isCompleted: true,
					},
				},
			});
	
			const instancePriceStepData = client.readQuery({
				query: instancePriceStepQueryClient,
			});
	
			client.writeQuery({
				query: instancePriceStepQueryClient,
				data: {
					instancePriceStep: {
						...instancePriceStepData.instancePriceStep,
						selectDateTime: false,
					},
				},
			});
		}
	}

	renderDay(props, currentDate, selectedDate){
		let date = currentDate.date();
		if(currentDate.date() < 10){
			date = '0' + currentDate.date();
		}
		return <td {...props}>{ date }</td>;
	}
	
	renderYear( props, year, selectedDate ){
		return <td {...props}>{ year + 543 }</td>;
	}
	
	modeChange(props){
		console.log(props)
	}

	clearFlashDeals() {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					flashDeals: {
						...instancePriceData.instancePrice.flashDeals,
						id: '',
					}
				}
			}
		});
	}

	isFlashDeals() {
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});

		return !!instancePriceData.instancePrice.flashDeals.id;
	}

	checkFlashDealsPickupDate(dateTime) {
		const data = client.readQuery({
		 query: instancePriceQueryClient,
		});

		const {
			pickupStart,
			pickupEnd,
		} =  data.instancePrice.flashDeals;
	
		return moment(dateTime).isBetween(
			pickupStart,
			pickupEnd,
		 null,
		 '[]',
		);
	}

	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Mutation
								mutation={updateInstancePriceMutationClient}
								update={() => {
									updateInstancePriceStep({
										variables: {
											selectDateTime: false,
										},
									});
								}}
							>
								{(updateInstancePrice, { error }) => {
									return (
										<Card
											content={
												<div>
													<CardHeader
														title={this.props.instancePriceQuery.instancePrice.shipmentType === 1 ? 
															this.props.t('common:instancePrice.selectDatetime.title') :
															this.props.instancePriceQuery.instancePrice.shipmentType === 2 ? this.props.t('common:instancePrice.selectDatetime.titleSchedulePickupDate') :
															this.props.instancePriceQuery.instancePrice.shipmentType === 3 ? this.props.t('common:instancePrice.selectDatetime.titleScheduleCYDate') : ''
														}
														onPressClose={() =>
															this.closeTap(updateInstancePriceStep)
														}
														borderCardBottom
													/>
													<div className="dateTime-wrapper">
														<Datetime
															locale={this.props.t('common:lang')}
															viewDate={moment(this.state.currentTime).format('YYYY-MM-DD HH:mm')}
															input={false}
															isValidDate={(date) => this.workingDate(date , getWorkingTime())}
															value={this.props.instancePriceQuery.instancePrice.shipmentType === 1 ? moment(this.state.moment).format('YYYY-MM-DD HH:mm') : moment(this.state.moment).format('YYYY-MM-DD')}
															dateFormat="YYYY-MM-DD"
															timeFormat={this.props.instancePriceQuery.instancePrice.shipmentType === 1 ? 'HH:mm' : false}
															onChange={this.handleChange}
															onViewModeChange={this.modeChange}
														/>

														{
															this.props.instancePriceQuery.instancePrice.shipmentType === 1 && (
																<TimeField
																	style={{
																		"width":"100%",
																		"fontSize":"50px",
																		"textAlign":"center",
																		"border": "none",
																		"borderBottom":"1px solid #efefef",
																		"lineHeight":"1",
																		"display":"block",
																		"color":"#19AA19",
																		"outline":"none",
																		"padding": "5px 0px",
																	}}
																	value={moment(this.state.moment).format('HH:mm')}
																	onChange={(event, value) => {
																		const date = moment(this.state.moment).format('YYYY-MM-DD');
																		const dateTime = date + ' ' + value;
																		this.setState({ moment: dateTime });
																	}}
																/>
															)
														}
													</div>
													
													{
														this.isFlashDeals() && (
															<div style={{ marginTop: '20px', lineHeight: 1.2 }}>
																<div><strong>FLASH DEALS</strong></div>
																<div>ช่วงวันเวลาที่รับสินค้า:</div>
																<div>
																	{`${moment(this.props.instancePriceQuery.instancePrice.flashDeals.pickupStart).format('YYYY-MM-DD HH:mm')} น. - ${moment(this.props.instancePriceQuery.instancePrice.flashDeals.pickupEnd).format('YYYY-MM-DD HH:mm')} น.`}
																</div>
															</div>
														)
													}

													<div style={{ marginTop: '20px' }}>
														<Button size="lg" block bsStyle="primary" onClick={() => {
															if(
																(this.props.instancePriceQuery.instancePrice.shipmentType === 1 &&
																moment(this.state.moment, 'YYYY-MM-DD HH:mm')
																	.isBefore(getWorkingTime().format('YYYY-MM-DD HH:mm'))) ||
																(this.props.instancePriceQuery.instancePrice.shipmentType !== 1 &&
																moment(this.state.moment, 'YYYY-MM-DD')
																	.isBefore(getWorkingTime().format('YYYY-MM-DD')))
															) {
																this.setState({ isOpenModalAlertWorkingTime: true });
																return;
															}

															this.updateInstancePrice(updateInstancePrice)
														}}>
															{this.props.t('common:instancePrice.selectDatetime.buttonConfirm')}
														</Button>
													</div>

													<ModalConfirm
														show={this.state.isOpenModalAlertFlashDeals}
														labelButtonConfirm={`ยืนยัน`}
														styleColorButtonConfirm="primary"
														labelButtonCancel={`ปิด`}
														titleHeader={`คุณกำลังพลาดโอกาส ได้ราคา FLASH DEALS !!`}
														onHide={() => {
															this.setState({
																isOpenModalAlertFlashDeals: false,
															});
														}}
														onPressButtonCancel={() => {
															this.setState({
																isOpenModalAlertFlashDeals: false,
															});
														}}
														onPressButtonConfirm={() => {
															this.clearFlashDeals();
															this.updateInstancePrice(updateInstancePrice);
															this.setState({
																isOpenModalAlertFlashDeals: false,
															});
														}}
													>
														<div style={{lineHeight: 1}}>
															วัน-เวลา รับสินค้าของคุณ ไม่ตรงตามเงื่อนไข FLASH DEALS นี้ ยืนยันทำรายการนี้ต่อหรือไม่?
														</div>
													</ModalConfirm>

													<ModalConfirm
														show={this.state.isOpenModalAlertWorkingTime}
														labelButtonConfirm={`ตกลง`}
														labelButtonCancel={`ยกเลิก`}
														styleColorButtonConfirm="primary"
														titleHeader={`แจ้งเตือน`}
														onHide={() => {
															this.setState({
																isOpenModalAlertWorkingTime: false,
															});
														}}
														onPressButtonCancel={() => {
															this.setState({
																isOpenModalAlertWorkingTime: false,
															});
														}}
														onPressButtonConfirm={() => {
															this.setState({
																isOpenModalAlertWorkingTime: false,
																moment : this.props.instancePriceQuery.instancePrice.shipmentType === 1 ? getWorkingTime().format('YYYY-MM-DD HH:mm') : getWorkingTime().format('YYYY-MM-DD')
															},() => this.updateInstancePrice(updateInstancePrice));
														}}
													>
														<div style={{lineHeight: 1}}>
															{this.props.instancePriceQuery.instancePrice.shipmentType === 1 && (<span>สามารถจองรถล่วงหน้าได้เร็วที่สุดวันที่ {getWorkingTime().format('DD/MM/YYYY เวลา HH:mm น.')} ต้องการใช้เวลานี้ใช่หรือไม่</span>)}
															{this.props.instancePriceQuery.instancePrice.shipmentType !== 1 && (<span>สามารถจองรถล่วงหน้าได้เร็วที่สุดวันที่ {getWorkingTime().format('DD/MM/YYYY')} ต้องการใช้เวลานี้ใช่หรือไม่</span>)}
														</div>
													</ModalConfirm>
												</div>
											}
										/>
									);
								}}
							</Mutation>
						);
					}}
				</Mutation>
			</Container>
		);
	}
}

SelectDateTime.propTypes = {};
SelectDateTime.defaultProps = {};


export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery'
	}),
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery'
	}),
	graphql(dateNowQuery, {
		name: 'dateNowQuery'
	})
)(withNamespaces()(SelectDateTime));
