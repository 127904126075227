
import React, { Component } from "react";
import { Row, Modal ,Button} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from "./../../../../config/color";

import {
    Card,
    CardHeader,
    BoxPriceItem
} from "./../../../../components";

import { graphql, Query, Mutation } from 'react-apollo';
import instancePriceStepQueryClient from './../../../../config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePrice';

const Container = styled.div`

`;

const ModalBodyContainer = styled.div`
    font-size: 100px;
    line-height: 1;
`;

class ModalAdditional extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: ''
        };
    }

    handleHide(updateInstancePriceStep){
        updateInstancePriceStep({
            variables: {
                additional: false,
                modalAdditional: false,
            },
        })
    }

    render() {
    return (
        <Container>
            <Mutation mutation={updateInstancePriceStepMutationClient}>
                {updateInstancePriceStep => {
                    return (
                        <Query query={instancePriceStepQueryClient}>
                        {({ data }) => {
                            return (
                                <Modal show={data.instancePriceStep.modalAdditional} size="lg">
                                    <Modal.Header>
                                        <Modal.Title>เงื่อนไขการใช้บริการแรงงานยกสินค้า</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <ModalBodyContainer>
                                            <b>แรงงานขนย้ายสินค้า</b> <br/>
                                            - แรงงานขนย้ายสินค้าสินค้าจำกัดสูงสุด 1 ท่าน เป็นเจ้าหน้าที่ติดรถ (ไม่รวมพนักงานขับรถ) <br/>
                                            - สงวนสิทธิ์การให้บริการขนย้ายขึ้นอาคารโดยไม่มีลิฟท์โดยสาร <br/>
                                            - สงวนสิทธิ์การจัดเรียงสินค้าโดยไม่มีผู้รับควบคุม<br/>
                                            - จำกัดระยะทางการขนย้ายจากตัวรถขนส่งไปยังจุดขึ้น-จุดลง หรือลิฟท์ขนส่งสินค้าสินค้าไม่เกิน 30 เมตร <br/>
                                            - จำกัดน้ำหนักสินค้าต่อชิ้นไม่เกิน 30 กิโลกรัม จำนวนไม่เกิน 15 ชิ้น <br/>
                                            - จำกัดน้ำหนักการขนย้ายต่อชิ้น 25 กิโลกรัม จำนวนไม่เกิน 15 ชิ้น <br/>
                                            - จำกัดขนาดของสินค้าต่อชิ้นไม่เกิน 50 x 50 x 50 ซม. <br/>
                                            - สงวนสิทธิ์การให้บริการต้นทาง 1 จุด และปลายทาง 1 จุดเท่านั้น  <br/>
                                            - จำกัดให้เวลาให้บริการขนย้าย จุดละไม่เกิน 2 ชั่วโมง ในกรณีใช้บริการเกินระยะเวลาที่กำหนด คิดอัตราค่าบริการชั่วโมงละ 500 บาท/ท่าน<br/>
                                            - สงวนสิทธิ์การรับประกันสินค้าเสียหายระหว่างการเคลื่อนย้ายทุกกรณี<br/>
                                            <div style={{marginTop:"20px"}}></div>
                                            <b>*เพิ่มเติม</b> <br/>
                                            - ในกรณีลูกค้าต้องการใช้บริการมากกว่าขอบเขตปกติ กรณีติดต่อพนักงานยกสินค้าโดยตรง<br/>
                                            - ทางลูกค้าต้องชำระค่าบริการเพิ่มเติมนอกเหนือจากขอบเขตปกติกับพนักงานยกสินค้าโดยตรง ซึ่งค่าบริการเพิ่มเติมดังกล่าวยังไม่รวมกับค่าบริการแรงงานปกติ <br/>
                                            
                                            <div style={{marginTop:"20px"}}></div>
                                            หากท่านกด ยอมรับ ถือว่าเป็นการยอมเงื่อนไขและข้อจำกัดดังกล่าวทุกประการ
                                        </ModalBodyContainer>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button  onClick={() => this.handleHide(updateInstancePriceStep)} bsStyle="primary" block>ยอมรับ</Button>
                                    </Modal.Footer>
                                </Modal>
                                );
                            }}
                        </Query>
                        );
             }}
             </Mutation>
        </Container>   
    )
    }
};

ModalAdditional.propTypes = {
};
  
ModalAdditional.defaultProps = {
};

export default ModalAdditional;
