import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	border-bottom: 1px solid #e3e3e3;
	padding-bottom: 12px;
	margin-bottom: 12px;

	.icon {
		font-size: 18px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 20px;
        justify-content: center;
        color: ${props => props.LeftIconColor}
	}

	.detail {
		flex: 1;
		margin-left: 15px;

		&-title {
			font-size: 18px;
			font-weight: bold;
			line-height: 1;
		}

		&-sub-title {
			font-size: 16px;
			line-height: 1;
			color: #808080;
		}
    }
    
    .arrow {
        font-size: 22px;
        line-height: 1;
        padding-left: 10px;
        text-align: right;
    }
`;
const AddressListItem = props => (
	<Container {...props} onClick={props.onClick} LeftIconColor={props.LeftIconColor}>
		<div className="icon">
			<i className={props.LeftIcon} />
		</div>
		<div className="detail">
			<div className="detail-title">{props.Title}</div>
			<div className="detail-sub-title">
				<Dotdotdot clamp={2}>{props.detail}</Dotdotdot>
			</div>
		</div>
		<div className="arrow">
			<i style={{ color: `${props.selected ? '#19AA19' : '#E3E3E3'}` }} className={props.selected ? 'fas fa-check-circle' : 'fal fa-circle'} />
		</div>
	</Container>
);

AddressListItem.propTypes = {
	Title: PropTypes.string,
    LeftIcon: PropTypes.string,
    LeftIconColor: PropTypes.string,
	detail: PropTypes.string,
	selected: PropTypes.bool,
};

AddressListItem.defaultProps = {
    Title: '',
    LeftIcon: '',
    LeftIconColor: '#000000',
	detail: '',
	selected: false,
};

export default AddressListItem;
