import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import moment from 'moment';
import { CardHeader, Input, ButtonLink } from '../../components';
import color from '../../config/color';
//Graphql Client
import updateModalForgotPasswordMutationClient from '../../config/graphqlClient/mutation/updateModalForgetPassword';
import updateModalLoginMutationClient from '../../config/graphqlClient/mutation/updateModalLogin';
//Graphql Server
import otpGenerateEmailMutation from './graphql/mutation/otpGenerateEmail';
import getOTPMutation from './graphql/mutation/getOTP';
import resetPasswordMutation from './graphql/mutation/resetPassword';

const Container = styled.div`
	margin-top: 32px;
`;

const ModalContainer = styled(Modal)`
	.modal-dialog {
		margin-top: 120px;
		width: 380px;
	}

	@media(max-width: 767px) {
		.modal-dialog {
			width: calc(100% - 20px);
			margin-top: 40px;
		}
	}
`;

const ButtonConfirm = styled(Button)`
	height: 40px;
	margin-top: 30px;
`;

const MessageContainer = styled.div`
	text-align: left;
	color: ${props => props.color || '#b3b3b3'};
	margin-bottom: 5px;
	line-height: 1;

	& i {
		font-size: 16px;
	}
`;

//OTP Page
const OTPRefContainer = styled.div`
	margin-top: 40px;
	text-align: center;
	color: #808080;
	line-height: 1;
`;

const InputOTPContainer = styled.div`
	margin-top: 20px;
	text-align: center;
	margin-bottom: 20px;
`;

const InputOTP = styled.input`
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	text-align: center;
	width: 48px;
	height: 56px;
	margin-right: 18px;
	font-size: 30px;
`;
//End OTP Page

class ModalForgetPassword extends React.Component {
	constructor(props) {
		super(props);

		this._inputOTP1 = React.createRef();
		this._inputOTP2 = React.createRef();
		this._inputOTP3 = React.createRef();
		this._inputOTP4 = React.createRef();
	}

	state = {
		email: '',
		loading: false,
		error_message: '',
		stepPage: 1,
		otp1: '',
		otp2: '',
		otp3: '',
		otp4: '',
		otpValid: '',
		otpInvalid: '',
		ref: '',
		time: '05:00',
		password: '',
		confirmPassword: '',
		passwordValidate: null,
		confirmPasswordValidate: null,
	};

	handleClose = updateModalForgetPassword => {
		updateModalForgetPassword({ variables: { isOpen: false } });
		this.setState({
			email: '',
			loading: false,
			error_message: '',
			stepPage: 1,
			otp1: '',
			otp2: '',
			otp3: '',
			otp4: '',
			otpValid: '',
			otpInvalid: '',
			ref: '',
			time: '05:00',
			password: '',
			confirmPassword: '',
			passwordValidate: null,
			confirmPasswordValidate: null,
		});
	};

	//### Input email page
	checkEmail = email => {
		const validateEmail = /^(([a-zA-Z0-9]+([\_\.\-][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
		if (validateEmail.test(email)) {
			return true;
		}

		return false;
	};
	//### End INput email page

	//### OTP Page
	//ป้อนหมายเลข OTP
	handleChangeOTP = (index, event, getOTP) => {
		const value = event.target.value;
		this.setState(
			{
				[`otp${index}`]: value,
				password: '',
				confirmPassword: '',
				passwordValidate: null,
				confirmPasswordValidate: null,
			},
			() => {
				if (
					this.state.otp1 &&
					this.state.otp2 &&
					this.state.otp3 &&
					this.state.otp4
				) {
					getOTP();
				} else {
					if (value) {
						if (index === 1) {
							this._inputOTP2.current.focus();
						} else if (index === 2) {
							this._inputOTP3.current.focus();
						} else if (index === 3) {
							this._inputOTP4.current.focus();
						}
					} else {
						if (index === 2) {
							this._inputOTP1.current.focus();
						} else if (index === 3) {
							this._inputOTP2.current.focus();
						} else if (index === 4) {
							this._inputOTP3.current.focus();
						}
					}
				}
			},
		);
	};

	//รีเซ็ตเวลา OTP
	durationTime = () => {
		clearTimeout(this.countDown);
		const eventTime = moment()
			.add(5, 'minutes')
			.unix();
		const currentTime = moment().unix();
		const diffTime = eventTime - currentTime;
		let duration = moment.duration(diffTime * 1000);
		const interval = 1000;

		this.countDown = setInterval(() => {
			duration = moment.duration(duration - interval);
			const time = `${('0' + duration.minutes()).slice(-2)}:${(
				'0' + duration.seconds()
			).slice(-2)}`;

			this.setState({
				time,
			});

			if (duration.as('milliseconds') === 0) {
				clearTimeout(this.countDown);
			}
		}, interval);
	};
	//### End OTP Page

	//### Change password page
	//Check format password
	checkPassword = password => {
		if (password.length >= 6) {
			//รหัสผ่านต้องมีอย่างน้อย 6 ตัว
			return true;
		}

		return false;
	};
	//### End Change password page

	matchPassword = () => {
		if (this.state.confirmPassword) {
			if (this.state.password === this.state.confirmPassword) {
				this.setState({ confirmPasswordValidate: true });
			} else {
				this.setState({ confirmPasswordValidate: false });
			}
		}
	};

	onSubmitEmail(e,otpGenerateEmail){
		const chkError = this.checkEmail(this.state.email);
		if(e.type == "click" || (e.type == "keypress" && e.charCode == 13) && chkError){
			this.setState(
				{ loading: true, error_message: '' },
				(e) => {
					otpGenerateEmail();
				},
			);
		}
	}

	render() {
		return (
			<Mutation mutation={updateModalForgotPasswordMutationClient}>
				{updateModalForgetPassword => {
					return (
						<ModalContainer
							show={this.props.show}
							onHide={() => this.handleClose(updateModalForgetPassword)}
						>
							<Modal.Body style={{ padding: 30 }}>
								{this.state.stepPage === 1 ? (

									<Mutation
										mutation={otpGenerateEmailMutation}
										variables={{ email: this.state.email }}
										update={(cache, { data }) => {
											const ref = data.otpGenerateEmail.ref;
											this.setState(
												{
													stepPage: 2,
													loading: false,
													ref: ref,
													otp1: '',
													otp2: '',
													otp3: '',
													otp4: '',
													otpValid: '',
													otpInvalid: '',
												},
												() => {
													this._inputOTP1.current.focus();
													this.durationTime();
												},
											);
										}}
										onError={error => {
											const err_message = error.graphQLErrors.length
												? error.graphQLErrors[0].message
												: null;
											if (err_message === 'Invalid username.') {
												this.setState({
													error_message: 'อีเมลนี้ไม่มีอยู่ในระบบ',
													loading: false,
												});
											} else {
												this.setState({
													error_message:
														'เกิดข้อผิดพลาดบางอย่างลองอีกครั้ง.',
													loading: false,
												});
											}
										}}
									>
										{otpGenerateEmail => {
											return (
												<div>
													<Mutation
														mutation={updateModalLoginMutationClient}
														variables={{ isOpen: true }}
													>
														{updateModalLogin => {
															return (
																<CardHeader
																	title="ลืมรหัสผ่าน?"
																	subTitle="อย่าตกใจไป...คุณสามารถกู้คืนรหัสผ่านของคุณได้โดยระบุอีเมลที่ใช้สมัคร เราจะส่งวิธีเปลี่ยนรหัสผ่านให้ทางอีเมล"
																	symbol
																	arrowBack
																	onClickArrowBack={() => {
																		updateModalForgetPassword({
																			variables: { isOpen: false },
																		});
																		setTimeout(() => {
																			updateModalLogin();
																		}, 200);
																	}}
																/>
															);
														}}
													</Mutation>

													<Container>
														{this.state.error_message ? (
															<MessageContainer>
																<i className="fas fa-times-circle" />{' '}
																{this.state.error_message}
															</MessageContainer>
														) : null}
														<Input
															type="text"
															placeholder="อีเมล : user@email.com"
															iconLeft="fas fa-envelope"
															value={this.state.email}
															onChange={event =>
																this.setState({ email: event.target.value })
															}
															onKeyPress={(e) => this.onSubmitEmail(e,otpGenerateEmail)}
															name="email"
															autoFocus
														/>

														<ButtonConfirm
															disabled={
																this.state.loading ||
																!this.checkEmail(this.state.email)
															}
															bsStyle="primary"
															block
															onClick={(e) => this.onSubmitEmail(e,otpGenerateEmail)}
														>
															{this.state.loading ? 'ยืนยัน...' : 'ยืนยัน'}
														</ButtonConfirm>
													</Container>
												</div>
											);
										}}
									</Mutation>
								) : null}

								{this.state.stepPage === 2 ? (
									<div>
										<CardHeader
											title="ส่งอีเมลเปลี่ยนรหัสผ่านให้คุณแล้ว"
											arrowBack
											onClickArrowBack={() => {
												this.setState({ stepPage: 1 });
											}}
										/>
										<div style={{lineHeight: 1}}>ทำตามขั้นตอนดังนี้</div>
										<div style={{lineHeight: 1}}>1. ตรวจสอบอีเมลของคุณ</div>
										<div style={{lineHeight: 1}}>2. กรอกรหัส OTP ที่ได้ในอีเมล</div>

										<OTPRefContainer>
											OTP Ref. {this.state.ref} (Time {this.state.time})
										</OTPRefContainer>

										<Mutation
											mutation={getOTPMutation}
											variables={{
												ref: this.state.ref,
												otp: `${this.state.otp1}${this.state.otp2}${
													this.state.otp3
													}${this.state.otp4}`,
											}}
											update={(
												cache,
												{
													data: {
														getOtp: { available },
													},
												},
											) => {
												if (available) {
													this.setState(
														{
															otpValid: true,
															otpInvalid: false,
														},
														() => {
															setTimeout(() => {
																this.setState({ stepPage: 3 });
															}, 500);
														},
													);
												} else {
													this.setState(
														{
															otpValid: false,
															otpInvalid: true,
															otp1: '',
															otp2: '',
															otp3: '',
															otp4: '',
														},
														() => {
															this._inputOTP1.current.focus();
														},
													);
												}
											}}
										>
											{getOTP => {
												return (
													<InputOTPContainer>
														<InputOTP
															ref={this._inputOTP1}
															maxLength="1"
															autocorrect="off"
															value={this.state.otp1}
															onChange={event => {
																this.handleChangeOTP(1, event, getOTP);
															}}
															onFocus={() => {
																this._inputOTP1.current.select();
															}}
														/>
														<InputOTP
															ref={this._inputOTP2}
															maxLength="1"
															autocorrect="off"
															value={this.state.otp2}
															onChange={event => {
																this.handleChangeOTP(2, event, getOTP);
															}}
															onFocus={() => {
																this._inputOTP2.current.select();
															}}
														/>
														<InputOTP
															ref={this._inputOTP3}
															maxLength="1"
															autocorrect="off"
															value={this.state.otp3}
															onChange={event => {
																this.handleChangeOTP(3, event, getOTP);
															}}
															onFocus={() => {
																this._inputOTP3.current.select();
															}}
														/>
														<InputOTP
															ref={this._inputOTP4}
															maxLength="1"
															autocorrect="off"
															value={this.state.otp4}
															onChange={event => {
																this.handleChangeOTP(4, event, getOTP);
															}}
															onFocus={() => {
																this._inputOTP4.current.select();
															}}
															style={{ marginRight: 0 }}
														/>
													</InputOTPContainer>
												);
											}}
										</Mutation>

										{/* ข้อความตรวจสอบ OTP */}
										{this.state.otpValid ? (
											<MessageContainer
												color={color.Primary}
												style={{ textAlign: 'center' }}
											>
												<i className="fas fa-check-circle" /> รหัส OTP ถูกต้อง
											</MessageContainer>
										) : null}

										{this.state.otpInvalid ? (
											<div>
												<MessageContainer
													color={color.SoftGray}
													style={{ textAlign: 'center' }}
												>
													<i className="fas fa-times-circle" /> รหัส OTP
													ไม่ถูกต้อง
												</MessageContainer>
												<MessageContainer
													color={color.SoftGray}
													style={{ textAlign: 'center' }}
												>
													กรอกรหัสใหม่ หรือ ส่งรหัส OTP ทางอีเมลอีกครั้ง
												</MessageContainer>
											</div>
										) : null}

										{/* ปุ่มส่งอีกครั้ง */}
										<div style={{ textAlign: 'center', marginTop: 100 }}>
											<Mutation
												mutation={otpGenerateEmailMutation}
												variables={{ email: this.state.email }}
												update={(cache, { data }) => {
													const ref = data.otpGenerateEmail.ref;
													this.setState(
														{
															ref: ref,
															otp1: '',
															otp2: '',
															otp3: '',
															otp4: '',
															otpValid: '',
															otpInvalid: '',
														},
														() => {
															this._inputOTP1.current.focus();
															this.durationTime();
														},
													);
												}}
												onError={error => {
													this.setState({
														error_message: error.graphQLErrors[0].message,
													});
												}}
												onCompleted={() => {
													this.setState({ loading: false });
												}}
											>
												{otpGenerate => {
													return (
														<ButtonLink
															disabled={this.state.loading}
															icon="fas fa-envelope"
															text={
																this.state.loading
																	? 'ส่งอีเมล อีกครั้ง...'
																	: 'ส่งอีเมล อีกครั้ง'
															}
															iconColor="#8B8B8B"
															textColor="#8B8B8B"
															onClick={() => {
																this.setState({ loading: true }, () => {
																	otpGenerate();
																});
															}}
														/>
													);
												}}
											</Mutation>
										</div>
									</div>
								) : null}

								{this.state.stepPage === 3 ? (
									<div>
										<CardHeader
											title="ตั้งรหัสผ่านใหม่"
											arrowBack
											onClickArrowBack={() => {
												this.setState({ stepPage: 2 });
											}}
										/>

										<Input
											style={{ marginTop: 20 }}
											type="password"
											placeholder="รหัสผ่านใหม่ (อย่างน้อย 6 ตัวขึ้นไป)"
											iconLeft="fas fa-key"
											iconLeftColor={
												this.state.passwordValidate ? color.Primary : null
											}
											status={this.state.passwordValidate === true ? 'valid' : this.state.passwordValidate === false ? 'invalid' : ''}
											value={this.state.password}
											onChange={event => {
												if (this.checkPassword(event.target.value)) {
													this.setState(
														{
															password: event.target.value,
															passwordValidate: true,
														},
														() => {
															this.matchPassword();
														},
													);
												} else {
													this.setState(
														{
															password: event.target.value,
															passwordValidate: false,
														},
														() => {
															this.matchPassword();
														},
													);
												}
											}}
											name="password"
										/>

										<Input
											style={{ marginTop: 20 }}
											type="password"
											placeholder="ยืนยันรหัสผ่านอีกครั้ง"
											iconLeft="fas fa-key"
											iconLeftColor={
												this.state.confirmPasswordValidate
													? color.Primary
													: null
											}
											status={this.state.confirmPasswordValidate === true ? 'valid' : this.state.confirmPasswordValidate === false ? 'invalid' : ''}
											value={this.state.confirmPassword}
											onChange={event => {
												this.setState(
													{ confirmPassword: event.target.value },
													() => {
														this.matchPassword();
													},
												);
											}}
											name="password"
										/>

										<Mutation mutation={updateModalLoginMutationClient}>
											{updateModalLogin => {
												return (
													<Mutation
														mutation={resetPasswordMutation}
														variables={{
															email: this.state.email,
															password: this.state.password,
															otp: `${this.state.otp1}${this.state.otp2}${
																this.state.otp3
																}${this.state.otp4}`,
															ref: this.state.ref,
														}}
														update={(
															cache,
															{
																data: {
																	resetPassword: { succeed },
																},
															},
														) => {
															if (succeed) {
																this.setState(
																	{ loading: false, stepPage: 1 },
																	() => {
																		this.handleClose(updateModalForgetPassword);
																		setTimeout(() => {
																			updateModalLogin({
																				variables: { isOpen: true },
																			});
																		}, 500);
																	},
																);
															}
														}}
														onError={error => {
															this.setState({ loading: false });
														}}
													>
														{resetPassword => {
															return (
																<ButtonConfirm
																	disabled={
																		this.state.loading ||
																		!this.checkPassword(this.state.password) ||
																		this.state.password !==
																		this.state.confirmPassword
																	}
																	bsStyle="primary"
																	block
																	onClick={() => {
																		this.setState(
																			{ loading: true, error_message: '' },
																			() => {
																				resetPassword();
																			},
																		);
																	}}
																>
																	{this.state.loading ? 'บันทึก...' : 'บันทึก'}
																</ButtonConfirm>
															);
														}}
													</Mutation>
												);
											}}
										</Mutation>
									</div>
								) : null}

								{/* เปลี่ยนรหัสผ่านสำเร็จ */}
								{this.state.stepPage === 4 ? (
									<div>เปลี่ยนรหัสผ่านสำเร็จ</div>
								) : null}
							</Modal.Body>
						</ModalContainer>
					);
				}}
			</Mutation>
		);
	}
}

export default ModalForgetPassword;
