import gql from 'graphql-tag';

export default gql`
	query CheckExistsTrackingURL($account_info_tracking_url: String!) {
		checkExistsTrackingURL(
			account_info_tracking_url: $account_info_tracking_url
		) {
			succeed
		}
	}
`;
