import React from 'react';
import classes from './FavoriteDriverDetail.module.css';

const FavoriteDriverDetail = (props) => {
  let icon = null;

  switch(props.type) {
    case 0:
      icon = (
        <div className={`${classes.container} ${classes.grey}`}>
          <i className="fas fa-heart-circle"></i> ไม่ดำเนินการใดๆ
        </div>
      );
      break;
    case 1:
      icon = (
        <div className={`${classes.container} ${classes.red}`}>
          <i className="fas fa-heart-circle"></i> บันทึกเป็นพนักงานขับรถคนโปรด
        </div>
      );
      break;
    case 2:
      icon = (
        <div className={`${classes.container} ${classes.black}`}>
          <i className="fas fa-times-octagon"></i> แบนพนักงานขับรถคนนี้
        </div>
      );
      break;
    default:
      icon = null
  }

  return icon;
}

export default FavoriteDriverDetail;