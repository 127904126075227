import React, { Component } from "react";
import { Row, Breadcrumb} from "react-bootstrap";
import styled from 'styled-components';
import color from "./../../../../config/color";
import heightTopHeader from 'src/app/utils/heightTopHeader';

const RowContainer = styled.div`
    background-color: #fff;
    width: 100%;
    border-radius: 0px;
    border: none;
    position: absolute;
    top: 60px;
    top: calc(60px + ${heightTopHeader}px);
    z-index: 100;
    margin-left: 0px;
    margin-right: 0px;
    height: 50px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,.3);

    @media(max-width: 767px) {
        position: relative;
        top: 0;
    }

    .breadcrumb>li+li:before  {
        content: "";
        padding: 0;
    }

    .breadcrumb li {
        display: inline-block;
        height: 36px;
        padding: 0 15px;
    }

    .breadcrumb li.right,
    .breadcrumb li.img  {
        padding: 0;
    }

    .breadcrumb li a {
        line-height: 35px;
        color: #000;
    }

    .breadcrumb>.active {
        color: #000;
        font-weight: bold;
        border-bottom: 2px solid #D90101;
    }

    .breadcrumb li.right a {
        line-height: 35px;
        color: #808080;
        text-decoration: none;
    }

    .breadcrumb li.img a {
        color: #808080;
        text-decoration: none;
    }
`;

const BreadcrumbContainer = styled(Breadcrumb)`
    padding: 7px 20px 0 0;
    background-color: transparent;
    margin-bottom: 0;
`;

const BackBtn = styled.a`
    float: left;
    padding-right: 10px;
    border-right: 1px solid #e3e3e3;
    margin-right: 10px;    
    margin-top: 8px;
    margin-left: 20px;
    color: ${color.Black};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`;

class SubHeader extends Component {
    render() {
        return (
            <RowContainer>
                <BreadcrumbContainer className="container">
                    { this.props.backLink ? <BackBtn href={this.props.backLink}> <i className="fal fa-arrow-left" style={{color:"#D90101",marginRight:"10px"}}></i> กลับ</BackBtn> : null}
                </BreadcrumbContainer>
            </RowContainer>
        )
    }
};

export default SubHeader;
