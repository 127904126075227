import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	padding-top: 10px;

	& .icon {
		font-size: 20px;
		width: 25px;
		text-align: center;
		margin-right: 10px;
	}

	& .content {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-bottom: 10px;

		${props =>
			props.border &&
			`
      border-bottom: 1px solid #E3E3E3;
    `}

		&-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: -5px;
			cursor: pointer;

			&-title {
				flex: 1;
				font-size: 20px;
				line-height: 1;
				margin-top: 5px;
				margin-bottom: 5px;
			}

			&-arrow {
				color: #888888;
				line-height: 1;
				display: flex;
				flex-direction: row;
				align-items: center;

				& i {
					margin-left: 5px;
				}
			}
		}

		&-detail {
			line-height: 1;
			font-weight: bold;
			font-size: 20px;
			word-break: break-all;
		}
	}
`;

const Required = styled.span`
	color: #d90101;
`;

const ShipmentDetailList = props => {
	const { onUpload, ...except } = props;
	return (
		<Container {...except}>
			<div className="icon">
				<i className={props.icon}></i>
			</div>
			<div className="content">
				<div className="content-header" onClick={props.onUpload}>
					<div className="content-header-title">
						{props.title}
						{props.required && <Required>*</Required>}
					</div>
					<div className="content-header-arrow">
						<span>{props.rightLabel}</span> <i className="fal fa-angle-right"></i>
					</div>
				</div>

				<div className="content-detail">{props.content && <div>{props.content}</div>}</div>

				<div>{props.children}</div>
			</div>
		</Container>
	);
};

ShipmentDetailList.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
	rightLabel: PropTypes.string,
	icon: PropTypes.string,
	required: PropTypes.bool,
};

ShipmentDetailList.defaultProps = {
	title: '',
	content: '',
	rightLabel: '',
	icon: '',
	required: false,
};

export default ShipmentDetailList;
