import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.i`
  display: inline-block;
  font-size: ${props =>  props.size ? `${props.size}px` : 'inherit'};
  vertical-align: -4px;
`;

class Icon extends Component {
  static propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
  }

  static defaultProps = {
    name: '',
    color: '#000000',
    type: 'fas',
  }

  render() {
    const {
      name,
      color,
      type,
      size,
    } = this.props;

    return (
      <Container
        {...this.props}
        className={`${type} ${name}`}
      />
    )
  }
}

export default Icon;