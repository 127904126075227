import React from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	Polyline,
	InfoWindow,
} from 'react-google-maps';
import googleMapsKey from 'src/config/googleMapsKey';
import styled from 'styled-components';
import DotDotDot from 'react-dotdotdot';
import client from 'src/config/client';
import getProvinceQuery from './graphql/query/getProvince';
import checkCountryQuery from './graphql/query/checkCountry';
import getNameFromlatlngQuery from './graphql/query/getNameFromlatlng';
import { ContactAddressContext } from '../../../../../../contexts/ContactAddressContext';

const markerIcon = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAA0CAYAAADi1poDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAppJREFUeNrMWEuOozAQJUn3ZKZXHMGLrLLiCByBIzA34AjcgHVWzA2YA0QikUaa7Mh+FuQEgdUsppuudo0cyQLb2MFu8qQnlA/2c1XZVWXPswfizYiIMqesKEHAhrKkTFwK9SlTNhkYEsWFti1yj5A+C7awScgtCOm7M5BNthhxUal6GbHZbC7b7bZdr9ctfm6aJtjv961G7Hyn/GFimUK2SkII7Ha75nq9NiDB6XSq4zges1agKyYVDeD7PmRZBiao6xrCMFS5bzSmApmYqqrgXiisVYwJKm2L0RAVGlmnKAqwARrwEASBkZUGWxz9bxNlWcqsJIyl2pV1eEisFIuS4yB2XAB3qkAQesdbqrI1XYmTpCgZl/QFDXxI48eJINW4y94Omx28oEP/x7ZtnUx6uVy0BA1wPp+dCFKNywsa/OtwODix0vF4FOrUOodMk6kO8DgRbPtIJCgTlRp45NtCmqZGWZ+IjnVMijaACVpindy4ZM3z3FVihbHqksgKejT3vZZRiMl0NkIsKzsx+5vURrgIiZuA9XXaXYiy24ii6L8bsUQVlRhJkqiE3ALZuIm03QJptUJjSCyLqWy017FFMb6tEz95JDFTY2pSzIyhukNQ5LKGIoY3IZn3CdCNp9pF3Gh3uCYdqZNaXeNy6tOh2nWzXIISiZh8zs4lfxTryGKpfIT+rlZdGsyBhEsR1jrXKbh1vT+9B0Jtw11PE617I953/6L8TbmmfGcE9tTGylDEgi0CJ/1K+Y3xhfIv5R/2G/KZjX+7nAfdCUyAk3xhfOaeT9zi3ihfOf7j2Nl02aLHJfdcsbGAc1MneMdqDAFbPe+Cjn235HbsO/c9b6nOhcv4gF4x8oHt9SzUcQHuuRSkGgemDPQhwAC3qLvGM4GyJgAAAABJRU5ErkJggg==`;
const GoogleMapsWrapper = withScriptjs(
	withGoogleMap(props => (
		<GoogleMap
			{...props}
			ref={c => {
				props.onMapMounted && props.onMapMounted(c);
			}}
		>
			{props.children}
		</GoogleMap>
	))
);

const google = (window.google = window.google ? window.google : {});
const InfoConfirmContainer = styled.div`
	font-family: 'db_heavent';
	padding: 8px;
	width: 340px;

	& .title {
		font-size: 22px;
		font-weight: bold;
		line-height: 1.2;
	}

	& .sub-title {
		font-size: 20px;
	}

	@media (max-width: 767px) {
		width: 100%;
	}
`;

const ButtonConfirm = styled.button`
	width: 100%;
	background-color: ${props => (props.available ? '#d90101' : '#b3b3b3')};
	border-radius: 4px;
	border: 0px;
	outline: none;
	color: #ffffff;
	font-size: 18px;
	padding: 10px;
	font-weight: bold;

	&:hover {
		background-color: ${props => (props.available ? '#b70101' : '#C3C3C3')};
	}
`;

class Map extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isCheckCountryAvailable: true,
			isLoadingCheckCountry: false,
		};
	}

	static contextType = ContactAddressContext;

	getLatLngDefaultCenter() {
		return {
			lat: parseFloat(13.685702),
			lng: parseFloat(100.611084),
		};
	}

	getMarker = () => {
		const { lat, lng, stepContactDetail, address } = this.context.state;

		if (stepContactDetail) {
			return (
				<Marker
					icon={{
						url: markerIcon,
					}}
					position={{
						lat: Number(lat),
						lng: Number(lng),
					}}
				>
					<InfoWindow>
						<InfoConfirmContainer>
							<div className="sub-title">
								<DotDotDot clamp={2}>{address}</DotDotDot>
							</div>
						</InfoConfirmContainer>
					</InfoWindow>
				</Marker>
			);
		}

		return null;
	};

	getMarkerSelectLocation = () => {
		const {
			tempLat,
			tempLng,
			tempAddress,
			stepSearchLocation,
			fitBounds,
		} = this.context.state;

		if (fitBounds) {
			this.setCenterAndZoom(tempLat, tempLng);
			this.context.setData({
				fitBounds: false,
			});
		}

		if (stepSearchLocation) {
			return (
				<Marker
					icon={{
						url: markerIcon,
					}}
					position={{
						lat: Number(tempLat),
						lng: Number(tempLng),
					}}
					draggable={true}
					onDragEnd={coords =>
						this.onMarkerChanged(coords.latLng.lat(), coords.latLng.lng())
					}
				>
					<InfoWindow>
						<InfoConfirmContainer>
							<div className="sub-title">
								<DotDotDot clamp={2}>{tempAddress}</DotDotDot>
							</div>
							<div style={{ marginTop: 10 }}>
								<ButtonConfirm
									available={this.state.isCheckCountryAvailable}
									disabled={
										!this.state.isCheckCountryAvailable ||
										this.state.isLoadingCheckCountry
									}
									onClick={() => {
										this.onSubmit();
									}}
								>
									{this.state.isLoadingCheckCountry
										? 'กำลังโหลด'
										: this.state.isCheckCountryAvailable
										? 'ยืนยันจุดนี้'
										: 'อยู่นอกเขตพื้นที่บริการ'}
								</ButtonConfirm>
							</div>
						</InfoConfirmContainer>
					</InfoWindow>
				</Marker>
			);
		}

		return null;
	};

	onMarkerChanged = async (lat, lng, address = '') => {
		this.context.setData({
			tempLat: lat,
			tempLng: lng,
		});

		try {
			this.setState({
				isLoadingCheckCountry: true,
				isCheckCountryAvailable: false,
			});

			const { data } = await client.query({
				query: getNameFromlatlngQuery,
				variables: {
					latitude: lat,
					longitude: lng,
				},
			});

			this.context.setData({
				tempAddress: address ? address : data.getNameFromlatlng.address || '',
			});

			//check country
			const { data: checkCountryData } = await client.query({
				query: checkCountryQuery,
				variables: {
					latitude: lat,
					longitude: lng,
				},
			});

			this.setState({
				isLoadingCheckCountry: false,
				isCheckCountryAvailable: checkCountryData.checkCountry.available,
			});
		} catch (error) {
			console.log(error);
			this.setState({
				isLoadingCheckCountry: false,
				isCheckCountryAvailable: false,
			});
		}
	};

	onSubmit() {
		const { tempLat, tempLng, tempAddress } = this.context.state;

		this.context.setData({
			lat: tempLat,
			lng: tempLng,
			address: tempAddress,
		});

		this.context.onCloseAllAndOpen(['stepContactDetail']);
	}

	setCenterAndZoom(lat, lng) {
		const bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lat, lng));
		this._map.fitBounds(bounds);
	}

	render() {
		return (
			<GoogleMapsWrapper
				clickableIcons={false}
				isMarkerShown
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: '100%' }} />}
				mapElement={<div style={{ height: `100%` }} />}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
				zoom={15}
				defaultCenter={this.getLatLngDefaultCenter()}
				options={{
					mapTypeControl: false,
					panControl: false,
					streetViewControl: false,
					minZoom: 5,
					maxZoom: 20,
				}}
				onMapMounted={c => (this._map = c)}
			>
				{this.getMarker()}
				{this.getMarkerSelectLocation()}
			</GoogleMapsWrapper>
		);
	}
}

export default Map;
