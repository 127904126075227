import React from 'react';
import urijs from 'urijs';
import { Grid } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { SubHeaderNav } from 'src/app/components';
import styled from 'styled-components';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import { UserCreateContext } from 'src/context/UserContext';

const SubHeaderNavStyle = styled(SubHeaderNav)`
	position: fixed;
	top: calc(60px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		position: relative;
		top: 0;
	}
`;

class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isParcelCustomer: false,
		};
	}

	static contextType = UserCreateContext;

	getParamUrl = () => {
		const uriRoute = urijs(window.location.href);
		const uriFragment = uriRoute.fragment(true);
		const pathname = uriFragment.pathname();

		return pathname;
	};

	setActive = path => {
		const paramUrl = this.getParamUrl();
		return path === paramUrl;
	};

	openUrl = url => {
		window.location = url;
	};

	render() {
		const menuTabs = [
			{
				active: this.setActive('/'),
				link: '/',
				icon: 'fal fa-search',
				title: this.props.t('common:instancePrice.checkTransportationPrice'),
			},
			{
				active: this.setActive('/shipment'),
				link: '/shipment',
				icon: 'fal fa-box',
				title: this.props.t('common:instancePrice.shipment'),
			},
			{
				active: this.setActive('/favorite-driver'),
				link: '/favorite-driver',
				icon: 'fal fa-user',
				title: this.props.t('common:instancePrice.favoriteDriver'),
			},
			...(this.context.state.isParcelCustomer
				? [
						{
							active: this.setActive('/product-list'),
							link: '/product-list',
							icon: 'fal fa-truck',
							title: 'งานขนส่งรายชิ้น',
						},
				  ]
				: []),
		];

		return (
			<div className="content">
				<div {...this.props}>
					<SubHeaderNavStyle menu={menuTabs} />
					<div>{this.props.children}</div>
				</div>
			</div>
		);
	}
}

export default withNamespaces()(Layout);
