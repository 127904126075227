import React, { Component } from "react";
import { Row, Col, Button, Modal, Image, Grid, Label, FormControl ,FormGroup,InputGroup} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import s3 from "./../../../../config/s3";

import Moment from 'react-moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './../../style.css';


const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;

	& .image {
		width: 86px !important;
		height: 86px !important;
		border: 1px solid #E3E3E3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000080;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0px;
		}
	}

	.btn-hover-image {
        position: relative;
		color: white;
		font-size: 14px;
		cursor: pointer;
		padding: 2px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: normal;

		& i {
            color: #ffffff !important;
            margin-right: 5px;
            left: unset !important;
            top: unset !important;
            position: relative !important;
            font-size: 12px !important;
		}
	}
`;

const TrackingItemExport = props => (
    <div>
        {/* ยืนยันพนักงานขับรถคืนตู้  */}

        {props.tracking[0].transporter_date != null && props.tracking[0].tracking_export_detail[0].track_driver && props.tracking[0].tracking_export_detail[0].track_driver.length > 0 && props.tracking[0].tracking_export_detail[0].accept_container && props.tracking[0].tracking_export_detail[0].accept_container.length > 0 && props.tracking[0].tracking_export_detail[0].commodity_picture && props.tracking[0].tracking_export_detail[0].commodity_picture.length > 0 && props.tracking[0].tracking_export_detail[0].driver_accept_document ? 
            props.tracking[0].tracking_export_detail[0].return_container_date != null ? (
                <div className="boxItem" >
                    <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                    <div className="content">
                        <div className="title"><b>พนักงานขับรถคืนตู้  เรียบร้อยแล้ว   </b> </div>
                        <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].tracking_export_detail[0].return_container_date}</Moment></div>
                        <div className="detail"> 
                        </div>
                    </div>
                </div>
            ):(
                <div className="boxItem noline" >
                    <i className="fal fa-ellipsis-h"></i>
                    <div className="content">
                        <div className="title" style={{color:"#8B8B8B"}}><b>ยืนยันพนักงานขับรถคืนตู้    </b></div>
                        <div className="detail"></div>
                    </div>
                </div>
        ) : null}

        {/* พนักงานขับรถได้ใบกำกับ  */}
        {props.tracking[0].transporter_date != null && props.tracking[0].tracking_export_detail[0].track_driver && props.tracking[0].tracking_export_detail[0].track_driver.length > 0 && props.tracking[0].tracking_export_detail[0].accept_container && props.tracking[0].tracking_export_detail[0].accept_container.length > 0 && props.tracking[0].tracking_export_detail[0].commodity_picture && props.tracking[0].tracking_export_detail[0].commodity_picture.length > 0 ? 
            props.tracking[0].tracking_export_detail[0].driver_accept_document ? (
                <div className="boxItem" >
                <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                <div className="content">
                    <div className="title"><b>พนักงานขับรถได้ใบกำกับ  เรียบร้อยแล้ว </b></div>
                    <div className="detail"> 
                    </div>
                    <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].tracking_export_detail[0].driver_accept_document}</Moment></div>
                </div>
            </div>
            ):(
                <div className="boxItem noline" >
                    <i className="fal fa-ellipsis-h"></i>
                    <div className="content">
                        <div className="title" style={{color:"#8B8B8B"}}><b>ยืนยันพนักงานขับรถได้ใบกำกับ    </b> </div>
                        <div className="detail"></div>
                    </div>
                </div>
        ) : null}
        
        {/* อัพโหลดรูปภาพสินค้า */}
        {props.tracking[0].transporter_date != null && props.tracking[0].tracking_export_detail[0].track_driver && props.tracking[0].tracking_export_detail[0].track_driver.length > 0 && props.tracking[0].tracking_export_detail[0].accept_container && props.tracking[0].tracking_export_detail[0].accept_container.length > 0 ? 
            props.tracking[0].tracking_export_detail[0].commodity_picture && props.tracking[0].tracking_export_detail[0].commodity_picture.length > 0 ? (
                <div className="boxItem">
                    <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                    <div className="content">
                        <div className="title"><b>อัพโหลดรูปภาพสินค้า  เรียบร้อยแล้ว </b> </div>
                        <div className="detail"> 
                        <Row>
                            <Col md={12}>
                            {
                                props.tracking[0].tracking_export_detail[0].commodity_picture.length > 0 && (
                                    <ImageContainer>
                                        {
                                            props.tracking[0].tracking_export_detail[0].commodity_picture.map((item, index) => (
                                                <div className="image">
                                                    <div className="image-hover">
                                                        <div className="btn-hover-image" onClick={() => props.onClickContainner(props.tracking[0].tracking_export_detail[0].commodity_picture,index)}>
                                                            <i className="fal fa-search"></i><span>ดูรูปภาพ</span>
                                                        </div>
                                                    </div>
                                                    <img alt="tracking-img" src={`${s3}/${item}`} alt="" />
                                                </div>
                                            ))
                                        }
                                    </ImageContainer>
                                )
                            }
                            </Col>
                        </Row>
                        </div>
                        <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].tracking_export_detail[0].commodity_picture_date}</Moment></div>
                        
                    </div>
                        
                </div>
            ) : (
                <div className="boxItem noline" >
                    <i className="fal fa-ellipsis-h"></i>
                    <div className="content">
                        <div className="title" style={{color:"#8B8B8B"}}><b>อัพโหลดรูปภาพสินค้า    </b> </div>
                        <div className="detail"></div>
                    </div>
                </div>
        ):null}

        
        {/* ระบุข้อมูลตู้คอนเทนเนอร์ */}
        {props.tracking[0].transporter_date != null && props.tracking[0].tracking_export_detail[0].track_driver && props.tracking[0].tracking_export_detail[0].track_driver.length > 0 ? 
            props.tracking[0].tracking_export_detail[0].accept_container && props.tracking[0].tracking_export_detail[0].accept_container.length > 0 ? (
                <div className="boxItem" >
                    <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                    <div className="content">
                        <div className="title"><b>ส่งข้อมูลคอนเทนเนอร์ เรียบร้อยแล้ว </b></div>
                        <div className="detail">
                            {
                                props.tracking[0].tracking_export_detail[0].accept_container.map((container, index) => (
                                    <div className="tracking-driver">
                                        Cont No : {container.cont_number} <br/>
                                        Seal No : {container.seal_number}  <br/>
                                        Tare Weight : {container.tare_weight}

                                        <Row>
                                            <Col md={12}>
                                            {
                                                container.picture.length > 0 && (
                                                    <ImageContainer>
                                                        {
                                                            container.picture.map((item, index) => (
                                                                <div className="image">
                                                                    <div className="image-hover">
                                                                        <div className="btn-hover-image" onClick={() => props.onClickContainner(container.picture,index)}>
                                                                            <i className="fal fa-search"></i><span>ดูรูปภาพ</span>
                                                                        </div>
                                                                    </div>
                                                                    <img alt="tracking-img" src={`${s3}/${item}`} alt="" />
                                                                </div>
                                                            ))
                                                        }
                                                    </ImageContainer>
                                                )
                                            }
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].tracking_export_detail[0].accept_container_date}</Moment></div>
                    </div>
                </div>
            ) : (
                <div className="boxItem noline" >
                    <i className="fal fa-ellipsis-h"></i>
                    <div className="content">
                        <div className="title" style={{color:"#8B8B8B"}}><b>ระบุข้อมูลตู้คอนเทนเนอร์   </b> </div>
                        <div className="detail"></div>
                    </div>
                </div>
        ) : null }
        
        

        {/* ข้อมูลพนักงานขับรถ */}
        {props.tracking[0].transporter_date != null ? 
            props.tracking[0].tracking_export_detail[0].track_driver && props.tracking[0].tracking_export_detail[0].track_driver.length > 0 ? (
                <div className="boxItem" >
                    <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                    <div className="content">
                        <div className="title"><b>ข้อมูลพนักงานขับรถ  เรียบร้อยแล้ว </b></div>
                        <div className="detail">
                            {
                                props.tracking[0].tracking_export_detail[0].track_driver.map((driver, index) => (
                                    <div className="tracking-driver">
                                        ชื่อคนขับ: {driver.driver_name} <br/>
                                        ทะเบียนหัว : {driver.license_plate_front}  <br/>
                                        ทะเบียนหาง: {driver.license_plate_back} <br/>
                                        เบอร์โทรศัพท์ : {`${driver.driver_phone_code == 66 ? 0 : driver.driver_phone_code + ' '}${driver.driver_phone_number}`}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].tracking_export_detail[0].track_driver_date}</Moment></div>
                    </div>
                </div>
            ) : (
                <div className="boxItem noline" >
                    <i className="fal fa-ellipsis-h"></i>
                    <div className="content">
                        <div className="title" style={{color:"#8B8B8B"}}><b>ระบุข้อมูลพนักงานขับรถ   </b></div>
                        <div className="detail"></div>
                    </div>
                </div>
        ) : null }

        {/* ขนส่งยืนยันงาน */}
        {/* {props.tracking[0].transporter_date != null ? (
            <div className="boxItem" >
                <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                <div className="content">
                    <div className="title"><b>ข้อมูลบริษัทขนส่ง</b></div>
                    <div className="detail">
                        บริษัทขนส่ง: {props.tracking[0].transporter_detail} <br/>
                        เบอร์โทรศัพท์: {props.tracking[0].transporter_phone_code} {props.tracking[0].transporter_phone}
                    </div>
                    <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].transporter_date}</Moment></div>
                </div>
            </div>
        ): null} */}

        {props.tracking[0].transporter_date != null ? (
            <div className="boxItem">
                <i className="fas fa-check-circle" style={{color:"#db1e26"}}></i>
                <div className="content">
                    <div className="title">ขนส่งยืนยันงาน เรียบร้อยแล้ว</div>
                    <div className="status"><Moment format="DD/MM/YYYY HH:mm">{props.tracking[0].transporter_date}</Moment></div>
                    <div className="detail">
                    </div>
                </div>
            </div>
        ) : (
            <div className="boxItem noline" >
                <i className="fal fa-ellipsis-h"></i>
                <div className="content">
                    <div className="title" style={{color:"#8B8B8B"}}><b>รอขนส่งยืนยันงาน </b></div>
                    <div className="detail"></div>
                </div>
            </div>
        )}
    </div>
);

TrackingItemExport.propTypes = {
    detail: PropTypes.node,
    index: PropTypes.string
};
  
TrackingItemExport.defaultProps = {
};

export default TrackingItemExport;