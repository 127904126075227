import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, NavDropdown , FormGroup,InputGroup,Button,FormControl,Modal} from 'react-bootstrap';
import { graphql, compose, Query, Mutation } from 'react-apollo';
import styled from 'styled-components';
import logo from './../../assets/images/logo_giztix.png';
import logoWhite from './../../assets/images/logo_rw.png';
import client from './../../config/client';
import updateModalLoginMutationClient from '../../config/graphqlClient/mutation/updateModalLogin';
import updateModalRegisterMutationClient from '../../config/graphqlClient/mutation/updateModalRegister';
import userQueryClient from '../../config/graphqlClient/query/user';
import AccountInfoList from './graphql/query/accountInfoList'
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import { Link } from 'react-router-dom';
import color from './../../config/color';
import landingUrl from '../../config/landingUrl';
import s3 from '../../config/s3';
import { withNamespaces } from 'react-i18next';



import imgWelcomeSubAccount from 'src/assets/images/welcomeSubAccount.png';

const WelcomeContainer = styled.div`
	& .close-btn{
		text-align: right;
	}
	& h2{
		color: #D90101;
		font-weight: bold;
		margin-bottom:0;
	}
	& h3{
		margin-top:0;
    	font-weight: bold;
		margin-bottom:0;
	}
	& p{
		
	}
`;

const HeaderContainer = styled(Navbar)`
	background-color: ${props => props.bgcolor === 'transparent' ?  'transparent' : props.bgcolor ? props.bgcolor : '#FFFFFF'};
	width: 100%;
	border-radius: 0px;
	border: none;
	border-bottom: ${props => props.border === 'true' ? '1px' : '0px'} solid rgba(0, 0, 0, 0.16);
	position: fixed;
	top: 0;
	z-index: 999;
	min-height: 60px !important;

	.nav-item-border {
		border-radius: 6px;
		padding: 8px 20px;
	}
	
	.nav-item-border-black {
		border: 1px solid #000000;
	}
	
	.nav-item-border-white {
		border: 1px solid #FFFFFF;
	}
	
	.navbar-nav {
		box-sizing: content-box;
	}
	
	.navbar-nav > li > a {
		padding-top: 18px;
	}
	
	.navbar-white > li > a {
		color: #FFFFFF !important;
	}
	
	.navbar-black > li > a {
		color: #000000 !important;
	}

	.dropdown-menu {
		background-color: #FFFFFF !important;
		border: 1px solid #EEEEEE !important;
		border-radius: 6px !important;
		margin-top: 0;
	}

	.dropdown-menu::after {
		content: '';
		width: 16px;
		height: 16px;
		position: absolute;
		top: -8px;
    right: 16px;
		background-color: #FFFFFF !important;
		transform: rotate(45deg);
		z-index: -999;
		border-top: 1px solid #EEEEEE;
		border-left: 1px solid #EEEEEE;
	}

	.dropdown-toggle {
		background-color: transparent !important;
		padding-left: 12px;
		padding-right: 12px;
	}

	.caret {
		display: none;
	}

	.navbar-right {
		margin: 0px;
		float: right;
	}
`;

const NavItemDivider = styled(NavItem)`
	background-color: #E3E3E3;
	width: 1px;
	margin-top: 12px;
	margin-left: 10px;
	margin-right: 10px;
`;

const NavbarBrand = styled(Navbar.Brand)`
	padding-right: 35px;
	padding-left:0px;
`;

const IconMenu = styled.i`
	font-size: 24px;
`;

const ItemMenu = styled.div`
	font-size: 22px;
	padding: 10px 20px;
	color: #000000;
	background-color: ${props => (props.active ? '#F2F2F2' : '#FFFFFF')};

	& i {
		color: ${color.SoftGray}
		padding-left: 5px;
		font-size: 14px;
	}

	&:hover {
		background-color: ${props => (props.active ? '#F2F2F2' : '#F2F2F270')};
	}
`;

const LinkA = styled.a`
	text-decoration: none !important;
`;

const LinkItemMenu = styled(Link)`
	text-decoration: none !important;
`;

const DrawerBody = styled.div`
	overflow-y: auto;
	max-height: calc(100vh - 150px);
	padding-bottom: 80px;

	&::-webkit-scrollbar { 
    display: none; 
	}
`;

const DrawerFooter = styled.div`
	background-color: #FFFFFF;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 20px;
	padding-left: 20px;
	width: 100%;
	box-sizing: border-box;
	height: 150px;

	& .contact-header {
		font-size: 20px;
	}

	& .contact-detail {
		padding-top: 10px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 1;
	}
`;

const NavItemDropDownList = styled(NavItem)`
	font-size: 20px;

	& a {
		display: flex !important;
		flex-direction: row !important;
		align-items: center !important;
		padding: 10px 15px !important;

		& i {
			padding-right: 10px;
		}
	}
`;

const NavText = styled.div`
	height: 25px;

	& i {
		display: block;
    font-size: 24px;
    float: left;
	}

	& .img {
		position: relative;
		width: 24px;
		height: 24px;
		overflow: hidden;
		border-radius: 50%;
		display: block;
		float: left;
		border: 1px solid #F2F2F2;
	}

	& img {
		width: 24px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
	}

	& span {
		display: block;
    float: left;
    line-height: 1;
    margin: 3px 0 0 10px;
	}
`;

const TextChangeLanguage = styled.span`
	font-size: 26px;
`;

const ButtonSearch = styled(Button)`
    width: 90px;
`;

const FormGroupSearch = styled(FormGroup)`
	width: 320px;
	margin: 5px 10px;
`;

const PromotionContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;

	& .close-btn{
		cursor: pointer;
		position: absolute;
		top: 5px;
		left: 10px;
		color: #fff;
		width: 40px;
		height: 40px;
	}

	& img {
		width: 100%;
		cursor: pointer;
	}
`;

class CustomSearchbox extends React.Component {
	constructor(props, context) {
	  	super(props, context);
  
	  	this.handleChange = this.handleChange.bind(this);
  
	  	this.state = { 
			value : "",
			autoFocus: false, 
		};
	}

    handleChange(e){
        this.setState({ value: e.target.value });
    }

    handleKeyPress(e){
        if (e.key === 'Enter') {
            if(this.state.value !== "" && this.state.value !== undefined){
                window.location.href = '/trackingdetail?shipment='+this.state.value;
            }
            else{
                alert("กรุณา กรอกรหัสติดตามงานขนส่งของคุณ");
            }
        }
    }

    searchTracking(){
        if(this.state.value !== "" && this.state.value !== undefined){
            window.location.href = '/trackingdetail?shipment='+this.state.value;
        }
        else{
            alert("กรุณา กรอกรหัสติดตามงานขนส่งของคุณ");
        }
	}
  
	render() {  
	  return (
		<FormGroupSearch>
			<InputGroup>
			<FormControl 
				type="text"
				autoFocus
				value={this.state.value}
				placeholder="กรอกรหัสติดตามงานขนส่งของคุณ"
				onChange={event =>
					this.handleChange(event)
				} 
				onKeyPress={event =>
					this.handleKeyPress(event)
				} 
			/>
			<InputGroup.Button>
				<ButtonSearch bsStyle="primary" onClick={() => this.searchTracking()}>ติดตาม</ButtonSearch>
			</InputGroup.Button>
			</InputGroup>
		</FormGroupSearch>
	  );
	}
}
  

class HeaderMobile extends React.Component {
	state = {
		openMenu: false,
		showWelcome: true,
		showDownload: false,
	};

	onTouchEnd = () => {
		this.setState({ openMenu: false });
	};

	onSwitchMenu = () => {
		this.setState({ openMenu: !this.state.openMenu });
	};

	handleSelect = (selectedKey, updateModal) => {
		switch (selectedKey) {
			case 1: //ปุ่มสมัครสมาชิก
				return updateModal();
			case 2: //ปุ่มเข้าสู่ระบบ
				return updateModal();
			case 3: //ปุ่มเมนู
				return this.onSwitchMenu();
			case 4: //ปุ่มออกจากระบบ
				localStorage.removeItem('token');
				return (window.location = '/');
			default:
				return null;
		}
	};

	selectActive(pathname) {
		if (pathname === window.location.pathname) {
			return true;
		}

		return false;
	}

	openPopUpUser(token , hash) {
		const noHashURL = window.location.href.replace(/#.*$/, '');
		window.history.replaceState('', document.title, noHashURL);

		if(!token){
			if(hash != ""){
				if(hash === "#register"){
					window.history.replaceState('', document.title, noHashURL) ;
					
					client.cache.writeData({
						data: {
							modalRegister: {
								isOpen: false,
								isBack: false ,
								__typename: 'ModalRegister',
							},
						},
					})
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: false,
								__typename: 'ModalLogin',
							},
						},
					})
				}

				if(hash === "#login"){
					window.history.replaceState('', document.title, noHashURL) ;
					
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: true,
								__typename: 'ModalLogin',
							},
						},
					});
					client.cache.writeData({
						data: {
							modalRegister: {
								isOpen: false, 
								isBack: false ,
								__typename: 'ModalRegister',
							},
						},
					});
				}
			}
			else{
				if(window.location.pathname === "/register-business"){
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: true,
								__typename: 'ModalLogin',
							},
						},
					});
				}
			}
		}
	}
	
	render() {
		return (
			<Query query={userQueryClient}>
				{({ data }) => {
					const token = data.user.token;

					return (
						<Query query={AccountInfoList}>
							{({ data: accountInfoListData, loading, error }) => {
								const account_info = accountInfoListData;
								let displayName = '';
								let displayPicture = '';
								let accountBuisness = null;

								if(!loading && !error && account_info !== {} && account_info !== undefined  && account_info.accountInfoList) {
									displayName = account_info.accountInfoList.account_info[0].account_users[0].account_user_display_name;
									displayPicture = account_info.accountInfoList.account_info[0].account_users[0].account_user_display_picture ? s3 + '/' + account_info.accountInfoList.account_info[0].account_users[0].account_user_display_picture : '';
									accountBuisness = account_info.accountInfoList.account_business !== null ? true : false;
								}
								
								return (
									<div>
										{
											this.openPopUpUser(token , window.location.hash)
										}

										{
											token && window.location.hash == "#welcome" ? (
												<Modal
													show={this.state.showWelcome}
													onHide={() =>
														this.setState({
															showWelcome: false
														},() => window.location.hash = "")
													}
												>
													<Modal.Body style={{ textAlign: 'center' }}>
														<WelcomeContainer>
															<div className="close-btn">
																<i className="fal fa-times" onClick={() => this.setState({showWelcome:false},() => window.location.hash = "")}></i>
															</div>

															<img src={imgWelcomeSubAccount} style={{width:"90%"}} />

															<h2>{ this.props.t('common:header.Welcome') } </h2>
															<h3>{displayName}</h3>
															<p>{ this.props.t('common:header.tagLine') }</p>

														</WelcomeContainer>
														

														<Button bsStyle="primary" onClick={() => this.setState({showWelcome:false},() =>  window.location.hash = "")} style={{width:150,marginBottom:30}}>
															{ this.props.t('common:header.WelcomeBtn') }
														</Button>

													</Modal.Body>
												</Modal>
											) :null
										}

										{
											this.state.showDownload && (
												<PromotionContainer>
													<img onClick={() => window.location = "https://giztix.page.link/downloadGiztixApp"} src={`${require('src/assets/images/popup_loadapp-05.png')}`} alt="" />
													<div className="close-btn" onClick={() => this.setState({showDownload : false})} > <i class="fal fa-times"></i> </div>
												</PromotionContainer>
											)
										}
										

										<HeaderContainer className={this.props.full ? "full" : ""} bgcolor={this.props.bgcolor} border={`${this.props.border}`}>
											

										<Navbar.Header>
												<NavbarBrand>
													<a href={landingUrl}>
														<img
															src={this.props.color === 'black' ? logo : logoWhite}
															className="logo"
															alt="logo"
															style={{ height: this.props.color === 'black' ? 36 : 24, paddingLeft: 15 }}
														/>
													</a>
												</NavbarBrand>
												<Nav pullRight onSelect={this.handleSelect} className={`${this.props.color === 'black' ? 'navbar-black' : this.props.color === 'white' ? 'navbar-white' : 'navbar-black'}`}>
													<NavItem eventKey={3} href="#">
														<IconMenu className="far fa-bars" />
													</NavItem>
												</Nav>
											</Navbar.Header>
										</HeaderContainer>

										<Drawer
											open={this.state.openMenu}
											width="260px"
											onMaskClick={this.onTouchEnd}
											level={null}
											handler={false}
											placement="right"
										>
											<DrawerBody>
												<LinkA
													href={landingUrl}
												>
													<ItemMenu
														active={false}
														style={{ marginTop: 30 }}
													>
														{ this.props.t('common:header.homepage') }
													</ItemMenu>
												</LinkA>

												<LinkItemMenu
													onClick={() => this.setState({ openMenu: false })}
													to="/"
												>
													<ItemMenu active={this.selectActive('/')}>
													{ this.props.t('common:header.checkTransportationPrice') }
													</ItemMenu>
												</LinkItemMenu>

												{token ? (
													<LinkItemMenu
														onClick={() => this.setState({ openMenu: false })}
														to="/shipment"
													>
														<ItemMenu active={this.selectActive('/shipment')}>
														{ this.props.t('common:header.shipmentList') }
														</ItemMenu>
													</LinkItemMenu>
												) : null}

											<LinkItemMenu
												onClick={() => this.setState({ openMenu: false })}
												to="/tracking"
											>
												<ItemMenu active={this.selectActive('/tracking')}>
												{ this.props.t('common:header.shipmentTracking') }
												</ItemMenu>
											</LinkItemMenu>

											<LinkA
												href={`${landingUrl}/pricing`}
											>
												<ItemMenu active={this.selectActive('/pricing')}>{ this.props.t('common:header.transportationRate') }</ItemMenu>
											</LinkA>
											

											<LinkA
												href="https://blog.giztix.com/"
												target="_blank"
											>
												<ItemMenu>{ this.props.t('common:header.newsPromotion') } <i className="fal fa-external-link"></i></ItemMenu>
											</LinkA>

											<hr style={{ margin: '10px 0px 10px 20px' }} />

											<LinkA
												href={`${landingUrl}/helpcenter`}
											>
												<ItemMenu active={this.selectActive('/helpcenter')}>{ this.props.t('common:header.helpCenter') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`${landingUrl}/insurance`}
											>
												<ItemMenu active={this.selectActive('/insurance')}>การคุ้มครองสินค้าจาก GIZTIX EXPRESS <small> (ลูกค้าองค์กร/ธุรกิจ)</small></ItemMenu>
											</LinkA>

											<LinkA
												href={`/giztixbusiness`}
											>
												<ItemMenu active={this.selectActive('/giztixbusiness')}>{ this.props.t('common:header.forcorporatebusinesscustomers') }</ItemMenu>
											</LinkA>

											<hr style={{ margin: '10px 0px 10px 20px' }} />
											
											{/*
												<LinkA
												href={`https://parcel.giztix.com/`}
												>
												<ItemMenu active={this.selectActive('https://parcel.giztix.com/')}>{ this.props.t('common:header.parcelMenu') }</ItemMenu>
												</LinkA>
											*/}

											<LinkA
												href={`${landingUrl}/aboutus`}
											>
												<ItemMenu active={this.selectActive('/aboutus')}>{ this.props.t('common:header.aboutUs') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`${landingUrl}/contactus`}
											>
												<ItemMenu active={this.selectActive('/contactus')}>{ this.props.t('common:header.contactUs') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`${landingUrl}/career`}
											>
												<ItemMenu active={this.selectActive('/career')}>{ this.props.t('common:header.career') }</ItemMenu>
											</LinkA>
											<LinkA
												href={`https://lin.ee/ateOhRI`}
												target="_blank"
											>
												<ItemMenu>{ this.props.t('common:header.MenuDrivewithus') }</ItemMenu>
											</LinkA>
											</DrawerBody>

											<DrawerFooter>
												<hr style={{ margin: '0px 0px 10px 0px' }} />
												<div className="contact-header">{this.props.t('common:header.contactCustomerService')}</div>
												<div className="contact-detail">
												<div>{this.props.t('common:header.emailGiztix')} service@giztix.com</div>
													<div style={{width:"100%",float: "left"}}>
														<div style={{float:"left"}}>{this.props.t('common:header.contactGiztix')} </div> 
														<div style={{float:"left",marginLeft:"5px"}}>02-026-6895</div> 
													</div>
													<div>{this.props.t('common:header.giztixOpenhours')}</div>
												</div>
											</DrawerFooter>
										</Drawer>
									</div>
								);
							}}
						</Query>
					);
				}}
			</Query>
		);
	}
}

HeaderMobile.propTypes = {
	active: PropTypes.number,
	full: PropTypes.bool,
	bgcolor: PropTypes.string,
	border: PropTypes.bool,
	color: PropTypes.string,
};

HeaderMobile.defaultProps = {
	active: null,
	full: false,
	border: true,
	color: 'black',
};

export default compose(
	graphql(AccountInfoList),
)(withNamespaces()(HeaderMobile));