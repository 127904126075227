import gql from 'graphql-tag';

export default gql`
	mutation AccountContactAddressUpdate(
		$addressId: ID!
		$contactId: ID!
		$changeContactId: ID
		$mainAddress: String
		$secondaryAddress: String
		$address: String
		$latitude: String
		$longitude: String
	) {
		accountContactAddressUpdate(
			_id: $addressId
			account_contact_id: $contactId
			change_account_contact_id: $changeContactId
			structured_formatting: {
				main_text: $mainAddress
				secondary_text: $secondaryAddress
			}
			address: $address
			lat: $latitude
			lng: $longitude
		) {
			_id
		}
	}
`;
