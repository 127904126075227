import baseUrl from './baseUrl';

let s3 = '';

if (baseUrl === 'https://api.giztix.com') {
  s3 = 'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com';
}

if (baseUrl === 'https://demoapi.giztix.com') {
  s3 = 'https://s3-ap-southeast-1.amazonaws.com/media.kaikin.co';
}

export default s3;
