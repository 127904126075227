import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import urijs from 'urijs';
import Routes from './routes';
import client from './config/client';
import './index.css';

import ReactGA from 'react-ga';
import gaCode from './config/gaCode';
import UserProvider from 'src/context/UserContext';

ReactGA.initialize(gaCode);
ReactGA.pageview(window.location.pathname);

class App extends React.Component {
	setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}
	getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}
	eraseCookie(name) {
		document.cookie = name + '=; Max-Age=-99999999;';
	}

	async componentWillMount() {
		try {
			if (!this.getCookie('user_ipaddress')) {
				const obj = await (
					await fetch('http://www.geoplugin.net/json.gp')
				).json();
				// console.log("user_ipaddress" , obj)
				this.setCookie('ip_address', obj.geoplugin_request, 7);
			}

			const utm_source = urijs(window.location.href).query(true).utm_source;
			const utm_medium = urijs(window.location.href).query(true).utm_medium;
			const utm_campaign = urijs(window.location.href).query(true).utm_campaign;
			if (utm_source && utm_medium && utm_campaign) {
				this.setCookie('utm_source', utm_source, 7);
				this.setCookie('utm_medium', utm_medium, 7);
				this.setCookie('utm_campaign', utm_campaign, 7);
			}
		} catch (error) {
			console.log(error);
		}
	}

	render(props) {
		return (
			<ApolloProvider client={client}>
				<UserProvider>
					<BrowserRouter>
						<Routes />
					</BrowserRouter>
				</UserProvider>
			</ApolloProvider>
		);
	}
}

export default App;
