import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '../../components';
import MediaQuery from 'react-responsive';
import Drawer from 'rc-drawer';
import color from 'src/config/color';
import landingUrl from 'src/config/landingUrl';
import { Link } from 'react-router-dom';
import { graphql, compose, Query, Mutation } from 'react-apollo';
import { withNamespaces } from 'react-i18next';

//GQL
import userQueryClient from 'src/config/graphqlClient/query/user';

const Container = styled.div``;

const LinkA = styled.a`
	text-decoration: none !important;
`;

const DrawerBody = styled.div`
	overflow-y: auto;
  max-height: calc(100vh - 150px);
	padding-bottom: 80px;

	&::-webkit-scrollbar { 
    display: none; 
  }
  
  @media(max-width: 767px) {
    max-height: calc(100vh - 160px);
  }
`;

const ItemMenu = styled.div`
	font-size: 22px;
	padding: 5px 20px;
  background-color: ${props => (props.active ? '#F2F2F2' : '#FFFFFF')};
  color: rgba(0, 0, 0, 0.7);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

	& i {
		color: ${color.SoftGray}
		padding-left: 5px;
		font-size: 14px;
	}

	&:hover {
    background-color: ${props => (props.active ? '#F2F2F2' : '#F2F2F270')};
    color: #000000;
	}
`;

const DrawerFooter = styled.div`
	background-color: #FFFFFF;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 20px;
	padding-left: 20px;
	width: 100%;
	box-sizing: border-box;
  height: 150px;
  padding-top: 20px;
  border-top: 1px solid #eee;

	& .contact-header {
		font-size: 20px;
	}

	& .contact-detail {
		padding-top: 6px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 1;
  }
  
  @media(max-width: 767px) {
    height: 160px;
  }
`;

const LinkItemMenu = styled(Link)`
	text-decoration: none !important;
`;

const TextChangeLanguage = styled.span`
	font-size: 18px;
`;

class HeaderMenu extends Component {

  selectActive(pathname) {
		if (pathname === window.location.pathname) {
			return true;
		}

		return false;
  }

  switchLanguage() {
		const { t, i18n } = this.props;
		if (t('common:lang') === 'th') {
			i18n.changeLanguage('en');
			localStorage.setItem('lang', 'en');
		} else {
			i18n.changeLanguage('th');
			localStorage.setItem('lang', 'th');
		}
  }
  
	render() {
		return (
			<Container>
        <Query query={userQueryClient}>
          {
            ({ data }) => {
              const token = data.user.token;

              return (
                <Drawer
                  open={this.props.openMenu}
                  width="260px"
                  onMaskClick={() => this.props.onTouchEnd ? this.props.onTouchEnd() : null}
                  level={null}
                  handler={false}
                  placement="right"
                >
                  <DrawerBody>
                    <LinkA
                      href={landingUrl}
                    >
                      <ItemMenu
                        active={false}
                        style={{ marginTop: 30 }}
                      >
                        { this.props.t('common:header.homepage') }
                      </ItemMenu>
                    </LinkA>

                    <LinkItemMenu
                      onClick={() => this.setState({ openMenu: false })}
                      to="/"
                    >
                      <ItemMenu active={this.selectActive('/')}>
                      { this.props.t('common:header.checkTransportationPrice') }
                      </ItemMenu>
                    </LinkItemMenu>

                    {token ? (
                      <LinkItemMenu
                        onClick={() => this.setState({ openMenu: false })}
                        to="/shipment"
                      >
                        <ItemMenu active={this.selectActive('/shipment')}>
                        { this.props.t('common:header.shipmentList') }
                        </ItemMenu>
                      </LinkItemMenu>
                    ) : null}

                    <LinkItemMenu
                      onClick={() => this.setState({ openMenu: false })}
                      to="/tracking"
                    >
                      <ItemMenu active={this.selectActive('/tracking')}>
                      { this.props.t('common:header.shipmentTracking') }
                      </ItemMenu>
                    </LinkItemMenu>

                    <LinkA
                      href={`${landingUrl}/pricing`}
                    >
                      <ItemMenu active={this.selectActive('/pricing')}>{ this.props.t('common:header.transportationRate') }</ItemMenu>
                    </LinkA>
                    

                    <LinkA
                      href="https://blog.giztix.com/"
                      target="_blank"
                    >
                      <ItemMenu>{ this.props.t('common:header.newsPromotion') } <i className="fal fa-external-link"></i></ItemMenu>
                    </LinkA>

                    <hr style={{ margin: '10px 0px 10px 20px' }} />

                    <LinkA
                      href={`${landingUrl}/helpcenter`}
                    >
                      <ItemMenu active={this.selectActive('/helpcenter')}>{ this.props.t('common:header.helpCenter') }</ItemMenu>
                    </LinkA>

                    <LinkA
                      href={`${landingUrl}/insurance`}
                    >
                      <ItemMenu active={this.selectActive('/insurance')}>การคุ้มครองสินค้าจาก GIZTIX EXPRESS<small> (ลูกค้าองค์กร/ธุรกิจ)</small></ItemMenu>
                    </LinkA>

                    <LinkA
                      href={`/giztixbusiness`}
                    >
                      <ItemMenu active={this.selectActive('/giztixbusiness')}>{ this.props.t('common:header.forcorporatebusinesscustomers') }</ItemMenu>
                    </LinkA>

                    <hr style={{ margin: '10px 0px 10px 20px' }} />

                    {/* 
                      <LinkA
                      href={`https://parcel.giztix.com/`}
                      >
                      <ItemMenu active={this.selectActive('https://parcel.giztix.com/')}>{ this.props.t('common:header.parcelMenu') } <i className="fal fa-external-link"></i></ItemMenu>
                      </LinkA>
                    */}
                    
                    <LinkA
                      href={`${landingUrl}/aboutus`}
                    >
                      <ItemMenu active={this.selectActive('/aboutus')}>{ this.props.t('common:header.aboutUs') }</ItemMenu>
                    </LinkA>

                    <LinkA
                      href={`${landingUrl}/contactus`}
                    >
                      <ItemMenu active={this.selectActive('/contactus')}>{ this.props.t('common:header.contactUs') }</ItemMenu>
                    </LinkA>

                    <LinkA
                      href={`${landingUrl}/career`}
                    >
                      <ItemMenu active={this.selectActive('/career')}>{ this.props.t('common:header.career') }</ItemMenu>
                    </LinkA>

                    <LinkA
                      href={`https://lin.ee/ateOhRI`}
                      target="_blank"
                    >
                      <ItemMenu>{ this.props.t('common:header.MenuDrivewithus') }</ItemMenu>
                    </LinkA>
                  </DrawerBody>

                  <DrawerFooter>
                    <div className="contact-header">{ this.props.t('common:header.contactCustomerService') }</div>
                    <div className="contact-detail">
                      <div><i className="fal fa-envelope" style={{fontSize: 14, marginRight: 10}}></i>service@giztix.com</div>
                      <div><i className="fal fa-phone" style={{fontSize: 14, marginRight: 10}}></i>02-026-6895</div>
                      <div>{ this.props.t('common:header.giztixOpenhours') }</div>
                    </div>

                    <MediaQuery maxWidth={767}>
                      <div onClick={() => this.switchLanguage()} style={{
                        marginTop: '5px',
                        backgroundColor: 'rgb(238, 238, 238)',
                        padding: '5px',
                        borderRadius: '3px',
                        width: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        lineHeight: 1,
                      }}>
                        <img src={`${require('src/assets/images/flag/' + (this.props.t('common:lang') === 'th' ? 'en' : 'th').toUpperCase() + '.png')}`} style={{height:15, marginRight: 5}} alt="flag" />
                        <TextChangeLanguage>{ (this.props.t('common:lang') === 'th' ? 'en' : 'th').toUpperCase() }</TextChangeLanguage>
                      </div>
                    </MediaQuery>
                  </DrawerFooter>
                </Drawer>
              )
            }
          }
        </Query>
      </Container>
		);
	}
}

export default withNamespaces()(HeaderMenu);
