
import React from "react";
import { Row, FormGroup ,InputGroup ,FormControl ,Button } from "react-bootstrap";
import styled from 'styled-components';
import {
    Card,
    CardHeader,
    PromotionListItem,
    ModalPromotion,
    Skeleton
} from "src/components";
import { compose, graphql, Query, Mutation } from 'react-apollo';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import promotionListQuery from './graphql/query/promotionList';
import s3 from 'src/config/s3';
import { withNamespaces } from 'react-i18next';
import detectLanguageData from './../../function/detectLanguageData';
import moment from 'moment';

const Container = styled.div`
    width: 100%;
`;

const PromotionContainer = styled.div`
    margin-top: 20px;
`;

const TabContainer = styled.div`
    margin-left: -20px;
    margin-right: -20px;

    & ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-direction: row;

        & li {
            padding: 15px 0px;
            line-height: 1;
            flex: 1;
            font-size: 20px;
            font-weight: bold;
            display: inline-block;
            text-align: center;
            border-bottom: 1px solid #E3E3E3;
            cursor: pointer;

            &.active {
                border-bottom: 2px solid #D90101;
                color: #D90101;
            }
        }
    }
`;

const PromotionListItemStyle = styled(PromotionListItem)`
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 12px;
    margin-top: 20px;
    padding-bottom: 12px;
`;

class Promotion extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '',
            openModal: false,
            promotionName: '',
            promotionNameSearch: '',
            tabSelect: 'promotion',
        };
    }

    closeTap(updateInstancePriceStep){
        updateInstancePriceStep({
            variables: {
                promotion: false,
            },
        });
    }

    handleEnterPromotion(e) {
        if(e.charCode === 13){
            this.setState({openModal: true, promotionName: this.state.promotionNameSearch})
        }
    }

    render() {
    return (
        <Container>
            <ModalPromotion show={this.state.openModal} name={this.state.promotionName} onCloseModal={() => this.setState({ openModal: false })} />
            <Mutation mutation={updateInstancePriceStepMutationClient}>
                {updateInstancePriceStep => {
                    return (
                    <Mutation
                        mutation={updateInstancePriceMutationClient}
                        update={() => {
                            updateInstancePriceStep({
                                variables: {
                                    promotion: false,
                                },
                            })
                        }}
                    >
                        {(updateInstancePrice, { error }) => {
                            return (
                            <Card content={
                                <div>
                                    <CardHeader borderCardBottom title={this.props.t('common:instancePrice.promotion.title')} onPressClose={() => this.closeTap(updateInstancePriceStep)} />
                                    <TabContainer>
                                        <ul>
                                            <li className={`${this.state.tabSelect === 'promotion' ? 'active' : ''}`} onClick={() => this.setState({ tabSelect: 'promotion' })}>{this.props.t('common:instancePrice.promotion.tab.promotionCode')}</li>
                                            <li className={`${this.state.tabSelect === 'promotion_for_you' ? 'active' : ''}`} onClick={() => this.setState({ tabSelect: 'promotion_for_you' })}>{this.props.t('common:instancePrice.promotion.tab.forYou')}</li>
                                        </ul>
                                    </TabContainer>

                                    {
                                        this.state.tabSelect === 'promotion' && (
                                            <PromotionContainer>
                                                <FormGroup style={{marginBottom: 0}} >
                                                    <InputGroup>
                                                        <FormControl 
                                                            type="text"
                                                            value={this.state.promotionNameSearch}
                                                            placeholder={this.props.t('common:instancePrice.promotion.inputSerch')}
                                                            onChange={(event) => this.setState({ promotionNameSearch: event.target.value })}
                                                            onKeyPress={(e) => this.handleEnterPromotion(e)}
                                                        />
                                                        <InputGroup.Button>
                                                            <Button onClick={() => this.setState({openModal: true, promotionName: this.state.promotionNameSearch})} disabled={!this.state.promotionNameSearch}>{this.props.t('common:instancePrice.promotion.buttonSearch')}</Button>
                                                        </InputGroup.Button>
                                                    </InputGroup>
                                                </FormGroup>
                                            </PromotionContainer>
                                        )
                                    }
                                    
                                    {
                                        this.props.data.instancePrice.shipmentAddress.length === 2 && (
                                            <Query
                                                fetchPolicy="network-only"
                                                query={promotionListQuery}
                                                variables={{
                                                    setting_truck_type_id: this.props.data.instancePrice.setting_truck_type_id,
                                                    lat_origin: this.props.data.instancePrice.shipmentAddress[0].latitude,
                                                    lng_origin: this.props.data.instancePrice.shipmentAddress[0].longitude,
                                                    lat_destination: this.props.data.instancePrice.shipmentAddress[1].latitude,
                                                    lng_destination: this.props.data.instancePrice.shipmentAddress[1].longitude,
                                                }}
                                            >
                                                {
                                                    ({ loading, error, data, variables }) => {
                                                        if(loading) return (
                                                            <Skeleton height={300} width={280} style={{marginTop: 10}}>
                                                                <rect x="0" y="0" rx="5" ry="5" width="65" height="65" />
                                                                <rect x="79" y="3" rx="4" ry="4" width="89" height="13" />
                                                                <rect x="79" y="27" rx="3" ry="3" width="159" height="10" />
                                                                <rect x="79" y="45" rx="3" ry="3" width="159" height="10" />

                                                                <rect x="0" y="96" rx="5" ry="5" width="65" height="65" />
                                                                <rect x="79" y="99" rx="4" ry="4" width="89" height="13" />
                                                                <rect x="79" y="123" rx="3" ry="3" width="159" height="10" />
                                                                <rect x="79" y="141" rx="3" ry="3" width="159" height="10" />

                                                                <rect x="0" y="192" rx="5" ry="5" width="65" height="65" />
                                                                <rect x="79" y="195" rx="4" ry="4" width="89" height="13" />
                                                                <rect x="79" y="219" rx="3" ry="3" width="159" height="10" />
                                                                <rect x="79" y="237" rx="3" ry="3" width="159" height="10" />
                                                            </Skeleton>
                                                        );

                                                        if(error) return 'Error';
                                                        
                                                        const promotionList = this.state.tabSelect === 'promotion' ? data.promotionList.promotion : data.promotionList.promotion_for_you;
                                                        return promotionList.map((item, index, arr) => 
                                                            (
                                                                <PromotionListItemStyle key={index}
                                                                    image={`${s3}/${item.promotion_pic}`}
                                                                    title={!item.for_you ? item.name : ''}
                                                                    subTitle={detectLanguageData(item.title_lang, this.props.t('common:lang'))}
                                                                    exp={!!item.exp ? this.props.t('common:instancePrice.promotion.validUntil') + ' ' + moment(item.exp).format('DD/MM/YYYY') : detectLanguageData(item.sub_title_lang, this.props.t('common:lang'))}
                                                                    onPress={() => {
                                                                        this.setState({openModal: true, promotionName: item.name})
                                                                    }}
                                                                />
                                                            )
                                                        )
                                                    }
                                                }
                                            </Query>
                                        )
                                    }
                                </div>
                            } />);
                        }}
                    </Mutation>
                    );
             }}
             </Mutation>
        </Container>   
    )
    }
};

export default compose(
    graphql(instancePriceQueryClient)
)(withNamespaces()(Promotion));
