import React, { Component } from "react";
import {Row} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Space = styled(Row)`
    height: ${props => props.height ? props => props.height : 15}px;
    background-color: #F9F9F9;
`;


const BlankSpace = props => (
    <Space {...props} />
);

BlankSpace.propTypes = {
    height: PropTypes.number
};
  
BlankSpace.defaultProps = {
    height: 15,
};

export default BlankSpace;
