import React from 'react';
export const ContactAddressContext = React.createContext();

class ContactAddressContextProvider extends React.Component {
	state = {
		// manage popup
		stepContactDetail: true,
		stepSearchLocation: false,

		// data
		contactId: null,
		addressId: null,
		contactName: '',
		contactTel: '',
		mainAddress: '',
		secondaryAddress: '',
		address: '',
		lat: 0,
		lng: 0,

		tempLat: 0,
		tempLng: 0,
		tempAddress: '',
		fitBounds: false,
	};

	render() {
		return (
			<ContactAddressContext.Provider
				value={{
					state: this.state,
					onCloseAll: () => {
						this.setState({
							stepContactDetail: false,
							stepSearchLocation: false,
						});
					},
					onCloseAllAndOpen: (popupName = []) => {
						this.setState(
							{
								stepContactDetail: false,
								stepSearchLocation: false,
							},
							() => {
								if (popupName.length > 0) {
									popupName.forEach(element => {
										this.setState({ [`${element}`]: true });
									});
								}
							}
						);
					},
					onClose: (popupName = []) => {
						popupName.forEach(element => {
							this.setState({ [`${element}`]: false });
						});
					},
					onOpen: (popupName = []) => {
						popupName.forEach(element => {
							this.setState({
								[`${element}`]: true,
							});
						});
					},
					setData: data => {
						this.setState((prev) => {
							return {
								...prev,
								...data,
							}
						});
					},
				}}
			>
				{this.props.children}
			</ContactAddressContext.Provider>
		);
	}
}

export default ContactAddressContextProvider;
