import React from 'react';
import styled from 'styled-components';
import color from './../../../../config/color'

const Container = styled.div`
  margin:0 auto;
  border-radius: 50px;
  border: 1px solid ${props => (props.percent === 0) ? '#eeeeee' : color.White};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);

  border-top-color: ${props => (props.percent >= 1) ? color.Primary : null};
  border-right-color: ${props => (props.percent >= 26) ? color.Primary : null};
  border-bottom-color: ${props => (props.percent >= 51) ? color.Primary : null};
  border-left-color: ${props => (props.percent >= 76 && props.percent <= 100) ? color.Primary : null};
`;

const Text = styled.span`
  font-weight: bold;
  transform: rotate(-45deg);
  font-size: 16px;
`;

const Label = (props) => {
  return (
    <Container percent={props.percent}>
      <Text>{props.percent}%</Text>
    </Container>
  );
}

export default Label;