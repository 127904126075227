import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from "react-i18next";

const Container = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

	.icon {
		font-size: 22px;
	}

	.detail {
		flex: 1;
		margin-left: 15px;

		&-title {
			font-size: 20px;
			font-weight: bold;
			line-height: 1;
		}

		&-sub-title {
			font-size: 18px;
			line-height: 1;
		}
	}

	.arrow {
		color: #b3b3b3;
		font-size: 20px;
		line-height: 1;

    ${ props => props.isSelected && `color: #D90101;` }
  }

  ${ props => props.isSelected && `color: #D90101;` }
`;

class ServiceSelect extends React.Component {
	render() {
		return (
			<Container {...this.props} isSelected={this.props.isSelected}>
				<div className="icon">
					<i className="fal fa-box" />
				</div>
				<div className="detail">
					<div className="detail-title">{this.props.t('common:instancePrice.stepServicePaymentDetails.additional.title')}</div>
					<div className="detail-sub-title">{ this.props.subTitle }</div>
				</div>
				<div className="arrow">
          {
            this.props.isSelected ? (
              <i className="fal fa-chevron-left" />
            ) : (
              <i className="fal fa-chevron-right" />
            )
          }
				</div>
			</Container>
		);
	}
}

ServiceSelect.propTypes = {
  subTitle: PropTypes.string,
  isSelected: PropTypes.bool,
};

ServiceSelect.defaultProps = {
  subTitle: '',
  isSelected: false,
};

export default withNamespaces()(ServiceSelect);
