import React from 'react';
import { Query } from 'react-apollo';
import accountInfoListByTrackingURLQuery from './graphql/query/accountInfoListByTrackingURL';
import { Redirect } from 'react-router-dom';

class CheckTrackingUrl extends React.Component {
	render() {
		return (
			<Query 
				query={accountInfoListByTrackingURLQuery} 
				fetchPolicy="network-only"
				variables={{
					account_info_tracking_url: this.props.trackingUrl,
				}}
			>
				{({ loading, error, data, client }) => {
					if (loading) {
						return null;
					}

					if(error) {
						return <Redirect to="/tracking" />;
					}

					return this.props.children;
				}}
			</Query>
		);
	}
}

export default CheckTrackingUrl;
