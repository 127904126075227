import gql from 'graphql-tag';

export default gql`
	query DetailRating($shipment_id: ID) {
		trackingList(shipment_id: $shipment_id) {
			tracking {
				shipment_tracking_number
				driver_name
				review_detail
				review_score
				review_reason {
					th
					en
				}
				driver_display_pic
				favorite_driver
			}
		}
		
    shipmentList(_id: $shipment_id, see_detail: true) {
			shipment {
        shipment_number
				driver_name_under_mama
        shipment_address {
          subdictrict
        }
			}
		}
	}
`;
