import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

const RouteDragHandle = SortableHandle((props) =>
  <DragAndDropContainer location={props.location} disabled={props.disabled}>
    <i className="far fa-grip-lines"></i>
  </DragAndDropContainer>
);

const Container = styled.div`
	display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  line-height: 1;
`;
  
const Placeholder = styled.div`
	font-size: 18px;
	font-weight: bold;
  color: #808080;
  padding-bottom: 3px;
`;
  
const Pin = styled.div`
	font-size: 16px;
  margin-right: 15px;
  color: ${props => props.color ? props.color : '#000000'};
  background-color: #FFFFFF;
  padding-left: 5px;
`;
  
const RouteContainer = styled.div`
  flex: 1;
  border-bottom: 1px solid #e3e3e3;
  min-height: 55px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LocationContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  flex: 1;
`;

const Location = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: bold;
`;

const Label = styled.div`
  color: #000000;
  font-size: 18px;
`;

const DragAndDropContainer = styled.div`
  color: #8B8B8B;
  margin-right: 15px;
  cursor: move;

  ${props => props.location && `
    color: #000000;
  `}

  ${props => props.disabled && `
    cursor: not-allowed;
    color: #8b8b8b70;
  `}
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  justify-content: center;

  & > button {
    font-size: 18px;
		background-color: transparent;
		border: 0px;
		outline: none;
		color: #888888;
		white-space: nowrap;

		&:disabled {
			color: #E3E3E3;
		}
	}
`;

const Route = (props) => {
	return (
		<Container {...props}>
			<Pin color={props.pinColor}>
				<i className="fas fa-map-marker-alt" />
			</Pin>

			<RouteContainer>
        {
          props.isDrag && (
            <RouteDragHandle location={props.location} disabled={props.isDragDisabled}/>
          )
        }

        {
          !props.location && (
            <LocationContainer onClick={props.onPressSelect}>
              <Placeholder onClick={props.onPressSelect}>{props.placeholder}</Placeholder>
            </LocationContainer>
          )
        }

        {
          props.location && (
            <LocationContainer onClick={props.onPressSelect}>
              <Label>{ props.label } </Label>
              <Location><Dotdotdot clamp={1}>{ props.location }</Dotdotdot></Location>
            </LocationContainer>
          )
        }
			</RouteContainer>
      
      <RightContainer>
        {
          props.rightIcon && (
            <button onClick={props.onPressRightIcon}>
              <i className={props.rightIcon} style={props.styleRightIcon}></i>
            </button>
          )
        }
      </RightContainer>
		</Container>
	);
};

Route.propTypes = {
	placeholder: PropTypes.string,
	pinColor: PropTypes.string,
	location: PropTypes.string,
	label: PropTypes.string,
	rightIcon: PropTypes.string,
	isDrag: PropTypes.bool,
	isDragDisabled: PropTypes.bool,
	onPressSelect: PropTypes.func,
	onPressRightIcon: PropTypes.func,
};

Route.defaultProps = {
  placeholder: '',
  pinColor: '#000000',
  location: '',
  label: '',
  isDrag: false,
  isDragDisabled: false,
  rightIcon: '',
};

export default Route;
