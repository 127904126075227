import gql from 'graphql-tag';

export default gql`
query  ShipmentDetailLineDriverAssignDriver(
  $_id : ID!
){
  shipmentList(
    _id: $_id
    see_detail:true
  	) {
    shipment {
      _id
      driver_name_under_mama
      driver_tel_under_mama
      shipment_status
      shipment_number
      shipper
      shipper_detail
      transporter
      transporter_detail
      user_setting_truck_id
      driver_user_id
      driver_phone_number
      shipment_tracking_number
      distance
      paid_type
      paid_status
      cancel_comment
      setting_truck_name{
        th
      }
      truck_type_name{
        th
      }
      truck_type_qty
      pick_up_date
      create_date
      last_update
      shipment_address{
        mode
        address
        province
        subdictrict
        lat
        lng
        contact_name
        contact_tel
        remark
        use_contact_point
        paid_by_cod
      }
      shipment_other_detail{
        remark
        user_ref
        document {
          path
        }
      }
      shipment_additional{
        additional
        qty
        price
      }
      trackStatus
      shipper_info{
        account_info_email
      }
      mama_status
    }
    total_page
  }

  shipmentCostList(
    shipment_id: $_id
  ) {
    shipment_id
    shipment_cost
    additional_cost
  }

  trackingList(
    shipment_id: $_id
  ) {
    tracking {
      driver_name
      driver_phone_code
      driver_phone_number
      user_setting_truck_license_plate
    }
  }

  invoiceList( 
    shipment_id :$_id 
  ) {
      shipment_id
      invoice_status
      paid_type
      paid_credit_card_detail{
          bank
          last_digits
          brand
          name
      }
      invoice_list{
          list_type
          list_name
          list_qty
          list_price
          list_total_price
      }
      promotion_code{
          name
          price
      }
      total_price
      create_date
  }
}
`;
