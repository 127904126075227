import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 36px;
  margin-top: 5px;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 10px;

  & i {
    font-size: 16px;
  }
`;

const Label = styled.div`
  font-size: 18px;
`;

const ButtonIcon = (props) => {
  return (
    <Container {...props}>
      <IconContainer><i className={ props.icon }></i></IconContainer>
      <Label>{ props.label }</Label>
    </Container>
  )
}

ButtonIcon.propTypes = {
	label: PropTypes.string,
	icon: PropTypes.string,
};

ButtonIcon.defaultProps = {
  label: '',
  icon: '',
};

export default ButtonIcon;