export default [
	// '2018-12-28',
	// '2018-12-29',
	// '2018-12-30',
	// '2018-12-31',
	// '2019-01-01',
	// '2019-02-19',
	// '2019-04-13',
	// '2019-04-14',
	// '2019-04-15',
	// '2019-04-16',
	// '2019-04-17',
	// '2019-05-01',
	// '2019-05-20',
	// '2019-07-16',
	// '2019-08-12',
	// '2019-10-14',
	// '2019-10-23',
	// '2019-12-05',
	// '2019-12-30',
	// '2019-12-31',
	'2020-12-31',
	'2021-01-01',
];
