import gql from "graphql-tag";

export default gql`
    query parcelOrderStatus(
        $create_date: String
      ){
        parcelOrderStatus(
          create_date: $create_date
        ){
          total
          new
          outbound_lazada
          inbound_giztix
          cancel
          out_delivery
          delivered
          return_lazada
        }
      }
`;
