import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const Container = styled.div`
  display: block;
  width: 100%;
`;
const Skeleton = (props) => {
  return (
    <Container style={props.style}>
      <ContentLoader {...props}>
        { props.children }
      </ContentLoader>
    </Container>
  )
}

export default Skeleton;