import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import URI from 'urijs';
import baseUrl from 'src/config/baseUrl';
import TrackingDetailTruck from './TrackingDetailTruck';
import TrackingDetailKA from './TrackingDetailKA';

const checkPrefix = number => {
	const prefix = ['GTN', 'GTB', 'GTC', 'GTX'];
	const text =  number.substr(0, 3)
	return prefix.filter(item => item === text).length > 0;
};

class TrackingDetail extends Component {
	state = {
		value: this.props.valueTracking,
	};

	render() {
		const uri = new URI(`${baseUrl}/${this.props.location.search}`);
		const params = uri.search(true);
		let shipmentNumber = params.shipment;
		return checkPrefix(shipmentNumber) ? (
			<TrackingDetailTruck shipmentNumber={shipmentNumber} />
		) : (
			<TrackingDetailKA shipmentNumber={shipmentNumber} />
		);
	}
}

export default withNamespaces()(TrackingDetail);
