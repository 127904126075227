import React, { Component } from "react";
import {Row , Col} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardContainer = styled(Col)`
    width: 100%;
    padding : 20px;
    z-index: 1;
`;

const Container = styled(Row)`
    .cardWrapper .card {
        -webkit-box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.2);
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
    }

    .cardWrapper:first-child .card {
        border-top: none;
    }

    .cardWrapper:last-child .card {
        border-bottom: none;
        box-shadow: none;
    }
`;

const Card = (props) => (
    <Container className="cardWrapper">
        <CardContainer className="card" sm={12} style={props.style}>
            {props.content}
        </CardContainer>
    </Container>
);

Card.propTypes = {
    content: PropTypes.node,
};
  
Card.defaultProps = {
    content : ""
};

export default Card;
