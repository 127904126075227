import gql from 'graphql-tag';

export default gql`
	mutation AccountInvite($email: String) {
		accountInvite(
      email: $email
    ) {
      succeed
    }
	}
`;
