import gql from 'graphql-tag';

export default gql`
  mutation NotificationCountUpdate(
    $count_number: Int!
  ) {
    notificationCountUpdate (
      count_number: $count_number
    ) {
      count_number
    }
  }
`;