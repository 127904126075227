import gql from 'graphql-tag';

export default gql`
	query ShipmentList($_id: ID) {
		shipmentList(_id: $_id, see_detail: true) {
			shipment {
				_id
				matching_time
				shipment_type
				driver_name_under_mama
				driver_tel_under_mama
				round_trip
				shipment_status
				shipment_number
				account_business_id
				shipper
				shipper_detail
				account_user_name
				account_user_display_name
				transporter
				transporter_detail
				transporter_name
				transporter_phone_code
				transporter_phone_number
				user_setting_truck_id
				driver_user_id
				shipment_tracking_number
				distance
				paid_type
				paid_status
				cancel_comment
				shipment_refund {
					amount
					status
					detail {
						th
						en
					}
				}
				shipment_fines {
					amount
					status
					detail {
						th
						en
					}
					detail_complete {
						th
						en
					}
				}
				setting_truck_name {
					th
				}
				truck_type_name {
					th
				}
				truck_type_qty
				pick_up_date
				create_date
				last_update
				completed_date
				cancel_date
				shipment_address {
					mode
					address
					subdictrict
					lat
					lng
					contact_name
					contact_tel
					remark
					use_contact_point
					paid_by_cod
					pick_up_date
					delivery_date
				}
				shipment_other_detail {
					remark
					user_ref
					document {
						path
					}
				}
				shipment_additional {
					additional_id
					additional
					qty
					price
					address
				}
				shipment_accessory {
					name_lang {
						th
						en
					}
				}
				shipment_confirm_document
				shipment_confirm_detail {
					receipt_doc {
						path
					}
				}
				trackStatus
				multi_point {
					distance_point
					distance_point_detail
					total_point
					price_per_point
				}
				business_status
				flash_deals_id
				matching_date
			}
			total_page
		}
	}
`;
