import gql from 'graphql-tag';

export default gql`
  query NotificationList(
    $_id: ID
    $account_info_id: ID
    $show_data: Int
    $page_number: Int
    $order_by: String
    $order_type: String
  ) {
    notificationList (
      _id: $_id
      account_info_id: $account_info_id
      show_data: $show_data
      page_number: $page_number
      order_by: $order_by
      order_type: $order_type
    ) {
      notification {
        _id
        shipment_id
        title_lang {
          th
          en
        }
        sub_title_lang {
          th
          en
        }
        pic_type
        read_status
        create_date
        last_update
        notification_type
      }
    }
  }
`;