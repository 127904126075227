import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Header } from './../../../../components';
import { Card } from 'src/components';
import { Query } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import styled from 'styled-components';
import { ShipmentDetailList } from './../../components';
import { InstancePriceContext } from './../../../../contexts/InstancePriceContext';

const ShipmentDetailListStyled = styled(ShipmentDetailList)`
	&:last-child {
		margin-bottom: -10px;
	}
`;

class SelectDriver extends React.Component {
	static contextType = InstancePriceContext;

	renderTextDriver = favoriteDriver => {
		return favoriteDriver.length === 0
			? 'พนักงานขับรถ Giztix ทั้งหมด'
			: `พนักงานขับรถคนโปรด ${favoriteDriver.length} คน`;
	};

	render() {
		return (
			<React.Fragment>
				<Query query={instancePriceStepQueryClient}>
					{instancePriceStepData => {
						return (
							<Query query={instancePriceQueryClient}>
								{instancePriceData => {
									const { favoriteDriver } = instancePriceData.data.instancePrice;
									return (
										<Card
											content={
												<div>
													<Header title={'ตัวเลือก'} />
													<div>
														<ShipmentDetailListStyled
															onClick={() => {
																this.context.onOpen(['selectDriver']);
															}}
															title={'พนักงานขับรถที่ต้องการ'}
															content={this.renderTextDriver(favoriteDriver)}
															icon="fal fa-user"
															rightLabel={'แก้ไข'}
														/>
													</div>
												</div>
											}
										/>
									);
								}}
							</Query>
						);
					}}
				</Query>
			</React.Fragment>
		);
	}
}

export default withNamespaces()(SelectDriver);
