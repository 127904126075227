import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	font-size: 20px;
	color: #000000;
	cursor: pointer;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
  align-self: flex-start;

	& i {
		font-size: 18px;
		color: #d90101;
		margin-right: 10px;
  }
`;

const ButtonBack = props => {
	return (
		<Container {...props} onClick={props.onClick}>
			<i className="fal fa-arrow-left" /> <span>{props.t('common:instancePrice.buttonBack')}</span>
		</Container>
	);
};

ButtonBack.propTypes = {
	onClick: PropTypes.func,
};

ButtonBack.defaultProps = {};

export default withNamespaces()(ButtonBack);
