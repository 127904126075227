import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Input, Button, Checkbox, Collapse, Spin, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
import { Query, graphql, Mutation, compose } from 'react-apollo';
import shipmentListQuery from './graphql/query/shipmentList';
import trackingDropCreateMutation from './graphql/mutation/trackingDropCreate';
import trackingDropDeleteMutation from './graphql/mutation/trackingDropDelete';
import trackingDropListQuery from './graphql/query/trackingDropList';
import 'lightbox-react/style.css';
import appUrl from 'src/config/appUrl';
import client from 'src/config/client';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	FacebookShareButton,
	GooglePlusShareButton,
	TwitterShareButton,
	LineShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	GooglePlusIcon,
	LineIcon,
	EmailIcon,
} from 'react-share';
import { ModalConfirm } from 'src/components';
import { RouteItem } from './components';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const Container = styled.div`
  margin-bottom: 10px;
	line-height: 1;

	& .line {
		background-color: #e3e3e3;
		height: 1px;
		margin: 16px 0px;
	}

	& .box-line {
		line-height: 1;
		border: 1px solid #e3e3e3;
		padding: 6px 10px;
		border-radius: 4px;
		margin: 10px 0px;
	}

	& .box-space-between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	& .btn-red {
		background-color: #d10901;
		color: #ffffff;
		border: none;
		min-width: 80px;

		&:hover,
		&:focus,
		&:active {
			color: #fff;
			background-color: #b70101;
			border-color: #b70101;
		}
  }
  
  & .btn-red-outline {
		color: #d10901;
    border: 1px solid #d10901;
    background-color: #ffffff;

    &:hover,
		&:focus,
		&:active {
			color: #d10901;
      border: 1px solid #d10901;
      background-color: #fafafa;
		}
  }

	& .mt-5 {
		margin-top: 5px;
	}

	& .mt-10 {
		margin-top: 10px;
  }
  
  & .mb-10 {
    margin-bottom: 10px;
  }

	& .list-location {
    display: flex;
    flex-direction: row;

		&:not(:last-child) {
			margin-bottom: 10px;
    }
    
    &__title {
      margin-right: 5px;
      font-weight: bold;
      white-space: nowrap;
    }
	}
`;

const LoadingContainer = styled.div`
	min-height: 220px;
	position: relative;

	& div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const ButtonCopy = styled(Button)`
  & i {
    margin-top: 6px;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;

  & div {
    outline: none;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const RouteItemCustom = styled(RouteItem)`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const TrackingDropList = styled.div`
  & .tracking-drop {
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  
    &__title {
      font-weight: bold;
    }
  }

  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #E3E3E3;
`;

const LineDashed = styled.div`
  height: 1px;
  margin: 16px 0px;
  border-top: 1px dashed #e3e3e3;
`;

const ExpandContainer = styled.div`
  margin-top: 16px;
  border-top: 1px dashed #e3e3e3;
  text-align: right;

  & button {
    background-color: #FFFFFF;
    border: none;
    color: #1587E2;
    line-height: 1.5em;
    outline: none;
  }
`;

class ShareRoute extends React.Component {
	state = {
		shortUrl: '',
		shipmentId: '',
		shipmentNumber: '',
		shipmentAddress: [],
    isLoading: false,
    isError: false,
		isOpenCreateLink: false,
		nameLink: '',
    routeSelect: [],
    isLoadingTrackingDropCreate: false,
    isOpenModalDeleteConfirm: false,
    trackingDropId: '',
    trackingDropName: '',
    isExpanded: false,
    itemsToShow: 2,
	};

	componentDidMount() {
		const { trackingUrl } = this.props;

		this.setState({
			trackingUrl: trackingUrl,
		});
	}

	setLoading(status) {
		this.setState({
			isLoading: status,
		});
	}

	getLinkUrl() {
		const { trackingUrl } = this.state;
		return `${appUrl}/tracking/${trackingUrl}`;
	}
	setOpenCreateLink = () => {
		this.setState({
			isOpenCreateLink: true,
			nameLink: '',
			routeSelect: [],
		});
	};

	setCloseCreateLink = () => {
		this.setState({
			isOpenCreateLink: false,
		});
	};

	handleChangeNameLink = value => {
		this.setState({
			nameLink: value,
		});
  };
  
	handleSelectRoute(checked) {
		this.setState({
			routeSelect: [...checked],
		});
  }
  
  renderRouteTrackingDrop = (shipmentAddress, trackingDrop) => {
    const filterShipmentAddress = shipmentAddress.map((item, index) => {
      return {...item, index: index + 1}
    }).filter((item, index) => _.includes(trackingDrop, index.toString()));
    
    return filterShipmentAddress.map((item, index) => (
      <RouteItemCustom
        key={index}
        titleRoute={`จุดที่ ${item.index} - ${item.mode}:`}
        address={item.address}
        contact={`ติดต่อ: ${item.contact_name}, ${item.contact_tel}`}
        />
    ));
  }

  handleCloseModalDeleteConfirm() {
    this.setState({
      isOpenModalDeleteConfirm: false,
    })
  }

  handleExpanded() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

	render() {
		const {
			shipmentNumber,
			isLoading,
      isError,
		} = this.state;

		return (
			<Container>
				{isLoading && (
					<LoadingContainer>
						<Spin indicator={antIcon} />
					</LoadingContainer>
        )}

        {isError && (
					<LoadingContainer>
						Error
					</LoadingContainer>
        )}

				{!isLoading && !isError && (
					<React.Fragment>

						<Row style={{ marginTop: 10 }}>
							<Col md={12}>
								<div>ลิงก์ติดตามงานขนส่ง:</div>
                <Row>
                  <Col md={12}>
                    <Input.Group compact>
                      <Input
                        defaultValue={this.getLinkUrl()}
                        style={{ width: 'calc(100% - 100px)' }}
                        value={this.getLinkUrl()}
                      />
                      <CopyToClipboard text={this.getLinkUrl()}>
                        <ButtonCopy>
                          {this.props.t('common:shipmentDetail.buttonCopy')}
                        </ButtonCopy>
                      </CopyToClipboard>
                    </Input.Group>
                  </Col>
                  <Col md={12} style={{marginTop:20}}>
                    <SocialContainer>
                      <LineShareButton
                        url={this.getLinkUrl()}
                        title={`คุณได้รับการแชร์สถานะงานขนส่ง ติดตามสถานะงานขนส่ง คลิก`}
                      >
                        <LineIcon size={32} round={true} />
                      </LineShareButton>
                      <FacebookShareButton
                        url={this.getLinkUrl()}
                        title={`คุณได้รับการแชร์สถานะงานขนส่ง ติดตามสถานะงานขนส่ง คลิก`}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                      <TwitterShareButton url={this.getLinkUrl()}>
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                      <GooglePlusShareButton url={this.getLinkUrl()}>
                        <GooglePlusIcon size={32} round={true} />
                      </GooglePlusShareButton>
                      <EmailShareButton url={this.getLinkUrl()}>
                        <EmailIcon size={32} round={true} />
                      </EmailShareButton>
                    </SocialContainer>
                  </Col>
                </Row>
							</Col>
						</Row>
					</React.Fragment>
        )}
      </Container>
		);
	}
}

export default withNamespaces()(ShareRoute);
