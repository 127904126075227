import React from 'react';
import styled from 'styled-components';
import { Header, ModalConfirm, Input } from 'src/components';
import { SubHeader, Card, Menu } from '../../components';
import Helmet from 'react-helmet';
import { Row, Col, Button } from 'react-bootstrap';
import color from 'src/config/color';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Menu as MenuAntd, Dropdown, Button as ButtonAntd, Icon, Avatar, Switch  } from 'antd';
import accountInviteMutation from './graphql/mutation/accountInvite';
import accountInviteDeleteMutation from './graphql/mutation/accountInviteDelete';
import accountUserUpdateMutation from './graphql/mutation/accountUserUpdate';
import sendEmailInviteMutation from './graphql/mutation/sendEmailInvite';
import accountInviteListQuery from './graphql/query/accountInviteList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { compose, Query, Mutation, graphql} from 'react-apollo';
import s3 from 'src/config/s3';
import { withNamespaces } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';
import { HeaderNav } from 'src/app/components';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const BackgroundContainer = styled.div`
	background-color: #f9f9f9;
	height: calc(100vh);
	overflow-y: auto;
`;

const Container = styled.div`
	padding-top: calc(150px + ${heightTopHeader}px);
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 60px;

	@media(max-width: 767px) {
		padding-top: 20px;
	}
`;

const TextHeader = styled.div`
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	position: relative;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
`;

const TextValidate = styled.div`
	font-size: 18px;
	line-height: 1.3;
	color: #D90101;
	height: 20px;
`;

const Title = styled.div`
	font-weight: bold;
`;

const EmailListContainer = styled.div`
	min-height: 60px;

	& .ant-btn-group:not(:last-child) {
		margin-right: 20px;
		margin-bottom: 10px;
	}

	padding-bottom: 10px;
`;

const LineHorizontal = styled.div`
	width: 100%;
	height: 1px;
	background-color: #E3E3E3;
`;

const UserListContainer = styled.div``;

const UserListItem = styled.div`
	display: flex;
	flex-direction: row;
	line-height: 1.2;
	padding-top: 20px;

	& .profile-detail {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-left: 20px;
	}

	& .profile-detail-list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&:not(:last-child) {
		padding-bottom: 15px;
		border-bottom: 1px solid #E3E3E3;
	}
`;

const ButtonDelete = styled.button`
	color: #888888;
	border: none;
	background-color: transparent;
	padding: 0px;

	& i {
		font-size: 16px;
		margin-right: 5px;
	}

	&:hover {
		color: #00000080;
	}

	&:focus {
		outline: none;
	}
`;

class SubAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenModalInviteUser: false,
			
			//บัญชีรอการตอบรับ
			isOpenModalResendEmail: false,
			isOpenModalDeleteInviteUser: false,
			accountInviteListId: '',
			accountInviteListEmail: '',

			//บัญชีย่อย
			isOpenModalDeleteSubAccount: false,
			subAccountId: '',
			subAccountName: '',
		}
	}

	async addAccountInvite(values) {
		try {
			const { data } = await this.props.accountInviteMutation({
				variables: {
					email: values.email
				},
				refetchQueries: () => ['AccountInviteList']
			});

			return {
				succeed: data.accountInvite.succeed,
				error: null,
			};
		} catch(error) {
			return {
				succeed: false,
				error: error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message,
			};
		}
	}

	render() {
		const InviteUserSchema = Yup.object().shape({
			email: Yup.string()
				.matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, this.props.t('common:setting.subAccount.modal.addSubAccount.input.email.validate.format'))
				.required(this.props.t('common:setting.subAccount.modal.addSubAccount.input.email.validate.required')),
		});

		return (
			<div>
				<Helmet>
					<title>บัญชีย่อย | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full={true} />
				<SubHeader backLink="/" />
				<BackgroundContainer>
					<Container>
						<Row>
							<Col lg={2} md={3} sm={12}>
								<Menu />
							</Col>
							<Col lg={8} md={6} sm={12}>
								<Card>
									<TextHeader>
										{this.props.t('common:setting.subAccount.title')}
										<Button style={{position: 'absolute', top: 0, right: 0}} onClick={() => this.setState({ isOpenModalInviteUser: true })}>
											<i style={{color: color.Primary}} className="fal fa-plus"></i> {this.props.t('common:setting.subAccount.buttonAdd')}
										</Button>
									</TextHeader>
									<TextSubHeader>
										{this.props.t('common:setting.subAccount.subTitle')}
									</TextSubHeader>

									<Row style={{marginTop: 30}}>
										<Col xs={12}>
											<Title>{this.props.t('common:setting.subAccount.titlePendingInvitation')}</Title>
											<EmailListContainer>
												<Query
													query={accountInviteListQuery}
													variables={{
														status: 1,
													}}
												>
													{
														({ data, loading, error }) => {
															if(loading || error) return 'loading...';
															const { accountInviteList } = data;

															return (
																<div>
																	{
																		accountInviteList.length === 0 ? '-' : accountInviteList.map((item, index) => (
																			<Dropdown.Button key={index} trigger={['click']} overlay={
																				<MenuAntd>
																					<MenuAntd.Item onClick={() => {
																						setTimeout(() => {
																							this.setState({ 
																								isOpenModalResendEmail : true,
																								accountInviteListId: item._id,
																								accountInviteListEmail: item.email,
																							});
																						}, 300);
																					}}
																					>
																						{this.props.t('common:setting.subAccount.buttonResend')}
																					</MenuAntd.Item>
																					<MenuAntd.Item onClick={() => {
																						setTimeout(() => {
																							this.setState({ 
																								isOpenModalDeleteInviteUser : true,
																								accountInviteListId: item._id,
																								accountInviteListEmail: item.email,
																							});
																						}, 300);
																					}}>
																						{this.props.t('common:setting.subAccount.buttonDeleteInvite')}
																					</MenuAntd.Item>
																				</MenuAntd>
																			} icon={<Icon type="more" />}>
																				{item.email}
																			</Dropdown.Button>
																		))
																	}
																</div>
															)
														}
													}
												</Query>

												<Mutation
													mutation={sendEmailInviteMutation}
													variables={{
														_id: this.state.accountInviteListId
													}}
													update={({ data }) => {
														this.setState({ 
															isOpenModalResendEmail: false,
															accountInviteListId: '',
															accountInviteListEmail: '',
														});
													}}
													refetchQueries={() => ['AccountInviteList']}
												>
													{
														(sendEmailInvite) => {
															return (
																<ModalConfirm
																	show={this.state.isOpenModalResendEmail}
																	titleHeader={this.props.t('common:setting.subAccount.modal.resend.title')}
																	labelButtonConfirm={this.props.t('common:setting.subAccount.modal.resend.buttonConfirm')}
																	styleColorButtonConfirm="primary"
																	onPressButtonConfirm={() => sendEmailInvite()}
																	labelButtonCancel={this.props.t('common:setting.subAccount.modal.resend.buttonCancel')}
																	onPressButtonCancel={() => {
																		this.setState({ 
																			isOpenModalResendEmail: false,
																			accountInviteListId: '',
																			accountInviteListEmail: '',
																		});
																	}}
																>
																	<div style={{lineHeight: 1}}>
																		{ ReactHTMLParser(this.props.t('common:setting.subAccount.modal.resend.content', { email: this.state.accountInviteListEmail }))}
																	</div>
																</ModalConfirm>
															)
														}
													}
												</Mutation>

												<Mutation
													mutation={accountInviteDeleteMutation}
													variables={{
														_id: this.state.accountInviteListId
													}}
													update={({ data }) => {
														this.setState({ 
															isOpenModalDeleteInviteUser: false,
															accountInviteListId: '',
															accountInviteListEmail: '',
														});
													}}
													refetchQueries={() => ['AccountInviteList']}
												>
													{
														(accountInviteDelete) => {
															return (
																<ModalConfirm
																	show={this.state.isOpenModalDeleteInviteUser}
																	titleHeader={this.props.t('common:setting.subAccount.modal.deleteInvite.title')}
																	labelButtonConfirm={this.props.t('common:setting.subAccount.modal.deleteInvite.buttonConfirm')}
																	styleColorButtonConfirm="primary"
																	onPressButtonConfirm={() => accountInviteDelete()}
																	labelButtonCancel={this.props.t('common:setting.subAccount.modal.deleteInvite.buttonCancel')}
																	onPressButtonCancel={() => {
																		this.setState({
																			isOpenModalDeleteInviteUser: false,
																			accountInviteListId: '',
																			accountInviteListEmail: '',
																		});
																	}}
																>
																	<div style={{lineHeight: 1}}>
																		{ ReactHTMLParser(this.props.t('common:setting.subAccount.modal.deleteInvite.content', { email: this.state.accountInviteListEmail }))}
																	</div>
																</ModalConfirm>
															)
														}
													}
												</Mutation>

												<Mutation
													mutation={accountUserUpdateMutation}
													variables={{
														_id: this.state.subAccountId,
														accountUserStatus: 3,
													}}
													update={({ data }) => {
														this.setState({ 
															isOpenModalDeleteSubAccount: false,
															subAccountName: '',
															subAccountId: '',
														});
													}}
													refetchQueries={() => ['AccountInfoListSubAccount']}
												>
													{
														(sendEmailInvite) => {
															return (
																<ModalConfirm
																	show={this.state.isOpenModalDeleteSubAccount}
																	titleHeader={this.props.t('common:setting.subAccount.modal.deleteSubAccount.title')}
																	labelButtonConfirm={this.props.t('common:setting.subAccount.modal.deleteSubAccount.buttonConfirm')}
																	styleColorButtonConfirm="primary"
																	onPressButtonConfirm={() => sendEmailInvite()}
																	labelButtonCancel={this.props.t('common:setting.subAccount.modal.deleteSubAccount.buttonCancel')}
																	onPressButtonCancel={() => {
																		this.setState({ 
																			isOpenModalDeleteSubAccount: false,
																			subAccountName: '',
																			subAccountId: '',
																		});
																	}}
																>
																	<div style={{lineHeight: 1}}>
																		{ ReactHTMLParser(this.props.t('common:setting.subAccount.modal.deleteSubAccount.content', { name: this.state.subAccountName })) }
																	</div>
																</ModalConfirm>
															)
														}
													}
												</Mutation>
											</EmailListContainer>
											<LineHorizontal/>
										</Col>
									</Row>

									<Row style={{marginTop: 10}}>
										<Col xs={12}>
											<Title>{this.props.t('common:setting.subAccount.titleSubAccount')}</Title>
											<UserListContainer>
												<Query
													query={accountInfoListQuery}
												>
													{
														({ data, loading, error }) => {
															if(loading || error) return 'loading...';
															const { account_users } = data.accountInfoList.account_info[0];
															const filterAccountUsers = account_users.filter(item => item.account_user_status !== 3).filter(item => item.account_user_type === 2);
															
															return (
																<div>
																	{
																		account_users && filterAccountUsers.length === 0 ? '-' : filterAccountUsers.map((item, index) => (
																			<UserListItem key={index}>
																				<div className="profile-img">
																					<Avatar size={60} icon="user" src={item.account_user_display_picture ? `${s3}/${item.account_user_display_picture}` : ''} />
																				</div>
																				<div className="profile-detail">
																					<div className="profile-detail-list">
																						<div><strong>{item.account_user_display_name}</strong></div>
																						<div></div>
																					</div>
																					<div className="profile-detail-list">
																						<div>{this.props.t('common:setting.subAccount.email')}: {item.account_user_name}</div>
																						<div>
																							{this.props.t('common:setting.subAccount.switchNotification')}
																							<Mutation
																								mutation={accountUserUpdateMutation}
																								variables={{
																									_id: item._id,
																									permissionNotification: !item.account_user_permission.noti
																								}}
																								refetchQueries={() => ['AccountInfoListSubAccount']}
																							>
																								{
																									(accountUserUpdate, { loading: loadingAccountUserUpdate }) => {
																										return (
																											<Switch onClick={() => accountUserUpdate()} loading={loadingAccountUserUpdate} size={'small'} style={{ marginLeft: 10 }} checked={item.account_user_permission.noti} />
																										)
																									}
																								}
																							</Mutation>
																						</div>
																					</div>
																					<div className="profile-detail-list">
																						<div>{this.props.t('common:setting.subAccount.mobileNumber')}: +{item.account_user_phone_code} {item.account_user_phone_number}</div>
																						<div>
																							{this.props.t('common:setting.subAccount.switchShipment')}
																							<Mutation
																								mutation={accountUserUpdateMutation}
																								variables={{
																									_id: item._id,
																									permissionShipmentList: !item.account_user_permission.shipment_list
																								}}
																								refetchQueries={() => ['AccountInfoListSubAccount']}
																							>
																								{
																									(accountUserUpdate, { loading: loadingAccountUserUpdate }) => {
																										return (
																											<Switch onClick={() => accountUserUpdate()} loading={loadingAccountUserUpdate} size={'small'} style={{ marginLeft: 10 }} checked={item.account_user_permission.shipment_list} />
																										)
																									}
																								}
																							</Mutation>
																						</div>
																					</div>
																					<div className="profile-detail-list">
																						<div>
																							<ButtonDelete
																								onClick={() => this.setState({
																									subAccountId: item._id,
																									subAccountName: item.account_user_display_name,
																									isOpenModalDeleteSubAccount: true,
																								})}
																							><i class="fal fa-trash-alt"></i> {this.props.t('common:setting.subAccount.buttonDeleteSubAccount')}</ButtonDelete>
																						</div>
																						<div></div>
																					</div>
																				</div>
																			</UserListItem>
																		))
																	}
																</div>
															)
														}
													}
												</Query>
											</UserListContainer>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col lg={2} md={3} sm={12}></Col>
						</Row>
					</Container>
				</BackgroundContainer>
				
				<Formik
					initialValues={{
						email: '',
					}}
					validationSchema={InviteUserSchema}
					onSubmit={async (values, { resetForm, setFieldError }) => {
						const { succeed, error } = await this.addAccountInvite(values);

						if(!succeed) {
							if(error === 'User name this use.' || error === 'Email used.') {
								setFieldError('email', this.props.t('common:setting.subAccount.modal.addSubAccount.input.email.validate.existing'));
							} else {
								setFieldError('email', this.props.t('common:setting.subAccount.modal.addSubAccount.input.email.validate.error'));
							}

							return;
						}

						this.setState({ isOpenModalInviteUser: false });
						setTimeout(() => {
							resetForm();
						}, 500);
					}}
				>
					{
						(props) => {
							const { handleBlur, handleSubmit, touched, values, errors } = props;
							return (
								<ModalConfirm
									show={this.state.isOpenModalInviteUser}
									titleHeader={this.props.t('common:setting.subAccount.modal.addSubAccount.title')}
									labelButtonConfirm={this.props.t('common:setting.subAccount.modal.addSubAccount.buttonConfirm')}
									styleColorButtonConfirm="primary"
									onPressButtonConfirm={() => handleSubmit()}
									labelButtonCancel={this.props.t('common:setting.subAccount.modal.addSubAccount.buttonCancel')}
									onPressButtonCancel={() => {
										this.setState({ 
											isOpenModalInviteUser: false,
										});

										setTimeout(() => {
											props.resetForm();
											props.setSubmitting(false);
										}, 500);
									}}
									>
									<div style={{lineHeight: 1}}>
										<div>{this.props.t('common:setting.subAccount.modal.addSubAccount.subTitle')}</div>
										<Row>
											<Col xs={12} sm={6}>
												<Input
													style={{marginTop: 10, border: (errors.email && touched.email) ? '1px solid #D90101' : null}}
													iconLeft="fas fa-envelope"
													placeholder={this.props.t('common:setting.subAccount.modal.addSubAccount.input.email.placeholder')}
													value={values.email}
													onChange={(e) => {
														props.setFieldValue('email', e.target.value);
													}}
													onBlur={handleBlur}
													type="text"
													name="email"
													autocomplete="off"
													onKeyDown={(e) => {
														if(e.key === 'Enter') {
															handleSubmit();
														}
													}}
												/>
											</Col>
										</Row>
										<TextValidate>
											{(errors.email && touched.email) && errors.email}
										</TextValidate>
									</div>
								</ModalConfirm>
							)
						}
					}
				</Formik>
			</div>
		);
	}
}

export default compose(
	graphql(accountInviteMutation, {
		name: 'accountInviteMutation'
	})
)(withNamespaces()(SubAccount));
