import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`;

export const DetailContainer = styled.div`
	flex: 1;
	padding-right: 20px;
	padding-bottom: 10px;
`;

export const Pin = styled.div`
	width: 20px;
	margin-right: 20px;
	color: #b3b3b3;
	padding-top: 7px;
  padding-left: 1px;
  font-size: 12px;

	${props =>
		props.type === 'success' &&
		`
    color: #23B034;
    font-size: 15px;
  `}

	${props =>
		props.type === 'fail' &&
		`
    color: #D90101;
    font-size: 12px;
    padding-top: 7px;
    padding-left: 1px;
  `}

	${props =>
		props.type === 'process' &&
		`
    color: #000000;
    font-size: 12px;
    padding-top: 7px;
    padding-left: 1px;
  `}
`;
