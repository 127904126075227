import gql from 'graphql-tag';

export default gql`
	query ShipmentListShareRoute($_id: ID) {
		shipmentList(_id: $_id) {
			shipment {
				_id
				shipment_number
				shipment_address {
					mode
					address
					subdictrict
          dictrict
          province
					lat
					lng
					contact_name
					contact_tel
				}
			}
		}
	}
`;
