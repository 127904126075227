import gql from 'graphql-tag';

export default gql`
	query SettingTruckList(
		$shipmentType: Int
	) {
		settingTruckList (
			shipment_type: $shipmentType
		) {
			_id
			setting_truck_name {
				th
				en
			}
			setting_truck_type {
				_id
				truck_type_name {
					th
					en
				}
				truck_type_enable
			}
		}
	}
`;
