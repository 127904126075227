import React from 'react';
import styled from 'styled-components';

const RadioSaveContactContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 1;
	margin-bottom: 20px;
	font-size: 18px;
	cursor: pointer;

	& i {
		margin-right: 10px;
		color: #707070;
	}

	& i.radio-checked {
		color: #19aa19;
	}
`;

const Checkbox = props => {
	let icon = <i className="fal fa-circle"></i>;
	if (props.checked) {
		icon = <i className="fas fa-check-circle radio-checked"></i>;
	}

	return (
		<RadioSaveContactContainer onClick={props.clicked}>
			<div>{icon}</div>
			<div>{props.text}</div>
		</RadioSaveContactContainer>
	);
};

export default Checkbox;
