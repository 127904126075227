import React, { Component } from 'react';
import styled from 'styled-components';
import { withNamespaces } from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  border-radius: 4px;
  background-color: ${props => props.bgColor || "#FFFFFF"}
  padding: 4px 6px 2px 6px;
  border: 1px solid ${props => props.borderColor || "#E3E3E3"};
  color: ${props => props.textColor || "#000000"};
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 1;
`;

const Icon = styled.span`
  font-size: 14px;
  line-height: 1;
  margin-right: 4px;
`;

class Label extends React.Component {
  renderStatus = (type) => {
    if (type === 2) {
      return (
        <Container bgColor="#FFFFFF" textColor="#000000" borderColor="#E3E3E3">
          <Text>{this.props.t('common:shipmentList.waitingForTransporterAccept')}...</Text>
        </Container>
      )
    } else if (type === 10) {
      return (
        <Container bgColor="#000000" textColor="#FFFFFF" borderColor="#000000">
          <Text>{this.props.t('common:shipmentList.waitingForStartShipment')}</Text>
        </Container>
      )
    } else if (type === 3) {
      return (
        <Container bgColor="#FFFFFF" textColor="#D90101" borderColor="#D90101">
          <Text>{this.props.t('common:shipmentList.shipmentProcessing')}</Text>
        </Container>
      )
    } else if (type === 4) {
      return (
        <Container bgColor="#D90101" textColor="#FFFFFF" borderColor="#D90101">
          <Icon><i className="far fa-check"></i></Icon>
          <Text>{this.props.t('common:shipmentList.shipmentCompleted')}</Text>
        </Container>
      )
    } else if (type === 5) {
      return (
        <Container bgColor="#FFFFFF" textColor="#000000" borderColor="#E3E3E3">
          <Icon><i className="fal fa-times"></i></Icon>
          <Text>{this.props.t('common:shipmentList.canceled')}</Text>
        </Container>
      )
    } else if (type === 6) {
      return (
        <Container bgColor="#FFFFFF" textColor="#000000" borderColor="#E3E3E3">
          <Icon><i className="fal fa-exclamation-triangle"></i></Icon>
          <Text>{this.props.t('common:shipmentList.shipmentHasProblem')}</Text>
        </Container>
      )
    } else if (type === 7) {
      return (
        <Container bgColor="#FFFFFF" textColor="#000000" borderColor="#E3E3E3">
          <Text>{this.props.t('common:shipmentList.shipmentHasProblem')}</Text>
        </Container>
      )
    } else if (type === 8) {
      return (
        <Container bgColor="#D90101" textColor="#FFFFFF" borderColor="#D90101">
          <Icon><i className="fas fa-phone"></i></Icon>
          <Text>{this.props.t('common:shipmentList.madeAnAppointmentToCustomer')}</Text>
        </Container>
      )
    } else if (type === 0) {
      return (
        <Container bgColor="#000000" textColor="#FFFFFF" borderColor="#000000">
          <Text>{this.props.t('common:shipmentList.madeAnAppointmentToCustomer')}</Text>
        </Container>
      )
    }

    return null;
  }

  render() {
    return (
      <div style={{ display: "inline-block" }}>
        {this.renderStatus(this.props.type)}
      </div>
    )
  }
}

export default withNamespaces()(Label);