import React from 'react';
import {
  Input,
  Card,
  Select,
} from './.././../components';
import { Row, Col, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import registerDriverLineMutation from './graphql/mutation/registerDriverLine';
import settingTruckListQuery from './graphql/query/settingTruckList';
import urijs from 'urijs';

const liff = window.liff;
//Validate Yup
const driverValidate = Yup.object().shape({
  driverName: Yup.string()
    .required('กรุณากรอกข้อมูล'),
  driverPhoneNumber: Yup.string()
    .matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  driverLicensePlate: Yup.string()
    .required('กรุณากรอกข้อมูล'),
    setting_truck_service: Yup.string()
    .required('กรุณากรอกข้อมูล'),
  driver_tel_under_mama: Yup.string()
    .matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง')
});

const Container = styled.div`
  margin-top: 10px;
  background-image: url(${require('src/assets/images/homepage/cover/circle.svg')});
  background-repeat: no-repeat;
  background-position: top left 100px;
  background-size: 200%;
  height: 100vh;
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`;

const Logo = styled.div`
  text-align: center;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;

  font-size: 24px;
`;

class RegisterDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userLineID: '1118',
      pictureUrl: '',
      statusMessage: '',
      setting_truck_service:null,

      isLoading: false,
      isSuccess: false,
      isError: false,
      accountInfoId: '',
      settingTruckList:null
      
    };
  }

  async componentWillMount() {
    await this.getProfile();
    const uriTR = urijs(window.location.href).query(true).tr;
    if(uriTR) {
      this.setState({
        accountInfoId: urijs(window.location.href).query(true).tr
      });
    }

    //console.log("componentWillMount",this.state)

    // alert(this.state.userLineID)
  }

  componentWillReceiveProps(newProps) {
		const { loading, error , settingTruckList } = newProps.settingTruckListQuery;
		if (!loading && !error) {
			
			this.setState({
				settingTruckList : settingTruckList
			});
		}
	}

  getProfile() {
    liff.init(async () => {
      let getProfile = await liff.getProfile();
      //console.log("getProfile" ,getProfile)
      this.setState({
        name: getProfile.displayName,
        userLineID: getProfile.userId,
        pictureUrl: getProfile.pictureUrl,
        statusMessage: getProfile.statusMessage
      });
    });
  }

  async register(values) {
    try {
      this.setState({ isLoading: true });
      this.props.registerDriverLineMutation({
        variables: {
          account_info_id: this.state.accountInfoId,
          line_user_id: values.userLineID,
          driver_name: values.driverName,
          driver_phone_code: values.driverPhoneCode,
          driver_phone_number: values.driverPhoneNumber.replace(/\b0+/g,'',),
          user_setting_truck_license_plate: values.driverLicensePlate,
          setting_truck_service: [values.setting_truck_service],
          driver_enable: 1,
        }
      })
      .then(({ data }) => {
        if(data.registerDriverLine._id) {
          if(data.registerDriverLine.exist){
             this.setState({ isLoading: false}, () => alert("เบอร์โทรศัพท์นี้ได้ลงทะเบียนไปแล้ว"));
          }
          else{
            this.setState({ isLoading: false, isSuccess: true });
          }
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({ isLoading: false });
      });
    } catch(error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Container>
        <Card style={{ backgroundColor: 'transparent' }}>
          <Logo>
            <img src={`${require('src/assets/images/logo_giztix.svg')}`} height={36} alt="" />
          </Logo>

          {
            this.state.isSuccess && (
              <div>
                <SuccessContainer>
                  ลงทะเบียนคนขับรถสำเร็จ
                </SuccessContainer>
              </div>
            )
          }

          {
            !this.state.isSuccess && (
              <div>
                <Header>ลงทะเบียนคนขับรถ</Header>
                <Formik
                  initialValues={{
                    driverName: '',
                    driverPhoneCode: '66',
                    driverPhoneNumber:  '',
                    driverLicensePlate: '',
                    setting_truck_service:null,
                    userLineID: this.state.userLineID,
                  }}
                  validationSchema={driverValidate}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    this.register(values);
                  }}
                >
                  {
                    (props) => {
                      const { values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
                      return (
                        <Row>
                          <Col xs={12}>
                            <Input
                              required
                              value={values.driverName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{marginTop: 10}}
                              placeholder={errors.driverName && touched.driverName ? errors.driverName : 'ชื่อคนขับรถ'}
                              inputLabel="ชื่อคนขับรถ"
                              name="driverName"
                              type="text"
                              error={errors.driverName && touched.driverName}
                            />
                          </Col>

                          <Col xs={12}>
                            <Input
                              required
                              value={values.driverPhoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{marginTop: 10}}
                              placeholder={errors.driverPhoneNumber && touched.driverPhoneNumber ? errors.driverPhoneNumber : '0812345678'}
                              inputLabel="เบอร์โทรศัพท์"
                              name="driverPhoneNumber"
                              type="text"
                              error={errors.driverPhoneNumber && touched.driverPhoneNumber}
                            />

                            <div style={{textAlign: 'right', color: '#D90101', lineHeight: 1}}>
                              { errors.driverPhoneNumber && touched.driverPhoneNumber && values.driverPhoneNumber ? errors.driverPhoneNumber : '' }
                            </div>
                          </Col>

                          <Col xs={12}>
                            <Select
                              required
                              value={values.setting_truck_service}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{marginTop: 10}}
                              styleSelect={{height:30,backgroundColor:"#ffffff"}}
                              placeholder={errors.setting_truck_service && touched.setting_truck_service ? errors.setting_truck_service : 'ระบุ'}
                              inputLabel="ประเภทรถ"
                              name="setting_truck_service"
                              type="text"
                              error={errors.setting_truck_service && touched.setting_truck_service}
                            >
                              <option value=""></option>
                                {this.state.settingTruckList != null &&
                                    this.state.settingTruckList.map((truck, index) => (
                                        <optgroup key={index} label={`รถ ${truck.setting_truck_name.th}`}>
                                            { truck.setting_truck_type.filter(item => item.truck_type_enable).map((item, key, arr) => (
                                                <option key={key} value={item._id} data-truckId={truck._id}>{
                                                    `${
                                                        truck.setting_truck_name.th
                                                    } - ${
                                                        item.truck_type_name.th
                                                    }` || undefined
                                                }</option>
                                            ))
                                            }
                                        </optgroup>
                                    ))
                                }
                            </Select>
                          </Col>

                          <Col xs={12}>
                            <Input
                              required
                              value={values.driverLicensePlate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{marginTop: 10}}
                              placeholder={errors.driverLicensePlate && touched.driverLicensePlate ? errors.driverLicensePlate : 'ระบุ'}
                              inputLabel="ทะเบียนรถ"
                              name="driverLicensePlate"
                              type="text"
                              error={errors.driverLicensePlate && touched.driverLicensePlate}
                            />
                          </Col>

                          <Col xs={12}>
                            <Button style={{marginTop: 30}} bsStyle="primary" block onClick={() => handleSubmit()} disabled={this.state.isLoading}>{this.state.isLoading ? 'ตกลง...' : 'ตกลง'}</Button>
                          </Col>
                        </Row>
                      )
                    }
                  }
                </Formik>
              </div>
            )
          }
        </Card>
      </Container>
    )
  }
}

export default compose(
  graphql(registerDriverLineMutation, {
    name: 'registerDriverLineMutation'
  }),
  graphql(settingTruckListQuery, {
		name: 'settingTruckListQuery',
			options: props => ({
			fetchPolicy: 'network-only',
			variables: {
				shipmentType: 1,
			},
		})
    })
)(RegisterDriver);