import React from 'react';
import styled from 'styled-components';
import { Statistic } from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const { Countdown } = Statistic;
const Container = styled.div`
  display: inline-block;
  width: auto;
  min-width: 250px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  border: 1px solid hsl(210, 9%, 96%);
  position: relative;
  height: 110px;
  overflow: hidden;
  cursor: pointer;
`;

const Ribbon = styled.div`
  line-height: 23px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #D90101;
  height: 23px;
  display: inline-block;
  min-width: 100px;
  padding: 0px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 5px;
`;

const ContentContainer = styled.div`
  text-align: center;
  margin-top: 32px;
  white-space: nowrap;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  line-height: 0.6;
  padding: 0 20px;
`;

const Button = styled.button`
  font-size: 18px;
  background-color: transparent;
  border: 1px solid #D90101;
  color: #D90101;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0px 16px;
  height: 20px;
  outline: none;

  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

  &:hover {
    color: #FFFFFF;
    background-color: #D90101;
  }
`;

const PriceContainer = styled.div`
  font-size: 18px;
  
  & .price {
    font-weight: bold;
    font-size: 34px;
    color: #D90101;
    margin-left: 5px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F3F3F3;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px 8px;
  border-top: 1px solid hsl(210, 9%, 96%);
`;

const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  position: absolute;
  font-size: 16px;
  top: 5px;
  right: 5px;

  & .countdown {
    font-weight: bold;
    padding-left: 5px;
    margin-top: 3px;
  }

  & .ant-statistic-content {
    font-size: 16px;
    line-height: 1;
  }

  & i {
    font-size: 12px;
  }
`;

const CardFlashDealsSmallItem = (props) => {
  return (
    <Container {...props} onClick={() => props.onSelect()}>
      <Ribbon>{props.name}</Ribbon>
      <ContentContainer>
        <Title>{props.provinceName}</Title>
        <PriceContainer>เหลือเพียง <span className="price">
          <NumberFormat displayType={'text'} value={props.priceSale} thousandSeparator={true}/> บาท
        </span></PriceContainer>
        <CountDownContainer>
          <i className="fal fa-clock"></i>
          <span className="countdown">
            <Countdown value={props.exp} format={`${moment(props.exp).diff(moment(), 'days') > 0 ? 'D วัน HH:mm:ss' : 'HH:mm:ss'}`} />
          </span>
        </CountDownContainer>
      </ContentContainer>
      <Footer>
        <div className="left">
          { props.truckTypeName }
        </div>
        <div className="right">
          <Button onClick={() => props.onSelect()}>จอง</Button>
        </div>
      </Footer>
    </Container>
  )
}

export default CardFlashDealsSmallItem;