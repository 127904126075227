import React, { Component } from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Modal,
	Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from 'src/config/color';

import {
	Card,
	CardHeader,
	AdditionalListItem,
	AccessoryListItem,
	Skeleton,
	Input,
} from 'src/components';

import { ModalConfirm } from 'src/components';

import InfoLabor from './components/InfoLabor';
import ExtraLargeCar6WheelsCabinet from './components/ExtraLargeCar6WheelsCabinet';
import ExtraLargeCar6WheelsStall from './components/ExtraLargeCar6WheelsStall';
import InfoLabor6Wheel from './components/InfoLabor6Wheel';
import InfoLabor10Wheel from './components/InfoLabor10Wheel';
import PODAddress from './components/PODAddress';
import InfoOverTimeTrailer from './components/InfoOverTimeTrailer';

import { graphql, Mutation, Query ,compose} from 'react-apollo';
import additionalListQuery from './graphql/query/additionalList';
import accessoryListQuery from './graphql/query/accessoryList';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import client from 'src/config/client.js';
import { withNamespaces } from "react-i18next";
import detectLanguageData from './../../function/detectLanguageData';
import ReactHTMLParser from 'react-html-parser';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const Container = styled.div`
	width: 100%;
`;

const TruckListContainer = styled.div`
	margin-top: 20px;
`;

const AccessoryContainer = styled.div`
	& .accessory {
		&-title {
			font-weight: bold;
			font-size: 20px;
			color: #000000;
		}

		&-sub-title {
			font-size: 18px;
			color: #808080;
		}
	}

	.ant-collapse-header {
		padding: 0px !important;
		padding-bottom: 15px;

		& i {
			position: absolute !important;
			right: 0px !important;
			text-align: right !important;
			color: #B3B3B3 !important;
			font-size: 16px !important;
		}
	}

	.ant-collapse-item {
		padding-bottom: 15px;
	}

	.ant-collapse-content-box {
		padding: 0px !important;
	}
`;

const AccessoryListItemStylePanel = styled(AccessoryListItem)`
	&:not(:last-child) {
		border-bottom: 1px solid #ddd;
  	padding-bottom: 15px;
	}

	&:first-child {
		margin-top: 20px;
	}

	&:last-child {
		border: 0px;
		margin-bottom: 0;
	}
`;

const AccessoryListItemStyle = styled(AccessoryListItem)`
	border-bottom: 1px solid #ddd;
	padding-bottom: 15px;
`;

class Additional extends React.Component {
	state = {
		onOpenInfo: false,
		onOpenInfoLabor: false,
		onOpenInfoMoreLabor4Wheels: false,
		onOpenInfoLabor6Wheels: false,
		onOpenInfoMoreLabor6Wheels: false,
		onOpenInfoMoreLabor6WheelsCrane: false,
		onOpenInfoLabor10Wheels: false,
		onOpenInfoMoreLabor10Wheels: false,
		onOpenExtraLargeCar4WheelsCabinet: false,
		onOpenExtraLargeCar4WheelsStall: false,
		onOpenExtraLargeCar6WheelsCabinet: false,
		onOpenExtraLargeCar6WheelsStall: false,
		onOpenExtraLargeCar6WheelsLift: false,
		onOpenExtraLargeCar10WheelsCabinet: false,
		onOpenExtraLargeCar10WheelsStall: false,
		onOpenExtraLargeCar10WheelsLift: false,
		onOpenPODAddress: false,
		onOpenInfoOverTimeTrailer: false,
		onOpenInfoLabor4WheelsBike: false,
		onOpenInfoMoreLabor4WheelsBike: false,
		onOpenInfoWoodenPallets: false,
		selectCurrentItem: {},
		viewInfo: false,
		addressPod: null,
	};

	closeTap(updateInstancePriceStep) {
		updateInstancePriceStep({
			variables: {
				additional: false,
			},
		});
	}

	onSelect(data, isAccept, updateInstancePrice, additionalList = []) {
		const { additional } = this.props.instancePriceQuery.instancePrice;

		const additionalFilter = additional.filter(item => item._id === data._id);
		if (additionalFilter.length === 0) {
			//Labor
			if (data._id === '5d14a013f13324149ba13560') { //ผู้ช่วย 4 ล้อ
				const additionalMoreLabor4Wheels = additionalList.filter(
					item => item._id === '5c38262bd4425c3d608c9061',
				);
				
				if (additionalMoreLabor4Wheels.length > 0) {
					const instancePriceData = this.props.instancePriceQuery;
					const addAdditional = [
						...instancePriceData.instancePrice.additional.filter(item => item._id !== '5d14a013f13324149ba13560' && item._id !== '5c38262bd4425c3d608c9061'),
						{
							_id: data._id,
							name: {
								...data.name_lang,
								__typename: 'AdditionalName',
							},
							price: data.price,
							qty: data.min,
							truck_type_size: {
								...data.truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: data.address,
							__typename: 'Additional',
						},
						{
							_id: additionalMoreLabor4Wheels[0]._id,
							name: {
								...additionalMoreLabor4Wheels[0].name_lang,
								__typename: 'AdditionalName',
							},
							price: additionalMoreLabor4Wheels[0].price,
							qty: additionalMoreLabor4Wheels[0].min,
							truck_type_size: {
								...additionalMoreLabor4Wheels[0].truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: additionalMoreLabor4Wheels[0].address,
							__typename: 'Additional',
						}
					];

					updateInstancePrice({
						variables: {
							additional: addAdditional,
						},
					});
				}
			} else if (data._id === '5c77a4bbd3bb6bbabe8681e1') {
				const additionalMoreLabor6Wheels = additionalList.filter(
					item => item._id === '5d14a03bf13324149ba13564',
				);
				
				if (additionalMoreLabor6Wheels.length > 0) {
					const instancePriceData = this.props.instancePriceQuery;
					const addAdditional = [
						...instancePriceData.instancePrice.additional,
						{
							_id: data._id,
							name: {
								...data.name_lang,
								__typename: 'AdditionalName',
							},
							price: data.price,
							qty: data.min,
							truck_type_size: {
								...data.truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: data.address,
							__typename: 'Additional',
						},
						{
							_id: additionalMoreLabor6Wheels[0]._id,
							name: {
								...additionalMoreLabor6Wheels[0].name_lang,
								__typename: 'AdditionalName',
							},
							price: additionalMoreLabor6Wheels[0].price,
							qty: additionalMoreLabor6Wheels[0].min,
							truck_type_size: {
								...additionalMoreLabor6Wheels[0].truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: additionalMoreLabor6Wheels[0].address,
							__typename: 'Additional',
						}
					];

					updateInstancePrice({
						variables: {
							additional: addAdditional,
						},
					});
				}
			} else if (data._id === '5d14a03bf13324149ba13564') {
				const additionalLabor6Wheels = additionalList.filter(
					item => item._id === '5c77a4bbd3bb6bbabe8681e1',
				);

				if (additionalLabor6Wheels.length > 0) {
					const instancePriceData = this.props.instancePriceQuery;
					const addAdditional = [
						...instancePriceData.instancePrice.additional,
						{
							_id: data._id,
							name: {
								...data.name_lang,
								__typename: 'AdditionalName',
							},
							price: data.price,
							qty: data.min,
							truck_type_size: {
								...data.truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: data.address,
							__typename: 'Additional',
						},
						{
							_id: additionalLabor6Wheels[0]._id,
							name: {
								...additionalLabor6Wheels[0].name_lang,
								__typename: 'AdditionalName',
							},
							price: additionalLabor6Wheels[0].price,
							qty: additionalLabor6Wheels[0].min,
							truck_type_size: {
								...additionalLabor6Wheels[0].truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: additionalLabor6Wheels[0].address,
							__typename: 'Additional',
						},
					];

					updateInstancePrice({
						variables: {
							additional: addAdditional,
						},
					});
				}
			} else if (data._id === '5d144613f13324149ba13545') {
				const additionalMoreLabor10Wheels = additionalList.filter(
					item => item._id === '5d14a06df13324149ba13568',
				);

				if (additionalMoreLabor10Wheels.length > 0) {
					const instancePriceData = this.props.instancePriceQuery;
					const addAdditional = [
						...instancePriceData.instancePrice.additional,
						{
							_id: data._id,
							name: {
								...data.name_lang,
								__typename: 'AdditionalName',
							},
							price: data.price,
							qty: data.min,
							truck_type_size: {
								...data.truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: data.address,
							__typename: 'Additional',
						},
						{
							_id: additionalMoreLabor10Wheels[0]._id,
							name: {
								...additionalMoreLabor10Wheels[0].name_lang,
								__typename: 'AdditionalName',
							},
							price: additionalMoreLabor10Wheels[0].price,
							qty: additionalMoreLabor10Wheels[0].min,
							truck_type_size: {
								...additionalMoreLabor10Wheels[0].truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: additionalMoreLabor10Wheels[0].address,
							__typename: 'Additional',
						},
					];

					updateInstancePrice({
						variables: {
							additional: addAdditional,
						},
					});
				}
			} else if (data._id === '5d14a06df13324149ba13568') {
				const additionalLabor10Wheels = additionalList.filter(
					item => item._id === '5d144613f13324149ba13545',
				);

				if (additionalLabor10Wheels.length > 0) {
					const instancePriceData = this.props.instancePriceQuery;
					const addAdditional = [
						...instancePriceData.instancePrice.additional,
						{
							_id: data._id,
							name: {
								...data.name_lang,
								__typename: 'AdditionalName',
							},
							price: data.price,
							qty: data.min,
							truck_type_size: {
								...data.truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: data.address,
							__typename: 'Additional',
						},
						{
							_id: additionalLabor10Wheels[0]._id,
							name: {
								...additionalLabor10Wheels[0].name_lang,
								__typename: 'AdditionalName',
							},
							price: additionalLabor10Wheels[0].price,
							qty: additionalLabor10Wheels[0].min,
							truck_type_size: {
								...additionalLabor10Wheels[0].truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: additionalLabor10Wheels[0].address,
							__typename: 'Additional',
						},
					];

					updateInstancePrice({
						variables: {
							additional: addAdditional,
						},
					});
				}
			} else if (data._id === '5ca46d2d910efac4f7704577') { //POD
				updateInstancePrice({
					variables: {
						additional: [
							...additional,
							{
								_id: data._id,
								name: {
									...data.name_lang,
									__typename: 'AdditionalName',
								},
								price: data.price,
								qty: data.min,
								truck_type_size: {
									...data.truck_type_size_lang,
									__typename: 'AdditionalTruckTypeSize',
								},
								address: this.state.addressPod,
								__typename: 'Additional',
							},
						],
					},
				});
			} else if (data._id === '5d5cf35766085815b00c2cf3') { //ผู้ช่วย 4 ล้อ
				const additionalMoreLabor4Wheels = additionalList.filter(
					item => item._id === '5d5cf2f566085815b00c27b6',
				);
				
				if (additionalMoreLabor4Wheels.length > 0) {
					const instancePriceData = this.props.instancePriceQuery;
					const addAdditional = [
						...instancePriceData.instancePrice.additional.filter(item => item._id !== '5d5cf35766085815b00c2cf3' && item._id !== '5d5cf2f566085815b00c27b6'),
						{
							_id: data._id,
							name: {
								...data.name_lang,
								__typename: 'AdditionalName',
							},
							price: data.price,
							qty: data.min,
							truck_type_size: {
								...data.truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: data.address,
							__typename: 'Additional',
						},
						{
							_id: additionalMoreLabor4Wheels[0]._id,
							name: {
								...additionalMoreLabor4Wheels[0].name_lang,
								__typename: 'AdditionalName',
							},
							price: additionalMoreLabor4Wheels[0].price,
							qty: additionalMoreLabor4Wheels[0].min,
							truck_type_size: {
								...additionalMoreLabor4Wheels[0].truck_type_size_lang,
								__typename: 'AdditionalTruckTypeSize',
							},
							address: additionalMoreLabor4Wheels[0].address,
							__typename: 'Additional',
						}
					];

					updateInstancePrice({
						variables: {
							additional: addAdditional,
						},
					});
				}
			} else {
				updateInstancePrice({
					variables: {
						additional: [
							...additional,
							{
								_id: data._id,
								name: {
									...data.name_lang,
									__typename: 'AdditionalName',
								},
								price: data.price,
								qty: data.min,
								truck_type_size: {
									...data.truck_type_size_lang,
									__typename: 'AdditionalTruckTypeSize',
								},
								address: null,
								__typename: 'Additional',
							},
						],
					},
				});
			}
		} else if (data._id === '5ca46d2d910efac4f7704577' && additionalFilter.length > 0 && isAccept === true) {
			const removeItemSelect = additional.filter(item => item._id !== data._id);
			const pod = [
				...removeItemSelect,
				{
					_id: data._id,
					name: {
						...data.name_lang,
						__typename: 'AdditionalName',
					},
					price: data.price,
					qty: data.min,
					truck_type_size: {
						...data.truck_type_size_lang,
						__typename: 'AdditionalTruckTypeSize',
					},
					address: this.state.addressPod,
					__typename: 'Additional',
				}
			];
			
			updateInstancePrice({
				variables: {
					additional: pod,
				},
			});
		} else {
			if(isAccept) {
				return;
			};

			const removeItemSelect = additional
				.filter(item => item._id !== data._id)
				.filter(item => {
					if(data._id === '5c38262bd4425c3d608c9061') { //คนขับช่วยยก 4 ล้อ
						return item._id !== '5d14a013f13324149ba13560'; //ผู้ช่วยเพิ่มเติม 4 ล้อ
					} else if(data._id === '5c77a4bbd3bb6bbabe8681e1') { //คนขับช่วยยก 6 ล้อ
						return item._id !== '5d14a03bf13324149ba13564'; //ผู้ช่วยเพิ่มเติม 6 ล้อ
					} else if(data._id === '5d144613f13324149ba13545') { //คนขับช่วยยก 10 ล้อ
						return item._id !== '5d14a06df13324149ba13568'; //ผู้ช่วยเพิ่มเติม 10 ล้อ
					} else if(data._id === '5d14a03bf13324149ba13564') { //ผู้ช่วยเพิ่มเติม 6 ล้อ
						return item._id !== '5c77a4bbd3bb6bbabe8681e1'; //คนขับช่วยยก 6 ล้อ
					} else if(data._id === '5d14a06df13324149ba13568') { //ผู้ช่วยเพิ่มเติม 10 ล้อ
						return item._id !== '5d144613f13324149ba13545'; //คนขับช่วยยก 10 ล้อ
					}

					return true;
				});

			if (data._id === 1) {
				this.setState({ onOpenInfo: false });
			}

			if (data._id === '5ca46d2d910efac4f7704577') {
				// POD
				this.setState({ addressPod: null });
			}

			updateInstancePrice({
				variables: {
					additional: removeItemSelect,
				},
			});
		}
	}

	checkSelected(id) {
		const { additional } = this.props.instancePriceQuery.instancePrice;
		const additionalFilter = additional.filter(item => item._id === id);

		if (additionalFilter.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	checkSelectedAccessory(accessoryItem) {
		const { accessory } = this.props.instancePriceQuery.instancePrice;
		const filterAccessory = accessory.filter(item => item._id === accessoryItem._id);
		return filterAccessory.length > 0;
	}

	onClickSelectedAccessory(accessoryItem) {
		const { accessory } = this.props.instancePriceQuery.instancePrice;
		const filterAccessory = accessory.filter(item => item._id === accessoryItem._id);
		
		let newAccessory = [];
		if(filterAccessory.length > 0) { //ถ้ามีใน client (เลือกไว้แล้ว) ให้ลบออก
			newAccessory = accessory.filter(item => item._id !== accessoryItem._id);
		} else { //ถ้ายังไม่มีให้เพิ่มเข้าไปใหม่
			newAccessory = [
				...accessory, 
				{ 
					_id: accessoryItem._id, 
					name: {
						...accessoryItem.name_lang,
						__typename: 'AccessoryName',
					}, 
					__typename: 'Accessory' 
				}
			];
		}
		
		const instancePriceData = client.readQuery({
			query: instancePriceQueryClient,
		});
		
		client.writeQuery({
			query: instancePriceQueryClient,
			data: {
				instancePrice: {
					...instancePriceData.instancePrice,
					accessory: newAccessory,
				}
			}
		});
	}

	onClickAdditional(item, updateInstancePrice) {
		this.setState({
			viewInfo: false,
		});

		if (item._id === '5c38262bd4425c3d608c9061' || item._id === '1') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoLabor: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d14a013f13324149ba13560') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoMoreLabor4Wheels: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5ce7cdb5f6539044d11b1e73') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenExtraLargeCar4WheelsStall: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5ce7cdd5f6539044d11b2002') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenExtraLargeCar4WheelsCabinet: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5c4fc4a01fcd14c699f2d956') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenExtraLargeCar6WheelsStall: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5c4fc4e31fcd14c699f2eff6') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenExtraLargeCar6WheelsCabinet: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5c77a4bbd3bb6bbabe8681e1') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoLabor6Wheels: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d14a03bf13324149ba13564') { //ผู้ช่วย 6 ล้อ
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoMoreLabor6Wheels: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d92d85366085815b0ba2804') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoMoreLabor6WheelsCrane: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d144613f13324149ba13545') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoLabor10Wheels: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d14a06df13324149ba13568') { //ผู้ช่วย 6 ล้อ
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoMoreLabor10Wheels: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5cbed9ab723da8228953b489') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoOverTimeTrailer: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5ca46d2d910efac4f7704577') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenPODAddress: true,
					addressPod: item.address,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.setState({ addressPod: null });
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if ( item._id === '5cada33a5e962c4d1510a669' ) {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenExtraLargeCar10WheelsStall: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if ( item._id === '5cada3005e962c4d151085a6' ) {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenExtraLargeCar10WheelsCabinet: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d5cf35766085815b00c2cf3') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoMoreLabor4WheelsBike: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5d5cf2f566085815b00c27b6') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoLabor4WheelsBike: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else if (item._id === '5e200f1cbf2cf7b8e72ab692') {
			const { additional } = this.props.instancePriceQuery.instancePrice;
			const additionalFilter = additional.filter(
				additional => additional._id === item._id,
			);

			if (additionalFilter.length === 0) {
				this.setState({
					onOpenInfoWoodenPallets: true,
					selectCurrentItem: item,
				});
			} else {
				// unselect
				this.onSelect(item, false, updateInstancePrice);
			}
		} else {
			// select not modal
			this.onSelect(item, false, updateInstancePrice);
		}
	}

	renderQty(item) {
		const { additional } = this.props.instancePriceQuery.instancePrice;
		const filter = additional.filter(data => data._id === item._id);
		if (filter.length === 1) {
			return filter[0].qty;
		}
		return item.min;
	}

	renderPrice(item) {
		const { additional } = this.props.instancePriceQuery.instancePrice;
		const additionalFilter = additional.filter(data => data._id === item._id);

		let qty = item.min;
		if (additionalFilter.length === 1) {
			qty = additionalFilter[0].qty;
		}

		return Number(item.price) * Number(qty);
	}

	checkDisabledAdditional(item) {
		const { promotion_name } = this.props.instancePriceQuery.instancePrice;
		const promotionName = promotion_name;

		// check addition labor and promotion true digital park
		if (
			((item._id === '5c38262bd4425c3d608c9061' ||
				item._id === '5c77a4bbd3bb6bbabe8681e1') &&
			(promotionName === 'TDP400' || promotionName === 'TDP6WH')) ||
			(item._id === '5d5cf2f566085815b00c27b6')
		) {
			return true;
		}

		return false;
	}

	onClickNumber(e, mode, item, updateInstancePrice) {
		e.stopPropagation();
		const { additional } = this.props.instancePriceQuery.instancePrice;
		const index = additional.findIndex(data => data._id === item._id);
		const ele = document.querySelectorAll(
			'.additionalItem[data-id="' + item._id + '"] input',
		);
		let qty = ele[0].value;

		if (mode === 'mis' && Number(qty) > item.min) {
			qty--;
		}
		if (mode === 'plus' && Number(qty) < item.limit) {
			qty++;
		}

		item.qty = qty;

		additional[index].qty = qty;

		if (item._id === 1) {
			this.setState({ onOpenInfo: false });
		}

		updateInstancePrice({
			variables: {
				additional: additional,
			},
		});
	}

	checkShowPodAddress(data) {
		const { additional } = this.props.instancePriceQuery.instancePrice;
		const additionalFilter = additional.filter(
			item => item._id === data._id,
		);

		return additionalFilter.length > 0 ? additionalFilter[0].address : '';
	}

	onClickInfoPOD(data) {
		const { additional } = this.props.instancePriceQuery.instancePrice;
		const additionalFilter = additional.filter(
			item => item._id === data._id,
		);

		this.setState({
			onOpenPODAddress: true,
			selectCurrentItem: data,
			addressPod: additionalFilter.length === 1 ? additionalFilter[0].address : null,
			viewInfo: true,
		});
	}

	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Mutation mutation={updateInstancePriceMutationClient}>
								{(updateInstancePrice, { error }) => {
									if (error) {
										return error;
									}
									return (
										<div>
											<Card
												content={
													<div>
														<CardHeader
															title={this.props.t('common:instancePrice.additional.title')}
															subTitle={this.props.t('common:instancePrice.additional.subtitle')}
															subColor="color_red"
															onPressClose={() =>
																this.closeTap(updateInstancePriceStep)
															}
															borderCardBottom
														/>

														<TruckListContainer>
															<Query
																query={additionalListQuery}
																variables={{
																	setting_truck_type_id: this.props.instancePriceQuery.instancePrice.setting_truck_type_id,
																}}
																fetchPolicy="network-only"
															>
																{({ loading, error, data }) => {
																	if (loading)
																		return (
																			<Skeleton width={220} height={170}>
																				<rect
																					x="0"
																					y="0"
																					rx="4"
																					ry="4"
																					width="143"
																					height="13"
																				/>
																				<rect
																					x="0"
																					y="24"
																					rx="3"
																					ry="3"
																					width="200"
																					height="10"
																				/>

																				<rect
																					x="0"
																					y="59"
																					rx="4"
																					ry="4"
																					width="143"
																					height="13"
																				/>
																				<rect
																					x="0"
																					y="83"
																					rx="3"
																					ry="3"
																					width="200"
																					height="10"
																				/>

																				<rect
																					x="0"
																					y="118"
																					rx="4"
																					ry="4"
																					width="143"
																					height="13"
																				/>
																				<rect
																					x="0"
																					y="142"
																					rx="3"
																					ry="3"
																					width="200"
																					height="10"
																				/>
																			</Skeleton>
																		);
																	if (error) return <div>{error.message}</div>;
																	
																	return (
																		<React.Fragment>
																			{data.additionalList.map((item, index, arr) => (
																				<AdditionalListItem
																					isInfo={
																						item._id ===
																							'5c38262bd4425c3d608c9061' || // แรงงาน
																						item._id ===
																							'5c77a4bbd3bb6bbabe8681e1' || // แรงงาน 6 ล้อ
																						item._id === '1' ||
																						item._id ===
																							'5ce7cdb5f6539044d11b1e73' || // 4 คอก
																						item._id ===
																							'5ce7cdd5f6539044d11b2002' || // 4 ตู้ทึบ
																						item._id ===
																							'5c4fc4a01fcd14c699f2d956' || // 6 คอก ขนาดใหญ่
																						item._id ===
																							'5c4fc4e31fcd14c699f2eff6' || // 6 ตู้ทึบ ขนาดใหญ่
																						item._id ===
																							'5cada3005e962c4d151085a6' || // 10 คอก ขนาดใหญ่
																						item._id ===
																							'5cada33a5e962c4d1510a669' || // 10 ตู้ทึบ ขนาดใหญ่
																						item._id ===
																							'5ca46d2d910efac4f7704577' || // POD
																						item._id ===
																							'5cbed9ab723da8228953b489' // ค้างคืน
																					}
																					showQty={
																						item.limit > 1
																					}
																					showPodAddress={this.checkShowPodAddress(
																						item,
																					)}
																					disabled={this.checkDisabledAdditional(
																						item,
																					)}
																					_id={item._id}
																					value={this.renderQty(item)}
																					min={item.min}
																					max={item.limit}
																					title={`${detectLanguageData(item.name_lang, this.props.t('common:lang'))}`}
																					subTitle={item._id === '5d5cf2f566085815b00c27b6' ? `- พนักงานขนขึ้นลงมอเตอร์ไซค์\n- อุปกรณ์สำหรับขนย้าย (บันไดเข็นรถ, เชือกมัดยึด)` : ''}
																					price={this.renderPrice(item)}
																					priceUnit={this.props.t('common:instancePrice.additional.baht')}
																					onClickNumber={(e, mode) =>
																						this.onClickNumber(
																							e,
																							mode,
																							item,
																							updateInstancePrice,
																						)
																					}
																					onClick={() =>
																						this.onClickAdditional(
																							item,
																							updateInstancePrice,
																						)
																					}
																					selected={this.checkSelected(item._id)}
																					onClickInfo={() => {
																						if (
																							item._id ===
																								'5c38262bd4425c3d608c9061' ||
																							item._id === '1'
																						) {
																							this.setState({
																								onOpenInfoLabor: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id === '5d14a013f13324149ba13560'
																						) {
																							this.setState({
																								onOpenInfoMoreLabor4Wheels: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5ce7cdb5f6539044d11b1e73'
																						) {
																							this.setState({
																								onOpenExtraLargeCar4WheelsStall: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5ce7cdd5f6539044d11b2002'
																						) {
																							this.setState({
																								onOpenExtraLargeCar4WheelsCabinet: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5e200f1cbf2cf7b8e72ab692'
																						) {
																							this.setState({
																								onOpenInfoWoodenPallets: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5c4fc4a01fcd14c699f2d956'
																						) {
																							this.setState({
																								onOpenExtraLargeCar6WheelsStall: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5c4fc4e31fcd14c699f2eff6'
																						) {
																							this.setState({
																								onOpenExtraLargeCar6WheelsCabinet: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5cbeddc4723da8228955c7e7'
																						) {
																							this.setState({
																								onOpenExtraLargeCar6WheelsLift: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5cada33a5e962c4d1510a669'
																						) {
																							this.setState({
																								onOpenExtraLargeCar10WheelsStall: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5cada3005e962c4d151085a6'
																						) {
																							this.setState({
																								onOpenExtraLargeCar10WheelsCabinet: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5cbede78723da82289561fe9'
																						) {
																							this.setState({
																								onOpenExtraLargeCar10WheelsLift: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5c77a4bbd3bb6bbabe8681e1'
																						) {
																							this.setState({
																								onOpenInfoLabor6Wheels: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5d14a03bf13324149ba13564'
																						) {
																							this.setState({
																								onOpenInfoMoreLabor6Wheels: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5d92d85366085815b0ba2804'
																						) {
																							this.setState({
																								onOpenInfoMoreLabor6WheelsCrane: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5d144613f13324149ba13545' //คนขับช่วยยก 10 ล้อ
																						) {
																							this.setState({
																								onOpenInfoLabor10Wheels: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5d14a06df13324149ba13568' //ผู้ช่วยเพิ่มเติม 10 ล้อ
																						) {
																							this.setState({
																								onOpenInfoMoreLabor10Wheels: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id ===
																							'5ca46d2d910efac4f7704577'
																						) {
																							this.onClickInfoPOD(item);
																						} else if (
																							item._id ===
																							'5cbed9ab723da8228953b489'
																						) {
																							this.setState({
																								onOpenInfoOverTimeTrailer: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id === '5d5cf2f566085815b00c27b6'
																						) {
																							this.setState({
																								onOpenInfoLabor4WheelsBike: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						} else if (
																							item._id === '5d5cf35766085815b00c2cf3'
																						) {
																							this.setState({
																								onOpenInfoMoreLabor4WheelsBike: true,
																								selectCurrentItem: item,
																								viewInfo: true,
																							});
																						}
																					}}
																				/>
																			))}

																			{
																				(data.accessoryList.length > 0 && this.props.instancePriceQuery.instancePrice.shipmentType === 1) && (
																					<AccessoryContainer>
																						<Collapse bordered={false}>
																							<Panel header={
																								<>
																									<div className="accessory-title">{this.props.t('common:instancePrice.additional.info.titleSafety')}</div>
																									<div className="accessory-sub-title">{this.props.t('common:instancePrice.additional.free')}</div>
																								</>																	
																							}>
																								{data.accessoryList.map((item, index, arr) => (
																									<AccessoryListItemStylePanel
																										key={index}
																										name={detectLanguageData(item.name_lang, this.props.t('common:lang'))}
																										selected={this.checkSelectedAccessory(item)}
																										onPressSelected={() => this.onClickSelectedAccessory(item)}
																									/>
																								))}
																							</Panel>
																						</Collapse>
																					</AccessoryContainer>
																				)
																			}

																			{/* 4 ล้อ คนขับช่วยยก */}
																			<ModalConfirm
																				id="InfoLabor4Wheel"
																				show={this.state.onOpenInfoLabor}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleLabour')}
																				onHide={() => this.setState({ onOpenInfoLabor: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoLabor: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoLabor: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div>
																					<InfoLabor />
																				</div>
																			</ModalConfirm>

																			{/* 4 ล้อ ผู้ช่วยเพิ่มเติม */}
																			<ModalConfirm
																				id="InfoMoreLabor4Wheel"
																				show={this.state.onOpenInfoMoreLabor4Wheels}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleAssistants')}
																				onHide={() => this.setState({ onOpenInfoMoreLabor4Wheels: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoMoreLabor4Wheels: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoMoreLabor4Wheels: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div style={{lineHeight: 1}}>
																					{this.props.t('common:instancePrice.additional.info.assistants4Wheel')}
																				</div>
																			</ModalConfirm>

																			{/* 4 ล้อ Bike คนขับช่วยยก */}
																			<ModalConfirm
																				show={this.state.onOpenInfoLabor4WheelsBike}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={`เงื่อนไขบริการขนส่งมอเตอร์ไซค์ขนาดใหญ่`}
																				onHide={() => this.setState({ onOpenInfoLabor4WheelsBike: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoLabor4WheelsBike: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoLabor4WheelsBike: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div style={{lineHeight: 1.2}}>
																					<div style={{fontWeight: 'bold'}}>เงื่อนไขการใช้บริการ</div>
																					<div>
																						1. จำกัดน้ำหนักรถไม่เกิน 300 กิโลกรัม ต่อเที่ยว สูงสุด 2 คัน<br/>
																						2. ในการขนส่งมอเตอร์ไซค์ลูกค้าจำเป็นต้องมีสำเนาเอกสารรถทุกคัน และมอบให้กับพนักงานขนส่ง ณ จุดรับรถเท่านั้น<br/>
																						3. ขนส่งเฉพาะภายในประเทศเท่านั้น<br/>
																						4. ค่าขนส่งดังกล่าวรวมค่าแรงและอุปกรณ์เสริมแล้ว<br/>
																						5. ประกันการขนส่งสูงสุด 20,000 บาท* 
																					</div>
																					<br/>
																					<div style={{fontWeight: 'bold'}}>เอกสารประกอบการขนส่ง</div>
																					<div>
																						1. สำเนาเล่มทะเบียนรถมอเตอร์ไซค์ (‪‎เล่มสีเขียว)‬<br/>
																						2. สำเนาบัตรประชาชนเจ้าของรถ<br/>
																						3. สำเนาบัตรประชนคนรับรถ (ถ้ามี)
																					</div>
																					<br/>
																					<div style={{fontWeight: 'bold'}}>การปฏิเสธความรับผิดชอบ‬</div>
																					<div>
																						1. เราขอปฏิเสธความรับผิดชอบทุกกรณีหาก รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ ที่ท่านนำมาส่งกับเรา เป็นรถที่ได้ มาโดยผิดกฏหมาย ไม่ว่าจะเป็นรถขโมยมา รถสวมทะเบียนปลอม รถหนีภาษี และ อื่นๆ<br/>
																						2. ในวันส่งมอบ รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ ลูกค้าต้องตรวจสอบความเรียบร้อยของตัว รถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ หากเกิดความเสียหายให้แจ้งกับพนักงานขนส่งทันที ก่อนเซ็นรับรถ "หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขนส่ง ของ Giztix ทราบโดยทันที Giztix ขอปฏิเสธความรับผิดชอบทุกกรณี และ ลูกค้าจะไม่สามารถเรียกร้องสิทธิ ค่าชดเชยใดๆทั้งสิ้น"<br/>
																						3. หากตกลงใช้บริการ ส่งรถมอเตอร์ไซค์ หรือ รถบิ๊กไบค์ กับ Giztix ถือว่าท่านได้อ่านเงื่อนไขต่างๆ ครบถ้วนทุกอย่างแล้ว และ ลูกค้าจะเรียกร้องสิทธิภายหลังไม่ได้
																					</div>
																				</div>

																				<div style={{padding: 20, margin: '10px 0px', backgroundColor: '#F9F9F9', borderRadius: 6, lineHeight: 1, textAlign: 'center'}}>
																					<div style={{color: '#D90101'}}>**ข้อควรระวัง**</div>
																					<div>
																						"หากเกิดความเสียหายแล้วไม่แจ้งให้พนักงานขับรถ หรือติดต่อ Giztix โดยทันที ก่อนเซ็นรับมอบ<br/>
																						เราจะถือว่าลูกค้าได้รับรถในสภาพสมบูรณ์เรียบร้อยตามสภาพรถเดิม<br/>
																						และลูกค้าจะไม่สามารถเรียกร้องสิทธิค่าชดเชยภายหลังได้”
																					</div>
																				</div>
																			</ModalConfirm>

																			{/* 4 ล้อ Bike ผู้ช่วยเพิ่มเติม */}
																			<ModalConfirm
																				show={this.state.onOpenInfoMoreLabor4WheelsBike}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={`ผู้ช่วยเพิ่มเติม`}
																				onHide={() => this.setState({ onOpenInfoMoreLabor4WheelsBike: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoMoreLabor4WheelsBike: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoMoreLabor4WheelsBike: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div style={{lineHeight: 1}}>
																					{this.props.t('common:instancePrice.additional.info.assistants4Wheel')}
																				</div>
																			</ModalConfirm>

																			{/* 6 ล้อ คนขับช่วยยก */}
																			<ModalConfirm
																				id="InfoLabor6Wheel"
																				show={this.state.onOpenInfoLabor6Wheels}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleLabour')}
																				onHide={() => this.setState({ onOpenInfoLabor6Wheels: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoLabor6Wheels: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoLabor6Wheels: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div>
																					<InfoLabor6Wheel />
																				</div>
																			</ModalConfirm>

																			{/* 6 ล้อ ผู้ช่วยเพิ่มเติม */}
																			<ModalConfirm
																				id="InfoMoreLabor6Wheel"
																				show={this.state.onOpenInfoMoreLabor6Wheels}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleAssistants')}
																				onHide={() => this.setState({ onOpenInfoMoreLabor6Wheels: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoMoreLabor6Wheels: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoMoreLabor6Wheels: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div style={{lineHeight: 1}}>
																					{this.props.t('common:instancePrice.additional.info.assistants6Wheel')}
																				</div>
																			</ModalConfirm>

																			{/* 6 ล้อเครน ผู้ช่วยเพิ่มเติม */}
																			<ModalConfirm
																				id="InfoMoreLabor6WheelCrane"
																				show={this.state.onOpenInfoMoreLabor6WheelsCrane}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleAssistants')}
																				onHide={() => this.setState({ onOpenInfoMoreLabor6WheelsCrane: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoMoreLabor6WheelsCrane: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoMoreLabor6WheelsCrane: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div style={{lineHeight: 1}}>
																					{ 
																						this.props.t('common:instancePrice.additional.info.labour6WheelCrane')
																						.split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
																					}
																				</div>
																			</ModalConfirm>

																			{/* 10 ล้อ คนขับช่วยยก */}
																			<ModalConfirm
																				id="InfoLabor10Wheel"
																				show={this.state.onOpenInfoLabor10Wheels}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleLabour')}
																				onHide={() => this.setState({ onOpenInfoLabor10Wheels: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoLabor10Wheels: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoLabor10Wheels: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div>
																					<InfoLabor10Wheel />
																				</div>
																			</ModalConfirm>

																			{/* 10 ล้อ ผู้ช่วยเพิ่มเติม */}
																			<ModalConfirm
																				id="InfoMoreLabor10Wheel"
																				show={this.state.onOpenInfoMoreLabor10Wheels}
																				labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
																				styleColorButtonConfirm="primary"
																				labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
																				titleHeader={this.props.t('common:instancePrice.additional.info.titleAssistants')}
																				onHide={() => this.setState({ onOpenInfoMoreLabor10Wheels: false })}
																				onPressButtonCancel={() => this.setState({ onOpenInfoMoreLabor10Wheels: false })}
																				onPressButtonConfirm={() => {
																					this.setState(
																						{ onOpenInfoMoreLabor10Wheels: false },
																						this.onSelect(
																							this.state.selectCurrentItem,
																							true,
																							updateInstancePrice,
																							data.additionalList
																						),
																					)
																				}}
																			>
																				<div style={{lineHeight: 1}}>
																					{this.props.t('common:instancePrice.additional.info.assistants10Wheel')}
																				</div>
																			</ModalConfirm>
																		</React.Fragment>
																	)
																}}
															</Query>
														</TruckListContainer>

														{
															(this.props.instancePriceQuery.instancePrice.shipmentType === 2 ||
															this.props.instancePriceQuery.instancePrice.shipmentType === 3) && (
																<div>
																	{
																		// <CardHeader
																		// 	style={{marginTop: 40}}
																		// 	title={this.props.t('common:instancePrice.additional.accessory.title')}
																		// 	borderCardBottom
																		// 	borderCardTop
																		// 	subTitle={this.props.t('common:instancePrice.additional.accessory.subTitle')}
																		// />
																	}

																	<Query
																		query={accessoryListQuery}
																		variables={{
																			setting_truck_type_id: this.props.instancePriceQuery.instancePrice.setting_truck_type_id,
																		}}
																		fetchPolicy="network-only"
																	>
																		{({ loading, error, data }) => {
																			if (loading)
																				return (
																					<Skeleton width={220} height={170} style={{marginTop: 10}}>
																						<rect
																							x="0"
																							y="0"
																							rx="4"
																							ry="4"
																							width="143"
																							height="13"
																						/>
																						<rect
																							x="0"
																							y="24"
																							rx="3"
																							ry="3"
																							width="200"
																							height="10"
																						/>

																						<rect
																							x="0"
																							y="59"
																							rx="4"
																							ry="4"
																							width="143"
																							height="13"
																						/>
																						<rect
																							x="0"
																							y="83"
																							rx="3"
																							ry="3"
																							width="200"
																							height="10"
																						/>

																						<rect
																							x="0"
																							y="118"
																							rx="4"
																							ry="4"
																							width="143"
																							height="13"
																						/>
																						<rect
																							x="0"
																							y="142"
																							rx="3"
																							ry="3"
																							width="200"
																							height="10"
																						/>
																					</Skeleton>
																				);
																			if (error) return <div>{error.message}</div>;

																			// eslint-disable-next-line no-lone-blocks
																			{
																				return <AccessoryContainer>
																							<Collapse bordered={false}>
																								<Panel header={
																									<>
																										<div className="accessory-title">{this.props.t('common:instancePrice.additional.info.titleSafety')}</div>
																										<div className="accessory-sub-title">{this.props.t('common:instancePrice.additional.free')}</div>
																									</>																	
																								}>
																									{data.accessoryList.map(
																										(item, index, arr) => (
																											<AccessoryListItemStylePanel
																												key={index}
																												name={detectLanguageData(item.name_lang, this.props.t('common:lang'))}
																												selected={this.checkSelectedAccessory(item)}
																												onPressSelected={() => this.onClickSelectedAccessory(item)}
																											/>
																										),
																									)}
																								</Panel>
																							</Collapse>
																						</AccessoryContainer>
																		}
																			
																			// return data.accessoryList.map(
																			// 	(item, index, arr) => (
																			// 		<AccessoryListItemStyle
																			// 			key={index}
																			// 			name={detectLanguageData(item.name_lang, this.props.t('common:lang'))}
																			// 			selected={this.checkSelectedAccessory(item)}
																			// 			onPressSelected={() => this.onClickSelectedAccessory(item)}
																			// 		/>
																			// 	),
																			// );

																		}}
																	</Query>
																</div>
															)
														}
													</div>
												}
											/>

											{/* Modal */}
											<ModalConfirm
												id="POD"
												show={this.state.onOpenPODAddress}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.modalPOD.title')}
												onPressButtonCancel={() => this.setState({ onOpenPODAddress: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{
															onOpenPODAddress: false,
															addressPod: this.state.addressPod,
														},
														() => {
															this.onSelect(
																this.state.selectCurrentItem,
																true,
																updateInstancePrice,
															);
														},
													)
												}}
												disabledButtonConfirm={!this.state.addressPod}
											>
												<div>
													<PODAddress />
													<FormGroup>
														<ControlLabel style={{ fontWeight: 'bold' }}>
															{this.props.t('common:instancePrice.additional.modalPOD.input.label')}
														</ControlLabel>
														<FormControl
															componentClass="textarea"
															onChange={event =>
																this.setState({
																	addressPod: event.target.value,
																})
															}
															value={this.state.addressPod}
															style={{ marginTop: 10 }}
															placeholder={this.props.t('common:instancePrice.additional.modalPOD.input.placeholder')}
															required
														/>
													</FormGroup>
												</div>
											</ModalConfirm>

											{/* 4 ล้อ */}
											<ModalConfirm
												id="ExtraLargeCar4WheelsCabinet"
												show={this.state.onOpenExtraLargeCar4WheelsCabinet}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleSingleCab')}
												onHide={() => this.setState({ onOpenExtraLargeCar4WheelsCabinet: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar4WheelsCabinet: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar4WheelsCabinet: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{this.props.t('common:instancePrice.additional.info.singleCab4WheelsCabinet').split('\n').map(item => (<div>{item}</div>))}
												</div>
											</ModalConfirm>

											<ModalConfirm
												id="ExtraLargeCar4WheelsStall"
												show={this.state.onOpenExtraLargeCar4WheelsStall}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleSingleCab')}
												onHide={() => this.setState({ onOpenExtraLargeCar4WheelsStall: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar4WheelsStall: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar4WheelsStall: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{this.props.t('common:instancePrice.additional.info.singleCab4WheelsStall').split('\n').map(item => (<div>{item}</div>))}
												</div>
											</ModalConfirm>

											<ModalConfirm
												id="ExtraLargeCar6WheelsCabinet"
												show={this.state.onOpenExtraLargeCar6WheelsCabinet}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleExtraSizeTruck')}
												onHide={() => this.setState({ onOpenExtraLargeCar6WheelsCabinet: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar6WheelsCabinet: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar6WheelsCabinet: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													<ExtraLargeCar6WheelsCabinet />
												</div>
											</ModalConfirm>

											<ModalConfirm
												id="ExtraLargeCar6WheelsStall"
												show={this.state.onOpenExtraLargeCar6WheelsStall}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleExtraSizeTruck')}
												onHide={() => this.setState({ onOpenExtraLargeCar6WheelsStall: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar6WheelsStall: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar6WheelsStall: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													<ExtraLargeCar6WheelsStall />
												</div>
											</ModalConfirm>

											<ModalConfirm
												id="ExtraLargeCar6WheelsLift"
												show={this.state.onOpenExtraLargeCar6WheelsLift}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleExtraSizeTruck')}
												onHide={() => this.setState({ onOpenExtraLargeCar6WheelsLift: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar6WheelsLift: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar6WheelsLift: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{this.props.t('common:instancePrice.additional.info.extraLargeTruck6WheelsLift').split('\n').map(item => (<div>{item}</div>))}
												</div>
											</ModalConfirm>

											{/* 10 ล้อ */}
											<ModalConfirm
												id="ExtraLargeCar10WheelsCabinet"
												show={this.state.onOpenExtraLargeCar10WheelsCabinet}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleExtraSizeTruck')}
												onHide={() => this.setState({ onOpenExtraLargeCar10WheelsCabinet: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar10WheelsCabinet: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar10WheelsCabinet: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{this.props.t('common:instancePrice.additional.info.extraLargeTruck10WheelCabinet').split('\n').map(item => (<div>{item}</div>))}
												</div>
											</ModalConfirm>

											<ModalConfirm
												id="ExtraLargeCar10WheelsStall"
												show={this.state.onOpenExtraLargeCar10WheelsStall}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleExtraSizeTruck')}
												onHide={() => this.setState({ onOpenExtraLargeCar10WheelsStall: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar10WheelsStall: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar10WheelsStall: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{this.props.t('common:instancePrice.additional.info.extraLargeTruck10WheelStall').split('\n').map(item => (<div>{item}</div>))}
												</div>
											</ModalConfirm>

											<ModalConfirm
												id="ExtraLargeCar10WheelsLift"
												show={this.state.onOpenExtraLargeCar10WheelsLift}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleExtraSizeTruck')}
												onHide={() => this.setState({ onOpenExtraLargeCar10WheelsLift: false })}
												onPressButtonCancel={() => this.setState({ onOpenExtraLargeCar10WheelsLift: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenExtraLargeCar10WheelsLift: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{ this.props.t('common:instancePrice.additional.info.extraLargeTruck10WheelsLift').split('\n').map(item => (<div>{item}</div>)) }
												</div>
											</ModalConfirm>

											{/* Trailer */}
											<ModalConfirm
												id="InfoLaborTrailer"
												show={this.state.onOpenInfoOverTimeTrailer}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleOverNight')}
												onHide={() => this.setState({ onOpenInfoOverTimeTrailer: false })}
												onPressButtonCancel={() => this.setState({ onOpenInfoOverTimeTrailer: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenInfoOverTimeTrailer: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div>
													<InfoOverTimeTrailer />
												</div>
											</ModalConfirm>

											{/* Wooden Pallets */}
											<ModalConfirm
												id="InfoWoodenPallets"
												show={this.state.onOpenInfoWoodenPallets}
												labelButtonConfirm={this.props.t('common:instancePrice.additional.info.button.agree')}
												styleColorButtonConfirm="primary"
												labelButtonCancel={this.props.t('common:instancePrice.additional.info.button.close')}
												titleHeader={this.props.t('common:instancePrice.additional.info.titleWoodenPallets')}
												onHide={() => this.setState({ onOpenInfoWoodenPallets: false })}
												onPressButtonCancel={() => this.setState({ onOpenInfoWoodenPallets: false })}
												onPressButtonConfirm={() => {
													this.setState(
														{ onOpenInfoWoodenPallets: false },
														this.onSelect(
															this.state.selectCurrentItem,
															true,
															updateInstancePrice,
														),
													)
												}}
											>
												<div style={{lineHeight: 1}}>
													{ this.props.t('common:instancePrice.additional.info.woodenPallets').split('\n').map(item => (<div>{item}</div>)) }
												</div>
											</ModalConfirm>
										</div>
									);
								}}
							</Mutation>
						);
					}}
				</Mutation>
			</Container>
		);
	}
}

export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery'
	}),
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery'
	}),
	)(withNamespaces()(Additional));
