import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Header } from './../../../../components';
import { Card, BoxServiceItem } from 'src/components';
import { Row } from 'react-bootstrap';
import { Query } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import detectLanguageData from './../../../../function/detectLanguageData';
import moment from 'moment';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';

class ShipmentDetail extends React.Component {
	renderAdditional(item) {
		let text = '';
		for (let i = 0; i < item.length; i++) {
			if (
				item[i]._id === '5c4fc4a01fcd14c699f2d956' ||
				item[i]._id === '5ca46d2d910efac4f7704577' ||
				item[i]._id === '5cada33a5e962c4d1510a669' ||
				item[i]._id === '5cada3005e962c4d151085a6' ||
				item[i]._id === '5c4fc4e31fcd14c699f2eff6' ||
				item[i]._id === '5cada3005e962c4d151085a6' ||
				item[i]._id === '5cbeddc4723da8228955c7e7' ||
				item[i]._id === '5cbede78723da82289561fe9' ||
				item[i]._id === '5ce7cdd5f6539044d11b2002' ||
				item[i]._id === '5ce7cdb5f6539044d11b1e73'
			) {
				text += `${detectLanguageData(item[i].name, this.props.t('common:lang'))}`;
			} else {
				text += `${detectLanguageData(item[i].name, this.props.t('common:lang'))} x ${item[i].qty}`;
			}

			if (i < item.length - 1) {
				text += ', ';
			}
		}

		return text;
	}

	renderAddressPOD(item) {
		let text = '';
		for (let i = 0; i < item.length; i++) {
			if (item[i]._id === '5ca46d2d910efac4f7704577') {
				text = `${item[i].address}`;
			}
		}
		return text;
	}

	renderAccessory(accessoryList) {
		let text = '';
		accessoryList.forEach((item, index, array) => {
			text += detectLanguageData(item.name, this.props.t('common:lang'));

			if (index < array.length - 1) {
				text += ', ';
			}
		});

		return text;
	}

	render() {
		return (
			<React.Fragment>
				<Query query={instancePriceStepQueryClient}>
					{instancePriceStepData => {
						return (
							<Query query={instancePriceQueryClient}>
								{instancePriceData => {
									return (
										<Card
											content={
												<div>
													<Header
														title={this.props.t(
															'common:instancePrice.shipmentConfirm.serviceDetails'
														)}
													/>
													<Row className="serviceWrapper">
														<BoxServiceItem
															icon={`${
																instancePriceData.data.instancePrice.shipmentType === 1
																	? 'fal fa-home'
																	: instancePriceData.data.instancePrice.shipmentType === 2
																	? 'fal fa-sign-in-alt'
																	: instancePriceData.data.instancePrice.shipmentType === 3
																	? 'fal fa-sign-out-alt'
																	: ''
															}`}
															title={`${this.props.t(
																'common:instancePrice.shipmentConfirm.shipmentType'
															)} :`}
															subTitle={`${
																instancePriceData.data.instancePrice.shipmentType === 1
																	? this.props.t('common:instancePrice.shipmentConfirm.domestic')
																	: instancePriceData.data.instancePrice.shipmentType === 2
																	? this.props.t('common:instancePrice.shipmentConfirm.import')
																	: instancePriceData.data.instancePrice.shipmentType === 3
																	? this.props.t('common:instancePrice.shipmentConfirm.export')
																	: ''
															}`}
														/>
														<BoxServiceItem
															icon="fal fa-truck"
															title={`${this.props.t(
																'common:instancePrice.shipmentConfirm.service'
															)} :`}
															subTitle={`${
																this.props.t('common:lang') === 'th' ? 'รถขนส่ง ' : ''
															}${detectLanguageData(
																instancePriceData.data.instancePrice.setting_truck_name,
																this.props.t('common:lang')
															)} - ${detectLanguageData(
																instancePriceData.data.instancePrice.setting_truck_type_name,
																this.props.t('common:lang')
															)}`}
														/>
														<BoxServiceItem
															icon="fal fa-box"
															title={`${this.props.t(
																'common:instancePrice.shipmentConfirm.additionalService'
															)} :`}
															subTitle={this.renderAdditional(
																instancePriceData.data.instancePrice.additional
															)}
														/>

														{this.renderAddressPOD(
															instancePriceData.data.instancePrice.additional
														) && (
															<BoxServiceItem
																title={`${this.props.t(
																	'common:instancePrice.shipmentConfirm.proofOfDeliveryAddress'
																)} :`}
																subTitle={this.renderAddressPOD(
																	instancePriceData.data.instancePrice.additional
																)}
															/>
														)}

														{instancePriceData.data.instancePrice.accessory.length > 0 && (
															<BoxServiceItem
																title={`${this.props.t(
																	'common:instancePrice.shipmentConfirm.accessory'
																)} :`}
																subTitle={this.renderAccessory(
																	instancePriceData.data.instancePrice.accessory
																)}
															/>
														)}

														{instancePriceData.data.instancePrice.shipmentType === 1 && (
															<BoxServiceItem
																icon="fal fa-clock"
																title={`${this.props.t(
																	'common:instancePrice.shipmentConfirm.pickupDate'
																)} :`}
																selected={
																	instancePriceStepData.data.instancePriceStep.selectDateTime
																}
																subTitle={
																	instancePriceData.data.instancePrice.pickupDate
																		? moment(
																				instancePriceData.data.instancePrice.pickupDate
																		  ).format('DD/MM/YYYY HH:mm')
																		: '-'
																}
															/>
														)}
													</Row>
												</div>
											}
										/>
									);
								}}
							</Query>
						);
					}}
				</Query>
			</React.Fragment>
		);
	}
}

export default withNamespaces()(ShipmentDetail);
