import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

const Container = styled.div`
  margin: 15px 0px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  line-height: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const TextTitle = styled.div`
  font-size: 20px;
  flex: 1;
  font-weight: bold;
`;

const CheckBoxContainer = styled.div`
  font-size: 22px;

  & i {
    padding-top: 2px;
    color: ${props => props.selected ? '#19aa19;' : '#e3e3e3'};
  }
`;

const DriverItem = (props) => {
  return (
    <Container {...props} onClick={props.onPressSelected}>
      <Avatar 
        size={50} 
        icon="user"
        style={{marginRight: 10}}
        src={props.src}
      />
      <TextTitle>{props.name}</TextTitle>
      <CheckBoxContainer selected={props.selected}>
        <i className={`${props.selected ? 'fas fa-check-circle' : 'fal fa-circle'}`}></i>
      </CheckBoxContainer>
    </Container>
  )
}

DriverItem.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.bool,
  onPressSelected: PropTypes.func,
  image: PropTypes.string,
};

DriverItem.defaultProps = {
  name: '',
  selected: false,
  image: '',
};

export default DriverItem;