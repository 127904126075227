import gql from 'graphql-tag';

export default gql`
	mutation AccountContactAddressDelete($contactId: ID!, $addressId: ID!) {
		accountContactAddressDelete(
			_id: $addressId
			account_contact_id: $contactId
		) {
			succeed
		}
	}
`;
