import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from "react-i18next";

const Container = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

	.icon {
		font-size: 22px;

		& .iconStyle {
			font-size: 22px;
			position: relative;
			left: 0px;
			top: 0px;

			& img {
				width: 40px;
			}
		}
	}

	.detail {
		flex: 1;
		margin-left: 15px;

		&-title {
			font-size: 20px;
			font-weight: bold;
			line-height: 1;
		}

		&-sub-title {
			font-size: 18px;
			line-height: 1;
		}
	}

	.arrow {
		color: #b3b3b3;
		font-size: 20px;
		line-height: 1;

    ${ props => props.isSelected && `color: #D90101;` }
  }

  ${ props => props.isSelected && `color: #D90101;` }
`;

class PaymentChannelSelect extends React.Component {
	render() {
		return (
			<Container {...this.props} isisSelected={this.props.isSelected}>
				<div className="icon">
				{this.props.CreditCardIcon ? (
             <div className="iconStyle" style={{color:this.props.LeftIconColor}}>{this.props.CreditCardIcon}</div>
        ) :  this.props.LeftCreditIcon ? (
            <div className="iconStyle" style={{color:this.props.LeftIconColor}}>{this.props.LeftCreditIcon}</div>
        ) :this.props.LeftIcon ? (
            <div className="iconStyle" style={{color:this.props.LeftIconColor}}><i className={this.props.LeftIcon}></i></div>
        ) : null} 
				</div>
				<div className="detail">
					<div className="detail-title">{ this.props.t('common:instancePrice.stepServicePaymentDetails.paymentMethods.title') }</div>
					<div className="detail-sub-title">{ this.props.subTitle }</div>
				</div>
				<div className="arrow">
          {
            this.props.isSelected ? (
              <i className="fal fa-chevron-left" />
            ) : (
              <i className="fal fa-chevron-right" />
            )
          }
				</div>
			</Container>
		);
	}
}

PaymentChannelSelect.propTypes = {
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  isSelected: PropTypes.bool,
};

PaymentChannelSelect.defaultProps = {
	subTitle: '',
	icon: '',
  isSelected: false,
};

export default withNamespaces()(PaymentChannelSelect);
