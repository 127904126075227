import React from 'react';
import styled from 'styled-components';
import { SubHeader, Card, Menu } from '../../components';
import Helmet from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { HeaderNav } from 'src/app/components';
import client from 'src/config/client';
import { Tabs } from 'antd';
import accountInfoListQuery from './graphql/query/accountInfoList';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import TableReport from './container/TableReport';
import TableWaitDownloadReport from './container/TableWaitDownloadReport';
import { withRouter } from 'react-router-dom';

const BackgroundContainer = styled.div`
	background-color: #f9f9f9;
	height: calc(100vh);
	overflow-y: auto;
`;

const Container = styled.div`
	padding-top: calc(150px + ${heightTopHeader}px);
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 60px;

	@media (max-width: 767px) {
		padding-top: 20px;
	}
`;

const TextHeader = styled.div`
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	position: relative;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
	margin-bottom: 20px;
`;

const Loading = styled.div`
	display: flex;
	align-items: center;
	color: #939393;
	padding-left: 10px;
	height: 34px;
`;

const TabsContainer = styled.div`
	.ant-tabs-bar {
		margin-bottom: 0;
	}

	.ant-tabs-nav .ant-tabs-tab-active {
		color: #000000;
		font-weight: bold;
		font-size: 20px;
	}

	.ant-tabs-nav .ant-tabs-tab:hover {
		color: #000000;
	}

	.ant-tabs-ink-bar {
		background-color: #d90101;
	}
`;

class Reports extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			isLoadingFetchDataAccountInfo: false,
			accountInfoId: '',
			selectedTabs: '0',
		};
	}

	getParamURL() {
		const param = new URLSearchParams(this.props.location.search);
		if (param.get('tab')) {
			this.setState({
				selectedTabs: param.get('tab'),
			});
		}
	}

	componentDidMount() {
		this.setState({ isLoadingFetchDataAccountInfo: true });
		this.fetchAccountInfoList();
		this.getParamURL();
	}

	async fetchAccountInfoList() {
		try {
			const { data } = await client.query({
				query: accountInfoListQuery,
				fetchPolicy: 'network-only',
			});
			const { account_info } = data.accountInfoList;
			this.setState({
				accountInfoId: account_info[0]._id,
			});
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({
				isLoadingFetchDataAccountInfo: false,
			});
		}
	}

	changeTab = key => {
		this.setState({ selectedTabs: key });
		this.props.history.push({
			search: `?tab=${key}`,
			pathname: '/reports',
		});
	};

	render() {
		return (
			<div>
				<Helmet>
					<title>
						Report | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
						รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav full={true} />
				<SubHeader backLink="/" />
				<BackgroundContainer>
					<Container>
						<Row>
							<Col lg={2} md={3} sm={12}>
								<Menu />
							</Col>
							<Col lg={10} md={9} sm={12}>
								<Card>
									<React.Fragment>
										<TextHeader>ตารางรายงาน</TextHeader>
										<TextSubHeader>
											สามารถดาวน์โหลดรายงานทั้งหมดได้จากหน้านี้
										</TextSubHeader>
										{this.state.isLoadingFetchDataAccountInfo && (
											<Loading
												style={{ display: 'flex', justifyContent: 'center' }}
											>
												<i className="fal fa-spinner-third fa-spin" />
											</Loading>
										)}

										{!this.state.isLoadingFetchDataAccountInfo && (
											<div style={{ overflowX: 'auto' }}>
												<TabsContainer>
													<Tabs
														onChange={key => this.changeTab(key)}
														animated={false}
														activeKey={this.state.selectedTabs}
														destroyInactiveTabPane
													>
														<Tabs.TabPane
															tab="หน้ารวมรายงาน"
															key="0"
															style={{ overflowX: 'auto' }}
														>
															<TableReport
																accountInfoId={this.state.accountInfoId}
																onComplete={() => {
																	this.props.history.push('/reports?tab=1');
																	this.setState({ selectedTabs: '1' });
																}}
															/>
														</Tabs.TabPane>

														<Tabs.TabPane
															tab="รายการรอดาวน์โหลด"
															key="1"
															style={{ overflowX: 'auto' }}
														>
															<TableWaitDownloadReport />
														</Tabs.TabPane>
													</Tabs>
												</TabsContainer>
											</div>
										)}
									</React.Fragment>
								</Card>
							</Col>
						</Row>
					</Container>
				</BackgroundContainer>
			</div>
		);
	}
}

export default withNamespaces()(withRouter(Reports));
