import React, { Component } from 'react';
import styled from 'styled-components';
import { Card, CardHeader } from 'src/components';
import { graphql, compose } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import client from 'src/config/client';
import { RemarkList } from './components';
import _ from 'lodash';
import { withNamespaces } from "react-i18next";

const Container = styled.div`
  width: 100%;
`;
class SelectRemark extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	closePopup() {
		client.writeQuery({
			query: instancePriceStepQueryClient,
			data: {
				instancePriceStep: {
					...this.props.instancePriceStepQuery.instancePriceStep,
					selectRemark: false,
				}
			}
		});
  }
  
  checkSelected(remarkItem) {
    const { remark } = this.props.instancePriceQuery.instancePrice;
    const arrRemark = remark.split(", ");    
    return _.includes(arrRemark, remarkItem.name);
  }
  
  onClickSelected(remarkItem, remarkList, isActive) {
    const { remark } = this.props.instancePriceQuery.instancePrice;
    const arrRemark = remark.split(", "); //นำค่าจาก client แปลงเป็น array
    const newRemarkList = remarkList.filter(item => {
      if(!isActive) { //ถ้ายังไม่ถูกเลือก ให้นำสิ่งที่เลือกเพิ่มเข้าไป
        //ตรวจสอบว่า remarkList มีค่าอะไรบ้างที่เหมือนกับ client และค่าที่เลือก (ที่ยังไม่มีใน client) ให้ถูกเพิ่มเข้าไป
        return _.includes(arrRemark, item.name) || remarkItem.name === item.name;
      }

      //ถ้าถูกเลือกแล้ว ให้นำสิ่งที่เลือกออก
      return _.includes(arrRemark, item.name) && remarkItem.name !== item.name;
    });

    client.writeQuery({
      query: instancePriceQueryClient,
      data: {
        instancePrice: {
          ...this.props.instancePriceQuery.instancePrice,
          remark: newRemarkList.map(item => item.name).join(', '),
        }
      }
    });
	}

	render() {
    const remarkList = [ //shipmentType = 2 นำเข้า 3 ส่งออก
      {
        shipmentType: 2,
        name_lang: this.props.t('common:instancePrice.selectRemark.lorem2'),
        name: 'Inspection สินค้า',
      },
      {
        shipmentType: 3,
        name_lang: this.props.t('common:instancePrice.selectRemark.lorem3'),
        name: 'ชั่งน้ำหนัก',
      },
      {
        shipmentType: 3,
        name_lang: this.props.t('common:instancePrice.selectRemark.lorem2'),
        name: 'Inspection สินค้า',
      },
    ];
    
		return (
			<Container>
				<Card
					content={
						<div>
							<CardHeader
								title={this.props.t('common:instancePrice.selectRemark.title')}
								onPressClose={() => this.closePopup()}
								borderCardBottom
							/>

              {
                remarkList.filter((item) => item.shipmentType === this.props.instancePriceQuery.instancePrice.shipmentType)
                  .map((item, index, arr) => (
                    <RemarkList
                      key={index}
                      name={item.name_lang}
                      selected={this.checkSelected(item)}
                      onPressSelected={() => this.onClickSelected(item, arr, this.checkSelected(item))}
                    />
                  ))
              }
						</div>
					}
				/>
			</Container>
		)
	}
}

SelectRemark.propTypes = {};
SelectRemark.defaultProps = {};
export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery'
	}),
	graphql(instancePriceStepQueryClient, {
		name: 'instancePriceStepQuery'
	}),
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery'
	})
)(withNamespaces()(SelectRemark));
