import gql from 'graphql-tag';

export default gql`
	mutation CancelOrderApi($trackingNumber: String) {
		cancelOrderApi(trackingNumber: $trackingNumber) {
			code
			message
			messageError
		}
	}
`;
