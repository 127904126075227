import React from "react";
import trackingUpdate from "./graphql/mutation/trackingUpdate";
import detailShipment from "./graphql/query/detailShipment";
import { Mutation, Query } from "react-apollo";
import styled from "styled-components";
import { Modal, Button, FormControl } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { StarRating } from "./../../../../components";
import { Avatar } from 'antd';
import s3 from 'src/config/s3';
import driverfavoriteAndBlacklistAddMutation from './graphql/mutation/driverfavoriteAndBlacklistAdd';

const ModalCustomerReview = styled(Modal)`
  & .modal-dialog {
    width: 420px;
  }
`;

const ReasonReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ReasonReviewList = styled.div`
  padding: 6px 10px;
  border: 1px solid #e3e3e3;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #000000;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #e3e3e3;
  }

  ${props =>
    props.selected &&
    `
		color: #d90101;
		border: 1px solid #d90101;
	`}

  ${props =>
    props.disabled &&
    `
		&:hover {
			background-color: transparent;
			cursor: default;
		}
	`}
`;

const AvatarContainer = styled.div`
  position: relative;
  display: inline-block;

  & .ant-avatar {
		border: 1px solid #E7E7E7;
	}
	
	& .ant-avatar img {
		width: 100%;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
	}
`;

const FavoriteIcon = styled.div`
  color: #FFFFFF;
  font-size: 10px;
  background-color: #DE0201;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
`;

class CustomerReview extends React.Component {
  state = {
    reviewDetail: "",
    reviewScore: 0,
    reviewReason: []
  };

  onSelectReasonReview(data) {
    const {
      reviewReason: reasons,
    } = this.state;
		const reasonsFilter = reasons.filter(item => item.id === data.id);
		if (reasonsFilter.length === 0) {
			return this.setState({
				reviewReason: [...reasons, data],
			});
		} else {
			const removeItemSelect = reasons.filter(item => item.id !== data.id);
			return this.setState({
				reviewReason: removeItemSelect,
			});
		}
  }
  
  getRatingToWord(rating) {
		if (rating === 5) {
			return {
				en: 'Excellent',
				th: 'ดีมาก',
			};
		} else if (rating === 4) {
			return {
				en: 'Good',
				th: 'ดี',
			};
		} else if (rating === 3) {
			return {
				en: 'Average',
				th: 'พอใช้',
			};
		} else if (rating === 2) {
			return {
				en: 'Bad',
				th: 'แย่',
			};
		} else if (rating === 1) {
			return {
				en: 'Worst',
				th: 'แย่มาก',
			};
		}

		return {
			en: 'Rate driver',
			th: 'ให้คะแนนพนักงานขับรถ',
		};
  }
  
  getReasonFromRating() {
		const { reviewScore: rating } = this.state;
		if (rating === 5 || rating === 4) {
			return [
				{
					id: 1,
					en: 'Good manner',
					th: 'มารยาทดี',
				},
				{
					id: 2,
					en: 'Rapidly',
					th: 'ขนส่งรวดเร็ว',
				},
				{
					id: 3,
					en: 'On time delivery',
					th: 'ตรงต่อเวลา',
				},
				{
					id: 4,
					en: 'Formal dressed',
					th: 'แต่งกายสุภาพ',
				},
				{
					id: 5,
					en: 'Good condition goods',
					th: 'สินค้าอยู่ในสภาพดี',
				},
			];
		} else if (rating === 3) {
			return [
				{
					id: 1,
					en: 'Delay',
					th: 'ขนส่งล่าช้า',
				},
				{
					id: 2,
					en: 'Rude',
					th: 'พูดจาไม่ค่อยดี',
				},
				{
					id: 3,
					en: 'Bad navigation',
					th: 'ไม่ชำนาญเส้นทาง',
				},
				{
					id: 4,
					en: 'Unsatisfied',
					th: 'ไม่ค่อยประทับใจ',
				},
				{
					id: 5,
					en: 'Damaged during delivery',
					th: 'เกิดปัญหาระหว่างการขนส่ง',
				},
			];
		} else if (rating === 2 || rating === 1) {
			return [
				{
					id: 1,
					en: 'Rude',
					th: 'พูดจาไม่ดี',
				},
				{
					id: 2,
					en: 'Delay',
					th: 'ขนส่งล่าช้า',
				},
				{
					id: 3,
					en: 'Bad navigation',
					th: 'ไม่ชำนาญเส้นทาง',
				},
				{
					id: 4,
					en: 'Informal dressed',
					th: 'แต่งกายไม่เรียบร้อย',
				},
				{
					id: 5,
					en: 'Damaged goods',
					th: 'สินค้าเกิดชำรุด',
				},
			];
		}

		return [];
  }
  
  checkSelectedReasonReview(id) {
    const {
      reviewReason
    } = this.state;
		const reasonsFilter = reviewReason.filter(item => item.id === id);
    return reasonsFilter.length > 0;
  }

  getStatusFavorite = () => {
    const {
      reviewScore
    } = this.state;

    return reviewScore > 2
  }

  render() {
    const {
      reviewScore,
      reviewReason,
      reviewDetail,
    } = this.state;
    return (
      <Query
        query={detailShipment}
        variables={{
          shipment_id: this.props.shipmentId
        }}
      >
        {({ data, loading, error }) => {
          if (loading || error) return "loading...";

          const { trackingList, shipmentList } = data;
          const { tracking } = trackingList;
          const { shipment } = shipmentList;

          return (
            <Mutation
              mutation={trackingUpdate}
              variables={{
                id: tracking[0]._id,
                reviewDetail: reviewDetail,
                reviewScore: reviewScore,
                reviewReason: reviewReason.map(item => ({
                  en: item.en,
                  th: item.th
                }))
              }}
              onError={() => {
                alert(
                  this.props.t("common:shipmentDetail.notification"),
                  this.props.t(
                    "common:shipmentDetail.somethingSWrongPleaseTryAgain"
                  )
                );
              }}
            >
              {(trackingUpdate, { loading: loadingTrackingUpdate }) => {
                return (
                  <ModalCustomerReview
                    show={this.props.show}
                    onHide={() => this.props.onHide()}
                  >
                    <Modal.Body
                      style={{
                        position: "relative"
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "8px 14px",
                          cursor: "pointer",
                          lineHeight: 1,
                          color: "#888888",
                          fontSize: 24
                        }}
                        onClick={this.props.onClose}
                      >
                        <i className="fal fa-times" />
                      </div>
                      <div
                        style={{
                          padding: "50px 20px 20px 20px",
                          lineHeight: 1.2,
                          minHeight: 350
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold"
                          }}
                        >
                          <AvatarContainer>
                            <Avatar 
                              size={70} 
                              icon="user"
                              style={{marginBottom: 10}}
                              src={`${s3}/${tracking[0].driver_display_pic}`}
                            />

                            {tracking[0].favorite_driver === 1 && (
                              <FavoriteIcon>
                                <i className="fas fa-heart"></i>
                              </FavoriteIcon>
                            )}
                          </AvatarContainer>
                          <div>
                            {`${
                              shipment[0].driver_name_under_mama
                                ? shipment[0].driver_name_under_mama
                                : tracking[0].driver_name
                            }`}
                          </div>
                          <div>
                            {`${
                              shipment[0].shipment_address[0].subdictrict
                            } - ${
                              shipment[0].shipment_address[
                                shipment[0].shipment_address.length - 1
                              ].subdictrict
                            }`}
                          </div>
                          <div>{`${shipment[0].shipment_number}`}</div>
                        </div>

                        <div
                          style={{
                            marginTop: 50,
                            textAlign: "center"
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold"
                            }}
                          >
                            {
                              this.getRatingToWord(reviewScore)[
                                this.props.t("common:lang")
                              ]
                            }
                          </div>
                          <div
                            style={{
                              marginTop: 20
                            }}
                          >
                            <StarRating
                              onRating={rating =>
                                this.setState({
                                  reviewScore: rating,
                                  reviewReason: []
                                })
                              }
                              size={28}
                              rating={reviewScore}
                            />
                          </div>
                        </div>

                        {reviewScore === 0 && (
                          <div
                            style={{
                              marginTop: 20,
                              textAlign: "center"
                            }}
                          >
                            {`"${this.props.t(
                              "common:shipmentDetail.customerReview.commentUs"
                            )}"`}
                          </div>
                        )}

                        {reviewScore !== 0 && (
                          <div>
                            <div
                              style={{
                                marginTop: 30,
                                fontWeight: "bold"
                              }}
                            >
                              {this.props.t(
                                "common:shipmentDetail.customerReview.reason"
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: 10
                              }}
                            >
                              <ReasonReviewContainer>
                                {this.getReasonFromRating().map(
                                  (item, index, arr) => (
                                    <ReasonReviewList
                                      selected={this.checkSelectedReasonReview(
                                        item.id
                                      )}
                                      key={index}
                                      onClick={() =>
                                        this.onSelectReasonReview(item)
                                      }
                                    >
                                      {item[this.props.t("common:lang")]}
                                    </ReasonReviewList>
                                  )
                                )}
                              </ReasonReviewContainer>
                            </div>
                            <div
                              style={{
                                marginTop: 20,
                                fontWeight: "bold"
                              }}
                            >
                              {this.props.t(
                                "common:shipmentDetail.customerReview.comment"
                              )}
                            </div>
                            <FormControl
                              style={{
                                marginTop: 10
                              }}
                              componentClass="textarea"
                              placeholder={this.props.t(
                                "common:shipmentDetail.customerReview.input.comment.placeholder"
                              )}
                              onChange={e =>
                                this.setState({
                                  reviewDetail: e.target.value
                                })
                              }
                              value={reviewDetail}
                            />

                            <div
                              style={{
                                marginTop: 20
                              }}
                            >
                              <Button
                                size="lg"
                                bsStyle="primary"
                                onClick={() => {
                                  trackingUpdate({
                                    refetchQueries: () => ["TrackingList"],
                                  }).then(() => {
                                    this.props.onClose();
                                  });
                                }}
                                block
                                style={{
                                  backgroundColor: '#FFFFFF',
                                  color: '#d90101',
                                  marginBottom: 10,
                                }}
                                disabled={loadingTrackingUpdate}
                              >
                                {this.props.t(
                                  "common:shipmentDetail.customerReview.buttonSave"
                                )}
                              </Button>

                              {shipment[0].shipment_type === 1 && tracking[0].driver_user_agent === '5d0083ebc88a512bb2f5f7a6' && tracking[0].favorite_driver !== 1 && (
                                <Mutation
                                  mutation={driverfavoriteAndBlacklistAddMutation}
                                  variables={{
                                    driver_user_id: [shipment[0].driver_user_id],
                                    mode: this.getStatusFavorite() ? 'favorite' : 'blacklist',
                                  }}
                                  onError={() => {
                                    alert(
                                      this.props.t(
                                        "common:shipmentDetail.somethingSWrongPleaseTryAgain"
                                      )
                                    );
                                  }}
                                >
                                  {
                                    (driverfavoriteAndBlacklistAdd, { loading: loadingDriverFavoriteAndBlacklistAdd }) => {
                                      return (
                                        <Button
                                          size="lg"
                                          bsStyle="primary"
                                          onClick={() => {
                                            trackingUpdate().then(() => {
                                              driverfavoriteAndBlacklistAdd({
                                                refetchQueries: () => ["TrackingList"],
                                              }).then(() => {
                                                this.props.onClose();
                                              });
                                            })
                                          }}
                                          block
                                          disabled={
                                            loadingTrackingUpdate ||
                                            loadingDriverFavoriteAndBlacklistAdd ||
                                            tracking[0].favorite_driver === 1
                                          }
                                        >
                                          { this.getStatusFavorite() ? 
                                            this.props.t('common:shipmentDetail.customerReview.buttonSaveAndFavoriteDriver') : 
                                            this.props.t('common:shipmentDetail.customerReview.buttonSaveAndBanDriver')  
                                          }
                                        </Button>
                                      )
                                    }
                                  }
                                </Mutation>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </Modal.Body>
                  </ModalCustomerReview>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default withNamespaces()(CustomerReview);
