import gql from 'graphql-tag';

export default gql`
	query PromotionList($setting_truck_type_id: ID, $name: String) {
		promotionList(setting_truck_type_id: $setting_truck_type_id, name: $name) {
			promotion {
				_id
				name
				detail_lang {
					en
					th
				}
				title
				discount
				type
				limit
				setting_truck_type_id
				location_start
				location_end
				start
				exp
				promotion_pic
				error
				title_lang {
					en
					th
				}
				sub_title_lang {
					en
					th
				}
				for_you
			}

			promotion_for_you {
				_id
				name
				detail_lang {
					en
					th
				}
				title
				discount
				type
				limit
				setting_truck_type_id
				location_start
				location_end
				start
				exp
				promotion_pic
				error
				title_lang {
					en
					th
				}
				sub_title_lang {
					en
					th
				}
				for_you
			}
		}
	}
`;
