import gql from 'graphql-tag';

export default gql`
  query LineTruck(
    $driver_user_id: ID
  ) {
    userSettingTruckList(
      driver_user_id: $driver_user_id
    ) {
      user_setting_truck {
        _id
        user_setting_truck_license_plate
      }
    }
  }
`;