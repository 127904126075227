import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
`;

const Icon = styled.div`
	background-color: #d90101;
	width: 3px;
	align-self: stretch;
	margin-right: 6px;
	line-height: 1;

	&::before {
		content: '';
		background-color: #d90101;
		width: 3px;
		height: 3px;
		border-radius: 50%;
		position: absolute;
		top: 1px;
	}

	&::after {
		content: '';
		background-color: #d90101;
		width: 3px;
		height: 3px;
		border-radius: 50%;
		position: absolute;
		bottom: 1px;
	}
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
`;

const Required = styled.span`
	font-size: 18px;
	color: #d90101;
	line-height: 1;
`;

const Header = props => {
	return (
		<Container {...props}>
			<Icon />
			<Title>{props.title}{props.required && (<Required>*</Required>)}</Title>
		</Container>
	);
};

Header.propTypes = {
	title: PropTypes.string,
	required: PropTypes.bool,
};

Header.defaultProps = {
	title: '',
	required: false,
};

export default Header;
