import gql from 'graphql-tag';

export default gql`
	query GetInstancePrice(
		$additional: [typeAddArg]
		$settingTruckTypeId: ID
		$pickupLatitude: String
		$pickupLongitude: String
		$deliveryLatitude: String
		$deliveryLongitude: String
		$promotionName: String
		$pickupDate: String
		$round_trip: Int
		$paymentType: Int
		$address: [inputGetInstancePriceAddress]
		$shipmentType: Int
		$utm_source: String
		$utm_medium: String
		$utm_campaign: String
		$ip_address: String
		$flash_deals_id: ID
	) {
		getInstancePrice(
			additional: $additional
			setting_truck_type_id: $settingTruckTypeId
			lat_origin: $pickupLatitude
			lng_origin: $pickupLongitude
			lat_destination: $deliveryLatitude
			lng_destination: $deliveryLongitude
			address: $address
			promotion_name: $promotionName
			pick_up_date: $pickupDate
			call_from: 2
			round_trip: $round_trip
			paymentType: $paymentType
			shipment_type: $shipmentType
			utm_source: $utm_source
			utm_medium: $utm_medium
			utm_campaign: $utm_campaign
			ip_address: $ip_address
			flash_deals_id: $flash_deals_id
		) {
			distance
			distancePrice
			additionalPrice
			additionalWithDiscountPrice
			additionalVat
			additionalTotalPrice
			discountPrice
			totalPrice
			promotion_error
			discount {
				detail
				price
				discount_type
				percent
			}
      additional {
				_id
				name
				name_lang {
					th
					en
				}
				price
				qty
				total_price
			}
			promotion {
				_id
				name
				discount
				type
				limit
				setting_truck_type_id
				location_start
				location_end
				start
				exp
			}
			discount_error {
				detail
				price
				discount_type
				percent
			}
			multi_point {
				distance_point
				distance_point_detail
				total_point
				price_per_point
			}
			gross_price
			promotionCodeList {
				for_you
				name
				title_lang {
					en
					th
				}
			}
			multi_point {
				distance_point
				distance_point_detail
				total_point
				price_per_point
			}
			multi_point {
				distance_point
				distance_point_detail
				total_point
				price_per_point
			}
			business_price_vat
			business_price_wht1
			business_price_wht3
			business_price_wht5
			business_total_price
			flash_deals {
				_id
				name_lang {
					th
					en
				}
				discount
				limit_per_user
				limit_count
				location_start_lang {
					th
					en
				}
				location_end_lang {
					th
					en
				}
				start
				exp
				pickup_start
				pickup_end
				price
				price_cost
				price_sale
			}
			error_flash_deals
		}
	}
`;
