import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;
const InputBox = styled.input`
  font-size: 19px;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  padding: 0 12px;
  line-height: 1;
  height: 40px;
  background-color: #FFFFFF;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    ${props => props.disabled && `
      background-color: #F9F9F9;
    `}
  }

  ${props => props.isBorderValidated && `
    border: 1px solid #FF0000;
  `}

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B3B3B3;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B3B3B3;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #B3B3B3;
  }
`;

const MessageError = styled.div`
  font-size: 18px;
  color: #FF0000;
  height: 22px;
  text-align: right;
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: bold;

  ${props => props.messageError && `
    color: #FF0000;
  `}
`;

const Required = styled.span`
  color: #D90101;
`;

class Input extends React.Component {
  render() {
    return (
      <Container style={this.props.style}>
        { this.props.inputLabel ? (<Label messageError={this.props.messageError}>{this.props.inputLabel}{ this.props.required && (<Required>*</Required>) }</Label>) : null }
        <InputBox
          name={this.props.name} 
          ref={this.props.inputRef} 
          onBlur={this.props.onBlur} 
          onKeyPress={this.props.onKeyPress} 
          name={this.props.name} 
          style={this.props.styleInput} 
          onChange={this.props.onChange} 
          value={this.props.value}
          isBorderValidated={this.props.messageError}
          placeholder={this.props.placeholder}
          type={this.props.type}
          disabled={this.props.disabled}
          autocomplete={this.props.autocomplete}
        />
        <MessageError>{this.props.messageError}</MessageError>
      </Container>
    )
  }
}

export default Input;