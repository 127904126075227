import baseUrl from './baseUrl';

let landingUrl = '';
if (baseUrl === 'https://api.giztix.com') {
	landingUrl = 'https://www.giztix.com';
}

if (baseUrl === 'https://demoapi.giztix.com') {
	if (window.location.hostname === 'localhost') {
		landingUrl = 'http://localhost:3001';
	} else {
		landingUrl = 'http://giztix-landing-page.netlify.com';
	}
}

export default landingUrl;
