import React from 'react';
import { Modal, Button } from '../../../../components';
import { Form, Input, Row, Col } from 'antd';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import accountContactAddMutation from './graphql/mutation/accountContactAdd';

const FormStyle = styled(Form.Item)`
	.ant-form-item-label {
		line-height: 1;
	}

	&.ant-form-item {
		margin-bottom: 15px;
	}

	.ant-input:hover,
	.ant-input:focus {
		border-color: rgba(0, 0, 0, 0.3);
		box-shadow: none;
	}

	.ant-form-explain {
		font-size: 18px;
		line-height: 1;
		min-height: auto;
	}
`;

class ContactAdd extends React.Component {
	render() {
		const contactAddSchema = Yup.object().shape({
			contactName: Yup.string().required('กรุณากรอกข้อมูล'),
			contactTel: Yup.string()
				.matches(/^[0]\d{8,9}$/, 'รูปแบบไม่ถูกต้อง')
				.required('กรุณากรอกข้อมูล'),
		});

		return (
			<Mutation mutation={accountContactAddMutation}>
				{(accountContactAdd, { loading, error }) => {
					return (
						<Formik
							initialValues={{
								contactName: '',
								contactTel: '',
							}}
							validationSchema={contactAddSchema}
							onSubmit={(values, { resetForm, setFieldError }) => {
								accountContactAdd({
									variables: {
										contact_name: values.contactName,
										contact_tel: values.contactTel,
									},
									update: (cache, { data }) => {
										if (data.accountContactAdd._id) {
											setTimeout(() => {
												resetForm({});
											}, 500);
											this.props.onHide();
										}
									},
									refetchQueries: () => ['AccountContactList'],
								}).catch(error => {
									const textError =
										error.graphQLErrors && error.graphQLErrors.length > 0
											? error.graphQLErrors[0].message
											: error.message;
									setFieldError('contactTel', textError);
								});
							}}
						>
							{props => {
								const {
									handleChange,
									handleSubmit,
									handleBlur,
									handleReset,
									touched,
									errors,
									values,
								} = props;

								return (
									<Modal
										width={640}
										title={
											<>
												<div>คุณต้องการเพิ่มข้อมูลผู้ติดต่อ ใช่หรือไม่?</div>
												<div className="sub-title">
													กรุณาตรวจสอบข้อมูลให้ถูกต้อง
												</div>
											</>
										}
										closable={false}
										visible={this.props.visible}
										onCancel={() => {
											handleReset();
											this.props.onHide();
										}}
										footer={[
											<Button
												key="back"
												onClick={() => {
													handleReset();
													this.props.onHide();
												}}
											>
												ปิด
											</Button>,
											<Button
												key="submit"
												type="primary"
												onClick={() => handleSubmit()}
												disabled={loading}
												loading={loading}
											>
												บันทึก
											</Button>,
										]}
									>
										<Row>
											<Col>
												<FormStyle
													label="รายชื่อผู้ติดต่อ"
													validateStatus={
														errors.contactName && touched.contactName && 'error'
													}
													help={
														errors.contactName &&
														touched.contactName &&
														errors.contactName
													}
												>
													<Input
														placeholder="ระบุ"
														value={values.contactName}
														onChange={handleChange}
														onBlur={handleBlur}
														name="contactName"
													/>
												</FormStyle>
											</Col>
											<Col>
												<FormStyle
													label="เบอร์โทรศัพท์"
													validateStatus={
														errors.contactTel && touched.contactTel && 'error'
													}
													help={
														errors.contactTel &&
														touched.contactTel &&
														errors.contactTel
													}
												>
													<Input
														placeholder="0812345678"
														value={values.contactTel}
														onChange={handleChange}
														onBlur={handleBlur}
														name="contactTel"
														onPressEnter={() => handleSubmit()}
													/>
												</FormStyle>
											</Col>
										</Row>
									</Modal>
								);
							}}
						</Formik>
					);
				}}
			</Mutation>
		);
	}
}

export default ContactAdd;
