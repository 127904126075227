import gql from 'graphql-tag';

export default gql`
	query ChallengeDistance {
		accountInfoList {
			account_business {
				challenge_distance {
          distance
          rank
          rank_old
        }
			}
		},

    challengeList (
			_id: "5dc2abdfdf43370dac1003b8"
		) {
			challenge {
        end_date
      }
		}
	}
`;
