import gql from 'graphql-tag';

export default gql`
	mutation EditPassword(
		$old_password: String!
		$new_password: String!
	) {
        editPassword(
            new_password:$new_password,
            old_password:$old_password
          ){
          succeed
        }
	}
`;
