import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, NavDropdown , FormGroup,InputGroup,Button,FormControl,Modal} from 'react-bootstrap';
import { graphql, compose, Query, Mutation } from 'react-apollo';
import styled from 'styled-components';
import logo from './../../assets/images/logo_giztix.png';
import logoWhite from './../../assets/images/logo_rw.png';
import client from './../../config/client';
import updateModalLoginMutationClient from '../../config/graphqlClient/mutation/updateModalLogin';
import updateModalRegisterMutationClient from '../../config/graphqlClient/mutation/updateModalRegister';
import userQueryClient from '../../config/graphqlClient/query/user';
import AccountInfoList from './graphql/query/accountInfoList'
import notificationListQuery from './graphql/query/notificationList';
import notificationCountQuery from './graphql/query/notificationCount';
import notificationUpdateMutation from './graphql/mutation/notificationUpdate';
import notificationCountUpdateMutation from './graphql/mutation/notificationCountUpdate';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import { Link } from 'react-router-dom';
import color from './../../config/color';
import landingUrl from '../../config/landingUrl';
import s3 from '../../config/s3';

//Start language
import { withNamespaces } from "react-i18next";
import imgWelcomeSubAccount from 'src/assets/images/welcomeSubAccount.png';
import { Empty, Spin, Icon } from 'antd';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment';

const PromotionContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;

	& .close-btn{
		cursor: pointer;
		position: absolute;
		top: 5px;
		left: 10px;
		color: #fff;
		width: 40px;
		height: 40px;
	}

	& img {
		width: 100%;
		cursor: pointer;
	}
`;

const WelcomeContainer = styled.div`
	& .close-btn{
		text-align: right;
	}
	& h2{
		color: #D90101;
		font-weight: bold;
		margin-bottom:0;
	}
	& h3{
		margin-top:0;
    	font-weight: bold;
		margin-bottom:0;
	}
`;

const TOKEN = {
  authorization: localStorage.getItem('token')
};

const HeaderContainer = styled(Navbar)`
	background-color: ${props => props.bgcolor === 'transparent' ?  'transparent' : props.bgcolor ? props.bgcolor : '#FFFFFF'};
	width: 100%;
	border-radius: 0px;
	border: none;
	border-bottom: ${props => props.border === 'true' ? '1px' : '0px'} solid rgba(0, 0, 0, 0.16);
	position: fixed;
	top: 0;
	z-index: 999;

	.nav-item-border {
		border-radius: 6px;
		padding: 8px 20px;
	}
	
	.nav-item-border-black {
		border: 1px solid #000000;
	}
	
	.nav-item-border-white {
		border: 1px solid #FFFFFF;
	}
	
	.navbar-nav {
		box-sizing: content-box;
	}
	
	.navbar-nav > li > a {
		padding-top: 18px;
	}
	
	.navbar-white > li > a {
		color: #FFFFFF !important;
	}
	
	.navbar-black > li > a {
		color: #000000 !important;
	}

	.dropdown-menu {
		background-color: #FFFFFF !important;
		border: 1px solid #EEEEEE !important;
		border-radius: 6px !important;
		margin-top: 0;
	}

	.dropdown-menu::after {
		content: '';
		width: 16px;
		height: 16px;
		position: absolute;
		top: -8px;
    right: 16px;
		background-color: #FFFFFF !important;
		transform: rotate(45deg);
		z-index: -999;
		border-top: 1px solid #EEEEEE;
		border-left: 1px solid #EEEEEE;
	}

	.dropdown-toggle {
		background-color: transparent !important;
		padding-left: 12px;
		padding-right: 12px;
	}

	.caret {
		display: none;
	}
`;

const NavItemDivider = styled(NavItem)`
	background-color: #E3E3E3;
	width: 1px;
	margin-top: 12px;
	margin-left: 10px;
	margin-right: 10px;
`;

const NavbarBrand = styled(Navbar.Brand)`
	padding-right: 35px;
	padding-left:0px;
	padding-top: 15px;
`;

const IconMenu = styled.i`
	font-size: 24px;
`;

const ItemMenu = styled.div`
	font-size: 22px;
	padding: 10px 20px;
	color: #000000;
	background-color: ${props => (props.active ? '#F2F2F2' : '#FFFFFF')};

	& i {
		color: ${color.SoftGray}
		padding-left: 5px;
		font-size: 14px;
	}

	&:hover {
		background-color: ${props => (props.active ? '#F2F2F2' : '#F2F2F270')};
	}
`;

const LinkA = styled.a`
	text-decoration: none !important;
`;

const LinkItemMenu = styled(Link)`
	text-decoration: none !important;
`;

const DrawerBody = styled.div`
	overflow-y: auto;
	max-height: calc(100vh - 150px);
	padding-bottom: 80px;

	&::-webkit-scrollbar { 
    display: none; 
	}
`;

const DrawerFooter = styled.div`
	background-color: #FFFFFF;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 20px;
	padding-left: 20px;
	width: 100%;
	box-sizing: border-box;
	height: 150px;

	& .contact-header {
		font-size: 20px;
	}

	& .contact-detail {
		padding-top: 10px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 1;
	}
`;

const NavItemDropDownList = styled(NavItem)`
	font-size: 20px;

	& a {
		display: flex !important;
		flex-direction: row !important;
		align-items: center !important;
		padding: 10px 15px !important;

		& i {
			padding-right: 10px;
		}
	}
`;

const NavText = styled.div`
	height: 25px;

	& i {
		display: block;
    font-size: 24px;
    float: left;
	}

	& .img {
		position: relative;
		width: 24px;
		height: 24px;
		overflow: hidden;
		border-radius: 50%;
		display: block;
		float: left;
		border: 1px solid #F2F2F2;
	}

	& img {
		width: 24px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
	}

	& span {
		display: block;
    float: left;
    line-height: 1;
    margin: 3px 0 0 10px;
	}
`;

const TextChangeLanguage = styled.span`
	font-size: 18px;
`;

const ButtonSearch = styled(Button)`
    width: 90px;
`;

const FormGroupSearch = styled(FormGroup)`
	width: 320px;
	margin: 5px 10px;
`;

const NotificationContainer = styled.div`
	font-size: 20px;
	line-height: 1;
	width: 320px;
	box-sizing: border-box;
	position: relative;

	& .notification {
		&-header {
			padding: 15px 20px;
			display: flex;
			flex-direction: row;
			align-item: center;
	
			&__title {
				font-weight: bold;
				flex: 1;
			}
	
			&__btn-read {
				color: #808080;
				cursor: pointer;
				font-size: 16px;
			}
		}
	
		&-body {
			display: flex;
			flex-direction: column;
			min-height: 250px;
			max-height: 380px;
			overflow-y: auto;
	
			&__title {
				padding: 10px 20px;
				font-size: 16px;
				color: #B3B3B3;
			}
	
			&__list {
				display: flex;
				flex-direction: row;
				padding: 15px 20px;
				position: relative;
				cursor: pointer;

				&:hover {
          background-color: #F9F9F980;
        }
	
				&--active {
					background-color: #F9F9F9;
				}

				&-detail {
					display: flex;
					flex-direction: column;
					flex: 1;

					&__title {
						font-size: 18px;
						font-weight: bold;
					}

					&__sub-title {
						font-size: 16px;
						color: #888888;
						white-space: pre-wrap;
					}
				}

				&-time {
					position: absolute;
          top: 10px;
          right: 20px;
					color: #B3B3B3;
					font-size: 14px;
					align-self: flex-start;
				}
			}
		}

		&-footer {
			margin-top: 2px;
		}

		&-icon {
			width: 35px;
			height:35px;
			background-color: #D90101;
			border-radius: 50%;
			margin-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px;

			& img {
				width: 100%;
			}
		}

		&__btn {
			padding: 10px 0px;
			width: 100%;
			font-size: 16px;
			color: #808080;
			background-color: #FFFFFF;
			border: none;
			outline: none;
		}

		&-line {
			width: 100%;
			height: 1px;
			background-color: #E3E3E3;
		}
	}
`;

const Badge = styled.span`
	position: absolute;
	top: 10px;
	right: 0px;
	min-width: 16px;
	height: 16px;
	padding: 0 6px;
	color: #fff;
	font-weight: normal;
	font-size: 16px;
	white-space: nowrap;
	text-align: center;
	background: #f5222d;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #000000;

    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      color: #000000;
    }
`;

class CustomSearchbox extends React.Component {
	constructor(props, context) {
	  	super(props, context);
  
	  	this.handleChange = this.handleChange.bind(this);
  
	  	this.state = { 
				value : "",
				autoFocus: false, 
		};
	}

	handleChange(e){
		this.setState({ value: e.target.value });
	}

	handleKeyPress(e){
		if (e.key === 'Enter') {
			if(this.state.value !== "" && this.state.value !== undefined){
					window.location.href = '/trackingdetail?shipment='+this.state.value;
			} else {
					alert(this.props.t('common:header.pleaseFillShipmentId'));
			}
		}
	}

	searchTracking(){
		if(this.state.value !== "" && this.state.value !== undefined){
				window.location.href = '/trackingdetail?shipment='+this.state.value;
		}
		else{
				alert(this.props.t('common:header.pleaseFillShipmentId'));
		}
	}
  
	render() {
	  return (
			<FormGroupSearch>
				<InputGroup>
				<FormControl 
					type="text"
					autoFocus
					value={this.state.value}
					placeholder={this.props.t('common:header.fillShipmentId')}
					onChange={event =>
						this.handleChange(event)
					} 
					onKeyPress={event =>
						this.handleKeyPress(event)
					} 
				/>
				<InputGroup.Button>
					<ButtonSearch bsStyle="primary" onClick={() => this.searchTracking()}>{this.props.t('common:header.tracking')}</ButtonSearch>
				</InputGroup.Button>
				</InputGroup>
			</FormGroupSearch>
	  );
	}
}

const isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	}
  };

class Header extends React.Component {
	state = {
		openMenu: false,
		showWelcome: true,
		showDownload: false,
	};

	onTouchEnd = () => {
		this.setState({ openMenu: false });
	};

	onSwitchMenu = () => {
		this.setState({ openMenu: !this.state.openMenu });
	};

	handleSelect = (selectedKey, updateModal) => {
		switch (selectedKey) {
			case 1: //ปุ่มสมัครสมาชิก
				return updateModal();
			case 2: //ปุ่มเข้าสู่ระบบ
				return updateModal();
			case 3: //ปุ่มเมนู
				return this.onSwitchMenu();
			case 4: //ปุ่มออกจากระบบ
				localStorage.removeItem('token');
				return (window.location = '/');
			default:
				return null;
		}
	};

	selectActive(pathname) {
		if (pathname === window.location.pathname) {
			return true;
		}

		return false;
	}

	openPopUpUser(token , hash) {
		const noHashURL = window.location.href.replace(/#.*$/, '');
		//window.history.replaceState('', document.title, noHashURL);

		if(!token){
			if(hash != ""){
				if(hash === "#register"){
					window.history.replaceState('', document.title, noHashURL) ;
					
					client.cache.writeData({
						data: {
							modalRegister: {
								isOpen: false,
								isBack: false ,
								__typename: 'ModalRegister',
							},
						},
					})
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: false,
								__typename: 'ModalLogin',
							},
						},
					})
				}

				if(hash === "#login"){
					window.history.replaceState('', document.title, noHashURL) ;
					
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: true,
								__typename: 'ModalLogin',
							},
						},
					});
					client.cache.writeData({
						data: {
							modalRegister: {
								isOpen: false, 
								isBack: false ,
								__typename: 'ModalRegister',
							},
						},
					});
				}
			}
			else{
				if(window.location.pathname === "/register-business" || window.location.pathname === "/shipment" ){
					client.cache.writeData({
						data: {
							modalLogin: {
								isOpen: true,
								__typename: 'ModalLogin',
							},
						},
					});
				}
			}		
		}
	}

	switchLanguage() {
		const { t, i18n } = this.props;
		if (t('common:lang') === 'th') {
			i18n.changeLanguage('en');
			localStorage.setItem('lang', 'en');
		} else {
			i18n.changeLanguage('th');
			localStorage.setItem('lang', 'th');
		}
	}
	
	render() {
		moment.locale(this.props.t('common:lang'));

		return (
			<Query query={userQueryClient}>
				{({ data }) => {
					const token = data.user.token;

					return (
						<Query
							query={AccountInfoList}
							context={TOKEN}
						>
							{({ data: accountInfoListData, loading, error }) => {
								const account_info = accountInfoListData;
								let displayName = '';
								let displayPicture = '';
								let accountBuisness = null;

								if(!loading && !error && account_info !== {} && account_info !== undefined && account_info.accountInfoList ) {
									displayName = account_info.accountInfoList.account_info[0].account_users[0].account_user_display_name;
									displayPicture = account_info.accountInfoList.account_info[0].account_users[0].account_user_display_picture ? s3 + '/' + account_info.accountInfoList.account_info[0].account_users[0].account_user_display_picture : '';
									accountBuisness = account_info.accountInfoList.account_business !== null ? true : false;
								}
								
								return (
								<div>
									{
										this.openPopUpUser(token , window.location.hash)
									}

									{
										token && window.location.hash == "#welcome" ? (
											<Modal
												show={this.state.showWelcome}
												onHide={() =>
													this.setState({
														showWelcome: false
													},() => window.location.hash = "")
												}
											>
												<Modal.Body style={{ textAlign: 'center' }}>
													<WelcomeContainer>
														<div className="close-btn">
															<i className="fal fa-times" onClick={() => this.setState({showWelcome:false},() => window.location.hash = "")}></i>
														</div>

														<img src={imgWelcomeSubAccount} style={{width:"90%"}} />

														<h2>{ this.props.t('common:header.Welcome') } </h2>
														<h3>{displayName}</h3>
														<p>{ this.props.t('common:header.tagLine') }</p>

													</WelcomeContainer>
													

													<Button bsStyle="primary" onClick={() => this.setState({showWelcome:false},() =>  window.location.hash = "")} style={{width:150,marginBottom:30}}>
														{ this.props.t('common:header.WelcomeBtn') }
													</Button>

												</Modal.Body>
											</Modal>
										) :null
									}

									{ this.state.showDownload && isMobile.any()  && (
										<PromotionContainer>
											<img onClick={() => window.location = "https://giztix.page.link/downloadGiztixApp"} src={`${require('src/assets/images/popup_loadapp-05.png')}`} alt="" />
											<div className="close-btn" onClick={() => this.setState({showDownload : false})} > <i class="fal fa-times"></i> </div>
										</PromotionContainer>
									)}
									

									<HeaderContainer className={this.props.full ? "full" : ""} bgcolor={this.props.bgcolor} border={`${this.props.border}`}>
										<Navbar.Header>
											<NavbarBrand>
												<a href={landingUrl}>
													<img
														src={this.props.color === 'black' ? logo : logoWhite}
														className="logo"
														alt="logo"
														style={{ height: this.props.color === 'black' ? 36 : 30 }}
													/>
												</a>
											</NavbarBrand>
										</Navbar.Header>
										<Navbar.Collapse>
											<Nav pullRight onSelect={this.handleSelect} className={`${this.props.color === 'black' ? 'navbar-black' : this.props.color === 'white' ? 'navbar-white' : 'navbar-black'}`}>
												{/* <NavItem componentClass={NavLink} to="/tracking" href="/tracking">
													<div className="navItem">
														<i class="fal fa-user-circle"></i>
														<div>ชื่อผู้ใช้งานระบบ</div>
													</div>
												</NavItem> */}
												{token ? (
												<NavDropdown id="user_dropdown" title={<NavText>{displayPicture !== '' ? (<div className="img"><img src={`${displayPicture}`} alt="" /></div>) : (<i className="fal fa-user-circle"></i>)}<span>{displayName ? displayName : 'username'}</span></NavText>}>
													<NavItemDropDownList href="/setting-profile">
														<i className="far fa-user"></i>{ this.props.t('common:header.editAccount') }
													</NavItemDropDownList>

													{
														accountBuisness ? (
															<hr style={{ margin: '0px 10px' }}/>
														) : null
													}

													{
														accountBuisness ? (
															<NavItemDropDownList href="/setting-business">
																<i className="far fa-suitcase"></i>{ this.props.t('common:header.companyProfile') }
															</NavItemDropDownList>
														) : null
													}
													
													<hr style={{ margin: '0px 10px' }}/>
													<NavItemDropDownList eventKey={4} href="#">
														<i className="far fa-power-off"></i>{ this.props.t('common:header.logOut') }
													</NavItemDropDownList>
												</NavDropdown>
												) : null}

												{token ? (<NavItemDivider />) : null}

												{token ? (
												<NavDropdown title={
													<Mutation
														mutation={notificationCountUpdateMutation}
														variables={{
															count_number: 0,
														}}
														refetchQueries={() => ['NotificationCount', 'NotificationList']}
													>
														{
															(notificationCountUpdate) => {
																return (
																	<NavText
																		onClick={() => {
																			notificationCountUpdate();
																		}}
																	>
																		<i class="fal fa-bell"></i>
																		<Query
																			query={notificationCountQuery}
																			fetchPolicy="cache-and-network"
																		>
																			{
																				({ data, loading, error }) => {
																					if(loading || error) return null;
																					return !!data.notificationCount.count_number && (
																						<Badge>{ data.notificationCount.count_number }</Badge>
																					)
																				}
																			}
																		</Query>
																	</NavText>
																)
															}
														}
													</Mutation>
													}
												>
													<NotificationContainer>
														<div className="notification-header">
															<div className="notification-header__title">{this.props.t('common:notification.title')}</div>
															<Mutation
																mutation={notificationUpdateMutation}
																variables={{
																	multi: true,
																	read_status: 2,
																}}
																refetchQueries={() => ['NotificationList']}
															>
																{
																	(notificationUpdate) => {
																		return (
																			<div className="notification-header__btn-read" onClick={() => notificationUpdate()}>{this.props.t('common:notification.markAllRead')}</div>
																		)
																	}
																}
															</Mutation>
														</div>
														<div className="notification-line"></div>
														<Query
															query={notificationListQuery}
															fetchPolicy="network-only"
															variables={{
																show_data: 10
															}}
														>
															{
																({ data, loading, error }) => {
																	if(loading) return (<div className="notification-body">
																		<Spin indicator={
																			<Icon type="loading" style={{ fontSize: 24, color: '#DDDDDD', margin: '80px 0px' }} spin />
																		} />
																	</div>);
																	if(error) return (<div className="notification-body">{ error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Error.' }</div>);

																	const {
																		notification
																	} = data.notificationList;
																	const filterNewNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') < 60);
																	const filterOldNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') >= 60);
																	const notificationList = [
																		{
																			title: this.props.t('common:notification.new'),
																			lists: [...filterNewNotification],
																		},
																		{
																			title: filterNewNotification.length > 0 ? this.props.t('common:notification.previous') : this.props.t('common:notification.all'),
																			lists: [...filterOldNotification],
																		}
																	];

																	return (
																		<div>
																			<div className="notification-body" data-simplebar>
																				{
																					notification.length === 0 && (
																						<Empty
																							image={Empty.PRESENTED_IMAGE_SIMPLE}
																							description={
																								<span>
																									{this.props.t('common:notification.empty')}
																								</span>
																							}
																							style={{margin: '80px 0px'}}
																						/>
																					)
																				}

																				{
																					notificationList.map((item) => (
																						<React.Fragment>
																							{ item.lists.length > 0 && (<div className="notification-body__title">{item.title}</div>) }
																							{ item.lists.map((list, index) => (
																								<Mutation
																									mutation={notificationUpdateMutation}
																									variables={{
																										_id: list._id,
																										read_status: 2,
																									}}
																									update={(cache, { data }) => {
																										const { succeed } = data.notificationUpdate;
																										if(succeed) {
																											window.location = `/shipmentdetail?shipment=${list.shipment_id}`
																										}
																									}}
																									refetchQueries={() => ['NotificationList']}
																								>
																									{
																										(notificationUpdate) => {
																											return (
																												<div onClick={() => {notificationUpdate()}} key={index} className={`notification-body__list ${list.read_status === 1 && 'notification-body__list--active'}`}>
																													<div className="notification-body__list-icon">
																														<div className="notification-icon">
																															<img 
																																src={`${list.pic_type === 1 ? require('src/assets/images/notification-icon-giztix.svg') : require('src/assets/images/notification-icon-truck.svg')}`}
																																alt="icon"
																															/>
																														</div>
																													</div>
																													<div className="notification-body__list-detail">
																														<div className="notification-body__list-detail__title">{list.title_lang[this.props.t('common:lang')]}</div>
																														<div className="notification-body__list-detail__sub-title">
																															<div>{list.sub_title_lang[this.props.t('common:lang')]}</div>
																														</div>
																													</div>
																													<div className="notification-body__list-time"  title={`${moment(list.create_date).format('YYYY-MM-DD HH:mm:ss')}`}>{moment(list.create_date).fromNow()}</div>
																												</div>
																											)
																										}
																									}
																								</Mutation>
																							)) }
																						</React.Fragment>
																					))
																				}
																			</div>
																		</div>
																	)
																}
															}
														</Query>

														<div className="notification-line"></div>
														<div className="notification-footer">
															<Link to="/notification">
																<button className="notification__btn">{this.props.t('common:notification.seeAll')}</button>
															</Link>
														</div>
													</NotificationContainer>


													{
														false && (
															<Query
																query={notificationListQuery}
																fetchPolicy="network-only"
															>
																{
																	({ data, loading, error }) => {
																		if(loading) return 'loading...';
																		if(error) return (<div style={{marginTop: 10}}>{ error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Error.' }</div>);

																		const {
																			notification
																		} = data.notificationList;
																		const filterNewNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') < 60);
																		const filterOldNotification = notification.filter(item => moment().diff(moment(item.create_date), 'minutes') >= 60);
																		const notificationList = [
																			{
																				title: this.props.t('common:notification.new'),
																				lists: [...filterNewNotification],
																			},
																			{
																				title: filterNewNotification.length > 0 ? this.props.t('common:notification.previous') : this.props.t('common:notification.all'),
																				lists: [...filterOldNotification],
																			}
																		];

																		return (
																			<NotificationContainer>
																				<div className="notification-header">
																					<div className="notification-header__title">{this.props.t('common:notification.title')}</div>
																					<div className="notification-header__btn-read">{this.props.t('common:notification.markAllRead')}</div>
																				</div>
																				<div className="notification-line"></div>
																				<div className="notification-body" data-simplebar>
																					{
																						notification.length === 0 && (
																							<Empty
																								image={Empty.PRESENTED_IMAGE_SIMPLE}
																								description={
																									<span>
																										{this.props.t('common:notification.empty')}
																									</span>
																								}
																								style={{margin: '80px 0px'}}
																							/>
																						)
																					}

																					{
																						notificationList.map((item) => (
																							<React.Fragment>
																								{ item.lists.length > 0 && (<div className="notification-body__title">{item.title}</div>) }
																								{ item.lists.map(list => (
																									<StyledLink to={`/shipmentdetail?shipment=${list.shipment_id}`}>
																										<div key={list._id} className={`notification-body__list ${list.read_status === 1 && 'notification-body__list--active'}`}>
																											<div className="notification-body__list-icon">
																												<div className="notification-icon">
																													<img 
																														src={`${list.pic_type === 1 ? require('src/assets/images/notification-icon-giztix.svg') : require('src/assets/images/notification-icon-truck.svg')}`}
																														alt="icon"
																													/>
																												</div>
																											</div>
																											<div className="notification-body__list-detail">
																												<div className="notification-body__list-detail__title">{list.title_lang[this.props.t('common:lang')]}</div>
																												<div className="notification-body__list-detail__sub-title">
																													<div>{list.sub_title_lang[this.props.t('common:lang')]}</div>
																												</div>
																											</div>
																											<div className="notification-body__list-time">{moment(list.create_date).fromNow()}</div>
																										</div>
																									</StyledLink>
																								)) }
																							</React.Fragment>
																						))
																					}                        
																				</div>

																				<div className="notification-line"></div>
																				<div className="notification-footer">
																					<Link to="/notification">
																						<button className="notification__btn">{this.props.t('common:notification.seeAll')}</button>
																					</Link>
																				</div>
																			</NotificationContainer>
																		)
																	}
																}
															</Query>
														)
													}
												</NavDropdown>
												) : null}

												{token ? (
												<NavDropdown id="search_dropdown" 
													onClick={e => e.stopPropagation()}
													title={<NavText><i className="fal fa-search"></i></NavText>}
												>
													<NavItemDropDownList as={CustomSearchbox} t={this.props.t} />
												</NavDropdown>
												) : null}

												{/* {token ? (<NavItemDivider />) : null} */}

												{!token ? (
													<Mutation
														mutation={updateModalRegisterMutationClient}
														variables={{ isOpen: true, isBack: false }}
													>
														{updateModalRegister => {
															return (
																<NavItem
																	eventKey={1}
																	href="#"
																	onSelect={event =>
																		this.handleSelect(event, updateModalRegister)
																	}
																>
																	{ this.props.t('common:header.register') }
																</NavItem>
															);
														}}
													</Mutation>
												) : null}

												{!token ? (
													<Mutation
														mutation={updateModalLoginMutationClient}
														variables={{ isOpen: true }}
													>
														{updateModalLogin => {
															return (
																<NavItem
																	eventKey={2}
																	href="#"
																	onSelect={event =>
																		this.handleSelect(event, updateModalLogin)
																	}
																>
																	<span className={`nav-item-border ${this.props.color === 'black' ? 'nav-item-border-black' : this.props.color === 'white' ? 'nav-item-border-white' : 'nav-item-border-black'}`}>
																	{ this.props.t('common:header.signin') }
																	</span>
																</NavItem>
															);
														}}
													</Mutation>
												) : null}

												<NavItem
													href="#"
													onClick={() => this.switchLanguage()}
												>
													<img src={`${require('src/assets/images/flag/' + this.props.t('common:lang').toUpperCase() + '.png')}`} style={{height:15,marginRight:10,marginBottom:4}} alt="flag" />
													<TextChangeLanguage>{ this.props.t('common:lang').toUpperCase() }</TextChangeLanguage>
												</NavItem>

												<NavItem eventKey={3} href="#">
													<IconMenu className="far fa-bars" />
												</NavItem>
											</Nav>
										</Navbar.Collapse>
									</HeaderContainer>
									<Drawer
										open={this.state.openMenu}
										width="260px"
										onMaskClick={this.onTouchEnd}
										level={null}
										handler={false}
										placement="right"
									>
										<DrawerBody>
											<LinkA
												href={landingUrl}
											>
												<ItemMenu
													active={false}
													style={{ marginTop: 30 }}
												>
													{ this.props.t('common:header.homepage') }
												</ItemMenu>
											</LinkA>

											<LinkItemMenu
												onClick={() => this.setState({ openMenu: false })}
												to="/"
											>
												<ItemMenu active={this.selectActive('/')}>
												{ this.props.t('common:header.checkTransportationPrice') }
												</ItemMenu>
											</LinkItemMenu>

											{token ? (
												<LinkItemMenu
													onClick={() => this.setState({ openMenu: false })}
													to="/shipment"
												>
													<ItemMenu active={this.selectActive('/shipment')}>
													{ this.props.t('common:header.shipmentList') }
													</ItemMenu>
												</LinkItemMenu>
											) : null}

											<LinkItemMenu
												onClick={() => this.setState({ openMenu: false })}
												to="/tracking"
											>
												<ItemMenu active={this.selectActive('/tracking')}>
												{ this.props.t('common:header.shipmentTracking') }
												</ItemMenu>
											</LinkItemMenu>

											<LinkA
												href={`${landingUrl}/pricing`}
											>
												<ItemMenu active={this.selectActive('/pricing')}>{ this.props.t('common:header.transportationRate') }</ItemMenu>
											</LinkA>
											

											<LinkA
												href="https://blog.giztix.com/"
												target="_blank"
											>
												<ItemMenu>{ this.props.t('common:header.newsPromotion') } <i className="fal fa-external-link"></i></ItemMenu>
											</LinkA>

											<hr style={{ margin: '10px 0px 10px 20px' }} />

											<LinkA
												href={`${landingUrl}/helpcenter`}
											>
												<ItemMenu active={this.selectActive('/helpcenter')}>{ this.props.t('common:header.helpCenter') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`${landingUrl}/insurance`}
											>
												<ItemMenu active={this.selectActive('/insurance')}>การคุ้มครองสินค้าจาก GIZTIX EXPRESS <small> (ลูกค้าองค์กร/ธุรกิจ)</small></ItemMenu>
											</LinkA>

											<LinkA
												href={`/giztixbusiness`}
											>
												<ItemMenu active={this.selectActive('/giztixbusiness')}>{ this.props.t('common:header.forcorporatebusinesscustomers') }</ItemMenu>
											</LinkA>

											<hr style={{ margin: '10px 0px 10px 20px' }} />
											
											{/*
												<LinkA
												href={`https://parcel.giztix.com/`}
												>
												<ItemMenu active={this.selectActive('https://parcel.giztix.com/')}>{ this.props.t('common:header.parcelMenu') }</ItemMenu>
												</LinkA>
											*/}

											<LinkA
												href={`${landingUrl}/aboutus`}
											>
												<ItemMenu active={this.selectActive('/aboutus')}>{ this.props.t('common:header.aboutUs') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`${landingUrl}/contactus`}
											>
												<ItemMenu active={this.selectActive('/contactus')}>{ this.props.t('common:header.contactUs') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`${landingUrl}/career`}
											>
												<ItemMenu active={this.selectActive('/career')}>{ this.props.t('common:header.career') }</ItemMenu>
											</LinkA>

											<LinkA
												href={`https://lin.ee/ateOhRI`}
												target="_blank"
											>
												<ItemMenu>{ this.props.t('common:header.MenuDrivewithus') }</ItemMenu>
											</LinkA>
										</DrawerBody>

										<DrawerFooter>
											<div className="contact-header">{ this.props.t('common:header.contactCustomerService') }</div>
											<div className="contact-detail">
												<div>{ this.props.t('common:header.emailGiztix') } service@giztix.com</div>
												<div style={{width:"100%",float: "left"}}>
													<div style={{float:"left"}}>{ this.props.t('common:header.contactGiztix') } </div> 
													<div style={{float:"left",marginLeft:"5px"}}>02-026-6895 </div> 
												</div>
												<div>{ this.props.t('common:header.giztixOpenhours') }</div>
											</div>
										</DrawerFooter>
									</Drawer>
								</div>
								);
							}}
						</Query>
					);
				}}
			</Query>
		);
	}
}

Header.propTypes = {
	active: PropTypes.number,
	full: PropTypes.bool,
	bgcolor: PropTypes.string,
	border: PropTypes.bool,
	color: PropTypes.string,
};

Header.defaultProps = {
	active: null,
	full: false,
	border: true,
	color: 'black',
};

export default compose(
	graphql(AccountInfoList),
	graphql(notificationListQuery, {
		name: 'notificationListQuery'
	})
)(withNamespaces()(Header));
