import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: ${props => props.bgColor ? props.bgColor : '#000000'};
  border-radius: 3px;

  font-size: 11px;
  padding: 3px;

  &, &:active, &:visited, &:hover, &:focus {
    color: ${props => props.iconColor ? props.iconColor : '#FFFFFF'} !important;
  }
`

const IconButton = (props) => {
  return (
    <Container style={props.style} iconColor={props.iconColor} bgColor={props.bgColor} href={props.href} onClick={props.onClick} style={{marginLeft: 5}}><i className={props.icon}></i></Container>
  )
}

export default IconButton;