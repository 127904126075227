import React from 'react';
import {Image,Table} from "react-bootstrap";
class TablePacking extends React.Component {
  render() {
    return (
      <div>
          <Table style={{marginTop:"10px"}}>
            <thead>
                <tr>
                    <th style={{width:"5%"}}></th>
                    <th style={{width:"5%"}}></th>
                    <th style={{width:"20%"}}>ชื่อสินค้า</th>
                    <th style={{width:"10%"}}>ยาว(mm)</th>
                    <th style={{width:"10%"}}>กว้าง(mm)</th>
                    <th style={{width:"10%"}}>สูง(mm)</th>
                    <th style={{width:"10%"}}>น้ำหนัก(kg)</th>
                    <th style={{width:"10%"}}>จำนวน</th>
                    <th style={{width:"10%"}}>สี</th>
                </tr>
            </thead>
            <tbody>
            {this.props.children}
            </tbody>
        </Table>
      </div>
    )
  }
}

export default TablePacking;