import React from 'react';
import styled from 'styled-components';
import { ModalConfirm } from 'src/components';
import { Row, Col, Radio, Checkbox, FormControl } from 'react-bootstrap';
import { Input, SelectBox, Select, Label  } from '../../components';
import Helmet from 'react-helmet';
import color from 'src/config/color';
import provinceData from 'src/config/province/changwats/th.json';
import districtData from 'src/config/province/amphoes/th.json';
import subDistrictData from 'src/config/province/tambons/th.json';
import accountBusinessUpdateMutation from './graphql/mutation/accountBusinessUpdate';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { Mutation, Query } from 'react-apollo';
import landingUrl from 'src/config/landingUrl';
import urijs from 'urijs';
import client from 'src/config/client';
import baseUrl from 'src/config/baseUrl';
import { HeaderNav } from 'src/app/components';
import Registered from './container/Registered';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Block } from 'react-color/lib/components/block/Block';

//react tag input
const KeyCodes = {
  comma: 188,
  enter: 13,
};

//styled component
const Container = styled.div`
	padding-top: 160px;
	padding-bottom: 60px;

	@media(max-width: 767px) {
		padding-top: 30px;
	}
`;

const TextHeader = styled.div`
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
`;

const TextSubHeader = styled.div`
	font-size: 20px;
`;

const StepContainer = styled.div`
	position: relative;
	margin-top: 25px;

	& ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;
		display: inline-block;

		& li {
			display: inline-block;

			&:not(:last-child)::after {
				content: '';
				background-color: #B3B3B3;
				width: 77px;
				height: 1px;
				display: inline-block;
				position: relative;
				margin: 4px 10px;
			}

			& span {
				display: inline-block;
				width: 24px;
				height: 24px;
				background-color: #B3B3B3;
				border-radius: 50%;
				text-align: center;
				font-weight: bold;
				padding-bottom: 5px;
				line-height: 1.3;
				margin-right: 6px;
			}

			&.active span {
				background-color: ${color.Primary};
				color: #FFFFFF;
			}
		}
	}
`;

const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const LineHorizontal = styled.div`
	height: 1px;
	width: 100%;
	background-color: #E3E3E3;
`;

const ButtonNext = styled.button`
  background-color: ${color.Primary};
  color: #FFFFFF;
	font-size: 20px;
  border: 1px solid ${color.Primary};
	border-radius: 4px;
	cursor: pointer;
	padding: 0px 60px;
	padding-top: 2px;
	box-sizing: border-box;
	min-height: 40px;
	white-space: nowrap;

  &:focus {
    outline: none;
	}
	
	&:hover {
		opacity: .9;
	}

  &:active {
    background-color: ${color.White}'50';
  }

  &:disabled {
    opacity: .65;
    cursor: not-allowed;
	}

	& i {
		font-size: 1.2em;
		display: inline-block;
		float: right;
		width: 20px;
	}
`;

const ButtonBack = styled.button`
  background-color: transparent;
  color: #000000;
	font-size: 20px;
  border: 0px solid ${color.Primary};
	border-radius: 4px;
	cursor: pointer;
	padding: 0px;
	padding-top: 2px;
	box-sizing: border-box;
	height: 40px;

  &:focus {
    outline: none;
	}

	&:hover {
		opacity: .9;
	}

  &:disabled {
    opacity: .65;
    cursor: not-allowed;
	}

	& i {
		font-size: 1.2em;
		display: inline-block;
		float: left;
		width: 30px;
	}
`;

const CustomRadio = styled(Radio)`
	padding-left: 35px;
	margin-top: 12px;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	& input:checked ~ .checkmark {
		background-color: #FFFFFF;
	}

	&:hover .checkmark {
		background-color: #F1F1F1;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #ffffff;
		border-radius: 50%;
		border: 1px solid #E3E3E3;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	& input:checked ~ .checkmark:after {
		display: block;
	}

	& .checkmark:after {
		top: 5px;
		left: 5px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #19AA19;
 }
`;

const CustomCheckbox = styled(Checkbox)`
	padding-left: 35px;
	margin-top: 12px;

	& input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 24px;
		width: 24px;
		background-color: #ffffff;
		border: 1px solid #E3E3E3;
		border-radius: 4px;
	}

	&:hover input ~ .checkmark {
		background-color: #F1F1F1;
	}

	& input:checked ~ .checkmark {
		border: 1px solid #FFFFFF;
		background-color: #19AA19;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	& input:checked ~ .checkmark:after {
		display: block;
	}

	& .checkmark:after {
		left: 7px;
		top: 2px;
		width: 8px;
		height: 14px;
		border: solid #FFFFFF;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const Link = styled.a`
	&:hover, &:active, &:focus, &:visited {
		color: #1CB5E0;
		text-decoration: none;
	}
`;

const TextRight = styled.div`
  height: 40px;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const TextContent = styled.div`
	font-size: 20px;
	line-height: 1;
	margin-top: 10px;
`;

const TextMessageError = styled.span`
	font-size: 20px;
	color: #FF0000;
`;

const TagsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
`;

const TagsEmail = styled.div`
	font-size: 19px;
	border-radius: 6px;
	border: 1px solid #E3E3E3;
	padding: 9px;
	line-height: 1;
	background-color: #f9f9f9;
	margin-right: 10px;
	display: inline-block;
	margin-bottom: 10px;

	& span.delete {
		font-size: 14px;
		color: #cacaca;
		cursor: pointer;
		margin-left: 5px;
	}
`;

class Business extends React.Component {
	defaultLabel = '-- เลือก --';

	constructor(props) {
		super(props);

		this.state = {
			step: 1,

			//data step 1
			businessName: '',
			taxId: '',
			branch: 'สำนักงานใหญ่',
			postcode: '',
			businessAddress: '',
			country: { value: '', label: this.defaultLabel },
			province: { value: '', label: this.defaultLabel, pid: '' },
			district: { value: '', label: this.defaultLabel, pid: ''  },
			subdistrict: { value: '', label: this.defaultLabel },
			shipping_from_main_address: false,
			shipping_postcode: '',
			shipping_businessAddress: '',
			shipping_country: { value: '', label: this.defaultLabel },
			shipping_province: { value: '', label: this.defaultLabel, pid: '' },
			shipping_district: { value: '', label: this.defaultLabel, pid: ''  },
			shipping_subdistrict: { value: '', label: this.defaultLabel },
			businessType: { value: '', label: this.defaultLabel },
			phoneCode: '66',
			phoneNumber: '',
			phoneCodeCoordinator: '66',
			phoneNumberCoordinator: '',
			emailTags: [],
			invite_code: this.checkInvite(window.location.search),
			businessCode: '',
			recommend_company: '',

			//data step 2
			business_usage_information_1: '',
			business_usage_information_2: [],
			business_usage_information_3: [],
			product_type_other: '',
			truckType: [
				{id: "4 ล้อ", value: "4 ล้อ"},
				{id: "6 ล้อ", value: "6 ล้อ"},
				{id: "10 ล้อ", value: "10 ล้อ"},
				{id: "4 ล้อ (ควบคุมอุณหภูมิ)", value: "4 ล้อ (ควบคุมอุณหภูมิ)"},
				{id: "6 ล้อ (ควบคุมอุณหภูมิ)", value: "6 ล้อ (ควบคุมอุณหภูมิ)"},
				{id: "10 ล้อ (ควบคุมอุณหภูมิ)", value: "10 ล้อ (ควบคุมอุณหภูมิ)"},
				{id: "เทรลเลอร์", value: "เทรลเลอร์"},
			],

			//data step 3
			business_credit_req: '',
			business_edocument_receipt: 1,
			business_alway_wht: '',
			business_invoice_type: '',
			business_invoice_date_range_start: '',
			business_invoice_date_range_end: '',
			business_invoice_date_range_start_second: '',
			business_invoice_date_range_end_second: '',
			business_pay_round_type: '',
			business_pay_round_round_date: '',
			business_pay_round_after_invoice: '',
			acceptTerms: false,
			
			isOpenModalError: false,
			textModalError: '',
		}

		this._inputCredit = React.createRef();
		this.handleAddition.bind(this);
	}
	
	async componentDidMount() {
		this.setState({
			country: { value: 'ไทย', label: 'ไทย' },
			businessCode: urijs(window.location.href).query(true).business_code || '',
		});

		//set email อัตโนมัติจาก account info
		try {
			const { data: accountInfoListData } = await client.query({
				query: accountInfoListQuery,
				fetchPolicy: 'network-only',
			});

			const { accountInfoList: { account_info } } = accountInfoListData;
			const accountInfo = account_info[0];
			this.setState({emailTags: [{ id: '', text: accountInfo.account_info_email  }]});
		} catch(error) {
			console.log(error);
		}
	}

	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	selectOptionProvinceData() {
		let options = [
			{ value: '', label: this.defaultLabel, pid: '' },
		];

		provinceData.th.changwats.map((item, index) => {
			const province = { value: item.name, label: item.name, pid: item.pid };
			options.push(province);
			return province;
		});

		return options;
	}

	selectOptionDistrictData(provinceId) {
		let options = [{ value: '', label: this.defaultLabel, pid: '' }];

		let dataFilter = districtData.th.amphoes.filter((e) => e.changwat_pid === provinceId);
		dataFilter.map((item, index) => {
			const district = { value: item.name, label: item.name, pid: item.pid };
			options.push(district);
			return district;
		});

		return options;
	}

	selectOptionSubDistrictData(districtId) {
		let options = [
			{ value: '', label: this.defaultLabel },
		];

		let dataFilter = subDistrictData.th.tambons.filter((e) => e.amphoe_pid === districtId);
		dataFilter.map((item, index) => {
			const subdistrict = { value: item.name, label: item.name };
			options.push(subdistrict);
			return subdistrict;
		});

		return options;
	}

	changeCountry(event) {
		this.setState({ country: { value: event.value, label: event.label }}, () => {
			this.setState({ province: { value: '', label: this.defaultLabel, pid: '' }, district: { value: '', label: this.defaultLabel }, countryInvalid: false });
		})
	}

	changeProvince(event) {
		this.setState({ province: { value: event.value, label: event.label, pid: event.pid }}, () => {
			this.setState({ district: { value: '', label: this.defaultLabel }, provinceInvalid: false})
			this.selectOptionDistrictData();
		});
	}

	changeDistrict(event) {
		this.setState({ district: { value: event.value, label: event.label, pid: event.pid }}, () => {
			this.setState({ subdistrict: { value: '', label: this.defaultLabel }, districtInvalid: false})
			this.selectOptionSubDistrictData();
		});
	}

	changeSubDistrict(event) {
		this.setState({ subdistrict: { value: event.value, label: event.label }, subDistrictInvalid: false })
	}

	changeBusinessType(event) {
		this.setState({ businessType: { value: event.value, label: event.label }, businessTypeInvalid: false})
	}

	handleSelectProductType(event, props) {
		const {
			values,
			setFieldValue,
		} = props;
		
		if(values.business_usage_information_2.includes(event.target.value)) {
			const filter = values.business_usage_information_2.filter(item => item !== event.target.value);
			setFieldValue('business_usage_information_2', filter);
			return;
		}

		const addValue = values.business_usage_information_2.concat(event.target.value);
		setFieldValue('business_usage_information_2', addValue);
	}

	handleSelectTruckType(event, props) {
		const {
			values,
			setFieldValue,
		} = props;
		
		if(values.business_usage_information_3.includes(event.target.value)) {
			const filter = values.business_usage_information_3.filter(item => item !== event.target.value);
			setFieldValue('business_usage_information_3', filter);
			return;
		}

		const addValue = values.business_usage_information_3.concat(event.target.value);
		setFieldValue('business_usage_information_3', addValue);
  }
  
  handleSaveStepOne(values) {
	  	console.log(values)
		this.setState({
			businessName: values.businessName,
			taxId: values.taxId,
			businessAddress: values.businessAddress,
			branch: values.branch,
			country: values.country,
			province: values.province,
			district: values.district,
			subdistrict: values.subdistrict,
			postcode: values.postcode,
			shipping_from_main_address: values.shipping_from_main_address,
			shipping_postcode: values.shipping_postcode,
			shipping_businessAddress: values.shipping_businessAddress,
			shipping_country: values.shipping_country,
			shipping_province: values.shipping_province,
			shipping_district: values.shipping_district,
			shipping_subdistrict: values.shipping_subdistrict,
			businessType: values.businessType,
			phoneCode: values.phoneCode,
			phoneNumber: values.phoneNumber,
			phoneCodeCoordinator: values.phoneCodeCoordinator,
			phoneNumberCoordinator: values.phoneNumberCoordinator,
			emailTags: values.emailTags,
			invite_code: values.inviteCode,
			recommend_company:values.recommendCompany
		});
	}

	handleSaveStepTwo(values) {
		this.setState({
			business_usage_information_1:
				values.business_usage_information_1,
			business_usage_information_2:
				values.business_usage_information_2,
			business_usage_information_3:
				values.business_usage_information_3,
			product_type_other: values.product_type_other,
		});
	}

	handleSaveStepThree(values) {
		this.setState({
			business_credit_req: values.business_credit_req,
			business_edocument_receipt: values.business_edocument_receipt,
			business_alway_wht: values.business_alway_wht,
			business_invoice_type: values.business_invoice_type,
			business_invoice_date_range_start: values.business_invoice_date_range_start,
			business_invoice_date_range_end: values.business_invoice_date_range_end,
			business_invoice_date_range_start_second: values.business_invoice_date_range_start_second,
			business_invoice_date_range_end_second: values.business_invoice_date_range_end_second,
			business_pay_round_type: values.business_pay_round_type,
			business_pay_round_round_date: values.business_pay_round_round_date,
			business_pay_round_after_invoice: values.business_pay_round_after_invoice
		});
	}

	handleSendForm(accountBusinessUpdate) {
		accountBusinessUpdate({ variables: {
			account_info_business_name: this.state.businessName,
			account_info_tax_id: this.state.taxId,
			account_info_branch: this.state.branch,
			account_info_postcode: this.state.postcode,
			account_info_address: this.state.businessAddress,
			account_info_country: this.state.country.value,
			account_info_province: this.state.province.value,
			account_info_district: this.state.district.value,
			account_info_subdistrict: this.state.subdistrict.value,
			account_info_corporate_type: this.state.businessType.value,
			account_info_corporate_phone_code: this.state.phoneCode,
			account_info_corporate_phone_number: this.state.phoneNumber,
			account_info_corporate_co_phone_code: this.state.phoneCodeCoordinator,
			account_info_corporate_co_phone_number: this.state.phoneNumberCoordinator,
			account_business_email: this.state.emailTags.map(e => {
				return { email: e.text }
			}),
			business_usage_information_1: this.state.business_usage_information_1,
			business_usage_information_2: this.state.business_usage_information_2.map(item => {
				if(item === 'อื่นๆ') {
					return this.state.product_type_other;
				}
				
				return item;
			}),
			business_usage_information_3: this.state.business_usage_information_3,
			business_credit_req: this.formatNumber(this.state.business_credit_req, true),
			business_edocument_receipt: this.state.business_edocument_receipt,
			business_invoice_type: this.state.business_invoice_type,
			business_alway_wht: this.state.business_alway_wht,
			business_invoice_date_range: Number(this.state.business_invoice_type) === 3 ? {
				start: this.state.business_invoice_date_range_start,
				end: this.state.business_invoice_date_range_end,
			} 
			: Number(this.state.business_invoice_type) === 5 ? {
				start: this.state.business_invoice_date_range_start_second,
				end: this.state.business_invoice_date_range_end_second,
			} : undefined,
			shipping_from_main_address: this.state.shipping_from_main_address,
			account_info_shipping_address: [
				{
					address: this.state.shipping_businessAddress,
					country: this.state.shipping_country.value,
					province: this.state.shipping_province.value,
					district: this.state.shipping_district.value,
					subdistrict: this.state.shipping_subdistrict.value,
					postcode: this.state.shipping_postcode,
				}
			],
			business_pay_round: {
				round_date: Number(this.state.business_pay_round_type) === 1 ? this.state.business_pay_round_round_date : '0',
				after_invoice: Number(this.state.business_pay_round_type) === 2 ? this.state.business_pay_round_after_invoice : '0'
			},
			account_info_business_type_th : "ลูกค้าองค์กร/ธุรกิจ",
			business_status: 1,
			invite_code: this.state.invite_code,
			recommend_company: this.state.recommend_company,
			utm_source: this.getCookie("utm_source"),
			utm_medium: this.getCookie("utm_medium"),
			utm_campaign: this.getCookie("utm_campaign"),
			ip_address: this.getCookie("ip_address"),
		}})
	}
	
	checkEmail = email => {
		const validateEmail = /^(([a-zA-Z0-9]+([\_\.\-][a-zA-Z0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
		if (validateEmail.test(email)) {
			return true;
		}

		return false;
	};

	//credit input format
	formatNumber(number, revers) {
		//ป้องกันข้อมูลว่างแล้วค่าเป็น 0
		if(number === '') {
			return '';
		}

		if(revers === true) {
			// format number 1,234,567 to 1000000
			return Number(number.replace(/\D/g, ""));
		}
		
		// format number 1000000 to 1,234,567
		return Number(number.replace(/\D/g, "")).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	formatCurrency(event, inputRef) {
		// get input value
		var input_val = event.target.value;

		// original length
		var original_len = input_val.length;

		// initial caret position 
		var caret_pos = event.target.selectionStart;

		input_val = this.formatNumber(input_val);

		const updated_len = input_val.length;
		caret_pos = updated_len - original_len + caret_pos;
		inputRef.current.setSelectionRange(caret_pos, caret_pos);

		// send updated string to input
		return input_val;
	}

	checkInvite(hash){
		const uri = urijs(`${baseUrl}/${this.props.location.search}`);
		const params = uri.search(true);
		const invite_code = params.business_code;

		return invite_code;
	}

	handleAddition(event, props) {
		const {
			values,
			setFieldValue,
			setFieldError,
		} = props;

		if(this.checkEmail(event.target.value)) {
			const checkEmailSame = values.emailTags.filter(e => e.text === event.target.value);
			if(checkEmailSame.length === 0) {
				setFieldValue('emailTags', [...values.emailTags, { id: '', text: event.target.value }]);
				setFieldValue('addEmail', '');
			} else {
				setFieldError('addEmail', 'อีเมลนี้ถูกเพิ่มแล้ว');
			}
		} else {
			setFieldError('addEmail', 'รูปแบบอีเมลไม่ถูกต้อง');
		}
  }

  render() {
		//Validate Yup
		const validateStepOne = Yup.object().shape({
			businessName: Yup.string().required('กรุณากรอกข้อมูล'),
			taxId: Yup.string().matches(/^\d{13}$/, 'ป้อนตัวเลข 13 หลัก').required('กรุณากรอกข้อมูล'),
			businessAddress: Yup.string().required('กรุณากรอกข้อมูล'),
			branch: Yup.string().required('กรุณากรอกข้อมูล'),
			country: Yup.object().shape({
				value: Yup.string().required("กรุณากรอกข้อมูล"),
			}),
			province: Yup.object().shape({
				value: Yup.string().required("กรุณากรอกข้อมูล"),
			}),
			district: Yup.object().shape({
				value: Yup.string().required("กรุณากรอกข้อมูล"),
			}),
			subdistrict: Yup.object().shape({
				value: Yup.string().required("กรุณากรอกข้อมูล"),
			}),
			postcode: Yup.string().matches(/^\d{5}$/, 'ป้อนข้อมูล 5 ตัวอักษร').required('กรุณากรอกข้อมูล'),
			shipping_businessAddress: Yup.string().when('shipping_from_main_address', {
				is: data => {
					return data === false;
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			shipping_country: Yup.object().when('shipping_from_main_address', {
					is: data => {
						return data === false;
					},
					then:  Yup.object().shape({
						value: Yup.string().required('กรุณากรอกข้อมูล')
					}) ,
				}),
			shipping_province: Yup.object().when('shipping_from_main_address', {
				is: data => {
					return data === false;
				},
				then:  Yup.object().shape({
					value: Yup.string().required('กรุณากรอกข้อมูล')
				}) ,
			}),
			shipping_district: Yup.object().when('shipping_from_main_address', {
				is: data => {
					return data === false;
				},
				then:  Yup.object().shape({
					value: Yup.string().required('กรุณากรอกข้อมูล')
				}) ,
			}),
			shipping_subdistrict: Yup.object().when('shipping_from_main_address', {
				is: data => {
					return data === false;
				},
				then:  Yup.object().shape({
					value: Yup.string().required('กรุณากรอกข้อมูล')
				}) ,
			}),
			shipping_postcode: Yup.string().matches(/^\d{5}$/, 'ป้อนข้อมูล 5 ตัวอักษร').when('shipping_from_main_address', {
				is: data => {
					return data === false;
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			businessType: Yup.object().shape({
				value: Yup.string().required("กรุณากรอกข้อมูล"),
			}),
			phoneCode: Yup.string().required('กรุณากรอกข้อมูล'),
			phoneNumber: Yup.string().matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง').required('กรุณากรอกข้อมูล'),
			phoneCodeCoordinator: Yup.string().required('กรุณากรอกข้อมูล'),
			phoneNumberCoordinator: Yup.string().matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง'),
			emailTags: Yup.array().required('กรุณากรอกข้อมูล').of(
				Yup.object().shape({
					text: Yup.string().matches(
						 /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
						'รูปแบบไม่ถูกต้อง',
					),
				}),
			),
			addEmail: Yup.string().matches(
				/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			 'รูปแบบไม่ถูกต้อง',
		 	),
			inviteCode: Yup.string(),
		});

		const validateStepTwo = Yup.object().shape({
			business_usage_information_1: Yup.string().required('กรุณาเลือกรายการ'),
			business_usage_information_2: Yup.array().required('กรุณาเลือกรายการ'),
			business_usage_information_3: Yup.array().required('กรุณาเลือกรายการ'),
			product_type_other: Yup.string().when('business_usage_information_2', {
				is: arr => {
					return arr.includes('อื่นๆ');
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
		});

		const validateStepThree = Yup.object().shape({
			business_credit_req: Yup.string().required('กรุณากรอกข้อมูล'),
			business_edocument_receipt: Yup.string().required('กรุณากรอกข้อมูล'),
			business_alway_wht: Yup.string().required('กรุณากรอกข้อมูล'),
			business_invoice_type: Yup.string().required('กรุณากรอกข้อมูล'),
			business_invoice_date_range_start: Yup.string().when('business_invoice_type', {
				is: data => {
					return data === '3';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			business_invoice_date_range_end: Yup.string().when('business_invoice_type', {
				is: data => {
					return data === '3';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			business_invoice_date_range_start_second: Yup.string().when('business_invoice_type', {
				is: data => {
					return data === '5';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			business_invoice_date_range_end_second: Yup.string().when('business_invoice_type', {
				is: data => {
					return data === '5';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			business_pay_round_type: Yup.string().required('กรุณากรอกข้อมูล'),
			business_pay_round_round_date: Yup.string().when('business_pay_round_type', {
				is: data => {
					return data === '1';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			}),
			business_pay_round_after_invoice : Yup.string().when('business_pay_round_type', {
				is: data => {
					return data === '2';
				},
				then: Yup.string().required('กรุณากรอกข้อมูล'),
			})
		});

		const selectOptionTypeBusiness = [
			{ value: '', label: this.defaultLabel },
			{ value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
			{ value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
			{ value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
			{ value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
			{ value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
			{ value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
			{ value: 'การบริการ', label: 'การบริการ' },
			{ value: 'เครื่องจักร', label: 'เครื่องจักร' },
			{ value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
			{ value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
			{ value: 'บ้านและสวน', label: 'บ้านและสวน' },
			{ value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
			{ value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
			{ value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
			{ value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
			{ value: 'อื่นๆ', label: 'อื่นๆ' },
		];
		
		const selectOptionCountry = [
			{ value: '', label: this.defaultLabel },
			{ value: 'ไทย', label: 'ไทย' }
		];

    return (
      <div>
		<Helmet>
			<title>จิซทิกซ์สำหรับองค์กร/ธุรกิจ | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
			<meta
				name="description"
				content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
			/>
		</Helmet>
		<HeaderNav />

		<Query query={accountInfoListQuery} fetchPolicy="network-only">
					{
						({ loading, error, data, refetch }) => {
							if (loading) return null;

							return (
								<Container className="container">
									{
										!data || !data.accountInfoList || data.accountInfoList.account_business === null ? (
											<Row>
												<Col md={12}>
													<div>
														<TextHeader>การลงทะเบียนใช้งาน Giztix Business</TextHeader>
														<TextSubHeader>ระบุข้อมูลของบริษัทเพื่อการจองขนส่งสำหรับองค์กร/ธุรกิจ</TextSubHeader>
														
														<StepContainer>
															<ul>
																<li className={`${this.state.step >= 1 ? 'active' : null}`}><span>1</span>ข้อมูลบริษัท</li>
																<li className={`${this.state.step >= 2 ? 'active' : null}`}><span>2</span>ข้อมูลการใช้งาน</li>
																<li className={`${this.state.step >= 3 ? 'active' : null}`}><span>3</span>ข้อมูลการขอเครดิต</li>
															</ul>
														</StepContainer>

														<Mutation mutation={accountBusinessUpdateMutation}
															update={(cache, { data }) => {

															}}

															onError={(error) => {																							
																const errorMessage = error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'เกิดข้อผิดพลาดบางอย่างลองใหม่อีกครั้ง';
																this.setState({
																	isOpenModalError: true,
																	textModalError: errorMessage,
																});
															}}

															refetchQueries={() => ['AccountInfoList']}
														>
															{
																(accountBusinessUpdate, { loading: loadingBusinessUpdate }) => {
																	return (
																		<React.Fragment>
																			{
																				this.state.step === 1 && (
																					<React.Fragment>
																						<Formik
																							initialValues={{
																								businessName: this.state.businessName,
																								taxId: this.state.taxId,
																								businessAddress: this.state.businessAddress,
																								branch: this.state.branch,
																								country: this.state.country,
																								province: this.state.province,
																								district: this.state.district,
																								subdistrict: this.state.subdistrict,
																								postcode: this.state.postcode,
																								shipping_from_main_address: this.state.shipping_from_main_address,
																								shipping_businessAddress: this.state.shipping_businessAddress,
																								shipping_country: this.state.shipping_country,
																								shipping_province: this.state.shipping_province,
																								shipping_district: this.state.shipping_district,
																								shipping_subdistrict: this.state.shipping_subdistrict,
																								shipping_postcode: this.state.shipping_postcode,
																								businessType: this.state.businessType,
																								phoneCode: this.state.phoneCode,
																								phoneNumber: this.state.phoneNumber,
																								phoneCodeCoordinator: this.state.phoneCodeCoordinator,
																								phoneNumberCoordinator: this.state.phoneNumberCoordinator,
																								emailTags: this.state.emailTags,
																								addEmail: '',
																								inviteCode: this.state.invite_code,
																								recommendCompany: this.state.recommend_company,
																							}}
																							validationSchema={validateStepOne}
																							onSubmit={(values) => {
																								this.handleSaveStepOne(values);
																								this.setState({ step: 2 });
																								window.scrollTo(0, 0);
																							}}
																							enableReinitialize={true}
																						>
																							{
																								(props) => {
																									const {
																										setFieldValue,
																										setFieldError,
																										setFieldTouched,
																										handleChange,
																										handleSubmit,
																										handleBlur,
																										touched,
																										errors,
																										values,
																									} = props;
																									return (
																										<div style={{marginTop: 20}}>
																											<Row>
																												<Col md={6} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.businessName}
																														style={{marginTop: 10}}
																														inputLabel="ชื่อบริษัท"
																														placeholder="ชื่อบริษัทของคุณ"
																														messageError={(errors.businessName && touched.businessName) && errors.businessName}
																														name="businessName"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.taxId}
																														style={{marginTop: 10}}
																														inputLabel="เลขประจำตัวผู้เสียภาษีอากร"
																														placeholder="00000000000000"
																														messageError={(errors.taxId && touched.taxId) && errors.taxId}
																														name="taxId"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.businessAddress}
																														style={{marginTop: 10}}
																														inputLabel="ที่อยู่บริษัท (ภาษาไทย)"
																														placeholder="123/3"
																														messageError={(errors.businessAddress && touched.businessAddress) && errors.businessAddress}
																														name="businessAddress"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.branch}
																														style={{marginTop: 10}}
																														inputLabel="สาขา"
																														placeholder="สำนักงานใหญ่"
																														messageError={(errors.branch && touched.branch) && errors.branch}
																														name="branch"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("country" , { value: event.value, label: event.label });
																															setFieldTouched('country');
																															setFieldValue("province" , { value: '', label: this.defaultLabel, pid: null });
																															setFieldTouched('province', false);
																															setFieldValue("district" , { value: '', label: this.defaultLabel, pid: null });
																															setFieldTouched('district', false);
																															setFieldValue("subdistrict" , { value: '', label: this.defaultLabel });
																															setFieldTouched('subdistrict', false);
																														}}
																														onBlur={() => setFieldTouched('country')}
																														value={values.country}
																														style={{marginTop: 10}}
																														options={selectOptionCountry}
																														inputLabel="ประเทศ"
																														messageError={(errors.country && touched.country) && errors.country.value}
																														name="country"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("province" , { value: event.value, label: event.label, pid: event.pid });
																															setFieldTouched('province');
																															setFieldValue("district" , { value: '', label: this.defaultLabel, pid: null });
																															setFieldTouched('district', false);
																															setFieldValue("subdistrict" , { value: '', label: this.defaultLabel });
																															setFieldTouched('subdistrict', false);
																														}}
																														onBlur={() => setFieldTouched('province')}
																														value={values.province}
																														style={{marginTop: 10}}
																														options={this.selectOptionProvinceData()}
																														inputLabel="จังหวัด"
																														messageError={(errors.province && touched.province) && errors.province.value}
																														disabled={!values.country.value}
																														name="province"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("district" , { value: event.value, label: event.label, pid: event.pid });
																															setFieldTouched('district');
																															setFieldValue("subdistrict" , { value: '', label: this.defaultLabel });
																															setFieldTouched('subdistrict', false);
																														}}
																														onBlur={() => setFieldTouched('district')}
																														value={values.district}
																														style={{marginTop: 10}}
																														options={this.selectOptionDistrictData(values.province.pid)}
																														inputLabel="เขต/อำเภอ"
																														messageError={(errors.district && touched.district) && errors.district.value}
																														disabled={!values.province.value}
																														name="district"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("subdistrict" , { value: event.value, label: event.label });
																															setFieldTouched('subdistrict');
																														}}
																														onBlur={() => setFieldTouched('subdistrict')}
																														value={values.subdistrict}
																														style={{marginTop: 10}}
																														options={this.selectOptionSubDistrictData(values.district.pid)}
																														inputLabel="แขวง/ตำบล"
																														messageError={(errors.subdistrict && touched.subdistrict) && errors.subdistrict.value}
																														disabled={!values.district.value}
																														name="subdistrict"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.postcode}
																														style={{marginTop: 10}}
																														inputLabel="รหัสไปรษณีย์"
																														placeholder="10260"
																														messageError={(errors.postcode && touched.postcode) && errors.postcode}
																														name="postcode"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("businessType" , { value: event.value, label: event.label });
																															setFieldTouched('businessType');
																														}}
																														onBlur={() => setFieldTouched('businessType')}
																														value={values.businessType}
																														style={{marginTop: 10}}
																														options={selectOptionTypeBusiness}
																														inputLabel="ประเภทธุรกิจ"
																														messageError={(errors.businessType && touched.businessType) && errors.businessType.value}
																														name="businessType"
																														required
																													/>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<Label style={{ marginTop: 10, color: `${(errors.phoneNumber && touched.phoneNumber) ? '#FF0000': ''}`}} required>เบอร์โทรศัพท์</Label>
																													<InputGroup>
																														<Select
																															onChange={handleChange}
																															onBlur={handleBlur}
																															value={values.phoneCode}
																															style={{
																																borderTopRightRadius: 0,
																																borderBottomRightRadius: 0,
																																borderRightWidth: 0,
																																border: `${(errors.phoneNumber && touched.phoneNumber) ? '1px solid #FF0000': ''}`
																															}}
																															name="phoneCode"
																														>
																															<option value="66">+66</option>
																														</Select>
																														<Input
																															onChange={handleChange}
																															onBlur={handleBlur}
																															value={values.phoneNumber}
																															placeholder="0123456789"
																															style={{width: '100%'}}
																															styleInput={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}
																															messageError={(errors.phoneNumber && touched.phoneNumber) && errors.phoneNumber}
																															name="phoneNumber"
																														/>
																													</InputGroup>
																												</Col>
																												
																												<Col md={6} sm={12}>
																													<Label style={{ marginTop: 10, color: `${(errors.phoneNumberCoordinator && touched.phoneNumberCoordinator) ? '#FF0000': ''}`}}>เบอร์เจ้าหน้าที่ประสานงาน</Label>
																													<InputGroup>
																														<Select
																															onChange={handleChange}
																															onBlur={handleBlur}
																															value={values.phoneCodeCoordinator}
																															style={{
																																borderTopRightRadius: 0,
																																borderBottomRightRadius: 0,
																																borderRightWidth: 0,
																																border: `${(errors.phoneNumberCoordinator && touched.phoneNumberCoordinator) ? '1px solid #FF0000': ''}`
																															}}
																															name="phoneCodeCoordinator"
																														>
																															<option value="66">+66</option>
																														</Select>
					
																														<Input
																															onChange={handleChange}
																															onBlur={handleBlur}
																															value={values.phoneNumberCoordinator}
																															placeholder="0123456789"
																															style={{width: '100%'}}
																															styleInput={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}
																															messageError={(errors.phoneNumberCoordinator && touched.phoneNumberCoordinator) && errors.phoneNumberCoordinator}
																															name="phoneNumberCoordinator"
																														/>
																													</InputGroup>
																												</Col>
																												
																												

																												<Col sm={12}>
																													<Label inline style={{ marginTop: 10, color: `${(errors.addEmail && touched.addEmail) || (errors.emailTags && touched.emailTags) ? '#FF0000': ''}` }} required>อีเมล์ เพื่อใช้ในการรับ เอกสารสำคัญ (กด enter เพื่อเพิ่มอีเมล)</Label> <span style={{color: '#FF0000'}}>{(errors.emailTags && touched.emailTags) && errors.emailTags}</span>
																												</Col>
					
																												<Col sm={12}>
																													<TagsContainer>
																														{
																															values.emailTags.length > 0 && values.emailTags.map((e, index) => (<TagsEmail key={index}>{ e.text }<span className="delete" onClick={() => {
																																setFieldValue('emailTags', values.emailTags.filter(item => item.text !== e.text))
																															}}> <i className="far fa-times"></i></span></TagsEmail>))
																														}
					
																														<Input
																															onChange={handleChange('addEmail')}
																															onBlur={(event) => {
																																setFieldTouched('addEmail');
																																this.handleAddition(event, props);
																															}}
																															onKeyPress={(event) => {
																																if(event.charCode === KeyCodes.enter || event.charCode === KeyCodes.comma) {
																																	setFieldTouched('addEmail');
																																	this.handleAddition(event, props);
																																}
																															}}
																															value={values.addEmail}
																															style={{ display: 'inline-block' }}
																															styleInput={{width: 250}} 
																															placeholder="user@email.com"
																															messageError={(errors.addEmail && touched.addEmail) && errors.addEmail}
																														/>
																													</TagsContainer>
																												</Col>
					
																												<Col md={6} sm={12}>
																													<Input
																														disabled={this.state.businessCode}
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.inviteCode}
																														style={{marginTop: 10}}
																														inputLabel="รหัสเชิญชวน"
																														placeholder="รหัสเชิญชวน"
																														name="inviteCode"
																													/>
																												</Col>

																												<Col md={6} sm={12}>
																													<Input
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.recommendCompany}
																														style={{marginTop: 10}}
																														inputLabel="บริษัทที่แนะนำ"
																														placeholder="บริษัทที่แนะนำ"
																														name="recommendCompany"
																													/>
																												</Col>
																											</Row>

																											<LineHorizontal/>

																											<Row>
																												{/*shipping address*/}
																												<Col sm={12}>
																													<Label inline style={{ marginTop: 10, fontSize: 30 }}>ที่อยู่สำหรับจัดส่งเอกสาร</Label> 
																													<CustomCheckbox
																															value={values.shipping_from_main_address} 
																															checked={values.shipping_from_main_address} 
																															onClick={(event) => {
																																console.log(event.target.checked)
																																setFieldValue('shipping_from_main_address', event.target.checked);
																																setFieldValue('shipping_businessAddress', values.businessAddress);
																																setFieldValue('shipping_country', values.country);
																																setFieldValue('shipping_province', values.province);
																																setFieldValue('shipping_district', values.district);
																																setFieldValue('shipping_subdistrict', values.subdistrict);
																																setFieldValue('shipping_postcode', values.postcode);
																															}}
																														>
																															<span className="checkmark"></span> ใช้ที่อยู่เดียวกันกับที่อยู่บริษัท
																														</CustomCheckbox>
																												</Col>

																												<Col md={9} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.shipping_businessAddress}
																														style={{marginTop: 10}}
																														inputLabel="ที่อยู่บริษัท (ภาษาไทย)"
																														placeholder="123/3"
																														messageError={(errors.shipping_businessAddress && touched.shipping_businessAddress) && errors.shipping_businessAddress}
																														name="shipping_businessAddress"
																														required={!values.shipping_from_main_address ? true : false}
																														disabled={values.shipping_from_main_address}
																													/>
																												</Col>

																												<Col md={3} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("shipping_country" , { value: event.value, label: event.label });
																															setFieldTouched('shipping_country');
																															setFieldValue("shipping_province" , { value: '', label: this.defaultLabel, pid: null });
																															setFieldTouched('shipping_province', false);
																															setFieldValue("shipping_district" , { value: '', label: this.defaultLabel, pid: null });
																															setFieldTouched('shipping_district', false);
																															setFieldValue("shipping_subdistrict" , { value: '', label: this.defaultLabel });
																															setFieldTouched('shipping_subdistrict', false);
																														}}
																														onBlur={() => setFieldTouched('shipping_country')}
																														value={values.shipping_country}
																														style={{marginTop: 10}}
																														options={selectOptionCountry}
																														inputLabel="ประเทศ"
																														messageError={(errors.shipping_country && touched.shipping_country) && errors.shipping_country.value}
																														name="shipping_country"
																														required={!values.shipping_from_main_address ? true : false}
																														disabled={values.shipping_from_main_address}
																													/>
																												</Col>

																												<Col md={3} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("shipping_province" , { value: event.value, label: event.label, pid: event.pid });
																															setFieldTouched('shipping_province');
																															setFieldValue("shipping_district" , { value: '', label: this.defaultLabel, pid: null });
																															setFieldTouched('shipping_district', false);
																															setFieldValue("shipping_subdistrict" , { value: '', label: this.defaultLabel });
																															setFieldTouched('shipping_subdistrict', false);
																														}}
																														onBlur={() => setFieldTouched('shipping_province')}
																														value={values.shipping_province}
																														style={{marginTop: 10}}
																														options={this.selectOptionProvinceData()}
																														inputLabel="จังหวัด"
																														messageError={(errors.shipping_province && touched.shipping_province) && errors.shipping_province.value}
																														disabled={!values.shipping_country.value || values.shipping_from_main_address}
																														name="shipping_province"
																														required={!values.shipping_from_main_address ? true : false}
																													/>
																												</Col>

																												<Col md={3} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("shipping_district" , { value: event.value, label: event.label, pid: event.pid });
																															setFieldTouched('shipping_district');
																															setFieldValue("shipping_subdistrict" , { value: '', label: this.defaultLabel });
																															setFieldTouched('shipping_subdistrict', false);
																														}}
																														onBlur={() => setFieldTouched('shipping_district')}
																														value={values.shipping_district}
																														style={{marginTop: 10}}
																														options={this.selectOptionDistrictData(values.shipping_province.pid)}
																														inputLabel="เขต/อำเภอ"
																														messageError={(errors.shipping_district && touched.shipping_district) && errors.shipping_district.value}
																														disabled={!values.shipping_province.value || values.shipping_from_main_address}
																														name="shipping_district"
																														required={!values.shipping_from_main_address ? true : false}
																													/>
																												</Col>

																												<Col md={3} sm={12}>
																													<SelectBox
																														onChange={(event) => {
																															setFieldValue("shipping_subdistrict" , { value: event.value, label: event.label });
																															setFieldTouched('shipping_subdistrict');
																														}}
																														onBlur={() => setFieldTouched('shipping_subdistrict')}
																														value={values.shipping_subdistrict}
																														style={{marginTop: 10}}
																														options={this.selectOptionSubDistrictData(values.shipping_district.pid)}
																														inputLabel="แขวง/ตำบล"
																														messageError={(errors.shipping_subdistrict && touched.shipping_subdistrict) && errors.shipping_subdistrict.value}
																														disabled={!values.shipping_district.value || values.shipping_from_main_address}
																														name="shipping_subdistrict"
																														required={!values.shipping_from_main_address ? true : false}
																													/>
																												</Col>

																												<Col md={3} sm={12}>
																													<Input 
																														onChange={handleChange}
																														onBlur={handleBlur}
																														value={values.shipping_postcode}
																														style={{marginTop: 10}}
																														inputLabel="รหัสไปรษณีย์"
																														placeholder="10260"
																														messageError={(errors.shipping_postcode && touched.shipping_postcode) && errors.shipping_postcode}
																														name="shipping_postcode"
																														required={!values.shipping_from_main_address ? true : false}
																														disabled={values.shipping_from_main_address}
																													/>
																												</Col>

																											</Row>
					
																											<LineHorizontal style={{ marginTop: 40, marginBottom: 40 }}/>
					
																											<div>
																												<Row>
																													<Col md={6} sm={6}></Col>
																													<Col md={6} sm={6}>
																														<div style={{textAlign: 'right'}}>
																															<ButtonNext onClick={() => handleSubmit()}>ต่อไป <i className="fal fa-angle-right"></i></ButtonNext>
																														</div>
																													</Col>
																												</Row>
																											</div>
																										</div>
																									)
																								}
																							}
																						</Formik>
																					</React.Fragment>
																				)
																			}
					
																			{
																				this.state.step === 2 && (
																					<div>
																						<Formik
																							validationSchema={validateStepTwo}
																							initialValues={{
																								business_usage_information_1:
																									this.state.business_usage_information_1,
																								business_usage_information_2:
																									this.state.business_usage_information_2,
																								business_usage_information_3:
																									this.state.business_usage_information_3,
																								product_type_other: this.state.product_type_other,
																							}}
																							onSubmit={(values) => {
																								this.handleSaveStepTwo(values);
																								this.setState({ step: 3 });
																								window.scrollTo(0, 0);
																							}}
																						>
																							{
																								(props) => {
																									const {
																										setFieldValue,
																										setFieldTouched,
																										handleChange,
																										handleSubmit,
																										handleBlur,
																										touched,
																										errors,
																										values,
																									} = props;
					
																									return (
																										<React.Fragment>
																											<div style={{marginTop: 40}}>
																												<Label inline required>1. คาดว่าบริษัทของท่านจะใช้บริการขนส่ง</Label> { errors.business_usage_information_1 && touched.business_usage_information_1 && (<TextMessageError>{errors.business_usage_information_1}</TextMessageError>) }
																												<Row>
																													<Col md={3}>
																														<CustomRadio
																															value={`1 ครั้ง ต่อวัน`}
																															checked={values.business_usage_information_1 === '1 ครั้ง ต่อวัน'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_usage_information_1',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_usage_information_1')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 ครั้ง ต่อวัน
																														</CustomRadio>
																													</Col>
																													<Col md={3}>
																														<CustomRadio 
																															value={`มากกว่า 1 ครั้งต่อวัน`}
																															checked={values.business_usage_information_1 === 'มากกว่า 1 ครั้งต่อวัน'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_usage_information_1',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_usage_information_1')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> มากกว่า 1 ครั้งต่อวัน
																														</CustomRadio>
																													</Col>
																													<Col md={3}>
																														<CustomRadio 
																															value={`1 - 5 ครั้ง/สัปดาห์`}
																															checked={values.business_usage_information_1 === '1 - 5 ครั้ง/สัปดาห์'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_usage_information_1',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_usage_information_1')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 - 5 ครั้ง/สัปดาห์
																														</CustomRadio>
																													</Col>
																												</Row>
																											</div>
					
																											<div style={{marginTop: 60}}>
																												<Label inline required>2. ประเภทสินค้าที่ต้องการจัดส่ง (เลือกได้มากกว่า 1)</Label> { errors.business_usage_information_2 && touched.business_usage_information_2 && (<TextMessageError>{errors.business_usage_information_2}</TextMessageError>) }
																												<Row>
																													<Col md={3}>
																														<CustomCheckbox 
																															inline
																															value="สินค้าทั่วไป"
																															checked={values.business_usage_information_2.includes('สินค้าทั่วไป')}
																															onClick={(event) => this.handleSelectProductType(event, props)}
																															onBlur={() => setFieldTouched('business_usage_information_2')}
																														>
																															<span className="checkmark"></span> สินค้าทั่วไป
																														</CustomCheckbox>
																													</Col>
																													<Col md={3}>
																														<CustomCheckbox
																															inline
																															value="รักษาอุณหภูมิ"
																															checked={values.business_usage_information_2.includes('รักษาอุณหภูมิ')}
																															onClick={(event) => this.handleSelectProductType(event, props)}
																															onBlur={() => setFieldTouched('business_usage_information_2')}
																														>
																															<span className="checkmark"></span> รักษาอุณหภูมิ
																														</CustomCheckbox>
																													</Col>
																													<Col md={3}>
																														<CustomCheckbox 
																															inline
																															value="กลุ่มสินค้าอันตราย"
																															checked={values.business_usage_information_2.includes('กลุ่มสินค้าอันตราย')}
																															onClick={(event) => this.handleSelectProductType(event, props)}
																															onBlur={() => setFieldTouched('business_usage_information_2')}
																														>
																															<span className="checkmark"></span> กลุ่มสินค้าอันตราย
																														</CustomCheckbox>
																													</Col>
																													<Col md={6}>
																														<CustomCheckbox
																															inline
																															value="อื่นๆ"
																															checked={values.business_usage_information_2.includes('อื่นๆ')}
																															onClick={(event) => this.handleSelectProductType(event, props)}
																															onBlur={() => setFieldTouched('business_usage_information_2')}
																														>
																															<span className="checkmark"></span> อื่นๆ
																														</CustomCheckbox>
					
																														<Input
																															styleInput={{border: `${errors.product_type_other && touched.product_type_other ? '1px solid #FF0000': ''}`}}
																															style={{marginLeft: 30, marginTop: 20, width: 135, display: 'inline-block'}}
																															value={values.product_type_other}
																															onChange={handleChange}
																															onBlur={handleBlur}
																															placeholder="ระบุ"
																															name="product_type_other"
																														/>
																													</Col>
																												</Row>
																											</div>
					
																											<div style={{marginTop: 60}}>
																												<Label inline required>3. ประเภทรถที่ต้องการจัดส่ง (เลือกได้มากกว่า 1)</Label> { errors.business_usage_information_3 && touched.business_usage_information_3 && (<TextMessageError>{errors.business_usage_information_3}</TextMessageError>) }
																												<Row>
																													{
																														this.state.truckType.map((e) => {
																															return (
																																<Col md={3}>
																																	<CustomCheckbox
																																		inline
																																		value={e.value}
																																		checked={values.business_usage_information_3.includes(e.value)}
																																		onClick={(event) => this.handleSelectTruckType(event, props)}
																																		onBlur={() => setFieldTouched('business_usage_information_3')}
																																	>
																																		<span className="checkmark"></span> {e.value}
																																	</CustomCheckbox>
																																</Col>
																															)
																														})
																													}
																												</Row>
																											</div>
					
																											<LineHorizontal style={{ marginTop: 150, marginBottom: 40 }}/>
					
																											<div>
																												<Row>
																													<Col md={6} sm={6} xs={6}>
																														<ButtonBack onClick={() => {
																															this.handleSaveStepTwo(values);
																															this.setState({ step: 1 });
																															window.scrollTo(0, 0);
																														}}><i className="fal fa-angle-left"></i>ก่อนหน้านี้</ButtonBack>
																													</Col>
																													<Col md={6} sm={6} xs={6}>
																														<div style={{textAlign: 'right'}}>
																															<ButtonNext onClick={() => handleSubmit()}>ต่อไป <i className="fal fa-angle-right"></i></ButtonNext>
																														</div>
																													</Col>
																												</Row>
																											</div>
																										</React.Fragment>
																									)
																								}
																							}
																						</Formik>
																					</div>
																				)
																			}
					
																			{
																				this.state.step === 3 && (
																					<div>
																						<Formik
																							validationSchema={validateStepThree}
																							initialValues={{
																								business_credit_req:
																									this.state.business_credit_req,
																								business_edocument_receipt:
																									this.state.business_edocument_receipt,
																								business_alway_wht:
																									this.state.business_alway_wht,
																								business_invoice_type: this.state.business_invoice_type,
																								business_invoice_date_range_start: this.state.business_invoice_date_range_start,
																								business_invoice_date_range_end: this.state.business_invoice_date_range_end,
																								business_invoice_date_range_start_second: this.state.business_invoice_date_range_start_second,
																								business_invoice_date_range_end_second: this.state.business_invoice_date_range_end_second,
																								business_pay_round_type: this.state.business_pay_round_type,
																								business_pay_round_round_date: this.state.business_pay_round_round_date,
																								business_pay_round_after_invoice: this.state.business_pay_round_after_invoice,
																							}}
																							onSubmit={(values) => {
																								this.handleSaveStepThree(values);
																								this.handleSendForm(accountBusinessUpdate);
																							}}
																							enableReinitialize={true}
																						>
																							{
																								(props) => {
																									const {
																										setFieldValue,
																										setFieldTouched,
																										handleSubmit,
																										handleBlur,
																										touched,
																										errors,
																										values,
																									} = props;
					
																									return (
																										<React.Fragment>
																											<div style={{marginTop: 40}}>
																												<Label style={{color: `${(errors.business_credit_req && touched.business_credit_req) ? '#FF0000': ''}` }} inline required>1. วงเงินเครดิตที่ต้องการ</Label>
																												<Row>
																													<Col md={6}>
																														<InputGroup style={{marginTop: 6}}>
																															<Input
																																type="text" 
																																value={values.business_credit_req}
																																inputRef={this._inputCredit}
																																onChange={(event) => {
																																	setFieldValue('business_credit_req', this.formatCurrency(event, this._inputCredit));
																																}}
																																onBlur={handleBlur}
																																style={{width: '100%'}} 
																																styleInput={{borderBottomRightRadius: 0, borderTopRightRadius: 0}} 
																																placeholder="จำนวนเงิน"  
																																messageError={(errors.business_credit_req && touched.business_credit_req) && errors.business_credit_req} 
																																name="business_credit_req"
																															/>
																															<TextRight style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderLeftWidth: 0, border: `${(errors.business_credit_req && touched.business_credit_req) ? '1px solid #FF0000': ''}`}}>บาท</TextRight>
																														</InputGroup>
					
																														<TextContent style={{color: '#B3B3B3'}}>
																															*จำนวนวงเงินเครดิตที่คุณระบุไว้อาจไม่ใช่จำนวนจริงที่คุณจะได้รับ 
																															ทั้งนี้จำนวนที่ได้รับเป็นไปตามการพิจารณาจากทาง Giztix เท่านั้น
																														</TextContent>
																													</Col>
																												</Row>
																											</div>
					
																											<div style={{marginTop: 40}}>
																												<Label>2. ช่องทางการรับเอกสารใบแจ้งหนี้และใบเสร็จรับเงิน</Label>
																												<Row>
																													<Col md={12}>
																														<CustomCheckbox
																															disabled
																															inline
																															onChange={() => {
																																setFieldTouched('business_edocument_receipt');
																																setFieldValue('business_edocument_receipt', values.business_edocument_receipt === 1 ? 2 : 1);
																															}}
																															checked={values.business_edocument_receipt ? 1 : 2}
																															onBlur={() => setFieldTouched('business_edocument_receipt')}
																														>
																															<span className="checkmark"></span> ท่านยอมรับการรับเอกสารผ่านช่องทาง E-Invoice/E-Receipt
																														</CustomCheckbox>
																													</Col>
																												</Row>
																											</div>
					
																											<div style={{marginTop: 40}}>
																												<Label inline required>3. คุณต้องการทำหัก ณ ที่จ่ายค่าขนส่งทุกครั้งที่ใช้บริการหรือไม่</Label>  { (errors.business_alway_wht && touched.business_alway_wht) && (<TextMessageError>{errors.business_alway_wht}</TextMessageError>) }
																												<Row>
																													<Col lg={3} md={4} sm={12}>
																														<CustomRadio 
																															value={`1`}
																															checked={values.business_alway_wht === '1'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_alway_wht',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_alway_wht')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> ต้องการ
																														</CustomRadio>
																													</Col>
																													<Col lg={3} md={4} sm={12}>
																														<CustomRadio
																															value={`2`}
																															checked={values.business_alway_wht === '2'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_alway_wht',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_alway_wht')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> ไม่ต้องการ
																														</CustomRadio>
																													</Col>
																												</Row>
																											</div>
					
																											<div style={{marginTop: 40}}>
																												<Label inline required>4. คุณต้องการรับเอกสารใบแจ้งหนี้เมื่อใด</Label> { (errors.business_invoice_type && touched.business_invoice_type) && (<TextMessageError>{errors.business_invoice_type}</TextMessageError>) }
																												<Row>
																													<Col md={12}>
																														<CustomRadio
																															value={`1`}
																															checked={values.business_invoice_type === '1'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_invoice_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_invoice_type')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลทุกสิ้นเดือน
																														</CustomRadio>
																													</Col>
																													<Col md={12}>
																														<CustomRadio
																															value={`2`}
																															checked={values.business_invoice_type === '2'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_invoice_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_invoice_type')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และวางบิลทุกวันหลังจบงาน
																														</CustomRadio>
																													</Col>
																													<Col md={12}>
																														<CustomRadio
																															value={`3`}
																															checked={values.business_invoice_type === '3'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_invoice_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_invoice_type');
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1 งาน และรวบวางบิลตามวันที่กำหนด (โปรดระบุวัน) {' '}
																															<br/>
																															<FormControl 
																																onChange={(event) => {
																																	if(Number(event.target.value) > Number(values.business_invoice_date_range_end)) {
																																		setFieldValue('business_invoice_date_range_end', '');
																																	}

																																	setFieldValue('business_invoice_date_range_start', event.target.value);
																																}}
																																onBlur={handleBlur('business_invoice_date_range_start')}
																																componentClass="select"
																																placeholder="select"
																																style={{
																																	display: 'inline-block',
																																	width: 80,
																																	fontSize: 18,
																																	marginLeft: 5,
																																	marginRight: 5,
																																	border: (errors.business_invoice_date_range_start && touched.business_invoice_date_range_start) ? '1px solid #FF0000' : ''
																																}}
																																value={values.business_invoice_date_range_start}
																																name="business_invoice_date_range_start"
																															>
																																<option value="">วันที่</option>
																																{Array.from(Array(31), (e, i) => (
																																	<option key={i} value={i+1}>{i+1}</option>
																																))}
																															</FormControl>
																															{` ถึง `}
																															<FormControl
																																onChange={(event) => {
																																	if(Number(event.target.value) < Number(values.business_invoice_date_range_start)) {
																																		setFieldValue('business_invoice_date_range_start', '');
																																	}

																																	setFieldValue('business_invoice_date_range_end', event.target.value);
																																}}
																																onBlur={handleBlur('business_invoice_date_range_end')}
																																componentClass="select"
																																placeholder="select"
																																style={{
																																	display: 'inline-block',
																																	width: 80,
																																	fontSize: 18,
																																	marginLeft: 5,
																																	marginRight: 5,
																																	border: (errors.business_invoice_date_range_end && touched.business_invoice_date_range_end) ? '1px solid #FF0000' : ''
																																}}
																																value={values.business_invoice_date_range_end}
																																name="business_invoice_date_range_end"
																															>
																																<option value="">วันที่</option>
																																{Array.from(Array(31), (e, i) => (
																																	<option key={i} value={i+1}>{i+1}</option>
																																))}
																															</FormControl>
																															{` ของทุกเดือน`}
																														</CustomRadio>
																													</Col>
																													<Col md={12}>
																														<CustomRadio
																															value={`4`}
																															checked={values.business_invoice_type === '4'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_invoice_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_invoice_type')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน
																														</CustomRadio>
																													</Col>
																													<Col md={12}>
																														<CustomRadio
																															value={`5`}
																															checked={values.business_invoice_type === '5'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_invoice_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_invoice_type');
																															}}
																															inline
																														>
																															<span className="checkmark"></span> 1 ใบแจ้งหนี้ สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด (โปรดระบุวัน) {' '}
																															<br/>
																															<FormControl 
																																onChange={(event) => {
																																	if(Number(event.target.value) > Number(values.business_invoice_date_range_end_second)) {
																																		setFieldValue('business_invoice_date_range_end_second', '');
																																	}

																																	setFieldValue('business_invoice_date_range_start_second', event.target.value);
																																}}
																																onBlur={handleBlur('business_invoice_date_range_start_second')}
																																componentClass="select"
																																placeholder="select"
																																style={{
																																	display: 'inline-block',
																																	width: 80,
																																	fontSize: 18,
																																	marginLeft: 5,
																																	marginRight: 5,
																																	border: (errors.business_invoice_date_range_start_second && touched.business_invoice_date_range_start_second) ? '1px solid #FF0000' : ''
																																}}
																																value={values.business_invoice_date_range_start_second}
																																name="business_invoice_date_range_start_second"
																															>
																																<option value="">วันที่</option>
																																{Array.from(Array(27), (e, i) => (
																																	<option key={i + 4} value={i+5}>{i+5}</option>
																																))}
																															</FormControl>
																															{` ถึง `}
																															<FormControl
																																onChange={(event) => {
																																	if(Number(event.target.value) < Number(values.business_invoice_date_range_start_second)) {
																																		setFieldValue('business_invoice_date_range_start_second', '');
																																	}

																																	setFieldValue('business_invoice_date_range_end_second', event.target.value);
																																}}
																																onBlur={handleBlur('business_invoice_date_range_end_second')}
																																componentClass="select"
																																placeholder="select"
																																style={{
																																	display: 'inline-block',
																																	width: 80,
																																	fontSize: 18,
																																	marginLeft: 5,
																																	marginRight: 5,
																																	border: (errors.business_invoice_date_range_end_second && touched.business_invoice_date_range_end_second) ? '1px solid #FF0000' : ''
																																}}
																																value={values.business_invoice_date_range_end_second}
																																name="business_invoice_date_range_end_second"
																															>
																																<option value="">วันที่</option>
																																{Array.from(Array(27), (e, i) => (
																																	<option key={i+4} value={i+5}>{i+5}</option>
																																))}
																															</FormControl>
																															{` ของทุกเดือน`}
																														</CustomRadio>
																													</Col>
																												</Row>
																											</div>

																											<div style={{marginTop: 40}}>
																												<Label inline required>5. คุณต้องการชำระเงินค่าบริการเมื่อใด</Label> { (errors.business_pay_round_type && touched.business_pay_round_type) && (<TextMessageError>{errors.business_pay_round_type}</TextMessageError>) }
																												<Row>
																													<Col lg={3} md={4} sm={12}>
																														<CustomRadio
																															value={`1`}
																															checked={values.business_pay_round_type === '1'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_pay_round_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_pay_round_type')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> ทุกวันที่
																														</CustomRadio>
																														<InputGroup style={{marginLeft: 30}} >
																															<FormControl 
																																onChange={(event) => {
																																	setFieldValue('business_pay_round_round_date', event.target.value);
																																}}
																																onBlur={handleBlur('business_pay_round_round_date')}
																																componentClass="select"
																																placeholder="select"
																																style={{
																																	width: 80,
																																	fontSize: 18,
																																	marginLeft: 5,
																																	marginRight: 5,
																																	border: (errors.business_pay_round_round_date && touched.business_pay_round_round_date) ? '1px solid #FF0000' : ''
																																}}
																																value={values.business_pay_round_round_date}
																																name="business_pay_round_round_date"
																															>
																																<option value="">วันที่</option>
																																{Array.from(Array(31), (e, i) => (
																																	<option key={i} value={i+1}>{i+1}</option>
																																))}
																															</FormControl>
																															{' ของเดือน'}	
																														</InputGroup>	
																													</Col>
																													<Col lg={3} md={4} sm={12}>
																														<CustomRadio
																															value={`2`}
																															checked={values.business_pay_round_type === '2'}
																															onChange={(event) => {
																																setFieldValue(
																																	'business_pay_round_type',
																																	event.target.value,
																																);
																															}}
																															onBlur={() => {
																																setFieldTouched('business_pay_round_type')
																															}}
																															inline
																														>
																															<span className="checkmark"></span> หลังจากวันแจ้งหนี้
																														</CustomRadio>	
																														<InputGroup style={{marginLeft: 30}} >
																															<Input  
																																type="number" 
																																value={values.business_pay_round_after_invoice}
																																onChange={(event) => {
																																	setFieldValue('business_pay_round_after_invoice', event.target.value);
																																}}
																																style={{
																																	width: 80, 
																																	marginRight: 10,
																																	border: (errors.business_pay_round_after_invoice && touched.business_pay_round_after_invoice) ? '1px solid #FF0000' : ''
																																}}
																															/>	
																															{' วัน'}	
																														</InputGroup>		
																													</Col>
																												</Row>			
																											</div>
					
																											<div style={{marginTop: 60}}>
																												<Row>
																													<Col md={7}>
																														<CustomCheckbox value={this.state.acceptTerms} checked={this.state.acceptTerms} onClick={(event) => this.setState({acceptTerms: event.target.checked})} inline><span className="checkmark"></span> ในการลงทะเบียน จะถือว่าคุณยอมรับ <Link href={landingUrl + '/termscondition'} target="_blank">เงื่อนไขข้อกำหนด Giztix Business</Link>, <Link href={landingUrl + '/policy'} target="_blank">เงื่อนไขข้อกำหนดการจัดส่งสินค้าและการรับประกัน </Link> และ <Link href={landingUrl + '/policy'} target="_blank">นโยบายคืนเงิน</Link> สำหรับการให้บริการของ Giztix Business ทั้งหมด</CustomCheckbox>
																													</Col>
																												</Row>
																											</div>
					
																											<LineHorizontal style={{ marginTop: 170, marginBottom: 40 }}/>
					
																											<div>
																												<Row>
																													<Col md={6} sm={6} xs={6}>
																														<ButtonBack onClick={() => {
																															this.handleSaveStepThree(values);
																															this.setState({ step: 2 });
																															window.scrollTo(0, 0);
																														}}><i className="fal fa-angle-left"></i>ก่อนหน้านี้</ButtonBack>
																													</Col>
																													<Col md={6} sm={6} xs={6}>
																														<div style={{textAlign: 'right'}}>
																															<ButtonNext
																																type="button"
																																disabled={!this.state.acceptTerms || loadingBusinessUpdate}
																																onClick={() => handleSubmit()}>
																																	{loadingBusinessUpdate ? 'ส่งแบบฟอร์ม...' : 'ส่งแบบฟอร์ม'}
																															</ButtonNext>
																														</div>
																													</Col>
																												</Row>
																											</div>
																										</React.Fragment>
																									)
																								}
																							}
																						</Formik>
																					</div>
																				)
																			}
																		</React.Fragment>
																	)
																}
															}
														</Mutation>
													</div>
												</Col>
											</Row>
										) : null
									}

									{
										data && data.accountInfoList && data.accountInfoList.account_business !== null ? (
											<Row>
												<Col md={12}>
													<Registered />
												</Col>
											</Row>
										) : null
									}

									<ModalConfirm
										show={this.state.isOpenModalError}
										onPressButtonConfirm={() => this.setState({ isOpenModalError: false })}
										styleColorButtonConfirm="primary"
										labelButtonConfirm={`ตกลง`}
										titleHeader={`แจ้งเตือน`}
										onHide={() => this.setState({ isOpenModalError: false })}
									>
										{ this.state.textModalError }
									</ModalConfirm>
								</Container>
							)
						}
					}
				</Query>
      </div>
    )
  }
}

export default Business;