import gql from 'graphql-tag';

export default gql`
mutation ShipmentsUpdate(
    $shipment_id: ID!
    $cancel_comment: String
    $shipment_status: Int
    $transporter: ID
){
    shipmentsUpdate(
        _id:$shipment_id
        cancel_comment: $cancel_comment
        shipment_status: $shipment_status
        transporter: $transporter
    ){
        _id
    }
  }
`;
