
import React from "react";
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
const Container = styled.div`

`;

class ExtraLargeCar6WheelsStall extends React.Component {

    render() {
    return (
        <Container>
            { this.props.t('common:instancePrice.additional.info.extraLargeTruck6WheelStall').split('\n').map(item => (<div>{item}</div>)) }
        </Container>   
    )
    }
};

export default withNamespaces()(ExtraLargeCar6WheelsStall);
