import gql from 'graphql-tag';

export default gql`
  mutation UserSettingTruckUpdate(
    $_id: ID!
    $user_setting_truck_license_plate: String
  ) {
    userSettingTruckUpdate(
      _id: $_id
      user_setting_truck_license_plate: $user_setting_truck_license_plate
    ) {
      _id
    }
  }
`;