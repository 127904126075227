import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from "src/config/color";

const Container = styled.div`
    display: flex;
    flex-direction: row;

    & .circle-container {
        width: 40px;
        margin-right: 10px;
    }

    & .circle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border: 1px solid ${color.Primary};
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    & .number {
        font-size: 26px;
        font-weight: bold;
        color: ${color.Primary};
        line-height: 1;
    }

    & .title {
        font-size: 22px;
        font-weight: bold;
        line-height: 1;
    }

    & .sub-title {
        font-size: 16px;
        color: #808080;
        line-height: 1;
    }
`;

const StepHeader = (props) => (
    <Container {...props}>
        <div className="circle-container">
            <div className="circle">
                <div className="number">{props.number}</div>
            </div>
        </div>

        <div>
            <div className="title">{props.title}</div>
            <div className="sub-title">{props.subTitle}</div>
        </div>
    </Container>
);

StepHeader.propTypes = {
    number: PropTypes.string, 
    title: PropTypes.string,
    subTitle: PropTypes.string,
};
  
StepHeader.defaultProps = {
    title : "Title",
    subTitle : "subTitle",
    number: "1.",
};

export default StepHeader;
