import React from "react";
import {Row , Col} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardContainer = styled(Row)`
    margin:0;
    
    .box-border{    
        display: -webkit-box;
        border-radius: 6px;
        border: 1px solid #D90101;
        padding: 10px 15px;
        color:#D90101;
        margin-bottom: 15px;

        @media(max-width: 767px) {
            padding: 5px 10px;
            margin-bottom: 10px;
        }
    }

    .box-border.active{
        border: 1px solid #000000;
        color:#000000;
    }

    .box-border.rating{
        border: 1px solid #dddddd;
        color:#000000;
    }

    .box-border .img{
        float: left;
        width: 60px;
        padding: 14px 10px;
    }

    .box-no-border .img img{
        width: 40px;
        margin-top: 3px;
    }

    .box-border .img i{
        font-size: 30px;
    }

    .box-border .detail{
        float: left;
    }

    .box-border.rating .detail{
        color: #000000;
        width: 100%;
        text-align: center;
        position: relative;
    }

    .box-border p{
        margin-bottom: 0;
    }
`;
const BoxBorder = (props) => (
    <CardContainer>
        <Col className={props.active ? "box-border active" : props.rating ? "box-border rating" : "box-border"} sm={12}>
            {props.content}
        </Col>
    </CardContainer>
);

BoxBorder.propTypes = {
    content: PropTypes.node,
    active:PropTypes.bool
};
  
BoxBorder.defaultProps = {
    content : "",
    active : false
};

export default BoxBorder;
