import React from 'react';
import uploadMutation from './graphql/mutation/upload';
import { compose, graphql } from 'react-apollo';

class UploadFile extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			path: '',
			loading: false,
			error: false,
		};
	}

	handleUpload = async (event, path) => {
		try {
			const {
				target: {
					validity,
					files: [file],
				},
			} = event;
			this.setState({
				error: false,
				loading: true,
			});
			if (validity) {
				await this.props.uploadMutation({
					variables: {
						file: file,
						path: `${path}`,
					},
					update: (cache, { data }) => {
						this.setState({
							error: false,
							loading: false,
							path: data.singleUpload.path,
						});
						const dataFile = {
							fileName: file.name,
							path: data.singleUpload.path,
						};
						this.props.completed(dataFile);
					},
				});
			}
		} catch (error) {
			this.setState({
				error: true,
				loading: false,
			});
		}
	};

	render() {
		return this.props.children((event, path) => this.handleUpload(event, path));
	}
}

export default compose(
	graphql(uploadMutation, {
		name: 'uploadMutation',
	})
)(UploadFile);
