import React from "react";
import detailRating from './graphql/query/detailRating';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Avatar } from 'antd';
import { StarRating } from 'src/components';
import s3 from 'src/config/s3';
import { withNamespaces } from 'react-i18next';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';

const ToolContainer = styled.div`
	padding: 0;
	float: left;
	height: calc(100vh - 110px);
`;

const AvatarContainer = styled.div`
  position: relative;
  display: inline-block;

  & .ant-avatar {
		border: 1px solid #E7E7E7;
	}
	
	& .ant-avatar img {
		width: 100%;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
	}
`;

const FavoriteIcon = styled.div`
  color: #FFFFFF;
  font-size: 10px;
  background-color: #DE0201;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
`;

const ReasonReviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const ReasonReviewList = styled.div`
	padding: 6px 10px;
	border: 1px solid #e3e3e3;
	font-size: 18px;
	margin-right: 10px;
	margin-bottom: 10px;
	color: #000000;
	border-radius: 6px;
	cursor: pointer;

	&:hover {
		background-color: #e3e3e3;
	}

	${props =>
		props.selected &&
		`
		color: #d90101;
		border: 1px solid #d90101;
	`}

	${props =>
		props.disabled &&
		`
		&:hover {
			background-color: transparent;
			cursor: default;
		}
	`}
`;

class DetailReviewDriver extends React.Component {
  static contextType = ShipmentDetailContext;

  getRatingToWord(rating) {
		if (rating === 5) {
			return {
				en: 'Excellent',
				th: 'ดีมาก',
			};
		} else if (rating === 4) {
			return {
				en: 'Good',
				th: 'ดี',
			};
		} else if (rating === 3) {
			return {
				en: 'Average',
				th: 'พอใช้',
			};
		} else if (rating === 2) {
			return {
				en: 'Bad',
				th: 'แย่',
			};
		} else if (rating === 1) {
			return {
				en: 'Worst',
				th: 'แย่มาก',
			};
		}

		return {
			en: 'Rate driver',
			th: 'ให้คะแนนพนักงานขับรถ',
		};
  }
  
  render() {
    return (
      <Query
        query={detailRating}
        variables={{
          shipment_id: this.props.shipmentId
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading || error || !data.trackingList.tracking[0].review_score) {
            return <div></div>;
          }

          const tracking = data.trackingList.tracking;
          const shipment = data.shipmentList.shipment;

          if (tracking.length > 0 && tracking[0].review_score) {
            return (
              this.context.state.customerReview && (
                <ToolContainer
                  className="ToolDetailWrapper"
                  style={{
                    width: "400px",
                    left: 840,
                    padding: 20
                  }}
                >
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "8px 14px",
                        cursor: "pointer",
                        lineHeight: 1,
                        color: "#888888",
                        fontSize: 24
                      }}
                      onClick={() => this.context.onClose(['customerReview'])}
                    >
                      <i className="fal fa-times" />
                    </div>
                    <div
                      style={{
                        paddingTop: "50px",
                        lineHeight: 1.2
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "bold"
                        }}
                      >
                        <AvatarContainer>
                          <Avatar
                            size={70}
                            icon="user"
                            style={{ marginBottom: 10 }}
                            src={`${s3}/${tracking[0].driver_display_pic}`}
                          />

                          {tracking[0].favorite_driver === 1 && (
                            <FavoriteIcon>
                              <i className="fas fa-heart"></i>
                            </FavoriteIcon>
                          )}
                        </AvatarContainer>
                        <div>
                          {`${
                            shipment[0].driver_name_under_mama
                              ? shipment[0].driver_name_under_mama
                              : tracking[0].driver_name
                          }`}
                        </div>
                        <div>
                          {`${shipment[0].shipment_address[0].subdictrict} - ${
                            shipment[0].shipment_address[
                              shipment[0].shipment_address.length - 1
                            ].subdictrict
                          }`}
                        </div>
                        <div>{`${shipment[0].shipment_number}`}</div>
                      </div>

                      <div
                        style={{
                          marginTop: 50,
                          textAlign: "center"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold"
                          }}
                        >
                          {
                            this.getRatingToWord(tracking[0].review_score)[
                              this.props.t("common:lang")
                            ]
                          }
                        </div>
                        <div
                          style={{
                            marginTop: 20
                          }}
                        >
                          <StarRating
                            disabled="disabled"
                            size={28}
                            rating={tracking[0].review_score}
                          />
                        </div>
                      </div>

                      {tracking[0].review_reason.length > 0 && (
                        <div>
                          <div
                            style={{
                              marginTop: 30,
                              fontWeight: "bold"
                            }}
                          >
                            {this.props.t(
                              "common:shipmentDetail.customerReview.reason"
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: 10
                            }}
                          >
                            <ReasonReviewContainer>
                              {tracking[0].review_reason.map(
                                (item, index, arr) => (
                                  <ReasonReviewList
                                    selected={true}
                                    key={index}
                                    disabled
                                  >
                                    {item[this.props.t("common:lang")]}
                                  </ReasonReviewList>
                                )
                              )}
                            </ReasonReviewContainer>
                          </div>
                        </div>
                      )}

                      {tracking[0].review_detail && (
                        <div>
                          <div
                            style={{
                              marginTop: 30,
                              fontWeight: "bold"
                            }}
                          >
                            {this.props.t(
                              "common:shipmentDetail.customerReview.comment"
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: 10
                            }}
                          >
                            {tracking[0].review_detail}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </ToolContainer>
              )
            );
          }
        }}
      </Query>
    );
  }
}

export default withNamespaces()(DetailReviewDriver);
