import React from 'react';
import Cookies from 'js-cookie';
import pdpaStyle from './PDPA.module.css';
import { withNamespaces } from 'react-i18next';

class PDPA extends React.Component {
	constructor() {
		super();

		this.state = {
			isShow: Cookies.get('pdpa') === undefined,
		};
	}

	onAgree() {
		this.setState({ isShow: false });
		Cookies.set('pdpa', 'agree', { path: '/' });
	}

	switchLanguage(lang) {
		const { i18n } = this.props;
		i18n.changeLanguage(lang);
		localStorage.setItem('lang', lang);
	}

	render() {
		const { t } = this.props;
		if (!this.state.isShow) {
			return null;
		}

		return (
			<div className={pdpaStyle.wrapper}>
				{/* <div className={pdpaStyle.content}>
					เราใช้คุกกี้เพื่อเสนอบริการและปรับปรุงอย่างต่อเนื่องตลอดจนแสดงเนื้อหาและการโฆษณาที่ปรับเปลี่ยนในแบบของคุณตามความสนใจส่วนบุคคลของคุณบนโซเชียลมีเดียและเว็บไซต์พันธมิตร
					<span>
						<a
							href="https://giztix.com/policy"
							target="_blank"
							className={pdpaStyle.link}
						>
							เรียนรู้เพิ่มเติม
						</a>
					</span>
				</div> */}
				<div className={pdpaStyle.content}>
					<div>
						{/* <span>
							&#91;{' '}
							<span
								onClick={() => this.switchLanguage('th')}
								style={{
									cursor: 'pointer',
									color: t('common:lang') === 'th' ? '#337ab7' : 'inherit',
								}}
							>
								ไทย
							</span>{' '}
							|{' '}
							<span
								onClick={() => this.switchLanguage('en')}
								style={{
									cursor: 'pointer',
									color: t('common:lang') === 'en' ? '#337ab7' : 'inherit',
								}}
							>
								EN
							</span>{' '}
							&#93;
						</span>{' '} */}
						<span
							dangerouslySetInnerHTML={{ __html: t('common:cookiepolicy.P1') }}
						/>
					</div>
					<span
						dangerouslySetInnerHTML={{ __html: t('common:cookiepolicy.P2') }}
					/>
				</div>
				<div>
					<button className={pdpaStyle.button} onClick={() => this.onAgree()}>
						{t('common:cookiepolicy.accept') }
					</button>
				</div>
			</div>
		);
	}
}

export default withNamespaces()(PDPA);
