import React, { Component } from 'react';
import {
	Row,
	Col,
	Button,
	Tooltip,
	Image,
	Grid,
	FormControl,
	InputGroup,
	FormGroup,
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Spin, Row as RowAnt, Col as ColAnt, Icon } from 'antd';
import styled from 'styled-components';
import Select from 'react-select';
import './style.css';
import color from 'src/config/color';
import s3 from 'src/config/s3';
import { Helmet } from 'react-helmet';
import URI from 'urijs';
import baseUrl from 'src/config/baseUrl';
//renderProps
import { CurrentUser } from '../../renderProps';
import { TrackingHeader, PDFPreview, Footer } from './../../components';
import Moment from 'react-moment';
import { Query } from 'react-apollo';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import trackingList from './graphql/query/trackingList';
import client from 'src/config/client';
import { HeaderNav } from 'src/app/components';
import accountInfoListByTrackingURLQuery from './graphql/query/accountInfoListByTrackingURL';
import endCustomerTracking from './graphql/query/endCustomerTracking';
import { withNamespaces } from 'react-i18next';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import _ from 'lodash';
import DropPointItem from './components/DropPointItem';
import LightBoxPreview from './components/LightBoxPreview';

const NotFoundErrorContainer = styled.div`
	max-width: 550px;
	font-size: 24px;
	text-align: center;
	margin: auto;
	margin-top: 80px;

	& img {
		width: 100%;
	}

	& .title {
		font-weight: bold;
	}
`;

const InputContainer = styled.div`
	margin: calc(100px + ${heightTopHeader}px) auto 0 auto;

	@media (max-width: 767px) {
		margin: calc(10px + ${heightTopHeader}px) auto 0 auto;
	}
`;

const Title = styled.div`
	font-size: 36px;
	text-align: left;

	@media (max-width: 767px) {
		font-size: 33px;
	}
`;

const Subtitle = styled.div`
	margin-bottom: 36px;
	text-align: left;
	color: #808080;
`;

const ErrorContainer = styled.div`
	background-color: #fff4f4;
	color: #d90101;
	text-align: left;
	padding: 20px;
	margin-left: 13px;
	margin-right: 13px;
`;

const ButtonSearch = styled(Button)`
	width: 90px;
`;

const TextBold = styled.div`
	font-weight: bold;
`;

const HeaderTable = styled.div`
	display: flex;
	margin-top: 20px;
	padding: 5px 5px 5px 10px;
	border: 1px solid #e3e3e3;
	border-radius: 5px 5px 0px 0px;
	background-color: #f9f9f9;
`;

const BodyTable = styled.div`
	padding: 5px 5px 5px 10px;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3;
	border-radius: 0px 0px 5px 5px;
`;

const StyledDropPointItem = styled(DropPointItem)`
	position: relative;

	&::after {
		content: '';
		position: absolute;
		width: 0px;
		height: 84%;
		top: 22px;
		left: 7px;
		border-right: 1px solid #e3e3e3;
	}
`;

const DocumentContainer = styled.div`
	border-top: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3;
	padding-top: 10px;
	padding-bottom: 10px;
`;

const ImageProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;

	& .image {
		width: 86px;
		height: 86px;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;
		cursor: pointer;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000030;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: block;
		}
	}
`;

const DropPointContainer = styled.div`
	margin-bottom: 20px;
`;

const RemarkText = styled.div`
	color: #808080;
`;

class TrackingDetailKA extends Component {
	constructor(props) {
		super(props);
		this.state = {
			section: 0,
			openSections: {},
			viewImageSrc: [],
			photoIndex: 0,
			isOpenLightbox: false,
			tabSelect: 1,
			photoProductIndexLightBox: 0,
			isOpenPhotoProductLightBox: false,
			isDriverWorking: false,
			shipperId: '',
			isLoadingTrackingUrl: false,
			isNotRegisterTrackingUrl: false,
			isOpenLightboxDriverAvatar: false,
			value: this.props.shipmentNumber,
			hiddenDocument: [],
		};
	}

	componentDidMount() {
		if (this.getParamsTrackingUrl()) {
			this.fetchAccountTrackingUrl(this.getParamsTrackingUrl());
		}
	}

	async fetchAccountTrackingUrl(trackingUrl) {
		try {
			this.setState({ isLoadingTrackingUrl: true });
			const { data } = await client.query({
				query: accountInfoListByTrackingURLQuery,
				variables: {
					account_info_tracking_url: trackingUrl,
				},
			});

			const { _id, account_info_logo } = data.accountInfoListByTrackingURL;

			console.log(
				'data.accountInfoListByTrackingURL',
				data.accountInfoListByTrackingURL
			);

			this.setState({
				shipperId: _id,
				account_info_logo: account_info_logo
					? s3 + '/' + account_info_logo
					: '',
				isLoadingTrackingUrl: false,
			});
		} catch (error) {
			this.setState({
				isLoadingTrackingUrl: false,
				isNotRegisterTrackingUrl: true,
			});

			console.log(error);
		}
	}

	renderStatus(status) {
		switch (status) {
			case 'ถูกยกเลิกการจัดส่ง':
				return (
					<div style={{ color: '#D90101' }}>
						{this.props.t('common:tracking.theDeliveryWasCanceled')}
					</div>
				);
			case 'จัดส่งสำเร็จ':
				return (
					<div style={{ color: '#23B034' }}>
						{this.props.t('common:tracking.shipmentDelivered ')}
					</div>
				);
			case 'กำลังดำเนินการขนส่ง':
				return this.props.t('common:tracking.onDelivery');
			case 'สินค้าอยู่ระหว่างการจัดเตรียม':
				return this.props.t('common:tracking.prepareToDelivery');
			default:
				return status;
		}
	}

	getParamsTrackingUrl() {
		const { trackingUrl } = this.props.shipmentNumber;

		return trackingUrl;
	}

	renderNotFound() {
		return (
			<Row className="wrapper">
				<Helmet>
					<title>
						{`PAGE ERROR 404`} | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง
						รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>

				<NotFoundErrorContainer>
					<img src={`${require('src/assets/images/404.png')}`} alt="404" />
					<div className="title">ERROR 404</div>
					<div>ขออภัย ไม่พบหน้าเว็ปไซต์ที่คุณต้องการ</div>
				</NotFoundErrorContainer>
			</Row>
		);
	}

	handleChange(e) {
		this.setState({ value: e.target.value });
	}

	searchTracking() {
		if (this.state.value !== '' && this.state.value !== undefined) {
			const { trackingUrl } = this.props.shipmentNumber;
			const path = `/trackingdetail${
				!!trackingUrl ? `/${trackingUrl}` : ''
			}?shipment=${this.state.value}`;
			window.location = path;
		} else {
			alert('กรุณา กรอกรหัสติดตามงานขนส่งของคุณ');
		}
	}

	renderPin(status) {
		switch (status) {
			case 'ถูกยกเลิกการจัดส่ง':
				return 'fail';
			case 'จัดส่งสำเร็จ':
				return 'success';
			case 'กำลังดำเนินการขนส่ง':
				return 'process';
			case 'สินค้าอยู่ระหว่างการจัดเตรียม':
				return 'process';
			default:
				return status;
		}
	}

	renderDetailTracking(status) {
		switch (status) {
			case 'สินค้าอยู่ระหว่างการจัดเตรียม ณ จุดจำหน่าย':
				return this.props.t('common:tracking.yourOrderIsBeingPrepared');
			case 'สินค้าอยู่ระหว่างการขนส่งไปยังศูนย์คัดแยก':
				return this.props.t('common:tracking.shipmentInTransit');
			case 'ถึงศูนย์คัดแยกเรียบร้อยแล้ว':
				return this.props.t('common:tracking.shipmentArrived');
			case 'อยู่ระหว่างการคัดแยกสินค้า เพื่อนำส่ง':
				return this.props.t('common:tracking.shipmentProcessSorting');
			case 'อยู่ระหว่างการขนส่งไปยังปลายทาง':
				return this.props.t('common:tracking.shipmentIsOutForDelivery');
			case 'จัดส่งสำเร็จ':
				return this.props.t('common:tracking.shipmentDeliveredSuccessfully');
			case 'ขออภัย เราได้พยายามจัดส่งสินค้าแต่ไม่สำเร็จ':
				return this.props.t('common:tracking.deliveryIsNotSuccess');
			case 'ขออภัย เราได้พยายามจัดส่งสินค้าแต่ไม่สำเร็จครบ 3 ครั้ง สินค้าจะถูกยกเลิกการจัดส่ง':
				return this.props.t('common:tracking.shipmentWillBeReturn');
			case 'คำสั่งซื้อถูกยกเลิกโดยผู้ซื้อ':
				return this.props.t('common:tracking.shipmentIsCancelled');
			default:
				return status;
		}
	}

	render() {
		const { viewImageSrc, isOpenLightbox, photoIndex } = this.state;
		// const uri = new URI(`${baseUrl}/${this.props.location.search}`);
		// const params = uri.search(true);
		// let shipmentNumber = this.props.shipmentNumber;

		// let shipmentId = null

		return (
			<CurrentUser>
				{!this.state.isLoadingTrackingUrl && (
					<div style={{ backgroundColor: '#ffffff' }}>
						<HeaderNav
							full
							isLoadingLogo={this.state.isLoadingTrackingUrl}
							trackingUrl={this.state.account_info_logo}
						/>
						{isOpenLightbox ? (
							viewImageSrc.length > 1 ? (
								<Lightbox
									mainSrc={viewImageSrc[photoIndex]}
									nextSrc={viewImageSrc[(photoIndex + 1) % viewImageSrc.length]}
									prevSrc={
										viewImageSrc[
											(photoIndex + viewImageSrc.length - 1) %
												viewImageSrc.length
										]
									}
									onCloseRequest={() =>
										this.setState({ isOpenLightbox: false })
									}
									onMovePrevRequest={() =>
										this.setState({
											photoIndex:
												(photoIndex + viewImageSrc.length - 1) %
												viewImageSrc.length,
										})
									}
									onMoveNextRequest={() =>
										this.setState({
											photoIndex: (photoIndex + 1) % viewImageSrc.length,
										})
									}
								/>
							) : (
								<Lightbox
									mainSrc={viewImageSrc[photoIndex]}
									onCloseRequest={() =>
										this.setState({ isOpenLightbox: false })
									}
								/>
							)
						) : null}

						<RowAnt>
							<InputContainer>
								<RowAnt>
									<Col
										xs={12}
										sm={8}
										smOffset={2}
										md={6}
										mdOffset={3}
										lg={6}
										lgOffset={3}
									>
										<Title>
											{this.props.t('common:header.shipmentTracking')}
										</Title>
										<Subtitle>
											{this.props.t('common:header.shipmentTrackingSubtitle')}
										</Subtitle>
										<FormGroup>
											<InputGroup>
												<FormControl
													type="text"
													value={this.state.value}
													placeholder={this.props.t(
														'common:header.pleaseFillShipmentId'
													)}
													onChange={event => this.handleChange(event)}
													onKeyPress={event => {
														if (event.charCode === 13) {
															this.searchTracking(event);
														}
													}}
												/>
												<InputGroup.Button>
													<ButtonSearch
														bsStyle="primary"
														onClick={() => this.searchTracking()}
													>
														{this.props.t('common:header.tracking')}
													</ButtonSearch>
												</InputGroup.Button>
											</InputGroup>
										</FormGroup>
									</Col>
								</RowAnt>

								{this.props.shipmentNumber && (
									<RowAnt>
										<Col
											xs={12}
											sm={8}
											smOffset={2}
											md={6}
											mdOffset={3}
											lg={6}
											lgOffset={3}
										>
											<Query
												query={endCustomerTracking}
												variables={{
													order_number: this.props.shipmentNumber,
												}}
												fetchPolicy="cache-and-network"
											>
												{({ loading, error, data, refetch, variables }) => {
													if (loading) return <Spin />;
													if (error) return console.log(error);
													if (data && data.endCustomerTracking) {
														const {
															order_number,
															sender,
															customer,
															state,
															status,
															status_timestamp,
														} = data.endCustomerTracking;
														const dropPoint = _.orderBy(
															state,
															['timestamp'],
															['desc']
														);

														if (data.endCustomerTracking) {
															return (
																<DropPointContainer>
																	<RowAnt>
																		<Col md={6}>
																			<Row>
																				<Col md={5}>
																					<TextBold>
																						{this.props.t(
																							'common:tracking.trackingNumber'
																						)}
																					</TextBold>
																				</Col>
																				<Col md={7}>{order_number || '-'}</Col>
																			</Row>
																			<Row>
																				<Col md={5}>
																					<TextBold>
																						{this.props.t(
																							'common:tracking.sender'
																						)}
																					</TextBold>
																				</Col>
																				<Col md={7}>
																					{sender.account_info_business_name ||
																						'-'}
																				</Col>
																			</Row>
																		</Col>
																		<Col
																			md={6}
																			className="statusContainer"
																			style={{ marginTop: 10 }}
																		>
																			<Row>
																				<Col>
																					{this.props.t(
																						'common:tracking.status'
																					)}
																				</Col>
																			</Row>
																			<Row>
																				<Col>
																					<TextBold
																						style={{
																							fontSize: 36,
																							marginTop: -10,
																							marginBottom: -10,
																						}}
																					>
																						{this.renderStatus(status)}
																					</TextBold>
																				</Col>
																			</Row>
																			<Row>
																				<Col>
																					<Moment format="DD/MM/YYYY HH:mm">
																						{status_timestamp}
																					</Moment>
																				</Col>
																			</Row>
																		</Col>
																	</RowAnt>
																	<RowAnt>
																		<Col>
																			<HeaderTable>
																				{this.props.t('common:tracking.from')}:
																				<TextBold
																					style={{
																						marginLeft: 5,
																						marginRight: 5,
																					}}
																				>
																					{sender.account_info_business_name ||
																						'-'}
																				</TextBold>
																				<div>
																					&gt;&gt;{' '}
																					{this.props.t('common:tracking.to')}:
																				</div>
																				<TextBold
																					style={{
																						marginLeft: 5,
																						marginRight: 5,
																					}}
																				>
																					{customer.name || '-'}
																				</TextBold>
																			</HeaderTable>
																		</Col>
																	</RowAnt>
																	<RowAnt>
																		<Col>
																			<BodyTable>
																				{dropPoint.map((item, index) => {
																					const documents = item.document.map(
																						item => {
																							const patternFileType = /\.[0-9a-z]{1,5}$/;
																							const patternCheckFileType = /^.*\.(pdf|PDF)$/;
																							const thumbnail = patternCheckFileType.test(
																								`${s3}/${item}`
																							)
																								? `${require('src/assets/images/file-type-pdf.png')}`
																								: `${s3}/${item}`;
																							const type = patternCheckFileType.test(
																								`${s3}/${item}`
																							)
																								? 'pdf'
																								: 'image';

																							return {
																								path: `${s3}/${item}`, //path ไฟล์
																								fileType: item.match(
																									patternFileType
																								)[0], //นามสกุลไฟล์
																								type: type, //ประเภทไฟล์
																								thumbnail: thumbnail, //รูปตัวอย่าง
																							};
																						}
																					);

																					const documentsImage = documents.map(
																						item => {
																							const patternCheckFileType = /^.*\.(pdf|PDF)$/;
																							if (
																								patternCheckFileType.test(item)
																							) {
																								return (
																									<PDFPreview file={item} />
																								);
																							}

																							return item.thumbnail;
																						}
																					);
																					return (
																						<StyledDropPointItem
																							type={
																								index === 0 &&
																								this.renderPin(status)
																							}
																							borderBottom
																							key={uuidv4()}
																						>
																							<Row>
																								<Col md={3}>
																									{index === 0 ? (
																										<TextBold>
																											<Moment format="DD/MM/YYYY HH:mm">
																												{item.timestamp}
																											</Moment>
																										</TextBold>
																									) : (
																										<div
																											style={{
																												color: '#808080',
																											}}
																										>
																											<Moment format="DD/MM/YYYY HH:mm">
																												{item.timestamp}
																											</Moment>
																										</div>
																									)}
																								</Col>
																								<Col md={9}>
																									<RowAnt>
																										<Col>
																											{this.renderDetailTracking(
																												item.state_text
																											)}
																										</Col>
																									</RowAnt>
																									{item.remark && (
																										<RowAnt>
																											<Col>
																												<RemarkText>
																													{item.remark}
																												</RemarkText>
																											</Col>
																										</RowAnt>
																									)}
																									{item.document.length > 0 && (
																										<DocumentContainer>
																											<RowAnt
																												style={{
																													display: 'flex',
																												}}
																											>
																												<ColAnt
																													style={{
																														marginRight: 10,
																													}}
																												>
																													{this.state.hiddenDocument.indexOf(
																														index
																													) === -1 && (
																														<i
																															className="far fa-times-circle"
																															onClick={() => {
																																this.setState(
																																	prev => ({
																																		hiddenDocument: [
																																			...prev.hiddenDocument,
																																			index,
																																		],
																																	})
																																);
																															}}
																														/>
																													)}
																													{this.state.hiddenDocument.indexOf(
																														index
																													) > -1 && (
																														<i
																															className="far fa-plus-circle"
																															onClick={() => {
																																this.setState(
																																	prev => ({
																																		hiddenDocument: prev.hiddenDocument.filter(
																																			item =>
																																				item !==
																																				index
																																		),
																																	})
																																);
																															}}
																														/>
																													)}
																												</ColAnt>
																												<ColAnt>
																													{this.props.t(
																														'common:tracking.deliveryConfirmation'
																													)}
																												</ColAnt>
																											</RowAnt>
																											{this.state.hiddenDocument.indexOf(
																												index
																											) === -1 && (
																												<LightBoxPreview
																													documentsImage={
																														item.document
																													}
																												/>
																											)}
																										</DocumentContainer>
																									)}
																								</Col>
																							</Row>
																						</StyledDropPointItem>
																					);
																				})}
																			</BodyTable>
																		</Col>
																	</RowAnt>
																</DropPointContainer>
															);
														}
													} else {
														return (
															<Row style={{ marginBottom: 20 }}>
																<Col>
																	<ErrorContainer>
																		{this.props.t(
																			'common:header.errorTracking'
																		)}
																	</ErrorContainer>
																</Col>
															</Row>
														);
													}
												}}
											</Query>
										</Col>
									</RowAnt>
								)}
							</InputContainer>
							<Footer />
						</RowAnt>
					</div>
				)}
			</CurrentUser>
		);
	}
}

export default withNamespaces()(TrackingDetailKA);
