import gql from 'graphql-tag';

export default gql`
	query GetMapPolyline(
		$pickupLatitude: String
		$pickupLongitude: String
		$deliveryLatitude: String
		$deliveryLongitude: String
		$address: [getMapPolylineAddress]
		$round_trip: Int
	) {
		getMapPolyline(
			lat_origin: $pickupLatitude
			lng_origin: $pickupLongitude
			lat_destination: $deliveryLatitude
			lng_destination: $deliveryLongitude
			address: $address
			round_trip: $round_trip
		) {
			polyline
			polyline_list {
				distance
				duration
				polyline
			}
		}
	}
`;
