import React from 'react';
import styled from 'styled-components';
import { CardHeader, Card } from './../../../../components';
import { graphql, Mutation } from 'react-apollo';
import updateInstancePriceStepMutationClient from './../../../../config/graphqlClient/mutation/updateInstancePriceStep';
import instancePriceQueryClient from './../../../../config/graphqlClient/query/instancePrice';
import { withNamespaces } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';

const Container = styled.div`
	width: 100%;
`;

const BoxContainer = styled.div`
	background-color: #f9f9f9;
	border-radius: 4px;
	font-size: 18px;
	padding: 10px;
	line-height: 1.2;
	margin-top: 20px;
	color: #808080;

	& strong {
		color: #000000;
	}
`;

class NotificationMultiDrop extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			value: '',
		};
	}

	closeTap(updateInstancePriceStep) {
		updateInstancePriceStep({
			variables: {
				notificationMultiDrop: false,
			},
		});
	}

	render() {
		return (
			<Container>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Card
								content={
									<div>
										<CardHeader
											borderCardBottom
											title={`${this.props.t(
												'common:instancePrice.notificationMultiDrop.title',
											)}`}
											onPressClose={() =>
												this.closeTap(updateInstancePriceStep)
											}
										/>

										<BoxContainer>
											{this.props
												.t('common:instancePrice.notificationMultiDrop.detail')
												.split('\n')
												.map(item => (
													<div>{ReactHTMLParser(item)}</div>
												))}
										</BoxContainer>
									</div>
								}
							/>
						);
					}}
				</Mutation>
			</Container>
		);
	}
}

export default graphql(instancePriceQueryClient, {
	name: 'instancePriceQuery',
})(withNamespaces()(NotificationMultiDrop));
