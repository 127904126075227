import React from 'react';
import FooterDesktop from './../FooterDesktop';
import FooterMobile from './../FooterMobile';
import MediaQuery from 'react-responsive';

class Footer extends React.Component {
	render(props) {
		return (
			<div>
				<MediaQuery minWidth={768}>
						<FooterDesktop />
				</MediaQuery>
				<MediaQuery maxWidth={767}>
						<FooterMobile />
				</MediaQuery>
			</div>
		)
	}
}

export default Footer;
