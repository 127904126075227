import React, { Component } from "react";
import {Row , Badge} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './style.css';

const PointItemContainer = styled(Row)`
    margin-left:40px;
`;

const DropPointItem = (props) => (
    <PointItemContainer onClick={props.onClick} className={`pointItem ${props.selected ? "selected" : ""}`}>
        <div className="iconStyle" style={{color:props.LeftIconColor}}><i className={props.LeftIcon}></i></div>
        <div className="title">{props.Title} <small>{props.subTitle}</small></div>
        <div className="rightIconStyle"><i className={props.RightIcon}></i></div>
    </PointItemContainer>
);

DropPointItem.propTypes = {
    LeftIcon: PropTypes.string,
    LeftIconColor: PropTypes.string,
    Title: PropTypes.string,
    subTitle: PropTypes.string,
    RightIcon: PropTypes.string,
    selected: PropTypes.bool
};
  
DropPointItem.defaultProps = {
    title : "",
    subTitle : "",
    selected: false
};

export default DropPointItem;
