import gql from 'graphql-tag';

export default gql`
	mutation ContainerPortFavoriteAdd(
    $setting_container_port_id: ID
    $container_port_type: Int
    $description: String
    $structured_formatting: structured_FavoriteArgs
    $lat: String
    $lng: String
  ) {
		containerPortFavoriteAdd(
      setting_container_port_id: $setting_container_port_id
      container_port_type: $container_port_type
      description: $description
      structured_formatting: $structured_formatting
      lat: $lat
      lng: $lng
    ) {
			_id
      account_info_id
      setting_container_port_id
      description
      structured_formatting {
        main_text
        secondary_text
      }
      lat
      lng
      container_port_type
      province
      district
      subdistrict
      postcode
		}
	}
`;
