import gql from 'graphql-tag';

export default gql`
	mutation TrackingUpdate(
		$id: ID!
		$reviewDetail: String
		$reviewScore: Int
		$reviewReason: [languageArg]
	) {
		trackingUpdate(
			_id: $id
			review_detail: $reviewDetail
			review_score: $reviewScore
			review_reason: $reviewReason
		) {
			_id
			tracking_status
		}
	}
`;
