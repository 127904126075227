import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
    margin-left:0px;
    display: flex;
    flex-direction: row;
    line-height: 1;
    cursor: pointer;
`;

const ImageContainer = styled.div`
    width: 80px;
    height: 50px;
    background-color: #DEDEDE;
    margin-right: 20px;
    border-radius: 2px;

    background-image: url(${props => props.url});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const ArrowContainer = styled.div`
    color: #B3B3B3;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

const Detail = styled.div`
    font-size: 17px;
    color: #000000;
    ${props => props.color && `color: #B3B3B3;`}
`;

const PromotionListItem = (props) => {
    const { title, ...except } = props;
    return(
        <Container {...except} onClick={props.onPress} >
            <ImageContainer url={props.image}/>
            <ContentContainer>
                <Title>{props.title}</Title>
                <Detail>{props.subTitle}</Detail>
                <Detail color="#B3B3B3">{props.exp}</Detail>
            </ContentContainer>
            <ArrowContainer>
                <i className="fal fa-chevron-right"></i>
            </ArrowContainer>
        </Container>
    )
}

PromotionListItem.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    image: PropTypes.string,
    exp: PropTypes.string,
};
  
PromotionListItem.defaultProps = {
    title : "",
    subTitle : "",
    image: "",
    exp: "",
};

export default withNamespaces()(PromotionListItem);
