import gql from 'graphql-tag';

export default gql`
	mutation ShipmentPaymentConfirm(
		$setting_truck_name_th: String
		$truck_type_name: String
		$setting_truck_id: ID
		$setting_truck_type_id: ID
		$pick_up_date: String
		$shipment_other_detail_remark: String
		$shipment_address_pickup_mod: String
		$shipment_address_pickup_address: String
		$shipment_address_pickup_lat: String
		$shipment_address_pickup_lng: String
		$shipment_address_pickup_contact_name: String
		$shipment_address_pickup_contact_tel: String
		$shipment_address_pickup_remark: String
		$shipment_address_pickup_paid_by_cod: Int
		$shipment_address_delivery_mod: String
		$shipment_address_delivery_address: String
		$shipment_address_delivery_lat: String
		$shipment_address_delivery_lng: String
		$shipment_address_delivery_contact_name: String
		$shipment_address_delivery_contact_tel: String
		$shipment_address_delivery_remark: String
		$shipment_address_delivery_paid_by_cod: Int
		$shipment_additional: [typeShipmentAdditionalArg]
		$paymentCreditCardId: ID
		$paymentCreditCardBank: String
		$paymentCreditCardLastDigits: String
		$paymentCreditCardBrand: String
		$paymentCreditCarName: String
		$additional: [getPriceAdditional]
		$promotion_name: String
		$paymentType: Int!
	) {
		shipmentPaymentConfirm(
			shipment: {
				setting_truck_name: { th: $setting_truck_name_th }
				truck_type_name: { th: $truck_type_name }
				setting_truck_id: $setting_truck_id
				setting_truck_type_id: $setting_truck_type_id
				truck_type_qty: 1
				pick_up_date: $pick_up_date
				shipment_detail_type: 1
				shipment_other_detail: { remark: $shipment_other_detail_remark }
				shipment_address: [
					{
						mode: $shipment_address_pickup_mod
						address: $shipment_address_pickup_address
						lat: $shipment_address_pickup_lat
						lng: $shipment_address_pickup_lng
						contact_name: $shipment_address_pickup_contact_name
						contact_tel: $shipment_address_pickup_contact_tel
						remark: $shipment_address_pickup_remark
						paid_by_cod: $shipment_address_pickup_paid_by_cod
					}
					{
						mode: $shipment_address_delivery_mod
						address: $shipment_address_delivery_address
						lat: $shipment_address_delivery_lat
						lng: $shipment_address_delivery_lng
						contact_name: $shipment_address_delivery_contact_name
						contact_tel: $shipment_address_delivery_contact_tel
						remark: $shipment_address_delivery_remark
						paid_by_cod: $shipment_address_delivery_paid_by_cod
					}
				]
				shipment_additional: $shipment_additional
			}
			paymentType: $paymentType
			paymentCreditCardId: $paymentCreditCardId
			paymentCreditCardBank: $paymentCreditCardBank
			paymentCreditCardLastDigits: $paymentCreditCardLastDigits
			paymentCreditCardBrand: $paymentCreditCardBrand
			paymentCreditCarName: $paymentCreditCarName
			getPrice: {
				setting_truck_type_id: $setting_truck_type_id
				lat_origin: $shipment_address_pickup_lat
				lng_origin: $shipment_address_pickup_lng
				lat_destination: $shipment_address_delivery_lat
				lng_destination: $shipment_address_delivery_lng
				additional: $additional
				promotion_name: $promotion_name
				call_from: 2
			}
		) {
			_id
			uri
		}
	}
`;
