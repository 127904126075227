import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 12px 10px 10px 10px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  margin-right: 10px;

  & i {
    font-size: 16px;
  }
`;

const Label = styled.div`
  font-size: 18px;
`;

const ButtonIcon = (props) => {
  return (
    <Container {...props}>
      <IconContainer><i className={ props.icon }></i></IconContainer>
      <Label>{ props.label }</Label>
    </Container>
  )
}

ButtonIcon.propTypes = {
	label: PropTypes.string,
	icon: PropTypes.string,
};

ButtonIcon.defaultProps = {
  label: '',
  icon: '',
};

export default ButtonIcon;