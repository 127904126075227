import React from 'react';
import singleUploadMutation from './graphql/mutation/singleUpload';
import { compose, graphql } from 'react-apollo';

class UploadFile extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      path: '',
      loading: false,
      error: false,
    };
  }

  handleSingleUpload = async (event, path) => {
    try {
      const {
        target: {
          validity,
          files: [file]
        }
      } = event;

      this.setState({
        error: false,
        loading: true,
      });
      
      if(validity) {
        await this.props.singleUploadMutation({
          variables: {
            file: file,
            path: `${path}`
          },
          update: (cache, { data }) => {
            this.setState({
              error: false,
              loading: false,
              path: data.singleUpload.path,
            });

            this.props.completed(data.singleUpload.path);
          }
        });
      }
    } catch (error) {
      this.setState({
        error: true,
        loading: false,
      });
    }
  }

  render() {
    return this.props.children((event, path) => this.handleSingleUpload(event, path));
  }
}

export default compose(
  graphql(singleUploadMutation, {
    name: 'singleUploadMutation'
  })
)(UploadFile);