import React from "react";
import { Row } from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from '../../../config/color';

const Container = styled.div`
    .ServicePromotionItem {
        position: relative;
        width: 100%;
        border-bottom: 1px solid #ddd;
        padding: 10px 0px;
    }

    .ServicePromotionItem.no-border {
        border-bottom: none;
        padding-bottom: 0; 
    }

    .ServicePromotionItem:last-child:before {
        border-left: none;
    }

    .ServicePromotionItem .title{
        display: flex;
        flex-direction: row;
        flex: 1;
        font-size: 20px;
        line-height: 1;
    }

    .ServicePromotionItem .price{
        color: #B3B3B3;
        text-align: right;
        font-size: 20px;
        line-height: 1;
        width: 100px;
    }

    .ServicePromotionItem.active .price{
        color: #000000;
    }

    .ServicePromotionItem .rightIconStyle{
        color: #B3B3B3;
        width: 20px;
        text-align: right;
        font-size: 18px;
        padding-top: 0;
        line-height: 1;
    }

    .ServicePromotionItem.selected,
    .ServicePromotionItem.selected .rightIconStyle
    {
        color: #d90101;
    }

    .line-delete {
        text-decoration: line-through;
        color: #B3B3B3;
    }

    .text-information {
        font-size: 18px;
        color: #B3B3B3;
    }

    .text-selected {
        color: #d90101 !important;
    }
`;

const ServiceItemContainer = styled(Row)`
    display: flex;
    flex-direction: row;
    margin-left:0px;
    align-items: center;

    ${ props => props.onClick && `
        cursor: pointer;
    `}
`;

const ButtonIcon = styled.i`
    color: ${color.SoftGray};
    padding-left: 5px;
    padding-top: 3px;
    font-size: 15px;

    ${props => props.onClick && `
        cursor: pointer;
    `}
`;

const ServicePromotionItem = (props) => (
    <Container>
        <ServiceItemContainer id={props.id} onClick={props.onClick} className={`ServicePromotionItem ${props.selected ? "selected" : ""} ${props.disabled ? "disabled" : ""} ${props.noBorder ? "no-border" : ""}`}>   
            <div className="title">
                <span>{props.Title} <span className="text-information">{props.textInfo}</span></span>
                { props.icon && (
                    <ButtonIcon id="create-shipment__icon_promotion" className={props.icon} onClick={(e) => props.onPressIcon && props.onPressIcon(e)}></ButtonIcon>
                )}
            </div>

            <div className={`${props.lineStrike && 'line-delete'}`}>
                <div className={`price ${props.selected ? 'text-selected' : null}`}>{props.price === "ไม่ได้ระบุ" ? props.price : `${props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</div>
            </div>

            {
                props.isShowRightIcon && (
                    <div className="rightIconStyle">
                        <i className={props.RightIcon ? props.RightIcon : `${props.selected ? "fal fa-chevron-left" : "fal fa-chevron-right"}`}></i>
                    </div>
                )
            }
        </ServiceItemContainer>
    </Container>
);

ServicePromotionItem.propTypes = {
    LeftIcon: PropTypes.string,
    LeftIconColor: PropTypes.string,
    Title: PropTypes.string,
    subTitle: PropTypes.string,
    serviceImage : PropTypes.string,
    RightIcon: PropTypes.string,
    CreditCardIcon: PropTypes.node,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    noBorder : PropTypes.bool,
    isShowRightIcon: PropTypes.bool,
    icon: PropTypes.string,
    lineStrike: PropTypes.bool,
    textInfo: PropTypes.string,
};
  
ServicePromotionItem.defaultProps = {
    title : "",
    subTitle : "",
    selected: false,
    disabled: false,
    noBorder: false,
    isShowRightIcon: false,
    icon: '',
    lineStrike: false,
    textInfo: '',
};

export default ServicePromotionItem;
