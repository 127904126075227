import baseUrl from './baseUrl';

const firebaseConfig = (baseUrl === 'https://api.giztix.com') ? {
  apiKey: "AIzaSyD2g6CXdO0BQE63OmxAdYAzAkTCx0a42UM",
  authDomain: "giztix-a6af7.firebaseapp.com",
  databaseURL: "https://giztix-a6af7.firebaseio.com",
  projectId: "giztix-a6af7",
  storageBucket: "giztix-a6af7.appspot.com",
  messagingSenderId: "42738844442",
  appId: "1:42738844442:web:3e2d02d175ddc7c7b6a2a3"
} : {
  apiKey: "AIzaSyD8goENyKpeA2XADlrXXO_hl7OWuO7cEKI",
  authDomain: "test-8ad6b.firebaseapp.com",
  databaseURL: "https://test-8ad6b.firebaseio.com",
  projectId: "test-8ad6b",
  storageBucket: "test-8ad6b.appspot.com",
  messagingSenderId: "203355552962",
  appId: "1:203355552962:web:47f079cfda7576a4800f5b"
};

export default firebaseConfig;