import React, { Component } from 'react';
import {
	Row,
	Col,
	Button,
	Tooltip,
	Image,
	Grid,
	FormControl,
	InputGroup,
	FormGroup,
} from 'react-bootstrap';
import { Spin, Row as RowAnt, Col as ColAnt, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import Select from 'react-select';
import './style.css';
import color from 'src/config/color';
import s3 from 'src/config/s3';
import { Helmet } from 'react-helmet';
import URI from 'urijs';
import baseUrl from 'src/config/baseUrl';

//renderProps
import { CurrentUser } from '../../renderProps';

// Component
import {
	GoogleMaps,
	TrackingItem,
	TrackingItemImport,
	TrackingItemExport,
} from './components';

import {
	Header,
	TrackingHeader,
	Card,
	BlankSpace,
	CardHeader,
	BoxBorder,
	BoxNoBorder,
	CreditCardIcon,
	BoxPriceItem,
	BoxServiceItem,
	BoxStatusDetail,
	Footer,
	Accordion,
	PDFPreview,
} from './../../components';

import Moment from 'react-moment';
import { Query } from 'react-apollo';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

import detailShipment from './graphql/query/detailShipment';
import invoiceList from './graphql/query/invoiceList';
import trackingList from './graphql/query/trackingList';
import accountInfo from './graphql/query/accountInfo';
import MediaQuery from 'react-responsive';
import client from 'src/config/client';
import { HeaderNav } from 'src/app/components';
import accountInfoListByTrackingURLQuery from './graphql/query/accountInfoListByTrackingURL';
import { Avatar } from 'antd';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const tooltip = <Tooltip id="tooltip">ดูข้อมูล</Tooltip>;

const ToolContainer = styled(Col)`
	padding: 0;
	float: left;
	height: calc(100vh - 110px - ${heightTopHeader}px);
`;

const SubContainer = styled(Col)`
	padding: 0 15px;
	height: calc(100vh - 110px - ${heightTopHeader}px);
`;

const DetailHeaderContainer = styled(Grid)`
	width: 100%;
	padding-top: 10px;
	border-bottom: 1px solid #e3e3e3;
`;

const TransporterContainer = styled(Row)`
	background-color: whitesmoke;
	margin-right: -20px;
	margin-left: -20px;
`;

const TransporterInner = styled(Col)`
	background-color: white;
	margin: 15px 20px;
	width: calc(100% - 40px);
	padding: 15px;
	border-radius: 4px;
	box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid #f3f3f3;
`;

const DetailMobileContainer = styled.div`
	padding: 10px 20px;
	display: flex;
	flex-direction: column;

	& .header {
		display: flex;
		flex-direction: column;

		& .title {
			font-size: 24px;
			font-weight: bold;
		}
	}

	@media (max-width: 767px) {
		.box-status {
			font-size: 16px;
			padding: 6px 12px;
			border-radius: 4px;
		}
	}

	& .detail {
		display: flex;
		flex-direction: column;

		& .driver {
			display: flex;
			flex-direction: row;
			margin-top: 15px;
			justify-content: space-between;

			&-left {
				line-height: 1;
			}
		}
	}

	& .tab-container {
		margin: 20px 0px 30px 0px;
		border-bottom: 1px solid #e3e3e3;

		& ul {
			list-style-type: none;
			margin: 0px;
			padding: 0px;
		}

		& li {
			font-size: 20px;
			display: inline-block;
			padding: 10px;
			cursor: pointer;

			&.active {
				color: #d90101;
				border-bottom: 2px solid #d90101;
				font-weight: bold;
			}

			&:not(:last-child) {
				margin-right: 20px;
			}
		}
	}

	& a {
		&:hover {
			color: #000000;
		}
	}
`;

const ImageProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;

	& .image {
		width: 86px;
		height: 86px;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;
		cursor: pointer;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000030;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: block;
		}
	}
`;

const NotFoundErrorContainer = styled.div`
	max-width: 550px;
	font-size: 24px;
	text-align: center;
	margin: auto;
	margin-top: 80px;

	& img {
		width: 100%;
	}

	& .title {
		font-weight: bold;
	}
`;

const DriverDetailContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const AvatarContainer = styled.div`
	position: relative;
	display: inline-block;

	& .ant-avatar {
		border: 1px solid #e7e7e7;
		cursor: pointer;
	}

	& .ant-avatar img {
		width: 100%;
		object-fit: cover;
		position: absolute;
		display: block;
		top: -9999px;
		bottom: -9999px;
		left: -9999px;
		right: -9999px;
		margin: auto;
	}
`;

const FavoriteIcon = styled.div`
	color: #ffffff;
	font-size: 10px;
	background-color: #de0201;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0px;
	right: 0px;
`;

const Title = styled.div`
	font-size: 36px;
	text-align: left;

	@media (max-width: 767px) {
		font-size: 33px;
	}
`;

const Subtitle = styled.div`
	margin-bottom: 36px;
	text-align: left;
	color: #808080;
`;

const ErrorContainer = styled.div`
	background-color: #fff4f4;
	color: #d90101;
	text-align: left;
	padding: 20px;
	margin-left: 13px;
	margin-right: 13px;
`;

const ButtonSearch = styled(Button)`
	width: 90px;
`;

const InputContainer = styled.div`
	margin: calc(100px + ${heightTopHeader}px) auto 0 auto;
`;

class TrackingDetailTruck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			section: 0,
			openSections: {},
			viewImageSrc: [],
			photoIndex: 0,
			isOpenLightbox: false,
			tabSelect: 1,
			photoProductIndexLightBox: 0,
			isOpenPhotoProductLightBox: false,
			isDriverWorking: false,
			shipperId: '',
			isLoadingTrackingUrl: false,
			isNotRegisterTrackingUrl: false,
			isOpenLightboxDriverAvatar: false,
			value: this.props.shipmentNumber,
			isInitial: true,
			isShowHeader: true,
		};
	}

	componentDidMount() {
		if (this.getParamsTrackingUrl()) {
			this.fetchAccountTrackingUrl(this.getParamsTrackingUrl());
		}
	}

	async fetchAccountTrackingUrl(trackingUrl) {
		try {
			this.setState({ isLoadingTrackingUrl: true });
			const { data } = await client.query({
				query: accountInfoListByTrackingURLQuery,
				variables: {
					account_info_tracking_url: trackingUrl,
				},
			});

			const { _id, account_info_logo } = data.accountInfoListByTrackingURL;

			console.log(
				'data.accountInfoListByTrackingURL',
				data.accountInfoListByTrackingURL
			);

			this.setState({
				shipperId: _id,
				account_info_logo: account_info_logo
					? s3 + '/' + account_info_logo
					: '',
				isLoadingTrackingUrl: false,
			});
		} catch (error) {
			this.setState({
				isLoadingTrackingUrl: false,
				isNotRegisterTrackingUrl: true,
			});

			console.log(error);
		}
	}

	renderAddressMode(mode, index) {
		if (mode === 'roundtrip') {
			return 'กลับจุดที่ 1 :';
		}

		return `จุดที่ ${index + 1} - ${mode}`;
	}

	renderStatus(status, isSuccess) {
		if (isSuccess !== null) {
			status = 4;
		}
		switch (status) {
			case 2:
				return (
					<Button bsStyle="primary" size="sm" className="outline box-status">
						รอขนส่งรับงาน
					</Button>
				);
			case 10:
				return (
					<Button bsStyle="default" size="sm" className="box-status">
						รอเริ่มงาน
					</Button>
				);
			case 3:
				return (
					<Button bsStyle="primary" size="sm" className="outline box-status">
						กำลังดำเนินการขนส่ง
					</Button>
				);
			case 4:
				return (
					<Button bsStyle="success" size="sm" className="outline box-status">
						เสร็จสิ้นแล้ว
					</Button>
				);
			case 5:
				return (
					<Button bsStyle="danger" size="sm">
						ถูกยกเลิก
					</Button>
				);
			case 6:
				return (
					<Button bsStyle="warning" size="sm" className="outline box-status">
						งานเกิดปัญหา
					</Button>
				);
			case 7:
				return (
					<Button bsStyle="default" size="sm" className="box-status">
						แบบร่าง
					</Button>
				);
			case 8:
				return (
					<Button bsStyle="primary" size="sm" className="outline box-status">
						นัดหมายลูกค้าแล้ว
					</Button>
				);
			default:
				return 'งานทั้งหมด';
		}
	}

	renderBadgeIcon = param => {
		switch (param) {
			case 4:
				return 'check';
			case 5:
				return 'times';
			case 6:
				return 'exclamation-triangle';
			default:
				return null;
		}
	};

	renderBadgeOutline = param => {
		switch (param) {
			case 2:
				return false;
			case 4:
				return false;
			default:
				return true;
		}
	};

	renderBadgeColor = param => {
		switch (param) {
			case 3:
				return `${color.Primary}`;
			case 4:
				return `${color.Primary}`;
			default:
				return `${color.Black}`;
		}
	};

	onClick = title => {
		const {
			state: { openSections },
		} = this;

		const isOpen = !!openSections[title];

		this.setState({
			openSections: {
				[title]: !isOpen,
			},
		});
	};

	renderLightboxContainner(data, index) {
		let image = [];
		for (var i = 0; i < data.length; i++) {
			const item = data[i];
			image.push(`${s3}/${item}`);
		}

		this.setState({
			isOpenLightbox: true,
			photoIndex: index,
			viewImageSrc: image,
		});
	}

	renderLightbox(mode, data, index) {
		if (mode === 'signature') {
			this.setState({
				isOpenLightbox: true,
				photoIndex: 0,
				viewImageSrc: [`${s3}/${data.signature_picture}`],
			});
		} else {
			let image = [];
			if (index == 1) {
				image.push(`${s3}/${data.back_picture}`);
				if (data.side_picture) {
					image.push(`${s3}/${data.side_picture}`);
				}
				if (data.front_picture) {
					image.push(`${s3}/${data.front_picture}`);
				}
			} else if (index == 2) {
				image.push(`${s3}/${data.side_picture}`);
				if (data.front_picture) {
					image.push(`${s3}/${data.front_picture}`);
				}
				if (data.back_picture) {
					image.push(`${s3}/${data.back_picture}`);
				}
			} else {
				image.push(`${s3}/${data.front_picture}`);

				if (data.back_picture) {
					image.push(`${s3}/${data.back_picture}`);
				}
				if (data.side_picture) {
					image.push(`${s3}/${data.side_picture}`);
				}
			}

			this.setState({
				isOpenLightbox: true,
				photoIndex: 0,
				viewImageSrc: image,
			});
		}
	}

	renderAdditional(item) {
		let text = '';
		for (let i = 0; i < item.length; i++) {
			if (item[i].list_type == 2) {
				if (
					item[i].list_name == 'รถใหญ่พิเศษ' ||
					item[i].list_name == 'บริการคืนใบส่งสินค้า (POD)'
				) {
					text += `${item[i].list_name}`;
				} else {
					text += `${item[i].list_name} x ${item[i].list_qty}`;
				}

				if (i < item.length - 1) {
					text += ', ';
				}
			}
		}
		return text;
	}

	renderAccessory(accessoryList) {
		const accessory = accessoryList.map(item => item.name_lang['th']);
		const textAccessory = accessory.map((item, index, array) => {
			const text = item;
			if (index !== array.length - 1) {
				return text + ', ';
			}

			return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>;
		});

		return <>{textAccessory}</>;
	}

	chkAdditionalPod(shipment) {
		const additional = shipment.shipment_additional.slice();
		const additionalFilter = additional.filter(
			item => item.additional_id === '5ca46d2d910efac4f7704577'
		);

		if (additionalFilter.length === 0) {
			return false;
		}
		return true;
	}

	renderAdditionalPod(shipment) {
		const additional = shipment.shipment_additional.slice();
		const additionalFilter = additional.filter(
			item => item.additional_id == '5ca46d2d910efac4f7704577'
		);
		if (additionalFilter.length === 0) {
			return '';
		}
		return additionalFilter[0].address;
	}

	getCurrentTrackingStatus(shipmentList, trackingList) {
		const trackingLength = trackingList.tracking_detail.length;

		// default data
		let currentTrackingStatus = '';
		let currentTrackingStatusNumber = 0;
		let totalTrackingStatusNumber = 1;

		const tracking = trackingList;
		const shipmentItem = shipmentList;

		// check total status number
		if (
			shipmentItem.shipment_additional.filter(
				item => item.additional_id === '5ca46d2d910efac4f7704577'
			).length > 0
		) {
			totalTrackingStatusNumber = trackingLength * 3 + 3;
		} else {
			totalTrackingStatusNumber = trackingLength * 3 + 2;
		}

		if (shipmentItem.shipment_status === 4) {
			if (
				shipmentItem.shipment_additional.filter(
					item => item.additional_id === '5ca46d2d910efac4f7704577'
				).length > 0
			) {
				currentTrackingStatus = 'ดำเนินการส่งเรียบร้อยแล้ว';
				currentTrackingStatusNumber = trackingLength * 3 + 3;
			} else {
				currentTrackingStatus = 'ดำเนินการส่งเรียบร้อยแล้ว';
				currentTrackingStatusNumber = trackingLength * 3 + 2;
			}
		} else {
			if (tracking.driver_accept === 1) {
				tracking.tracking_detail
					.sort(function(a, b) {
						return a.droppoint - b.droppoint;
					})
					.map((item, index) => {
						if (item.contact_customer_date) {
							if (item.take_picture_date) {
								if (item.signature_date) {
									if (
										currentTrackingStatusNumber <
										(index + 1) * 3 + currentTrackingStatusNumber
									) {
										currentTrackingStatus = `จุดที่ ${index +
											1} ยืนยันการรับสินค้า เรียบร้อยแล้ว`;
										currentTrackingStatusNumber = (index + 1) * 3;
									}
								} else {
									if (
										currentTrackingStatusNumber <
										(index + 1) * 2 + currentTrackingStatusNumber
									) {
										currentTrackingStatus = `จุดที่ ${index +
											1} ถ่ายรูปสินค้า เรียบร้อยแล้ว`;
										currentTrackingStatusNumber = (index + 1) * 2;
									}
								}
							} else {
								if (
									currentTrackingStatusNumber <
									(index + 1) * 1 + currentTrackingStatusNumber
								) {
									currentTrackingStatus = `จุดที่ ${index +
										1} ติดต่อจุดรับสินค้า เรียบร้อยแล้ว`;
									currentTrackingStatusNumber = (index + 1) * 1;
								}
							}
						} else {
							if (currentTrackingStatusNumber == 0) {
								currentTrackingStatus = 'กำลังดำเนินการ';
								currentTrackingStatusNumber = 0;
							}
						}
					});
			} else {
				currentTrackingStatus = 'รอขนส่งรับงาน';
				currentTrackingStatusNumber = 0;
			}
		}

		return {
			totalTrackingStatusNumber,
			currentTrackingStatus,
			currentTrackingStatusNumber,
		};
	}

	async getDriverWorking(shipmentId) {
		try {
			const { data } = await client.query({
				query: trackingList,
				variables: {
					shipment_id: shipmentId,
				},
			});
			this.setState({
				isDriverWorking: data.tracking[0].working,
			});
		} catch (error) {
			console.log(error);
		}
	}

	checkPODProcess(shipment, tracking) {
		const sortTrackingDetail = tracking.tracking_detail.sort(
			(a, b) => b.droppoint - a.droppoint
		);

		return (
			this.chkAdditionalPod(shipment) &&
			(shipment.shipment_type === 1 &&
				tracking.driver_accept === 1 &&
				sortTrackingDetail[0].signature_date !== null)
		);
	}

	getParamsTrackingUrl() {
		const { trackingUrl } = this.props.shipmentNumber;

		return trackingUrl;
	}

	renderNotFound() {
		return (
			<Row className="wrapper">
				<Helmet>
					<title>
						{`PAGE ERROR 404`} | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง
						รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>

				<NotFoundErrorContainer>
					<img src={`${require('src/assets/images/404.png')}`} alt="404" />
					<div className="title">ERROR 404</div>
					<div>ขออภัย ไม่พบหน้าเว็ปไซต์ที่คุณต้องการ</div>
				</NotFoundErrorContainer>
			</Row>
		);
	}

	handleChange(e) {
		this.setState({ value: e.target.value });
	}

	searchTracking() {
		if (this.state.value !== '' && this.state.value !== undefined) {
			const { trackingUrl } = this.props.shipmentNumber;
			const path = `/trackingdetail${
				!!trackingUrl ? `/${trackingUrl}` : ''
			}?shipment=${this.state.value}`;
			window.location = path;
		} else {
			alert('กรุณา กรอกรหัสติดตามงานขนส่งของคุณ');
		}
	}

	render() {
		const { viewImageSrc, isOpenLightbox, photoIndex } = this.state;
		// const uri = new URI(`${baseUrl}/${this.props.location.search}`);
		// const params = uri.search(true);
		// let shipmentNumber = params.shipment;
		let shipmentNumber = this.props.shipmentNumber;

		if (shipmentNumber === undefined || shipmentNumber === '') {
			shipmentNumber = 0;
		}

		let shipmentId = null;

		return (
			<CurrentUser>
				{!this.state.isLoadingTrackingUrl && (
					<div style={{ backgroundColor: '#ffffff' }}>
						<HeaderNav
							full
							isLoadingLogo={this.state.isLoadingTrackingUrl}
							trackingUrl={this.state.account_info_logo}
						/>

						{this.state.isShowHeader && (
							<TrackingHeader
								backLink={`/tracking${
									this.state.isNotRegisterTrackingUrl ||
									!this.getParamsTrackingUrl()
										? ''
										: `/${this.getParamsTrackingUrl()}`
								}`}
								valueTracking={shipmentNumber}
								trackingUrl={
									this.state.isNotRegisterTrackingUrl ||
									!this.getParamsTrackingUrl()
										? ''
										: this.getParamsTrackingUrl()
								}
							/>
						)}
						{isOpenLightbox ? (
							viewImageSrc.length > 1 ? (
								<Lightbox
									mainSrc={viewImageSrc[photoIndex]}
									nextSrc={viewImageSrc[(photoIndex + 1) % viewImageSrc.length]}
									prevSrc={
										viewImageSrc[
											(photoIndex + viewImageSrc.length - 1) %
												viewImageSrc.length
										]
									}
									onCloseRequest={() =>
										this.setState({ isOpenLightbox: false })
									}
									onMovePrevRequest={() =>
										this.setState({
											photoIndex:
												(photoIndex + viewImageSrc.length - 1) %
												viewImageSrc.length,
										})
									}
									onMoveNextRequest={() =>
										this.setState({
											photoIndex: (photoIndex + 1) % viewImageSrc.length,
										})
									}
								/>
							) : (
								<Lightbox
									mainSrc={viewImageSrc[photoIndex]}
									onCloseRequest={() =>
										this.setState({ isOpenLightbox: false })
									}
								/>
							)
						) : null}

						{this.state.isNotRegisterTrackingUrl && (
							<React.Fragment>{this.renderNotFound()}</React.Fragment>
						)}

						{this.state.isNotRegisterTrackingUrl === false && shipmentNumber && (
							<Query
								query={detailShipment}
								variables={{
									shipment_tracking_number: shipmentNumber,
									shipper: this.state.shipperId,
								}}
								onCompleted={data => {
									if (this.state.isInitial) {
										this.setState({
											isInitial: false,
											isShowHeader: data.shipmentList.shipment.length !== 0,
										});
									}
								}}
							>
								{({ loading, error, data, refetch, variables }) => {
									if (loading) return 'Loading...';
									if (error) return { error };
									const shipment = data.shipmentList.shipment;

									if (shipment.length === 0) {
										return (
											<React.Fragment>
												<RowAnt className="wrapper">
													{/* <Helmet>
														<title>
															{`Tracking ${
																shipmentNumber ? `${shipmentNumber}` : ''
															} | GIZTIX`}{' '}
															| GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง
															รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า
															ส่งสินค้าด่วน
														</title>
														<meta
															name="description"
															content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
														/>
													</Helmet> */}

													<RowAnt>
														<Col
															xs={12}
															sm={8}
															smOffset={2}
															md={6}
															mdOffset={3}
															lg={6}
															lgOffset={3}
														>
															<Title>
																{this.props.t('common:header.shipmentTracking')}
															</Title>
															<Subtitle>
																{this.props.t(
																	'common:header.shipmentTrackingSubtitle'
																)}
															</Subtitle>
															<FormGroup>
																<InputGroup>
																	<FormControl
																		type="text"
																		value={this.state.value}
																		placeholder={this.props.t(
																			'common:header.pleaseFillShipmentId'
																		)}
																		onChange={event => this.handleChange(event)}
																		onKeyPress={event => {
																			if (event.charCode === 13) {
																				this.searchTracking(event);
																			}
																		}}
																	/>
																	<InputGroup.Button>
																		<ButtonSearch
																			bsStyle="primary"
																			onClick={() => this.searchTracking()}
																		>
																			{this.props.t('common:header.tracking')}
																		</ButtonSearch>
																	</InputGroup.Button>
																</InputGroup>
															</FormGroup>
														</Col>
													</RowAnt>
													<RowAnt style={{ marginBottom: 20 }}>
														<Col
															xs={12}
															sm={8}
															smOffset={2}
															md={6}
															mdOffset={3}
															lg={6}
															lgOffset={3}
														>
															<Row>
																<Col>
																	<ErrorContainer>
																		{this.props.t(
																			'common:header.errorTracking'
																		)}
																	</ErrorContainer>
																</Col>
															</Row>
														</Col>
													</RowAnt>

													{/* <MediaQuery minWidth={768}>
														<Col
															sm={12}
															style={{ textAlign: 'center', marginTop: '80px' }}
														>
															<div style={{ fontSize: '60px' }}>
																<i className="fal fa-box" />
															</div>
															<h1 style={{ marginTop: '10px' }}>ไม่พบข้อมูล</h1>
															<h4 style={{ marginTop: '15px' }}>
																กรุณาตรวจสอบรหัสติดตามงานขนส่งถูกต้อง ตย. GTX
																ตามด้วยตัวเลขและตัวอักษร 12 หลัก(ตัวอย่าง
																GTX191105B12345) <br />{' '}
																หรือช่องทางที่คุณได้รับรหัสมา
															</h4>
														</Col>
														<div className="container">
															<Col
																sm={6}
																style={{ textAlign: 'left', marginTop: '80px' }}
															>
																<h3 style={{ marginTop: '10px' }}>
																	เกิดปัญหาหรือไม่?
																</h3>
																<p style={{ marginTop: '15px', lineHeight: 1 }}>
																	ฝ่ายบริการลูกค้าของเราจะตอบทุกคำถามที่คุณมีเกี่ยวกับการติดตามพัสดุ{' '}
																	<br />
																	หากคุณไม่แน่ใจ
																	คุณสามารถติดต่อฝ่ายบริการลูกค้าได้
																	<br />
																	คุณสามารถอ่านคำถามที่พบบ่อยเกี่ยวกับการติดตามพัสดุด้านล่างนี้
																</p>
															</Col>
														</div>
													</MediaQuery>

													<MediaQuery maxWidth={767}>
														<Col
															sm={12}
															style={{ textAlign: 'center', marginTop: '80px' }}
														>
															<div style={{ fontSize: '50px' }}>
																<i className="fal fa-box" />
															</div>
															<div
																style={{
																	marginTop: '10px',
																	fontWeight: 'bold',
																}}
															>
																ไม่พบข้อมูล
															</div>
															<div>
																กรุณาตรวจสอบรหัสติดตามงานขนส่ง ให้ครบทั้งหมด 11
																ตัว <br /> หรือช่องทางที่คุณได้รับรหัสมา
															</div>
														</Col>
														<div className="container">
															<Col
																sm={6}
																style={{ textAlign: 'left', marginTop: '80px' }}
															>
																<hr />
																<div
																	style={{
																		marginTop: '10px',
																		textAlign: 'center',
																		fontWeight: 'bold',
																	}}
																>
																	เกิดปัญหาหรือไม่?
																</div>
																<p style={{ marginTop: '15px', lineHeight: 1 }}>
																	ฝ่ายบริการลูกค้าของเราจะตอบทุกคำถามที่คุณมีเกี่ยวกับการติดตามพัสดุ
																	หากคุณไม่แน่ใจ
																	คุณสามารถติดต่อฝ่ายบริการลูกค้าได้
																	คุณสามารถอ่านคำถามที่พบบ่อยเกี่ยวกับการติดตามพัสดุด้านล่างนี้
																</p>
															</Col>
														</div>
													</MediaQuery> */}

													{/* <div
														className="container"
														style={{ marginBottom: '40px' }}
													>
														<Col sm={6}>
															<Accordion
																style={{ marginBottom: 10, marginTop: 10 }}
																title="วิธีการติดตามสถานะขนส่ง"
																isOpen={
																	!!this.state.openSections[
																		'วิธีการติดตามสถานะขนส่ง'
																	]
																}
																onClick={title => this.onClick(title)}
															>
																หลังจากทำการจองสำเร็จ ในหน้าข้อมูลการขนส่ง
																คุณจะได้รับหมายเลขงานขนส่ง GTX
																ตามด้วยตัวเลขและตัวอักษร 12 หลัก(ตัวอย่าง
																GTX191105B12345) คุณสามารถนำหมายเลขงานขนส่ง
																มาเช็คสถานะงานขนส่งได้ทุกขั้นตอน
															</Accordion>

															<Accordion
																title="สถานะขนส่งเช็คอะไรได้บ้าง?"
																isOpen={
																	!!this.state.openSections[
																		'สถานะขนส่งเช็คอะไรได้บ้าง?'
																	]
																}
																onClick={title => this.onClick(title)}
															>
																คุณสามารถเช็คข้อมูลงานขนส่งได้ตั้งแต่ชื่อเจ้าหน้าที่ขับรถ
																ทะเบียน
																รวมถึงสถานะการขนส่งทุกขั้นตอนจากเจ้าหน้าที่ขับรถได้ทันที
																และมีหลักฐานการรับ - ส่งสินค้าทั้งรูปถ่าย
																และลายเซ็น
															</Accordion>
														</Col>
													</div> */}
													<Footer />
												</RowAnt>
											</React.Fragment>
										);
									} else {
										shipmentId = shipment[0]._id;
										this.getDriverWorking(shipmentId);
										const documents = shipment[0].shipment_other_detail.document.map(
											item => {
												const patternFileType = /\.[0-9a-z]{1,5}$/;
												const patternCheckFileType = /^.*\.(pdf|PDF)$/;
												const thumbnail = patternCheckFileType.test(item.path)
													? `${require('src/assets/images/file-type-pdf.png')}`
													: `${s3}/${item.path}`;
												const type = patternCheckFileType.test(item.path)
													? 'pdf'
													: 'image';

												return {
													path: `${s3}/${item.path}`, //path ไฟล์
													fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
													type: type, //ประเภทไฟล์
													thumbnail: thumbnail, //รูปตัวอย่าง
												};
											}
										);

										const documentsImage = documents.map(item => {
											const patternCheckFileType = /^.*\.(pdf|PDF)$/;
											if (patternCheckFileType.test(item.path)) {
												return <PDFPreview file={item.path} />;
											}

											return item.thumbnail;
										});

										return (
											<Query
												query={invoiceList}
												variables={{
													shipment_id: shipmentId,
												}}
											>
												{({ loading, error, data, refetch, variables }) => {
													if (loading) return 'Loading...';
													if (error) return { error };
													const invoiceList = data.invoiceList;
													return (
														<Row className="wrapper">
															<Helmet>
																<title>
																	Tracking {shipment[0].shipment_number} |
																	GIZTIX
																</title>
																<meta
																	name="description"
																	content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย"
																/>
															</Helmet>

															{this.state.isOpenPhotoProductLightBox && (
																<Lightbox
																	mainSrc={
																		documentsImage[
																			this.state.photoProductIndexLightBox
																		]
																	}
																	nextSrc={
																		documentsImage.length > 1
																			? documentsImage[
																					(this.state
																						.photoProductIndexLightBox +
																						1) %
																						documentsImage.length
																			  ]
																			: undefined
																	}
																	prevSrc={
																		documentsImage.length > 1
																			? documentsImage[
																					(this.state
																						.photoProductIndexLightBox +
																						documentsImage.length -
																						1) %
																						documentsImage.length
																			  ]
																			: undefined
																	}
																	onCloseRequest={() =>
																		this.setState({
																			isOpenPhotoProductLightBox: false,
																		})
																	}
																	onMovePrevRequest={() =>
																		this.setState(prev => ({
																			photoProductIndexLightBox:
																				(prev.photoProductIndexLightBox +
																					documentsImage.length -
																					1) %
																				documentsImage.length,
																		}))
																	}
																	onMoveNextRequest={() =>
																		this.setState(prev => ({
																			photoProductIndexLightBox:
																				(prev.photoProductIndexLightBox + 1) %
																				documentsImage.length,
																		}))
																	}
																/>
															)}

															<MediaQuery minWidth={768}>
																{/* mainTrackingDetail */}
																<ToolContainer
																	className="ToolDetailWrapper"
																	id="mainTrackingDetail"
																	style={{ width: '840px' }}
																>
																	<SubContainer className="subDetailWrapper">
																		<Card
																			content={
																				<div>
																					<CardHeader
																						border
																						title={`ข้อมูลงานขนส่ง`}
																					/>

																					<Row>
																						<Col
																							sm={6}
																							style={{ paddingRight: '0' }}
																						>
																							<div style={{ lineHeight: 1 }}>
																								<div className="detail">
																									<p>
																										รหัสงานขนส่ง: <br />
																										<b>
																											{
																												shipment[0]
																													.shipment_number
																											}
																										</b>
																									</p>
																								</div>
																							</div>
																						</Col>
																						<Col
																							sm={6}
																							style={{ paddingLeft: '0' }}
																						>
																							<div style={{ lineHeight: 1 }}>
																								<div
																									className="detail"
																									style={{
																										borderLeft:
																											'1px solid #E3E3E3',
																										paddingLeft: '15px',
																									}}
																								>
																									<p>
																										จองเมื่อ: <br />
																										<b>
																											<Moment format="DD/MM/YYYY HH:mm">
																												{
																													shipment[0]
																														.create_date
																												}
																											</Moment>
																										</b>
																									</p>
																								</div>
																							</div>
																						</Col>
																					</Row>

																					<TransporterContainer>
																						<TransporterInner sm={12}>
																							<div
																								className="detail"
																								style={{ lineHeight: 1 }}
																							>
																								<p
																									style={{ marginBottom: '0' }}
																								>
																									ผู้ให้บริการขนส่ง: <br />
																									<b>
																										{shipment[0]
																											.transporter_detail
																											? shipment[0]
																													.transporter_detail
																											: 'บริษัท จิซทิกซ์ จำกัด'}
																									</b>
																								</p>
																							</div>
																							<div className="img" />
																						</TransporterInner>
																					</TransporterContainer>

																					<Row className="serviceWrapper">
																						<BoxServiceItem
																							icon="fal fa-pallet"
																							title="รูปแบบการขนส่ง"
																							subTitle={
																								shipment[0].shipment_type == 1
																									? 'ภายในประเทศ'
																									: shipment[0].shipment_type ==
																									  2
																									? 'นำเข้า'
																									: shipment[0].shipment_type ==
																									  3
																									? 'ส่งออก'
																									: 'ภายในประเทศ'
																							}
																						/>

																						<BoxServiceItem
																							icon="fal fa-truck"
																							title="บริการ"
																							subTitle={`รถขนส่ง ${
																								shipment[0].setting_truck_name
																									.th
																							} ${
																								shipment[0].truck_type_name.th
																							}`}
																						/>
																						<BoxServiceItem
																							icon="fal fa-box"
																							title="บริการเสริม"
																							subTitle={this.renderAdditional(
																								invoiceList[0].invoice_list
																							)}
																						/>
																						{shipment[0].shipment_accessory
																							.length > 0 && (
																							<BoxServiceItem
																								title={
																									shipment[0].shipment_type ===
																									1
																										? 'อุปกรณ์นิรภัย'
																										: 'อุปกรณ์เสริม'
																								}
																								subTitle={this.renderAccessory(
																									shipment[0].shipment_accessory
																								)}
																							/>
																						)}
																						{this.chkAdditionalPod(
																							shipment[0]
																						) ? (
																							<BoxServiceItem
																								title="ที่อยู่ในการจัดส่ง สำหรับ POD:"
																								subTitle={this.renderAdditionalPod(
																									shipment[0]
																								)}
																							/>
																						) : null}
																						<BoxServiceItem
																							icon="fal fa-clock"
																							title="เวลาเริ่มงาน"
																							subTitle={
																								<Moment format="DD/MM/YYYY HH:mm">
																									{shipment[0].pick_up_date}
																								</Moment>
																							}
																						/>
																					</Row>

																					<div
																						className="boxPosition"
																						style={{ lineHeight: 1 }}
																					>
																						{shipment[0].shipment_address.map(
																							(item, key) => (
																								<div className="boxItem">
																									{item.mode == 'รับสินค้า' ? (
																										<i
																											className="fas fa-dot-circle"
																											style={{
																												color: '#000000',
																												fontSize: 16,
																											}}
																										/>
																									) : (
																										<i
																											className="fas fa-map-marker-alt"
																											style={{
																												color: '#ED1C24',
																												fontSize: 16,
																											}}
																										/>
																									)}

																									<div
																										className="content"
																										style={{
																											borderBottom:
																												'1px solid rgb(227, 227, 227)',
																										}}
																									>
																										<div className="title">
																											{this.renderAddressMode(
																												item.mode,
																												key
																											)}
																										</div>
																										<div className="detail">
																											<p
																												style={{
																													paddingTop: '10px',
																												}}
																											>
																												<span
																													style={{
																														fontWeight: 600,
																													}}
																												>
																													{item.address}
																												</span>
																											</p>
																											{item.pick_up_date && (
																												<p>
																													{item.mode ==
																														'รับตู้หนัก' ||
																													item.mode ==
																														'รับตู้เบา'
																														? `วันที่${
																																item.mode
																														  }`
																														: `วัน/เวลา ที่${
																																item.mode
																														  }`}
																													:<br />{' '}
																													<span
																														style={{
																															fontWeight: 600,
																														}}
																													>
																														{item.mode ==
																															'รับตู้หนัก' ||
																														item.mode ==
																															'รับตู้เบา' ? (
																															<Moment format="DD/MM/YYYY">
																																{
																																	item.pick_up_date
																																}
																															</Moment>
																														) : (
																															<Moment format="DD/MM/YYYY HH:mm">
																																{
																																	item.pick_up_date
																																}
																															</Moment>
																														)}
																													</span>
																												</p>
																											)}
																											{item.delivery_date && (
																												<p>
																													{`วัน/เวลา ที่${
																														item.mode
																													}`}
																													:<br />{' '}
																													<span
																														style={{
																															fontWeight: 600,
																														}}
																													>
																														<Moment format="DD/MM/YYYY HH:mm">
																															{
																																item.delivery_date
																															}
																														</Moment>
																													</span>
																												</p>
																											)}
																											<p>
																												ติดต่อ :{' '}
																												<span
																													style={{
																														fontWeight: 600,
																													}}
																												>
																													{item.contact_name},{' '}
																													<span
																														style={{
																															fontWeight: 600,
																														}}
																													>
																														{item.contact_tel}
																													</span>
																												</span>
																											</p>
																											{item.remark ? (
																												<p>
																													หมายเหตุ <br />
																													<span
																														style={{
																															fontWeight: 600,
																															whiteSpace:
																																'pre-line',
																														}}
																													>
																														{item.remark}
																													</span>
																												</p>
																											) : null}
																										</div>
																									</div>
																								</div>
																							)
																						)}
																					</div>
																				</div>
																			}
																		/>

																		{/* shipment more detail */}
																		{shipment[0].shipment_other_detail.remark ||
																		shipment[0].shipment_other_detail
																			.user_ref ||
																		documents.length ? (
																			<div>
																				<BlankSpace height={10} />
																				<Card
																					content={
																						<div>
																							{documents.length > 0 && (
																								<div
																									style={{ marginBottom: 20 }}
																								>
																									<div>รูปภาพสินค้า/เอกสาร</div>
																									<ImageProductContainer>
																										{documents.map(
																											(item, index) => (
																												<div
																													className="image"
																													onClick={() =>
																														this.setState({
																															photoProductIndexLightBox: index,
																															isOpenPhotoProductLightBox: true,
																														})
																													}
																												>
																													<div className="image-hover" />
																													<img
																														src={`${
																															item.thumbnail
																														}`}
																														alt="productImage"
																													/>
																												</div>
																											)
																										)}
																									</ImageProductContainer>
																								</div>
																							)}

																							{shipment[0].shipment_other_detail
																								.user_ref && (
																								<div
																									style={{ marginBottom: 20 }}
																								>
																									<div>
																										รหัสอ้างอิงงานของคุณ
																									</div>
																									<div
																										style={{
																											whiteSpace: 'pre-line',
																											lineHeight: 1,
																											wordBreak: 'break-all',
																										}}
																									>
																										<strong>
																											{
																												shipment[0]
																													.shipment_other_detail
																													.user_ref
																											}
																										</strong>
																									</div>
																								</div>
																							)}

																							{shipment[0].shipment_other_detail
																								.remark && (
																								<div
																									style={{ marginBottom: 20 }}
																								>
																									<div>
																										หมายเหตุ/รายละเอียดสินค้า
																									</div>
																									<div
																										style={{
																											whiteSpace: 'pre-line',
																											lineHeight: 1,
																										}}
																									>
																										<strong>
																											{
																												shipment[0]
																													.shipment_other_detail
																													.remark
																											}
																										</strong>
																									</div>
																								</div>
																							)}
																						</div>
																					}
																				/>
																			</div>
																		) : null}
																	</SubContainer>
																	<SubContainer className="subDetailWrapper">
																		<Card
																			content={
																				<div>
																					{/* Tracking */}
																					<Query
																						query={trackingList}
																						variables={{
																							shipment_id: shipmentId,
																							check_agent_accept:
																								shipment[0].transporter,
																							tracking_drop:
																								shipment[0].tracking_drop,
																						}}
																					>
																						{({
																							loading,
																							error,
																							data,
																							refetch,
																							variables,
																						}) => {
																							if (loading) return 'Loading...';
																							if (error) return 'แบบร่าง';

																							const tracking =
																								data.trackingList.tracking;
																							let latestTracking = null;
																							if (
																								shipment[0].shipment_type === 1
																							) {
																								latestTracking =
																									tracking[0].tracking_detail[0]
																										.signature_date;
																							} else if (
																								shipment[0].shipment_type === 2
																							) {
																								latestTracking =
																									tracking[0]
																										.tracking_import_detail[0]
																										.return_container_date;
																							} else if (
																								shipment[0].shipment_type === 3
																							) {
																								latestTracking =
																									tracking[0]
																										.tracking_export_detail[0]
																										.return_container_date;
																							}
																							if (tracking.length > 0) {
																								const trackingStatus = this.getCurrentTrackingStatus(
																									shipment[0],
																									tracking[0]
																								);
																								return (
																									<div>
																										<CardHeader
																											border
																											title="การติดตามงาน"
																										/>

																										<div>
																											{this.renderStatus(
																												shipment[0]
																													.shipment_status,
																												latestTracking
																											)}
																										</div>
																										<div
																											style={{
																												padding: '5px 0',
																												borderBottom:
																													'1px solid #E3E3E3',
																											}}
																										>
																											<small
																												style={{
																													color: '#808080',
																												}}
																											>
																												อัพเดทล่าสุด:{' '}
																												<Moment format="DD/MM/YYYY HH:mm">
																													{
																														shipment[0]
																															.last_update
																													}
																												</Moment>{' '}
																											</small>
																										</div>
																										<div
																											style={{ lineHeight: 1 }}
																										>
																											{/* Driver Detail */}
																											{tracking[0]
																												.driver_name ? (
																												<div
																													style={{
																														margin: '15px 0',
																													}}
																												>
																													{shipment[0]
																														.transporter &&
																														(!shipment[0]
																															.driver_user_id ||
																															shipment[0]
																																.transporter !==
																																'5d0083ebc88a512bb2f5f7a6') && (
																															<div>
																																<b>
																																	ผู้ดูแลงาน :{' '}
																																	{shipment[0]
																																		.transporter_name
																																		? shipment[0]
																																				.transporter_name
																																		: tracking[0]
																																				.driver_name}
																																</b>
																																<br />
																																<span>
																																	โทร :{' '}
																																	<b>{`${
																																		shipment[0]
																																			.transporter_phone_code
																																			? shipment[0]
																																					.transporter_phone_code ==
																																			  66
																																				? 0
																																				: shipment[0]
																																						.transporter_phone_code +
																																				  ' '
																																			: tracking[0]
																																					.driver_phone_code ==
																																			  66
																																			? 0
																																			: shipment[0]
																																					.driver_phone_code +
																																			  ' '
																																	}${
																																		shipment[0]
																																			.transporter_phone_number
																																			? shipment[0]
																																					.transporter_phone_number
																																			: tracking[0]
																																					.driver_phone_number
																																	}`}</b>
																																</span>
																																<hr
																																	style={{
																																		marginBottom:
																																			'5px',
																																		marginTop:
																																			'5px',
																																	}}
																																/>
																															</div>
																														)}

																													{shipment[0]
																														.shipment_type ===
																														1 && (
																														<React.Fragment>
																															<DriverDetailContainer>
																																<div>
																																	<div>
																																		พนักงานขับรถ
																																		:{' '}
																																		<b>
																																			{shipment[0]
																																				.driver_name_under_mama
																																				? shipment[0]
																																						.driver_name_under_mama
																																				: tracking[0]
																																						.driver_name}
																																		</b>
																																	</div>
																																	<div>
																																		ทะเบียนรถ :{' '}
																																		<b>
																																			{
																																				tracking[0]
																																					.user_setting_truck_license_plate
																																			}
																																		</b>
																																	</div>
																																	<div>
																																		โทร :{' '}
																																		<b>
																																			{shipment[0]
																																				.driver_tel_under_mama
																																				? `${
																																						tracking[0]
																																							.driver_phone_code ==
																																						66
																																							? 0
																																							: tracking[0]
																																									.driver_phone_code +
																																							  ' '
																																				  }${
																																						shipment[0]
																																							.driver_tel_under_mama
																																				  }`
																																				: `${
																																						tracking[0]
																																							.driver_phone_code ==
																																						66
																																							? 0
																																							: tracking[0]
																																									.driver_phone_code +
																																							  ' '
																																				  }${
																																						tracking[0]
																																							.driver_phone_number
																																				  }`}
																																		</b>
																																	</div>
																																</div>

																																<AvatarContainer>
																																	<Avatar
																																		size={60}
																																		icon="user"
																																		src={`${s3}/${
																																			tracking[0]
																																				.driver_display_pic
																																		}`}
																																		onClick={() =>
																																			this.setState(
																																				{
																																					isOpenLightboxDriverAvatar: true,
																																				}
																																			)
																																		}
																																	/>

																																	{tracking[0]
																																		.favorite_driver ===
																																		1 && (
																																		<FavoriteIcon>
																																			<i className="fas fa-heart" />
																																		</FavoriteIcon>
																																	)}
																																</AvatarContainer>

																																{this.state
																																	.isOpenLightboxDriverAvatar && (
																																	<Lightbox
																																		mainSrc={`${s3}/${
																																			tracking[0]
																																				.driver_display_pic
																																		}`}
																																		onCloseRequest={() =>
																																			this.setState(
																																				{
																																					isOpenLightboxDriverAvatar: false,
																																				}
																																			)
																																		}
																																	/>
																																)}
																															</DriverDetailContainer>
																														</React.Fragment>
																													)}
																												</div>
																											) : null}

																											{/* Status Detail */}
																											{shipment[0]
																												.shipment_type == 1 && (
																												<BoxStatusDetail
																													numPoint={
																														shipment[0]
																															.shipment_address
																															.length
																													}
																													pickup={
																														shipment[0]
																															.shipment_address[0]
																															.subdictrict
																													}
																													delivery={
																														shipment[0]
																															.shipment_address[
																															shipment[0]
																																.shipment_address
																																.length - 1
																														].subdictrict
																													}
																													detail={
																														trackingStatus.currentTrackingStatus
																													}
																													statusNumber={
																														trackingStatus.currentTrackingStatusNumber
																													}
																													totalStatusNumber={
																														trackingStatus.totalTrackingStatusNumber
																													}
																													icon={
																														shipment[0]
																															.shipment_status ===
																															4 &&
																														'fal fa-check'
																													}
																												/>
																											)}

																											{/* PayCOD Detail */}
																											{shipment[0].paid_type ==
																											2 ? (
																												<BoxBorder
																													active={
																														shipment[0]
																															.paid_status
																															? true
																															: false
																													}
																													content={
																														<div>
																															<div className="img">
																																<i className="fal fa-hand-holding-usd" />
																															</div>
																															<div className="detail">
																																<b>
																																	ช่องทางชำระ:
																																</b>
																																<p>
																																	{shipment[0].shipment_address
																																		.map(
																																			(
																																				item,
																																				index
																																			) => ({
																																				...item,
																																				index,
																																			})
																																		)
																																		.filter(
																																			item =>
																																				item.paid_by_cod ===
																																				1
																																		)
																																		.map(
																																			item =>
																																				`ชำระเงิน ณ จุดที่ ${item.index +
																																					1} - ${
																																					item.mode
																																				}`
																																		)}
																																	<br />
																																	{shipment[0]
																																		.paid_status ===
																																	1
																																		? '*ชำระเงินเรียบร้อย'
																																		: '*โปรดชำระเงินให้กับพนักงานขับรถ'}
																																</p>
																															</div>
																														</div>
																													}
																												/>
																											) : shipment[0]
																													.paid_type === 3 ? (
																												<BoxBorder
																													active={true}
																													content={
																														<div>
																															<div className="img">
																																<i className="fal fa-file-alt" />
																															</div>
																															<div
																																className="detail"
																																style={{
																																	marginTop:
																																		'10px',
																																}}
																															>
																																<b>
																																	ช่องทางชำระ:
																																</b>
																																<p>
																																	ออกใบแจ้งหนี้
																																</p>
																															</div>
																														</div>
																													}
																												/>
																											) : null}

																											<div className="boxPosition tracking">
																												{shipment[0]
																													.shipment_status ==
																												4 ? (
																													<div className="boxItem inProgress">
																														<i className="fas fa-circle" />
																														<div className="content">
																															<div className="title">
																																<b>
																																	ดำเนินการส่งเรียบร้อยแล้ว
																																</b>{' '}
																															</div>
																															<div className="status">
																																<Moment format="DD/MM/YYYY HH:mm">
																																	{
																																		shipment[0]
																																			.completed_date
																																	}
																																</Moment>
																															</div>
																															<div className="detail" />
																														</div>
																													</div>
																												) : null}

																												{this.checkPODProcess(
																													shipment[0],
																													tracking[0]
																												) ? (
																													tracking[0]
																														.tracking_detail[0]
																														.pod_date !==
																													null ? (
																														<div className="boxItem">
																															<i className="fas fa-check-circle" />
																															<div className="content">
																																<div className="title">
																																	<b>
																																		ส่งเอกสาร
																																		POD
																																		เรียบร้อยแล้ว
																																	</b>
																																</div>
																																<div
																																	className="detail"
																																	style={{
																																		margin:
																																			'10px 0',
																																	}}
																																>
																																	<Row>
																																		<Col sm={5}>
																																			จัดส่งโดย:
																																		</Col>
																																		<Col sm={7}>
																																			{
																																				tracking[0]
																																					.tracking_detail[0]
																																					.pod_from
																																			}
																																		</Col>
																																	</Row>
																																	<Row>
																																		<Col sm={5}>
																																			หมายเลขพัสดุ:
																																		</Col>
																																		<Col
																																			sm={7}
																																			style={{
																																				fontWeight:
																																					'bold',
																																			}}
																																		>
																																			{
																																				tracking[0]
																																					.tracking_detail[0]
																																					.pod_track
																																			}
																																		</Col>
																																	</Row>
																																	<Row>
																																		<Col sm={5}>
																																			หมายเหตุ:
																																		</Col>
																																		<Col sm={7}>
																																			{
																																				tracking[0]
																																					.tracking_detail[0]
																																					.pod_remark
																																			}
																																		</Col>
																																	</Row>
																																</div>
																																<div className="status">
																																	<Moment format="DD/MM/YYYY HH:mm">
																																		{
																																			tracking[0]
																																				.tracking_detail[0]
																																				.pod_date
																																		}
																																	</Moment>
																																</div>
																																<div className="detail">
																																	{tracking[0].tracking_detail[0].pod_slip_picture
																																		.filter(
																																			item =>
																																				item
																																		)
																																		.map(
																																			(
																																				item,
																																				index,
																																				arr
																																			) => (
																																				<div
																																					className="img"
																																					onClick={() =>
																																						this.renderLightboxContainner(
																																							arr,
																																							index
																																						)
																																					}
																																				>
																																					<Image
																																						alt="pod_slip_picture"
																																						src={`${s3}/${item}`}
																																						rounded
																																					/>
																																				</div>
																																			)
																																		)}
																																</div>
																															</div>
																														</div>
																													) : (
																														<div className="boxItem inProgress">
																															<i className="fas fa-circle" />
																															<div className="content">
																																<div className="title">
																																	<b>
																																		ส่งเอกสาร
																																		POD
																																	</b>{' '}
																																</div>
																																<div className="status">
																																	รอดำเนินการ...
																																</div>
																																<div className="detail" />
																															</div>
																														</div>
																													)
																												) : null}

																												{/* วนที่อยู่ tracking */}
																												{shipment[0]
																													.shipment_type ==
																													1 && (
																													<Row>
																														<Col md={12}>
																															{tracking[0].tracking_detail
																																.sort(function(
																																	a,
																																	b
																																) {
																																	return (
																																		b.droppoint -
																																		a.droppoint
																																	);
																																})
																																.map(
																																	(
																																		item,
																																		index
																																	) => (
																																		<TrackingItem
																																			step={[
																																				1,
																																				2,
																																				3,
																																			]}
																																			tracking={
																																				tracking[0]
																																			}
																																			detail={
																																				tracking[0]
																																					.tracking_detail
																																			}
																																			index={
																																				index
																																			}
																																			renderLightbox={(
																																				mode,
																																				data,
																																				index
																																			) =>
																																				this.renderLightbox(
																																					mode,
																																					data,
																																					index
																																				)
																																			}
																																		/>
																																	)
																																)}

																															{/* ขนส่งยืนยันงาน */}
																															{tracking[0]
																																.driver_accept_date ==
																															null ? (
																																<div className="boxItem inProgress">
																																	<i className="fas fa-circle" />
																																	<div className="content">
																																		<div className="title">
																																			<b>
																																				รอขนส่งยืนยันงาน
																																			</b>{' '}
																																		</div>
																																		<div className="status">
																																			ขนส่งยังไม่ได้ยืนยันรับงาน
																																		</div>
																																		<div className="detail" />
																																	</div>
																																</div>
																															) : (
																																<div className="boxItem">
																																	<i className="fas fa-check-circle" />
																																	<div className="content">
																																		<div className="title">
																																			ขนส่งยืนยันงาน
																																			เรียบร้อยแล้ว
																																		</div>
																																		<div className="status">
																																			<Moment format="DD/MM/YYYY HH:mm">
																																				{
																																					tracking[0]
																																						.driver_accept_date
																																				}
																																			</Moment>
																																		</div>
																																		<div className="detail" />
																																	</div>
																																</div>
																															)}
																														</Col>
																													</Row>
																												)}
																												{shipment[0]
																													.shipment_type ==
																													2 && ( //import
																													<Row>
																														<Col md={12}>
																															<TrackingItemImport
																																tracking={
																																	tracking
																																}
																																onClickContainner={(
																																	data,
																																	index
																																) =>
																																	this.renderLightboxContainner(
																																		data,
																																		index
																																	)
																																}
																															/>
																														</Col>
																													</Row>
																												)}

																												{shipment[0]
																													.shipment_type ==
																													3 && ( //Export
																													<Row>
																														<Col md={12}>
																															<TrackingItemExport
																																tracking={
																																	tracking
																																}
																																onClickContainner={(
																																	data,
																																	index
																																) =>
																																	this.renderLightboxContainner(
																																		data,
																																		index
																																	)
																																}
																															/>
																														</Col>
																													</Row>
																												)}
																											</div>
																										</div>
																									</div>
																								);
																							}
																							return (
																								<div className="boxPosition tracking">
																									<div className="boxItem">
																										<i className="fas fa-check-circle" />
																										<div className="content">
																											<div className="title">
																												งานแบบร่าง
																											</div>
																											<div className="status">
																												<Moment format="DD/MM/YYYY HH:mm">
																													{
																														shipment[0]
																															.pick_up_date
																													}
																												</Moment>
																											</div>
																											<div className="detail" />
																										</div>
																									</div>
																								</div>
																							);
																						}}
																					</Query>
																				</div>
																			}
																		/>
																	</SubContainer>
																</ToolContainer>

																<div className="MapDetailWrapper">
																	<Query
																		query={trackingList}
																		variables={{
																			shipment_id: shipmentId,
																			check_agent_accept:
																				shipment[0].transporter,
																			tracking_drop: shipment[0].tracking_drop,
																		}}
																	>
																		{({
																			loading,
																			error,
																			data,
																			refetch,
																			variables,
																		}) => {
																			if (loading) return 'Loading...';
																			if (error) return 'แบบร่าง';
																			const tracking =
																				data.trackingList.tracking;
																			let diverWorking = false;
																			if (shipment[0].shipment_type === 1) {
																				diverWorking =
																					tracking.length > 0
																						? !tracking[0].tracking_detail[0]
																								.signature_date &&
																						  tracking[0].working &&
																						  !this.checkPODProcess(
																								shipment[0],
																								tracking[0]
																						  )
																						: false;
																			} else if (
																				shipment[0].shipment_type === 2 ||
																				shipment[0].shipment_type === 3
																			) {
																				diverWorking =
																					shipment[0].shipment_status === 3;
																			}
																			return (
																				<GoogleMaps
																					shipmentId={shipmentId}
																					shipmentNumber={shipmentNumber}
																					isHideDriver={!diverWorking}
																				/>
																			);
																		}}
																	</Query>
																</div>
															</MediaQuery>

															<MediaQuery maxWidth={767}>
																<div>
																	<div className="container">
																		<Row>
																			<Col xs={12}>
																				<div className="map-mobile-size">
																					<Query
																						query={trackingList}
																						variables={{
																							shipment_id: shipmentId,
																							check_agent_accept:
																								shipment[0].transporter,
																							tracking_drop:
																								shipment[0].tracking_drop,
																						}}
																					>
																						{({
																							loading,
																							error,
																							data,
																							refetch,
																							variables,
																						}) => {
																							if (loading) return 'Loading...';
																							if (error) return 'แบบร่าง';
																							const tracking =
																								data.trackingList.tracking;
																							let diverWorking = false;

																							if (
																								shipment[0].shipment_type === 1
																							) {
																								diverWorking =
																									tracking.length > 0
																										? !tracking[0]
																												.tracking_detail[0]
																												.signature_date &&
																										  tracking[0].working &&
																										  !this.checkPODProcess(
																												shipment[0],
																												tracking[0]
																										  )
																										: false;
																							} else if (
																								shipment[0].shipment_type ===
																									2 ||
																								shipment[0].shipment_type === 3
																							) {
																								diverWorking =
																									shipment[0]
																										.shipment_status === 3;
																							}

																							return (
																								<GoogleMaps
																									shipmentId={shipmentId}
																									shipmentNumber={
																										shipmentNumber
																									}
																									isHideDriver={!diverWorking}
																								/>
																							);
																						}}
																					</Query>
																				</div>

																				<DetailMobileContainer>
																					<div className="detail">
																						<Query
																							query={trackingList}
																							variables={{
																								shipment_id: shipmentId,
																								check_agent_accept:
																									shipment[0].transporter,
																								tracking_drop:
																									shipment[0].tracking_drop,
																							}}
																						>
																							{({
																								loading,
																								error,
																								data,
																								refetch,
																								variables,
																							}) => {
																								if (loading)
																									return 'Loading...';
																								if (error) return 'แบบร่าง';

																								const tracking =
																									data.trackingList.tracking;
																								let latestTracking = null;
																								if (
																									shipment[0].shipment_type ===
																									1
																								) {
																									latestTracking =
																										tracking[0]
																											.tracking_detail[0]
																											.signature_date;
																								} else if (
																									shipment[0].shipment_type ===
																									2
																								) {
																									latestTracking =
																										tracking[0]
																											.tracking_import_detail[0]
																											.return_container_date;
																								} else if (
																									shipment[0].shipment_type ===
																									3
																								) {
																									latestTracking =
																										tracking[0]
																											.tracking_export_detail[0]
																											.return_container_date;
																								}

																								if (tracking.length > 0) {
																									const trackingStatus = this.getCurrentTrackingStatus(
																										shipment[0],
																										tracking[0]
																									);
																									return (
																										<div>
																											<div className="header">
																												<div className="title">
																													{
																														shipment[0]
																															.shipment_address[0]
																															.subdictrict
																													}{' '}
																													-{' '}
																													{
																														shipment[0]
																															.shipment_address[
																															shipment[0]
																																.shipment_address
																																.length - 1
																														].subdictrict
																													}
																												</div>
																												<div>
																													{this.renderStatus(
																														shipment[0]
																															.shipment_status,
																														latestTracking
																													)}
																												</div>
																											</div>
																											<div
																												style={{
																													margin: '15px 0',
																												}}
																											>
																												<div
																													style={{
																														lineHeight: 1,
																													}}
																												>
																													{shipment[0]
																														.transporter &&
																														(!shipment[0]
																															.driver_user_id ||
																															shipment[0]
																																.transporter !==
																																'5d0083ebc88a512bb2f5f7a6') && (
																															<div>
																																<b>
																																	ผู้ดูแลงาน :{' '}
																																	{shipment[0]
																																		.transporter_name
																																		? shipment[0]
																																				.transporter_name
																																		: tracking[0]
																																				.driver_name}
																																</b>
																																<br />
																																<span>
																																	โทร :{' '}
																																	<b>{`${
																																		shipment[0]
																																			.transporter_phone_code
																																			? shipment[0]
																																					.transporter_phone_code ==
																																			  66
																																				? 0
																																				: shipment[0]
																																						.transporter_phone_code +
																																				  ' '
																																			: tracking[0]
																																					.driver_phone_code ==
																																			  66
																																			? 0
																																			: shipment[0]
																																					.driver_phone_code +
																																			  ' '
																																	}${
																																		shipment[0]
																																			.transporter_phone_number
																																			? shipment[0]
																																					.transporter_phone_number
																																			: tracking[0]
																																					.driver_phone_number
																																	}`}</b>
																																</span>
																															</div>
																														)}
																													{shipment[0]
																														.shipment_type ===
																														1 && (
																														<React.Fragment>
																															<hr
																																style={{
																																	marginBottom:
																																		'5px',
																																	marginTop:
																																		'5px',
																																}}
																															/>
																															<DriverDetailContainer>
																																<div>
																																	<div>
																																		พนักงานขับรถ
																																		:{' '}
																																		<b>
																																			{shipment[0]
																																				.driver_name_under_mama
																																				? shipment[0]
																																						.driver_name_under_mama
																																				: tracking[0]
																																						.driver_name}
																																		</b>
																																	</div>
																																	<div>
																																		ทะเบียนรถ :{' '}
																																		<b>
																																			{
																																				tracking[0]
																																					.user_setting_truck_license_plate
																																			}
																																		</b>
																																	</div>
																																	<div>
																																		โทร :{' '}
																																		<a
																																			href={`tel:${
																																				shipment[0]
																																					.driver_tel_under_mama
																																					? `${
																																							tracking[0]
																																								.driver_phone_code ==
																																							66
																																								? 0
																																								: tracking[0]
																																										.driver_phone_code +
																																								  ' '
																																					  }${
																																							shipment[0]
																																								.driver_tel_under_mama
																																					  }`
																																					: `${
																																							tracking[0]
																																								.driver_phone_code ==
																																							66
																																								? 0
																																								: tracking[0]
																																										.driver_phone_code +
																																								  ' '
																																					  }${
																																							tracking[0]
																																								.driver_phone_number
																																					  }`
																																			}`}
																																		>
																																			<b>
																																				{shipment[0]
																																					.driver_tel_under_mama
																																					? `${
																																							tracking[0]
																																								.driver_phone_code ==
																																							66
																																								? 0
																																								: tracking[0]
																																										.driver_phone_code +
																																								  ' '
																																					  }${
																																							shipment[0]
																																								.driver_tel_under_mama
																																					  }`
																																					: `${
																																							tracking[0]
																																								.driver_phone_code ==
																																							66
																																								? 0
																																								: tracking[0]
																																										.driver_phone_code +
																																								  ' '
																																					  }${
																																							tracking[0]
																																								.driver_phone_number
																																					  }`}
																																			</b>
																																		</a>
																																	</div>
																																</div>

																																<AvatarContainer>
																																	<Avatar
																																		size={60}
																																		icon="user"
																																		src={`${s3}/${
																																			tracking[0]
																																				.driver_display_pic
																																		}`}
																																		onClick={() =>
																																			this.setState(
																																				{
																																					isOpenLightboxDriverAvatar: true,
																																				}
																																			)
																																		}
																																	/>

																																	{tracking[0]
																																		.favorite_driver ===
																																		1 && (
																																		<FavoriteIcon>
																																			<i className="fas fa-heart" />
																																		</FavoriteIcon>
																																	)}
																																</AvatarContainer>

																																{this.state
																																	.isOpenLightboxDriverAvatar && (
																																	<Lightbox
																																		mainSrc={`${s3}/${
																																			tracking[0]
																																				.driver_display_pic
																																		}`}
																																		onCloseRequest={() =>
																																			this.setState(
																																				{
																																					isOpenLightboxDriverAvatar: false,
																																				}
																																			)
																																		}
																																	/>
																																)}
																															</DriverDetailContainer>
																														</React.Fragment>
																													)}
																												</div>
																												{shipment[0]
																													.shipment_type ==
																													1 && (
																													<div>
																														{/* Status Detail */}
																														<BoxStatusDetail
																															numPoint={
																																shipment[0]
																																	.shipment_address
																																	.length
																															}
																															pickup={
																																shipment[0]
																																	.shipment_address[0]
																																	.subdictrict
																															}
																															delivery={
																																shipment[0]
																																	.shipment_address[
																																	shipment[0]
																																		.shipment_address
																																		.length - 1
																																].subdictrict
																															}
																															detail={
																																trackingStatus.currentTrackingStatus
																															}
																															statusNumber={
																																trackingStatus.currentTrackingStatusNumber
																															}
																															totalStatusNumber={
																																trackingStatus.totalTrackingStatusNumber
																															}
																															icon={
																																shipment[0]
																																	.shipment_status ===
																																	4 &&
																																'fal fa-check'
																															}
																														/>
																													</div>
																												)}

																												<div className="tab-container">
																													<ul>
																														<li
																															className={`${this
																																.state
																																.tabSelect ===
																																1 && 'active'}`}
																															onClick={() =>
																																this.setState({
																																	tabSelect: 1,
																																})
																															}
																														>
																															ติดตามงาน
																														</li>
																														<li
																															className={`${this
																																.state
																																.tabSelect ===
																																2 && 'active'}`}
																															onClick={() =>
																																this.setState({
																																	tabSelect: 2,
																																})
																															}
																														>
																															ข้อมูลงาน
																														</li>
																													</ul>
																												</div>

																												{this.state
																													.tabSelect === 1 && (
																													<div>
																														<div
																															style={{
																																lineHeight: 1,
																															}}
																														>
																															{/* PayCOD Detail */}
																															{shipment[0]
																																.paid_type ==
																															2 ? (
																																<BoxBorder
																																	active={
																																		shipment[0]
																																			.paid_status
																																			? true
																																			: false
																																	}
																																	content={
																																		<div>
																																			<div className="img">
																																				<i className="fal fa-hand-holding-usd" />
																																			</div>
																																			<div className="detail">
																																				<b>
																																					ช่องทางชำระ:
																																				</b>
																																				<p>
																																					{shipment[0].shipment_address
																																						.map(
																																							(
																																								item,
																																								index
																																							) => ({
																																								...item,
																																								index,
																																							})
																																						)
																																						.filter(
																																							item =>
																																								item.paid_by_cod ===
																																								1
																																						)
																																						.map(
																																							item =>
																																								`ชำระเงิน ณ จุดที่ ${item.index +
																																									1} - ${
																																									item.mode
																																								}`
																																						)}
																																					<br />
																																					{shipment[0]
																																						.paid_status ===
																																					1
																																						? '*ชำระเงินเรียบร้อย'
																																						: '*โปรดชำระเงินให้กับพนักงานขับรถ'}
																																				</p>
																																			</div>
																																		</div>
																																	}
																																/>
																															) : shipment[0]
																																	.paid_type ===
																															  3 ? (
																																<BoxBorder
																																	active={true}
																																	content={
																																		<div>
																																			<div className="img">
																																				<i className="fal fa-file-alt" />
																																			</div>
																																			<div
																																				className="detail"
																																				style={{
																																					marginTop:
																																						'10px',
																																				}}
																																			>
																																				<b>
																																					ช่องทางชำระ:
																																				</b>
																																				<p>
																																					ออกใบแจ้งหนี้
																																				</p>
																																			</div>
																																		</div>
																																	}
																																/>
																															) : null}
																														</div>
																														<div className="boxPosition tracking">
																															{shipment[0]
																																.shipment_status ==
																															4 ? (
																																<div className="boxItem inProgress">
																																	<i className="fas fa-circle" />
																																	<div className="content">
																																		<div className="title">
																																			<b>
																																				ดำเนินการส่งเรียบร้อยแล้ว
																																			</b>{' '}
																																		</div>
																																		<div className="status">
																																			<Moment format="DD/MM/YYYY HH:mm">
																																				{
																																					shipment[0]
																																						.completed_date
																																				}
																																			</Moment>
																																		</div>
																																		<div className="detail" />
																																	</div>
																																</div>
																															) : null}

																															{this.chkAdditionalPod(
																																shipment[0]
																															) ? (
																																shipment[0]
																																	.shipment_type ==
																																	1 &&
																																tracking[0]
																																	.driver_accept ==
																																	1 &&
																																tracking[0]
																																	.tracking_detail[0]
																																	.signature_date !=
																																	null ? (
																																	tracking[0]
																																		.tracking_detail[0]
																																		.pod_date !=
																																	null ? (
																																		<div className="boxItem">
																																			<i className="fas fa-check-circle" />
																																			<div className="content">
																																				<div className="title">
																																					<b>
																																						ส่งเอกสาร
																																						POD
																																						เรียบร้อยแล้ว
																																					</b>
																																				</div>
																																				<div
																																					className="detail"
																																					style={{
																																						margin:
																																							'10px 0',
																																					}}
																																				>
																																					<Row>
																																						<Col
																																							sm={
																																								5
																																							}
																																						>
																																							จัดส่งโดย:
																																						</Col>
																																						<Col
																																							sm={
																																								7
																																							}
																																						>
																																							{
																																								tracking[0]
																																									.tracking_detail[0]
																																									.pod_from
																																							}
																																						</Col>
																																					</Row>
																																					<Row>
																																						<Col
																																							sm={
																																								5
																																							}
																																						>
																																							หมายเลขพัสดุ:
																																						</Col>
																																						<Col
																																							sm={
																																								7
																																							}
																																							style={{
																																								fontWeight:
																																									'bold',
																																							}}
																																						>
																																							{
																																								tracking[0]
																																									.tracking_detail[0]
																																									.pod_track
																																							}
																																						</Col>
																																					</Row>
																																					<Row>
																																						<Col
																																							sm={
																																								5
																																							}
																																						>
																																							หมายเหตุ:
																																						</Col>
																																						<Col
																																							sm={
																																								7
																																							}
																																						>
																																							{
																																								tracking[0]
																																									.tracking_detail[0]
																																									.pod_remark
																																							}
																																						</Col>
																																					</Row>
																																				</div>
																																				<div className="status">
																																					<Moment format="DD/MM/YYYY HH:mm">
																																						{
																																							tracking[0]
																																								.tracking_detail[0]
																																								.pod_date
																																						}
																																					</Moment>
																																				</div>
																																			</div>
																																		</div>
																																	) : (
																																		<div className="boxItem inProgress">
																																			<i className="fas fa-circle" />
																																			<div className="content">
																																				<div className="title">
																																					<b>
																																						ส่งเอกสาร
																																						POD
																																					</b>{' '}
																																				</div>
																																				<div className="status">
																																					รอดำเนินการ...
																																				</div>
																																				<div className="detail" />
																																			</div>
																																		</div>
																																	)
																																) : null
																															) : null}

																															{shipment[0]
																																.shipment_type ==
																																1 && ( //import
																																<Row>
																																	<Col md={12}>
																																		{/* วนที่อยู่ tracking */}
																																		{tracking[0].tracking_detail
																																			.sort(
																																				function(
																																					a,
																																					b
																																				) {
																																					return (
																																						b.droppoint -
																																						a.droppoint
																																					);
																																				}
																																			)
																																			.map(
																																				(
																																					item,
																																					index
																																				) => (
																																					<TrackingItem
																																						step={[
																																							1,
																																							2,
																																							3,
																																						]}
																																						tracking={
																																							tracking[0]
																																						}
																																						detail={
																																							tracking[0]
																																								.tracking_detail
																																						}
																																						index={
																																							index
																																						}
																																						renderLightbox={(
																																							mode,
																																							data,
																																							index
																																						) =>
																																							this.renderLightbox(
																																								mode,
																																								data,
																																								index
																																							)
																																						}
																																					/>
																																				)
																																			)}

																																		{/* ขนส่งยืนยันงาน */}
																																		{tracking[0]
																																			.driver_accept_date ==
																																		null ? (
																																			<div className="boxItem inProgress">
																																				<i className="fas fa-circle" />
																																				<div className="content">
																																					<div className="title">
																																						<b>
																																							รอขนส่งยืนยันงาน
																																						</b>{' '}
																																					</div>
																																					<div className="status">
																																						ขนส่งยังไม่ได้ยืนยันรับงาน
																																					</div>
																																					<div className="detail" />
																																				</div>
																																			</div>
																																		) : (
																																			<div className="boxItem">
																																				<i className="fas fa-check-circle" />
																																				<div className="content">
																																					<div className="title">
																																						ขนส่งยืนยันงาน
																																						เรียบร้อยแล้ว
																																					</div>
																																					<div className="status">
																																						<Moment format="DD/MM/YYYY HH:mm">
																																							{
																																								tracking[0]
																																									.driver_accept_date
																																							}
																																						</Moment>
																																					</div>
																																					<div className="detail" />
																																				</div>
																																			</div>
																																		)}
																																	</Col>
																																</Row>
																															)}

																															{shipment[0]
																																.shipment_type ==
																																2 && ( //import
																																<Row>
																																	<Col md={12}>
																																		<TrackingItemImport
																																			tracking={
																																				tracking
																																			}
																																			onClickContainner={(
																																				data,
																																				index
																																			) =>
																																				this.renderLightboxContainner(
																																					data,
																																					index
																																				)
																																			}
																																		/>
																																	</Col>
																																</Row>
																															)}

																															{shipment[0]
																																.shipment_type ==
																																3 && ( //Export
																																<Row>
																																	<Col md={12}>
																																		<TrackingItemExport
																																			tracking={
																																				tracking
																																			}
																																			onClickContainner={(
																																				data,
																																				index
																																			) =>
																																				this.renderLightboxContainner(
																																					data,
																																					index
																																				)
																																			}
																																		/>
																																	</Col>
																																</Row>
																															)}
																														</div>
																													</div>
																												)}

																												{this.state
																													.tabSelect === 2 && (
																													<div>
																														<Row className="serviceWrapper">
																															<BoxServiceItem
																																icon="fal fa-pallet"
																																title="รูปแบบการขนส่ง"
																																subTitle={
																																	shipment[0]
																																		.shipment_type ==
																																	1
																																		? 'ภายในประเทศ'
																																		: shipment[0]
																																				.shipment_type ==
																																		  2
																																		? 'นำเข้า'
																																		: shipment[0]
																																				.shipment_type ==
																																		  3
																																		? 'ส่งออก'
																																		: 'ภายในประเทศ'
																																}
																															/>
																															<BoxServiceItem
																																icon="fal fa-truck"
																																title="บริการ"
																																subTitle={`รถขนส่ง ${
																																	shipment[0]
																																		.setting_truck_name
																																		.th
																																} ${
																																	shipment[0]
																																		.truck_type_name
																																		.th
																																}`}
																															/>
																															<BoxServiceItem
																																icon="fal fa-box"
																																title="บริการเสริม"
																																subTitle={this.renderAdditional(
																																	invoiceList[0]
																																		.invoice_list
																																)}
																															/>
																															{shipment[0]
																																.shipment_accessory
																																.length > 0 && (
																																<BoxServiceItem
																																	title={
																																		shipment[0]
																																			.shipment_type ===
																																		1
																																			? 'อุปกรณ์นิรภัย'
																																			: 'อุปกรณ์เสริม'
																																	}
																																	subTitle={this.renderAccessory(
																																		shipment[0]
																																			.shipment_accessory
																																	)}
																																/>
																															)}
																															<BoxServiceItem
																																icon="fal fa-clock"
																																title="เวลาเริ่มงาน"
																																subTitle={
																																	<Moment format="DD/MM/YYYY HH:mm">
																																		{
																																			shipment[0]
																																				.pick_up_date
																																		}
																																	</Moment>
																																}
																															/>
																														</Row>

																														<div
																															className="boxPosition"
																															style={{
																																lineHeight: 1,
																															}}
																														>
																															{shipment[0].shipment_address.map(
																																(item, key) => (
																																	<div className="boxItem">
																																		{item.mode ==
																																		'รับสินค้า' ? (
																																			<i
																																				className="fas fa-dot-circle"
																																				style={{
																																					color:
																																						'#000000',
																																					fontSize: 16,
																																				}}
																																			/>
																																		) : (
																																			<i
																																				className="fas fa-map-marker-alt"
																																				style={{
																																					color:
																																						'#ED1C24',
																																					fontSize: 16,
																																				}}
																																			/>
																																		)}
																																		<div
																																			className="content"
																																			style={{
																																				borderBottom:
																																					'1px solid rgb(227, 227, 227)',
																																			}}
																																		>
																																			<div className="title">
																																				{this.renderAddressMode(
																																					item.mode,
																																					key
																																				)}
																																			</div>
																																			<div className="detail">
																																				<p
																																					style={{
																																						paddingTop:
																																							'10px',
																																					}}
																																				>
																																					<span
																																						style={{
																																							fontWeight: 600,
																																						}}
																																					>
																																						{
																																							item.address
																																						}
																																					</span>
																																				</p>

																																				{item.pick_up_date && (
																																					<p>
																																						{item.mode ==
																																							'รับตู้หนัก' ||
																																						item.mode ==
																																							'รับตู้เบา'
																																							? `วันที่${
																																									item.mode
																																							  }`
																																							: `วัน/เวลา ที่${
																																									item.mode
																																							  }`}
																																						:
																																						<br />{' '}
																																						<span
																																							style={{
																																								fontWeight: 600,
																																							}}
																																						>
																																							{item.mode ==
																																								'รับตู้หนัก' ||
																																							item.mode ==
																																								'รับตู้เบา' ? (
																																								<Moment format="DD/MM/YYYY">
																																									{
																																										item.pick_up_date
																																									}
																																								</Moment>
																																							) : (
																																								<Moment format="DD/MM/YYYY HH:mm">
																																									{
																																										item.pick_up_date
																																									}
																																								</Moment>
																																							)}
																																						</span>
																																					</p>
																																				)}
																																				{item.delivery_date && (
																																					<p>
																																						{`วัน/เวลา ที่${
																																							item.mode
																																						}`}
																																						:
																																						<br />{' '}
																																						<span
																																							style={{
																																								fontWeight: 600,
																																							}}
																																						>
																																							<Moment format="DD/MM/YYYY HH:mm">
																																								{
																																									item.delivery_date
																																								}
																																							</Moment>
																																						</span>
																																					</p>
																																				)}

																																				<p>
																																					ติดต่อ
																																					:{' '}
																																					<span
																																						style={{
																																							fontWeight: 600,
																																						}}
																																					>
																																						{
																																							item.contact_name
																																						}
																																						,{' '}
																																						<span
																																							style={{
																																								fontWeight: 600,
																																							}}
																																						>
																																							{
																																								item.contact_tel
																																							}
																																						</span>
																																					</span>
																																				</p>
																																				{item.remark ? (
																																					<p>
																																						หมายเหตุ{' '}
																																						<br />
																																						<span
																																							style={{
																																								fontWeight: 600,
																																								whiteSpace:
																																									'pre-line',
																																							}}
																																						>
																																							{
																																								item.remark
																																							}
																																						</span>
																																					</p>
																																				) : null}
																																			</div>
																																		</div>
																																	</div>
																																)
																															)}
																														</div>

																														{/* shipment more detail */}
																														{shipment[0]
																															.shipment_other_detail
																															.remark ||
																														shipment[0]
																															.shipment_other_detail
																															.user_ref ||
																														documents.length ? (
																															<div>
																																<BlankSpace
																																	height={10}
																																/>
																																<Card
																																	content={
																																		<div>
																																			{documents.length >
																																				0 && (
																																				<div
																																					style={{
																																						marginBottom: 20,
																																					}}
																																				>
																																					<div>
																																						รูปภาพสินค้า/เอกสาร
																																					</div>
																																					<ImageProductContainer>
																																						{documents.map(
																																							(
																																								item,
																																								index
																																							) => (
																																								<div
																																									className="image"
																																									onClick={() =>
																																										this.setState(
																																											{
																																												photoProductIndexLightBox: index,
																																												isOpenPhotoProductLightBox: true,
																																											}
																																										)
																																									}
																																								>
																																									<div className="image-hover" />
																																									<img
																																										src={`${
																																											item.thumbnail
																																										}`}
																																										alt="productImage"
																																									/>
																																								</div>
																																							)
																																						)}
																																					</ImageProductContainer>
																																				</div>
																																			)}

																																			{shipment[0]
																																				.shipment_other_detail
																																				.user_ref && (
																																				<div
																																					style={{
																																						marginBottom: 20,
																																					}}
																																				>
																																					<div>
																																						รหัสอ้างอิงงานของคุณ
																																					</div>
																																					<div
																																						style={{
																																							whiteSpace:
																																								'pre-line',
																																							lineHeight: 1,
																																							wordBreak:
																																								'break-all',
																																						}}
																																					>
																																						<strong>
																																							{
																																								shipment[0]
																																									.shipment_other_detail
																																									.user_ref
																																							}
																																						</strong>
																																					</div>
																																				</div>
																																			)}

																																			{shipment[0]
																																				.shipment_other_detail
																																				.remark && (
																																				<div
																																					style={{
																																						marginBottom: 20,
																																					}}
																																				>
																																					<div>
																																						หมายเหตุ/รายละเอียดสินค้า
																																					</div>
																																					<div
																																						style={{
																																							whiteSpace:
																																								'pre-line',
																																							lineHeight: 1,
																																						}}
																																					>
																																						<strong>
																																							{
																																								shipment[0]
																																									.shipment_other_detail
																																									.remark
																																							}
																																						</strong>
																																					</div>
																																				</div>
																																			)}
																																		</div>
																																	}
																																/>
																															</div>
																														) : null}
																													</div>
																												)}
																											</div>
																										</div>
																									);
																								}
																							}}
																						</Query>
																					</div>
																				</DetailMobileContainer>
																			</Col>
																		</Row>
																	</div>
																</div>
																<Footer />
															</MediaQuery>
														</Row>
													);
												}}
											</Query>
										);
									}
								}}
							</Query>
						)}
					</div>
				)}
			</CurrentUser>
		);
	}
}

export default withNamespaces()(TrackingDetailTruck);
