import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;
const InputBox = styled.input`
  width: 100%;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  padding: 0px 7px;
  height: 30px;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: #E3E3E3;

    ${props => props.error && `
      color: #D90101;
    `}
  }

  ${props => props.error && `
    border: 1px solid #D90101;
  `}

  &:disabled {
    background-color: #e9ecef;
    color: #000000 !important;
  }
`;

const Label = styled.label`
  line-height: 1;
  color: #808080;
  white-space: nowrap;
`;

const Required = styled.span`
  color: #D90101;
`;

const Input = (props) => {
  const {style, ...other } = props;
  return (
    <Container style={props.style}>
      <Label>{props.inputLabel}{props.required && <Required>*</Required>}</Label>
      <InputBox {...other} />
    </Container>
  )
}

export default Input;