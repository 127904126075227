import gql from 'graphql-tag';

export default gql`
	mutation ShipmentAddressHistoryAdd(
    $mode: String
    $address: String
    $lat: String
    $lng: String
    $history: Int
    $favorite: Int
    ) {
		shipmentAddressHistoryAdd(
      mode: $mode
      address: $address
      lat: $lat
      lng: $lng
      history: $history
      favorite: $favorite
    ) {
			_id
		}
	}
`;
