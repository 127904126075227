import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const Header = styled.div`
  text-align: center;

  & p {
    margin: 0;
    padding: 0;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1;
  }

  & i {
    display: block;
    font-size: 30px;
    margin-bottom: 10px;
  }
`;

const ModalContainer = styled(Modal)`
  min-width: 300px;

  & .modal-dialog{
    max-width: ${props => props.maxWidth ? props => props.maxWidth : "100%"};
  }
`;

const Body = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 1;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 23px;
  top: 15px;
  cursor: pointer;

  & i {
    font-size: 24px;
  }
`;

const ModalAlert = props => (
      <ModalContainer {...props} id={props.id} show={props.show} onHide={() => props.onCloseModal ? props.onCloseModal() : null}>
        <Modal.Body>
          <Header>
            {
              props.icon && (<i className={props.icon}></i>)
            }
            
            <p>{props.title}</p>

            {props.onPressClose &&
            (
              <CloseButton>
                <i onClick={props.onPressClose} className="fal fa-times"></i>
              </CloseButton>
              
            )}
          </Header>

          <Body>
            {props.children}
          </Body>
        </Modal.Body>
      </ModalContainer>
);
ModalAlert.propTypes = {
	maxWidth: PropTypes.string,
	id: PropTypes.string,
	show: PropTypes.bool,
	title: PropTypes.string,
	icon: PropTypes.string,
	onPressClose: PropTypes.func,
	children: PropTypes.node,
};

ModalAlert.defaultProps = {
	show: false
};
export default ModalAlert;