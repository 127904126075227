import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export class CardTable extends Component {
  render() {
    return (
      <div className={'card card_table' + (this.props.plain ? ' card-plain' : '')}>
        {this.props.title ? (
          <div className={'header' + (this.props.hCenter ? ' text-center' : '')}>
            <div style={{ float: 'left', marginRight: '15px' }}>
              <h3 className="title">{this.props.title}</h3>
              <p className="category">{this.props.category}</p>
            </div>
            {this.props.rightButton && this.props.rightButton}
          </div>
        ) : this.props.leftButton ? (
          <div className={'header' + (this.props.hCenter ? ' text-center' : '')}>
            {this.props.leftButton && this.props.leftButton}
            {this.props.rightButton && this.props.rightButton}
          </div>
        ) : (
          ''
        )}

        <div
          className={
            'content' +
            (this.props.ctAllIcons ? ' all-icons' : '') +
            (this.props.ctTableFullWidth ? ' table-full-width' : '') +
            (this.props.ctTableResponsive ? ' table-responsive' : '') +
            (this.props.ctTableUpgrade ? ' table-upgrade' : '')
          }
        >
          {this.props.content}

          <div className="footer">
            {this.props.legend}
            {this.props.stats != null ? <hr /> : ''}
            <div className="stats">
              <i className={this.props.statsIcon} /> {this.props.stats}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardTable;
