const modeToEng = (mode, shipmentType = 1) => {
  if(mode === 'รับสินค้า') {
    return 'pickup';
  } else if(mode === 'ส่งสินค้า' && shipmentType === 1) {
    return 'delivery';
  } else if(mode === 'roundtrip') {
    return 'roundtrip';
  } else if(mode === 'รับตู้หนัก') {
    return 'importPickup';
  } else if(mode === 'ส่งสินค้า' && shipmentType === 2) {
    return 'importDelivery';
  } else if(mode === 'คืนตู้เบา') {
    return 'importReturn';
  } else if(mode === 'รับตู้เบา') {
    return 'exportPickup';
  } else if(mode === 'ขึ้นสินค้า') {
    return 'exportLoading';
  } else if(mode === 'คืนตู้หนัก') {
    return 'exportReturn';
  } else {
    return mode;
  }
}

export default modeToEng;