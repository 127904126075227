import gql from 'graphql-tag';

export default gql`
	mutation ShipmentPaymentConfirm(
		$setting_truck_name: languageArg
		$truck_type_name: languageArg
		$setting_truck_id: ID
		$setting_truck_type_id: ID
		$pick_up_date: String
		$shipment_other_detail_document: [type_pathArg]
		$shipment_other_detail_user_ref: String
		$shipment_other_detail_remark: String
		$shipment_additional: [typeShipmentAdditionalArg]
		$paymentCreditCardId: ID
		$paymentCreditCardBank: String
		$paymentCreditCardLastDigits: String
		$paymentCreditCardBrand: String
		$paymentCreditCarName: String
		$additional: [getPriceAdditional]
		$promotion_name: String
		$paymentType: Int!
		$reShipment: Int
		$shipment_lat: String
		$shipment_lng: String
		$round_trip: Int
		$shipment_address: [typeShipmentAddressArg]
		$get_price_address: [inputGetInstancePriceAddressShipment]
		$shipment_type: Int!
		$shipment_accessory: [typeShipmentAccessoryArg]
		$shipment_confirm_detail: typeShipmentConfirmDetailArg
		$utm_source: String
		$utm_medium: String
		$utm_campaign: String
		$ip_address: String
		$flash_deals_id: ID
		$show_driver_user_id: [ID]
	) {
		shipmentPaymentConfirm(
			shipment: {
				setting_truck_name: $setting_truck_name
				truck_type_name: $truck_type_name
				setting_truck_id: $setting_truck_id
				setting_truck_type_id: $setting_truck_type_id
				truck_type_qty: 1
				pick_up_date: $pick_up_date
				shipment_detail_type: 1
				shipment_other_detail: {
					document: $shipment_other_detail_document
					user_ref: $shipment_other_detail_user_ref
					remark: $shipment_other_detail_remark
				}
				shipment_address: $shipment_address
				shipment_additional: $shipment_additional
				re_shipment: $reShipment
				shipment_lat: $shipment_lat
				shipment_lng: $shipment_lng
				round_trip: $round_trip
				shipment_type: $shipment_type
				shipment_accessory: $shipment_accessory
				shipment_confirm_detail: $shipment_confirm_detail
				utm_source: $utm_source
				utm_medium: $utm_medium
				utm_campaign: $utm_campaign
				ip_address: $ip_address
				flash_deals_id: $flash_deals_id
				show_driver_user_id: $show_driver_user_id
			}
			paymentType: $paymentType
			paymentCreditCardId: $paymentCreditCardId
			paymentCreditCardBank: $paymentCreditCardBank
			paymentCreditCardLastDigits: $paymentCreditCardLastDigits
			paymentCreditCardBrand: $paymentCreditCardBrand
			paymentCreditCarName: $paymentCreditCarName
			getPrice: {
				setting_truck_type_id: $setting_truck_type_id
				additional: $additional
				promotion_name: $promotion_name
				call_from: 2
				round_trip: $round_trip
				address: $get_price_address
			}
		) {
			_id
			uri
		}
	}
`;
