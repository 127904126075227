import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 18px;
  height: 40px;
  padding: 10px 15px;
  background-color: ${props => (props.isSelected) ? "#F2F2F2" : "#FFFFFF"};
  cursor: pointer;

  &:hover {
    background-color: ${props => (props.isSelected) ? "#F2F2F2" : "#F2F2F270"};
  }
`;

const IconStyle = styled.div`
  display: inline-block;
  width: 20px;
  linr-height:16px;
  margin-right: 15px;
  color: ${props => props.iconColor || "#000000"}
  font-size: 18px;
`;

class SelectDropdown extends React.Component {
  render() {
    return (
      <Container {... this.props.innerProps} isSelected={this.props.isSelected}>
        { (this.props.iconName) ? (<IconStyle iconColor={this.props.iconColor}><i className={this.props.iconName}></i></IconStyle>) : null }
        {this.props.label}
      </Container>
    )
  }
}

export default SelectDropdown;