import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { Card, BlankSpace, DropPoint, Input, IconButton, Modal, MessageAlert } from '../../components';
import { PDFPreview } from 'src/components';
import urijs from 'urijs';
import Helmet from 'react-helmet';
import Moment from 'react-moment';
import Autosuggest from 'react-autosuggest';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import s3 from "../../../../config/s3";
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

//gql
import shipmentListQuery from './graphql/query/shipmentList';
import driverUserListQuery from './graphql/query/driverUserList';
import driverUserAddMutation from './graphql/mutation/driverUserAdd';
import userSettingTruckAddMutation from './graphql/mutation/userSettingTruckAdd';
import driverUserUpdateMutation from './graphql/mutation/driverUserUpdate';
import userSettingTruckUpdateMutation from './graphql/mutation/userSettingTruckUpdate';
import assignDriverMutation from './graphql/mutation/assignDriver';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import { Query, Mutation, compose, graphql } from 'react-apollo';
import client from '../../../../config/client';
import lineDriveUserQuery from './graphql/query/lineDriveUser';
import lineTruckQuery from './graphql/query/lineTruck';
import lineAssignDriverMutation from './graphql/mutation/lineAssignDriver';
import acceptShipmentDriverMutation from './graphql/mutation/acceptShipmentDriver';

//LIFF
const liff = window.liff; 

const token = {
  authorization: 'giztix ej8RYXCKc4DM3LT5t4pjA72JfyxxtnTMP85N2hrHP38EdxYdTDTfDjgrQ6Zr7s2hgT76SzNg5XHPqBHZVJa9ZbrxUUe5jxqmQTeS77mgZFfWLDdu7mNVSXFrEqEf3J24'
};

//Validate Yup
const driverValidate = Yup.object().shape({
  driverName: Yup.string()
    .required('กรุณากรอกข้อมูล'),
  driverPhoneNumber: Yup.string()
    .matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง')
    .required('กรุณากรอกข้อมูล'),
  driverLicensePlate: Yup.string()
    .required('กรุณากรอกข้อมูล'),
  driver_tel_under_mama: Yup.string()
    .matches(/^[0]\d{9}$/, 'รูปแบบไม่ถูกต้อง')
});

const Container = styled.div`
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;
  font-size: 18px;

  .text-primary {
    color: #D90101;
  }

  .text-grey {
    color: #808080;
  }

  .text-green {
    color: #009222;
  }

  .text-header {
    white-space: nowrap;
    color: #808080;
  }

  .text-bold {
    font-weight: bold;
  }

  @media(min-width: 564px) {
    font-size: 20px;
  }

  & a, a:active, a:focus, a:visited, a:hover {
    color: #087DE3;
    text-decoration: none;
  }

  & button {
    height: 40px;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #E3E3E3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
  }

  & .react-autosuggest__suggestions-container {
    position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 3px 10px;
    background-color: #FFFFFF;
    cursor: pointer;

    &:hover {
      background-color: #E3E3E3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #E3E3E3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #E3E3E3;
  }
`;

const GetJobContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;

  font-size: 24px;
`;

const ImageProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;

    & .image {
        width: 86px;
        height: 86px;
        border: 1px solid #E3E3E3;
        overflow: hidden;
        border-radius: 4px;
        position: relative;
        box-sizing: content-box;
        cursor: pointer;

        & img {
            object-fit: cover;
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: -9999px;
            bottom: -9999px;
            left: -9999px;
            right: -9999px;
            margin: auto;
        }

        margin-right: 10px;
        margin-bottom: 10px;
    }
        
    .image-hover {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #00000030;
        border-radius: 4px;
        z-index: 999;
        width: 100%;
        height: 100%;
    }

    .image:hover {
        .image-hover {
            display: block;
        }
    }
`;

class AssignDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //url
      shipmentId: '',
      accountInfoId: '',

      isOpenModalCancel: false,
      isOpenModalCancelReason: false,
      cancelReasonInvalid: '',
      cancelReason: '',
      driverSuggestions: [], //ข้อมูลจากการ filter (เมื่อพิมพ์)
      driverListSuggest: [], //ข้อมูลจาก database
      truckSuggestions: [], //ข้อมูลจากการ filter (เมื่อพิมพ์)
      truckListSuggest: [], //ข้อมูลจาก database

      isChangeDriver: false, //สถานะการเปลี่ยน driver เพื่อสลับปุ่ม UI
      isOpenModalChangeDriver: false, //Modal ยืนยันการเปลี่ยน driver

      isAddedDriver: false,

      isShowAlert: false,
      titleAlert: 'ข้อความ',
      isShowAlertCloseJob: false,
      isFirst: true,

      //LIFF
      displayName : '', //get from api line
      userId : '', //get from api line
      lineDriverName: '',
      lineDriverPhoneCode: '',
      lineDriverPhoneNumber: '',
      lineTruckLicensePlate: '',
      isDisabledLineDriver: false, //กรณีเจอข้อมูล driver จาก line ที่ลงทะเบียนไว้
      LoadingLine: false,
      //photoProductIndexLightBox
      photoProductIndexLightBox: 0,
      isOpenPhotoProductLightBox: false,

      backhaulPrice: 0,      
    };
  }

  async componentWillMount() {
    const uriShipmentId = urijs(window.location.href).query(true).shipment;
    if(uriShipmentId) {
      this.setState({
        shipmentId: urijs(window.location.href).query(true).shipment
      });
    }

    const uriTR = urijs(window.location.href).query(true).tr;
    if(uriTR) {
      this.setState({
        accountInfoId: urijs(window.location.href).query(true).tr
      });
    }

    const uriPrice = urijs(window.location.href).query(true).price;
    if(uriPrice) {
      this.setState({
        backhaulPrice: urijs(window.location.href).query(true).price
      });
    }
  }

  initializeLIFF() {
    liff.init(async (data) => {
      let profile = await liff.getProfile();
      this.setState({
        displayName : profile.displayName,
        userId : profile.userId,
      }, () => {
        this.fetchLineDriver();
      });
    });
  }

  async componentDidMount() {
    try {
      this.setState({LoadingLine:true});
      await this.initializeLIFF();
      const { data } = await client.query({
        query: driverUserListQuery,
        variables: {
          account_info_id: this.state.accountInfoId,
        },
        fetchPolicy: "network-only",
      });
      
      this.setState({ driverListSuggest: data.driverUserList.driver_user.map(e => {
        return {
          name: e.driver_name,
          phoneNumber: '0' + e.driver_phone_number
        };
      }),
        truckListSuggest: data.userSettingTruckList.user_setting_truck.map(e => {
          return {
            name: e.user_setting_truck_license_plate,
          };
        })
      });
      this.setState({LoadingLine:false});
    } catch(error) {
      console.log(error);
      this.setState({LoadingLine:false});
    }
  }

  async fetchLineDriver() {
    try {
      const { data: lineDriverUserData } = await client.query({
        query: lineDriveUserQuery,
        variables: {
          account_info_id: this.state.accountInfoId,
          line_user_id: this.state.userId,
        },
        fetchPolicy: "network-only",
      });

      const { data: lineTruckData } = await client.query({
        query: lineTruckQuery,
        variables: {
          driver_user_id: lineDriverUserData.driverUserList.driver_user[0]._id,
        },

        fetchPolicy: "network-only",
      });

      const { driver_name, driver_phone_code, driver_phone_number } = lineDriverUserData.driverUserList.driver_user[0];
      if(lineDriverUserData.driverUserList.driver_user.length === 1 && lineTruckData.userSettingTruckList.user_setting_truck.length === 1) {
        this.setState({
          lineDriverName: driver_name,
          lineDriverPhoneCode: driver_phone_code,
          lineDriverPhoneNumber: 0 + driver_phone_number,
          lineTruckLicensePlate: lineTruckData.userSettingTruckList.user_setting_truck[0].user_setting_truck_license_plate,
          isDisabledLineDriver: true,
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  changeCancelReason(event) {
		this.setState({ cancelReason: { value: event.value, label: event.label }});
  }
  
  formatPhoneNumber(phoneNumber) {
    const re = /(^0\d{2})(\d{3})(\d{4})$/g;
    const newData = phoneNumber.replace(re, '$1-$2-$3');
    return newData;
  }

  formatNumber(number, mode) {
    if(mode === "nodot"){
      return (parseFloat(number)).toFixed(0).replace(/\d(?=(\d{3}))/g, '$&,');
    }

    return (parseFloat(number)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  //Autosuggest DriverList
  getSuggestions = value => {
    const inputValue = value.trim().toString().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 ? [] : this.state.driverListSuggest.filter(item =>
      item.name && _.includes(item.name.toString().toLowerCase(), inputValue)
    );
  };
  
  getSuggestionValue = suggestion => suggestion.name;
  
  renderSuggestion = suggestion => (
    <div>
      {suggestion.name}
    </div>
  );

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      driverSuggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      driverSuggestions: []
    });
  };

  shouldRenderSuggestions() {
    return true;
  }
  //End Autosuggest DriverList

  //Autosuggest TruckList
  getSuggestionsTruckList = value => {
    const inputValue = value.trim().toString().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 ? [] : this.state.truckListSuggest.filter(item =>
      item.name && _.includes(item.name.toString().toLowerCase(), inputValue)
    );
  }
  
  getSuggestionValueTruckList = suggestion => suggestion.name;

  renderVariablesShipmentsUpdate(values){
    if(this.state.backhaulPrice !== 0){
      return {
        shipment_id : this.state.shipmentId,
        transporter : this.state.accountInfoId,
        driver_name_under_mama : values.driver_name_under_mama,
        driver_tel_under_mama :  values.driver_tel_under_mama.replace(/\b0+/g,'',),
        backhaul : this.state.backhaulPrice
      };
    }
    return {
      shipment_id : this.state.shipmentId,
      transporter : this.state.accountInfoId,
      driver_name_under_mama : values.driver_name_under_mama,
      driver_tel_under_mama :  values.driver_tel_under_mama.replace(/\b0+/g,'',),
    };
  }
  
  renderSuggestionTruckList = suggestion => (
    <div>
      {suggestion.name}
    </div>
  );

  onSuggestionsFetchRequestedTruckList = ({ value }) => {
    this.setState({
      truckSuggestions: this.getSuggestionsTruckList(value)
    });
  };

  onSuggestionsClearRequestedTruckList = () => {
    this.setState({
      truckSuggestions: []
    });
  };

  shouldRenderSuggestionsTruckList() {
    return true;
  }
  //End Autosuggest TruckList

  async handleAssignDriver(values, refetchShipmentListQuery) {
    if(values.driverName !== "" && values.driverLicensePlate !== "" && values.driverPhoneNumber !== "0" ){
      try {
          //สำหรับตรวจสอบก่อน assignDriver
          const { data: shipmentListData } = await client.query({
            query: shipmentListQuery,
            variables: {
              _id: this.state.shipmentId
            },
            fetchPolicy: "network-only",
            context: token
          });

          const shipmentList = shipmentListData.shipmentList.shipment[0];
          if( //ป้องกันการ assignDriver ชนกันกรณีคนละ transporter
            !(shipmentList.transporter === null || shipmentList.transporter === this.state.accountInfoId) || shipmentList.transporter == "5d0083ebc88a512bb2f5f7a6"
          ) {
            this.setState({ isShowAlertCloseJob: true }, () => {
              refetchShipmentListQuery();
            });
            return 0;
          } else if( //ป้องกันการ assignDriver ชนกันกรณี transporter เดียวกัน เพิ่มลิงก์พร้อมกัน
            shipmentList.transporter === this.state.accountInfoId &&
            shipmentList.transporter !== null &&
            shipmentList.driver_user_id !== null &&
            this.state.isChangeDriver === false
          ) {
            this.setState({ isOpenModalChangeDriver: true }, () => {
              refetchShipmentListQuery();
            });
            return 0;
          }

          //สำหรับ assignDriver
          const { data, loading } = await client.query({
            query: driverUserListQuery,
            variables: {
              account_info_id: this.state.accountInfoId,
            },
            fetchPolicy: "network-only",
            context: token
          });

          if(data && !loading) {
            //ตรวจสอบว่ามี driver อยู่แล้วหรือไม่
            const checkDriverName = data.driverUserList.driver_user.filter(e => e.driver_name === values.driverName); 
            //ตรวจสอบว่ามีทะเบียนรถแล้วหรือไม่
            const checkTruck = data.userSettingTruckList.user_setting_truck.filter(e => e.user_setting_truck_license_plate === values.driverLicensePlate); 
            //ถ้ามีอยู่แล้วให้อัพเดทข้อมูล Driver และ assign driver

            let driverID = null;
            if(checkDriverName.length) {
              console.log('มีคนขับอยู่แล้ว');
              driverID = checkDriverName[0]._id;
              await this.props.driverUserUpdateMutation({
                variables: {
                  _id: checkDriverName[0]._id,
                  driver_name: checkDriverName[0].driver_name,
                  driver_phone_code: values.driverPhoneCode,
                  driver_phone_number: values.driverPhoneNumber.replace(/\b0+/g,'',),
                  driver_enable: checkDriverName[0].driver_enable,
                },
                context: token,
              }).then(({ data }) => {
                if(data.driverUserUpdate._id) {
                  console.log('อัพเดทข้อมูลคนขับสำเร็จ', data);
                  this.fetchDriverUserList();
                }
              }).then()
                .catch(error => {
                console.log('อัพเดทข้อมูลคนขับผิดพลาด', error);
              });
            } else { //ถ้าไม่มีให้เพิ่ม Driver และ assign driver
              console.log('เพิ่ม Driver');
              await this.props.driverUserAddMutation({
                variables: {
                  account_info_id: this.state.accountInfoId,
                  driver_name: values.driverName,
                  driver_phone_code: values.driverPhoneCode,
                  driver_phone_number: values.driverPhoneNumber.replace(/\b0+/g,'',),
                  driver_enable: 1,
                },
                context: token,
              }).then(({ data }) => {
                if(data.driverUserAdd._id) {
                  console.log('เพิ่มคนขับสำเร็จ', data);
                  driverID = data.driverUserAdd._id;
                  this.fetchDriverUserList();
                }
              }).catch(error => {
                console.log('เพิ่มคนขับผิดพลาด', error);
                return 0;
              });
            }

            let truckID = null;
            if(checkTruck.length) {
              console.log('มีทะเบียนอยู่แล้ว');
              truckID = checkTruck[0]._id;
            } else {
              await this.props.userSettingTruckAddMutation({
                variables: {
                  user_setting_truck_license_plate: values.driverLicensePlate,
                },
                context: token,
              }).then(({ data }) => {
                if(data.userSettingTruckAdd._id) {
                  console.log('เพิ่มข้อมูลทะเบียนสำเร็จ', data);
                  truckID = data.userSettingTruckAdd._id;
                  this.fetchDriverUserList();
                }
              }).catch((error,variables) => {
                console.log('เพิ่มข้อมูลทะเบียนผิดพลาด', error ,variables);
                return 0;
              });
            }

            if(!this.state.isChangeDriver){
              console.log("lineAssignDriverMutation");
              await this.props.lineAssignDriverMutation({
                variables: {
                  shipment_id: this.state.shipmentId,
                  driver_user_id: driverID,
                  user_setting_truck_id: truckID
                },
                context: token
              }).then(({ data }) => {
                if(data.lineAssignDriver.succeed) {
                  console.log('อัพเดท shipment สำเร็จ', data);
                  this.props.shipmentsUpdateMutation({
                    variables: this.renderVariablesShipmentsUpdate(values),
                    context: token,
                    refetchQueries: () => ['ShipmentDetailLineDriverAssignDriver']
                  }).then(({ data }) => {
                    if(data.shipmentsUpdate._id) {
                      console.log('อัพเดท shipmentUpdate สำเร็จ');
                    }
                  }).then(() => {
                        this.setState({ isChangeDriver: false, isShowAlert: true , isAddedDriver:true });
                    setTimeout(function(){ 
                        refetchShipmentListQuery();}
                    , 3000);
                  })
                  .catch(error => {
                    console.log(error);
                    return 0;
                  })
                }
              }).catch(error => {
                console.log('อัพเดท shipment ผิดพลาด', error);
              });
            }
            else{
              console.log("assignDriverMutation");
              await this.props.assignDriverMutation({
                variables: {
                  shipment_id: this.state.shipmentId,
                  driver_user_id: driverID,
                  user_setting_truck_id: truckID
                },
                context: token
              }).then(({ data }) => {
                if(data.assignDriver.succeed) {
                  console.log('อัพเดท shipment สำเร็จ', data);
                  this.props.shipmentsUpdateMutation({
                    variables: this.renderVariablesShipmentsUpdate(values),
                    context: token,
                    refetchQueries: () => ['ShipmentDetailLineDriverAssignDriver']
                  }).then(({ data }) => {
                    if(data.shipmentsUpdate._id) {
                      console.log('อัพเดท shipmentUpdate สำเร็จ');
                    }
                  }).then(() => {
                        this.setState({ isChangeDriver: false, isShowAlert: true , isAddedDriver:true });
                    setTimeout(function(){ 
                        refetchShipmentListQuery();}
                    , 3000);
                  })
                  .catch(error => {
                    console.log(error);
                    return 0;
                  })
                }
              }).catch(error => {
                console.log('อัพเดท shipment ผิดพลาด', error);
              });
            }
          }
        } catch(error) { //error query driver list
          alert(error);
        }
    }
    else{
      this.setState({isShowAlertRequire:true});
    }
  }

  async fetchDriverUserList() {
    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        variables: {
          account_info_id: this.state.accountInfoId
        },
        fetchPolicy: "network-only",
      });
      
      this.setState({
        driverListSuggest: data.driverUserList.driver_user.map(e => {
          return {
            name: e.driver_name,
            phoneNumber: '0' + e.driver_phone_number
          };
        }),
        truckListSuggest: data.userSettingTruckList.user_setting_truck.map(e => {
          return {
            name: e.user_setting_truck_license_plate,
          };
        })
      });
    } catch(error) {
      console.log(error);
    }
  }

  checkJob = () => {
    if(this.state.isFirst) {
      this.setState({ isShowAlertCloseJob: true, isFirst: false });
    }
  }

  renderAdditional(item){
    let text = "";
    
		for (let i = 0; i < item.length; i++) {
			if(item[i].list_type === 2){
        text += `${item[i].list_name}`;
        
        if(item[i].list_name === 'แรงงานยกสินค้า') {
          text += ` x ${item[i].list_qty}`;
        }

				if(i < item.length - 1){
					text += ", "
				}
			}
    }
    
    if(text === '') {
      text = 'ไม่มี';
    }
    
		return text;
  }
  
  async handleAcceptShipmentDriver(refetchShipmentListQuery) {
    try {
      //สำหรับตรวจสอบก่อน assignDriver
      const { data: shipmentListData } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: this.state.shipmentId
        },
        fetchPolicy: "network-only",
        context: token
      });

      const shipmentList = shipmentListData.shipmentList.shipment[0];
      if( //ป้องกันการ assignDriver ชนกันกรณีคนละ transporter
        !(shipmentList.transporter === null || shipmentList.transporter === this.state.accountInfoId) || shipmentList.transporter == "5d0083ebc88a512bb2f5f7a6"
      ) {
        this.setState({ isShowAlertCloseJob: true }, () => {
          refetchShipmentListQuery();
        });
        return 0;
      } else if( //ป้องกันการ assignDriver ชนกันกรณี transporter เดียวกัน เพิ่มลิงก์พร้อมกัน
        shipmentList.transporter === this.state.accountInfoId &&
        shipmentList.transporter !== null &&
        shipmentList.driver_user_id !== null &&
        this.state.isChangeDriver === false
      ) {
        this.setState({ isOpenModalChangeDriver: true }, () => {
          refetchShipmentListQuery();
        });
        return 0;
      }

        console.log("acceptShipmentDriverMutation");
        await this.props.acceptShipmentDriverMutation({
          variables: {
            shipment_id: this.state.shipmentId,
            transporter: this.state.accountInfoId
          },
          context: token
        }).then(({ data }) => {
          if(data.acceptShipmentDriver.succeed) {
            console.log('อัพเดท shipment สำเร็จ', data);
            this.props.shipmentsUpdateMutation({
              variables: {
                shipment_id: this.state.shipmentId,
                transporter: this.state.accountInfoId
              },
              context: token,
              refetchQueries: () => ['ShipmentDetailLineDriverAssignDriver']
            }).then(({ data }) => {
              if(data.shipmentsUpdate._id) {
                console.log('อัพเดท shipmentUpdate สำเร็จ');
              }
            }).then(() => {
              this.setState({ isChangeDriver: false, isShowAlert: true , isAddedDriver:true });
              setTimeout(function(){ 
                  refetchShipmentListQuery();}
              , 3000);
            })
            .catch(error => {
              console.log(error);
              return 0;
            })
          }
        }).catch(error => {
          console.log('อัพเดท shipment ผิดพลาด', error);
        });
      }
      catch(error) { //error query driver list
        alert(error);
      }
  }

  render() {
    return(
      <Container>
        <Helmet>
          <title>BOOKING ใหม่ | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
          <meta name="description" content="สามารถเช็คราคาและจองบริการขนส่ง ทั้งแอปพลิเคชันบนมือถือ แทบเล็ต ให้ธุรกิจคุณสะดวกขึ้น" />
        </Helmet>
        
        <Query
          query={shipmentListQuery}
          variables={{_id:this.state.shipmentId}}
          onCompleted={(data) => {
            const status = data.shipmentList.shipment[0].shipment_status;
            if(status === 4 || status === 5 || status === 6) {
              this.checkJob();
            }
          }} 
          context={token}
        >
          {
            ({ loading, error, data, refetch }) => {
              if(loading || this.state.LoadingLine) return (
                <Card>
                  loading...
                </Card>
              );

              if(error) return (
                <Card>
                  { error.graphQLErrors[0].message }
                </Card>
              );

              if(data.shipmentList.shipment.length === 0 || data.shipmentList.shipment[0]._id !== this.state.shipmentId) {
                return (<Card>ไม่พบข้อมูล</Card>)
              }

              const shipmentList = data.shipmentList.shipment[0];
              const shipmentCostList = data.shipmentCostList;
              const trackingList = data.trackingList.tracking[0];
              const invoiceList = data.invoiceList[0];

              const documents = shipmentList.shipment_other_detail.document.map(item => {
                const patternFileType = /\.[0-9a-z]{1,5}$/;
                const patternCheckFileType = /^.*\.(pdf|PDF)$/;
                const thumbnail = patternCheckFileType.test(item.path) ? `${require('src/assets/images/file-type-pdf.png')}` : `${s3}/${item.path}`;
                const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

                return {
                  path: `${s3}/${item.path}`, //path ไฟล์
                  fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
                  type: type, //ประเภทไฟล์
                  thumbnail: thumbnail, //รูปตัวอย่าง
                }
              });

              const documentsImage = documents.map(item => {
                const patternCheckFileType = /^.*\.(pdf|PDF)$/;
                if(patternCheckFileType.test(item.path)) {
                  return (<PDFPreview file={item.path} />);
                }

                return item.thumbnail;
              });

              return (
                <div>
                  <Helmet>
                    <title>BOOKING ใหม่ หมายเลข : { shipmentList.shipment_number }</title>
                    <meta name="description" content="สามารถเช็คราคาและจองบริการขนส่ง ทั้งแอปพลิเคชันบนมือถือ แทบเล็ต ให้ธุรกิจคุณสะดวกขึ้น" />
                  </Helmet>

                  {
                    shipmentList.shipment_status !== 3 &&
                    (shipmentList.transporter === null ||
                    shipmentList.transporter === this.state.accountInfoId) ? (
                      <div>
                        {
                          this.state.isShowAlert && shipmentList.transporter == "5d0083ebc88a512bb2f5f7a6" && (
                            <div onClick={() => this.setState({ isShowAlert: false })}>
                              <MessageAlert text={this.state.titleAlert} time={(<Moment format="DD/MM/YYYY เวลา HH:mm น.">{ new Date() }</Moment>)} />
                              <BlankSpace/>
                            </div>
                          )
                        }

                        {
                          this.state.isShowAlertCloseJob && (
                            <div onClick={() => this.setState({ isShowAlertCloseJob: false })}>
                              <MessageAlert text="ปิดงานแล้ว คุณมาช้าไป" bgColor="#B3B3B3" icon="fas fa-times-circle" time={(<Moment format="DD/MM/YYYY เวลา HH:mm น.">{ new Date() }</Moment>)} />
                              <BlankSpace/>
                            </div>
                          )
                        }

                        {
                          this.state.isShowAlertRequire && (
                            <div onClick={() => this.setState({ isShowAlertRequire: false })}>
                              <MessageAlert text="กรุณากรอกข้อมูลให้ครบถ้วน" bgColor="#B3B3B3" icon="fas fa-times-circle" time="" />
                              <BlankSpace/>
                            </div>
                          )
                        }

                        {
                           shipmentList.transporter != null && shipmentList.transporter != "5d0083ebc88a512bb2f5f7a6"  && shipmentList.transporter == this.state.accountInfoId && 
                          (
                            <div onClick={() => this.setState({ isShowAlertCloseJob: false })}>
                              <MessageAlert text="คุณได้รับงานนี้แล้ว" time={(<Moment format="DD/MM/YYYY เวลา HH:mm น.">{ new Date() }</Moment>)} />
                              <BlankSpace/>
                            </div>
                          )
                        }
                        
                        <Card>
                          {/* <Row>
                            <Col xs={4}><span className="text-header">LINE ID :</span></Col>
                            <Col xs={8}><span className="text-bold">{ this.state.userId || '-' }</span></Col>
                          </Row> */}
                          <Row>
                            <Col xs={4}><span className="text-header">ชื่อผู้จอง :</span></Col>
                            <Col xs={8}><span className="text-bold">{ shipmentList.shipper_detail }</span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">วันที่ใช้บริการ :</span></Col>
                            <Col xs={8}><span className="text-primary text-bold text-primary"><Moment format="DD/MM/YYYY เวลา HH:mm น.">{ shipmentList.pick_up_date }</Moment></span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">ประเภทรถ :</span></Col>
                            <Col xs={8}><span className="text-bold text-primary">รถ {shipmentList.setting_truck_name.th} {shipmentList.truck_type_name.th}</span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">บริการเสริม :</span></Col>
                            <Col xs={8}><span className="text-bold text-primary">{ this.renderAdditional(invoiceList.invoice_list) }</span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">ช่องทางชำระเงิน :</span></Col>
                            <Col xs={8}><span className="text-bold">
                              { shipmentList.paid_type === 1 ? "บัตรเครดิต" : null }
                              { shipmentList.paid_type === 2 ? shipmentList.paid_status ? "ชำระเงินแล้ว" : "ยังไม่ได้ชำระเงิน" : null }
                              { shipmentList.paid_type === 3 ? "วางบิล" : null }
                            </span></Col>
                          </Row>
                        </Card>

                        <BlankSpace/>

                        <Card>
                          <DropPoint address={shipmentList.shipment_address} pickup={shipmentList.shipment_address[0].address} delivery={shipmentList.shipment_address[1].address} hrefPickup={`https://maps.google.com?q=${shipmentList.shipment_address[0].lat},${shipmentList.shipment_address[0].lng}`} hrefDelivery={`https://maps.google.com?q=${shipmentList.shipment_address[1].lat},${shipmentList.shipment_address[1].lng}`} />
                        </Card>
                        
                        { shipmentList.shipment_other_detail.remark || shipmentList.shipment_other_detail.user_ref || documents.length ? (
                        <BlankSpace/>
                        ) : null }

                        { shipmentList.shipment_other_detail.remark || shipmentList.shipment_other_detail.user_ref || documents.length ? (
                        <Card>
                          {documents.length > 0 && (
                            <Row>
                              <Col xs={12}><span className="text-header">รูปภาพสินค้า/เอกสาร :</span></Col>
                              <Col xs={12}>
                                <ImageProductContainer>
                                      {
                                          documents.map((item, index) => (
                                              <div
                                                  className="image"
                                                  onClick={() => this.setState({
                                                      photoProductIndexLightBox: index,
                                                      isOpenPhotoProductLightBox: true,
                                                  })}
                                              >
                                                  <div className="image-hover"></div>
                                                  <img src={`${item.thumbnail}`} alt="productImage" />
                                              </div>
                                          ))
                                      }
                                  </ImageProductContainer>

                                  {
                                      this.state.isOpenPhotoProductLightBox && (
                                          <Lightbox
                                              mainSrc={documentsImage[this.state.photoProductIndexLightBox]}
                                              nextSrc={documentsImage.length > 1 ? documentsImage[(this.state.photoProductIndexLightBox + 1) % documentsImage.length] : undefined}
                                              prevSrc={documentsImage.length > 1 ? documentsImage[(this.state.photoProductIndexLightBox + documentsImage.length - 1) % documentsImage.length] : undefined}
                                              onCloseRequest={() => this.setState({ isOpenPhotoProductLightBox: false })}
                                              onMovePrevRequest={() =>
                                                  this.setState(prev => ({
                                                      photoProductIndexLightBox: (prev.photoProductIndexLightBox + documentsImage.length - 1) % documentsImage.length,
                                                  }))
                                              }
                                              onMoveNextRequest={() =>
                                                  this.setState(prev => ({
                                                      photoProductIndexLightBox: (prev.photoProductIndexLightBox + 1) % documentsImage.length,
                                                  }))
                                              }
                                          />
                                      )
                                  }
                              </Col>
                            </Row>
                          )}
                          {shipmentList.shipment_other_detail.user_ref && (
                            <Row>
                              <Col xs={12}><span className="text-header">รหัสอ้างอิงงานของคุณ :</span></Col>
                              <Col xs={12}><span className="text-bold">{ shipmentList.shipment_other_detail.user_ref }</span></Col>
                            </Row>
                          )}
                          {shipmentList.shipment_other_detail.remark && (
                            <Row>
                              <Col xs={12}><span className="text-header">หมายเหตุ/รายละเอียดสินค้า :</span></Col>
                              <Col xs={12}><span className="text-bold">{ shipmentList.shipment_other_detail.remark }</span></Col>
                            </Row>
                          )}
                          
                        </Card>) : null }

                        <BlankSpace/>

                        <Card>
                          <Row>
                            <Col xs={4}><span className="text-header">ต้นทาง :</span></Col>
                            <Col xs={8}><span className="text-bold">{ shipmentList.shipment_address[0].subdictrict }, { shipmentList.shipment_address[0].province }</span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">ปลายทาง :</span></Col>
                            <Col xs={8}><span className="text-bold">{ shipmentList.shipment_address[1].subdictrict }, { shipmentList.shipment_address[1].province }</span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">รวมระยะทาง :</span></Col>
                            <Col xs={8}><span className="text-bold">{ shipmentList.distance ? this.formatNumber(shipmentList.distance,"nodot") : 0 } กิโลเมตร</span></Col>
                          </Row>
                          <Row>
                            <Col xs={4}><span className="text-header">ราคา :</span></Col>
                            <Col xs={8}><span className="text-bold">{ this.state.backhaulPrice ? this.formatNumber(this.state.backhaulPrice) :  this.formatNumber(shipmentCostList.shipment_cost + shipmentCostList.additional_cost) } บาท</span></Col>
                          </Row>
                        </Card>
                        {
                          console.log(trackingList)
                        }
                        {
                          //this.state.accountInfoId == "5d0083ebc88a512bb2f5f7a6" &&
                          ((shipmentList.driver_user_id && !this.state.isChangeDriver ) || (this.state.isAddedDriver &&  this.state.accountInfoId == "5d0083ebc88a512bb2f5f7a6") ) && (
                            <div>
                              <BlankSpace/>
                              <Card>
                                <Row>
                                  <Col xs={4}><span className="text-header">ชื่อคนขับรถ :</span></Col>
                                  <Col xs={8}><span className="text-bold">{ trackingList.driver_name}</span></Col>
                                </Row>
                                <Row>
                                  <Col xs={4}><span className="text-header">เบอร์โทรศัพท์ :</span></Col>
                                  <Col xs={8}><span className="text-bold">{ this.formatPhoneNumber(`0${trackingList.driver_phone_number}`) }</span> <IconButton bgColor="#D90101" icon="fas fa-phone" href={`tel:+${trackingList.driver_phone_code}${trackingList.driver_phone_number}`} style={{marginLeft: 5}} /></Col>
                                </Row>
                                <Row>
                                  <Col xs={4}><span className="text-header">ทะเบียนรถ :</span></Col>
                                  <Col xs={8}><span className="text-bold">{ trackingList.user_setting_truck_license_plate }</span></Col>
                                </Row>
                            </Card>
                                
                            </div>
                          )
                        }

                        <Modal title="แจ้งเตือน" show={this.state.isOpenModalChangeDriver} onHide={() => this.setState({ isOpenModalChangeDriver: false })}>
                          <div>คุณต้องการเปลี่ยนคนขับรถหรือไม่?</div>
                          <div style={{marginTop: 10}}>
                            <Row>
                              <Col xs={6}><Button block onClick={() => this.setState({ isOpenModalChangeDriver: false })}>ยกเลิก</Button></Col>
                              <Col xs={6}><Button bsStyle="primary" block onClick={() => this.setState({ isChangeDriver: true, isOpenModalChangeDriver: false })}>ยืนยัน</Button></Col>
                            </Row>
                          </div>
                        </Modal>
              
                        {
                          (shipmentList.shipment_status === 1 || shipmentList.shipment_status === 2) &&
                          (this.state.accountInfoId === "5d0083ebc88a512bb2f5f7a6" && (shipmentList.transporter === null || shipmentList.transporter === this.state.accountInfoId)) ?
                          (
                            <Formik
                              initialValues={{
                                driverName: this.state.lineDriverName,
                                driverPhoneCode: '66',
                                driverPhoneNumber:  this.state.lineDriverPhoneNumber,
                                driverLicensePlate: this.state.lineTruckLicensePlate,
                                driver_name_under_mama:'',
                                driver_tel_under_mama:''
                              }}
                              validationSchema={driverValidate}
                              enableReinitialize={true}
                              onSubmit={(values) => {
                                this.setState({ titleAlert: 'คุณได้รับงานนี้แล้ว'}, () => this.handleAssignDriver(values, refetch));
                              }}
                            >
                              {
                                (props) => {
                                  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = props;
                                  return (
                                    <div>
                                      { (!shipmentList.driver_user_id || this.state.isChangeDriver ) && !this.state.isAddedDriver  ? (
                                          <div>
                                            <BlankSpace/>
                                            <Card>
                                              <Row>
                                                <Col xs={12}>
                                                  <Autosuggest
                                                    suggestions={this.state.driverSuggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    focusInputOnSuggestionClick={false}
                                                    inputProps={{
                                                      placeholder: errors.driverName && touched.driverName ? errors.driverName : 'ระบุ',
                                                      value: values.driverName,
                                                      onChange : e => {
                                                        handleChange(e)
                                                        props.setFieldValue('driver_name_under_mama', e.target.value)
                                                      },
                                                      error: errors.driverName && touched.driverName,
                                                      inputLabel: `ชื่อคนขับรถ`,
                                                      required: true,
                                                      name: "driverName",
                                                      type: "text",
                                                      onBlur: () => props.setFieldTouched('driverName', true),
                                                      disabled: this.state.isDisabledLineDriver,
                                                    }}
                                                    renderInputComponent={Input}
                                                    onSuggestionSelected={(event, { suggestion }) => {
                                                      values.driverName = suggestion.name;
                                                      values.driverPhoneNumber = suggestion.phoneNumber;
                                                      values.driver_name_under_mama = suggestion.name;
                                                      values.driver_tel_under_mama = suggestion.phoneNumber;
                                                      props.setValues(values);
                                                    }}
                                                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                                                  />
                                                </Col>

                                                <Col xs={12}>
                                                  <Input
                                                    value={values.driverPhoneNumber}
                                                    onChange={e => {
                                                      handleChange(e)
                                                      props.setFieldValue('driver_tel_under_mama', e.target.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    style={{marginTop: 10}}
                                                    placeholder={errors.driverPhoneNumber && touched.driverPhoneNumber ? errors.driverPhoneNumber : '0812345678'}
                                                    inputLabel="เบอร์โทรศัพท์"
                                                    required
                                                    name="driverPhoneNumber"
                                                    type="text"
                                                    error={errors.driverPhoneNumber && touched.driverPhoneNumber}
                                                    disabled={this.state.isDisabledLineDriver}
                                                  />
                                                  <div style={{textAlign: 'right', color: '#D90101', lineHeight: 1}}>
                                                    { errors.driverPhoneNumber && touched.driverPhoneNumber && values.driverPhoneNumber ? errors.driverPhoneNumber : '' }
                                                  </div>
                                                </Col>
                                              
                                                <Col xs={12}>
                                                  <Autosuggest
                                                    suggestions={this.state.truckSuggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTruckList}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedTruckList}
                                                    getSuggestionValue={this.getSuggestionValueTruckList}
                                                    renderSuggestion={this.renderSuggestionTruckList}
                                                    focusInputOnSuggestionClick={false}
                                                    inputProps={{
                                                      placeholder: errors.driverLicensePlate && touched.driverLicensePlate ? errors.driverLicensePlate : 'ระบุ',
                                                      value: values.driverLicensePlate,
                                                      onChange: handleChange,
                                                      error: errors.driverLicensePlate && touched.driverLicensePlate,
                                                      inputLabel: "ทะเบียนรถ",
                                                      required: true,
                                                      name: "driverLicensePlate",
                                                      type: "text",
                                                      onBlur: () => props.setFieldTouched('driverLicensePlate', true),
                                                      style: {marginTop: 10},
                                                      disabled: this.state.isDisabledLineDriver,
                                                    }}
                                                    renderInputComponent={Input}
                                                    onSuggestionSelected={(event, { suggestion }) => {
                                                      values.driverLicensePlate = suggestion.name.toString();
                                                      props.setValues(values);
                                                    }}
                                                    shouldRenderSuggestions={this.shouldRenderSuggestionsTruckList}
                                                  />
                                                </Col>
                                              </Row>
                                              {
                                                this.state.accountInfoId != "5d0083ebc88a512bb2f5f7a6" && (
                                                  <div>
                                                    <hr style={{marginBottom:"5px"}} />
                                                    <Row>
                                                      <Col xs={12}>
                                                        <Input
                                                          value={values.driver_name_under_mama}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          style={{marginTop: 10}}
                                                          placeholder={'ชื่อคนขับรถ'}
                                                          inputLabel="ชื่อคนขับรถ"
                                                          name="driver_name_under_mama"
                                                          type="text"
                                                        />
                                                      </Col>
                                                      <Col xs={12}>
                                                        <Input
                                                          value={values.driver_tel_under_mama}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          style={{marginTop: 10}}
                                                          placeholder={'0812345678'}
                                                          inputLabel="เบอร์โทรศัพท์"
                                                          name="driver_tel_under_mama"
                                                          type="text"
                                                          error={errors.driver_tel_under_mama && touched.driver_tel_under_mama}
                                                        />
                                                        <div style={{textAlign: 'right', color: '#D90101', lineHeight: 1}}>
                                                          { errors.driver_tel_under_mama && touched.driver_tel_under_mama && values.driver_tel_under_mama ? errors.driver_tel_under_mama : '' }
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )
                                              }
                                              
                                            </Card>
                                          </div>
                                        ) : null
                                      }
                                      
                                      <Card>
                                        <Row>
                                          <Col xs={12}>
                                            <div style={{marginTop: 20}}>
                                              {
                                                !shipmentList.driver_user_id  && !this.state.isAddedDriver &&  (
                                                  <Button bsStyle="primary" block onClick={() => handleSubmit()}>รับงาน</Button>
                                                )
                                              }

                                              {
                                                shipmentList.driver_user_id && !this.state.isChangeDriver && this.state.accountInfoId != "5d0083ebc88a512bb2f5f7a6" && (
                                                  <Row>
                                                    <Col xs={12}><Button block onClick={() => this.setState({ isOpenModalChangeDriver: true }, () => {
                                                      values.driverName = trackingList.driver_name;
                                                      values.driverLicensePlate = trackingList.user_setting_truck_license_plate ? trackingList.user_setting_truck_license_plate : "";
                                                      values.driverPhoneNumber = '0' + trackingList.driver_phone_number;
                                                      values.driver_name_under_mama = shipmentList.driver_name_under_mama ? shipmentList.driver_name_under_mama : "";
                                                      values.driver_tel_under_mama = shipmentList.driver_tel_under_mama ? '0' + shipmentList.driver_tel_under_mama : "";
                                                      values.driverPhoneCode = '66';
                                                      props.setValues(values);
                                                    })}>เปลี่ยนคนขับ</Button></Col>
                                                  </Row>
                                                )
                                              }

                                              {
                                                shipmentList.driver_user_id && this.state.isChangeDriver && (
                                                  <Row>
                                                    <Col xs={6}><Button block onClick={() => this.setState({ isChangeDriver: false })}>ยกเลิก</Button></Col>
                                                    <Col xs={6}>
                                                      <Button disabled={!props.isValid} bsStyle="primary" block onClick={() => {
                                                        this.setState({ titleAlert: 'คุณได้รับงานนี้แล้ว' }, () => this.handleAssignDriver(values, refetch))
                                                      }}>บันทึกการเปลี่ยนแปลง</Button>
                                                    </Col>
                                                  </Row>
                                                )
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card>
                                    </div>
                                  )
                                }
                              }
                            </Formik>
                          ) : (
                            <div>
                              <Col xs={12}>
                              {
                                  !shipmentList.driver_user_id  && !this.state.isAddedDriver && shipmentList.transporter === null &&  (
                                    <Button bsStyle="primary" block onClick={() => this.handleAcceptShipmentDriver(refetch)}>รับงาน</Button>
                                  )
                                }
                              </Col>
                            </div>
                          )
                        }
                      </div>
                    ) : (
                      <GetJobContainer>
                        <div>BOOKING หมายเลข : { shipmentList.shipment_tracking_number}</div>
                        <div className="text-primary text-bold">มีผู้รับงานนี้แล้ว</div>
                      </GetJobContainer>
                    )
                  }
                </div>
              );
            }
          }
        </Query>
      </Container>
    )
  }
}

export default compose(graphql(
  driverUserAddMutation, {
    name: "driverUserAddMutation"
  }
), graphql(
  driverUserUpdateMutation,
  {
    name: "driverUserUpdateMutation"
  }
), graphql(
  userSettingTruckUpdateMutation, {
    name: "userSettingTruckUpdateMutation"
  }
), graphql(
  userSettingTruckAddMutation, {
    name: "userSettingTruckAddMutation"
  }
), graphql(
  assignDriverMutation, {
    name: "assignDriverMutation"
  }
), graphql(
  lineAssignDriverMutation, {
    name: "lineAssignDriverMutation"
  }
), graphql(
  shipmentsUpdateMutation, {
    name: "shipmentsUpdateMutation"
  }
), graphql(
  acceptShipmentDriverMutation, {
    name: "acceptShipmentDriverMutation"
  }
)
)(AssignDriver);

