import React from 'react';
import {
	Row,
	Col,
	FormGroup,
	ControlLabel,
	FormControl,
	Button,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from 'src/config/color';
import { compose, graphql, Query, Mutation } from 'react-apollo';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import mapSearchQuery from './graphql/query/mapSearch';
import textMapSearchQuery from './graphql/query/textMapSearch';
import shipmentAddressHistoryListQuery from './graphql/query/shipmentAddressHistoryList';
import { geolocated } from 'react-geolocated';
import getLatLngMutation from './graphql/mutation/getLatLng';
import updateLocationTempMutationClient from 'src/config/graphqlClient/mutation/updateLocationTemp';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import getNameFromlatlngQuery from './graphql/query/getNameFromlatlng';
import checkCountryQuery from './graphql/query/checkCountry';
import updateInstancePriceMutationClient from 'src/config/graphqlClient/mutation/updateInstancePrice';
import shipmentAddressHistoryAddMutation from './graphql/mutation/shipmentAddressHistoryAdd';
import shipmentAddressHistoryUpdateDelectMutation from './graphql/mutation/shipmentAddressHistoryUpdateDelect';
import accountInfoListQuery from './graphql/query/accountInfoList';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import settingPortListQuery from './graphql/query/settingPortList';
import settingContainerListQuery from './graphql/query/settingContainerList';
import containerPortFavoriteAddMutation from './graphql/mutation/containerPortFavoriteAdd';
import containerPortFavoriteDeleteMutation from './graphql/mutation/containerPortFavoriteDelete';

import { BlankSpace, ButtonLink, Input, Skeleton } from 'src/components';

import { ButtonBack, Header } from './../../components';
import { LocationList, RouteSearch, ButtonIcon, ContactList } from './components';
import client from '../../../../config/client';
import modeToText from './../../function/modeToText';
import _ from 'lodash';
import { withNamespaces } from "react-i18next";
import { Tabs as TabsAntd } from 'antd';
import accountContactListQuery from './graphql/query/accountContactList';
import { InstancePriceContext } from '../../contexts/InstancePriceContext';

const Container = styled.div`
	height: 100%;
`;

const ButtonBackStyle = styled(ButtonBack)`
	margin-top: 20px;
`;

const HeaderStyle = styled(Header)`
	margin-top: 10px;
	margin-bottom: 10px;
`;

const Card = styled.div`
	margin-bottom: 16px;
`;

const RouteSearchStyle = styled(RouteSearch)`
	margin-top: 16px;
	margin-bottom: 10px;
`;

const ButtonSearchContainer = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	& > div {
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		&:not(:last-child) {
			border-right: 1px solid #E3E3E3;
			margin-right: 10px;
		}
	}
`;

const MoreSearchContainer = styled.div`
	text-align: center;
	margin-top: 20px;

	& .text-label {
		font-size: 18px;
	}
`;

const TabsContainer = styled.div`
	.ant-tabs-nav {
		display: flex !important;
		width: 100% !important;
	}

	.ant-tabs-nav > div {
		display: flex;
		flex: 1;
	}

	.ant-tabs-nav .ant-tabs-tab {
		flex: 1;
		text-align: center;
	}

	.ant-tabs-nav .ant-tabs-tab-active {
		color: #000000;
		font-weight: bold;
		font-size: 20px;
	}

	.ant-tabs-nav .ant-tabs-tab:hover {
		color: #000000;
	}

	.ant-tabs-ink-bar {
		background-color: #d90101;
	}
`;

class SearchLocation extends React.Component {
	constructor(props, context) {
		super(props, context);

		this._inputSearch = React.createRef();
		this._searchLocationDelay = null;

		this.state = {
			focus: false,
			numAddress: 0,
			totalAddress: 0,
			showTitle: true,
			showTextSearch: false,
			searchLocation: '',
			searchLocationDelay: '',
			isLoading: false,

			//นำเข้า และส่งออกสินค้า
			settingPortList: null, //ท่าเรือ
			settingContainerList: null, //ตู้ container

			shipmentAddress: [],
			shipmentAddressFavorite: [],
			isLoadingDelay: false,

			selectedTabs: '1',
		};
	}

	static contextType = InstancePriceContext;

	componentDidMount() {
		this._inputSearch.current.focus();
		this.setState({ isLoading: true });
		
		setTimeout(() => {
			const { type } = this.props.locationTempQuery.locationTemp;
			//หาสถานที่ปกติแสดงประวัติการจอง
			if(
				type === 'pickup' ||
				type === 'delivery' ||
				type === 'exportLoading' ||
				type === 'importDelivery'
			) {
				this.fetchShipmentAddressHistoryList();

			//ท่าเรือ
			} else if(type === 'importPickup' || type === 'exportReturn') {
				this.fetchSettingPortList();

			//ตู้ container
			} else if(type === 'importReturn' || type === 'exportPickup') {
				this.fetchSettingContainerList();
			}
		}, 500); //ใส่เวลาไว้ เพราะแก้ปัญหารับค่า locationTemp.type จาก client ไม่ทัน
	}

	async fetchSettingContainerList() {
		try {
			const { data } = await client.query({
				query: settingContainerListQuery,				
        fetchPolicy: "network-only",
			});

			this.setState({
				shipmentAddress: data.settingContainerList.settingContainer,
				shipmentAddressFavorite: data.settingContainerList.settingContainerFavorite,
				isLoading: false,
			});
		} catch(error) {
      this.setState({ isLoading: false });
    }
	}

	async fetchSettingPortList() {
		try {
			const { data } = await client.query({
				query: settingPortListQuery,
        fetchPolicy: "network-only",
			});

			this.setState({
				shipmentAddress: data.settingPortList.settingPort,
				shipmentAddressFavorite: data.settingPortList.settingPortFavorite,
				isLoading: false,
			});
		} catch(error) {
      this.setState({ isLoading: false });
    }
	}

	async fetchShipmentAddressHistoryList() {
		try {
			const { data } = await client.query({
				query: shipmentAddressHistoryListQuery,
				variables: {
          mode: this.getMode(),
				},
				
        fetchPolicy: "network-only",
			});

			this.setState({
				shipmentAddress: data.shipmentAddressHistoryList.shipmentAddressHistory,
				shipmentAddressFavorite: data.shipmentAddressHistoryList.shipmentAddressFavorite,
				isLoading: false,
			});
		} catch(error) {
      this.setState({ isLoading: false });
    }
	}

	getType() {
		const { type, shipmentAddressIndex } = this.props.locationTempQuery.locationTemp;
		return this.props.t('common:instancePrice.searchLocation.selectLocationPoint') + ' ' + (shipmentAddressIndex+1) + ' - ' + modeToText(type, this.props.t('common:lang'));
	}

	handleChangeSearchLocation(e) {
		this.setState({
			numAddress: 0,
			showTextSearch: false,
			searchLocationDelay: e.target.value,
			isLoadingDelay: true,
		}, () => {
			clearTimeout(this._searchLocationDelay);
			this._searchLocationDelay = setTimeout(() => {
				this.setState({
					searchLocation: this.state.searchLocationDelay,
					isLoadingDelay: false,
				});

				this.props.locationTempMutate({
					variables: {
						address: this.state.searchLocationDelay,
						addressDefault: undefined,
						latitude: undefined,
						longitude: undefined,
						latitudeCenter: undefined,
						longitudeCenter: undefined,
						isFitBounds: undefined,
						isSelected: undefined,
						type: undefined,
						shipmentAddressIndex: undefined,
						datetime: undefined,
					},
				});
			}, 1200);
		});
	}

	//ใช้ตำแหน่งปัจจุบัน
	getCurrentLocation(getNameFromlatlng) {
		if (this.props.coords) {
			const { latitude, longitude } = this.props.coords;
			getNameFromlatlng({
				latitude: latitude,
				longitude: longitude,
			}).then(({ data }) => {
				const locationTempData = client.readQuery({
					query: locationTempQueryClient,
				});

				client.writeData({
					query: locationTempQueryClient,
					data: {
						locationTemp: {
							...locationTempData.locationTemp,
							address: data.getNameFromlatlng.address,
							addressDefault: data.getNameFromlatlng.address,
							latitudeCenter: latitude,
							longitudeCenter: longitude,
							latitude: latitude,
							longitude: longitude,
						}
					}
				});
			});
		}
	}

	getMode() {
		const { type } = this.props.locationTempQuery.locationTemp;
		if (type === 'pickup') {
			return 'รับสินค้า';
		} else if (type === 'delivery' || type === 'importDelivery' || type === 'exportLoading') {
			return 'ส่งสินค้า';
		}
	}

	//คลิกเลือกตำแหน่งจากรายการ
	selectAddress(item, getLatLng) {
		this.setState({
			focus: false,
			numAddress : 0,
		});

		this.context.setData({
			searchLocationIsDrag: false,
			searchLocationMainAddress: item.structured_formatting.main_text,
			searchLocationSecondaryAddress: item.structured_formatting.secondary_text,
		});

		if (
			item.mode || //เมื่อมี mode (มาจากประวัติการจอง)
			(item.lat && item.lng) //เมื่อมี ตำแหน่งอยู่แล้ว (นำเข้าสินค้า ส่งออกสินค้า)
			) {
			this.props
				.locationTempMutate({
					variables: {
						address: item.description,
						addressDefault: item.description,
						latitude: Number(item.lat),
						longitude: Number(item.lng),
						latitudeCenter: Number(item.lat),
						longitudeCenter: Number(item.lng),
						isFitBounds: undefined,
						isSelected: true,
						type: undefined,
						shipmentAddressIndex: undefined,
						datetime: undefined,
					},
				}).then(() => {
					window.scrollTo(0, 0);
				});
		} else {
			getLatLng({
				variables: {
					address: item.description,
				},
			}).then(() => {
				window.scrollTo(0, 0);
			});
		}
	}

	//คลิกเพิ่มบุ๊คมาร์ก
	async addBookmark(item, type) {
		if(type === 'containerPort') { //คลิกเพิ่มบุ๊คมาร์กจากรายการท่าเรือ ตู้คอนฯ
			if(item._id) {
				await this.props.containerPortFavoriteAddMutation({
					variables: {
						setting_container_port_id: item._id,
						container_port_type: item.container_port_type,
					}
				}).then(({ data }) => {
					if(data.containerPortFavoriteAdd._id) {
						if(data.containerPortFavoriteAdd.container_port_type === 1) { //ท่าเรือ
							this.fetchSettingPortList();
						} else if(data.containerPortFavoriteAdd.container_port_type === 2) { //ตู้คอนเทนเนอร์
							this.fetchSettingContainerList();
						}
					}
				});
			} else {
				await this.props.getLatLngMutation({
					variables: {
						address: item.description
					}
				})
				.then(({ data }) => {
					this.props.containerPortFavoriteAddMutation({
						variables: {
							description: item.description,
							structured_formatting: {
								main_text: item.structured_formatting.main_text,
								secondary_text: item.structured_formatting.secondary_text,
							},
							lat: data.getLatlng.lat,
							lng: data.getLatlng.lng,
							container_port_type: item.container_port_type,
						}
					}).then(({ data }) => {
						if(data.containerPortFavoriteAdd._id) {
							if(data.containerPortFavoriteAdd.container_port_type === 1) { //ท่าเรือ
								this.fetchSettingPortList();
							} else if(data.containerPortFavoriteAdd.container_port_type === 2) { //ตู้คอนเทนเนอร์
								this.fetchSettingContainerList();
							}
						}
					});
				})
				.catch(error => {
					console.log(error);
				});
			}
		}

		if(type === 'history') { //คลิกเพิ่มบุ๊คมาร์กจากประวัติการจอง และปักหมุด (ค้นหาปกติ)
			if(item._id) { //สำหรับแก้ไข จากประวัติการจอง
				await this.props.shipmentAddressHistoryUpdateDelectMutation({
					variables: {
						id: item._id,
						history: undefined,
						favorite: 2,
					}
				}).then(({ data }) => {
					this.fetchShipmentAddressHistoryList();
				}).catch(error => {
					console.log(error);
				});
			} else { //สำหรับเพิ่มใหม่ จากการค้นหา
				await this.props.getLatLngMutation({
					variables: {
						address: item.description,
					}
				}).then(({ data }) => {
					this.props.shipmentAddressHistoryAddMutation({
						variables: {
							mode: this.getMode(),
							address: item.description,
							lat: Number(data.getLatlng.lat),
							lng: Number(data.getLatlng.lng),
							history: 1,
							favorite: 2,
						}
					}).then(({ data }) => {
						this.fetchShipmentAddressHistoryList();
					}).catch(error => {
						console.log(error);
					});
				}).catch(error => {
					console.log(error);
				});
			}
		}
	}

	//คลิกลบบุ๊คมาร์ก
	async deleteBookmark(item, type) {		
		if(type === 'containerPort') { //ลบที่ปักหมุด ท่าเรือ, ตู้คอนเทนเนอร์
			await this.props.containerPortFavoriteDeleteMutation({
				variables: {
					id: item._id,
				}
			}).then(({ data }) => {
				if(data.containerPortFavoriteDelete.succeed) {
					if(item.container_port_type === 1) { //ท่าเรือ
						this.fetchSettingPortList();
					}
					else if (item.container_port_type === 2)  { //ตู้คอนเทนเนอร์
						this.fetchSettingContainerList();
					}
				}
			}).catch(error => {
				console.log(error);
			});
		}

		if(type === 'history') { //ลบที่ปักหมุด ประวัติการจอง, หมุดที่ปักจากการค้นหา google map
			await this.props.shipmentAddressHistoryUpdateDelectMutation({
				variables: {
					id: item._id,
					favorite: 1,
					history: undefined,
				}
			}).then(({ data }) => {
				this.fetchShipmentAddressHistoryList();
			}).catch(error => {
				console.log(error);
			});
		}
	}

	//ตรวจสอบ location รายการค้นหา มีการปักหมุดไว้แล้วหรือไม่ ถ้าปักหมุดแล้วให้ขึ้น active
	checkHasBookmark(mainText) {
		const data = this.state.shipmentAddressFavorite.filter(item => item.structured_formatting.main_text === mainText ||
				item.description === mainText //เพิ่มเงื่อนไขนี้เข้ามาเพื่อใช้เช็คกรณี mainText ไม่ตรงกับ description (รายการตู้คอนเทนเนอร์)
			);
		return (data && data.length) ? true : false;
	}

	//ดึง bookmark ใน state เพื่อนำ id ใช้ในการแก้ไขหรือลบ (ค้นหาสถานที่ไม่มี id)
	getBookmark(item) {
		const data = this.state.shipmentAddressFavorite.filter(e => e.structured_formatting.main_text === item.description ||
				e.description === item.description
			);
		return data[0];
	}

	renderFilterSetting() {
		const filterShipmentAddress = this.state.shipmentAddress.filter(item => _.includes(item.description.toString().toLowerCase(), this.state.searchLocation.trim().toString().toLowerCase()));
		const filterShipmentAddressFavorite = this.state.shipmentAddressFavorite.filter(item => _.includes(item.description.toString().toLowerCase(), this.state.searchLocation.trim().toString().toLowerCase()));
		const shipmentAddress = [...filterShipmentAddressFavorite, ...filterShipmentAddress];
		if(shipmentAddress.length > 0) {
			return (
				shipmentAddress.map((item, index) => (
					<Mutation
						key={index}
						mutation={getLatLngMutation}
						update={(cache, { data }) => {
							this.props.locationTempMutate({
								variables: {
									address: item.description,
									addressDefault: item.description,
									latitude: Number(data.getLatlng.lat),
									longitude: Number(data.getLatlng.lng),
									latitudeCenter: Number(
										data.getLatlng.lat,
									),
									longitudeCenter: Number(
										data.getLatlng.lng,
									),
									isFitBounds: undefined,
									isSelected: true,
									type: undefined,
									shipmentAddressIndex: undefined,
									datetime: undefined,
								},
							});
						}}
					>
						{ getLatLng => {
							return (
								<LocationList
									isBookmark={this.checkHasBookmark(item.description)}
									isShowBookmark={localStorage.getItem('token')}
									className="search-location__location-list"
									locationTitle={item.structured_formatting.main_text}
									locationAddress={item.structured_formatting.secondary_text}
									onPressSelect={() =>
										this.selectAddress(
											item,
											getLatLng
										)
									}

									onPressBookmark={() => {
										if(this.checkHasBookmark(item.description)) {
											const bookmark = this.getBookmark(item);
											this.deleteBookmark(bookmark, 'containerPort');
										} else {
											this.addBookmark(item, 'containerPort');
										}
									}}
								/>
							);
						}}
					</Mutation>
				))
			)
		} else {
			return (
				<MoreSearchContainer>
					<div className="text-label">{this.props.t('common:instancePrice.searchLocation.noLocationFound')}</div>
				</MoreSearchContainer>
			)
		}
	}

	changeTab = (key) => {
		this.setState({selectedTabs: key});
	}

	selectContactAddress = (item) => {
		this.context.setData({
			searchLocationIsDrag: false,
			searchLocationMainAddress: item.mainAddress,
			searchLocationSecondaryAddress: item.secondaryAddress,
			searchLocationIsSelectContactAddress: true,
			searchLocationContactName: item.contactName,
			searchLocationContactTel: item.contactTel,
		});

		this.props
			.locationTempMutate({
				variables: {
					address: item.address,
					addressDefault: item.address,
					latitude: Number(item.lat),
					longitude: Number(item.lng),
					latitudeCenter: Number(item.lat),
					longitudeCenter: Number(item.lng),
					isFitBounds: undefined,
					isSelected: true,
					type: undefined,
					shipmentAddressIndex: undefined,
					datetime: undefined,
				},
			}).then(() => {
				window.scrollTo(0, 0);
			});
	}

	render() {
		return (
			<Container className="tutorial-search-location">
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {						
						return (
							<ButtonBackStyle
								onClick={() => {
									updateInstancePriceStep({
										variables: {
											createShipment: true,
											searchLocation: false,
										},
									}).then(() => {
										this.props.locationTempMutate({
											variables: {
												address: '',
												addressDefault: '',
												latitude: 0,
												longitude: 0,
												latitudeCenter: 0,
												longitudeCenter: 0,
												isFitBounds: false,
												isSelected: false,
												shipmentAddressIndex: 0,
												type: '',
												datetime: '',
											},
										});
									});
								}}
							/>
						);
					}}
				</Mutation>

				<RouteSearchStyle
					placeholder={this.props.t('common:instancePrice.searchLocation.search')}
					label={this.getType()}
					inputType="text"
					inputValue={this.state.searchLocationDelay}
					inputRef={this._inputSearch}
					onChange={(e) => this.handleChangeSearchLocation(e)}
					onPressDelete={() => {
						this.props.locationTempMutate({
							variables: {
								address: '',
								addressDefault: undefined,
								latitude: undefined,
								longitude: undefined,
								latitudeCenter: undefined,
								longitudeCenter: undefined,
								isFitBounds: undefined,
								isSelected: undefined,
								type: undefined,
								shipmentAddressIndex: undefined,
								datetime: undefined,
							},
						});

						this._inputSearch.current.focus();
						this.setState({ searchLocation: '', searchLocationDelay: '', showTextSearch: false });
					}}
				/>

				{
					(
						this.props.locationTempQuery.locationTemp.type === 'pickup' ||
						this.props.locationTempQuery.locationTemp.type === 'delivery' ||
						this.props.locationTempQuery.locationTemp.type === 'importDelivery' ||
						this.props.locationTempQuery.locationTemp.type === 'exportLoading'
					) && (
						<ButtonSearchContainer>
							<div>
								<Query
									fetchPolicy="network-only"
									query={getNameFromlatlngQuery}
								>
									{({ refetch }) => {
										return (
											<ButtonIcon
												className="search-location__current-location"
												icon="fal fa-crosshairs" 
												label={this.props.t('common:instancePrice.searchLocation.useCurrentLocation')}
												onClick={() => this.getCurrentLocation(refetch)} 
											/>
										);
									}}
								</Query>
							</div>
							{/* <div>
								<ButtonIcon icon="fal fa-map" label="เลือกบนแผนที่" />
							</div> */}
						</ButtonSearchContainer>
					)
				}

				{
					this.state.isLoadingDelay && (
						<Card>
							<BlankSpace height={10} />
							<HeaderStyle title={this.props.t('common:instancePrice.searchLocation.headerSearchResults')} />
							<Skeleton height={180} width={400} style={{marginTop: 10}}>
								<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
								<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
								<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
								<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
								<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
								<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
							</Skeleton>
						</Card>
					)
				}

				{
					(this.state.searchLocation && !this.state.isLoadingDelay) && (
						<Card>
							<BlankSpace height={10} />
							<HeaderStyle title={this.props.t('common:instancePrice.searchLocation.headerSearchResults')} />

							{
								//สำหรับค้นหาแผนที่จาก google map
								(this.props.locationTempQuery.locationTemp.type === 'pickup' ||
								this.props.locationTempQuery.locationTemp.type === 'delivery' ||
								this.props.locationTempQuery.locationTemp.type === 'importDelivery' ||
								this.props.locationTempQuery.locationTemp.type === 'exportLoading') && (
									<Query
										query={mapSearchQuery}
										variables={{
											input: this.state.searchLocation,
										}}
										fetchPolicy="cache-and-network"
									>
										{({ loading, error, data }) => {
											if (loading) return (
												<Skeleton height={180} width={400} style={{marginTop: 10}}>
													<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
													<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
													<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
													<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
													<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
													<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
												</Skeleton>
											);
											if (error) return error.graphQLErrors[0].message;

											//ค้นหาสถานที่เพิ่มเติม
											if(this.state.showTextSearch) {
												return (
													<Query
														query={textMapSearchQuery}
														variables={{
															input: this.state.searchLocation,
														}}
														fetchPolicy="cache-and-network"
													>
														{({ loading, error, data }) => {
															if (loading) return (
																<Skeleton height={180} width={400} style={{marginTop: 10}}>
																	<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
																	<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
																	<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
																	<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
																	<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
																	<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
																</Skeleton>
															);
															if (error) return error.graphQLErrors[0].message;
															if(data.textMapSearch === null || data.textMapSearch.length === 0) return (
																<div style={{textAlign:"center", marginTop: 20, fontSize: 18}}>
																	{this.props.t('common:instancePrice.searchLocation.noLocationFound')}
																</div>
															);
															
															return data.textMapSearch.map((item, index) => (
																<Mutation
																	key={index}
																	mutation={getLatLngMutation}
																	update={(cache, { data }) => {
																		this.props.locationTempMutate({
																			variables: {
																				address: undefined,
																				addressDefault: item.description,
																				latitude: Number(data.getLatlng.lat),
																				longitude: Number(data.getLatlng.lng),
																				latitudeCenter: Number(
																					data.getLatlng.lat,
																				),
																				longitudeCenter: Number(
																					data.getLatlng.lng,
																				),
																				isFitBounds: undefined,
																				isSelected: true,
																				type: undefined,
																				shipmentAddressIndex: undefined,
																				datetime: undefined,
																			},
																		});
																	}}
																>
																	{ getLatLng => {
																		return (
																			<LocationList
																				isBookmark={this.checkHasBookmark(item.description)}
																				isShowBookmark={localStorage.getItem('token')}
																				className="search-location__location-list"
																				locationTitle={item.structured_formatting.main_text}
																				locationAddress={item.structured_formatting.secondary_text}
																				onPressSelect={() =>
																					this.selectAddress(
																						item,
																						getLatLng
																					)
																				}

																				onPressBookmark={() => {
																					if(this.checkHasBookmark(item.description)) {
																						const bookmark = this.getBookmark(item);
																						this.deleteBookmark(bookmark, 'history');
																					} else {
																						this.addBookmark(item, 'history');
																					}
																				}}
																			/>
																		);
																	}}
																</Mutation>
															));
														}}
													</Query>
												)
											}

											//ค้นหาสถานที่ปกติ
											return (
												<div>
													{
														data.mapSearch.map((item, index) => (
															<Mutation
																key={index}
																mutation={getLatLngMutation}
																update={(cache, { data }) => {
																	this.props.locationTempMutate({
																		variables: {
																			address: item.description,
																			addressDefault: item.description,
																			latitude: Number(data.getLatlng.lat),
																			longitude: Number(data.getLatlng.lng),
																			latitudeCenter: Number(
																				data.getLatlng.lat,
																			),
																			longitudeCenter: Number(
																				data.getLatlng.lng,
																			),
																			isFitBounds: undefined,
																			isSelected: true,
																			type: undefined,
																			shipmentAddressIndex: undefined,
																			datetime: undefined,
																		},
																	});
																}}
															>
																{ getLatLng => {
																	return (
																		<div>
																			<LocationList
																				isBookmark={this.checkHasBookmark(item.description)}
																				isShowBookmark={localStorage.getItem('token')}
																				className="search-location__location-list"
																				locationTitle={item.structured_formatting.main_text}
																				locationAddress={item.structured_formatting.secondary_text}
																				onPressSelect={() =>
																					this.selectAddress(
																						item,
																						getLatLng
																					)
																				}

																				onPressBookmark={() => {
																					if(this.checkHasBookmark(item.description)) {
																						const bookmark = this.getBookmark(item);
																						this.deleteBookmark(bookmark, 'history');
																					} else {
																						this.addBookmark(item, 'history');
																					}
																				}}
																			/>
																		</div>
																	);
																}}
															</Mutation>
														))
													}

													{
														(data.mapSearch.length === 0) && (
															<MoreSearchContainer>
																<div className="text-label">{this.props.t('common:instancePrice.searchLocation.canNotFindRequiredLocation')}</div>
																<div><Button className="search-location__search-location-more" style={{marginTop: 10}} block onClick={() => this.setState({showTextSearch : true})}>{this.props.t('common:instancePrice.searchLocation.findMoreLocation')}</Button></div>
															</MoreSearchContainer>
														)
													}
												</div>
											)
										}}
									</Query>
								)
							}

							{ //ท่าเรือ
								(this.props.locationTempQuery.locationTemp.type === 'importPickup' ||
								this.props.locationTempQuery.locationTemp.type === 'exportReturn') && (
									<div>
										{ this.renderFilterSetting() }
									</div>
								)
							}

							{ //ลานตู้
								(this.props.locationTempQuery.locationTemp.type === 'importReturn' ||
								this.props.locationTempQuery.locationTemp.type === 'exportPickup') && (
									<Query
										query={settingContainerListQuery}
										variables={{
											description: this.state.searchLocation,
										}}
										fetchPolicy="network-only"
									>
										{({ loading, error, data: settingContainerListData }) => {
											if (loading) return (
												<Skeleton height={180} width={400} style={{marginTop: 10}}>
													<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
													<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
													<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
													<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
													<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
													<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
												</Skeleton>
											);
											if (error) return error.graphQLErrors[0].message;

											return (
												<div>
													{
														settingContainerListData.settingContainerList.settingContainer.map((item, index) => (
															<Mutation
																key={index}
																mutation={getLatLngMutation}
																update={(cache, { data }) => {
																	this.props.locationTempMutate({
																		variables: {
																			address: item.description,
																			addressDefault: item.description,
																			latitude: Number(data.getLatlng.lat),
																			longitude: Number(data.getLatlng.lng),
																			latitudeCenter: Number(
																				data.getLatlng.lat,
																			),
																			longitudeCenter: Number(
																				data.getLatlng.lng,
																			),
																			isFitBounds: undefined,
																			isSelected: true,
																			type: undefined,
																			shipmentAddressIndex: undefined,
																			datetime: undefined,
																		},
																	});
																}}
															>
																{ getLatLng => {
																	return (
																		<LocationList
																			key={index}
																			isBookmark={this.checkHasBookmark(item.description)}
																			isShowBookmark={localStorage.getItem('token')}
																			className="search-location__location-list"
																			locationTitle={item.structured_formatting.main_text}
																			locationAddress={item.structured_formatting.secondary_text}
																			onPressSelect={() =>
																				this.selectAddress(
																					item,
																					getLatLng
																				)
																			}

																			onPressBookmark={() => {
																				if(this.checkHasBookmark(item.description)) {
																					const bookmark = this.getBookmark(item);
																					this.deleteBookmark(bookmark, 'containerPort');
																				} else {
																					this.addBookmark(item, 'containerPort');
																				}
																			}}
																		/>
																	);
																}}
															</Mutation>
														))
													}

													{
														(settingContainerListData.settingContainerList.settingContainer.length === 0) && (
															<MoreSearchContainer>
																<div className="text-label">{this.props.t('common:instancePrice.searchLocation.noLocationFound')}</div>
															</MoreSearchContainer>
														)
													}
												</div>
											)
										}}
									</Query>
								)
							}
						</Card>
					)
				}

				{
					(!this.state.searchLocation && !this.state.isLoadingDelay) && (
						<div>
							{
								(!this.state.isLoading) && (
									<div>
										<Card>
											{
												(localStorage.getItem('token') ||
												(!localStorage.getItem('token') && (
													this.props.locationTempQuery.locationTemp.type === 'importPickup' ||
													this.props.locationTempQuery.locationTemp.type === 'exportPickup' ||
													this.props.locationTempQuery.locationTemp.type === 'importReturn' ||
													this.props.locationTempQuery.locationTemp.type === 'exportReturn'
												))) && (
													<React.Fragment>
														<BlankSpace height={10} />
														<TabsContainer>
															<TabsAntd onChange={(key) => this.changeTab(key)} animated={false}>
																{
																	(
																		localStorage.getItem('token') &&
																		(this.props.instancePriceQuery.instancePrice.shipmentType === 1 ||
																			(this.props.instancePriceQuery.instancePrice.shipmentType !== 1 && this.props.locationTempQuery.locationTemp.type === 'importDelivery') ||
																			(this.props.instancePriceQuery.instancePrice.shipmentType !== 1 && this.props.locationTempQuery.locationTemp.type === 'exportLoading')
																	)) && (
																		<TabsAntd.TabPane tab={this.props.t('common:instancePrice.searchLocation.headerSaved')} key="1">
																			<Query
																				query={accountContactListQuery}
																				fetchPolicy="network-only"
																			>
																				{
																					({ data: dataAccountContactList, loading: loadingAccountContactList, error: errorAccountContactList }) => {
																						if (loadingAccountContactList) return (
																							<Skeleton height={180} width={400} style={{marginTop: 10}}>
																								<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
																								<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
																								<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
																								<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
																								<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
																								<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
																							</Skeleton>
																						);

																						if (errorAccountContactList) return errorAccountContactList.graphQLErrors[0].message;
																						
																						const accountContactFilter = dataAccountContactList.accountContactList.account_contact.filter(
																							item => item.contact_address.length > 0
																						);
											
																						let contactItem = [];
																						accountContactFilter.forEach(accountContact =>
																							accountContact.contact_address.forEach(
																								contactAddress => {
																									const data = {
																										contactName: accountContact.contact_name,
																										contactTel: accountContact.contact_tel,
																										mainAddress:
																											contactAddress.structured_formatting.main_text,
																										secondaryAddress:
																											contactAddress.structured_formatting.secondary_text,
																										address: contactAddress.address,
																										lat: contactAddress.lat,
																										lng: contactAddress.lng,
																									};
																									contactItem.push(data);
																								}
																							)
																						);

																						return (
																							<div>
																								{
																									contactItem.map((item, index) => {
																										return (
																											<ContactList
																												key={index}
																												locationName={item.mainAddress || 'ไม่มีชื่อสถานที่'}
																												locationAddress={item.secondaryAddress}
																												contact={`${item.contactName}, ${item.contactTel}`}
																												clicked={() => this.selectContactAddress(item)}
																											/>
																										)
																									})
																								}

																								{
																									(contactItem.length === 0) && (
																										<MoreSearchContainer>
																											<div className="text-label">ไม่มีข้อมูลที่บันทึกไว้</div>
																										</MoreSearchContainer>
																									)
																								}
																							</div>
																						)
																					}
																				}
																			</Query>
																		</TabsAntd.TabPane>
																	)
																}

																{
																	localStorage.getItem('token') && (
																		<TabsAntd.TabPane tab={this.props.t('common:instancePrice.searchLocation.headerPin')} key="2">
																			{
																				(this.state.shipmentAddressFavorite.length > 0) ? this.state.shipmentAddressFavorite.map((item, index) => {
																					return (
																						<Mutation
																							key={index}
																							mutation={getLatLngMutation}
																							update={(cache, { data }) => {
																								this.props.locationTempMutate({
																									variables: {
																										address: item.description,
																										addressDefault: item.description,
																										latitude: Number(data.getLatlng.lat),
																										longitude: Number(data.getLatlng.lng),
																										latitudeCenter: Number(
																											data.getLatlng.lat,
																										),
																										longitudeCenter: Number(
																											data.getLatlng.lng,
																										),
																										isFitBounds: undefined,
																										isSelected: true,
																										type: undefined,
																										shipmentAddressIndex: undefined,
																										datetime: undefined,
																									},
																								});
																							}}
																						>
																							{ getLatLng => {
																								return (
																									<div>
																										<LocationList
																											isBookmark
																											isShowBookmark
																											className="search-location__location-list"
																											locationTitle={item.structured_formatting.main_text}
																											locationAddress={item.structured_formatting.secondary_text}
																											onPressSelect={() =>
																												this.selectAddress(
																													item,
																													getLatLng
																												)
																											}
																											
																											onPressBookmark={() => {
																												if(item.container_port_type) {
																													this.deleteBookmark(item, 'containerPort');
																												} else {
																													this.deleteBookmark(item, 'history')
																												}
																											}}
																										/>
																									</div>
																								);
																							}}
																						</Mutation>
																					)
																				}) : (
																					<div style={{textAlign:"center", marginTop: 20, fontSize: 18}}>
																							{this.props.t('common:instancePrice.searchLocation.lorem1')}
																					</div>
																				)
																			}
																		</TabsAntd.TabPane>
																	)
																}

																{
																	(( //เงื่อนไขตรวจสอบการแสดงผลเมื่อล็อกอิน และไม่ได้ล็อกอิน
																		localStorage.getItem('token') && ( //ต้องล็อกอิน
																				this.props.locationTempQuery.locationTemp.type === 'pickup' ||
																				this.props.locationTempQuery.locationTemp.type === 'delivery' ||
																				this.props.locationTempQuery.locationTemp.type === 'importDelivery' ||
																				this.props.locationTempQuery.locationTemp.type === 'exportLoading'
																			)
																	) || ( //ไม่ต้องล็อกอิน
																		this.props.locationTempQuery.locationTemp.type === 'importPickup' ||
																		this.props.locationTempQuery.locationTemp.type === 'exportPickup' ||
																		this.props.locationTempQuery.locationTemp.type === 'importReturn' ||
																		this.props.locationTempQuery.locationTemp.type === 'exportReturn'
																	)) && (
																		<TabsAntd.TabPane tab={
																				this.props.locationTempQuery.locationTemp.type === 'importPickup' || this.props.locationTempQuery.locationTemp.type === 'exportPickup' ? this.props.t('common:instancePrice.searchLocation.lorem2') :
																				this.props.locationTempQuery.locationTemp.type === 'importReturn' || this.props.locationTempQuery.locationTemp.type === 'exportReturn' ? this.props.t('common:instancePrice.searchLocation.lorem3')
																				: this.props.t('common:instancePrice.searchLocation.headerHistory')
																			}
																			key="3"
																		>
																			{
																				this.state.shipmentAddressFavorite.map((item, index) => (
																					<Mutation
																							key={index}
																							mutation={getLatLngMutation}
																							update={(cache, { data }) => {
																								this.props.locationTempMutate({
																									variables: {
																										address: item.description,
																										addressDefault: item.description,
																										latitude: Number(data.getLatlng.lat),
																										longitude: Number(data.getLatlng.lng),
																										latitudeCenter: Number(
																											data.getLatlng.lat,
																										),
																										longitudeCenter: Number(
																											data.getLatlng.lng,
																										),
																										isFitBounds: undefined,
																										isSelected: true,
																										type: undefined,
																										shipmentAddressIndex: undefined,
																										datetime: undefined,
																									},
																								});
																							}}
																						>
																							{ getLatLng => {
																								return (
																									<div>
																										<LocationList
																											isBookmark
																											isShowBookmark
																											className="search-location__location-list"
																											locationTitle={item.structured_formatting.main_text}
																											locationAddress={item.structured_formatting.secondary_text}
																											onPressSelect={() =>
																												this.selectAddress(
																													item,
																													getLatLng
																												)
																											}
																											
																											onPressBookmark={() => {
																												if(item.container_port_type) {
																													this.deleteBookmark(item, 'containerPort');
																												} else {
																													this.deleteBookmark(item, 'history')
																												}
																											}}
																										/>
																									</div>
																								);
																							}}
																						</Mutation>
																				))
																			}

																			<Card>
																				{
																					this.state.shipmentAddress.length ? this.state.shipmentAddress.map((item, index) => {
																						return (
																							<Mutation 
																								key={index}
																								mutation={getLatLngMutation}
																								update={(cache, { data }) => {
																									this.props.locationTempMutate({
																										variables: {
																											address: item.description,
																											addressDefault: item.description,
																											latitude: Number(data.getLatlng.lat),
																											longitude: Number(data.getLatlng.lng),
																											latitudeCenter: Number(
																												data.getLatlng.lat,
																											),
																											longitudeCenter: Number(
																												data.getLatlng.lng,
																											),
																											isFitBounds: undefined,
																											isSelected: true,
																											type: undefined,
																											shipmentAddressIndex: undefined,
																											datetime: undefined,
																										},
																									});
																								}}
																							>
																								{ getLatLng => {
																									return (
																										<div>
																											<LocationList
																												isShowBookmark={localStorage.getItem('token')}
																												className="search-location__location-list"
																												locationTitle={item.structured_formatting.main_text}
																												locationAddress={item.structured_formatting.secondary_text}
																												onPressSelect={() =>
																													this.selectAddress(
																														item,
																														getLatLng
																													)
																												}
																												onPressBookmark={() => {
																													if(item.container_port_type) {
																														this.addBookmark(item, 'containerPort');
																													} else {
																														this.addBookmark(item, 'history')
																													}
																												}}
																											/>
																										</div>
																									);
																								}}
																							</Mutation>
																						)
																					}) : (
																						<div style={{textAlign:"center", marginTop: 20, fontSize: 18}}>
																								{this.props.t('common:instancePrice.searchLocation.lorem4')}
																						</div>
																					)
																				}
																			</Card>
																		</TabsAntd.TabPane>
																	)
																}
															</TabsAntd>
														</TabsContainer>
													</React.Fragment>
												)
											}
										</Card>
									</div>
								)
							}

							{
								this.state.isLoading && (
									<Skeleton height={180} width={400} style={{marginTop: 10}}>
										<rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
										<rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
										<rect x="0" y="60" rx="4" ry="4" width="300" height="13" />
										<rect x="0" y="80" rx="3" ry="3" width="250" height="10" />
										<rect x="0" y="120" rx="4" ry="4" width="300" height="13" />
										<rect x="0" y="140" rx="3" ry="3" width="250" height="10" />
									</Skeleton>
								)
							}
						</div>
					)
				}
			</Container>
		);
	}
}

export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	watchPosition: true,
	geolocationProvider: navigator.geolocation,
})(
	compose(
		graphql(instancePriceQueryClient, {
			name: 'instancePriceQuery',
		}),
		graphql(updateLocationTempMutationClient, {
			name: 'locationTempMutate',
		}),
		graphql(locationTempQueryClient, {
			name: 'locationTempQuery',
		}),
		graphql(textMapSearchQuery, {
			name: 'textMapSearchQuery',
		}),
		graphql(shipmentAddressHistoryListQuery, {
			name: 'shipmentAddressHistoryListQuery',
		}),
		graphql(getLatLngMutation, {
			name: 'getLatLngMutation',
		}),
		graphql(shipmentAddressHistoryUpdateDelectMutation, {
			name: 'shipmentAddressHistoryUpdateDelectMutation',
		}),
		graphql(shipmentAddressHistoryAddMutation, {
			name: 'shipmentAddressHistoryAddMutation',
		}),
		graphql(containerPortFavoriteAddMutation, {
			name: 'containerPortFavoriteAddMutation',
		}),
		graphql(containerPortFavoriteDeleteMutation, {
			name: 'containerPortFavoriteDeleteMutation',
		}),
	)(withNamespaces()(SearchLocation)),
);
