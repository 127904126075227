import gql from 'graphql-tag';

export default gql`
	mutation UpdateRegister(
		$email: String!
		$password: String
		$phoneCode: String!
		$phoneNumber: String!
		$taxID: String
	) {
		register(
			email: $email
			password: $password
			phoneCode: $phoneCode
			phoneNumber: $phoneNumber
			taxID: $taxID
		) @client
	}
`;
