import React, { Component } from 'react';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import 'moment-duration-format';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	right: 0px;
	top: 50%;
	z-index: 1;
	transform: translateY(-50%);
	transition: 0.5s;

	${props => !props.isOpen && `right: -336px;`}

	@media(max-width: 480px) {
		top: 56%;
		${props => props.isOpen && `right: -85px;`}
	}
`;

const ButtonFlag = styled.div`
	width: 50px;
	height: 50px;
	background: #000000;
	border-radius: 10px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: -6px 6px 20px rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-size: 26px;
	margin-top: 20px;
	cursor: pointer;
`;

const BoxContent = styled.div`
	background-color: #ffffff;
	width: 336px;
	min-height: 200px;
	border-radius: 6px;
	box-shadow: -6px 6px 20px rgba(0, 0, 0, 0.16);
	overflow: hidden;

	& .body {
		min-height: 200px;

		& img {
			width: 100%;
		}
	}
`;

class GiztixSurvey extends Component {
	state = {
		isOpen: false,
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isOpen: true });
		}, 500);
	}

	openHandler = () => {
		const { isOpen } = this.state;
		this.setState({ isOpen: !isOpen });
	};

	render() {
		const { isOpen } = this.state;

		return (
			<Container isOpen={isOpen}>
				<ButtonFlag onClick={this.openHandler} title="GIZTIX Survey">
					{isOpen ? <i className="fas fa-times-circle" /> : <i className="fas fa-clipboard-list" />}
				</ButtonFlag>

				<BoxContent isOpen={isOpen}>
					<div className="body">
						<a href="http://bit.ly/2G5tbNs" target="_blank">
							<img src={require('./images/Banner-survey.jpg')} alt="giztix-survey" />
						</a>
					</div>
				</BoxContent>
			</Container>
		);
	}
}

export default withNamespaces()(GiztixSurvey);
