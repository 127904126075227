import React from 'react';
import styled from 'styled-components';

const liff = window.liff;
const Container = styled.div`
  padding: 10px;
`;

class GetProfileLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userLineID: '',
      pictureUrl: '',
      statusMessage: '',
    };
  }

  async componentWillMount() {
    await this.getProfile();
  }

  getProfile() {
    liff.init(async () => {
      let getProfile = await liff.getProfile();
      this.setState({
        name: getProfile.displayName,
        userLineID: getProfile.userId,
        pictureUrl: getProfile.pictureUrl,
        statusMessage: getProfile.statusMessage
      });
    });
  }

  render() {
    return (
      <Container>
        <div style={{fontWeight: 'bold'}}>displayName</div>
        <div>{ this.state.name || '-' }</div>
        <div style={{fontWeight: 'bold'}}>userId</div>
        <div>{ this.state.userLineID || '-' }</div>
        <div style={{fontWeight: 'bold'}}>pictureUrl</div>
        <div>{ this.state.pictureUrl || '-' }</div>
        <div style={{fontWeight: 'bold'}}>statusMessage</div>
        <div>{ this.state.statusMessage || '-' }</div>
      </Container>
    )
  }
}

export default GetProfileLine;