import gql from 'graphql-tag';

export default gql`
	query search($_id: ID) {
		accountInfoList(_id: $_id, show_data: 1) {
			account_info {
				_id
				account_info_email
				account_info_business_name
				account_info_business_type {
					th
				}
				account_info_status
				account_info_phone_number
				account_info_phone_code
				account_info_tracking_url
				account_info_logo
			}
			total_page
		}
	}
`;
