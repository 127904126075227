import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	border-bottom: 1px solid #e3e3e3;
	padding-top: 12px;
	padding-bottom: 12px;

	.icon {
		font-size: 26px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 40px;
		justify-content: center;
		${props => props.disabled && `color: #E3E3E3;`}
	}

	.detail {
		flex: 1;
		margin-left: 15px;

		&-title {
			font-size: 18px;
			font-weight: bold;
			line-height: 1.2;
			${props => props.disabled && `color: #E3E3E3;`}
		}

		&-sub-title {
			font-size: 16px;
			line-height: 1;
			color: ${props => props.subTitleColor};
			${props => props.disabled && `color: #E3E3E3;`};
			white-space: pre-wrap;
		}

		&-sub-note {
			font-size: 16px;
			line-height: 1;
			color: #19aa19;
		}
	}

	.arrow {
		color: #e3e3e3;
		font-size: 16px;
		line-height: 1;
	}
`;

const PaymentChannelListItem = props => {
	return (
		<Container {...props} onClick={() => (!props.disabled && props.onSelect) && props.onSelect()}>
			<div className="icon">
				<i className={props.LeftIcon} />
			</div>
			<div className="detail">
				<div className="detail-title">{props.Title}</div>
				<div className="detail-sub-title">{props.subTitle}</div>
				<div className="detail-sub-note">{props.subNote}</div>
			</div>
			<div className="arrow">
				<i className="fal fa-chevron-right" />
			</div>
		</Container>
	);
};

PaymentChannelListItem.defaultProps = {
	LeftIcon: '',
	LeftIconColor: '',
	Title: '',
	subTitle: '',
	subNote: '',
	subTitleColor: '#808080',
	disabled: false,
};

export default PaymentChannelListItem;
