import gql from 'graphql-tag';

export default gql`
	mutation ShareRouteAdd(
			$obj_msg: String
			$utm_link : String
		) {
		shareRouteAdd(
			obj_msg: $obj_msg
			utm_link : $utm_link
		) {
			_id
			url
			real_url
		}
	}
`;
