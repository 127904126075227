import React from 'react';
import { GoogleMaps } from './components';

class Map extends React.Component {
	render() {
		return <GoogleMaps />;
	}
}

export default Map;
