import gql from 'graphql-tag';

export default gql`
	mutation AccountInfoUpdate(
		$account_info_phone_code: String
		$account_info_phone_number: String
	) {
		accountInfoUpdate(
			account_info_phone_code: $account_info_phone_code
			account_info_phone_number: $account_info_phone_number
		) {
			_id
		}
	}
`;
