import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1;
  align-items: center;
  color: ${props => props.color};

  & .icon {
    font-size: 12px;
    margin-right: 5px;
  }

  & .text-rank {
    font-size: 16px;
  }
`;

const LabelRank = (props) => {
  return (
    <Container {...props}>
      <div className="icon">
        <i className={props.icon}></i>
      </div>
      <div className="text-rank">{props.label}</div>
    </Container>
  )
};

LabelRank.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
}

LabelRank.defaultProps = {
  label: '',
  icon: '',
  color: '#000000',
}

export default LabelRank;