import gql from 'graphql-tag';

export default gql`
	mutation ResetPassword(
		$email: String!
		$password: String!
		$otp: String!
		$ref: String!
	) {
		resetPassword(
			account_user_name: $email
			account_user_password: $password
			otp: $otp
			ref: $ref
		) {
			succeed
		}
	}
`;
