import gql from 'graphql-tag';

export default gql`
  mutation NotificationUpdate(
    $_id: ID
    $read_status: Int
    $multi: Boolean
  ) {
    notificationUpdate (
      _id: $_id
      read_status: $read_status
      multi: $multi
    ) {
      succeed
    }
  }
`;