import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import client from 'src/config/client';
import moment from 'moment';
import _ from 'lodash';
import holiday from 'src/config/holiday';

const getInstancePriceClientData = () => {
	const instancePriceData = client.readQuery({
		query: instancePriceQueryClient,
	});

	return instancePriceData.instancePrice;
};

export const getWorkingTimeFlashDeals = currenttime => {
	const { flashDeals } = getInstancePriceClientData();
	const { pickupStart } = flashDeals;
	const currentTime = moment(currenttime);

	if (moment(pickupStart).isBefore(currentTime, 'minute')) {
		return currentTime;
	}

	return pickupStart;
};

const checkProvinceInBangkok = () => {
	const { shipmentAddress } = getInstancePriceClientData();
	const provinces = ['กรุงเทพมหานคร', 'นครปฐม', 'นนทบุรี', 'ปทุมธานี', 'สมุทรปราการ', 'สมุทรสาคร'];
	const shipmentAddressProvince = shipmentAddress
		.filter(item => item.mode === 'pickup')
		.map(item => item.province);

	return _.some(shipmentAddressProvince, item => provinces.indexOf(item) !== -1);
};

export const checkProvinceMultiDrop4Wheels = () => {
	const { shipmentAddress } = getInstancePriceClientData();

	const provinces = [
		'กรุงเทพมหานคร',
		'สมุทรปราการ',
		'สมุทรสาคร',
		'ปทุมธานี',
		'นนทบุรี',
	];

	const shipmentAddressProvince = shipmentAddress.map(
		item => item.province,
	);

	return (
		shipmentAddress.length >= 3 &&
		_.every(shipmentAddressProvince, item => provinces.indexOf(item) !== -1)
	);
}

export const getWorkingTime = currenttime => {
	const { setting_truck_id, setting_truck_type_id } = getInstancePriceClientData();
	if (setting_truck_id === '5be5057787415b16d8cf48f6') {
		// 4 Wheels
		return getWorkingTime4Wheels(currenttime);
	} else if (
		(setting_truck_id === '5c4ac1ea9d52c92174bf0b2f' ||
			setting_truck_id === '5cad5a065e962c4d15eb2daf')
	) {
		// 6 Wheels & 10 Wheels
		return getWorkingTime6Wheels(currenttime);
	} else if (
		setting_truck_id === '5cbe8678723da822892a6417'
	) {
		// Trailer
		return getWorkingTimeTrailer(currenttime);
	}
};

const getWorkingTimeTrailer = currenttime => {
	const currentTime = moment(currenttime);

	if (
		currentTime.isBetween(
			moment(currentTime).set({
				hour: '00',
				minute: '00',
				second: '00',
			}),
			moment(currentTime).set({
				hour: '16',
				minute: '00',
				second: '59',
			}),
			null,
			'[]'
		)
	) {
		let count = 1;
		let futureDay = moment(currentTime)
			.startOf('day')
			.add(2, 'day');

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '06',
			minute: '00',
			second: '00',
		});
	} else {
		let count = 1;
		let futureDay = moment(currentTime)
			.startOf('day')
			.add(2, 'day');

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '13',
			minute: '00',
			second: '00',
		});
	}
};

const getWorkingTime6Wheels = currenttime => {
	const currentTime = moment(currenttime);

	if (
		currentTime.isBetween(
			moment(currentTime).set({
				hour: '16',
				minute: '01',
				second: '00',
			}),
			moment(currentTime).set({
				hour: '23',
				minute: '59',
				second: '59',
			}),
			null,
			'[]'
		)
	) {
		let count = 1;
		let futureDay = moment(currentTime).add(1, 'day');

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '13',
			minute: '00',
			second: '00',
		});
	} else if (
		currentTime.isBetween(
			moment(currentTime).set({
				hour: '00',
				minute: '00',
				second: '00',
			}),
			moment(currentTime).set({
				hour: '06',
				minute: '59',
				second: '59',
			}),
			null,
			'[]'
		)
	) {
		let count = 1;
		let futureDay = moment(currentTime);

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '13',
			minute: '00',
			second: '00',
		});
	} else {
		let count = 1;
		let futureDay = moment(currentTime)
			.startOf('day')
			.add(1, 'day');

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '06',
			minute: '00',
			second: '00',
		});
	}
};

const checkDateNewYear = () => {
	const currentTime = moment();
	if (currentTime.isBetween('2019-12-28', '2020-01-01', null, '[]')) {
		return true;
	}

	return false;
};

export const getWorkingTime4Wheels = currenttime => {
	const currentTime = moment(currenttime);
	const { shipmentAddress } = getInstancePriceClientData();

	if (checkDateNewYear()) {
		return moment(currentTime).add(1, 'day');
	} else if (shipmentAddress.length >= 3) {
		if (checkProvinceMultiDrop4Wheels() &&
    currentTime.isBetween(
			moment(currentTime).set({
				hour: '05',
				minute: '00',
				second: '00',
     }),
     	moment(currentTime).set({
      	hour: '18',
      	minute: '00',
      	second: '59',
     }),
     null,
     '[]',
    )) {
			const holidayFilter = holiday.filter(item => item === moment(currentTime).format('YYYY-MM-DD'));
			if (holidayFilter.length === 1) {
				let count = 1;
				let futureDay = moment(currentTime).startOf('day');

				for (let i = 0; i < count; i++) {
					const holidayFilter = holiday.filter(
						item => item === moment(futureDay).format('YYYY-MM-DD')
					);

					if (holidayFilter.length === 1) {
						count++;
						futureDay = moment(futureDay).add(1, 'day');
					}
				}

				return futureDay.set({
					hour: '07',
					minute: '00',
					second: '00',
				});
			}

			const currentTime120 = moment(currentTime)
			.add(120, 'minutes');
			return currentTime120;
		} else {
			let count = 1;
			let futureDay = moment(currentTime)
				.startOf('day')
				.add(1, 'day');
	
			for (let i = 0; i < count; i++) {
				const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));
	
				if (holidayFilter.length === 1) {
					count++;
					futureDay = moment(futureDay).add(1, 'day');
				}
			}
	
			return futureDay.set({
				hour: '07',
				minute: '00',
				second: '00',
			});
		}
	} else if (
		checkProvinceInBangkok() &&
		currentTime.isBetween(
			moment(currentTime).set({
				hour: '18',
				minute: '01',
				second: '00',
			}),
			moment(currentTime).set({
				hour: '20',
				minute: '00',
				second: '59',
			}),
			null,
			'[]'
		)
	) {
		const holidayFilter = holiday.filter(item => item === moment(currentTime).format('YYYY-MM-DD'));

		if (holidayFilter.length === 1) {
			let count = 1;
			let futureDay = moment(currentTime).startOf('day');

			for (let i = 0; i < count; i++) {
				const holidayFilter = holiday.filter(
					item => item === moment(futureDay).format('YYYY-MM-DD')
				);

				if (holidayFilter.length === 1) {
					count++;
					futureDay = moment(futureDay).add(1, 'day');
				}
			}

			return futureDay.set({
				hour: '07',
				minute: '00',
				second: '00',
			});
		}

		return moment(currentTime).add(120, 'minutes');
	} else if (
		currentTime.isBetween(
			moment(currentTime).set({
				hour: '18',
				minute: '01',
				second: '00',
			}),
			moment(currentTime).set({
				hour: '23',
				minute: '59',
				second: '59',
			}),
			null,
			'[]'
		)
	) {
		let count = 1;
		let futureDay = moment(currentTime)
			.startOf('day')
			.add(1, 'day');

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '07',
			minute: '00',
			second: '00',
		});
	} else if (
		currentTime.isBetween(
			moment(currentTime).set({
				hour: '00',
				minute: '00',
				second: '00',
			}),
			moment(currentTime).set({
				hour: '04',
				minute: '59',
				second: '59',
			}),
			null,
			'[]'
		)
	) {
		let count = 1;
		let futureDay = moment(currentTime).startOf('day');

		for (let i = 0; i < count; i++) {
			const holidayFilter = holiday.filter(item => item === moment(futureDay).format('YYYY-MM-DD'));

			if (holidayFilter.length === 1) {
				count++;
				futureDay = moment(futureDay).add(1, 'day');
			}
		}

		return futureDay.set({
			hour: '07',
			minute: '00',
			second: '00',
		});
	} else {
		const holidayFilter = holiday.filter(item => item === moment(currentTime).format('YYYY-MM-DD'));

		if (holidayFilter.length === 1) {
			let count = 1;
			let futureDay = moment(currentTime).startOf('day');

			for (let i = 0; i < count; i++) {
				const holidayFilter = holiday.filter(
					item => item === moment(futureDay).format('YYYY-MM-DD')
				);

				if (holidayFilter.length === 1) {
					count++;
					futureDay = moment(futureDay).add(1, 'day');
				}
			}

			return futureDay.set({
				hour: '07',
				minute: '00',
				second: '00',
			});
		}

		const currentTime120 = moment(currentTime)
			.add(120, 'minutes');
		return currentTime120;
	}
};
