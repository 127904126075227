import React, { Component } from "react";
import {Row , Badge} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './style.css';

const ServiceItemContainer = styled(Row)`
    margin-left:0px;
`;

const ServiceListItem = (props) => (
    <ServiceItemContainer onClick={props.onClick} className={`ServiceListItem ${props.serviceImage ? "img" : props.CreditCardIcon ? "credit" : ""} ${props.selected ? "selected" : ""} ${props.disabled ? "disabled" : ""} ${props.noBorder ? "no-border" : ""} ${props.rightBorder ? "right-border" : ""}`}>
        {props.CreditCardIcon ? (
             <div className="iconStyle" style={{color:props.LeftIconColor}}>{props.CreditCardIcon}</div>
        ) :  props.LeftCreditIcon ? (
            <div className="iconStyle" style={{color:props.LeftIconColor}}>{props.LeftCreditIcon}</div>
        ) :props.LeftIcon ? (
            <div className="iconStyle" style={{color:props.LeftIconColor}}><i className={props.LeftIcon}></i></div>
        ) : null}        
        <div className="title">{props.Title} <small>{props.subTitle}</small></div>
        {props.serviceImage ? (
            <div className="imgesStyle">
                <img src={props.serviceImage} />
            </div>
            
        ) : null}
        <div className="rightIconStyle"><i className={props.RightIcon ? props.RightIcon : `${props.selected ? "fal fa-chevron-left" : "fal fa-chevron-right"}`}></i></div>
    </ServiceItemContainer>
);

ServiceListItem.propTypes = {
    LeftIcon: PropTypes.string,
    LeftIconColor: PropTypes.string,
    Title: PropTypes.string,
    subTitle: PropTypes.string,
    serviceImage : PropTypes.string,
    RightIcon: PropTypes.string,
    CreditCardIcon: PropTypes.node,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    noBorder : PropTypes.bool,
    rightBorder : PropTypes.bool,
};
  
ServiceListItem.defaultProps = {
    title : "",
    subTitle : "",
    selected: false,
    disabled: false,
    noBorder: false,
    rightBorder: false,
};

export default ServiceListItem;
