import React from "react";
import {Row , Col} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from 'src/config/color';

import { withNamespaces } from "react-i18next";

const CardContainer = styled(Row)`
    margin:0;

    .box-price{
        color: #000000;
        display: grid;
        padding: 0 0 10px;
        font-size: 20px;
        line-height: 1;
    }
    
    .box-price.border{
        color: #000000;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(227, 227, 227);
    }
    
    .box-price.border-top{
        color: #000000;
        padding-top: 10px;
        border-top: 1px solid rgb(227, 227, 227);
    }

    .box-price small{
        color: #808080;
        font-size: 16px;
        line-height: 1;
    }

    & .ellipsis{    
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
    }
`;

const TextPrice = styled.span`
    font-size: 28px;
    font-weight: bold;
`;

const ButtonIcon = styled.i`
    color: ${color.SoftGray};
    padding-left: 5px;
    padding-top: 3px;
    font-size: 15px;

    ${props => props.onClick && `
        cursor: pointer;
    `}
`;

const BoxPriceItem = (props) => (
    <CardContainer>
        <Col className={`${props.border ? "box-price border" : "box-price"} ${props.borderTop ? "box-price border-top" : ""}`} sm={12}>
            <div>
                <div style={{float:"left", width: 'calc(100% - 95px)'}}>
                <span className="ellipsis">{props.title}</span>
                { props.qty && (
                    <span style={{paddingLeft:5}} >{props.qty}</span>
                )}
                { props.icon && (
                    <ButtonIcon id="create-shipment__icon_promotion" className={props.icon} onClick={(e) => props.onPressIcon && props.onPressIcon(e)}></ButtonIcon>
                )}
                {props.subPrice ? (<small><br/>{props.subPrice}</small>) : null}
                {props.subTitleNewLine ? (<small><br/>{props.subTitleNewLine}</small>) : null}
                </div>
                <div style={{float:"right", textAlign:"right", position: 'absolute', right: 0, top: 0}}>{props.subTitle || props.subTitleNewLine ? props.price === 0 ? (<TextPrice><b style={{color: "#D90101"}}>ฟรี ไม่มีค่าใช้จ่าย</b></TextPrice>) : (<TextPrice><b>{props.price}</b> {props.t('common:shipmentDetail.baht')}</TextPrice>)  : `${props.price === "ไม่มี" ? props.price : `${props.price}`}`}
                
                {props.subTitle ? (<small style={{marginTop:"-20px",display:"block"}}><br/>{props.subTitle}</small>) : null}</div>
                
            </div>

        </Col>
    </CardContainer>
);

BoxPriceItem.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    price: PropTypes.string,
    border:PropTypes.bool,
    borderTop:PropTypes.bool,
    icon: PropTypes.string,
    qty: PropTypes.node,
};
  
BoxPriceItem.defaultProps = {
    title : "",
    price : "",
    border : false,
    borderTop : false,
    icon: '',
    qty: '',
};

export default withNamespaces()(BoxPriceItem);
