import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #FFFFFF;
  padding: 15px 20px;
`;

const Card = (props) => {
  return (
    <Container {...props}>
      { props.children }
    </Container>
  )
}

export default Card;