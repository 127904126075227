import React from 'react';
import { Query } from 'react-apollo';
import currentUserQuery from './graphql/query/currentUser';
import accountInfoListBusinessQuery from './graphql/query/accountInfoListBusiness';
import { defaults } from 'src/config/client';
import { Redirect } from 'react-router-dom';

class CurrentUserBusiness extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Query query={currentUserQuery} fetchPolicy="network-only">
				{({
					loading: loadingCurrentUser,
					errorCurrentUser,
					data: dataCurrentUser,
					client: clientCurrentUser,
				}) => {
					if (loadingCurrentUser || errorCurrentUser) return null;

					if (!dataCurrentUser.currentUser.result) {
						clientCurrentUser.cache.writeData({
							data: defaults,
						});

						localStorage.removeItem('token');
						return <Redirect to="/" />;
					}

					return (
						<Query
							query={accountInfoListBusinessQuery}
							fetchPolicy="network-only"
						>
							{({ loading, error, data }) => {
								if (loading || error) return null;

								const customerServiceType = this.props.customerServiceType;
								const isCustomerServiceType =
									customerServiceType && customerServiceType.length > 0;

								if (
									!data.accountInfoList.account_business ||
									data.accountInfoList.account_business.business_status !== 2 ||
									(isCustomerServiceType &&
										customerServiceType.filter(item =>
											data.accountInfoList.account_business.customer_service_type.includes(
												item
											)
										).length === 0)
								) {
									return <Redirect to="/register-business" />;
								}

								return this.props.children;
							}}
						</Query>
					);
				}}
			</Query>
		);
	}
}

export default CurrentUserBusiness;
