
import React from "react";
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';


const Container = styled.div`
	font-size: 18px;
	line-height: 1;
`;

class InfoLabor extends React.Component {

    render() {
    return (
        <Container>
				{ 
						this.props.t('common:instancePrice.additional.info.labour')
						.split('\n').map(item => (<div>{ReactHTMLParser(item)}</div>))
				}
        </Container>   
    )
    }
};

export default withNamespaces()(InfoLabor);
