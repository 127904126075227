import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Input, Button, Checkbox, Collapse, Spin, Icon } from 'antd';
import { withNamespaces } from 'react-i18next';
import { Query, graphql, Mutation, compose } from 'react-apollo';
import shipmentListQuery from './graphql/query/shipmentList';
import trackingDropCreateMutation from './graphql/mutation/trackingDropCreate';
import trackingDropDeleteMutation from './graphql/mutation/trackingDropDelete';
import trackingDropListQuery from './graphql/query/trackingDropList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import appUrl from 'src/config/appUrl';
import client from 'src/config/client';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
	FacebookShareButton,
	GooglePlusShareButton,
	TwitterShareButton,
	LineShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	GooglePlusIcon,
	LineIcon,
	EmailIcon,
} from 'react-share';
import { ModalConfirm } from 'src/components';
import { RouteItem } from './components';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const Container = styled.div`
  margin-bottom: 10px;
	line-height: 1;

	& .line {
		background-color: #e3e3e3;
		height: 1px;
		margin: 16px 0px;
	}

	& .box-line {
		line-height: 1;
		border: 1px solid #e3e3e3;
		padding: 6px 10px;
		border-radius: 4px;
		margin: 10px 0px;
	}

	& .box-space-between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	& .btn-red {
		background-color: #d10901;
		color: #ffffff;
		border: none;
		min-width: 80px;

		&:hover,
		&:focus,
		&:active {
			color: #fff;
			background-color: #b70101;
			border-color: #b70101;
		}
  }
  
  & .btn-red-outline {
		color: #d10901;
    border: 1px solid #d10901;
    background-color: #ffffff;

    &:hover,
		&:focus,
		&:active {
			color: #d10901;
      border: 1px solid #d10901;
      background-color: #fafafa;
		}
  }

	& .mt-5 {
		margin-top: 5px;
	}

	& .mt-10 {
		margin-top: 10px;
  }
  
  & .mb-10 {
    margin-bottom: 10px;
  }

	& .list-location {
    display: flex;
    flex-direction: row;

		&:not(:last-child) {
			margin-bottom: 10px;
    }
    
    &__title {
      margin-right: 5px;
      font-weight: bold;
      white-space: nowrap;
    }
	}
`;

const LoadingContainer = styled.div`
	min-height: 220px;
	position: relative;

	& div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const ButtonCopy = styled(Button)`
  & i {
    margin-top: 6px;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;

  & div {
    outline: none;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const RouteItemCustom = styled(RouteItem)`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const TrackingDropList = styled.div`
  & .tracking-drop {
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  
    &__title {
      font-weight: bold;
    }
  }

  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #E3E3E3;
`;

const LineDashed = styled.div`
  height: 1px;
  margin: 16px 0px;
  border-top: 1px dashed #e3e3e3;
`;

const ExpandContainer = styled.div`
  margin-top: 16px;
  border-top: 1px dashed #e3e3e3;
  text-align: right;

  & button {
    background-color: #FFFFFF;
    border: none;
    color: #1587E2;
    line-height: 1.5em;
    outline: none;
  }
`;

class ShareRoute extends React.Component {
	state = {
		shortUrl: '',
		shipmentId: '',
		shipmentNumber: '',
		trackingUrl: '',
		shipmentAddress: [],
    isLoading: false,
    isError: false,
		isOpenCreateLink: false,
		nameLink: '',
    routeSelect: [],
    isLoadingTrackingDropCreate: false,
    isOpenModalDeleteConfirm: false,
    trackingDropId: '',
    trackingDropName: '',
    isExpanded: false,
    itemsToShow: 2,
	};

	componentDidMount() {
		const { shipmentId } = this.props;

		this.setState({
			shipmentId: shipmentId,
		});

		this.fetchDataShipment(shipmentId);
	}

	setLoading(status) {
		this.setState({
			isLoading: status,
		});
	}

	getLinkUrl() {
    const { shipmentNumber } = this.state;
    
    if(this.state.trackingUrl){
      return `${appUrl}/trackingdetail/${this.state.trackingUrl}?shipment=${shipmentNumber}`;
    }
		return `${appUrl}/trackingdetail?shipment=${shipmentNumber}`;
  }
  
	fetchDataShipper = async Id => {
		try {
			this.setLoading(true);
			const { data } = await client.query({
				query: accountInfoListQuery,
				variables: {
					_id: Id,
				},
			});

      const {
        account_info_tracking_url
      } = data.accountInfoList.account_info[0];
      
      this.setState(
				{
					trackingUrl: account_info_tracking_url,
				},
				() => {
					this.setLoading(false);
				},
			);
      
		} catch (error) {
      this.setLoading(false);
      this.setState({ isError: true });
			console.log(error);
		}
	};

	fetchDataShipment = async shipmentId => {
		try {
			this.setLoading(true);
			const { data } = await client.query({
				query: shipmentListQuery,
				variables: {
					_id: shipmentId,
				},
			});

			const {
        shipper,
				shipment_number,
				shipment_address,
			} = data.shipmentList.shipment[0];

			this.setState(
				{
					shipmentNumber: shipment_number,
					shipmentAddress: shipment_address.map(item => {
						if (item.mode === 'roundtrip') {
							return { ...item, mode: 'กลับไปจุดที่ 1' };
						}

						return item;
					}),
				},
				() => {
          this.fetchDataShipper(shipper);
				},
			);
		} catch (error) {
      this.setLoading(false);
      this.setState({ isError: true });
			console.log(error);
		}
	};

	setOpenCreateLink = () => {
		this.setState({
			isOpenCreateLink: true,
			nameLink: '',
			routeSelect: [],
		});
	};

	setCloseCreateLink = () => {
		this.setState({
			isOpenCreateLink: false,
		});
	};

	handleChangeNameLink = value => {
		this.setState({
			nameLink: value,
		});
	};

	async handleCreateLink() {
		try {
      this.setState({ isLoadingTrackingDropCreate: true });
			const { shipmentId, nameLink, routeSelect } = this.state;
			await this.props.trackingDropCreateMutation({
				variables: {
					shipment_id: shipmentId,
					tracking_name: nameLink,
					tracking_drop: routeSelect,
        },
        refetchQueries: () => ['TrackingDropList'],
			});

			this.setState({ isLoadingTrackingDropCreate: false });
			this.setCloseCreateLink();
		} catch (error) {
      this.setState({ isLoadingTrackingDropCreate: false });
			console.log(error);
		}
	}

	handleSelectRoute(checked) {
		this.setState({
			routeSelect: [...checked],
		});
  }
  
  renderRouteTrackingDrop = (shipmentAddress, trackingDrop) => {
    const filterShipmentAddress = shipmentAddress.map((item, index) => {
      return {...item, index: index + 1}
    }).filter((item, index) => _.includes(trackingDrop, index.toString()));
    
    return filterShipmentAddress.map((item, index) => (
      <RouteItemCustom
        key={index}
        titleRoute={`จุดที่ ${item.index} - ${item.mode}:`}
        address={item.address}
        contact={`ติดต่อ: ${item.contact_name}, ${item.contact_tel}`}
        />
    ));
  }

  handleCloseModalDeleteConfirm() {
    this.setState({
      isOpenModalDeleteConfirm: false,
    })
  }

  handleExpanded() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

	render() {
		const {
      shipmentId,
			shipmentNumber,
			shipmentAddress,
			isLoading,
			isOpenCreateLink,
			routeSelect,
      nameLink,
      isLoadingTrackingDropCreate,
      isOpenModalDeleteConfirm,
      trackingDropId,
      trackingDropName,
      isError,
      isExpanded,
      itemsToShow,
		} = this.state;

		return (
			<Container>
				{isLoading && (
					<LoadingContainer>
						<Spin indicator={antIcon} />
					</LoadingContainer>
        )}

        {isError && (
					<LoadingContainer>
						Error
					</LoadingContainer>
        )}

				{!isLoading && !isError && (
					<React.Fragment>
            <Row>
              <Col md={12}>
                <strong>รหัสติดตามงานขนส่ง: {`${shipmentNumber}`}</strong>
              </Col>
              <Col md={12} style={{ marginTop: 20 }}>
                {shipmentAddress.filter((item, index, arr) => {
                  if(isExpanded) {
                    return true;
                  }

                  return (index) < itemsToShow;
                }).map((item, index) => (
                  <RouteItemCustom
                    key={index}
                    titleRoute={`จุดที่ ${index + 1} - ${item.mode}:`}
                    address={item.address}
                    contact={`ติดต่อ: ${item.contact_name}, ${item.contact_tel}`}
                  />
                ))}

                {
                  shipmentAddress.length > 2 && (
                    <ExpandContainer>
                      <button onClick={() => this.handleExpanded()}>
                        { isExpanded ? "ซ่อน" : `แสดงอีก ${shipmentAddress.length - 2} จุด` }
                      </button>
                    </ExpandContainer>
                  )
                }
              </Col>
            </Row>

						<Row style={{ marginTop: 20 }}>
							<Col md={12}>
								<div>ลิงก์ติดตามงานขนส่ง:</div>
                <Row>
                  <Col md={7}>
                    <Input.Group compact>
                      <Input
                        defaultValue={this.getLinkUrl()}
                        style={{ width: '210px' }}
                        value={this.getLinkUrl()}
                      />
                      <CopyToClipboard text={this.getLinkUrl()}>
                        <ButtonCopy>
                          {this.props.t('common:shipmentDetail.buttonCopy')}
                        </ButtonCopy>
                      </CopyToClipboard>
                    </Input.Group>
                  </Col>
                  <Col md={5}>
                    <SocialContainer>
                      <LineShareButton
                        url={this.getLinkUrl()}
                        title={`คุณได้รับการแชร์สถานะงานขนส่งหมายเลข ${
                          this.state.shipmentNumber
                        } ติดตามสถานะงานขนส่งของคุณ คลิก`}
                      >
                        <LineIcon size={32} round={true} />
                      </LineShareButton>
                      <FacebookShareButton
                        url={this.getLinkUrl()}
                        title={`คุณได้รับการแชร์สถานะงานขนส่งหมายเลข ${
                          this.state.shipmentNumber
                        } ติดตามสถานะงานขนส่งของคุณ คลิก`}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>
                      <TwitterShareButton url={this.getLinkUrl()}>
                        <TwitterIcon size={32} round={true} />
                      </TwitterShareButton>
                      <GooglePlusShareButton url={this.getLinkUrl()}>
                        <GooglePlusIcon size={32} round={true} />
                      </GooglePlusShareButton>
                      <EmailShareButton url={this.getLinkUrl()}>
                        <EmailIcon size={32} round={true} />
                      </EmailShareButton>
                    </SocialContainer>
                  </Col>
                </Row>
							</Col>
						</Row>

            {
              shipmentAddress.length > 2 && (
                <React.Fragment>
                  <div className="line" />

                  <Query
                    query={trackingDropListQuery}
                    variables={{
                      shipment_id: shipmentId
                    }}
                  >
                    {
                      ({ data, loading, error }) => {
                        if(loading) return (
                          <LoadingContainer style={{minHeight: 50}}>
                            <Spin indicator={antIcon} />
                          </LoadingContainer>
                        )

                        if(error) return (
                          <LoadingContainer>
                            Error
                          </LoadingContainer>
                        )

                        const {
                          trackingDropList
                        } = data;

                        return (
                          <div>
                            {
                              trackingDropList.map((item, index) => (
                                <TrackingDropList>
                                  <div className="tracking-drop__header">
                                    <div className="tracking-drop__title">{item.tracking_name}</div>
                                    <Button
                                      onClick={() => this.setState({
                                        isOpenModalDeleteConfirm: true,
                                        trackingDropId: item._id,
                                        trackingDropName: item.tracking_name
                                      })}
                                      loading={loading}
                                    >ลบลิงก์</Button>
                                  </div>
                                  
                                  <div className="tracking-drop__body">
                                    { this.renderRouteTrackingDrop(shipmentAddress, item.tracking_drop) }
                                  </div>

                                  <LineDashed />

                                  <div className="mt-10">ลิงก์ติดตามงานขนส่ง:</div>

                                  <Row>
                                    <Col md={7}>
                                      <Input.Group compact>
                                        <Input
                                          defaultValue={item.drop_link}
                                          style={{ width: '210px' }}
                                          value={item.drop_link}
                                        />
                                        <CopyToClipboard text={item.drop_link}>
                                          <ButtonCopy>
                                            {this.props.t('common:shipmentDetail.buttonCopy')}
                                          </ButtonCopy>
                                        </CopyToClipboard>
                                      </Input.Group>
                                    </Col>
                                    <Col md={5}>
                                      <SocialContainer>
                                        <LineShareButton
                                          url={item.drop_link}
                                          title={`คุณได้รับการแชร์สถานะงานขนส่งหมายเลข ${
                                            item.tracking_number
                                          } ติดตามสถานะงานขนส่งของคุณ คลิก`}
                                        >
                                          <LineIcon size={32} round={true} />
                                        </LineShareButton>
                                        <FacebookShareButton
                                          url={item.drop_link}
                                          title={`คุณได้รับการแชร์สถานะงานขนส่งหมายเลข ${
                                            item.tracking_number
                                          } ติดตามสถานะงานขนส่งของคุณ คลิก`}
                                        >
                                          <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={item.drop_link}>
                                          <TwitterIcon size={32} round={true} />
                                        </TwitterShareButton>
                                        <GooglePlusShareButton url={item.drop_link}>
                                          <GooglePlusIcon size={32} round={true} />
                                        </GooglePlusShareButton>
                                        <EmailShareButton url={item.drop_link}>
                                          <EmailIcon size={32} round={true} />
                                        </EmailShareButton>
                                      </SocialContainer>
                                    </Col>
                                  </Row>
                                </TrackingDropList>
                              )) 
                            }
                          </div>
                        );
                      }
                    }
                  </Query>

                  <div>
                    <strong>เลือกแชร์สถานะการขนส่งเฉพาะจุด</strong>
                  </div>
                  {!isOpenCreateLink && (
                    <div className="box-line">
                      <div className="box-space-between">
                        <div>สร้างลิงก์ติดตามงานขนส่งที่คุณต้องการ</div>
                        <Button className="btn-red-outline" onClick={() => this.setOpenCreateLink()}>
                          สร้างลิงก์
                        </Button>
                      </div>
                    </div>
                  )}

                  {isOpenCreateLink && (
                    <div className="box-line">
                      <div className="box-space-between">
                        <div style={{ flex: 1 }}>
                          <Input
                            placeholder="ระบุชื่อติดตามงาน"
                            defaultValue={this.state.nameLink}
                            style={{ width: '90%' }}
                            onChange={e => this.handleChangeNameLink(e.target.value)}
                          />
                        </div>
                        <div>
                          <Button
                            style={{ marginRight: 10 }}
                            className="btn-red"
                            onClick={() => this.handleCreateLink()}
                            disabled={nameLink === '' || routeSelect.length <= 1}
                            loading={isLoadingTrackingDropCreate}
                          >
                            สร้างลิงก์
                          </Button>

                          <Button
                            onClick={() => this.setCloseCreateLink()}
                          >
                            ปิด
                          </Button>
                        </div>
                      </div>

                      <div className="mt-10 mb-10">เลือกจุดที่คุณต้องการสร้าง</div>
                      <Checkbox.Group
                        style={{ lineHeight: 1 }}
                        onChange={checked => this.handleSelectRoute(checked)}
                        defaultValue={routeSelect}
                      >
                        {shipmentAddress.map((item, index) => (
                          <RouteItemCustom
                            key={index}
                            titleRoute={
                              <Checkbox value={index}>
                                <strong>{`จุดที่ ${index + 1} - ${item.mode}`}:</strong>
                              </Checkbox>
                            }
                            address={item.address}
                            contact={`ติดต่อ: ${item.contact_name}, ${item.contact_tel}`}
                          />
                        ))}
                      </Checkbox.Group>
                    </div>
                  )}
                </React.Fragment>
              )
            }
					</React.Fragment>
        )}

        <Mutation
          mutation={trackingDropDeleteMutation}
          variables={{
            _id: trackingDropId,
          }}
          update={(cache, { data }) => {
            this.setState({
              isOpenModalDeleteConfirm: false,
            })
          }}
          refetchQueries={() => ['TrackingDropList']}
        >
          {
            (trackingDropDelete, { loading }) => {
              return (
                <ModalConfirm
                  show={isOpenModalDeleteConfirm}
                  labelButtonConfirm={'ลบ'}
                  labelButtonCancel={'ยกเลิก'}
                  styleColorButtonConfirm="primary"
                  titleHeader={`แจ้งเตือน`}
                  onHide={() => this.handleCloseModalDeleteConfirm()}
                  onPressButtonCancel={() => this.handleCloseModalDeleteConfirm()}
                  onPressButtonConfirm={() => {
                    trackingDropDelete()
                  }}
                >
                  <div style={{lineHeight: 1}}>
                    ต้องการลบลิงก์ <strong>{trackingDropName}</strong> ใช่หรือไม่
                  </div>
                </ModalConfirm>
              )
            }
          }
        </Mutation>
        
			</Container>
		);
	}
}

export default compose(
	graphql(trackingDropCreateMutation, {
		name: 'trackingDropCreateMutation',
	}),
)(withNamespaces()(ShareRoute));
