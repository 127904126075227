import React, { Component } from "react";
import {Button} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './style.css';

const ButtonContainer = styled(Button)`
    border: none;
    color: #333;
    text-align: left;
    padding: 10px 0;
    text-decoration: none;
    font-size: 18px;

    & i {
        font-size: 16px;
        padding-right: 10px;
    }
`;
const ButtonLink = (props) => (
    <ButtonContainer block={props.block} className="btn-link" onClick={props.onClick} disabled={props.disabled}>
        {props.icon ? (<i className={props.icon} style={{color:`${props.iconColor}`}}></i>) : null} <span style={{color: props.textColor}}>{props.text}</span>
    </ButtonContainer>
);

ButtonLink.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    textColor: PropTypes.string,
    block: PropTypes.bool,
};
  
ButtonLink.defaultProps = {
    text : "",
    icon: "",
    iconColor: "#000000",
    textColor: "#000000",
    block: false,
};

export default ButtonLink;
