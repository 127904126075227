import gql from 'graphql-tag';

export default gql`
	query AccessoryList($setting_truck_type_id: ID) {
		accessoryList(setting_truck_type_id: $setting_truck_type_id) {
			_id
      name
			name_lang {
				th
				en
			}
      position
      setting_truck_type_id
		}
	}
`;
