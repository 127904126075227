import React from 'react';
import styled from 'styled-components';
import { HeaderNav } from 'src/app/components';
import Helmet from 'react-helmet';
import { CardFlashDealsItem } from 'src/app/components';
import { Query } from 'react-apollo';
import flashDealsListQuery from './graphql/query/flashDealsList';
import { Skeleton, Empty } from 'antd';
import { withNamespaces } from 'react-i18next';
import s3 from 'src/config/s3.js';
import heightTopHeader from 'src/app/utils/heightTopHeader';

const Container = styled.div``;

const WrapperContainer = styled.div`
	padding-top: calc(100px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		padding-top: 30px;
	}
`;

const ImageIcon = styled.div`
	height: 40px;

	& img {
		height: 100%;
	}

	margin-bottom: 20px;

	@media (max-width: 767px) {
		height: 25px;
	}
`;

const SubTitleContainer = styled.div`
	line-height: 1;
	margin-bottom: 20px;

	& .sub-title {
		&__detail {
			font-size: 24px;
		}

		&__condition {
			font-size: 18px;
			color: #808080;
			margin-top: 5px;
		}
	}

	@media (max-width: 767px) {
		& .sub-title {
			&__detail {
				font-size: 18px;
			}

			&__condition {
				font-size: 16px;
			}
		}
	}
`;

const FlashDealsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& > div {
		flex: 0 31%;
		margin-bottom: 15px;
		margin-left: 1%;
		margin-right: 1%;
	}

	@media (max-width: 767px) {
		flex-direction: column;

		& > div {
			flex: 1;
		}
	}
`;

class FlashDeals extends React.Component {
	render() {
		return (
			<Container>
				<Helmet>
					<title>
						Flash Deals | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง
						รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>
				<HeaderNav />

				<WrapperContainer className="container">
					<ImageIcon>
						<img src={require('src/assets/images/icon-flashdeals.png')} alt="flash deals" />
					</ImageIcon>

					<SubTitleContainer>
						<div className="sub-title__detail">
							โปรโมชั่น ค่าขนส่งราคาพิเศษ สำหรับลูกค้าที่ต้องการใช้บริการขนส่งเต็มคัน
							ในเส้นทางดังนี้
						</div>
						<div className="sub-title__condition">*เงื่อนไขเป็นไปตามบริษัทกำหนด</div>
					</SubTitleContainer>

					<Query query={flashDealsListQuery} fetchPolicy="cache-and-network">
						{({ data, loading, error }) => {
							if (loading) return <Skeleton active />;
							if (error)
								return (
									<div style={{ marginTop: 10 }}>
										{error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Error.'}
									</div>
								);

							const { flash_deals: flashDeals, total_page } = data.flashDealsList;

							return (
								<FlashDealsContainer>
									{flashDeals.length === 0 && (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={<span>ไม่มีรายการ Flash Deals</span>}
											style={{ margin: '80px auto' }}
										/>
									)}

									{flashDeals.map((item, index, arr) => (
										<CardFlashDealsItem
											key={item._id}
											name={item.name_lang[this.props.t('common:lang')]}
											provinceName={`${item.location_start_lang[this.props.t('common:lang')]} - ${
												item.location_end_lang[this.props.t('common:lang')]
											}`}
											price={item.price}
											priceSale={item.price_sale}
											exp={item.exp}
											truckTypeImage={`${s3}/${item.truck_type_pic}`}
											onSelect={() => {
												window.location = '/?flashDealId=' + item._id;
											}}
										/>
									))}
								</FlashDealsContainer>
							);
						}}
					</Query>
				</WrapperContainer>
			</Container>
		);
	}
}

export default withNamespaces()(FlashDeals);
