import gql from 'graphql-tag';

export default gql`
	query search($shipment_id: ID, $shipment_tracking_number: String) {
		trackingList(
			shipment_id: $shipment_id
			shipment_tracking_number: $shipment_tracking_number
		) {
			tracking {
				shipment_tracking_number
				tracking_status
				driver_id
				driver_name
				driver_phone_code
				driver_phone_number
				driver_accept
				driver_accept_date
				user_setting_truck_license_plate
				tracking_detail {
					droppoint
					pick_up_address
					customer_name
					customer_phone
					customer_phone_code

					contact_customer_date
					front_picture
					back_picture
					side_picture
					other_picture {
						path
					}
					take_picture_date
					problem_picture {
						path
					}
					problem_solved
					problem_take_picture_detail
					signature_picture
					signature_date
				}
			}
			total_page
		}
	}
`;
