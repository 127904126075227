import styled from 'styled-components';

export const TextMessageForm = styled.span`
	color: #ff0000;
	font-size: 18px;
	white-space: nowrap;
`;

export const ReceiptDocContainer = styled.div`
	background-color: #ffffff;
	border: 1px solid #e3e3e3;
	border-radius: 6px;
	padding: 10px;
`;

export const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;
`;

export const ImageThumbnail = styled.div`
	display: flex;
	flex-direction: column;
	width: 86px;
	margin-right: 15px;

	& .image {
		width: 86px;
		height: 86px;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000080;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0px;
		}
	}

	.btn-hover-image {
		color: white;
		font-size: 18px;
		cursor: pointer;
		padding: 2px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: normal;

		& i {
			margin-right: 5px;
		}
	}

	& .title-filename {
		font-size: 18px;
    line-height: 1;
    color: #b3b3b3;
    word-break: break-word;
	}
`;

export const ButtonUploadReceiptDoc = styled.button`
	color: #000000;
	border: 1px solid #888888;
	border-radius: 6px;
	background-color: #ffffff;
	font-size: 18px;
	outline: none;
	padding: 3px 10px;

	&:hover {
		background-color: #eeeeee;
	}

	&:disabled {
		background-color: #eeeeee;
	}
`;
