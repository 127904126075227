
import React, { Component } from "react";
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	font-size: 18px;
	line-height: 1;
`;

class PODAddress extends React.Component {

    render() {
    return (
        <Container>
			{this.props.t('common:instancePrice.additional.modalPOD.detail')}<br/><br/>
            <b>{this.props.t('common:instancePrice.additional.modalPOD.condition')}</b> <br/>
			{this.props.t('common:instancePrice.additional.modalPOD.conditionList').split('\n').map(item => (<div>{item}</div>))}
            <hr/>
        </Container>   
    )
    }
};

export default withNamespaces()(PODAddress);
