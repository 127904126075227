import React from 'react';
import { Swipeable } from "react-swipeable";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Footer, Header } from './../../components';
import { Helmet } from 'react-helmet';
import { Cover, TextHeader } from './components';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import bgContact from '../../assets/images/gtxBusiness/Picture/bgContact.png';
import { Row, Col, Button } from 'react-bootstrap';
import landingUrl from 'src/config/landingUrl';
import BannerSponsor from 'src/app/container/BannerSponsor';

const GiztixBusinesstyled = styled.div`
.cover-height {
  position: relative;
  height: calc(100vh - 100px);
}

@media (max-width: 991px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 1023px) {
  .cover-height {
    height: calc(100vh - 80px);
  }
}

.gtxbusiness {
  margin-top: 60px;
  line-height: 1;

  &__section-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      margin-right: 10px;
    }

    @media(max-width: 767px) {
      flex-direction: column;
    }
  }

  &__section-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

   & > div:first-child {
     margin-right: 10px;
   }

    @media(max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  &__header {
    font-weight: bold;
  
    &__small {
      font-size: 30px;
    }
  
    &__medium {
      font-size: 48px;
    }
  
    @media(max-width: 767px) {
      text-align: center;
  
      &__small {
        font-size: 20px;
      }
  
      &__medium {
        font-size: 30px;
      }
    }
  }

  &__box-item {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__left {
      margin-right: 40px;
    }

    &__right {
      flex: 1;
      padding-left: 40px;
    }

    @media(max-width: 767px) {
      flex-direction: column;
      text-align: center;

      &__left {
        margin-right: 0px;
      }
    }

    @media(max-width: 991px) {
      &__right {
        padding-left: 0px;
      }
    }
  }

  &__image-icon {
    width: 100px;
    height: 100px;
  }

  &__text-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;

    @media(max-width: 767px) {
      margin-top: 20px;
    }
  }

  &__text-content {
    font-size: 20px;
    max-width: 350px;
    line-height: 1;
  }

  &__image-big {
    max-width: 400px;
    margin: 40px;
    box-sizing: border-box;

    & img {
      width: 100%;
    }

    @media(max-width: 767px) {
      margin-top: 40px;
      max-width: 250px;

      display: none;
    }
  }

  &__lineHorizontal {
    width: 100%;
    border-bottom: 1px dashed #CECECE;
    margin: 80px 0;
  
    @media(max-width: 767px) {
      margin: 40px 0;
    }
  }

  &__link {
    display: block;
    margin-top: 10px;
  }

  &__symbol {
    width: 10px;
    height: 10px;
    margin-bottom: 30px;
    display: inline-block;
    background-color: #D90101;
    border-radius: 2px;

    @media(max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__download {
    display: flex;
    justify-content: flex-start;
    margin-bottom: -50px;
    margin-top: 50px;

    & a {
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
  
      & i {
        margin-right: 10px;
      }
    }

    @media(max-width: 767px) {
      margin-bottom: 0px;
      margin-top: 30px;
      justify-content: center;
    }

    &--center {
      justify-content: center;
    }
  }

  &__card {
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    min-height: 255px;
    justify-content: center;

    &__text-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  
    &__text-content {
      font-size: 20px;
      line-height: 1;
      max-width: 170px;
    }
  }
}

.slide {
  &-header {
    line-height: 1;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 70px;

    &__title {
      font-size: 48px;
      font-weight: bold;
    }
  
    &__sub-title {
      font-size: 36px;
    }
  
    @media(max-width: 767px) {
      max-width: 320px;
  
      &__title {
        font-size: 20px;
      }
    
      &__sub-title {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  &-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div:nth-child(2) {
      margin: 0px 50px;
      flex: 1;
      overflow: hidden;
      text-align: center;

      & img {
        width: 275px;
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      padding: 20px;
      max-width: 360px;
      line-height: 1;
      align-items: center;
      margin-bottom: 50px;
      border-radius: 6px;
      cursor: pointer;
      transition: .5s;
  
      &__content {
        flex: 1;
  
        &__header {
          font-size: 24px;
          font-weight: bold;
        }
  
        &__detail {
          font-size: 20px;
        }
      }
  
      &__icon {
        width: 100px;
        height: 100px;
        margin-left: 20px;
  
        & img {
          width: 100%;
        }
      }

      &--right {
        text-align: right;
        flex-direction: row-reverse;

        & > .slide-mobile-item__icon {
          margin-left: 0px;
          margin-right: 20px;
        }
      }

      &--opacity {
        opacity: 0.5;
      }
  
      &--active {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
      }
    }

    @media(max-width: 1199px) {
      display: none;
    }

    &-card {
      .slick-slide {
        padding: 10px;
        padding-top: 0;
      }

      @media(min-width: 1200px) {
        display: none;
      }
    }
  }

  @media(max-width: 767px) {
    &-header {
      margin-bottom: 50px;
    }
  }
}

.slide-image-hidden {
  display: none;
}



// section 3
.gtxbusiness {
  &__section-3 {
    margin-bottom: 120px;

    &__header {
      font-weight: bold;
      line-height: 1;
      text-align: center;
      margin-bottom: 90px;
  
      &--1 {
        font-size: 30px;
      }
  
      &--2 {
        font-size: 48px;
      }
  
      &--3 {
        font-size: 20px;
        font-weight: normal;
      }

      @media(max-width: 767px) {
        &--1 {
          font-size: 24px;
        }
    
        &--2 {
          font-size: 36px;
        }
    
        &--3 {
          font-size: 20px;
          font-weight: normal;
        }
      }
    }
  
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media(max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__text-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  
    &__text-content {
      font-size: 20px;
      max-width: 350px;
      line-height: 1;
    }

    &__box-item {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 10px;

      @media(max-width: 767px) {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }

    @media(max-width: 767px) {
      margin-bottom: 50px;

      &__header {
        margin-bottom: 40px;
      }
    }
  }
}`;

const ContactContainer = styled.div`
	background-image: url(${bgContact});
	padding: 80px 0;
	background-repeat: no-repeat;
    background-size: cover;

	& .container {
		background-color: #ffffff;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 1px 2px 8px #000;
		position: relative;

		& .check-button{
			height: 48px;
			width: 260px;
			font-size: 26px;
		}

		@media(max-width: 767px) {
			& .check-button{
				margin-top: 20px;
				width: 100%;
			}
		}
	}
`;

class GiztixBusiness extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isTop: true,
			imageIdx: 0,
			images: [
				{
					value: 0,
					path: require('src/assets/images/gtxBusiness/mockup/phone-01.png'),
				},
				{
					value: 1,
					path: require('src/assets/images/gtxBusiness/mockup/phone-02.png'),
				},
				{
					value: 2,
					path: require('src/assets/images/gtxBusiness/mockup/phone-03.png'),
				},
				{
					value: 3,
					path: require('src/assets/images/gtxBusiness/mockup/phone-04.png'),
				},
				{
					value: 4,
					path: require('src/assets/images/gtxBusiness/mockup/phone-05.png'),
				},
			]
		}

		this._reactSwipeEl = React.createRef();
		this.slideTimer = null;
	}

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 100;
			if (isTop !== this.state.isTop) {
				this.setState({ isTop });
			}
		});

		this.setAutoSlide();
	}
	
	setAutoSlide = () => {
		this.slideTimer = setInterval(() => {
			const { imageIdx, images } = this.state;
			if(imageIdx < (images.length - 1)) {
				this.setPositionSlide(imageIdx + 1);
			} else {
				this.setPositionSlide(0);
			}
		}, 5000);
	}

	setPositionSlide = (index) => {
		clearInterval(this.slideTimer);
		this.setState({ imageIdx: index }, () => this.setAutoSlide());
	}

	render() {
		const { images } = this.state;
		const { imageIdx } = this.state;
		const settingsSlider = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
				},
				{
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
		};

		return (
			<GiztixBusinesstyled>
				<Helmet>
					<title>Business | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย" />
				</Helmet>

				<Header
					bgcolor={this.state.isTop ? 'transparent' : '#00000090'}
          border={false}
          color="white"
				/>

				<div className="cover-height">
					<Cover/>
				</div>

				<div className="container">
					<div className="gtxbusiness">
						<div className="gtxbusiness__section-1">
							<div>
								<Fade bottom delay={300}>
									<div className="gtxbusiness__header">
											<div className="gtxbusiness__header__small">สะดวกสบายกับรูปแบบ</div>
											<div className="gtxbusiness__header__medium">การชำระเงินแบบวางบิล</div>
									</div>
								</Fade>
								
								<Fade bottom delay={300}>
									<div className="gtxbusiness__box-item">
										<div className="gtxbusiness__box-item__left">
											<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/PaymentService.svg')} />
										</div>
										<div className="gtxbusiness__box-item__right">
											<div className="gtxbusiness__text-title">บริการชำระค่าบริการขนส่ง</div>
											<div className="gtxbusiness__text-content">
												จัดการเอกสารบัญชีรายจ่ายสำหรับธุรกิจที่ชำระค่าบริการหลายงานขนส่ง ได้ที่ GIZTIX EXPRESS ที่เดียว
											</div>
										</div>
									</div>
								</Fade>

								<Fade bottom delay={300}>
									<div className="gtxbusiness__box-item">
										<div className="gtxbusiness__box-item__left">
											<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/SavePrice.svg')} />
										</div>
										<div className="gtxbusiness__box-item__right">
											<div className="gtxbusiness__text-title">เครดิตค่าบริการขนส่งสูงสุด 50,000 บาท</div>
											<div className="gtxbusiness__text-content">
												รับวงเงินเครดิตค่าบริการขนส่งสูงสุด 50,000 บาท นาน 30 วันและเพิ่มวงเงินเครดิตได้ทันที

												<div className="gtxbusiness__download">
													<a href="https://docsend.com/view/sqqwc4c8fvmdtdcd" target="blank">
														<i className="fas fa-arrow-alt-to-bottom"></i> ดาวน์โหลดเอกสารสิทธิพิเศษ
													</a>
												</div>
											</div>
										</div>
									</div>
								</Fade>
							</div>
							
							<div>
								<Fade bottom delay={300}>
									<div className="gtxbusiness__image-big">
										<img src={require('src/assets/images/gtxBusiness/Icon/cover1.png')} />
									</div>
								</Fade>
							</div>
						</div>

						<div className="gtxbusiness__lineHorizontal"></div>

						<div className="gtxbusiness__section-2">
							<div>
								<Fade bottom delay={300}>
									<div className="gtxbusiness__image-big">
										<img src={require('src/assets/images/gtxBusiness/Icon/cover2.png')} />
									</div>
								</Fade>
							</div>

							<div>
								<Fade bottom delay={300}>
									<div>
										<div className="gtxbusiness__header">
											<div className="gtxbusiness__header__small">บริการขนส่งที่ดีที่สุดสำหรับองค์กร</div>
											<div className="gtxbusiness__header__medium">ขนส่งคุณภาพราคาประหยัด และปลอดภัย</div>
										</div>

										<div className="gtxbusiness__box-item">
											<div className="gtxbusiness__box-item__left">
												<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/ManageShipment.svg')}  />
											</div>
											<div className="gtxbusiness__box-item__right">
												<div className="gtxbusiness__text-title">จัดการขนส่งสินค้าอย่างมีประสิทธิภาพ</div>
												<div className="gtxbusiness__text-content">
													ส่งสินค้าได้ง่าย และมีประสิทธิภาพมากกว่าเดิม
													ด้วยเทคโนโลยี การขนส่งรูปแบบใหม่ที่ทุกองค์กร
													สามารถใช้ได้ไม่ต้องลงทุน
												</div>
											</div>
										</div>
									</div>
								</Fade>

								<Fade bottom delay={300}>
									<div className="gtxbusiness__box-item">
										<div className="gtxbusiness__box-item__left">
											<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/SaveCost.svg')} />
										</div>
										<div className="gtxbusiness__box-item__right">
											<div className="gtxbusiness__text-title">ประกันคุ้มครองสินค้าทุกการขนส่ง</div>
											<div className="gtxbusiness__text-content">
												GIZTIX EXPRESS มีนโยบายคุ้มครองสินค้าระหว่างการขนส่ง
												ด้วยวงเงินคุ้มครองสูงสุดถึง 1,000,000 บาท 
												ให้ลูกค้ามั่นใจว่า สินค้าของคุณจะถูกดูแลอย่างดี
												<a target="blank" href={`${landingUrl}/insurance`} className="gtxbusiness__link">ดูเพิ่มเติม >></a>
											</div>
										</div>
									</div>
								</Fade>
							</div>
						</div>

						<div className="gtxbusiness__lineHorizontal"></div>
					</div>

					<Fade bottom delay={300}>
						<div className="slide-header">
							<div className="slide-header__title">บริการขนส่งสินค้าที่ได้มากกว่าการส่งสินค้า</div>
							<div className="slide-header__sub-title">ฟีเจอร์การจองรถขนส่งระดับองค์กรที่ทำให้ธุรกิจส่งสินค้าง่ายทุกวัน</div>
						</div>
					</Fade>

					<div className="slide-mobile">
						<div>
							<div onClick={() => this.setPositionSlide(1)} className={`slide-mobile-item ${this.state.imageIdx === 1 ? 'slide-mobile-item--active' : ''} ${this.state.imageIdx !== 0 ? 'slide-mobile-item--opacity' : ''}`}>
								<div className="slide-mobile-item__content">
									<div className="slide-mobile-item__content__header">Tracking</div>
									<div className="slide-mobile-item__content__detail">
										สินค้าอยู่ที่ไหน คุณรู้ ลูกค้าของคุณก็รู้ 
										ติดตามรถขนส่งได้ Real-time
									</div>
								</div>
								<div className="slide-mobile-item__icon">
									<img src={require('src/assets/images/gtxBusiness/Icon/slide/001.png')} alt="PaymentService" />
								</div>
							</div>

							<div onClick={() => this.setPositionSlide(2)} className={`slide-mobile-item ${this.state.imageIdx === 2 ? 'slide-mobile-item--active' : ''} ${this.state.imageIdx !== 0 ? 'slide-mobile-item--opacity' : ''}`}>
								<div className="slide-mobile-item__content">
									<div className="slide-mobile-item__content__header">Multidrop</div>
									<div className="slide-mobile-item__content__detail">
										ส่งสินค้าได้สูงสุด 8 จุดทั่วประเทศไทย 
										ส่งหลายที่ก็จัดเส้นทางได้ 
									</div>
								</div>
								<div className="slide-mobile-item__icon">
									<img src={require('src/assets/images/gtxBusiness/Icon/slide/002.png')} alt="PaymentService" />
								</div>
							</div>
						</div>

						<div>
							<Swipeable
								trackMouse
								preventDefaultTouchmoveEvent
							>
								<div>
									{images.map((item) => {
										return (<img src={item.path} alt="slide" className={`${item.value === imageIdx ? '' : 'slide-image-hidden'}`} />)
									})}
								</div>
							</Swipeable>
						</div>

						<div>
							<div onClick={() => this.setPositionSlide(3)} className={`slide-mobile-item slide-mobile-item--right ${this.state.imageIdx === 3 ? 'slide-mobile-item--active' : ''} ${this.state.imageIdx !== 0 ? 'slide-mobile-item--opacity' : ''}`}>
								<div className="slide-mobile-item__content">
									<div className="slide-mobile-item__content__header">Cargo Insurance</div>
									<div className="slide-mobile-item__content__detail">
										คุ้มครองสินค้าทุกการขนส่ง 
										ไม่ต้องซื้อประกันก็เคลมได้
									</div>
								</div>
								<div className="slide-mobile-item__icon">
									<img src={require('src/assets/images/gtxBusiness/Icon/slide/003.png')} alt="PaymentService" />
								</div>
							</div>

							<div onClick={() => this.setPositionSlide(4)} className={`slide-mobile-item slide-mobile-item--right ${this.state.imageIdx === 4 ? 'slide-mobile-item--active' : ''} ${this.state.imageIdx !== 0 ? 'slide-mobile-item--opacity' : ''} `}>
								<div className="slide-mobile-item__content">
									<div className="slide-mobile-item__content__header">Round trip</div>
									<div className="slide-mobile-item__content__detail">
										จองรถขนส่งไป-กลับเส้นทางเดิมได้
										ในราคาประหยัดสูงสุด 50%
									</div>
								</div>
								<div className="slide-mobile-item__icon">
									<img src={require('src/assets/images/gtxBusiness/Icon/slide/004.png')} alt="PaymentService" />
								</div>
							</div>
						</div>
					</div>

					<div className="slide-mobile-card">
						<Slider {...settingsSlider}>
							<Fade bottom delay={300}>
								<div className="gtxbusiness__card">
									<div>
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/slide/001.png')} />
									</div>
									<div>
										<div className="gtxbusiness__card__text-title">Tracking</div>
										<div className="gtxbusiness__card__text-content">
											สินค้าอยู่ที่ไหน คุณรู้ ลูกค้าของคุณก็รู้
											ติดตามรถขนส่งได้ Real-time
										</div>
									</div>
								</div>
							</Fade>

							<Fade bottom delay={300}>
								<div className="gtxbusiness__card">
									<div>
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/slide/002.png')} />
									</div>
									<div>
										<div className="gtxbusiness__card__text-title">Cargo Insurance</div>
										<div className="gtxbusiness__card__text-content">
											คุ้มครองสินค้าทุกการขนส่ง
											ไม่ต้องซื้อประกันก็เคลมได้
										</div>
									</div>
								</div>
							</Fade>

							<Fade bottom delay={300}>
								<div className="gtxbusiness__card">
									<div>
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/slide/003.png')} />
									</div>
									<div>
										<div className="gtxbusiness__card__text-title">Multidrop</div>
										<div className="gtxbusiness__card__text-content">
											ส่งสินค้าได้สูงสุด 8 จุดทั่วประเทศไทย
											ส่งหลายที่ก็จัดเส้นทางได้ 
										</div>
									</div>
								</div>
							</Fade>

							<Fade bottom delay={300}>
								<div className="gtxbusiness__card">
									<div>
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/slide/004.png')} />
									</div>
									<div>
										<div className="gtxbusiness__card__text-title">Round trip</div>
										<div className="gtxbusiness__card__text-content">
											จองรถขนส่งไป-กลับเส้นทางเดิมได้
											ในราคาประหยัดสูงสุด 50%
										</div>
									</div>
								</div>
							</Fade>
						</Slider>
					</div>

					<Fade bottom delay={300}>
						<div className="gtxbusiness__download gtxbusiness__download--center">
							<a href="https://docsend.com/view/sqqwc4c8fvmdtdcd" target="blank">
								<i className="fas fa-arrow-alt-to-bottom"></i> ดาวน์โหลดเอกสารแนะนำ Giztix Express
							</a>
						</div>
					</Fade>

					<BannerSponsor page="giztixBusiness" style={{marginTop: 80}} />
					
					<div className="gtxbusiness__lineHorizontal"></div>
					
					<div className="gtxbusiness__section-3">
						<Fade bottom delay={300}>
							<div className="gtxbusiness__section-3__header">
								<div className="gtxbusiness__section-3__header--1">สบายใจด้วยการดูแล</div>
								<div className="gtxbusiness__section-3__header--2">ระดับองค์กรตลอด 24 ชม.</div>
								<div className="gtxbusiness__section-3__header--3">ไม่ว่าจะจองรถขนส่งเร่งด่วนขนาดไหน เราพร้อมดูแลคุณทุกการขนส่ง</div>
							</div>
						</Fade>
						
						<Fade bottom delay={300}>	
							<div className="gtxbusiness__section-3__content">
								<div className="gtxbusiness__section-3__box-item">
									<div className="gtxbusiness__section-3__box-item__left">
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/support/001.png')} />
									</div>
									<div className="gtxbusiness__section-3__box-item__right">
										<div className="gtxbusiness__section-3__text-title">มีเจ้าหน้าที่ผู้เชี่ยวชาญ</div>
										<div className="gtxbusiness__section-3__text-content">
											ให้คำปรึกษาและดูแลคุณตลอด 24 ชม.
										</div>
									</div>
								</div>

								<div className="gtxbusiness__section-3__box-item">
									<div className="gtxbusiness__section-3__box-item__left">
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/support/002.png')} />
									</div>
									<div className="gtxbusiness__section-3__box-item__right">
										<div className="gtxbusiness__section-3__text-title">บริการขนส่งสินค้าสำหรับ<br/>องค์กร/ธุรกิจ เต็มรูปแบบ</div>
										<div className="gtxbusiness__section-3__text-content">
											รองรับบริการขนส่งในประเทศ
											นำเข้า ส่งออก และพิธีการศุลกากร
											แบบ One Stop Service
										</div>
									</div>
								</div>

								<div className="gtxbusiness__section-3__box-item">
									<div className="gtxbusiness__section-3__box-item__left">
										<img className="gtxbusiness__image-icon" alt="icon" src={require('src/assets/images/gtxBusiness/Icon/support/003.png')} />
									</div>
									<div className="gtxbusiness__section-3__box-item__right">
										<div className="gtxbusiness__section-3__text-title">ปลอดภัยทุกการขนส่ง</div>
										<div className="gtxbusiness__section-3__text-content">
											การันตีคุณภาพการส่งสินค้า<br/>
											ตลอดการขนส่งจนถึงปลายทาง
										</div>
									</div>
								</div>
							</div>
						</Fade>
					</div>
				</div>

				<ContactContainer>
						<Fade bottom delay={500}>
							<div className="container"> 
								<Row>
									<Col sm={8} xs={12}>
										<TextHeader
											className="title"
											style={{ textAlign:"left"}}
											title="จิซทิกซ์สำหรับลูกค้าองค์กร/ธุรกิจ"
											detail="ตอบสนองความต้องการใช้บริการขนส่งในระดับองค์กร"
										/>
									</Col>
									<Col sm={4} xs={12}>
										<Button bsStyle="primary" className="check-button" onClick={() => window.location = `/register-business`}>
											ลงทะเบียน
										</Button>
									</Col>
								</Row>
							</div>
						</Fade>
				</ContactContainer>

				<Footer />
			</GiztixBusinesstyled>
		)
	}
}

export default GiztixBusiness;