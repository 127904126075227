import React, { Component } from 'react';
import styled from 'styled-components';
import { Card, CardHeader, Skeleton } from 'src/components';
import { graphql, compose, Query } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import _ from 'lodash';
import { withNamespaces } from 'react-i18next';
import { InstancePriceContext } from './../../contexts/InstancePriceContext';
import { Input } from 'antd';
import { ContactItem } from './components';
import accountContactListQuery from './graphql/query/accountContactList';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import updateLocationTempMutationClient from 'src/config/graphqlClient/mutation/updateLocationTemp';
import client from 'src/config/client';

const Container = styled.div`
	width: 100%;
`;

const ContactListContainer = styled.div`
	margin-top: 10px;
`;

const TextMessage = styled.div`
	color: #888888;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
`;

class SelectContact extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			contactNameSearch: '',
		};
	}

	static contextType = InstancePriceContext;

	closePopup() {
		this.context.onClose(['selectContactSaved']);
	}

	searchContactHandler = event => {
		this.setState({ contactNameSearch: event.target.value });
	};

	selectContactHandler = (contact) => {
		const locationTempData = client.readQuery({
			query: locationTempQueryClient,
		});

		client.writeQuery({
			query: locationTempQueryClient,
			data: {
				locationTemp: {
					...locationTempData.locationTemp,

				}
			}
		});

		this.context.setData({
			contactName: contact.contact_name,
			contactTel: contact.contact_tel,
		});

		this.context.onCloseAll();
	}

	render() {
		return (
			<Container>
				<Card
					content={
						<div>
							<CardHeader
								title={'เลือกผู้ติดต่อที่บันทึกไว้'}
								onPressClose={() => this.closePopup()}
								borderCardBottom
							/>

							<Input.Search
								placeholder="ค้นหารายชื่อผู้ติดต่อ"
								onSearch={value => console.log(value)}
								value={this.state.contactNameSearch}
								onChange={event => this.searchContactHandler(event)}
								style={{ marginTop: 20 }}
							/>

							<ContactListContainer>
								<Query
									query={accountContactListQuery}
									variables={{
										contact_name: this.state.contactNameSearch,
									}}
								>
									{({ data, loading, error }) => {
										if (loading)
											return (
												<Skeleton width={220} height={170}>
													<rect
														x="0"
														y="0"
														rx="4"
														ry="4"
														width="143"
														height="13"
													/>
													<rect
														x="0"
														y="24"
														rx="3"
														ry="3"
														width="200"
														height="10"
													/>

													<rect
														x="0"
														y="59"
														rx="4"
														ry="4"
														width="143"
														height="13"
													/>

													<rect
														x="0"
														y="83"
														rx="3"
														ry="3"
														width="200"
														height="10"
													/>

													<rect
														x="0"
														y="118"
														rx="4"
														ry="4"
														width="143"
														height="13"
													/>

													<rect
														x="0"
														y="142"
														rx="3"
														ry="3"
														width="200"
														height="10"
													/>
												</Skeleton>
											);

										if (error) {
											let textError =
												error.graphQLErrors && error.graphQLErrors.length > 0
													? error.graphQLErrors[0].message
													: error.message;
											
											if(textError === 'You must login.') {
												textError = 'เข้าสู่ระบบเพื่อใช้งาน';
											}
											
											return <div>{textError}</div>;
										}

										const {
											account_contact: accountContact,
										} = data.accountContactList;

										return (
											<React.Fragment>
												{accountContact.map((item, index) => (
													<ContactItem
														key={index}
														name={item.contact_name}
														subTitle={item.contact_tel}
														clicked={() => this.selectContactHandler(item)}
													/>
												))}

												{accountContact.length === 0 && (
													<TextMessage>- ไม่มีข้อมูล -</TextMessage>
												)}
											</React.Fragment>
										);
									}}
								</Query>
							</ContactListContainer>
						</div>
					}
				/>
			</Container>
		);
	}
}

export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
)(withNamespaces()(SelectContact));
