import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1;
  height: 50px;
  align-items: center;

  & .list {
    &__icon {
      width: 36px;
      height: 36px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
  
      &--gold {
        color: #ffce00;
      }
  
      &--silver {
        color: #b5b5b5;
      }
  
      &--bronze {
        color: #cc6600;
      }
  
      &--grey {
        color: #b3b3b3;
      }
  
      &--black {
        color: #000000;
      }

      &--1 {
        color: #FFCE00;
      }

      &--2 {
        color: #B5B5B5;
      }

      &--3 {
        color: #CC6600;
      }

      &--4 {
        color: #FFD09A;
      }

      &--5 {
        color: #9B4E00;
      }
    }
  
    &__detail {
      flex: 1;
  
      &__title {
        font-size: 14px;
      }
  
      &__description {
        font-weight: bold;
        font-size: 24px;
        color: ${props => props.colorDescription};
      }
    }
  }
`;

const List = (props) => {
  const {title, ...except} = props;
  
  return (
    <Container {...except}>
      {props.icon && (
        <div className={`list__icon list__icon--${props.iconNameColor}`}>
          <i className={props.icon} />
        </div>
      )}
      
      <div className="list__detail">
        <div className="list__detail__title">{props.title}</div>
        <div className="list__detail__description" color={props.colorDescription}>{props.description}</div>
      </div>
    </Container>
  )
};

List.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconNameColor: PropTypes.string,
  colorDescription: PropTypes.string,
}

List.defaultProps = {
  title: '',
  description: '',
  icon: '',
  iconNameColor: 'black',
  colorDescription: 'black',
}

export default List;