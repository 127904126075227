const modeToText = (mode, language = 'th') => {
  if(language === 'th') {
    if(mode === 'pickup') {
      return 'รับสินค้า';
    } else if(mode === 'delivery') {
      return 'ส่งสินค้า';
    } else if(mode === 'roundtrip') {
      return 'roundtrip';
    } else if(mode === 'importPickup') {
      return 'รับตู้หนัก';
    } else if(mode === 'importDelivery') {
      return 'ส่งสินค้า';
    } else if(mode === 'importReturn') {
      return 'คืนตู้เบา';
    } else if(mode === 'exportPickup') {
      return 'รับตู้เบา';
    } else if(mode === 'exportLoading') {
      return 'ขึ้นสินค้า';
    } else if(mode === 'exportReturn') {
      return 'คืนตู้หนัก';
    } else {
      return mode;
    }
  }

  if(language === 'en') {
    if(mode === 'pickup') {
      return 'Pickup';
    } else if(mode === 'delivery') {
      return 'Delivery';
    } else if(mode === 'roundtrip') {
      return 'RoundTrip';
    } else if(mode === 'importPickup') {
      return 'Pickup';
    } else if(mode === 'importDelivery') {
      return 'Delivery';
    } else if(mode === 'importReturn') {
      return 'Return';
    } else if(mode === 'exportPickup') {
      return 'Pickup';
    } else if(mode === 'exportLoading') {
      return 'Loading';
    } else if(mode === 'exportReturn') {
      return 'Return';
    } else {
      return mode;
    }
  }
}

export default modeToText;