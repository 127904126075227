import gql from 'graphql-tag';

export default gql`
	mutation ShipmentAddressHistoryUpdateDelect(
    $id: ID
    $history: Int
    $favorite: Int
    ) {
		shipmentAddressHistoryUpdateDelect(
      _id: $id
      history: $history
      favorite: $favorite
    ) {
			_id
		}
	}
`;
