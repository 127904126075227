import gql from 'graphql-tag';

export default gql`
	mutation UpdateInstancePrice(
		$setting_truck_id: String
		$setting_truck_name: SettingTruckName
		$setting_truck_type_id: String
		$setting_truck_type_name: SettingTruckTypeName
		$setting_truck_type_pic: String
		$additional: [Additional]
		$creditCard: CreditCard
		$pickupDate: String
		$promotion_name: String
		$paymentType: String
		$remark: String
		$userRef: String
		$document: [Document]
		$roundTrip: String
		$shipmentAddress: [ShipmentAddress]
		$optimizeRoute: Boolean
		$tempShipmentAddress: [TempShipmentAddress]
		$shipmentType: Int
		$shipmentConfirmDetail: [ShipmentConfirmDetail]
		$accessory: [Accessory]
		$flashDealsId: String
		$flashDealsName: String
		$flashDealsSettingTruckId: String
		$flashDealsSettingTruckName: String
		$flashDealsIdSettingTruckTypeId: String
		$flashDealsTruckTypeName: String
		$flashDealsIdTruckTypePic: String
		$flashDealsIdLocationStart: String
		$flashDealsIdLocationEnd: String
		$flashDealsExp: String
		$flashDealsIdPickupStart: String
		$flashDealsIdPickupEnd: String
		$flashDealsPrice: Int
		$flashDealsPriceSale: Int
		$favoriteDriver: [FavoriteDriver]
	) {
		updateInstancePrice(
			setting_truck_id: $setting_truck_id
			setting_truck_name: $setting_truck_name
			setting_truck_type_id: $setting_truck_type_id
			setting_truck_type_name: $setting_truck_type_name
			setting_truck_type_size: $setting_truck_type_size
			setting_truck_type_pic: $setting_truck_type_pic
			additional: $additional
			creditCard: $creditCard
			pickupDate: $pickupDate
			promotion_name: $promotion_name
			paymentType: $paymentType
			remark: $remark
			userRef: $userRef
			document: $document
			roundTrip: $roundTrip
			shipmentAddress: $shipmentAddress
			optimizeRoute: $optimizeRoute
			tempShipmentAddress: $tempShipmentAddress
			shipmentType: $shipmentType
			shipmentConfirmDetail: $shipmentConfirmDetail
			accessory: $accessory
			flashDealsId: $flashDealsId
			flashDealsName: $flashDealsName
			flashDealsSettingTruckId: $flashDealsSettingTruckId
			flashDealsSettingTruckName: $flashDealsSettingTruckName
			flashDealsIdSettingTruckTypeId: $flashDealsIdSettingTruckTypeId
			flashDealsTruckTypeName: $flashDealsTruckTypeName
			flashDealsIdTruckTypePic: $flashDealsIdTruckTypePic
			flashDealsIdLocationStart: $flashDealsIdLocationStart
			flashDealsIdLocationEnd: $flashDealsIdLocationEnd
			flashDealsExp: $flashDealsExp
			flashDealsIdPickupStart: $flashDealsIdPickupStart
			flashDealsIdPickupEnd: $flashDealsIdPickupEnd
			flashDealsPrice: $flashDealsPrice
			flashDealsPriceSale: $flashDealsPriceSale
			favoriteDriver: $favoriteDriver
		) @client
	}
`;
