import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import reportUrl from './../../config/reportUrl';
import urijs from 'urijs';
import firebase from 'firebase';
import firebaseConfig from './../../config/firebaseConfig';


const Container = styled.div`
  // margin-top: 10px;
  background-image: url(${require('src/assets/images/homepage/cover/circle.svg')});
  background-repeat: no-repeat;
  background-position: top left 100px;
  background-size: 200%;
  height: 100vh;
`;


const InnerContainer = styled.div`
  max-width: 600px;
  margin: 50px auto 0;
`;

const Label = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const SpinIcon = styled.div`
  text-align : center;

  & i{
    animation: ${props => props.status == 1 ?  `spin 2s linear infinite` : `none` };
    
    font-size: 50px;
    color: ${props => props.status == 1 ?  `#e0e0e0` : props => props.status == 2 ?  `#8bc34a` : `#D90101` };
  }
  @keyframes spin {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

class Uploadfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        status: 1,
        name: '',
        id: ''
    };

    if (!firebase.apps.length) {
			firebase.initializeApp(firebaseConfig);
		}
  }

  componentDidMount() {
		this.setState({
			name: urijs(window.location.href).query(true).name || '',
			status: urijs(window.location.href).query(true).status || '',
      id: urijs(window.location.href).query(true).id || '',
      error: ''
    });
    this.getReportData();
    this.setTimeoutError();
  }

  setTimeoutError(){
    let timerId = setInterval(() => 
      this.setState({
        status: 3,
        error: "Error: Time out"
      }), 300000);

    if(this.state.status == 2 || this.state.status == 3){
      clearTimeout(timerId);
    }
    
  }

  getReportData = async () => {
		try {
			firebase
				.database()
				.ref(`/optimize_result/${this.state.id}`).limitToLast(1)
				.on("value", async (snapshot) => {
					if (snapshot.numChildren() === 1) {
						snapshot.forEach( child  => {
                const keys = child.key;
                child.forEach(item  => {
                  console.log(this.state.id)
                  console.log(keys)
                  console.log(item.val().status)
                  if(keys == this.state.id ){
                    this.setState({
                        status: item.val().status,
                        error: item.val().error_result,
                    });
                  }
                })
						});
					}
				});
		} catch (error) {
        this.setState({
          status: 3,
          error: error
        });
			console.log(error);
		}
	}

	handleSingleUploadDocument(uploadFile, documentName , event) {
		const {
			target: {
				validity,
				files: [file]
			}
		} = event;

		if(validity) {
			const timestamp = new Date().getTime();
			uploadFile({ variables: {
				file: file,
				path: `upload/admin_upload/${documentName}_${timestamp}`
			}})	
		}
  }

  renderIcon(){
    switch (this.state.status) {
      case 2:
          return <i class="fal fa-check-circle"></i>;
      case 3:
          return <i class="far fa-times-circle"></i>;
      default:
          return <i class="fal fa-circle-notch"></i>;
  }
  }
  
  render() {
    return (
      <Container>
        <Helmet>
          <title>OptimizeRoute | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
          <meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
        </Helmet>
        <InnerContainer>
          <Label>Optimize Route for Shipment</Label>
          <Row style={{marginTop: 10}}>
            <Col sm={12}>
                <SpinIcon status={this.state.status}>
                    {this.renderIcon()}
                    <div>{this.state.status == 1 ? 'Inprogress...' : this.state.status == 2 ? 'Finish' : 'Error' }</div>
              </SpinIcon>
              <div style={{textAlign: 'center',color: '#d90001'}}>
                {this.state.error}
              </div>
            </Col>
          </Row>
          { !this.state.error ? (
            <Row style={{textAlign:"center",marginTop:"20px"}}>
              <Col>
                  <Button disabled={this.state.status !== 2 ? true : false } bsStyle="primary" onClick={() => window.open(reportUrl+"/optimizeTruckDownload?name="+this.state.name, "_self")} ><i class="fal fa-download"></i> Download</Button>
              </Col>
              <Col style={{marginTop:"10px"}}>
                  <Button bsStyle="default" onClick={() => window.open("/uploadfile", "_self")} ><i class="fal fa-angle-left"></i> Upload route</Button>
              </Col>
            </Row>
          ) : (
            <Row style={{textAlign:"center",marginTop:"20px"}}>
              <Col>
                  <Button bsStyle="primary" onClick={() => window.open("/uploadfile", "_self")} ><i class="fal fa-angle-left"></i>  Upload route</Button>
              </Col>
            </Row>
          )}
          
        </InnerContainer>
      </Container>
    )
  }
}

export default Uploadfile;