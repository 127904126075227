import gql from 'graphql-tag';

export default gql`
	mutation UpdateSelectDate(
		$isCompleted: Boolean!
	) {
		updateSelectDate(
			isCompleted: $isCompleted
		) @client
	}
`;
