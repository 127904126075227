import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { Header, BreadcrumbTitel, Footer } from './../../components';
import HeaderHelmet from '../../components/HeaderHelmet';

const Container = styled.div`
	margin: 145px auto 50px;
	text-align: left;
	min-height: 30vh;
`;
const TitelNumber = styled.ul`
	margin-top: 30px;
	margin-bottom: 10px;

	@media (max-width: 768px) {
		padding-left: 0px;
		margin-top: 0px;
	}
`;
const Titel = styled.div`
	margin-bottom: 30px;
	font-size: 36px;

	@media (max-width: 768px) {
		margin-bottom: 20px;
		font-size: 24px;
	}
`;
const TextIndent = styled.p`
	margin-bottom: 15px;
	margin-left: 64px;
	line-height: 1;

	@media (max-width: 768px) {
		margin-left: 20px;
	}
`;

class CookiePolicy extends React.Component {
	render() {
		const menu = [
			{
				active: true,
				link: '/helpcenter',
				title: 'ศูนย์ความช่วยเหลือ >',
			},
			{
				active: true,
				link: '/complain',
				title: 'นโยบายคุ้กกี้ ',
			},
		];

		return (
			<div style={{ backgroundColor: '#ffffff' }}>
				<HeaderHelmet title="Cookie Policy" />
				<Header active={3}></Header>
				<BreadcrumbTitel menu={menu}></BreadcrumbTitel>
				<div>
					<Container className="container">
						<Titel>นโยบายคุ้กกี้</Titel>
						<TextIndent>
							บริษัท จิซทิกซ์ จำกัด (“บริษัท”) ในฐานะผู้พัฒนาและบริหารจัดการ
							www.giztix.com (“เว็บไซต์”) ให้ความเชื่อมั่นแก่ท่านว่า
							ข้อมูลส่วนบุคคลทั้งหมดของท่านเป็นข้อมูลที่ทางบริษัทให้ความสำคัญของสิทธิในความเป็นส่วนตัว
							(privacy right) และสิทธิในข้อมูลส่วนบุคคล (personal data right)
							โดยบริษัทรับประกันจะปกป้องและประมวลผลข้อมูลส่วบบุคคลด้วยมาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสมอย่างดีที่สุด
							และข้อมูลทั้งหมดของท่านจะถูกเก็บรักษาเป็นความลับ
							ในฐานะผู้ใช้บริการ ไม่ว่าในนามส่วนบุคคลหรือในนามบริษัท
							การเข้าถึงเว็บไซต์ ติดต่อ และ/หรือ ใช้บริการของทางบริษัท
							รวมถึงกรณีที่ท่านส่งข้อมูลส่วนบุคคลใดๆของท่าน ให้แก่บริษัท
							หรือให้ความยินยอมใดๆแก่บริษัท
							ภายใต้เงื่อนไขและข้อกำหนดที่ระบุไว้ในนโนยบายข้อมูลส่วนบุคคลฉบับนี้
							บริษัทจะถือว่า
							ท่านตกลงและยอมรับที่จะปฏิบัติตามนโยบายข้อมูลส่วนบุคคลฉบับนี้แล้ว
							ทั้งนี้ นโยบายข้อมูลส่วนบุคคล ฉบับนี้มีจุดประสงค์เพิ่มเติม
							โดยไม่มีผลเปลี่ยนแทนหรือทดแทนการให้ความยินยอมใดๆ
							ซึ่งท่านอาจเคยได้ให้แก่บริษัทในส่วนที่เกี่ยวข้องกับ
							ข้อมูลส่วนบุคคลของท่านกรุณาศึกษานโยบายข้อมูลส่วนบุคคล
							พร้อมกับเงื่อนไขการให้บริการของบริษัทอย่างละเอียด
							หากท่านไม่ตกลงให้ทางบริษัทดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่าน
							ตามที่ระบุไว้นี้ บริษัทขอสงวนสิทธิที่จะปฏิเสธการให้บริการใดๆ
							แก่ท่าน
							เนื่องจากการประมวลผลข้อมูลส่วนบุคคลภายใต้นโยบายฉบับนี้มีความจำเป็นอย่างยิ่งต่อการปฏิบัติหน้าที่
							ในการให้บริการแก่ท่าน
							โดยบริษัทจะปฏิบัติตามนโยบายความเป็นส่วนตัวฉบับนี้
							มีผลใช้บังคับกับเว็บไซต์ แอปพลิเคชั่น การติดต่อ
							และ/หรือการบริการของบริษัทเท่านั้น บริการ หรือเว็บไซต์อื่นๆ
							ที่เชื่อมต่อกับเว็บไซต์ของบริษัท
							ซึ่งเป็นของบุคคลภายนอกและบริษัทไม่มีอำนาจควบคุม
							ซึ่งเป็นส่วนที่ผู้ใช้บริการต้องทำความตกลง และศึกษาเกี่ยวกับ
							นโยบายข้อมูลส่วนบุคคล
							ที่บังคับใช้และประกาศโดยบุคคลภายนอกดังกล่าวแยกต่างหาก
							ทางบริษัทอาจปรับปรุงนโยบายฉบับนี้ตามแต่ละระยะเวลา
							เพื่อให้สอดคล้องกับแนวปฏิบัติและกฎหมายที่เกี่ยวข้อง
							และให้สอดคล้องกับการให้บริการต่างๆ ของบริษัท ทั้งนี้
							บริษัทจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลง
							ด้วยการประกาศนโยบายฉบับปรับปรุงใหม่ผ่านทางเว็บไซต์
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>
									1.
									ลักษณะข้อมูลส่วนบุคคลที่บริษัทประมวลผลในการให้บริการและติดต่อกับท่าน
								</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							การประมวลผลข้อมูลส่วนบุคคล
							ที่ระบุไว้ภายใต้เงื่อนไขนโยบายข้อมูลส่วนบุคคลฉบับนี้ มีความจำเป็น
							สำหรับการดำเนินกระบวนการทางธุรกิจของบริษัท
							โดยเฉพาะส่วนที่เกี่ยวข้องกับการให้บริการโดยตรงแก่ท่าน
							ในฐานะผู้ใช้บริการเว็บไซต์ ไม่ว่าจะเป็นการส่งหนังสือบอกกล่าว
							การติดต่อสื่อสารตอบกลับการขอข้อมูลใบเสนอราคาของท่าน
							การบริหารความสัมพันธ์กับท่านในฐานะลูกค้า การนำเสนอสินค้าใหม่
							หรือการจัดกิจกรรมต่างๆ รวมถึง
							กระบวนการในการวางแผนเกี่ยวกับบริการใหม่ในอนาคตของบริษัท ทั้งนี้
							ข้อมูล ส่วนบุคคลของท่าน ที่บริษัทมีความจำเป็นต้องประมวลผลข้อมูล
							มีดังนี้ ข้อมูลทั่วไปเกี่ยวกับท่านและการติดต่อ อาทิ ชื่อ นามสกุล
							ที่อยู่ สถานที่ติดต่อ เบอร์โทรศัพท์ อีเมล
							ข้อมูลการใช้บริการของท่าน เช่น
							ประวัติการทำธุรกรรมการใช้บริการของท่าน รายละเอียดบริการที่
							ท่านซื้อจากบริษัท ประวัติการทำธุรกรรมอื่นๆ ของท่านผ่านเว็บไซต์
							รวมถึงข้อมูลการชำระเงินที่ท่านชำระให้แก่บริษัท
							ข้อมูลทางเทคนิคในการระบุตัวตน อาทิ หมายเลขรหัสสินค้า (Product
							Serial Numbers) หมาย เลขระบุตำแหน่งคอมพิวเตอร์ (IP Address)
							การตั้งค่าและการเชื่อมต่อบราวเซอร์ของอุปกรณ์ที่ผู้ใช้บริการ
							ใช้เชื่อมต่อกับระบบของบริษัท และอาจรวมถึงการใช้ Cookies ต่างๆ
							เพื่อการติดตามพฤติกรรมการใช้งานของท่าน ข้อมูลอื่นที่ท่านอาจให้
							เกี่ยวกับลักษณะการทำการตลาดที่ท่านต้องการ
							หรือข้อมูลที่ท่านให้เพื่อการเข้าร่วมกิจกรรมส่งเสริมการขาย การสำรวจ
							การแข่งขัน การชิงรางวัล
							หรือกิจกรรมส่งเสริมการขายลักษณะใดๆกับบริษัท
							ข้อมูลส่วนบุคคลอื่นๆที่ท่านอาจให้แก่บริษัทระหว่างการติดต่อสื่อสารเพื่อการให้บริการแก่ท่านหรือ
							ระหว่างการติดต่อเพื่อการให้บริการหลังการขายต่างๆ
							หรือข้อมูลส่วนบุคคลที่ท่านอาจให้ยินยอมแก่บริษัท
							ในการประมวลผลข้อมูลส่วนบุคคลดังกล่าว{' '}
						</TextIndent>
						<TextIndent>
							บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน เมื่อ:
							ท่านติดต่อกับบริษัท (เช่น เมื่อท่านติดต่อเพื่อสอบถามข้อมูล
							ขอใบเสนอราคา ด้วยวิธีการประชุมแบบพบหน้า ทางหน้าเว็บไซต์
							ทางโทรศัพท์ และทางอีเมล) ท่าน ในนามส่วนบุคคล
							หรือในนามของนิติบุคคลใด
							กรอกเข้าในแบบฟอร์มสำหรับการสร้างเอกสารประเภทต่างๆ
							ผ่านทางเว็บไซต์ของบริษัท
							ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อมูลการติดต่อท่าน
							ในฐานะผู้ประมวลผลข้อมูลส่วนบุคคล หรือข้อมูลรายละเอียดอื่นๆ
							ซึ่งอาจมีการกรอกเข้าระบบเพื่อการสร้างเอกสาร ดังกล่าวนั้น
							และข้อมูลอีเมลการติดต่อสำหรับการส่งเอกสารที่เกี่ยวข้องซึ่งสร้างจากเว็บไซต์ของบริษัท
							ให้แก่ท่านในฐานะผู้ใช้บริการ ท่านลงทะเบียนความสนใจ
							และให้ความสนใจสมัครรับข้อมูลข่าวสาร เกี่ยวกับบริการ
							รวมถึงการสื่อสารประชาสัมพันธ์อื่นๆ จากบริษัท (เช่น
							หนังสือพิมพ์ข่าว) ท่านเข้าร่วมการทำแบบสำรวจ การแข่งขัน
							การชิงรางวัล หรือกิจกรรมส่งเสริมการขายอื่นๆ
							ท่านให้ความคิดเห็นเกี่ยวกับการทำงาน และให้บริการของบริษัท (เช่น
							การแสดงความคิดเห็นผ่าน ทางเว็บไซต์ของบริษัทหรือเป็นกระดาษแบบฟอร์ม)
							และ/หรือ ท่านส่งข้อมูลส่วนบุคคลให้แก่บริษัท ด้วยเหตุผลอื่น
							นอกเหนือจากการได้รับข้อมูลส่วนบุคคลโดยตรงจากท่าน
							(ตามที่ระบุไว้ข้างต้น) แล้ว บริษัทอาจได้รับข้อมูล
							ส่วนบุคคลของท่านจากบุคคลอื่น ที่ท่านอาจได้ติดต่อ
							และให้ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคล ของท่านให้แก่บริษัท
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>2. จุดประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							ข้อมูลส่วนบุคคลที่ท่านให้แก่บริษัท จะได้รับการเก็บ
							และใช้เพื่อจุดประสงค์ต่างๆ ซึ่งรวมถึงจุดประสงค์ ดังต่อไปนี้:
							การส่งหนังสือบอกกล่าว
							การติดต่อกลับเพื่อนำเสนอข้อมูลใบเสนอราคาที่ท่านร้องขอ การให้บริการ
							แก่ท่านในการสร้างเอกสารเพื่อการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลให้แก่ท่าน
							รวมถึงการให้บริการฝึกอบรม หรือการให้บริการสนับสนุนท่านผู้ใช้บริการ
							ในลักษณะอื่นที่เกี่ยวข้อง ในกรณีที่ได้รับความยินยอมจากท่าน
							การส่งข้อมูลให้แก่ท่าน ผ่านทางอีเมล หรือช่องทางการติดต่ออื่นๆ
							หรือการติดต่อผ่านทางช่องทางโซเชียลมีเดียใดๆ
							เกี่ยวกับบริการของบริษัท ซึ่งตรงกับความสนใจของท่าน การสร้าง
							และปรับปรุงความสัมพันธ์ทางธุรกิจที่บริษัทมีกับท่าน
							ซึ่งอาจรวมถึงการฝึกอบรมพนักงาน และการควบคุมรับประกันคุณภาพ
							โดยเฉพาะส่วนของพนักงานที่รับผิดชอบประสานงานกับลูกค้าผ่านช่อง
							ทางการติดต่อต่างๆ รวมถึงการวิเคราะห์ สอบสวน
							จัดการและแก้ไขปัญหาต่างๆ ที่เกี่ยวข้องกับการให้ บริการ ทั้งนี้
							บริษัทจะประเมินถึงความจำเป็นในการใช้ข้อมูลเพื่อจุดประสงค์ดังกล่าวเพื่อความจำเป็นเท่านั้น
							การจัดการตอบรับการสื่อสารที่ท่านติดต่อพวกเรา (เช่น
							เพื่อการตอบคำถาม หรือการจัดการข้อร้องเรียนการใช้บริการ
							หรือการให้ความคิดเห็นต่างๆ) การแจ้งให้ท่านทราบเกี่ยวกับ
							การเปลี่ยนแปลงในส่วนของลักษณะรูปแบบการทำงานของบริการ ของบริษัท
							เช่น ข้อมูลการปรับปรุงเวอร์ชั่นของเอกสารต่างๆ
							ซึ่งเป็นบริการของบริษัท การวิเคราะห์ความสนใจของท่าน
							เพื่อใช้ในการที่บริษัทจะปรับปรุงรูปแบบการให้บริการ
							โดยเฉพาะการออกแบบ กระบวนการกลไกการวิเคราะห์และทดสอบต่างๆ
							ให้สามารถตอบสนองความต้องการ และความสนใจของท่าน ได้มากขึ้น
							หรือเพื่อการสร้างความสัมพันธ์ที่ดีขึ้นระหว่างบริษัทและท่าน
							การป้องกันและตรวจสอบการกระทำที่อาจผิดกฎหมายหรือผิดเงื่อนไขการใช้บริการของบริษัท
							เช่น การป้องกัน
							และการปกป้องสิทธิกรณีการละเมิดทรัพย์สินทางปัญญาของบริษัท
							การเข้าถึงหรือเข้าร่วมในการแข่งขัน กิจกรรมส่งเสริมการขายต่างๆ
							ที่บริษัทจัดขึ้น ซึ่งท่านอาจตกลงเข้าร่วม ภายใต้เงื่อนไขที่กำหนดไว้
							การปฏิบัติหน้าที่ตามกฎหมายที่บริษัทอาจมี และต้องปฏิบัติตาม เช่น
							หน้าที่ในการจัดทำบัญชี และ ชำระภาษี
							หรือหน้าที่ภายใต้พระราชบัญญัติเกี่ยวกับคอมพิวเตอร์หรือการให้บริการอิเล็กทรอนิกส์ต่างๆ
							จุดประสงค์อื่นใดที่พิจารณาได้ว่ามีความเกี่ยวข้องกับจุดประสงค์ที่ระบุไว้
						</TextIndent>
						<TextIndent>
							บริษัทมีความจำเป็นต้องประมวลผล และเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน
							เพื่อจุดประสงค์ต่างๆ ที่ระบุ ไว้ข้างต้น
							ตลอดระยะเวลาตราบเท่าที่บริษัทยังมีหน้าที่ตามสัญญาการให้บริการหรือตามกฎหมายต่อท่าน
							และอาจต่อเนื่องไปภายหลังการสิ้นสุดหน้าที่ตามสัญญา
							หากเป็นส่วนที่บริษัทอาจมีความจำเป็นต้องเก็บรวบรวมเพื่อการต่อสู้
							หรือปกป้องสิทธิที่บริษัทอาจมีต่อท่าน
							โดยเฉพาะส่วนของการป้องกันการกระทำผิดกฎหมาย
							เช่นการละเมิดทรัพย์สินทางปัญญาของบริษัท
							หรือการกระทำผิดเงื่อนไขการใช้บริการของบริษัท
							โดยเก็บต่อเนื่องเป็นระยะเวลาที่เหมาะสมและจำเป็นในการดำเนินการดังกล่าว
							และสำหรับการประมวลผล ข้อมูลด้วยความยินยอมที่ท่านได้ให้แก่บริษัท
							บริษัทจะประมวลผลข้อมูลส่วนบุคคลของท่านจนกว่า
							ท่านจะใช้สิทธิในการยกเลิกความยินยอมที่ท่านได้ให้ แก่บริษัท ทั้งนี้
							การที่ท่านยังคงใช้บริการของบริษัทอยู่
							บริษัทจะถือว่าท่านยอมรับนโยบายฉบับนี้เสมอ
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>3. การเปิดเผยข้อมูลส่วนบุคคล</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							โดยหลักการแล้วข้อมูลส่วนบุคคลที่ท่านให้แก่บริษัทจะได้รับการเก็บเป็นความลับ
							แต่ในบางกรณีข้อมูลส่วน
							บุคคลของท่านอาจมีความจำเป็นต้องได้รับการเปิดเผยให้แก่บุคคล ดังนี้
							เพื่อการปฏิบัติหน้าที่ตามสัญญา
							หรือตามกฎหมายที่บริษัทอาจมีให้แก่ท่าน:
							เปิดเผยให้แก่ผู้ให้บริการภายนอกของบริษัท
							(ซึ่งรวมถึงแต่ไม่จำกัดเพียง บริษัทพันธมิตรธุรกิจ)
							ที่ให้การช่วยเหลือสนับสนุนบริษัทในการให้บริการแก่ท่าน เช่น
							ที่ปรึกษา ผู้ให้บริการฝึกอบรม รวมถึง
							ผู้ให้บริการภายนอกที่ให้บริการประเมินการทำงานของเว็บไซต์
							โดยบริษัทจะดำเนินการส่งต่อและเปิดเผยข้อมูลส่วนบุคคลของท่าน
							ตามขอบวัตถุประสงค์ที่ระบุไว้เท่านั้น
							และบนพื้นฐานเท่าที่จำเป็นเท่านั้น
							ข้อมูลต่างๆในการให้บริการของบริษัท
							ซึ่งรวมถึงข้อมูลส่วนบุคคลของผู้ใช้บริการอาจได้รับการเก็บรักษาไว้ใน
							ระบบ Cloud (ซึ่งอาจเป็นหน่วยงานภายในหรือต่างประเทศ) ทั้งนี้
							ในการใช้บริการดังกล่าว บริษัทรับประกัน
							การพิจารณาตั้งค่าการรักษาความมั่นคงปลอดภัยและความลับของข้อมูลส่วนบุคคลของผู้ใช้บริการ
							เป็นระดับ พิเศษด้วยมาตรฐานที่เหมาะสมกับความเสี่ยง
							บริษัทอาจมีความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่าน
							เพื่อความจำเป็นในการปกป้อง และ ต่อสู้สิทธิใดๆของบริษัท
							หรือเพื่อการป้องกันและตรวจสอบลักษณะการกระทำผิดที่เกี่ยวข้องกับการใช้บริการของท่าน
							ซึ่งอาจกระทบสิทธิของบุคคลอื่น
							โดยบริษัทรับประกันจะดำเนินการดังกล่าวเท่าที่จำเป็น
							ด้วยจุดประสงค์ดังกล่าวเท่านั้น ในกรณีที่บริษัทมีหน้าที่ตามกฎหมาย
							หรืออยู่ภายใต้บังคับคำพิพากษา หรือคำสั่งของหน่วยงาน ราชการ
							บริษัทอาจมีความจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการให้แก่
							หน่วยงานดังกล่าว เพื่อเป็นการปฏิบัติตามหน้าที่ที่มีตามกฎหมาย
							โดยบริษัทจะดำเนินการ เพียงเท่าที่จำเป็นตามหน้าที่ดังกล่าวเท่านั้น
							หน่วยงานอื่นซึ่งท่านเคยได้ให้ความยินยอมโดยชัดแจ้งให้เราสามารถเปิดเผยข้อมูลดังกล่าวได้
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>
									4. คุ้กกี้ (Cookies) ที่บริษัทใช้ในการให้บริการแก่ท่าน
								</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							Cookies คือ text files ที่อยู่ใน Browser ของผู้ใช้บริการ
							ใช้เพื่อจัดเก็บรายละเอียดข้อมูล บันทึกการใช้งาน อินเตอร์เน็ต
							หรือพฤติกรรมการเยี่ยมชมเว็บไซต์ของผู้ใช้บริการ ทั้งนี้
							เพื่อรับประกันประสิทธิภาพในการทำงานให้บริการของเว็บไซต์แก่ผู้ใช้บริการ
							บริษัทมีความจำเป็นต้องใช้คุกกี้หลายประเภทเพื่อจุดประสงค์ต่างกัน
							โดย แบ่งออกเป็น 2 ประเภท ต่อไปนี้ Strictly Necessary Cookies
							เป็นคุกกี้ประเภทที่มีความจำเป็นอย่างมากต่อการทำงานของเว็บไซต์
							และการให้บริการแก่ผู้ใช้งาน
							ในการเข้าถึงข้อมูลได้อย่างทั่วถึงและปลอดภัย Other Cookies:
							ซึ่งอาจรวมถึงคุกกี้ประเภท Functionality Cookies
							ที่ทำหน้าที่จดจำสิ่งที่ผู้ใช้บริการเลือกหรือตั้งค่าบนแพลตฟอร์ม
							เช่น ชื่อบัญชีผู้ใช้ ภาษา ฟ้อนต์ และรูปแบบแพลตฟอร์ม
							เพื่อการนำเสนอข้อมูล ที่ตรงความต้องการเฉพาะบุคคล
							ให้แก่ผู้ใช้บริการได้มากขึ้น ตามการตั้งค่าต่างที่เลือกไว้ หรือ
							คุกกี้ประเภท Performance Cookies
							ที่ทำหน้าที่ประเมินประสิทธิภาพในการทำงานแต่ละส่วนของ เว็บไซต์
							และคุกกี้ประเภท Advertising Cookies
							ที่ทำหน้าที่ในการจดจำสิ่งที่ท่านเคยเยี่ยมชม รวมถึง
							ลักษณะการใช้เว็บไซต์ ของผู้ใช้บริการ
							เพื่อนำเสนอบริการที่เกี่ยวข้องและตรงกับความสนใจของผู้ใช้บริการ
						</TextIndent>
						<TextIndent>
							สำหรับคุกกี้ประเภท Strictly Necessary Cookies นั้น
							บริษัทขอชี้แจงให้ท่านผู้ใช้บริการทราบว่า
							ทางบริษัทมีความจำเป็นในการใช้คุกกี้ดังกล่าว
							เพื่อการให้บริการของเว็บไซต์ได้อย่างเต็มประสิทธิภาพ หาก
							บริษัทไม่ใช้คุกกี้ประเภทดังกล่าว
							บริษัทย่อมไม่สามารถให้บริการแก่ท่านผู้ใช้บริการได้
							และในส่วนของคุกกี้ ประเภทอื่นนั้น
							หากได้รับความยินยอมจากท่านในการอนุญาตให้บริษัทใช้คุกกี้ดังกล่าว
							บริษัทจะใช้คุกกี้ ดังกล่าวทั้งหมด เพื่อจุดประสงค์
							เฉพาะที่ได้ระบุไว้สำหรับแต่ละประเภทคุกกี้
						</TextIndent>
						<TextIndent>
							แม้ว่า การใช้คุกกี้จะมีประโยชน์ในการเสริมประสิทธิภาพในการให้บริการ
							และการทำงานของเว็บไซต์แก่ ท่านผู้ใช้บริการ
							แต่หากผู้ใช้บริการต้องการ ท่านสามารถลบการตั้งค่าคุกกี้บน Browser
							ของตนเองได้ อย่างไรก็ตาม ผู้ใช้บริการต้องรับทราบว่า
							การดำเนินการดังกล่าว อาจส่งผลกระทบต่อประสิทธิภาพ
							ในการทำงานให้บริการของเว็บไซต์ได้ในบางส่วน
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>5. การทำการตลาดทางตรง</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							หากท่านได้ให้ความยินยอมแก่บริษัทแล้ว
							บริษัทอาจใช้ข้อมูลส่วนบุคคลของท่าน เพื่อให้ข้อมูลเกี่ยวกับ
							สินค้าและ/หรือบริการอื่นๆของบริษัท และ/หรือของบริษัทในเครือ
							หรือพันธมิตรทางธุรกิจของบริษัท ซึ่งอาจเป็นที่น่าสนใจ
							หรือเป็นประโยชน์แก่ท่านได้ เว้นแต่
							ท่านใช้สิทธิในการถอนความยินยอมดังกล่าว
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>
									6.
									ประกันการดำเนินมาตรการรักษาความมั่นคงปลอดภัยในข้อมูลที่เหมาะสม
								</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							บริษัทรับประกันจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
							เพื่อป้องกัน การเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข
							หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ นอกจากนี้
							บริษัทได้กำหนดแนวปฏิบัติภายใน
							เพื่อกำหนดสิทธิในการเข้าถึงหรือการใช้ข้อมูลส่วนบุคคล
							ของเจ้าของข้อมูลส่วนบุคคล เพื่อรักษาความลับและความปลอดภัยของข้อมูล
							ทั้งนี้ บริษัทจะจัดให้มีการ ทบทวนมาตรการดังกล่าวเป็นระยะ
							เพื่อความเหมาะสมตามมาตรฐานในอุตสาหกรรม
							และโดยสอดคล้องกับกฎหมายที่เกี่ยวข้อง
						</TextIndent>
						<TitelNumber>
							<p>
								<strong>7. สิทธิของเจ้าของข้อมูล</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							บริษัทรับทราบ และเคารพสิทธิตามกฎหมายของผู้ใช้บริการ
							ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่าน
							ที่อยู่ในการควบคุมของบริษัทได้
							ตามเงื่อนไขกำหนดสิทธิที่ระบุไว้ในกฎหมาย ดังนี้ สิทธิขอเข้าถึง
							และขอรับสำเนาข้อมูลส่วนบุคคล
							รวมถึงสิทธิในการขอแก้ไขข้อมูลส่วนบุคคลให้เป็นปัจจุบัน และถูกต้อง
							สิทธิขอรับข้อมูลส่วนบุคคล
							ในกรณีที่บริษัททำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่าน
							หรือใช้งาน
							โดยทั่วไปด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ รวมถึง
							สิทธิขอให้ส่งหรือโอนข้อมูลรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
							สิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคล สิทธิขอให้ลบหรือทำลาย
							หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้
							เมื่อข้อมูลนั้นหมดความจำเป็นหรือเมื่อมีการถอนความยินยอม
							สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลได้
							ในกรณีเมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบ หรือ
							เมื่อข้อมูลดังกล่าวหมดความจำเป็น สิทธิถอนความยินยอม
							ในการประมวลผลข้อมูลที่ผู้ใช้บริการเคยให้ไว้ตามวัตถุประสงค์ที่ระบุไว้
							ผู้ใช้บริการสามารถติดต่อมายังบริษัท
							เพื่อดำเนินการขอใช้สิทธิข้างต้นได้
							ตามรายละเอียดการติดต่อที่บริษัทได้กำหนดไว้
							โดยบริษัทจะพิจารณาและแจ้งผลการพิจารณาคำร้องของท่านให้ทราบภายใน 30
							วันนับแต่วันที่ได้รับ คำร้องขอดังกล่าว
						</TextIndent>

						<TitelNumber>
							<p>
								<strong>ช่องทางการติดต่อ</strong>
							</p>
						</TitelNumber>
						<TextIndent>
							รายละเอียดผู้ควบคุมข้อมูลชื่อ: บริษัท จิซทิกซ์ จำกัด
						</TextIndent>
						<TextIndent>
							จำกัดสถานที่ติดต่อ: 777 อาคาร ดับบลิวเอชเอ ทาวเวอร์ ชั้น 15
							หมู่ที่ 13 ถนนเทพรัตน (บางนา-ตราด) กม.7 ตำบลบางแก้ว อำเภอบางพลี
							จังหวัดสมุทรปราการ 10540
						</TextIndent>
						<TextIndent>ช่องทางการติดต่อ: 02-026-6895</TextIndent>
						<TextIndent>อีเมล: service@giztix.com</TextIndent>
					</Container>
					<Footer />
				</div>
			</div>
		);
	}
}

export default withNamespaces()(CookiePolicy);
