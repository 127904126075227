import React from 'react';
import styled from 'styled-components';
import { FormGroup, InputGroup, Button, FormControl } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';

const FormGroupSearch = styled(FormGroup)`
	width: 320px;
	margin: 5px 10px;
`;

const ButtonSearch = styled(Button)`
    width: 90px;
`;

class SearchTracking extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleChange = this.handleChange.bind(this);

		this.state = {
			value: '',
			autoFocus: false,
		};
	}

	handleChange(e) {
		this.setState({ value: e.target.value });
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			if (this.state.value !== '' && this.state.value !== undefined) {
				window.location.href = '/trackingdetail?shipment=' + this.state.value;
			} else {
				alert(this.props.t('common:header.pleaseFillShipmentId'));
			}
		}
	}

	searchTracking() {
		if (this.state.value !== '' && this.state.value !== undefined) {
			window.location.href = '/trackingdetail?shipment=' + this.state.value;
		} else {
			alert(this.props.t('common:header.pleaseFillShipmentId'));
		}
	}

	render() {
		return (
			<FormGroupSearch>
				<InputGroup>
					<FormControl
						type="text"
						autoFocus
						value={this.state.value}
						placeholder={this.props.t('common:header.fillShipmentId')}
						onChange={event => this.handleChange(event)}
						onKeyPress={event => this.handleKeyPress(event)}
					/>
					<InputGroup.Button>
						<ButtonSearch
							bsStyle="primary"
							onClick={() => this.searchTracking()}
						>
							{this.props.t('common:header.tracking')}
						</ButtonSearch>
					</InputGroup.Button>
				</InputGroup>
			</FormGroupSearch>
		);
	}
}

export default withNamespaces()(SearchTracking);
