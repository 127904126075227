import gql from 'graphql-tag';

export default gql`
  query DriverUserList(
    $account_info_id: ID
  ) {
    driverUserList (
      account_info_id: $account_info_id
    ) {
      driver_user {
        _id
        account_info_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_enable
      }
      total_page
    }

    userSettingTruckList {
      user_setting_truck {
        _id
        user_setting_truck_license_plate
      }
    }
  }
`;