import gql from 'graphql-tag';

export default gql`
	query search(
		$page_number: Int
		$shipment_status: Int
		$shipment_number: String
		$create_date: String
		$order_by: String
		$order_type: String
		$pick_up_date: String
		$shipment_user_ref: String
	) {
		shipmentList(
			page_number: $page_number
			show_data: 30
			order_by: $order_by
			order_type: $order_type
			shipment_status: $shipment_status
			shipment_number: $shipment_number
			create_date: $create_date
			pick_up_date: $pick_up_date
			shipment_user_ref: $shipment_user_ref
		) {
			shipment {
				_id
				shipment_type
				round_trip
				transporter_detail
				shipment_status
				shipment_number
				shipper
				shipper_detail
				account_business_id
				account_user_name
				account_user_display_name
				user_setting_truck_id
				driver_user_id
				shipment_tracking_number
				distance
				paid_type
				paid_status
				create_date
				last_update
				trackStatus
				user_setting_truck_license_plate
				user_setting_truck_province
				truck_type_name {
					th
				}
				setting_truck_name {
					th
				}
				pick_up_date
				shipment_address {
					mode
					address
					subdictrict
					province
					postcode
					contact_name
					contact_tel
					paid_by_cod
				}
				shipment_other_detail {
					remark
					user_ref
				}
			}
			total_page
		}
	}
`;
