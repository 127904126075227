import gql from 'graphql-tag';

export default gql`
	mutation ShipmentsUpdate(
		$id: ID!
		$shipmentRefund: shipment_refund_input
		$shipmentFines: shipment_fines_input
		$cancelComment: String
		$shipmentStatus: Int
	) {
		shipmentsUpdate(
			_id: $id
			shipment_refund: $shipmentRefund
			shipment_fines: $shipmentFines
			cancel_comment: $cancelComment
			shipment_status: $shipmentStatus
		) {
			_id
		}
	}
`;
