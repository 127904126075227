import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Header } from './../../../../components';
import {
	Card,
	BlankSpace,
	CardHeader,
	BoxServiceItem,
	CreditCardIcon,
	BoxPriceItem,
	ModalAlert,
	Skeleton,
	PDFPreview,
	ModalConfirm,
} from 'src/components';
import { Row, Col, Button, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import detectLanguageData from './../../../../function/detectLanguageData';
import modeToText from './../../../../function/modeToText';
import moment from 'moment';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import updateInstancePriceStepMutationClient from 'src/config/graphqlClient/mutation/updateInstancePriceStep';
import updateLocationTempMutationClient from 'src/config/graphqlClient/mutation/updateLocationTemp';
import styled from 'styled-components';
import { RouteContact, PaymentChannel, ShipmentDetailList } from './../../components';
import { InstancePriceContext } from '../../../../contexts/InstancePriceContext';

const RouteContactContainerList = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	flex: 1;
`;

const RouteContactStyle = styled(RouteContact)`
	&:not(:last-child)::after {
		content: '';
		width: 1px;
		height: 100%;
		position: absolute;
		border-left: 2px dotted #b3b3b3;
		margin-top: 20px;
		left: 5px;
		top: 0px;
	}

	&:last-child {
		margin-bottom: 0px;
	}
`;

class ShipmentAddress extends React.Component {
	static contextType = InstancePriceContext;

	renderFormatDatetime(datetime, index, type) {
		const format = index === 0 && type !== 1 ? 'DD/MM/YYYY' : 'DD/MM/YYYY - HH:mm';
		return datetime ? moment(datetime, 'YYYY-MM-DD HH:mm').format(format) : '';
	}

	getTitleRemark(mode) {
		if (mode === 'importPickup' || mode === 'exportPickup') {
			return 'Pickup remark';
		} else if (mode === 'importDelivery') {
			return 'Delivery remark';
		} else if (mode === 'importReturn' || mode === 'exportReturn') {
			return 'Return remark';
		} else if (mode === 'exportLoading') {
			return 'Loading remark';
		} else {
			return this.props.t('common:instancePrice.shipmentConfirm.contact.detail.titleRemark');
		}
	}

	getTitlePickupDate(mode) {
		let text = '';
		if (mode === 'importPickup' || mode === 'exportPickup') {
			text += this.props.t('common:instancePrice.shipmentConfirm.contact.date');
		} else {
			text += this.props.t('common:instancePrice.shipmentConfirm.contact.dateTime');
		}

		return text + modeToText(mode, this.props.t('common:lang'));
	}

	render() {
		return (
			<React.Fragment>
				<Mutation mutation={updateInstancePriceStepMutationClient}>
					{updateInstancePriceStep => {
						return (
							<Query query={instancePriceStepQueryClient}>
								{instancePriceStepData => {
									return (
										<Query query={instancePriceQueryClient}>
											{instancePriceData => {
												return (
													<Card
														content={
															<div id="confirm-shipment-route">
																<Header
																	title={
																		instancePriceData.data.instancePrice.shipmentType === 1
																			? this.props.t(
																					'common:instancePrice.shipmentConfirm.titleAddress.domestic'
																			  )
																			: instancePriceData.data.instancePrice.shipmentType === 2
																			? this.props.t(
																					'common:instancePrice.shipmentConfirm.titleAddress.import'
																			  )
																			: instancePriceData.data.instancePrice.shipmentType === 3
																			? this.props.t(
																					'common:instancePrice.shipmentConfirm.titleAddress.export'
																			  )
																			: ''
																	}
																	style={{ marginBottom: 20 }}
																/>
																<RouteContactContainerList>
																	{instancePriceData.data.instancePrice.shipmentAddress.map(
																		(item, index, arr) => (
																			<RouteContactStyle
																				key={index}
																				className="confirm-shipment-route__list"
																				pinColor={
																					item.mode === 'pickup' ||
																					item.mode === 'importPickup' ||
																					item.mode === 'exportPickup'
																						? '#000000'
																						: '#DB1F26'
																				}
																				label={`${this.props.t(
																					'common:instancePrice.shipmentConfirm.point'
																				)} ${index + 1} - ${modeToText(
																					item.mode,
																					this.props.t('common:lang')
																				)}`}
																				location={item.address}
																				onPressEdit={() => {
																					if (
																						index !== 0 &&
																						arr[index - 1].pickupDate === '' &&
																						instancePriceData.data.instancePrice.shipmentType !== 1
																					) {
																						const text = `${this.props.t(
																							'common:instancePrice.modal.contactRequiredPickupDate.content'
																						)} ${index} - ${modeToText(
																							arr[index - 1].mode,
																							this.props.t('common:lang')
																						)}`;
																						this.setState({
																							isOpenModalAlertContactDatetime: true,
																							textModalAlertContactDatetime: text,
																						});
																						return;
																					}

																					this.props
																						.locationTempMutation({
																							variables: {
																								address: undefined,
																								addressDefault: undefined,
																								latitude: undefined,
																								longitude: undefined,
																								latitudeCenter: undefined,
																								longitudeCenter: undefined,
																								isFitBounds: undefined,
																								isSelected: undefined,
																								shipmentAddressIndex: index,
																								type: item.mode,
																								datetime: item.pickupDate,
																							},
																						})
																						.then(() => {
																							updateInstancePriceStep({
																								variables: {
																									contact: false,
																									selectDateTimeContact: false,
																									selectRemark: false,
																								},
																							}).then(() => {
																								this.context.onCloseAll();
																								updateInstancePriceStep({
																									variables: {
																										contact: true,
																										selectDateTimeContact: false,
																										selectRemark: false,
																									},
																								});
																							});
																						});
																				}}
																				contactName={item.contactName}
																				contactPhoneNumber={item.phoneNumber}
																				contactRemark={
																					item.remark
																						? item.remark
																						: this.props.t(
																								'common:instancePrice.shipmentConfirm.contact.remarkNotApplicable'
																						  )
																				}
																				titleContactRemark={this.getTitleRemark(item.mode)}
																				contactDatetime={this.renderFormatDatetime(
																					item.pickupDate,
																					index,
																					instancePriceData.data.instancePrice.shipmentType
																				)}
																				titleContactDatetime={this.getTitlePickupDate(item.mode)}
																				shipmentType={
																					instancePriceData.data.instancePrice.shipmentType
																				}
																			/>
																		)
																	)}

																	{instancePriceData.data.instancePrice.roundTrip === 1 && (
																		<RouteContactStyle
																			className="confirm-shipment-route__list"
																			pinColor="#DB1F26"
																			label={`${this.props.t(
																				'common:instancePrice.shipmentConfirm.backToPoint'
																			)} 1 :`}
																			location={
																				instancePriceData.data.instancePrice.shipmentAddress[0]
																					.address
																			}
																			contactName={
																				instancePriceData.data.instancePrice.shipmentAddress[0]
																					.contactName
																			}
																			contactPhoneNumber={
																				instancePriceData.data.instancePrice.shipmentAddress[0]
																					.phoneNumber
																			}
																			contactRemark={
																				instancePriceData.data.instancePrice.shipmentAddress[0]
																					.remark
																					? instancePriceData.data.instancePrice.shipmentAddress[0]
																							.remark
																					: this.props.t(
																							'common:instancePrice.shipmentConfirm.contact.remarkNotApplicable'
																					  )
																			}
																			shipmentType={
																				instancePriceData.data.instancePrice.shipmentType
																			}
																		/>
																	)}
																</RouteContactContainerList>
															</div>
														}
													/>
												);
											}}
										</Query>
									);
								}}
							</Query>
						);
					}}
				</Mutation>
			</React.Fragment>
		);
	}
}

export default compose(
	graphql(updateLocationTempMutationClient, {
		name: 'locationTempMutation',
	})
)(withNamespaces()(ShipmentAddress));
