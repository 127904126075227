import React, { Component } from 'react';
import styled from 'styled-components';
import { HeaderNav, SubHeaderNav } from 'src/app/components';
import { ModalConfirm } from 'src/components';
import Helmet from 'react-helmet';
import { Query, Mutation } from 'react-apollo';
import { withNamespaces } from 'react-i18next';
import s3 from 'src/config/s3.js';
import { FormControl } from 'react-bootstrap';
import { Tabs, Avatar } from '../../components';
import classes from './FavoriteDriver.module.css';
import favoriteAndBlacklistDriverListQuery from './graphql/query/favoriteAndBlacklistDriverList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import client from 'src/config/client';
import driverfavoriteAndBlacklistDeleteMutation from './graphql/mutation/driverfavoriteAndBlacklistDelete';
import heightTopHeader from 'src/app/utils/heightTopHeader';
import { Menu } from 'src/components';

const Container = styled.div``;
const WrapperContainer = styled.div`
	padding-top: calc(100px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		padding-top: 30px;
	}
`;

const ContentContainer = styled.div`
	padding: 20px;
`;

const SubTitleContainer = styled.div`
	margin-bottom: 20px;

	& .header {
		&__title {
			font-size: 30px;
			font-weight: bold;
		}

		&__sub-title {
			font-size: 20px;
			color: #808080;
		}
	}

	@media (max-width: 767px) {
		& .header {
			&__title {
				font-size: 26px;
			}

			&__sub-title {
				font-size: 18px;
			}
		}
	}
`;

const SubHeaderNavStyle = styled(SubHeaderNav)`
	position: fixed;
	top: calc(60px + ${heightTopHeader}px);

	@media (max-width: 767px) {
		position: relative;
		top: 0;
	}
`;

class FavoriteDriver extends Component {
	state = {
		selectTab: 'favorite',
		settingTruckType: [],
		selectSettingTruckService: '',
		inputDriverName: '',
		driverId: '',
		driverName: '',
		isOpenModalDeleteDriver: false,
	};

	componentDidMount() {
		this.fetchDataSettingTruck();
	}

	fetchDataSettingTruck = async () => {
		const { data } = await client.query({
			query: settingTruckListQuery,
			variables: {
				shipment_type: 1,
			},
		});

		const { settingTruckList } = data;

		const truckList = settingTruckList.reduce((truckList, item) => {
			return [
				...truckList,
				...item.setting_truck_type
					.filter(item => item.truck_type_enable === 1)
					.map(settingTruckType => ({
						settingTruckTypeId: settingTruckType._id,
						name: {
							th: `${item.setting_truck_name.th} - ${settingTruckType.truck_type_name.th}`,
							en: `${item.setting_truck_name.en} - ${settingTruckType.truck_type_name.en}`,
						},
					})),
			];
		}, []);

		this.setState({ settingTruckType: truckList });
	};

	deleteDriverHandler = (driverId, driverName) => {
		this.setState({
			isOpenModalDeleteDriver: true,
			driverId: driverId,
			driverName: driverName,
		});
	};

	render() {
		const menuTabs = [
			{
				icon: 'fal fa-heart-circle',
				title: 'พนักงานขับรถคนโปรด',
				active: this.state.selectTab === 'favorite',
				clicked: () => this.setState({ selectTab: 'favorite' }),
			},
			{
				icon: 'fal fa-times-octagon',
				title: 'พนักงานขับรถที่แบน',
				active: this.state.selectTab === 'blacklist',
				clicked: () => this.setState({ selectTab: 'blacklist' }),
			},
		];

		return (
			<Container>
				<Helmet>
					<title>
						พนักงานขับรถคนโปรด | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง
						รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
					</title>
					<meta
						name="description"
						content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
					/>
				</Helmet>

				<HeaderNav full />
				<Menu>
					<WrapperContainer>
						<ContentContainer>
							<SubTitleContainer>
								<div className="header__title">
									พนักงานขับรถคนโปรด / พนักงานขับรถที่แบน
								</div>
								<div className="header__sub-title">
									สำหรับการจัดการพนักงานขับรถคนโปรด / พนักงานขับรถที่แบน
								</div>
							</SubTitleContainer>

							<Tabs menu={menuTabs} />

							<table class={classes.table}>
								<thead>
									<tr>
										<th className={classes.column1}>เครื่องมือ</th>
										<th className={classes.column2}>ชื่อพนักงานขับรถ</th>
										<th className={classes.column3}>ประเภทรถ</th>
										<th className={classes.column4}></th>
									</tr>
									<tr>
										<th></th>
										<th>
											<FormControl
												type="text"
												value={this.state.inputDriverName}
												placeholder="ค้นหา"
												onChange={event =>
													this.setState({ inputDriverName: event.target.value })
												}
												className={classes.selectBox}
											/>
										</th>
										<th>
											<FormControl
												value={this.state.selectSettingTruckService}
												onChange={event =>
													this.setState({
														selectSettingTruckService: event.target.value,
													})
												}
												componentClass="select"
												placeholder="select"
												className={classes.selectBox}
											>
												<option value="">ทั้งหมด</option>
												{this.state.settingTruckType.map((item, index) => (
													<option key={index} value={item.settingTruckTypeId}>
														{item.name[this.props.t('common:lang')]}
													</option>
												))}
											</FormControl>
										</th>
									</tr>
								</thead>

								<tbody>
									<Query
										query={favoriteAndBlacklistDriverListQuery}
										variables={{
											mode: this.state.selectTab,
											setting_truck_service: this.state
												.selectSettingTruckService,
											driver_name: this.state.inputDriverName,
										}}
									>
										{({ data, loading, error }) => {
											if (loading)
												return (
													<tr>
														<td colspan="3" className={classes.plSmall}>
															กำลังโหลด...
														</td>
													</tr>
												);

											if (error)
												return (
													<tr>
														<td colspan="3" className={classes.plSmall}>
															{error.graphQLErrors[0].message}
														</td>
													</tr>
												);

											const {
												driver_user,
											} = data.favoriteAndBlacklistDriverList;

											return (
												<React.Fragment>
													{driver_user.length === 0 && (
														<tr>
															<td colspan="3" className={classes.plSmall}>
																ไม่มีข้อมูล
															</td>
														</tr>
													)}

													{driver_user.map((item, index) => (
														<tr key={index}>
															<td className={classes.column1}>
																<button
																	className={classes.btnDelete}
																	title="ลบพนักงานขับรถ"
																	onClick={() =>
																		this.deleteDriverHandler(
																			item._id,
																			item.driver_name
																		)
																	}
																>
																	<i className="far fa-trash-alt"></i>
																</button>
															</td>
															<td className={classes.column2}>
																<div className={classes.profileList}>
																	<Avatar
																		src={
																			item.driver_display_pic
																				? `${s3}/${item.driver_display_pic}`
																				: ''
																		}
																	/>
																	<div className={classes.profileContent}>
																		{item.driver_name}
																	</div>
																</div>
															</td>
															<td className={classes.column3}>
																{
																	item.setting_truck_name[
																		this.props.t('common:lang')
																	]
																}{' '}
																-{' '}
																{
																	item.truck_type_name[
																		this.props.t('common:lang')
																	]
																}
															</td>
														</tr>
													))}
												</React.Fragment>
											);
										}}
									</Query>
								</tbody>
							</table>
						</ContentContainer>
					</WrapperContainer>

					<Mutation
						mutation={driverfavoriteAndBlacklistDeleteMutation}
						variables={{
							driver_user_id: this.state.driverId,
						}}
						update={({ data }) => {
							this.setState({
								isOpenModalDeleteDriver: false,
							});
						}}
						refetchQueries={() => ['FavoriteAndBlacklistDriverList']}
					>
						{(
							driverfavoriteAndBlacklistDelete,
							{ loading: loadingDriverfavoriteAndBlacklistDelete }
						) => {
							return (
								<ModalConfirm
									show={this.state.isOpenModalDeleteDriver}
									labelButtonConfirm={`ยืนยัน`}
									styleColorButtonConfirm="primary"
									labelButtonCancel={`ยกเลิก`}
									titleHeader={`แจ้งเตือน`}
									onHide={() => {
										this.setState({
											isOpenModalDeleteDriver: false,
										});
									}}
									onPressButtonCancel={() => {
										this.setState({
											isOpenModalDeleteDriver: false,
										});
									}}
									onPressButtonConfirm={() => {
										driverfavoriteAndBlacklistDelete();
									}}
									disabledButtonConfirm={
										loadingDriverfavoriteAndBlacklistDelete
									}
								>
									<div style={{ lineHeight: 1 }}>
										คุณต้องการลบ <strong>{this.state.driverName}</strong>{' '}
										ออกจากพนักงานคนโปรดใช่หรือไม่ ?
									</div>
								</ModalConfirm>
							);
						}}
					</Mutation>
				</Menu>
			</Container>
		);
	}
}

export default withNamespaces()(FavoriteDriver);
