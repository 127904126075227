import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import * as Datetime from 'react-datetime';
import './style.css';
import { Card, CardHeader, ModalConfirm, Skeleton } from 'src/components';
import { graphql, compose, Query } from 'react-apollo';
import instancePriceQueryClient from 'src/config/graphqlClient/query/instancePrice';
import instancePriceStepQueryClient from 'src/config/graphqlClient/query/instancePriceStep';
import locationTempQueryClient from 'src/config/graphqlClient/query/locationTemp';
import modeToText from '../../function/modeToText';
import client from 'src/config/client';
import { withNamespaces } from 'react-i18next';
import TimeField from 'react-simple-timefield';
import getMapPolylineQuery from './graphql/query/getMapPolyline';

const Container = styled.div`
	width: 100%;

	& .rdtDays tfoot {
		display: none !important;
	}
`;
class SelectDateTimeContact extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			datetime: '',
			validDatetime: '',
			isOpenModalAlert: false,
			textAlertModal: '',
			isLoading: false,
			isError: false,
			messageError: '',
			duration: 0,
		};
	}

	async componentDidMount() {
		await this.fetchDataShipmentDuration();
		this.setPickupDateShipmentAddress();
	}

	setPickupDateShipmentAddress = () => {
		const {
			shipmentAddressIndex,
			datetime,
		} = this.props.locationTempQuery.locationTemp;
		const { shipmentType } = this.props.instancePriceQuery.instancePrice;
		const { duration } = this.state;
		const pickupDateSelected = datetime;
		const pickupDatePreviousSelected = this.getPickupDatePreviousShipmentAddress();

		const isCheckHasDatetimeSelect = moment(
			pickupDateSelected,
			'YYYY-MM-DD HH:mm'
		).isValid();

		if (shipmentType === 1) {
			if (isCheckHasDatetimeSelect) {
				const datetimeAddDuration = this.addDateTimeShipmentDuration(
					pickupDatePreviousSelected,
					shipmentAddressIndex,
					duration
				);

				this.setState({ datetime: pickupDateSelected });
				this.setValidDatetime(datetimeAddDuration);
			} else {
				const isCheckDatetimeSameOrAfter = moment(
					pickupDateSelected
				).isSameOrAfter(pickupDatePreviousSelected);

				const datetimeCheck = isCheckDatetimeSameOrAfter
					? pickupDateSelected
					: pickupDatePreviousSelected;

				const datetimeAddDuration = this.addDateTimeShipmentDuration(
					datetimeCheck,
					shipmentAddressIndex,
					duration
				);

				this.setState({ datetime: datetimeAddDuration });
				this.setValidDatetime(datetimeAddDuration);
			}
		} else {
			//type 2 or 3
			if (isCheckHasDatetimeSelect) {
				this.setState({ datetime: pickupDateSelected });
				this.setValidDatetime(pickupDatePreviousSelected);
			} else {
				const isCheckDatetimeSameOrAfter = moment(
					pickupDateSelected
				).isSameOrAfter(pickupDatePreviousSelected);

				const datetimeCheck = isCheckDatetimeSameOrAfter
					? pickupDateSelected
					: pickupDatePreviousSelected;

				this.setState({ datetime: datetimeCheck });
				this.setValidDatetime(datetimeCheck);
			}
		}
	};

	addDateTimeShipmentDuration = (dateTime, shipmentAddressIndex, duration) => {
		const dateTimeAddDuration = moment(dateTime).add(duration, 'seconds');
		if (shipmentAddressIndex === 1) {
			dateTimeAddDuration.add(1, 'hours');
		} else if (shipmentAddressIndex > 1) {
			dateTimeAddDuration.add(20, 'minutes');
		}

		return dateTimeAddDuration.format('YYYY-MM-DD HH:mm');
	};

	getPickupDatePreviousShipmentAddress = () => {
		const {
			shipmentAddress,
			shipmentType,
			pickupDate,
		} = this.props.instancePriceQuery.instancePrice;

		const { shipmentAddressIndex } = this.props.locationTempQuery.locationTemp;

		if (shipmentType === 1) {
			return shipmentAddressIndex === 1
				? pickupDate
				: shipmentAddress[shipmentAddressIndex - 1].pickupDate;
		}

		return shipmentAddress[shipmentAddressIndex - 1].pickupDate;
	};

	getLatLngShipmentAddressClient = shipmentAddressIndex => {
		const { shipmentAddress } = this.props.instancePriceQuery.instancePrice;
		return {
			lat: shipmentAddress[shipmentAddressIndex].latitude,
			lng: shipmentAddress[shipmentAddressIndex].longitude,
		};
	};

	fetchDataShipmentDuration = async () => {
		this.setState({ isLoading: true });

		try {
			const {
				shipmentAddressIndex,
			} = this.props.locationTempQuery.locationTemp;

			const shipmentAddressLatLngForm = this.getLatLngShipmentAddressClient(
				shipmentAddressIndex - 1
			);

			const shipmentAddressLatLngTo = this.getLatLngShipmentAddressClient(
				shipmentAddressIndex
			);

			const { data } = await client.query({
				query: getMapPolylineQuery,
				variables: {
					address: [shipmentAddressLatLngForm, shipmentAddressLatLngTo],
				},
			});

			const { polyline_list: polylineList } = data.getMapPolyline;
			this.setState({
				isLoading: false,
				duration: polylineList[0].duration,
			});
		} catch (error) {
			let textError =
				error.graphQLErrors && error.graphQLErrors.length > 0
					? error.graphQLErrors[0].message
					: error.message;

			this.setState({
				messageError: textError,
				isLoading: false,
				isError: true,
			});
		}
	};

	closePopup() {
		client.writeQuery({
			query: instancePriceStepQueryClient,
			data: {
				instancePriceStep: {
					...this.props.instancePriceStepQuery.instancePriceStep,
					selectDateTimeContact: false,
				},
			},
		});
	}

	setValidDatetime(datetime) {
		const date = moment(datetime, 'YYYY-MM-DD HH:mm').format(
			'YYYY-MM-DD HH:mm'
		);
		this.setState({ validDatetime: date });
	}

	checkValidDate(calendarDate) {
		const date = moment(calendarDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
		const futureDay = moment(
			this.state.validDatetime,
			'YYYY-MM-DD HH:mm'
		).format('YYYY-MM-DD');
		return moment(date).isSameOrAfter(futureDay);
	}

	handleChange = selectDate => {
		const datetime = moment(selectDate).format('YYYY-MM-DD HH:mm');
		this.setState({ datetime: datetime });
	};

	confirmSelectDatetime() {
		const { duration } = this.state;
		const { shipmentAddressIndex } = this.props.locationTempQuery.locationTemp;
		const { shipmentType } = this.props.instancePriceQuery.instancePrice;
		const pickupDatePrevious = this.getPickupDatePreviousShipmentAddress();

		let isValidDatetime = false;
		let pickupDateMin = null;
		if (shipmentType === 1) {
			pickupDateMin = this.addDateTimeShipmentDuration(
				pickupDatePrevious,
				shipmentAddressIndex,
				duration
			);

			isValidDatetime = moment(this.state.datetime).isSameOrAfter(
				pickupDateMin
			);
		} else {
			pickupDateMin = pickupDatePrevious;

			isValidDatetime = moment(this.state.datetime).isSameOrAfter(
				pickupDateMin
			);
		}

		if (isValidDatetime) {
			client.writeQuery({
				query: locationTempQueryClient,
				data: {
					locationTemp: {
						...this.props.locationTempQuery.locationTemp,
						datetime: this.state.datetime,
					},
				},
			});

			this.closePopup();
		} else {
			this.setState({
				isOpenModalAlert: true,
				textAlertModal: `${this.props.t(
					'common:instancePrice.selectDateTimeContact.modalSelectDateTimeInvalid.content1'
				)} ${modeToText(
					this.props.locationTempQuery.locationTemp.type,
					this.props.t('common:lang')
				)} ${this.props.t(
					'common:instancePrice.selectDateTimeContact.modalSelectDateTimeInvalid.content2'
				)} ${moment(pickupDateMin).format('DD/MM/YYYY HH:mm')}`,
			});
		}
	}

	render() {
		let datePicker = (
			<Skeleton width={220} height={170}>
				<rect x="0" y="0" rx="4" ry="4" width="143" height="13" />
				<rect x="0" y="24" rx="3" ry="3" width="200" height="10" />
				<rect x="0" y="59" rx="4" ry="4" width="143" height="13" />
				<rect x="0" y="83" rx="3" ry="3" width="200" height="10" />
				<rect x="0" y="118" rx="4" ry="4" width="143" height="13" />
				<rect x="0" y="142" rx="3" ry="3" width="200" height="10" />
			</Skeleton>
		);

		if (!this.state.isLoading) {
			if (!this.state.isError) {
				datePicker = (
					<div>
						<Datetime
							locale={this.props.t('common:lang')}
							input={false}
							isValidDate={date => this.checkValidDate(date)}
							dateFormat="YYYY-MM-DD"
							timeFormat="HH:mm"
							value={this.state.datetime}
							onChange={this.handleChange}
						/>

						<TimeField
							style={{
								width: '100%',
								fontSize: '50px',
								textAlign: 'center',
								border: 'none',
								borderBottom: '1px solid #efefef',
								lineHeight: '1',
								display: 'block',
								color: '#19AA19',
								outline: 'none',
								padding: '5px 0px',
							}}
							value={moment(this.state.datetime).format('HH:mm')}
							onChange={(event, value) => {
								const date = moment(this.state.datetime).format('YYYY-MM-DD');
								const dateTime = date + ' ' + value;
								this.setState({ datetime: dateTime });
							}}
						/>
					</div>
				);
			} else {
				datePicker = <div>{this.state.messageError}</div>;
			}
		}

		return (
			<Container>
				<ModalConfirm
					show={this.state.isOpenModalAlert}
					onPressButtonConfirm={() =>
						this.setState({ isOpenModalAlert: false })
					}
					labelButtonConfirm={this.props.t(
						'common:instancePrice.selectDateTimeContact.modalSelectDateTimeInvalid.buttonConfirm'
					)}
					titleHeader={this.props.t(
						'common:instancePrice.selectDateTimeContact.modalSelectDateTimeInvalid.title'
					)}
					styleColorButtonConfirm="primary"
					onHide={() => this.setState({ isOpenModalAlert: false })}
				>
					{this.state.textAlertModal}
				</ModalConfirm>

				<Card
					content={
						<div>
							<CardHeader
								title={`${this.props.t(
									'common:instancePrice.selectDateTimeContact.title'
								)}${modeToText(
									this.props.locationTempQuery.locationTemp.type,
									this.props.t('common:lang')
								)}`}
								onPressClose={() => this.closePopup()}
								borderCardBottom
							/>

							<div className="dateTime-wrapper">{datePicker}</div>
							<div style={{ marginTop: '20px' }}>
								<Button
									onClick={() => this.confirmSelectDatetime()}
									size="lg"
									block
									bsStyle="primary"
								>
									{this.props.t(
										'common:instancePrice.selectDateTimeContact.buttonConfirm'
									)}
								</Button>
							</div>
						</div>
					}
				/>
			</Container>
		);
	}
}

SelectDateTimeContact.propTypes = {};
SelectDateTimeContact.defaultProps = {};
export default compose(
	graphql(instancePriceQueryClient, {
		name: 'instancePriceQuery',
	}),
	graphql(instancePriceStepQueryClient, {
		name: 'instancePriceStepQuery',
	}),
	graphql(locationTempQueryClient, {
		name: 'locationTempQuery',
	})
)(withNamespaces()(SelectDateTimeContact));
