import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	position: relative;
`;

const Pin = styled.div`
	font-size: 16px;
	margin-right: 15px;
	color: ${props => (props.color ? props.color : '#000000')};
`;

const RouteContainer = styled.div`
	flex: 1;
	min-height: 42px;
`;

const LocationContainer = styled.div`
	padding-bottom: 10px;
	margin-top: -3px;
`;

const Location = styled.div`
	color: #000000;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
`;

const Label = styled.div`
	color: #000000;
	font-size: 18px;
`;

const ContactContainer = styled.div``;

const ContactButtonContainer = styled.div`
	margin-top: 5px;
	margin-bottom: 20px;
`;

const ButtonAddContact = styled.button`
	font-size: 18px;
	background-color: #f9f9f9;
	border: 1px solid #e3e3e3;
	outline: none;
	color: #000000;
	border-radius: 4px;
	width: 100%;
	line-height: 1;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	& i {
		color: #19aa19;
		font-size: 16px;
		margin-right: 5px;
	}

	&:hover {
		background-color: #f9f9f990;
	}
`;

const Required = styled.span`
	color: #ff0000;
`;

const ContactDetailContainer = styled.div`
	margin-bottom: 15px;

	& .contact-detail:not(:first-child) {
		margin-top: 10px;
	}
`;

const ContactTitle = styled.div`
	font-size: 18px;
`;

const ContactDetail = styled.div`
	font-size: 18px;
	color: #000000;
	font-weight: bold;
	line-height: 1;
`;

const Line = styled.div`
	border-bottom: 1px solid #e3e3e3;
`;

const ContactEditContainer = styled.div`
	margin-top: 6px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

const ButtonEdit = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	color: #888888;
	cursor: pointer;

	& i {
		margin-left: 5px;
	}
`;

const RouteContact = props => {
	return (
		<Container {...props}>
			<Pin color={props.pinColor}>
				<i className="fas fa-map-marker-alt" />
			</Pin>
			<RouteContainer>
				<LocationContainer>
					<Label>{props.label}</Label>
					<Location>{props.location}</Location>
				</LocationContainer>
				<ContactContainer>
					{props.onPressEdit && !(
						(props.shipmentType === 1 && props.contactName && props.contactPhoneNumber) ||
						((props.shipmentType === 2 || props.shipmentType === 3) &&
							props.contactName &&
							props.contactPhoneNumber &&
							props.contactDatetime)
					) && (
						<ContactButtonContainer>
							<ButtonAddContact onClick={props.onPressEdit}>
								<i className="fal fa-plus-circle"></i>
								<span>{props.t('common:instancePrice.shipmentConfirm.contact.buttonAdd')}</span>
								<Required>*</Required>
							</ButtonAddContact>
						</ContactButtonContainer>
					)}

					{((props.shipmentType === 1 && props.contactName && props.contactPhoneNumber) ||
						((props.shipmentType === 2 || props.shipmentType === 3) &&
							props.contactName &&
							props.contactPhoneNumber &&
							props.contactDatetime)) && (
						<ContactDetailContainer>
							{props.contactDatetime && (
								<div className="contact-detail">
									<ContactTitle>{props.titleContactDatetime}</ContactTitle>
									<ContactDetail>{props.contactDatetime}</ContactDetail>
								</div>
							)}

							<div className="contact-detail">
								<ContactTitle>
									{props.t(
										'common:instancePrice.shipmentConfirm.contact.titleCardContactInformation'
									)}
								</ContactTitle>
								<ContactDetail>
									{props.contactName}, {props.contactPhoneNumber}
								</ContactDetail>
							</div>

							<div className="contact-detail">
								<ContactTitle>{props.titleContactRemark}</ContactTitle>
								<ContactDetail>{props.contactRemark}</ContactDetail>
							</div>
						</ContactDetailContainer>
					)}
				</ContactContainer>

				<Line />
				
				{
					(props.onPressEdit && ((props.shipmentType === 1 && props.contactName && props.contactPhoneNumber) ||
					((props.shipmentType === 2 || props.shipmentType === 3) &&
						props.contactName &&
						props.contactPhoneNumber &&
						props.contactDatetime))) && (
						<ContactEditContainer>
							<ButtonEdit onClick={props.onPressEdit}>
								<span>{props.t('common:instancePrice.shipmentConfirm.contact.buttonEdit')}</span>{' '}
								<i className="fal fa-chevron-right"></i>
							</ButtonEdit>
						</ContactEditContainer>
					)
				}
			</RouteContainer>
		</Container>
	);
};

RouteContact.propTypes = {
	pinColor: PropTypes.string,
	location: PropTypes.string,
	label: PropTypes.string,
	onPressEdit: PropTypes.func,
	contactName: PropTypes.string,
	contactPhoneNumber: PropTypes.string,
	contactRemark: PropTypes.string,
	contactDatetime: PropTypes.string,
	titleContactDatetime: PropTypes.string,
	titleContactRemark: PropTypes.string,
};

RouteContact.defaultProps = {
	pinColor: '#000000',
	location: '',
	label: '',
	contactName: '',
	contactPhoneNumber: '',
	contactRemark: '',
	contactDatetime: '',
	titleContactDatetime: '',
	titleContactRemark: '',
};

export default withNamespaces()(RouteContact);
