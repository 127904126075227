import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;
const SelectBox = styled.select`
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  font-size: 18px;
  padding: 4px 10px;
  width: 100%;
  outline: none;

  ${props => props.error && `
    border: 1px solid #D90101;
  `}
`;
const Label = styled.label`
  line-height: 1;
  color: #808080;
  white-space: nowrap;
`;

const Required = styled.span`
  color: #D90101;
`;

const Select = (props) => {
  return (
    <Container style={props.style}>
      {
        props.inputLabel && (
          <Label>{props.inputLabel}{props.required && <Required>*</Required>}</Label>
        )
      }
      
      <SelectBox {...props}  style={props.styleSelect}>{ props.children }</SelectBox>
    </Container>
  )
}

export default Select;