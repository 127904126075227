import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import {
	Card,
	BlankSpace,
	IconButton,
	DropPoint,
	MessageAlert,
	Select,
	Modal,
} from '../../components';
import { PDFPreview } from 'src/components';
import urijs from 'urijs';
import Moment from 'react-moment';
import Helmet from 'react-helmet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import baseUrl from '../../../../config/baseUrl';
import s3 from '../../../../config/s3';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

//gql
import { Query, Mutation } from 'react-apollo';
import shipmentListQuery from './graphql/query/shipmentList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import sendShipmentToMamaMutation from './graphql/mutation/sendShipmentToMama';
import reSendShipmentToMamaMutation from './graphql/mutation/reSendShipmentToMama';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';

const token = {
	authorization:
		'giztix ej8RYXCKc4DM3LT5t4pjA72JfyxxtnTMP85N2hrHP38EdxYdTDTfDjgrQ6Zr7s2hgT76SzNg5XHPqBHZVJa9ZbrxUUe5jxqmQTeS77mgZFfWLDdu7mNVSXFrEqEf3J24',
};

const Container = styled.div`
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	position: relative;

	display: flex;
	flex-direction: column;
	font-size: 18px;

	.text-primary {
		color: #d90101;
	}

	.text-grey {
		color: #808080;
	}

	.text-green {
		color: #009222;
	}

	.text-header {
		white-space: nowrap;
		color: #808080;
	}

	.text-bold {
		font-weight: bold;
	}

	@media (min-width: 564px) {
		font-size: 20px;
	}

	& a,
	a:active,
	a:focus,
	a:visited,
	a:hover {
		color: #087de3;
		text-decoration: none;
	}

	& button {
		height: 40px;
		box-sizing: border-box;

		&:focus {
			outline: none;
		}
	}
`;
const ImageProductContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 10px;

	& .image {
		width: 86px;
		height: 86px;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;
		box-sizing: content-box;
		cursor: pointer;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}

		margin-right: 10px;
		margin-bottom: 10px;
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000030;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: block;
		}
	}
`;

class ShipmentCS extends React.Component {
	state = {
		isOpenModalSendShipmentToMama: false,
		isOpenModalSendShipmentToMamaAgain: false,
		isOpenModalCancel: false,
		isOpenModalCancelReason: false,
		isShowAlert: false,
		cancelReasonInvalid: '',
		cancelReason: '',
		shipmentId: '',
		loading: false,
		titleAlert: '',
		isFirst: true,
	};

	componentWillMount() {
		const uri = urijs(window.location.href).query(true).shipment;
		if (uri) {
			this.setState({ shipmentId: uri });
		}
	}

	changeCancelReason(event) {
		this.setState({ cancelReason: { value: event.value, label: event.label } });
	}

	formatPhoneNumber(phoneNumber) {
		const re = /(^0\d{2})(\d{3})(\d{4})$/g;
		const newData = phoneNumber.replace(re, '$1-$2-$3');
		return newData;
	}

	formatNumber(number, mode) {
		if (mode === 'nodot') {
			return number.toFixed(0).replace(/\d(?=(\d{3}))/g, '$&,');
		}
		return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	}

	checkDriver = () => {
		if (this.state.isFirst) {
			this.setState({
				isShowAlert: true,
				titleAlert: 'ได้คนขับรถเรียบร้อยแล้ว',
				isFirst: false,
			});
		}
	};

	renderAdditional(item) {
		let text = '';

		for (let i = 0; i < item.length; i++) {
			if (item[i].list_type === 2) {
				text += `${item[i].list_name}`;

				if (item[i].list_name === 'แรงงานยกสินค้า') {
					text += ` x ${item[i].list_qty}`;
				}

				if (i < item.length - 1) {
					text += ', ';
				}
			}
		}

		if (text === '') {
			text = 'ไม่มี';
		}

		return text;
	}

	render() {
		const cancelReasonValidate = Yup.object().shape({
			cancelReason: Yup.string().required('Required'),
		});

		return (
			<Container>
				<Helmet>
					<title>BOOKING</title>
					<meta
						name="description"
						content="สามารถเช็คราคาและจองบริการขนส่ง ทั้งแอปพลิเคชันบนมือถือ แทบเล็ต ให้ธุรกิจคุณสะดวกขึ้น"
					/>
				</Helmet>

				<Query
					query={shipmentListQuery}
					variables={{ _id: this.state.shipmentId }}
					fetchPolicy="network-only"
					onCompleted={data => {
						if (data.shipmentList.shipment[0].driver_user_id) {
							this.checkDriver();
						}
					}}
					context={token}
				>
					{({ loading, error, data, refetch }) => {
						console.log(data);
						if (loading) return <Card>loading...</Card>;

						if (error) return <Card>Error</Card>;

						if (
							data.shipmentList.shipment.length === 0 ||
							data.shipmentList.shipment[0]._id !== this.state.shipmentId
						) {
							return <Card>ไม่พบข้อมูล</Card>;
						}

						const shipmentList = data.shipmentList.shipment[0];
						const shipmentCostList = data.shipmentCostList;
						const trackingList = data.trackingList.tracking[0];
						const invoiceList = data.invoiceList[0];

						const documents = shipmentList.shipment_other_detail.document.map(
							item => {
								const patternFileType = /\.[0-9a-z]{1,5}$/;
								const patternCheckFileType = /^.*\.(pdf|PDF)$/;
								const thumbnail = patternCheckFileType.test(item.path)
									? `${require('src/assets/images/file-type-pdf.png')}`
									: `${s3}/${item.path}`;
								const type = patternCheckFileType.test(item.path)
									? 'pdf'
									: 'image';

								return {
									path: `${s3}/${item.path}`, //path ไฟล์
									fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
									type: type, //ประเภทไฟล์
									thumbnail: thumbnail, //รูปตัวอย่าง
								};
							}
						);

						const documentsImage = documents.map(item => {
							const patternCheckFileType = /^.*\.(pdf|PDF)$/;
							if (patternCheckFileType.test(item.path)) {
								return <PDFPreview file={item.path} />;
							}

							return item.thumbnail;
						});

						return (
							<Query
								query={accountInfoListQuery}
								context={token}
								variables={{ id: shipmentList.shipper }}
							>
								{({
									loading: loadingAccountInfoList,
									error: errorAccountInfoList,
									data,
								}) => {
									if (loadingAccountInfoList || errorAccountInfoList)
										return null;

									const accountInfoList = data.accountInfoList.account_info[0];
									if (
										!loading &&
										!loadingAccountInfoList &&
										!errorAccountInfoList
									) {
										return (
											<div>
												<Helmet>
													<title>
														BOOKING หมายเลข : {shipmentList.shipment_number} |
														GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
														รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า
														ส่งสินค้าด่วน
													</title>
													<meta
														name="description"
														content="สามารถเช็คราคาและจองบริการขนส่ง ทั้งแอปพลิเคชันบนมือถือ แทบเล็ต ให้ธุรกิจคุณสะดวกขึ้น"
													/>
												</Helmet>

												<Modal
													title="แจ้งเตือน"
													show={this.state.isOpenModalSendShipmentToMama}
													onHide={() =>
														this.setState({
															isOpenModalSendShipmentToMama: false,
														})
													}
												>
													<div>คุณต้องการกระจายงานนี้ ใช่หรือไม่?</div>
													<div style={{ marginTop: 10 }}>
														<Row>
															<Col xs={6}>
																<Button
																	block
																	onClick={() =>
																		this.setState({
																			isOpenModalSendShipmentToMama: false,
																		})
																	}
																>
																	ยกเลิก
																</Button>
															</Col>
															<Col xs={6}>
																<Mutation
																	mutation={sendShipmentToMamaMutation}
																	variables={{ shipment_id: shipmentList._id }}
																	update={(cache, { data }) => {
																		this.setState(
																			{
																				isOpenModalSendShipmentToMama: false,
																				loading: false,
																				titleAlert: 'กระจายงานเรียบร้อยแล้ว',
																				isShowAlert: true,
																			},
																			() => refetch()
																		);
																	}}
																	onError={error => {
																		console.log(error);
																		this.setState({ loading: false });
																	}}
																	context={token}
																>
																	{sendShipmentToMama => {
																		return (
																			<Button
																				disabled={this.state.loading}
																				bsStyle="primary"
																				block
																				onClick={() =>
																					this.setState(
																						{
																							isOpenModalSendShipmentToMama: false,
																							loading: true,
																						},
																						() => sendShipmentToMama()
																					)
																				}
																			>
																				ยืนยัน
																			</Button>
																		);
																	}}
																</Mutation>
															</Col>
														</Row>
													</div>
												</Modal>

												<Modal
													title="แจ้งเตือน"
													show={this.state.isOpenModalSendShipmentToMamaAgain}
													onHide={() =>
														this.setState({
															isOpenModalSendShipmentToMamaAgain: false,
														})
													}
												>
													<div>คุณต้องการกระจายงานนี้ อีกครั้งหรือไม่?</div>
													<div style={{ marginTop: 10 }}>
														<Row>
															<Col xs={6}>
																<Button
																	block
																	onClick={() =>
																		this.setState({
																			isOpenModalSendShipmentToMamaAgain: false,
																		})
																	}
																>
																	ยกเลิก
																</Button>
															</Col>
															<Col xs={6}>
																<Mutation
																	mutation={reSendShipmentToMamaMutation}
																	variables={{ shipment_id: shipmentList._id }}
																	update={(cache, { data }) => {
																		this.setState(
																			{
																				isOpenModalSendShipmentToMamaAgain: false,
																				loading: false,
																				titleAlert: 'กระจายงานเรียบร้อยแล้ว',
																				isShowAlert: true,
																			},
																			() => refetch()
																		);
																	}}
																	onError={error => {
																		console.log(error);
																		this.setState({ loading: false });
																	}}
																	context={token}
																>
																	{reSendShipmentToMama => {
																		return (
																			<Button
																				disabled={this.state.loading}
																				bsStyle="primary"
																				block
																				onClick={() =>
																					this.setState(
																						{
																							isOpenModalSendShipmentToMamaAgain: false,
																							loading: true,
																						},
																						() => reSendShipmentToMama()
																					)
																				}
																			>
																				ยืนยัน
																			</Button>
																		);
																	}}
																</Mutation>
															</Col>
														</Row>
													</div>
												</Modal>

												<Modal
													title="แจ้งเตือน"
													show={this.state.isOpenModalCancel}
													onHide={() =>
														this.setState({ isOpenModalCancel: false })
													}
												>
													<div>คุณต้องการยกเลิก BOOKING นี้ใช่หรือไม่?</div>
													<div style={{ marginTop: 10 }}>
														<Row>
															<Col xs={6}>
																<Button
																	block
																	onClick={() =>
																		this.setState({ isOpenModalCancel: false })
																	}
																>
																	ยกเลิก
																</Button>
															</Col>
															<Col xs={6}>
																<Button
																	bsStyle="primary"
																	block
																	onClick={() =>
																		this.setState({
																			cancelReason: '',
																			isOpenModalCancel: false,
																			isOpenModalCancelReason: true,
																		})
																	}
																>
																	ยืนยัน
																</Button>
															</Col>
														</Row>
													</div>
												</Modal>

												<Formik
													initialValues={{ cancelReason: '' }}
													validationSchema={cancelReasonValidate}
													render={props => {
														return (
															<Modal
																title="แจ้งเตือน"
																show={this.state.isOpenModalCancelReason}
																onHide={() =>
																	this.setState({
																		isOpenModalCancelReason: false,
																	})
																}
															>
																<div>
																	เลือกเหตุผลที่ต้องการยกเลิก BOOKING นี้
																</div>
																<div style={{ marginTop: 10 }}>
																	<Select
																		value={props.values.cancelReason}
																		onChange={props.handleChange}
																		name="cancelReason"
																	>
																		<option value="">-- เลือก --</option>
																		<option value="ทดสอบ">ทดสอบ</option>
																		<option value="ลูกค้ายกเลิกงาน">
																			ลูกค้ายกเลิกงาน
																		</option>
																		<option value="เปลี่ยนเส้นทาง">
																			เปลี่ยนเส้นทาง
																		</option>
																		<option value="เปลี่ยนวันใช้งาน">
																			เปลี่ยนวันใช้งาน
																		</option>
																		<option value="เปลี่ยนประเภทรถ">
																			เปลี่ยนประเภทรถ
																		</option>
																		<option value="ไม่ตรงตามเงื่อนไข">
																			ไม่ตรงตามเงื่อนไข
																		</option>
																		<option value="ไม่พร้อมให้บริการ">
																			ไม่พร้อมให้บริการ
																		</option>
																		<option value="ลูกค้าจองซ้ำ">
																			ลูกค้าจองซ้ำ
																		</option>
																		<option value="ไม่สามารถติดต่อลูกค้าได้">
																			ไม่สามารถติดต่อลูกค้าได้
																		</option>
																		<option value="ลูกค้าต้องการใช้บริการเสริม">
																			ลูกค้าต้องการใช้บริการเสริม
																		</option>
																	</Select>
																</div>
																<div style={{ marginTop: 100 }}>
																	<Row>
																		<Col xs={6}>
																			<Button
																				block
																				onClick={() =>
																					this.setState({
																						isOpenModalCancelReason: false,
																					})
																				}
																			>
																				ยกเลิก
																			</Button>
																		</Col>
																		<Col xs={6}>
																			<Mutation
																				mutation={shipmentsUpdateMutation}
																				variables={{
																					shipment_id: shipmentList._id,
																					cancel_comment:
																						props.values.cancelReason,
																					shipment_status: 5,
																				}}
																				update={(cache, { data }) => {
																					if (data.shipmentsUpdate._id) {
																						this.setState(
																							{
																								isOpenModalCancelReason: false,
																								loading: false,
																							},
																							() => refetch()
																						);
																					}
																				}}
																				onError={error => {
																					console.log(error);
																					this.setState({ loading: false });
																				}}
																				context={token}
																			>
																				{shipmentsUpdate => {
																					return (
																						<Button
																							disabled={
																								this.state.loading ||
																								!props.isValid
																							}
																							bsStyle="primary"
																							block
																							onClick={() =>
																								this.setState(
																									{ loading: true },
																									() => shipmentsUpdate()
																								)
																							}
																						>
																							บันทึก
																						</Button>
																					);
																				}}
																			</Mutation>
																		</Col>
																	</Row>
																</div>
															</Modal>
														);
													}}
												/>

												{this.state.isShowAlert && (
													<div
														onClick={() =>
															this.setState({ isShowAlert: false })
														}
													>
														<MessageAlert
															text={this.state.titleAlert}
															time={
																<Moment format="DD/MM/YYYY เวลา HH:mm น.">
																	{new Date()}
																</Moment>
															}
														/>
														<BlankSpace />
													</div>
												)}

												<Card>
													<Row>
														<Col xs={4}>
															<span className="text-header">ชื่อผู้จอง :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{shipmentList.shipper_detail}
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">
																วันที่ใช้บริการ :
															</span>
														</Col>
														<Col xs={8}>
															<span className="text-primary text-bold">
																<Moment format="DD/MM/YYYY เวลา HH:mm น.">
																	{shipmentList.pick_up_date}
																</Moment>
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">ประเภทรถ :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																รถ {shipmentList.setting_truck_name.th}{' '}
																{shipmentList.truck_type_name.th}
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">บริการเสริม :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{this.renderAdditional(
																	invoiceList.invoice_list
																)}
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">
																ช่องทางชำระเงิน :
															</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{shipmentList.paid_type === 1
																	? 'บัตรเครดิต'
																	: null}
																{shipmentList.paid_type === 2
																	? shipmentList.paid_status
																		? 'ชำระเงินแล้ว'
																		: 'ยังไม่ได้ชำระเงิน'
																	: null}
																{shipmentList.paid_type === 3 ? 'วางบิล' : null}
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">
																เบอร์โทรติดต่อ :
															</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{this.formatPhoneNumber(
																	'0' +
																		accountInfoList.account_info_phone_number
																)}
															</span>{' '}
															<IconButton
																bgColor="#D90101"
																icon="fas fa-phone"
																href={`tel:+${accountInfoList.account_info_phone_code}${accountInfoList.account_info_phone_number}`}
																style={{ marginLeft: 5 }}
															/>
														</Col>
													</Row>
												</Card>

												<BlankSpace />

												<Card>
													<DropPoint
														address={shipmentList.shipment_address}
														pickup={shipmentList.shipment_address[0].address}
														delivery={shipmentList.shipment_address[1].address}
														hrefPickup={`https://maps.google.com?q=${shipmentList.shipment_address[0].lat},${shipmentList.shipment_address[0].lng}`}
														hrefDelivery={`https://maps.google.com?q=${shipmentList.shipment_address[1].lat},${shipmentList.shipment_address[1].lng}`}
													/>
												</Card>

												{shipmentList.shipment_other_detail.remark ||
												shipmentList.shipment_other_detail.user_ref ||
												documents.length ? (
													<BlankSpace />
												) : null}

												{shipmentList.shipment_other_detail.remark ||
												shipmentList.shipment_other_detail.user_ref ||
												documents.length ? (
													<Card>
														{documents.length > 0 && (
															<Row>
																<Col xs={12}>
																	<span className="text-header">
																		รูปภาพสินค้า/เอกสาร :
																	</span>
																</Col>
																<Col xs={12}>
																	<ImageProductContainer>
																		{documents.map((item, index) => (
																			<div
																				className="image"
																				onClick={() =>
																					this.setState({
																						photoProductIndexLightBox: index,
																						isOpenPhotoProductLightBox: true,
																					})
																				}
																			>
																				<div className="image-hover"></div>
																				<img
																					src={`${item.thumbnail}`}
																					alt="productImage"
																				/>
																			</div>
																		))}
																	</ImageProductContainer>

																	{this.state.isOpenPhotoProductLightBox && (
																		<Lightbox
																			mainSrc={
																				documentsImage[
																					this.state.photoProductIndexLightBox
																				]
																			}
																			nextSrc={
																				documentsImage.length > 1
																					? documentsImage[
																							(this.state
																								.photoProductIndexLightBox +
																								1) %
																								documentsImage.length
																					  ]
																					: undefined
																			}
																			prevSrc={
																				documentsImage.length > 1
																					? documentsImage[
																							(this.state
																								.photoProductIndexLightBox +
																								documentsImage.length -
																								1) %
																								documentsImage.length
																					  ]
																					: undefined
																			}
																			onCloseRequest={() =>
																				this.setState({
																					isOpenPhotoProductLightBox: false,
																				})
																			}
																			onMovePrevRequest={() =>
																				this.setState(prev => ({
																					photoProductIndexLightBox:
																						(prev.photoProductIndexLightBox +
																							documentsImage.length -
																							1) %
																						documentsImage.length,
																				}))
																			}
																			onMoveNextRequest={() =>
																				this.setState(prev => ({
																					photoProductIndexLightBox:
																						(prev.photoProductIndexLightBox +
																							1) %
																						documentsImage.length,
																				}))
																			}
																		/>
																	)}
																</Col>
															</Row>
														)}
														{shipmentList.shipment_other_detail.user_ref && (
															<Row>
																<Col xs={12}>
																	<span className="text-header">
																		รหัสอ้างอิงงานของคุณ :
																	</span>
																</Col>
																<Col xs={12}>
																	<span className="text-bold">
																		{
																			shipmentList.shipment_other_detail
																				.user_ref
																		}
																	</span>
																</Col>
															</Row>
														)}
														{shipmentList.shipment_other_detail.remark && (
															<Row>
																<Col xs={12}>
																	<span className="text-header">
																		หมายเหตุ/รายละเอียดสินค้า :
																	</span>
																</Col>
																<Col xs={12}>
																	<span className="text-bold">
																		{shipmentList.shipment_other_detail.remark}
																	</span>
																</Col>
															</Row>
														)}
													</Card>
												) : null}

												<BlankSpace />

												<Card>
													<Row>
														<Col xs={4}>
															<span className="text-header">ต้นทาง :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{shipmentList.shipment_address[0].subdictrict},{' '}
																{shipmentList.shipment_address[0].province}
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">ปลายทาง :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{shipmentList.shipment_address[1].subdictrict},{' '}
																{shipmentList.shipment_address[1].province}
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">รวมระยะทาง :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold">
																{shipmentList.distance
																	? this.formatNumber(
																			shipmentList.distance,
																			'nodot'
																	  )
																	: 0}{' '}
																กิโลเมตร
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">ราคาซื้อ :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold text-primary">
																{this.formatNumber(
																	shipmentCostList.shipment_cost +
																		shipmentCostList.additional_cost
																)}{' '}
																บาท
															</span>
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<span className="text-header">ราคาขาย :</span>
														</Col>
														<Col xs={8}>
															<span className="text-bold text-green">
																{this.formatNumber(invoiceList.total_price)} บาท
															</span>
														</Col>
													</Row>

													{!shipmentList.driver_user_id && (
														<div style={{ marginTop: 10, lineHeight: 1 }}>
															ดูรายละเอียดงานฉบับเต็ม <br />
															<a
																href={`${
																	baseUrl === 'http://localhost:3000'
																		? window.location.hostname === 'localhost'
																			? urijs(window.location)
																					.port('3002')
																					.host()
																			: urijs(window.location).host()
																		: 'http://admin.giztix.com'
																}/shipmentdetail?shipment=${shipmentList._id}`}
																rel="noopener noreferrer"
																target="_blank"
															>
																{`${
																	baseUrl === 'http://localhost:3000'
																		? window.location.hostname === 'localhost'
																			? urijs(window.location)
																					.port('3002')
																					.host()
																			: urijs(window.location).host()
																		: 'http://admin.giztix.com'
																}/shipmentdetail?shipment=${shipmentList._id}`}
															</a>
														</div>
													)}
												</Card>

												{shipmentList.driver_user_id && (
													<div>
														<BlankSpace />
														<Card>
															<Row>
																<Col xs={4}>
																	<span className="text-header">
																		ชื่อคนขับรถ :
																	</span>
																</Col>
																<Col xs={8}>
																	<span className="text-bold">
																		{trackingList.driver_name}
																	</span>
																</Col>
															</Row>
															<Row>
																<Col xs={4}>
																	<span className="text-header">
																		ทะเบียนรถ :
																	</span>
																</Col>
																<Col xs={8}>
																	<span className="text-bold">
																		{
																			trackingList.user_setting_truck_license_plate
																		}
																	</span>
																</Col>
															</Row>
															<Row>
																<Col xs={4}>
																	<span className="text-header">
																		เบอร์โทรติดต่อ :
																	</span>
																</Col>
																<Col xs={8}>
																	<span className="text-bold">
																		{this.formatPhoneNumber(
																			`0${trackingList.driver_phone_number}`
																		)}
																	</span>{' '}
																	<IconButton
																		bgColor="#D90101"
																		icon="fas fa-phone"
																		href={`tel:+${trackingList.driver_phone_code}${trackingList.driver_phone_number}`}
																		style={{ marginLeft: 5 }}
																	/>
																</Col>
															</Row>
														</Card>
													</div>
												)}

												{!shipmentList.driver_user_id && (
													<Card style={{ marginTop: 40 }}>
														{shipmentList.shipment_status !== 5 && (
															<Row>
																<Col xs={6}>
																	<Button
																		block
																		onClick={() =>
																			this.setState({ isOpenModalCancel: true })
																		}
																	>
																		ยกเลิกงาน
																	</Button>
																</Col>
																<Col xs={6}>
																	{shipmentList.mama_status !== '2' && (
																		<Button
																			bsStyle="primary"
																			block
																			onClick={() =>
																				this.setState({
																					isOpenModalSendShipmentToMama: true,
																				})
																			}
																		>
																			กระจายงาน
																		</Button>
																	)}

																	{shipmentList.mama_status === '2' && (
																		<Button
																			bsStyle="primary"
																			block
																			onClick={() =>
																				this.setState({
																					isOpenModalSendShipmentToMamaAgain: true,
																				})
																			}
																		>
																			กระจายงานอีกครั้ง
																		</Button>
																	)}
																</Col>
															</Row>
														)}
													</Card>
												)}
											</div>
										);
									}
								}}
							</Query>
						);
					}}
				</Query>
			</Container>
		);
	}
}

export default ShipmentCS;
