import React from 'react';
import styled from 'styled-components';

const SelectBox = styled.select`
  font-size: 19px;
  height: 40px;
  border: 1px solid #E3E3E3;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  outline: none;
`;

class Select extends React.Component {
  render() {
    return(
      <SelectBox
        name={this.props.name}
        style={this.props.style}
        value={this.props.value}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
      >
        {
          React.Children.map(this.props.children, (child, key) => {
            return React.cloneElement(child);
          })
        }
      </SelectBox>
    )
  }
}

export default Select;