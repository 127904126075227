import gql from 'graphql-tag';

export default gql`
	mutation PhoneNumberAvailable(
		$phone_code: String!
		$phone_number: String!
	) {
		phoneNumberAvailable(
			phone_code: $phone_code
			phone_number: $phone_number
		) {
			available
		}
	}
`;
